import styled from 'styled-components';

export const MessageTextareaWrapper = styled.div`
  label {
    display: block;
    font-size: 15px;
    margin-bottom: 8px;
    font-weight: 500;
    color: #666666;
  }
`;

export const TextArea = styled.textarea`
    background-color: #EEF0F4;
    resize: none;
    height: 200px;
    width: 100%;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    padding: 16px;
    color: #444444;
    border: 0;
`;
