import styled from 'styled-components';
import { COLOR_1, COLOR_2 } from '../../../styled/variables/variables';
import { backgrounder, mqDesktop, mqTablet } from '../../../styled/helpers/helpers';
import { BaseLabel } from '../../../styled/labels/labels';

export const Wrapper = styled.div`
  background-color: ${COLOR_1};
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  padding-top: 120px;

  * { position: relative; }
  &:before {
    content: "";
    ${backgrounder(`${process.env.PUBLIC_URL}/imgs/koort-texture-outline.svg`)};
    background-size: 800px;
    height: 100%;
    width: 100%;
    opacity: .3;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
  }

  ${mqTablet`
    &:before {
      background-size: 600px;
      background-repeat: repeat;
    }
  ` }
`;

export const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-bottom: 10px;
  width: 315px;
  margin-top: 50px;
  ${mqTablet`
    flex-grow: 1;
    width: 315px;
    order: 2;
    align-self: flex-start;
    margin-top: 50px;
  ` }
`;

export const Name = styled(BaseLabel)`
  color: ${COLOR_2};
  align-self: center;
  font-weight: 700;
  margin-bottom: 10px;
  cursor: pointer;

  ${mqTablet`
    align-self: flex-start;
    font-size: 24px;
  ` }
`;

export const Wrapper2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items:center;
  align-self:center;
  background-color:white;
  /* height: 700px; */
  width: 100%;
  ${mqTablet`
  margin-left: calc(55% - 630px);
    width: 950px;
    padding-bottom: 20px
  ` };
    ${mqDesktop`
    width: 100%;
  ` };
`;
