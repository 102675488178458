


// TODO retrieve it from the API.
const realEstateTypes = [
  {
    label: 'Tipos',
    id: 1,
    types: [
      {
        label: 'Todos os imóveis',
        id: undefined,
      },
      {
        label: 'apartamento',
        id: 0,
      },
      {
        label: 'casa',
        id: 1,
      },
      {
        label: 'casa de condomínio',
        id: 2,
      },
      {
        label: 'chácara',
        id: 3,
      },
      {
        label: 'cobertura',
        id: 4,
      },
      {
        label: 'flat',
        id: 5,
      },
      {
        label: 'kitnet',
        id: 6,
      },
      {
        label: 'lote / terreno',
        id: 7,
      },
      {
        label: 'sobrado',
        id: 8,
      },
      {
        label: 'consultório',
        id: 9,
      },
      {
        label: 'edifício residencial',
        id: 10,
      },
      {
        label: 'fazenda / sítio',
        id: 11,
      },
      {
        label: 'galpão / depósito / armazém',
        id: 12,
      },
      {
        label: 'imóvel comercial',
        id: 13,
      },
      {
        label: 'loja',
        id: 14,
      },
      {
        label: 'ponto comercial',
        id: 15,
      },
      {
        label: 'sala comercial',
        id: 16,
      }
    ]
  },
];

export default realEstateTypes;


// // TODO retrieve it from the API.
// const realEstateTypes = [
//   {
//     label: 'residencial',
//     id: '1',
//     types: [
//       {
//         label: 'casa',
//         id: '1',
//       },
      
//       {
//         label: 'apartamento',
//         id: '2',
//       },
//       {
//         label: 'cobertura',
//         id: '3',
//       },
//       {
//         label: 'chácara',
//         id: '4',
//       },
//       {
//         label: 'casa de condomínio',
//         id: '5',
//       },
//       {
//         label: 'cobertura',
//         id: '6',
//       },
//       {
//         label: 'flat',
//         id: '7',
//       },
//       {
//         label: 'kitnet',
//         id: '8',
//       },
//       {
//         label: 'lote / terreno',
//         id: '9',
//       },
//       {
//         label: 'sobrado',
//         id: '10',
//       },
//       {
//         label: 'edifício residencial',
//         id: '11',
//       },
//     ]
//   },
//   {
//     label: 'comercial',
//     id: '12',
//     types: [
//       {
//         label: 'galpão',
//         id: '13',
//       },
//       {
//         label: 'sala comercial',
//         id: '14',
//       },
//       {
//         label: 'loja',
//         id: '15',
//       },
//       {
//         label: 'consultório',
//         id: '16',
//       },
//       {
//         label: 'fazenda / sítio',
//         id: '17',
//       },
//       {
//         label: 'galpão / depósito / armazém',
//         id: '18',
//       },
//       {
//         label: 'imóvel comercial',
//         id: '19',
//       },
//       {
//         label: 'loja',
//         id: '20',
//       },
//       {
//         label: 'ponto comercial',
//         id: '21',
//       },
//       {
//         label: 'sala comercial',
//         id: '22',
//       },
//       {
//         label: 'lote / terreno comercial',
//         id: '23',
//       },
//     ]
//   },
// ];

// export default realEstateTypes;
