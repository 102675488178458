import React, { PureComponent } from 'react';
import styled from 'styled-components';
import Select from 'react-select';

import { BaseLabel } from '../../../../styled/labels/labels';

class CommonAreaFeatures extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        features: [],
      },
      fieldsHasFilled: false,
      generalPropertiesUnformatted: [],
      leisurePropertiesUnformatted: [],
      servicesPropertiesUnformatted: [],
      generalPropertiesFormatted: [],
      leisurePropertiesFormatted: [],
      servicesPropertiesFormatted: [],
    };
  }

  handleSelectChange = (arr, name) => {
    if (name === "selectedOptionsGeneral") {
      this.setState(prevState => ({
        ...prevState,
        generalPropertiesUnformatted: arr,
        generalPropertiesFormatted: arr !== null ?
          arr.map(item => item.value) : [],
      }));
    }

    if (name === "selectedOptionsLeisure") {
      this.setState(prevState => ({
        ...prevState,
        leisurePropertiesUnformatted: arr,
        leisurePropertiesFormatted: arr !== null ?
          arr.map(item => item.value) : [],
      }));
    }

    if (name === "selectedOptionsServices") {
      this.setState(prevState => ({
        ...prevState,
        servicesPropertiesUnformatted: arr,
        servicesPropertiesFormatted: arr !== null ?
          arr.map(item => item.value) : [],
      }));
    }

    this.setState(prevState => ({
      ...prevState,
      form: {
        features: prevState.generalPropertiesFormatted
          .concat(prevState.leisurePropertiesFormatted)
          .concat(prevState.servicesPropertiesFormatted)
      }
    }));
  }

  render() {
    const { isEditing, populate, data } = this.props;
    const { fieldsHasFilled } = this.state;

    if (isEditing) {
      if (populate.length > 0 && !fieldsHasFilled && !!data.length) {
        let generalProperties = [];

        for (let i = 0; i < data.length; i++) {
          for (let j = 0; j < populate.length; j++) {
            if (data[i].key === populate[j].key) {
              generalProperties.push({
                label: data[i].label,
                value: data[i].value,
              });
            }
          }
        }

        this.setState(prevState => ({
          ...prevState,
          fieldsHasFilled: true,
          generalPropertiesUnformatted: generalProperties,
          generalPropertiesFormatted: generalProperties.map(item => item.label),
          form: {
            features: generalProperties.map(item => item.label),
          }
        }));
      }
    }

    const customStyle = {
      control: base => ({
        ...base,
        border: '1px solid #999',
        boxShadow: 'none'
      }),
      option: (provided, state) => ({
        ...provided,
        fontSize: 16,
      }),
      input: (provided, state) => ({
        ...provided,
        fontSize: 25,
      }),

    }

    return (
      <Wrapper>
        <GroupTitle>
          características gerais das áreas comuns
        </GroupTitle>
        <GroupWrapper>
          <Select
            noOptionsMessage={() => 'Sem opções'}
            styles={customStyle}
            theme={theme => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary: 'none',
              },
            })}
            value={this.state.generalPropertiesUnformatted}
            onChange={(value) => { this.handleSelectChange(value, 'selectedOptionsGeneral') }}
            name="options"
            id="options"
            placeholder={'Selecione'}
            options={data}
            isMulti={true}
          />
        </GroupWrapper>
      </Wrapper>
    );
  }
}

const Wrapper = styled.form`
  margin-bottom: 20px;
`;

const GroupTitle = styled(BaseLabel)`
  text-transform: uppercase;
  font-size: 14px;
  margin-bottom: 10px;
`;

const GroupWrapper = styled.div`
  margin-bottom: 20px;
`;

const options = {
  general: [
    { label: 'Aceita Pet', value: 'Aceita Pet' },
    { label: 'Acessibilidade', value: 'Acessibilidade' },
    { label: 'Bicicletário', value: 'Bicicletário' },
    { label: 'Depósito Privativo', value: 'Depósito Privativo' },
    { label: 'Elevador', value: 'Elevador' },
    { label: 'Estacionamento Coberto', value: 'Estacionamento Coberto' },
    { label: 'Estacionamento Visitantes', value: 'Estacionamento Visitantes' },
    { label: 'Sistema de Incêndio', value: 'Sistema de Incêndio' },
    { label: 'Espaço Home Office', value: 'Espaço Home Office' },
    { label: 'Espaço Coworking', value: 'Espaço Coworking' },
    { label: 'Espaço para armazenar compras', value: 'Espaço para armazenar compras' },
    { label: 'Garagem com Vagas Elétricas', value: 'Garagem com Vagas Elétricas' },
  ],
  leisure: [
    { label: 'Academia', value: 'Academia' },
    { label: 'Churrasqueira', value: 'Churrasqueira' },
    { label: 'Piscina', value: 'Piscina' },
    { label: 'Playground', value: 'Playground' },
    { label: 'Quadra Poliesportiva', value: 'Quadra Poliesportiva' },
    { label: 'Salão de Festas', value: 'Salão de Festas' },
    { label: 'Salão de Jogos', value: 'Salão de Jogos' },
    { label: 'Sauna', value: 'Sauna' },
  ],
  services: [
    { label: 'Comércio/Serviços no Condomínio', value: 'Comércio/Serviços no Condomínio' },
    { label: 'Porteiro 24 Horas', value: 'Porteiro 24 Horas' },
    { label: 'Segurança Interna', value: 'Segurança Interna' },
    { label: 'Serviço de Transporte no Condomínio', value: 'Serviço de Transporte no Condomínio' },
  ],
};

export default CommonAreaFeatures;
