import httpClient from "../../clients/koort-business/http/http.client";

const getAllDataExternalIntegration = async ({ token }) => {
  const path = '/portals';

  const response = await httpClient.get({ path, token });

  return response;
};

const getRealEstates = async ({ token }) => {
  const path = '/realestates';

  const response = await httpClient.get({ path, token });

  return response;
}

const getIntegratedRealEstatesIds = async ({ portal, token }) => {
  const path = `/portals/${portal}/realestates`;

  const response = await httpClient.get({ path, token });

  return response;
}


const addRealEstatesOnPortal = async ({
  portal,
  realEstatesIds,
  companyCode = null,
  token
}) => {
  const path = `/portals/${portal}/realestates/add`;

  let params = {
    ids: realEstatesIds
  };

  if (companyCode !== null) {
    params = {
      ...params,
      companyCode,
    };
  }

  const response = await httpClient.post({
    path,
    params,
    token
  });

  return response;
}

const removeRealEstatesOnPortal = async ({ portal, realEstatesIds, token }) => {
  const path = `/portals/${portal}/realestates/remove`;

  const response = await httpClient.post({
    path,
    params: {
      ids: realEstatesIds,
    },
    token,
  });

  return response;
}

const removePortalIntegration = async ({ portal, token }) => {
  const path = `/portals/${portal}`;

  const response = await httpClient.del({
    path,
    token,
  });

  return response;
}

export default {
  getAllDataExternalIntegration,
  getRealEstates,
  getIntegratedRealEstatesIds,
  addRealEstatesOnPortal,
  removeRealEstatesOnPortal,
  removePortalIntegration,
};
