import React from 'react';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import propTypes from 'prop-types';

import reducer from '../../reducers/reducer';

const store = createStore(reducer);

const StoreProvider = ({
  children,
}) => (
  <Provider store={ store }>
    { children }
  </Provider>
);

StoreProvider.propTypes = {
  children: propTypes.arrayOf(propTypes.object),
};

export default StoreProvider;
