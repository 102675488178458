import React from 'react';
import { RiPhoneFill } from "react-icons/ri";

import { CTAWidgetWrapper } from "./styles";

export function CTAWidget({ onClick }) {
  return (
    <CTAWidgetWrapper type="button" onClick={onClick} aria-label="Entrar em contato">
      <RiPhoneFill size={24} color="#FFFFFF" />
    </CTAWidgetWrapper>
  );
}
