/* eslint-disable no-unreachable */
import React, { useEffect, useState } from 'react';
import { RiCloseLine, RiShieldCheckFill } from 'react-icons/ri';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toast } from 'react-toastify';

import BillingInfo from '../../purchase-flow/billing-info/BillingInfo';
import { ProductToContact } from './components/product-to-contact';

import planService from '../../../services/plan/plan.service';
import planV2Service from '../../../services/plan/plan-v2.service';
import userService from '../../../services/user/user.service';

import currencyUtil from '../../../utils/currency/currency.util';
import pathsConstants from '../../../constants/paths';

import {
  Container,
  ContactContainer,
  LoadingContainer,
  ErrorContainer,
  AboutPlanContent,
  PlanUseContent,
  Label,
  AdsInUseContent,
  PlanDivider,
  AdsTotalContent,
  Separator,
  PriceAndContractContent,
  PriceContent,
  ContractContent,
  ContractInformContent,
  RenewPlanButton,
  AboutAndUpgradePlanContent,
  UpgradePlanContent,
  UpgradePlanExpose,
  UpgradePlanCard,
  UpgradePlanCardHeader,
  UpgradePlanCardName,
  UpgradePlanCardDetailsList,
  UpgradePlanCardPrice,
  UpgradePlanCardButton,
  ChangePlanModal,
  ChangePlanModalContent,
  ChangePlanModalBody,
  ChangePlanModalCloseButton,
  LoadingProcessingPayment,
  ProcessingPaymentSuccessFul,
  RenewPlanModal,
  RenewPlanModalBody,
  AboutPlanContentBoxTop,
  AboutPlanContentBoxBottom,
  CancelPlanModal,
  CancelPlanModalBody,
  CancelPlanModalButtons,
  CancelPlanButton,
} from './styles';

export function Plan() {
  const [userStored, setUserStored] = useState();

  const [planData, setPlanData] = useState();
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const [plansForUpgradeData, setPlansForUpgradeData] = useState([]);
  const [isAvailableForRenewal, setIsAvailableForRenewal] = useState(false);
  const [loadingPlansForUpgrade, setLoadingPlansForUpgrade] = useState(true);
  const [hasErrorPlansForUpgrade, setHasErrorPlansForUpgrade] = useState(false);

  const [changePlanModalVisible, setChangePlanModalVisible] = useState(false);
  const [planId, setPlanId] = useState('');
  const [loadingProcessingPayment, setLoadingProcessingPayment] = useState(false);
  const [processingPaymentSuccessFul, setProcessingPaymentSuccessFul] = useState(false);

  const [renewPlanModalVisible, setRenewPlanModalVisible] = useState(false);
  const [loadingProcessingRenewal, setLoadingProcessingRenewal] = useState(false);
  const [processingPaymentRenewalSuccessFul, setProcessingPaymentRenewalSuccessFul] = useState(false);

  const [cancelPlanModalVisible, setCancelPlanModalVisible] = useState(false);
  const [loadingCancelPlan, setLoadingCancelPlan] = useState(false);
  const [planCancelledSuccessFul, setPlanCancelledSuccessFul] = useState(false);
  const [availableToCancellation, setAvailableToCancellation] = useState(false);
  const [endContractAt, setEndContractAt] = useState('');

  useEffect(() => {
    async function loadPlan() {
      try {
        const user = userService.retrieve();

        setUserStored(user);

        const { status, data } = await planV2Service.getUserSubscription({ token: user.token });

        if (status !== 200) {
          throw new Error();
        }

        const { subscription } = data;

        const dataSerialized = {
          ...data,
          subscription: {
            ...subscription,
            createdAtFormatted: new Date(subscription.createdAt).toLocaleDateString('pt-br'),
            endContractAtFormatted: subscription.endAt ? new Date(subscription.endAt).toLocaleDateString('pt-br') : '',
            cancellationAt: subscription.cancellationAt ? new Date(subscription.cancellationAt).toLocaleDateString('pt-br') : '',
            priceFormatted: currencyUtil.format({
              value: subscription.price,
              options: {
                minimumFractionDigits: 0,
              }
            }),
          },
        }

        setAvailableToCancellation(subscription.cancellationAt ? false : true)
        setEndContractAt(dataSerialized.subscription.endContractAtFormatted)
        setPlanData(dataSerialized);
      } catch (err) {
        setHasError(true);
      } finally {
        setLoading(false);
      }
    }

    loadPlan();
  }, []);

  // Se isPrivate (subscriptionPLan) for true, é pq é customizado

  // se o plano é recorrente ele só pode fazer upgrade pra plano recorrente, mandar o isRecurrence

  useEffect(() => {
    // NÃO IRÁ EXISTIR ESSA FUNCIONALIDADE DE REALIZAR UPGRADE PLANO
    async function loadPlansForUpgrade() {
      try {
        const user = userService.retrieve();

        const { status, data } = await planService.getPlansForUpgrade({ token: user.token });

        if (status !== 200) {
          throw new Error();
        }

        const dataSerialized = data.map(item => {
          return {
            ...item,
            priceFormatted: currencyUtil.format({
              value: item.price,
              options: {
                minimumFractionDigits: 0,
              }
            })
          };
        });

        setPlansForUpgradeData(dataSerialized);
      } catch {
        setHasErrorPlansForUpgrade(true);
      } finally {
        setLoadingPlansForUpgrade(false);
      }
    }

    // loadPlansForUpgrade();
  }, []);

  async function handleUpgradePlan(paymentInfo, creditCardInfo, isCompany) {
    try {
      setLoadingProcessingPayment(true);

      // Se o plano for recoorrete mada recorrente senao for nao manda

      const { status } = await planService.upgradePlanRealtor({
        planId,
        paymentInfo,
        creditCardInfo,
        isCompany,
        isRecurrence: planData.consumption.isRecurrence,
        token: userStored.token,
      });

      if (status !== 204) {
        throw 'Ocorreu um erro ao processar o pagamento, tente novamente!';
      }

      let userProfile = await userService.getUserProfile({
        userId: userStored._id,
        token: userStored.token
      });

      localStorage.setItem("user", JSON.stringify(userProfile));

      setProcessingPaymentSuccessFul(true);

      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (err) {
      toast.error(err, {
        autoClose: false,
      });
    } finally {
      setLoadingProcessingPayment(false);
    }
  }

  async function handleRenewPlan(paymentInfo, creditCardInfo, isCompany) {
    try {
      setLoadingProcessingRenewal(true);

      const { status } = await planService.renewPlan({
        paymentInfo,
        creditCardInfo,
        isCompany,
        token: userStored.token
      });

      if (status !== 204) {
        throw 'Ocorreu um erro ao processar o pagamento, tente novamente!';
      }

      setProcessingPaymentRenewalSuccessFul(true);

      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (err) {
      toast.error(err);
    } finally {
      setLoadingProcessingRenewal(false);
    }
  }

  function handleGetInTouch(subject) {
    const path = `${pathsConstants.PURCHASE_FLOW_INCORPORATION}?type=${userStored.userType}&subject=${subject}`;

    window.open(path, '_blank');
  }

  async function handleCancelPlan() {
    try {
      setLoadingCancelPlan(true);

      const response = await planV2Service.cancelPlan({
        subscriptionId: planData.subscription.id,
        token: userStored.token
      });

      if (response.status !== 204) {
        throw 'Ocorreu um erro ao cancelar seu plano, tente novamente mais tarde!';
      }

      setPlanCancelledSuccessFul(true);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (err) {
      setCancelPlanModalVisible(false);
      toast.error(err);
    } finally {
      setLoadingCancelPlan(false)
    }
  }

  return (
    <Container>
      {loading &&
        <LoadingContainer>
          <h3>Carregando...</h3>
          <p>Aguarde enquanto buscamos os dados do seu plano...</p>
        </LoadingContainer>
      }

      {hasError &&
        <ErrorContainer>
          <h3>Ops...</h3>
          <p>Ocorreu um erro ao carregar os dados do seu plano, recarregue a página ou tente novamente mais tarde!</p>
        </ErrorContainer>
      }

      {!hasError && planData && (
        <>
          <AboutAndUpgradePlanContent>
            <AboutPlanContent>
              <AboutPlanContentBoxTop>
                <strong>{planData.subscription.plan.name}</strong>

                <p>{planData.subscription.plan.description}</p>

                <PlanUseContent>
                  <AdsInUseContent>
                    <Label>Anúncios em uso</Label>
                    <h3>{planData.subscription.adsInUse}</h3>
                  </AdsInUseContent>

                  <PlanDivider />

                  <AdsTotalContent>
                    <Label>Total de anúncios</Label>
                    {planData.subscription.plan.product.hasUnlimitedAds && <h3>ILIMITADO</h3>}
                    {!planData.subscription.plan.product.hasUnlimitedAds && <h3>{planData.subscription.plan.product.numberOfAds}</h3>}
                  </AdsTotalContent>
                </PlanUseContent>
              </AboutPlanContentBoxTop>

              <Separator />

              <AboutPlanContentBoxBottom>
                <PriceAndContractContent>
                  <PriceContent>
                    <Label>Preço</Label>
                    <h3>
                      {planData.subscription.priceFormatted}

                      <sub>/mês</sub>
                    </h3>
                  </PriceContent>

                  <ContractContent>
                    <ContractInformContent>
                      <Label>Contratado em</Label>
                      <h3>{planData.subscription.createdAtFormatted}</h3>
                    </ContractInformContent>

                    {endContractAt &&
                      <ContractInformContent>
                        <Label>Encerra em</Label>
                        <h3>{endContractAt}</h3>
                      </ContractInformContent>
                    }
                  </ContractContent>
                </PriceAndContractContent>
              </AboutPlanContentBoxBottom>

              {isAvailableForRenewal &&
                <RenewPlanButton
                  type="button"
                  onClick={() => setRenewPlanModalVisible(true)}
                >
                  Renovar plano
                </RenewPlanButton>
              }
            </AboutPlanContent>

            {endContractAt &&
              <ContactContainer>
                <h3>{`Seu acesso expira em ${endContractAt}`}</h3>
                <p>Você solicitou o cancelamento do seu plano, para reativar ou contratar um novo plano, entre em contato com nossa equipe.</p>
                <UpgradePlanCardButton
                  type="button"
                  onClick={() => handleGetInTouch("upgrade plan")}
                >
                  Entrar em contato
                </UpgradePlanCardButton>
              </ContactContainer>
            }

            {/* INÍCIO - ESTE CÓDIGO COMENTADO REFERE-SE A LISTAGEM DOS PLANOS POSSÍVEIS A UPGRADE/DOWNGRADE COM SUAS RESPECTIVAS AÇÕES */}
             {/* {(!hasErrorPlansForUpgrade && plansForUpgradeData && availableToCancellation) && */}
              {/* <UpgradePlanExpose> */}
                {/* {plansForUpgradeData.map(item => ( */}
                  {/* <UpgradePlanCard key={item._id}> */}
                    {/* <UpgradePlanCardHeader isAvailable={item.availableForUpgrade}>
                      <UpgradePlanCardName>{item.name}</UpgradePlanCardName>

                      {item.price !== 0 &&
                        <UpgradePlanCardPrice>
                          {item.priceFormatted}
                          {planData.consumption.isRecurrence && <sub>/mês</sub>}
                        </UpgradePlanCardPrice>}
                    </UpgradePlanCardHeader> */}

                    {/* <UpgradePlanCardDetailsList isAvailable={item.availableForUpgrade}>
                      <li>Anúncios<br />{item.price !== 0 ? item.numberOfAds : 'a combinar'}</li>
                      <li>Dias contratados<br />{item.price !== 0 ? item.contractDuration : 'a combinar'}</li>
                    </UpgradePlanCardDetailsList> */}

                    {/* {item.currentPlan &&
                      <UpgradePlanCardButton type="button" disabled>
                        Plano atual
                      </UpgradePlanCardButton>
                    } */}

                    {/* {item.availableForUpgrade && item.price !== 0 &&
                      <UpgradePlanCardButton
                        type="button"
                        onClick={() => {
                          setPlanId(item._id);
                          setChangePlanModalVisible(true);
                        }}
                      >
                        Trocar plano
                      </UpgradePlanCardButton>
                    } */}

                    {/* Esta validação remete ao upgrade do plano */}
                    {/* {item.availableForUpgrade && item.price === 0 &&
                      <UpgradePlanCardButton
                        type="button"
                        onClick={() => handleGetInTouch("upgrade plan")}
                      >
                        Entrar em contato
                      </UpgradePlanCardButton>
                    } */}

                    {/* Esta validação remete ao downgrade do plano */}
                    {/* {!item.availableForUpgrade && !item.currentPlan &&
                      <UpgradePlanCardButton
                        type="button"
                        onClick={() => handleGetInTouch("downgrade plan")}
                      >
                        Entrar em contato
                      </UpgradePlanCardButton>
                    } */}
                  {/* </UpgradePlanCard> */}
                {/* ))} */}
              {/* </UpgradePlanExpose> */}
            {/* } */}
            {/* FINAL - ESTE CÓDIGO COMENTADO REFERE-SE A LISTAGEM DOS PLANOS POSSÍVEIS A UPGRADE/DOWNGRADE COM SUAS RESPECTIVAS AÇÕES */}

            {!endContractAt &&
              <UpgradePlanContent>
                <ProductToContact
                  data={[planData.productToContact]}
                  onPlanClick={() => handleGetInTouch("upgrade plan")}
                />
              </UpgradePlanContent>
            }

          </AboutAndUpgradePlanContent>
          {availableToCancellation && (
            <CancelPlanButton
              type="button"
              onClick={() => setCancelPlanModalVisible(true)}
            >
              Quero cancelar meu plano
            </CancelPlanButton>
          )}
        </>
      )}

      <ChangePlanModal
        isOpen={changePlanModalVisible}
        style={{
          content: {
            backgroundColor: 'transparent',
            border: 0,
            padding: 0,
            inset: 0,
            borderRadius: 0,
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)'
          }
        }}
      >
        <ChangePlanModalContent>
          <ChangePlanModalBody
            loadingProcessingPayment={loadingProcessingPayment}
            processingPaymentSuccessFul={processingPaymentSuccessFul}
          >
            <>
              {!loadingProcessingPayment &&
                !processingPaymentSuccessFul &&
                <ChangePlanModalCloseButton
                  type="button"
                  onClick={() => setChangePlanModalVisible(false)}
                >
                  <RiCloseLine size={24} />
                </ChangePlanModalCloseButton>
              }

              {!loadingProcessingPayment &&
                !processingPaymentSuccessFul &&
                <BillingInfo
                  user={userStored}
                  isChangePlan
                  onChangePlanTriggered={(paymentInfo, creditCardInfo, isCompany) => handleUpgradePlan(paymentInfo, creditCardInfo, isCompany)}
                />
              }

              {loadingProcessingPayment &&
                <LoadingProcessingPayment>
                  <h3>Um momento, o pagamento está sendo processado</h3>
                  <CircularProgress size={32} />
                </LoadingProcessingPayment>
              }

              {processingPaymentSuccessFul &&
                <ProcessingPaymentSuccessFul>
                  <RiShieldCheckFill size={36} />

                  <h3>Sua troca de plano foi realizada com sucesso!</h3>

                  <span>Aguarde, página será recarregada em instantes</span>
                </ProcessingPaymentSuccessFul>
              }
            </>
          </ChangePlanModalBody>
        </ChangePlanModalContent>
      </ChangePlanModal>

      <RenewPlanModal
        isOpen={renewPlanModalVisible}
        style={{
          content: {
            backgroundColor: 'transparent',
            border: 0,
            padding: 0,
            inset: 0,
            borderRadius: 0,
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)'
          }
        }}
      >
        <ChangePlanModalContent>
          <RenewPlanModalBody
            loadingProcessingRenewal={loadingProcessingRenewal}
            processingPaymentRenewalSuccessFul={processingPaymentRenewalSuccessFul}
          >
            <>
              {!loadingProcessingRenewal &&
                !processingPaymentRenewalSuccessFul &&
                <ChangePlanModalCloseButton
                  type="button"
                  onClick={() => setRenewPlanModalVisible(false)}
                >
                  <RiCloseLine size={24} />
                </ChangePlanModalCloseButton>
              }

              {!loadingProcessingRenewal &&
                !processingPaymentRenewalSuccessFul &&
                <BillingInfo
                  user={userStored}
                  isChangePlan
                  onChangePlanTriggered={(paymentInfo, creditCardInfo, isCompany) => handleRenewPlan(paymentInfo, creditCardInfo, isCompany)}
                />
              }

              {loadingProcessingRenewal &&
                <LoadingProcessingPayment>
                  <h3>Um momento, o pagamento está sendo processado</h3>
                  <CircularProgress size={32} />
                </LoadingProcessingPayment>
              }

              {processingPaymentRenewalSuccessFul &&
                <ProcessingPaymentSuccessFul>
                  <RiShieldCheckFill size={36} />

                  <h3>Seu plano foi renovado com sucesso!</h3>

                  <span>Aguarde, a página será recarregada em instantes</span>
                </ProcessingPaymentSuccessFul>
              }
            </>
          </RenewPlanModalBody>
        </ChangePlanModalContent>
      </RenewPlanModal>

      <CancelPlanModal
        isOpen={cancelPlanModalVisible}
        style={{
          content: {
            backgroundColor: 'transparent',
            border: 0,
            padding: 0,
            inset: 0,
            borderRadius: 0,
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)'
          }
        }}
      >
        <ChangePlanModalContent>
          <CancelPlanModalBody>
            {!loadingCancelPlan &&
              !planCancelledSuccessFul && (
                <>
                  <ChangePlanModalCloseButton
                    type="button"
                    onClick={() => setCancelPlanModalVisible(false)}
                  >
                    <RiCloseLine size={24} />
                  </ChangePlanModalCloseButton>

                  <h2>Você realmente deseja cancelar o seu plano?</h2>

                  <span>Lembre-se:</span>
                  <p>
                    Ao prosseguir com o cancelamento do seu plano, todos os seus imóveis serão despublicados.
                  </p>

                  <CancelPlanModalButtons>
                    <button
                      type="button"
                      onClick={() => setCancelPlanModalVisible(false)}
                    >
                      Manter plano
                    </button>

                    <button
                      type="button"
                      onClick={handleCancelPlan}
                    >
                      Cancelar mesmo assim
                    </button>
                  </CancelPlanModalButtons>
                </>
              )}

            {loadingCancelPlan &&
              <LoadingProcessingPayment>
                <h3>Aguarde enquanto cancelamos o seu plano</h3>
                <CircularProgress size={32} />
              </LoadingProcessingPayment>
            }

            {planCancelledSuccessFul &&
              <ProcessingPaymentSuccessFul>
                <RiShieldCheckFill size={36} />

                <h3>Seu plano foi cancelado com sucesso!</h3>

                <span>Aguarde, a página será recarregada em instantes</span>
              </ProcessingPaymentSuccessFul>
            }
          </CancelPlanModalBody>
        </ChangePlanModalContent>
      </CancelPlanModal>
    </Container>
  );
}
