import React from 'react';

import { Bold } from '../../../../../styled/labels/labels';

import { Wrapper, TSectionTitle } from './styles';

export function Title() {
  return (
    <Wrapper>
      <TSectionTitle as="h2">
        Confira os <Bold>destaques.</Bold>
      </TSectionTitle>
    </Wrapper>
  );
}
