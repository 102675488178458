import React from 'react';
import { Controller } from 'react-hook-form'
import IntlCurrencyInput from 'react-intl-currency-input'

import { ErrorMessage } from '../error-message';

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      },
    },
  },
};

export function PriceInput({ control, name, label, error = null }) {
  return (
    <>
      <div className="form-floating">
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value } }) => (
            <IntlCurrencyInput
              className="form-control"
              currency="BRL"
              config={currencyConfig}
              value={value}
              placeholder="Prices"
              onChange={onChange}
            />
          )}
        />

        <label htmlFor={name} className="form-label">{label}</label>
      </div>

      {error && <ErrorMessage message={error.message} />}
    </>
  );
}
