
import React from 'react';
import { withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';
import propTypes from 'prop-types';

import { COLOR_1, COLOR_2 } from '../../../styled/variables/variables';
import { backgrounder, mqTablet, mqDesktop, pointer } from '../../../styled/helpers/helpers';
import { ProfilePicture } from '../../../styled/components/components';
import { BaseLabel } from '../../../styled/labels/labels';

// import RatingStars from '../../../components/rating-stars';

import pathConstants from '../../../constants/paths';

/*
  global
  process
*/

const { PUBLIC_URL } = process.env;

const Cover = ({
  profile,
  pictureUrl,
  rating,
  reviews,
  history,
  buttonVisible,
}) => (
  <Wrapper>
    <ContentWrapper>
      <NameWrapper>
        <Name>{profile ? profile.screenName : ''}</Name>
        {/*<CRatingStars rating={ rating } />*/}
      </NameWrapper>
      <Reviews>
        {/* reviews + 'avaliações'*/}
      </Reviews>
      <CProfilePicture
        pictureUrl={pictureUrl}
        buttonVisible={buttonVisible}
        onClick={() => {
          if (buttonVisible) {
            history.push(pathConstants.CONFIG_ACCOUNT_PROFILE);
          }
        }}
      >
        {buttonVisible &&
          <EditProfileWrapper>
            <EditProfileText>Editar Perfil</EditProfileText>
          </EditProfileWrapper>
        }
      </CProfilePicture>
    </ContentWrapper>
  </Wrapper>
);

Cover.propTypes = {
  id: propTypes.string.isRequired,
  name: propTypes.string,
  pictureUrl: propTypes.string,
  rating: propTypes.number,
  reviews: propTypes.number,
  history: propTypes.object,
};

const Wrapper = styled.div`
  background-color: ${COLOR_1};
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  padding-top: 120px;
  margin-bottom: 120px;

  * { position: relative; }
  &:before {
    content: "";
    ${backgrounder(`${PUBLIC_URL}/imgs/koort-texture-outline.svg`)};
    background-size: 800px;
    height: 100%;
    width: 100%;
    opacity: .3;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
  }

  ${mqTablet`
    margin-bottom: 90px;

    &:before {
      background-size: 600px;
      background-repeat: repeat;
    }
  ` }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${mqTablet`
    flex-direction: row;
    width: 100%;
    align-self: center;
    margin-top: 40px;
    padding: 0 10px;
    ` }

  ${mqDesktop`
    max-width: 1000px;
  ` }
`;

const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-bottom: 10px;

  ${mqTablet`
    flex-grow: 1;
    width: 1px;
    order: 2;
    align-self: flex-start;
    margin-top: 10px;
  ` }
`;

const Name = styled(BaseLabel)`
  color: ${COLOR_2};
  align-self: center;
  font-weight: 700;
  margin-bottom: 10px;

  ${mqTablet`
    align-self: flex-start;
    font-size: 24px;
  ` }
`;

const Reviews = styled(Name)`
  font-size: 12px;
  margin-bottom: 20px;

  ${mqTablet`
    order: 3;
    font-size: 12px;
    margin-top: 50px;
  ` }
`;

const CProfilePicture = styled(ProfilePicture)`
  align-self: center;
  min-height: 140px;
  min-width: 120px;
  margin-bottom: 20px;
  background-color: #f0f0f0;

  ${({ buttonVisible }) => buttonVisible && css`
    ${pointer}
  `}

  ${mqTablet`
    order: 1;
    margin-right: 20px;
  ` }
`;

const EditProfileWrapper = styled.div`
  position:absolute;
  bottom:0;

  align-self:center;
  justify-content:center;
  width: 100%;
  background-color: rgba(0,0,0,0.5);
`;

const EditProfileText = styled.h6`
  margin-bottom:3px;
  color:white;
  text-align:center;
`;

export default withRouter(Cover);
