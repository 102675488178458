import React, { useState, useEffect } from 'react';
import { RiClipboardLine } from 'react-icons/ri';
import { toast } from 'react-toastify';

import { IntegrationActionButtons } from '../../../integration-action-buttons';

import pathsConstants from '../../../../../../../constants/paths';
import stringUtil from '../../../../../../../utils/string/string.util';

import {
  CasaMineiraWrapper,
  UrlXmlWrapper,
  UrlXmlLink,
  ClipboardUrlXml,
  CompanyCodeWrapper
} from './styles';

export function CasaMineira({
  data,
  integratedPortal,
  portalToBeRemoved,
  onRemoveIntegration,
  setPortalToBeRemoved,
}) {
  const [companyCode, setCompanyCode] = useState();

  const [clipboardUrlXmlButtonDisabled, setClipboardUrlXmlButtonDisabled] = useState(false);

  useEffect(() => {
    if (!!integratedPortal && !!integratedPortal.companyCode) {
      setCompanyCode(integratedPortal.companyCode);
    }
  }, [integratedPortal]);

  function handleCopyUrlXmlToClipboard(urlXml) {
    setClipboardUrlXmlButtonDisabled(true);

    stringUtil.copyToClipboard({
      str: urlXml,
    });

    toast.info('Url copiada para a área de transferência!', {
      autoClose: 2000,
    });

    setTimeout(() => {
      setClipboardUrlXmlButtonDisabled(false);
    }, 2000);
  }

  function handleRemoveIntegration() {
    if (portalToBeRemoved === data.value) {
      onRemoveIntegration();
    } else {
      setPortalToBeRemoved(data.value);
    }
  }

  function handleManageIntegrationIntoDashboard() {
    if (!companyCode) {
      toast.error('Informe seu código de imobiliária!', {
        autoClose: 3000,
      });

      return;
    }

    window.open(`${pathsConstants.DASHBOARD_ADS}?integrationPortal=${data.value}&companyCode=${companyCode}`, '_self')
  }

  return (
    <CasaMineiraWrapper>
      <h3>{data.label}</h3>

      <CompanyCodeWrapper>
        <label htmlFor="company-code">
          Para prosseguir, informe seu código de imobiliária disponibilizada pela Casa Mineira:
        </label>

        <input
          id="company-code"
          placeholder="Informe o código aqui..."
          value={companyCode}
          onChange={event => setCompanyCode(event.target.value)}
        />
      </CompanyCodeWrapper>

      <div>
        {integratedPortal !== null ?
          <>
            <UrlXmlWrapper>
              <UrlXmlLink>
                <a
                  href={integratedPortal.url}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {integratedPortal.url}
                </a>
              </UrlXmlLink>

              <ClipboardUrlXml
                type="button"
                title="Copiar URL"
                disabled={clipboardUrlXmlButtonDisabled}
                onClick={() => handleCopyUrlXmlToClipboard(integratedPortal.url)}
              >
                <RiClipboardLine size={24} />
              </ClipboardUrlXml>
            </UrlXmlWrapper>
          </>
          :
          <span>Aqui estará o seu arquivo XML</span>
        }

        <IntegrationActionButtons
          hasPortalToBeRemoved={portalToBeRemoved === data.value}
          onRemoveIntegration={handleRemoveIntegration}
          onManageIntegrationIntoDashboard={handleManageIntegrationIntoDashboard}
        />
      </div>
    </CasaMineiraWrapper>
  );
}
