import React from 'react';
import propTypes from 'prop-types';

import { Wrapper, Label } from './styles';

const InputWrapper = ({
  className,
  label,
  children,
  htmlFor,
}) => (
  <Wrapper className={className}>
    <Label htmlFor={htmlFor}>{label}</Label>
    {children}
  </Wrapper>
);

InputWrapper.propTypes = {
  label: propTypes.string,
  className: propTypes.string,
  children: propTypes.element,
  htmlFor: propTypes.string,
};

InputWrapper.defaultProps = {

};



export default InputWrapper;
