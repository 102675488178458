import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import styled from 'styled-components';

import { COLOR_1, COLOR_2, COLOR_6 } from '../../../../styled/variables/variables';
import { KoortMarkerOrangeOutline } from '../../../../styled/components/components';
import { BaseLabel } from '../../../../styled/labels/labels';
import { BaseButton } from '../../../../styled/buttons/buttons';
import { mqTablet, bigSectionBaseStyle } from '../../../../styled/helpers/helpers';

import { KOORTIMATIVA } from './../../../../constants/paths';

/* global process */

const Content = ({
  id,
}) => (
  <Wrapper id={id}>
    <InnerWrapper>
      <TitleWrapper>
        <AKoortMarkerWhiteOutline />
        <KoortimativaText />
      </TitleWrapper>
      <Description>
        Descubra nossas ferramentas de inteligência de mercado e conheça mais sobre o valor do seu imóvel.
      </Description>
      <SeeMoreButton to={KOORTIMATIVA}>Saiba mais</SeeMoreButton>
    </InnerWrapper>
  </Wrapper>
);

const Wrapper = styled.div`
  ${bigSectionBaseStyle}
  background-color: ${COLOR_1};
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${mqTablet`
    flex-direction: row;
  ` }
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${mqTablet`
    width: 700px;
    padding-top: 100px;
  ` }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${mqTablet`
    align-self: flex-start;
    flex-direction: row;
  ` }
`;

const AKoortMarkerWhiteOutline = styled(KoortMarkerOrangeOutline)`
  height: 40px;
  align-self: center;
  margin-bottom: 10px;

  ${mqTablet`
    margin-right: 5px;
    height: 32px;
  ` }
`;

const KoortimativaText = styled.img.attrs({
  src: `${process.env.PUBLIC_URL}/imgs/koortimativa-text-orange-white.png`,
})`
  width: 160px;
  align-self: center;
  margin-bottom: 20px;

  ${mqTablet`
    width: 240px;
  ` }
`;

const Description = styled(BaseLabel)`
  color: ${COLOR_2};
  font-size: 18px;
  text-align: center;
  margin-bottom: 50px;

  ${mqTablet`
    text-align: justify;
    align-self: flex-start;
    text-align: left;
    width: 300px;
  ` }
`;

const SeeMoreButton = styled(BaseButton).attrs({ as: Link })`
  background-color: ${COLOR_6};
  color: ${COLOR_2};
  align-self: center;
  border: none;
  font-weight: 700;

  ${mqTablet`
    align-self: flex-start;
  ` }
`;

export default withRouter(Content);
