
import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import { mqTablet, backgrounder, pointer } from '../../../../../styled/helpers/helpers';

const Slider = ({
  url,
}) => (
  <Wrapper 
    url={ url }
  />
);

Slider.propTypes = {
  url: propTypes.string,
};

Slider.defaultProps = {
  
};

const Wrapper = styled.div`
  ${ ({ url }) => backgrounder(url) }
  height: 190px;
  width: 290px;
  border-radius: 1px;;
  border: solid 1px lightgray;
  align-self: center;
  margin-top: -25px;
  ${ pointer }

  ${ mqTablet`
    height: 250px;
    width: 400px;
    margin-top: 0px;
    margin-left: -30px;
  ` }
`;

export default Slider;
