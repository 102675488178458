
import React from 'react';
import styled from 'styled-components';
import { SectionTitle, Bold } from '../../../../styled/labels/labels';
import { COLOR_3 } from '../../../../styled/variables/variables';
import { mqTablet } from '../../../../styled/helpers/helpers';

const Title = () => (
  <Wrapper>
    <TSectionTitle as="h2">
      Confira os <Bold>novos anúncios.</Bold>
    </TSectionTitle>
  </Wrapper>
);

const Wrapper = styled.div`
  align-self: center;
  text-align: center;
  margin-bottom: 30px;

  ${ mqTablet`
    margin-bottom: 40px;
  ` }
`;

const TSectionTitle = styled(SectionTitle)`
  color: ${ COLOR_3 };
`;

export default Title;
