import { UAParser } from 'ua-parser-js';

import httpClient from "../../clients/http/http.client";

const registerConsentCookies = async ({ cookieInfo, method }) => {
  const parser = new UAParser();

  let ip;

  await fetch('https://api.ipify.org/?format=json')
  .then(response => response.json())
  .then(data => {
    ip = data.ip
  })
  .catch(error => console.error(error));

  const path = '/consents/from/cookies';

  const params = {
    ip,
    device: {
      model: parser.getDevice().model,
      type: parser.getDevice().type,
      vendor: parser.getDevice().vendor,
      browser: {
        name: parser.getBrowser().name,
        version: parser.getBrowser().version,
        language: window.navigator.language || window.navigator.userLanguage || undefined,
      },
      os: {
        name: parser.getOS().name,
        version: parser.getOS().version,
      }
    },
    cookie: {
      type: cookieInfo.type,
      description: cookieInfo.description,
      purpose: cookieInfo.purpose,
    },
    method: method,
  };

  const response = await httpClient.post({ path, params });

  return response;
}

export default {
  registerConsentCookies,
}
