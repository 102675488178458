import React, { useState, Component } from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';

import imageUtil from '../../utils/image/image.util';

export default class SliderFullScreenComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      images: [],
      modalIsOpen: true,
      currentSlideIndex: 0,
    }
  }

  componentDidMount() {
    this.parseToArray();
    this.calculateIndex();
  }

  toggleModal = () => {
    this.setState({ modalIsOpen: !this.state.modalIsOpen })
    this.props.handle(!this.props.show)
  }

  calculateIndex = () => {
    const currentURL = this.props.currentURL;
    const imgs = this.props.images;

    for (let i = 0; i < imgs.length; i++){
      if(imgs[i] === currentURL)
        this.setState({currentSlideIndex: i})
    }

  }

  parseToArray = () => {
    const imgs = this.props.images;
    const newArray = []

    for (let i = 0; i < imgs.length; i++) {
      newArray.push({
        src: imageUtil.transformImageLink(imgs[i], 'large')
      })
    }

    this.setState({ images: newArray })
  }

  render() {
    const { modalIsOpen } = this.state;
    const images = this.state.images;
    const current = this.state.currentSlideIndex;

    return (
      <ModalGateway>
        {modalIsOpen ? (
          <Modal onClose={this.toggleModal}>
            <Carousel
              views={images}
              currentIndex={current}
              />
          </Modal>
        ) : null}
      </ModalGateway>
    );
  }
}
