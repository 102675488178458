import React from 'react';

import {
  Wrapper,
  IconWrapper,
  BedIcon,
  Label,
  M2Icon,
  CarIcon,
  ShowerIcon,
  PetIcon,
  Spacer,
} from './styles';

export function Features({
  bedrooms,
  area,
  parkingSpaces,
  bathrooms,
  petAcceptance,
}) {
  return (
    <Wrapper>
    <IconWrapper>
      <BedIcon />
      <Label>
        {bedrooms.length === 1 &&
          `${bedrooms} quarto${bedrooms > 1 ? 's' : ''}`
        }

        {bedrooms.length === 2 &&
          `${bedrooms[0]} - ${bedrooms[1]} quartos`
        }
      </Label>
    </IconWrapper>
    <IconWrapper>
      <M2Icon />
      <Label>
        {area.length === 1 &&
          `${area} m²`
        }

        {area.length === 2 &&
          `${area[0]} - ${area[1]} m²`
        }
      </Label>
    </IconWrapper>
    <IconWrapper>
      <CarIcon />
      <Label>
        {parkingSpaces.length === 0 && 'Sem vagas'}

        {parkingSpaces.length === 1 &&
          `${parkingSpaces} vaga${parkingSpaces > 1 ? 's' : ''}`
        }

        {parkingSpaces.length === 2 &&
          `${parkingSpaces[0]} - ${parkingSpaces[1]} vagas`
        }
      </Label>
    </IconWrapper>
    <IconWrapper>
      <ShowerIcon />
      <Label>
        {bathrooms.length === 1 &&
          `${bathrooms} banheiro${bathrooms > 1 ? 's' : ''}`
        }

        {bathrooms.length === 2 &&
          `${bathrooms[0]} - ${bathrooms[1]} banheiros`
        }
      </Label>
    </IconWrapper>
    {/* <IconWrapper>
      <PetIcon />
      <Label>
        {petAcceptance ? 'Permitido' : 'Não'}
      </Label>
    </IconWrapper> */}
    <Spacer />
  </Wrapper>
  );
};
