import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { BaseButton } from '../../styled/buttons/buttons';
import { BaseLabel } from '../../styled/labels/labels';
import { COLOR_4 } from '../../styled/variables/variables';
import { mqTablet } from '../../styled/helpers/helpers';

import Address from './address/Address';
import CondoFeatures from './condo-features/CondoFeatures';
import Description from './description/Description';
import Development from './development/Development';
import Features from './features/Features';
import Koortimativa from './koortimativa/Koortimativa';
import MainInfo from './main-info/MainInfo';
import Others from './others/Others';
import Price from './price/Price';
import Subtitle from './subtitle/Subtitle';
import ReportTitle from './report-title/ReportTitle';
import Title from './title/Title';
import Use from './use/Use';
import SurroundingFeatures from './surrounding-features/SurroundingFeatures';

import queryStringUtil from '../../utils/query-string/query-string.util';
import pathConstants from '../../constants/paths';

import reportService from '../../services/report/report.service';
import userService from '../../services/user/user.service';

/*
  global
  alert
*/

let reportTitleRef;
let titleRef;
let subtitleRef;
let descriptionRef;
let mainInfoRef;
let developmentRef;
let featuresRef;
let condoFeaturesRef;
let surroundingFeaturesRef;
let addressRef;
let priceRef;
let koortimativaRef;
let useRef;
let othersRef;

const customId = "custom-id-yes";
const ReportBuilder = ({ history }) => {

  const [state, setState] = useState({
    report: null,
  });

  useEffect(() => {
    const { reportId } = queryStringUtil.parse(history);
    if (reportId) getReport();
  }, []);

  const getReport = async () => {

    const user = userService.retrieve();
    const { reportId } = queryStringUtil.parse(history);
    let result;
    try {
      result = await reportService.getById({
        token: user.token,
        reportId,
      });
    } catch (error) {
      toast.error('Erro buscando anúncio.'), {
        autoClose: false,
        toastId: customId,
      };
      return;
    }

    setState(prev => ({
      ...prev,
      report: result,
    }));
  };

  const handleFormSubmit = async event => {
    event.preventDefault();

    const data = {
      ...reportTitleRef.state.form,
      ...titleRef.state.form,
      ...subtitleRef.state.form,
      ...descriptionRef.state.form,
      ...mainInfoRef.state.form,
      ...developmentRef.state.form,
      ...addressRef.state.form,
      ...priceRef.state.form,
      ...useRef.state.form,
      ...othersRef.state.form,
      koortimativa: koortimativaRef.state.form,
      features: {
        property: featuresRef.state.form.features,
        condo: condoFeaturesRef.state.form.features,
        near: surroundingFeaturesRef.state.form.features,
      },
    };

    const user = userService.retrieve();
    const { reportId, realEstateId } = queryStringUtil.parse(history);

    let result;
    try {
      if (reportId) {
        result = await reportService.update({
          token: user.token,
          reportId,
          ...data,
        });
      } else {
        result = await reportService.create({
          token: user.token,
          realEstateId,
          ...data,
        });
      }
    } catch (error) {
      console.error(error);
      toast.error('Erro criando relatório.', {
        autoClose: false,
        toastId: customId,
      });
      return;
    }

    if (reportId) {
      toast.error('Relatório atualizado.', {
        autoClose: false,
        toastId: customId,
      });
    } else {
      history.push({
        pathname: pathConstants.REPORT,
        search: queryStringUtil.stringify(null, {
          reportId: result.id,
        }),
      });
    }
  };

  const { reportId } = queryStringUtil.parse(history);
  if (reportId && !state.report) return <Wrapper />;

  return (
    <Wrapper>
      <PageTitle>
        Criar Relatório
      </PageTitle>
      <Form onSubmit={handleFormSubmit}>
        <InputLabel>
          Nome do relatório
        </InputLabel>
        <ReportTitle
          {...(state.report || {})}
          ref={ref => {
            reportTitleRef = ref;
          }}
        />

        <InputLabel>
          Título do anúncio
        </InputLabel>
        <Title
          {...(state.report || {})}
          ref={ref => {
            titleRef = ref;
          }}
        />

        <InputLabel>
          Subtítulo
        </InputLabel>
        <Subtitle
          {...(state.report || {})}
          ref={ref => {
            subtitleRef = ref;
          }}
        />

        <InputLabel>
          Descrição
        </InputLabel>
        <Description
          {...(state.report || {})}
          ref={ref => {
            descriptionRef = ref;
          }}
        />

        <InputLabel>
          Características principais
        </InputLabel>
        <MainInfo
          ref={ref => {
            mainInfoRef = ref;
          }}
        />

        <InputLabel>
          Desenvolvimento
        </InputLabel>
        <Development
          ref={ref => {
            developmentRef = ref;
          }}
        />

        <InputLabel>
          Características do imóvel
        </InputLabel>
        <Features
          ref={ref => {
            featuresRef = ref;
          }}
        />

        <InputLabel>
          Características do condomínio
        </InputLabel>
        <CondoFeatures
          ref={ref => {
            condoFeaturesRef = ref;
          }}
        />

        <InputLabel>
          Características do entorno
        </InputLabel>
        <SurroundingFeatures
          ref={ref => {
            surroundingFeaturesRef = ref;
          }}
        />

        <InputLabel>
          Endereço
        </InputLabel>
        <Address
          ref={ref => {
            addressRef = ref;
          }}
        />

        <InputLabel>
          Preços
        </InputLabel>
        <Price
          ref={ref => {
            priceRef = ref;
          }}
        />

        <InputLabel>
          Koortimativa
        </InputLabel>
        <Koortimativa
          ref={ref => {
            koortimativaRef = ref;
          }}
        />

        <InputLabel>
          Uso
        </InputLabel>
        <Use
          ref={ref => {
            useRef = ref;
          }}
        />

        <InputLabel>
          Outros
        </InputLabel>
        <Others
          ref={ref => {
            othersRef = ref;
          }}
        />

        <ButtonWrapper>
          <PreviewButton>
            visualizar
          </PreviewButton>
          <SaveButton>
            salvar
          </SaveButton>
        </ButtonWrapper>
      </Form>
    </Wrapper>
  );
};

ReportBuilder.propTypes = {
  history: propTypes.object,
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 100px 0;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
  max-width: 500px;
  align-self: center;
`;

const PageTitle = styled(BaseLabel)`
  font-size: 24px;
  font-weight: bold;
  align-self: center;
  margin-bottom: 50px;
`;

const InputLabel = styled(BaseLabel)`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  ${mqTablet`
    justify-content: flex-start;
  ` }
`;

const SaveButton = styled(BaseButton).attrs({
  type: 'submit',
})`
  flex-grow: 1;
  width: 1px;
  max-width: 170px;
`;

const PreviewButton = styled(SaveButton)`
  background-color: ${COLOR_4};
`;

export default ReportBuilder;
