import React, { PureComponent, Fragment } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { transitions } from 'polished';
import { Auth } from 'aws-amplify';
import { FacebookF } from 'styled-icons/fa-brands/FacebookF'
import { toast } from 'react-toastify';
import { RiArrowLeftLine } from 'react-icons/ri';

import { BaseLabel } from '../../styled/labels/labels';
import { COLOR_1, COLOR_2, COLOR_17, COLOR_20 } from '../../styled/variables/variables';
import { backgrounder, iconBaseStyle, mqDesktop, mqTablet } from '../../styled/helpers/helpers';
import { KoortWhiteVerticalLogo } from '../../styled/components/components';
import { BaseButton } from '../../styled/buttons/buttons';
import { BaseInput } from '../../styled/inputs/inputs';

import Checkbox from '../../components/checkbox';
import InputWrapper from '../../components/input-wrapper';
import { InputBootstrap } from '../../components/form/input-bootstrap';
import { ErrorMessage } from '../../components/form/error-message';


const { PUBLIC_URL } = process.env;

const NewPasswordAuth = () => (
    <Wrapper>
        <Texture />
        <ContentWrapper>
            <Logo />
            <Fragment>
                <PasswordRecoveryInstructions>
                    Informe sua nova senha.
                </PasswordRecoveryInstructions>
                <Form
                >
                    <div className="mb-3">
                        <InputBootstrap
                            name="newPassword"
                            label="Nova senha"
                            placeholder="Nova senha"
                        />
                        {/* <label className={'koort__input_label'} htmlFor={'codigo'}>Informe o código</label> */}
                    </div>
                    <div className="mb-3">
                        <InputBootstrap
                            name="newPassword"
                            label="Confirmar nova senha"
                            placeholder="Confirmar nova senha"
                        />
                        {/* <label className={'koort__input_label'} htmlFor={'codigo'}>Informe o código</label> */}
                    </div>
                    <ConfirmButton>
                        Criar nova senha
                    </ConfirmButton>
                </Form>
            </Fragment>
        </ContentWrapper>
    </Wrapper>
);

const Wrapper = styled.div`
  background-color: ${COLOR_17};
  background-position-y: -50px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  ${mqDesktop`
    flex-direction: row;
  ` }
`;

const Texture = styled.div`
  ${backgrounder(`${PUBLIC_URL}/imgs/koort-texture-white-outline.svg`)};
  background-position: bottom;
  height: 140px;
  width: 100%;
  background-repeat: repeat-x;
  background-size: 620px;
  margin-bottom: 30px;

  ${mqDesktop`
    background-position: right;
    width: 300px;
    height: 100vh;
    background-size: 1000px;
    margin: 0;
  ` }
`;

const Logo = styled(KoortWhiteVerticalLogo)`
  margin-bottom: 50px;
  align-self: center;

  ${mqTablet`
    transform: scale(1.3);
    ${transitions(['max-width'], 'ease .6s .6s')}
  `}

  ${mqDesktop`
    width: 290px;
    transform: scale(1);
  ` }
`;


const ContentWrapper = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  padding: 10px;
  padding-bottom: 100px;
  width: 290px;

  ${mqDesktop`
    align-self: center;
    margin-left: -300px;
    width: 100%;
  ` }
`;


const ActionLabel = styled(BaseLabel)`
  color: ${COLOR_2};
  font-weight: 700;
  margin-bottom: 10px;
  width: 300px;
  align-self: center;

  @media (max-width: 370px) {
    width: 280px;
  }
`;

const PasswordRecoveryResponse = styled(ActionLabel)`
  align-self: center;
  text-align: center;
  font-size: 22px;
  margin-bottom: 20px;
`;

const PasswordRecoveryInstructions = styled(PasswordRecoveryResponse)`
  font-weight: normal;
  margin-bottom: 10px;
  font-size: 16px;
`;

const Form = styled.form`
  width: 300px;
  align-self: center;

  @media (max-width: 370px) {
    width: 280px;
  }
`;

const Input = styled(BaseInput)``;

const SignButton = styled(BaseButton)`
  background-color: ${COLOR_20};
  align-self: center;
  color: ${COLOR_2};
  text-transform: none;
  width: 100%;
  margin: 0;
  margin-bottom: 10px;
  border: none;

  ${mqDesktop`
    width: 300px;
  ` }
`;

const ConfirmButton = styled(SignButton).attrs(() => ({
    type: 'submit',
}))`
  background-color: ${({ disabled }) => disabled ? `${COLOR_16}` : `${COLOR_1}`};
  color: ${({ disabled }) => disabled ? `${COLOR_9}` : `${COLOR_2}`};
  font-weight: 700;
  text-transform: uppercase;
  ${mqTablet`
    margin-bottom: 10px;
  `}
`;

export default NewPasswordAuth;
