import styled from 'styled-components';

export const Container = styled.div`
  > a, button {
      background-color: transparent;
      color: #363636;

      & + button {
        margin-left: 12px;
      }

      &:hover {
        color: #2B50D8;
      }
    }
`;
