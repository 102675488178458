
import uuid from 'uuid';

export const CLIENT_GENERATED_ID = 'CLIENT_GENERATED_ID';

class Base {
  constructor({
    id,
  }) {
    this.id = id || `${ CLIENT_GENERATED_ID }_${ uuid.v4() }`;
    this.instantiatedAt = Date.now();
  }
}

export default Base;
