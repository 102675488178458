
import React, { PureComponent } from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import Marker, { TYPES as MARKER_TYPES } from '../marker/Marker';

/*
  global
  setTimeout
  clearTimeout
  google
*/

class Map extends PureComponent {

  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.highlightedMarkers = [ ];
    this.markers = [ ];
    this.isFunctionComponent = false;
  }

  componentDidMount() {
    const {
      defaultLocation,
      options,
      onLoad,
    } = this.props;

    this.map = new google.maps.Map(this.ref.current, {
      center: {
        lat: defaultLocation.latitude,
        lng: defaultLocation.longitude,
      },
      zoom: 15,
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      disableDoubleClickZoom: true,
      draggable: false,
      clickableIcons: false,
      // styles: GOOGLE_MAPS_STYLE,
      ...options,
    })

    if(onLoad) {
      if (this.props.isFunctionComponent) {
        onLoad();
      } else {
        google.maps.event.addListener(this.map, 'load', onLoad);
      }
    }

    this.randomBlink();
  }

  componentWillUnmount() {
    clearTimeout(this.randomBlinkingTimeout);
  }

  randomBlink = (index = 0) => {
    this.randomBlinkingTimeout = setTimeout(() => {
      const marker = this.highlightedMarkers[index];

      if(marker) {
        marker.startBlinking();
        setTimeout(() => {
          marker.stopBlinking();
        }, 2000);
      }

      index = index >= this.highlightedMarkers.length - 1 ? 0 : index + 1;
      this.randomBlink(index);
    }, 2000);
  };

  setMarkers = ({
    realEstates = [ ],
    surroundingPlaces = [ ],
  }) => {

    this.markers = surroundingPlaces.map(sp => {
      const marker = new Marker({
        ...sp,
        map: this.map,
        data: sp,
        type: MARKER_TYPES.SURROUNDING_PLACE,
      });

      return marker;
    }).concat(realEstates.sort((a, b) => {
      return a.highlight - b.highlight;
    }).map(re => {
      const marker = new Marker({
        ...re,
        map: this.map,
        data: re,
        type: re.highlight ?
          MARKER_TYPES.REAL_ESTATE_HIGHLIGHT :
          MARKER_TYPES.REAL_ESTATE
      });

      const { onMarkerClick } = this.props;
      if(onMarkerClick) {
        google.maps.event.addDomListener(marker.element, 'click', () => {
          onMarkerClick(marker);
        });
      }

      return marker;
    }));

    this.highlightedMarkers = this.markers.filter(m => {
      return m.type === MARKER_TYPES.REAL_ESTATE_HIGHLIGHT;
    });
  };

  render() {
    const { className } = this.props;

    return (
      <Wrapper
        className={ className }
        ref={ this.ref }
      />
    );
  }
}

Map.propTypes = {
  onMarkerClick: propTypes.func,
  className: propTypes.string,
  defaultLocation: propTypes.objectOf(propTypes.shape({
    latitude: propTypes.number,
    longitude: propTypes.number,
  })),
  options: propTypes.object,
  onLoad: propTypes.func,
};

Map.defaultProps = {
  defaultLocation: {
    latitude: -22.9416269,
    longitude: -43.1800372,
  },
  options: { },
};

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`;

export default Map;
