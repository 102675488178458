
import React, { PureComponent } from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { Persist } from 'react-persist-plus';

import { BaseInput } from '../../../styled/inputs/inputs';
import storageUtil from '../../../utils/storage/storage.util';

/* 
  global
  window
*/

class Subtitle extends PureComponent {

  constructor(props) {
    super(props);

    const { subtitle } = props;
    if(subtitle) storageUtil.del(Subtitle.STORAGE_KEY, false);

    this.state = {
      form: {
        subtitle: subtitle ||  '',
      },
      showErrorsFor: { },
    };
  }

  handleFormChange = event => {
    const { name, value } = event.target;

    this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        [name]: value,
      },
    }));
  };

  handleInputBlur = event => {
    const { name} = event.target;

    this.setState(state => ({
      ...state,
      showErrorsFor: {
        ...state.showErrorsFor,
        [name]: true,
      },
    }));
  };

  render() {
    const { form } = this.state;

    return (
      <Wrapper onChange={ this.handleFormChange }>
        <Persist 
          name={ Subtitle.STORAGE_KEY }
          data={ form }
          onMount={ data => this.setState(state => ({
            ...state,
            form: data,
          })) }
          store={ window.sessionStorage }
        />
        <Input 
          value={ form.subtitle } 
          onBlur={ this.handleInputBlur }
        />
      </Wrapper>
    );
  }
}

Subtitle.STORAGE_KEY = 'REPORT_BUILDER_SUBTITLE_FORM';

Subtitle.propTypes = {
  subtitle: propTypes.string,
};

Subtitle.defaultProps = { };

const Wrapper = styled.form`
  margin-bottom: 20px;
`;

const Input = styled(BaseInput).attrs({
  name: 'subtitle',
})``;

export default Subtitle;
