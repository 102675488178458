import styled from 'styled-components';


export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: black;
  border-radius: 1px;
  overflow: hidden;

  & > iframe {
    height: 300px;
  }

  & > div {
    height: 100% !important;
    width: 100% !important;
    margin: 0 auto;
  }
`;