import stringUtil from "../string/string.util";

const isNullOrUndefined = value => {
  return (
    value === null || 
    value === undefined || 
    value === ''
  );
}

const zipCode = ({
  value,
}) => {
  if(isNullOrUndefined(value)) return false;

  value = value.trim();
  value = stringUtil.onlyNumbers(value);
  
  return /[0-9]{5}[\d]{3}/.test(value);
};

const email = ({
  value,
}) => {
  if(isNullOrUndefined(value)) return false;

  value = value.trim();
  return /^([0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9})$/.test(value);
};

const phone = ({
    value
}) => {
    if(isNullOrUndefined(value)) return false

    value = value.trim()
    return /(\+\d{2})\s(\(?\d{2}\)?\s)?(\d{4,5}\-\d{4})/.test(value)
}

const number = ({
  value,
  min = 0,
  max,
}) => {
  if(isNullOrUndefined(value)) return false;

  value = typeof value === 'string' ? parseFloat(value) : value;

  if(typeof min === 'number' && value < min) {
    return false
  } else if(typeof max === 'number' && value > max) {
    return false;
  } else {
    return true;
  }
};

const text = ({
  minLength = 3,
  maxLength = 150,
  value,
}) => {
  if(isNullOrUndefined(value)) return false;

  if(typeof minLength === 'number' && value.length < minLength) {
    return false;
  } else if (typeof maxLength === 'number' && value.length > maxLength) {
    return false;
  } else {
    return true;
  }
};

const url = ({
  value,
}) => {
  return /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/.test(value);
};

const youtubeUrl = ({
  value,
}) => {
  return /^(https?:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/.test(value);
};

const cpf = ({
  value,
}) => {
  if(isNullOrUndefined(value)) return false;
  const cpf = value.replace(/[^\d]+/g, '');
  if (cpf === '') return false;
  if (
    cpf.length !== 11 ||
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  )
    return false;
  let add = 0;
  for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i), 10) * (10 - i);
  let rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(9), 10)) return false;
  add = 0;
  for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i), 10) * (11 - i);
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(10), 10)) return false;
  return true;
}

const cnpj = ({
  value,
}) => {
  if(isNullOrUndefined(value)) return false;
  const cnpj = value.replace(/[^\d]+/g, '');

  if (cnpj.length !== 14) return false;

  if (/^(\d)\1+$/.test(cnpj)) return false;

  const t = cnpj.length - 2;
  const d = cnpj.substring(t);
  const d1 = parseInt(d.charAt(0), 10);
  const d2 = parseInt(d.charAt(1), 10);
  const calc = (x) => {
    const n = cnpj.substring(0, x);
    let y = x - 7;
    let s = 0;
    let r = 0;

    for (let i = x; i >= 1; i--) {
      s += n.charAt(x - i) * y--;
      if (y < 2) y = 9;
    }

    r = 11 - (s % 11);
    return r > 9 ? 0 : r;
  };

  return calc(t) === d1 && calc(t + 1) === d2;
}

export default {
    phone,
    zipCode,
    email,
    text,
    number,
    url,
    youtubeUrl,
    cpf,
    cnpj,
};
