import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  margin-bottom: 54px;

  h3 {
    font-size: 18px;

    > strong {
      display: block;
      font-size: 20px;
      margin-top: 12px;
      margin-bottom: 24px;
    }
  }

  @media(max-width: 1050px) {
    padding: 0 16px;
  }
`;

export const InfoContent = styled.div``;

export const ContactWrapper = styled.div`
  label {
    font-size: 15px;
    margin-bottom: 8px;
    font-weight: 500;
    color: #666666;
  }
`;

export const ContactButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ContactButtonBase = styled.button`
  width: 48%;
  height: 54px;
  background-color: transparent;
  border-radius: 8px;
  border: 1px solid #EEF0F4;
  font-size: 16px;
  font-weight: 500;
  padding: 16px;
  color: #444444;

  display: flex;
  align-items: center;
  justify-content: center;

  gap: 8px;

  transition: background-color .2s;

  &:hover {
    background-color: #EEF0F4;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export const ContactMarkAsDone = styled(ContactButtonBase)`
  ${(props) => props.maskAsDone && css`
    background-color: #EEF0F4;
  `}
`;

export const ContactMarkAsUndone = styled(ContactButtonBase)`
   ${(props) => props.maskAsUndone && css`
    background-color: #EEF0F4;
  `}
`;

export const LeadAnnotationSaveButton = styled.button`
  height: 54px;
  padding: 16px;
  width: 100%;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  margin-top: 18px;
  background-color: #2B50D8;
  color: #FFFFFF;
  margin-bottom: 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: all .2s;

  &:hover {
    filter: brightness(0.9);
  }

  &:disabled {
    cursor: not-allowed;
  }

  ${(props) => props.isSuccess && css`
    background-color: #8ACC2C;
  `}

  ${(props) => props.isError && css`
    background-color: #dc3545;
  `}

  @media (max-width: 680px) {
    margin-bottom: 28px;
  }
`;

export const ResponsiveGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 16px;

  margin-top: 16px;
`;

export const AnnotationGroup = styled.div``;

export const Divisor = styled.div`
  height: 1px;
  width: 100%;
  background-color: #EEF0F4;
  margin: 42px 0;
`;

export const KualifiqueContainer = styled.div`
  h3 {
    font-size: 20px;
    font-weight: 700;
  }

  p {
    margin-top: 8px;
  }
`;

export const KualifiqueContent= styled.div`
  margin-top: 16px;
`;

export const KualifiqueLoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 24px;
`;

export const GoBackButton = styled.button`
  background-color: transparent;

  display: flex;
  align-items: center;

  color: #2B50D8;
  font-size: 15px;
  font-weight: 600;

  margin-bottom: 32px;

  transition: filter 0.2s;

  > svg {
    margin-right: 8px;
  }

  &:hover {
    filter: brightness(0.9);
  }
`;
