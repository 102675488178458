
/*
  global
  document
  CustomEvent
  window
*/

export const dispatch = ({ element = document, name, params }) => {
  element.dispatchEvent(new CustomEvent(name, {
    detail: { params },
  }));
};

export const subscribe = ({ element = document, name, callback }) => {
  element.addEventListener(name, callback);
};

export const unsubscribe = ({ element = document, name, callback }) => {
  element.removeEventListener(name, callback);
};

export const hasReachedBottom = ({
  element = window,
}) => {
  const { scrollHeight, scrollTop, clientHeight } = element;

  // return scrollHeight - (scrollTop + clientHeight) < (scrollHeight / 3);
  return scrollHeight - (scrollTop + clientHeight) < 480;
};

export default {
  dispatch,
  subscribe,
  unsubscribe,
  hasReachedBottom,
};
