
import React from 'react';
import styled from 'styled-components';
import { mqTablet, backgrounder } from '../../../styled/helpers/helpers';
import { COLOR_17, COLOR_2 } from '../../../styled/variables/variables';
import { Divider } from '../../../styled/components/components';
import { BaseLabel } from '../../../styled/labels/labels';
import { KoortMarkerOrangeOutline } from '../../../styled/components/components';

/*
  global
  process
*/

const { PUBLIC_URL } = process.env;

const Cover = () => (
  <Wrapper>
    <CDivider />
    <Label>sobre a</Label>
    <TitleWrapper>
        <AKoortMarkerWhiteOutline />
      <KoortimativaText />
    </TitleWrapper>
    <CDivider />
  </Wrapper>
);

const Wrapper = styled.div`
  padding: 150px 0 50px 0;
  background-color: ${ COLOR_17 };
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const CDivider = styled(Divider)`
  background-color: ${ COLOR_2 };
  width: 200px;
  align-self: center;
  opacity: 0.7;
`;

const Label = styled(BaseLabel)`
  font-weight: 300;
  color: ${ COLOR_2 };
  align-self: center;
  text-transform: uppercase;
  margin-bottom: 5px;
`;

  const AKoortMarkerWhiteOutline = styled(KoortMarkerOrangeOutline)`
  height: 40px;
  margin-right: 10px;

  ${ mqTablet`
    margin-right: 5px;
    height: 32px;
    ` }
    `;

const KoortimativaText = styled.img.attrs({
  src: `${ PUBLIC_URL }/imgs/koortimativa-text-orange-white.png`,
})`
width: 240px;
align-self: flex-end;
margin-bottom: -5px;

${ mqTablet`
  width: 240px;
  ` }
`;

const TitleWrapper = styled.div`
display: flex;
align-self: center;
margin-bottom: 20px;

${ mqTablet`
  flex-direction: row;
  ` }
`;

export default Cover;
