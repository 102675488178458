
import Base from '../base/base.model';

export const GROUP_TYPES = {
  SURROUNDINGS: 'SURROUNDINGS',
  CONDO: 'CONDO',
  PROPERTY: 'PROPERTY',
};

class Feature extends Base {

  constructor({
    id,

    label,
    group,
  }) {
    super({ id });

    this.label = label;
    this.group = group;
  }
}

export default Feature;
