import React, { PureComponent } from 'react';
import styled from 'styled-components';

import Selector from '../../../../components/selector';

class PropertyType extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      typesHasDefined: false,
      useTypeDefault: '',
      propertyTypeDefault: '',
      form: {
        use: types[0].use,
        type: types[0].value,
      },
    };
  }

  handleFormChange = event => {
    event.stopPropagation();
    const { name, value } = event.target;

    this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        [name]: value,
      },
    }));
  };

  render() {
    const { populate, isEditing } = this.props;
    const { typesHasDefined } = this.state;

    if (isEditing) {
      if (!!populate.use && !!populate.property && !typesHasDefined) {
        this.setState(prevState => ({
          ...prevState,
          typesHasDefined: true,
          useTypeDefault: populate.use,
          propertyTypeDefault: populate.property,
          form: {
            ...prevState.form,
            use: populate.use,
           type: populate.property,
          }
        }));
      }
    }

    return (
      <Wrapper onChange={this.handleFormChange}>
        <div className="form-floating" style={{ marginBottom: '15px' }}>
          <select
            className="form-select"
            id="floatingSelect"
            name="use"
            aria-label="Selecione um uso"
          >
            {uses.map(({ value, label, }) => (
              <option
                key={value}
                value={value}
                selected={this.state.useTypeDefault === label ? 'selected' : ''}
              >
                {label}
              </option>
            ))}
          </select>
          <label htmlFor="floatingSelect">Uso</label>
        </div>
        <div className="form-floating">
          <select
            className="form-select"
            id="floatingSelect"
            name="type"
            aria-label="Selecione um tipo" >
            {types.map(({ value, label, }) => (
              <option
                key={value}
                value={value}
                selected={this.state.propertyTypeDefault === label ? 'selected' : ''}
              >
                {label}
              </option>
            ))}
          </select>
          <label htmlFor="floatingSelect">Tipo</label>
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
`;

const PTSelector = styled(Selector)``;

const uses = [
  {
    label: 'Selecione um uso',
    value: '',
  },
  {
    label: 'Residencial',
    value: 'Residencial',
  },
  {
    label: 'Comercial',
    value: 'Comercial',
  },
];

const types = [
  {
    label: 'Selecione um tipo',
    value: '',
    use: uses[0].value,
  },
  {
    label: 'Apartamento',
    value: 'Apartamento',
    use: uses[0].value,
  },
  {
    label: 'Casa',
    value: 'Casa',
    use: uses[0].value,
  },
  {
    label: 'Casa de Condomínio',
    value: 'Casa de Condomínio',
    use: uses[0].value,
  },
  {
    label: 'Chácara/Sítio/Fazenda',
    value: 'Chácara | Sítio | Fazenda',
    use: uses[0].value,
  },
  {
    label: 'Cobertura',
    value: 'Cobertura',
    use: uses[0].value,
  },
  {
    label: 'Flat',
    value: 'Flat',
    use: uses[0].value,
  },
  {
    label: 'Kitnet',
    value: 'Kitnet',
    use: uses[0].value,
  },
  {
    label: 'Terreno',
    value: 'Terreno',
    use: uses[0].value,
  },
  {
    label: 'Edifício',
    value: 'Edifício',
    use: uses[0].value,
  },
  {
    label: 'Galpão/Depósito/Armazém',
    value: 'Galpão | Depósito | Armazém',
    use: uses[1].value,
  },
  {
    label: 'Loja/Ponto Comercial',
    value: 'Loja | Ponto Comercial',
    use: uses[1].value,
  },
  {
    label: 'Sala Comercial',
    value: 'Sala',
    use: uses[1].value,
  },
];

export default PropertyType;
