import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { mqDesktop } from '../../../styled/helpers/helpers';

import AdCardInteractive from '../../../components/ad-card-interactive-legacy';
import NoResults from '../../../components/no-results';

import userFavoriteService from '../../../services/user/favorite/user.favorite.service';
import { WrapperLoading } from '../../../components/ad-card-interactive/styles';

const customId = "custom-id-yes";

const Ads = ({
  ads,
  user,
}) => {
  const [favorites, setFavorites] = useState([]);
  const [loading, setLoading] = useState(true)

  async function getFavorites() {
    try {
      if (!user) {
        setLoading(false)
        return;
      }

      if (user) {
        const data = await userFavoriteService.get({ token: user.token });
        setFavorites(data);
        setLoading(false)
      }

    } catch {
      toast.error('Houve um erro ao destacar os favoritos!', {
        autoClose: false,
        toastId: customId,
      });
    }
  }

  useEffect(() => {
    getFavorites();
  }, []);

  function searchFavoriteAdOnFavorites(adId) {
    if (favorites && favorites.includes(adId)) return true;

    return false
  }

  if (loading) {
    return <WrapperLoading />
  }

  return (
    <Wrapper>
      {ads.map(ad => (
        <AAdCardInteractive
          {...ad.ads}
          {...ad}
          {...ad.category}
          {...ad.price}
          {...ad.address}
          id={ad._id}
          key={ad._id}
          user={user}
          favorite={searchFavoriteAdOnFavorites(ad._id)}
        />
      ))}
      {ads.length === 0 && (
        <NoResults />
      )}
    </Wrapper>

  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-flow: wrap;
`;

const AAdCardInteractive = styled(AdCardInteractive)`
  align-self: center;

  ${mqDesktop`
    width: 220px;
  ` }
`;

export default Ads;
