import * as yup from 'yup';

const profileFormSchema = yup.object().shape({
  name: yup
    .string()
    .required('Este campo é obrigatório')
    .min(3, 'Mínimo de 3 caracteres')
    .max(80, 'Mínimo de 80 caracteres'),
  telephone: yup.string()
    .min(18, 'Informe os 10 dígitos')
    .optional(),
  cellphone: yup.string()
    .required('Este campo é obrigatório')
    .min(19, 'Informe os 11 dígitos'),
  email: yup.string(),
});

export { profileFormSchema };
