function calculateDistance(coord1, coord2) {
  const [x1, y1] = coord1;
  const [x2, y2] = coord2;
  return Math.sqrt((x1 - x2) ** 2 + (y1 - y2) ** 2);
}

function calculateCenter(cluster) {
  const totalPoints = cluster.length;
  const sumCoords = cluster.reduce((sum, point) => {
      return [sum[0] + point.coordinates[0], sum[1] + point.coordinates[1]];
  }, [0, 0]);
  
  return [sumCoords[0] / totalPoints, sumCoords[1] / totalPoints];
}

export function clusterPoints(points, threshold) {
  const clusters = [];

  points.forEach(point => {
      let addedToCluster = false;
      for (const cluster of clusters) {
          for (const member of cluster.cluster) {
              if (calculateDistance(member.coordinates, point.coordinates) <= threshold) {
                  cluster.cluster.push(point);
                  cluster.coordinates = calculateCenter(cluster.cluster);
                  addedToCluster = true;
                  break;
              }
          }
          if (addedToCluster) break;
      }
      if (!addedToCluster) {
          const newCluster = { cluster: [point], coordinates: point.coordinates };
          clusters.push(newCluster);
      }
  });

  return clusters;
}

export function calculateProportionalDistanceFromCenter({borderStart, borderLimit, point}) {
  const center = (borderStart + borderLimit) / 2
  const distanceFromCenter = point - center
  const distanceFromBorders = borderLimit - borderStart
  const proportionalDistanceFromCenter = distanceFromCenter / distanceFromBorders

  return proportionalDistanceFromCenter
}