import Base from "../base/base.model";

class Review extends Base {
  constructor({
    id,

    reviewId,
    authorEmail,
    authorId,
    authorName,
    date,
    comment,

    main,
    negotiation,
    propertyKnowledge,
    recommend,
    service,
    surroundingsKnowledge,
  }) {
    super({ id });
    this.reviewId = reviewId;

    this.authorEmail = authorEmail;
    this.authorId = authorId;
    this.authorName = authorName;
    this.date = date instanceof Date ? date : new Date(date);
    this.comment = comment;
    this.main = main;
    this.negotiation = negotiation;
    this.propertyKnowledge = propertyKnowledge;
    this.recommend = recommend;
    this.service = service;
    this.surroundingsKnowledge = surroundingsKnowledge;
  }

  // get averageScore() {
  //   let averageScore = 0;
  //   averageScore += this.negotiation;
  //   averageScore += this.propertyKnowledge;
  //   averageScore += this.recommend;
  //   averageScore += this.service;
  //   averageScore += this.surroundingsKnowledge;
  //   averageScore /= 5;
  //   return averageScore;
  // }
}

export default Review;
