import styled, { css } from 'styled-components';

import { BaseButton } from '../../../styled/buttons/buttons';
import { COLOR_1, COLOR_4, COLOR_2, COLOR_17 } from '../../../styled/variables/variables';

export const Container = styled.div`
  display: flex;
  margin-bottom: 50px;
`;

export const Button = styled(BaseButton)`
 flex-grow: 1;
  width: 1px;
  font-weight: 300;
  border: solid 1px ${COLOR_1};

  ${({ isActive }) => !isActive && css`
    color: ${COLOR_4};
    background-color: ${COLOR_2};
    border-color: ${COLOR_17};
  ` }
`;
