
import React, { PureComponent } from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { Flag as FlagSolid } from 'styled-icons/boxicons-solid/Flag';
import { Flag as FlagRegular } from 'styled-icons/boxicons-regular/Flag';
import { AngleDown } from 'styled-icons/fa-solid/AngleDown';
import { Persist } from 'react-persist-plus';
import { toast } from 'react-toastify';

import RatingStars from '../../components/rating-stars';
import FancyModalButton from "../../components/modal";
import RatingDetail from './rating-detail/RatingDetail';

import { ProfilePicture } from '../../styled/components/components';
import { BaseLabel } from '../../styled/labels/labels';
import { pointer, iconBaseStyle } from '../../styled/helpers/helpers';
import { COLOR_19 } from '../../styled/variables/variables';
import { BaseButton } from '../../styled/buttons/buttons';
import { COLOR_2, COLOR_4, COLOR_1, COLOR_16 } from '../../styled/variables/variables';
import { BaseTextArea } from '../../styled/inputs/inputs';

import userReviewService from '../../services/user/review/user.review.service';

/*
  global
  window
  setTimeout
*/

class Review extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      showRatingDetail: false,
      form: {
        reportText: '',
      },
      reportAble: false,
      open: false,
    };
  }

  componentDidMount() {
    window.addEventListener('click', this.handleOutsideClick);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleOutsideClick);
  }

  handleOutsideClick = () => {
    const { showRatingDetail } = this.state;
    if (!showRatingDetail) return;

    this.setState(state => ({
      ...state,
      showRatingDetail: false,
    }));
  };

  handleRantingClick = () => {
    const { showRatingDetail } = this.state;
    if (showRatingDetail) return;

    setTimeout(() => {
      this.setState(state => ({
        ...state,
        showRatingDetail: true,
      }));
    }, 250)
  };

  handleFormChange = event => {
    const { name, value } = event.target;

    if (value.length > 9) {
      this.setState({
        reportAble: true
      })
    } else {
      this.setState({
        reportAble: false
      })
    }

    this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        [name]: value,
      }
    }))
  };

  handleSendReport = async () => {
    const { token } = this.props;
    const { review } = this.props;
    const customId = "custom-id-yes";

    await userReviewService.updateReport({
      item: this.state.form.reportText,
      token: token,
      id: review.id,
    }).then(
      this.setState({ open: false }),
      toast.info('Denúncia realizada com sucesso!', {
        autoClose: false,
        toastId: customId,
      })
    )
  }

  render() {
    const { review } = this.props;
    let able = this.state.reportAble;
    const { showRatingDetail } = this.state;
    const { form } = this.state.form;

    return (
      <Wrapper>
        <UserInfoWrapper>
          <UserPicture />
          <NameAndDateWrapper>
            <Name>
              <NameIconWrapper>
                <div>
                  {review.authorName}
                </div>
                <div>
                </div>
              </NameIconWrapper>
            </Name>
            <br></br>
            <Date>
              {review.date.toLocaleDateString('pt-br')}
            </Date>
          </NameAndDateWrapper>
        </UserInfoWrapper>
        <RatingWrapper onClick={this.handleRantingClick}>
          <RRatingStars rating={review.recommend} />
          <Rating>{review.recommend}</Rating>
          <DownArrowIcon />
          <RatingDetail
            {...review}
            show={showRatingDetail}
          />
        </RatingWrapper>
        <Comment>
          {review.comment}
        </Comment>
      </Wrapper>
    );
  }
}

Review.propTypes = {
  review: propTypes.shape({
    id: propTypes.string,
    authorEmail: propTypes.string,
    authorId: propTypes.string,
    authorName: propTypes.string,
    detail: propTypes.string,
    main: propTypes.string,
    negotiation: propTypes.string,
    propertyKnowledge: propTypes.string,
    recommend: propTypes.string,
    service: propTypes.string,
    surroundingsKnowledge: propTypes.string,
  }),
};

Review.defaultProps = {
  review: {
    id: '123',
    authorEmail: 'example@domail.com',
    authorId: '321',
    authorName: 'Rogério Albuquerque',
    detail: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    main: 5,
    negotiation: 5,
    propertyKnowledge: 5,
    recommend: 5,
    service: 5,
    surroundingsKnowledge: 5,
  },
};

const ReportWrapper = styled.form`

`;

const TextArea = styled(BaseTextArea).attrs({
  name: 'reportText',
})``;

const CancelButton = styled(BaseButton)`
  color: ${COLOR_2};
  background-color: ${COLOR_4};
  flex-grow: 1;
  width: 50%;
  border: none;
`;

const KeepButton = styled(CancelButton).attrs({
  type: 'submit',
})`
  background-color: ${({ disabled }) => disabled ? `${COLOR_16}` : `${COLOR_1}`};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  width: 50%;
  margin-top: 60px;
`;

const Title = styled.h2`
  margin-bottom: 20px;
  text-align: center;
`;

const ModalDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-content: center;
`;

const NameIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 200px;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
`;

const UserInfoWrapper = styled.div`
  display: table-row;
  flex-direction: column;
`;

const UserPicture = styled(ProfilePicture)`
  display: table-cell;
  float: left;
  margin-bottom: 10px;
  height: 60px;
  width: 60px;
`;

const NameAndDateWrapper = styled.div`
  height: 60px;
  display: table-cell;
  margin-bottom: 30px;
  padding-left: 10px;
  vertical-align: middle;
`;

const Name = styled(BaseLabel)`
  font-weight: 700;
  font-size: 14px;
  align-self: flex-end;
  margin-right: 5px;
`;

const Date = styled.p`
  font-size: 12px;
  align-self: flex-end;
  margin-right: 5px;
`;

const FlagEmptyIcon = styled(FlagRegular)`
  ${iconBaseStyle}
  color: ${COLOR_19};
  align-self: flex-start;
  height: 28px;
  margin-bottom: -2px;
`;

const FlatFillIcon = styled(FlagEmptyIcon).attrs({
  as: FlagSolid,
})`
  display: inline;
  transform: scale(0.8);
`;

const RatingWrapper = styled.div`
  display: flex;
  ${pointer}
  margin-bottom: 15px;
`;

const RRatingStars = styled(RatingStars)`
  align-self: center;
  margin-right: 20px;
`;

const Rating = styled(Name)`
  align-self: center;
  font-size: 16px;
  margin: 0;
  margin-top: 1px;
  margin-right: 5px;
`;

const DownArrowIcon = styled(AngleDown)`
  ${iconBaseStyle}
  align-self: center;
  height: 18px;
  margin-top: 2px;
`;

const Comment = styled(BaseLabel).attrs({
  as: 'p',
})`
  font-size: 16px;
`;

export default Review;
