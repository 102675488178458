import styled from 'styled-components';

export const Form = styled.form`
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    > h3 {
      color: #FFFFFF;
      font-size: 18px;

      @media (max-width: 360px) {
        max-width: 180px;
      }
    }

    > button {
      background-color: transparent;

      transition: filter .2s;

      &:hover {
        filter: brightness(0.9);
      }
        > svg {
        color: #FFFFFF;
      }
    }
  }
`;

export const PropertyDescriptionContent = styled.fieldset`
  legend {
    font-size: 15px;
    color: #FFF;
    margin-bottom: 12px;
  }

  span[role="alert"] {
    color: #6f1c1f;
  }
`;

export const PropertyUseSelectContent = styled.div`
  margin-bottom: 12px;
`;

export const CityAndNeighborhoodContent = styled.div`
  margin-top: 12px;
  display: flex;
  gap: 12px;

  @media (max-width: 480px) {
    flex-direction: column;
  }
`;

export const WhatsAppContent = styled.a`
margin-right: 12px;
text-decoration: none;
color: black;
  > svg {
    margin-right: 12px;
  }
`;


export const NumberOfBedroomsAndBathroomsContent = styled.div`
  margin-top: 12px;
  display: flex;
  gap: 12px;

  @media (max-width: 480px) {
    flex-direction: column;
  }
`;

export const NumberOfParkingSpacesAndAreaContent = styled.div`
  margin: 12px 0;
  display: flex;
  gap: 12px;

  @media (max-width: 480px) {
    flex-direction: column;
  }
`;

export const ContactDetailsContent = styled.fieldset`
  margin-top: 32px;

  legend {
    font-size: 15px;
    color: #FFF;
    margin-bottom: 12px;
  }

  span[role="alert"] {
    color: #6f1c1f;
    margin: 0;
  }
`;

export const ContactDetailsFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  > div:last-child {
    margin-top: 0;
  }
`;

export const ButtonsContent = styled.div`
  margin-top: 32px;

  button {
    background: #8ACC2C;
    color: #FFF;
    border: 0;
    cursor: pointer;
    font-weight: 500;
    width: 100%;
    padding: 8px;

    transition: filter .2s;

    &:nth-child(2) {
    margin-top: 12px;
    background: transparent;
    opacity: 0.6;

    &:disabled {
      cursor: not-allowed;
    }
  }

  &:hover {
    filter: brightness(0.9);
  }
}
`;
