import React from 'react';
import ReactPaginate from 'react-paginate';

import { StyledWrapper } from './styles';

const Pagination = (props) => {
	return ( 
		<StyledWrapper>
			<ReactPaginate 
			onPageChange={props.onPageChange} 
			pageCount={props.pageCount} 
			initialPage={props.initialPage}
			previousLabel="Anterior"
			nextLabel="Próxima"
			pageClassName="pagination-page-item"  /> 
		</StyledWrapper>
	);
}


 
export default Pagination;