// filterReducer.js
import { 
  SET_INITIAL_STATE, TOGGLE_TAB, CHANGE_CITY, ADD_NEIGHBORHOOD, REMOVE_NEIGHBORHOOD, ADD_TYPE, REMOVE_TYPE, ADD_FEATURE, REMOVE_FEATURE, SET_MAX_VALUE, SET_MIN_VALUE, SET_MAX_AREA, SET_MIN_AREA, SET_IS_RELEASE, ADD_BEDROOM, 
  REMOVE_BEDROOM, ADD_BATHROOM, REMOVE_BATHROOM, ADD_SUITE, REMOVE_SUITE, ADD_PARKING_SPACE, REMOVE_PARKING_SPACE, ORDER_BY, SORT, SET_RECT
} from './actions';

const params = (new URL(document.location)).searchParams;
const initialCity = params.get('city');
const initialTransaction = params.get('transaction');

const BUY_TRANSACTION_INDEX = 0

export const transactionOptions = [
  { label: "Comprar", value: 0, action: "Venda" },
  { label: "Lançamentos", value: 1, action: "Lançamento" },
  { label: "Alugar", value: 2, action: "Locação" },
]

const initialState = {
  transaction: 
    initialTransaction ? 
    transactionOptions.find(transaction => transaction.value === parseInt(initialTransaction)) : 
    transactionOptions[BUY_TRANSACTION_INDEX],
  city: initialCity,
  neighborhood: [],
  types: [],
  features: [],
  minValue: 0,
  maxValue: 1000000000000,
  minArea: 0,
  maxArea: 1000000000,
  isRelease: initialTransaction === "1",
  bedrooms: [], 
  bathrooms: [],
  suites: [],
  parkingSpaces: [],
  orderBy: 'updatedAt',
  sort: 'asc',
  rect: undefined
};

const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INITIAL_STATE:
      return {
        ...initialState,
        city: action.payload.city,
        neighborhood: action.payload.neighborhood,
        types: action.payload.types,
        bedrooms: action.payload.bedrooms,
      };
    case TOGGLE_TAB:
      return {
        ...state,
        transaction: transactionOptions[action.payload],
      };
    case CHANGE_CITY:
      return {
        ...initialState,
        city: action.payload,
      };
    case SET_IS_RELEASE:
      return {
        ...state,
        isRelease: action.payload,
      };
    case ADD_NEIGHBORHOOD:
      return {
        ...state,
        neighborhood: [...state.neighborhood, action.payload],
      };
    case REMOVE_NEIGHBORHOOD:
      return {
        ...state,
        neighborhood: state.neighborhood.filter(neighborhood => neighborhood !== action.payload),
      };
    case ADD_TYPE:
      return {
        ...state,
        types: [...state.types, action.payload],
      };
    case REMOVE_TYPE:
      return {
        ...state,
        types: state.types.filter(type => type !== action.payload),
      };
    case ADD_FEATURE:
      return {
        ...state,
        features: [...state.features, action.payload],
      };
    case REMOVE_FEATURE:
      return {
        ...state,
        features: state.features.filter(feature => feature !== action.payload),
      };
    case SET_MAX_VALUE:
      return {
        ...state,
        maxValue: action.payload,
      };
    case SET_MIN_VALUE:
      return {
        ...state,
        minValue: action.payload,
      };
    case SET_MAX_AREA:
      return {
        ...state,
        maxArea: action.payload,
      };
    case SET_MIN_AREA:
      return {
        ...state,
        minArea: action.payload,
      };
    case ADD_BEDROOM:
      return {
        ...state,
        bedrooms: [...state.bedrooms, action.payload],
      };
    case REMOVE_BEDROOM:
      return {
        ...state,
        bedrooms: state.bedrooms.filter(bedroom => bedroom !== action.payload),
      };
    case ADD_BATHROOM:
      return {
        ...state,
        bathrooms: [...state.bathrooms, action.payload],
      };
    case REMOVE_BATHROOM:
      return {
        ...state,
        bathrooms: state.bathrooms.filter(bathroom => bathroom !== action.payload),
      };
    case ADD_SUITE:
      return {
        ...state,
        suites: [...state.suites, action.payload],
      };
    case REMOVE_SUITE:
      return {
        ...state,
        suites: state.suites.filter(suite => suite !== action.payload),
      };
    case ADD_PARKING_SPACE:
      return {
        ...state,
        parkingSpaces: [...state.parkingSpaces, action.payload],
      };
    case REMOVE_PARKING_SPACE:
      return {
        ...state,
        parkingSpaces: state.parkingSpaces.filter(parkingSpace => parkingSpace !== action.payload),
      };
    case ORDER_BY:
      return {
        ...state,
        orderBy: action.payload,
      };
    case SORT:
      return {
        ...state,
        sort: action.payload,
      };
    case SET_RECT:
      return {
        ...state,
        rect: action.payload,
      };
    default:
      return state;
  }
};

export default filterReducer;