import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;

  @media (max-width: 680px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Card = styled.div`
  width: 100%;
  max-width: 300px;
  padding-bottom: 24px;

  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);

  header {
    background-color: #2b50d8;
    padding: 12px 16px;

    h4 {
      font-size: 28px;
      font-weight: 700;
      color: #ffffff;
      text-align: center;
    }
  }

  p {
    font-size: 15px;
    font-weight: 500;
    padding: 0 16px;
    margin-top: 18px;
    text-align: center;
  }
`;

export const PriceGroup = styled.div`
  padding: 0 16px;
  margin-top: 24px;

  strong {
    display: block;
    font-size: ${({ isCustomPlan }) => isCustomPlan ? '32px' : '42px'};
    text-align: center;

    ${({ isCustomPlan }) => !isCustomPlan && css`
      &::before {
        content: "R$";
        font-size: 24px;
        top: -16px;
        display: inline-block;
        position: relative;
      }

      &::after {
        content: "/mês";
        font-size: 12px;
        display: inline-block;
        color: grey;
      }
    `}
  }

  > p {
    opacity: 0.7;
    margin-top: 24px;
    font-size: 14px;
  }
`;

export const CTAButton = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 16px;

  button {
    margin-top: 24px;
    background-color: transparent;
    border: 1px solid #3578E5;
    color: #3578E5;
    font-size: 16px;
    font-weight: 600;
    padding: 10px 16px;
    width: 100%;

    transition: all .2s;

    &:hover {
      background-color: #3578E5;
      color: #ffffff;
    }
  }
`;

export const Divisor = styled.div`
  width: 100%;
  height: 1px;
  margin: 32px 0;
  background-color: #eaeaea;
`;

export const ResourcesList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0 16px;

  li {
    span {
      margin-right: 9px;
    }

    & + li {
      margin-top: 6px;
    }

    font-size: 14px;
    font-weight: 500;
  }
`;
