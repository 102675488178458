/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import { RiErrorWarningFill } from 'react-icons/ri';

import { Container } from './styles';

export function ErrorMessage({ message }) {
  return (
    <Container role="alert">
      <RiErrorWarningFill size="16" />
      {message}
    </Container>
  );
}
