import Base from "../base/base.model";

class ReportConfig extends Base {
  constructor({
    id,
    adId,
    realEstateId,

    // Report specific attributes.
    description,
    name,
    title,
    subtitle,

    // Address display control.
    city,
    complement,
    floor,
    location,
    neighborhood,
    state,
    street,
    number,
    zipCode,

    // Característics
    area,
    bathrooms,
    bedrooms,
    koortimativaRange,
    koortimativaValue,
    parkingSpaces,
    petAcceptance,
    suites,
    
    // Prices
    condo,
    iptu,
    rental,
    sale,
    transaction,

    // Features
    condoFeatures,
    surroundingFeatures,
    propertyFeatures,

    // Media
    panoramas,
    video,
    images,
    typeOfGuarantee,
        
    // Surrounding places
    surroundingPlaceType01,
    surroundingPlaceType02,
    surroundingPlaceType03,
    surroundingPlaceType04,
    surroundingPlaceType05,
    surroundingPlaceType06,
    surroundingPlaceType07,
    surroundingPlaceType08,
    surroundingPlaceType09,
    surroundingPlaceType10,
    surroundingPlaceType11,
    surroundingPlaceType12,

    // Others
    constructionStatus,
    status,
  }) {
    super({ id });

    this.adId = adId;
    this.area = area;
    this.bathrooms = bathrooms;
    this.bedrooms = bedrooms;
    this.city = city;
    this.complement = complement;
    this.condo = condo;
    this.condoFeatures = condoFeatures;
    this.constructionStatus = constructionStatus;
    this.description = description;
    this.floor = floor;
    this.images = images;
    this.iptu = iptu;
    this.koortimativaRange = koortimativaRange;
    this.koortimativaValue = koortimativaValue;
    this.location = location;
    this.name = name;
    this.neighborhood = neighborhood;
    this.number = number;
    this.panoramas = panoramas;
    this.parkingSpaces = parkingSpaces;
    this.petAcceptance = petAcceptance;
    this.propertyFeatures = propertyFeatures;
    this.realEstateId = realEstateId;
    this.rental = rental;
    this.sale = sale;
    this.state = state;
    this.status = status;
    this.street = street;
    this.subtitle = subtitle;
    this.suites = suites;
    this.surroundingFeatures = surroundingFeatures;
    this.surroundingPlaceType01 = surroundingPlaceType01;
    this.surroundingPlaceType02 = surroundingPlaceType02;
    this.surroundingPlaceType03 = surroundingPlaceType03;
    this.surroundingPlaceType04 = surroundingPlaceType04;
    this.surroundingPlaceType05 = surroundingPlaceType05;
    this.surroundingPlaceType06 = surroundingPlaceType06;
    this.surroundingPlaceType07 = surroundingPlaceType07;
    this.surroundingPlaceType08 = surroundingPlaceType08;
    this.surroundingPlaceType09 = surroundingPlaceType09;
    this.surroundingPlaceType10 = surroundingPlaceType10;
    this.surroundingPlaceType11 = surroundingPlaceType11;
    this.surroundingPlaceType12 = surroundingPlaceType12;
    this.title = title;
    this.transaction = transaction;
    this.typeOfGuarantee = typeOfGuarantee;
    this.video = video;
    this.zipCode = zipCode;
  }
}

export default ReportConfig;
