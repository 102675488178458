import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { RiErrorWarningLine } from 'react-icons/ri';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import Cover from '../../dashboard/cover/Cover';
import { GeneralStatistics } from '../components/general-statistics';
import { Chart } from '../components/chart';
import { LeadsTable } from './components/leads-table';

import userService from '../../../services/user/user.service';
import userProfileService from '../../../services/user-profile/user-profile.service';
import leadsService from '../../../services/leads/leads.service';

import eventsConstants from '../../../constants/events'
import domEventsUtil from '../../../utils/dom-events/dom-events.util';

import {
  PageErrorWrapper,
  CompanyBrokerWrapper,
  OriginLeadsSelect,
  Title,
  ChartAndLeadsTableWrapper,
} from './styles';

export function CompanyBrokerContent() {
  const [brokerProfileData, setBrokerProfileData] = useState(null);

  const [hasPageError, setHasPageError] = useState(false);

  const [leadsOriginsData, setLeadsOriginsData] = useState([]);
  const [leadsOriginSelected, setLeadsOriginsSelected] = useState('koort');

  async function getLeadsFromCompanyBroker() {
    try {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: true }
      });

      const user = userService.retrieve();

      const params = (new URL(document.location)).searchParams;

      const brokerId = params.get('brokerId');

      const responseUserProfile = await userProfileService.getUserBasicProfile({
        userId: brokerId,
        token: user.token,
      });

      setBrokerProfileData(responseUserProfile);
    } catch {
      setHasPageError(true);
    } finally {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: false }
      });
    }
  }

  useEffect(() => {
    getLeadsFromCompanyBroker();
  }, []);

  useEffect(() => {
    async function getLeadsOrigins() {
      try {
        const user = userService.retrieve();

        const { status, data } = await leadsService.getLeadsOriginsFromLoggedUser({
          brokerId: brokerProfileData?._id,
          token: user.token,
        });

        if (status !== 200) {
          throw new Error('Não foi possível buscar as origens dos leads, recarregue a página!')
        }

        const params = (new URL(document.location)).searchParams;

        const originParam = params.get('origin');

        setLeadsOriginsData(data);
        setLeadsOriginsSelected(originParam)
      } catch (err) {
        toast.error(err.message, {
          autoClose: 5000,
        });
      }
    }

    if (brokerProfileData && brokerProfileData._id) {
      getLeadsOrigins();
    }
  }, [brokerProfileData]);

  function handleChangeLeadsOrigins(origin) {
    if (origin === leadsOriginSelected) {
      return;
    }

    const url = new URL(window.location);
    url.searchParams.set('origin', origin);
    window.history.pushState(null, '', url.toString());
    window.location.reload();
  }

  return (
    <>
      {hasPageError && (
        <PageErrorWrapper>
          <div>
            <RiErrorWarningLine size={120} color="#dc3545" />

            <p>Algumas informações necessárias não puderam ser carregadas</p>

            <button
              type="button"
              onClick={() => window.location.reload(true)}
            >
              Recarregar
            </button>
          </div>
        </PageErrorWrapper>
      )}

      {!hasPageError && (
        <>
          <CompanyBrokerWrapper>
            <OriginLeadsSelect>
              <ButtonGroup
                color="primary"
                aria-label="outlined primary button group"

              >
                {leadsOriginsData.map(item => (
                  <Button
                    key={item}
                    variant={item === leadsOriginSelected ? 'contained' : 'outlined'}
                    onClick={() => handleChangeLeadsOrigins(item)}
                  >
                    {item}
                  </Button>
                ))}
              </ButtonGroup>
            </OriginLeadsSelect>

            <Title>Console de leads do corretor</Title>

            <GeneralStatistics
              referenceType="realtor"
              referenceId={brokerProfileData?._id}
            />

            <ChartAndLeadsTableWrapper>
              {/* <Chart /> */}

              <LeadsTable />
            </ChartAndLeadsTableWrapper>

          </CompanyBrokerWrapper>
        </>
      )}
    </>
  );
}
