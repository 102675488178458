
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import propTypes from 'prop-types';
import Card from './card/Card';
import { Row, Col } from 'react-bootstrap';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import { ArrowBack } from 'styled-icons/boxicons-regular/ArrowBack';
import { PhotoSizeSelectSmall } from 'styled-icons/material/PhotoSizeSelectSmall';
import { toast } from 'react-toastify';
import { RiLinksLine, RiLinkUnlinkM } from "react-icons/ri";

import { COLOR_2, COLOR_3, COLOR_6, COLOR_20, COLOR_7 } from '../../../styled/variables/variables';
import { BaseButton } from '../../../styled/buttons/buttons';
import { Divider } from '../../../styled/components/components';
import { mqTablet } from '../../../styled/helpers/helpers';
import { MaskedBaseInput, masks } from '../../../styled/inputs/inputs';

import NoResults from '../../../components/no-results';

import domEventsUtil from '../../../utils/dom-events/dom-events.util';
import { PURCHASE_FLOW_BUILDER, PURCHASE_FLOW_BUILDER3, PURCHASE_FLOW_BUILDER3_REGISTER_PROPERTY } from '../../../constants/paths';
import listingService from '../../../services/listing/listing.service';
import realEstateService from '../../../services/real-estate/real-estate.service';
import realEstatePendingService from '../../../services/real-estate-pending/real-estate-pending.service';
import eventsConstants from '../../../constants/events';
import realEstateTypes from '../../../constants/real-estate-types';
import attachBrokersService from '../../../services/attach-brokers/attach-brokers.service';
import userService from '../../../services/user/user.service';
import externalIntegrationService from '../../../services/koort-business/external-integration.service';

import './ads.css';

const customStylesModal = {
  overlay: {
    background: 'rgba(0, 0, 0, 0.8)',
  },
  content: {
    background: '#F2F2F2',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    width: '100%',
    maxWidth: '665px',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const Reports = ({ user, history }) => {

  const [state, setState] = useState({
    realEstates: [],
    optionsUse: [],
    optionsTransaction: [],
    optionsQuartos: [],
    optionsVagas: [],
    optionsClassification: [],
    externalId: '',
    precoMin: 0,
    precoMax: 99999999999999,
    areaMin: 0,
    areaMax: 99999999999999,
    cities: [],
    city: null,
    assignment: '',
    districts: [{
      types: [{
        label: 'Selecione uma cidade',
        id: 1
      }],
    }],
    neighborhood: null,
    type: null,
    bedrooms: null,
    classification: null,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageOnFilter, setCurrentPageOnFilter] = useState(1);
  const [showLoadMoreButton, setShowLoadMoreButton] = useState(false);
  const [filterHasUsed, setFilterHasUsed] = useState(false);
  const [isSearchByExternalId, setIsSearchByExternalId] = useState(false);

  const [integrationPortal, setIntegrationPortal] = useState(null);
  const [integratedRealEstatesIds, setIntegratedRealEstatesIds] = useState([]);
  const [integrationPortalModalVisible, setIntegrationPortalModalVisible] = useState(false);

  const [masterFilterSelected, setMasterFilterSelected] = useState('all');
  const [realEstatePendingTotal, setRealEstatePendingTotal] = useState(0);

  async function handleAttachPropertyToBroker(realEstateId) {
    try {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: true },
      });

      const params = new URL(document.location).searchParams;

      const brokerId = params.get("brokerId");

      const token = userService.retrieve().token;

      const { status } = await attachBrokersService.attachPropertyToBroker({
        realEstateId,
        brokerId,
        token
      });

      if (status !== 204) {
        throw new Error('Não foi possível vincular esse imóvel, tente novamente!');
      }

      const realEstatesFiltered = state.realEstates.filter(item => item._id !== realEstateId);

      setState(prevState => ({
        ...prevState,
        realEstates: realEstatesFiltered,
      }));

      toast.success('Imóvel vinculado com sucesso!')
    } catch (err) {
      toast.error(err.message)
    } finally {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: false },
      });
    }
  }

  async function getRealEstatePendingTotal() {
    try {
      const user = userService.retrieve();

      const { status, data } = await realEstatePendingService.getRealEstatePendingTotal({
        token: user.token,
      });

      if (status !== 200) {
        throw new Error('Não foi possível buscar o total de pendências!');
      }

      setRealEstatePendingTotal(data.total);
    } catch (error) {
      toast.error(error.message, {
        autoClose: 3000,
      });
    }
  }

  useEffect(() => {
    domEventsUtil.dispatch({
      name: eventsConstants.LOADING_ANIMATION,
      params: { show: true }
    });

    getReports();
    getCities();
    getRealEstatePendingTotal();

    const onClick = (e) => {
      if (e.target.className.includes && (!e.target.className.includes('lb-filter') && !e.target.className.includes('bt-filter') && e.target.parentNode.className !== "form-input")) {
        for (let i = 0; i < document.getElementsByClassName("bt-filter").length; i++) {
          let el = document.getElementsByClassName("bt-filter")[i]
          el.checked = false;
          let lb = el.parentNode.childNodes[1];

          let values = ""
          for (let iInput = 0; iInput < lb.getElementsByClassName("form-control").length; iInput++) {
            if (lb.getElementsByClassName("form-control")[iInput].value.trim().length > 0) {
              values += ` ${lb.getElementsByClassName("form-control")[iInput].value} -`;
            }
          }
          if (values && values.trim().replaceAll("-", "").length > 0) {
            lb.classList.add('color-blue');
          } else {
            lb.classList.remove('color-blue');
          }

        }
      }
    };
    document.body.addEventListener("click", onClick);

    return () => {
      document.body.removeEventListener("click", onClick);
    };
  }, [currentPage, masterFilterSelected]);

  // ####### INÍCIO - ESCOPO RESPONSÁVEL PELA INTEGRAÇÃO COM XML #######

  async function getIntegratedRealEstatesIds(portal) {
    try {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: true }
      });

      const user = userService.retrieve();

      const { data, status } = await externalIntegrationService.getIntegratedRealEstatesIds({
        portal,
        token: user.token,
      });

      if (status !== 200) {
        throw new Error("Não foi possível buscar as informações necessárias.")
      }

      setIntegratedRealEstatesIds(data);
    } catch {
      setIntegrationPortal(null);

      toast.error("Não foi possível buscar as informações necessárias", {
        autoClose: 5000,
      });
    } finally {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: false }
      });

      if (!localStorage.getItem('@koortConsumer:confirmUnderstandIntegrationPortal')) {
        setIntegrationPortalModalVisible(true);
      }
    }
  }

  useEffect(() => {
    const params = new URL(document.location).searchParams;

    const integrationPortalParam = params.get("integrationPortal");

    if (!!integrationPortalParam) {
      setIntegrationPortal(integrationPortalParam);
      getIntegratedRealEstatesIds(integrationPortalParam);
    }
  }, []);

  async function handleAddRealEstateOnPortal(realEstateId) {
    try {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: true }
      });

      const user = userService.retrieve();

      const params = new URL(document.location).searchParams;

      let companyCode = null;

      if (integrationPortal === 'casa-mineira' || integrationPortal === 'imovelweb') {
        const companyCodeParam = params.get("companyCode");

        companyCode = companyCodeParam;
      }

      const { status } = await externalIntegrationService.addRealEstatesOnPortal({
        portal: integrationPortal,
        realEstatesIds: [realEstateId],
        companyCode,
        token: user.token,
      });

      if (status !== 204) {
        throw new Error("Não foi possível anexar esse imóvel ao XML, tente novamente!")
      }

      setIntegratedRealEstatesIds(prevState => [...prevState, realEstateId]);
      toast.success('Imóvel anexado ao XML!', {
        autoClose: 2000
      })
    } catch {
      toast.error("Não foi possível anexar esse imóvel ao XML, tente novamente!", {
        autoClose: 5000,
      });
    } finally {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: false }
      });
    }
  }

  async function handleRemoveRealEstateOnPortal(realEstateId) {
    try {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: true }
      });

      const user = userService.retrieve();

      const { status } = await externalIntegrationService.removeRealEstatesOnPortal({
        portal: integrationPortal,
        realEstatesIds: [realEstateId],
        token: user.token,
      });

      if (status !== 204) {
        throw new Error("Não foi possível remover esse imóvel do XML, tente novamente!")
      }

      const integratedRealEstatesIdsFiltered = integratedRealEstatesIds.filter(item => item !== realEstateId);
      toast.success('Imóvel removido do XML!', {
        autoClose: 2000
      })
      setIntegratedRealEstatesIds(integratedRealEstatesIdsFiltered);
    } catch {
      toast.error("Não foi possível remover esse imóvel do XML, tente novamente!", {
        autoClose: 5000,
      });
    } finally {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: false }
      });
    }
  }

  function handleConfirmUnderstandIntegrationPortal() {
    localStorage.setItem('@koortConsumer:confirmUnderstandIntegrationPortal', 'confirmed');

    setIntegrationPortalModalVisible(false);
  }

  // ####### FIM - ESCOPO RESPONSÁVEL PELA INTEGRAÇÃO COM XML #######

  const getCities = async () => {
    var r = await listingService.getCitiesFromUser({ token: user.token })
    if (r[0].types[0]) {
      setState(prev => ({
        ...prev,
        cities: r,
      }));
    }
  }

  const getReports = async () => {
    domEventsUtil.dispatch({
      name: eventsConstants.LOADING_ANIMATION,
      params: { show: true }
    });

    let realEstates = await realEstateService.getUserRealEstates({
      token: user.token,
      use: state.optionsUse,
      precoMin: 0,
      precoMax: 99999999999,
      page: currentPage,
      isFilter: false,
      situation: masterFilterSelected,
      assignment: !window.location.href.includes('/dashboard-brokers/attach-property') ? '' : 'company',
    });

    setState(prev => ({
      ...prev,
      realEstates: currentPage === 1 ? [...realEstates] : [...prev.realEstates, ...realEstates],
    }));

    if (realEstates) {
      if (realEstates.length === 10) {
        setShowLoadMoreButton(true);
      } else {
        setShowLoadMoreButton(false);

        if (currentPage > 1) {
          toast.info("Todos os seus imóveis foram carregados!", {
            autoClose: false,
          });
        }
      }
    }

    domEventsUtil.dispatch({
      name: eventsConstants.LOADING_ANIMATION,
      params: { show: false }
    });

    domEventsUtil.dispatch({
      name: eventsConstants.LOADING_ANIMATION,
      params: { show: false }
    });
  };

  // Condição antiga para redirecionamento do formulário,
  // de cadastrar imóvel, baseado no tipo de usuário

  //  let purchaseFlowLink = PURCHASE_FLOW_BUILDER;

  //  if (user.userType === 3) {
  //    purchaseFlowLink = PURCHASE_FLOW_BUILDER3 + "?flowType=BROKER&noStep=true";
  //  }

  // Mantendo apenas um tipo de formulário
  let purchaseFlowLink = PURCHASE_FLOW_BUILDER3_REGISTER_PROPERTY + "?noStep=true";

  const customStyle = {
    control: base => ({
      ...base,
      border: '1px solid #999',
      boxShadow: 'none'
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: 16,
    }),
    input: (provided, state) => ({
      ...provided,
      fontSize: 25,
      height: 40,
    }),
  }

  const handleChangePrice = async (e) => {
    setFilterHasUsed(true);
    setCurrentPageOnFilter(1);

    let realEstates = null;
    let val = e.target.value.replace(/[^0-9]/g, '');
    let id = e.target.id;;

    if (!val) {
      val = 0;
    }

    let params = {
      token: user.token,
      transaction: state.transaction,
      classification: state.optionsClassification,
      bedrooms: state.optionsQuartos,
      parkingSpaces: state.optionsVagas,
      precoMin: state.precoMin,
      precoMax: state.precoMax,
      areaMin: state.areaMin,
      areaMax: state.areaMax,
      city: state.city,
      neighborhood: state.neighborhood,
      type: state.type,
      classification: state.classification,
      bedrooms: state.bedrooms,
      externalId: state.externalId,
      page: 1,
      isFilter: true,
      situation: masterFilterSelected,
      assignment: !window.location.href.includes('/dashboard-brokers/attach-property') ? '' : 'company',
    }

    switch (id) {
      case "external-id":
        setShowLoadMoreButton(false);
        setIsSearchByExternalId(true);

        e.persist();

        if (!e.target.value) {
          handleClearFilter();
        }

        await setState(prev => ({
          ...prev,
          externalId: e.target.value,
        }))
        params.externalId = e.target.value;
        break;
      case "preco-min":
        await setState(prev => ({
          ...prev,
          precoMin: val,
        }))
        params.precoMin = val;
        break;
      case "preco-max":
        await setState(prev => ({
          ...prev,
          precoMax: val,
        }))
        params.precoMax = val;
        break;
      case "area-min":
        await setState(prev => ({
          ...prev,
          areaMin: val,
        }))
        params.areaMin = val;
        break;
      case "area-max":
        await setState(prev => ({
          ...prev,
          areaMax: val,
        }))
        params.areaMax = val;
        break;
    }
    realEstates = await realEstateService.getUserRealEstates(params);

    if (realEstates) {
      await setState(prev => ({
        ...prev,
        realEstates,
      }));
    }

  }

  const handleDropdownItemClick = async (e) => {
    setFilterHasUsed(true);
    setCurrentPageOnFilter(1);

    let val = e.target.value
    let id = e.target.id
    let params = {
      token: user.token,
      transaction: state.transaction,
      classification: state.optionsClassification,
      bedrooms: state.optionsQuartos,
      parkingSpaces: state.optionsVagas,
      precoMin: state.precoMin,
      precoMax: state.precoMax,
      areaMin: state.areaMin,
      areaMax: state.areaMax,
      city: state.city,
      neighborhood: state.neighborhood,
      type: state.type,
      classification: state.classification,
      bedrooms: state.bedrooms,
      page: 1,
      isFilter: true,
      situation: masterFilterSelected,
      assignment: !window.location.href.includes('/dashboard-brokers/attach-property') ? state.assignment : 'company',
    }

    switch (id) {
      case "select-city":
        var r = await listingService.getDistrictsFromUser({
          city: val,
          token: user.token,
        })

        await setState(prev => ({
          ...prev,
          city: val,
          districts: r,
        }))
        params.city = val;
        break;
      case "select-bairro":
        await setState(prev => ({
          ...prev,
          neighborhood: val,
        }))
        params.neighborhood = val;
        break;
      case "select-tipo":
        await setState(prev => ({
          ...prev,
          type: val,
        }))
        params.type = val;
        break;
      case "select-quarto":
        await setState(prev => ({
          ...prev,
          bedrooms: val,
        }))
        params.bedrooms = val;
        break;
      case "select-classification":
        await setState(prev => ({
          ...prev,
          classification: val,
        }))
        params.classification = val;
        break;
      case "select-linked-property":
        await setState(prev => ({
          ...prev,
          assignment: val,
        }))
        params.assignment = val;
        break;
    }

    const realEstates = await realEstateService.getUserRealEstates(params);

    if (realEstates) {
      await setState(prev => ({
        ...prev,
        realEstates,
      }));
    }
  };

  async function handleLoadMoreDataFilter() {
    try {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: true }
      });

      let params = {
        token: user.token,
        transaction: state.transaction,
        classification: state.optionsClassification,
        bedrooms: state.optionsQuartos,
        parkingSpaces: state.optionsVagas,
        precoMin: state.precoMin,
        precoMax: state.precoMax,
        areaMin: state.areaMin,
        areaMax: state.areaMax,
        city: state.city,
        neighborhood: state.neighborhood,
        type: state.type,
        classification: state.classification,
        bedrooms: state.bedrooms,
        page: currentPageOnFilter + 1,
        isFilter: true,
        situation: masterFilterSelected,
        assignment: !window.location.href.includes('/dashboard-brokers/attach-property') ? '' : 'company',
      }

      const realEstates = await realEstateService.getUserRealEstates(params);

      if (realEstates) {
        setState(prev => ({
          ...prev,
          realEstates: [...prev.realEstates, ...realEstates],
        }));

        if (realEstates.length === 10) {
          setShowLoadMoreButton(true);
        } else {
          setShowLoadMoreButton(false);

          toast.info("Todos os seus imóveis foram carregados!", {
            autoClose: false,
          });
        }
      }

      setCurrentPageOnFilter(currentPageOnFilter + 1);
    } catch {
      toast.error("Houve um erro ao carregar seus imóveis, tente novamente!", {
        autoClose: false,
      });
    } finally {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: false }
      });
    }
  }

  function handleClearFilter() {
    setCurrentPage(1);
    setCurrentPageOnFilter(1);
    setFilterHasUsed(false);
    setShowLoadMoreButton(false);
    setIsSearchByExternalId(false);

    setState({
      realEstates: [],
      optionsUse: [],
      optionsTransaction: [],
      optionsClassification: [],
      optionsQuartos: [],
      optionsVagas: [],
      precoMin: 0,
      precoMax: 99999999999999,
      areaMin: 0,
      areaMax: 99999999999999,
      externalId: '',
      cities: [],
      city: null,
      assignment: '',
      districts: [{
        types: [{
          label: 'Selecione uma cidade',
          id: 1
        }],
      }],
      neighborhood: null,
      type: null,
      bedrooms: null,
      classification: null,
    });

    document.getElementById("select-city").selectedIndex = 0;
    document.getElementById("select-bairro").selectedIndex = 0;
    document.getElementById("select-tipo").selectedIndex = 0;
    document.getElementById("select-quarto").selectedIndex = 0;
    document.getElementById("select-classification").selectedIndex = 0;
    document.getElementById("select-linked-property").selectedIndex = 0;

    if (currentPage === 1) {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: true }
      });

      getReports();
      getCities();
    }
  }

  function handleMasterFilterChange(value) {
    if (currentPage > 1) {
      setCurrentPage(1);
    }

    setMasterFilterSelected(value);
  }

  return (
    <>
      <Wrapper>
        <div >
          {/* <br /> */}
          <Row className={'no-gutter dashboard-ads'}>
            <MasterFilterWrapper>
              <MasterFilterSeparator>
                <MasterFilterButton
                  active={masterFilterSelected === 'all'}
                  disabled={masterFilterSelected === 'all'}
                  onClick={() => handleMasterFilterChange('all')}
                >
                  Todos os imóveis
                </MasterFilterButton>

                <MasterFilterButton
                  active={masterFilterSelected === 'published'}
                  disabled={masterFilterSelected === 'published'}
                  onClick={() => handleMasterFilterChange('published')}
                >
                  Anunciados
                </MasterFilterButton>
              </MasterFilterSeparator>

              <MasterFilterSeparator>
                <MasterFilterButton
                  active={masterFilterSelected === 'unpublished'}
                  disabled={masterFilterSelected === 'unpublished'}
                  onClick={() => handleMasterFilterChange('unpublished')}
                >
                  Desanunciados
                </MasterFilterButton>

                <MasterFilterButton
                  active={masterFilterSelected === 'pending'}
                  disabled={masterFilterSelected === 'pending'}
                  onClick={() => handleMasterFilterChange('pending')}
                >
                  Com pendências

                  <PendingBadge>{realEstatePendingTotal}</PendingBadge>
                </MasterFilterButton>
              </MasterFilterSeparator>
            </MasterFilterWrapper>

            <Col xl={2} style={{ marginTop: '5px' }}>
              <input disabled={isSearchByExternalId} type="checkbox" className="bt-filter" id="filter-preco" />
              <InputLabelFilter isDisabled={isSearchByExternalId} htmlFor={"filter-preco"} className="lb-filter form-select">
                Preço (min e máx)
                {/* <BedroomsIcon className={"icon-filter"} /> */}
                <div className={'container__filter'}>
                  <Row>
                    <Col xl={6}>
                      <div className="form-input" style={{ marginBottom: "10px", width: "100%" }}>
                        <PriceInput
                          mask={masks.price()}
                          id={'preco-min'}
                          className={'form-control'}
                          placeholder={'Preço mínimo'}
                          onChange={handleChangePrice}
                        />
                        {/* <label htmlFor="preco-min" className="form-label">Preço mínimo</label> */}
                      </div>
                    </Col>
                    <Col xl={6}>
                      <div className="form-input" style={{ width: "100%" }}>
                        <PriceInput
                          mask={masks.price()}
                          id={'preco-max'}
                          className={'form-input'}
                          placeholder={'Preço máximo'}
                          onChange={(e) => { handleChangePrice(e, "precoMax") }}
                        />
                        {/* <label htmlFor="preco-max" className="form-label">Preço máximo</label> */}
                      </div>
                    </Col>
                  </Row>
                </div>
              </InputLabelFilter>
            </Col>
            <Col xl={2} style={{ marginTop: '5px' }}>
              <input disabled={isSearchByExternalId} type="checkbox" className="bt-filter" id="filter-area" />
              <InputLabelFilter isDisabled={isSearchByExternalId} htmlFor={"filter-area"} className="lb-filter form-select">
                Área (min e máx)
                {/* <BedroomsIcon className={"icon-filter"} /> */}
                <div className={'container__filter'}>
                  <Row>
                    <Col xl={6}>
                      <div className="form-input" style={{ marginBottom: "10px", width: "100%" }}>
                        <PriceInput
                          mask={masks.integer()}
                          id={'area-min'}
                          className={'form-control'}
                          placeholder={'Área mínima'}
                          onChange={handleChangePrice}
                        />
                        {/* <label htmlFor="preco-min" className="form-label">Preço mínimo</label> */}
                      </div>
                    </Col>
                    <Col xl={6}>
                      <div className="form-input" style={{ width: "100%" }}>
                        <PriceInput
                          mask={masks.integer()}
                          id={'area-max'}
                          className={'form-input'}
                          placeholder={'Área máxima'}
                          onChange={(e) => { handleChangePrice(e, "areaMax") }}
                        />
                        {/* <label htmlFor="preco-max" className="form-label">Preço máximo</label> */}
                      </div>
                    </Col>
                  </Row>
                </div>
              </InputLabelFilter>
            </Col>
            <Col xl={2} style={{ marginTop: '5px' }}>
              <select disabled={isSearchByExternalId} className="form-select" id="select-city" name="select-city" onChange={handleDropdownItemClick}>
                <option selected disabled hidden>Cidade</option>
                {state.cities[0] && state.cities[0].types.map(({ value, label, }) => (
                  <option key={label} value={label}> {label} </option>
                ))}
              </select>
            </Col>
            <Col xl={2} style={{ marginTop: '5px' }}>
              <select disabled={isSearchByExternalId} className="form-select" id="select-bairro" name="select-bairro" onChange={handleDropdownItemClick}>
                <option selected disabled hidden>Bairro</option>
                {state.districts[0] && state.districts[0].types.map(({ value, label, }) => (
                  <option key={label} value={label}> {label} </option>
                ))}
              </select>
            </Col>
            <Col xl={2} style={{ marginTop: '5px' }}>
              <select disabled={isSearchByExternalId} className="form-select" id="select-tipo" name="select-tipo" onChange={handleDropdownItemClick}>
                <option selected disabled hidden>Tipo</option>
                {realEstateTypes[0] && realEstateTypes[0].types.map(({ id, label }) => (
                  <option key={id} value={id}> {label} </option>
                ))}
              </select>
            </Col>

            <Col xl={2} style={{ marginTop: '5px' }}>
              <select disabled={isSearchByExternalId} className="form-select" id="select-quarto" name="select-quarto" onChange={handleDropdownItemClick}>
                <option selected disabled hidden>N° de quartos</option>
                <option value="1"></option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4+</option>
              </select>
            </Col>

            <Col xl={3} style={{ marginTop: '5px' }}>
              <select disabled={isSearchByExternalId} className="form-select" id="select-classification" name="select-classification" onChange={handleDropdownItemClick}>
                <option selected disabled hidden>Classificação</option>
                <option value="more_views">Mais acessados</option>
                <option value="less_views">Menos acessados</option>
                <option value="more_favorites">Mais favoritados</option>
                <option value="less_favorites">Menos favoritados</option>
              </select>
            </Col>

            {!window.location.href.includes('/dashboard-brokers/attach-property') && (
              <Col xl={3} style={{ marginTop: '5px' }}>
                <select disabled={isSearchByExternalId} className="form-select" id="select-linked-property" name="select-linked-property" onChange={handleDropdownItemClick}>
                  <option selected disabled hidden>Situação</option>
                  <option value="">Todos</option>
                  <option value="company">Não vinculados à corretores</option>
                </select>
              </Col>
            )}

            <Col xl={4} style={{ marginTop: '5px' }}>
              <div className="form-input" style={{ marginBottom: "10px", width: "100%" }}>
                <ExternalIdInput
                  id={'external-id'}
                  className={'form-control'}
                  placeholder='Código do anúncio'
                  value={state.externalId}
                  onChange={handleChangePrice}
                />
                {/* <label htmlFor="preco-min" className="form-label">Preço mínimo</label> */}
              </div>
            </Col>

            <Col xl={12} xs={12} style={{ marginTop: '10px' }}>
              {/* <BtnLink to={purchaseFlowLink}> */}
              {filterHasUsed && <ButtonCleanFilter onClick={handleClearFilter}>Limpar Filtro</ButtonCleanFilter>}

              {!window.location.href.includes('/dashboard-brokers/attach-property') &&
                user && !user.isLinked && <Button onClick={() => history.push(purchaseFlowLink)}>Cadastrar Imóvel</Button>
              }
              {/* <BaseButton>Cadastrar Imóvel</BaseButton> */}
              {/* </BtnLink> */}
            </Col>

          </Row>
          <Row>
            <Col>
              <Divider />
            </Col>
          </Row>
          <Wrapper>
            {state.realEstates && state.realEstates.map(re => (
              <Card
                key={re._id}
                realEstate={re}
                user={user}
                hasPending={masterFilterSelected === 'pending'}
                onReportUpdate={getReports}
                onAttachPropertyToBroker={() => handleAttachPropertyToBroker(re._id)}
                integrationPortal={integrationPortal}
                hasIntegrationPortal={integratedRealEstatesIds.includes(re._id)}
                onAddRealEstateOnPortal={() => handleAddRealEstateOnPortal(re._id)}
                onRemoveRealEstateOnPortal={() => handleRemoveRealEstateOnPortal(re._id)}
              />
            ))}
            {state.realEstates && state.realEstates.length === 0 && (
              <NoResults />
            )}

            {state.realEstates &&
              !!state.realEstates.length &&
              showLoadMoreButton &&
              <LoadMoreButton type="button" onClick={() => {
                if (filterHasUsed) {
                  handleLoadMoreDataFilter();
                } else {
                  setCurrentPage(prevState => prevState + 1);
                }
              }}>
                Carregar mais imóveis
              </LoadMoreButton>
            }
          </Wrapper>
        </div>

        <Modal
          isOpen={integrationPortalModalVisible}
          style={customStylesModal}
          contentLabel="Modal Instruções de Integração com Terceiros"
        >
          <IntegrationPortalModalContent>
            <h3>INTEGRAÇÃO COM TERCEIROS</h3>
            <p>O processo de vincular ou remover um imóvel do XML é muito simples.</p>
            <p>Você poderá realizar cada ação através dos seguintes botões:</p>
            <ul>
              <li>
                <RiLinksLine size={28} />
                Vincular ao XML
              </li>

              <li>
                <RiLinkUnlinkM size={28} />
                Remover do XML
              </li>
            </ul>

            <button
              type="button"
              onClick={handleConfirmUnderstandIntegrationPortal}
            >
              Entendi
            </button>
          </IntegrationPortalModalContent>
        </Modal>
      </Wrapper>
    </>
  );
};

Reports.propTypes = {
  history: propTypes.object,
  user: propTypes.object,
};

const MasterFilterWrapper = styled.div`
  display: flex;
  margin-bottom: 24px;
  padding: 0;

  @media (max-width: 740px) {
    flex-direction: column;
  }
`;

const MasterFilterButton = styled.button`
  background-color: ${(props) => props.active ? '#2B2A28' : 'transparent'};
  color: ${(props) => props.active ? '#FFFFFF' : '#2B2A28'};
  border: 1px solid #2B2A28;
  padding: 8px 16px;
  flex: 1;
  cursor: pointer;

  transition: background-color 0.2s, color 0.2s;

  position: relative;

  & + button {
    margin-left: 8px;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:not(:disabled):hover {
    background-color: #2B2A28;
    color: #FFFFFF;
  }

  @media (max-width: 384px) {
    margin-left: 0 !important;
    margin-top: 8px;
  }
`;

const PendingBadge = styled.span`
  position: absolute;

  /* top: -11px;
  left: -2px; */

  /* left: 8px; */

  right: 8px;

  @media (max-width: 1020px) {
    top: -8px;
    right: -2px;
  }

  @media (max-width: 740px) {
    top: unset;
    right: 8px;
  }

  @media (max-width: 525px) {
    top: -8px;
    right: -2px;
  }

  @media (max-width: 384px) {
    top: unset;
    right: 8px;
  }

  min-width: 28px;

  background-color: #dc3545;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  border-radius: 8px;
  padding: 2px 4px;
`;

const MasterFilterSeparator = styled.div`
  display: flex;
  flex: 1;
  padding: 0;

  @media (min-width: 740px) {
    & + div {
      margin-left: 8px;
    }
  }

  @media (max-width: 740px) {
   margin-top: 8px;
  }

  @media (max-width: 384px) {
    flex-direction: column;
  }
`;

const IntegrationPortalModalContent = styled.div`
  > h3 {
    font-weight: 700;
    text-align: center;
    font-size: 18px;
    margin-bottom: 24px;
  }

  > p {
    font-size: 16px;
    font-weight: 500;
  }

  > ul {
    margin: 42px 0;
    padding-left: 0px;

    display: flex;
    justify-content: space-evenly;


    > li {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: rgba(0, 0, 0, 0.6);

      > svg {
        fill: #2B50D8;
        margin-bottom: 16px;
      }
    }

    @media (max-width: 480px) {
      flex-direction: column;
      gap: 24px;
    }
  }

  > button {
    background-color: #2B50D8;
    padding: 12px 8px;
    width: 100%;
    color: #FFFFFF;
    font-weight: 500;
    border-radius: 4px;

    transition: background-color .2s;

    &:hover {
      background-color: #2b43b0;
    }
  }
`;

const ExternalIdInput = styled.input`
  &::placeholder {
    color: #212529 !important;
  }
`;

const InputLabelFilter = styled.label`
    ${({ isDisabled }) => isDisabled && css`
    background-color: #E9ECEF !important;
    cursor: not-allowed;
  `}
`;

const LoadMoreButton = styled.button`
  padding: 16px;
  background-color: ${COLOR_20};
  margin-top: 24px;

  font-size: 16px;
  color: #fff;
  font-weight: 500;
`;

const PriceInput = styled(MaskedBaseInput)``;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 500px;

  ${mqTablet`
    width: 100%;
    max-width: 1000px;
    align-self: center;
  ` }

 select:disabled {
  cursor: not-allowed;
  }
`;

const M2Icon = styled(PhotoSizeSelectSmall)`
  color: #000;
  height: 17px;
  align-self: center;
  margin-right: 5px;
  position: absolute;
  right: 5px;
  top: 16px;
  transform: rotate(-90deg);
`;

const Button = styled(BaseButton).attrs({
  type: 'submit',
})`
  background-color: ${COLOR_6};
  color: ${COLOR_2};
  height: 50px;
  padding: 0 10px;
  width: 100%;
  margin-top: 5px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  border: none;
`;

const ButtonCleanFilter = styled(BaseButton).attrs({
  type: 'button',
})`
  background-color: #83786f;
  color: ${COLOR_2};
  height: 50px;
  padding: 0 10px;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 24px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  border: none;
`;

const ButtonDisabled = styled(BaseButton).attrs({
  type: 'submit',
})`
  background-color: ${COLOR_3};
  color: ${COLOR_2};
  height: 50px;
  padding: 0 10px;
  width: 200px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  border: none;
`;

const BedroomsIcon = styled(M2Icon).attrs({
  as: ArrowBack,
})``;

const options = {
  use: [
    { label: 'Residencial', value: 'Residencial' },
    { label: 'Comercial', value: 'Comercial' },
  ],
  transaction: [
    { label: 'Venda', value: 'Venda' },
    { label: 'Locação', value: 'Locação' },
  ],
  quartos: [
    { label: 'Todos', value: 'Todos' },
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
  ],
  preco: [
    { label: '10.000,00', value: '10.000,00' },
    { label: '10.000.000,00', value: '10.000.000,00' }
  ]
};

export default Reports;
