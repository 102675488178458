
import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import propTypes from 'prop-types';
import { transitions, ellipsis } from 'polished';
import { ArrowSortedDown } from 'styled-icons/typicons';

import { BaseLabel } from '../../../../../styled/labels/labels';
import { CheckboxInput as CheckboxInputComponent, RadioInput } from '../../../../../styled/inputs/inputs';
// import { COLOR_2 } from '../../../../styled/variables/variables';

import { pointer, mqDesktop, iconBaseStyle } from '../../../../../styled/helpers/helpers';
// import { RangeSlider } from '../../../../../styled/components/components';
import { SPECTRUM_TYPE, CHECKBOX_TYPE, RADIO_TYPE } from '../../../../../constants/filters';

// import currencyUtil from '../../../../../utils/currency/currency.util';


const Category = ({
  index,
  label,
  name,
  onCategoryClick,
  onChange,
  options,
  show,
  type,
}) => (
  <Wrapper show={show || type === SPECTRUM_TYPE}>
    {/* <TitleWrapper onClick={ _ => onCategoryClick(index) }> */}
    {options.length > 0 && (
      <TitleWrapper>
        <Title>
          {label}
        </Title>
        {/* { type !== SPECTRUM_TYPE && <RightArrowIcon /> } */}
      </TitleWrapper>
    )}
    <OptionGroup>
      {options.map(option => (
        <OptionWrapper
          key={option.label || label}
          show={show || type === SPECTRUM_TYPE}
          inline={type !== SPECTRUM_TYPE}
        >
          <Fragment>
            {type === CHECKBOX_TYPE && <CheckboxInput
              id={`${name}_${option.name}`}
              onChange={event => onChange(index, option.name, event.target.checked)}
              checked={option.value}
            />}
            <Option
              htmlFor={`${name}_${option.name}`}
              key={option.name}>
              {option.label}
            </Option>
          </Fragment>
        </OptionWrapper>
      ))}
    </OptionGroup>
  </Wrapper>
);

Category.propTypes = {
  label: propTypes.string.isRequired,
  options: propTypes.arrayOf(Object).isRequired,
  // onClick: propTypes.func.isRequired,
  // onOptionClick: propTypes.func.isRequired,

  index: propTypes.number.isRequired,
  name: propTypes.string.isRequired,
  onCategoryClick: propTypes.func.isRequired,
  onChange: propTypes.func.isRequired,
  show: propTypes.bool.isRequired,
  type: propTypes.oneOf([SPECTRUM_TYPE, CHECKBOX_TYPE, RADIO_TYPE]),
};

const OptionGroup = styled.div`
    column-count: 2;
`;

const CheckboxInput = styled(CheckboxInputComponent)`
  display: flex;
  align-items: center;
  justify-content: center;

  &:checked {
    -webkit-appearance: none;
    background-color: #111111 !important;

    &::after {
      display: block;
      content: '';
      width: 5px;
      height: 10px;
      border: solid #FFFFFF;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
`;

const Wrapper = styled.div`
  border-radius: 1px;;
  padding: 0 10px;
  overflow: hidden;
  margin-bottom: 10px;
  margin-top: 16px;
  ${pointer};
  max-height: 30px;
  ${transitions(['max-height'], 'ease .3s')};

  ${({ show }) => show && css`
    max-height: 1000px;
  ` };

    margin-bottom: 0;
    margin-bottom: 5px;
    padding-left: 0;
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  ${pointer};
  height: 30px;
  margin-bottom: 5px;
`;

const Title = styled(BaseLabel)`
  line-height: 40px;
  /* text-transform: capitalize; */
  ${ellipsis('100%')}
  color: #2b2b2b;
  font-size: 14px;
  font-weight: 500;
  align-self: center;
  ${mqDesktop`
    line-height: 30px;
  ` };
`;

const RightArrowIcon = styled(ArrowSortedDown)`
  ${iconBaseStyle};
  color: #2b2b2b;
  align-self: center;
`;

const OptionWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  ${({ inline }) => !inline && 'flex-direction: column;'}
  margin-bottom: 10px;
  padding-left: 10px;
  opacity: 0;
  ${transitions(['opacity', 'ease .3s'])};

  ${({ show }) => show && css`
    opacity: 1;
  ` };
`;

const Option = styled(BaseLabel)`
  ${ellipsis('100%')}
  color: #111111;
  font-size: 14px;
  align-self: center;
`;

const SliderValue = styled(BaseLabel)`
  color: #111111;
  align-self: center;
`;

export default Category;
