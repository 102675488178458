import React, { useState, useMemo } from "react";
import { Link, withRouter } from "react-router-dom";
import styled, { css } from "styled-components";
import LazyLoad from 'react-lazyload';
import { toast } from 'react-toastify';
import { Modal, Button } from 'react-bootstrap'
import { RiLinksLine } from "react-icons/ri";

import { Features } from '../../../search-detail/features'
import { BaseLabel } from "../../../../styled/labels/labels";
import { BaseInput } from '../../../../styled/inputs/inputs';
import { SmallButton } from "../../../../styled/buttons/buttons";
import { backgrounder, mqTablet } from "../../../../styled/helpers/helpers";
import { COLOR_BORDER, COLOR_16 } from "../../../../styled/variables/variables";

import EditButtons from "./buttons/EditButtons";
import ViewAdLink from "./buttons/ViewAdLink";
import StatsBar from "./stats-bar/StatsBar";
import Checkbox from '../../../../components/checkbox';
import { AttachBrokerButton } from "./buttons/AttachBrokerButton";
import { IntegrationRealEstateButton } from "./buttons/IntegrationRealEstateButton";

import pathConstants from "../../../../constants/paths";
import queryStringUtil from "../../../../utils/query-string/query-string.util";
import { TYPES as IMAGE_TYPES } from "./../../../../models/image/image.model";

import currencyUtil from '../../../../utils/currency/currency.util'

/*
  global
  process
  window
*/
const customId = "custom-id-yes";

const Card = ({
  realEstate,
  user,
  onReportUpdate,
  history,
  hasPending,
  onAttachPropertyToBroker,
  integrationPortal,
  hasIntegrationPortal,
  onAddRealEstateOnPortal,
  onRemoveRealEstateOnPortal,
}) => {
  const openReport = reportId => {
    const href = history.createHref({
      pathname: pathConstants.REPORT,
      search: queryStringUtil.stringify(null, {
        reportId
      })
    });

    history.push(href);
  };

  const [state, setState] = useState({
    modalVisible: false,
    name: 'vitor',
    checked: false,
  });
  var pictures = realEstate.ads.imagesSerialized.filter(
    ({ type }) => type === IMAGE_TYPES.PICTURE
  );

  const handleFormChange = event => {
    const { name, value, type, checked, id } = event.target;
    setState(prev => ({
      ...prev,
      form: {
        ...prev.form,
        [name]: type === 'checkbox' ? checked : value,
      },
    }));
  };

  const handleFormSubmit = async event => {
    event.preventDefault();
    try {
      // await userProfileService.update({
      //   userId: state.user.id,
      //   token: state.user.token,
      //   data: {
      //     ...state.form,
      //     phoneNumber: phoneNumber,
      //     cellphoneNumber: cellphoneNumber
      //   },
      // });
      toast.info('Perfil atualizado!', {
        autoClose: false,
        toastId: customId,
      });
    } catch (error) {
      console.error(error);
      return;
    }
  };

  const handleModal = () => {
    setState(() => ({
      modalVisible: !state.modalVisible
    }));
  }

  const renderCheckBoxNear = (quantity) => {
    var indents = [];
    let i;
    for (i = 1; i <= quantity; i++) {
      indents.push(
        <div key={i} style={{ 'display': 'flex', flexDirection: 'row', marginTop: 10 }}>
          <LabelBox>Proximidade{i}? </LabelBox>
          <CheckboxReport
            name='CheckboxReport'
            checked={state.checked}
          />
          <LabelBox>Proximidade{i + 1}? </LabelBox>
          <CheckboxReport
            name='CheckboxReport'
            checked={state.checked}
          />
          <LabelBox>Proximidade{i + 2}? </LabelBox>
          <CheckboxReport
            name='CheckboxReport'
            checked={state.checked}
          />
          <LabelBox>Proximidade{i + 3}? </LabelBox>
          <CheckboxReport
            name='CheckboxReport'
            checked={state.checked}
          />
        </div>
      )
      i = i + 3;
    }
    return indents
  }

  const linkedBrokerName = useMemo(() => {
    if (!!realEstate.isLinked) {
      if (!realEstate.user || !realEstate.user.name) {
        return '';
      }

      const name = realEstate.user.name.split(' ');

      if (name.length === 1) {
        return name[0].toUpperCase();
      }

      const nameFormatted = `${name[0]} ${name[1].charAt(0)}.`;

      return nameFormatted.toUpperCase();
    } else {
      return '';
    }
  }, [realEstate]);

  return (
    <Wrapper>
      <PictureWrapper>
        <LazyLoad once>
          <Picture
            url={
              pictures.length > 0 ? pictures.map(({ url }) => url)[0] : `${process.env.PUBLIC_URL}/imgs/ad-example.png`
            }
          />
        </LazyLoad>
        <StatsBar
          views={!!realEstate.ads.accessStatistics ? realEstate.ads.accessStatistics.views.total : 0}
          messages={!!realEstate.ads.accessStatistics ? realEstate.ads.accessStatistics.messages.total : 0}
          favorites={!!realEstate.ads.accessStatistics ? realEstate.ads.accessStatistics.favorites.total : 0}
          shares={!!realEstate.ads.accessStatistics ? realEstate.ads.accessStatistics.shares.total : 0}
        />
      </PictureWrapper>
      <TabletRightSideWrapper>
        <InfoWrapper >
          <PropertyLabel>
            {!!realEstate.isLinked && (
              <TagBadge brokerAttached>
                <div>
                  <RiLinksLine /> {linkedBrokerName}
                </div>
              </TagBadge>
            )}

            <TagBadge>
              {realEstate.ads.types.transaction.toUpperCase()}
            </TagBadge>

            <TagBadge>
              {realEstate.realEstate.types.property.toUpperCase()}
            </TagBadge>

            {realEstate.isPublished && <TagBadge>ANUNCIADO</TagBadge>}
          </PropertyLabel>
          <Title>
            {`${currencyUtil.format({ value: realEstate.ads.prices.main, })}`}
          </Title>
          <Neighborhood>
            {realEstate.realEstate.address.city} - {realEstate.realEstate.address.neighborhood}
          </Neighborhood>
          <FirstSectionDivider />
          <Features
            bedrooms={realEstate.realEstate.bedrooms}
            area={realEstate.realEstate.area}
            parkingSpaces={realEstate.realEstate.parkingSpaces}
            bathrooms={realEstate.realEstate.bathrooms}
            petAcceptance={realEstate.realEstate.features.condo.includes('Aceita Pet')}
          />
          <SecondSectionDivider />

          {!window.location.href.includes('/dashboard-brokers/attach-property') && (
            <Link to={{
              pathname: hasPending ? pathConstants.DASHBOARDADS_PENDENCIES : pathConstants.DASHBOARDADS_MESSAGES,
              state: { realEstate },
              search: queryStringUtil.stringify(null, {
                realEstateId: realEstate._id,
              })
            }}>
              <EditButtons />
            </Link>
          )}

          {/* ####### INÍCIO - ESCOPO RESPONSÁVEL PELA INTEGRAÇÃO COM XML ####### */}

          {!!integrationPortal && !window.location.href.includes('/dashboard-brokers/attach-property') && (
            <IntegrationRealEstateButton
              hasIntegrationPortal={hasIntegrationPortal}
              onAddRealEstateOnPortal={onAddRealEstateOnPortal}
              onRemoveRealEstateOnPortal={onRemoveRealEstateOnPortal}
            />
          )}

          {/* ####### FIM - ESCOPO RESPONSÁVEL PELA INTEGRAÇÃO COM XML ####### */}

          {window.location.href.includes('/dashboard-brokers/attach-property') && (
            <AttachBrokerButton onAttachPropertyToBroker={onAttachPropertyToBroker} />
          )}

          <ViewAdLink to={`${pathConstants.AD_DETAIL}/${realEstate.slug}?inDashboard=true`} />
        </InfoWrapper>
        {state.modalVisible &&
          <div style={{ justifyContent: 'center', alignItems: 'center' }}>
            <Modal
              show={state.modalVisible}
              onHide={() => { handleModal() }}
              dialogClassName="modal-90w"
              centered
              scrollable
              style={{
                alignSelf: 'center',
                flex: 1,
                position: 'absolute',
                top: 500,
                left: '12.5%',
                marginTop: 200,
                backgroundColor: 'white',
                width: '75%',
              }}
              aria-labelledby="styling-title"
            >
              <Modal.Dialog scrollable>
                <Modal.Header style={{ alignItems: 'center', justifyContent: 'center', marginTop: 10 }}>
                  <Modal.Title style={{ alignSelf: 'center', textAlign: 'center', fontSize: 22, color: 'black', fontWeight: 'bold' }}>
                    Modal title
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto', marginTop: 20 }}>
                  <p></p>
                  <Form
                    onChange={handleFormChange}
                    onSubmit={handleFormSubmit}
                    style={{ marginLeft: 10 }}
                  >
                    <NameInput
                      name='name'
                      value={state.name}
                    />
                    <DescriptionInput
                      name='description'
                      value={state.description}
                    />
                    <TitleInput
                      name='title'
                      value={state.title}
                    />
                    <SubTitleInput
                      name='subtitle'
                      value={state.subtitle}
                    />
                    <h4>Proximidades</h4>
                    <div>
                      {renderCheckBoxNear(12)}
                    </div>
                    <h4>Endereço</h4>
                  </Form>
                </Modal.Body>
                <Modal.Footer style={{ marginTop: 20, 'display': 'flex', justifyContent: 'center', paddingBottom: 20 }}>
                  <SmallButton onClick={() => { handleModal() }} >Fechar</SmallButton>
                  <SmallButton>Enviar</SmallButton>
                </Modal.Footer>
              </Modal.Dialog>
            </Modal>
          </div>
        }
      </TabletRightSideWrapper>
    </Wrapper>
  );
};

const TagBadge = styled.div`
  float: left;
  padding: 5px 10px 5px 10px;
  background: #0d6efd;
  color: #fff;
  text-align: center;
  margin-right: 10px;
  font-weight: bold;
  font-size: 11px;

  ${({ brokerAttached }) => brokerAttached && css`
    background: #8ACC2C;

    > div {
      display: flex;
      align-items: center;

      > svg {
        margin-right: 4px;
        font-size: 14px;
      }
    }
  `}
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${mqTablet`
    flex-direction: row;
  `}
`;

const Form = styled.form`

`;

const PictureWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  ${mqTablet`
    width: 300px;
    margin-right: 20px;
  `}
`;

const NameInput = styled(BaseInput).attrs({
  placeholder: 'Nome completo'
})`
  max-width: 200px;
`;

const DescriptionInput = styled(BaseInput).attrs({
  placeholder: 'Descrição'
})`
  max-width: 200px;
`;

const TitleInput = styled(BaseInput).attrs({
  placeholder: 'Título'
})`
  max-width: 200px;
`;

const SubTitleInput = styled(BaseInput).attrs({
  placeholder: 'SubTítulo'
})`
  max-width: 200px;
`;

const CheckboxReport = styled(Checkbox)`
  margin-bottom: 10px;
  max-width: 100px;
  margin-left: 10px;
`;

const Picture = styled.div`
  ${({ url }) => backgrounder(url)}
  border-radius: 1px;
  width: 100%;
  height: 300px;
  border: 1px solid ${COLOR_16};
  margin-bottom: 5px;

  ${mqTablet`
    height: 200px;
  `}
`;

const TabletRightSideWrapper = styled.div`
  ${mqTablet`
    flex-grow: 1;
    width: 1px;
  `}
`;

const LabelBox = styled.p`
  margin-left:5px;
  margin-right:5px;
`;
const InfoWrapper = styled.div`
  padding: 20px 10px;
  height: 235px;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  position: relative;
`;

const PropertyLabel = styled(BaseLabel)`
  font-size: 12px;
  margin-bottom: 10px;
`;

const Title = styled(PropertyLabel)`
  font-weight: 700;
  font-size: 24px;
`;

const Neighborhood = styled(PropertyLabel)`
  font-size: 14px;
  margin: 0;
`;

const FirstSectionDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${COLOR_BORDER};
  margin: 15px 0;
`;

const SecondSectionDivider = styled(FirstSectionDivider)``;

export default withRouter(Card);
