import httpClient from "../../clients/koort-business/http/http.client";

const getUrl = async ({ token }) => {
  const path = '/integrators';

  const response = await httpClient.get({ path, token });

  return response;
};

const sendUrl = async ({
  token,
  url,
  toForceIntegration = false,
}) => {
  const path = '/integrators/ads-integration/urls';

  const params = {
    url,
    toForceIntegration,
  };

  const response = await httpClient.post({ path, params, token });

  return response;
};

const removeUrl = async ({
  token,
}) => {
  const path = '/integrators/ads-integration/urls';

  const response = await httpClient.del({ path, token });

  return response;
};

const getAdLogs = async ({
  token,
  page,
  tag,
  period,
  logPerPage
}) => {
  const path = `/integration-logs?tag=${tag === 'todas' ? '' : tag}&days=${period === 'todas' ? '' : period}&size=${logPerPage}&page=${page}`

  const response = await httpClient.get({ path, token });

  return response;
};

export default { getUrl, sendUrl, removeUrl, getAdLogs };
