import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import { ellipsis } from 'polished';

import { pointer, noTextSelect, mqCustom } from '../../../styled/helpers/helpers';
import { BaseLabel } from '../../../styled/labels/labels';
import { COLOR_2 } from '../../../styled/variables/variables';

const UserGreetings = ({ name, showSearchBar }) => {
  return (
    <Wrapper >
      {
        name ? (
          <Label>{ `Olá, ${ name }` }</Label>
        ) : (
          <Label>Seja bem-vindo!</Label>
        ) }
    </Wrapper>
  );
};

UserGreetings.propTypes = {
  onClick: propTypes.func,
  name: propTypes.string,
};

const Wrapper = styled.div`
  display: none;

  ${ mqCustom(1240)`
    padding: 20px;
    justify-content: space-between;
    ${ pointer };
    ${ noTextSelect }
    display: flex;
  ` }
`;

const Label = styled(BaseLabel)`
${ ellipsis('100%') }
  color: ${ COLOR_2 };
  font-size: 14px;
  font-weight: 500;
`;

export default UserGreetings;
