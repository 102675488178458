import React, { useState, useCallback, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import Modal from 'react-modal';
import Cropper from 'react-easy-crop';
import { toast } from 'react-toastify';
import { FiPlus } from 'react-icons/fi';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FiAlertCircle, FiX } from 'react-icons/fi';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ReactTagInput from "@pathofdev/react-tag-input";
import 'react-image-crop/dist/ReactCrop.css';
import "@pathofdev/react-tag-input/build/index.css";
import { useAuth0 } from '@auth0/auth0-react';

import '../config-account.css';

import { profileFormSchema, profileForUserOwnerFormSchema } from './schema';

import userService from '../../../services/user/user.service';
import userProfileService from '../../../services/user-profile/user-profile.service';

import { InputBootstrap } from '../../../components/form/input-bootstrap';
import { CellphoneInput } from '../../../components/form/cellphone-input';
import { PhoneInputBootstrap } from '../../../components/form/phone-input-bootstrap';
import { CepInput } from '../../../components/form/cep-input';
import { TextArea } from '../../../components/form/textarea';

import getCroppedImg from '../../../utils/image/cropImage.util';
import domEventsUtil from '../../../utils/dom-events/dom-events.util';
import { addMaskCellphone, addMaskTelephone } from '../../../utils/add-masks';
import eventsConstants from '../../../constants/events';
import pathsConstants from '../../../constants/paths';

import './profile.css';

import {
  Wrapper,
  Form,
  ProfilePhotoWrapper,
  SectionTitle,
  ProfilePicture,
  DeleteUserDataContent,
  CropImageContentOverlay,
  CropImageCard,
  CropImageCardHeader,
  CropImageCardHeaderTitleWrapper,
  CropImageCardWrapper,
  CropImageCardContent,
  CropImageCardFooter,
  CheckboxInputWrapper,
  WhatsappCheckboxInput,
  OccupationAreaContainer,
  OccupationAreaTitle,
  OccupationAreaCount,
  OccupationAreaScroll,
  OccupationAreaEmpty,
  OccupationAreaFields,
  OccupationAreaActionButtons,
  OccupationAreaButtonConfirmRemove,
  OccupationAreaButtonCancelRemove,
  RowButton,
  ButtonWrapper,
  ViewButton,
  SaveButton,
  ModalContent,
  ModalBody,
  DeleteUserDataButtonsGroup,
  LoadingModal,
  CountTextArea,
} from './styles';

const customId = "custom-id-yes";

export const Profile = ({
  history
}) => {
  const [profileImageCrop, setProfileImageCrop] = useState({ x: 0, y: 0 })
  const [profileImageZoom, setProfileImageZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [profileImg, setProfileImg] = useState(null);
  const [profileImgName, setProfileImgName] = useState(null);
  const [profileImgInFile, setProfileImgInFile] = useState(null);
  const [profileImgCropped, setProfileImgCropped] = useState(null);

  const [specialtiesTags, setSpecialtiesTags] = useState([]);
  const [occupationArea, setOccupationArea] = useState([]);
  const [removeOccupationArea, setRemoveOccupationArea] = useState(null);

  const [name, setName] = useState('');
  const [hasWhatsapp, setHasWhatsapp] = useState(false);
  const [pictureUrl, setPictureUrl] = useState('');
  const [loaded, setLoaded] = useState(false);

  const [userLocal, setUserLocal] = useState(userService.retrieve());
  const [deleteUserDataModalVisible, setDeleteUserDataModalVisible] = useState(false);
  const [deleteUserDataLoading, setDeleteUserDataLoading] = useState(false);

  const [countTextArea, setCountTextArea] = useState(0);

  const { register, handleSubmit, formState, control, setValue, watch } = useForm({
    resolver: yupResolver(userLocal && userLocal.userType === 2 ?
      profileForUserOwnerFormSchema : profileFormSchema),
  });

  const { logout } = useAuth0();

  const { errors } = formState;

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setProfileImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
      setProfileImgName(e.target.files[0].name)
    }
  };

  const loadInfo = async () => {
    try {
      const user = userService.retrieve();

      const response = await userProfileService.getUserCompleteProfile({
        token: user.token,
        userId: user._id,
      });

      setLoaded(true);
      setPictureUrl(response.profile.image);

      if (response.userType === 2) {
        setName(response.name);
        setValue('name', response.profile.screenName);

        if (response.profile.professionalInformation.telephone.number) {
          setValue('telephone', addMaskTelephone(response.profile.professionalInformation.telephone.number));
        }

        if (response.profile.professionalInformation.cellphone.number) {
          setValue('cellphone', addMaskCellphone(response.profile.professionalInformation.cellphone.number));
        }
      } else {
        const occupationAreaResponse = response.profile.professionalInformation.serviceAreas;

        setSpecialtiesTags(response.profile.specialties);
        setOccupationArea(occupationAreaResponse);
        setHasWhatsapp(response.profile.professionalInformation.cellphone.whatsApp);
        setName(response.name);

        setValue('name', response.profile.screenName);

        if (response.profile.professionalInformation.telephone.number) {
          setValue('telephone', addMaskTelephone(response.profile.professionalInformation.telephone.number));
        }

        if (response.profile.professionalInformation.cellphone.number) {
          setValue('cellphone', addMaskCellphone(response.profile.professionalInformation.cellphone.number));
        }

        setValue('aboutMe', response.profile.aboutMe);
        setValue('creci', response.profile.professionalInformation.creci);
        setValue('role', response.profile.role);
        setValue('email', response.email);
        setValue('facebook', response.profile.professionalInformation.facebook);
        setValue('instagram', response.profile.professionalInformation.instagram);
        setValue('linkedIn', response.profile.professionalInformation.linkedIn);
        setValue('zipCode', response.profile.professionalInformation.address.zip);
        setValue('city', response.profile.professionalInformation.address.city);
        setValue('number', response.profile.professionalInformation.address.number);
        setValue('complement', response.profile.professionalInformation.address.complement);
        setValue('street', response.profile.professionalInformation.address.street);
      }
    } catch (err) {
      toast.error('Houve um erro ao carregar os dados do seu perfil!', {
        autoClose: 5000,
        toastId: customId,
      });
      console.error("Erro: ", err)
    }
  };

  useEffect(() => {
    loadInfo();
  }, []);

  useEffect(() => {
    const subscription = watch((data) => {
      if (data.aboutMe) {
        setCountTextArea(data.aboutMe.length)
      }
    });

    return () => subscription.unsubscribe();
  }, [watch])

  const uploadProfileImage = async () => {
    try {
      const user = userService.retrieve();

      await userProfileService.uploadProfileImage({
        picture: profileImgInFile,
        token: user.token
      })
    } catch (error) {
      toast.error('Erro enviando imagem de perfil.', {
        autoClose: 5000,
        toastId: customId,
      });
    }
  }

  const onCompleteProfileImageCrop = useCallback((_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, []);

  const handleCloseCropImage = () => {
    setProfileImg(null);
    setProfileImageZoom(1);
    setProfileImageCrop({ x: 0, y: 0 });
  }

  const showCroppedImage = useCallback(async () => {
    try {
      const { imageInBase64, imageInBlob } = await getCroppedImg(
        profileImg,
        croppedAreaPixels,
        0
      );

      const urlImageInBlob = await imageInBlob;

      fetch(imageInBase64)
        .then(res => res.blob())
        .then(blob => {
          const file = new File([blob], profileImgName, { type: 'image/png' })
          setProfileImgInFile(file)
        });

      setProfileImgCropped(urlImageInBlob)
      handleCloseCropImage();
    } catch (e) {
      handleCloseCropImage();
      toast.error('Erro ao ajustar imagem, tente novamente!', {
        autoClose: 5000,
        toastId: customId,
      })
    }
  }, [croppedAreaPixels]);

  const handleAddNewOccupationArea = () => {
    setOccupationArea([
      {
        city: '',
        neighborhoods: []
      },
      ...occupationArea,
    ]);
  }

  const handleChangeCityInputOccupationArea = (position, value) => {
    const updatedOccupationArea = occupationArea.map((item, index) => {
      if (position === index) {
        return { ...item, city: value };
      }

      return item;
    });

    setOccupationArea(updatedOccupationArea);
  }

  const handleChangeNeighborhoodsOccupationArea = (tags, position) => {
    const updatedOccupationArea = occupationArea.map((item, index) => {
      if (position === index) {
        return { ...item, neighborhoods: tags };
      }

      return item;
    });

    setOccupationArea(updatedOccupationArea);
  }

  const handleRemoveOccupationArea = (position) => {
    const updatedOccupationArea = occupationArea.filter((_, index) =>
      index !== position);

    setRemoveOccupationArea(null);
    setOccupationArea(updatedOccupationArea);
  }

  const handleUpdateProfileForm = useCallback(async values => {
    try {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: true },
      });

      if (profileImgInFile !== null) {
        await uploadProfileImage();
      }

      const occupationAreaSerialized = occupationArea.filter(item =>
        item.city !== '');

      const formData = {
        ...values,
        zipCode: values.zipCode ? values.zipCode.replace('-', '') : '',
        cellphoneNumber: {
          number: values.cellphone.replace(/[^a-zA-Z0-9]+/g, '').substring(2),
          whatsApp: hasWhatsapp,
        },
        telephone: {
          number: values.telephone ? values.telephone.replace(/[^a-zA-Z0-9]+/g, '').substring(2) : '',
        },
        serviceAreas: occupationAreaSerialized,
        specialties: specialtiesTags
      };

      const user = userService.retrieve();

      await userProfileService.update({
        userId: user._id,
        token: user.token,
        data: formData,
      });

      const userProfileData = await userService.getUserProfile({
        token: user.token,
      });

      const userData = {
        ...userProfileData,
        token: user.token,
      }

      localStorage.setItem("user", JSON.stringify(userData));

      toast.info('Perfil atualizado!', {
        autoClose: 5000,
        toastId: customId,
      });
      await history.push(pathsConstants.PROFILE);
    } catch (error) {
      toast.error('Houve um erro ao atualizar o seu perfil, tente novamente!', {
        autoClose: 5000,
        toastId: customId,
      });
      console.log(error)
    } finally {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: false },
      });
    }
  }, [occupationArea, profileImgInFile, specialtiesTags, hasWhatsapp]);

  const handleDeleteUserData = () => {
    const userStored = userService.retrieve();

    setDeleteUserDataLoading(true);

    userService.deleteUserData({ token: userStored.token })
      .then(response => {
        if (response.status !== 204) {
          throw 'Ocorreu um erro ao excluir seus dados, tente novamente!';
        }

        domEventsUtil.dispatch({
          name: eventsConstants.SIGN_OUT,
        });

        logout({ logoutParams: { returnTo: `${window.location.origin}/?userDeleteAccount=true` }});
      }).catch(err => {
        toast.error(err, { autoClose: 5000 });

        setDeleteUserDataLoading(false);
      });
  }

  return (
    <>
      <Container fluid="md" className={"container_fluid"}>
        <Wrapper>
          {loaded &&
            <Form
              onSubmit={handleSubmit(handleUpdateProfileForm)}
            >
              <ProfilePhotoWrapper>
                <SectionTitle>
                  Foto de perfil
                </SectionTitle>
                <ProfilePicture
                  src={profileImgCropped ? profileImgCropped : `${pictureUrl}?${Math.random()}`}
                  alt={name}
                />
                <div>
                  <input
                    style={{ width: '100%', visibility: 'hidden' }}
                    type="file"
                    id={"input-file-profile"}
                    accept="image/*"
                    onClick={event => event.target.value = ''}
                    onChange={onSelectFile} />
                  <label
                    htmlFor={'input-file-profile'}
                    className={'input-file-profile'}
                    style={{ cursor: 'pointer' }}
                  >
                    Clique aqui para <b>adicionar/trocar sua foto</b>
                  </label>
                </div>
              </ProfilePhotoWrapper>

              {profileImg &&
                <CropImageContentOverlay>
                  <CropImageCard>
                    <CropImageCardHeader>
                      <CropImageCardHeaderTitleWrapper>
                        <h3>Corte sua nova foto de perfil</h3>
                        <span>
                          <FiAlertCircle size="16" />
                          Arraste ou dê zoom para ajustar a imagem
                        </span>
                      </CropImageCardHeaderTitleWrapper>

                      <button type="button" onClick={handleCloseCropImage}>
                        <FiX size="24" />
                      </button>
                    </CropImageCardHeader>

                    <CropImageCardWrapper>
                      <CropImageCardContent>
                        <Cropper
                          image={profileImg}
                          crop={profileImageCrop}
                          zoom={profileImageZoom}
                          aspect={3 / 4}
                          onCropChange={setProfileImageCrop}
                          onCropComplete={onCompleteProfileImageCrop}
                          onZoomChange={setProfileImageZoom}
                        />
                      </CropImageCardContent>
                    </CropImageCardWrapper>

                    <CropImageCardFooter>
                      <button
                        type="button"
                        onClick={showCroppedImage}>
                        Visualizar
                      </button>
                    </CropImageCardFooter>
                  </CropImageCard>

                </CropImageContentOverlay>
              }

              <SectionTitle style={{ marginTop: 40 }}>
                Dados pessoais
              </SectionTitle>

              <div className="mb-3">
                <InputBootstrap
                  name="name"
                  label="Nome"
                  placeholder="Nome"
                  error={errors.name}
                  {...register('name')}
                />
              </div>

              <div className="mb-3">
                <PhoneInputBootstrap
                  name="telephone"
                  label="Telefone"
                  error={errors.telephone}
                  control={control}
                  {...register('telephone')}
                />
              </div>

              <CellphoneInput
                name="cellphone"
                label="Celular"
                error={errors.cellphone}
                control={control}
                {...register('cellphone')}
              />

              <CheckboxInputWrapper
                label='Este número é Whatsapp'
                htmlFor='checkBoxWhatsapp'
              >
                <WhatsappCheckboxInput
                  id='checkBoxWhatsapp'
                  name='checkBoxWhatsapp'
                  checked={hasWhatsapp}
                  onClick={() => setHasWhatsapp(prevState => !prevState)}
                />
              </CheckboxInputWrapper>

              {userLocal && userLocal.userType !== 2 && (
                <>
                  <TextArea
                    name="aboutMe"
                    label="Sobre Meu Trabalho"
                    error={errors.aboutMe}
                    maxLength={1000}
                    {...register('aboutMe')}
                  />
                  <CountTextArea>{countTextArea}/1000</CountTextArea>

                  <div className="my-3">
                    <label
                      className="mb-2"
                      style={{
                        opacity: 0.65,
                        color: '#000000',
                      }}
                    >
                      Especialidades
                    </label>

                    <ReactTagInput
                      maxTags={10}
                      tags={specialtiesTags}
                      removeOnBackspace
                      editable
                      placeholder="Digite e pressione enter"
                      onChange={(tags) => {
                        setSpecialtiesTags(tags)
                      }}
                    />
                  </div>

                  <div className="mb-3">
                    <OccupationAreaContainer>
                      <OccupationAreaTitle>
                        <label
                          style={{
                            opacity: 0.65,
                            color: '#000000',
                          }}
                        >
                          Áreas de atuação

                          {occupationArea.length > 0 &&
                            <OccupationAreaCount>
                              {occupationArea.length}
                            </OccupationAreaCount>
                          }
                        </label>

                        <button
                          type="button"
                          onClick={handleAddNewOccupationArea}
                          disabled={occupationArea.some(item => item.city === '')}
                        >
                          <FiPlus size="16" />
                          novo
                        </button>
                      </OccupationAreaTitle>

                      <OccupationAreaScroll>
                        {!occupationArea.length ?
                          <OccupationAreaEmpty>
                            <span>
                              Clique no botão '+ novo' e adicione sua área de atuação
                            </span>
                          </OccupationAreaEmpty>
                          :
                          occupationArea.map((occupationItem, index) => (
                            <OccupationAreaFields key={index}>
                              <div className="form-floating mt-2">
                                <input
                                  style={{
                                    textTransform: 'capitalize',
                                  }}
                                  placeholder="Cidade"
                                  value={occupationItem.city}
                                  type="text"
                                  className="form-control"
                                  id="occupation_area_city"
                                  name="occupation_area_city"
                                  aria-describedby="occupation_area_city"
                                  onChange={(event) => {
                                    handleChangeCityInputOccupationArea(index, event.target.value)
                                  }}
                                ></input>
                                <label
                                  htmlFor="occupation_area_city"
                                  className="form-label"
                                >
                                  Cidade
                                </label>
                              </div>

                              <div className="mt-2">
                                <ReactTagInput
                                  tags={occupationItem.neighborhoods}
                                  removeOnBackspace
                                  editable
                                  placeholder="Digite um bairro e pressione enter"
                                  onChange={
                                    (tag) => handleChangeNeighborhoodsOccupationArea(tag, index)
                                  }
                                />
                              </div>

                              <span>
                                <button
                                  type="button"
                                  disabled={removeOccupationArea === index}
                                  onClick={() => setRemoveOccupationArea(index)}>
                                  Excluir
                                </button>
                              </span>
                              {removeOccupationArea === index &&
                                <OccupationAreaActionButtons>
                                  <OccupationAreaButtonConfirmRemove
                                    type="button"
                                    onClick={() => handleRemoveOccupationArea(index)}
                                  >
                                    Confirmar
                                  </OccupationAreaButtonConfirmRemove>

                                  <OccupationAreaButtonCancelRemove
                                    type="button"
                                    onClick={() => setRemoveOccupationArea(null)}
                                  >
                                    Cancelar
                                  </OccupationAreaButtonCancelRemove>
                                </OccupationAreaActionButtons>
                              }
                            </OccupationAreaFields>
                          ))
                        }
                      </OccupationAreaScroll>
                    </OccupationAreaContainer>
                  </div>

                  <SectionTitle style={{ marginTop: 40 }}>
                    Informações Profissionais
                  </SectionTitle>
                  <div className="mb-3">
                    <InputBootstrap
                      name="creci"
                      label="Creci"
                      placeholder="Creci"
                      error={errors.creci}
                      {...register('creci')}
                    />
                  </div>

                  <div className="mb-3">
                    <InputBootstrap
                      name="role"
                      label="Profissão"
                      placeholder="Profissão"
                      error={errors.role}
                      {...register('role')}
                    />
                  </div>

                  <div className="mb-3">
                    <InputBootstrap
                      name="email"
                      label="E-mail"
                      placeholder="E-mail"
                      disabled
                      error={errors.email}
                      {...register('email')}
                    />
                  </div>

                  <div className="mb-3">
                    <InputBootstrap
                      name="facebook"
                      label="Facebook"
                      placeholder="Facebook"
                      error={errors.facebook}
                      {...register('facebook')}
                    />
                  </div>

                  <div className="mb-3">
                    <InputBootstrap
                      name="instagram"
                      label="Instagram"
                      placeholder="Instagram"
                      error={errors.instagram}
                      {...register('instagram')}
                    />
                  </div>

                  <div className="mb-3">
                    <InputBootstrap
                      name="linkedIn"
                      label="LinkedIn"
                      placeholder="LinkedIn"
                      error={errors.linkedIn}
                      {...register('linkedIn')}
                    />
                  </div>
                  <SectionTitle style={{ marginTop: 40 }}>
                    Endereço Comercial
                  </SectionTitle>

                  <div className="mb-3">
                    <CepInput
                      name="zipCode"
                      label="Cep"
                      error={errors.zipCode}
                      control={control}
                      {...register('zipCode')}
                    />
                  </div>

                  <div className="mb-3">
                    <InputBootstrap
                      name="city"
                      label="Cidade"
                      placeholder="Cidade"
                      error={errors.city}
                      {...register('city')}
                    />
                  </div>

                  <div className="form-floating mb-3">
                    <div className="row g-2">
                      <div className="col-md">
                        <InputBootstrap
                          name="number"
                          label="Número"
                          placeholder="Número"
                          type="number"
                          error={errors.number}
                          {...register('number')}
                        />
                      </div>
                      <div className="col-md">
                        <InputBootstrap
                          name="complement"
                          label="Complemento"
                          placeholder="Complemento"
                          error={errors.complement}
                          {...register('complement')}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="mb-3">
                    <InputBootstrap
                      name="street"
                      label="Logradouro"
                      placeholder="Logradouro"
                      error={errors.street}
                      {...register('street')}
                    />
                  </div>
                </>
              )}

              <RowButton>
                <ButtonWrapper>
                  <ViewButton onClick={history.goBack}>
                    voltar
                  </ViewButton>

                  <SaveButton>
                    salvar
                  </SaveButton>
                </ButtonWrapper>
              </RowButton>
            </Form>
          }

          {loaded && (
            <DeleteUserDataContent>
              <button
                type="button"
                onClick={() => setDeleteUserDataModalVisible(true)}
              >
                Excluir meus dados
              </button>
            </DeleteUserDataContent>
          )}
        </Wrapper>
      </Container>

      <Modal
        isOpen={deleteUserDataModalVisible}
        style={{
          content: {
            backgroundColor: 'transparent',
            border: 0,
            padding: 0,
            inset: 0,
            borderRadius: 0,
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)'
          }
        }}
      >
        <ModalContent>
          <ModalBody>
            <span>Lembre-se:</span>
            <p>Ao prosseguir com a ação, todos os seus dados serão excluídos da plataforma, e com isso você também perderá o acesso a sua conta.</p>

            <label>Deseja prosseguir com a ação?</label>

            <DeleteUserDataButtonsGroup>
              <button
                type="button"
                onClick={() => setDeleteUserDataModalVisible(false)}
              >
                Não
              </button>

              <button
                type="button"
                onClick={handleDeleteUserData}
              >
                Sim, desejo prosseguir
              </button>
            </DeleteUserDataButtonsGroup>

            {deleteUserDataLoading && (
              <LoadingModal>
                <h3>Aguarde um momento enquanto excluímos seus dados</h3>
                <CircularProgress color="inherit" size={32} />
              </LoadingModal>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
