import React, { useState, useCallback, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Cropper from 'react-easy-crop';
import { toast } from 'react-toastify';
import { FiPlus } from 'react-icons/fi';
import { FiAlertCircle, FiX } from 'react-icons/fi';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import ReactTagInput from "@pathofdev/react-tag-input";
import 'react-image-crop/dist/ReactCrop.css';
import "@pathofdev/react-tag-input/build/index.css";

import userService from '../../services/user/user.service';
import userProfileService from '../../services/user-profile/user-profile.service';

import { InputBootstrap } from '../../components/form/input-bootstrap';
import { CellphoneInput } from '../../components/form/cellphone-input';
import { PhoneInputBootstrap } from '../../components/form/phone-input-bootstrap';
import { CepInput } from '../../components/form/cep-input';
import { TextArea } from '../../components/form/textarea';

import getCroppedImg from '../../utils/image/cropImage.util';
import domEventsUtil from '../../utils/dom-events/dom-events.util';
import eventsConstants from '../../constants/events';
import pathsConstants from '../../constants/paths';

import './profile-edition.css';

import {
  Wrapper,
  Form,
  SectionTitle,
  ProfilePicture,
  ExcluirUsuario,
  CropImageContentOverlay,
  CropImageCard,
  CropImageCardHeader,
  CropImageCardHeaderTitleWrapper,
  CropImageCardWrapper,
  CropImageCardContent,
  CropImageCardFooter,
  CheckboxInputWrapper,
  WhatsappCheckboxInput,
  OccupationAreaContainer,
  OccupationAreaTitle,
  OccupationAreaCount,
  OccupationAreaScroll,
  OccupationAreaEmpty,
  OccupationAreaFields,
  OccupationAreaActionButtons,
  OccupationAreaButtonConfirmRemove,
  OccupationAreaButtonCancelRemove,
  RowButton,
  ButtonWrapper,
  ViewButton,
  SaveButton,
} from './styles';

const customId = "custom-id-yes";

const profileFormSchema = yup.object().shape({
  name: yup
    .string()
    .required('Este campo é obrigatório')
    .min(3, 'Mínimo de 3 caracteres')
    .max(80, 'Mínimo de 80 caracteres'),
  telephone: yup.string()
    .required('Este campo é obrigatório')
    .min(18, 'Informe os 10 dígitos'),
  cellphone: yup.string()
    .required('Este campo é obrigatório')
    .min(19, 'Informe os 11 dígitos'),
  aboutMe: yup.string()
    .required('Este campo é obrigatório')
    .min(10, 'Mínimo de 10 caracteres')
    .max(2000, 'Máximo de 2000 caracteres'),
  creci: yup.string(),
  role: yup.string(),
  email: yup.string(),
  facebook: yup.string()
    .url('Informe uma URL válida'),
  instagram: yup.string()
    .url('Informe uma URL válida'),
  linkedIn: yup.string()
    .url('Informe uma URL válida'),
  zipCode: yup.string(),
  city: yup.string(),
  number: yup.string(),
  complement: yup.string(),
  street: yup.string(),
});

export const ProfileEdition = ({
  history
}) => {
  const [profileImageCrop, setProfileImageCrop] = useState({ x: 0, y: 0 })
  const [profileImageZoom, setProfileImageZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [profileImg, setProfileImg] = useState(null);
  const [profileImgName, setProfileImgName] = useState(null);
  const [profileImgInFile, setProfileImgInFile] = useState(null);
  const [profileImgCropped, setProfileImgCropped] = useState(null);

  const [specialtiesTags, setSpecialtiesTags] = useState([]);
  const [occupationArea, setOccupationArea] = useState([]);
  const [removeOccupationArea, setRemoveOccupationArea] = useState(null);

  const [name, setName] = useState('');
  const [hasWhatsapp, setHasWhatsapp] = useState(false);
  const [pictureUrl, setPictureUrl] = useState('');
  const [loaded, setLoaded] = useState(false);

  const { register, handleSubmit, formState, control, setValue } = useForm({
    resolver: yupResolver(profileFormSchema),
  });

  const { errors } = formState;

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setProfileImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
      setProfileImgName(e.target.files[0].name)
    }
  };

  const addMaskTelephone = (value) => {
    const number = value.split('');

    return `+55 (${number[0]}${number[1]}) ${number[2]}${number[3]}${number[4]}${number[5]}-${number[6]}${number[7]}${number[8]}${number[9]}`;
  }

  const addMaskCellphone = (value) => {
    const number = value.split('');

    return `+55 (${number[0]}${number[1]}) ${number[2]}${number[3]}${number[4]}${number[5]}${number[6]}-${number[7]}${number[8]}${number[9]}${number[10]}`;
  }

  const loadInfo = async () => {
    try {
      const user = userService.retrieve();

      const response = await userProfileService.getUserCompleteProfile({
        token: user.token,
        userId: user._id,
      });

      setLoaded(true);
      setPictureUrl(response.profile.image);

      const occupationAreaResponse = response.profile.professionalInformation.serviceAreas;

      setSpecialtiesTags(response.profile.specialties);
      setOccupationArea(occupationAreaResponse);
      setHasWhatsapp(response.profile.professionalInformation.cellphone.whatsApp);
      setName(response.name);

      setValue('name', response.profile.screenName);

      if (response.profile.professionalInformation.telephone.number) {
        setValue('telephone', addMaskTelephone(response.profile.professionalInformation.telephone.number));
      }

      if (response.profile.professionalInformation.cellphone.number) {
        setValue('cellphone', addMaskCellphone(response.profile.professionalInformation.cellphone.number));
      }

      setValue('aboutMe', response.profile.aboutMe);
      setValue('creci', response.profile.professionalInformation.creci);
      setValue('role', response.profile.role);
      setValue('email', response.email);
      setValue('facebook', response.profile.professionalInformation.facebook);
      setValue('instagram', response.profile.professionalInformation.instagram);
      setValue('linkedIn', response.profile.professionalInformation.linkedIn);
      setValue('zipCode', response.profile.professionalInformation.address.zip);
      setValue('city', response.profile.professionalInformation.address.city);
      setValue('number', response.profile.professionalInformation.address.number);
      setValue('complement', response.profile.professionalInformation.address.complement);
      setValue('street', response.profile.professionalInformation.address.street);
    } catch {
      toast.error('Houve um erro ao carregar os dados do seu perfil!', {
        autoClose: false,
        toastId: customId,
      });
    }
  };

  useEffect(() => {
    loadInfo();
  }, []);

  const uploadProfileImage = async () => {
    try {
      const user = userService.retrieve();

      await userProfileService.uploadProfileImage({
        picture: profileImgInFile,
        token: user.token
      })
    } catch (error) {
      toast.error('Erro enviando imagem de perfil.', {
        autoClose: false,
        toastId: customId,
      });
    }
  }

  const onCompleteProfileImageCrop = useCallback((_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, []);

  const handleCloseCropImage = () => {
    setProfileImg(null);
    setProfileImageZoom(1);
    setProfileImageCrop({ x: 0, y: 0 });
  }

  const showCroppedImage = useCallback(async () => {
    try {
      const { imageInBase64, imageInBlob } = await getCroppedImg(
        profileImg,
        croppedAreaPixels,
        0
      );

      const urlImageInBlob = await imageInBlob;

      fetch(imageInBase64)
        .then(res => res.blob())
        .then(blob => {
          const file = new File([blob], profileImgName, { type: 'image/png' })
          setProfileImgInFile(file)
        });

      setProfileImgCropped(urlImageInBlob)
      handleCloseCropImage();
    } catch (e) {
      handleCloseCropImage();
      toast.error('Erro ao ajustar imagem, tente novamente!', {
        autoClose: false,
        toastId: customId,
      })
    }
  }, [croppedAreaPixels]);

  const handleAddNewOccupationArea = () => {
    setOccupationArea([
      {
        city: '',
        neighborhoods: []
      },
      ...occupationArea,
    ]);
  }

  const handleChangeCityInputOccupationArea = (position, value) => {
    const updatedOccupationArea = occupationArea.map((item, index) => {
      if (position === index) {
        return { ...item, city: value };
      }

      return item;
    });

    setOccupationArea(updatedOccupationArea);
  }

  const handleChangeNeighborhoodsOccupationArea = (tags, position) => {
    const updatedOccupationArea = occupationArea.map((item, index) => {
      if (position === index) {
        return { ...item, neighborhoods: tags };
      }

      return item;
    });

    setOccupationArea(updatedOccupationArea);
  }

  const handleRemoveOccupationArea = (position) => {
    const updatedOccupationArea = occupationArea.filter((_, index) =>
      index !== position);

    setRemoveOccupationArea(null);
    setOccupationArea(updatedOccupationArea);
  }

  const handleUpdateProfileForm = useCallback(async values => {
    try {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: true },
      });

      if (profileImgInFile !== null) {
        await uploadProfileImage();
      }

      const occupationAreaSerialized = occupationArea.filter(item =>
        item.city !== '');

      const formData = {
        ...values,
        zipCode: values.zipCode ? values.zipCode.replace('-', '') : '',
        cellphoneNumber: {
          number: values.cellphone.replace(/[^a-zA-Z0-9]+/g, '').substring(2),
          whatsApp: hasWhatsapp,
        },
        telephone: {
          number: values.telephone.replace(/[^a-zA-Z0-9]+/g, '').substring(2),
        },
        serviceAreas: occupationAreaSerialized,
        specialties: specialtiesTags
      };

      const user = userService.retrieve();

      await userProfileService.update({
        userId: user._id,
        token: user.token,
        data: formData,
      });

      toast.info('Perfil atualizado!', {
        autoClose: false,
        toastId: customId,
      });
      await history.push(pathsConstants.PROFILE);
    } catch (error) {
      toast.error('Houve um erro ao atualizar o seu perfil, tente novamente!', {
        autoClose: false,
        toastId: customId,
      });
    } finally {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: false },
      });
    }
  }, [occupationArea, profileImgInFile, specialtiesTags, hasWhatsapp]);

  return (
    <>
      <Container fluid="md" className={"container_fluid"}>
        <Wrapper>
          {loaded &&
            <Form
              onSubmit={handleSubmit(handleUpdateProfileForm)}
            >
              <SectionTitle>
                Insira sua foto
              </SectionTitle>
              <ProfilePicture
                src={profileImgCropped ? profileImgCropped : `${pictureUrl}?${Math.random()}`}
                alt={name}
              />
              <div>
                <input
                  style={{ width: '100%', visibility: 'hidden' }}
                  type="file"
                  id={"input-file-profile"}
                  accept="image/*"
                  onClick={event => event.target.value = ''}
                  onChange={onSelectFile} />
                <label
                  htmlFor={'input-file-profile'}
                  className={'input-file-profile'}
                  style={{ cursor: 'pointer' }}
                >
                  Clique ou <b>arraste e solte aqui.</b>
                </label>
                <ExcluirUsuario>Excluir dados do usuário</ExcluirUsuario>
              </div>

              {profileImg &&
                <CropImageContentOverlay>
                  <CropImageCard>
                    <CropImageCardHeader>
                      <CropImageCardHeaderTitleWrapper>
                        <h3>Corte sua nova foto de perfil</h3>
                        <span>
                          <FiAlertCircle size="16" />
                          Arraste ou dê zoom para ajustar a imagem
                        </span>
                      </CropImageCardHeaderTitleWrapper>

                      <button type="button" onClick={handleCloseCropImage}>
                        <FiX size="24" />
                      </button>
                    </CropImageCardHeader>

                    <CropImageCardWrapper>
                      <CropImageCardContent>
                        <Cropper
                          image={profileImg}
                          crop={profileImageCrop}
                          zoom={profileImageZoom}
                          aspect={3 / 4}
                          onCropChange={setProfileImageCrop}
                          onCropComplete={onCompleteProfileImageCrop}
                          onZoomChange={setProfileImageZoom}
                        />
                      </CropImageCardContent>
                    </CropImageCardWrapper>

                    <CropImageCardFooter>
                      <button
                        type="button"
                        onClick={showCroppedImage}>
                        Visualizar
                      </button>
                    </CropImageCardFooter>
                  </CropImageCard>

                </CropImageContentOverlay>
              }

              <SectionTitle style={{ marginTop: 40 }}>
                Dados pessoais
              </SectionTitle>

              <div className="mb-3">
                <InputBootstrap
                  name="name"
                  label="Nome"
                  placeholder="Nome"
                  error={errors.name}
                  {...register('name')}
                />
              </div>

              <div className="mb-3">
                <PhoneInputBootstrap
                  name="telephone"
                  label="Telefone"
                  error={errors.telephone}
                  control={control}
                  {...register('telephone')}
                />
              </div>

              <CellphoneInput
                name="cellphone"
                label="Celular"
                error={errors.cellphone}
                control={control}
                {...register('cellphone')}
              />

              <CheckboxInputWrapper
                label='Este número é Whatsapp'
                htmlFor='checkBoxWhatsapp'
              >
                <WhatsappCheckboxInput
                  id='checkBoxWhatsapp'
                  name='checkBoxWhatsapp'
                  checked={hasWhatsapp}
                  onClick={() => setHasWhatsapp(prevState => !prevState)}
                />
              </CheckboxInputWrapper>

              <TextArea
                name="aboutMe"
                label="Sobre Meu Trabalho"
                error={errors.aboutMe}
                {...register('aboutMe')}
              />

              <div className="my-3">
                <label
                  className="mb-2"
                  style={{
                    opacity: 0.65,
                    color: '#000000',
                  }} zzz
                >
                  Especialidades
                </label>

                <ReactTagInput
                  maxTags={10}
                  tags={specialtiesTags}
                  removeOnBackspace
                  editable
                  placeholder="Digite e pressione enter"
                  onChange={(tags) => {
                    console.log(tags)
                    setSpecialtiesTags(tags)
                  }}
                />
              </div>

              <div className="mb-3">
                <OccupationAreaContainer>
                  <OccupationAreaTitle>
                    <label
                      style={{
                        opacity: 0.65,
                        color: '#000000',
                      }}
                    >
                      Áreas de atuação

                      {occupationArea.length > 0 &&
                        <OccupationAreaCount>
                          {occupationArea.length}
                        </OccupationAreaCount>
                      }
                    </label>

                    <button
                      type="button"
                      onClick={handleAddNewOccupationArea}
                      disabled={occupationArea.some(item => item.city === '')}
                    >
                      <FiPlus size="16" />
                      novo
                    </button>
                  </OccupationAreaTitle>

                  <OccupationAreaScroll>
                    {!occupationArea.length ?
                      <OccupationAreaEmpty>
                        <span>
                          Clique no botão '+ novo' e adicione sua área de atuação
                        </span>
                      </OccupationAreaEmpty>
                      :
                      occupationArea.map((occupationItem, index) => (
                        <OccupationAreaFields key={index}>
                          <div className="form-floating mt-2">
                            <input
                              style={{
                                textTransform: 'capitalize',
                              }}
                              placeholder="Cidade"
                              value={occupationItem.city}
                              type="text"
                              className="form-control"
                              id="occupation_area_city"
                              name="occupation_area_city"
                              aria-describedby="occupation_area_city"
                              onChange={(event) => {
                                handleChangeCityInputOccupationArea(index, event.target.value)
                              }}
                            ></input>
                            <label
                              htmlFor="occupation_area_city"
                              className="form-label"
                            >
                              Cidade
                            </label>
                          </div>

                          <div className="mt-2">
                            <ReactTagInput
                              tags={occupationItem.neighborhoods}
                              removeOnBackspace
                              editable
                              placeholder="Digite um bairro e pressione enter"
                              onChange={
                                (tag) => handleChangeNeighborhoodsOccupationArea(tag, index)
                              }
                            />
                          </div>

                          <span>
                            <button
                              type="button"
                              disabled={removeOccupationArea === index}
                              onClick={() => setRemoveOccupationArea(index)}>
                              Excluir
                            </button>
                          </span>
                          {removeOccupationArea === index &&
                            <OccupationAreaActionButtons>
                              <OccupationAreaButtonConfirmRemove
                                type="button"
                                onClick={() => handleRemoveOccupationArea(index)}
                              >
                                Confirmar
                              </OccupationAreaButtonConfirmRemove>

                              <OccupationAreaButtonCancelRemove
                                type="button"
                                onClick={() => setRemoveOccupationArea(null)}
                              >
                                Cancelar
                              </OccupationAreaButtonCancelRemove>
                            </OccupationAreaActionButtons>
                          }
                        </OccupationAreaFields>
                      ))
                    }
                  </OccupationAreaScroll>
                </OccupationAreaContainer>
              </div>

              <SectionTitle style={{ marginTop: 40 }}>
                Informações Profissionais
              </SectionTitle>
              <div className="mb-3">
                <InputBootstrap
                  name="creci"
                  label="Creci"
                  placeholder="Creci"
                  error={errors.creci}
                  {...register('creci')}
                />
              </div>

              <div className="mb-3">
                <InputBootstrap
                  name="role"
                  label="Profissão"
                  placeholder="Profissão"
                  error={errors.role}
                  {...register('role')}
                />
              </div>

              <div className="mb-3">
                <InputBootstrap
                  name="email"
                  label="E-mail"
                  placeholder="E-mail"
                  disabled
                  error={errors.email}
                  {...register('email')}
                />
              </div>

              <div className="mb-3">
                <InputBootstrap
                  name="facebook"
                  label="Facebook"
                  placeholder="Facebook"
                  error={errors.facebook}
                  {...register('facebook')}
                />
              </div>

              <div className="mb-3">
                <InputBootstrap
                  name="instagram"
                  label="Instagram"
                  placeholder="Instagram"
                  error={errors.instagram}
                  {...register('instagram')}
                />
              </div>

              <div className="mb-3">
                <InputBootstrap
                  name="linkedIn"
                  label="LinkedIn"
                  placeholder="LinkedIn"
                  error={errors.linkedIn}
                  {...register('linkedIn')}
                />
              </div>
              <SectionTitle style={{ marginTop: 40 }}>
                Endereço
              </SectionTitle>

              <div className="mb-3">
                <CepInput
                  name="zipCode"
                  label="Cep"
                  error={errors.zipCode}
                  control={control}
                  {...register('zipCode')}
                />
              </div>

              <div className="mb-3">
                <InputBootstrap
                  name="city"
                  label="Cidade"
                  placeholder="Cidade"
                  error={errors.city}
                  {...register('city')}
                />
              </div>

              <div className="form-floating mb-3">
                <div className="row g-2">
                  <div className="col-md">
                    <InputBootstrap
                      name="number"
                      label="Número"
                      placeholder="Número"
                      type="number"
                      error={errors.number}
                      {...register('number')}
                    />
                  </div>
                  <div className="col-md">
                    <InputBootstrap
                      name="complement"
                      label="Complemento"
                      placeholder="Complemento"
                      error={errors.complement}
                      {...register('complement')}
                    />
                  </div>
                </div>
              </div>

              <div className="mb-3">
                <InputBootstrap
                  name="street"
                  label="Rua"
                  placeholder="Rua"
                  error={errors.street}
                  {...register('street')}
                />
              </div>

              <RowButton>
                <ButtonWrapper>
                  <ViewButton onClick={history.goBack}>
                    voltar
                  </ViewButton>

                  <SaveButton>
                    salvar
                  </SaveButton>
                </ButtonWrapper>
              </RowButton>
            </Form>
          }
        </Wrapper>
      </Container>
    </>
  );
}
