import styled from "styled-components";
import { mqDesktop, mqTablet, mqCustom } from "../../../../styled/helpers/helpers";

export const Results = styled.div`
  margin-top: 80px;
  padding-bottom: 1rem;
  padding-right: 1rem;
  padding-left: 1rem;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 80px);
  min-width: 250px;
  flex-grow: 1;
  overflow-y: scroll;
  max-width: ${props => (!props.isMapVisible && !props.isFilterVisible) ? "1600px" : "1220px"};

  .realEstates {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
    flex-wrap: wrap;
  }

  .noContent {
    display: flex;
    gap: 2rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 8rem 3rem;
    text-align: center;
    grid-column: 1 / span 2;
  }

  ${mqTablet`
    position: relative;
    top: 0;
    display: flex;
    height: calc(100vh - 80px);

    .realEstates {
      grid-template-columns: repeat(
        ${props => props.isFilterVisible ? 2 : 3}, 
        1fr
      );
    }
  `}

  ${mqDesktop`
    .realEstates {
      grid-template-columns: repeat(
        ${props => props.isMapVisible && props.isFilterVisible ? 1 : 2}, 
        1fr
      );
    }
  `}

  ${mqCustom(1600)`
  .realEstates {
    grid-template-columns: repeat(
      ${props => props.isMapVisible && props.isFilterVisible ? 2 : 3}, 
      1fr
    );
  }
`}
`;

export const TopFilter = styled.div`
  padding: 1rem 0 1rem;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid #ccc;
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 11;

  .hideFilter {
    text-decoration: none;
    background-color: #2b2b2b;
    padding: 0.25rem 0.5rem;
    color: #fff;
    font-weight: 600;
    font-size: 11pt;
  }

  .hideMap {
    display: none;
    ${mqCustom(1300)`
      display: flex;
    `}
  }

  p:first-of-type {
    color: #2B2B2B;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  .fastFilter {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row;
    gap: 1rem;

    .visibilityButtons {
      display: flex;
      gap: 0.5rem;
    }

    .topFilter {
      display: none;
      gap: 0.75rem;
      justify-content: space-between;

      button {
        display: flex;
        flex-grow: 1;
        height: 30px;
        gap: 0.5rem;
        align-items: center;
        justify-content: center;
        padding: 0.125rem 0.5rem;
        font-size: 10pt;
        border: 1px solid #aeaeae;
        color: #777;
        background-color: transparent;

        svg {
          color: rgb(72, 72, 72, 0.71);
        }
      }

      button.featureChecked {
        padding: 0.125rem 0.5rem;
        color: #fff;
        font-size: 10pt;
        background-color: #83786f;
        border: none;

        svg {
          color: #fff;
        }
      }

      ${mqTablet`
        display: flex; 
      `}
    }

    .orderSearch {
      display: flex;
      gap: 1rem;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      .orderBy {
        position: relative;
        display: flex;
        gap: 0.25rem;
        align-items: center;

        svg {
          color: #2748C2;
        }
      }

      .orderOptions {
        position: absolute;
        margin-top: 0.25rem;
        right: 0.25rem;
        z-index: 10;
        background-color: #fff;
        border: 1px solid #aeaeae;

        option {
          padding: 0.125rem 1rem;
          color: #777;
        }

        option:hover {
          background-color: rgb(39, 72, 194, 0.17);
          cursor: pointer;
        }
      }
    }
  }

  ${mqDesktop`
    position: sticky;
    top: 0;
    z-index: 11;
  `}
`

export const LoadingResults = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 2rem;
  width: 100%;
  min-height: 10rem;

  p {
    color: #778899;
    font-style: italic;
  }
`