
import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import { ellipsis } from 'polished';
import { ArrowSortedDown } from 'styled-icons/typicons/ArrowSortedDown';

import { iconBaseStyle, pointer, mqTablet } from '../../../../../styled/helpers/helpers';
import { COLOR_2, COLOR_14, COLOR_17 } from '../../../../../styled/variables/variables';
import { BaseLabel } from '../../../../../styled/labels/labels';

import Dropdown from './dropdown/Dropdown';

const RealEstateTypeSelector = ({
  dropdownCategories,
  onClick,
  onDropdownItemClicked,
  selectedType,
  showDropdown,
}) => (
  <Wrapper>
    <TextWrapper onClick={ onClick }>
      <RealStateSelected>{ selectedType.label }</RealStateSelected>
      <RealStateArrowIcon />
    </TextWrapper>
    <Dropdown
      show={ showDropdown }
      categories={ dropdownCategories }
      selected={ selectedType }
      onTypeClicked={ onDropdownItemClicked }
    />
  </Wrapper>
);

RealEstateTypeSelector.propTypes = {
  dropdownCategories: propTypes.array.isRequired,
  onClick: propTypes.func.isRequired,
  onDropdownItemClicked: propTypes.func.isRequired,
  selectedType: propTypes.object.isRequired,
  showDropdown: propTypes.bool.isRequired,
};

const Wrapper = styled.div`
  height: 50px;
  width: 100%;
  ${ pointer };
  align-self: center;

  ${ mqTablet`
    margin-right: 10px;
    max-width: 160px;
  ` };
`;

const TextWrapper = styled.div`
  display: flex;
  cursor: pointer;
  height: 50px;
`;

const RealStateSelected = styled(BaseLabel)`
  margin: 0 auto;
  color: ${ COLOR_17 };
  text-transform: uppercase;
  font-size: 14px;
  line-height: 24px;
  margin-top: 13px;
  align-self: center;
  flex-grow: 1;
  margin-bottom:5px;
  padding-bottom:9px;
  font-weight: 300;
  ${ ellipsis('100%') };
  border-bottom: 1px solid ${ COLOR_14 };
`;

const RealStateArrowIcon = styled(ArrowSortedDown)`
  ${ iconBaseStyle };
  align-self: center;
  margin-top: 2px;
  color: ${ COLOR_14 };
  margin-left: -20px;
`;

export default RealEstateTypeSelector;
