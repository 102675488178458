import React from 'react';
import styled from 'styled-components';

import { BaseLabel } from '../../../styled/labels/labels';
import { COLOR_2 } from '../../../styled/variables/variables';
import { mqTablet, mqCustom } from '../../../styled/helpers/helpers';

const Info = () => (
  <Wrapper>
    <Label>KOORT IMÓVEIS</Label>
    <Label>Rio de Janeiro, RJ</Label>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-bottom: 100px;

  ${ mqTablet`
    align-self: center;
    width: 700px;
  ` }

  ${ mqCustom(1500)`
    margin-bottom: -20px;
  ` }
`;

const Label = styled(BaseLabel)`
  font-size: 14px;
  color: ${ COLOR_2 };
  text-transform: uppercase;
  align-self: center;
  opacity: .75;

  ${ mqTablet`
    align-self: flex-end;
  ` }
`;

export default Info;
