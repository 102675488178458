import httpClient from "../../clients/http/http.client";

import AccessData from "../../models/access-data/access-data.model";
import Address from "../../models/address/address.model";
import Feature, { GROUP_TYPES } from "../../models/feature/feature.model";
import Geolocation from "../../models/geolocation/geolocation.model";
import Image, { TYPES as IMAGE_TYPES } from "../../models/image/image.model";
import imageUtil from "../../utils/image/image.util";
import Koortimativa from "../../models/koortimativa/koortimativa.model";
import Price from "../../models/price/price.model";
import RealEstate from "../../models/real-estate/real-estate.model";

import stringUtil from "../../utils/string/string.util";

/*
  global
  process
  FormData
  Promise
*/

const BASE_PATH = "/realestates";

const changeAdsKoortimativa = async ({ adId, data, user }) => {
  const path = "/realestates/realestate/save/owner/step-4/" + adId;
  const response = await httpClient
    .patch({
      path,
      params: {
        ads: {
          showInfo: {
            koortimativa: data,
          },
        },
      },
      token: user.token,
      options: {
        header: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
      },
    })
    .catch((ex) => {
      console.log(ex);
    });

  return response;
};

const createRealStateStep3 = async ({ adId, data, user }) => {
  const path = "/realestates/creation-flow/owner/step-3/" + adId;
  const response = await httpClient
    .patch({
      path,
      params: {
        realEstate: {
          address: {
            floor: data.floor,
          },
          yearBuilt: data.constructionYear,
          features: {
            property: data.features.property,
            condo: data.features.condo,
            near: data.features.near,
          },
        },
        ads: {
          types: {
            publication: "Padrão",
          },
          video: "",
          typeOfGuarantee: data.guarantees.length > 0 ? data.guarantees : [],
          title: data.title,
          description: data.description,
          showInfo: {
            prices: true,
          },
        },
      },
      token: user.token,
      options: {
        header: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
      },
    })
    .catch((ex) => {
      console.log(ex);
    });

  return response;
};

const createPlanAndPayment = async ({
  isCompany,
  paymentInfo,
  creditCardInfo,
  user,
  isRecurrence = false,
}) => {
  let path;

  // if (isRecurrence) {
  //   path = `/plans/${paymentInfo.id}/subscribe`;
  // } else {
  //   path = `/plans/${paymentInfo.id}/purchase`;
  // }

  const BASE_PATH = '/api/v2';

  path = `${BASE_PATH}/plans/${paymentInfo.id}/subscribe`;

  const phoneNumberUnmasked = paymentInfo.phoneNumber.replace(
    /[^a-zA-Z0-9]+/g,
    ""
  );

  const countryCode = phoneNumberUnmasked.slice(0, 2);
  const areaCode = phoneNumberUnmasked.slice(2, 4);
  const numberPhone = phoneNumberUnmasked.slice(4, phoneNumberUnmasked.length);

  const expirationDate = creditCardInfo.expiryDate;
  const expirationMonth = Number(expirationDate.split("/")[0]);
  const expirationYear = expirationDate.split("/")[1];

  const billingOfDayRequestData = {
    customer: {
      name: isCompany ? paymentInfo.social : paymentInfo.personName,
      email: paymentInfo.email,
      type: isCompany ? "COMPANY" : "INDIVIDUAL",
      document: paymentInfo.document.replace(/\D/g, ""),
      documentType: isCompany ? "CNPJ" : "CPF",
      phones: {
        mobilePhone: {
          countryCode: countryCode,
          areaCode: areaCode,
          number: numberPhone,
        },
      },
    },
    creditCard: {
      number: creditCardInfo.cardNumber.replace(/\./g, ""),
      holderName: creditCardInfo.ownerName,
      holderDocument: paymentInfo.document.replace(/\D/g, ""),
      expMonth: expirationMonth,
      expYear: expirationYear,
      cvv: creditCardInfo.securityCode,
      brand: creditCardInfo.brand,
      billingAddress: {
        line1: `${paymentInfo.form.number}, ${paymentInfo.form.street}, ${paymentInfo.form.neighborhood}`,
        line2: paymentInfo.form.additionalInfo,
        zipCode: paymentInfo.form.zipCode.replace(/\D/g, ""),
        city: paymentInfo.form.city,
        state: paymentInfo.form.state,
        country: "BR",
      },
    },
  };

  const response = await httpClient.post({
    path,
    params: billingOfDayRequestData,
    token: user.token,
  });

  return response;
};

const createRealEstateRealtor = async ({ data, user }) => {
  const path = "/realestates/creation-flow";
  const response = await httpClient
    .post({
      path,
      params: {
        realEstate: {
          types: {
            use: data.use,
            property: data.type,
          },
          address: {
            street: data.street,
            streetNumber: data.number,
            complement: data.additionalInfo,
            neighborhood: data.neighborhood,
            city: data.city,
            state: data.state,
            zipCode: data.zipCode.replace(/\D/g, ""),
            location: {
              coordinates: [data.longitude, data.latitude],
            },
          },
          features: {
            property: data.features.property,
            condo: data.features.condo,
            near: data.features.near,
          },
          bedrooms: data.bedrooms,
          bathrooms: data.bathrooms,
          suites: data.suites,
          parkingSpaces: data.parkingSpaces,
          area: data.area,
          totalArea: data.totalArea,
        },
        ads: {
          types: {
            publication: "Padrão",
            listing: data.listing,
            transaction: data.transaction,
          },
          development: {
            name: data.development.name,
            constructionStatus: data.development.constructionStatus,
            buildings: data.development.buildings,
            floor: data.development.floors,
            apartmentsPerFloor: data.development.apartmentsPerFloor,
          },
          prices: {
            main: data.price ? data.price : 0,
            condo: data.condo ? data.condo : 0,
            iptu: data.iptu ? data.iptu : 0,
            rental: data.rental ? data.rental : 0,
          },
          showInfo: {
            address: data.showOnMap,
            prices: true,
            koortimativa: false,
          },
          contact: {
            name: data.name,
            email: data.email,
            phone: {
              number: data.phoneNumber,
              whatsApp: data.whatsApp,
            },
          },
          typeOfGuarantee: data.guarantees,
          video: data.videoUrl,
          // title: data.title,
          description: data.description,
        },
      },
      token: user.token,
      options: {
        header: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
      },
    })
    .catch((ex) => {
      console.log(ex);
    });

  const { _id } = response.data;

  return _id;
};

const createinfoAd = async ({ data, user }) => {
  //Format number
  if (String(data.phoneNumber).length == 19) {
    data.phoneNumber = data.phoneNumber
      .replace(/[^0-9]/g, "")
      .replace(/(\d{2})?(\d{2})?(\d{5})?(\d{4})/, "+$1 ($2) $3-$4");
  }

  const path = "/realestates/creation-flow/owner/step-1";
  const response = await httpClient
    .post({
      path,
      params: {
        realEstate: {
          types: {
            use: data.use,
            property: data.type,
          },
          address: {
            street: data.street,
            streetNumber: data.number,
            complement: data.additionalInfo,
            neighborhood: data.neighborhood,
            city: data.city,
            state: data.state,
            zipCode: data.zipCode.replace(/\D/g, ""),
            location: {
              coordinates: [data.longitude, data.latitude],
            },
          },
          bedrooms:
            data.bedrooms && data.bedrooms2
              ? [data.bedrooms, data.bedrooms2]
              : [data.bedrooms],
          bathrooms:
            data.bathrooms && data.bathrooms2
              ? [data.bathrooms, data.bathrooms2]
              : [data.bathrooms],
          suites:
            data.suites && data.suites2
              ? [data.suites, data.suites2]
              : [data.suites],
          parkingSpaces:
            data.parkingSpaces && data.parkingSpaces2
              ? [data.parkingSpaces, data.parkingSpaces2]
              : [data.parkingSpaces],
          area: data.area && data.area2 ? [data.area, data.area2] : [data.area],
          totalArea:
            data.totalArea && data.totalArea2
              ? [data.totalArea, data.totalArea2]
              : [data.totalArea],
        },
        ads: {
          types: {
            publication: "Padrão",
            transaction: data.transaction,
          },
          prices: {
            main: data.price ? data.price : 0,
            condo: data.condo ? data.condo : 0,
            iptu: data.iptu ? data.iptu : 0,
          },
          showInfo: {
            address: data.showOnMap,
          },
          contact: {
            name: data.name,
            email: data.email,
            phone: {
              number: data.phoneNumber,
              whatsApp: data.whatsApp,
            },
          },
        },
      },
      token: user.token,
      options: {
        header: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
      },
    })
    .catch((ex) => {
      console.log(ex);
    });

  const { _id } = response.data;

  return _id;
};

const getNewById = async ({ id, token }) => {
  const path = `/realestates/${id}`;
  const { data } = await httpClient.get({ path, token });

  const dataSerialized = {
    ...data,
    dashboard: {
      ...data.dashboard,
      imagesSerialized: data.dashboard.images.map((url) =>
        url.replace("{width}", 1024)
      ),
    },
  };

  return dataSerialized;
};

/**
 * Este endpoint serve pra desanunciar
 */
const removeEstateProduct = async ({ token, realId }) => {
  try {
    const path = `/realestates/unpublish-ad/${realId}`;
    const result = await httpClient.patch({ path, token });

    return result;
  } catch (err) {
    throw new Error(err);
  }
};

const addEstateProduct = async ({ token, realId }) => {
  const path = `/realestates/publish-ad/${realId}`;
  const result = await httpClient.patch({ path, token });

  return result;
};

const getById = async ({ id, token }) => {
  const path = `${BASE_PATH}/realestate/${id}`;
  const response = await httpClient.get({ path, token });

  const { _id, ads, near, realEstate, reports, contact } = response.data;

  const { ad, ad_m, ad_g, floor_plan, tour360 } = ads.images;
  const { features } = ads;
  const result = new RealEstate({
    ...ads,
    ...ads.development,
    ...realEstate,
    ...ads.types,
    ...realEstate.types,
    ...contact,
    yearBuilt: realEstate.yearBuilt,
    area: realEstate.area[0],
    video: ads.video,
    bathrooms: realEstate.bathrooms[0],
    bedrooms: realEstate.bedrooms[0],
    description: ads.description,
    highlight: ads.types.publication.toLowerCase() === "super destaque",
    id: _id,
    reports: response.data.reports,
    realEstateId: _id,
    parkingSpaces: realEstate.parkingSpaces[0],
    subtitle: ads.subTitle,
    suites: realEstate.suites[0],
    petAcceptance: !!realEstate.features.condo.find((f) => {
      return f.toLowerCase() === "aceita pet";
    }),
    accessData: new AccessData({
      ...ads.accessStatistics,
    }),
    address: new Address({
      ...realEstate.address,
      number: realEstate.address.streetNumber,
      geolocation: new Geolocation({
        ...realEstate.address.location,
        latitude: realEstate.address.location.coordinates[1],
        longitude: realEstate.address.location.coordinates[0],
      }),
    }),

    images: (ad || [])
      .map(
        (url) =>
          new Image({
            url: url.replace("{width}", "1024"),
            size: "p",
            type: IMAGE_TYPES.PICTURE,
          })
      )
      .concat(
        (ad_m || []).map(
          (url) =>
            new Image({
              url: url.replace("{width}", "1024"),
              size: "m",
              type: IMAGE_TYPES.PICTURE,
            })
        )
      )
      .concat(
        (ad_g || []).map(
          (url) =>
            new Image({
              url: url.replace("{width}", "1024"),
              size: "g",
              type: IMAGE_TYPES.PICTURE,
            })
        )
      )
      .concat(
        (floor_plan || []).map(
          (url) =>
            new Image({
              url: url.replace("{width}", "1024"),
              type: IMAGE_TYPES.FLOOR_PLAN,
            })
        )
      )
      .concat(
        (tour360 || []).map(
          (url) =>
            new Image({
              url: url.replace("{width}", "1024"),
              type: IMAGE_TYPES.PANORAMA,
            })
        )
      ),
    price: new Price({
      ...ads.prices,
      main: ads.prices.main,
    }),
    koortimativa: new Koortimativa({
      ...ads.koortimativa,
      rangeStart: ads.koortimativa.range[0],
      rangeEnd: ads.koortimativa.range[1],
    }),
  });
  return result;
};

const getUserRealEstates = async ({
  city,
  neighborhood,
  precoMin = 0,
  precoMax = 0,
  areaMin = 0,
  areaMax = 0,
  externalId = "",
  type,
  token,
  classification = "",
  bedrooms = 0,
  page = 1,
  isFilter,
  assignment = "",
  situation = "",
}) => {
  precoMax = precoMax > 0 ? precoMax : 99999999999;
  areaMax = areaMax > 0 ? areaMax : 99999999999;

  const params = {};
  if (city && city != "") {
    params.city = `"${city}"`;
  }
  if (neighborhood && neighborhood != "") {
    params.neighborhood = `"${neighborhood}"`;
  }
  if (type && type != "") {
    params.type = type;
  }
  if (bedrooms > 0) {
    params.bedrooms = bedrooms;
  }

  if (externalId !== "") {
    params.externalId = externalId;
  }

  if (classification !== "" && classification !== null) {
    params.classification = classification;
  }

  if (assignment !== "") {
    params.assignment = assignment;
  }

  if (situation !== "") {
    params.situation = situation;
  }

  let path;

  if (isFilter) {
    path = `/realestates/search?price=[${precoMin},${precoMax}]&area=[${areaMin},${areaMax}]&${encodeQueryData(
      params
    )}&page=${page}`;
  } else {
    path = `/realestates/search?price=[${precoMin},${precoMax}]&area=[${areaMin},${areaMax}]&${encodeQueryData(
      params
    )}&page=${page}`;
  }

  if (path.includes('&&')) {
    path = path.replace('&&', '&');
  }

  const response = await httpClient.get({ path, token });
  let result = null;

  if (response && response.data) {
    result = response.data.items.map((item) => {
      const { ads } = item;
      const { ad, ad_m, ad_g, floor_plan, tour360 } = ads.images;

      return {
        ...item,
        highlight: ads.types.publication.toLowerCase() === "super destaque",
        ads: {
          ...ads,
          imagesSerialized: (ad || [])
            .map(
              (url) =>
                new Image({
                  url: url.replace("{width}", "1024"),
                  size: "p",
                  type: IMAGE_TYPES.PICTURE,
                })
            )
            .concat(
              (ad_m || []).map(
                (url) =>
                  new Image({
                    url: url.replace("{width}", "1024"),
                    size: "m",
                    type: IMAGE_TYPES.PICTURE,
                  })
              )
            )
            .concat(
              (ad_g || []).map(
                (url) =>
                  new Image({
                    url: url.replace("{width}", "1024"),
                    size: "g",
                    type: IMAGE_TYPES.PICTURE,
                  })
              )
            )
            .concat(
              (floor_plan || []).map(
                (url) =>
                  new Image({
                    url: url.replace("{width}", "1024"),
                    type: IMAGE_TYPES.FLOOR_PLAN,
                  })
              )
            )
            .concat(
              (tour360 || []).map(
                (url) =>
                  new Image({
                    url: url.replace("{width}", "1024"),
                    type: IMAGE_TYPES.PANORAMA,
                  })
              )
            ),
        },
      };
    });
  }

  return result;
};

const uploadImages = async ({
  pictureBlobs,
  floorPlanBlobs,
  panoramaBlobs,
  realEstateId,
  token,
}) => {
  const params = new FormData();
  await Promise.all([
    Promise.all(
      pictureBlobs.map(async (blob) => {
        const compressed = await imageUtil.compress(blob, {
          maxSizeMB: 5,
        });

        params.append("ad", compressed);
      })
    ),

    Promise.all(
      floorPlanBlobs.map(async (blob) => {
        const compressed = await imageUtil.compress(blob, {
          maxSizeMB: 5,
        });

        params.append("floor_plan", compressed);
      })
    ),

    Promise.all(
      panoramaBlobs.map(async (blob) => {
        const compressed = await imageUtil.compress(blob, {
          maxSizeMB: 5,
        });

        params.append("tour360", compressed);
      })
    ),
  ]);

  const path = `/realestates/images/upload-ad-image/${realEstateId}`;

  const response = await httpClient.patch({
    path,
    params,
    token,
    options: {
      header: {
        "Content-Type": "multipart/form-data",
      },
    },
  });

  return response;
};

const informView = async ({ id }) => {
  const path = `/realestates/increment-views/${id}`;
  const result = await httpClient.patch({
    path,
  });

  return result;
};

const getMessages = async ({ id, token }) => {
  const path = `/messages/realestate/${id}`;
  const response = await httpClient.get({ path, token });
  return response;
};

const deleteEstate = async ({ id, token }) => {
  const path = `/realestates/${id}`;

  await httpClient.del({ path, token });
};

const getOthersRecommendedAds = async ({ token, id, page = 1 }) => {
  const path = `/listings/recommendations/ads/by-location/${id}?page=${page}`;
  const response = await httpClient.get({ path, token });
  const result = response.data.items.map((item) => {
    const { _id } = item;
    const ads = item.ad;
    const { ad, ad_m, ad_g, floor_plan, tour360 } = ads.images;
    const mapped = new RealEstate({
      ...ads,
      ...ads.types,
      ...ads.types,
      area: ads.area[0],
      bathrooms: ads.bathrooms[0],
      bedrooms: ads.bedrooms[0],
      id: _id,
      realEstateId: _id,
      parkingSpaces: ads.parkingSpaces[0],
      suites: ads.suites[0],
      address: new Address({
        ...ads.address,
        number: ads.address.streetNumber,
        geolocation: new Geolocation({
          ...ads.address.location,
          latitude: ads.address.location.coordinates[1],
          longitude: ads.address.location.coordinates[0],
        }),
      }),
      images: (ad || [])
        .map(
          (url) =>
            new Image({
              url: url.replace("{width}", "1024"),
              size: "p",
              type: IMAGE_TYPES.PICTURE,
            })
        )
        .concat(
          (ad_m || []).map(
            (url) =>
              new Image({
                url: url.replace("{width}", "1024"),
                size: "m",
                type: IMAGE_TYPES.PICTURE,
              })
          )
        )
        .concat(
          (ad_g || []).map(
            (url) =>
              new Image({
                url: url.replace("{width}", "1024"),
                size: "g",
                type: IMAGE_TYPES.PICTURE,
              })
          )
        )
        .concat(
          (floor_plan || []).map(
            (url) =>
              new Image({
                url: url.replace("{width}", "1024"),
                type: IMAGE_TYPES.FLOOR_PLAN,
              })
          )
        )
        .concat(
          (tour360 || []).map(
            (url) =>
              new Image({
                url: url.replace("{width}", "1024"),
                type: IMAGE_TYPES.PANORAMA,
              })
          )
        ),
      price: new Price({
        ...ads.prices,
        main: ads.prices.main,
      }),
    });

    return mapped;
  });

  return { items: result, _links: response.data._links };
};

const getRecommendedAds = async ({ token, id, page = 1 }) => {
  const path = `/listings/recommendations/ads/by-similarity/${id}?page=${page}`;
  const response = await httpClient.get({ path, token });
  const result = response.data.items.map((item) => {
    const { _id } = item;
    const ads = item.ad;
    const { ad, ad_m, ad_g, floor_plan, tour360 } = ads.images;
    const mapped = new RealEstate({
      ...ads,
      ...ads.types,
      ...ads.types,
      area: ads.area[0],
      bathrooms: ads.bathrooms[0],
      bedrooms: ads.bedrooms[0],
      id: _id,
      realEstateId: _id,
      parkingSpaces: ads.parkingSpaces[0],
      suites: ads.suites[0],
      address: new Address({
        ...ads.address,
        number: ads.address.streetNumber,
        geolocation: new Geolocation({
          ...ads.address.location,
          latitude: ads.address.location.coordinates[1],
          longitude: ads.address.location.coordinates[0],
        }),
      }),
      images: (ad || [])
        .map(
          (url) =>
            new Image({
              url: url.replace("{width}", "1024"),
              size: "p",
              type: IMAGE_TYPES.PICTURE,
            })
        )
        .concat(
          (ad_m || []).map(
            (url) =>
              new Image({
                url: url.replace("{width}", "1024"),
                size: "m",
                type: IMAGE_TYPES.PICTURE,
              })
          )
        )
        .concat(
          (ad_g || []).map(
            (url) =>
              new Image({
                url: url.replace("{width}", "1024"),
                size: "g",
                type: IMAGE_TYPES.PICTURE,
              })
          )
        )
        .concat(
          (floor_plan || []).map(
            (url) =>
              new Image({
                url: url.replace("{width}", "1024"),
                type: IMAGE_TYPES.FLOOR_PLAN,
              })
          )
        )
        .concat(
          (tour360 || []).map(
            (url) =>
              new Image({
                url: url.replace("{width}", "1024"),
                type: IMAGE_TYPES.PANORAMA,
              })
          )
        ),
      price: new Price({
        ...ads.prices,
        main: ads.prices.main,
      }),
    });

    return mapped;
  });

  return { items: result, _links: response.data._links };
};

const getRecommendations = async ({ params }) => {
  const { REACT_APP_SEARCH_API_URL } = process.env;
  const path = `${BASE_PATH}/recomenda`;
  const _params = {
    cidade: params.city,
    is_logado: params.islogged,
    user_id: params.userId,
    center: params.center,
    n_answers: params.itemNumbers,
  };
  const response = await httpClient.post({
    url: `${REACT_APP_SEARCH_API_URL}`,
    path,
    params: _params,
  });

  const result = response.data.results;
  return { items: result };
};

const encodeQueryData = (data) => {
  const ret = [];
  for (let d in data)
    ret.push(encodeURIComponent(d) + "=" + encodeURIComponent(data[d]));
  return ret.join("&");
};

const updateRealEstate = async ({ realEstateId, data, user }) => {
  const path = `/realestates/${realEstateId}`;
  await httpClient
    .patch({
      path,
      params: {
        realEstate: {
          types: {
            use: data.use,
            property: data.type,
          },
          address: {
            street: data.street,
            streetNumber: data.number,
            floor: data.floor,
            complement: data.additionalInfo,
            neighborhood: data.neighborhood,
            city: data.city,
            state: data.state,
            zipCode: data.zipCode.replace(/\D/g, ""),
          },
          features: {
            property: data.features.property,
            condo: data.features.condo,
            near: data.features.near,
          },
          bedrooms: data.bedrooms,
          bathrooms: data.bathrooms,
          suites: data.suites,
          parkingSpaces: data.parkingSpaces,
          area: data.area,
          totalArea: data.totalArea,
        },
        ads: {
          types: {
            publication: "Padrão",
            listing: data.listing,
            transaction: data.transaction,
          },
          development: {
            name: data.development.name,
            constructionStatus: data.development.constructionStatus,
            buildings: data.development.buildings,
            floor: data.development.floors,
            apartmentsPerFloor: data.development.apartmentsPerFloor,
          },
          prices: {
            main: data.price ? data.price : 0,
            condo: data.condo ? data.condo : 0,
            iptu: data.iptu ? data.iptu : 0,
            rental: data.rental ? data.rental : 0,
          },
          showInfo: {
            address: data.showOnMap,
            prices: true,
            koortimativa: false,
          },
          contact: {
            name: data.name,
            email: data.email,
            phone: {
              number: data.phoneNumber,
              whatsApp: data.whatsApp,
            },
          },
          video: data.videoUrl,
          // title: data.title,
          description: data.description,
          typeOfGuarantee: data.guarantees.length > 0 ? data.guarantees : [],
        },
      },
      token: user.token,
      options: {
        header: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
      },
    })
    .catch((ex) => {
      console.log(ex);
    });
};

const removeImageOfAdRealEstate = async ({ realEstateId, data, user }) => {
  const path = `/realestates/remove-images/${realEstateId}`;

  await httpClient
    .patch({
      path,
      params: {
        images: data,
      },
      token: user.token,
      options: {
        header: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
      },
    })
    .catch((ex) => {
      console.log(ex);
    });
};

const getRealEstates = async ({ userId, token, page = 1, transaction }) => {
  try {
    const path = `/realestates/search/${userId}?transaction=${transaction}&page=${page}`;

    const { data } = await httpClient.get({ path, token });

    return data;
  } catch (err) {
    console.log(err);
  }
};

export default {
  getUserRealEstates,
  getRecommendedAds,
  getRecommendations,
  createinfoAd,
  createPlanAndPayment,
  createRealStateStep3,
  getMessages,
  deleteEstate,
  getById,
  createRealEstateRealtor,
  getNewById,
  uploadImages,
  informView,
  changeAdsKoortimativa,
  getOthersRecommendedAds,
  addEstateProduct,
  removeEstateProduct,
  updateRealEstate,
  removeImageOfAdRealEstate,
  getRealEstates,
};
