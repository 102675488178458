import styled from 'styled-components';

import { BaseButton } from '../../../../styled/buttons/buttons';
import { BaseLabel } from '../../../../styled/labels/labels';
import { COLOR_2, COLOR_4, COLOR_1 } from '../../../../styled/variables/variables';
import { mqTablet } from '../../../../styled/helpers/helpers';

export const Form = styled.form`
 width: 100%;
 max-width: 500px;
 align-self: flex-start;
 display: flex;
 flex-direction: column;
`;

export const SectionTitle = styled(BaseLabel)`
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 10px;
`;

export const Counter = styled.span`
  font-size: 12px;
  margin-top: 8px;
  margin-bottom: 50px;
  text-align: right;

  @media(max-width: 768px) {
    display: block;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;

  ${mqTablet`
    align-self: center;
    width: 400px;
  ` }
`;

export const SaveButton = styled(BaseButton).attrs({
  type: 'submit',
})`
  color: ${COLOR_2};
  background-color: ${COLOR_1};
  flex-grow: 1;
  width: 1px;
  border: none;
`;

