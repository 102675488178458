import BaseModel from '../base/base.model';

export const TYPES = {
  OWNER: 'OWNER',
  REAL_ESTATE_AGENT: 'REAL_ESTATE_AGENT',
};

class Publisher extends BaseModel {

  constructor({
    id,

    company,
    emails,
    name,
    phones,
    pictureUrl,
    rating,
    type,
    websites,
  }) {
    super({ id, });

    this.company = company;
    this.emails = emails;
    this.name = name;
    this.phones = phones;
    this.pictureUrl = pictureUrl;
    this.rating = rating;
    this.type = type;
    this.websites = websites;
  }

}

export default Publisher;
