import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Accordion, AccordionItem } from 'react-sanfona';

import { BaseLabel } from '../../../../styled/labels/labels';
import { COLOR_1, COLOR_2 } from '../../../../styled/variables/variables';
import { mqTablet, hexToRgb } from '../../../../styled/helpers/helpers';
import { BaseButton } from '../../../../styled/buttons/buttons';

import pathsConstants from '../../../../constants/paths';

const Arguments = () => (
  <Wrapper>
    <Accordion>
      {list.map((item, index) => {
        return (
          <AccordionItem key={item.key} title={item.text} style={{ color: 'white', backgroundColor: 'black' }}>
            <div style={{ margin: 10, padding: 20, textAlign: 'left' }}>
              <p style={{ whiteSpace: "pre-wrap" }}>
                {item.insideText ? item.insideText : item.text}
              </p>
            </div>
          </AccordionItem>
        );
      })}
    </Accordion>
    <CreateAdButton>
      anuncie
    </CreateAdButton>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content:space-around;
  ${ mqTablet`
    align-self: center;
    width: 750px;
  ` }
`;

const Item = styled.div`
  display: flex;
  margin-bottom: 15px;
  ${ mqTablet`
    align-self: center;
  ` }
`;

const OrderIndicator = styled(BaseLabel)`
  color: ${ COLOR_1};
  font-size: 28px;
  align-self: flex-start;
  margin-right: 15px;
  font-weight: bold;

  ${ mqTablet`
    font-size: 36px;
  ` }
`;

const Text = styled(BaseLabel)`
  color: ${ COLOR_2};
  align-self: center;
  text-align: left;
  font-size: 14px;
  opacity: 0.75;
  width: 230px;
`;

const CreateAdButton = styled(BaseButton).attrs({
  as: Link,
  to: pathsConstants.PURCHASE_CHOOSE_TYPE
})`
  background-color: ${ COLOR_1};
  color: ${ hexToRgb(COLOR_2, 0.75)};
  align-self: center;
  width: 200px;
  margin: 0;
  margin-top: 50px;
  border: none;
  font-weight: 700;
  line-height: 50px;

  ${ mqTablet`
    align-self: center;
    width: 160px;
  ` }
`;

const list = [
  {
    key: '1',
    text: 'Para quem procura um imóvel para alugar ou comprar',
    insideText: '-No KOORT, você pode encontrar o imóvel ideal para morar ou investir. ' +
      'A plataforma oferece ferramentas visuais e intuitivas que permitem buscar, filtrar e comparar as suas opções favoritas. '
      + 'O KOORT usa técnicas inteligentes para ordenar resultados, destacar opções interessantes e recomendar imóveis relevantes,' +
      "e você não perde tempo vendo o que não interessa.\n\n\n" +
      "-A marca do KOORT é a busca pelo mapa. Assim, você não fica limitado a escolher um bairro, mas pode procurar de perto na região do seu interesse. " +
      'Já passou a época em que procurar um imóvel era como tentar achar uma agulha em uma pilha de anúncios irrelevantes: no KOORT você encontra o que precisa de forma' +
      'inteligente e rápida.'

  },
  {
    key: '2',
    text: 'Para quem tem um imóvel para alugar ou vender',
    insideText: "O proprietário que quer vender ou alugar tem várias opções no KOORT.\n\n\
      - Anunciar por contra própria, sem precisar de corretor(e sem ser perturbado por corretores quando essa não é sua preferência).\n\
      - Procurar um corretor na nossa base de corretores cadastrados.\n\
      - Cadastrar seu imóvel, sem custos, e encontrar um corretor na nossa rede de corretores cadastrados.\n\n\
      - Quem anuncia no KOORT pode incluir uma grande variedade de detalhes para atrair rapidamente compradores ou inquilinos interessados exatamente naquilo que ele tem para oferecer.\
 Além disso, pode incluir fotos, panorâmicas, vídeos e outros recursos.\n\n\
      - O proprietário buscando fazer negócios conta ainda com a KOORTIMATIVA, a nossa ferramenta inteligente de precificação.\
 Usando as características informadas sobre seu imóvel, a KOORTIMATIVA faz uso da sua grande base de dados e algoritmos \
 de machine learning para estimar uma faixa de valores para o seu imóvel, economizando o tempo e dinheiro que seriam gastos com avaliadores caros e pouco confiáveis.\n\n\
      - Além de oferecer vantagens ao proprietário que quer vender ou alugar, o KOORT quer ser o melhor site para quem procura imóvel e para quem trabalha no segmento.\
 A experiência de busca oferecida pelo site atrai mais interessados em comprar ou alugar, e as oportunidades para corretores permitem ligar quem pode trabalhar o seu imóvel com quem está \
 realmente interessado."
  },
  {
    key: '3',
    text: 'Para o corretor de imóveis',
    insideText: "-No KOORT, o corretor não é um mero comprador, mas faz parte de uma inovadora plataforma de negócios para o mercado imobiliário.\
 Escolhemos os corretores como parceiros de primeira hora, e em breve estaremos lançando recursos para outros players de mercado.\
 Bons negócios acontecem quando as partes interessadas estão juntas e contam com ferramentas inteligentes. O KOORT veio para facilitar esse encontro.\n\n\
 - Ao criar a sua conta você poderá criar o seu perfil profissional e apresentar ao mundo suas credenciais e experiências.\
 Esse perfil será seu cartão de visitas para o mercado imobiliário e para potenciais clientes.\n\n\
 - As ferramentas da plataforma ajudam o corretor a organizar seus negócios e mostrar suas atividades para o mundo.\
 Os corretores terão a opção de tornar seu histórico de transações visível no seu perfil, sinalizando a experiência e profissionalismo que diferenciam seu trabalho.\
  Além disso, o corretor poderá ter o feedback do seu trabalho com avaliações que podem também servir como testemunhos.\
 O corretor conta ainda com a KOORTIMATIVA para ajudá - lo a tomar decisões sobre preços e estratégias de venda.\n\n\
  - O KOORT vem também para ajudar proprietários que querem vender ou alugar a encontrar seu corretor.\
 Até pouco tempo não existia forma fácil e segura de encontrar um corretor que trabalhasse para vender ou alugar o seu imóvel.\
 Ao lançar o perfil do corretor, o KOORT veio para ajudar proprietários e corretores a descobrirem as melhores oportunidades.\
    "
  },
  {
    key: '4',
    text: 'Para imobiliárias e incorporadoras',
    insideText: "- O KOORT quer ser um lugar para se fazer negócios. Estamos abertos e atentos a parcerias com imobiliárias, incorporadoras e outros players do mercado imobiliário.\n\n\
- Nosso papel não é o de virar imobiliária(contrariando tendências dos últimos anos), mas de ligar os agentes.\
  O KOORT vai permitir levar seus imóveis e lançamentos ao público, seja como anúncio ou por geração de leads, e pode ainda conectar sua empresa com a base de corretores cadastrados.\n\n\
- Ferramentas como a KOORTIMATIVA podem, ainda, ser utilizadas em massa para avaliar carteiras e tomar decisões de investimento."
  },
];

export default Arguments;
