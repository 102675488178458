/* eslint-disable no-undef */
/* eslint-disable react/prop-types */

import React, { PureComponent, useState, useEffect } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import propTypes from 'prop-types';
import { transitions } from 'polished';
import styled from 'styled-components';
import { useAuth0 } from '@auth0/auth0-react';

import { COLOR_1, COLOR_2 } from '../../styled/variables/variables';
import { mqTablet, mqDesktop, backgrounder, pointer } from '../../styled/helpers/helpers';
import { BaseLabel } from '../../styled/labels/labels';

import userService from '../../services/user/user.service';
import pathsConstants from '../../constants/paths';
import queryStringUtil from '../../utils/query-string/query-string.util';

const { PUBLIC_URL } = process.env;

const PROPRIETARIO_TYPE = "2";
const CORRETOR_TYPE = "3";
const IMOBILIARIA_TYPE = "4";
const INCORPORADORA_TYPE = "5";

const ChoosePlan = ({
  history
}) => {
  const [token, setToken] = useState();
  const [user, setUser] = useState();

  const { loginWithRedirect } = useAuth0();

  const getToken = async () => {
    let user;
    user = await userService.retrieve();

    if (user !== undefined) {
      setToken(user.token);
      setUser(user);
    } else {
      setToken(null)
      setUser(null);
    }
  }

  useEffect(() => {
    getToken();
  }, []);

  const handleRedirectionToForm = path => {
    if (!user || !token) {
      let pathRedirect = pathsConstants.PURCHASE_CHOOSE_TYPE;
      localStorage.setItem('@Koort:PathRedirect', pathRedirect);

      loginWithRedirect({ authorizationParams: { redirect_uri: `${window.location.origin}/authentication/login/success?login=true` } });
    } else {
      if (path === 'proprietario') {
        history.push({
          pathname: pathsConstants.PURCHASE_FLOW_PLANS,
          state: { flowType: 'OWNER' },
          search: `flowType=OWNER&noStep=true`
        });
      } else if (path === 'corretor') {
        // history.push({
        //   pathname: pathsConstants.PURCHASE_FLOW_PLANS,
        //   state: { flowType: 'BROKER' },
        //   search: `flowType=BROKER&noStep=true`
        // });
        history.push({
          pathname: pathsConstants.PURCHASE_FLOW_INCORPORATION,
          search: `type=${CORRETOR_TYPE}&subject=subscribe plan`,
          state: { type: CORRETOR_TYPE }
        })
      } else if (path === 'imobiliaria') {
        history.push({
          pathname: pathsConstants.PURCHASE_FLOW_INCORPORATION,
          search: `type=${IMOBILIARIA_TYPE}&subject=subscribe plan`,
          state: { type: IMOBILIARIA_TYPE }
        })
      } else if (path === 'incorporadora') {
        history.push({
          pathname: pathsConstants.PURCHASE_FLOW_INCORPORATION,
          search: `type=${INCORPORADORA_TYPE}&subject=subscribe plan`,
          state: { type: INCORPORADORA_TYPE }
        });
      } else {
        window.location.reload();
      }
    }
  }


  return (
    <Wrapper>
      <h1 style={{ color: 'white' }}>Escolha seu perfil</h1>
      <WrapperTexture>
        <Texture />
        <div style={{ 'display': 'flex', justifyContent: 'center', flex: 0.8 }}>
          <WrapperSquares>
            <WrapperCards onClick={() => handleRedirectionToForm('proprietario')}>
              {/* <Squares> */}
              <Title>Proprietário</Title>
              <SubtitleWrapper>
                <Subtitle>Obtenha serviço de corretagem ou divulgue seu imóvel pelo menor preço do mercado.</Subtitle>
              </SubtitleWrapper>
              {/* </Squares> */}
            </WrapperCards>
            <WrapperCards onClick={() => handleRedirectionToForm('corretor')}>
              {/* <Squares> */}
              <Title>Corretor</Title>
              <SubtitleWrapper>
                <Subtitle>Torne-se um corretor parceiro e obtenha um conjunto de vantagens.</Subtitle>
              </SubtitleWrapper>
              {/* </Squares> */}
            </WrapperCards>
            <WrapperCards onClick={() => handleRedirectionToForm('imobiliaria')}>
              {/* <Squares> */}
              <Title>Imobiliária</Title>
              <SubtitleWrapper>
                <Subtitle>A melhor forma de divulgar seu portfólio. Obtenha vantagens tornando-se um parceiro Koort.</Subtitle>
              </SubtitleWrapper>
              {/* </Squares> */}
            </WrapperCards>
            <WrapperCards onClick={() => handleRedirectionToForm('incorporadora')}>
              {/* <Squares> */}
              <Title>Incorporadora</Title>
              <SubtitleWrapper>
                <Subtitle>Direcione seus anúncios para o público correto e obtenha <i>leads</i> de qualidade.</Subtitle>
              </SubtitleWrapper>
              {/* </Squares> */}
            </WrapperCards>
          </WrapperSquares>
        </div>
      </WrapperTexture>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 120px;
  justify-content:center;
  align-items:center;
  align-self:center;
  background-color:black;
  width: 100%;

  @media(min-width: 900px) {
    padding-right: 120px;
  }
`;

const Texture = styled.div`
  ${backgrounder(`${PUBLIC_URL}/imgs/koort-texture-white-outline.svg`)};
  background-position: bottom;
  height: 140px;
  width: 100%;
  background-repeat: repeat-x;
  background-size: 620px;
  margin-bottom: 30px;

  @media(min-width: 900px) {
    background-position: right;
    width: 300px;
    height: 100vh;
    background-size: 1000px;
    margin: 0;
  }
`;

const WrapperSquares = styled.div`
  display: flex;
  flex-direction: column;

  @media(min-width: 900px) {
    flex-direction: row;
  }
`;

const WrapperTexture = styled.div`
  display: flex;
  flex-direction: column;
  width:100%;
  padding-top:20px;

  @media(min-width: 900px) {
    flex-direction: row;
  }
`;

const WrapperCards = styled.div`
  ${backgrounder(`${PUBLIC_URL}/imgs/koort-texture-white.svg`)};
  background-position: bottom;
  background-position-y: 190px;
  height: 280px;
  background-size: 620px;
  margin-bottom: 30px;
  background-color: ${COLOR_1};

  width: 200px;
  margin: 8px 8px 24px;
  min-height: 200px;
  min-width: 200px;
  padding-top:15px;

  cursor: pointer;

  @media (min-width: 900px) {
    ${transitions(['transform'], 'ease .3s')}
    &:hover {
      transform: scale(1.1);
    }
  }
`;

// const Squares = styled.div`
//   background-color: #FEFEFE;
//   height: 200px;
//   width: 200px;
//   margin: 5px;
//   word-wrap:break-word;
// `;

const SubtitleWrapper = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  margin-top: 20px;
`;

const Title = styled.h4`
  text-align: center;
  padding-top: 15px;
  font-weight: 700;
  font-size: 26px;
  color: ${COLOR_2}
`;

const Subtitle = styled.p`
  width: 180px;
  text-align: left;
  align-self: center;
  color: ${COLOR_2}
`;

export default ChoosePlan;
