import React from 'react';
import styled from 'styled-components';
import Arrows from '../cover/arrows/Arrows';
import Arguments from './arguments/Arguments';
import Title from './title/Title';

import { mqTablet, bigSectionBaseStyle } from '../../../styled/helpers/helpers';
import { COLOR_17, COLOR_1 } from '../../../styled/variables/variables';

import stringUtil from '../../../utils/string/string.util';
import windowUtil from '../../../utils/window/window.util';
import Recommended from '../recommended/Recommended';

const handleShowMoreButton = () => windowUtil.scrollToElement({
  element: Recommended.ID
})

const CallToAction = () => (
  <Wrapper as="section">
    <ContentWrapper>
      <Title />
      <Arguments />
    </ContentWrapper>
    <SeeMoreButton onClick={() => handleShowMoreButton()}>
      <Arrows color={COLOR_1} />
    </SeeMoreButton>
  </Wrapper>
);

CallToAction.ID = `${ CallToAction.name }_${ stringUtil.generateUniqueId() }`;

const Wrapper = styled.div.attrs({
  id: CallToAction.ID,
})`
  ${ bigSectionBaseStyle }
  background-color: ${ COLOR_17 };
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${ mqTablet`
    align-self: center;
    width: 700px;
  ` }
`;

const SeeMoreButton = styled.div`
  display: none;

  ${ mqTablet`
      display: flex;
      justify-content: center;
      margin-top: 200px;
      align-self: center;
      align-items: center;
  `}
`;

export default CallToAction;
