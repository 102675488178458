import React from 'react';
import styled from 'styled-components';

import { BaseLabel, Bold } from '../../../../styled/labels/labels';
import { COLOR_1 } from '../../../../styled/variables/variables';
import { mqTablet } from '../../../../styled/helpers/helpers';

/*
  global
  process
*/

const { PUBLIC_URL } = process.env;

const Title = () => (
  <Wrapper>
    <Subtitle>
      Como o <Bold>
        KOORT  </Bold> pode ajudar <Bold> você </Bold> ?
    </Subtitle>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  ${ mqTablet`
    flex-direction: row;
    width: 100%;
    justify-content: center;
  ` }
`;

const TopWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${ mqTablet`
    margin-right: 30px;
    & > label {
      align-self: flex-start;
    }
  ` }
`;

const Label = styled(BaseLabel)`
  color: white;
  font-weight: 200;
  align-self: center;
  text-align: center;
  margin-bottom: 15px;

  ${ mqTablet`
    text-align: left;
    font-size: 20px;
    align-self: flex-start;
  ` }
`;

const Subtitle = styled(Label)`
  width: 200px;
  ${ mqTablet`
    align-self: flex-end;
    width: 330px;
  ` }
`;

const KoortText = styled.img.attrs({
  src: `${ PUBLIC_URL }/imgs/koort-text-rj.svg`
})`
  align-self: center;
  width: 280px;
  margin-bottom: 20px;

  ${ mqTablet`
    align-self: flex-start;
    width: 310px;
  ` }
`;

export default Title;
