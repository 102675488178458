import React from 'react';
import { RiCheckLine, RiCloseLine } from 'react-icons/ri';

import { Container, Card, PriceGroup, CTAButton, Divisor, ResourcesList } from './styles';

export function ProductToContact({ data, onPlanClick }) {
  return (
    <Container>
      {data.map(plan => (
        <Card key={plan.id}>
          <header>
            <h4>{plan.name}</h4>
          </header>

          <p>{plan.description}</p>

          <PriceGroup isCustomPlan={plan.ident.includes('CUSTOM')}>
            <strong>{plan.ident.includes('CUSTOM') ? plan.priceFormatted : plan.price}</strong>

            {plan.trialPeriodDays > 0 && (
              <p>Experimente gratuitamente por {plan.trialPeriodDays} dias.</p>
            )}

            <CTAButton>
              <button type="button" onClick={() => onPlanClick(plan.ident, plan)}> Entrar em contato</button>
            </CTAButton>
          </PriceGroup>

          <Divisor />

          <ResourcesList>
            {plan.product.resources.map(item => (
              <li key={item.name}>
                {item.isAvailable ?
                  <span><RiCheckLine size={16} color="#3578E5" /></span>
                  :
                  <span><RiCloseLine size={16} color="#D93025" /></span>
                }
                {item.name}
              </li>
            ))}
          </ResourcesList>
        </Card>
      ))}
    </Container>
  )
}
