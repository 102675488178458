import objectUtil from '../object/object.util';

/* global localStorage */
/* global sessionStorage */

const APP_STORAGE_KEY = 'appStorage';

const get = (key, useLocal = true) => {
  const storage = useLocal ? localStorage : sessionStorage;

  const result = objectUtil.parse(storage.getItem(APP_STORAGE_KEY) || '{ }');
  return key ? result[key] : result;
};

const set = (key, value, useLocal = true) => {
  const storage = useLocal ? localStorage : sessionStorage;

  const appStorage = get();
  if (objectUtil.isNullOrUndefined(value)) delete appStorage[key];
  else appStorage[key] = value;

  storage.setItem(APP_STORAGE_KEY, objectUtil.stringify(appStorage));
  return get(key);
};

const del = (key, useLocal = true) => {
  set(key, undefined, useLocal);
};

export default {
  get,
  set,
  del,
};
