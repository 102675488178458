
import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';

import { COLOR_1, COLOR_6, COLOR_2 } from '../../styled/variables/variables';
import { BaseLabel } from '../../styled/labels/labels';

import { KoortMarkerOrangeOutline } from '../../styled/components/components';

import currencyUtil from '../../utils/currency/currency.util';
import { mqDesktop } from '../../styled/helpers/helpers';

/*
  global
  process
*/

const { PUBLIC_URL } = process.env;

const Koortimativa = ({
  className,
  rangeEnd,
  rangeStart,
  value,
}) => (
  <Wrapper className={ className }>
    <ContentWrapper>
      <SymbolWrapper>
        <InlineWrapper>
          <KKoortMarkerWhiteOutline />
          <KoortimativaText />
        </InlineWrapper>
        <Value>
          { currencyUtil.format({
            value,
          }) }
        </Value>
      </SymbolWrapper>
      <BarWrapper>
        <Bar />
        <Bar />
      </BarWrapper>
      <RangeIndicatorWrapper>
        <RangeIndicator>(MÍN)</RangeIndicator>
        <RangeIndicator>(MÁX)</RangeIndicator>
      </RangeIndicatorWrapper>
      <InlineWrapper>
        <Range>
        { currencyUtil.format({
            value: rangeStart,
          }) }
        </Range>
        <Range>
        { currencyUtil.format({
            value: rangeEnd,
          }) }
        </Range>
      </InlineWrapper>
    </ContentWrapper>
  </Wrapper>
);

Koortimativa.propTypes = {
  value: propTypes.number,
  rangeStart: propTypes.number,
  rangeEnd: propTypes.number,
};

Koortimativa.defaultProps = {
  value: 900000,
  rangeStart: 850000,
  rangeEnd: 950000,
};

const Wrapper = styled.div`
  background-color: ${ COLOR_1 };
  border-radius: 1px;;
  padding: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  ${ mqDesktop`
    margin-bottom: 10px;
  ` }
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 215px;
`;

const SymbolWrapper = styled.div`
  border: solid 1px ${ COLOR_6 };
  padding: 10px;
  border-radius: 1px;;
  align-self: center;
  display: flex;
  flex-direction: column;
`;

const InlineWrapper = styled.div`
  display: flex;
`;

const KKoortMarkerWhiteOutline = styled(KoortMarkerOrangeOutline)`
  height: 25px;
  align-self: center;
  margin-right: 5px;
`;

const KoortimativaText = styled.img.attrs({
  src: `${ PUBLIC_URL }/imgs/koortimativa-text-orange-white.png`,
})`
  width: 110px;
  align-self: flex-start;
`;

const Value = styled(BaseLabel)`
  font-size: 14px;
  font-weight: 500;
  align-self: center;
  color: ${ COLOR_2 };
  width: fit-content;
`;

const BarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-self: center;
  width: 100px;
  margin-bottom: 3px;
`;

const Bar = styled.div`
  width: 1px;
  align-self: center;
  height: 35px;
  background-color: ${ COLOR_6 };
`;

const RangeIndicatorWrapper = styled.div`
  align-self: center;
  width: 130px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const RangeIndicator = styled(Value)`
  font-weight: 200;
  font-size: 12px;
  margin: 0;
  margin-left: -1px;

  &:last-child {
    margin: 0;
    margin-right: -2px;
  }
`;

const Range = styled(Value)`
  font-size: 12px;
  flex-grow: 1;
  width: 1px;
  text-align: center;
`;

export default Koortimativa;
