
import storageUtil from '../../../utils/storage/storage.util';
import httpClient from '../../../clients/http/http.client';

const USER_FAVORITE_KEY = 'userFavorite';

const get = async ({ token }) => {
  const path = '/users/favorite';
  const response = await httpClient.get({ path, token, });
  const result = response.data;
  return result;
};

const patch = async ({ listingId, token }) => {
  const response = await httpClient.patch({
    path: '/users/favorite',
    token,
    params: [ listingId ],
    options: {
      headers: {
        'Content-Type': 'application/merge-patch+json',
      },
    },
  });
  
  const result = response.data.favoriteAds;
  return result;
};

const retrieve = () => {
  return storageUtil.get(USER_FAVORITE_KEY) || [ ];
};

const isFavorite = listingId => {
  const favorites = retrieve();
  const result = favorites.findIndex(favorite => favorite === listingId);
  return result !== -1;
};

const save = ({
  listingId,
  listingIds,
}) => {

  let favorites = listingIds;
  if(!favorites) {
    favorites = retrieve();
    favorites.push(listingId);
  }

  storageUtil.set(USER_FAVORITE_KEY, favorites);
};

const removeOne = listingId => {
  const favorites = retrieve();
  const indexFound = favorites.findIndex(favorite => favorite === listingId);

  if(indexFound !== -1) {
    favorites.splice(indexFound, 1);
    storageUtil.set(USER_FAVORITE_KEY, favorites);
  }
};

const remove = () => storageUtil.set(USER_FAVORITE_KEY);

export default {
  get,
  patch,
  retrieve,
  isFavorite,
  save,
  remove,
  removeOne,
};
