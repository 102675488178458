import React, { PureComponent } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import propTypes from 'prop-types';
import styled from 'styled-components';

import { mqTablet } from '../../styled/helpers/helpers';
import { BaseLabel } from '../../styled/labels/labels';

import BillingInfo from './billing-info/BillingInfo';
import { BuilderRefactored } from './builder/builder-refactored';
import { Builder2Refactored } from './builder2/builder2-refactored';
import Builder3 from './builder3/Builder';
import { Builder3Refactored } from './builder3/builder3-refactored';
import { TYPES } from './steps/Steps';
import Plans from './plans/Plans';
import Payment from './payment/Payment';
import Steps, { TYPES as STEP_TYPES } from './steps/Steps';
import Congratulations from './congratulations/Congratulations';

import userService from '../../services/user/user.service';

import pathsConstants from '../../constants/paths';
import queryStringUtil from '../../utils/query-string/query-string.util';

class PurchaseFlow extends PureComponent {
  constructor(props) {
    super(props);
    const { history } = this.props;
    const queryParams = queryStringUtil.parse(history);
    const { flowType } = queryParams;

    this.state = {
      user: userService.retrieve(),
      currentStep: this.pathToStep({}),
      list: [
        {
          type: TYPES.CREATION,
          label: flowType === 'OWNER' ? 'Criação' : 'Seleção',
        },
        {
          type: TYPES.CONGRATULATIONS,
          label: 'Parabéns',
        },
      ],
    };

    this.unsubscribeToHistory = props.history.listen((location) => {
      const currentStep = this.pathToStep(location);
      this.setState(state => ({
        ...state,
        currentStep,
      }));
    });
  }

  componentDidMount() {
    this.ensureCurrentStep();
  }

  componentWillUnmount() {
    this.unsubscribeToHistory();
  }

  ensureCurrentStep = () => {
    const { history } = this.props;
    const { user } = this.state;

    const queryParams = queryStringUtil.parse(history);
    const { tryCode, planId, paymentMethod, flowType, plans, finishedAd } = queryParams;
    let pathname,
      search = queryStringUtil.stringify(history);
    if (flowType === 'BROKER') {
      if (planId) {
        pathname = pathsConstants.BILLING_INFO
      }
      else if (planId && paymentMethod) {
        pathname = pathsConstants.PURCHASE_FLOW_CONGRATULATIONS
      }
      else if (!user) {
        pathname = pathsConstants.AUTHENTICATION;
      }
      else {
        pathname = pathsConstants.PURCHASE_FLOW_PLANS;
      }
    } else {
      this.setState({ list: [] });
      const isSameTry = user && user.token.includes(tryCode);
      if (!user) {
        pathname = pathsConstants.AUTHENTICATION;
        localStorage.setItem('userNotLogged', true);
      }
      if (isSameTry && !plans && !paymentMethod) {
        pathname = pathsConstants.PURCHASE_FLOW_BUILDER;
      } else if (isSameTry && plans && !paymentMethod) {
        pathname = pathsConstants.PURCHASE_FLOW_PLANS;
      } else if (isSameTry && paymentMethod && plans && !finishedAd) {
        pathname = pathsConstants.PURCHASE_FLOW_BUILDER2
      } else if (isSameTry && paymentMethod && plans && finishedAd) {
        pathname = pathsConstants.PURCHASE_FLOW_CONGRATULATIONS
      }

      else if (user) {
        pathname = pathsConstants.PURCHASE_FLOW_BUILDER;
        search = queryStringUtil.stringify(null, {
          // 'tryCode' prevents an unauthorized part to proceed with
          // the purchase flow of another user.
          tryCode: user.token.substring(0, 10),
        });
      }
    }
  };

  pathToStep = ({
    pathname = this.props.history.location.pathname
  }) => {
    const { history } = this.props;
    const queryParams = queryStringUtil.parse(history);
    const { flowType } = queryParams;
    let step;
    if (flowType) {
      switch (pathname) {
        case pathsConstants.PURCHASE_FLOW_PLANS: {
          step = STEP_TYPES.PLAN;
          break;
        }
        case pathsConstants.PURCHASE_FLOW_PAYMENT: {
          step = STEP_TYPES.CREATION
          break;
        }
        case pathsConstants.PURCHASE_FLOW_CONGRATULATIONS: {
          step = STEP_TYPES.CONGRATULATIONS;
          break;
        }
        case pathsConstants.BILLING_INFO: {
          step = STEP_TYPES.CREATION;
          break;
        }
        case pathsConstants.PURCHASE_FLOW_BUILDER: {
          step = STEP_TYPES.CREATION;
          break;
        }
        case pathsConstants.PURCHASE_FLOW_BUILDER2: {
          step = STEP_TYPES.PAYMENT;
          break;
        }
        default: break;
      }
    } else {
      switch (pathname) {
        case pathsConstants.PURCHASE_FLOW_BUILDER: {
          step = STEP_TYPES.CREATION;
          break;
        }
        case pathsConstants.PURCHASE_FLOW_PLANS: {
          step = STEP_TYPES.PLAN;
          break;
        }
        case pathsConstants.PURCHASE_FLOW_PAYMENT: {
          step = STEP_TYPES.PLAN
          break;
        }
        case pathsConstants.PURCHASE_FLOW_CONGRATULATIONS: {
          step = STEP_TYPES.CONGRATULATIONS;
          break;
        }
        case pathsConstants.PURCHASE_FLOW_BUILDER2: {
          step = STEP_TYPES.PAYMENT;
          break;
        }
        case pathsConstants.BILLING_INFO: {
          step = STEP_TYPES.PLAN;
          break;
        }
        default: break;
      }
    }

    if (flowType === "BROKER") {
      switch (pathname) {
        case pathsConstants.PURCHASE_FLOW_PLANS: {
          step = STEP_TYPES.CREATION;
          break;
        }
      }
    }

    return step;
  };

  render() {
    const { history } = this.props;
    const { user, currentStep, list } = this.state;
    const queryParams = queryStringUtil.parse(history);
    const { flowType, finishedAd, noStep } = queryParams;
    return (
      <Wrapper>
        {flowType === 'OWNER' &&
          <>
            <Title>Novo plano</Title>
          </>
        }
        {flowType === 'BROKER' &&
          <>
            {!noStep &&
              <Title>Novo plano</Title>
            }
            {noStep &&
              <Title>Novo plano</Title>
            }
          </>
        }
        {!noStep &&
          <>
            <Steps activeType={currentStep} alternativeSteps={list.length > 0 && list} />
          </>
        }
        <ContentWrapper>
          <Router history={history}>
            <Switch>
              <Route
                path={pathsConstants.PURCHASE_FLOW_BUILDER3_REGISTER_PROPERTY}
                render={props => (
                  <Builder3Refactored
                    {...props}
                    history={history}
                    user={user}
                  />
                )}
              />

              <Route
                path={pathsConstants.PURCHASE_FLOW_PLANS}
                render={props => (
                  <Plans
                    {...props}
                    user={user}
                  />
                )}
              />
              <Route
                path={pathsConstants.BILLING_INFO}
                render={props => (
                  <BillingInfo
                    {...props}
                    user={user}
                    history={history}
                  />
                )}
              />
              <Route
                path={pathsConstants.PURCHASE_FLOW_PAYMENT}
                component={props => (
                  <Payment
                    {...props}
                    user={user}
                    history={history}
                  />
                )}
              />
              {flowType !== 'BROKER' && !finishedAd &&
                <>
                  <Route
                    path={pathsConstants.PURCHASE_FLOW_BUILDER}
                    render={props => (
                      <BuilderRefactored
                        {...props}
                        history={history}
                        user={user}
                      />
                    )}
                  />
                  <Route
                    path={pathsConstants.PURCHASE_FLOW_BUILDER2}
                    render={props => (
                      <Builder2Refactored
                        {...props}
                        history={history}
                        user={user}
                      />
                    )}
                  />
                </>
              }

              <Route
                path={pathsConstants.PURCHASE_FLOW_BUILDER3}
                render={props => (
                  <Builder3
                    {...props}
                    history={history}
                    user={user}
                  />
                )}
              />

              <Route
                path={pathsConstants.PURCHASE_FLOW_CONGRATULATIONS}
                render={props => (
                  <Congratulations
                    {...props}
                    history={history}
                    user={user}
                  />
                )}
              />
            </Switch>
          </Router>
        </ContentWrapper>
      </Wrapper>
    );
  }
}

PurchaseFlow.propTypes = {
  history: propTypes.object,
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 120px;

  ${mqTablet`
    padding-top: 140px
  ` };
`;

const Title = styled(BaseLabel)`
  font-weight: 700;
  font-size: 22px;
  align-self: center;
  margin-bottom: 50px;

  ${mqTablet`
    font-size: 30px;
  ` }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  padding-bottom: 100px;

  ${mqTablet`
    width: 100%;
    max-width: 1200px;
    align-self: center;
  ` }
`;

export default PurchaseFlow;
