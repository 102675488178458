import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import AboutKoortimativa from './about-koortimativa/AboutKoortimativa';
import CallToAction from './call-to-action/CallToAction';
import Cover from './cover/Cover';
import Highlights from './highlights/Highlights';
import Recommended from './recommended/Recommended';
import { Releases } from './releases';
import Advantages from './advantages/Advantages';
import HighlightedAgents from './highlighted-agents/HighlightedAgents';
import Footer from '../../partials/footer/Footer';

import userService from '../../services/user/user.service';
import queryStringUtil from "../../utils/query-string/query-string.util";

/*
  global
  window
  setTimeout
  clearTimeout
  document
*/

class Home extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      shouldListenToScroll: false,
      sections: [
        { Section: Cover, shouldRender: true, },
        { Section: Releases, shouldRender: true, },
        { Section: Highlights, shouldRender: true, },
        // { Section: AboutKoortimativa, shouldRender: true, },
        { Section: CallToAction, shouldRender: true, },
        { Section: Recommended, shouldRender: true, },
        // { Section: Advantages, shouldRender: true,},
        // { Section: HighlightedAgents, shouldRender: true, },
        { Section: Footer, shouldRender: false, },
      ],
    };
  }

  componentDidMount() {
    const { history } = this.props;

    const params = queryStringUtil.stringify(history);

    if (params.includes('userDeleteAccount')) {
      toast.info('Ficamos tristes em ver você ir embora. Mas respeitamos sua decisão!', {
        autoClose: false,
      });

      window.history.pushState(
        {},
        document.title,
        window.location.pathname,
      )
    }

    const user = userService.retrieve();

    if (!!user && userService.retrieve().isSubscriber == true) {
      this.setState(prevState => ({
        ...prevState,
        sections: prevState.sections.filter((_, index) => index !== 3)
      }));
    }

    const userCoordinatesStored = JSON.parse(localStorage.getItem('@Koort:UserCoordinates'));

    try {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (data) => {
            const { coords: { latitude, longitude } } = data;

            const userCoordinates = [latitude, longitude];

            if (!userCoordinatesStored || userCoordinatesStored[0] !== latitude) {
              localStorage.setItem('@Koort:UserCoordinates', JSON.stringify(userCoordinates));

              window.location.reload(true);
            }
          },
          () => {
            if (!!userCoordinatesStored) {
              localStorage.removeItem('@Koort:UserCoordinates');
            }
          }
        );
      } else {
        console.log('Geolocation is not supported by this browser.');
      }
    } catch {
      console.log('ERRO NO MODAL DE PERMISSÃO DA LOCALIZAÇÃO')
    }
  }

  render() {
    return (
      <Wrapper>
        {this.state.sections.map(({ Section, shouldRender }) => (
          shouldRender && <Section key={Section.ID} history={this.props.history} />
        ))}
      </Wrapper>
    )
  }
}

const Wrapper = styled.div``;

const ContainerButton = styled.div`
 flex-basis: 100%;
  order: 3;
  padding: 5px;
  text-align: right;

`;

const ButtonContainer = styled.button`
  height: 32px;
  line-height: 0;
  width: 100px;
  background: #2b50d8;
  color: #FFF;
  border: 1px solid white;
  cursor: pointer;
  font-weight: bold;
`;


export default Home;
