import styled from 'styled-components';

export const CTAWidgetWrapper = styled.button`
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 9;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: #96D22D;
  border-radius: 8px;
  padding: 12px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.3);

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }
`;
