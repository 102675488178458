
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Persist } from 'react-persist-plus';

import InputWrapper from '../../../components/input-wrapper';
import Checkbox from '../../../components/checkbox';

/*
  global
  window
*/

class Koortimativa extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      form: {
        value: false,
        range: false,
      },
    };

  }

  handleFormChange = event => {
    const { name, checked } = event.target;

    this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        [name]: checked,
      },
    }));
  };

  render() {
    const { form } = this.state;

    return (
      <Wrapper
        onChange={ this.handleFormChange }
      >
        <Persist
          name='REPORT_BUILDER_KOORTIMATIVA_FORM'
          data={ form }
          onMount={ data => this.setState(state => ({
            ...state,
            form: data,
          })) }
          store={ window.sessionStorage }
        />
        <AInputWrapper
          label='Valor'
          htmlFor='value'
        >
          <ACheckBox
            id='value'
            name='value'
            checked={ form.value }
          />
        </AInputWrapper>

        <AInputWrapper
          label='Margem'
          htmlFor='range'
        >
          <ACheckBox
            id='range'
            name='range'
            checked={ form.range }
          />
        </AInputWrapper>
      </Wrapper>
    );
  }
}

const Wrapper = styled.form`
  margin-bottom: 20px;
`;

const AInputWrapper = styled(InputWrapper)`
  flex-direction: row-reverse;
  justify-content: flex-end;
  margin-bottom: 10px;
`;

const ACheckBox = styled(Checkbox)`
  margin-right: 5px;
`;

export default Koortimativa;
