import React, { forwardRef } from 'react';

import { ErrorMessage } from '../error-message';

const InputBase = ({
  name,
  error = null,
  label,
  placeholder,
  type = 'text',
  ...rest
}, ref) => {
  return (
    <>
      <div className="form-floating">
        <input
          id={name}
          name={name}
          type={type}
          className="form-control"
          placeholder={placeholder}
          autoComplete="off"
          ref={ref}
          {...rest}
        />

        <label
          htmlFor={name}
          className="form-label"
        >
          {label}
        </label>
      </div>

      {error && <ErrorMessage message={error.message} />}
    </>
  );
}

export const InputBootstrap = forwardRef(InputBase);
