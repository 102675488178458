import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 1px;
  overflow: hidden;
  user-select: none;
  &:focus {
    outline: none;
  }
  & > canvas {
    bottom: unset !important;
    position: relative !important;
    height: 100% !important;
  }
`;