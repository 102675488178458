import React from 'react';
import styled from 'styled-components';

import Content from './content/Content';
import { mqTablet, backgrounder } from '../../../styled/helpers/helpers';
import Arrows from '../cover/arrows/Arrows';
import { COLOR_6, COLOR_1 } from '../../../styled/variables/variables';
import { BaseButton } from '../../../styled/buttons/buttons';

import stringUtil from '../../../utils/string/string.util';
import windowUtil from '../../../utils/window/window.util';
import CallToAction from '../call-to-action/CallToAction';

/*
  global
  process
*/

const { PUBLIC_URL } = process.env;

const AboutKoortimativa = () => (
  <Wrapper>
    <ContentWrapper>
      <TopWrapper>
        <Texture />
      </TopWrapper>
      <Content id={AboutKoortimativa.ID} />
    </ContentWrapper>
    <SeeMoreButton
      onClick={() => windowUtil.scrollToElement({
        element: CallToAction.ID,
        options: {
          offset: 0,
        },
      })}
    >
      <Arrows color={COLOR_6} />
    </SeeMoreButton>
  </Wrapper>
);

AboutKoortimativa.ID = `${AboutKoortimativa.name}_${stringUtil.generateUniqueId()}`;

AboutKoortimativa.propTypes = {

};

const Wrapper = styled.div`
    ${ mqTablet`
      display: flex;
      flex-direction: column;
      background-color: ${COLOR_1};

    `}
`;

const ContentWrapper = styled.div``;

const SeeMoreButton = styled(BaseButton)`
  border: none;
  display: none;

  ${ mqTablet`
    display: flex;
    align-self: center;
    margin-top: -100px;
  ` }
`;

const TopWrapper = styled.div`
  height: 80px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  ${ mqTablet`
    overflow: unset;
    height: 100px;
    justify-content: flex-end;
  ` }
`;

const Texture = styled.div`
  ${ backgrounder(`${PUBLIC_URL}/imgs/koort-texture-outline.svg`)}
  min-width: 600px;
  background-position: top;
  height: 100%;
  position: relative;
  flex-grow: 1.2;

  ${ mqTablet`
    width: 1px;
    min-width: unset;
    flex-grow: .5;
    height: 770px;
    max-width: 705px;
    background-position: left;
  ` }
`;

export default AboutKoortimativa;
