import * as yup from 'yup';

const formSchema = yup.object().shape({
  propertyUse: yup.string()
    .test("", "Seleção obrigatória", value => {
      if (value !== 'Selecione um uso') {
        return true;
      } else {
        return false;
      }
    }),
  propertyType: yup.string()
    .test("", "Seleção obrigatória", value => {
      if (value !== 'Selecione um tipo') {
        return true;
      } else {
        return false;
      }
    }),
  zipCode: yup.string()
    .test("", "Campo obrigatório", value => {
      if (!!value) {
        const zipCodeUnMasked = value.replace('-', "");

        if (zipCodeUnMasked.length === 8) {
          return true;
        } else {
          return false;
        }
      }
    }),
  number: yup.string().required('Campo obrigatório'),
  complement: yup.string(),
  neighborhood: yup.string().required('Campo obrigatório'),
  bedrooms: yup.string().required('Campo obrigatório'),
  bathrooms: yup.string().required('Campo obrigatório'),
  suites: yup.string().required('Campo obrigatório'),
  parkingSpaces: yup.string().required('Campo obrigatório'),
  area: yup.string().required('Campo obrigatório'),
  totalArea: yup.string().required('Campo obrigatório'),
  price: yup.string().required('Campo obrigatório'),
  condo: yup.string(),
  iptu: yup.string(),
  name: yup.string()
    .required('Este campo é obrigatório')
    .min(3, 'Mínimo de 3 caracteres')
    .max(30, 'Máximo de 30 caracteres'),
  email: yup.string()
    .required('Este campo é obrigatório')
    .email('Informe um e-mail válido'),
  cellphone: yup.string()
    .required('Este campo é obrigatório')
    .min(18, 'Informe 10 ou 11 dígitos')
});

export { formSchema };
