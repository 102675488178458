import React, { PureComponent } from 'react';
import propTypes from 'prop-types';

import domEventsUtil from '../../utils/dom-events/dom-events.util';
import eventsConstants from '../../constants/events';
import googleMapsService from '../../services/google-maps/google-maps.service';

import { Wrapper, selectedAlternativeStyle, Alternative, AlternativeLabel, LocationIcon } from './styles';
/*
  global
  clearTimeout
  setTimeout
*/

class SearchAutocomplete extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      alternatives: [],
      arrowSelectedAlternative: 0,
    };
  }

  componentDidMount() {
    this.handleSearchStringUpdate();
    domEventsUtil.subscribe({ name: eventsConstants.KEY_UP, callback: this.handleArrowKeyPress });
  }

  componentWillUnmount() {
    domEventsUtil.unsubscribe({ name: eventsConstants.KEY_UP, callback: this.handleArrowKeyPress });
  }

  componentDidUpdate(previousProps) {
    const { searchString } = this.props;
    if (searchString !== previousProps.searchString) {
      if (this.searchTimeout) clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.handleSearchStringUpdate();
      }, 500);
    }
  }

  handleSearchStringUpdate = async _ => {
    const { searchString } = this.props;

    let alternatives;
    if (!searchString) {
      alternatives = [];
    } else {
      alternatives = await googleMapsService.findCompleteAddress(searchString);
    }

    this.setState({
      ...this.state,
      alternatives,
      arrowSelectedAlternative: 0,
    }, () => {
      const { onLoad } = this.props;
      const { alternatives } = this.state;
      onLoad(alternatives);
    });
  };

  handleArrowKeyPress = event => {
    const arrowUpCode = 'ArrowUp';
    const arrowDownCode = 'ArrowDown';

    const { code } = event;
    const { alternatives } = this.state;
    let arrowSelectedAlternative = this.state.arrowSelectedAlternative;
    const min = 0;
    const max = alternatives.length - 1;

    if (code === arrowUpCode) arrowSelectedAlternative--;
    else if (code === arrowDownCode) arrowSelectedAlternative++;
    else return;

    if (arrowSelectedAlternative < min || arrowSelectedAlternative > max) return;

    this.setState({
      ...this.state,
      arrowSelectedAlternative,
    }, () => {
      const { onSelectedAlternativeUpdate } = this.props;
      onSelectedAlternativeUpdate(alternatives[arrowSelectedAlternative]);
    });
  };

  render() {
    const { className, show } = this.props;
    const { alternatives, arrowSelectedAlternative } = this.state;
    return (
      <Wrapper className={className} show={show && alternatives.length > 0}>
        {alternatives.map((alternative, index) => (
          <Alternative
            key={alternative.id}
            onClick={() => {
              const { onItemClick } = this.props;
              onItemClick(alternative);
            }}
            isSelected={index === arrowSelectedAlternative}
          >
            <LocationIcon />
            {/* { alternative.type === ESTABLISHMENT_TYPE && <BuildingIcon /> } */}
            <AlternativeLabel>
              {alternative.formatted}
            </AlternativeLabel>
          </Alternative>
        ))}
      </Wrapper>
    );
  }
}

SearchAutocomplete.propTypes = {
  history: propTypes.object,
  onLoad: propTypes.func,
  searchString: propTypes.string,
  onSelectedAlternativeUpdate: propTypes.func,
  className: propTypes.string,
  show: propTypes.bool,
  onItemClick: propTypes.func,
};

SearchAutocomplete.defualtProps = {};



export default SearchAutocomplete;
