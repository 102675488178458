
import React, { PureComponent } from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { Persist } from 'react-persist-plus';

import { BaseTextArea } from '../../../styled/inputs/inputs';
import storageUtil from '../../../utils/storage/storage.util';

/* 
  global
  window
*/

class Description extends PureComponent {

  constructor(props) {
    super(props);

    const { description } = props;
    if(description) storageUtil.del(Description.STORAGE_KEY, false);

    this.state = {
      form: {
        description: description || '',
      },
    };
  }

  handleFormChange = event => {
    const { name, value } = event.target;

    this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        [name]: value,
      },
    }));
  };

  render() {
    const { form } = this.state;

    return (
      <Wrapper onChange={ this.handleFormChange }>
        <Persist 
          name={ Description.STORAGE_KEY }
          data={ form }
          onMount={ data => this.setState(state => ({
            ...state,
            form: data,
          })) }
          store={ window.sessionStorage }
        />
        <TextArea value={ form.description } />
      </Wrapper>
    );
  }
}

Description.STORAGE_KEY = 'REPORT_BUILDER_DESCRIPTION_FORM';

Description.propTypes = {
  description: propTypes.string,
};

Description.defaultProps = { };

const Wrapper = styled.form`
  margin-bottom: 20px;
`;

const TextArea = styled(BaseTextArea).attrs({
  name: 'description',
})``;

export default Description;
