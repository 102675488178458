
import React from 'react';
import styled from 'styled-components';

import { BaseLabel } from '../../styled/labels/labels';
import { COLOR_1, COLOR_3 } from '../../styled/variables/variables';
import { mqTablet } from '../../styled/helpers/helpers';

const Contract = () => (
  <Wrapper>
    <Title>Contrato de Adesão</Title>

    { blocks.map(({ title, topic, level1, level2, }, index) => (
      <BlockWrapper key={ index }>
        { title && (
          <SectionTitle>
            { title }
          </SectionTitle>
        ) }
        
        { topic && (
          <Topic>{ topic }</Topic>
        ) }

        { level1 && (
          <TextBlockLevel1>
            { level1 }
          </TextBlockLevel1>
        ) }

        { level2 && (
          <TextBlockLevel2>
            { level2 }
          </TextBlockLevel2>
        ) }

      </BlockWrapper>
    )) }
  </Wrapper>
);

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 10px;
  padding-top: 130px;
  padding-bottom: 50px;

  ${ mqTablet`
    padding-top: 200px;
    padding-bottom: 200px;
  ` }
`;

const BlockWrapper = styled.div`
  margin-bottom: 30px;

  ${ mqTablet`
    max-width: 1000px;
    align-self: left;
    width: 70%; 
    margin: 0 auto; 
    margin-bottom: 30px;
    text-align: left
  ` }
`;

const Title = styled(BaseLabel)`
  align-self: center;
  font-size: 28px;
  font-weight: 800;
  margin-bottom: 30px;
`;

const SectionTitle = styled(Title)`
  color: ${ COLOR_1 };
  margin-bottom: 20px;
  margin-top: 30px;
  font-size: 24px;
  font-weight: 700;
`;

const Topic = styled(Title)`
  font-size: 20px;
  color: ${ COLOR_1 };
  align-self: flex-start;
  margin-bottom: 10px;
`;

const TextBlockLevel1 = styled.p`
  padding-left: 5px;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: ${ COLOR_3 };
  font-size: 16px;
  text-align: justify;
`;

const TextBlockLevel2 = styled(TextBlockLevel1)`
  padding-left: 25px;
`;

const blocks = [
    { title: 'CONSIDERANDO QUE:' },
    { level1: '(i) KOORT é empresa especializada, entre outras atividades, na criação, desenvolvimento e gestão de sistemas de comercialização de bens e serviços em ambiente virtual, ou seja, em ambiente eletrônico na rede mundial de computadores (“Internet”), sendo responsável pela gestão, administração e operação de sua plataforma digital de comércio eletrônico.' },
    { level1: '(ii) O ANUNCIANTE é todo aquele que deseja veicular um imóvel através da plataforma.' },
    { level1: '(iii) O Contrato de adesão visa estabelecer as condições pelas quais o ANUNCIANTE manterá vínculo por meio do ambiente de comércio eletrônico desenvolvido pelo KOORT, fixando-se as obrigações das partes, além de definir a forma de remuneração da plataforma pela operacionalização das ferramentas disponibilizadas.' },
    { level1: 'Têm, as partes entre si justo e acordado este CONTRATO, nos termos e condições abaixo:' },
    { title: '1. DEFINIÇÕES' },
    { level1: '1.1 Para a exata compreensão acerca do presente instrumento particular, as partes reconhecem como válidas as seguintes definições:' },
    { level2: 'Plataforma: Ambiente de web disponibilizado pelo KOORT em seu site e/ou aplicativos, na qual poderão ser oferecidos serviços direcionados ao mercado imobiliários para terceiros.' },
    { level2: 'Comprador: pessoa física ou jurídica que adquire produtos ou contrata serviços do ANUNCIANTE por meio da Plataforma KOORT.' },
    { level2: 'Meios de Pagamento (citado no item 5.3): sistema integrado à Plataforma, todavia operacionalizado por terceiros, através do qual são processados os pagamentos das compras dos anúncios feitas no ambiente virtual.' },
    { level2: 'Remuneração: contraprestação pecuniária devida ao KOORT em virtude dos serviços disponibilizados.' },
    { title: '2. OBJETO ' },
    { level1: '2.1 O objeto do presente contrato é a permissão de acesso á Plataforma KOORT, de forma não exclusiva, intransferível e temporária, a fim de que o ANUNCIANTE possa comercializar ou alugar os seus imóveis.' },
    { title: '3. DO PRAZO' },
    { level1: '3.1 Desde que sejam respeitadas todas as cláusulas deste instrumento, salvo deliberação diversa entre as partes, os serviços serão prestados pelo prazo estabelecido no plano de anúncio escolhido.' },
    { title: '4. DA UTILIZAÇÃO DOS SERVIÇOS' },
    { level1: '4.1 O ANUNCIANTE declara ter pleno e total conhecimento dos requisitos técnicos necessários para a correta utilização e veiculação dos seus anúncios junto ao KOORT.' },
    { level1: '4.2 Concedido o acesso ao sistema da Plataforma, o ANUNCIANTE poderá anunciar os imóveis, ofertando-os de acordo com as melhores práticas de mercado e da legislação consumerista.' },
    { level1: '4.3 Os anúncios serão exibidos juntamente com os demais constantes na plataforma, sempre que houver uma busca pelo tipo de imóvel oferecido pelo ANUNCIANTE. ' },
    { level1: '4.4 Os anúncios serão padronizados e poderão contar com algum tipo de destaque dependendo da escolha comercial do anunciante, em relação aos demais. A organização dos anúncios exibidos seguirá critérios estabelecidos pelo KOORT, visando proporcionar a melhor experiência aos usuários da Plataforma.' },
    { level1: '4.5 O KOORT poderá deixar de exibir os anúncios da ANUNCIANTE, parcial ou integralmente, em caso de não observância os Termos de Uso da Plataforma.' },
    { title: '5. DO ANÚNCIO' },
    { level1: '5.1 A Plataforma disponibilizará ao ANUNCIANTE a sessão apropriada para o anúncio do imóvel desejada, devendo o anúncio ser realizado dentro da respectiva sessão.' },
    { level1: '5.2 O ANUNCIANTE deverá, quando do anúncio, prestar todas as informações corretas, para fins de caracterizar o imóvel da forma mais fidedigna possível.' },
    { level2: '5.2.1 O valor do imóvel anunciado, seja para venda ou locação, deverá ser certo e conter todos os tributos sobre ele incidentes. A inexatidão de tal informação, por não ser de responsabilidade da Plataforma, não impede a publicação do anúncio, devendo o ANUNCIANTE se responsabilizar pelas controvérsias geradas pelas informações divergentes. ' },
    { level2: '5.2.2 A disposição gráfica do anúncio ficará a critério do ANUNCIANTE, desde que tal possibilidade seja abrangida pelo Plano de Anúncio escolhido e não viole o Termo de Uso da Plataforma. ' },
    { level2: '5.2.3 O ANUNCIANTE somente poderá anunciar imóveis de sua propriedade ou aqueles sobres os quais possua o consentimento do proprietário, enquanto agente imobiliário, de oferecer ao mercado.' },
    { title: '6. DA REMUNERAÇÃO DA PLATAFORMA' },
    { level1: 'A remuneração do KOORT será exclusivamente advinda do plano de anúncio contratado pelo ANUNCIANTE' },
    { title: '7. DO PAGAMENTO E MARCO INICIAL DO ANÚNCIO CONTRATADO' },
    { level1: '7.1 O anúncio será veiculado após a escolha do plano de anúncio disponibilizado pela Plataforma e a confirmação do pagamento realizado pelo ANUNCIANTE.' },
    { level1: '7.2 Caso o pagamento seja efetuado pelo cartão de crédito, a Plataforma possui o prazo de até 24 (vinte e quatro) horas, após a confirmação da compra, para veicular o anúncio. ' },
    { level1: '7.3 Os pagamentos realizados poderão ser acompanhados pelo ANUNCIANTE na área disponível em seu acesso ao sistema.' },
    { title: '8. DURAÇÃO DO CONTRATO E RENOVAÇÃO DO PLANO' },
    { level1: '8.1 O anúncio permanecerá na Plataforma durante o período escolhido pelo ANUNCIANTE quando da escolha do plano de anúncio.' },
    { level1: '8.2 É dever do ANUNCIANTE comunicar ao KOORT quando o imóvel, por qualquer motivo, não estiver mais disponível. Recebida a comunicação, a Plataforma excluirá o anúncio em até 48 (quarenta e oito) horas.' },
    { level1: '8.3 Se o ANUNCIANTE optar por retirar o anúncio antes do encerramento do plano contratado, sem que o mesmo tenha sido vendido ou locado, a Plataforma não fará qualquer espécie de reembolso ou abatimento.' },
    { level1: '8.4 Encerrado o período contratado pelo ANUNCIANTE, o plano poderá ser renovado de forma automática, desde que tal opção seja escolhida pelo ANUNCIANTE e a opção de pagamento tenha sido através do cartão de crédito. ' },
    { level1: '8.5 Sendo escolhida pelo ANUNCIANTE a renovação automática, a Plataforma realizará o lançamento no cartão de crédito indicado.' },
    { level1: '8.6 A Plataforma não se responsabiliza, em caso de renovação automática, por anúncios de imóveis do ANUNCIANTE que já não estejam mais disponíveis. A Plataforma seguirá a opção escolhida pelo ANUNCIANTE, sendo obrigação do ANUNCIANTE comunicar a venda, locação ou indisponibilidade do imóvel antes de ocorrer a renovação automática. ' },
    { level1: '8.7 Não havendo a comunicação de que trata o item 8.6 e ocorrendo a renovação automática, o ANUNCIANTE não será reembolsado pelo lançamento ocorrido em seu cartão de crédito.' },
    { level1: '8.8 O término do anúncio ocorrerá com a venda ou locação do imóvel, após a Plataforma ser comunicada pelo ANUNCIANTE, ou após o encerramento do plano de anúncio contratado. ' },
    { level1: '8.9 A Plataforma se reserva ao direito de excluir anúncios que desrespeitem os termos do presente instrumento e o Termo de Uso do KOORT.' },
    { level1: '8.10 O ANUNCIANTE que optar pelo plano de anúncio disponível para acima de 10 (dez) imóveis, poderá manter na Plataforma um sistema flexível de inserção ou retirada de imóveis, desde que não extrapole o número de imóveis do plano contratado. ' },
    { title: '9. DAS OBRIGAÇÕES DO KOORT' },
    { level1: 'Além daquelas explicitas e implícitas que constem no presente contrato, bem como as constantes no TERMO DE USO, são obrigações do KOORT:' },
    { level2: 'a)	A manutenção da Plataforma e dos sistemas que componentes.' },
    { level2: 'b)	Zelar pela segurança da Plataforma, contratando ferramentas capazes de mitigar a ocorrência de fraudes ou crimes digitais praticados contra o comércio eletrônico, mas não garantindo a plena eficácia de tais ferramentas, tendo em vista a dinâmica das estratégias de burla aos meios de segurança digital.' },
    { level2: 'c)	Repassar ao ANUNCIANTE as demandas dos compradores que lhe sejam direcionadas, fornecendo as informações disponíveis.' },
    { level2: 'd)	Assumir todas as obrigações e riscos decorrentes de suas atividades empresariais, tais como obrigações fiscais, administrativas, trabalhistas, dentre outras, que são de sua inteira e exclusiva responsabilidade.' },
    { title: '10. DAS OBRIGAÇÕES DO ANUNCIANTE' },
    { level1: '10.1 Além daquelas explicitas e implícitas que constem no presente contrato, bem como no TERMO DE USO, são obrigações do ANUNCIANTE:' },
    { level2: 'a)	Realizar seus anúncios de acordo com o Código de Defesa do Consumidor (CDC), fornecendo condições claras, precisas e integrais da oferta, disponibilidade, forma e prazo da execução do serviço ou da entrega ou disponibilização do produto, restrições de uso, riscos à saúde e segurança, se houver, entre outras informações que as melhores práticas comerciais possam incentivar.' },
    { level2: 'b)	Atender aos Compradores que, diretamente ou por meio do KOORT, solicitarem informações ou fizerem reclamações, não podendo contrariar, em nenhuma hipótese, a legislação consumerista ou as boas práticas comerciais.' },
    { level2: 'c)	Atender às demandas dos Usuários da Plataforma, diretamente ou por meio do KOORT, no menor tempo possível, não podendo, em qualquer caso, este tempo ser superior que 48 (quarenta e oito) horas para primeiro contato e tentativa de solução e que 15 (quinze) dias para solução completa da questão.' },
    { level2: 'd)	Assumir todas as obrigações decorrentes da comercialização ou aluguel dos imóveis, tais como obrigações fiscais, administrativas, trabalhistas, dentre outras, que são de sua inteira e exclusiva responsabilidade.' },
    { level2: 'e)	Cumprir integralmente todos os dispositivos contidos nos termos de uso, políticas, manuais, tutoriais ou orientações recebidas através da Plataforma, inclusive as relativas ao material fotográfico que será inserido na oferta.' },
    { level2: 'f)	Fornecer material fotográfico e especificações técnicas para anúncio do produto.' },
    { level2: 'g)	Responder, perante os Compradores ou Locadores, exclusivamente, por todo e qualquer tipo de dano causado pelos imóveis comercializados, seja em decorrência de vício, seja em decorrência de defeito do mesmo.' },
    { level1: '10.2 Em caso de descumprimento, pelo ANUNCIANTE, das condições pactuadas com o Comprador ou Locatário, o ANUNCIANTE se compromete a realizar o reembolso dos valores pagos pelo Comprador ou Locatário, a qualquer título.' },
    { title: '11. DOS DADOS PESSOAIS' },
    { level1: '11.1 Os dados pessoais fornecidos pelos usuários do KOORT serão utilizados de acordo com o disposto em sua Política de Privacidade, não sendo repassados ao ANUNCIANTE, exceto aqueles dados pessoais indispensáveis para a conclusão ou manutenção do negócio jurídico.' },
    { level1: '11.2 O ANUNCIANTE se compromete a dar adequado tratamento aos dados pessoais recebidos, não os utilizando para qualquer outra finalidade que não seja a da conclusão ou manutenção do negócio jurídico.' },
    { title: '12. DA PRODUÇÃO DE MATERIAL FOTOGRÁFICO' },
    { level1: '12.1 O KOORT não produz, diretamente ou por meio de terceiros, o material fotográfico dos imóveis que serão comercializados na Plataforma Marketplace.' },
    { title: '13. DO DIREITO DE REGRESSO' },
    { level1: '13.1 As partes garantem entre si o direito de regresso, ou seja, sempre que uma parte for responsabilizada por ação ou omissão da outra deverá ser reembolsada em até 5 (cinco) dias da data de recebimento de notificação neste sentido, sob pena de multa de 10% dez por cento) sobre o valor devido, sem prejuízo de apuração de perdas e danos.' },
    { title: '14. GARANTIA E RESPONSABILIDADE' },
    { level1: '14.1 O ANUNCIANTE concorda em proteger e isentar o KOORT, ou ainda indenizar esta, por quaisquer danos morais ou materiais sofridos no âmbito da comercial, não se limitando a, qualquer reclamação, reivindicação ou intervenção de terceiros, de qualquer natureza, judicial ou extrajudicial, incluindo, mas não se limitando a quaisquer bens ou serviços e respectivas marcas que estejam amparados por proteção legal, relativamente a propriedade intelectual, industrial, sujeitas a violação de imagem e da privacidade, de pessoas, dados e documentos, arcando com todas as despesas necessárias à defesa dos interesses do KOORT, inclusive honorários advocatícios.' },
    { level1: '14.2 O KOORT não é proprietário dos imóveis oferecidos pelo ANUNCIANTE e não realiza oferta de vendas na relação entre ANUNCIANTE e Comprador ou Locatário. ' },
    { title: '15. DA CONFIDENCIALIDADE' },
    { level1: '15.1 São consideradas INFORMAÇÕES CONFIDENCIAIS, e assim doravante denominadas, todas e quaisquer informações que forem divulgadas por uma das partes à outra, seja verbalmente, por escrito, por meio eletrônico ou por qualquer outra forma de transmissão tangível, e que por determinação de uma ou ambas as partes, em razão de suas características essenciais ou em virtude de circunstâncias fáticas, não possam ser tornadas públicas, devendo ser abrigadas pela confidencialidade e pelo sigilo, na forma disposta neste instrumento.' },
    { level1: '15.2 A receptora das informações compromete-se a:' },
    { level2: 'a)	Zelar pela manutenção do sigilo e confidencialidade de todas as INFORMAÇÕES CONFIDENCIAIS que eventualmente venham a ter ciência ou acesso, ou que lhe venha a ser confiadas por qualquer razão, durante a vigência da contratação dos serviços oferecidos.' },
    { level2: 'b)	Fazer com que seus sócios, empregados, prepostos, consultores, contratados, diretores, representantes ou quaisquer outras pessoas sob sua responsabilidade (direta ou indireta), ou ainda quaisquer outras pessoas de empresas relacionadas, mantenham sob sigilo e confidencialidade todas as INFORMAÇÕES CONFIDENCIAIS a que tiverem acesso.' },
    { level2: 'c)	Não divulgar a terceiros, revelar, comercializar, reproduzir ou de qualquer modo dispor das INFORMAÇÕES CONFIDENCIAIS recebidas da outra parte sobre ela própria (a outra parte) e sobre terceiros, salvo autorização prévia e por escrito da outra parte.' },
    { level2: 'd)	Não fazer ou permitir que sejam feitas cópias das INFORMAÇÕES CONFIDENCIAIS, salvo se autorizado previamente e por escrito pela outra parte.' },
    { level2: 'e)	Não utilizar as INFORMAÇÕES CONFIDENCIAIS para interesses estranhos à consecução dos objetivos eventualmente ajustados, sejam tais interesses próprios e/ou de qualquer outra pessoa ou empresa.' },
    { level2: 'f)	Tomar todas as precauções de segurança razoáveis para proteger a integridade e confidencialidade das INFORMAÇÕES CONFIDENCIAIS.' },
    { level2: 'g)	Não divulgar a terceiros a existência do presente Termo ou de seu objeto sem o consentimento previamente escrito da outra parte.' },
    { level1: '15.3 A receptora responderá solidariamente pelos danos, eventualmente causados, por atos de seus sócios, colaboradores, prepostos, contratados ou terceiros sob sua responsabilidade à outra parte ou a terceiros.' },
    { level1: '15.4 Em caso de necessidade de utilização ou revelação a terceiros de INFORMAÇÕES CONFIDENCIAIS, a receptora deverá obter, para tal fim, autorização prévia e por escrito da outra parte, exceto quando se der por determinação judicial.' },
    { level1: '15.5 Em caso de necessidade de utilização ou revelação das INFORMAÇÕES CONFIDENCIAIS em razão da lei ou de ordem judicial e/ou da Administração Pública, a parte que for assim obrigada a utilizar ou revelar as INFORMAÇÕES CONFIDENCIAIS deverá notificar a outra parte, imediatamente e por escrito, da obrigatoriedade da divulgação, antes mesmo de mencionada divulgação, para que a outra parte possa tomar as providências necessárias para garantir a confidencialidade das INFORMAÇÕES, utilizando-se para tanto das medidas, quer sejam judiciais ou não, aplicáveis ao caso, para que obtenha êxito na salvaguarda dos direitos de sigilo e confidencialidade aqui estabelecidos.' },
    { level1: '15.6 A receptora concorda em instruir seus respectivos sócios, diretores, administradores, funcionários, empregados, contratados, subcontratados, consultores, prepostos e representantes, conjuntamente a seguir designados “REPRESENTANTES”, que recebam ou tenham acesso às INFORMAÇÕES CONFIDENCIAIS, a manter e a não revelar ou usar as INFORMAÇÕES CONFIDENCIAIS de qualquer forma que não para a consecução dos objetivos eventualmente ajustados. A receptora concorda, ainda, em restringir o conhecimento das INFORMAÇÕES CONFIDENCIAIS ao menor número possível de REPRESENTANTES e, em todo caso, restringir tal conhecimento àqueles REPRESENTANTES que estiverem diretamente ligados aos objetivos eventualmente estabelecidos entre as partes.' },
    { level1: '15.7 As partes concordam que a confidencialidade disciplinada neste acordo impõe obrigações de fazer e de não fazer, sendo cabível a execução específica destas obrigações para evitar ou remediar a violação do presente acordo, podendo a parte que tiver suas INFORMAÇÕES CONFIDENCIAIS reveladas proceder na forma disciplinada pelo Código de Processo Civil Brasileiro, sem prejuízo das demais medidas.' },
    { level1: '15.8 Excetuam-se da definição de INFORMAÇÕES CONFIDENCIAIS as informações:' },
    { level2: 'a)	Que tenham sido publicadas ou tenham se tornado de domínio público, desde que tal fato não tenha ocorrido por atos ou omissões das partes.' },
    { level2: 'b)	Que tenham sido fornecidas ou trazidas ao conhecimento das partes por terceiros, que não atuam direta ou indiretamente em nome das partes, informações estas divulgadas legalmente e sem restrição quanto à sua utilização ou revelação.' },
    { level2: 'c)	Que já estejam em seu poder, conforme possa ser demonstrado pelos arquivos existentes, desde que estas informações não sejam objeto de outro contrato de confidencialidade ou obrigação de sigilo das partes entre si.' },
    { level2: 'd)	Que tenham sua divulgação aprovada previamente e por escrito pela parte que a revelou, desde que respeitados os limites e condições dispostos na permissão para divulgação das informações.' },
    { level2: 'e)	Que tenham que ser reveladas em virtude de lei, ordem judicial e/ou da Administração Pública, respeitados os estritos limites da requisição ou determinação e observados os termos dispostos neste instrumento.' },
    { level1: '15.9 As obrigações contraídas referentes à confidencialidade perdurarão por tempo indeterminado, independentemente da data da rescisão, só podendo ser revogadas em comum acordo entre as partes.' },
    { title: '16. DISPOSIÇÕES GERAIS' },
    { level1: '16.1 Os serviços prestados pelo KOORT são fornecidos de acordo com as condições estabelecidas neste instrumento, bem como o TERMO DE USO, sem quaisquer outras garantias expressas ou implícitas de qualquer natureza, incluindo, mas não se limitando, a garantias de comercialidade, de adequação para uma finalidade particular ou de não violação de direitos difusos ou coletivos.' },
    { level1: '16.2 O KOORT não garante que os serviços prestados na Plataforma serão ininterruptos ou isentos de erros, não obstante todos os seus esforços para tanto, muito menos que os mesmos serão prestados indeterminadamente.' },
    { level1: '16.3 A Plataforma poderá ficar indisponível para ações de manutenção, correção ou reformulação técnica ou estética. A notificação do ANUNCIANTE não será obrigatória, mas sempre que possível será realizada através da Plataforma.' },
    { level1: '16.4 A utilização dos serviços não implica em transferência de direitos de qualquer natureza sobre o software, hardware ou outros recursos que o KOORT utilize na prestação dos serviços.' },
    { level1: '16.5 Os custos ou riscos do negócio praticado entre o ANUNCIANTE e o Comprador ou Locatário não são de do KOORT, sendo a Plataforma meramente um meio para divulgação das ofertas do primeiro.' },
    { level1: '16.6 O ANUNCIANTE é responsável integralmente pelo gerenciamento sobre as ofertas, valores anunciados ou outras condições do negócio, devendo estipular tais condições com clareza e cumpri-las fielmente.' },
    { level1: '16.7 O ANUNCIANTE não poderá ceder e, de nenhuma forma, transferir, total ou parcialmente o presente contrato, ou quaisquer direitos decorrentes deste, sem o consentimento por escrito do KOORT.' },
    { level1: '16.8 A omissão ou tolerância das Partes, em exigir o estrito cumprimento dos termos e condições deste Contrato, não constituirá novação ou renúncia, nem afetará os seus direitos, que poderão ser exercidos a qualquer tempo.' },
    { title: '17. DA EXTINÇÃO CONTRATUAL' },
    { level1: '17.1 Este contrato será considerado rescindido, de pleno direito, independentemente de aviso, notificação judicial ou administrativa, em caso de insolvência notória, pedido de recuperação judicial, extrajudicial ou decretação de falência de uma das PARTES, bem como no caso do descumprimento de qualquer disposição do presente contrato e do TERMO DE USO.' },
    { level1: '17.2 As partes poderão rescindir o presente contrato a qualquer tempo, mediante notificação com antecedência mínima de 60 (sessenta) dias, período em que os serviços e repasses deverão ser realizados normalmente.' },
    { title: '18. DO FORO' },
    { level1: '18.1 As partes elegem o foro da Comarca do Rio de Janeiro/RJ para dirimir as questões oriundas do presente contrato. ' },        
];

export default Contract;
