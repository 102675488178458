
import React from 'react';
import propTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Dashboard } from 'styled-icons/octicons/Dashboard';

import { mqTablet } from '../../../../../styled/helpers/helpers';

// import { Edit } from 'styled-icons/boxicons-regular/Edit';

import TooltipButton from '../../../../../components/tooltip-button';

import pathConstants from '../../../../../constants/paths';
import queryStringUtil from '../../../../../utils/query-string/query-string.util';

/*
  global
  window
  alert
*/

const EditButtons = ({
  history,
  realEstate,
}) => {

  const doNothing = () => {};

  return (
    <Wrapper className={"dashboard-btn-edit"}>
      <BTooltipButton
        label='Dashboard do imóvel'
        onClick={ doNothing }
        Icon={ Dashboard }
        iconStyle={ editIconStyle }
      />
    </Wrapper>
  );
};

EditButtons.propTypes = {
  history: propTypes.object,
  realEstate: propTypes.object,
};

EditButtons.defaultProps = {

};

const Wrapper = styled.div`
  position: absolute;
  top: 5px;
  right: 0;

  ${ mqTablet`
    align-self: flex-end;
  ` }
`;

const BTooltipButton = styled(TooltipButton)``;

const editIconStyle = css`
  height: 20px;
`;

export default withRouter(EditButtons);
