import React, { useEffect, useState } from "react";
import { PiSealCheckFill, PiHeart, PiHeartFill, PiCaretRightBold, PiCaretLeftBold } from "react-icons/pi";
import currencyUtil from '../../../../../utils/currency/currency.util';
import pathConstants from '../../../../../constants/paths';
import userService from "../../../../../services/user/user.service";
import userFavoriteService from "../../../../../services/user/favorite/user.favorite.service";
import { CardContainer, M2Icon, BedroomsIcon } from './styles'
import { useAuth0 } from '@auth0/auth0-react';
import { useDisplay } from "../../../../../contexts/filterContext";

const NEXT_IMG = "next"
const PREVIOUS_IMG = "previous"

export function RealEstateCard(realEstate) {
  const { isFilterVisible, isMapVisible, isMobileDevice } = useDisplay();

  const { loginWithRedirect } = useAuth0();  
  const [realEstateProps, setRealEstateProps] = useState({...realEstate.realEstate})
  const [imageIndex, setImageIndex] = useState(0)
  const [showImageControl, setShowImageControl] = useState(false)

  const handleImageURL = (url) => {
    return url.replace('{width}', '316')
  }
  
  const handleResultClick = (selectedRealEstate) => {
    const win = window.open(`${pathConstants.AD_DETAIL}/${selectedRealEstate.slug}`, "_blank");
    win.focus();
  };

  const addToFavorite = async (listingId, realEstateId) => {
    const user = userService.retrieve()

    if (!user) {
      const pathRedirect = `${pathConstants.FILTER}${window.location.search}`;
      localStorage.setItem('@Koort:PathRedirect', pathRedirect);

      loginWithRedirect({ authorizationParams: { redirect_uri: `${window.location.origin}/authentication/login/success?login=true` } });
    } else {
      setRealEstateProps({...realEstateProps, favorite: !realEstateProps.favorite})

      try {
        const result = await userFavoriteService.patch({
          listingId: listingId || realEstateId,
          token: user.token,
        });
        userFavoriteService.save({
          listingIds: result,
        });
      } catch (error) {
        console.error(error);
      }
    }
  }

  function handleImageIndex(event, imageList, action) {
    event.stopPropagation();
    
    if (!imageList) {
      return
    }

    if (action === NEXT_IMG) {
      showNextImage(imageList)
    } 
    
    if (action === PREVIOUS_IMG) {
      showPreviousImage(imageList)
    }
  }

  function showNextImage(imageList) {
    if (imageIndex === imageList.length - 1) {
      setImageIndex(0)
    } else {
      setImageIndex(state => state + 1)
    }
  }

  function showPreviousImage(imageList) {
    if (imageIndex === 0) {
      setImageIndex(imageList.length - 1)
    } else {
      setImageIndex(state => state - 1)
    }
  }

  return (
    <CardContainer isFilterVisible={isFilterVisible} isMapVisible={isMapVisible}>
      <div 
        className="cover" 
        onClick={() => handleResultClick(realEstateProps)} 
        onMouseEnter={() => setShowImageControl(true)}
        onMouseLeave={() => setShowImageControl(false)}
      >
        { realEstateProps.listing === "Lançamento" && <span className="releaseTag"><PiSealCheckFill />Lançamento</span> }
        { (isMobileDevice || (showImageControl && realEstateProps.images.length > 0)) &&
          <div className="imageActions">
            <span onClick={(e) => handleImageIndex(e, realEstateProps.images, PREVIOUS_IMG)}><PiCaretLeftBold size={28} /></span>
            <span onClick={(e) => handleImageIndex(e, realEstateProps.images, NEXT_IMG)}><PiCaretRightBold size={28} /></span>
          </div>
        }
        <img src={handleImageURL(realEstateProps.images[imageIndex].url)} alt="" />
        <div className="priceContainer">
          <p>
            {
              (realEstateProps.price.main[0] !== 0 && realEstateProps.listing === "Lançamento") && 
              "A partir de" 
            }
          </p>
          
          <strong className="price">
            {
              realEstateProps.price.main === 0 ?
              'Sob consulta' : 
              `${currencyUtil.format({ value: realEstateProps.price.main })}`
            }
          </strong>
        </div>
        
      </div>
      <div className="infos">
        <div className="neighborhood">
          <h3 onClick={() => handleResultClick(realEstateProps)}>{realEstateProps.address.neighborhood}</h3>
          <div onClick={() => addToFavorite(realEstateProps.listingId, realEstateProps.realEstateId, realEstateProps.slug)}>
            {
              realEstateProps.favorite 
              ?
              <PiHeartFill size={24} /> 
              :
              <PiHeart size={24} />
            }
          </div>
        </div>
        <div className="otherInfos" onClick={() => handleResultClick(realEstateProps)}>
          <span>{realEstateProps.property}</span>
          <span>{realEstateProps.address.city}</span>
          <div className="roomsAndArea">
            <div>
              <M2Icon />
              <span>
                {
                  realEstateProps.area[realEstateProps.area.length - 1] !== 0 ?
                  `${realEstateProps.area[realEstateProps.area.length - 1]} m²` :
                  "Consultar"
                }
              </span>
            </div>
            <div>
              <BedroomsIcon />
              <span>
                {
                  realEstateProps.bedrooms[realEstateProps.bedrooms.length - 1] !== 0 ?
                  realEstateProps.bedrooms[realEstateProps.bedrooms.length - 1] :
                  "Consultar"
                }
              </span>
            </div>
          </div>
        </div>
      </div>
    </CardContainer>
  )
}