import React, { useState, useEffect } from 'react';
import { mqTablet } from '../../styled/helpers/helpers';
import { RiAddLine } from 'react-icons/ri';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { RealtorCard } from './realtor-card';
import queryStringUtil from '../../utils/query-string/query-string.util';
import { BaseLabel } from '../../styled/labels/labels';
import Cover from './cover/Cover';
import listingV2Service from '../../services/listing/listing-v2.service';
import windowUtil from '../../utils/window/window.util';
import { addMaskCellphone, addMaskTelephone } from '../../utils/add-masks';
import domEventsUtil from '../../utils/dom-events/dom-events.util';
import eventsConstants from '../../constants/events';

import './cover.css';

const RealtorsSearch = ({ history }) => {
  const [realtors, setRealtors] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState({});

  const { city, district, professionalType } = queryStringUtil.parse(history);

  const search = async (city, district, professionalType) => {
    try {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: true },
      });

      var { data } = await listingV2Service.getProfessionalsBySearch({
        city: city,
        neighborhood: district,
        userTypes: professionalType ? professionalType : '3, 4, 5',
        page: currentPage,
      });

      const dataSerialized = data.items.map(item => {
        return {
          ...item,
          profile: {
            ...item.profile,
            professionalInformation: {
              ...item.profile.professionalInformation,
              cellphoneFormatted: !!item.profile.professionalInformation.cellphone.number ?
                addMaskCellphone(item.profile.professionalInformation.cellphone.number).substring(4) : '',
              telephoneFormatted: !!item.profile.professionalInformation.telephone.number ?
                addMaskTelephone(item.profile.professionalInformation.telephone.number).substring(4) : '',
            }
          }
        };
      });

      setRealtors([...realtors, ...dataSerialized]);
      setPagination(data.pagination);

      if (dataSerialized.length === 0) {
        toast.error("Não foi encontrado nenhum resultado para a busca.")
      }

      if (currentPage === 1) {
        windowUtil.scrollToElement({
          element: "realtor-search-page-wrapper",
          options: {
            offset: -100,
          },
        });
      }

    } catch {
      toast.error('Houve um erro ao carregar os dados, tente novamente!', {
        autoClose: false,
      });
    } finally {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: false },
      });
    }
  };

  useEffect(() => {
    search(city, district, professionalType);
  }, [city, district, currentPage, professionalType]);

  function clearRealtorData() {
    setRealtors([]);
    setPagination({});
    setCurrentPage(1);
  }

  return (
    <div>
      <Cover history={history} handlePressFindRealtors={() => clearRealtorData()} />
      {realtors && realtors.length > 0 &&
        <>
          <Wrapper id="realtor-search-page-wrapper">
            <SectionTitle>
              Sua busca retornou {pagination.total} {pagination.total > 1 ? "resultados" : "resultado"}
            </SectionTitle>
            <RealtorsList>
              {realtors.map((realtor) => (
                <li key={realtor._id}>
                  <RealtorCard {...realtor} />
                </li>
              ))}

              {!!pagination.page && currentPage < Number(pagination.page.split('/')[1]) &&
                <LoadMoreRealtors>
                  <button
                    type="button"
                    onClick={() => setCurrentPage(currentPage + 1)}
                  >
                    <RiAddLine size={32} />
                    Carregar mais
                  </button>
                </LoadMoreRealtors>
              }
            </RealtorsList>

          </Wrapper>
        </>
      }
    </div>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 500px;
	margin: 10px auto;

  ${mqTablet`
    width: 100%;
		max-width: 1000px;
    align-self: center;
  ` }

@media (max-width: 1050px) {
    padding: 0 24px;
  }
`;

const RealtorsList = styled.ul`
	display: flex;
  padding-left: 0;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;

  > li {
    @media (max-width: 1040px) {
    flex: 1;
  }
  }
`;

const SectionTitle = styled(BaseLabel)`
font-weight: 700;
width: 100%;
font-size: 24px;
margin-bottom: 30px;
margin-top: 20px;
`;

const LoadMoreRealtors = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 320px;
  min-height: 160px;

button {
  background-color: transparent;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;


  font-size: 18px;
  color: #3578E5;

  transition: filter .2s;

  > svg {
    margin-bottom: 16px;
  }

  &:hover {
    filter: brightness(0.8);
  }
}
`;

export default RealtorsSearch;
