const propertyUsesItems = [
  {
    label: 'Selecione um uso',
    value: 'Selecione um uso',
  },
  {
    label: 'Residencial',
    value: 'Residencial',
  },
  {
    label: 'Comercial',
    value: 'Comercial',
  },
];

const propertyTypesItems = [
  {
    label: 'Selecione um tipo',
    value: 'Selecione um tipo',
  },
  {
    label: 'Apartamento',
    value: 'Apartamento',
  },
  {
    label: 'Apartamento Garden',
    value: 'Apartamento Garden',
  },
  {
    label: 'Casa',
    value: 'Casa',
  },
  {
    label: 'Casa de Condomínio',
    value: 'Casa de Condomínio',
  },
  {
    label: 'Chácara/Sítio/Fazenda',
    value: 'Chácara | Sítio | Fazenda',
  },
  {
    label: 'Cobertura',
    value: 'Cobertura',
  },
  {
    label: 'Flat',
    value: 'Flat',
  },
  {
    label: 'Kitnet',
    value: 'Kitnet',
  },
  {
    label: 'Terreno',
    value: 'Terreno',
  },
  {
    label: 'Edifício',
    value: 'Edifício',
  },
  {
    label: 'Galpão/Depósito/Armazém',
    value: 'Galpão | Depósito | Armazém',
  },
  {
    label: 'Loja/Ponto Comercial',
    value: 'Loja | Ponto Comercial',
  },
  {
    label: 'Sala Comercial',
    value: 'Sala',
  },
];

export { propertyUsesItems, propertyTypesItems }
