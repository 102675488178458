import React from 'react';
import styled, { css } from 'styled-components';
import { RiLinksLine } from "react-icons/ri";

import { mqTablet } from '../../../../../styled/helpers/helpers';

import TooltipButton from '../../../../../components/tooltip-button';

/*
  global
  window
  alert
*/

export function AttachBrokerButton({
  onAttachPropertyToBroker,
}) {

  const handleClickButton = () => {
    onAttachPropertyToBroker();
  };

  return (
    <Wrapper className={"dashboard-btn-view"}>
      <BTooltipButton
        label='Vincular esse imóvel'
        onClick={handleClickButton}
        Icon={RiLinksLine}
        iconStyle={linksIconStyle}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
  font-size: 80px;
  user-select: none;

  top: 5px;
  right: 0;

  ${mqTablet`
    align-self: flex-end;
  ` }
`;

const BTooltipButton = styled(TooltipButton)``;

const linksIconStyle = css`
  height: 20px;
`;
