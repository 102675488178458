import React, { useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Bold } from '../../styled/labels/labels';

import { InputBootstrap } from '../../components/form/input-bootstrap';
import { CellphoneInput } from '../../components/form/cellphone-input';

import messageService from '../../services/message/message.service';
import userService from '../../services/user/user.service';

import pathsConstants from '../../constants/paths';
import eventsConstants from '../../constants/events';
import domEventsUtil from '../../utils/dom-events/dom-events.util';
import { addMaskCellphone } from '../../utils/add-masks';

import {
  Wrapper,
  Title,
  Subtitle,
  Form,
  SendButton,
} from './styles';

const customId = "custom-id-yes";

const contactFormSchema = yup.object().shape({
  name: yup.string()
    .required('Este campo é obrigatório')
    .min(3, 'Mínimo de 10 caracteres')
    .max(30, 'Máximo de 2000 caracteres'),
  email: yup.string()
    .required('Este campo é obrigatório')
    .email('Informe um e-mail válido'),
  telephone: yup.string()
    .required('Este campo é obrigatório')
    .min(19, 'Informe 11 dígitos')
});

export const Incorporation = () => {
  const { register, handleSubmit, formState, control, setValue } = useForm({
    resolver: yupResolver(contactFormSchema),
  });

  const { errors } = formState;
  const handleFormSubmit = useCallback(async values => {
    const params = (new URL(document.location)).searchParams;

    const type = params.get('type');
    const subject = params.get('subject');

    if (!type || !subject) {
      return;
    }

    try {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: true },
      });

      const formData = {
        name: values.name,
        email: values.email,
        phone: values.telephone,
        type,
        subject,
      };

      const user = userService.retrieve();

      const data = await messageService.sendMessage({
        data: formData,
        token: user.token
      });


      if (data && !String(data.status).startsWith('20')) {
        throw new Error(data);
      }

      const userProfile = await userService.getUserProfile({
        token: user.token,
      });

      const userData = {
        ...userProfile,
        token: user.token,
      }

      localStorage.setItem("user", JSON.stringify(userData));



      toast.info('Formulário enviado com sucesso!', {
        autoClose: 3000,
        toastId: customId,
      });

      setTimeout(() => {
        window.open('/', '_self');
      }, 2000)
    } catch (error) {
      toast.error('Houve um erro ao realizar a solicitação, tente novamente!', {
        autoClose: false,
        toastId: customId,
      });
    } finally {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: false },
      });
    }
  }, [document.location]);

  useEffect(() => {
    const user = userService.retrieve();

    setValue('name', user.name);
    setValue('email', user.email);

    if (user.profile.professionalInformation.cellphone.number) {
      setValue('telephone', addMaskCellphone(user.profile.professionalInformation.cellphone.number));
    }
  }, []);

  return (
    <Wrapper>
      <Title>Anuncie com o <Bold>KOORT</Bold></Title>
      <Subtitle>
        Nossa equipe de vendas tem os melhores planos para sua empresa.<br /> Entraremos em contato para oferecer a melhor proposta para divulgação de seu portfólio.
      </Subtitle>
      <Form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="mb-3">
          <InputBootstrap
            name="name"
            label="Nome"
            placeholder="Nome"
            error={errors.name}
            {...register('name')}
          />
        </div>

        <div className="mb-3">
          <InputBootstrap
            name="email"
            label="E-mail"
            placeholder="E-mail"
            error={errors.email}
            {...register('email')}
          />
        </div>

        <CellphoneInput
          name="telephone"
          label="Telefone"
          error={errors.telephone}
          control={control}
          {...register('telephone')}
        />

        <br />
        <SendButton>enviar</SendButton>
      </Form>
    </Wrapper>
  )
}
