import styled from 'styled-components';

import { backgrounder, mqTablet } from '../../../../../../../styled/helpers/helpers';
import { BaseLabel } from '../../../../../../../styled/labels/labels';
import { COLOR_16, COLOR_BORDER } from '../../../../../../../styled/variables/variables';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${mqTablet`
    flex-direction: row;
  `}
`;

export const PictureWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  ${mqTablet`
    width: 300px;
    margin-right: 20px;
  `}
`;

export const Picture = styled.div`
  ${({ url }) => backgrounder(url)}
  border-radius: 1px;
  width: 100%;
  height: 300px;
  border: 1px solid ${COLOR_16};
  margin-bottom: 5px;

  ${mqTablet`
    height: 200px;
  `}
`;

export const TabletRightSideWrapper = styled.div`
  ${mqTablet`
    flex-grow: 1;
    width: 1px;
  `}
`;

export const InfoWrapper = styled.div`
  padding: 20px 10px;
  height: 235px;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  position: relative;
`;

export const PropertyLabel = styled(BaseLabel)`
  font-size: 12px;
  margin-bottom: 10px;
`;

export const TagBadge = styled.div`
  float: left;
  padding: 5px 10px 5px 10px;
  background: #0d6efd;
  color: #fff;
  text-align: center;
  margin-right: 10px;
  font-weight: bold;
  font-size: 11px;
`;

export const Title = styled(PropertyLabel)`
  font-weight: 700;
  font-size: 24px;
`;

export const Neighborhood = styled(PropertyLabel)`
  font-size: 14px;
  margin: 0;
`;

export const FirstSectionDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${COLOR_BORDER};
  margin: 15px 0;
`;

export const SecondSectionDivider = styled(FirstSectionDivider)``;
