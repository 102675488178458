
import Base from '../base/base.model';

export const TYPES = {
  PICTURE: 'PICTURE',
  FLOOR_PLAN: 'FLOOR_PLAN',
  PANORAMA: 'PANORAMA',
  SLIDES: 'SLIDES',
};

class Image extends Base {
  constructor({
    id,

    description,
    size,
    title,
    type,
    url,
  }) {
    super({ id, });

    this.description = description;
    this.size = size;
    this.title = title;
    this.type = type;
    this.url = url;
  }
}

export default Image;
