import styled, {css} from 'styled-components';

export const FloatingCTABox = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 80px;
  margin-top: -10px;
  margin-left: 28px;

  @media (max-width: 1200px) {
  margin-right: 12px;
  }

  @media (max-width: 515px) {
      margin-right: 12px;
    }
  @media (max-width: 400px) {
      margin-right: 8px;
    }
`;

export const FloatingCTABoxContent = styled.div`
  box-shadow: 1px 1px 20px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
  max-width: 800px;
  height: 80px;
  padding: 20px 24px;

  display: flex;
  flex-direction: row;

  background: linear-gradient(0deg, #000 50%, #fff 50%);
  background-size: 100% 200%;
  transition: background-position 0.2s;

  ${({ isTop, isMozillaNavigator }) => isTop && css`
    max-width: 1000px;
    background-position: 0 100%;
    padding: 20px 0px;

    ${isMozillaNavigator && css`
      padding-left: 24px;
      margin-left: -24px;
    `}

    @media (max-width: 1200px) {
      max-width: 860px;

      ${isMozillaNavigator && css`
        max-width: 880px;
      `}
    }

    @media (max-width: 1030px) {
      max-width: 830px;

      ${isMozillaNavigator && css`
        max-width: 860px;
      `}
    }

    @media (max-width: 1010px) {
      max-width: 810px;

      ${isMozillaNavigator && css`
        max-width: 840px;
      `}
    }

    @media (max-width: 992px) {
      max-width: 780px;

      ${isMozillaNavigator && css`
        max-width: 820px;
      `}
    }

    @media (max-width: 958px) {
      max-width: 750px;

      ${isMozillaNavigator && css`
        max-width: 782px;
      `}
    }

    @media (max-width: 930px) {
      max-width: 724px;

      ${isMozillaNavigator && css`
        max-width: 760px;
      `}
    }

    @media (max-width: 896px) {
      max-width: 694px;

      ${isMozillaNavigator && css`
        max-width: 724px;
      `}
    }

    @media (max-width: 868px) {
      max-width: 664px;

      ${isMozillaNavigator && css`
        max-width: 694px;
      `}
    }

    @media (max-width: 840px) {
      max-width: 636px;

      ${isMozillaNavigator && css`
        max-width: 670px;
      `}
    }

    @media (max-width: 808px) {
      max-width: 604px;

      ${isMozillaNavigator && css`
        max-width: 638px;
      `}
    }

    @media (max-width: 775px) {
      max-width: 590px;

      ${isMozillaNavigator && css`
        max-width: 624px;
      `}
    }

    @media (max-width: 734px) {
      max-width: 560px;

      ${isMozillaNavigator && css`
        max-width: 590px;
      `}
    }

    @media (max-width: 710px) {
      max-width: 534px;

      ${isMozillaNavigator && css`
        max-width: 564px;
      `}
    }

    @media (max-width: 679px) {
      max-width: 504px;

      ${isMozillaNavigator && css`
        max-width: 534px;
      `}
    }

    @media (max-width: 644px) {
      max-width: 470px;

      ${isMozillaNavigator && css`
        max-width: 500px;
      `}
    }

    @media (max-width: 612px) {
      max-width: 440px;

      ${isMozillaNavigator && css`
        max-width: 470px;
      `}
    }

    @media (max-width: 580px) {
      max-width: 406px;

      ${isMozillaNavigator && css`
        max-width: 436px;
        margin-left: 12px;
      `}
    }

    @media (max-width: 545px) {
      max-width: 370px;

      ${isMozillaNavigator && css`
        max-width: 408px;
      `}
    }

    @media (max-width: 515px) {
      max-width: 350px;

      ${isMozillaNavigator && css`
        max-width: 380px;
      `}
    }

    @media (max-width: 488px) {
      max-width: 314px;

      ${isMozillaNavigator && css`
        max-width: 344px;
      `}
    }

    @media (max-width: 453px) {
      max-width: 300px;

      ${isMozillaNavigator && css`
        max-width: 344px;
      `}
    }

    @media (max-width: 446px) {
      max-width: 270px;

      ${isMozillaNavigator && css`
        max-width: 300px;
      `}
    }

    @media (max-width: 408px) {
      max-width: 240px;

      ${isMozillaNavigator && css`
        max-width: 260px;
      `}
    }
  `}

  ${({ isTop }) => !isTop && css`
    @media (max-width: 1080px) {
      max-width: calc(100vw - 48px);
    }

    @media (max-width: 535px) {
      height: 100px;
      bottom: -100px;
    }

    @media (max-width: 474px) {
      flex-direction: column;
      height: 140px;
      bottom: -100px;
    }
  `}
`;

export const PriceGroup = styled.div`
  display: flex;
  align-items: center;

  width: max-content;

  button {
    background-color: transparent;
    font-size: 0;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.8);
    }
  }

  div {
    margin-left: 24px;

    color: #000000;
    font-size: 12px;
    font-weight: 400;

    strong {
      font-size: 16px;
      font-weight: 700;
    }
  }

  @media (max-width: 474px) {
    width: 100%;
    justify-content: center;
  }

  ${({ isTop }) => isTop && css`
    div {
      color: #fff;
      margin-left: 16px;

      strong {
        color: #fff;
      }
    }

    @media (max-width: 515px) {
      margin-right: 18px;

      div {
        strong {
          font-size: 12px;
        }
      }
    }

    @media (max-width: 474px) {
      margin-right: 18px;
      justify-content: unset;
    }
  `}
`;

export const SeparatorLine = styled.div`
  width: 1px;
  height: 30px;
  background: rgba(0, 0, 0, 0.10);
  margin: auto 42px;

  @media (max-width: 474px) {
    display: none;
  }

  ${({ isTop }) => isTop && css`
    @media (max-width: 840px) {
      margin: auto 24px;
    }

    @media (max-width: 775px) {
      margin: auto 12px;
    }

    @media (max-width: 734px) {
      display: none;
    }
  `}
`;

export const CompanyGroup = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 120px;
    height: 80px;
    object-fit: cover;
    margin-right: 24px;

    ${({ isTop }) => isTop && css`
      height: 60px;

        @media (max-width: 1040px) {
          display: none;
        }
    `}
  }

  span {
    color: #000000;
    font-size: 12px;

    strong {
      font-size: 14px;
    }

    ${({ isTop }) => isTop && css`
    color: #fff;

    strong {
      color: #fff;
    }
  `}
  }

  @media (max-width: 900px) {
    img {
      display: none;
    }
  }

  @media (max-width: 760px) {
    display: none;
  }

  ${({ isTop }) => isTop && css`
    @media (max-width: 734px) {
      display: none;
      }
  `}
`;

export const CTAButton = styled.button`
  margin-left: auto;
  padding: 12px 32px;
  background-color: #96D22D;

  color: #FFF;
  font-size: 14px;
  font-weight: 700;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }

  @media (max-width: 474px) {
   margin-left: unset;
   margin-top: 16px;
  }

  ${({ isTop }) => isTop && css`
    @media (max-width: 515px) {
      padding: 12px 14px;
      font-size: 14px;
    }

    @media (max-width: 488px) {
      height: 48px;
      margin-top: -4px;
    }

    @media (max-width: 453px) {
      width: 200px;
    }
  `}

`;
