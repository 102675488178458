
import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';

import { BaseLabel } from '../../../styled/labels/labels';
import { mqDesktop } from '../../../styled/helpers/helpers';

const TextSection = ({
  title,
  content,
  className,
}) => (
  <Wrapper className={ className }>
    <Title>{ title }</Title>
    <Content>{ content }</Content>
  </Wrapper>
);

TextSection.propTypes = {
  title: propTypes.string,
  content: propTypes.string,
  className: propTypes.string,
};

const Wrapper = styled.div`
  margin-bottom: 30px;

  ${ mqDesktop`
    
  ` }
`;

const Title = styled(BaseLabel)`
  font-size: 14px;
  font-weight: 700;
  display: block;
  margin-bottom: 10px;
`;

const Content = styled(Title).attrs({
  as: 'p',
})`
  font-weight: 300;
  margin: 0;
`;

export default TextSection;
