import styled, { css } from 'styled-components';
import PulseLoader from 'react-spinners/PulseLoader';
import { Range } from 'rc-slider';
import { transitions } from 'polished';

import { COLOR_1, COLOR_2, COLOR_16, COLOR_4 } from '../variables/variables';
import { BaseInput } from '../inputs/inputs';
import { backgrounder } from '../helpers/helpers';
import 'rc-slider/assets/index.css';

/*
  global
  process
*/

const { PUBLIC_URL } = process.env;

export const ProfilePicture = styled.div`
  ${({ pictureUrl }) => {
    const random = Math.random();

    if (pictureUrl) {
      return backgrounder(`${pictureUrl}?${random}`);
    }
    return backgrounder(`${PUBLIC_URL}/imgs/profile-placeholder-white.png`);
  }}
  height: 140px;
  width: 120px;
  border-radius: 1px;
  border: solid 1px ${COLOR_16};
  background-color: white;
`;

export const OvalProfilePicture = styled(ProfilePicture)`
  border-radius: 10px;
  height: 140px;
  width: 100px;
  background-position-y: -10px;
  background-size: 182px;
`;

export const EditableLabel = styled(BaseInput)`
  padding: 0 10px;
  appearance: none;
  font-size: 16px;
  height: 40px;
  margin-bottom: 10px;

  ${transitions([
    'background-color ease .3s .6s',
    'color ease .3s .6s',
    'border ease .3s .6s',
    'padding ease .3s .6s',
    'margin ease .3s',
    'height ease .3s',
  ])}

  ${({ isEditing }) =>
    !isEditing &&
    css`
      ${transitions([
        'background-color ease .3s',
        'color ease .3s',
        'border ease .3s',
        'padding ease .3s',
        'margin ease .3s .6s',
        'height ease .3s .6s',
      ])}

      color: ${COLOR_2};
      background-color: transparent;
      border-color: transparent;
      padding: 0px;
      margin: 0px;
    `}
`;

export const KoortWhiteVerticalLogo = styled.img.attrs({
  src: `${PUBLIC_URL}/imgs/koort-logo-white-vertical.svg`,
})`
  height: 100px;
  width: 90px;
  padding: 10px;
  ${transitions(['height', 'width', 'padding'], 'ease .6s')};
`;

export const KoortBlueVerticalLogo = styled.img.attrs({
  src: `${PUBLIC_URL}/imgs/koort-logo-blue-vertical.svg`,
  alt: 'Logo Koort Imóveis',
})`
  height: 100px;
  width: 90px;
  padding: 10px;
  ${transitions(['height', 'width', 'padding'], 'ease .6s')};
`;

export const KoortLogoHorizontal = styled.img.attrs({
  src: `${PUBLIC_URL}/imgs/koort-logo-horizontal.svg`,
})``;

export const KoortMarker = styled.img.attrs({
  src: `${PUBLIC_URL}/imgs/koort-marker.svg`,
})``;

export const KoortMarkerGreen = styled(KoortMarker).attrs({
  src: `${PUBLIC_URL}/imgs/koort-marker-green.svg`,
})``;

export const KoortMarkerWhiteOutline = styled(KoortMarker).attrs({
  src: `${PUBLIC_URL}/imgs/koort-marker-white-outline.svg`,
})``;

export const KoortMarkerOrangeOutline = styled(KoortMarker).attrs({
  src: `${PUBLIC_URL}/imgs/koort-marker-orange-outline.svg`,
})``;

export const Spacer = styled.div`
  height: ${({ height }) => height || 100}px;
`;

export const RangeSlider = styled(Range).attrs({
  allowCross: false,
  pushable: true,
  dots: false,
})`
  height: 18px !important;
  margin: 5px 0 10px 0;
  .rc-slider-dot {
    display: none;
  }
  .rc-slider-rail {
    margin-top: 4px;
    height: 1px;
    background-color: #b2b2b2;
    &.rc-slider-step {
      height: 8px;
    }
  }
  .rc-slider-track {
    background-color: #111111;
    margin-top: 4px;
    &.rc-slider-track-1 {
      height: 1px;
    }
  }
  .rc-slider-handle {
    border-radius: 1px;
    border-color: #2b2b2b;
    background-color: #2b2b2b;
    &:focus,
    &:hover,
    &:active {
      border-color: #2b2b2b;
    }
    &:active {
      box-shadow: 0 0 10px #2b2b2b;
    }
    &.rc-slider-handle-1,
    &.rc-slider-handle-2 {
      height: 20px;
      width: 20px;
    }
  }
  .rc-slider-mark {
    .rc-slider-mark-text {
      /* left: unset !important; */
      transform: none !important;
      left: 0;
      &:last-child {
        left: unset !important;
        right: 0;
      }
    }
  }
`;

export const Divider = styled.div`
  width: 100%;
  margin: 20px 0;
  height: 1px;
  background-color: ${COLOR_4};
  border-radius: 1px;
`;

export const LoadingAnimation = styled(PulseLoader).attrs(props => ({
  css: props.css || css``,
}))``;

export default {
  OvalProfilePicture,
  Divider,
  EditableLabel,
  KoortWhiteVerticalLogo,
  KoortLogoHorizontal,
  KoortMarker,
  KoortMarkerWhiteOutline,
  KoortMarkerOrangeOutline,
  LoadingAnimation,
  RangeSlider,
  Spacer,
};
