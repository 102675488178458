import React from 'react';
import styled, { css } from 'styled-components';
import propTypes from 'prop-types';
import { transitions } from 'polished';
import { Menu } from 'styled-icons/feather/Menu';

import { COLOR_2, COLOR_3 } from '../../../../styled/variables/variables';
import { iconBaseStyle, pointer } from '../../../../styled/helpers/helpers';

const Drawer = ({ highlight, onClick, scrolled }) => (
  <Wrapper highlight={ highlight } onClick={ onClick }>
    <Icon scrolled={ scrolled } />
  </Wrapper>
);

Drawer.propTypes = {
  highlight: propTypes.bool.isRequired,
  onClick: propTypes.func.isRequired,
  scrolled: propTypes.bool,
};

const Wrapper = styled.div`
  align-self: center;
  ${ pointer };
  padding: 15px 15px;
  margin-right: -10px;
  ${ transitions([ 'background-color' ], 'ease .3s') };

  ${ ({ highlight }) => highlight && css`
    background-color: ${ COLOR_2 };
    svg {
      color: ${ COLOR_3 };
    }
  ` };
`;

const Icon = styled(Menu)`
  ${ iconBaseStyle };
  color: ${COLOR_2};
  height: 30px;
  ${ transitions([ 'color' ], 'ease .3s') };
`;

export default Drawer;
