import React from 'react';
import propTypes from 'prop-types';

import { Wrapper, IconWrapper, Icon, Input } from './styles';

const Checkbox = ({
  checked,
  className,
  id,
  name,
  onClick,
}) => (
  <Wrapper className={className}>
    <IconWrapper>
      <Icon show={checked} />
    </IconWrapper>
    <Input
      id={id}
      name={name}
      checked={checked}
      value={checked ? 'on' : 'off'}
      onClick={onClick}
    />
  </Wrapper>
);

Checkbox.propTypes = {
  checked: propTypes.bool.isRequired,
  className: propTypes.string,
  id: propTypes.string,
  name: propTypes.string,
};

Checkbox.defaultProps = {

};

export default Checkbox;
