import styled, { css } from 'styled-components';
import { transitions } from 'polished';
import PulseLoader from 'react-spinners/PulseLoader';

import { COLOR_5, COLOR_3 } from '../../styled/variables/variables';
import { dimmer } from '../../styled/helpers/helpers';


export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  ${ ({ opacify }) => {
    if(opacify) return dimmer();
    else return `background-color: ${ COLOR_3 }`;
  } };
  display: flex;
  flex-direction: column;
  justify-content: center;
  

  ${ ({ show }) => show ? css`
    width: 100%;
    opacity: 1;
    ${ transitions([ 'opacity ease 1s', 'width 0s 0s' ]) };
  ` : css`
    opacity: 0;
    width: 0%;
    ${ transitions([ 'opacity ease 1s', 'width 0s 1s' ]) };
  ` };
`;

export const LoadingSpinner = styled(PulseLoader).attrs({
  color: COLOR_5,
  css: css`
    align-self: center;
  `,
})``;