import httpClient from '../../clients/http/http.client';

/**
 * global
 * FormData
 * Promise
 */

const subscribeLead = async (
    params,
) => {

    const path = '/subscribers'
    const response = await httpClient.post({ path, params });

    return response;
};


export default {
    subscribeLead
};
