
// Dom events
export const DASHBOARD_CONTENT_LOADING = 'dashboard_loading_content';
export const LOADING_ANIMATION = 'loading_animation';
export const SCROLL = 'scroll';
export const TOUCH_MOVE = 'touchmove';
export const TOUCH_START = 'touchstart';
export const KEY_UP = 'keyup';
export const DRAG_FILE_ENTER = 'dragenter';
export const DRAG_FILE_LEAVE = 'dragleave';

// App events
export const AUTH_STATUS_CHANGED = 'auth_status_changed';
export const NEW_SEARCH = 'new_search';
export const SIGN_OUT = 'sign_out';

export default {
  AUTH_STATUS_CHANGED,
  DASHBOARD_CONTENT_LOADING,
  DRAG_FILE_ENTER,
  DRAG_FILE_LEAVE,
  KEY_UP,
  LOADING_ANIMATION,
  SCROLL,
  NEW_SEARCH,
  SIGN_OUT,
  TOUCH_MOVE,
  TOUCH_START,
};
