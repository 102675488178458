import React, { PureComponent } from 'react';
import propTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import DragSortableList from 'react-drag-sortable';

import { Bold } from '../../styled/labels/labels';

import eventsConstants from '../../constants/events'
import domEventsUtil from '../../utils/dom-events/dom-events.util';

import {
  Wrapper,
  InnerWrapper,
  HiddenInput,
  GalleryAddButton,
  ImagesIcon,
  PanoramaIcon,
  UploadIcon,
  GalleryAddButtonLabel,
  SelectedImageWrapper,
  PDPanorama,
  SelectedImage,
  GalleryWrapper,
  GalleryImage,
  GalleryImageDim,
  DimTrashIcon,
} from './styles';

/*
  setTimeout
  clearTimeout
  FileReader
  alert
*/

class ImageDropGallery extends PureComponent {
  constructor(props) {
    super(props);
    this.hiddenInputRef = React.createRef();
    this.galleryAddButtonRef = React.createRef();

    this.state = {
      images: [],
      imagesRemoteRemoved: [],
      imagesHasPopulated: false,
      selectedImage: 0,
      galleryAddButtonFileHover: false,
    };
  }

  galleryAddButtonTimeout;
  handleGalleryAddButtonDragEnter = () => {
    clearTimeout(this.galleryAddButtonTimeout);

    this.setState({
      ...this.state,
      galleryAddButtonFileHover: true,
    });
  }

  handleGalleryAddButtonDragLeave = () => {
    this.galleryAddButtonTimeout = setTimeout(() => {
      this.setState({
        ...this.state,
        galleryAddButtonFileHover: false,
      });
    }, 2000);
  };

  handleDrop = async files => {
    clearTimeout(this.galleryAddButtonTimeout);

    if (this.state.images.length > 0 && !this.props.multipleFiles) {
      return;
    }

    domEventsUtil.dispatch({
      name: eventsConstants.LOADING_ANIMATION,
      params: { show: true }
    });


    const reader = new FileReader();

    const length = files.length;

    let index = 0;
    reader.onload = () => {

      const images = [...this.state.images];
      images.push({
        dataUrl: reader.result,
        blob: files[index],
      });

      this.setState({
        ...this.state,
        images,
      }, async () => {
        if (index === length - 1) {
          domEventsUtil.dispatch({
            name: eventsConstants.LOADING_ANIMATION,
            params: { show: false }
          });
          setTimeout(() => this.setState(state => ({
            ...state,
            galleryAddButtonFileHover: false,
            selectedImage: 0,
          })), 1000);
          const { images } = this.state;
          const { onUpdate } = this.props;
          onUpdate(images);
          return;
        }

        index++;
        reader.readAsDataURL(files[index]);
      });
    };

    reader.readAsDataURL(files[index]);
  };

  handleSort = elements => {
    const images = elements.map(element => {
      const index = element.content.props.index;
      return this.state.images[index];
    });

    this.setState(state => ({
      ...state,
      images,
    }), () => {

      const { onUpdate } = this.props;
      onUpdate(images);
    });
  };

  handleImageClick = selectedImage => {
    this.setState(state => ({
      ...state,
      selectedImage,
    }));
  }

  handleRemoveClick = index => {
    const { isEditing, onUpdate, onDelete } = this.props;
    const { images, selectedImage } = this.state;

    if (isEditing) {
      const imageFounded = images.find((_, position) => index === position);

      if (!!imageFounded.isRemote) {
        this.setState(prevState => ({
          ...prevState,
          imagesRemoteRemoved: [...prevState.imagesRemoteRemoved, imageFounded]
        }));
      }
    }

    const updatedImageList = [...images];
    updatedImageList.splice(index, 1);

    this.setState(state => ({
      ...state,
      images: updatedImageList,
      selectedImage: updatedImageList[selectedImage] ?
        selectedImage :
        selectedImage - 1,
    }), () => {
      const { images, imagesRemoteRemoved } = this.state;
      onUpdate(images);

      if (this.props.isEditing) {
        onDelete(imagesRemoteRemoved);
      }
    });
  };

  render() {
    const { className, isPanorama, populateImages } = this.props;
    const {
      images,
      galleryAddButtonFileHover,
      selectedImage
    } = this.state;

    if (!!populateImages) {
      if (!this.state.images.length &&
        !!populateImages.length &&
        !this.state.imagesHasPopulated) {
        const imagesSerialized = populateImages.map(item => ({
          isRemote: true,
          dataUrl: item.replace('{width}', 1024),
        }));

        this.setState(prevState => ({
          ...prevState,
          images: imagesSerialized,
          imagesHasPopulated: true,
        }));
      }
    }

    return (
      <Wrapper className={className}>
        <Dropzone onDrop={this.handleDrop}>
          {({ getRootProps, getInputProps }) => (
            <InnerWrapper {...getRootProps()}>
              <HiddenInput
                ref={this.hiddenInputRef}
                {...getInputProps()}
                accept='image/x-png,image/jpeg'
              />
              <GalleryAddButton
                ref={this.galleryAddButtonRef}
                fileHover={galleryAddButtonFileHover}
                onDragEnter={this.handleGalleryAddButtonDragEnter}
                onDragLeave={this.handleGalleryAddButtonDragLeave}
              >
                {!isPanorama && (
                  <ImagesIcon show={!galleryAddButtonFileHover} />
                )}
                {isPanorama && (
                  <PanoramaIcon show={!galleryAddButtonFileHover} />
                )}
                <UploadIcon show={galleryAddButtonFileHover} />
                <GalleryAddButtonLabel>Clique ou <Bold>arraste e solte aqui</Bold>.</GalleryAddButtonLabel>
              </GalleryAddButton>
            </InnerWrapper>
          )}
        </Dropzone>
        {images[selectedImage] && (
          <SelectedImageWrapper>
            {isPanorama && (
              <PDPanorama
                url={images[selectedImage].dataUrl}
              />
            )}
            {!isPanorama && (
              <SelectedImage
                url={images[selectedImage].dataUrl}
              />
            )}
          </SelectedImageWrapper>
        )}
        <GalleryWrapper>
          <DragSortableList
            onSort={this.handleSort}
            dropBackTransitionDuration={0.3}
            type='grid'
            items={
              images.map((i, index) => ({
                content: (
                  <GalleryImage
                    src={i.dataUrl}
                    index={index}
                    onClick={() => this.handleImageClick(index)}
                  >
                    <GalleryImageDim
                      onClick={event => {
                        event.stopPropagation();
                        this.handleRemoveClick(index);
                      }}
                    >
                      <DimTrashIcon />
                    </GalleryImageDim>
                  </GalleryImage>
                ),
              }))
            }
          />
        </GalleryWrapper>
      </Wrapper>
    );
  }
}

ImageDropGallery.propTypes = {
  onUpdate: propTypes.func,
  multipleFiles: propTypes.bool,
  className: propTypes.string,
  isPanorama: propTypes.bool,
};

ImageDropGallery.defaultProps = {
  onUpdate: () => alert('Lista atualizada.'),
  multipleFiles: true,
  isPanorama: false,
};

export default ImageDropGallery;
