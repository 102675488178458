import React, { forwardRef } from 'react';

import { ErrorMessage } from '../error-message';

const SelectBase = ({
  name,
  error = null,
  label,
  options,
  ...rest
}, ref) => {
  return (
    <>
      <div className="form-floating">
        <select
          className="form-select"
          id={name}
          name={name}
          ref={ref}
          {...rest}
        >
          {options.map(({ value, label, }) => (
            <option key={value} value={value}> {label} </option>
          ))}
        </select>
        <label htmlFor={name}>{label}</label>
      </div>

      {error && <ErrorMessage message={error.message} />}
    </>
  );
}

export const SelectBootstrap = forwardRef(SelectBase);
