import React, { useEffect, useState } from 'react';
import { RiCloseLine, RiSettings3Fill, RiDeleteBin6Line } from 'react-icons/ri';
import { toast } from 'react-toastify';
import Select from 'react-select';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import { GeneralStatistics } from './components/general-statistics';
import { Chart } from './components/chart';
import { BrokersTable } from './components/brokers-table';
import { LeadsTable } from './components/leads-table';

import userService from '../../services/user/user.service';
import leadsService from '../../services/leads/leads.service';

import {
  GroupButtons,
  OriginLeadsSelect,
  SettingsButton,
  Container,
  Title,
  ChartAndBrokersContainer,
  UserSettingsModal,
  UserSettingsModalBody,
  UserSettingsModalHeader,
  UserSettingsModalContent,
  GroupSelect,
  Label,
  UserSettingsActionFeedback,
  UserSettingsModalSectionTitle,
  UserSettingsModalNotificationArea,
  C2SFormWrapper,
  C2SLink,
} from './styles';

const customStyle = {
  control: base => ({
    ...base,
    border: '1px solid #999',
    boxShadow: 'none',
    background: 'transparent',

  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: 16,
  }),
  input: (provided, state) => ({
    ...provided,
    fontSize: 25,
  }),
}

export function DashboardLeadsContent() {
  // broker ou realEstateAgency
  const [isBrokerOrRealEstateAgency, setIsBrokerOrRealEstateAgency] = useState(null);

  const [userSettingsModalVisible, setUserSettingsModalVisible] = useState(false);

  const [receiveLeadsBy, setReceiveLeadsBy] = useState([]);
  const [c2sIntegrationData, setC2SIntegrationData] = useState(null);

  const [c2sEmail, setC2SEmail] = useState('');

  const [submitUserSettingsText, setSubmitUserSettingsText] = useState('');
  const [submitUserSettingsSuccess, setSubmitUserSettingsSuccess] = useState(false);
  const [submitUserSettingsError, setSubmitUserSettingsError] = useState(false);
  const [submitUserSettingsInProgress, setSubmitUserSettingsInProgress] = useState(false);

  const [leadsOriginsData, setLeadsOriginsData] = useState([]);
  const [leadsOriginSelected, setLeadsOriginsSelected] = useState('koort');

  const receiveLeadsOptions = [
    {
      label: 'email',
      value: 'email',
    },
    {
      label: 'whatsapp',
      value: 'whatsapp',
    }
  ]

  useEffect(() => {
    function verifyUserType() {
      const user = userService.retrieve();

      if (user.userType === 3) {
        setIsBrokerOrRealEstateAgency('broker');
      } else if (user.userType > 3) {
        setIsBrokerOrRealEstateAgency('realEstateAgency');
      } else {
        // Não tem acesso à página
        // window.open('/', '_self');
      }
    }

    verifyUserType();
  }, []);

  useEffect(() => {
    async function getUserSettings() {
      try {
        const user = userService.retrieve();

        const { status, data } = await leadsService.getUserSettingsNotifications({
          userId: user._id,
          token: user.token,
        });

        if (status !== 200) {
          throw new Error('Não foi possível buscar suas configurações, tente novamente!');
        }

        const receiveLeadsSerialized = data.settings.receiveLeadsBy.map(item => {
          return {
            label: item,
            value: item,
          }
        });

        setReceiveLeadsBy(receiveLeadsSerialized);
        setC2SIntegrationData(data.settings.c2s);
        setC2SEmail(data.settings.c2s.email);
      } catch (err) {
        toast.error(err.message);
      }
    }

    if (isBrokerOrRealEstateAgency !== null) {
      getUserSettings();
    }
  }, [isBrokerOrRealEstateAgency]);

  useEffect(() => {
    async function getLeadsOrigins() {
      try {
        const user = userService.retrieve();

        const { status, data } = await leadsService.getLeadsOriginsFromLoggedUser({
          token: user.token,
        });


        if (status !== 200) {
          throw new Error('Não foi possível buscar as origens dos leads, recarregue a página!')
        }

        const params = (new URL(document.location)).searchParams;

        const originParam = params.get('origin');

        setLeadsOriginsData(data);
        setLeadsOriginsSelected(originParam)
      } catch (err) {
        toast.error(err.message, {
          autoClose: 5000,
        });
      }
    }

    getLeadsOrigins();
  }, []);

  function handleOpenUserSettingsModal() {
    setUserSettingsModalVisible(true);
  }

  function handleCloseUserSettingsModal() {
    setUserSettingsModalVisible(false);
  }

  async function handleChangeReceivedLeads(options) {
    try {
      setSubmitUserSettingsInProgress(true);
      setSubmitUserSettingsText('Salvando...');

      const user = userService.retrieve();

      const response = await leadsService.updateUserSettingsNotifications({
        receiveLeadsMode: options.map(item => item.value),
        token: user.token,
      });

      const { status, response: responseError } = response;

      if (status !== 204) {
        if (!!responseError && responseError.data.message === "It's necessary to add a cellphone with whatsapp in your account to use this option.") {
          throw new Error('Adicione seu celular para utilizar essa opção!')
        } else {
          throw new Error('Tente novamente em instantes!')
        }
      }

      setReceiveLeadsBy(options);

      setSubmitUserSettingsInProgress(false);
      setSubmitUserSettingsSuccess(true);
      setSubmitUserSettingsText('Alterações salvas com sucesso!');

      setTimeout(() => {
        setSubmitUserSettingsText('');
        setSubmitUserSettingsSuccess(false);
      }, 2000);
    } catch (err) {
      setSubmitUserSettingsInProgress(false);
      setSubmitUserSettingsText(err.message);
      setSubmitUserSettingsError(true);

      setTimeout(() => {
        setSubmitUserSettingsText('');
        setSubmitUserSettingsError(false);
      }, 2000);
    }
  }

  async function handleEnableIntegrateC2S(event) {
    event.preventDefault();

    try {
      setSubmitUserSettingsInProgress(true);
      setSubmitUserSettingsText('Salvando...');

      const user = userService.retrieve();

      const response = await leadsService.enableC2SIntegration({
        email: c2sEmail,
        token: user.token,
      });

      const { status, response: responseError } = response;

      if (status !== 204) {
        if (!!responseError && responseError.data.message === "Integration already enabled.") {
          throw new Error('Integração já habilitada para este e-mail!');
        } else if (!!responseError && responseError.data.errors.includes("email must contain a @contact2sale.com string")) {
          throw new Error('E-mail precisa conter o domínio @contact2sale.com!');
        } else {
          throw new Error('Tente novamente em instantes!');
        }
      }

      setSubmitUserSettingsInProgress(false);
      setSubmitUserSettingsSuccess(true);
      setSubmitUserSettingsText('Alterações salvas com sucesso!');

      setC2SIntegrationData(prevState => {
        return {
          ...prevState,
          isEnabled: true,
        }
      });

      setTimeout(() => {
        setSubmitUserSettingsText('');
        setSubmitUserSettingsSuccess(false);
      }, 2000);
    } catch (err) {
      setSubmitUserSettingsInProgress(false);
      setSubmitUserSettingsText(err.message);
      setSubmitUserSettingsError(true);

      setTimeout(() => {
        setSubmitUserSettingsText('');
        setSubmitUserSettingsError(false);
      }, 2000);
    }
  }

  async function handleDisableC2SIntegration() {
    if (window.confirm("Tem certeza que deseja desabilitar a integração com o C2S?")) {
      try {
        setSubmitUserSettingsInProgress(true);
        setSubmitUserSettingsText('Excluindo...');

        const user = userService.retrieve();

        const response = await leadsService.disableC2SIntegration({
          token: user.token,
        });

        const { status } = response;

        if (status !== 204) {
          throw new Error('Tente novamente em instantes!');
        }

        setSubmitUserSettingsInProgress(false);
        setSubmitUserSettingsSuccess(true);
        setSubmitUserSettingsText('Alterações salvas com sucesso!');

        setC2SEmail('');
        setC2SIntegrationData({
          email: '',
          isEnabled: false,
        });

        setTimeout(() => {
          setSubmitUserSettingsText('');
          setSubmitUserSettingsSuccess(false);
        }, 2000);
      } catch (e) {
        setSubmitUserSettingsInProgress(false);
        setSubmitUserSettingsText(err.message);
        setSubmitUserSettingsError(true);

        setTimeout(() => {
          setSubmitUserSettingsText('');
          setSubmitUserSettingsError(false);
        }, 2000);
      }
    }
  };

  const generalStatisticsReferenceType = isBrokerOrRealEstateAgency === 'realEstateAgency' ? 'company' : 'realtor';
  const generalStatisticsReferenceId = userService.retrieve()._id;

  function handleChangeLeadsOrigins(origin) {
    if (origin === leadsOriginSelected) {
      return;
    }

    const url = new URL(window.location);
    url.searchParams.set('origin', origin);
    window.history.pushState(null, '', url.toString());
    window.location.reload();
  }

  return (
    <>
      <Container>
        <GroupButtons>
          <OriginLeadsSelect>
            <ButtonGroup
              color="primary"
            >
              {leadsOriginsData.map(item => (
                <Button
                  key={item}
                  variant={item === leadsOriginSelected ? 'contained' : 'outlined'}
                  onClick={() => handleChangeLeadsOrigins(item)}
                >
                  {item}
                </Button>
              ))}
            </ButtonGroup>
          </OriginLeadsSelect>

          <SettingsButton
            type="button"
            title="Configurações"
            onClick={handleOpenUserSettingsModal}
          >
            <RiSettings3Fill size={28} />
          </SettingsButton>
        </GroupButtons>

        <Title>Console de leads</Title>

        <GeneralStatistics
          referenceType={generalStatisticsReferenceType}
          referenceId={generalStatisticsReferenceId}
        />

        <ChartAndBrokersContainer>
          {/* <Chart /> */}

          {isBrokerOrRealEstateAgency === 'realEstateAgency' && (
            <BrokersTable />
          )}
        </ChartAndBrokersContainer>

        <LeadsTable />
      </Container>

      <UserSettingsModal
        isOpen={userSettingsModalVisible}
        onRequestClose={handleCloseUserSettingsModal}
      >
        <UserSettingsModalBody>
          <UserSettingsModalHeader>
            <h3> Gerencie suas configurações </h3>

            <button type="button" onClick={handleCloseUserSettingsModal}>
              <RiCloseLine size={22} />
            </button>
          </UserSettingsModalHeader>

          <UserSettingsModalContent>
            <UserSettingsModalNotificationArea>

              <UserSettingsModalSectionTitle>
                Recebimento de leads:
              </UserSettingsModalSectionTitle>

              <GroupSelect>
                <Label>Quero receber via</Label>
                <Select
                  className="react-select-container"
                  classNamePrefix="react-select"
                  onChange={handleChangeReceivedLeads}
                  options={receiveLeadsOptions}
                  defaultValue={receiveLeadsBy}
                  clearable
                  isMulti
                  placeholder="Selecione"
                  noOptionsMessage={() => 'Sem opções'}
                  styles={customStyle}
                />
              </GroupSelect>

              <GroupSelect>
                <Label>Quero integrar à ferramenta C2S</Label>
                <C2SLink>
                  Obs: para integrar com a ferramenta C2S, informe o e-mail da sua conta e verifique se a conta está ativada.
                  <br /> Saiba mais acessando {' '}
                  <a
                    href="https://www.contact2sale.com"
                    target='_blank'
                    rel="noreferrer noopener"
                  >
                    contact2sale.com
                  </a>
                </C2SLink>

                <C2SFormWrapper onSubmit={handleEnableIntegrateC2S}>
                  <input
                    type="email"
                    required
                    placeholder="example@contact2sale.com"
                    value={c2sEmail}
                    onChange={event => setC2SEmail(event.target.value)}
                  />

                  <div>
                    <button
                      type="submit"
                      disabled={submitUserSettingsInProgress}
                    >
                      Salvar
                    </button>
                    <button
                      type="button"
                      className="removeC2SButton"
                      disabled={!c2sIntegrationData?.isEnabled}
                      onClick={handleDisableC2SIntegration}
                    >
                      <RiDeleteBin6Line size={24} color="#D93025" />
                    </button>
                  </div>
                </C2SFormWrapper>
              </GroupSelect>
            </UserSettingsModalNotificationArea>
          </UserSettingsModalContent>

          <UserSettingsActionFeedback
            isSubmitting={submitUserSettingsInProgress}
            isSuccess={submitUserSettingsSuccess}
            isError={submitUserSettingsError}
          >
            {submitUserSettingsText}
          </UserSettingsActionFeedback>
        </UserSettingsModalBody>
      </UserSettingsModal>
    </>
  )
}
