import React, { forwardRef } from 'react';

import { ErrorMessage } from '../error-message';

import { Input } from './styles';

const InputBase = ({
  name,
  error = null,
  label,
  ...rest
}, ref) => {
  return (
    <>
      <Input
        name={name}
        id={name}
        ref={ref}
        autoComplete="off"
        placeholder="input"
        {...rest}
      />
      {error && <ErrorMessage message={error.message} />}
    </>
  );
}

export const InputNewsletters = forwardRef(InputBase);
