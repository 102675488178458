
import Base from '../base/base.model';

class Address extends Base {
  constructor({
    id,

    city,
    complement,
    country,
    formatted,
    geolocation,
    neighborhood,
    number,
    state,
    street,
    type,
    zipCode,
    zone,
  }) {
    super({ id });

    this.city = city;
    this.complement = complement;
    this.country = country;
    this.formatted = formatted;
    this.geolocation = geolocation;
    this.neighborhood = neighborhood;
    this.number = number;
    this.state = state;
    this.street = street;
    this.type = type;
    this.zipCode = zipCode;
    this.zone = zone;
  }
}

export default Address;
