import React from 'react';
import { CTAButton, CompanyGroup, FloatingCTABox, FloatingCTABoxContent, PriceGroup, SeparatorLine } from './styles';
import { RiHeartFill, RiHeartLine } from 'react-icons/ri';
import currencyUtil from '../../../../../../../utils/currency/currency.util';

export function FloatingBox({
  isFullScreenImageUrl,
  panoramaModalVisible,
  floatingCTAOnTop,
  isMozillaNavigator,
  price,
  incorporation,
  isFavorited,
  onAddOrRemoveFavorite,
  onOpenContactFormModal,
}) {
  return (
    <FloatingCTABox floatingCTAOnTop={floatingCTAOnTop} isTop={isFullScreenImageUrl || panoramaModalVisible}>
      <FloatingCTABoxContent
        id="floating-cta-box"
        isTop={floatingCTAOnTop}
        isMozillaNavigator={isMozillaNavigator}
      >
        <PriceGroup isTop={floatingCTAOnTop}>
          <button type="button" onClick={onAddOrRemoveFavorite} aria-label="Favoritar anúncio">
            {isFavorited ? (
              <RiHeartFill size={24} color={floatingCTAOnTop ? '#fff' : '#000'} />
            ) : (
              <RiHeartLine size={24} color={floatingCTAOnTop ? '#fff' : '#000'} />
            )}
          </button>

          <div>
            A partir de <br />
            <strong>{price.main[0] !== 0 ? currencyUtil.format({ value: price.main[0] }) : 'Sob consulta'}</strong>
          </div>
        </PriceGroup>

        <SeparatorLine isTop={floatingCTAOnTop} />

        <CompanyGroup isTop={floatingCTAOnTop}>
          {!floatingCTAOnTop && <img src={incorporation?.image} alt={incorporation?.name} />}

          <span>Incorporadora <strong>{incorporation?.name}</strong></span>
        </CompanyGroup>

        <CTAButton type="button" onClick={onOpenContactFormModal} isTop={floatingCTAOnTop}>
          {window.innerWidth < Number(520) && floatingCTAOnTop ? 'Contato' : 'Entrar em contato'}
        </CTAButton>
      </FloatingCTABoxContent>
    </FloatingCTABox>
  );
}
