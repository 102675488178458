import React, { useEffect, useRef, useState } from 'react'
import { Container }  from './styles'
import { useDisplay } from '../../../../contexts/filterContext';
import { 
  setRect
} from '../../../../reducers/filterReducer/actions';

import GoogleMapReact from 'google-map-react';
import { useDispatch, useSelector } from 'react-redux';
import listingV2Service from '../../../../services/listing/listing-v2.service';
import { Marker } from './components/marker';
import { mapOptions } from './mapStyleOptions';
import { clusterPoints } from './utils';

export function MapSearch({ realEstates }) {
  const dispatch = useDispatch();
  const { isFilterVisible, setFilterVisible, isMapVisible, setMapVisible, isMobileDevice } = useDisplay();
  const { types, transaction, isRelease, neighborhood, city, minValue, maxValue, minArea, maxArea, bedrooms, bathrooms, suites, parkingSpaces, features, rect } = useSelector(state => state.filter);
  
  const [markerCoordinates, setMarkerCoordinates] = useState(null)
  const [defaultCenter, setDefaultCenter] = useState()
  const [center, setCenter] = useState()
  const [threshold, setThreshold] = useState(0.0001)
  const [isMouseOnTooltip, setIsMouseOnTooltip] = useState(false)
  
  const initialZoom = 11
  const [zoom, setZoom] = useState(initialZoom)

  const createMapOptions = {
    disableDoubleClickZoom: true,
    clickableIcons: false,
    panControl: true,
    mapTypeControl: false,
    scrollwheel: true,
    fullscreenControl: false,
    maxZoom: 18,
    minZoom: 9,
    styles: [
      ...mapOptions
    ]
  }

  const getMarkerCoordinates = async () => {
    try {
      const listingsCoordinates = await listingV2Service.getCoordinates({
        type: types,
        transaction: isRelease ? 1 : transaction.value,
        city,
        neighborhood,
        bedrooms,
        bathrooms,
        suites,
        parkingSpaces,
        area: [minArea, maxArea],
        mainPrice: [minValue, maxValue],
        features,
        rect
      })

      if (listingsCoordinates?.coordinates) {
        const clusters = clusterPoints(listingsCoordinates.coordinates, threshold)
        const markersWithCluster = {...listingsCoordinates, coordinates: clusters}
        addShowTooltipProp(markersWithCluster)
      }
      
      if (!defaultCenter) {
        setDefaultCenter({ lat: listingsCoordinates.longLat[1], lng: listingsCoordinates.longLat[0] })
      } 
    } catch (err) {
      console.error("Não foi possível obter as coordenadas.", err)
    }
  }

  const addShowTooltipProp = (markerCoordinatesData, markerIndex=null) => {
    const coordinatesWithtooltipProp = markerCoordinatesData.coordinates.map((coordinate, index) => {
      if (index === parseInt(markerIndex) && !coordinate.showingTooltip) {
        return {
          ...coordinate,
          showingTooltip: true
        }
      }

      return {
        ...coordinate,
        showingTooltip: false
      }
    })

    setMarkerCoordinates({...markerCoordinatesData, coordinates: coordinatesWithtooltipProp})
  }

  const getCityCoordinates = async () => {
    try {
      const listingsCoordinates = await listingV2Service.getCoordinates({
        city,
        neighborhood
      })

      setCenter({ lat: listingsCoordinates.longLat[1], lng: listingsCoordinates.longLat[0] })
    } catch (err) {
      console.error("Não foi possível obter as coordenadas da cidade.", err)
    }
  }

  async function handleClickMap() {
    if (!isMouseOnTooltip) {
      addShowTooltipProp(markerCoordinates)
      setIsMouseOnTooltip(false)
    }
  }

  async function changeMap(mapProps) {
    new Promise(() => {
      setTimeout(() => {
        dispatch(setRect([
          [mapProps.bounds.ne.lat, mapProps.bounds.ne.lng],
          [mapProps.bounds.sw.lat, mapProps.bounds.sw.lng]
        ]))
      }, 600)
    })

    if (mapProps.zoom !== initialZoom) {
      setZoom(mapProps.zoom)
    }

    setIsMouseOnTooltip(false)
  } 

  const handleClickMarker = (markerIndex) => {
    const clickedCluster = markerCoordinates.coordinates.find((coordinate, index) => parseInt(markerIndex) === index)
    console.log(clickedCluster)

    if (clickedCluster && clickedCluster.cluster.length > 5 && zoom < 18)  {
      setZoom(state => state + 1)
      setCenter({ lat: clickedCluster.coordinates[1], lng: clickedCluster.coordinates[0] })
      return
    }

    if (!isMouseOnTooltip) {
      addShowTooltipProp(markerCoordinates, markerIndex)
    }
  }

  useEffect(() => {
    if (rect) {
      setThreshold((rect[0][0] - rect[1][0] + 0.0001) / 100)
    }
   
    getMarkerCoordinates()
  }, [types, transaction, neighborhood, city, minValue, maxValue, minArea, maxArea, bedrooms, bathrooms, suites, parkingSpaces, features, rect])

  useEffect(() => {
    getCityCoordinates()
    setZoom(initialZoom)
  }, [neighborhood, city])

  return (
    <Container isFilterVisible={isFilterVisible} isMapVisible={isMapVisible} style={{ height: 'calc(100vh - 80px)' }}>
      {
        (markerCoordinates && defaultCenter) && 
        <GoogleMapReact
          options={createMapOptions}
          draggable={!isMouseOnTooltip}
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
          onChange={(mapProps) => changeMap(mapProps)}
          onClick={handleClickMap}
          onChildClick={index => handleClickMarker(index)}
          defaultCenter={defaultCenter}
          defaultZoom={initialZoom}
          zoom={zoom}
          center={center}
        >
          {
            markerCoordinates.coordinates.map((marker, index) => {
              return (
                  <Marker 
                    key={index}
                    marker={marker}
                    isMouseOverTooltip={setIsMouseOnTooltip}
                    lat={marker.coordinates[1]}
                    lng={marker.coordinates[0]} 
                  />
              )
            })
          }
        </GoogleMapReact>
      }
    </Container>
  );
}