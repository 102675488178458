import React from 'react';

import { Container, Button } from './styles';

export function ButtonsOptions({
  option1,
  option2,
  option1Active,
  option2Active,
  option1OnClick,
  option2OnClick,
}) {
  return (
    <Container>
      <Button
        isActive={option1Active}
        onClick={option1OnClick}
      >
        {option1}
      </Button>
      <Button
        isActive={option2Active}
        onClick={option2OnClick}
      >
        {option2}
      </Button>
    </Container>
  );
}
