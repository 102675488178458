import Base from "../base/base.model";

class Profile extends Base {
  constructor({
    id,
    accessData,
    address,
    creci,
    description,
    email,
    experience,
    name,
    phones,
    pictureUrl,
    realEstates,
    reviews,
    role,
    specialties,
    serviceAreas,
    response,
    photos
  }) {
    super({ id });
    this.response = response;
    this.accessData = accessData;
    this.address = address;
    this.creci = creci;
    this.description = description;
    this.email = email;
    this.experience = experience;
    this.name = name;
    this.phones = phones;
    this.pictureUrl = pictureUrl;
    this.realEstates = realEstates;
    this.reviews = reviews;
    this.role = role;
    this.specialties = specialties;
    this.serviceAreas = serviceAreas;
    this.photos = photos;
  }
}

export default Profile;
