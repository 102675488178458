import React from 'react';
import propTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Eye } from "styled-icons/fa-regular/Eye";

import { mqTablet } from '../../../../../styled/helpers/helpers';

import TooltipButton from '../../../../../components/tooltip-button';

/*
  global
  window
  alert
*/

const ViewAdLink = ({
  to,
}) => {

  const doNothing = () => {};

  return (
		<a href={to} target="_blank">
			<Wrapper className={"dashboard-btn-view"}>
				<BTooltipButton 
					label='Visualizar anúncio'
					onClick={ doNothing }
					Icon={ Eye }
					iconStyle={ eyeIconStyle }
				/>
			</Wrapper>
		</a>
  );
};

ViewAdLink.propTypes = {
  history: propTypes.object,
  realEstate: propTypes.object,
};

ViewAdLink.defaultProps = {

};

const Wrapper = styled.div`
  position: absolute;
  top: 68px;
  right: 0px;
  
  ${ mqTablet`
    align-self: flex-end;
  ` }
`;

const BTooltipButton = styled(TooltipButton)``;

const eyeIconStyle = css`
  height: 20px;
`;

export default ViewAdLink;
