import queryString from 'query-string';
import { isNullOrUndefined } from 'util';
import objectUtil from '../object/object.util';

/*
  global
  window
*/

export const update = (history, newParams) => {
  const location = { ...history.location };
  const queryStringObj = {
    ...queryString.parse(location.search),
    ...newParams,
  };
  
  Object.keys(queryStringObj).forEach(key => {
    if(isNullOrUndefined(queryStringObj[key])) delete queryStringObj[key];
  });
  
  history.location.search = queryString.stringify(queryStringObj);
  const { origin } = window.location;
  const { pathname, search } = history.location;
  const url = `${ origin + pathname }?${ search }`;
  
  window.history.replaceState({ path: url, }, '', url);
};

export const parse = history => {
  let obj = queryString.parse(history ? history.location.search : '');
  obj = objectUtil.conformify(obj);
  return obj;
};

export const stringify = (history, newParams) => {
  let objToStringify = { };
  if(history) objToStringify = { ...objToStringify, ...parse(history) };
  if(newParams) objToStringify = { ...objToStringify, ...newParams };
  
  return queryString.stringify(objToStringify);
};

export default {
  parse,
  stringify,
  update,
};
