import styled from 'styled-components';


export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`;

export const WrapperErr = styled.div`
  height: 100%;
  width: 100%;
  text-align: center;
  background: rgba(0,0,0,1);
  color: white;
  font-size: 24px;
  line-height: 250px;
`