
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import ImageDropGallery from '../../../../components//image-drop-gallery';


class Panoramas extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      form: {
        panoramas: [],
        panoramaImagesRemoteRemoved: [],
      },
    };
  }

  handleImageDropGalleryUpdate = panoramas => {
    if (this.props.isEditing) {
      const imagesLocal = panoramas.filter(item => !item.isRemote);

      this.setState(state => ({
        form: {
          ...state.form,
          panoramas: imagesLocal,
        },
      }));
    } else {
      this.setState(state => ({
        form: {
          ...state.form,
          panoramas,
        },
      }));
    }
  };

  handleDeleteRemoteImages = images => {
    this.setState(state => ({
      form: {
        ...state.form,
        panoramaImagesRemoteRemoved: images,
      },
    }));
  }

  render() {
    const { panorama } = this.state;

    return (
      <Wrapper>
        <PImageDropGallery
          onUpdate={this.handleImageDropGalleryUpdate}
          onDelete={this.handleDeleteRemoteImages}
          show={ panorama }
          isPanorama
          populateImages={this.props.populateImages}
          isEditing={!!this.props.isEditing}
        />
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  margin-bottom: 50px;
`;

const PImageDropGallery = styled(ImageDropGallery)``;

export default Panoramas;
