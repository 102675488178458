
import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import Links from './links/Links';
import Info from './info/Info';

import { mqTablet, bigSectionBaseStyle, backgrounder, pointer } from '../../styled/helpers/helpers';
import { COLOR_17 } from '../../styled/variables/variables';
import { KoortBlueVerticalLogo } from '../../styled/components/components';

import SocialIcons from '../../components/social-icons';

import { NewsletterForm } from './newsletter-form';
import stringUtil from '../../utils/string/string.util';
import pathConstants from '../../constants/paths';

/*
  global
  process
*/

const { PUBLIC_URL } = process.env;

const Footer = ({ history }) => (
  <Wrapper>
    <TopWrapper>
      <Logo onClick={() => history.push(pathConstants.HOME)} />
      <Links />
      <FSocialIcons />
    </TopWrapper>
    <NewsletterForm />
    <BottomWrapper>
      <Info />
      <Texture />
    </BottomWrapper>
  </Wrapper>
);

Footer.ID = `${Footer.name}_${stringUtil.generateUniqueId()}`

Footer.propTypes = {
  history: propTypes.object,
};

Footer.defaultProps = {};

const Wrapper = styled.section.attrs({
  id: Footer.ID,
})`
  ${bigSectionBaseStyle}
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  background-color: ${COLOR_17};
  display: flex;
  flex-direction: column;

  ${mqTablet`
    padding-top: 250px;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  ` };
`;

const TopWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${mqTablet`
    flex-direction: row;
    justify-content: space-between;
    align-self: center;
    width: 700px;
  ` }
`;

const Logo = styled(KoortBlueVerticalLogo)`
  width: fit-content;
  height: fit-content;
  align-self: center;
  margin-bottom: 50px;
  ${pointer}
`;

const FSocialIcons = styled(SocialIcons)`
  align-self: center;
  margin-bottom: 50px;

  ${mqTablet`
    & > svg {
      height: 15px;
    }
  ` }
`;

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${mqTablet`
    flex-grow: 1;
    height: 1px;
    justify-content: flex-end;
  ` }
`;

const Texture = styled.div`
  ${backgrounder(`${PUBLIC_URL}/imgs/koort-texture-white.svg`)}
  height: 140px;
  width: 100%;
  background-size: 1040px;
  background-position: top left;
  background-position: -70px 0;
`;

export default withRouter(Footer);
