
import React from 'react';
import styled from 'styled-components';

import { BaseLabel } from '../../../styled/labels/labels';
import { COLOR_1 } from '../../../styled/variables/variables';
import { backgrounder, mqTablet } from '../../../styled/helpers/helpers';

/*
  global
  process
  */

  const { PUBLIC_URL } = process.env;

  const Content = () => (
    <Wrapper>
    <TextBlock>
    KOORT é uma <BlueText>plataforma de negócios para o mercado imobiliário</BlueText>.
    </TextBlock>

    <TextBlock>
    Muito além de um site de anúncios, o KOORT tem por objetivo facilitar os negócios de compra e aluguel de imóveis residenciais, integrando em um só lugar as várias etapas e os diversos agentes de mercado.  
    </TextBlock>
    <TextBlock>
     Alguns dos princípios que nos orientam para atingir essa visão:
    </TextBlock>

    <SectionTitle>
    A adoção rápida e contínua de novas tecnologias se tornou indispensável para inovar no mercado imobiliário.
  </SectionTitle>
  <TextBlock>
    Juntar as pontas de um negócio imobiliário requer o uso de tecnologias avançadas nos mais diversos contextos. Compradores e locatários precisam descobrir o melhor imóvel para morar e investir, enquanto que proprietários precisam encontrar o melhor negócio possível na hora de vender ou alugar. Agentes de mercado como corretores, seguradoras e incorporadoras precisam entender o mercado para planejar suas atividades. Nada disso é simples no mundo <em>off-line</em>, e a tecnologia tem um papel crucial em simplificar e multiplicar os negócios.
  </TextBlock>
  <TextBlock>
    Fazer face a esse desafio requer mais do que combinar produtos de prateleira e boas ideias que olham para um aspecto do mercado sem considerar a sua totalidade. Por isso, o KOORT reúne uma equipe de matemáticos, economistas e estatísticos que estão continuamente desenvolvendo novas ferramentas de ponta -- da experiência do usuário à inteligência artificial por trás das câmeras.
  </TextBlock>

  <SectionTitle>
  Identificar e corrigir imperfeições no funcionamento dos mercados é a chave para criar valor.
</SectionTitle>
<TextBlock>
Um negócio gera valor quando todas as partes saem mais satisfeitas do que estavam antes. Quando funciona bem, o mecanismo de mercado permite que os agentes de mercado se encontrem e descubram soluções mutuamente benéficas. Mas o mercado imobiliário tem fricções que dificultam esse processo e desperdiçam parte do que poderia ter sido criado em valor econômico e satisfação das pessoas.
</TextBlock>

<TextBlock>
Existem várias imperfeições no mercado imobiliário que limitam o seu potencial. Quem quer vender um imóvel descobre que a sua avaliação custa caro e é muito incerta: frequentemente, cinco avaliadores têm cinco respostas diferentes. Já quem quer alugar o seu imóvel descobre que precisa fazer a própria pesquisa de mercado e contar com intuição e sorte. Em ambos casos, quem não pode ficar de plantão mostrando o imóvel descobre que precisa de um corretor, e não sabe como encontrar um. E esses são apenas alguns exemplos. 
</TextBlock>

<SectionTitle>
Um imóvel é um imóvel e seu entorno. Reconhecer esse fato será cada vez mais crucial para agir no mercado imobiliário.
</SectionTitle>
<TextBlock>
Antigamente, um anúncio classificado tentava vender ou alugar um "apartamento de dois quartos em Copacabana, 100 m<sup>2</sup>". Mas um imóvel não é apenas um conjunto de paredes: podem variar inúmeras características internas, bem como do prédio e do condomínio. Mais ainda, importa o entorno do imóvel, o acesso aos meios de transporte e a percepção de valor da região (às vezes até da rua) em qu e se encontra.  
</TextBlock>
<TextBlock>
Essa perspectiva é parte integral do KOORT, desde a experiência de busca até as ferramentas de inteligência artificial. Por exemplo, a KOORTIMATIVA usa um grande número de informações sobre a cidade e como seu imóvel se insere nela, gerando estimativas de quanto vale um imóvel naquele exato lugar.
</TextBlock>


<SectionTitle>
 A plataforma KOORT deve facilitar os negócios, tornando-os mais inteligentes e rápidos.
</SectionTitle>
<TextBlock>
  A visão da KOORT é criar um verdadeiro <em>marketplace</em> -- o lugar onde os mercados se encontram -- unindo as mais diveras partes necessárias para fazer negócios.
</TextBlock>
<TextBlock>
   Uma inovação que o KOORT já está trazendo é incluir o corretor como usuário da plataforma. No KOORT, os corretores terão acesso a oportunidades de negócio com proprietários que desejam vender ou alugar, mas não encontraram corretor ainda. Além disso, os corretores poderão criar seus perfis, acompanhar seus negócios e ter acesso à KOORTIMATIVA para orientar suas estratégias de venda/aluguel. 
</TextBlock>
<TextBlock>
  Mas esse é só o início. A equipe KOORT está trabalhando intensamente para trazer novos produtos e serviços para facilitar os negócios imobiliários, integrando processos e criando novas ferramentas de inteligência.
</TextBlock>

<SectionTitle>
  Aprimorar continuamente experiência do usuário gera  novas oportunidades.
</SectionTitle>
<TextBlock>
  A busca visual no mapa, inovação trazida pelo KOORT ao Brasil, é ao mesmo tempo uma mudança radical na forma de se buscar e encontrar um imóvel, e uma experiência intuitiva, que faz uso do que os usuários já entendem para ajudá-los a encontrar o que realmente querem. Encontrar um imóvel para morar não é mais uma questão de navegar por extensas listagens de anúncios irrelevantes: se você sabe onde quer morar, o KOORT te leva exatamente lá.
</TextBlock>
  <TextBlock>
    Do ponto de vista de quem quer vender ou alugar, aprimorar a experiência de busca     atrai mais olhares relevantes para o seu anúncio: no lugar de competir para tentar aparecer em uma montanha de anúncios classificados, você expõe seu imóvel para quem tem mais chance de acabar fechando negócio.
  </TextBlock>



</Wrapper>
);

const Wrapper = styled.div`
padding: 20px 10px;

${ mqTablet`
width: 100%;
max-width: 700px;
align-self: center;
padding: 80px 10px;
display: flex;
flex-direction: column;
` }
`;

const SectionTitle = styled(BaseLabel)`
font-weight: 700;
font-size: 24px;
margin-bottom: 30px;
margin-top: 20px;
`;

const TextBlock = styled(BaseLabel).attrs({
  as: 'p',
})`
font-size: 16px;
margin-bottom: 20px;
`;

const BlueText = styled.p`
color: ${ COLOR_1 };
font-weight: 700;
display: inline;
`;


export default Content;
