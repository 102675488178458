export const COLOR_1 = '#2B50D8';
export const COLOR_2 = '#FFFFFF';
export const COLOR_3 = '#333333';
export const COLOR_4 = '#83786f';
export const COLOR_5 = '#D1CCC4';
export const COLOR_6 = '#f6861f';
export const COLOR_7 = '#dc3545';
export const COLOR_8 = '#2B2A28';
export const COLOR_9 = '#565451';
export const COLOR_10 = '#F3F1EF';
export const COLOR_11 = '#FBBC4C';
export const COLOR_12 = '#4267b2';
export const COLOR_13 = '#8ACC2C';
export const COLOR_14 = '#2B2B28';
export const COLOR_15 = '#EBEBEB';
export const COLOR_16 = 'lightgray';
export const COLOR_17 = 'black';
export const COLOR_18 = '#F6F6F6';
export const COLOR_19 = '#95211C';
export const COLOR_20 = '#3578E5'; // Facebook

export const COLOR_WHATS_APP_1 = '#1EBEA5';
export const COLOR_GMAIL_1 = '#D93025';
export const COLOR_BORDER = '#707070';
export const COLOR_BORDER_INPUTS = '#AAB0B0';

export const COLOR_1_TRANSLUCID = '#2B50D880';
export const COLOR_BORDER_TRANSLUCID = '#70707080';

export const GOOGLE_MAPS_STYLE = [
  {
    elementType: 'geometry',
    stylers: [
      {
        // "color": "#f5f5f5"
      },
    ],
  },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        // "color": "#616161"
      },
    ],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        // "color": "#f5f5f5"
      },
    ],
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels.text.fill',
    stylers: [
      {
        // "color": "#bdbdbd"
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        // "color": "#eeeeee"
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [
      {
        // "color": "#757575"
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        // "color": "#e5e5e5"
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [
      {
        // "color": "#9e9e9e"
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      {
        // "color": "#ffffff"
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry.stroke',
    stylers: [
      {
        // "color": "#757575"
      },
      {
        saturation: 0,
      },
      {
        lightness: 5,
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.text.fill',
    stylers: [
      {
        // "color": "#757575"
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [
      {
        // "color": "#dadada"
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [
      {
        // "color": "#616161"
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [
      {
        // "color": "#9e9e9e"
      },
    ],
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry',
    stylers: [
      {
        // "color": "#e5e5e5"
      },
    ],
  },
  {
    featureType: 'transit.station',
    elementType: 'geometry',
    stylers: [
      {
        // "color": "#eeeeee"
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        // "color": "#c9c9c9"
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        // "color": "#9e9e9e"
      },
    ],
  },
];

export default {
  // Colors
  COLOR_1,
  COLOR_2,
  COLOR_3,
  COLOR_4,
  COLOR_5,
  COLOR_6,
  COLOR_7,
  COLOR_8,
  COLOR_9,
  COLOR_10,
  COLOR_11,
  COLOR_12,
  COLOR_14,
  COLOR_15,
  COLOR_16,
  COLOR_17,
  COLOR_18,
  COLOR_19,
  COLOR_20,
  COLOR_WHATS_APP_1,
  COLOR_GMAIL_1,
  COLOR_BORDER,

  // Google Maps default theme
  GOOGLE_MAPS_STYLE,
};
