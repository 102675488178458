import styled, { css } from 'styled-components';

import CheckBox from '../../../../components/checkbox';
import InputWrapper from '../../../../components/input-wrapper';

import { BaseButton } from '../../../../styled/buttons/buttons';
import { BaseLabel } from '../../../../styled/labels/labels';
import { COLOR_2, COLOR_4, COLOR_1, COLOR_17 } from '../../../../styled/variables/variables';
import { mqTablet, mqDesktop } from '../../../../styled/helpers/helpers';

export const AdvertiserDataContainer = styled.div`
  margin-top: 50px;

  > div {
    & + div {
      margin-top: 16px;
    }
  }
`;

export const PropertyFeaturesBathroomsInputContainer = styled.div``;

export const PropertyFeaturesBedroomsInputContainer = styled.div`
  margin-right: 15px;

  @media (max-width: 768px) {
    margin-right: unset;
  }
`;

export const PropertyFeaturesContainer = styled.div`
  margin-top: 50px;
`;

export const ValuesContainer = styled.div`
  margin-top: 50px;

  > div {
    & + div {
      margin-top: 16px;
    }
  }
`;

export const HasWhatsappInput = styled(CheckBox).attrs({
  name: 'whatsApp',
})`
  margin-right: 10px;
`;

export const AddressCompleteInTheAd = styled.div`
  color: #ff000094;
  margin-top: 0px;
  font-size: 14px;
  font-weight: 500;
`;

export const CheckboxInputWrapper = styled(InputWrapper)`
  margin-top: 12px;
  flex-direction: row-reverse;
  justify-content: flex-end;
`;

export const ShowOnMapInput = styled(CheckBox)`
  margin-right: 10px;
`;

export const AddressCityInputContainer = styled.div`
  min-width: 49%;
  margin-right: 2%;
  margin-bottom: 8px;

  @media (max-width: 768px) {
    margin-right: unset;
    margin-bottom: unset;
  }
`;

export const AddressStateInputContainer = styled.div`
  min-width: 49%;
`;

export const SelectTransactionContainer = styled.div`
  display: flex;
  margin-bottom: 50px;
`;

export const SelectTransactionButton = styled(BaseButton)`
 flex-grow: 1;
  width: 1px;
  font-weight: 300;
  border: solid 1px ${COLOR_1};

  ${({ isActive }) => !isActive && css`
    color: ${COLOR_4};
    background-color: ${COLOR_2};
    border-color: ${COLOR_17};
  ` }
`;

export const AddressNeighborhoodInputContainer = styled.div``;

export const AddressStreetInputContainer = styled.div``;

export const AddressNumberInputContainer = styled.div`
  margin-right: 10px;

  @media (max-width: 768px) {
    margin-right: unset;
  }
`;

export const AddressComplementInputContainer = styled.div`
  min-width: 65%;
`;

export const PropertiesSelectsContainer = styled.div`
  display: flex;
  flex-direction: column;

  > div {
      & + div {
        margin-top: 16px;
      }
  }
`;

export const TabletInliner = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  ${mqTablet`
    flex-direction: row;
  ` }

  @media (max-width: 768px) {
    margin-bottom: 16px;
    width: 100%;

    > div {
      & + div {
        margin-top: 16px;
      }
    }
  }
`;

export const Form = styled.form`
  ${mqTablet`
    width: 100%;
    max-width: 500px;
    align-self: flex-start;
    display: flex;
    flex-direction: column;
  ` }
`;

export const SectionTitle = styled(BaseLabel)`
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 10px;
`;

export const AddressContainer = styled.div`
  margin-top: 50px;

  ${mqTablet`
    width: 100%;
  `}
  ${mqDesktop`
     width: 100%;
  `}
`;


export const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 70px;

    ${mqTablet`
      align-self: center;
      width: 400px;
  ` }
`;

export const VisualizeButton = styled(BaseButton)`
  color: ${COLOR_2};
  background-color: ${COLOR_4};
  flex-grow: 1;
  width: 1px;
  border: none;
`;

export const SaveButton = styled(VisualizeButton).attrs({
  type: 'submit',
})`
  background-color: ${COLOR_1};
`;
