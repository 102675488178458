import styled, { css } from 'styled-components';
import ReactTextMask from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import emailMask from 'text-mask-addons/dist/emailMask';
import { transitions } from 'polished';

import { COLOR_2, COLOR_8, COLOR_4, COLOR_7 } from '../variables/variables';
import { interacted } from '../helpers/helpers';

export const ErrorMessage = styled.label`
  color: ${COLOR_7};
  font-size: 12px;
  margin-top: -5px;
  margin-bottom: 5px;
  display: block;
`;

export const BaseInput = styled.input.attrs(props => ({
  type: props.type || 'text',
}))`
  display: block;
  background-color: ${COLOR_2};
  height: 50px;
  border-radius: 1px;
  padding: 0 10px;
  font-size: 16px;
  font-weight: 300;
  appearance: none;
  width: 100%;
  margin-bottom: 10px;

  ::placeholder {
    color: ${COLOR_8};
    opacity: 0.5;
    ${({ placeholderStyle }) => placeholderStyle}
  }

  ${interacted`
    outline: none;
  ` };

  ${transitions(['border-color', 'border-width'], 'ease .1s')}
  ${({ isInvalid }) => isInvalid && css`
      border-color: ${COLOR_7};
      border-width: 2px;
    ` }

  ${({ disabled }) => disabled && css`
    opacity: .7;
    color: ${COLOR_8};
  ` }
`;

export const SelectInput = styled(BaseInput).attrs(() => ({
  as: 'select',
}))``;

export const CheckboxInput = styled(BaseInput).attrs(() => ({
  type: 'checkbox',
}))`
  appearance: checkbox;
  height: 20px;
  width: 20px;
  min-height: 20px;
  min-width: 20px;
  padding: 0;
  margin-bottom: 0;
  margin-right: 10px;
`;

export const RadioInput = styled(CheckboxInput).attrs(() => ({
  type: 'radio',
}))`
  appearance: radio;
  border-radius: 50%;
`;

export const BaseTextArea = styled(BaseInput).attrs(() => ({
  as: 'textarea',
}))`
  padding-top: 10px;
  height: 100px;
  resize: none;
  line-height: 25px;
`;

export const MaskedBaseInput = styled(BaseInput).attrs((props) => ({
  as: ReactTextMask,
  type: props.type || 'text',
  guide: props.guide || false,
  mask: props.mask || [],
}))``;

// DO NOT ERASE THE COMMENTS BELLOW!
// All create number masks attributes:
//   prefix: 'R$',
//   suffix: '',
//   includeThousandsSeparator: false,
//   thousandsSeparatorSymbol: '',
//   allowDecimal: false,
//   decimalSymbol: '',
//   decimalLimit: 0,
//   requireDecimal: false,
//   allowNegative: false,
//   allowLeadingZeroes: false,
//   integerLimit: 999,
// DO NOT ERASE THE COMMENTS ABOVE!

export const masks = {

  price: ({
    prefix,
    thousandsSeparatorSymbol = '.',
    allowNegative,
    allowDecimal = true,
  } = {
      thousandsSeparatorSymbol: '.'
    }) => createNumberMask({
      prefix: prefix ? prefix : 'R$ ',
      includeThousandsSeparator: thousandsSeparatorSymbol ? true : false,
      thousandsSeparatorSymbol: thousandsSeparatorSymbol || '',
      requireDecimal: false,
      decimalSymbol: ',',
      allowNegative: !!allowNegative,
      allowLeadingZeroes: false,
      allowDecimal,
    }),


  float: ({
    prefix,
    suffix,
    thousandsSeparatorSymbol,
    decimalSeparatorSymbol = ',',
    allowNegative
  } = {
      decimalSeparatorSymbol: ',',
    }) => createNumberMask({
      prefix: prefix ? prefix : '',
      suffix: suffix ? suffix : undefined,
      includeThousandsSeparator: thousandsSeparatorSymbol ? true : false,
      thousandsSeparatorSymbol: thousandsSeparatorSymbol ? thousandsSeparatorSymbol : undefined,
      requireDecimal: typeof decimalSeparatorSymbol === 'string' ? true : false,
      decimalSymbol: typeof decimalSeparatorSymbol === 'string' ? decimalSeparatorSymbol : undefined,
      allowNegative: !!allowNegative,
      allowLeadingZeroes: false,
    }),

  integer: ({ prefix, suffix, thousandsSeparatorSymbol, allowNegative } = {}) => createNumberMask({
    prefix: prefix ? prefix : '',
    suffix: suffix ? suffix : undefined,
    includeThousandsSeparator: !!thousandsSeparatorSymbol,
    thousandsSeparatorSymbol: thousandsSeparatorSymbol ? thousandsSeparatorSymbol : undefined,
    allowDecimal: false,
    allowNegative: !!allowNegative,
    allowLeadingZeroes: false,
  }),

  creditCardExpiryDate: [
    /[0-1]/,
    /[1-9]/,
    '/',
    '2',
    '0',
    new RegExp(`[${(new Date()).getFullYear().toString().substring(2, 3)}-9]`),
    /\d/,
  ],
  creditCardNumber: [/\d/, /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/,],
  creditCardSecurityCode: [/\d/, /\d/, /\d/, /\d/],
  zipCode: [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/],
  year: [/\d/, /\d/, /\d/, /\d/],
  email: emailMask,
  cellphone: ['+', '5', '5', ' ', '(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  phone: ['+', '5', '5', ' ', '(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  cpf: [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/],
  cnpj: [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/],
  phonesV2: (rawVal) => {
    let el = document.getElementById(rawVal.name)
    let len = String(el ? el.value : '').length;
    if (len > 18) {
      return ['+', '5', '5', ' ', '(', /[1-9]/, /\d/, ')', ' ', /[1-9]/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    } else {
      return ['+', '5', '5', ' ', '(', /[1-9]/, /\d/, ')', ' ', /[1-9]/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /[1-9]/];
    }
  },
  phones: (rawVal) => {
    // DEPCRECATED ( @MateusVeloso )
    // const numbers = String(rawVal).match(/\d/g);
    // let numberLength = 0;
    // if (numbers) {
    //     numberLength = numbers.join('').length;
    // }

    let len = String(rawVal.value).length;
    if (len > 18) {
      return ['+', '5', '5', ' ', '(', /[1-9]/, /\d/, ')', ' ', /[1-9]/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    } else {
      return ['+', '5', '5', ' ', '(', /[1-9]/, /\d/, ')', ' ', /[1-9]/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /[1-9]/];
    }
  },
}


export default {
  BaseInput,
  BaseTextArea,
  SelectInput,
  MaskedBaseInput,
  masks,
};
