import React, { PureComponent } from "react";
import styled from "styled-components";
import propTypes from "prop-types";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import Joyride, { EVENTS } from 'react-joyride';

import Requests from "./requests/Requests";
import Reviews from "./reviews/Reviews";
import Cover from "./cover/Cover";
import Ads from "./ads/Ads";
import Plan from "./plan/Plan";
import Tabs from "../../components/tabs";
import { MyBrokers } from "./my-brokers";

import pathsConstants from "../../constants/paths";
import userService from "../../services/user/user.service";
import Recommendations from "./recommendations/Recommendations";

/*
  global
  window
  setTimeout
  process
  alert
*/

class Dashboard extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showFixedTabs: false,

      user: userService.retrieve(),
      run: false,
      steps: [
        {
          content:
            <>
              <h2><b>Bem vindo!</b></h2>
              <br></br>

              <p>Este é o início de um tutorial para o <b>Dashboard do Corretor</b>.</p>
            </>,
          // locale: { skip: <strong aria-label="skip">Sair</strong> },
          placement: 'center',
          target: 'body',
          locale: {
            next: `Próximo`, skip: `Sair`
          },
        },
        {
          target: '.dashboard-visualizacao',
          content:
            <>
              <h2><b>Visualizações do Perfil</b></h2>
              <br></br>
              <p>Você visualiza o número de usuários que acessaram seu perfil em um determinado mês.</p>
            </>,
          locale: {
            next: `Próximo`, skip: `Sair`, back: 'Voltar'
          },
        },
        {
          target: '.dashboard-anuncios',
          content:
            <>
              <h2><b>Visualizações dos anúncios</b></h2>
              <br></br>
              <p>Você consegue identificar quantas mensagens, visualizações e favoritos seus anúncios receberam até a data de hoje.</p>
            </>,
          locale: {
            next: `Próximo`, skip: `Sair`, back: 'Voltar'
          },
        },
        {
          target: '.dashboard-ads',
          content:
            <>
              <h2><b>Filtros</b></h2>
              <br></br>
              <p>Selecione os filtros relevantes para visualizar seus imóveis.</p>
            </>,
          locale: {
            next: `Próximo`, skip: `Sair`, back: 'Voltar', last: 'Último'
          },
        }, {
          target: '.dashboard-btn-view',
          content:
            <>
              <h2><b>Visualização</b></h2>
              <br></br>
              <p>O icone abaixo permite visualizar o imóvel como se você fosse um usuário.</p>
            </>,
          locale: {
            next: `Próximo`, skip: `Sair`, back: 'Voltar', last: 'Último'
          },
        }, {
          target: '.dashboard-btn-edit',
          content:
            <>
              <h2><b>Edição</b></h2>
              <br></br>
              <p>O icone abaixo permite acessar o dashboard do imóvel e realizar sua edição.</p>
            </>,
          locale: {
            next: `Próximo`, skip: `Sair`, back: 'Voltar', last: 'Último'
          },
        },
      ],
    };
  }

  componentDidMount() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  }

  hasAccess = () => {
    const { user } = this.state;
    if (user)
      return [0, 2, 3, 4].includes(user.type);

    return false;
  };

  initTour = () => {
    this.setState({
      ...this.state,
      run: true
    });
  }

  handleJoyrideCallback = (data) => {
    const { type } = data;
    if ((type === EVENTS.TOUR_END || type === EVENTS.BEACON) && this.state.run) {
      this.setState({
        ...this.state,
        run: false
      });
    }
  }

  render() {
    const { history } = this.props;
    const { user, steps, run } = this.state;

    const tabConfigs = (() => {
      let tabs = [
        {
          label: "Meus Anúncios",
          path: pathsConstants.DASHBOARD_ADS,
          showTooltip: true,
          tooltipTitle:
            "Aqui você tem acesso a todos os imóveis cadastrados em sua conta."
        },
        // {
        //   label: "Recomendações",
        //   path: pathsConstants.DASHBOARD_RECOMMENDATIONS,
        //   showTooltip: true,
        //   tooltipTitle:
        //     "Listagem com imóveis de proprietários procurando serviço de corretagem."
        // },
        {
          label: "Solicitações",
          path: pathsConstants.DASHBOARD_REQUESTS,
          showTooltip: true,
          tooltipTitle:
            "Mensagens de proprietários interessados em seus serviços de corretagem."
        },
        // {
        //   label: "Avaliações",
        //   path: pathsConstants.DASHBOARD_REVIEWS,
        //   showTooltip: true,
        //   tooltipTitle:
        //   "Avaliações do seu perfil de corretor. Essas avaliações não são públicas."
        // },
        //{
        // label: "Meu plano",
        // path: pathsConstants.DASHBOARD_PLAN,
        // showTooltip: true,
        // tooltipTitle:
        // "Informações básicas sobre o plano de serviços contratado com o Koort."
        // }
      ];

      if (user.userType === 4 || user.userType === 5) {
        tabs.push(
          {
            label: "Meus Corretores",
            path: pathsConstants.DASHBOARD_MY_BROKERS,
            showTooltip: true,
            tooltipTitle:
              "Aqui você tem acesso a todos os corretores vinculados à sua conta."
          },
        );
      }

      return tabs.filter((tab) => {
        if (tab.path === pathsConstants.DASHBOARD_REQUESTS && !this.hasAccess()) {
          return false
        }
        return true
      });
    })();

    return (
      <Wrapper>
        <Joyride
          steps={steps}
          showProgress={true}
          showSkipButton={true}
          continuous={true}
          run={run}
          callback={this.handleJoyrideCallback}
          scrollDuration={300}
          scrollToFirstStep={true}
          disableScrolling={true}
          styles={
            {
              buttonClose: {
                display: 'none',
              },
            }
          }
        />
        <Cover
          name={user.name}
          pictureUrl={user.profile.image}
          initTour={this.initTour}
          userType={user.userType}
        />
        <ContentWrapper>
          <Tabs configs={tabConfigs} />
          <Router history={history}>
            <Switch>
              <Route
                path={pathsConstants.DASHBOARD_ADS}
                render={props => <Ads {...props} user={user} />}
              />
              <Route
                path={pathsConstants.DASHBOARD_MY_BROKERS}
                render={props => <MyBrokers {...props} user={user} />}
              />
              <Route
                path={pathsConstants.DASHBOARD_RECOMMENDATIONS}
                render={props => <Recommendations {...props} user={user} />}
              />
              <Route
                path={pathsConstants.DASHBOARD_REQUESTS}
                render={props => <Requests {...props} user={user} />}
              />
              <Route
                path={pathsConstants.DASHBOARD_REVIEWS}
                render={props => <Reviews {...props} user={user} />}
              />
              <Route
                path={pathsConstants.DASHBOARD_PLAN}
                render={props => <Plan {...props} user={user} />}
              />
              <Redirect to={pathsConstants.DASHBOARD_ADS} />
            </Switch>
          </Router>
        </ContentWrapper>
        {/* <OpenTourGlobal alt={'Iniciar tutorial'} onClick={this.initTour} src={`${process.env.PUBLIC_URL}/imgs/tutorial.png`}></OpenTourGlobal> */}
      </Wrapper>
    );
  }
}

Dashboard.propTypes = {
  history: propTypes.object
};

Dashboard.defaultProps = {};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const ContentWrapper = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
`;

const OpenTourGlobal = styled.img`
  width: 40px;
  position: fixed;
  z-index: 1;
  right: 10px;
  bottom: 20px;
  cursor: pointer;
`;

export default Dashboard;
