import React, { PureComponent } from 'react';
import propTypes from 'prop-types';

import { Wrapper, WrapperErr } from './styles';
/* 
  global
  google
  Promise
*/

class StreetView extends PureComponent {

  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.highlightedMarkers = [];
    this.markers = [];
    this.state = {
      streetViewErr: false
    }
  }

  componentDidMount() {
    this.build();
  }

  build = async () => {
    const { options, onLoad, defaultLocation } = this.props;
    console.log(defaultLocation)

    const svResult = await new Promise((resolve, reject) => {
      new google.maps.StreetViewService().getPanorama({
        location: {
          lat: defaultLocation.latitude,
          lng: defaultLocation.longitude,
        },
        source: google.maps.StreetViewSource.OUTDOOR,
      }, (result, status) => {

        if (status === 'OK') {
          resolve(result);
        } else {
          this.setState({
            ...this.state,
            streetViewErr: true,
          });
          reject(result);
        }
      });
    })


    this.streetView = new google.maps.StreetViewPanorama(this.wrapperRef.current, {
      position: {
        lat: svResult.location.latLng.lat(),
        lng: svResult.location.latLng.lng(),
      },
      fullscreenControl: false,
      addressControl: false,
      ...options,
    });

    if (onLoad) {
      google.maps.event.addListener(
        this.streetView,
        'load',
        onLoad
      );
    }
  };

  render() {
    const { className } = this.props;

    return (
      <>
        {this.state.streetViewErr ? <WrapperErr>Desculpe, não conseguimos carregar suas informações do <b>Street View</b>.</WrapperErr> :
          <Wrapper
            className={className}
            ref={this.wrapperRef}
          />}
      </>
    );
  }
}

StreetView.propTypes = {
  className: propTypes.string,
  onLoad: propTypes.func,
  options: propTypes.object,
  defaultLocation: propTypes.objectOf(propTypes.shape({
    latitude: propTypes.number,
    longitude: propTypes.number,
  })),
}

StreetView.defaultProps = {
  address: 'Botafogo, Rio de Janeiro - RJ',
  options: {},
};



export default StreetView;
