import React from 'react';
import { Controller } from 'react-hook-form'
import { IMaskInput } from 'react-imask';

import { ErrorMessage } from '../../../../../../components/form/error-message';

import { Container } from './styles';

export function PhoneInput({ control, name, label, error = null }) {
  return (
    <>
      <Container>
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value } }) => (
            <IMaskInput
              mask='+55 (00) 00000-0000'
              autoComplete="off"
              placeholder="phone input"
              value={value}
              onChange={onChange}
            />
          )}
        />

        <label htmlFor={name}>{label}</label>
      </Container>

      {error && <ErrorMessage message={error.message} />}
    </>
  );
}
