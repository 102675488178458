
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';

import { mqTablet } from '../../../styled/helpers/helpers';

import RatingAverages from '../../../components/rating-averages';
import NoResults from '../../../components/no-results';
import Review from '../../../components/review/Review';

import userReviewService from '../../../services/user/review/user.review.service';

class Reviews extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      reviews: [ ],
    };
  }

  componentDidMount() {
    this.getReviews();
  }

  getReviews = async () => {
    const { user } = this.props;

    let result;
    try {
      result = await userReviewService.get({
        token: user.token,
      });
    } catch (error) {
      // 
    }

    this.setState(state => ({
      ...state,
      reviews: result || [ ],
    }));
  }; 

  render() {
    const { reviews } = this.state;
    const { user } = this.props;

    return (
      <Wrapper>
        { reviews.length > 0 && (
          <RatingAverages 
            reviews={ reviews }
          />
        ) }
        { reviews.map(review => (
          <Review 
            key={ review.id }
            review={ review }
            token={ user.token }
          />
        )) }
        { reviews.length === 0 && (
          <NoResults />
        ) }
      </Wrapper>
    );
  }
}

Reviews.propTypes = {
  reviews: propTypes.array,
};

Reviews.defaultProps = {
  reviews: [ 1,2,3 ],
};

const Wrapper = styled.div`
  ${ mqTablet`
    width: 700px;
    align-self: center;
  ` }
`;

export default Reviews;
