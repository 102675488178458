import React from 'react';
import propTypes from 'prop-types';

import { StarHalf } from 'styled-icons/boxicons-solid/StarHalf';
import { Star as StarFull } from 'styled-icons/boxicons-solid/Star';
import { Star as StarEmpty } from 'styled-icons/boxicons-regular/Star';

import { Wrapper, starStyle } from './styles';

const RatingStars = ({
  className,
  rating,
}) => {

  const stars = [];
  const fullStars = parseInt(rating / 2);
  const rest = rating % 2;
  const count = 5;

  for (let i = 0; i < count; i++) {
    if (i < fullStars) {
      stars.push(styled(StarFull)`
        ${starStyle};
      `);
    } else if (rest > 0 && fullStars === i) {
      stars.push(styled(StarHalf)`
        ${starStyle};
      `);
    } else {
      stars.push(styled(StarEmpty)`
        ${starStyle};
      `);
    }
  }

  return (
    <Wrapper className={className}>
      {stars.map((Star, index) => <Star key={index} />)}
    </Wrapper>
  );
};

RatingStars.propTypes = {
  rating: propTypes.number.isRequired,
  className: propTypes.string,
};



export default RatingStars;
