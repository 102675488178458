import React, { PureComponent } from 'react';
import styled from 'styled-components';

class Description extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      fieldsHasFilled: false,
      form: {
        description: props.description,
      },
      count: 0,
    };
  }

  handleChangeText = event => {
    event.persist();

    this.setState(prevState => ({
      ...prevState,
      form: {
        description: event.target.value,
      },
      count: event.target.value.length
    }));
  }

  render() {
    const { isEditing, populate } = this.props;
    const { fieldsHasFilled } = this.state;

    if (isEditing) {
      if (!!populate && !fieldsHasFilled) {
        this.setState(prevState => ({
          ...prevState,
          fieldsHasFilled: true,
          count: populate.length,
          form: {
            ...prevState.form,
            description: populate,
          }
        }));
      }
    }

    return (
      <Wrapper>
        <div className="form-floating">
          <textarea
            maxLength={2000}
            className="form-control"
            placeholder="Descrição"
            id="message"
            name="message"
            style={{ height: '190px' }}
            value={this.state.form.description}
            onChange={(event) => this.handleChangeText(event)}
          />

          <label
            htmlFor="message"
            style={{
              background: '#ffffff',
              height: 'auto',
              padding: 0,
              top: '1px',
              paddingTop: '0.15rem',
              left: '0.75rem',
              width: '94%',
              transform: 'unset',
              opacity: 1,
              fontSize: '0.85rem',
              color: '#595959'
            }}
          >
            Descrição do imóvel
          </label>
        </div>
        {<Counter>{this.state.count}/2000</Counter>}
      </Wrapper>
    );
  }
}

const Wrapper = styled.form`
  margin-bottom: 50px;
`;

const Counter = styled.div`
  font-size: 12px;
  margin-top: 15px;
  margin-bottom: 5px;
  text-align: right;
`;

export default Description;
