
import Address from '../../models/address/address.model';
import Geolocation from '../../models/geolocation/geolocation.model';
import { toast } from 'react-toastify';

/*
  global
  Promise
  google
*/

const customId = "custom-id-yes";

const getGeolocationSummaryByAddress = async ({
  addressString,
}) => {
  const newAddressString = addressString.replace('São Paulo', 'SP');

  const response = await new Promise((resolve, reject) => {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({
      address: newAddressString,
    }, (responses, status) => {
      if (status === 'OK' && responses[0]) {
        resolve(responses[0]);
      } else if (status !== "OK") {
        toast.error('Nenhum resultado encontrado.', {
          autoClose: false,
          toastId: customId,
        });
        reject(responses);
      } else {
        reject(responses);
      }
    });
  });

  const { location, viewport } = response.geometry;
  const result = new Geolocation({
    id: response.place_id,
    formattedAddress: response.formatted_address,
    type: response.types[0],
    north: viewport.getNorthEast().lat(),
    south: viewport.getSouthWest().lat(),
    east: viewport.getNorthEast().lng(),
    west: viewport.getSouthWest().lng(),
    latitude: location.lat(),
    longitude: location.lng(),
  });

  return result;
};

const getAddressByGeolocation = async ({
  latitude,
  longitude,
}) => {

  const response = await new Promise((resolve, reject) => {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({
      address: `${latitude}, ${longitude}`,
    }, (responses, status) => {
      if (responses[0] && status === 'OK') {
        resolve(responses[0]);
      } else {
        reject(responses);
      }
    });
  });

  let number, street, neighborhood, city, state, country, zipCode;
  response.address_components.forEach(ac => {
    const type = ac.types.find(t => {
      return t === 'street_number' || // Number
        t === 'route' || // Street
        t === 'sublocality' || // Neighborhood
        t === 'administrative_area_level_2' || // City
        t === 'administrative_area_level_1' || // State
        t === 'country' ||
        t === 'postal_code';
    });

    switch (type) {
      case 'street_number': number = ac.long_name; break;
      case 'route': street = ac.long_name; break;
      case 'sublocality': neighborhood = ac.long_name; break;
      case 'administrative_area_level_2': city = ac.long_name; break;
      case 'administrative_area_level_1': state = ac.short_name; break;
      case 'country': country = ac.long_name; break;
      case 'postal_code': zipCode = ac.long_name; break;
      default: break;
    }
  });

  const result = new Address({
    street,
    number,
    neighborhood,
    city,
    state,
    zipCode,
    country,
    location: new Geolocation({
      latitude: response.geometry.location.lat(),
      longitude: response.geometry.location.lng(),
    }),
  });

  return result;
};

const findCompleteAddress = async addressString => {
  let response = await (new Promise((resolve, reject) => {
    new google.maps.places.AutocompleteService().getQueryPredictions({
      input: addressString,
    }, (results, status) => {
      if (status === 'OK') resolve(results);
      else reject(status);
    });
  }));

  const result = response.filter(item => {
    const termsFound = item.terms.filter(term => {
      return term.value.toLowerCase() === 'brasil' ||
        term.value.toLowerCase() === 'rj' ||
        term.value.toLowerCase() === 'sp';
    });

    return termsFound.length === 2;
  }).map(item => {
    return new Address({
      ...item,
      formatted: item.description,
      type: (item.types || [])[0],
    });
  });
  return result;
};

export default {
  findCompleteAddress,
  getGeolocationSummaryByAddress,
  getAddressByGeolocation,
};
