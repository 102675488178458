import React from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";

import { COLOR_BORDER, COLOR_1 } from "../../../styled/variables/variables";
import { BaseLabel } from "../../../styled/labels/labels";
import { mqDesktop } from "../../../styled/helpers/helpers";
import { ShareButtons } from "../components/ShareButtons";

const Work = ({
  description,
  areas,
  checkDescriptionSize,
  showMore,
  visible,
}) => {
  return (
    <Wrapper>
      <div className={"profile-description"}>
        {description && (
          <ContainerWork>
            <SectionTitle>MEU TRABALHO</SectionTitle>

            {description && (
              <SectionText>
                {checkDescriptionSize && visible ? (
                  <>
                    {description.length > 550
                      ? description.slice(0, 550) + "..."
                      : description}
                    {description.length > 550 && (
                      <SeeMore href="#" onClick={showMore}>
                        {" "}
                        Veja mais{" "}
                      </SeeMore>
                    )}
                  </>
                ) : (
                  <>
                    {description}
                    <SeeMore href="#" onClick={showMore}>
                      {" "}
                      Veja menos
                    </SeeMore>
                  </>
                )}
              </SectionText>
            )}
          </ContainerWork>
        )}
      </div>
      {areas && areas.length > 0 && (
        <SectionTitleOccupationArea>
          ÁREAS DE ATUAÇÃO
        </SectionTitleOccupationArea>
      )}
      {areas && <List list={areas} />}
    </Wrapper>
  );
};

const List = ({ list }) => {
  let countNeighborhoods = 0;
  return (
    <ListContainer>
      {list.map((item) => {
        countNeighborhoods = item.neighborhoods.length;

        return (
          <ListContent key={item._id}>
            <CityContainer key={item.city}>
              <CityTitle>Cidade:</CityTitle>
              <CityName>{item.city}</CityName>
            </CityContainer>
            <NeighborhoodContainer>
              <NeighborhoodTitle>Bairros:</NeighborhoodTitle>
              <NeighborhoodContent>
                {countNeighborhoods === 0 && (
                  <NeighborhoodName>Não informados</NeighborhoodName>
                )}

                {item.neighborhoods.map((item, index) => (
                  <NeighborhoodName key={index}>
                    {item}
                    {index !== countNeighborhoods - 1 && (
                      <PipeDivisor>|</PipeDivisor>
                    )}
                  </NeighborhoodName>
                ))}
              </NeighborhoodContent>
            </NeighborhoodContainer>
          </ListContent>
        );
      })}
    </ListContainer>
  );
};

const ListContainer = styled.div``;

const ListContent = styled.div`
  margin-bottom: 24px;
`;

const Wrapper = styled.div`
  margin-top: 20px;
`;

const ContainerWork = styled.div`
  ${mqDesktop`

  `}
`;

const SectionTitle = styled(BaseLabel)`
  font-weight: 700;
  margin-bottom: 10px;
  margin-top: 50px;
`;

const SectionText = styled(BaseLabel).attrs({
  as: "p",
})`
  text-align: justify;
  font-size: 16px;
  display: block;
  margin-bottom: 30px;

  &:last-child {
    margin: 0;
  }
`;

const SeeMore = styled.a`
  color: ${COLOR_1};
  margin: 0.2em;
`;

const SectionTitleOccupationArea = styled(BaseLabel)`
  font-weight: 700;
  margin-bottom: 10px;
  margin-top: 20px;
`;

const CityContainer = styled.div`
  display: flex;
`;

const CityTitle = styled.h3`
  margin-right: 20px;
  color: #333333;
  font-size: 16px;
  font-weight: 600;
`;

const CityName = styled.h3`
  color: #5c5c5c;
  font-size: 16px;
  font-weight: normal;
  text-transform: capitalize;
`;

const NeighborhoodContainer = styled.div`
  margin-top: 12px;
  display: flex;
`;

const NeighborhoodContent = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const NeighborhoodTitle = styled.h3`
  margin-right: 20px;
  color: #333333;
  font-size: 16px;
  font-weight: 600;
`;

const NeighborhoodName = styled.h3`
  color: #5c5c5c;
  font-size: 16px;
  font-weight: normal;
  text-transform: capitalize;
`;

const PipeDivisor = styled.span`
  margin: 0 4px;
`;

export default Work;
