import React from 'react';

import Cover from '../dashboard/cover/Cover';
import { BillingLeadsContent } from './billing-leads-content';

import userService from '../../services/user/user.service';

export function BillingLeads() {
  return (
    <>
      <Cover
        name={userService.retrieve().name}
        pictureUrl={userService.retrieve().profile.image}
      />

      <BillingLeadsContent />
    </>
  );
}
