import httpClient from '../../clients/http/http.client';

const sendMessage = async ({
  data,
}) => {
  const params = {
    "email": data.email,
    "subjectCode": data.subjectCode,
    "message": data.message
  };

  const path = '/contacts'
  const response = await httpClient.post({ path, params });

  return response;
};

const sendMessageToRealtor = async (data) => {
  const path = '/leads/from/koort';

  return await httpClient.post({ path, params: data }).then(r => {
    if (r.status !== 204) {
      throw r.data;
    } else {
      return r.data;
    }
  });
};

const sendFeedback = async ({
  type,
  comment,
  page,
  error,
}) => {
  const path = '/contacts/feedback';

  const response = await httpClient.post({
    path,
    params: {
      type,
      comment,
      page,
    }
  });

  return response;
}

export default {
  sendMessage,
  sendMessageToRealtor,
  sendFeedback,
};
