/* eslint-disable no-undef */
import React, { useState } from 'react';
import { Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import Modal from 'react-modal';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import {
  RiFullscreenLine,
  RiFullscreenExitLine,
  RiCloseLine,
} from 'react-icons/ri';

import { KeyboardArrowLeft } from 'styled-icons/material/KeyboardArrowLeft';
import { KeyboardArrowRight } from 'styled-icons/material/KeyboardArrowRight';
import { COLOR_2 } from '../../styled/variables/variables';

import Panorama from '../panorama';

import imageUtil from '../../utils/image/image.util';

import {
  Container,
  Carousel,
  SlideItem,
  SlideImg,
  SliderControlButtonLeft,
  SliderControlButtonRight,
  ModalSliderControlButtonLeft,
  ModalSliderControlButtonRight,
  ModalContent,
  ModalBody,
  ButtonsArea,
  CarouselContent,
} from './styles'


export function CarouselPresentation({ images }) {
  const [currentSlideState, setCurrentSlideState] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const handle = useFullScreenHandle();

  function handleChangeFullscreen(mode) {
    if (mode === 'maximize') {
      setIsFullscreen(true);
      handle.enter();
    } else {
      setIsFullscreen(false);
      handle.exit();
    }
  }

  function handleCloseModal() {
    setModalVisible(false);
    if (isFullscreen) {
      setIsFullscreen(false)
    }
  }

  function onCloseFullScreen(state) {
    if (!state) {
      setIsFullscreen(false)
    }
  }

  function handleOpenModal(index) {
    setCurrentSlideState(index);
    setModalVisible(true);
  }

  return (
    <Container>
      <Carousel
        naturalSlideWidth={window.innerWidth}
        naturalSlideHeight={400}
        totalSlides={images.length}
        dragEnabled={false}
      >
        <Slider
          style={{
            height: '100%'
          }}
        >
          {images.map((item, index) => (
            <Slide key={index} index={index}>
              <SlideItem onClick={() => handleOpenModal(index)}>
                <SlideImg
                  src={
                    imageUtil.transformImageLink(item, 'large')
                  }
                />
              </SlideItem>
            </Slide>
          ))}
        </Slider>

        <SliderControlButtonLeft>
          <ButtonBack>
            <KeyboardArrowLeft size="60" color={COLOR_2} />
          </ButtonBack>
        </SliderControlButtonLeft>
        <SliderControlButtonRight>
          <ButtonNext>
            <KeyboardArrowRight size="60" color={COLOR_2} />
          </ButtonNext>
        </SliderControlButtonRight>
      </Carousel>

      <Modal
        isOpen={modalVisible}
        onRequestClose={handleCloseModal}
        style={{
          content: {
            backgroundColor: 'transparent',
            border: 0,
            padding: 0,
            inset: 0,
            borderRadius: 0,
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)'
          }
        }}
      >
        <FullScreen handle={handle} onChange={(state) => onCloseFullScreen(state)}>
          <ModalContent>
            <ButtonsArea>
              {isFullscreen ?
                <button type="button" onClick={() => handleChangeFullscreen('minimize')}>
                  <RiFullscreenExitLine size="28" color="#FFFFFF" />
                </button>
                :
                <button type="button" onClick={() => handleChangeFullscreen('maximize')}>
                  <RiFullscreenLine size="28" color="#FFFFFF" />
                </button>
              }
              <button type="button" onClick={handleCloseModal}>
                <RiCloseLine size="28" color="#FFFFFF" />
              </button>
            </ButtonsArea>

            <ModalBody>
              <CarouselContent>
                <Carousel
                  naturalSlideWidth={window.innerWidth}
                  naturalSlideHeight={window.innerHeight * 0.85}
                  totalSlides={images.length}
                  currentSlide={currentSlideState}
                  dragEnabled={false}
                  touchEnabled={false}
                >
                  <Slider
                    style={{
                      height: '100%'
                    }}
                  >
                    {images.map((item, index) => (
                      <Slide key={index} index={index}>
                        <Panorama
                          url={imageUtil.transformImageLink(item, 'large')}
                        />
                      </Slide>
                    ))}
                  </Slider>

                  <ModalSliderControlButtonLeft>
                    <ButtonBack>
                      <KeyboardArrowLeft size="42" color={COLOR_2} />
                    </ButtonBack>
                  </ModalSliderControlButtonLeft>
                  <ModalSliderControlButtonRight>
                    <ButtonNext>
                      <KeyboardArrowRight size="42" color={COLOR_2} />
                    </ButtonNext>
                  </ModalSliderControlButtonRight>
                </Carousel>
              </CarouselContent>
            </ModalBody>
          </ModalContent>
        </FullScreen>
      </Modal>
    </Container>
  );
}
