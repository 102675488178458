import React, { useState, useEffect } from 'react';
import { RiErrorWarningLine } from 'react-icons/ri';

import Cover from '../../dashboard/cover/Cover';
import { CompanyBrokerContent } from './company-broker-content';

import userService from '../../../services/user/user.service';
import userProfileService from '../../../services/user-profile/user-profile.service';

import eventsConstants from '../../../constants/events'
import domEventsUtil from '../../../utils/dom-events/dom-events.util';

import {
  PageErrorWrapper,
} from './styles';

export function CompanyBroker() {
  const [brokerProfileData, setBrokerProfileData] = useState(null);

  const [hasPageError, setHasPageError] = useState(false);

  async function getLeadsFromCompanyBroker() {
    try {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: true }
      });

      const user = userService.retrieve();

      const params = (new URL(document.location)).searchParams;

      const brokerId = params.get('brokerId');

      const responseUserProfile = await userProfileService.getUserBasicProfile({
        userId: brokerId,
        token: user.token,
      });

      setBrokerProfileData(responseUserProfile);
    } catch {
      setHasPageError(true);
    } finally {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: false }
      });
    }
  }

  useEffect(() => {
    function verifyUserType() {
      const user = userService.retrieve();

      const params = (new URL(document.location)).searchParams;

      const brokerId = params.get('brokerId');

      if (user.userType > 3 && !!brokerId) {
        getLeadsFromCompanyBroker();
      } else {
        // Não tem acesso à página
        window.open('/', '_self');
      }
    }

    verifyUserType();
  }, []);

  return (
    <>
      {hasPageError && (
        <PageErrorWrapper>
          <div>
            <RiErrorWarningLine size={120} color="#dc3545" />

            <p>Algumas informações necessárias não puderam ser carregadas</p>

            <button
              type="button"
              onClick={() => window.location.reload(true)}
            >
              Recarregar
            </button>
          </div>
        </PageErrorWrapper>
      )}

      {!hasPageError && (
        <>
          <Cover
            name={brokerProfileData ? `Corretor(a): ${brokerProfileData.name}` : ''}
            pictureUrl={brokerProfileData ? brokerProfileData.profile.image : ''}
          />

          <CompanyBrokerContent />
        </>
      )}
    </>
  );
}
