import React, { useState, useEffect } from "react";

import { StyledModal, CloseButton, ModalContentWrapper } from './styles';

function FancyModalButton(props) {
  const [isOpen, setIsOpen] = useState(props.open);
  const [opacity, setOpacity] = useState(0);

  const open = props.open;

  function toggleModal(e) {
    setIsOpen(!isOpen);
  }

  function afterOpen() {
    setTimeout(() => {
      setOpacity(1);
    }, 10);
  }

  function beforeClose() {
    return new Promise(resolve => {
      setOpacity(0);
      setTimeout(resolve, 200);
    });
  }

  return (
    <>
      <div onClick={toggleModal}>{props.children}</div>
      <StyledModal
        isOpen={isOpen && open ? true : false}
        afterOpen={afterOpen}
        beforeClose={beforeClose}
        onBackgroundClick={toggleModal}
        onEscapeKeydown={toggleModal}
        opacity={opacity}
        backgroundProps={{ opacity }}>

        <ModalContentWrapper noMargin={props.closeButton}>
          {props.data.props.children}
        </ModalContentWrapper>

        {props.closeButton &&
          <CloseButton onClick={toggleModal}>x</CloseButton>
        }
      </StyledModal>
    </>
  );
}



export default FancyModalButton;