import axios from 'axios';

import objectUtil from '../../../utils/object/object.util';
import domEventsUtil from '../../../utils/dom-events/dom-events.util';
import eventsConstants from '../../../constants/events';

/*
  process
  FormData
*/

axios.interceptors.response.use(response => {
  return response;
}, error => {
  if (error && error.response && error.response.status === 401) {
    window.open('/', '_self');
    domEventsUtil.dispatch({ name: eventsConstants.SIGN_OUT })
  } else if (error && error.response && error.response.status === 403) {
    window.open('/', '_self');
    domEventsUtil.dispatch({ name: eventsConstants.SIGN_OUT });
  }
  return error;
});

const { REACT_APP_KOORT_BUSINESS_API_URL } = process.env;

const get = async ({
  url,
  path,
  params,
  token,
  options = {},
}) => {
  url = (url ? url : REACT_APP_KOORT_BUSINESS_API_URL)
  url += path;

  options = {
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options.headers || {},
      ...token ? { Authorization: `Bearer ${token}` } : {},
    },
  };

  return axios.get(url, { params, ...options, });
};

const post = async ({
  url = REACT_APP_KOORT_BUSINESS_API_URL,
  path,
  params,
  token,
  options = {
    headers: {
      'Content-Type': 'application/json',
    },
  },
}) => {
  url = (url ? url : REACT_APP_KOORT_BUSINESS_API_URL)
  url += path;

  options = {
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options.headers || {},
      ...token ? { Authorization: `Bearer ${token}` } : {},
    },
  };

  if (typeof params !== 'string' && !(params instanceof FormData)) {
    params = objectUtil.stringify(params);
  }

  return axios.post(url, params, options);
};

const patch = async ({
  url,
  path,
  params,
  token,
  options = {
    headers: {
      'Content-Type': 'application/merge-patch+json',
    },
  },
}) => {
  url = (url ? url : REACT_APP_KOORT_BUSINESS_API_URL)
  url += path;

  options = {
    ...options,
    headers: {
      'Content-Type': 'application/merge-patch+json',
      ...options.headers || {},
      ...token ? { Authorization: `Bearer ${token}` } : {},
    },
  };

  if (typeof params !== 'string' && !(params instanceof FormData)) {
    params = objectUtil.stringify(params);
  }
  return axios.patch(url, params, options);
};

const put = async ({
  url,
  path,
  params,
  token,
  options = {
    headers: {
      'Content-Type': 'application/merge-patch+json',
    },
  },
}) => {
  url = (url ? url : REACT_APP_KOORT_BUSINESS_API_URL)
  url += path;

  options = {
    ...options,
    headers: {
      'Content-Type': 'application/merge-patch+json',
      ...options.headers || {},
      ...token ? { Authorization: `Bearer ${token}` } : {},
    },
  };

  if (typeof params !== 'string' && !(params instanceof FormData)) {
    params = objectUtil.stringify(params);
  }
  return axios.put(url, params, options);
};

const del = async ({ path, token }) => {
  const url = REACT_APP_KOORT_BUSINESS_API_URL + path;

  return axios.delete(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  });
}

export default {
  get,
  post,
  patch,
  put,
  del,
};
