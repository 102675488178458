import React from 'react';
import styled from 'styled-components';

import { mqTablet, backgrounder } from '../../../styled/helpers/helpers';
import { COLOR_1, COLOR_2 } from '../../../styled/variables/variables';
import { BaseLabel } from '../../../styled/labels/labels';

import { KoortMarkerOrangeOutline } from '../../../styled/components/components';


/*
  global
  process
*/

const { PUBLIC_URL } = process.env;

const Koortimativa = () => (
  <Wrapper>
    <TextureWrapper>
      <Texture />
    </TextureWrapper>
    <ContentWrapper>
      <Content>
        <TitleWrapper>
          <AKoortMarkerWhiteOutline />
          <KoortimativaText />
        </TitleWrapper>
        <SectionTitle>
          A Koortimativa é uma ferramenta inteligente que aprende sobre as características anunciadas dos imóveis e com os valores dados pelos usuários.
        </SectionTitle>
        <TextBlock>
          Quando a Koortimativa vê um novo imóvel, ela é capaz de calcular uma estimativa de valor segundo os atributos que ela já conhece. Portanto, a Koortimativa fornece o valor de um imóvel similar àquele que está sendo avaliado, mas não avalia o imóvel em si.
        </TextBlock>
        <TextBlock>
          No mundo real, o valor do imóvel depende de inúmeros aspectos específicos, que não entram nos anúncios. Por exemplo, uma casa com metragem interessante em um bairro atraente pode ter problemas de conservação, ou precisar trocar a fiação elétrica. Em outro exemplo, um apartamento pequeno pode ter uma vista deslumbrante, ou ficar na esquina mais barulhenta do bairro. Todas essas características produzem variações individuais e específicas em torno do valor recomendado pela Koortimativa.
        </TextBlock>
      </Content>
    </ContentWrapper>
  </Wrapper>
);


const Wrapper = styled.div``;

const TextureWrapper = styled.div`
  height: 80px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  ${mqTablet`
    overflow: unset;
    height: 200px;
    justify-content: flex-end;
    ` }
    `;

const Texture = styled.div`
  ${backgrounder(`${PUBLIC_URL}/imgs/koort-texture-outline.svg`)}
  min-width: 600px;
  background-position: top;
  height: 100%;
  position: relative;
  flex-grow: 1.2;

  ${mqTablet`
    width: 1px;
    min-width: unset;
    flex-grow: .5;
    height: 850px;
    max-width: 500px;
    background-position: left;
    ` }
  `;

const ContentWrapper = styled.div`
  padding: 30px 10px;
  background-color: ${COLOR_1};

  ${mqTablet`
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 100px 10px;
    ` }
   `;

const Content = styled.div`
  display: flex;
  flex-direction: column;

  ${mqTablet`
    width: 100%;
    max-width: 1000px;
    ` }
  `;

const TitleWrapper = styled.div`
  display: flex;
  align-self: center;
  margin-bottom: 20px;

  ${mqTablet`
    align-self: flex-start;
    flex-direction: row;
    ` }
  `;

const AKoortMarkerWhiteOutline = styled(KoortMarkerOrangeOutline)`
  height: 40px;
  align-self: flex-start;
  margin-right: 10px;

  ${mqTablet`
    margin-right: 5px;
    height: 32px;
    ` }
    `;

const KoortimativaText = styled.img.attrs({
  src: `${PUBLIC_URL}/imgs/koortimativa-text-orange-white.png`,
})`
    width: 240px;
    align-self: flex-end;
    margin-bottom: -5px;

    ${mqTablet`
      width: 240px;
      ` }
    `;

const SectionTitle = styled(BaseLabel)`
  color: ${COLOR_2};
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 30px;
  margin-top: 30px;
  max-width: 100%;
  align-self:center;
  ${mqTablet`
      max-width: calc(50vw - 50px);
      align-self:flex-start;
  ` }
  `;

const TextBlock = styled(BaseLabel).attrs({
  as: 'p',
})`
  color: ${COLOR_2};
  font-size: 14px;
  margin-bottom: 20px;

  ${mqTablet`
    max-width: calc(50vw - 50px);
    ` }
    `;

const BoldText = styled.p`
  display: inline;
  font-weight: 700;
  `;

export default Koortimativa;