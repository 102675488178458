const {
  REACT_APP_REGION,
  REACT_APP_USER_POOLS_ID,
  REACT_APP_WEB_CLIENT_ID,
} = process.env;

const amplifyConfig = {
  "aws_cognito_region": REACT_APP_REGION,
  "aws_user_pools_id": REACT_APP_USER_POOLS_ID,
  "aws_user_pools_web_client_id": REACT_APP_WEB_CLIENT_ID
};

export default amplifyConfig;
