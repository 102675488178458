import * as yup from 'yup';

const formSchema = yup.object().shape({
  propertyUse: yup.string()
    .test("", "Seleção obrigatória", value => {
      if (value !== 'Selecione um uso') {
        return true;
      } else {
        return false;
      }
    }),
  propertyType: yup.string()
    .test("", "Seleção obrigatória", value => {
      if (value !== 'Selecione um tipo') {
        return true;
      } else {
        return false;
      }
    }),
  zipCode: yup.string().required('Este campo é obrigatório'),
  street: yup.string().required('Este campo é obrigatório'),
  number: yup.string().required('Este campo é obrigatório'),
  complement: yup.string(),
  neighborhood: yup.string().required('Este campo é obrigatório'),
  city: yup.string().required('Este campo é obrigatório'),
  state: yup.string()
    .test("", "Seleção obrigatória", value => {
      if (value !== 'Selecione um estado') {
        return true;
      } else {
        return false;
      }
    }),
  enterpriseName: yup.string(),
  towerNumber: yup.string(),
  floorsNumber: yup.string(),
  apartmentsPerFloor: yup.string(),
  bedroomsFrom: yup.string().when('$adTypeIsRelease', {
    is: (adTypeIsRelease) => adTypeIsRelease,
    then: yup.string().required('Este campo é obrigatório'),
    otherwise: yup.string(),
  }),
  bedroomsTo: yup.string().when('$adTypeIsRelease', {
    is: (adTypeIsRelease) => adTypeIsRelease,
    then: yup.string().required('Este campo é obrigatório'),
    otherwise: yup.string(),
  }),
  bathroomsFrom: yup.string().when('$adTypeIsRelease', {
    is: (adTypeIsRelease) => adTypeIsRelease,
    then: yup.string().required('Este campo é obrigatório'),
    otherwise: yup.string(),
  }),
  bathroomsTo: yup.string().when('$adTypeIsRelease', {
    is: (adTypeIsRelease) => adTypeIsRelease,
    then: yup.string().required('Este campo é obrigatório'),
    otherwise: yup.string(),
  }),
  suitesFrom: yup.string().when('$adTypeIsRelease', {
    is: (adTypeIsRelease) => adTypeIsRelease,
    then: yup.string().required('Este campo é obrigatório'),
    otherwise: yup.string(),
  }),
  suitesTo: yup.string().when('$adTypeIsRelease', {
    is: (adTypeIsRelease) => adTypeIsRelease,
    then: yup.string().required('Este campo é obrigatório'),
    otherwise: yup.string(),
  }),
  parkingSpacesFrom: yup.string(),
  parkingSpacesTo: yup.string(),
  areaFrom: yup.string(),
  areaTo: yup.string(),
  totalAreaFrom: yup.string().when('$adTypeIsRelease', {
    is: (adTypeIsRelease) => adTypeIsRelease,
    then: yup.string().required('Este campo é obrigatório'),
    otherwise: yup.string(),
  }),
  totalAreaTo: yup.string().when('$adTypeIsRelease', {
    is: (adTypeIsRelease) => adTypeIsRelease,
    then: yup.string().required('Este campo é obrigatório'),
    otherwise: yup.string(),
  }),
  bedrooms: yup.string().when('$adTypeIsRelease', {
    is: (adTypeIsRelease) => !adTypeIsRelease,
    then: yup.string().required('Este campo é obrigatório'),
    otherwise: yup.string(),
  }),
  bathrooms: yup.string().when('$adTypeIsRelease', {
    is: (adTypeIsRelease) => !adTypeIsRelease,
    then: yup.string().required('Este campo é obrigatório'),
    otherwise: yup.string(),
  }),
  suites: yup.string().when('$adTypeIsRelease', {
    is: (adTypeIsRelease) => !adTypeIsRelease,
    then: yup.string().required('Este campo é obrigatório'),
    otherwise: yup.string(),
  }),
  parkingSpaces: yup.string(),
  area: yup.string(),
  totalArea: yup.string().when('$adTypeIsRelease', {
    is: (adTypeIsRelease) => !adTypeIsRelease,
    then: yup.string().required('Este campo é obrigatório'),
    otherwise: yup.string(),
  }),
  // title: yup.string().notRequired().when('title', {
  //   is: value => value?.length,
  //   then: rule => rule.min(10, 'Mínimo de 10 caracteres').max(150, 'Máximo de 150 caracteres')
  // }),
  description: yup.string()
    .required('Este campo é obrigatório')
    .min(10, 'Mínimo de 10 caracteres'),
  price: yup.string(),
  condo: yup.string(),
  iptu: yup.string(),
  name: yup.string().required('Este campo é obrigatório'),
  email: yup.string()
    .required('Este campo é obrigatório')
    .email('Informe um e-mail válido'),
  cellphone: yup.string().notRequired().when('cellphone', {
    is: value => value?.length,
    then: rule => rule.min(18, 'Informe 10 ou 11 dígitos')
  }),
}, [['cellphone', 'cellphone'], ['title', 'title']]);

export { formSchema };
