import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { Wrapper, Input, SendButton } from './styles';

import { InputNewsletters } from '../../../components/form/input-newsletters';

import subscribeService from '../../../services/newsletter/newsletter.service'

import domEventsUtil from '../../../utils/dom-events/dom-events.util';
import eventsConstants from '../../../constants/events';
import httpClient from '../../../clients/http/http.client';

/**
 * global
 * FormData
 * Promise
 */

const customId = "custom-id-yes";

const formSchema = yup.object().shape({
  email: yup.string().required('Este campo é obrigatório').email('Informe um email valido')
})

export function NewsletterForm() {
  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    resolver: yupResolver(formSchema)
  })

  const handleSubscribeLead = useCallback(async values => {
    try {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: true },
      });

      const data = await subscribeService.subscribeLead({ email: values.email });

      if (data.status === 201) {
        toast.info('Inscrição realizada com sucesso!', {
          autoClose: false,
          toastId: customId,
        });
      }

      if (data.response && data.response.status === 400) {
        toast.info('Identificamos sua inscrição, estamos felizes em ter você no programa!', {
          autoClose: false,
          toastId: customId,
        });

        reset();

        return;
      }

      reset();

      if (data.status !== 201 && data.status !== 400) {
        throw 'Erro, tente novamente mais tarde!'

      }
    } catch (err) {
      toast.error(err, {
        autoClose: false,
        toastId: customId,
      });

    } finally {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: false },
      });
    }
  }, []);


  return (
    <Wrapper onSubmit={handleSubmit(handleSubscribeLead)}>
      <div>
        <InputNewsletters
          name="email"
          label="E-mail"
          placeholder="E-mail"
          error={errors.email}
          {...register('email')}
        />
      </div>
      <SendButton>enviar</SendButton>
    </Wrapper>
  );
}