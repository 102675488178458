import httpClient from "../../clients/http/http.client";
import userService from "../user/user.service";

async function getPaymentLinkInformationsById({ paymentLinkId }) {
  const { token } = userService.retrieve();

  return httpClient.get({ path: `/payments/links/${paymentLinkId}`, token });
}

async function payPlanByPaymentLinkId({
  paymentInfo,
  creditCardInfo,
  isCompany,
  paymentLinkId,
}) {
  const { token } = userService.retrieve();

  const phoneNumberUnmasked = paymentInfo.phoneNumber.replace(/[^a-zA-Z0-9]+/g, '');

  const countryCode = phoneNumberUnmasked.slice(0, 2);
  const areaCode = phoneNumberUnmasked.slice(2, 4);
  const numberPhone = phoneNumberUnmasked.slice(4, phoneNumberUnmasked.length);

  const expirationDate = creditCardInfo.expiryDate;
  const expirationMonth = expirationDate.split('/')[0];
  const expirationYear = expirationDate.split('/')[1].substr(2);

  const params = {
    customer: {
      name: isCompany ? paymentInfo.social : paymentInfo.personName,
      email: paymentInfo.email,
      type: isCompany ? "company" : "individual",
      document: paymentInfo.document.replace(/\D/g, ""),
      document_type: isCompany ? "CNPJ" : "CPF",
      phones: {
        mobile_phone: {
          country_code: countryCode,
          area_code: areaCode,
          number: numberPhone,
        }
      },
    },
    credit_card: {
      number: creditCardInfo.cardNumber.replace(/\./g, ''),
      holder_name: creditCardInfo.ownerName,
      exp_month: expirationMonth,
      exp_year: expirationYear,
      cvv: creditCardInfo.securityCode,
      brand: creditCardInfo.brand,
      billing_address: {
        line_1: `${paymentInfo.form.number}, ${paymentInfo.form.street}, ${paymentInfo.form.neighborhood}`,
        line_2: paymentInfo.form.additionalInfo,
        zip_code: paymentInfo.form.zipCode.replace(/\D/g, ""),
        city: paymentInfo.form.city,
        state: paymentInfo.form.state,
        country: "BR",
      }
    }
  };

  return httpClient.post({
    path: `/payments/links/${paymentLinkId}/pay`,
    params,
    token
  });
}

export { getPaymentLinkInformationsById, payPlanByPaymentLinkId };
