import styled, { css } from 'styled-components';
import { AngleDown } from 'styled-icons/fa-solid/AngleDown';

import { SelectInput } from '../../styled/inputs/inputs';
import { iconBaseStyle } from '../../styled/helpers/helpers';

export const Wrapper = styled.div`
  display: flex;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Select = styled(SelectInput)`
  align-self: center;
  margin: 0;
  z-index: 0;
  background-color: transparent;
`;

export const ArrowDownIcon = styled(AngleDown)`
  ${iconBaseStyle}
  align-self: center;
  margin-left: -30px;
  height: 18px;

  ${({ disabled }) => disabled && css`
    opacity: 0.7;
  ` }
`;

export const Option = styled.option``;