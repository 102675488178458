import styled from 'styled-components';
import { ellipsis } from 'polished';

import { BaseLabel } from '../../styled/labels/labels';
import { COLOR_17 } from '../../styled/variables/variables';
import { pointer } from '../../styled/helpers/helpers';

export const Wrapper = styled.div`
  text-align: left;
  width: 100%;
  padding-top: 10px;
  ${ellipsis('100%')};
  display: flex;
`;

export const Label = styled(BaseLabel)`
  color: ${COLOR_17};
  margin-bottom: 5px;
  font-size: 14px;
  ${pointer}
`;