import React, { useEffect } from 'react';

import { AnnotationTextareaWrapper, TextArea } from './styles';

export function AnnotationTextarea({ label, value, isResponsibleLead }) {
  useEffect(() => {
    document.getElementById('annotation-textarea').value = value;
  }, [value]);

  return (
    <AnnotationTextareaWrapper>
      <label>{label}</label>
      <TextArea
        id="annotation-textarea"
        placeholder='Informe suas observações aqui...'
        isResponsibleLead={isResponsibleLead}
        disabled={!isResponsibleLead}
        maxLength={2000}
      />
    </AnnotationTextareaWrapper>
  )
}
