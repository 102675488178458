import styled from 'styled-components';

import { BaseInput } from '../../../styled/inputs/inputs';
import { mqTablet } from '../../../styled/helpers/helpers';
import { BaseButton } from '../../../styled/buttons/buttons';
import { COLOR_2, COLOR_1 } from '../../../styled/variables/variables';

export const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-self: center;
  /* width: 50%; */
  margin-bottom: 100px;

  ${mqTablet`
    flex-direction: row;
  ` }

@media (min-width: 400px)
{
    flex-direction: row;
    align-self: center;
 }

@media (max-width: 400px) {
  align-items: center;

  > button {
    width: 280px;
  }
}
`;


export const SendButton = styled(BaseButton).attrs({
  type: 'submit',
})`
  background-color: ${COLOR_1};
  text-transform: uppercase;
  color: ${COLOR_2};
  width: 130px;
  border: none;
  font-weight: 700;
  /* align-self: center; */

  ${mqTablet`
    border-radius: 0 3px 3px 0;
  ` }
`;
