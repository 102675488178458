import React from 'react';
import styled from 'styled-components';

import { COLOR_17, COLOR_2 } from '../../../styled/variables/variables';
import { Divider } from '../../../styled/components/components';
import { BaseLabel } from '../../../styled/labels/labels';

/*
  global
  process
*/

const { PUBLIC_URL } = process.env;

const Cover = () => (
  <Wrapper>
    <CDivider />
    <Label>sobre o</Label>
    <KoortName />
    <CDivider />
  </Wrapper>
);

const Wrapper = styled.div`
  padding: 150px 0 50px 0;
  background-color: ${ COLOR_17 };
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const CDivider = styled(Divider)`
  background-color: ${ COLOR_2 };
  width: 200px;
  align-self: center;
  opacity: 0.7;
`;

const Label = styled(BaseLabel)`
  font-weight: 300;
  color: ${ COLOR_2 };
  align-self: center;
  text-transform: uppercase;
  margin-bottom: 5px;
`;

const KoortName = styled.img.attrs({
  src: `${ PUBLIC_URL }/imgs/koort-name-white.png`,
})`
  height: 40px;
  width: fit-content;
  align-self: center;
`;

export default Cover;
