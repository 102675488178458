import styled, { css } from 'styled-components';
import { transitions } from 'polished';
import { KeyboardArrowLeft } from 'styled-icons/material/KeyboardArrowLeft';
import { KeyboardArrowRight } from 'styled-icons/material/KeyboardArrowRight';
import { Trash } from 'styled-icons/boxicons-solid/Trash';

import { COLOR_2 } from '../../styled/variables/variables';
import { iconBaseStyle, mqTablet, pointer, mqDesktop, dimmer } from '../../styled/helpers/helpers';

import ImageSlider from '../image-slider/ImageSlider';

export const BackButtonIcon = styled(KeyboardArrowLeft)`
  ${iconBaseStyle}
  color: ${COLOR_2};
  height: 60px;
  align-self: flex-start;
  position: relative;
  ${pointer}

  ${({ height }) => css`
    margin-top: calc(((${height} / 2) * -1) - 30px);
  ` }

  ${mqTablet`
    ${({ tabletHeight }) => css`
      margin-top: calc(((${tabletHeight} / 2) * -1) - 30px);
    ` }
  ` }

  ${mqDesktop`
    ${({ desktopHeight }) => css`
      margin-top: calc(((${desktopHeight} / 2) * -1) - 30px);
    ` }
  ` }
`;
export const NextButtonIcon = styled(BackButtonIcon).attrs({
  as: KeyboardArrowRight
})``;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;
  position:relative;

  ${BackButtonIcon}, ${NextButtonIcon} {
    opacity: 0;
    transition: opacity .25s ease;
    z-index: 2;
  }

  &:hover {
    &:before, &:after {
      opacity: 0.3;
    }

    ${BackButtonIcon}, ${NextButtonIcon} {
      opacity: 1;
    }
  }

  &:before, &:after {
    position: absolute;
    z-index: 1;
    content: '';
    display: block;
    top: 0;
    bottom: 0;
    width: 60px;
    pointer-events: none;
    opacity: 0;
    transition: opacity .25s ease;
  }

  &:before {
    left: 0;
    background: rgb(0,0,0);
    background: -moz-linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,212,255,0) 100%);
    background: -webkit-linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,212,255,0) 100%);
    background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,212,255,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#00d4ff",GradientType=1);
  }

  &:after {
    right: 0;
    background: rgb(0,0,0);
    background: -moz-linear-gradient(270deg, rgba(0,0,0,1) 0%, rgba(0,212,255,0) 100%);
    background: -webkit-linear-gradient(270deg, rgba(0,0,0,1) 0%, rgba(0,212,255,0) 100%);
    background: linear-gradient(270deg, rgba(0,0,0,1) 0%, rgba(0,212,255,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#00d4ff",GradientType=1);
  }
`;

export const GalleryImageDim = styled.div`
  height: 20%;
  width: 5%;
  align-self: flex-end;
    ${mqTablet`
    > div {
      ${transitions(['opacity ease .3s', 'width 0s .3s'])};
      width: 0;
      opacity: 0;
    }
    &:hover {
      > div {
        ${transitions(['opacity ease .3s', 'width 0s .0s'])};
        width: 100%;
        opacity: 1;
      }
    }
  ` };
  ${dimmer()};
  justify-content: flex-end;
  align-items:flex-end;
  background-color:transparent;
  position: absolute;
  z-index:10;
  left:49%;
`;

export const DimTrashIcon = styled(Trash)`
  ${pointer};
  color: ${COLOR_2};
  position: absolute;
  bottom: 20px;
  height: 30px;
`;

export const SImageSlider = styled(ImageSlider)`
  width: 100%;
`;
