
import httpClient from '../../clients/http/http.client';

import AccessData from '../../models/access-data/access-data.model';
import Address from '../../models/address/address.model';
import Feature, { GROUP_TYPES } from '../../models/feature/feature.model';
import Geolocation from '../../models/geolocation/geolocation.model';
import Image, { TYPES as IMAGE_TYPES } from '../../models/image/image.model';
import Price from '../../models/price/price.model';
import RealEstate from '../../models/real-estate/real-estate.model';
import Request from '../../models/request/request.model';
import User from '../../models/user/user.model';

/*
  global
*/

const get = async ({
  token,
}) => {

  const response = await httpClient.get({
    path: '/realtors/requests',
    token,
  });

  const result = response.data.map(r => new Request({
    ...r,
    id: r._id,
    user: new User({
      ...r.owner,
    }),
    realEstate: new RealEstate({

    }),
  }));

  return response.data;
};

const send = async ({ data, id, token }) => {
  const params = {
    owner: {
      name: data.name,
      email: data.email,
      phone: data.telephone,
      message: data.message,
    },
    address: {
      city: data.city,
      neighborhood: data.neighborhood,
    },
    realEstate: {
      price: data.price,
      area: data.area,
      bathrooms: data.bathrooms,
      bedrooms: data.bathrooms,
      parkingSpaces: data.parkingSpaces
    },
    types: {
      property: data.propertyType,
      transaction: data.propertyUse,
    },
  }

  const response = await httpClient.post({
    path: `/realtors/requests/${id}`,
    params,
    token,
  });

  return response;
}

const remove = async ({ token, id }) => {
  const response = await httpClient.patch({
    path: `/realtors/requests/delete/${id}`,
    token
  });

  return response.data;
};

const search = async ({ params }) => {
  const { data } = await httpClient.get({
    path: '/users/realtors/search',
    params
  })

  return data;
}

export default {
  get,
  send,
  search,
  remove
};
