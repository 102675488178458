import React, { PureComponent } from 'react';
import propTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Row } from 'react-bootstrap';

import { DETAIL_VIEW, RESULTS_VIEW} from '../../../search/view-mode-selector/ViewModeSeletor';
import AdTypeButtonList, { BUY_AD_TYPE, NEW_RELEASES_AD_TYPE } from './ad-type-button-list/AdTypeButtonList';
import { Select } from './components/select';

import listingV2Service from '../../../../services/listing/listing-v2.service';

import queryStringUtil from '../../../../utils/query-string/query-string.util';
import pathsConstants from '../../../../constants/paths';

import {
  Wrapper,
  SearchBarWrapper,
  FieldsFormWrapper,
  PropertyAndBedroomWrapper,
  ItemFormWrapper,
  SelectWrapper,
  Button,
} from './styles';

const customId = "custom-id-yes";

class SearchForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedAdType: BUY_AD_TYPE,

      citiesOptions: [],
      districtsOptions: [],
      propertyTypesOptions: [],
      bedroomsOptions: [],
      selectedCityOption: null,
      selectedDistrictOption: null,
      selectedPropertyTypeOption: null,
      selectedBedroomOption: null,
    };
  }

  getCities = async ({ adType }) => {
    try {
      const { status, data } = await listingV2Service.getCities({ adType });

      if (status !== 200) {
        throw new Error('Não foi possível buscar as cidades!');
      }

      const citiesOptions = data.map(item => {
        return {
          label: item.state,
          options: item.cities.map(city => {
            return {
              value: city,
              label: city,
              propertyTypes: item.propertyTypes,
              bedrooms: item.bedrooms,
              uf: item.uf,
            }
          })
        }
      });

      this.setState(prevState => ({
        ...prevState,
        citiesOptions,
      }));
    } catch (error) {
      toast.error(error.message, {
        autoClose: 3000,
      });
    }
  }

  async componentDidMount() {
    localStorage.setItem("adTypeForSearchInMap", "Venda");

    this.getCities({ adType: "Venda" });
  }

  resetSearchFormStates = ({ adType }) => {
    this.setState(prevState => ({
      ...prevState,
      citiesOptions: [],
      districtsOptions: [],
      propertyTypesOptions: [],
      bedroomsOptions: [],
      selectedCityOption: null,
      selectedDistrictOption: null,
      selectedPropertyTypeOption: null,
      selectedBedroomOption: null,
      selectedAdType: adType,
    }));
  }

  handleAdTypeSelection = async event => {
    event.preventDefault();

    const { name: adType } = event.target;

    localStorage.setItem("adTypeForSearchInMap", adType);

    this.resetSearchFormStates({ adType });

    this.getCities({ adType });
  };

  handleChangeCity = async (option) => {
    try {
      const { status, data } = await listingV2Service.getDistricts({
        city: option.value,
        adType: localStorage.getItem("adTypeForSearchInMap"),
      });

      if (status !== 200) {
        throw new Error('Não foi possível buscar os bairros!');
      }

      if (this.state.selectedCityOption && this.state.selectedCityOption.uf !== option.uf) {
        this.setState(prevState => ({
          ...prevState,
          selectedPropertyTypeOption: null,
          selectedBedroomOption: null,
        }));
      }

      const districtsOptions = data.map(district => {
        return {
          label: district.neighborhood,
          value: district.neighborhood,
        }
      });

      const propertyTypesOptions = option.propertyTypes.map(propertyType => {
        return {
          label: propertyType.name,
          value: propertyType.index,
        }
      });

      const serializedBedrooms = [...new Set(option.bedrooms)].filter(item => item <= 4);

      const bedroomsOptions = serializedBedrooms.map(bedroom => {
        if (bedroom < 4) {
          return {
            label: String(bedroom),
            value: String(bedroom),
          }
        }

        return {
          label: `${bedroom}+`,
          value: `${bedroom}+`,
        }
      });

      this.setState(prevState => ({
        ...prevState,
        districtsOptions,
        propertyTypesOptions,
        bedroomsOptions,
        selectedCityOption: option,
        selectedDistrictOption: null,
      }));
    } catch (error) {
      toast.error(error.message, {
        autoClose: 3000,
      })
    }
  }

  handleChangeDistrict = (option) => {
    this.setState(prevState => ({
      ...prevState,
      selectedDistrictOption: option,
    }));
  }

  handleChangePropertyType = (option) => {
    this.setState(prevState => ({
      ...prevState,
      selectedPropertyTypeOption: option,
    }));
  }

  handleChangeBedroom = (option) => {
    this.setState(prevState => ({
      ...prevState,
      selectedBedroomOption: option,
    }));
  }

  search = event => {
    if (event) event.preventDefault();
    const {
      selectedAdType,
      selectedCityOption,
      selectedDistrictOption,
      selectedPropertyTypeOption,
      selectedBedroomOption,
    } = this.state;

    console.log({selectedAdType,
      selectedCityOption,
      selectedDistrictOption,
      selectedPropertyTypeOption,
      selectedBedroomOption,})

    const { history } = this.props;

    if (selectedCityOption === null) {
      toast.error('Selecione uma cidade!', {
        autoClose: false,
        toastId: customId,
      });

      return;
    }

    let params = {};

    if (selectedAdType) {
      const types = {
        "Venda": 0,
        "Lançamento": 1,
        "Locação": 2,
      };

      const transactionType = types[selectedAdType];

      params.transaction = transactionType;
    }

    if (selectedPropertyTypeOption) {
      params.type = selectedPropertyTypeOption.value;
    }

    if (selectedCityOption) {
      params.city = selectedCityOption.value;
      params.uf = selectedCityOption.uf;
    }

    if (selectedDistrictOption) {
      params.neighborhood = selectedDistrictOption.value;
    }

    if (selectedBedroomOption) {
      params.bedrooms = selectedBedroomOption.value.replace('+', '');
    }

    const screenWidth = window.innerWidth;

    if (screenWidth <= 1079) {
      params.viewMode = RESULTS_VIEW;
    } else {
      params.viewMode = DETAIL_VIEW;
    }

    console.log(params)

    history.push({
      pathname: pathsConstants.FILTER,
      search: queryStringUtil.stringify(history, params),
    });
  };

  render() {
    const {
      citiesOptions,
      districtsOptions,
      propertyTypesOptions,
      bedroomsOptions,
      selectedCityOption,
      selectedDistrictOption,
      selectedPropertyTypeOption,
      selectedBedroomOption
    } = this.state;

    return (
      <Wrapper>
        <AdTypeButtonList
          onButtonClick={this.handleAdTypeSelection}
          selectedAdType={this.state.selectedAdType}
        />

        <SearchBarWrapper>
          <Row style={{ margin: '0px', marginTop: '8px' }}>
            <FieldsFormWrapper className="first">
              <ItemFormWrapper className="city">
                <SelectWrapper>
                  <label>Cidade</label>
                  <Select
                    name="city-select"
                    options={citiesOptions}
                    onChange={option => this.handleChangeCity(option)}
                    placeholder="Pesquise uma cidade"
                    value={selectedCityOption}
                  />
                </SelectWrapper>
              </ItemFormWrapper>
            </FieldsFormWrapper>
          </Row>
          <Row style={{ margin: '0px' }}>
            <FieldsFormWrapper>
              <ItemFormWrapper>
                <SelectWrapper>
                  <label>Bairro</label>
                  <Select
                    name="bairro-select"
                    onChange={option => this.handleChangeDistrict(option)}
                    placeholder="Pesquise um bairro"
                    noOptionsMessage={() => 'Escolha a cidade'}
                    options={districtsOptions}
                    value={selectedDistrictOption}
                    isClearable
                  />
                </SelectWrapper>
              </ItemFormWrapper>

              <PropertyAndBedroomWrapper>
                <ItemFormWrapper className="type">
                  <SelectWrapper>
                    <label>Tipo</label>
                    <Select
                      name="tipo-select"
                      onChange={option => this.handleChangePropertyType(option)}
                      placeholder="Selecione"
                      isSearchable={false}
                      options={propertyTypesOptions}
                      noOptionsMessage={() => 'Escolha a cidade'}
                      value={selectedPropertyTypeOption}
                      isClearable
                    />
                  </SelectWrapper>
                </ItemFormWrapper>
                <ItemFormWrapper className="bedrooms">
                  <SelectWrapper>
                    <label>N° de quartos</label>
                    <Select
                      name="quartos-select"
                      onChange={option => this.handleChangeBedroom(option)}
                      placeholder="Selecione"
                      isSearchable={false}
                      value={selectedBedroomOption}
                      options={bedroomsOptions}
                      noOptionsMessage={() => 'Escolha a cidade'}
                      isClearable
                    />
                  </SelectWrapper>
                </ItemFormWrapper>
              </PropertyAndBedroomWrapper>
            </FieldsFormWrapper>
          </Row>
        </SearchBarWrapper>
        <Button onClick={this.search}>ENCONTRAR</Button>
      </Wrapper>
    );
  }
}

SearchForm.propTypes = {
  history: propTypes.object,
};

SearchForm.defaultProps = {};

export default withRouter(SearchForm);
