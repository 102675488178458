
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Persist } from 'react-persist-plus';

import InputWrapper from '../../../components/input-wrapper';
import Checkbox from '../../../components/checkbox';

/*
  global
  window
*/

class SurroundingFeatures extends PureComponent {

  constructor(props) {
    super(props);

    this.state ={
      form: {
        features: [ ],
      },
    };
  }

  handleFormChange = event => {
    const { name, checked } = event.target;
    const { form } = this.state;

    const indexFound = form.features.findIndex(s => s === name);

    let features = [ ...form.features ];
    if(checked) features.push(name);
    else features.splice(indexFound, 1);

    this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        features,
      },
    }));
  };

  render() {
    const { form } = this.state;

    return (
      <Wrapper
        onChange={ this.handleFormChange }
      >
        <Persist
          name='REPORT_BUILDER_SURROUNDING_FEATURES_FORM'
          data={ form }
          onMount={ data => this.setState(state => ({
            ...state,
            form: data,
          })) }
          store={ window.sessionStorage }
        />
        { options.map((feature, index) => (
          <FInputWrapper
            key={ feature }
            label={ feature }
            htmlFor={ `${ feature }_${ index.toString() }` }
          >
            <FCheckBox
              id={ `${ feature }_${ index.toString() }` }
              name={ feature }
              checked={ !!form.features.find(s => s === feature) }
            />
          </FInputWrapper>
        )) }
      </Wrapper>
    );
  }
}

const Wrapper = styled.form`
  margin-bottom: 20px;
`;

const FInputWrapper = styled(InputWrapper)`
  flex-direction: row-reverse;
  justify-content: flex-end;
  margin-bottom: 10px;
`;

const FCheckBox = styled(Checkbox)`
  margin-right: 5px;
`;

const options = [
  'Próximo a Academia',
  'Próximo a Áreas de Lazer',
  'Próximo a Centro Empresarial',
  'Próximo a Comércio Diversos',
  'Próximo a Escola Particular',
  'Próximo a Escola Pública',
  'Próximo a Farmácia',
  'Próximo a Mercado',
  'Próximo a Parques e Áreas Verdes',
  'Próximo a Praia',
  'Próximo a Restaurante',
  'Próximo a Serviços de Saúde',
  'Próximo ao Metrô',
  'Próximo ao Shopping',
];

export default SurroundingFeatures;
