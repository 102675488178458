import Base from '../base/base.model';

class Geolocation extends Base {

  constructor({
    id,

    address,
    formattedAddress,
    geolocation,
    latitude,
    longitude,
    mapViewport,
    type,

    east,
    north,
    south,
    west,
  }) {
    super({ id });

    this.address = address;
    this.formattedAddress = formattedAddress;
    this.geolocation = geolocation;
    this.latitude = latitude;
    this.longitude = longitude;
    this.mapViewport = mapViewport;
    this.type = type;

    this.east = east;
    this.north = north;
    this.south = south;
    this.west = west;
  }
}

export default Geolocation;
