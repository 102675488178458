
import React, { PureComponent } from 'react';
import styled from 'styled-components';

import ImageDropGallery from '../../../../components//image-drop-gallery';

class Images extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      form: {
        images: [ ],
      },
    };
  }

  handleImageDropGalleryUpdate = images => {
    this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        images,
      },
    }));
  };

  render() {

    return (
      <Wrapper>
        <IImageDropGallery
          onUpdate={ this.handleImageDropGalleryUpdate }
        />
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  margin-bottom: 50px;
`;

const IImageDropGallery = styled(ImageDropGallery)``;

export default Images;
