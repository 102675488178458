
import React from 'react';
import styled from 'styled-components';
import { LocationOn } from 'styled-icons/material/LocationOn';

import { COLOR_4, COLOR_3 } from '../../../../../styled/variables/variables';
import { BaseLabel } from '../../../../../styled/labels/labels';
import { dimmer, iconBaseStyle, mqTablet, pointer } from '../../../../../styled/helpers/helpers';
import { transitions } from 'polished';
import currencyUtil from '../../../../../utils/currency/currency.util';

const Info = ({
  description,
  city,
  neighborhood,
  property,
  sale,
  price,
  main,
  rental,
  transaction,
}) => (
  <Wrapper>
    <TopWrapper>
      <RealEstateTypeLabel>[ { property } ]</RealEstateTypeLabel>
      <CityLabel>{ city }</CityLabel>
      <NeighborhoodWrapper>
        <LocationIcon />
        <NeighborhoodLabel>{ neighborhood }</NeighborhoodLabel>
      </NeighborhoodWrapper>
      <DescriptionLabel>
        { `${ description && description.slice(0, 135) }...` }
      </DescriptionLabel>
    </TopWrapper>
    <PriceLabel>{
      currencyUtil.format({
        value: main,
      })
    }</PriceLabel>
  </Wrapper>
);

const Wrapper = styled.div`
  height: 255px;
  width: 240px;
  /* background-color: ${ COLOR_4 };
  opacity: 0.7; */
  ${ dimmer(0.7, COLOR_4) }
  border-radius: 1px;;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px 10px 40px 10px;
  position: relative;
  ${ pointer }

  ${ mqTablet`
    width: 370px;
    height: 220px;
    padding: 30px 40px;

    ${ transitions([ 'transform' ], 'ease .3s') }
    &:hover {
      transform: scale(1.1);
    }
  ` }
`;

const TopWrapper = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
`;

const RealEstateTypeLabel = styled(BaseLabel)`
  align-self: center;
  color: ${ COLOR_3 };
  font-size: 12px;
  font-weight: 200;
  margin-bottom: 5px;
`;

const CityLabel = styled(RealEstateTypeLabel)`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
`;

const NeighborhoodWrapper = styled.div`
  align-self: center;
  display: flex;
  margin-bottom: 10px;
`;

const LocationIcon = styled(LocationOn)`
  ${ iconBaseStyle }
  color: ${ COLOR_3 };
  height: 14px;
  align-self: center;
  margin-right: 3px;
  margin-top: 1px;
`;

const NeighborhoodLabel = styled(RealEstateTypeLabel)`
  font-size: 12px;
  align-self: center;
  margin-bottom: 0;
`;

const DescriptionLabel = styled(RealEstateTypeLabel)`
  margin-bottom: 0;
  text-align: center;
`;

const PriceLabel = styled(CityLabel)`
  font-size: 14px;
`;

export default Info;
