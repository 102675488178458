import styled, { css } from 'styled-components';
import Modal from 'react-modal';

import {
  COLOR_1,
  COLOR_2,
  COLOR_20,
  COLOR_13,
  COLOR_7,
  COLOR_BORDER
} from '../../../styled/variables/variables';

export const Container = styled.div`
  margin: 5% 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1000px;
  align-self: center;
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ loadingPlansForUpgrade = false }) => loadingPlansForUpgrade && css`
    justify-content: center;
    height: 100%;
  `}

  h3 {
    font-size: 18px;
    font-weight: 500;
  }

  p {
    font-size: 16px;
    margin-top: 12px;
    text-align: center;
  }
`;

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 24px;

  @media (min-width: 1020px) {
    margin-top: 150px;
  }

  @media (max-width: 1020px) {
    margin-top: 24px;
    margin-left: unset;
  }

  ${({ loadingPlansForUpgrade = false }) => loadingPlansForUpgrade && css`
    justify-content: center;
    height: 100%;
  `}

  h3 {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
  }

  p {
    font-size: 16px;
    margin-top: 12px;
    text-align: center;
    max-width: 500px;
  }
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ loadingPlansForUpgrade = false }) => loadingPlansForUpgrade && css`
    justify-content: center;
    height: 100%;
  `}

  h3 {
    font-size: 18px;
    font-weight: 600;
  }

  p {
    font-size: 16px;
    margin-top: 12px;
    text-align: center;
    max-width: 500px;
  }
`;

export const AboutPlanContent = styled.div`
  max-width: 360px;

  border: 1px solid #E5E5E5;
  border-radius: 4px;

  strong {
    display: block;
    font-size: 24px;
    font-weight: 600;
    padding: 24px 24px 0 24px;
  }

  p {
    font-size: 16px;
    margin-top: 12px;
    margin-bottom: 0;
    opacity: 0.8;
    padding: 0 24px;
  }

  @media (min-width: 876px) and (max-width: 1020px) {
    max-width: unset;
    display: flex;
    align-items: center;
    justify-content: space-between;

    height: 300px;

    strong {
      padding-top: unset;
    }
  }

  @media (max-width: 875px) {
    max-width: unset;
  }
  `;

export const AboutPlanContentBoxTop = styled.div`
  @media (min-width: 876px) and (max-width: 1020px) {
    max-width: 360px;
  }
`;

export const AboutPlanContentBoxBottom = styled.div`
  width: 100%;

  @media (min-width: 876px) and (max-width: 1020px) {
    max-width: 290px;
  }
`;


export const PlanUseContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 24px;
  padding: 0 24px;
`;

export const Label = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: #707070;
`;

export const AdsInUseContent = styled.div`
    h3 {
    font-size: 24px;
    font-weight: 600;
    color: #363636;
  }
`;

export const PlanDivider = styled.div`
  background-color: #E5E5E5;
  height: 58px;
  width: 1px;
`;


export const AdsTotalContent = styled.div`
  h3 {
    font-size: 24px;
    font-weight: 600;
  }
`;

export const Separator = styled.div`
  background-color: #E5E5E5;
  height: 1px;
  margin: 32px 0;
  width: 100%;

  @media (min-width: 876px) and (max-width: 1020px) {
    height: 252px;
    width: 1px;
  }
`;

export const PriceAndContractContent = styled.div`
  padding: 0 24px 24px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  min-height: 140px;
`;

export const PriceContent = styled.div`
  h3 {
    font-size: 24px;
    font-weight: 600;
  }

  sub {
    font-size: 12px;
    font-weight: 500;
  }
`;

export const ContractContent = styled.div`
  div {
    & + div {
      margin-top: 24px;
    }
  }
`;

export const ContractInformContent = styled.div``;


export const RenewPlanButton = styled.button`
  width: 100%;
  background-color: ${COLOR_7};
  border-radius: 0 0 4px 4px;
  height: 54px;

  color: ${COLOR_2};
`;

export const AboutAndUpgradePlanContent = styled.div`
  display: flex;

  @media (max-width: 1020px) {
    flex-direction: column;
  }
`;

export const UpgradePlanContent = styled.div`
  flex: 1;
  margin-left: 12px;

  border: 1px solid #E5E5E5;
  border-radius: 4px;
  padding: 24px;

  h3 {
    font-size: 24px;
    font-weight: 600;
  }

  @media (max-width: 1020px) {
    margin-left: unset;
    margin-top: 12px;
  }

  @media (max-width: 552px) {
    padding: 24px 0;
  }
`;

export const UpgradePlanExpose = styled.div`
  display: flex;
  height: 100%;

  @media (max-width: 1020px) {
    height: 480px;
  }

  @media (max-width: 552px) {
    overflow-x: scroll;
  }
`;

export const UpgradePlanCard = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  & + div {
    margin-left: 12px;
  }

  &:first-child {
    margin-left: 24px;
  }

  &:last-child {
    margin-right: 24px;
  }

  @media (max-width: 552px) {
    min-width: 152px;
  }
`;

export const UpgradePlanCardHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  height: 132px;

  background-color: ${COLOR_20};

  ${({ isAvailable }) => !isAvailable && css`
    opacity: 0.4;
  `}
`;

export const UpgradePlanCardName = styled.strong`
  display: block;
  color: ${COLOR_2};
  font-size: 16px;
  font-weight: 400;
  text-align: center;
`;

export const UpgradePlanCardPrice = styled.div`
  font-size: 24px;
  color: ${COLOR_2};
  margin-top: 16px;
  font-weight: 600;

  > sub {
    font-size: 12px;
    font-weight: 500;
  }
`;

export const UpgradePlanCardDetailsList = styled.ul`
  padding-left: 0;
  margin: auto 0;

  display: flex;
  flex-direction: column;
  align-items: center;

  li {
    text-align: center;

    & + li {
      margin-top: 16px;
    }
  }

  ${({ isAvailable }) => !isAvailable && css`
    opacity: 0.4;
  `}
`;


export const UpgradePlanCardButton = styled.button`
  margin-top: auto;
  background-color: ${COLOR_13};

  font-size: 16px;
  color: ${COLOR_2};
  padding: 16px;

  height: 56px;

  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    cursor: not-allowed;
    filter: grayscale(0.6);
  }
`;

export const ChangePlanModal = styled(Modal)``;

export const ChangePlanModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ChangePlanModalBody = styled.div`
  background: ${COLOR_2};
  height: ${window.innerHeight}px;
  width: 100%;
  max-width: 500px;
  position: relative;

  ${({ loadingProcessingPayment, processingPaymentSuccessFul }) =>
    !loadingProcessingPayment &&
    !processingPaymentSuccessFul &&
    css`
    overflow-y: scroll;
    padding: 48px 24px 24px;
  `}
`;

export const ChangePlanModalCloseButton = styled.button`
  position: absolute;
  top: 24px;
  right: 24px;
  padding: 4px;
  border: 1px solid ${COLOR_BORDER};
  background-color: transparent;
  color: ${COLOR_BORDER};

  transition: all .2s;

  &:hover {
    border-color: #000000;
    color: #000000;
  }
`;

export const LoadingProcessingPayment = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h3 {
    font-size: 20px;
    max-width: 320px;
    text-align: center;

    margin-bottom: 24px;
  }
`;

export const ProcessingPaymentSuccessFul = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100%;

  > svg {
    color: ${COLOR_1};
  }

  h3 {
    font-size: 20px;
    max-width: 320px;
    text-align: center;
    width: 100%;
    margin-top: 32px;;
  }

  span {
    font-size: 14px;
    margin-top: 32px;
  }
`;

export const RenewPlanModal = styled(Modal)``;

export const RenewPlanModalBody = styled.div`
  background: ${COLOR_2};
  height: ${window.innerHeight}px;
  width: 100%;
  max-width: 500px;
  position: relative;

  ${({ loadingProcessingRenewal, processingPaymentRenewalSuccessFul }) =>
    !loadingProcessingRenewal &&
    !processingPaymentRenewalSuccessFul &&
    css`
    overflow-y: scroll;
    padding: 48px 24px 24px;
  `}
`;

export const CancelPlanModal = styled(Modal)``;

export const CancelPlanModalBody = styled.div`
  background: ${COLOR_2};
  height: ${window.innerHeight}px;
  width: 100%;
  max-width: 500px;
  position: relative;
  padding: 48px 24px 24px;

  h2 {
    font-size: 20px;
    font-weight: 600;
  }

  span {
    font-size: 16px;
    margin: 32px 0 16px;
    display: block;
  }
`;

export const CancelPlanModalButtons = styled.div`
  margin-top: 36px;
  display: flex;
  flex-direction: column;

  button {
    transition: filter .2s;

    &:first-child{
      background-color: ${COLOR_1};
      padding: 8px;

      font-size: 18px;
      font-weight: 500;
      color: ${COLOR_2};
    }

    &:last-child {
      background-color: transparent;
      font-size: 15px;
      font-weight: 500;
      margin-top: 20px;
      color: ${COLOR_BORDER};
    }

    &:hover {
      filter: brightness(0.9);
    }
  }
`;

export const CancelPlanButton = styled.button`
  height: 54px;
  background-color: ${COLOR_7};
  font-size: 16px;
  font-weight: 500;
  color: ${COLOR_2};
  margin-top: 42px;
`;
