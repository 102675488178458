/* eslint-disable no-unreachable */
import React, { useState, useEffect } from 'react';
import { RiErrorWarningLine } from 'react-icons/ri';
import { Route, Switch } from "react-router-dom";

import Cover from '../../cover/Cover';
import Ads from '../../ads/Ads'
import Tabs from '../../../../components/tabs';
import { AttachedProperty } from './attached-property';

import userProfileService from "../../../../services/user-profile/user-profile.service";
import userService from '../../../../services/user/user.service';

import eventsConstants from "../../../../constants/events";
import pathsConstants from "../../../../constants/paths";
import domEventsUtil from "../../../../utils/dom-events/dom-events.util";

import {
  Wrapper,
  ContentWrapper,
  ErrorLoadBrokerData
} from './styles';

const tabs = [
  {
    label: "Todos os imóveis",
    path: pathsConstants.DASHBOARD_BROKERS_ATTACH_PROPERTY,
    showTooltip: true,
    tooltipTitle:
      "Aqui você tem acesso a todos os imóveis cadastrados em sua conta disponíveis para vínculo."
  },
  {
    label: "Imóveis vinculados",
    path: pathsConstants.DASHBOARD_BROKERS_ATTACH_PROPERTY_LINKED,
    showTooltip: true,
    tooltipTitle:
      "Aqui você tem acesso a todos imóveis vinculados a esse corretor."
  },
];

export function AttachProperty() {
  const [brokerData, setBrokerData] = useState({});
  const [errorLoadBrokerData, setErrorLoadBrokerData] = useState(false);

  async function loadBrokerInfo() {

    try {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: true },
      });

      const params = new URL(document.location).searchParams;

      const brokerId = params.get("brokerId");

      // Se não houver um brokerId, redirecionar para a home
      if (!brokerId) {
        window.open('/', '_self');
      }

      const data = await userProfileService.getUserBasicProfile({ userId: brokerId })

      if (!data._id) {
        throw new Error('Erro inesperado!');
      }

      setBrokerData(data);
    } catch {
      setErrorLoadBrokerData(true);
    } finally {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: false },
      });
    }
  }

  useEffect(() => {
    loadBrokerInfo();
  }, []);

  return (
    <Wrapper>
      {errorLoadBrokerData && (
        <ErrorLoadBrokerData>
          <div>
            <RiErrorWarningLine size={120} color="#dc3545" />

            <p>Algumas informações necessárias não puderam ser carregadas</p>

            <button
              type="button"
              onClick={() => window.location.reload(true)}
            >
              Recarregar
            </button>
          </div>
        </ErrorLoadBrokerData>
      )}

      {!errorLoadBrokerData && !!brokerData._id && (
        <>
          <Cover
            name={brokerData.name}
            pictureUrl={brokerData.profile.image}
          />

          <ContentWrapper>
            <Tabs configs={tabs} />
            <Switch>
              <Route
                path={pathsConstants.DASHBOARD_BROKERS_ATTACH_PROPERTY}
                render={props => <Ads
                  {...props}
                  user={userService.retrieve()}
                />}
                exact
              />

              <Route
                path={pathsConstants.DASHBOARD_BROKERS_ATTACH_PROPERTY_LINKED}
                render={() => <AttachedProperty />}
              />
            </Switch>
          </ContentWrapper>
        </>
      )}
    </Wrapper>
  );
}
