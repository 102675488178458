import React, { useEffect } from 'react';

import {
  Wrapper,
  IconWrapper,
  BedIcon,
  Label,
  M2Icon,
  CarIcon,
  ShowerIcon,
  PetIcon,
  Spacer,
} from './styles';

const SOB_CONSULTA = "Sob consulta"

export function Features({
  bedrooms,
  area,
  parkingSpaces,
  bathrooms,
  petAcceptance,
  isRelease = false,
}) {
  useEffect(() => console.log(area), [])
  return (
    <Wrapper>
      <IconWrapper>
        <BedIcon />
        <Label>
          {bedrooms.length === 1 && area[0] === 0 &&
            `${SOB_CONSULTA}`
          }

          {bedrooms.length === 1 && bedrooms[0] !== 0 &&
            `${bedrooms[0]} quarto${bedrooms[0] > 1 ? 's' : ''}`
          }

          {bedrooms.length === 2 && bedrooms[0] !== bedrooms[1] &&
            `${bedrooms[0]} - ${bedrooms[1]} quartos`
          }

          {bedrooms.length === 2 && bedrooms[0] === bedrooms[1] &&
            `${bedrooms[0]} quarto${bedrooms[0] > 1 ? 's' : ''}`
          }
        </Label>
      </IconWrapper>
      <IconWrapper>
        <M2Icon />
        <Label>
          {area.length === 1 && area[0] === 0 &&
            `${SOB_CONSULTA}`
          }

          {area.length === 1 && area[0] !== 0 &&
            `${area[0]} m²`
          }

          {area.length === 2 && area[0] !== area[1] &&
            `${area[0]} - ${area[1]} m²`
          }

          {area.length === 2 && area[0] === area[1] &&
            `${area[0]} m²`
          }
        </Label>
      </IconWrapper>
      <IconWrapper>
        <CarIcon />
        <Label>
          {parkingSpaces.length === 0 && 'Sem vagas'}

          {parkingSpaces.length === 1 && parkingSpaces[0] === 0 &&
            `${SOB_CONSULTA}`
          }

          {parkingSpaces.length === 1 && parkingSpaces[0] !== 0 &&
            `${parkingSpaces} vaga${parkingSpaces[0] > 1 ? 's' : ''}`
          }

          {parkingSpaces.length === 2 && parkingSpaces[0] !== parkingSpaces[1] &&
            `${parkingSpaces[0]} - ${parkingSpaces[1]} vaga${parkingSpaces[1] > 1 ? 's' : ''}`
          }

          {parkingSpaces.length === 2 && parkingSpaces[0] === parkingSpaces[1] &&
            `${parkingSpaces[0]} vaga${parkingSpaces[1] > 1 ? 's' : ''}`
          }
        </Label>
      </IconWrapper>
      <IconWrapper>
        <ShowerIcon />
        <Label>
          {bathrooms.length === 1 && bathrooms[0] === 0 &&
            `${SOB_CONSULTA}` 
          }

          {bathrooms.length === 1 && bathrooms[0] !== 0 &&
            `${bathrooms} banheiro${bathrooms[0] > 1 ? 's' : ''}`
          }

          {bathrooms.length === 2 && bathrooms[0] !== bathrooms[1] &&
            `${bathrooms[0]} - ${bathrooms[1]} banheiros`
          }

          {bathrooms.length === 2 && bathrooms[0] === bathrooms[1] &&
            `${bathrooms[0]} banheiro${bathrooms[0] > 1 ? 's' : ''}`
          }
        </Label>
      </IconWrapper>
      {/* {!isRelease && (
        <IconWrapper>
          <PetIcon />
          <Label>
            {petAcceptance ? 'Permitido' : 'Não'}
          </Label>
        </IconWrapper>
      )} */}
      <Spacer />
    </Wrapper>
  );
};
