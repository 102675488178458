import httpClient from "../../clients/http/http.client";

const BASE_PATH = '/api/v2';

const getPlans = async ({
  token,
  type
}) => {
  const path = `${BASE_PATH}/plans?userType=${type}`

  const response = await httpClient.get({ path, token });

  return response;
};

const getUserSubscription = async ({ token }) => {
  const path = `${BASE_PATH}/me/subscription`;

  const response = await httpClient.get({ path, token });

  return response;
};

const cancelPlan = async ({ subscriptionId, token }) => {
  const path = `${BASE_PATH}/subscriptions/${subscriptionId}`;

  const response = await httpClient.del({ path, token });

  return response;
}

export default {
  getPlans,
  getUserSubscription,
  cancelPlan,
}
