import React, { useState, useCallback, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import Modal from 'react-modal';
import Cropper from 'react-easy-crop';
import { toast } from 'react-toastify';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FiAlertCircle, FiX } from 'react-icons/fi';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ReactTagInput from "@pathofdev/react-tag-input";
import 'react-image-crop/dist/ReactCrop.css';
import "@pathofdev/react-tag-input/build/index.css";
import { useAuth0 } from '@auth0/auth0-react';

import { profileFormSchema } from './schema';

import userService from '../../../../services/user/user.service';
import userProfileService from '../../../../services/user-profile/user-profile.service';

import { InputBootstrap } from '../../../../components/form/input-bootstrap';
import { CellphoneInput } from '../../../../components/form/cellphone-input';
import { PhoneInputBootstrap } from '../../../../components/form/phone-input-bootstrap';

import getCroppedImg from '../../../../utils/image/cropImage.util';
import domEventsUtil from '../../../../utils/dom-events/dom-events.util';
import { addMaskCellphone, addMaskTelephone } from '../../../../utils/add-masks';
import eventsConstants from '../../../../constants/events';
import pathsConstants from '../../../../constants/paths';

import './common-user-profile.css';

import {
  Wrapper,
  Form,
  ProfilePhotoWrapper,
  SectionTitle,
  ProfilePicture,
  DeleteUserDataContent,
  CropImageContentOverlay,
  CropImageCard,
  CropImageCardHeader,
  CropImageCardHeaderTitleWrapper,
  CropImageCardWrapper,
  CropImageCardContent,
  CropImageCardFooter,
  CheckboxInputWrapper,
  WhatsappCheckboxInput,
  RowButton,
  ButtonWrapper,
  ViewButton,
  SaveButton,
  ModalContent,
  ModalBody,
  DeleteUserDataButtonsGroup,
  LoadingModal,
} from '../../profile/styles';

const customId = "custom-id-yes";

export function CommonUserProfilePage({
  history
}) {
  const [profileImageCrop, setProfileImageCrop] = useState({ x: 0, y: 0 })
  const [profileImageZoom, setProfileImageZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [profileImg, setProfileImg] = useState(null);
  const [profileImgName, setProfileImgName] = useState(null);
  const [profileImgInFile, setProfileImgInFile] = useState(null);
  const [profileImgCropped, setProfileImgCropped] = useState(null);

  const [name, setName] = useState('');
  const [hasWhatsapp, setHasWhatsapp] = useState(false);
  const [pictureUrl, setPictureUrl] = useState('');
  const [loaded, setLoaded] = useState(false);

  const [deleteUserDataModalVisible, setDeleteUserDataModalVisible] = useState(false);
  const [deleteUserDataLoading, setDeleteUserDataLoading] = useState(false);

  const { register, handleSubmit, formState, control, setValue } = useForm({
    resolver: yupResolver(profileFormSchema),
  });

  const { logout } = useAuth0();

  const { errors } = formState;

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setProfileImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
      setProfileImgName(e.target.files[0].name)
    }
  };

  const loadInfo = async () => {
    try {
      const user = userService.retrieve();

      const response = await userProfileService.getUserCompleteProfile({
        token: user.token,
        userId: user._id,
      });

      setLoaded(true);
      setPictureUrl(response.profile.image);


        setName(response.name);
        setValue('name', response.profile.screenName);

        if (response.profile.professionalInformation.telephone.number) {
          setValue('telephone', addMaskTelephone(response.profile.professionalInformation.telephone.number));
        }

        if (response.profile.professionalInformation.cellphone.number) {
          setValue('cellphone', addMaskCellphone(response.profile.professionalInformation.cellphone.number));
        }

        setHasWhatsapp(response.profile.professionalInformation.cellphone.whatsApp);

        setValue('email', response.email);

    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    loadInfo();
  }, []);

  const uploadProfileImage = async () => {
    try {
      const user = userService.retrieve();

      await userProfileService.uploadProfileImage({
        picture: profileImgInFile,
        token: user.token
      })
    } catch (error) {
      toast.error('Erro enviando imagem de perfil.', {
        autoClose: 5000,
        toastId: customId,
      });
    }
  }

  const onCompleteProfileImageCrop = useCallback((_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, []);

  const handleCloseCropImage = () => {
    setProfileImg(null);
    setProfileImageZoom(1);
    setProfileImageCrop({ x: 0, y: 0 });
  }

  const showCroppedImage = useCallback(async () => {
    try {
      const { imageInBase64, imageInBlob } = await getCroppedImg(
        profileImg,
        croppedAreaPixels,
        0
      );

      const urlImageInBlob = await imageInBlob;

      fetch(imageInBase64)
        .then(res => res.blob())
        .then(blob => {
          const file = new File([blob], profileImgName, { type: 'image/png' })
          setProfileImgInFile(file)
        });

      setProfileImgCropped(urlImageInBlob)
      handleCloseCropImage();
    } catch (e) {
      handleCloseCropImage();
      toast.error('Erro ao ajustar imagem, tente novamente!', {
        autoClose: 5000,
        toastId: customId,
      })
    }
  }, [croppedAreaPixels]);

  const handleUpdateProfileForm = useCallback(async values => {
    try {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: true },
      });

      if (profileImgInFile !== null) {
        await uploadProfileImage();
      }

      const formData = {
        ...values,
        name: values.name,
        cellphoneNumber: {
          number: values.cellphone.replace(/[^a-zA-Z0-9]+/g, '').substring(2),
          whatsApp: hasWhatsapp,
        },
        telephone: {
          number: values.telephone ? values.telephone.replace(/[^a-zA-Z0-9]+/g, '').substring(2) : '',
        },
      };

      const user = userService.retrieve();

      await userProfileService.update({
        userId: user._id,
        token: user.token,
        data: formData,
      });

      const userProfileData = await userService.getUserProfile({
        token: user.token,
      });

      const userData = {
        ...userProfileData,
        token: user.token,
      }

      localStorage.setItem("user", JSON.stringify(userData));

      toast.info('Perfil atualizado!', {
        autoClose: 5000,
        toastId: customId,
      });

      window.location.reload(true);
    } catch (error) {
      toast.error('Houve um erro ao atualizar o seu perfil, tente novamente!', {
        autoClose: 5000,
        toastId: customId,
      });
      console.log(error)
    } finally {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: false },
      });
    }
  }, [profileImgInFile, hasWhatsapp]);

  const handleDeleteUserData = () => {
    const userStored = userService.retrieve();

    setDeleteUserDataLoading(true);

    userService.deleteUserData({ token: userStored.token })
      .then(response => {
        if (response.status !== 204) {
          throw 'Ocorreu um erro ao excluir seus dados, tente novamente!';
        }

        domEventsUtil.dispatch({
          name: eventsConstants.SIGN_OUT,
        });

        logout({ logoutParams: { returnTo: `${window.location.origin}/?userDeleteAccount=true` }});
      }).catch(err => {
        toast.error(err, { autoClose: 5000 });

        setDeleteUserDataLoading(false);
      });
  }

  return (
    <>
      <Container fluid="md" className={"container_fluid"}>
        <Wrapper>
          {loaded &&
            <Form
              onSubmit={handleSubmit(handleUpdateProfileForm)}
            >
              <ProfilePhotoWrapper>
                <SectionTitle>
                  Foto de perfil
                </SectionTitle>
                <ProfilePicture
                  src={profileImgCropped ? profileImgCropped : `${pictureUrl}?${Math.random()}`}
                  alt={name}
                />
                <div>
                  <input
                    style={{ width: '100%', visibility: 'hidden' }}
                    type="file"
                    id={"input-file-profile"}
                    accept="image/*"
                    onClick={event => event.target.value = ''}
                    onChange={onSelectFile} />
                  <label
                    htmlFor={'input-file-profile'}
                    className={'input-file-profile'}
                    style={{ cursor: 'pointer' }}
                  >
                    Clique aqui para <b>adicionar/trocar sua foto</b>
                  </label>
                </div>
              </ProfilePhotoWrapper>

              {profileImg &&
                <CropImageContentOverlay>
                  <CropImageCard>
                    <CropImageCardHeader>
                      <CropImageCardHeaderTitleWrapper>
                        <h3>Corte sua nova foto de perfil</h3>
                        <span>
                          <FiAlertCircle size="16" />
                          Arraste ou dê zoom para ajustar a imagem
                        </span>
                      </CropImageCardHeaderTitleWrapper>

                      <button type="button" onClick={handleCloseCropImage}>
                        <FiX size="24" />
                      </button>
                    </CropImageCardHeader>

                    <CropImageCardWrapper>
                      <CropImageCardContent>
                        <Cropper
                          image={profileImg}
                          crop={profileImageCrop}
                          zoom={profileImageZoom}
                          aspect={3 / 4}
                          onCropChange={setProfileImageCrop}
                          onCropComplete={onCompleteProfileImageCrop}
                          onZoomChange={setProfileImageZoom}
                        />
                      </CropImageCardContent>
                    </CropImageCardWrapper>

                    <CropImageCardFooter>
                      <button
                        type="button"
                        onClick={showCroppedImage}>
                        Visualizar
                      </button>
                    </CropImageCardFooter>
                  </CropImageCard>

                </CropImageContentOverlay>
              }

              <SectionTitle style={{ marginTop: 40 }}>
                Dados pessoais
              </SectionTitle>

              <div className="mb-3">
                <InputBootstrap
                  name="name"
                  label="Nome"
                  placeholder="Nome"
                  error={errors.name}
                  {...register('name')}
                />
              </div>

              <div className="mb-3">
                    <InputBootstrap
                      name="email"
                      label="E-mail"
                      placeholder="E-mail"
                      disabled
                      error={errors.email}
                      {...register('email')}
                    />
                  </div>

              <div className="mb-3">
                <PhoneInputBootstrap
                  name="telephone"
                  label="Telefone"
                  error={errors.telephone}
                  control={control}
                  {...register('telephone')}
                />
              </div>

              <CellphoneInput
                name="cellphone"
                label="Celular"
                error={errors.cellphone}
                control={control}
                {...register('cellphone')}
              />

              <CheckboxInputWrapper
                label='Este número é Whatsapp'
                htmlFor='checkBoxWhatsapp'
              >
                <WhatsappCheckboxInput
                  id='checkBoxWhatsapp'
                  name='checkBoxWhatsapp'
                  checked={hasWhatsapp}
                  onClick={() => setHasWhatsapp(prevState => !prevState)}
                />
              </CheckboxInputWrapper>

              <RowButton>
                <ButtonWrapper>
                  <ViewButton onClick={history.goBack}>
                    voltar
                  </ViewButton>

                  <SaveButton>
                    salvar
                  </SaveButton>
                </ButtonWrapper>
              </RowButton>
            </Form>
          }

          {loaded && (
            <DeleteUserDataContent>
              <button
                type="button"
                onClick={() => setDeleteUserDataModalVisible(true)}
              >
                Excluir meus dados
              </button>
            </DeleteUserDataContent>
          )}
        </Wrapper>
      </Container>

      <Modal
        isOpen={deleteUserDataModalVisible}
        style={{
          content: {
            backgroundColor: 'transparent',
            border: 0,
            padding: 0,
            inset: 0,
            borderRadius: 0,
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)'
          }
        }}
      >
        <ModalContent>
          <ModalBody>
            <span>Lembre-se:</span>
            <p>Ao prosseguir com a ação, todos os seus dados serão excluídos da plataforma, e com isso você também perderá o acesso a sua conta.</p>

            <label>Deseja prosseguir com a ação?</label>

            <DeleteUserDataButtonsGroup>
              <button
                type="button"
                onClick={() => setDeleteUserDataModalVisible(false)}
              >
                Não
              </button>

              <button
                type="button"
                onClick={handleDeleteUserData}
              >
                Sim, desejo prosseguir
              </button>
            </DeleteUserDataButtonsGroup>

            {deleteUserDataLoading && (
              <LoadingModal>
                <h3>Aguarde um momento enquanto excluímos seus dados</h3>
                <CircularProgress color="inherit" size={32} />
              </LoadingModal>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
