import React, { useContext, useEffect, useState } from "react";
import { AsideFilter, MainContainer, Tabs, Title, Spaces, Features, LoadingData, LoadingFilter, NoData } from './styles'
import { AntSwitch } from "./components/mui-components";
import { IoCloseCircle } from "react-icons/io5";
import { FaFilterCircleXmark } from "react-icons/fa6";
import { useDispatch, useSelector } from 'react-redux';
import { 
  setInitialState, toggleTab, addNeighborhood, removeNeighborhood, addType, removeType, addFeature, removeFeature, setMaxValue, setMinValue, setMaxArea, setMinArea, setIsRelease, addBedroom, removeBedroom, addBathroom, 
  removeBathroom, addSuite, removeSuite, addParkingSpace, removeParkingSpace,
  changeCity
} from '../../reducers/filterReducer/actions';
import { ResultsSection } from "./components/results";
import userService from '../../services/user/user.service';
import listingV2Service from "../../services/listing/listing-v2.service";
import { HistoryContext } from "../../contexts/historyContext";
import queryStringUtil from "../../utils/query-string/query-string.util";
import objectUtil from '../../utils/object/object.util';
import { transactionOptions } from "../../reducers/filterReducer/filterReducer";
import { MapSearch } from "./components/map";
import { useDisplay } from "../../contexts/filterContext";

const BUY_INDEX = 0
const RELEASE_INDEX = 1
const RENT_INDEX = 2

const SPACES_INTERVAL = [1, 2, 3, 4]

const BEDROOM_CASE = "bedroom"
const BATHROOM_CASE = "bathroom"
const SUITE_CASE = "suite"
const PARKING_SPACE_CASE = "parking space"

export function Filter() {
  let url = window.location.href;

  if (url.includes("http://localhost:3000")) {
    url = url.replace("http://localhost:3000", "https://dev.koort.com.br");
  }

  const dispatch = useDispatch();
  const { neighborhood, types, city, features, isRelease, transaction, minValue, maxValue, minArea, maxArea, bedrooms, bathrooms, suites, parkingSpaces, orderBy, sort, rect } = useSelector(state => state.filter);
  
  const { isFilterVisible, setFilterVisible, isMapVisible, setMapVisible } = useDisplay();
  
  const history = useContext(HistoryContext)
  const { 
    city: queryCity, 
    transaction: queryTransaction, 
    neighborhood: queryNeighborhood,
    type: queryType,
    bedrooms: queryBedrooms,
    bathrooms: queryBathrooms,
    suites: querySuites,
    parkingSpaces: queryParkingSpaces,
    features: queryFeatures,
    mainPrice: queryPrice,
    area: queryArea
  } = queryStringUtil.parse(history);
  
  const user = userService.retrieve()
  
  const [priceInterval, setPriceInterval] = useState(["", ""])
  const [areaInterval, setAreaInterval] = useState(["", ""])
  const [isPriceInputValid, setIsPriceInputValid] = useState(true)
  const [initialFilterData, setInitialFilterData] = useState({ 
    transaction: queryTransaction ? queryTransaction : 0,
    availableCities: [],
    city: queryCity,
    features: {
      condo: [],
      property: [],
      near: [],
    } ,
    neighborhoods: [],
    propertyTypes: []
  })
  const [filteredNeighborhoods, setfilteredNeighborhoods] = useState([])
  const [results, setResults] = useState(null)
  const [neighborhoodOptionsVisible, setNeighborhoodOptionsVisible] = useState(false)
  const [cityOptionsVisible, setCityOptionsVisible] = useState(false)
  const [neighborhoodInput, setNeighborhoodInput] = useState("")
  const [isFetchingResults, setIsFetchingResults] = useState(false)
  const [noMoreResults, setNoMoreResults] = useState(false)
  const [loadingFilter, setLoadingFilter] = useState(true)
  const [inputCity, setInputCity] = useState("")
  const [filteredCities, setFilteredCities] = useState([])

  const formatNumber = (num) => {
    return num.toLocaleString('pt-BR');
  };

  const handlePriceChange = (index, value) => {
    // A expressão value.replace(/\D/g, '') está sendo usada com regex temporariamente para evitar instalação de novas bibliotecas.
    // - Encontra todos os caracteres na string value que não são números.
    // - Substitui esses caracteres por uma string vazia '' (ou seja, remove-os).
    const num = value.replace(/\D/g, '');
    const formatted = num ? formatNumber(Number(num)) : '';
    const newInterval = [...priceInterval];
    newInterval[index] = formatted;
    setPriceInterval(newInterval);
  };

  const handlePriceBlur = () => {
    dispatch(setMinValue(priceInterval[0] ? Number(priceInterval[0].replace(/\D/g, '')) : 0));
    dispatch(setMaxValue(priceInterval[1] ? Number(priceInterval[1].replace(/\D/g, '')) : 1000000000));
  };

  const handleMinInput = (e) => {
    handlePriceChange(0, e.target.value);
  };

  const handleMaxInput = (e) => {
    handlePriceChange(1, e.target.value);
  };

  const handleToggleTab = (tab_index) => {
    dispatch(toggleTab(tab_index))
  };

  const handleChangeCity = (city) => {
    dispatch(changeCity(city))
    setInputCity("")
    setCityOptionsVisible(false)
  };

  const handleAddNeighborhood = (newNeighborhood) => {
    if (Array.isArray(newNeighborhood)) {
      newNeighborhood.forEach(neighborhood =>  dispatch(addNeighborhood(neighborhood)))
    } else {
      dispatch(addNeighborhood(newNeighborhood));
    }
    setNeighborhoodOptionsVisible(false)
    setNeighborhoodInput("")
  };

  const handleRemoveNeighborhood = (neighborhood) => {
    dispatch(removeNeighborhood(neighborhood))
  };

  const handleAddType = (newType) => {
    if (Array.isArray(newType)) {
      newType.forEach(type =>  dispatch(addType(type)))
    } else {
      dispatch(addType(newType));
    }
  };

  const handleRemoveType = (type) => {
    dispatch(removeType(type))
  };

  const handleAddFeature = (newFeature) => {
    if (Array.isArray(newFeature)) {
      newFeature.forEach(type => dispatch(addFeature(type)))
    } else {
      dispatch(addFeature(newFeature));
    }
  };

  const handleRemoveFeature = (feature) => {
    dispatch(removeFeature(feature))
  };

  const handleMinAreaInput = (e) => {
    const value = e.target.value ? parseFloat(e.target.value) : "";
    setAreaInterval([value, areaInterval[1]]);
  };

  const handleMaxAreaInput = (e) => {
    const value = e.target.value ? parseFloat(e.target.value) : "";
    setAreaInterval([areaInterval[0], value]);
  };

  const handleAreaInputBlur = () => {
    dispatch(setMinArea(areaInterval[0] ? areaInterval[0] : 0));
    dispatch(setMaxArea(areaInterval[1] ? areaInterval[1] : 1000000));
  };

  const handleToggleIsRelease = () => {
    dispatch(setIsRelease(!isRelease))
  };

  useEffect(() => {
    dispatch(toggleTab(isRelease ? 1 : 0))
  }, [isRelease])

  const handleAddSpace = (space, numberOfSpaces) => {
    if (space === BEDROOM_CASE) {
      handleNumberOfSpacesType(numberOfSpaces, addBedroom)
    }
    if (space === BATHROOM_CASE) {
      handleNumberOfSpacesType(numberOfSpaces, addBathroom)
    }
    if (space === SUITE_CASE) {
      handleNumberOfSpacesType(numberOfSpaces, addSuite)
    }
    if (space === PARKING_SPACE_CASE) {
      handleNumberOfSpacesType(numberOfSpaces, addParkingSpace)
    }
  };

  const handleNumberOfSpacesType = (numberOfSpaces, action) => {
    if (Array.isArray(numberOfSpaces)) {
      numberOfSpaces.forEach(space =>  dispatch(action(space)))
    } else {
      dispatch(action(numberOfSpaces))
    }
  }

  const handleRemoveSpace = (space, numberOfSpaces) => {
    if (space === BEDROOM_CASE) {
      dispatch(removeBedroom(numberOfSpaces))
    }
    if (space === BATHROOM_CASE) {
      dispatch(removeBathroom(numberOfSpaces))
    }
    if (space === SUITE_CASE) {
      dispatch(removeSuite(numberOfSpaces))
    }
    if (space === PARKING_SPACE_CASE) {
      dispatch(removeParkingSpace(numberOfSpaces))
    }
  };

  const handleResetFilter = async () => {
    dispatch(setInitialState({
      city: queryCity,
      minValue: initialFilterData.prices[0],
      maxValue: initialFilterData.prices[1],
      minArea: initialFilterData.areas[0],
      maxArea: initialFilterData.areas[1],
      transaction: transaction.value === 2 ? transactionOptions[2] : transactionOptions[0],
      isRelease: false,
      neighborhood: [],
      types: [],
      bedrooms: []
    }))

    setPriceInterval(["", ""])
    setAreaInterval(["", ""])
  };

  const handleCityInputChange = (value) => {
    setInputCity(value)
  }

  useEffect(() => {
    if (initialFilterData.availableCities) {
      const cities = initialFilterData.availableCities.map(state => {
          return {
            ...state,
            cities: state.cities.filter(city => city.toLowerCase().includes(inputCity.toLowerCase()))
          }
      });
      setFilteredCities(cities)
    }

  }, [inputCity, initialFilterData.availableCities])

  const handleNeighborhoodInputChange = (value) => {
    setfilteredNeighborhoods(initialFilterData?.neighborhoods?.filter((filteredNeighborhood) => {
      return filteredNeighborhood.neighborhood.toLowerCase().includes(value) 
      && !neighborhood.includes(filteredNeighborhood.neighborhood)
    }))
    
    setNeighborhoodInput(value)
  }

  const handleCityInputFocus = () => {
    setCityOptionsVisible(true)
    handleCityInputChange("")
  }

  const handleNeighborhoodInputFocus = () => {
    setNeighborhoodOptionsVisible(true)
    handleNeighborhoodInputChange("")
  }

  useEffect(() => {
    dispatch(changeCity(queryCity))
    if (queryNeighborhood && !neighborhood.includes(queryNeighborhood)) {
      handleAddNeighborhood(queryNeighborhood)
    }
    if (queryTransaction === 0 || queryTransaction) {
      dispatch(toggleTab(parseInt(queryTransaction)))
    }
    if (queryTransaction === 1) {
      dispatch(setIsRelease(true))
    }
    if (queryBedrooms && !bedrooms.includes(queryBedrooms)) {
      handleAddSpace(BEDROOM_CASE, queryBedrooms)
    }
    if (queryBathrooms && !bathrooms.includes(queryBathrooms)) {
      handleAddSpace(BATHROOM_CASE, queryBathrooms)
    }
    if (querySuites && !suites.includes(querySuites)) {
      handleAddSpace(SUITE_CASE, querySuites)
    }
    if (queryParkingSpaces && !parkingSpaces.includes(queryParkingSpaces)) {
      handleAddSpace(PARKING_SPACE_CASE, queryParkingSpaces)
    }
    if (queryType === 0 || queryType && !types.includes(queryType)) {
      handleAddType(queryType)
    }
    if (queryFeatures === 0 || queryFeatures && !features.includes(queryFeatures)) {
      
      handleAddFeature(queryFeatures)
    }
    if (queryPrice === 0 || queryPrice) {
      if (Array.isArray(queryPrice)) {
        dispatch(setMinValue(
          parseFloat(queryPrice[0])
        ));
        dispatch(setMaxValue(
          parseFloat(queryPrice[1])
        ));
      } else {
        dispatch(setMinValue(0));
        dispatch(setMaxValue(
          parseFloat(queryPrice)
        ));
      }
    }
    
    if (queryArea === 0 || queryArea) {
      if (Array.isArray(queryArea)) {
        dispatch(setMinArea(
          parseFloat(queryArea[0])
        ));
        dispatch(setMaxArea(
          parseFloat(queryArea[1])
        ));
      } else {
        dispatch(setMinArea(0));
        dispatch(setMaxArea(
          parseFloat(queryArea)
        ));
      }
    }
  }, []);

  useEffect(() => {
    setLoadingFilter(true)
    if (!!city && !!transaction) {
      getInitialFilterData()
    }
    setPriceInterval(["", ""])
    setAreaInterval(["", ""])
  }, [transaction, city]);

  useEffect(() => {
    if (!loadingFilter) {
      setIsFetchingResults(true)
      getResults();
    }

    queryStringUtil.update(history, {
      city,
      transaction: transaction.value === 2 ? 2 : (isRelease ? 1 : 0),
      neighborhood: neighborhood.length ? objectUtil.stringify(neighborhood) : null,
      mainPrice: objectUtil.stringify([minValue, maxValue]),
      area: objectUtil.stringify([minArea, maxArea]),
      bedrooms: bedrooms.length ? objectUtil.stringify(bedrooms) : null,
      bathrooms: bathrooms.length ? objectUtil.stringify(bathrooms) : null,
      suites: suites.length ? objectUtil.stringify(suites) : null,
      parkingSpaces: parkingSpaces.length ? objectUtil.stringify(parkingSpaces) : null,
      type: neighborhood.length ? objectUtil.stringify(types) : null,
      features: features.length ? objectUtil.stringify(features) : null,
    })

  }, [isMapVisible, isRelease, city, neighborhood, types, features, transaction, minValue, maxValue, minArea, maxArea, bedrooms, bathrooms, suites, parkingSpaces, orderBy, sort, rect, initialFilterData]);

  const getInitialFilterData = async () => {
    await getFeatures()
    await getNeighborhoods(city, transaction.action)
    await getCities()
  };

  const getFeatures = async () => {
    console.log(transaction.action)
    try {
      const response = await listingV2Service.getFeatures(transaction.action, city)
      const data = transaction.value === RENT_INDEX ? response.data.rental : response.data.sale
      setInitialFilterData({...initialFilterData, ...data})

    } catch (error) {
      console.error('Error fetching initial data:', error);
    } finally {
      setLoadingFilter(false)
    }
  }

  const getCities = async () => {
    try {
      const cityResponse = await listingV2Service.getCities({adType: transaction.action})
      const cities = cityResponse.data
  
      setInitialFilterData(state => ({...state, availableCities: cities}))
    } catch (error) {
      console.error('Error fetching cities:', error);
    }
  }

  const getNeighborhoods = async (city, adType) => {
    try {
      const neighborhoodsResponse = await listingV2Service.getDistricts({city, adType})
      const neighborhoods = neighborhoodsResponse.data
  
      if (neighborhoods) {
        setInitialFilterData(state => ({...state, neighborhoods}))
        setfilteredNeighborhoods(neighborhoods)
      } else {
        setInitialFilterData(state => ({...state, neighborhoods: []}))
        setfilteredNeighborhoods([])
      }

    } catch (error) {
      console.error('Error fetching neighborhoods:', error);
    }
  }
  
  const getResults = async (page = 1, sizePagination = 12) => {
    try {
      const response = await listingV2Service.get({
        city,
        token: user ? user.token : null,
        mainPrice: [minValue, maxValue],
        area: [minArea && minArea, maxArea && maxArea],
        transaction: transaction.value === 2 ? 2 : (isRelease ? 1 : 0),
        bedrooms,
        bathrooms,
        suites,
        parkingSpaces,
        neighborhood,
        type: types,
        features,
        sizePagination,
        page,
        orderBy,
        sort,
        rect: isMapVisible ? rect : []
      });

      if (page === 1) {
        setResults(response)
      } 
      else {
        setResults(state => {
          return {
            ...state, 
            seo: response.seo,
            realEstates: [...state.realEstates, ...response.realEstates] 
          }
        })
      }

      setNoMoreResults(response.realEstates.length === 0)
      
      return results
    } catch (error) {
      console.error("Fetch error:", error);
      setNoMoreResults(true)
    } finally {
      setIsFetchingResults(false)
    }
  };

  useEffect(() => {
    // CÓDIGO RESPONSÁVEL POR OCULTAR O SCROLL NA PÁGINA DO MAPA
    const el = document.querySelector("html");
    el.style.overflowY = "hidden";

    // CÓDIGO RESPONSÁVEL POR NÃO EXIBIR O FILTRO AO ABRIR A PÁGINA PARA DISPOSITIVOS MÓVEIS
    if (window.innerWidth < 768) {
      setFilterVisible(false)
    }

    return () => {
      el.style.overflowY = "auto";
    }
  }, [])

  useEffect(() => {
    const minPriceFormatted = Number(priceInterval[0].replace(/\D/g, ''))
    const maxPriceFormatted = Number(priceInterval[1].replace(/\D/g, ''))

    const isValid = !minPriceFormatted || !maxPriceFormatted || (minPriceFormatted <= maxPriceFormatted)

    setIsPriceInputValid(isValid)
  }, [priceInterval])

  useEffect(() => {
    console.log(initialFilterData)
  }, [initialFilterData.neighborhoods])

  return (
    <MainContainer isMapVisible={isMapVisible}>
      <main>
        {
          isFilterVisible &&
          <AsideFilter>
            { loadingFilter ?
            <div className="filter">
              <LoadingFilter><p>Carregando filtro...</p></LoadingFilter>
            </div> :
            <div className="filter">
              <Tabs>
                <button className={(transaction.value === BUY_INDEX || transaction.value === RELEASE_INDEX) && "selectedTab"} onClick={() => handleToggleTab(BUY_INDEX)}>Compra</button>
                <button className={transaction.value === RENT_INDEX && "selectedTab"} onClick={() => handleToggleTab(RENT_INDEX)}>Aluguel</button>
              </Tabs>
              { (transaction.value === BUY_INDEX || transaction.value === RELEASE_INDEX) && 
                <div className="releaseSwitch">
                  <AntSwitch onChange={() => handleToggleIsRelease()} checked={isRelease} />
                  <span>Apenas lançamentos</span>
                </div>
              }
              <div className="locale">
                <Title>Cidade</Title>
                <div className="inputContainer">
                  <input 
                    type="text" 
                    placeholder="Busque pelo nome da cidade"
                    value={inputCity}
                    onChange={(e) => handleCityInputChange(e.target.value)}
                    onFocus={() => handleCityInputFocus()} 
                    onBlur={() => setCityOptionsVisible(false)}
                  />
                  {
                    cityOptionsVisible &&
                    <div className="neighborhoodOptions">
                      
                      {
                        filteredCities.filter(state => state.cities.length > 0).map(filteredCity => {
                            return (
                              <optgroup key={filteredCity.state} label={filteredCity.state}>
                                {filteredCity.cities.map((city) => {
                                  return (
                                    <option className="option"
                                      key={city} 
                                      onMouseDown={() => handleChangeCity(city)}
                                    >
                                      {city}
                                    </option>
                                  )
                                })}
                              </optgroup>
                            )
                        })
                      }
                    </div>
                  }
                </div>
                <div className="buttonArea">
                  <button>
                    <span>{city}</span>
                  </button>
                  </div>
              </div>
              <div className="locale">
                <Title>Adicionar bairros</Title>
                <div className="inputContainer">
                  <input 
                    type="text" 
                    placeholder="Busque pelo nome do bairro"
                    value={neighborhoodInput}
                    onChange={(e) => handleNeighborhoodInputChange(e.target.value)}
                    onFocus={() => handleNeighborhoodInputFocus()} 
                    onBlur={() => setNeighborhoodOptionsVisible(false)}
                  />
                  {
                    neighborhoodOptionsVisible &&
                    <div className="neighborhoodOptions">
                      {
                        filteredNeighborhoods.length 
                        ? 
                        <>
                          {
                            initialFilterData.neighborhoods ?
                            filteredNeighborhoods.map(filteredNeighborhood => {
                              return (
                                
                                <div className="option"
                                  key={filteredNeighborhood.neighborhood} 
                                  onMouseDown={() => handleAddNeighborhood(filteredNeighborhood.neighborhood)}
                                >
                                  {filteredNeighborhood.neighborhood}
                                </div>
                                
                              )
                            }) :
                            <NoData>
                                <p>Carregando bairros...</p>
                            </NoData>
                          }
                        </>
                        :
                        <NoData>
                          <p>Não há bairros a serem exibidos</p>
                        </NoData>
                      }
                      
                    </div>
                  }
                </div>
                  
                <div>
                  <div className="buttonArea">
                    {
                      neighborhood.map(addedNeighborhood => {
                        return (
                          <button key={addedNeighborhood}>
                            <span>{addedNeighborhood}</span>
                            <IoCloseCircle onClick={() => handleRemoveNeighborhood(addedNeighborhood)} />
                          </button>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
              <div className="propertyType">
                <Title>Tipo de imóvel</Title>
                <div className="buttonArea">
                  {initialFilterData?.propertyTypes?.map(type => {
                    return (
                      <button 
                        key={type.index} 
                        className={types.includes(type.index) && "typeChecked"}
                        onClick={
                          types.includes(type.index) ? 
                          () => handleRemoveType(type.index) :
                          () => handleAddType(type.index)
                        }
                      >
                        {type.name}
                      </button>
                    )
                  })}
                </div>
              </div>
              <div className="priceSlider">
                <Title>Preço</Title>
                <div className="minMax">
                  <div>
                    <span>Mínimo (R$)</span>
                    <span>Máximo (R$)</span>
                  </div>
                  <div>
                    <input
                      className={!isPriceInputValid ? "invalidInput" : ""}
                      type="text"
                      value={priceInterval[0]}
                      placeholder="Preço mínimo"
                      onChange={handleMinInput}
                      onBlur={
                        !priceInterval[0] || !priceInterval[1] || Number(priceInterval[0].replace(/\D/g, '')) < maxValue ? 
                        handlePriceBlur : 
                        () => console.error("O preço mínimo não pode ser maior que o preço máximo")}
                    />
                    <input
                      className={!isPriceInputValid ? "invalidInput" : ""}
                      type="text"
                      value={priceInterval[1]}
                      placeholder="Preço máximo"
                      onChange={handleMaxInput}
                      onBlur={
                        !priceInterval[0] || !priceInterval[1] || minValue < Number(priceInterval[1].replace(/\D/g, '')) ? 
                        handlePriceBlur : 
                        () => console.error("O preço mínimo não pode ser maior que o preço máximo")}
                    />
                  </div>
                  {
                    (!isPriceInputValid) && 
                    <span className="errorMessage">O preço mínimo não pode ser maior que o preço máximo</span>
                  }
                </div>
              </div>
              <div className="priceSlider">
                <Title>Área</Title>
                <div className="minMax">
                  <div>
                    <span>Mínimo (m²)</span>
                    <span>Máximo (m²)</span>
                  </div>
                  <div>
                    <input
                      className={(!!areaInterval[0] && !!areaInterval[1]) && (areaInterval[0] > areaInterval[1]) ? "invalidInput" : ""}
                      type="number"
                      value={areaInterval[0]}
                      placeholder="Área mínima"
                      onChange={handleMinAreaInput}
                      onBlur={!areaInterval[0] || areaInterval[0] < maxArea ? handleAreaInputBlur : () => console.error("A área mínima não pode ser maior que a área máxima")}
                    />
                    <input
                      className={(!!areaInterval[0] && !!areaInterval[1]) && (areaInterval[0] > areaInterval[1]) ? "invalidInput" : ""}
                      type="number"
                      value={areaInterval[1]}
                      placeholder="Área máxima"
                      onChange={handleMaxAreaInput}
                      onBlur={!areaInterval[1] || minArea < areaInterval[1] ? handleAreaInputBlur : () => console.error("A área mínima não pode ser maior que a área máxima")}
                    />
                  </div>
                  {
                    ((!!areaInterval[0] && !!areaInterval[1]) && (areaInterval[0] > areaInterval[1])) && 
                    <span className="errorMessage">A área mínima não pode ser maior que a área máxima</span>
                  }
                </div>
              </div>
              <Spaces>
                <div className="rooms bedrooms">
                  <Title>Quartos</Title>
                  <div className="buttonArea">
                    {
                      SPACES_INTERVAL.map(numberOfSpace => {
                        return (
                          <button 
                            key={numberOfSpace} 
                            onClick={
                              bedrooms.includes(numberOfSpace) ? 
                              () => handleRemoveSpace(BEDROOM_CASE, numberOfSpace) : 
                              () => handleAddSpace(BEDROOM_CASE, numberOfSpace)}
                            className={bedrooms.includes(numberOfSpace) ? "selected" : ""}
                          >
                            {numberOfSpace !== 4 ? numberOfSpace : `${4}+`}
                          </button>
                        )
                      })
                    }
                  </div>
                  </div>
                  <div className="rooms bathrooms">
                  <Title>Banheiros</Title>
                  <div className="buttonArea">
                  {
                      SPACES_INTERVAL.map(numberOfSpace => {
                        return (
                          <button 
                            key={numberOfSpace} 
                            onClick={
                              bathrooms.includes(numberOfSpace) ? 
                              () => handleRemoveSpace(BATHROOM_CASE, numberOfSpace) : 
                              () => handleAddSpace(BATHROOM_CASE, numberOfSpace)}
                            className={bathrooms.includes(numberOfSpace) ? "selected" : ""}
                          >
                            {numberOfSpace !== 4 ? numberOfSpace : `${4}+`}
                          </button>
                        )
                      })
                    }
                  </div>
                  </div>
                  <div className="rooms suites">
                  <Title>Suítes</Title>
                  <div className="buttonArea">
                    {
                      SPACES_INTERVAL.map(numberOfSpace => {
                        return (
                          <button 
                            key={numberOfSpace} 
                            onClick={
                              suites.includes(numberOfSpace) ? 
                              () => handleRemoveSpace(SUITE_CASE, numberOfSpace) : 
                              () => handleAddSpace(SUITE_CASE, numberOfSpace)}
                            className={suites.includes(numberOfSpace) ? "selected" : ""}
                          >
                            {numberOfSpace !== 4 ? numberOfSpace : `${4}+`}
                          </button>
                        )
                      })
                    }
                  </div>
                  </div>
                  <div className="rooms parking">
                  <Title>Vagas</Title>
                  <div className="buttonArea">
                    {
                      SPACES_INTERVAL.map(numberOfSpace => {
                        return (
                          <button 
                            key={numberOfSpace} 
                            onClick={
                              parkingSpaces.includes(numberOfSpace) ? 
                              () => handleRemoveSpace(PARKING_SPACE_CASE, numberOfSpace) : 
                              () => handleAddSpace(PARKING_SPACE_CASE, numberOfSpace)}
                            className={parkingSpaces.includes(numberOfSpace) ? "selected" : ""}
                          >
                            {numberOfSpace !== 4 ? numberOfSpace : `${4}+`}
                          </button>
                        )
                      })
                    }
                  </div>
                </div>
              </Spaces>
              <Features>
                {initialFilterData?.features.property.length > 0 &&
                  <details>
                    <summary>Características do imóvel</summary>
                    <div className="buttonArea">
                      {initialFilterData?.features.property.map(feature => {
                        return (
                          <button 
                            key={feature.key} 
                            className={features.includes(feature.index) && "featureChecked"}
                            onClick={
                              features.includes(feature.index) ? 
                              () => handleRemoveFeature(feature.index) :
                              () => handleAddFeature(feature.index)
                            }
                          >
                            {feature.value}
                          </button>
                        )
                      })}
                    </div>
                  </details>
                }
                
                {initialFilterData?.features.condo.length > 0 &&
                  <details>
                    <summary>Características do Condomínio</summary>
                    <div className="buttonArea">
                      {initialFilterData?.features.condo.map(feature => {
                        return (
                          <button 
                            key={feature.key} 
                            className={features.includes(feature.index) && "featureChecked"}
                            onClick={
                              features.includes(feature.index) ? 
                              () => handleRemoveFeature(feature.index) :
                              () => handleAddFeature(feature.index)
                            }
                          >
                            {feature.value}
                          </button>
                        )
                      })}
                    </div>
                  </details>
                }

                {initialFilterData?.features.near.length > 0 &&
                  <details>
                    <summary>Características do Entorno</summary>
                    <div className="buttonArea">
                      {initialFilterData?.features.near.map(feature => {
                        return (
                          <button 
                            key={feature.key} 
                            className={features.includes(feature.index) && "featureChecked"}
                            onClick={
                              features.includes(feature.index) ? 
                              () => handleRemoveFeature(feature.index) :
                              () => handleAddFeature(feature.index)
                            }
                          >
                            {feature.value}
                          </button>
                        )
                      })}
                    </div>
                  </details>
                }
              </Features>
            </div>
            }
            <div className="cleanFilters">
              <button className="hideFilterButton" onClick={() => setFilterVisible(false)}><span>BUSCAR</span></button>
              <button onClick={() => handleResetFilter()}><FaFilterCircleXmark /><span>LIMPAR FILTROS</span></button>
            </div>
          </AsideFilter>
        }
        {
          loadingFilter || isFetchingResults ?
            <LoadingData className="loading" isMapVisible={isMapVisible}>
                {loadingFilter && <p>Aguarde. Carregando informações do filtro...</p>}
                {isFetchingResults && <p>Buscando imóveis...</p>}
            </LoadingData>
          :
            <ResultsSection 
              results={results} 
              getMoreResults={getResults} 
              noMoreResults={noMoreResults} 
            />
        }
        {
          isMapVisible &&
          <MapSearch realEstates={results} />
        }
      </main>
    </MainContainer>
  );
}
