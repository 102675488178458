import httpClient from '../../clients/http/http.client';

/**
 * global
 * FormData
 * Promise
 */

const sendMessage = async ({
  data,
  token
}) => {
  const params = {
    "name": data.name,
    "email": data.email,
    "type": data.type,
    "phone": data.phone.replace(/[^a-zA-Z0-9]+/g, '').substring(2),
    "subject": data.subject,
  };

  const path = '/sales'
  const response = await httpClient.post({ path, params, token });

  return response;
};

const deleteMessage = async ({ messageId, token }) => {
  const path = `/messages/${messageId}`;

  await httpClient.del({ path, token });
}

export default {
  sendMessage,
  deleteMessage,
};
