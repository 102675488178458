import React from "react";
import styled, { css } from "styled-components";
import propTypes from "prop-types";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { Slide } from "react-slideshow-image";
import Tooltip from "react-simple-tooltip";
import { BaseLabel } from "../../../styled/labels/labels";
import { BaseButton } from "../../../styled/buttons/buttons";
import { mqTablet, mqDesktop } from "../../../styled/helpers/helpers";
import {
  COLOR_1,
  COLOR_7,
  COLOR_18,
  COLOR_17,
} from "../../../styled/variables/variables";
import AdSlider from "../../../components/ad-slider";
import { Features } from "../features";
import realEstateService from "../../../services/real-estate/real-estate.service";
import realEstateV2Service from "../../../services/real-estate/real-estate-v2.service";
import pathConstants from "../../../constants/paths";
import currencyUtil from "../../../utils/currency/currency.util";
import domEventsUtil from "../../../utils/dom-events/dom-events.util";
import eventsConstants from "../../../constants/events";

const customId = "custom-id-yes";
const properties = {
  duration: 3000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  indicators: true,
  canSwipe: false,
  indicators: false,
  prevArrow: (
    <button type="button" aria-label="previous" className={"bt-new-slide"}>
      <svg
        viewBox="0 0 24 24"
        height="60"
        width="60"
        aria-hidden="true"
        focusable="false"
        fill="currentColor"
        color="#FFFFFF"
      >
        <path d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z"></path>
      </svg>
    </button>
  ),
  nextArrow: (
    <button type="button" aria-label="next" className={"bt-new-slide-next"}>
      <svg
        viewBox="0 0 24 24"
        height="60"
        width="60"
        aria-hidden="true"
        focusable="false"
        fill="currentColor"
        color="#FFFFFF"
      >
        <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z"></path>
      </svg>
    </button>
  ),
};
const style = {
  textAlign: "center",
  height: "100%",
};
const styleImg = {
  width: "100%",
  height: "100%",
};

const Stats = ({
  adInfo,
  user,
  history,
}) => {
  let value = adInfo.dashboard.prices.main.toString();
  value = currencyUtil.format({
    value: value,
  });
  const handleRemoveEstate = async (user, adInfo) => {
    if (window.confirm("Tem certeza que deseja excluir este imóvel?")) {
      try {
        await realEstateService.deleteEstate({
          token: user.token,
          id: adInfo._id,
        });
        history.push(pathConstants.DASHBOARD_ADS);
      } catch (e) {
        toast.error(
          "Não foi possível excluir este imóvel. Por favor, tente novamente.",
          {
            autoClose: false,
            toastId: customId,
          }
        );
      }
    }
  };
  const removeEstateProduct = async () => {
    try {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: true },
      });
      let result = await realEstateV2Service.removeEstateProduct({
        token: user.token,
        realId: adInfo._id,
      });
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: false },
      });
      if (result && result.status === 204) {
        window.location.reload();
      }
    } catch {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: false },
      });
      toast.error(
        "Não foi possível desanunciar este imóvel. Por favor, tente novamente.",
        {
          autoClose: false,
          toastId: customId,
        }
      );
    }
  };
  const addEstateProduct = () => {
    domEventsUtil.dispatch({
      name: eventsConstants.LOADING_ANIMATION,
      params: { show: true },
    });
    realEstateV2Service
      .addEstateProduct({
        token: user.token,
        realId: adInfo._id,
      })
      .then((result) => {
        domEventsUtil.dispatch({
          name: eventsConstants.LOADING_ANIMATION,
          params: { show: false },
        });
        if (result) {
          if (result.status === 204) {
            window.location.reload();
          } else {
            toast.error(
              result.response.data.message === 'Resource not available to user.' ? 'Você não possui recursos disponíveis para publicar este imóvel. Entre em contato com a nossa equipe.' :
              result.response.data.message.replace("Usuário", "Você"),
              {
                autoClose: false,
                toastId: customId,
              }
            );
          }
        }
      });
  };

  return (
    <Wrapper>
      <AdInfoContainer>
        <CarouselContainer>
          {adInfo && !!adInfo.dashboard.imagesSerialized.length && (
            <div>
              <Slide {...properties} style={{ height: "100%" }}>
                {adInfo.dashboard.imagesSerialized.map((each, index) => (
                  <div key={index} style={style}>
                    <img src={each} style={styleImg} />
                  </div>
                ))}
              </Slide>
            </div>
          )}
          {adInfo && !adInfo.dashboard.imagesSerialized.length && (
            <ImageDefault
              src={`${process.env.PUBLIC_URL}/imgs/website-preview.png`}
            />
          )}
        </CarouselContainer>
        <InfoBold>{adInfo.dashboard.title}</InfoBold>
        <Info>{adInfo.dashboard.subTitle}</Info>
        <InfoBold>{adInfo.dashboard.neighborhood}</InfoBold>
        <Divider />
        <div style={{ marginTop: 25 }}>
          <Features
            bedrooms={adInfo.dashboard.bedrooms}
            area={adInfo.dashboard.area}
            parkingSpaces={adInfo.dashboard.parkingSpaces}
            bathrooms={adInfo.dashboard.bathrooms}
            petAcceptance={adInfo.dashboard.acceptsPet}
          />
        </div>
        <Divider />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: 30,
            marginBottom: 30,
          }}
        >
          <h3> Valor de venda:</h3>
          <p style={{ alignSelf: "flex-end", fontSize: 18 }}>{value}</p>
        </div>
      </AdInfoContainer>
      <AdDashboardContainer>
        <ButtonContainer className={"dashboard-ads-bt"}>
          <Tooltip
            content="Excluir imóvel da plataforma"
            placement="bottom"
            arrow={15}
            background="#000"
            border="#000"
            color="#fff"
            fadeDuration={0}
            fadeEasing="linear"
            fixed={false}
            fontFamily="inherit"
            fontSize="inherit"
            offset={0}
            padding={12}
            radius={0}
            zIndex={1}
            customCss={css`
              white-space: nowrap;
            `}
          >
            <BaseButton
              style={{
                width: 170,
                backgroundColor: COLOR_7,
                marginRight: 16,
              }}
              onClick={() => {
                handleRemoveEstate(user, adInfo);
              }}
            >
              EXCLUIR IMÓVEL
            </BaseButton>
          </Tooltip>
          {adInfo.isPublished && (
            <Tooltip
              content="Remover publicação do anúncio"
              placement="bottom"
              arrow={15}
              background="#000"
              border="#000"
              color="#fff"
              fadeDuration={0}
              fadeEasing="linear"
              fixed={false}
              fontFamily="inherit"
              fontSize="inherit"
              offset={0}
              padding={12}
              radius={0}
              zIndex={1}
              customCss={css`
                white-space: nowrap;
              `}
            >
              <BaseButton
                style={{
                  width: 170,
                  backgroundColor: COLOR_7,
                  marginRight: 16,
                }}
                onClick={removeEstateProduct}
              >
                REMOVER ANÚNCIO
              </BaseButton>
            </Tooltip>
          )}
          {!adInfo.isPublished && (
            <BaseButton
              style={{ width: 170, backgroundColor: "#90B744" }}
              onClick={addEstateProduct}
            >
              ANUNCIAR
            </BaseButton>
          )}
        </ButtonContainer>
        <AdData>
          <AdDataWrapper className={"dashboard-ads-view"}>
            <VerticalDataWrapper>
              <AdStatsNumber>
                {!!adInfo.dashboard.accessStatistics ? adInfo.dashboard.accessStatistics.messages.total : 0}
              </AdStatsNumber>
              <AdDataTitle>MENSAGENS</AdDataTitle>
            </VerticalDataWrapper>
            <VerticalDataWrapper>
              <AdStatsNumber>
                {!!adInfo.dashboard.accessStatistics ? adInfo.dashboard.accessStatistics.views.total : 0}
              </AdStatsNumber>
              <AdDataTitle>VISUALIZAÇÕES</AdDataTitle>
            </VerticalDataWrapper>
            <VerticalDataWrapper>
              <AdStatsNumber>
                {!!adInfo.dashboard.accessStatistics ? adInfo.dashboard.accessStatistics.shares.total : 0}
              </AdStatsNumber>
              <AdDataTitle>COMPARTILHAMENTOS</AdDataTitle>
            </VerticalDataWrapper>
          </AdDataWrapper>
        </AdData>
      </AdDashboardContainer>
    </Wrapper>
  );
};
Stats.propTypes = {
  id: propTypes.string.isRequired,
  fill: propTypes.number,
  views: propTypes.number,
  prints: propTypes.number,
  viewsByMonth: propTypes.number,
  messages: propTypes.number,
  history: propTypes.object,
};
Stats.propTypes = {
  fill: 0,
  views: 0,
  prints: 0,
  viewsByMonth: 0,
  messages: 0,
};
const CarouselContainer = styled.div`
  width: 450px;
  margin: 0;
  height: 250px;
  ${mqTablet`
  width: 450px;
`}
  > div {
    height: 250px;
  }
  .images-wrap {
    height: 250px;
  }
  @media (max-width: 480px) {
    width: 420px;
  }
  @media (max-width: 460px) {
    width: 400px;
  }
  @media (max-width: 430px) {
    width: 380px;
  }
  @media (max-width: 415px) {
    width: 360px;
  }
  @media (max-width: 400px) {
    width: 340px;
  }
  @media (max-width: 370px) {
    width: 320px;
  }
  @media (max-width: 355px) {
    width: 300px;
  }
`;
const ImageDefault = styled.img`
  height: 100%;
`;
const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${mqDesktop`
    flex-direction:row;
    max-width:1000px;
    align-self:center;
  `}
`;
const AdInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  ${mqTablet`
    display:flex;
    flex-direction:column;
    flex:1;
  `}
`;
const AdDashboardContainer = styled.div`
  align-self: center;
  ${mqTablet`
    display:flex;
    flex-direction:column;
    flex:1;
  `}
  ${mqDesktop`
    display:flex;
    flex-direction:column;
    align-self:flex-start;
    flex:1;
`}
`;
const ButtonContainer = styled.div`
  align-self: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  width: 350;

  ${mqTablet`
    margin-right:15px;
  `}
  ${mqDesktop`
    align-self:flex-end;
    margin-right:60px;
  `}
`;
const PAdSlider = styled(AdSlider).attrs({
  playSlideshow: false,
})`
  width: 320px;
  margin: 0;
  align-self: center;
  height: 250px;
  display: flex;
  flex: 1;
  ${mqTablet`
    height: 350px;
    width: 400px;
    align-self:center;
  `}
  ${mqDesktop`
    height: 450px;
    width: 450px;
    align-self:flex-start;
  `}
`;
const SectionTitle = styled(BaseLabel)`
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 10px;
  ${mqTablet`
    font-size: 14px;
  `}
`;
const ProfileData = styled.div`
  margin: 0;
  flex-grow: 1;
  width: 80%;
  margin-left: 10%;
  align-self: center;
  ${mqTablet`
        margin: 0;
        margin-right: 20px;
        flex-grow: 1;
        width:90%;
        align-self:center;
    `}
  ${mqDesktop`
        width:50%;
        font-size: 14px;
        align-self:center;
    `}
`;
const DataWrapper = styled.div`
  padding: 15px;
  background-color: ${COLOR_18};
  border-radius: 1px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  &:last-child {
    margin-bottom: 0;
  }
  align-self: center;
`;
const DataTitle = styled(SectionTitle)`
  font-weight: 300;
  color: ${COLOR_17};
  text-transform: none;
  opacity: 0.8;
`;
const AdData = styled(ProfileData)`
  margin-bottom: 70px;
  margin-top: 70px;
  align-self: center;
  ${mqTablet`
        flex-grow: unset;
        // width: 330px;
        margin-bottom: 70px;
        margin-top:50px;
        // margin-left:45px;
        align-self: center;
    `}
  ${mqDesktop`
        width:50%;
        margin-top:5px;
        align-self:center;
    `}
`;
const AdDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  transform: rotate(-90deg);
  margin-top: -50px;
  justify-content: space-between;
  width: 252px;
  ${mqTablet`
        margin: -50px 0px -50px 0px;
        // margin-top: -50px;
    `}
  ${mqDesktop`
        margin-left: -5px;
        margin-top: -50px;
    `}
`;
const Divider = styled.div`
  height: 1px;
  background-color: gray;
  margin-top: 25px;
`;
const Info = styled.p`
  margin-top: 15px;
  font-size: 13px;
`;
const InfoBold = styled.h2`
  margin-top: 15px;
  font-weight: bold;
`;
const VerticalDataWrapper = styled(DataWrapper)`
  width: 100%;
  display: flex;
  flex-direction: row;
  &:last-child {
    margin-bottom: 0;
  }
  ${mqTablet`
    height: 90px;
  `}
  ${mqDesktop`
    margin-top:10px;
    padding-top:50px;
    padding-bottom:50px;
`}
`;
const AdDataTitle = styled(DataTitle)`
  align-self: center;
  text-transform: uppercase;
  margin: 0;
`;
const AdStatsNumber = styled(BaseLabel)`
  font-weight: bold;
  font-size: 38px;
  color: ${COLOR_1};
  margin-right: 15px;
  align-self: center;
  ${mqTablet`
    font-size: 28px;
  `}
`;
export default withRouter(Stats);
