import React from 'react';

import {
  IntegrationActionButtonsWrapper,
  IntegrationRemoveButton,
  IntegrationManageButton
} from './styles';

export function IntegrationActionButtons({
  hasPortalToBeRemoved,
  onRemoveIntegration,
  onManageIntegrationIntoDashboard
}) {
  return (
    <IntegrationActionButtonsWrapper>
      <IntegrationRemoveButton
        type="button"
        hasPortalToBeRemoved={hasPortalToBeRemoved}
        onClick={onRemoveIntegration}
      >
        {hasPortalToBeRemoved ?
          'Confirmar exclusão'
          :
          'Excluir integração'
        }
      </IntegrationRemoveButton>

      <IntegrationManageButton
        type="button"
        onClick={onManageIntegrationIntoDashboard}
      >
        Gerenciar imóveis
      </IntegrationManageButton>
    </IntegrationActionButtonsWrapper>
  )
}
