import styled from 'styled-components';

import { mqTablet } from '../../../styled/helpers/helpers';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 500px;

  ${mqTablet`
    width: 100%;
    max-width: 1000px;
    align-self: center;
  ` }

  > header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 32px;

    @media (max-width: 680px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  span {
    font-weight: 500;
  }
`;

export const FilterForm = styled.form`
    flex: 1;

  > input {
    background-color: transparent;
    padding: 16px;
    margin-right: 12px;
    width: 100%;
    max-width: 320px;
    height: 54px;

    border: solid 1px #83786f;

    &::placeholder {
      color: #212529;
    }
  }

  > button {
    height: 54px;
    width: 54px;
    border: 0;
    color: #fff;
    background-color: #2B50D8;

    transition: filter .2s;

    &:hover {
      filter: brightness(0.9);
    }
  }

  @media (max-width: 678px) {
      display: flex;
      margin-bottom: 32px;
    }
`;

export const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > button {
    background: transparent;
    color: #212529;

    &:first-child {
      margin-right: 12px;
    }

    &:hover {
      color: #2B50D8;
    }

    &:last-child:hover {
      color: red;
    }
  }
`;

export const ModalContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DeleteBrokerDialog = styled.div`
  background-color: #F3F1EF;
  width: 100%;
  max-width: 520px;
  padding: 24px;
  border-radius: 8px;

  > h3 {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
  }
`;

export const BrokerInfoDialog = styled.div`
  margin-top: 16px;

  > p {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 8px;

    > span {
      font-weight: 400;
    }
  }
`;

export const BrokerActionButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: 32px;
  min-height: 68px;

  > button {
    cursor: pointer;
    font-size: 18px;
    background-color: transparent;

    transition: filter .2s;

    &:first-child {
      margin-bottom: 16px;

      font-weight: 600;
      color: #2B50D8;

      &:hover {
        filter: brightness(0.7);
      }
    }

    &:last-child {
      font-weight: 500;
      color: rgba(0, 0, 0, 0.6);

      &:hover {
        color: rgba(0, 0, 0, 0.7);
      }
    }
  }
`;

export const ClearFilterAndNewBroker = styled.div`
  display: flex;
  align-items: center;

  > button:last-child {
    background-color: transparent;
    padding: 8px;
    color: #2B50D8;
    font-weight: 700;

    transition: filter .2s;

    &:hover {
      filter: brightness(0.9);
    }
  }
`;

export const NewBrokerForm = styled.form`
  background-color: #F3F1EF;
  width: 100%;
  max-width: 520px;
  padding: 24px;
  border-radius: 8px;

  > h3 {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 32px;
  }

  > button[type=submit] {
    background-color: #2B50D8;
    margin-top: 40px;
    width: 100%;
    height: 54px;
    color: #FFF;
    font-weight: 500;

    display: flex;
    align-items: center;
    justify-content: center;

    &:disabled {
      cursor: not-allowed;
      filter: grayscale(0.4);
    }
  }
`;

export const NewBrokerInputWrapper = styled.div`
  > label {
    display: block;
    font-weight: 500;
    margin-bottom: 8px;
  }

  & + div {
    margin-top: 18px;
  }

  > input {
    background-color: transparent;
    padding: 16px;
    margin-right: 12px;
    width: 100%;
    height: 54px;

    border: solid 1px #83786f;

    &::placeholder {
      color: #83786f;
    }
  }
`;

export const ClearFilterButton = styled.button`
    background-color: transparent;
    padding: 8px;
    color: #dc3545;
    font-weight: 700;
    margin-right: 24px;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: filter .2s;

    &:hover {
      filter: brightness(0.9);
    }

    > svg {
      margin-right: 8px;
    }
`;

export const CancelRegisterBrokerButton = styled.button`
    background-color: transparent;
    margin-top: 12px;
    width: 100%;
    height: 54px;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 500;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: color .2s;

    &:hover {
      color: rgba(0, 0, 0, 0.7);
    }
`;
