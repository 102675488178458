import React from 'react';
import styled from 'styled-components';
import Title from './title/Title';
import Arrows from '../cover/arrows/Arrows';

import { mqTablet, bigSectionBaseStyle } from '../../../styled/helpers/helpers';
import { COLOR_17, COLOR_1 } from '../../../styled/variables/variables';


import Arguments from './arguments/Arguments';
import stringUtil from '../../../utils/string/string.util';
import windowUtil from '../../../utils/window/window.util';
import HilightedAgents from '../highlighted-agents/HighlightedAgents'

const handleShowMoreButton = () => windowUtil.scrollToElement({
  element: HilightedAgents.ID
})

const Advantages = () => (
  <Wrapper>
    <ContentWrapper>
      <Title />
      <Arguments />
    </ContentWrapper>
      <SeeMoreButton onClick={() => handleShowMoreButton()}>
        <Arrows color={COLOR_1} />
      </SeeMoreButton>
  </Wrapper>
);

Advantages.ID = `${Advantages.name}_${stringUtil.generateUniqueId()}`;

const Wrapper = styled.div.attrs({
  id: Advantages.ID,
})`
  ${ bigSectionBaseStyle}
  background-color: ${ COLOR_17};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${ mqTablet`
    align-self: center;
    width: 700px;
  ` }
`;

const SeeMoreButton = styled.div`
  display: none;

  ${ mqTablet`
      display: flex;
      justify-content: flex-end;
      align-self: center;
      align-items: flex-end;
  `} 
`;

export default Advantages;
