export const SET_INITIAL_STATE = 'SET_INITIAL_STATE';
export const TOGGLE_TAB = 'TOGGLE_TAB';
export const CHANGE_CITY = 'CHANGE_CITY'
export const ADD_FEATURE = 'ADD_FEATURE';
export const REMOVE_FEATURE = 'REMOVE_FEATURE';
export const ADD_NEIGHBORHOOD = 'ADD_NEIGHBORHOOD';
export const REMOVE_NEIGHBORHOOD = 'REMOVE_NEIGHBORHOOD';
export const ADD_TYPE = 'ADD_TYPE';
export const REMOVE_TYPE = 'REMOVE_TYPE';
export const SET_MAX_VALUE = 'SET_MAX_VALUE';
export const SET_MIN_VALUE = 'SET_MIN_VALUE';
export const SET_MAX_AREA = 'SET_MAX_AREA';
export const SET_MIN_AREA = 'SET_MIN_AREA';
export const SET_IS_RELEASE = 'SET_IS_RELEASE';
export const SET_RECT = 'SET_RECT';

export const ADD_BEDROOM = 'ADD_BEDROOM';
export const REMOVE_BEDROOM = 'REMOVE_BEDROOM';
export const ADD_BATHROOM = 'ADD_BATHROOM';
export const REMOVE_BATHROOM = 'REMOVE_BATHROOM';
export const ADD_SUITE = 'ADD_SUITE';
export const REMOVE_SUITE = 'REMOVE_SUITE';
export const ADD_PARKING_SPACE = 'ADD_PARKING_SPACE';
export const REMOVE_PARKING_SPACE = 'REMOVE_PARKING_SPACE';

export const ORDER_BY = 'ORDER_BY';
export const SORT = 'SORT';

export const setInitialState = (initialState) => ({
  type: SET_INITIAL_STATE,
  payload: initialState,
});

export const toggleTab = (transaction) => ({
  type: TOGGLE_TAB,
  payload: transaction,
});

export const changeCity = (city) => ({
  type: CHANGE_CITY,
  payload: city,
});

export const addNeighborhood = (neighborhood) => ({
  type: ADD_NEIGHBORHOOD,
  payload: neighborhood,
});

export const removeNeighborhood = (neighborhood) => ({
  type: REMOVE_NEIGHBORHOOD,
  payload: neighborhood,
});

export const addType = (type) => ({
  type: ADD_TYPE,
  payload: type,
});

export const removeType = (type) => ({
  type: REMOVE_TYPE,
  payload: type,
});

export const addFeature = (feature) => ({
  type: ADD_FEATURE,
  payload: feature,
});

export const removeFeature = (feature) => ({
  type: REMOVE_FEATURE,
  payload: feature,
});

export const setMaxValue = (value) => ({
  type: SET_MAX_VALUE,
  payload: value,
});

export const setMinValue = (value) => ({
  type: SET_MIN_VALUE,
  payload: value,
});

export const setMaxArea = (area) => ({
  type: SET_MAX_AREA,
  payload: area,
});

export const setMinArea = (area) => ({
  type: SET_MIN_AREA,
  payload: area,
});

export const setIsRelease = (value) => ({
  type: SET_IS_RELEASE,
  payload: value,
});

export const addBedroom = (numberOfBedrooms) => ({
  type: ADD_BEDROOM,
  payload: numberOfBedrooms,
});

export const removeBedroom = (numberOfBedrooms) => ({
  type: REMOVE_BEDROOM,
  payload: numberOfBedrooms,
});

export const addBathroom = (numberOfBathrooms) => ({
  type: ADD_BATHROOM,
  payload: numberOfBathrooms,
});

export const removeBathroom = (numberOfBathrooms) => ({
  type: REMOVE_BATHROOM,
  payload: numberOfBathrooms,
});

export const addSuite = (numberOfSuites) => ({
  type: ADD_SUITE,
  payload: numberOfSuites,
});

export const removeSuite = (numberOfSuites) => ({
  type: REMOVE_SUITE,
  payload: numberOfSuites,
});

export const addParkingSpace = (numberOfParkingSpaces) => ({
  type: ADD_PARKING_SPACE,
  payload: numberOfParkingSpaces,
});

export const removeParkingSpace = (numberOfParkingSpaces) => ({
  type: REMOVE_PARKING_SPACE,
  payload: numberOfParkingSpaces,
});

export const setOrderBy = (orderByString) => ({
  type: ORDER_BY,
  payload: orderByString,
});

export const setSort = (sortString) => ({
  type: SORT,
  payload: sortString,
});

export const setRect = (mapBounds) => ({
  type: SET_RECT,
  payload: mapBounds,
});