import styled from 'styled-components';

import Checkbox from '../../../../components/checkbox';
import InputWrapper from '../../../../components/input-wrapper';

import { COLOR_1, COLOR_5, COLOR_2, COLOR_13, COLOR_BORDER, COLOR_1_TRANSLUCID, COLOR_BORDER_TRANSLUCID } from '../../../../styled/variables/variables';
import { mqDesktop, mqTablet } from '../../../../styled/helpers/helpers';
import { BaseLabel } from '../../../../styled/labels/labels';
import { BaseButton } from '../../../../styled/buttons/buttons';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Title = styled(BaseLabel)`
  font-weight: 700;
  font-size: 22px;
  text-align: center;
  margin-bottom: 50px;
  width: 100%;

  ${mqTablet`
    margin-bottom: 100px;
    font-size: 30px;
  ` }
`;

export const Form = styled.form`
 ${mqTablet`
    width: 100%;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
  ` }
`;

export const SectionTitle = styled(BaseLabel)`
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 10px;
`;

export const PropertiesSelectsContainer = styled.div`
  display: flex;
  flex-direction: column;

  > div {
      & + div {
        margin-top: 16px;
      }
  }
`;

export const AddressContainer = styled.div`
  margin: 50px 0;

  ${mqTablet`
    width: 100%;
  `}
  ${mqDesktop`
     width: 100%;
  `}
`;

export const ReleaseFeatures = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  flex-wrap: wrap;
  gap: 2rem;
`;

export const FeatureContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const TabletInliner = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  width: 100%;
  gap: .5rem;

  & > * {
    flex-grow: 1;
  }

  ${mqTablet`
    flex-direction: row;
  ` }

  @media (max-width: 768px) {
    margin-bottom: 1rem;
    width: 100%;

    > div {
      & + div {
        margin-top: 16px;
      }
    }
  }
`;

export const AddressNeighborhoodInputContainer = styled.div``;

export const AddressStreetInputContainer = styled.div``;

export const AddressNumberInputContainer = styled.div`

  @media (max-width: 768px) {
    margin-right: unset;
  }
`;

export const AddressComplementInputContainer = styled.div`
  min-width: 65%;
  flex-grow: 1;
`;

export const CheckboxInputWrapper = styled(InputWrapper)`
  margin-top: 12px;
  flex-direction: row-reverse;
  justify-content: flex-end;
`;

export const ShowOnMapInput = styled(Checkbox)`
  margin-right: 10px;
`;

export const AddressCityInputContainer = styled.div`
  flex-grow: 1;
  margin-bottom: 8px;

  @media (max-width: 768px) {
    margin-right: unset;
    margin-bottom: unset;
  }
`;

export const AddressStateInputContainer = styled.div`
  min-width: 49%;
`;

export const AddressCompleteInTheAd = styled.div`
  color: #ff000094;
  margin-top: 0px;
  font-size: 14px;
  font-weight: 500;
`;

export const EnterpriseStatusTitle = styled.label`
  margin: 16px 0;
`;

export const SelectContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  margin: 3rem 0;

  @media screen and (max-width: 500px) {
    flex-direction: column;
    justify-content: unset;
  }
`;

export const SelectTextItem = styled.span`
  color: ${({ active }) => active ? COLOR_1 : COLOR_BORDER};
  margin-top: 12px;
  transition: color 0.2s;

  @media screen and (max-width: 500px) {
      margin-left: 16px;
      margin-top: 0;
    }
`;

export const SelectStep = styled.button`
  flex:1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  background-color: transparent;

  @media screen and (max-width: 500px) {
    flex-direction: row;
    background-color: #fff;

    & + button {
      margin-top: 48px;
    }
  }
`;

export const StepBulletContainer = styled.div`
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background: ${({ active }) => active ? COLOR_1_TRANSLUCID : COLOR_BORDER_TRANSLUCID};
  display: flex;
  justify-content: center;
  align-items: center;

  transition: background-color 0.2s;
`;

export const StepBulletGap = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;

  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StepBullet = styled.div`
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: ${({ active }) => active ? COLOR_1 : '#777'};

  transition: background-color 0.2s;
`;

export const StepLine = styled.div`
  position: absolute;
  height: 2px;
  top: 16px;
  right: 15%;
  left: 15%;
  background: ${COLOR_5};
  opacity: 0.65;
  z-index: 0;
  margin: 0 auto;

  @media screen and (max-width: 500px) {
    top: 0;
    bottom: 0;
    left: 1rem;
    right: unset;
    height: unset;
    width: 2px;
  }
`;

export const PropertyFeaturesBathroomsInputContainer = styled.div`
  flex-grow: 1;
`;

export const PropertyFeaturesBedroomsInputContainer = styled.div`
  flex-grow: 1;
`;

export const PropertyFeaturesContainer = styled.div`
  margin: 50px 0 40px;
`;

export const LabelInputPropertyFeature = styled.label`
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  opacity: 0.65;
  margin-bottom: 12px;
  display: block;
`;

export const Counter = styled.span`
  font-size: 12px;
  margin-top: 8px;
  margin-bottom: 40px;
  text-align: right;

  @media(max-width: 768px) {
    display: block;
  }
`;

export const ValuesContainer = styled.div`
  margin-bottom: 50px;

  > div {
    & + div {
      margin-top: 16px;
    }
  }
`;

export const AdvertiserDataContainer = styled.div`
  > div {
    & + div {
      margin-top: 16px;
    }
  }
`;

export const HasWhatsappInput = styled(Checkbox).attrs({
  name: 'whatsApp',
})`
  margin-right: 10px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 80px;
  gap: 10px;

  ${mqTablet`
    align-self: center;
    width: 400px;
  ` }

  @media (max-width: 380px) {
    flex-direction: column;
  }
`;

export const SaveButton = styled(BaseButton).attrs({
  type: 'submit',
})`
  color: ${COLOR_2};
  background-color: ${COLOR_1};
  flex-grow: 1;
  border: none;
  margin-right: unset;
`;

export const SaveAndPublishButton = styled(SaveButton)`
  background-color: ${COLOR_13};
`

export const LoadDataErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > h2 {
    font-size: 24px;
    font-weight: 700;
  }

  > p {
    margin-top: 16px;
    text-align: center;
    font-size: 18px;
    max-width: 460px;
  }
`;

export const ReturnDashboardButton = styled(BaseButton).attrs({
  type: 'button',
})`
  color: ${COLOR_2};
  background-color: ${COLOR_1};
  flex-grow: 1;
  border: none;
  margin-top: 24px;
`;
