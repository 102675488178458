
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';

import AdSlider from '../../../components/ad-slider';
import { shadower } from '../../../styled/helpers/helpers';

/*
  global
  window
  process
*/

class MainSlider extends PureComponent {

  constructor(props) {
    super(props);

    const { innerWidth } = window;
    this.state = {
      concurrentImageSlides: innerWidth > 768 ? 3 : 1,
    };
  }

  componentDidMount() {
    window.addEventListener(
      'resize',
      this.handleWindowResizeEvent
    );
  }

  componentWillUnmount() {
    window.removeEventListener(
      'resize',
      this.handleWindowResizeEvent
    );
  }

  handleWindowResizeEvent = () => {
    const { innerWidth } = window;
    this.setState(state => ({
      ...state,
      concurrentImageSlides: innerWidth > 768 ? 3 : 1,
    }));
  };

  render() {
    const { images } = this.props;
    const { concurrentImageSlides } = this.state;

    return (
      <Wrapper
        images={ images }
        concurrentSlides={ concurrentImageSlides }
      />
    );
  }
}

MainSlider.propTypes = {
  images: propTypes.arrayOf(propTypes.string),
};

MainSlider.defaultProps = { };

const Wrapper = styled(AdSlider).attrs({
  height: '250px',
  tabletHeight: '250px',
  desktopHeight: '300px',
  playSlideshow: false,
})`
  position: relative;
  width: 100%;
  ${ shadower({ direction: 'bottom' }) }
`;

export default MainSlider;
