import * as yup from 'yup';

const profileFormSchema = yup.object().shape({
  name: yup
    .string()
    .required('Este campo é obrigatório')
    .min(3, 'Mínimo de 3 caracteres')
    .max(80, 'Mínimo de 80 caracteres'),
  telephone: yup.string()
    .min(18, 'Informe os 10 dígitos')
    .optional(),
  cellphone: yup.string()
    .required('Este campo é obrigatório')
    .min(19, 'Informe os 11 dígitos'),
  aboutMe: yup.string()
    .required('Este campo é obrigatório')
    .min(10, 'Mínimo de 10 caracteres')
    .max(1000, 'Máximo de 1000 caracteres'),
  creci: yup.string(),
  role: yup.string(),
  email: yup.string(),
  facebook: yup.string()
    .url('Informe uma URL válida'),
  instagram: yup.string()
    .url('Informe uma URL válida'),
  linkedIn: yup.string()
    .url('Informe uma URL válida'),
  zipCode: yup.string(),
  city: yup.string(),
  number: yup.string(),
  complement: yup.string(),
  street: yup.string(),
});

const profileForUserOwnerFormSchema = yup.object().shape({
  name: yup
    .string()
    .required('Este campo é obrigatório')
    .min(3, 'Mínimo de 3 caracteres')
    .max(80, 'Mínimo de 80 caracteres'),
  telephone: yup.string()
    .min(18, 'Informe os 10 dígitos')
    .optional(),
  cellphone: yup.string()
    .required('Este campo é obrigatório')
    .min(19, 'Informe os 11 dígitos'),
});

export { profileFormSchema, profileForUserOwnerFormSchema };
