import React from 'react';
import styled from 'styled-components';

import Cover from './cover/Cover';
import Content from './content/Content';

const Koortimativa = () => (
  <Wrapper>
    <Cover />
    <Content />
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export default Koortimativa;
