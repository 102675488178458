import React from 'react';
import styled, { css } from 'styled-components';
import { transitions } from 'polished';
import propTypes from 'prop-types';
import { Close } from 'styled-icons/material/Close';

import { COLOR_2, COLOR_17 } from '../../../styled/variables/variables';
import { noScrollBar, dimmer, mqTablet, iconBaseStyle, pointer } from '../../../styled/helpers/helpers';

import NavItemList from './nav-item-list/NavItemList';

const Menu = ({
  show,
  user,
  onItemClick,
  onOutsideClick,
}) => (
  <Wrapper show={ show } onClick={ onOutsideClick }>
    <MenuWrapper show={ show }>
      <CloseButton>
        <CloseIcon />
      </CloseButton>
      <NavItemList
        isAuthenticated={ !!user }
        onItemClick={ onItemClick }
        user={user}
      />
    </MenuWrapper>
  </Wrapper>
);

Menu.propTypes = {
  show: propTypes.bool.isRequired,
};

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  ${ dimmer() };
  overflow: hidden;
  display: flex;
  justify-content: flex-end;

  opacity: 0;
  width: 0%;
  ${ transitions([ 'opacity ease .6s', 'width 0s .6s' ]) };
  ${ ({ show }) => show && css`
    width: 100vw;
    opacity: 1;
    ${ transitions([ 'opacity ease .6s', 'width 0s .0s' ]) };
  `};
`;

const MenuWrapper = styled.div`
  background-color: ${ COLOR_17 };
  ${ noScrollBar };
  margin-right: -85vw;
  top: 0;
  overflow: hidden;
  overflow-y: scroll;
  height: 100vh;
  opacity: 0;
  width: 85vw;
  display: flex;
  flex-direction: column;
  ${ transitions([ 'margin-right', 'opacity' ], 'ease .6s') };

  ${ ({ show }) => show && css`
    opacity: 1;
    margin-right: 0;
  ` };

  ${ mqTablet`
    margin-right: -450px;
    width: 450px;

    ${ ({ show }) => show && css`
      margin-right: 0;
    ` };
  ` }
`;

const CloseButton = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CloseIcon = styled(Close)`
  ${ iconBaseStyle }
  color: ${ COLOR_2 };
  padding: 20px;
  height: 60px;
  ${ pointer }

  ${ mqTablet`
    margin-right: 20px;
  ` }
`;

export default Menu;
