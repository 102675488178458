import React, { useContext, useEffect, useState } from "react";

import { 
  setInitialState, changeCity
} from '../../../../reducers/filterReducer/actions';

import { PiListFill, PiMapTrifold } from "react-icons/pi";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from './styles'
import queryStringUtil from "../../../../utils/query-string/query-string.util";
import { HistoryContext } from "../../../../contexts/historyContext";
import { transactionOptions } from "../../../../reducers/filterReducer/filterReducer";

export function MapToggle({ currentFilterStyle }) {
  const history = useContext(HistoryContext)
  const queryParams = queryStringUtil.parse(history);

  const dispatch = useDispatch();
  const { city, neighborhood, types, features, isRelease, transaction, minValue, maxValue, minArea, maxArea, bedrooms, bathrooms, suites, parkingSpaces, orderBy, sort } = useSelector(state => state.filter);

  const [firstLoad, setFirstLoad] = useState(true)

  const setUpFilterContext = () => {
    console.log(queryParams)
    dispatch(setInitialState({
      transaction: queryParams.transaction ? queryParams.transaction : transactionOptions.filter(transaction => transaction.value === 1),
      minValue: queryParams.mainPrice && queryParams.mainPrice.length > 0 && queryParams.mainPrice[0],
      maxValue: queryParams.mainPrice && queryParams.mainPrice.length > 1 && queryParams.mainPrice[1],
      minArea: queryParams.area && queryParams.area.length > 0 && queryParams.area[0],
      maxArea: queryParams.area && queryParams.area.length > 1 && queryParams.area[1],
      parkingSpaces: queryParams.parkingSpaces,
      bathrooms: queryParams.bathrooms,
      suites: queryParams.suites,
      bedrooms: [],
      types: [],
      neighborhood: [],
      features: [],
    }))

    queryParams.city && dispatch(changeCity(queryParams.city))
  }

  const updateURLParams = () => {
    queryStringUtil.update(history, {
      city,
      transaction: transaction.value === 2 ? 2 : (isRelease ? 1 : 0),
      mainPrice: [minValue, maxValue],
      area: [minArea, maxArea],
      bedrooms,
      bathrooms,
      suites,
      parkingSpaces,
      type: types,
    })

    neighborhood && queryStringUtil.update(history, {neighborhood})
  }

  useEffect(() => {
    if (!firstLoad) {
      updateURLParams()
    }
    setFirstLoad(false)
  }, [city, neighborhood, types, isRelease, transaction, minValue, maxValue, minArea, maxArea, bedrooms, bathrooms, suites, parkingSpaces])

  return (
    <Container>
      {
        currentFilterStyle === "map" 
        ?
          <Link 
            onClick={() => setUpFilterContext()}
            to={`/busca?city=${queryParams.city}&transaction=${queryParams.transaction}`}
            className="view"
          >
            <PiListFill size={20} weight="fill" />
            <span className="mapView">Ver Lista</span>
          </Link>
        :
          <Link 
            to={`/mapa?city=${queryParams.city}&transaction=${transaction.value}`}
            className="view"
          >
            <PiMapTrifold size={20} />
            <span>Ver mapa</span>
          </Link>
      }
      
    </Container>
  )
}