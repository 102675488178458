import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { InputBootstrap } from '../../components/form/input-bootstrap';
import { SelectBootstrap } from '../../components/form/select-bootstrap';
import { TextArea } from '../../components/form/textarea';

import contactService from '../../services/contact/contact.service';

import pathsConstants from '../../constants/paths';
import eventsConstants from '../../constants/events';
import domEventsUtil from '../../utils/dom-events/dom-events.util';

import {
  Wrapper,
  Title,
  Subtitle,
  CSocialIcons,
  Form,
  Counter,
  SendButton,
} from './styles';

const contactFormSchema = yup.object().shape({
  email: yup.string()
    .required('Este campo é obrigatório')
    .email('Informe um e-mail válido'),
  subject: yup.string()
    .test("", "Selecione um assunto", (value) => {
      if (value !== '0') {
        return true;
      } else {
        return false;
      }
    }),
  message: yup.string()
    .required('Este campo é obrigatório')
    .min(10, 'Mínimo de 10 caracteres')
    .max(2000, 'Máximo de 2000 caracteres'),
});

const subjects = [
  { value: '0', label: 'Escolha um assunto', },
  { value: '1', label: 'Informações sobre anúncios', },
  { value: '2', label: 'Informações sobre planos', },
  { value: '3', label: 'Informações sobre valores e faturamento', },
  { value: '4', label: 'Sugestões e reclamações', },
  { value: '5', label: 'Outros', },
];

export const Contact = (props) => {
  const [count, setCount] = useState(0);

  const { register, handleSubmit, formState, watch } = useForm({
    resolver: yupResolver(contactFormSchema),
  });

  const { errors } = formState;

  useEffect(() => {
    const subscription = watch((data) => setCount(data.message.length));

    return () => subscription.unsubscribe();
  }, [watch])

  const handleFormSubmit = useCallback(async values => {
    const { history } = props

    try {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: true },
      });

      const formData = {
        email: values.email,
        subjectCode: values.subject,
        message: values.message,
      };

      const data = await contactService.sendMessage({
        data: formData,
      });

      if (data.status !== 204) {
        throw new Error(data);
      }

      toast.info('Formulário enviado com sucesso!', {
        autoClose: false,
      });

      history.push(pathsConstants.HOME);
    } catch {
      toast.error('Houve um erro ao entrar em contato, tente novamente!', {
        autoClose: false,
      });
    } finally {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: false },
      });
    }
  }, []);

  return (
    <Wrapper>
      <Title>Fale conosco</Title>
      <Subtitle>
        Envie-nos suas dúvidas, sugestões e reclamações. <br /> Nossa equipe está pronta para ajudar.
      </Subtitle>
      <CSocialIcons />

      <Form onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="mb-3">
          <InputBootstrap
            name="email"
            label="E-mail"
            placeholder="E-mail"
            error={errors.email}
            {...register('email')}
          />
        </div>

        <SelectBootstrap
          name="subject"
          label="Assunto"
          options={subjects}
          error={errors.subject}
          {...register('subject')}
        />

        <TextArea
          name="message"
          label="Mensagem"
          height={100}
          error={errors.message}
          maxLength={2000}
          {...register('message')}
        />
        <br />

        <Counter>{count}/2000</Counter>
        <br />
        <SendButton>enviar</SendButton>
      </Form>
    </Wrapper>
  )
}
