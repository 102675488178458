import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';
import { transitions } from 'polished';

import { BaseInput, ErrorMessage } from '../../../../styled/inputs/inputs';
import { mqTablet } from '../../../../styled/helpers/helpers';

import VideoPlayer from '../../../../components/video-player/VideoPlayer';

import validationUtil from '../../../../utils/validation/validation.util';

class Video extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        videoUrl: props.video,
      },
      fieldsHasFilled: false,
      showErrorsFor: {},
      loadError: false,
    };
  }

  handleFormChange = event => {
    const { name, value } = event.target;

    this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        [name]: value,
      },
    }));
  };

  handleInputBlur = event => {
    const { name } = event.target;

    this.setState(state => ({
      ...state,
      showErrorsFor: {
        ...state.showErrorsFor,
        [name]: true,
      },
    }));
  };

  handleVideoLoad = ({ error = false }) => {
    this.setState(state => ({
      ...state,
      loadError: error,
      showErrorsFor: {
        ...state.showErrorsFor,
        'videoUrl': error,
      },
    }));
  };

  render() {
    const { isEditing, populate } = this.props;
    const { form, showErrorsFor, loadError, fieldsHasFilled } = this.state;

    if (isEditing) {
      if (!!populate && !fieldsHasFilled) {
        this.setState(prevState => ({
          ...prevState,
          fieldsHasFilled: true,
          form: {
            ...prevState.form,
            videoUrl: populate,
          }
        }))
      }
    }

    const isVideoUrlValid = (
      validationUtil.youtubeUrl({
        value: form.videoUrl,
      }) && !loadError
    ) || form.videoUrl.length === 0;

    return (
      <Wrapper onChange={this.handleFormChange}>
        <Input
          value={form.videoUrl}
          onBlur={this.handleInputBlur}
          isInvalid={
            showErrorsFor.videoUrl && !isVideoUrlValid
          }
        />
        {showErrorsFor.videoUrl && !isVideoUrlValid && (
          <ErrorMessage>
            URL inválida ou vídeo não encontrado. OBS: URLs devem ser de vídeos do Youtube.
          </ErrorMessage>
        )}
        <VVideoPlayer
          url={form.videoUrl}
          show={isVideoUrlValid && form.videoUrl.length > 0}
          onError={() => this.handleVideoLoad({ error: true })}
          onReady={() => this.handleVideoLoad({ error: false })}
        />
      </Wrapper>
    );
  }
}

const Wrapper = styled.form`
  margin-bottom: 40px;
`;

const Input = styled(BaseInput).attrs({
  name: 'videoUrl',
  placeholder: 'Cole aqui a URL do Youtube'
})``;

const VVideoPlayer = styled(VideoPlayer)`
  height: 0px;
  opacity: 0;

  ${transitions([
  'height ease .6s 1.5s',
  'opacity ease 1.25s 0s'
])}
  ${({ show }) => show && css`
    ${transitions([
  'height ease .6s 0s',
  'opacity ease 1s .85s'
])}
    height: 300px;
    opacity: 1;
  ` }

  ${mqTablet`
    ${({ show }) => show && css`
      height: 280px;
    ` }
  ` }
`;

export default Video;
