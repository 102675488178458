import React, { PureComponent } from 'react';
import styled from 'styled-components';

import { COLOR_17, COLOR_2 } from '../../../styled/variables/variables';
import { BaseLabel } from '../../../styled/labels/labels';
import { hexToRgb, mqTablet } from '../../../styled/helpers/helpers';
import { BaseButton } from '../../../styled/buttons/buttons';
import { BaseInput, BaseTextArea, MaskedBaseInput, masks } from '../../../styled/inputs/inputs';

import Info from './info/Info';

class ContactForm extends PureComponent {
  render() {
    return (
      <Wrapper>
        <ContentWrapper>
          <Info />
          <Form>
            <CallToAction>
              Fale com o corretor
            </CallToAction>
            <NameInput />
            <EmailInput />
            <PhoneNumberInput />
            <MessageTextArea />
            <SendButton>
              enviar
            </SendButton>
          </Form>
        </ContentWrapper>
      </Wrapper>
    );
  }
}

ContactForm.propTypes = {

};

ContactForm.defaultProps = {

};

const Wrapper = styled.div`
  padding: 10px;
  margin-bottom: 70px;

  ${ mqTablet`
    width: 100%;
    max-width: 1000px;
    align-self: center;
  ` }
`;

const ContentWrapper = styled.div`
  background-color: ${ COLOR_17 };
  padding: 30px 20px;
  border-radius: 1px;
  display: flex;
  flex-direction: column;

  ${ mqTablet`
    flex-direction: row;
    padding: 50px;
  ` }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;

  ${ mqTablet`
    flex-grow: 1;
  ` }
`;

const CallToAction = styled(BaseLabel)`
  color: ${ hexToRgb(COLOR_2, 0.8) };
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 10px;
`;

const NameInput = styled(BaseInput).attrs({
  placeholder: 'Nome',
})`
  height: 35px;
  line-height: 35px;
`;

const EmailInput = styled(NameInput).attrs({
  type: 'email',
  placeholder: 'Email'
})``;

const PhoneNumberInput = styled(NameInput).attrs({
  as: MaskedBaseInput,
  mask: masks.cellphone,
  placeholder: 'Telefone',
})``;

const MessageTextArea = styled(BaseTextArea).attrs({
  placeholder: 'Digite sua mensagem'
})``;

const SendButton = styled(BaseButton)`
  width: 150px;
  align-self: flex-end;
  height: 35px;
  line-height: 35px;
`;

export default ContactForm;
