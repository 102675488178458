import styled from 'styled-components';
import { transitions } from 'polished';

import { COLOR_17 } from '../../../styled/variables/variables';
import { backgrounder, mqDesktop, mqTablet } from '../../../styled/helpers/helpers';
import { KoortWhiteVerticalLogo } from '../../../styled/components/components';

export const Wrapper = styled.div`
  background-color: ${COLOR_17};
  background-position-y: -50px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  ${mqDesktop`
    flex-direction: row;
  `}
`;

export const ContentWrapper = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  padding: 10px;
  padding-bottom: 100px;
  width: 100%;

  ${mqDesktop`
    align-self: center;
    margin-left: -300px;
    width: 100%;
  `}
`;

export const Texture = styled.div`
  ${backgrounder(`${process.env.PUBLIC_URL}/imgs/koort-texture-white-outline.svg`)};
  background-position: bottom;
  height: 140px;
  width: 100%;
  background-repeat: repeat-x;
  background-size: 620px;
  margin-bottom: 30px;

  ${mqDesktop`
    background-position: right;
    width: 300px;
    height: 100vh;
    background-size: 1000px;
    margin: 0;
  `}
`;

export const Logo = styled(KoortWhiteVerticalLogo)`
  margin-bottom: 50px;
  align-self: center;

  ${mqTablet`
    transform: scale(1.3);
    ${transitions(["max-width"], "ease .6s .6s")}
  `}

  ${mqDesktop`
    width: 290px;
    transform: scale(1);
  `}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CheckingAccountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  color: #FFFFFF;

  p {
    font-size: 18px;
    text-align: center;
    margin: 0;
    margin-top: 8px;
  }

  .MuiCircularProgress-root{
    margin-top: 32px;
  }

  @media (max-width: 660px) {
    padding: 0 24px;
  }
`;

export const ChooseAccount = styled.div`
  align-self: center;
  background-color: #FFFFFF;
  max-width: 400px;
  max-height: 500px;
  border-radius: 4px;
  padding: 14px 0;
  margin-top: -20px;

  > strong {
    display: block;
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    padding: 0px 16px 14px 16px;
    text-align: center;
    font-size: 20px;
  }

  @media (max-width: 1080px) {
    width: 400px;
  }

  @media (max-width: 470px) {
    width: 340px;
    max-height: 600px;
  }

  @media (max-width: 400px) {
    width: 320px;
    max-height: 600px;
  }

  @media (max-width: 320px) {
    width: 290px;
    max-height: 600px;
  }
`;

export const AccountCardsContainer = styled.div`
 overflow-y: scroll;
 max-height: 430px;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const ChooseAccountList = styled.div`
  padding: 20px 16px 0 16px;
`;

export const OwnAccountContainer = styled.div`
  > h3 {
    font-size: 18px;
    font-weight: 600;
  }
`;

export const AccountCard = styled.button`
display: flex;
align-items: center;
margin-top: 16px;
width: 100%;
padding: 8px;
background: transparent;
text-align: start;
border-radius: 4px;

transition: background-color .2s;

&:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

> img {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  margin-right: 12px;
}

> div {
  h3 {
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
  }

  span {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.6);
  }
}
`;

export const LinkedAccountContainer = styled.div`
  margin-top: 32px;

  > h3 {
    font-size: 18px;
    font-weight: 600;
  }
`;

export const ErrorAccountWrapper = styled(CheckingAccountWrapper)`
  button {
    background-color: #FFFFFF;
    font-weight: 600;
    padding: 12px;
    font-size: 16px;
    margin-top: 32px;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }
  }
`;
