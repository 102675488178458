
import httpClient from '../../../clients/http/http.client';
import Review from '../../../models/review/review.model';

const get = async ({
  token,
}) => {
  
  const response = await httpClient.get({ 
    path: '/users/get-reviews', 
    token,
  });
  
  const result = response.data.profile.reviews.map(review => {
    const { ratings, authorId, } = review
    return new Review({
      ...review,
      ...ratings,
      authorId: authorId._id,
      authorEmail: authorId.email,
      authorName: authorId.name,
      main: review.serviceProvided,
      comment: review.detail,
      negotiation: ratings.negotiationSkills,
      service: ratings.qualityOfService,
      surroundingsKnowledge: ratings.localKnowledge,
      id: review._id,
    });
  });
  
  return result;
};

const updateReport = async ({
  item,
  token,
  id
}) => {
  let params = {
    "text": item,
    "valid": 2,
  };

  const path = `/reviews/report-review/${id}`;

  const response = await httpClient.patch({
    path,
    params,
    token,
    options: {
      header: {
        'Content-Type': 'application/merge-patch+json'
      },
    },
  });
  return response;
}

export default {
  get,
  updateReport,
};
