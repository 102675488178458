import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { RiWhatsappLine } from "react-icons/ri";
import Modal from "react-modal";
import { toast } from "react-toastify";
import styled from "styled-components";
import {
  FacebookSquare,
  Instagram,
  LinkedinSquare,
} from "styled-icons/boxicons-logos";
import { SelectBootstrap } from "../../../components/form/select-bootstrap";
import realtorsService from "../../../services/realtors/realtors.service";
import userService from "../../../services/user/user.service";
import { BaseButton } from "../../../styled/buttons/buttons";
import { mqCustom, mqDesktop, mqTablet } from "../../../styled/helpers/helpers";
import {
  BaseInput,
  MaskedBaseInput,
  masks,
} from "../../../styled/inputs/inputs";
import {
  COLOR_1,
  COLOR_13,
  COLOR_2,
} from "../../../styled/variables/variables";
import { addMaskCellphone } from "../../../utils/add-masks";
import "./modal.css";
import { formSchema } from "./schema";

const customId = "custom-id-yes";

Modal.setAppElement("#root");

import {
  ButtonsContent,
  CityAndNeighborhoodContent,
  ContactDetailsContent,
  ContactDetailsFields,
  Form,
  NumberOfBedroomsAndBathroomsContent,
  NumberOfParkingSpacesAndAreaContent,
  PropertyDescriptionContent,
  PropertyUseSelectContent,
  WhatsAppContent,
} from "./styles";

import { RiCloseLine } from "react-icons/ri";
import { CellphoneInput } from "../../../components/form/cellphone-input";
import { InputBootstrap } from "../../../components/form/input-bootstrap";
import { PriceInput } from "../../../components/form/price-input";
import { TextArea } from "../../../components/form/textarea";
import { ShareButtons } from "../components/ShareButtons";

const propertyUsesItems = [
  {
    label: "Selecione uma transação",
    value: "Selecione uma transação",
  },
  {
    label: "Venda",
    value: "Venda",
  },
  {
    label: "Locação",
    value: "Locação",
  },
];

const propertyTypesItems = [
  {
    label: "Selecione um tipo",
    value: "Selecione um tipo",
  },
  {
    label: "Apartamento",
    value: "Apartamento",
  },
  {
    label: "Casa",
    value: "Casa",
  },
  {
    label: "Casa de Condomínio",
    value: "Casa de Condomínio",
  },
  {
    label: "Chácara/Sítio/Fazenda",
    value: "Chácara | Sítio | Fazenda",
  },
  {
    label: "Cobertura",
    value: "Cobertura",
  },
  {
    label: "Flat",
    value: "Flat",
  },
  {
    label: "Kitnet",
    value: "Kitnet",
  },
  {
    label: "Terreno",
    value: "Terreno",
  },
  {
    label: "Edifício",
    value: "Edifício",
  },
  {
    label: "Galpão/Depósito/Armazém",
    value: "Galpão | Depósito | Armazém",
  },
  {
    label: "Loja/Ponto Comercial",
    value: "Loja | Ponto Comercial",
  },
  {
    label: "Sala Comercial",
    value: "Sala",
  },
];

export function ContactInfo(props) {
  const [modalVisible, setModalVisible] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    reset,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  async function handleFormSubmit(values) {
    const params = new URL(document.location).searchParams;

    const id = params.get("id");
    const token = userService.retrieve().token;

    const formData = {
      ...values,
      price: values.price.replace(/[R$\.\,]/g, "").trim(),
    };

    try {
      const { status } = await realtorsService.send({
        data: formData,
        id,
        token,
      });

      if (status !== 200) {
        throw new Error(
          "Não foi possível enviar sua mensagem, tente novamente mais tarde!"
        );
      }

      setModalVisible(false);

      toast.info("Mensagem enviada com sucesso.", {
        autoClose: false,
        toastId: customId,
      });
    } catch (err) {
      setModalVisible(false);

      toast.error(err.message, {
        autoClose: false,
        toastId: customId,
      });
    } finally {
      reset();
    }
  }

  const {
    city,
    email,
    neighborhood,
    phoneNumber,
    number,
    state,
    street,
    facebook,
    instagram,
    linkedIn,
    isContactuser,
    whatsapp,
  } = props;

  const messageWhatsapp = `Olá! Tenho interesse em um imóvel. Vi seu perfil no Koort e gostaria de saber se pode me ajudar!`;

  const customStyles = {
    content: {
      background: "#2B50D8",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      height: "calc(100% - 50px)",
      width: "calc(100% - 20px)",
      maxWidth: "450px",
      maxHeight: "600px",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  return (
    <div>
      <Wrapper>
        <ShareButtons />

        <Info>
          {isContactuser && (
            <ButtonContact onClick={() => setModalVisible(true)}>
              Entre em contato
            </ButtonContact>
          )}
        </Info>
        {(street || number || neighborhood || city || state) && (
          <Info>
            <b>Endereço comercial:</b>
          </Info>
        )}
        {street && (
          <Info>
            {street}, {number}
          </Info>
        )}
        {neighborhood && <Info>{neighborhood}</Info>}
        {city && state && (
          <Info>
            {city} - {state}
          </Info>
        )}
        {email && (
          <Info>
            <b>E-mail:</b>
          </Info>
        )}
        {email && <Info>{email}</Info>}
        {phoneNumber && whatsapp === true && (
          <Info>
            <WhatsAppContent
              target="_blank"
              rel="noreferrer noopener"
              href={`https://api.whatsapp.com/send?phone=55${phoneNumber}&text=${messageWhatsapp}`}
            >
              <RiWhatsappLine size={24} />
              {addMaskCellphone(phoneNumber).substring(4)}
            </WhatsAppContent>
          </Info>
        )}
        <SocialMedia>
          <ul>
            {facebook && (
              <li className="facebook-link">
                <a target="_blank" rel="noreferrer noopener" href={facebook}>
                  <FacebookSquare size="44" />
                </a>
              </li>
            )}
            {instagram && (
              <li className="instagram-link">
                <a target="_blank" rel="noreferrer noopener" href={instagram}>
                  <Instagram size="44" />
                </a>
              </li>
            )}
            {linkedIn && (
              <li className="linkedin-link">
                <a target="_blank" rel="noreferrer noopener" href={linkedIn}>
                  <LinkedinSquare size="44" />
                </a>
              </li>
            )}
          </ul>
        </SocialMedia>
        <Modal
          isOpen={modalVisible}
          style={customStyles}
          contentLabel="Contact"
        >
          <Form onSubmit={handleSubmit(handleFormSubmit)}>
            <header>
              <h3>Preencha o formulário</h3>

              <button
                type="button"
                onClick={() => {
                  clearErrors();
                  reset();
                  setModalVisible(false);
                }}
              >
                <RiCloseLine size={24} />
              </button>
            </header>

            <PropertyDescriptionContent>
              <legend>Descreva seu imóvel para o corretor</legend>

              <PropertyUseSelectContent>
                <SelectBootstrap
                  name="propertyUse"
                  label="Tipo da transação"
                  options={propertyUsesItems}
                  error={errors.propertyUse}
                  {...register("propertyUse")}
                />
              </PropertyUseSelectContent>

              <SelectBootstrap
                name="propertyType"
                label="Tipo do imóvel"
                options={propertyTypesItems}
                error={errors.propertyType}
                {...register("propertyType")}
              />

              <CityAndNeighborhoodContent>
                <div>
                  <InputBootstrap
                    name="city"
                    label="Cidade"
                    placeholder="Cidade"
                    error={errors.city}
                    {...register("city")}
                  />
                </div>

                <div>
                  <InputBootstrap
                    name="neighborhood"
                    label="Bairro"
                    placeholder="Bairro"
                    error={errors.neighborhood}
                    {...register("neighborhood")}
                  />
                </div>
              </CityAndNeighborhoodContent>

              <NumberOfBedroomsAndBathroomsContent>
                <div>
                  <InputBootstrap
                    name="bedrooms"
                    label="Nº de quartos"
                    placeholder="Nº de quartos"
                    type="number"
                    error={errors.bedrooms}
                    {...register("bedrooms")}
                  />
                </div>

                <div>
                  <InputBootstrap
                    name="bathrooms"
                    label="Nº de banheiros"
                    placeholder="Nº de banheiros"
                    type="number"
                    error={errors.bathrooms}
                    {...register("bathrooms")}
                  />
                </div>
              </NumberOfBedroomsAndBathroomsContent>

              <NumberOfParkingSpacesAndAreaContent>
                <div>
                  <InputBootstrap
                    name="parkingSpaces"
                    label="Nº de vagas"
                    placeholder="Nº de vagas"
                    type="number"
                    error={errors.parkingSpaces}
                    {...register("parkingSpaces")}
                  />
                </div>

                <div>
                  <InputBootstrap
                    name="area"
                    label="Área (m²)"
                    placeholder="Área (m²)"
                    type="number"
                    error={errors.area}
                    {...register("area")}
                  />
                </div>
              </NumberOfParkingSpacesAndAreaContent>

              <PriceInput
                name="price"
                label="Valor"
                error={errors.price}
                control={control}
                {...register("price")}
              />
            </PropertyDescriptionContent>

            <ContactDetailsContent>
              <legend>Dados de contato</legend>

              <ContactDetailsFields>
                <InputBootstrap
                  name="name"
                  label="Nome"
                  placeholder="Nome"
                  error={errors.name}
                  {...register("name")}
                />

                <InputBootstrap
                  name="email"
                  label="E-mail"
                  placeholder="E-mail"
                  error={errors.email}
                  {...register("email")}
                />

                <CellphoneInput
                  name="telephone"
                  label="Telefone"
                  error={errors.telephone}
                  control={control}
                  {...register("telephone")}
                />

                <TextArea
                  name="message"
                  label="Mensagem"
                  height={80}
                  error={errors.message}
                  {...register("message")}
                />
              </ContactDetailsFields>
            </ContactDetailsContent>

            <ButtonsContent>
              <button type="submit" disabled={isSubmitting}>
                {isSubmitting ? "Enviando..." : "Enviar"}
              </button>

              <button
                type="button"
                onClick={() => {
                  clearErrors();
                  reset();
                  setModalVisible(false);
                }}
              >
                Cancelar
              </button>
            </ButtonsContent>
          </Form>
        </Modal>
      </Wrapper>
    </div>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${mqTablet`
    align-self: flex-start;
  `}
`;

const Info = styled.div`
  align-self: center;
  text-align: right;
  font-size: 14px;
  padding-bottom: 3px;

  /* ${mqTablet`
    align-self: flex-end;
  `} */
`;

const SocialMedia = styled.div`
  ul {
    justify-content: center;
    list-style: none;
    display: flex;
  }
  .facebook-link a {
    color: #3b5998;
    &:visited {
      color: #3b5998;
    }
  }

  .instagram-link a {
    color: #d0548b;
    &:visited {
      color: #d0548b;
    }
  }

  .linkedin-link a {
    color: #0e76a8;
    &:visited {
      color: #0e76a8;
    }
  }
`;

const ButtonContact = styled(BaseButton)`
  align-self: flex-start;
  text-transform: uppercase;
  display: none;
  background-color: ${COLOR_13};
  color: ${COLOR_2};
  border: none;
  font-weight: 700;

  line-height: 43px;
  padding: 0 15px;
  height: 40px;
  margin-bottom: 10px;

  ${mqTablet`
    display: unset;
    margin-right: 0px;
  `};

  ${mqCustom(250)`
    display: unset;
  `}

  ${mqDesktop`
    margin-right: -128px;
  `}
`;

const SaveModal = styled(BaseButton)`
  color: ${COLOR_2};
  background-color: ${COLOR_1};
  flex-grow: 1;
  width: 1px;
  border: none;
  width: 150px;
`;

const PhoneNumberInput = styled(BaseInput).attrs({
  as: MaskedBaseInput,
  mask: masks.phonesV2,
  id: "telefone",
  guide: false,
  placeholder: "Telefone",
})``;

const ValorInput = styled(BaseInput).attrs({
  as: MaskedBaseInput,
  mask: masks.price,
  id: "valor",
  name: "valor",
  guide: false,
  placeholder: "Valor",
})``;
