import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { transitions } from 'polished';

import { sectionPadding, dimmer, mqTablet, mqDesktop, mqCustom } from '../../../../../styled/helpers/helpers';

import Brand from '../../../../../partials/header/brand/Brand';
import Navbar from '../../../../../partials/header/navbar/Navbar';
import SocialIcons from '../../../../../components/social-icons';

import pathsConstants from '../../../../../constants/paths';
import { COLOR_17 } from '../../../../../styled/variables/variables';
import domEventsUtil from '../../../../../utils/dom-events/dom-events.util';
import UserGreetings from '../../../../../partials/header/user-greetings/UserGreetings';
import eventsConstants from '../../../../../constants/events';

import userService from '../../../../../services/user/user.service';
import pathConstants from '../../../../../constants/paths';
import { FloatingBox } from './components/FloatingBox';

class Header extends PureComponent {

  constructor(props) {
    super(props);

    this.wrapperRef = React.createRef();
    this.state = {
      scrolled: false,
      showMenu: false,
      showSearchBar: false,
      user: userService.retrieve(),
      menuOpen: false,
    };
  }

  openMenu=()=> {
    this.setState(prevState => ({
      ...prevState,
      menuOpen: !prevState.menuOpen
    }))
  }

  componentDidMount() {
    const { history } = this.props;
    this.setSearchBarStatus(history.location);

    window.addEventListener('scroll', this.handleScrollEvent);
    this.setHeaderScrolledStatus({ force: true });
    domEventsUtil.subscribe({
      name: eventsConstants.AUTH_STATUS_CHANGED,
      callback: this.handleAuthStatusChanged,
    });

    this.unlistenRouteChange = this.props.history.listen(this.handleRouteChange);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScrollEvent);
    domEventsUtil.unsubscribe({
      name: eventsConstants.AUTH_STATUS_CHANGED,
      callback: this.handleAuthStatusChanged,
    });
    this.unlistenRouteChange();
  }

  handleRouteChange = location => {
    this.setHeaderScrolledStatus({ force: true });
    this.setSearchBarStatus({ ...location, showOnHome: false, });
  };

  handleAuthStatusChanged = event => {
    const { auth } = event.detail.params;
    this.setState({
      ...this.state,
      user: auth ? userService.retrieve() : null,
    });
  }

  handleScrollEvent = () => {
    this.setHeaderScrolledStatus({});

    const { history } = this.props;
    const { showSearchBar } = this.state;
    if (!showSearchBar && (window.scrollY > window.innerHeight / 3)) {
      this.setSearchBarStatus({ ...history.location, showOnHome: true });
    } else if (showSearchBar && (window.scrollY < window.innerHeight / 3)) {
      this.setSearchBarStatus({ ...history.location, showOnHome: false });
    }
  };

  setSearchBarStatus = ({
    pathname,
    showOnHome = false,
  }) => {
    let status = false;

    if (pathname === pathsConstants.SEARCH) status = false;
    else if (pathname === pathsConstants.HOME && showOnHome) status = true;
    else if (pathname.includes(pathConstants.AD_DETAIL) && !pathname.includes('-id-') && showOnHome) status = true;

    this.setState({
      ...this.state,
      showSearchBar: status,
    });
  };

  setHeaderScrolledStatus = ({ force = false }) => {
    if (!this.wrapperRef.current) return;

    let scrolled;
    if (window.scrollY > this.wrapperRef.current.offsetHeight / 3) scrolled = true;
    else scrolled = false;

    const { history } = this.props;
    const { pathname } = history.location;
    if (pathname !== pathsConstants.HOME) scrolled = true;

    if (this.state.scrolled === scrolled && !force) return;

    setTimeout(() => {
      this.setState({ ...this.state, scrolled, });
    }, 0);
  };

  render() {
    const { user, scrolled, showSearchBar, showMenu, menuOpen } = this.state;
    const {
      isFullScreenImageUrl,
      panoramaModalVisible,
      floatingCTAOnTop,
      isMozillaNavigator,
      price,
      incorporation,
      isFavorited,
      onAddOrRemoveFavorite,
      onOpenContactFormModal,
    } = this.props;

    const hide = showSearchBar && (window.location.href.includes('/anuncio') && !window.location.href.includes('-id-'));

    return (
      <>
        <Wrapper ref={this.wrapperRef} scrolled={scrolled || window.location.pathname === pathConstants.SEARCH} showMenu={menuOpen}>
          <ContentWrapper space={showSearchBar || window.location.pathname === pathConstants.SEARCH}>
            <LeftWrapper>
              <Brand />
              {hide ? <></> : (
                <div style={{}}>
                  <UserGreetings
                    name={user ? user.name : null}
                  />
                </div>
              )}
            </LeftWrapper>

            {floatingCTAOnTop && (
              <FloatingBox
                isFullScreenImageUrl={isFullScreenImageUrl}
                panoramaModalVisible={panoramaModalVisible}
                floatingCTAOnTop={floatingCTAOnTop}
                isMozillaNavigator={isMozillaNavigator}
                price={price}
                isFavorited={isFavorited}
                incorporation={incorporation}
                onAddOrRemoveFavorite={onAddOrRemoveFavorite}
                onOpenContactFormModal={onOpenContactFormModal}
              />
            )}
            {!floatingCTAOnTop &&
              <HSocialIcons show={true} />
            }

            <RightWrapper>
              <Navbar
                showMenu={showMenu}
                openMenu={this.openMenu}
                scrolled={false}
                isReleasePage={hide}
              />
            </RightWrapper>
          </ContentWrapper>
        </Wrapper>
      </>
    );
  }
}

const Wrapper = styled.section`
position: relative;
  ${sectionPadding};
  display: flex;
  justify-content: center;
  height: 80px;
  position: fixed;
  width: 100%;
  z-index: 99;
  top: 0;
  ${transitions(['background-color'], 'ease .3s')};
  ${({ scrolled }) => scrolled ? dimmer(1, COLOR_17) : dimmer(0, COLOR_17)};

  ${({showMenu}) => showMenu && css`
    z-index: 999;
  `}
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  ${mqTablet`
    max-width: 1000px;
    justify-content: space-between;
    ${transitions(['max-width'], 'ease .6s .6s')}
    ${({ space }) => space && css`
      max-width: 100%;
      ${transitions(['max-width'], 'ease .6s')}
    ` }
  ` }

  ${mqCustom(250)`
    max-width: 1000px;
    justify-content: space-between;
    ${transitions(['max-width'], 'ease .6s .6s')}
    ${({ space }) => space && css`
      max-width: 100%;
      ${transitions(['max-width'], 'ease .6s')}
    ` }
  ` }
`;

const LeftWrapper = styled.div`
align-self: center;
width: 1px;
height: 100%;

${mqDesktop`
    flex-grow: 1;
    display: flex;
    width: fit-content;
    flex-grow: unset;
  ` }
${mqCustom(250)`
    display: flex;
    width: fit-content;
    flex-grow: unset;
`}
`;

const HSocialIcons = styled(SocialIcons)`
  align-self: center;
  justify-content: center;

  & > svg {
    height: 15px;
  }

  max-width: 100%;
  ${mqDesktop`
    ${({ show }) => show ? css`
      flex-grow: 1;
      opacity: 1;
      margin-left: 143px;
      ${transitions([
  'opacity',
  'max-width',
], 'ease .6s .6s')}
    ` : css`
      opacity: 0;
      max-width: 0;
      margin-left: 0;
      ${transitions([
  'opacity',
  'max-width',
], 'ease .6s')}
    ` }
  ` }

  ${mqCustom(1240)`
    margin-left: 0;
  ` }

  @media (max-width: 550px) {
    display: none;
  }
`;

const RightWrapper = styled.div`
  display: flex;
  align-self: center;
  flex-grow: 1;
  width: 1px;
  justify-content: flex-end;

  ${mqDesktop`
    width: fit-content;
    flex-grow: unset;
  ` }
  ${mqCustom(250)`
  width: fit-content;
  flex-grow: unset;

  `}
`;

export default withRouter(Header);
