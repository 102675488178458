/* eslint-disable no-unreachable */
import React, { PureComponent, Fragment } from "react";
import styled, { css } from "styled-components";
import { Link, Redirect } from "react-router-dom";
import { transitions } from "polished";
import { Auth } from "aws-amplify";
import { FacebookF } from "styled-icons/fa-brands/FacebookF";
import { toast } from "react-toastify";
import { RiArrowLeftLine } from "react-icons/ri";

import {
  COLOR_17,
  COLOR_20,
  COLOR_2,
  COLOR_1,
  COLOR_13,
  COLOR_16,
  COLOR_9,
} from "../../styled/variables/variables";
import {
  backgrounder,
  iconBaseStyle,
  mqDesktop,
  mqTablet,
} from "../../styled/helpers/helpers";
import { KoortWhiteVerticalLogo } from "../../styled/components/components";
import { BaseButton } from "../../styled/buttons/buttons";
import { BaseLabel } from "../../styled/labels/labels";
import { BaseInput } from "../../styled/inputs/inputs";

import Checkbox from "../../components/checkbox";
import InputWrapper from "../../components/input-wrapper";
import { InputBootstrap } from "../../components/form/input-bootstrap";
import { ErrorMessage } from "../../components/form/error-message";

import User from "../../models/user/user.model";
import storageUtil from "../../utils/storage/storage.util";
import httpClient from "../../clients/http/http.client";
import userService from "../../services/user/user.service";
import domEventsUtil from "../../utils/dom-events/dom-events.util";
import pathsConstants, { AUTHENTICATION_PARAMS } from "../../constants/paths";
import eventsConstants from "../../constants/events";
import { InputPasswordBootstrap } from "../../components/form/input-password-bootstrap";

import './overwrite-inputs-css.css';

/*
  global
  process
*/

const { PUBLIC_URL } = process.env;
const customId = "custom-id-yes";
class Authentication extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        name: null,
        email: null,
        password: null,
        codigo: null,
        newsletter: true,
        terms: false,
        errorNameInput: "",
      },
      accessedRegistrationPage: false,
      setNewPassword: false,
      user: null,
      chooseAccount: false,
      userOwnAccount: [],
      userLinkedAccounts: [],
      userAccountSelected: {},
    };
  }

  componentDidUpdate() {
    const emailConfirmField = document.querySelector("#emailConfirm");
    const passwordConfirmField = document.querySelector("#passwordConfirm");

    if (!!emailConfirmField && !!passwordConfirmField) {
      emailConfirmField.addEventListener("paste", (event) => {
        event.preventDefault();
      });

      passwordConfirmField.addEventListener("paste", (event) => {
        event.preventDefault();
      });
    }
  }

  goNext = () => {
    const { history } = this.props;
    const queryStringObj = queryStringUtil.parse(history);

    const navConfig = {};
    if (queryStringObj && queryStringObj.fallback) {
      const { fallback } = queryStringObj;
      const splitPosition = fallback.indexOf("?");
      navConfig.pathname = fallback.substring(0, splitPosition);
      navConfig.search = fallback.substring(splitPosition + 1, fallback.length);
    } else navConfig.pathname = pathsConstants.HOME;
    let userLogged = localStorage.getItem("userNotLogged");
    userLogged = JSON.parse(userLogged);
    if (userLogged) {
      navConfig.pathname = pathsConstants.PURCHASE_CHOOSE_TYPE;
      localStorage.setItem("userNotLogged", false);
    }

    history.push(navConfig);
  };

  goBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  handleFormChange = (event) => {
    const { name, value, type, checked } = event.target;
    this.setState((state) => ({
      ...state,
      form: {
        ...state.form,
        [name]: type === "checkbox" ? checked : value,
      },
    }));
  };

  handleFormSubmit = async (event) => {
    event.preventDefault();

    if (this.state.form.errorNameInput) {
      toast.error("Campo obrigatório");

      return;
    }

    const { match } = this.props;
    let { action } = match.params;

    const {
      LOGIN,
      SIGNUP,
      PASSWORD_RECOVERY,
      EMAIL_CONFIRM,
      PASSWORD_RECOVERY_CONFIRM,
      NEW_PASSWORD,
    } = AUTHENTICATION_PARAMS;
    if (action === LOGIN) this.handleGetUserAccounts();
    else if (action === SIGNUP) this.signup();
    else if (action === PASSWORD_RECOVERY) this.recoverPassword();
    else if (action === EMAIL_CONFIRM) this.emailConfirm();
    else if (action === PASSWORD_RECOVERY_CONFIRM) this.changePassword();
    else if (action === NEW_PASSWORD) this.newPassword();
  };

  handleGetUserAccounts = async () => {
    // Essa função realiza a busca das contas vinculadas ao usuário
    // e as identifica

    domEventsUtil.dispatch({
      name: eventsConstants.LOADING_ANIMATION,
      params: { show: true },
    });

    const { form } = this.state;
    const { email, password } = form;

    let hasErrorLogin = false;

    await Auth.signIn(email, password).catch((err) => {
      hasErrorLogin = true;

      if (err.code == "NotAuthorizedException") {
        toast.error("E-mail ou senha incorreto.", {
          toastId: customId,
        });
      } else {
        toast.error("Erro ao fazer login.", {
          toastId: customId,
        });
      }

      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: false },
      });
    });

    if (hasErrorLogin) return;

    try {
      const { data, status } = await userService.getUserAccounts({ userEmail: email });

      if (status !== 200) {
        throw new Error('Não encontramos o seu usuário, tente novamente!');
      }

      const userHasLinkedAccount = data.find(item => !!item.company);

      if (!!userHasLinkedAccount && data.length > 1) {
        const userOwnAccount = data.filter(item => !item.company);
        const userLinkedAccounts = data.filter(item => !!item.company);

        this.setState(prevState => ({
          ...prevState,
          userOwnAccount,
          userLinkedAccounts,
          chooseAccount: true,
        }));

        domEventsUtil.dispatch({
          name: eventsConstants.LOADING_ANIMATION,
          params: { show: false },
        });
      } else {
        this.setState(prevState => ({
          ...prevState,
          userAccountSelected: data[0],
        }));

        domEventsUtil.dispatch({
          name: eventsConstants.LOADING_ANIMATION,
          params: { show: false },
        });

        this.login();
      }
    } catch (err) {
      toast.error(err.message);

      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: false },
      });

      return;
    }
  }

  handleProceedLogin(userAccount) {
    // Essa função permite que o usuário prossiga com a conta escolhida
    // através do modal

    this.setState(prevState => ({
      ...prevState,
      userAccountSelected: userAccount,
    }), () => {
      this.login();
    });
  }

  login = async () => {
    domEventsUtil.dispatch({
      name: eventsConstants.LOADING_ANIMATION,
      params: { show: true },
    });

    const { form } = this.state;
    const { email, password } = form;
    const { history } = this.props;
    var isErr = false;
    var uCognito = await Auth.signIn(email, password).catch((err) => {
      isErr = true;
      if (err.code == "NotAuthorizedException") {
        toast.error("E-mail ou senha incorreto.", {
          toastId: customId,
        });
      } else {
        toast.error("Erro ao fazer login.", {
          toastId: customId,
        });
      }

      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: false },
      });
    });

    if (!isErr) {
      if (uCognito.challengeName === "NEW_PASSWORD_REQUIRED") {
        this.state.user = uCognito;
        domEventsUtil.dispatch({
          name: eventsConstants.LOADING_ANIMATION,
          params: { show: false },
        });
        const { history } = this.props;
        const { NEW_PASSWORD } = AUTHENTICATION_PARAMS;
        history.push(`${pathsConstants.AUTHENTICATION}/${NEW_PASSWORD}`);
        return;
      }

      var userKoort = await userService.getUserProfile({
        token: `${uCognito.signInUserSession.idToken.jwtToken}:userId=${this.state.userAccountSelected._id}`,
      });

      const user = {
        ...userKoort,
        token: `${uCognito.signInUserSession.idToken.jwtToken}:userId=${this.state.userAccountSelected._id}`,
      };

      storageUtil.set("user", user);
      this.setCookie("user", JSON.stringify(user));
      this.setCookie(
        "AUTH_USER_TOKEN_KEY",
        `${uCognito.signInUserSession.idToken.jwtToken}:userId=${this.state.userAccountSelected._id}`
      );
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem(
        "AUTH_USER_TOKEN_KEY",
        `${uCognito.signInUserSession.idToken.jwtToken}:userId=${this.state.userAccountSelected._id}`
      );
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: false },
      });
      const url = new URL(window.location.href);
      // const urlRedirect = url.searchParams.get("url");
      const urlRedirect = url.search.replace('?url=', '');
      if (urlRedirect) {
        history.push(urlRedirect);
      } else {
        history.push("/");

        // history.push(`/`, {
        //   state: {
        //     firstLogin: userKoort.hadFirstLogin
        //   }
        // });
      }
    }
  };

  signup = async () => {
    if (
      this.state.form.name === null ||
      !this.state.form.name.length ||
      !!this.state.form.errorNameInput
    ) {
      toast.error("Campo nome é obrigatório", {
        autoClose: false,
      });

      return;
    }

    // if (this.state.form.emailConfirm !== this.state.form.email) {
    //   toast.error("Os e-mails não coincidem!", {
    //     autoClose: false,
    //   });

    //   return;
    // }

    if (this.state.form.password.length < 8) {
      toast.error("A senha deve conter no mínimo 8 caracteres.", {
        toastId: customId,
      });

      this.state.form.password = "";
      this.state.form.passwordConfirm = "";

      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: false },
      });

      return;
    }

    // if (this.state.form.passwordConfirm !== this.state.form.password) {
    //   toast.error("As senhas não coincidem!", {
    //     autoClose: false,
    //   });

    //   return;
    // }

    domEventsUtil.dispatch({
      name: eventsConstants.LOADING_ANIMATION,
      params: { show: true },
    });

    const { form } = this.state;
    const { name, email, password, newsletter } = form;
    const { history } = this.props;

    var isErr = false;

    Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
        name,
        phone_number: "+5581999999999",
      },
    })
      .then(() => {
        httpClient.post({
          path: "/users",
          params: {
            name,
            email,
            receiveNewsOffers: newsletter,
          },
        });
      })
      .catch((err) => {
        isErr = true;
        console.error(err);
        if (err.code == "UsernameExistsException") {
          toast.error("Usuário ou E-mail já cadastrado.", {
            toastId: customId,
          });
          return;
        }

        toast.error("Erro criando conta.", {
          toastId: customId,
        });
      })
      .finally(() => {
        domEventsUtil.dispatch({
          name: eventsConstants.LOADING_ANIMATION,
          params: { show: false },
        });
        if (!isErr) {
          history.push(`${pathsConstants.AUTHENTICATION}/email-confirm${new URL(window.location.href).search}`);
        }
      });

    //history.go(-2);
    // this.goNext();
  };

  recoverPassword = async () => {
    domEventsUtil.dispatch({
      name: eventsConstants.LOADING_ANIMATION,
      params: { show: true },
    });

    const { form } = this.state;
    var isErr = false;

    Auth.forgotPassword(form.email)
      .then((data) => {
        console.log(data);
      })
      .catch((err) => {
        toast.error("Erro enviando e-mail.", {
          toastId: customId,
        });
        console.error(err);
        isErr = true;
      })
      .finally(() => {
        domEventsUtil.dispatch({
          name: eventsConstants.LOADING_ANIMATION,
          params: { show: false },
        });
        if (!isErr) {
          const { history } = this.props;
          const { PASSWORD_RECOVERY_CONFIRM } = AUTHENTICATION_PARAMS;
          history.push(
            `${pathsConstants.AUTHENTICATION}/${PASSWORD_RECOVERY_CONFIRM}${new URL(window.location.href).search}`
          );
        }
      });
  };

  emailConfirm = async () => {
    domEventsUtil.dispatch({
      name: eventsConstants.LOADING_ANIMATION,
      params: { show: true },
    });

    var isErr = false;
    const { history } = this.props;

    Auth.confirmSignUp(this.state.form.email, this.state.form.codigo)
      .then(() => {
        // this.handleOpenNotification('success', 'Succesfully confirmed!', 'You will be redirected to login in a few!');
      })
      .catch((err) => {
        console.error(err);
        isErr = true;
        toast.error("Erro ao confirmar o código.", {
          toastId: customId,
        });
      })
      .finally(() => {
        domEventsUtil.dispatch({
          name: eventsConstants.LOADING_ANIMATION,
          params: { show: false },
        });
        if (!isErr) {
          toast.info("Código confirmado com sucesso.", {
            toastId: customId,
            autoClose: 3000,
          });

          this.handleGetUserAccounts();

          //history.push(`${pathsConstants.AUTHENTICATION}/login`);
        }
      });
  };

  setCookie = (c_name, value, exdays) => {
    var exdate = new Date();
    exdate.setDate(exdate.getDate() + exdays);
    var c_value =
      escape(value) +
      (exdays == null ? "" : "; expires=" + exdate.toUTCString()) +
      "; path=/; domain=koort.com.br";

    document.cookie = c_name + "=" + c_value;

    var c_value =
      escape(value) +
      (exdays == null ? "" : "; expires=" + exdate.toUTCString()) +
      "; path=/; domain=localhost";

    document.cookie = c_name + "=" + c_value;
  };

  getCookie = (c_name) => {
    var i,
      x,
      y,
      ARRcookies = document.cookie.split(";");
    for (i = 0; i < ARRcookies.length; i++) {
      x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
      y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
      x = x.replace(/^\s+|\s+$/g, "");
      if (x == c_name) {
        return unescape(y);
      }
    }
  };

  newPassword = async () => {
    domEventsUtil.dispatch({
      name: eventsConstants.LOADING_ANIMATION,
      params: { show: true },
    });
    const { form } = this.state;
    var isErr = false;
    const { history } = this.props;

    if (this.state.form.password.length < 8) {
      toast.error("A senha deve conter no mínimo 8 caracteres.", {
        toastId: customId,
      });

      this.state.form.password = "";
      this.state.form.passwordConfirm = "";

      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: false },
      });

      return;
    }

    if (this.state.form.passwordConfirm !== this.state.form.password) {
      toast.error("As senhas não coincidem!", {
        autoClose: false,
      });

      return;
    }

    Auth.completeNewPassword(this.state.user, this.state.form.password)
      .then(async (userResponse) => {
        toast.success("Sua senha foi redefinida com sucesso!");

        var userKoort = await userService.getUserProfile({
          token: `${userResponse.signInUserSession.idToken.jwtToken}:userId=${this.state.userAccountSelected._id}`,
        });

        const user = {
          ...userKoort,
          token: `${userResponse.signInUserSession.idToken.jwtToken}:userId=${this.state.userAccountSelected._id}`,
        };

        storageUtil.set("user", user);
        this.setCookie("user", JSON.stringify(user));
        this.setCookie(
          "AUTH_USER_TOKEN_KEY",
          `${userResponse.signInUserSession.idToken.jwtToken}:userId=${this.state.userAccountSelected._id}`
        );
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem(
          "AUTH_USER_TOKEN_KEY",
          `${userResponse.signInUserSession.idToken.jwtToken}:userId=${this.state.userAccountSelected._id}`
        );
        domEventsUtil.dispatch({
          name: eventsConstants.LOADING_ANIMATION,
          params: { show: false },
        });
        const url = new URL(window.location.href);
        const urlRedirect = url.search.replace('?url=', '');
        if (urlRedirect) {
          history.push(urlRedirect);
        } else {
          history.push("/");
        }
      })
      .catch((error) => {
        toast.error(
          "Não foi possível redefinir sua senha, tente novamente mais tarde!"
        );
      })
      .finally(() => {
        domEventsUtil.dispatch({
          name: eventsConstants.LOADING_ANIMATION,
          params: { show: false },
        });
      });
  };

  changePassword = async () => {
    domEventsUtil.dispatch({
      name: eventsConstants.LOADING_ANIMATION,
      params: { show: true },
    });

    const { form } = this.state;
    var isErr = false;
    const { history } = this.props;

    if (form.password.length < 8) {
      toast.error("A senha deve conter no mínimo 8 caracteres.", {
        toastId: customId,
      });

      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: false },
      });

      return;
    }

    Auth.forgotPasswordSubmit(form.email, form.codigo, form.password)
      .then(() => {
        console.log("forgotPasswordSubmit");
      })
      .catch((err) => {
        console.error(err);
        isErr = true;
        toast.error("Erro ao mudar senha.", {
          toastId: customId,
        });
      })
      .finally(() => {
        domEventsUtil.dispatch({
          name: eventsConstants.LOADING_ANIMATION,
          params: { show: false },
        });
        if (!isErr) {
          toast.info("Senha alterada com sucesso.", {
            toastId: customId,
          });
          history.push(`${pathsConstants.AUTHENTICATION}/login${new URL(window.location.href).search}`);
        }
      });
  };

  verifyTextOnNameInput2(text) { }

  verifyTextOnNameInput(text) {
    if (text.trim() === "") {
      this.setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          errorNameInput: "Não é permitido espaços em branco",
        },
      }));

      return;
    }
    if (text.match(/[^a-zA-Z 0-9áéíóúâêôãçÁÉÍÓÚÂÊÔÃÇ]+/g)) {
      this.setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          errorNameInput: "Não é permitido caracteres especiais",
        },
      }));

      return;
    }

    if (text.length < 3) {
      this.setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          errorNameInput: "Mínimo de 3 caracteres",
        },
      }));

      return;
    }

    if (text.length > 30) {
      this.setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          errorNameInput: "Máximo de 30 caracteres",
        },
      }));

      return;
    }

    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        errorNameInput: "",
      },
    }));
  }

  handleBackToLoginFields = () => {
    this.setState(prevState => ({
      ...prevState,
      chooseAccount: false,
      userOwnAccount: [],
      userLinkedAccounts: [],
      userAccountSelected: {},
    }));
  }

  render() {
    const { match } = this.props;
    const { form } = this.state;
    const { action } = match.params;

    const {
      SIGNUP,
      LOGIN,
      PASSWORD_RECOVERY,
      PASSWORD_RECOVERY_CONFIRM,
      EMAIL_CONFIRM,
      NEW_PASSWORD,
    } = AUTHENTICATION_PARAMS;
    return (
      <Wrapper>
        <Texture />
        <ContentWrapper>
          <Logo />


          {action === NEW_PASSWORD && !this.state.chooseAccount && (
            <Fragment>
              <PasswordRecoveryInstructions>
                Informe uma nova senha para confirmar seu cadastro.
              </PasswordRecoveryInstructions>
              <Form
                onChange={this.handleFormChange}
                onSubmit={this.handleFormSubmit}
              >
                <div className="mb-3">
                  <InputBootstrap
                    name="password"
                    label="Nova senha"
                    placeholder="Nova senha"
                    id="password"
                    type="password"
                  />
                </div>
                <div className="mb-3">
                  <InputBootstrap
                    name="passwordConfirm"
                    label="Confirmar senha"
                    placeholder="Confirmar senha"
                    id="passwordConfirm"
                    type="password"
                  />
                </div>
                <ConfirmButton>Criar nova senha</ConfirmButton>
              </Form>
              <TermsAndConditionsLabel>
                Ao confirmar seu cadastro você aceita e concorda com os nossos {" "}
                <TermsAndConditionsLink>
                  Termos e Condições
                </TermsAndConditionsLink>
                .
              </TermsAndConditionsLabel>
            </Fragment>
          )}

          {action === PASSWORD_RECOVERY_CONFIRM && !this.state.chooseAccount && (
            <Fragment>
              <PasswordRecoveryInstructions>
                Informe o código recebido no e-mail.
              </PasswordRecoveryInstructions>
              <Form
                onChange={this.handleFormChange}
                onSubmit={this.handleFormSubmit}
              >
                <InputBootstrap
                  name="email"
                  type="hidden"
                  value={this.state.form.email}
                />
                <InputBootstrap
                  name="password"
                  type="hidden"
                  value={this.state.form.password}
                />
                <div
                  className={"koort__input_control"}
                  style={{ "margin-bottom": "10px" }}
                >
                  <Input
                    name="codigo"
                    id="codigo"
                    type="text"
                    className={"koort__input"}
                    style={{ width: "100%" }}
                    placeholder={"Informe o código"}
                  />
                  <label className={"koort__input_label"} htmlFor={"codigo"}>
                    Código
                  </label>
                </div>
                <div
                  className={"koort__input_control"}
                  style={{ "margin-bottom": "10px" }}
                >
                  <Input
                    name="password"
                    type="password"
                    id="password"
                    className={"koort__input"}
                    style={{ width: "100%" }}
                    placeholder="Nova Senha"
                  />
                  <label className={"koort__input_label"} htmlFor={"password"}>
                    Nova Senha
                  </label>
                </div>
                <ConfirmButton>{"Alterar senha"}</ConfirmButton>
              </Form>
            </Fragment>
          )}

          {action === EMAIL_CONFIRM && !this.state.chooseAccount && (
            <Fragment>
              <PasswordRecoveryInstructions>
                Informe o código recebido no e-mail.
              </PasswordRecoveryInstructions>
              <Form
                onChange={this.handleFormChange}
                onSubmit={this.handleFormSubmit}
              >
                <InputBootstrap
                  name="name"
                  label="Nome"
                  placeholder="Nome"
                  value={this.state.form.name}
                  type="hidden"
                />
                <InputBootstrap
                  name="password"
                  type="hidden"
                  value={this.state.form.password}
                />
                <div className="mb-3">
                  <InputBootstrap
                    name="codigo"
                    label="Informe o código"
                    placeholder="Informe o código"
                  />
                </div>
                <ConfirmButton>{"Validar"}</ConfirmButton>
              </Form>
            </Fragment>
          )}

          {!this.state.chooseAccount &&
            action !== PASSWORD_RECOVERY_CONFIRM &&
            action !== EMAIL_CONFIRM &&
            action !== NEW_PASSWORD && (
              <Fragment>
                {action !== PASSWORD_RECOVERY && (
                  <ActionLabel>
                    {action === LOGIN ? "Entre" : "Cadastre-se"} com seu e-mail
                  </ActionLabel>
                )}
                <Form
                  onChange={this.handleFormChange}
                  onSubmit={this.handleFormSubmit}
                >
                  {action === SIGNUP && (
                    <>
                      <div className="mb-3">
                        <InputBootstrap
                          name="name"
                          label="Nome"
                          placeholder="Nome"
                          onChange={(event) =>
                            this.verifyTextOnNameInput(event.target.value)
                          }
                        />
                      </div>

                      {this.state.form.errorNameInput && (
                        <ErrorMessage
                          message={this.state.form.errorNameInput}
                        />
                      )}
                    </>
                  )}
                  <div className="mb-3">
                    <InputBootstrap
                      name="email"
                      label="E-mail"
                      id="email"
                      type="email"
                      required={true}
                      placeholder={
                        action === PASSWORD_RECOVERY
                          ? "Digite o e-mail cadastrado"
                          : "E-mail"
                      }
                    />
                  </div>
                  {action === SIGNUP && (
                    <div className="mb-3">
                      <InputPasswordBootstrap
                        name="password"
                        label="Senha"
                        placeholder="password"
                        id="password"
                        required={true}
                      />
                    </div>
                  )}

                  {action === LOGIN && (
                    <div className="mb-3">
                      <InputPasswordBootstrap
                        name="password"
                        label="Senha"
                        placeholder="password"
                        id="password"
                        required={true}
                      />
                    </div>
                  )}

                  {action === SIGNUP && (
                    <>
                      <CheckboxInputWrapper
                        label="Aceito receber novidades"
                        htmlFor="newsletter"
                      >
                        <NewsletterCheckbox
                          id="newsletter"
                          name="newsletter"
                          checked={form.newsletter}
                        />
                      </CheckboxInputWrapper>
                    </>
                  )}
                  <ConfirmButton
                    style={{ "margin-top": "15px" }}
                  // disabled={form.terms || action !== SIGNUP ? false : true}
                  >
                    {action === LOGIN && "entrar"}
                    {action === SIGNUP && "cadastrar"}
                    {action === PASSWORD_RECOVERY && "redefinir senha"}
                  </ConfirmButton>
                </Form>
                {action === LOGIN && (
                  <BackToHomeAndForgotPasswordWrapper>
                    <BackToHomeLink>
                      <RiArrowLeftLine /> Voltar
                    </BackToHomeLink>

                    <ForgotPasswordLink>Esqueci minha senha</ForgotPasswordLink>
                  </BackToHomeAndForgotPasswordWrapper>
                )}
                {action !== PASSWORD_RECOVERY &&
                  action !== PASSWORD_RECOVERY_CONFIRM && (
                    <TermsAndConditionsLabel isSignUp={action === SIGNUP}>
                      {action === SIGNUP && "Ao se cadastrar você aceita e concorda com os nossos "}
                      {action === LOGIN && "Acesse os nossos "}
                      {action === NEW_PASSWORD && "Ao confirmar seu cadastro você aceita e concorda com os nossos "}
                      <TermsAndConditionsLink>
                        Termos e Condições
                      </TermsAndConditionsLink>
                      .
                    </TermsAndConditionsLabel>
                  )}

                {action === LOGIN && (
                  <SignLink>
                    Não é cadastrado? <SignUpLink>Cadastre-se aqui</SignUpLink>
                  </SignLink>
                )}
                {action === SIGNUP && (
                  <SignLink>
                    Já possui uma conta? <LogInLink>Entre aqui</LogInLink>
                  </SignLink>
                )}
                {action === PASSWORD_RECOVERY && (
                  <BackToLoginLink>Voltar para login</BackToLoginLink>
                )}
              </Fragment>
            )}

          {this.state.chooseAccount && (
            <>
              <ChooseAccount>
                <strong>Escolha uma conta para prosseguir</strong>

                <AccountCardsContainer>
                  <ChooseAccountList>
                    {!!this.state.userOwnAccount.length && (
                      <OwnAccountContainer>
                        <h3>Conta própria</h3>

                        {this.state.userOwnAccount.map(item => (
                          <AccountCard
                            key={item._id}
                            onClick={() => this.handleProceedLogin(item)}
                          >
                            <img src={item.avatarUrl} />

                            <div>
                              <h3>{item.name}</h3>
                              <span>{item.email}</span>
                            </div>
                          </AccountCard>
                        ))}
                      </OwnAccountContainer>
                    )}

                    {!!this.state.userLinkedAccounts.length && (
                      <LinkedAccountContainer>
                        <h3>Contas vinculadas</h3>

                        {this.state.userLinkedAccounts.map(item => (
                          <AccountCard
                            key={item._id}
                            onClick={() => this.handleProceedLogin(item)}
                          >
                            <img src={item.avatarUrl} />

                            <div>
                              <h3>{item.name}</h3>
                              <span>{item.company.name}</span>
                            </div>
                          </AccountCard>
                        ))}
                      </LinkedAccountContainer>
                    )}
                  </ChooseAccountList>
                </AccountCardsContainer>
              </ChooseAccount>

              <BackToLoginFields>
                <button type="button" onClick={this.handleBackToLoginFields}>
                  <RiArrowLeftLine /> Voltar ao início
                </button>
              </BackToLoginFields>
            </>
          )}
        </ContentWrapper>
      </Wrapper >
    );
  }
}

const BackToLoginFields = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    color: #2B50D8;

    transition: filter .2s;

    &:hover {
      filter: brightness(0.8);
    }

    > svg {
      margin-right: 12px;
    }
  }
`;

const ChooseAccount = styled.div`
  align-self: center;
  background-color: #FFFFFF;
  max-width: 400px;
  max-height: 500px;
  border-radius: 4px;
  padding: 14px 0;
  margin-top: -20px;

  > strong {
    display: block;
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    padding: 0px 16px 14px 16px;
    text-align: center;
    font-size: 20px;
  }

  @media (max-width: 1080px) {
    width: 400px;
  }

  @media (max-width: 470px) {
    width: 340px;
    max-height: 600px;
  }

  @media (max-width: 400px) {
    width: 320px;
    max-height: 600px;
  }

  @media (max-width: 320px) {
    width: 290px;
    max-height: 600px;
  }
`;

const ChooseAccountList = styled.div`
  padding: 20px 16px 0 16px;
`;

const OwnAccountContainer = styled.div`
  > h3 {
    font-size: 18px;
    font-weight: 600;
  }
`;

const AccountCard = styled.button`
  display: flex;
  align-items: center;
  margin-top: 16px;
  width: 100%;
  padding: 8px;
  background: transparent;
  text-align: start;
  border-radius: 4px;

  transition: background-color .2s;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  > img {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    margin-right: 12px;
  }

  > div {
    h3 {
      font-weight: 600;
      color: rgba(0, 0, 0, 0.8);
    }

    span {
      font-weight: 500;
      color: rgba(0, 0, 0, 0.6);
    }
  }
`;

const LinkedAccountContainer = styled.div`
  margin-top: 32px;

  > h3 {
    font-size: 18px;
    font-weight: 600;
  }
`;

const AccountCardsContainer = styled.div`
 overflow-y: scroll;
 max-height: 430px;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const Wrapper = styled.div`
  background-color: ${COLOR_17};
  background-position-y: -50px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  ${mqDesktop`
    flex-direction: row;
  `}
`;

const BackToHomeAndForgotPasswordWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 300px;
  align-self: center;
  margin: 0px 0px 16px;

  > a:first-child {
    text-decoration: none;
  }

  @media (max-width: 370px) {
    width: 280px;
  }
`;

const Texture = styled.div`
  ${backgrounder(`${PUBLIC_URL}/imgs/koort-texture-white-outline.svg`)};
  background-position: bottom;
  height: 140px;
  width: 100%;
  background-repeat: repeat-x;
  background-size: 620px;
  margin-bottom: 30px;

  ${mqDesktop`
    background-position: right;
    width: 300px;
    height: 100vh;
    background-size: 1000px;
    margin: 0;
  `}
`;

const ContentWrapper = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  padding: 10px;
  padding-bottom: 100px;
  width: 290px;

  ${mqDesktop`
    align-self: center;
    margin-left: -300px;
    width: 100%;
  `}
`;

const Logo = styled(KoortWhiteVerticalLogo)`
  margin-bottom: 50px;
  align-self: center;

  ${mqTablet`
    transform: scale(1.3);
    ${transitions(["max-width"], "ease .6s .6s")}
  `}

  ${mqDesktop`
    width: 290px;
    transform: scale(1);
  `}
`;

const FacebookButton = styled(BaseButton)`
  background-color: ${COLOR_20};
  align-self: center;
  color: ${COLOR_2};
  text-transform: none;
  width: 100%;
  margin: 0;
  border: none;

  ${mqTablet`
  transform: scale(1.3);
  margin-bottom: 50px;
  ${transitions(["max-width"], "ease .6s .6s")}
  `}

  ${mqDesktop`
  width: 290px;
  transform: scale(1);
  margin-bottom: 30px;
  `}
`;

const FacebookIcon = styled(FacebookF)`
  ${iconBaseStyle}
  color: ${COLOR_2};
  align-self: center;
  height: 16px;
  margin-right: 20px;
`;

const ActionLabel = styled(BaseLabel)`
  color: ${COLOR_2};
  font-weight: 700;
  margin-bottom: 10px;
  width: 300px;
  align-self: center;

  @media (max-width: 370px) {
    width: 280px;
  }
`;

const PasswordRecoveryResponse = styled(ActionLabel)`
  align-self: center;
  text-align: center;
  font-size: 22px;
  margin-bottom: 20px;
`;

const PasswordRecoveryInstructions = styled(PasswordRecoveryResponse)`
  font-weight: normal;
  margin-bottom: 10px;
  font-size: 16px;
`;

const Form = styled.form`
  width: 300px;
  align-self: center;

  @media (max-width: 370px) {
    width: 280px;
  }
`;

const Input = styled(BaseInput)``;

const CheckboxInputWrapper = styled(InputWrapper)`
  flex-direction: row-reverse;
  justify-content: flex-end;
  margin-bottom: 20px;
  & > label {
    color: ${COLOR_2};
  }
`;

const NewsletterCheckbox = styled(Checkbox)`
  margin-right: 5px;
`;

const SignButton = styled(BaseButton)`
  background-color: ${COLOR_20};
  align-self: center;
  color: ${COLOR_2};
  text-transform: none;
  width: 100%;
  margin: 0;
  margin-bottom: 10px;
  border: none;

  ${mqDesktop`
    width: 300px;
  `}
`;

const ConfirmButton = styled(SignButton).attrs(() => ({
  type: "submit",
}))`
  background-color: ${({ disabled }) =>
    disabled ? `${COLOR_16}` : `${COLOR_1}`};
  color: ${({ disabled }) => (disabled ? `${COLOR_9}` : `${COLOR_2}`)};
  font-weight: 700;
  text-transform: uppercase;
  ${mqTablet`
    margin-bottom: 10px;
  `}
`;

const BackToHomeButton = styled(ConfirmButton).attrs(() => ({
  as: Link,
  to: pathsConstants.HOME,
}))`
  margin-top: 30px;
`;

const ForgotPasswordLink = styled(BaseLabel).attrs(() => ({
  as: Link,
  to: `${pathsConstants.AUTHENTICATION}/${AUTHENTICATION_PARAMS.PASSWORD_RECOVERY}${new URL(window.location.href).search}`,
}))`
  color: ${COLOR_1};
  font-size: 14px;
  font-weight: bold;
  align-self: center;
  ${mqTablet`
    margin-top: 5px;
  `}
`;

const DefaultLink = styled(BaseLabel).attrs(() => ({
  as: Link,
  to: `${pathsConstants.AUTHENTICATION}/${AUTHENTICATION_PARAMS.PASSWORD_RECOVERY}`,
}))`
  color: ${COLOR_1};
  font-size: 16px;
  font-weight: bold;
  align-self: center;
`;

const Label = styled(DefaultLink).attrs(() => ({
  as: "label",
}))`
  color: ${COLOR_2};
  text-align: center;
`;

const SignLink = styled(Label)`
  margin-top: 8px;
  width: 300px !important;
  font-size: 15px;

  @media (max-width: 370px) {
    width: 280px !important;
  }
`;

const SignUpLink = styled.label.attrs(() => ({
  as: Link,
  to: `${pathsConstants.AUTHENTICATION}/${pathsConstants.AUTHENTICATION_PARAMS.SIGNUP}${new URL(window.location.href).search}`,
}))`
  color: ${COLOR_13};
  text-decoration: underline;
`;

const TermsAndConditionsLabel = styled(Label)`
  width: 300px !important;
  font-size: 15px;

  ${({ isSignUp }) =>
    isSignUp &&
    css`
      margin-top: 16px;
    `}

  @media (max-width: 370px) {
    width: 280px !important;
  }
`;

const TermsAndConditionsLink = styled(SignUpLink).attrs(() => ({
  as: "a",
  href: pathsConstants.TERMS_AND_CONDITIONS,
  target: "_blank",
}))`
  color: ${COLOR_1};
`;

const LogInLink = styled(SignUpLink).attrs(() => ({
  to: `${pathsConstants.AUTHENTICATION}/${pathsConstants.AUTHENTICATION_PARAMS.LOGIN}`,
}))``;

const BackToLoginLink = styled(ForgotPasswordLink).attrs(() => ({
  to: `${pathsConstants.AUTHENTICATION}/${AUTHENTICATION_PARAMS.LOGIN}${new URL(window.location.href).search}`,
}))``;

const BackToHomeLink = styled(ForgotPasswordLink).attrs(() => ({
  to: `${!window.location.href.includes('?url=') ? pathsConstants.HOME : new URL(window.location.href).searchParams.get('url')}`,
}))`
  display: flex;
  align-items: center;
  font-size: 14px;

  > svg {
    margin-right: 8px;
  }
`;

export default Authentication;
