
import httpClient from '../../clients/http/http.client';
import ReportConfig from '../../models/report-config/report-config.model';
import RealEstate from '../../models/real-estate/real-estate.model';
import Address from '../../models/address/address.model';
import Geolocation from '../../models/geolocation/geolocation.model';
import Feature, { GROUP_TYPES } from '../../models/feature/feature.model';
import Image, { TYPES as IMAGE_TYPES } from '../../models/image/image.model';
import Price from '../../models/price/price.model';
import Koortimativa from '../../models/koortimativa/koortimativa.model';
import SurroundingPlace from '../../models/surrounding-place/surrounding-place.model';

const get = async ({
  token,
}) => {
  
  const response = await httpClient.get({
    path: '/reports/list-user-reports',
    token,
  });

  const result = response.data.map(r => {
    const { prices, development, types, address, koortimativa, near } = r.report;
    
    // TODO: Still missing mappings.
    // Do it as needed.
    const mapped = new ReportConfig({
      ...response.data,
      ...r.report,
      ...prices,
      ...development,
      ...types,
      ...address,
      id: r._id,
      adId: r.adId,
      panoramas: r.report.tour360,
      name: r.report.name,
      subtitle: r.report.subTitle,
      koortimativaRange: koortimativa.range,
      koortimativaValue: koortimativa.value,

      surroundingPlaceType01: near.near_01,
      surroundingPlaceType02: near.near_02,
      surroundingPlaceType03: near.near_03,
      surroundingPlaceType04: near.near_04,
      surroundingPlaceType05: near.near_05,
      surroundingPlaceType06: near.near_06,
      surroundingPlaceType07: near.near_07,
      surroundingPlaceType08: near.near_08,
      surroundingPlaceType09: near.near_09,
      surroundingPlaceType10: near.near_10,
      surroundingPlaceType11: near.near_11,
      surroundingPlaceType12: near.near_12,
    });

    return mapped;
  });

  return result;
};

const getById = async ({
  reportId,
  token,
}) => {

  const response = await httpClient.get({
    path: `/reports/${ reportId }`,
    token,
  });
  
  const result = new ReportConfig({
    ...response.data,
    ...response.data.report,
    ...response.data.report.prices,
    ...response.data.report.development,
    ...response.data.report.types,
    ...response.data.report.address,
    id: response.data._id,
    adId: response.data.adId,
    panoramas: response.data.report.tour360,
    subtitle: response.data.report.subTitle,
    name: response.data.report.name,
    koortimativaRange: response.data.report.koortimativa.range,
    koortimativaValue: response.data.report.koortimativa.value,

    surroundingPlaceType01: response.data.report.near.near_01,
    surroundingPlaceType02: response.data.report.near.near_02,
    surroundingPlaceType03: response.data.report.near.near_03,
    surroundingPlaceType04: response.data.report.near.near_04,
    surroundingPlaceType05: response.data.report.near.near_05,
    surroundingPlaceType06: response.data.report.near.near_06,
    surroundingPlaceType07: response.data.report.near.near_07,
    surroundingPlaceType08: response.data.report.near.near_08,
    surroundingPlaceType09: response.data.report.near.near_09,
    surroundingPlaceType10: response.data.report.near.near_10,
    surroundingPlaceType11: response.data.report.near.near_11,
    surroundingPlaceType12: response.data.report.near.near_12,
  });

  return result;
};

const getByIdPublic = async ({
  reportId,
  token,
}) => {

  const response = await httpClient.get({
    path: `/reports/get-report/${ reportId }`,
    token,
  });
  
  const {
    _id,
    ads,
    near,
    realEstate,
  } = response.data;
  const { ad, ad_m, ad_g, floor_plan, tour360 } = ads.images;
  const { features } = realEstate;
  
  const result = new RealEstate({
    ...ads,
    ...ads.development,
    ...realEstate,
    ...ads.types,
    ...realEstate.types,
    area: (realEstate.area || [ ])[0],
    bathrooms: (realEstate.bathrooms || [ ])[0],
    bedrooms: (realEstate.bedrooms || [ ])[0],
    description: ads.description,
    id: _id,
    realEstateId: _id,
    parkingSpaces: (realEstate.parkingSpaces || [ ])[0],
    subtitle: ads.subTitle,
    suites: (realEstate.suites || [ ])[0],
    petAcceptance: !!realEstate.features.condo.find(f => {
      return f.toLowerCase() === 'aceita pet';
    }),
    address: new Address({
      ...realEstate.address,
      number: realEstate.address.streetNumber,
      geolocation: realEstate.address.location ? new Geolocation({
        ...realEstate.address.location,
        latitude: realEstate.address.location.coordinates[1],
        longitude: realEstate.address.location.coordinates[0],
      }) : null,
    }),

    features: (features.near || [ ]).map(l => new Feature({
      label: l,
      group: GROUP_TYPES.SURROUNDINGS,
    })).concat((features.condo || [ ]).map(l => new Feature({
      label: l,
      group: GROUP_TYPES.CONDO,
    }))).concat((features.property || [ ]).map(l => new Feature({
      label: l,
      group: GROUP_TYPES.PROPERTY,
    }))),

    images: (ad || [ ]).map(url => new Image({
      url: url.replace('{width}', '1024'),
      size: 'p',
      type: IMAGE_TYPES.PICTURE,
    })).concat((ad_m || [ ]).map(url => new Image({
      url: url.replace('{width}', '1024'),
      size: 'm',
      type: IMAGE_TYPES.PICTURE,
    }))).concat((ad_g || [ ]).map(url => new Image({
      url: url.replace('{width}', '1024'),
      size: 'g',
      type: IMAGE_TYPES.PICTURE,
    }))).concat((floor_plan || [ ]).map(url => new Image({
      url: url.replace('{width}', '1024'),
      type: IMAGE_TYPES.FLOOR_PLAN,
    }))).concat((tour360 || [ ]).map(url => new Image({
      url: url.replace('{width}', '1024'),
      type: IMAGE_TYPES.PANORAMA,
    }))),
    price: new Price({
      ...ads.prices,
      main: (ads.prices.main || [ ])[0],
    }),
    koortimativa: new Koortimativa({
      ...ads.koortimativa,
      rangeStart: ads.koortimativa.range[0],
      rangeEnd: ads.koortimativa.range[1],
    }),
    // TODO: Map the surrundings list when available.
    // publisher: new Publisher({
    //   ...user,
    //   id: user._id,
    // }),
    surroundingPlaces: (() => {
      const list = [ ];
      near.forEach(category => {
        if(!category) return;
        category.points.forEach(point => {
          list.push(new SurroundingPlace({
            ...point,
            id: point._id,
            formattedAddress: point.address,
            subCategory: point.type,
            category: category.name, //.toLowerCase(),
            categoryId: category.id,
            geolocation: new Geolocation({
              latitude: point.lat,
              longitude: point.lng,
            }),
          }));
        });
      });

      return list;
    })(),
  });

  return result;
};

const create = async ({
  token,
  realEstateId,
  obj
}) => {
  let params = {
    "report": {
      "area": obj.area,
      "bathrooms": obj.bathrooms,
      "bedrooms": obj.bedrooms,
      "description": obj.description,
      "name": obj.name,
      "parkingSpaces": obj.parkingSpaces,
      "subTitle": obj.subtitle,
      "suites": obj.suites,
      "title": obj.title,
      "typeOfGuarantee": obj.typeOfGuarantee,
      "video": obj.video,
      "yearBuilt": obj.yearBuilt,
  
      "koortimativa": {
        value: true,
        range: true,
      },
      "near": {
        "near_01": true,
        "near_02": true,
        "near_03": true,
        "near_04": true,
        "near_05": true,
        "near_06": true,
        "near_07": true,
        "near_08": true,
        "near_09": true,
        "near_10": true,
        "near_11": true,
        "near_12": true
      },
      "types": {
        "transaction": true,
        "listing": true,
        "use": true,
        "property": true
      },
      "address": {
        "street": obj.street,
        "streetNumber": obj.streetNumber,
        "unitNumber": obj.unitNumber,
        "floor": obj.floor,
        "complement": obj.complement,
        "neighborhood": obj.neighborhood,
        "city": obj.city,
        "state": obj.state,
        "zipCode": obj.zipCode,
        "location": obj.location,
      },
      "features": {
        "property": ["Ambientes Integrados", "Armários Planejados", "Closet", "Cozinha Americana", "Gás Encanado", "Acessibilidade", "Mobiliado", "Piscina", "Sauna", "Churrasqueira", "Área de Serviço", "Quarto/WC Empregada", "Terraço", "Varanda", "Vista Panorâmica"],
        "condo": ["Academia", "Aceita Pet", "Bicicletário", "Churrasqueira", "Comércio/Serviços no Condomínio", "Depósito Privativo", "Elevador", "Estacionamento Coberto", "Estacionamento Visitantes", "Piscina", "Sauna", "Quadra Poliesportiva", "Playground", "Salão de Festas", "Salão de Jogos", "Porteiro 24 Horas", "Segurança Interna", "Serviço de Transporte no Condomínio", "Sistema de Incêndio", "Acessibilidade"],
        "near": ["Próximo a Áreas de Lazer", "Próximo a Parques e Áreas Verdes", "Próximo a Praia", "Próximo ao Metrô", "Próximo a Mercado", "Próximo a Farmácia", "Próximo a Academia", "Próximo a Escola Pública", "Próximo a Escola Particular", "Próximo ao Shopping", "Próximo a Restaurante", "Próximo a Centro Empresarial", "Próximo a Serviços de Saúde", "Próximo a Comércio Diversos"]
      },
      "development": {
        "code": obj.code,
        "name": obj.Name,
        "logo": obj.logo,
        "constructionStatus": obj.constructionStatus,
        "buildings": obj.buildings,
        "floors": obj.floors,
        "highlights": obj.highlights,
      },
      "prices": {
        "main": obj.main,
        "sale": obj.sale,
        "rental": obj.rental,
        "condo": obj.condo,
        "iptu": obj.iptu,
      },
    },
  };
  const response = await httpClient.post({
    path: `/reports/${ realEstateId }`,
    token,
    params,
  });

  const result = new ReportConfig({
    ...response.data,
    ...response.data.report,
    ...response.data.report.prices,
    ...response.data.report.development,
    ...response.data.report.types,
    ...response.data.report.address,
    id: response.data._id,
    adId: response.data.adId,
    panoramas: response.data.report.tour360,
    subtitle: response.data.report.subTitle,
    name: response.data.report.name,
    koortimativaRange: response.data.report.koortimativa.range,
    koortimativaValue: response.data.report.koortimativa.value,

    surroundingPlaceType01: response.data.report.near.near_01,
    surroundingPlaceType02: response.data.report.near.near_02,
    surroundingPlaceType03: response.data.report.near.near_03,
    surroundingPlaceType04: response.data.report.near.near_04,
    surroundingPlaceType05: response.data.report.near.near_05,
    surroundingPlaceType06: response.data.report.near.near_06,
    surroundingPlaceType07: response.data.report.near.near_07,
    surroundingPlaceType08: response.data.report.near.near_08,
    surroundingPlaceType09: response.data.report.near.near_09,
    surroundingPlaceType10: response.data.report.near.near_10,
    surroundingPlaceType11: response.data.report.near.near_11,
    surroundingPlaceType12: response.data.report.near.near_12,
  });

  return result;
};

const update = async ({
  token,
  reportId,
  ...obj
}) => {
  const params = {
    // report: {
    //   name: obj.reportTitle,
    //   near: {
    //     near_01: true,
    //     near_02: true,
    //     near_03: true,
    //     near_04: true,
    //     near_05: true,
    //     near_06: true,
    //     near_07: true,
    //     near_08: true,
    //     near_09: true,
    //     near_10: true,
    //     near_11: true,
    //     near_12: true,
    //   },
    //   development: {
    //     code: obj.code,
    //     name: obj.name,
    //     logo: obj.logo,
    //     constructionStatus: obj.constructionStatus,
    //     buildings: obj.buildings,
    //     floors: obj.floors,
    //     highlights: obj.highlights,
    //   },
    //   prices: {
    //     main: obj.main,
    //     sale: obj.sale,
    //     rental: obj.rental,
    //     condo: obj.condo,
    //     iptu: obj.iptu,
    //   },
    //   typeOfGuarantee: obj.typeOfGuarantee,
    //   area: obj.area,
    //   bathrooms: obj.bathrooms,
    //   bedrooms: obj.bedrooms,
    //   description: obj.description,
    //   parkingSpaces: obj.parkingSpaces,
    //   subTitle: obj.subtitle,
    //   suites: obj.suites,
    //   title: obj.title,
    //   video: obj.video,
    //   yearBuilt: obj.yearBuilt,
  
    //   koortimativa: {
    //     value: obj.koortimativa.value,
    //     range: obj.koortimativa.range,
    //   },
    //   types: {
    //     transaction: obj.transaction,
    //     listing: obj.listing,
    //     use: obj.use,
    //     property: obj.property,
    //   },
    //   address: {
    //     street: obj.street,
    //     streetNumber: obj.number,
    //     unitNumber: true,
    //     floor: obj.floor,
    //     complement: obj.complement,
    //     neighborhood: obj.neighborhood,
    //     city: obj.city,
    //     state: obj.state,
    //     zipCode: obj.zipCode,
    //     location: obj.location,
    //   },
    //   features: {
    //     property: obj.features.property,
    //     condo: obj.features.condo,
    //     near: obj.features.near,
    //   },
    // },
    "report": {
      "name": "Relatório de teste atualizado",
      "near": {
        "near_01": false,
        "near_02": false,
        "near_03": false,
        "near_04": false,
        "near_08": false,
        "near_09": false,
        "near_10": false,
        "near_11": false,
        "near_12": false
      },
      "development": {
        "highlights": false
      },
      "prices": {
        "rental": false,
        "iptu": false
      },
      "typeOfGuarantee": false,
      "images": {
        "ad": [
          "https://storage.googleapis.com/koort-ads-images/5d1e1a5b024ba44868bc09b7/koort.img.002.jpg",
          "https://storage.googleapis.com/koort-ads-images/5d1e1a5b024ba44868bc09b7/koort.img.010.jpg",
          "https://storage.googleapis.com/koort-ads-images/5d1e1a5b024ba44868bc09b7/koort.img.011.jpg",
          "https://storage.googleapis.com/koort-ads-images/5d1e1a5b024ba44868bc09b7/koort.img.012.jpg",
          "https://storage.googleapis.com/koort-ads-images/5d1e1a5b024ba44868bc09b7/koort.img.013.jpg",
          "https://storage.googleapis.com/koort-ads-images/5d1e1a5b024ba44868bc09b7/koort.img.014.jpg",
          "https://storage.googleapis.com/koort-ads-images/5d1e1a5b024ba44868bc09b7/koort.img.015.jpg",
          "https://storage.googleapis.com/koort-ads-images/5d1e1a5b024ba44868bc09b7/koort.img.016.jpg",
          "https://storage.googleapis.com/koort-ads-images/5d1e1a5b024ba44868bc09b7/koort.img.017.jpg",
          "https://storage.googleapis.com/koort-ads-images/5d1e1a5b024ba44868bc09b7/koort.img.029.jpg"
        ]
      },
      "title": "Oportunidade editada",
      "subTitle": "Apartamento 300 quartos no Leblon"
    }
  };

  const result = await httpClient.patch({
    path: `/reports/${ reportId }`,
    token,
    params,
  });

  return result;
};

const del = async ({
  reportId,
  token,
}) => {
  
  const response = await httpClient.patch({
    path: `/reports/delete/${ reportId }`,
    token,
    options: {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  });

  return response.data;
}

export default {
  get,
  getById,
  getByIdPublic,
  update,
  create,
  del,
};
