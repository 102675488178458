import styled from 'styled-components';

import { BaseLabel } from '../../styled/labels/labels';
import { BaseButton } from '../../styled/buttons/buttons';
import { mqTablet } from '../../styled/helpers/helpers';

export const SendButton = styled(BaseButton).attrs({
  type: 'submit'
})`
  margin-top: 15px;
  width: 100%;
`;

export const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  padding-top: 120px;
  justify-content:center;
  align-items:center;
  align-self:center;

  padding-bottom: 25px;
  ${mqTablet`
    width: 700px;
    padding-top: 140px
    padding-bottom: 260px
  ` };
`;

export const Title = styled(BaseLabel)`
  align-self: center;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 20px;
`;

export const Subtitle = styled(BaseLabel)`
  align-self: center;
  text-align: center;
  max-width: 700px;
  font-size: 14px;
  margin-bottom: 20px;
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 540px;

  ${mqTablet`
    align-self: center;
  ` }
`;
