import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { RiCloseLine } from 'react-icons/ri';

import AdOwner from './ad-owner';
import { ContactFormFields } from './contact-form-fields';

import {
  Wrapper,
  CFDivider,
  FormCall,
  CallToActionButton,
  ModalContent,
  ModalBody,
  ContactFormModal
} from './styles';

export function ContactForm({
  publisher,
  isListing,
  contact,
  listingId,
  isIncorporation,
  incorporationInfo,
  isRelease,
}) {
  const [modalVisible, setModalVisible] = useState(false);

  function handleOpenModal() {
    setModalVisible(true);
  }

  function handleCloseModal() {
    setModalVisible(false);
  }

  return (
    <>
    <Wrapper>
      <AdOwner
        {...publisher}
        isListing={isListing}
        contact={contact}
        email={publisher.emails[0]}
        isIncorporation={isIncorporation}
        incorporationInfo={incorporationInfo}
      />

      <CallToActionButton type="button" onClick={handleOpenModal} id="call-to-action-button">
        <strong>Falar com executivo de vendas</strong>
      </CallToActionButton>
      {/* <CFDivider /> */}

      {/* <FormCall>Fale com {isIncorporation || (!!publisher && publisher.type === 'INCORPORADORA') ? 'executivo de vendas' : 'o corretor'}</FormCall> */}
    </Wrapper>

    <Modal
        isOpen={modalVisible}
        style={{
          content: {
            backgroundColor: 'transparent',
            border: 0,
            padding: 0,
            inset: 0,
            borderRadius: 0,
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.15)'
          }
        }}
      >
        <ModalContent>
          <ModalBody>
            <ContactFormModal>
              <header>
                <h3>Acesse condições exclusivas junto ao executivo de vendas</h3>

                <button type="button" onClick={handleCloseModal}>
                  <RiCloseLine size={26} />
                </button>
              </header>

              <p>
                O Koort é o seu parceiro confiável na busca pelo imóvel perfeito. Aproveite esta oportunidade única e
                transforme este anúncio em realidade. Não espere mais, preencha o formulário agora e comece sua nova jornada!
              </p>

              <ContactFormFields
                listingId={listingId}
                onSuccess={handleCloseModal}
              />
            </ContactFormModal>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
