import React, { PureComponent } from 'react';
import styled from 'styled-components';

import ImageDropGallery from '../../../../components//image-drop-gallery';


class Panoramas extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      form: {
        panoramas: [ ],
      },
    };
  }

  handleImageDropGalleryUpdate = panoramas => {
    this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        panoramas,
      },
    }));
  };

  render() {
    const { panorama } = this.state;

    return (
      <Wrapper>
        <PImageDropGallery
          onUpdate={ this.handleImageDropGalleryUpdate }
          show={ panorama }
          isPanorama
        />
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  margin-bottom: 50px;
`;

const PImageDropGallery = styled(ImageDropGallery)``;

export default Panoramas;
