import React from 'react';
import LazyLoad from 'react-lazyload';

import StatsBar from '../../../../../ads/card/stats-bar/StatsBar';
import ViewAdLink from "../../../../../ads/card/buttons/ViewAdLink";
import { DetachBrokerButton } from './buttons/DetachBrokerButton';

import currencyUtil from '../../../../../../../utils/currency/currency.util';
import { Features } from '../../../../../../search-detail/features';
import pathsConstants from '../../../../../../../constants/paths';

import {
  Wrapper,
  PictureWrapper,
  Picture,
  TabletRightSideWrapper,
  PropertyLabel,
  InfoWrapper,
  TagBadge,
  Title,
  Neighborhood,
  FirstSectionDivider,
  SecondSectionDivider,
} from './styles';

export function Card({ realEstate, onDetachPropertyToBroker }) {
  const picture = realEstate.ads.images.ad[0].replace("{width}", "1024");

  return (
    <Wrapper>
      <PictureWrapper>
        <LazyLoad>
          <Picture
            url={
              !!picture ? picture : `${process.env.PUBLIC_URL}/imgs/ad-example.png`
            }
          />
        </LazyLoad>
        <StatsBar
          views={!!realEstate.ads.accessStatistics ? realEstate.ads.accessStatistics.views.total : 0}
          messages={!!realEstate.ads.accessStatistics ? realEstate.ads.accessStatistics.messages.total : 0}
          favorites={!!realEstate.ads.accessStatistics ? realEstate.ads.accessStatistics.favorites.total : 0}
          shares={!!realEstate.ads.accessStatistics ? realEstate.ads.accessStatistics.shares.total : 0}
        />
      </PictureWrapper>
      <TabletRightSideWrapper>
        <InfoWrapper>
          <PropertyLabel>
            <TagBadge>
              {realEstate.ads.types.transaction.toUpperCase()}
            </TagBadge>

            <TagBadge>
              {realEstate.realEstate.types.property.toUpperCase()}
            </TagBadge>

            {realEstate.isPublished && <TagBadge>ANUNCIADO</TagBadge>}
          </PropertyLabel>
          <Title>
            {`${currencyUtil.format({ value: realEstate.ads.prices.main, })}`}
          </Title>
          <Neighborhood>
            {realEstate.realEstate.address.city} - {realEstate.realEstate.address.neighborhood}
          </Neighborhood>
          <FirstSectionDivider />
          <Features
            bedrooms={realEstate.realEstate.bedrooms}
            area={realEstate.realEstate.area}
            parkingSpaces={realEstate.realEstate.parkingSpaces}
            bathrooms={realEstate.realEstate.bathrooms}
            petAcceptance={realEstate.realEstate.features.condo.includes('Aceita Pet')}
          />
          <SecondSectionDivider />

          <DetachBrokerButton
            onDetachPropertyToBroker={() => onDetachPropertyToBroker(realEstate._id)}
          />

          <ViewAdLink to={`${pathsConstants.AD_DETAIL}/${realEstate.slug}?inDashboard=true`} />
        </InfoWrapper>
      </TabletRightSideWrapper>
    </Wrapper>
  );
}
