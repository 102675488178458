/* eslint-disable no-const-assign */
import React, { PureComponent } from 'react';
import { Heart } from 'styled-icons/boxicons-regular/Heart';
import { KeyboardArrowLeft } from 'styled-icons/material/KeyboardArrowLeft';
import { KeyboardArrowRight } from 'styled-icons/material/KeyboardArrowRight';
import styled, { css } from 'styled-components';
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { transitions } from 'polished';
import { toast } from 'react-toastify';
import Select from 'react-select';

import { BaseButton } from '../../styled/buttons/buttons';
import { BaseLabel } from '../../styled/labels/labels';
import { COLOR_4, COLOR_2, COLOR_1 } from '../../styled/variables/variables';
import { Divider } from '../../styled/components/components';
import { iconBaseStyle, mqTablet, mqDesktop, mqCustom, maxMqCustom, maxMqTablet, pointer } from '../../styled/helpers/helpers';
import './favorites.css'

import NoResults from '../../components/no-results';
import Tabs from "../../components/tabs";
import Cover from '../profile/cover/Cover';
import Info from './info/Info';

import { TYPES as IMAGE_TYPES } from '../../models/image/image.model';
import Ad from './ad/Ad';

import domEventsUtil from '../../utils/dom-events/dom-events.util';
import eventsConstants from '../../constants/events';
import eventConstants from '../../constants/events';

import listingService from '../../services/listing/listing.service';
import userFavoriteService from '../../services/user/favorite/user.favorite.service';
import userService from '../../services/user/user.service';
import userProfileService from '../../services/user-profile/user-profile.service';
import regionsService from '../../services/regions/regions.service';

import pathsConstants from "../../constants/paths";
import { InputBootstrap } from '../../components/form/input-bootstrap';
import constantsService from '../../services/constants/constants.service';
import { el } from 'date-fns/locale';

/*
  global
  alert
  window
*/
const customId = "custom-id-yes";
class Favorites extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      user: userService.retrieve(),
      citiesHasLoadedOnDidMount: false,
      minValueParkingSpaces: '',
      maxValueParkingSpaces: '',
      minValueSuites: '',
      maxValueSuites: '',
      minValueBedrooms: '',
      maxValueBedrooms: '',
      minValueBathrooms: '',
      maxValueBathrooms: '',

      minValueParkingSpacesErrorMessage: '',
      minValueSuitesErrorMessage: '',
      minValueBedroomsErrorMessage: '',
      minValueBathroomsErrorMessage: '',

      ads: [],
      states: [],
      cities: [],
      neighborhoods: [],
      preferences: null,
      preferences_use: [],
      preferences_transaction: [],
      preferences_listing: [],
      preferences_state: [],
      preferences_city: [],
      preferences_neighborhood: [],
      preferences_condo: [],
      preferences_near: [],
      preferences_typeOfGuarantee: [],
      preferences_optionsfproperty: [],
      preferences_property: [],
      preferences_ok: false,
      comparing: [],
      page: 1,
      pageSize: 1,
      profile: null,
      optionsfproperty: [],
      optionscondo: [],
      optionsnear: [],
      constantsFeaturesData: {
        CONDO: [],
        NEAR: [],
        PROPERTY: [],
      }
    };
  }


  getCities = async () => {
    const token = userService.retrieve().token;

    const { data: statesData } = await regionsService.getStates({ token });

    const statesDataSerialized = statesData.map(item => {
      return {
        label: item.name,
        value: item.name,
      }
    });

    this.setState(prevState => ({
      ...prevState,
      states: statesDataSerialized,
    }));
  }

  componentDidMount() {
    window.addEventListener('resize', this.adaptList);

    this.getProfile();
    this.getFavorites();
    this.adaptList();
    this.getPreferences();
    this.getCities();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.adaptList);
  }

  getConstantesFeatures = async () => {
    try {
      const { data: responseData, status } = await constantsService.getRealEstatesFeatures();

      if (status !== 200) {
        throw new Error('Não foi possível buscar as características. Recarregue a página!');
      }

      const { data: { CONDO, NEAR, PROPERTY } } = responseData;

      const featuresMapped = {
        CONDO: CONDO.map(item => {
          return {
            ...item,
            label: item.value,
            value: item.key,
          }
        }),
        NEAR: NEAR.map(item => {
          return {
            ...item,
            label: item.value,
            value: item.key,
          }
        }),
        PROPERTY: PROPERTY.map(item => {
          return {
            ...item,
            label: item.value,
            value: item.key,
          }
        }),
      };

      this.setState(prevState => ({
        ...prevState,
        constantsFeaturesData: featuresMapped,
      }));
    } catch (err) {
      toast.error('Não foi possível buscar as características. Recarregue a página!', {
        autoClose: false,
      });
    }
  }


  adaptList = () => {
    const { pageSize } = this.state;
    const { innerWidth } = window;
    if (pageSize !== 1 && innerWidth < 620) {
      this.setState(state => ({
        ...state,
        pageSize: 1,
        page: 1,
      }));
    } else if (pageSize !== 2 && innerWidth >= 620 && innerWidth < 768) {
      this.setState(state => ({
        ...state,
        pageSize: 2,
        page: 1,
      }));
    } else if (pageSize !== 3 && innerWidth >= 768 && innerWidth <= 1080) {
      this.setState(state => ({
        ...state,
        pageSize: 3,
        page: 1,
      }));
    } else if (pageSize !== 4 && innerWidth > 1080) {
      this.setState(state => ({
        ...state,
        pageSize: 4,
        page: 1,
      }));
    }
  };

  getPreferences = async () => {
    const { user } = this.state;

    try {
      const { data: constantsResponseData, status } = await constantsService.getRealEstatesFeatures();

      if (status !== 200) {
        throw new Error('Não foi possível buscar as características. Recarregue a página!');
      }

      const { data: { CONDO, NEAR, PROPERTY } } = constantsResponseData;

      const featuresMapped = {
        CONDO: CONDO.map(item => {
          return {
            ...item,
            label: item.value,
            value: item.key,
          }
        }),
        NEAR: NEAR.map(item => {
          return {
            ...item,
            label: item.value,
            value: item.key,
          }
        }),
        PROPERTY: PROPERTY.map(item => {
          return {
            ...item,
            label: item.value,
            value: item.key,
          }
        }),
      };

      let result = await userService.getPreferences(user.token);
      this.setState(state => ({
        ...state,
        preferences: result.preferences,
      }));

      let use = [];
      let transactions = [];
      let listing = [];
      let state = [];
      let city = [];
      let neighborhood = [];
      let condo = [];
      let near = [];
      let typeOfGuarantee = [];
      let fproperty = [];
      let property = [];

      for (let i = 0; i < result.preferences.types.use.length; i++) {
        use.push({ label: result.preferences.types.use[i], value: result.preferences.types.use[i] })
      }

      for (let i = 0; i < result.preferences.types.transaction.length; i++) {
        transactions.push({ label: result.preferences.types.transaction[i], value: result.preferences.types.transaction[i] })
      }

      for (let i = 0; i < result.preferences.types.listing.length; i++) {
        listing.push({ label: result.preferences.types.listing[i], value: result.preferences.types.listing[i] })
      }

      for (let i = 0; i < result.preferences.types.property.length; i++) {
        property.push({ label: result.preferences.types.property[i], value: result.preferences.types.property[i] })
      }

      for (let i = 0; i < result.preferences.state.length; i++) {
        state.push({ label: result.preferences.state[i], value: result.preferences.state[i] })
      }

      for (let i = 0; i < result.preferences.city.length; i++) {
        city.push({ label: result.preferences.city[i], value: result.preferences.city[i] })
      }

      for (let i = 0; i < result.preferences.features.condo.length; i++) {
        condo.push({ label: result.preferences.features.condo[i].value, value: result.preferences.features.condo[i].key })
      }

      for (let i = 0; i < result.preferences.features.property.length; i++) {
        fproperty.push({ label: result.preferences.features.property[i].value, value: result.preferences.features.property[i].key })
      }

      for (let i = 0; i < result.preferences.features.near.length; i++) {
        near.push({ label: result.preferences.features.near[i].value, value: result.preferences.features.near[i].key })
      }

      for (let i = 0; i < result.preferences.typeOfGuarantee.length; i++) {
        typeOfGuarantee.push({ label: result.preferences.typeOfGuarantee[i], value: result.preferences.typeOfGuarantee[i] })
      }

      if (!!result.preferences.parkingSpaces.length) {
        this.setState(prevState => ({
          ...prevState,
          minValueParkingSpaces: result.preferences.parkingSpaces[0],
          maxValueParkingSpaces: result.preferences.parkingSpaces[1],
        }));
      }

      if (!!result.preferences.suites.length) {
        this.setState(prevState => ({
          ...prevState,
          minValueSuites: result.preferences.suites[0],
          maxValueSuites: result.preferences.suites[1],
        }));
      }

      if (!!result.preferences.bathrooms.length) {
        this.setState(prevState => ({
          ...prevState,
          minValueBathrooms: result.preferences.bathrooms[0],
          maxValueBathrooms: result.preferences.bathrooms[1],
        }));
      }

      if (!!result.preferences.bedrooms.length) {
        this.setState(prevState => ({
          ...prevState,
          minValueBedrooms: result.preferences.bedrooms[0],
          maxValueBedrooms: result.preferences.bedrooms[1],
        }));
      }

      this.setState(prevState => ({
        ...prevState,
        constantsFeaturesData: featuresMapped,
        optionsfproperty: fproperty.map(item => item.value),
        optionscondo: condo.map(item => item.value),
        optionsnear: near.map(item => item.value),
        preferences_use: use,
        preferences_transaction: transactions,
        preferences_listing: listing,
        preferences_state: state,
        preferences_city: city,
        preferences_neighborhood: neighborhood,
        preferences_condo: condo,
        preferences_optionsfproperty: fproperty,
        preferences_typeOfGuarantee: typeOfGuarantee,
        preferences_near: near,
        preferences_property: property,
        preferences_ok: true,
      }));


    } catch (error) {
      toast.error('Erro buscando preferências.', {
        autoClose: 5000,
        toastId: customId,
      })
    }
  }

  validateValuesMinimumAndMaximum() {
    let hasValidationError = false;

    if (!this.state.minValueParkingSpaces && !!this.state.maxValueParkingSpaces) {
      this.setState(prevState => ({
        ...prevState,
        minValueParkingSpacesErrorMessage: 'Obrigatório'
      }));

      hasValidationError = true;
    }

    if (!this.state.minValueSuites && !!this.state.maxValueSuites) {
      this.setState(prevState => ({
        ...prevState,
        minValueSuitesErrorMessage: 'Obrigatório'
      }));

      hasValidationError = true;
    }

    if (!this.state.minValueBedrooms && !!this.state.maxValueBedrooms) {
      this.setState(prevState => ({
        ...prevState,
        minValueBedroomsErrorMessage: 'Obrigatório'
      }));

      hasValidationError = true;
    }

    if (!this.state.minValueBathrooms && !!this.state.maxValueBathrooms) {
      this.setState(prevState => ({
        ...prevState,
        minValueBathroomsErrorMessage: 'Obrigatório'
      }));

      hasValidationError = true;
    }

    if (Number(this.state.minValueParkingSpaces) > Number(this.state.maxValueParkingSpaces)) {
      this.setState(prevState => ({
        ...prevState,
        minValueParkingSpacesErrorMessage: 'Valor mínimo deve ser menor que valor máximo'
      }));

      hasValidationError = true;
    }

    if (Number(this.state.minValueSuites) > Number(this.state.maxValueSuites)) {
      this.setState(prevState => ({
        ...prevState,
        minValueSuitesErrorMessage: 'Valor mínimo deve ser menor que valor máximo'
      }));

      hasValidationError = true;
    }

    if (Number(this.state.minValueBedrooms) > Number(this.state.maxValueBedrooms)) {
      this.setState(prevState => ({
        ...prevState,
        minValueBedroomsErrorMessage: 'Valor mínimo deve ser menor que valor máximo'
      }));

      hasValidationError = true;
    }

    if (Number(this.state.minValueBathrooms) > Number(this.state.maxValueBathrooms)) {
      this.setState(prevState => ({
        ...prevState,
        minValueBathroomsErrorMessage: 'Valor mínimo deve ser menor que valor máximo'
      }));

      hasValidationError = true;
    }

    return { hasValidationError };
  }

  savePreferences = async () => {
    const { hasValidationError } = this.validateValuesMinimumAndMaximum();

    if (hasValidationError) {
      return;
    }

    this.setState(prevState => ({
      ...prevState,
      minValueParkingSpacesErrorMessage: '',
      minValueSuitesErrorMessage: '',
      minValueBedroomsErrorMessage: '',
      minValueBathroomsErrorMessage: '',
    }));

    domEventsUtil.dispatch({
      name: eventConstants.LOADING_ANIMATION,
      params: { show: true, },
    });

    const { user } = this.state;
    const customId = "custom-id-yes";

    let r = await userService.savePreferences(this.state, user.token);
    if (r == "Ok") {
      this.componentDidMount();

      toast.info('Preferências salvas com sucesso.', {
        autoClose: 5000,
        toastId: customId,

      });
    }
    domEventsUtil.dispatch({
      name: eventConstants.LOADING_ANIMATION,
      params: { show: false, },
    });
  }

  getCitiesOnDidMount = async () => {
    const token = userService.retrieve().token;
    let citiesData = [];

    for await (const state of this.state.preferences_state) {
      const { data } = await regionsService.getCitiesByState({
        state: state.value,
        token,
      });


      const dataSerialized = data.map(item => {
        return {
          ...item,
          label: item.name,
          value: item.name,
        }
      });

      citiesData.push(...dataSerialized);
    }

    this.setState(prevState => ({
      ...prevState,
      cities: citiesData,
      citiesHasLoadedOnDidMount: true,
    }));
  }

  componentDidUpdate() {
    const { preferences_state, citiesHasLoadedOnDidMount } = this.state;

    // Buscando cidades relacionadas aos estados salvos nas preferências
    // Nota: citiesHasLoadedOnDidMount serve para evitar loop na requisição
    if (preferences_state.length && !citiesHasLoadedOnDidMount) {
      this.getCitiesOnDidMount();
    }
  }

  getFavorites = async () => {
    domEventsUtil.dispatch({
      name: eventConstants.LOADING_ANIMATION,
      params: { show: true, },
    });

    const { user } = this.state;

    let ids;
    try {
      ids = await userFavoriteService.get({ token: user.token })
    } catch (error) {
      toast.error('Erro buscando favoritos.', {
        autoClose: false,
        toastId: customId,
      });
      return;
    } finally {
      domEventsUtil.dispatch({
        name: eventConstants.LOADING_ANIMATION,
        params: { show: false, },
      });
    }

    let ads = [];
    if (ids && ids.length > 0) {
      try {
        ads = await listingService.get({
          ids,
        });
      } catch (error) {
        toast.error('Erro buscando imóveis.', {
          autoClose: false,
          toastId: customId,
        });
        return;
      } finally {
        domEventsUtil.dispatch({
          name: eventConstants.LOADING_ANIMATION,
          params: { show: false, },
        });
      }
    }

    this.setState(state => ({
      ...state,
      ads,
    }));
  };

  handleRealEstateCompareClick = async realEstate => {
    const { comparing } = this.state;

    const newComparing = [...comparing];

    const indexFound = newComparing.findIndex(c => c.id === realEstate.id);
    if (indexFound > -1) {
      newComparing.splice(indexFound, 1);
    } else {
      const toPush = await listingService.getById({
        listingId: realEstate.listingId,
      });
      newComparing.push(toPush);
    }

    if (newComparing.length > 2) {
      newComparing.splice(0, 1);
    }

    if (newComparing.length > 1) {
      document.getElementById('div-compare').scrollIntoView({ block: "center", inline: "nearest" });
    }

    this.setState(state => ({
      ...state,
      comparing: newComparing,
    }));
  };

  handlePaginationClick = ({ next = true }) => {
    const { ads, page, pageSize } = this.state;
    const totalPages = Math.ceil(ads.length / pageSize);
    let newPage = next ? page + 1 : page - 1;

    if (newPage < 0) newPage = totalPages;
    else if (newPage > totalPages) newPage = 1;

    if (newPage == 0) {
      return;
    }

    this.setState(state => ({
      ...state,
      page: newPage,
    }));
  };

  handleRemoveClick = async realEstate => {
    const { user } = this.state;
    const { listingId } = realEstate;
    const isFavorite = userFavoriteService.isFavorite(listingId);

    const result = await userFavoriteService.patch({
      listingId: listingId,
      token: user.token,
    });

    userFavoriteService.save({ ids: result });

    if (isFavorite) userFavoriteService.removeOne(listingId);
    else userFavoriteService.save({ id: listingId });

    this.getFavorites();
  };

  getProfile = async () => {
    domEventsUtil.dispatch({
      name: eventsConstants.LOADING_ANIMATION,
      params: { show: true }
    });

    // const { history } = this.props;
    const { user } = this.state;
    let result;
    try {
      result = await userProfileService.getUserCompleteProfile({
        token: user.token,
        userId: user._id,
      });
    } catch (error) {
      throw error;
    } finally {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: false },
      });
    }

    this.setState(state => ({
      ...state,
      profile: result,
    }));
  }

  handleSelectChange = async (arr, name) => {
    const token = userService.retrieve().token;

    this.setState(prevState => ({
      ...prevState,
      [name]: [],
    }), () => {

      let newArr = [];

      for (let v in arr) {
        let opt = arr[v].value;
        newArr.push(opt);
      }

      this.setState(prevState => ({
        ...prevState,
        [name]: newArr,
      }))
    });

    if (name === "optionsState") {
      let citiesData = [];

      if (arr === null || !arr.length) {
        this.selectCityRef.select.clearValue();

        this.setState(prevState => ({
          ...prevState,
          cities: [],
          preferences_city: []
        }));

        return;
      }

      for await (const state of arr) {
        const { data } = await regionsService.getCitiesByState({
          state: state.value,
          token
        });


        const dataSerialized = data.map(item => {
          return {
            ...item,
            label: item.name,
            value: item.name,
          }
        });

        citiesData.push(...dataSerialized);
      }

      this.setState(prevState => ({
        ...prevState,
        cities: citiesData,
      }));
    }
  }

  render() {
    const { ads, comparing, page, states, cities, pageSize, profile, preferences_state, preferences_use, preferences_transaction, preferences_listing, preferences_city,
      preferences_condo, preferences_ok, preferences_near, preferences_optionsfproperty, preferences_typeOfGuarantee,
      preferences_property } = this.state;

    const tabConfigs = (() => {
      const tabs = [
        {
          label: "Meus Favoritos",
          path: pathsConstants.FAVORITES_MY_FAVORITES,
          showTooltip: true,
          tooltipTitle:
            "Aqui você tem acesso a todos os imóveis favoritos."
        },
        {
          label: "Minhas preferências",
          path: pathsConstants.FAVORITES_PREFERENCES,
          showTooltip: true,
          tooltipTitle:
            "Aqui você tem acesso a suas preferências."
        },
      ];
      return tabs;
    })();

    const customStyle = {
      control: base => ({
        ...base,
        border: '1px solid #999',
        boxShadow: 'none',
      }),
      option: (provided, state) => ({
        ...provided,
        fontSize: 16,
      }),
      input: (provided, state) => ({
        ...provided,
        fontSize: 25,
      }),
    }

    return (
      <Wrapper>
        <Cover
          {...profile}
          pictureUrl={profile ? profile.profile.image : ''}
          buttonVisible={null}
        />
        <Tabs configs={tabConfigs} />
        <Router history={this.props.history}>
          <Switch>
            <Route
              path={pathsConstants.FAVORITES_MY_FAVORITES}
              render={props =>
                <>
                  {ads && ads.length > 0 ? <>
                    <ContentWrapper>
                      {ads.slice((page - 1) * pageSize, page * pageSize).map(re => (
                        <Ad
                          {...re}
                          {...re.address}
                          {...re.price}
                          key={re.id}
                          image={re.images.find(i => {
                            return i.type === IMAGE_TYPES.PICTURE;
                          })}
                          onRemoveClick={() => this.handleRemoveClick(re)}
                          onCompareClick={() => this.handleRealEstateCompareClick(re)}
                          isComparing={!!comparing.find(c => c.listingId === re.listingId)}
                        />
                      ))}

                    </ContentWrapper>
                    <ArrowsWrapper>
                      <LeftArrowIcon
                        onClick={() => this.handlePaginationClick({
                          next: false,
                        })}
                      />
                      <RightArrowIcon
                        onClick={() => this.handlePaginationClick({
                          next: true,
                        })}
                      />
                    </ArrowsWrapper>
                    {comparing.length > 0 && (
                      <InfoSectionTitle id={'div-compare'}>
                        Comparando os imóveis
                      </InfoSectionTitle>
                    )}
                    <InfoWrapper >
                      {comparing.map(c => (
                        <Info
                          {...c}
                          {...c.address}
                          key={c.id}
                        />
                      ))}
                    </InfoWrapper>
                  </>
                    :
                    <>
                      <br />
                      <br />
                      <br />
                      <NoResults />
                    </>
                  }
                </>
              }
            />
            <Route
              path={pathsConstants.FAVORITES_PREFERENCES}
              render={props =>
                <>
                  {preferences_ok &&
                    <ContentWrapper isPreferencesPage>
                      <div className={'koort_form'} style={{ "padding": "10px", "margin-bottom": "30px" }}>
                        <Row>
                          <GroupSelect>
                            <Label>Selecione os tipos de usos</Label>
                            <Select
                              styles={customStyle}
                              noOptionsMessage={() => 'Sem opções'}
                              onChange={(value) => { this.handleSelectChange(value, 'optionsUse') }}
                              name="options-use"
                              id="options-use"
                              placeholder={'Selecione'}
                              defaultValue={preferences_use}
                              clearable={true}
                              options={options.use}
                              isMulti={true}
                              theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary: 'none',
                                },
                              })}
                            />
                          </GroupSelect>
                        </Row>

                        <Row>
                          <GroupSelect>
                            <Label>Selecione os tipos de propriedades</Label>
                            <Select
                              styles={customStyle}
                              noOptionsMessage={() => 'Sem opções'}
                              onChange={(value) => { this.handleSelectChange(value, 'optionsProperty') }}
                              name="options-property"
                              id="options-property"
                              placeholder={'Selecione'}
                              defaultValue={preferences_property}
                              options={options.property}
                              isMulti={true}
                              theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary: 'none',
                                },
                              })}
                            />
                          </GroupSelect>
                        </Row>
                        <Row>
                          <GroupSelect>
                            <Label>Selecione os tipos de transações</Label>
                            <Select
                              styles={customStyle}
                              noOptionsMessage={() => 'Sem opções'}
                              onChange={(value) => { this.handleSelectChange(value, 'optionsTransaction') }}
                              name="options-transaction"
                              id="options-transaction"
                              placeholder={'Selecione'}
                              options={options.transaction}
                              defaultValue={preferences_transaction}
                              isMulti={true}
                              theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary: 'none',
                                },
                              })}
                            />
                          </GroupSelect>
                        </Row>
                        <Row>
                          <GroupSelect>
                            <Label>Selecione os tipos de anúncios</Label>
                            <Select
                              styles={customStyle}
                              noOptionsMessage={() => 'Sem opções'}
                              onChange={(value) => { this.handleSelectChange(value, 'optionsListing') }}
                              name="options-listing"
                              id="options-listing"
                              placeholder={'Selecione'}
                              options={options.listing}
                              defaultValue={preferences_listing}
                              isMulti={true}
                              theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary: 'none',
                                },
                              })}
                            />
                          </GroupSelect>
                        </Row>

                        <Row>
                          <GroupSelect>
                            <Label>Selecione os estados</Label>
                            <Select
                              styles={customStyle}
                              noOptionsMessage={() => 'Sem opções'}
                              onChange={(value) => { this.handleSelectChange(value, 'optionsState') }}
                              name="options-state"
                              id="options-state"
                              placeholder={'Selecione'}
                              options={states}
                              defaultValue={preferences_state}
                              isMulti={true}
                              theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary: 'none',
                                },
                              })}
                            />
                          </GroupSelect>
                        </Row>

                        <Row>
                          <GroupSelect>
                            <Label>Selecione as cidades</Label>
                            <Select
                              ref={ref => this.selectCityRef = ref}
                              styles={customStyle}
                              noOptionsMessage={() => 'Sem opções'}
                              onChange={(value) => { this.handleSelectChange(value, 'optionsCity') }}
                              name="options-city"
                              id="options-city"
                              placeholder={!!cities.length ? 'Selecione' : 'Selecione um estado'}
                              options={cities}
                              defaultValue={preferences_city}
                              isMulti={true}
                              theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary: 'none',
                                },
                              })}
                            />
                          </GroupSelect>
                        </Row>
                        {/* <GroupSelect>
                            <Label>Bairro</Label>
                            <Select
                              styles={customStyle}
                              noOptionsMessage={() => 'Sem opções'}
                              onChange={(value) => { this.handleSelectChange(value, 'optionsBairro') }}
                              name="options-neighborhood"
                              id="options-neighborhood"
                              placeholder={'Selecione'}
                              options={neighborhoods}
                              defaultValue={preferences_neighborhood}
                              isMulti={true}
                              theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary: 'none',
                                },
                              })}
                            />
                          </GroupSelect> */}
                        <Row>
                          <GroupSelect>
                            <Label>Selecione as características gerais</Label>
                            <Select
                              styles={customStyle}
                              noOptionsMessage={() => 'Sem opções'}
                              onChange={(value) => { this.handleSelectChange(value, 'optionsfproperty') }}
                              name="options-features-property"
                              id="options-features-property"
                              placeholder={'Selecione'}
                              options={this.state.constantsFeaturesData.PROPERTY}
                              defaultValue={preferences_optionsfproperty}
                              isMulti={true}
                              theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary: 'none',
                                },
                              })}
                            />
                          </GroupSelect>
                        </Row>

                        <Row>
                          <GroupSelect>
                            <Label>Selecione as áreas comuns</Label>
                            <Select
                              styles={customStyle}
                              noOptionsMessage={() => 'Sem opções'}
                              onChange={(value) => { this.handleSelectChange(value, 'optionscondo') }}
                              name="options-condo"
                              id="options-condo"
                              placeholder={'Selecione'}
                              options={this.state.constantsFeaturesData.CONDO}
                              defaultValue={preferences_condo}
                              isMulti={true}
                              theme={theme => ({
                                ...theme,
                                height: 250,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary: 'none',
                                },
                              })}
                            />
                          </GroupSelect>
                        </Row>

                        <Row>
                          <GroupSelect>
                            <Label>Selecione os entornos</Label>
                            <Select
                              styles={customStyle}
                              noOptionsMessage={() => 'Sem opções'}
                              onChange={(value) => { this.handleSelectChange(value, 'optionsnear') }}
                              name="options-near"
                              id="options-near"
                              placeholder={'Selecione'}
                              options={this.state.constantsFeaturesData.NEAR}
                              defaultValue={preferences_near}
                              isMulti={true}
                              theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary: 'none',
                                },
                              })}
                            />
                          </GroupSelect>
                        </Row>
                        <Row>
                          <GroupSelect>
                            <Label>Selecione os tipos de garantias</Label>
                            <Select
                              styles={customStyle}
                              noOptionsMessage={() => 'Sem opções'}
                              onChange={(value) => { this.handleSelectChange(value, 'optionsguarantee') }}
                              name="options-garantee"
                              id="options-garantee"
                              placeholder={'Selecione'}
                              options={options.typeOfGuarantee}
                              defaultValue={preferences_typeOfGuarantee}
                              isMulti={true}
                              theme={theme => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                  ...theme.colors,
                                  primary: 'none',
                                },
                              })}
                            />
                          </GroupSelect>
                        </Row>
                        <GroupSelectWrapper>
                          <GroupSelect style={{ width: '48%' }}>
                            <Label>Informe o número de vagas de estacionamento</Label>
                            <GroupSelectInner>
                              <GroupSelectInputWrapper>
                                <InputBootstrap
                                  label="Mínimo"
                                  name='minimum-parking-spaces'
                                  type='number'
                                  value={this.state.minValueParkingSpaces}
                                  error={this.state.minValueParkingSpacesErrorMessage ?
                                    { message: this.state.minValueParkingSpacesErrorMessage }
                                    : null}
                                  onChange={event => {
                                    event.persist();
                                    this.setState(prevState => ({
                                      ...prevState,
                                      minValueParkingSpaces: event.target.value,
                                      minValueParkingSpacesErrorMessage: '',
                                    }))
                                  }}
                                />
                              </GroupSelectInputWrapper>
                              <InputBootstrap
                                label="Máximo"
                                name='maximum-parking-spaces'
                                type='number'
                                style={{ width: '100%' }}
                                value={this.state.maxValueParkingSpaces}
                                onChange={event => {
                                  event.persist();
                                  this.setState(prevState => ({
                                    ...prevState,
                                    maxValueParkingSpaces: event.target.value
                                  }))
                                }}
                              />
                            </GroupSelectInner>
                          </GroupSelect>

                          <GroupSelect style={{ width: '48%', marginRight: 0 }}>
                            <Label>Informe o número de suítes</Label>
                            <GroupSelectInner>
                              <GroupSelectInputWrapper>
                                <InputBootstrap
                                  label="Mínimo"
                                  name='minimum-suites'
                                  type='number'
                                  value={this.state.minValueSuites}
                                  error={this.state.minValueSuitesErrorMessage ?
                                    { message: this.state.minValueSuitesErrorMessage }
                                    : null}
                                  onChange={event => {
                                    event.persist();
                                    this.setState(prevState => ({
                                      ...prevState,
                                      minValueSuites: event.target.value,
                                      minValueSuitesErrorMessage: '',
                                    }))
                                  }}
                                />
                              </GroupSelectInputWrapper>
                              <InputBootstrap
                                label="Máximo"
                                name='maximum-suites'
                                type='number'
                                style={{ width: '100%' }}
                                value={this.state.maxValueSuites}
                                onChange={event => {
                                  event.persist();
                                  this.setState(prevState => ({
                                    ...prevState,
                                    maxValueSuites: event.target.value
                                  }))
                                }}
                              />
                            </GroupSelectInner>
                          </GroupSelect>
                        </GroupSelectWrapper>

                        <GroupSelectWrapper>
                          <GroupSelect style={{ width: '48%' }}>
                            <Label>Informe o número de quartos</Label>
                            <GroupSelectInner>
                              <GroupSelectInputWrapper>
                                <InputBootstrap
                                  label="Mínimo"
                                  name='minimum-bedrooms'
                                  type='number'
                                  value={this.state.minValueBedrooms}
                                  error={this.state.minValueBedroomsErrorMessage ?
                                    { message: this.state.minValueBedroomsErrorMessage }
                                    : null}
                                  onChange={event => {
                                    event.persist();
                                    this.setState(prevState => ({
                                      ...prevState,
                                      minValueBedrooms: event.target.value,
                                      minValueBedroomsErrorMessage: '',
                                    }))
                                  }}
                                />
                              </GroupSelectInputWrapper>
                              <InputBootstrap
                                label="Máximo"
                                name='maximum-bedrooms'
                                type='number'
                                style={{ width: '100%' }}
                                value={this.state.maxValueBedrooms}
                                onChange={event => {
                                  event.persist();
                                  this.setState(prevState => ({
                                    ...prevState,
                                    maxValueBedrooms: event.target.value
                                  }))
                                }}
                              />
                            </GroupSelectInner>
                          </GroupSelect>

                          <GroupSelect style={{ width: '48%', marginRight: 0 }}>
                            <Label>Informe o número de banheiros</Label>
                            <GroupSelectInner>
                              <GroupSelectInputWrapper>
                                <InputBootstrap
                                  label="Mínimo"
                                  name='minimum-bathrooms'
                                  type='number'
                                  value={this.state.minValueBathrooms}
                                  error={this.state.minValueBathroomsErrorMessage ?
                                    { message: this.state.minValueBathroomsErrorMessage }
                                    : null}
                                  onChange={event => {
                                    event.persist();
                                    this.setState(prevState => ({
                                      ...prevState,
                                      minValueBathrooms: event.target.value,
                                      minValueBathroomsErrorMessage: '',
                                    }))
                                  }}
                                />
                              </GroupSelectInputWrapper>
                              <InputBootstrap
                                label="Máximo"
                                name='maximum-bathrooms'
                                type='number'
                                style={{ width: '100%' }}
                                value={this.state.maxValueBathrooms}
                                onChange={event => {
                                  event.persist();
                                  this.setState(prevState => ({
                                    ...prevState,
                                    maxValueBathrooms: event.target.value
                                  }))
                                }}
                              />
                            </GroupSelectInner>
                          </GroupSelect>
                        </GroupSelectWrapper>
                      </div>
                      <SaveButtonWrapper>
                        <SaveButton onClick={() => { this.savePreferences() }}>
                          salvar preferências
                        </SaveButton>
                      </SaveButtonWrapper>
                    </ContentWrapper>
                  }
                </>
              }
            />
            <Redirect to={pathsConstants.FAVORITES} />
          </Switch>
        </Router>
      </Wrapper>
    )
  }
}

const GroupSelectInputWrapper = styled.div`
> .form-floating > input {
  border-color: rgba(0, 0, 0, 0.4);
}
  @media (max-width: 920px) {
    width: 100%;
  }
`;

const GroupSelectInner = styled.div`
  display: flex;
  gap: 12px;

  > .form-floating > input {
  border-color: rgba(0, 0, 0, 0.4);
}

  @media (max-width: 920px) {
    > div.form-floating {
      width: 100%;
    }
  }

`;

const GroupSelectWrapper = styled.div`
  width: 100%;
  display: flex;
  padding-left: 30px;
  margin-bottom: 20px;
  justify-content: space-between;

  @media (max-width: 920px) {
    flex-direction: column;

    > div {
      width: 100% !important;

      & + div {
      margin-top: 20px;
      }
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  ${mqTablet`
    width: 100%;
    align-self: center;
  ` }
`;

const GroupSelect = styled.div`
  margin-right: 10px;
  width: 100%;
  float:left;
`;
const Row = styled.div`
  margin-bottom: 20px;
  width: 100%;
  padding-left: 30px;
`

const Label = styled(BaseLabel)`
  font-weight: bold;
  font-size: 15px;
  color: #555;
  align-self: center;
  margin-bottom: 8px;
  margin-top: 5px;
`;

const SaveButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 18px;
  padding-bottom: 40px;

  position: sticky;
  bottom: 0;
  background: #FFFFFF;

  @media(max-width: 768px) {
    justify-content: center;
    padding: 18px 10px 40px 40px;
  }
`;

const SaveButton = styled(BaseButton).attrs({
  type: 'submit',
})`
 @media(max-width: 768px) {
    width: 100%;
  }
`;

const TabsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 80px;
`;

const Tab = styled(BaseButton)`
  padding: 0;
  height: 50px;
  width: 50px;
  align-self: flex-end;
  border: solid 1px ${COLOR_4};
  background-color: ${COLOR_4};

  & > svg {
    color: ${COLOR_2};
  }
`;

const HeartIcon = styled(Heart)`
  ${iconBaseStyle}
  color: ${COLOR_4};
  align-self: center;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 20px;

  ${mqDesktop`
    width: 1000px;
    flex-direction: row;
    flex-flow: wrap;
    justify-content: center;
    align-self: center;
  `}
  ${maxMqTablet`
    flex-direction: row;
    flex-flow: column wrap-reverse;
    justify-content: flex-start;
    align-self: center;
  ` }
  ${maxMqCustom(250)`
    flex-direction: column;
    flex-flow: column wrap-reverse;
    justify-content: center;
    align-self: center;
    width: 100%;
  `}
`;

const ArrowsWrapper = styled.div`
  align-self: center;
  display: flex;
  margin-top: -20px;
  margin-bottom: 70px;
`;

const LeftArrowIcon = styled(KeyboardArrowLeft)`
  ${iconBaseStyle}
  height: 50px;
  align-self: center;
  ${pointer}

  ${mqDesktop`
    ${transitions(['transform'], 'ease .3s')}
    &:hover {
      transform: scale(1.3);
    }
  ` }
`;

const RightArrowIcon = styled(LeftArrowIcon).attrs({
  as: KeyboardArrowRight,
})``;

const InfoSectionTitle = styled(BaseLabel)`
  font-weight: bold;
  font-size: 16px;
  color: ${COLOR_1};
  align-self: center;
  margin-bottom: 30px;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${mqDesktop`
    flex-direction: row;
  ` }
`;


const options = {
  use: [
    { label: 'Residencial', value: 'Residencial' },
    { label: 'Comercial', value: 'Comercial' },
  ],
  property: [
    {
      label: 'Apartamento',
      value: 'Apartamento',
    },
    {
      label: 'Casa',
      value: 'Casa',
    },
    {
      label: 'Casa de Condomínio',
      value: 'Casa de Condomínio',
    },
    {
      label: 'Chácara/Sítio/Fazenda',
      value: 'Chácara | Sítio | Fazenda',
    },
    {
      label: 'Cobertura',
      value: 'Cobertura',
    },
    {
      label: 'Flat',
      value: 'Flat',
    },
    {
      label: 'Kitnet',
      value: 'Kitnet',
    },
    {
      label: 'Terreno',
      value: 'Terreno',
    },
    {
      label: 'Edifício',
      value: 'Edifício',
    },
    {
      label: 'Galpão/Depósito/Armazém',
      value: 'Galpão | Depósito | Armazém',
    },
    {
      label: 'Loja/Ponto Comercial',
      value: 'Loja | Ponto Comercial',
    },
    {
      label: 'Sala Comercial',
      value: 'Sala',
    },
  ],
  transaction: [
    { label: 'Venda', value: 'Venda' },
    { label: 'Locação', value: 'Locação' },
  ],
  listing: [
    { label: 'Lançamento', value: 'Lançamento' },
    { label: 'Usado', value: 'Usado' },
  ],
  city: [
    { label: 'Rio de Janeiro', value: 'Rio de Janeiro' },
    { label: 'Niterói', value: 'Niterói' },
    { label: 'São Paulo', value: 'São Paulo' },
    { label: 'São José do Campos', value: 'São José do Campos' },
  ],
  neighborhood: [
    { label: 'Barra da Tijuca', value: 'Barra da Tijuca' },
    { label: 'Recreio dos Bandeirantes', value: 'Recreio dos Bandeirantes' },
    { label: 'Gávea', value: 'Gávea' },
    { label: 'Ibirapuera', value: 'Ibirapuera' },
  ],
  features_property: [
    { label: "Ambientes Integrados", value: "Ambientes Integrados" },
    { label: "Armários Planejados", value: "Armários Planejados" },
    { label: "Closet", value: "Closet" },
    { label: "Cozinha Americana", value: "Cozinha Americana" },
    { label: "Gás Encanado", value: "Gás Encanado" },
    { label: "Acessibilidade", value: "Acessibilidade" },
    { label: "Mobiliado", value: "Mobiliado" },
    { label: "Piscina", value: "Piscina" },
    { label: "Sauna", value: "Sauna" },
    { label: "Churrasqueira", value: "Churrasqueira" },
    { label: "Área de Serviço", value: "Área de Serviço" },
    { label: "Quarto/WC Empregada", value: "Quarto/WC Empregada" },
    { label: "Terraço", value: "Terraço" },
    { label: "Varanda", value: "Varanda" },
    { label: "Vista Panorâmica", value: "Vista Panorâmica" },
  ],
  condo: [
    { label: "Academia", value: "Academia" },
    { label: "Aceita Pet", value: "Aceita Pet" },
    { label: "Bicicletário", value: "Bicicletário" },
    { label: "Churrasqueira", value: "Churrasqueira" },
    { label: "Comércio/Serviços no Condomínio", value: "Comércio/Serviços no Condomínio" },
    { label: "Depósito Privativo", value: "Depósito Privativo" },
    { label: "Elevador", value: "Elevador" },
    { label: "Estacionamento Coberto", value: "Estacionamento Coberto" },
    { label: "Estacionamento Visitantes", value: "Estacionamento Visitantes" },
    { label: "Piscina", value: "Piscina" },
    { label: "Sauna", value: "Sauna" },
    { label: "Quadra Poliesportiva", value: "Quadra Poliesportiva" },
    { label: "Playground", value: "Playground" },
    { label: "Salão de Festas", value: "Salão de Festas" },
    { label: "Salão de Jogos", value: "Salão de Jogos" },
    { label: "Porteiro 24 Horas", value: "Porteiro 24 Horas" },
    { label: "Segurança Interna", value: "Segurança Interna" },
    { label: "Serviço de Transporte no Condomínio", value: "Serviço de Transporte no Condomínio" },
    { label: "Sistema de Incêndio", value: "Sistema de Incêndio" },
    { label: "Acessibilidade", value: "Acessibilidade" },
  ],
  near: [
    { label: "Próximo a Áreas de Lazer", value: "Próximo a Áreas de Lazer" },
    { label: "Próximo a Parques e Áreas Verdes", value: "Próximo a Parques e Áreas Verdes" },
    { label: "Próximo a Praia", value: "Próximo a Praia" },
    { label: "Próximo ao Metrô", value: "Próximo ao Metrô" },
    { label: "Próximo a Mercado", value: "Próximo a Mercado" },
    { label: "Próximo a Farmácia", value: "Próximo a Farmácia" },
    { label: "Próximo a Academia", value: "Próximo a Academia" },
    { label: "Próximo a Escola Pública", value: "Próximo a Escola Pública" },
    { label: "Próximo a Escola Particular", value: "Próximo a Escola Particular" },
    { label: "Próximo ao Shopping", value: "Próximo ao Shopping" },
    { label: "Próximo a Restaurante", value: "Próximo a Restaurante" },
    { label: "Próximo a Centro Empresarial", value: "Próximo a Centro Empresarial" },
    { label: "Próximo a Serviços de Saúde", value: "Próximo a Serviços de Saúde" },
    { label: "Próximo a Comércio Diversos", value: "Próximo a Comércio Diversos" },
  ],
  typeOfGuarantee: [
    { label: "Fiador", value: "Fiador" },
    { label: "Seguro-Fiança", value: "Seguro-Fiança" },
    { label: "Título de Capitalização", value: "Título de Capitalização" },
    { label: "Carta de Fiança", value: "Carta de Fiança" },
    { label: "Depósito Caução", value: "Depósito Caução" },
  ],
  parkingSpaces: [
    { label: "1+", value: "1+" },
    { label: "2+", value: "2+" },
    { label: "3+", value: "3+" },
    { label: "4+", value: "4+" },
    { label: "5+", value: "5+" },
    { label: "6+", value: "6+" },
  ],
  suites: [
    { label: "1+", value: "1+" },
    { label: "2+", value: "2+" },
    { label: "3+", value: "3+" },
    { label: "4+", value: "4+" },
    { label: "5+", value: "5+" },
    { label: "6+", value: "6+" },
  ]
};

export default Favorites;
