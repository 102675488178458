import React, { useState, useCallback } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import 'react-toastify/dist/ReactToastify.css';

import Loading from '../../../../../../../components/loading';

import { Input } from './components/input';
import { PhoneInput } from './components/phone-input';
import { TextArea } from './components/textarea';

import ContactService from '../../../../../../../services/contact/contact.service';

import { Form, SendButton } from './styles';

const customId = "custom-id-yes";

const contactFormSchema = yup.object().shape({
  name: yup
    .string()
    .required('Este campo é obrigatório')
    .min(3, 'Mínimo de 3 caracteres')
    .max(80, 'Máximo de 80 caracteres'),
  email: yup.string()
    .required('Este campo é obrigatório')
    .email('Informe um e-mail válido'),
  telephone: yup.string()
    .test("Campo aceito", "Mínimo de 10 dígitos", (value) => {
      if (value === undefined || value === '' || value === '+55 (') {
        return false;
      }

      if (!!value) {
        const number = value.replace(/[^a-zA-Z0-9]+/g, '').substring(2);

        if (number.length >= 10) {
          return true;
        } else {
          return false;
        }
      }
    }),
  // message: yup.string()
  //   .min(10, 'Mínimo de 10 caracteres')
  //   .max(500, 'Máximo de 500 caracteres'),
});

export function ContactFormFields({ listingId, onSuccess }) {
  const [isLoading, setIsLoading] = useState(false);

  const { register, handleSubmit, formState, control, reset } = useForm({
    resolver: yupResolver(contactFormSchema),
  });

  const { errors } = formState;

  const handleGetInTouch = useCallback(values => {
    let data = {
      name: values.name,
      email: values.email,
      realEstateId: listingId,
    };

    if (Boolean(values.telephone) && values.telephone !== '+55 (') {
      data = {
        ...data,
        phone: values.telephone.replace(/[^a-zA-Z0-9]+/g, '').substring(2),
      }
    }

    setIsLoading(true);

    ContactService.sendMessageToRealtor(data)
      .then(() => {
        reset();

        try {
          const url = new URL(window.location.href);
          const params = new URLSearchParams(url.search);

          params.set('formStatus', 'sent');

          url.search = params.toString();

          const newUrl = url.toString();

          window.history.replaceState({ path: newUrl, }, '', newUrl);
        } catch (err) {
          console.log(err)
        }

        onSuccess();
        toast.success("Mensagem enviada com sucesso!", {
          toastId: customId,
        })
      })
      .catch(() => {
        toast.error('Ocorreu um erro ao enviar o formulário. Por favor, verifique os campos', {
          toastId: customId,
        });
      })
      .finally(() => setIsLoading(false));
  }, [listingId]);

  return (
    <Form onSubmit={handleSubmit(handleGetInTouch)}>
      <Input
        name="name"
        label="Nome"
        error={errors.name}
        {...register('name')}
      />

      <Input
        name="email"
        label="E-mail"
        error={errors.email}
        {...register('email')}
      />
      <PhoneInput
        label="Telefone"
        name="telephone"
        control={control}
        error={errors.telephone}
      />

      {/* <TextArea
        name="message"
        label="Mensagem"
        error={errors.message}
        {...register('message')}
      /> */}
      <SendButton type="submit" disabled={isLoading}>
        {isLoading ? 'Enviando' : 'Enviar'}
      </SendButton>
      <Loading show={isLoading} />
      <ToastContainer
        position="bottom-center"
        autoClose={false}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Form>
  );
}
