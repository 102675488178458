import React, { PureComponent } from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import { Persist } from 'react-persist-plus';

import { MaskedBaseInput, masks, BaseInput } from '../../../../styled/inputs/inputs';
import Checkbox from '../../../../components/checkbox';

import InputWrapper from '../../../../components/input-wrapper';

import validationUtil from '../../../../utils/validation/validation.util';

/*
  global
  window
*/

class CreatorInfo extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      form: {
        name: props.name,
        email: props.email,
        phoneNumber: props.telephone,
        cellphoneNumber: props.cellphone,
        whatsApp: true,
      },
      showErrorsFor: { },
    };
  }

  handleFormChange = event => {
    const { name, value, type, checked } = event.target;

    this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        [name]: type === 'checkbox' ? checked : value,
      },
    }));
  };

  handleInputBlur = event => {
    const { name } = event.target;

    this.setState(state => ({
      ...state,
      showErrorsFor: {
        ...state.showErrorsFor,
        [name]: true,
      },
    }));
  };

  render() {
    const { showInvalids } = this.props;
    const { form, showErrorsFor } = this.state;
    return (
      <Wrapper onChange={ this.handleFormChange }>
        <Persist
          name='PURCHASE_FLOW_BUILDER_CREATOR_INFO_FORM'
          data={ form }
          onMount={ data => this.setState(state => ({
            ...state,
            form: data,
          })) }
          store={ window.sessionStorage }
        />
        <NameInput
          value={ form.name }
          defaultValue = {form.name}
          onBlur={ this.handleInputBlur }
          isInvalid={ (() => {
            if(!showErrorsFor.name && !showInvalids) return;

            return !validationUtil.text({
              minLength: 3,
              value: form.name,
            });
          })() }
        />
        <EmailInput
          value={ form.email }
          onBlur={ this.handleInputBlur }
          isInvalid={ (() => {
            if(!showErrorsFor.email && !showInvalids) return;

            return !validationUtil.email({
              value: form.email,
            });
          })() }
        />
        <PhoneNumberInput
          value={ form.phoneNumber }
          onBlur={ this.handleInputBlur }
          isInvalid={ (() => {
            if(!showErrorsFor.phoneNumber && !showInvalids) return;

            return !validationUtil.text({
              value: form.phoneNumber,
              minLength: 18,
              maxLength: 18,
            }) && form.phoneNumber.length > 0;
          })() }
        />
        <CellphoneNumber
          value={ form.cellphoneNumber }
          onBlur={ this.handleInputBlur }
          isInvalid={ (() => {
            if(!showErrorsFor.cellphoneNumber && !showInvalids) return;

            return !validationUtil.text({
              value: form.cellphoneNumber,
              minLength: 19,
              maxLength: 19,
            }) && form.cellphoneNumber.length > 0;
          })() }
        />
        <CheckboxInputWrapper
          label='WhatsApp?'
          htmlFor='whatsApp'
        >
          <HasWhatsappInput
            id='whatsApp'
            name='whatsApp'
            checked={ form.whatsApp }
          />
        </CheckboxInputWrapper>
      </Wrapper>
    )
  }
}

CreatorInfo.propTypes = {
  showInvalids: propTypes.bool,
};

CreatorInfo.defaultProps = {
  showInvalids: false,
};

const Wrapper = styled.form`
  margin-bottom: 70px;
`;

const NameInput = styled(BaseInput).attrs({
  name: 'name',
  placeholder: 'Nome',
})``;

const EmailInput = styled(BaseInput).attrs({
  name: 'email',
  placeholder: 'E-mail',
  type: 'email',
})``;

const PhoneNumberInput = styled(NameInput).attrs({
  as: MaskedBaseInput,
  mask: masks.phone,
  name: 'phoneNumber',
  placeholder: 'Telefone',
})``;

const CellphoneNumber = styled(NameInput).attrs({
  as: MaskedBaseInput,
  mask: masks.cellphone,
  name: 'cellphoneNumber',
  placeholder: 'Celular',
})``;

const CheckboxInputWrapper = styled(InputWrapper)`
  flex-direction: row-reverse;
  justify-content: flex-end;
`;

const HasWhatsappInput = styled(Checkbox).attrs({
  name: 'whatsApp',
})`
  margin-right: 10px;
`;

export default CreatorInfo;
