
import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import { Eye } from 'styled-icons/feather/Eye';
import { MailOutline } from 'styled-icons/material/MailOutline';
import { Heart } from 'styled-icons/boxicons-regular/Heart';
import { Share } from 'styled-icons/material/Share';

import { COLOR_4, COLOR_2 } from '../../../../../styled/variables/variables';
import { iconBaseStyle } from '../../../../../styled/helpers/helpers';
import { BaseLabel } from '../../../../../styled/labels/labels';

const StatsBar = ({
  views,
  messages,
  favorites,
  shares
}) => (
  <Wrapper>
    <EyeIcon />
    <StatsValue>{ views }</StatsValue>
    <MailIcon />
    <StatsValue>{ messages }</StatsValue>
    <HeartIcon />
    <StatsValue>{ favorites }</StatsValue>
    <ShareIcon />
    <StatsValue>{ shares }</StatsValue>
  </Wrapper>
);

StatsBar.propTypes = {
  views: propTypes.number.isRequired,
  messages: propTypes.number.isRequired,
  favorites: propTypes.number.isRequired,
  shares: propTypes.number.isRequired,
  value: propTypes.any, 
};

StatsBar.defaultProps = {
  views: 10,
  messages: 6,
  favorites: 34,
  shares: 34,
};

const Wrapper = styled.div`
  padding: 5px 0;
  background-color: ${ COLOR_4 };
  border-radius: 1px;
  display: flex;
  justify-content: center;
`;

const EyeIcon = styled(Eye)`
  ${ iconBaseStyle }
  color: ${ COLOR_2 };
  margin-right: 5px;
  align-self: center;
`;

const MailIcon = styled(EyeIcon).attrs({
  as: MailOutline,
})`
  height: 18px;
`;

const HeartIcon = styled(EyeIcon).attrs({
  as: Heart,
})`
  height: 18px;
`;

const ShareIcon = styled(EyeIcon).attrs({
  as: Share,
})`
  height: 18px;
`;

const StatsValue = styled(BaseLabel)`
  color: ${ COLOR_2 };
  font-size: 10px;
  margin-right: 10px;
  align-self: center;
`;

export default StatsBar;
