import styled from 'styled-components';
import { transitions } from 'polished';

import { COLOR_2, COLOR_1, COLOR_6 } from '../variables/variables';
import { pointer } from '../helpers/helpers';

export const BaseButton = styled.button.attrs({
  type: 'button',
})`
  color: ${COLOR_2};
  padding: 0 20px;
  height: 50px;
  font-size: 12px;
  font-weight: 700;
  outline: none;
  cursor: ${({ disabled }) => (disabled ? 'no-drop' : 'pointer')};
  border-radius: 1px;
  margin-right: 10px;
  line-height: 50px;
  text-decoration: none;
  ${pointer};
  text-transform: uppercase;
  background-color: ${COLOR_1};
  text-align: center;
  display: flex;
  justify-content: center;
  border: none;

  &:last-child {
    margin-right: 0px;
  }

  ${transitions(
    ['background-color', 'color', 'font-weight', 'border-color'],
    'ease .3s',
  )};
`;

export const SecondaryButton = styled(BaseButton)`
  background-color: ${COLOR_6};
  color: ${COLOR_2};
  border: none;
  font-weight: 700;
`;

export const SmallButton = styled(BaseButton)`
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
`;

export const NoStyleButton = styled(BaseButton)`
  display: block;
  background: none;
  border: none;
  height: auto;
  border-radius: none;
  margin: 0;
  padding: 0;
  line-height: unset;
  font-size: unset;
`;

export default {
  BaseButton,
  SecondaryButton,
  SmallButton,
};
