import React, { forwardRef } from 'react';

import { ErrorMessage } from '../error-message';

import { Container } from './styles';

const InputBase = ({
  name,
  error = null,
  label,
  ...rest
}, ref) => {
  return (
    <>
      <Container>
        <input
          name={name}
          id={name}
          ref={ref}
          autoComplete="off"
          placeholder="input"
          {...rest}
        />

        <label htmlFor={name}>{label}</label>
      </Container>

      {error && <ErrorMessage message={error.message} />}
    </>
  );
}

export const Input = forwardRef(InputBase);
