/* eslint-disable no-undef */
/* eslint-disable react/prop-types */

import React, { PureComponent, useState, useEffect } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { transitions } from 'polished';
import { Container, Col, Row } from 'react-bootstrap';

import { mqTablet, mqDesktop, backgrounder, pointer } from '../../styled/helpers/helpers';
import { COLOR_1, COLOR_2 } from '../../styled/variables/variables';
import './config-account.css'
import './Payment.css'

import userService from '../../services/user/user.service';

const { PUBLIC_URL } = process.env;

const Payment = ({
  history
}) => {

  const [token, setToken] = useState();
  const [user, setUser] = useState();
  const getToken = async () => {
    let user;
    user = await userService.retrieve();

    if(user !== undefined){
      setToken(user.token);
      setUser(user);
    } else {
      setToken(null)
      setUser(null);
    }
  }

  useEffect(() => {
    getToken();
  }, []);


  return (
    <>
      <Container fluid="md" className={"container_fluid"}>
                <Row>
                    <Col xs={12} md={12}>
                        <h4 className={"h4_tittle"}>Integração de anúncios</h4>
                        <h6 className={"text_p_2"}>Forma de pagamento</h6>
                    </Col>
                    <Row style={{ paddingTop: '3%' }}>
                        <Col xs={12} md={3}>
                            <div className={"div_card"}>
                                <h6 className={"text_p_2"}>Boleto Bancário</h6>
                            </div>
                        </Col>
                        <div style={{paddingTop: '3%'}}><a href='' className={"a_link"}>Modificar forma de Pagamento</a></div>
                    </Row>
                    <Row style={{ paddingTop: '5%' }}>
                        <Col md={8} className={"Col_md_8_end"}>
                            <h4 className={"h4_tittle"}>Faturas</h4>
                        </Col>
                    </Row>
                    <Col md={10} xs={12}>
                        <div className="table-responsive">
                            <table className="table ">
                                <tbody>
                                    <tr>
                                        <th><h6 className={"text_p_2"}>Nº</h6></th>
                                        <th><h6 className={"text_p_2"}>$</h6></th>
                                        <th><h6 className={"text_p_2"}>Vencimento</h6></th>
                                        <th><h6 className={"text_p_2"}>Pagamento</h6></th>
                                        <th><h6 className={"text_p_2"}>Status</h6></th>
                                        <th><h6 className={"text_p_2"}>Atividades</h6></th>
                                    </tr>
                                    <tr>
                                        <th style={{ paddingTop: '2%', paddingBottom: '2%' }}><h6 className={"text_p_2"}>956442</h6></th>
                                        <td style={{ paddingTop: '2%', paddingBottom: '2%' }}><h6 className={"text_p_2"}>258,00</h6></td>
                                        <td style={{ paddingTop: '2%', paddingBottom: '2%' }}><h6 className={"text_p_2"}>06/10/2021</h6></td>
                                        <td style={{ paddingTop: '2%', paddingBottom: '2%' }}><h6 className={"text_p_2"}>-</h6></td>
                                        <th style={{ paddingTop: '2%', paddingBottom: '2%' }}><h6 className={"text_p_2"}><a href='' className={"a_link"}>Gerar Boleto</a></h6></th>
                                        <td style={{ paddingTop: '2%', paddingBottom: '2%' }}><h6 className={"text_p_2"}>Pagar agora</h6></td>
                                    </tr>
                                    <tr>
                                        <th style={{ paddingTop: '2%', paddingBottom: '2%' }}><h6 className={"text_p_2"}>956442</h6></th>
                                        <td style={{ paddingTop: '2%', paddingBottom: '2%' }}><h6 className={"text_p_2"}>258,00</h6></td>
                                        <td style={{ paddingTop: '2%', paddingBottom: '2%' }}><h6 className={"text_p_2"}>06/10/2021</h6></td>
                                        <td style={{ paddingTop: '2%', paddingBottom: '2%' }}><h6 className={"text_p_2"}>06/10/2021</h6></td>
                                        <td style={{ paddingTop: '2%', paddingBottom: '2%' }}><h6 className={"text_p_2"}>Pago</h6></td>
                                        <td style={{ paddingTop: '2%', paddingBottom: '2%' }}><h6 className={"text_p_2"}><a href='' className={"a_link"}>Ver Comprovante</a></h6></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Col>
                </Row>


            </Container>
    </>
  );
}

const Wrapper = styled.div`
  background-color: ${ COLOR_1 };
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  padding-top: 120px;

  * { position: relative; }
  &:before {
    content: "";
    ${ backgrounder(`${ PUBLIC_URL }/imgs/koort-texture-outline.svg`) };
    background-size: 800px;
    height: 100%;
    width: 100%;
    opacity: .3;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
  }

  ${ mqTablet`
    &:before {
      background-size: 600px;
      background-repeat: repeat;
    }
  ` }
`;


const Wrapper2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;
  align-self:center;
  background-color:white;
  /* height: 700px; */
  width: 100%;
  ${ mqTablet`
    width: 950px;
    padding-bottom: 20px
  ` };
    ${ mqDesktop`
    width: 100%;
  ` };
`;

const Texture = styled.div`
  ${ backgrounder(`${PUBLIC_URL}/imgs/koort-texture-white-outline.svg`)};
  background-position: bottom;
  height: 140px;
  width: 100%;
  background-repeat: repeat-x;
  background-size: 620px;
  margin-bottom: 30px;

  ${ mqDesktop`
    background-position: right;
    width: 300px;
    height: 100vh;
    background-size: 1000px;
    margin: 0;
  ` }
`;

const WrapperSquares = styled.div`
  display: flex;
  flex-direction: column;
  ${ mqTablet`
    flex-direction: row;
  ` };
`;

const WrapperTexture = styled.div`
  display: flex;
  flex-direction: column;
  width:100%;
  padding-top:20px;
  ${ mqTablet`
    flex-direction: row;
  ` };
`;

const WrapperCards = styled.div`
  ${ backgrounder(`${PUBLIC_URL}/imgs/koort-texture-white.svg`)};
  background-position: bottom;
  background-position-y: 190px;
  height: 280px;
  background-size: 620px;
  margin-bottom: 30px;
  background-color: ${ COLOR_1};

  width: 200px;
  margin: 8px 8px 24px;
  min-height: 200px;
  min-width: 200px;
  padding-top:15px;

  cursor: pointer;

  ${ mqDesktop`
    ${ transitions(['transform'], 'ease .3s')}
    &:hover {
      transform: scale(1.1);
    }
  `}
`;

// const Squares = styled.div`
//   background-color: #FEFEFE; 
//   height: 200px; 
//   width: 200px; 
//   margin: 5px; 
//   word-wrap:break-word;
// `;

const SubtitleWrapper = styled.div`
  display:flex; 
  justify-content:center; 
  align-items:center; 
  margin-top: 20px;
`;

const Title = styled.h4`
  text-align: center;
  padding-top: 15px;
  font-weight: 700;
  font-size: 26px;
  color: ${ COLOR_2}
`;

const Subtitle = styled.p`
  width: 180px; 
  text-align: left; 
  align-self: center;
  color: ${ COLOR_2}
`;


// const Titlee = styled(BaseLabel)`
//   font-weight: 700;
//   font-size: 22px;
//   align-self: center;
//   margin-bottom: 50px;

//   ${ mqTablet`
//     margin-bottom: 100px;
//     font-size: 30px;
//   ` }
// `;



// const ContentWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   padding: 10px;
//   padding-bottom: 100px;

//   ${ mqTablet`
//     width: 100%;
//     max-width: 1200px;
//     align-self: center;
//   ` }
// `;

export default Payment;