
import { combineReducers } from 'redux';
import filterReducer from './filterReducer/filterReducer'

// import appReducer from './app/app.reducer';
import dataReducer from './data/data.reducer';

export default combineReducers({
  // app: appReducer,
  data: dataReducer,
  filter: filterReducer,
});
