import React, { PureComponent } from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';

import { COLOR_2 } from '../../../../styled/variables/variables';
import { DETAIL_VIEW } from '../../../search/view-mode-selector/ViewModeSeletor';
import { mqTablet, mqLandscape, dimmer } from '../../../../styled/helpers/helpers';
import AdTypeButtonList, { BUY_AD_TYPE } from './ad-type-button-list/AdTypeButtonList';

import realEstateTypes from '../../../../constants/real-estate-types';
import RealStateTypeSelector from './real-estate-type-selector/RealEstateTypeSelector';
import SearchAutocomplete from '../../../../components/search-autocomplete';
import Slogan from '../../../realtors-search/cover/slogan/Slogan_Realtors';
import SearchButton from './search-button/SearchButton';
import SearchInput from './search-input/SearchInput';

import pathsConstants from '../../../../constants/paths';
import geolocationService from '../../../../services/geolocation/geolocation.service';
import domEventsUtil from '../../../../utils/dom-events/dom-events.util';
import eventsConstants from '../../../../constants/events';
import googleMapsService from '../../../../services/google-maps/google-maps.service';
import queryStringUtil from '../../../../utils/query-string/query-string.util';


/*
  global
  setTimeout
  alert
  console
*/

const customId = "custom-id-yes";
class SearchForm extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      showRealStateSelector: false,
      realEstateTypes,
      showRealEstateDropdown: false,
      autocompleteAlternatives: [],
      selectedAutocomplete: null,

      selectedAdType: BUY_AD_TYPE,
      searchString: '',
      selectedRealEstateType: realEstateTypes[0].types[0],
    };
  }

  handleAdTypeSelection = event => {
    event.preventDefault();
    const { name } = event.target;

    this.setState({
      ...this.state,
      selectedAdType: parseInt(name),
    });
  };

  handleSearchStringChange = event => {
    const { value } = event.target;
    this.setState({
      ...this.state,
      searchString: value,
    });
  };

  handleDropdownClick = () => {
    const { showRealEstateDropdown } = this.state;
    this.setState({
      ...this.state,
      showRealEstateDropdown: !showRealEstateDropdown,
    });
  };

  handleDropdownItemClick = selectedRealEstateType => {
    const { showRealEstateDropdown } = this.state;
    this.setState({
      ...this.state,
      selectedRealEstateType,
      showRealEstateDropdown: !showRealEstateDropdown,
    });
  };

  search = event => {
    if (event) event.preventDefault();
    const {
      searchString,
      selectedAdType,
      selectedRealEstateType,
      selectedAutocomplete,
      autocompleteAlternatives
    } = this.state;

    let newSearchString;
    if (selectedAutocomplete) {
      newSearchString = autocompleteAlternatives.find(item => {
        return item.id === selectedAutocomplete;
      }).formatted;
    } else if (autocompleteAlternatives.length > 0) {
      newSearchString = autocompleteAlternatives[0].formatted;
    } else {
      newSearchString = searchString;
    }
    const { history } = this.props;

    history.push({
      pathname: pathsConstants.REALTORS_SEARCH,
      search: queryStringUtil.stringify(history, {
        text: searchString
      }),
    });
  };

  searchByLocation = async () => {
    domEventsUtil.dispatch({
      name: eventsConstants.LOADING_ANIMATION,
      params: { show: true, },
    });

    let address;
    try {
      const geolocation = await geolocationService.getCurrentGeolocation();
      address = await googleMapsService.getAddressByGeolocation(geolocation);
    } catch (error) {
      console.error(error.response);
      toast.error('Erro buscando localização. Por favor verifique se a permissão foi concedida', {
        autoClose: false,
        toastId: customId,
      });
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: false, },
      });
      return;
    }

    const { neighborhood, city, state, country } = address;
    let searchString = '';
    if (neighborhood) searchString += neighborhood;
    if (city) searchString += ` ${city}`;
    if (state) searchString += ` ${state}`;
    if (country) searchString += ` ${country}`;

    this.setState(state => ({
      ...state,
      searchString,
      autocompleteAlternatives: [],
      selectedAutocomplete: null,
    }), () => setTimeout(() => {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: false, },
      });
      this.search();
    }), 1000);
  };

  handleAutocompleteClick = addressAutocomplete => {
    this.setState({
      ...this.state,
      selectedAutocomplete: addressAutocomplete.id,
    }, () => {
      this.search();
    });
  };

  handleAutocompleteLoad = autocompleteAlternatives => {
    this.setState({
      ...this.state,
      autocompleteAlternatives,
    });
  };

  handleSelectedAutocompleteAlternativeUpdate = alternative => {
    this.setState({
      ...this.state,
      selectedAutocomplete: alternative.id,
    });
  };

  render() {
    const {
      searchString,
      showRealEstateDropdown,
      realEstateTypes,
      selectedRealEstateType,
    } = this.state;

    return (
      <Wrapper>
        <SearchBarWrapper>
          <SearchInput
            onChange={this.handleSearchStringChange}
            value={searchString}
            onPlaceholderClick={this.searchByLocation}
            history={this.props.history}
            handlePressFindRealtors={() => this.props.handlePressFindRealtors()}
          />
        </SearchBarWrapper>
        <SearchButton onClick={this.search} disabled={searchString ? false : true} />
        <SFSearchAutocomplete
          searchString={searchString}
          onItemClick={this.handleAutocompleteClick}
          onLoad={this.handleAutocompleteLoad}
          onSelectedAlternativeUpdate={this.handleSelectedAutocompleteAlternativeUpdate}
          show={searchString.length > 0}
        />
      </Wrapper>
    );
  }
}

SearchForm.propTypes = {
  history: propTypes.object,
};

SearchForm.defaultProps = {};

const Wrapper = styled.form`
  width: 100%;
  align-self: center;
  display: flex;
  flex-direction: column;

  ${mqTablet`
    // justify-content: center;
    max-width: 700px;
  ` };

  ${mqLandscape(200)`
    margin-top: 70px;
  `};
`;

const SearchBarWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 1px;
  ${mqTablet`
    border-radius: 1px;;
    min-height: 60px;
    max-height: 60px;
    flex-direction: row;
  ` }
`;

const SFSearchAutocomplete = styled(SearchAutocomplete)`
  max-height: 200px;
  width: calc(100% - 60px);
  position: absolute;
  margin-left: 20px;
  margin-top: 195px;
  ${mqTablet`
    margin-top: 60px;
    margin-left: 0;
    max-height: 250px;
    width: 700px;
  ` }
`;

export default withRouter(SearchForm);
