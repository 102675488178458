import styled from 'styled-components';

export const Header = styled.header`
  > span {
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 500;
  }
`;

export const FeedbackTypeStepWrapper = styled.div`
  display: flex;
  padding-top: 16px;
  padding-bottom: 16px;
  gap: 8px;
  width: 100%;

  > button {
    background-color: #FFFFFF;
    border-radius: 8px;
    padding-top: 18px;
    padding-bottom: 18px;
    width: 96px;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    transition: filter 0.2s;

    > span {
      font-weight: 500;
      color: #222222;
    }

    &:hover {
      filter: brightness(0.9);
    }
  }
`;
