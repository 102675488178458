import styled, { css } from 'styled-components';
import { transitions } from 'polished';
import { CloudUpload } from 'styled-icons/material/CloudUpload';
import { Images } from 'styled-icons/icomoon/Images';
import { Trash } from 'styled-icons/boxicons-solid/Trash';

import { backgrounder, iconBaseStyle, mqTablet, pointer, dimmer } from '../../styled/helpers/helpers';
import { BaseButton } from '../../styled/buttons/buttons';
import { BaseLabel } from '../../styled/labels/labels';
import { COLOR_2, COLOR_1, COLOR_6 } from '../../styled/variables/variables';

import Panorama from '../panorama';

const { PUBLIC_URL } = process.env;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InnerWrapper = styled.div``;

export const HiddenInput = styled.input``;

export const GalleryWrapper = styled.div`
  margin-bottom: 10px;
  > .List {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const GalleryAddButton = styled(BaseButton)`
  width: 100%;
  color: ${COLOR_2};
  background-color: ${COLOR_1};
  display: flex;
  justify-content: center;
  text-transform: unset;
  margin: 0;
  margin-bottom: 10px;
  border: none;
  justify-content: flex-start;

  ${mqTablet`
    ${transitions(['background-color', 'height'], 'ease .3s')};
    &:hover {
      background-color: ${COLOR_6};
    }

    ${({ fileHover }) => fileHover && css`
      height: 100px;
      background-color: ${COLOR_6};
    ` };
  ` };
`;

export const GalleryAddButtonLabel = styled(BaseLabel)`
  color: ${COLOR_2};
  align-self: center;
  font-weight: 300;
  opacity: 0.9;
  font-size: 14px;
`;

export const ImagesIcon = styled(Images)`
  ${iconBaseStyle};
  height: 14px;
  color: ${COLOR_2};
  align-self: center;
  margin-right: 10px;

  ${mqTablet`
    opacity: 0;
    ${transitions(['opacity'], 'ease .3s')}
    ${({ show }) => show && css`
      opacity: 1;
    ` }
  ` };
`;

export const PanoramaIcon = styled(ImagesIcon).attrs({
  as: 'img',
  src: `${PUBLIC_URL}/imgs/icons/view-360.svg`,
})``;

export const UploadIcon = styled(CloudUpload)`
  height: 15px;
  opacity: 0;
  align-self: center;
  margin-left: -20px;
  margin-right: 5px;
  ${mqTablet`
    ${transitions(['opacity', 'height'], 'ease .3s')};
    ${({ show }) => show && css`
      height: 50px;
      opacity: 1;
    ` }
  ` };
`;

export const GalleryImage = styled.div`
  ${({ src }) => backgrounder(src)};
  ${pointer};
  height: 100px;
  width: 100px;
  margin: 5px;
  border: solid 2px ${COLOR_2};
  border-radius: 1px;
  align-self: center;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  ${mqTablet`
    height: 115px;
    width: 115px;
    > div {
      ${transitions(['opacity ease .3s', 'width 0s .3s'])};
      width: 0;
      opacity: 0;
    }
    &:hover {
      > div {
        ${transitions(['opacity ease .3s', 'width 0s .0s'])};
        width: 100%;
        opacity: 1;
      }
    }
  ` };
`;

export const GalleryImageDim = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  ${dimmer()};
`;

export const DimTrashIcon = styled(Trash)`
  ${iconBaseStyle};
  color: ${COLOR_2};
  align-self: center;
  height: 15px;
`;

export const SelectedImageWrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 250px;
  opacity: 1;

  ${mqTablet`
    height: 300px;
  ` }
`;

export const SelectedImage = styled.div`
  height: 100%;
  width: 100%;
  ${({ url }) => backgrounder(url)}
  border-radius: 1px;
`;

export const PDPanorama = styled(Panorama)`
  min-height: 250px;
  max-height: 250px;
  margin-bottom: 10px;

  ${mqTablet`
    min-height: 300px;
    max-height: 300px;
  ` }
`;
