import styled, { css } from 'styled-components';
import propTypes from 'prop-types';
import { transitions } from 'polished';
import { Check } from 'styled-icons/fa-solid/Check';

import { iconBaseStyle, pointer } from '../../styled/helpers/helpers';
import { COLOR_4, COLOR_1, COLOR_2 } from '../../styled/variables/variables';

export const Wrapper = styled.div``;

export const IconWrapper = styled.div`
  height: 20px;
  width: 20px;
  border-radius: 1px;
  display: flex;
  justify-content: center;
  border: solid 1px ${COLOR_4};
  background-color: ${COLOR_2};
`;

export const Icon = styled(Check)`
  ${iconBaseStyle}
  color: ${COLOR_1};
  align-self: center;
  height: 10px;
  opacity: 0;

  ${transitions(['opacity'], 'ease .3s')}

  ${({ show }) => show && css`
    opacity: 1;
  ` }
`;

export const Input = styled.input.attrs({
  type: 'checkbox',
})`
  height: 20px;
  width: 20px;
  margin-top: -20px;
  opacity: 0;
  display: block;
  ${pointer}
`;
