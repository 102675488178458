import React, { PureComponent } from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';

import { MaskedBaseInput, masks, BaseInput } from '../../../../styled/inputs/inputs';

import Checkbox from '../../../../components/checkbox';
import InputWrapper from '../../../../components/input-wrapper';

import validationUtil from '../../../../utils/validation/validation.util';

class CreatorInfo extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        name: props.name,
        email: props.email,
        phoneNumber: props.telephone,
        whatsApp: true,
      },
      fieldsHasFilled: false,
      showErrorsFor: {},
    };
  }

  handleFormChange = event => {
    const { name, value, type, checked } = event.target;

    this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        [name]: type === 'checkbox' ? checked : value,
      },
    }));

    if (name === 'phoneNumber') {
      this.setState(state => ({
        ...state,
        form: {
          ...state.form,
          phoneNumber: value.replace(/[^a-zA-Z0-9]+/g, '').substr(2),
        }
      }));
    }
  };

  handleInputBlur = event => {
    const { name } = event.target;

    this.setState(state => ({
      ...state,
      showErrorsFor: {
        ...state.showErrorsFor,
        [name]: true,
      },
    }));
  };

  render() {
    const { showInvalids, isEditing, populate } = this.props;
    const { form, showErrorsFor, fieldsHasFilled } = this.state;

    if (isEditing) {
      if ((!!populate.name || !!populate.email) && !fieldsHasFilled) {
        const number = populate.phone.number.split('');

        let numberMasked = '';

        if (number.length === 11) {
          numberMasked = `+55 (${number[0]}${number[1]})
        ${number[2]}${number[3]}${number[4]}${number[5]}${number[6]}-
        ${number[7]}${number[8]}${number[9]}${number[10]}`;
        }

        this.setState(prevState => ({
          ...prevState,
          fieldsHasFilled: true,
          form: {
            ...prevState,
            name: populate.name,
            email: populate.email,
            phoneNumber: numberMasked,
            whatsApp: populate.phone.whatsApp,
          }
        }));
      }
    }

    return (
      <Wrapper onChange={this.handleFormChange}>
        <div className={'form-floating'}>
          <NameInput
            name='name'
            id={'name'}
            className={'form-control'}
            style={{ 'width': '100%' }}
            value={form.name}
            defaultValue={form.name}
            onBlur={this.handleInputBlur}
            isInvalid={(() => {
              if (!showErrorsFor.name && !showInvalids) return;

              return !validationUtil.text({
                minLength: 3,
                value: form.name,
              });
            })()}
          />
          <label className={'form-label'} htmlFor={'name'}>Nome</label>
        </div>
        <div className={'form-floating'}>
          <EmailInput
            name='email'
            value={form.email}
            className={'form-control'}
            style={{ 'width': '100%' }}
            id={'email'}
            onBlur={this.handleInputBlur}
            isInvalid={(() => {
              if (!showErrorsFor.email && !showInvalids) return;

              return !validationUtil.email({
                value: form.email,
              });
            })()}
          />
          <label className={'form-label'} htmlFor={'email'}>Email</label>
        </div>

        <div className={'form-floating'}>
          <PhoneNumberInput
            name='telephone'
            id={'telephone'}
            className={'form-control'}
            style={{ 'width': '100%' }}
            value={form.phoneNumber}
            onBlur={this.handleInputBlur}
            isInvalid={(() => {
              if (!showErrorsFor.phoneNumber && !showInvalids) return;

              return !validationUtil.text({
                value: form.phoneNumber,
                minLength: 18,
                maxLength: 19,
              }) && form.phoneNumber.length > 0;
            })()}
          />
          <label className={'form-label'} htmlFor={'telephone'}>Telefone/Celular</label>
        </div>
        <CheckboxInputWrapper
          label='WhatsApp?'
          htmlFor='whatsApp'
        >
          <HasWhatsappInput
            id='whatsApp'
            name='whatsApp'
            checked={form.whatsApp}
          />
        </CheckboxInputWrapper>
      </Wrapper>
    )
  }
}

CreatorInfo.propTypes = {
  showInvalids: propTypes.bool,
};

CreatorInfo.defaultProps = {
  showInvalids: false,
};

const Wrapper = styled.form`
  margin-bottom: 70px;
`;

const NameInput = styled(BaseInput).attrs({
  name: 'name',
  placeholder: 'Nome',
})``;

const EmailInput = styled(BaseInput).attrs({
  name: 'email',
  placeholder: 'E-mail',
  type: 'email',
})``;

const PhoneNumberInput = styled(NameInput).attrs({
  as: MaskedBaseInput,
  mask: masks.phones,
  greedy: false,
  name: 'phoneNumber',
  placeholder: 'Telefone/Celular',
})``;

const CheckboxInputWrapper = styled(InputWrapper)`
  flex-direction: row-reverse;
  justify-content: flex-end;
`;

const HasWhatsappInput = styled(Checkbox).attrs({
  name: 'whatsApp',
})`
  margin-right: 10px;
`;

export default CreatorInfo;
