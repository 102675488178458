import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';

import { ProfilePicture } from '../../../../styled/components/components';
import { COLOR_2 } from '../../../../styled/variables/variables';
import { BaseLabel } from '../../../../styled/labels/labels';
import { hexToRgb, mqTablet } from '../../../../styled/helpers/helpers';

const Info = ({
  type,
  name,
  phoneNumber,
  email,
}) => (
  <Wrapper>
    <CFProfilePicture />
    <UserType>
      { type }
    </UserType>
    <InfoText>
      { name }
    </InfoText>
    <InfoText>
      Tel.: { phoneNumber }
    </InfoText>
    <InfoText>
      { email }
    </InfoText>
  </Wrapper>
);

Info.propTypes = {
  type: propTypes.string,
  name: propTypes.string,
  phoneNumber: propTypes.string,
  email: propTypes.string,
};

Info.defaultProps = {
  type: 'corretor de imóveis',
  name: 'Fulano de Tal',
  phoneNumber: '(99) 99999-9999',
  email: 'email@domain.com',
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  border-bottom: solid 1px ${ hexToRgb(COLOR_2, 0.7) };
  margin-bottom: 20px;

  ${ mqTablet`
    margin: 0;
    flex-grow: 1;
    width: 1px;
    margin-right: 50px;
    align-self: flex-start;
    border-top: solid 1px ${ hexToRgb(COLOR_2, 0.7) };
    padding: 30px 0;
    margin-top: 27px;
  ` }
`;

const CFProfilePicture = styled(ProfilePicture)`
  align-self: center;
  margin-bottom: 20px;
  height: 90px;
  width: 80px;
`;

const UserType = styled(BaseLabel)`
  font-size: 12px;
  text-transform: uppercase;
  color: ${ COLOR_2 };
  opacity: .7;
  align-self: center;
  margin-bottom: 10px;
`;

const InfoText = styled(UserType)`
  font-size: 10px;
  margin: 0;
  text-transform: none;
`;

export default Info;
