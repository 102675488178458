import React, { PureComponent } from 'react';
import styled from 'styled-components';

import { MaskedBaseInput, masks } from '../../../../styled/inputs/inputs';
import { mqTablet } from '../../../../styled/helpers/helpers';

import validationUtil from '../../../../utils/validation/validation.util';

class MainInfo extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      fieldsHasFilled: false,
      form: {
        bedrooms: props.bedrooms,
        bathrooms: props.bathrooms,
        suites: props.suites,
        parkingSpaces: props.parkingSpaces,
        area: props.area,
        totalArea: props.totalArea
      },
      showErrorsFor: {},
    };
  }

  handleFormChange = event => {
    const { name, value } = event.target;

    this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        [name]: value,
      },
    }));
  };

  handleInputBlur = event => {
    const { name } = event.target;

    this.setState(state => ({
      ...state,
      showErrorsFor: {
        ...state.showErrorsFor,
        [name]: true,
      },
    }));
  };

  render() {
    const { showInvalids, isEditing, populate } = this.props;
    const { form, showErrorsFor, fieldsHasFilled } = this.state;

    if (isEditing) {
      if (!!populate.bedrooms && !!populate.bathrooms &&
        !!populate.suites  && !!populate.parkingSpaces &&
        !!populate.area  && !!populate.totalArea  &&
        !fieldsHasFilled) {
        this.setState(prevState => ({
          ...prevState,
          fieldsHasFilled: true,
          form: {
            ...prevState.form,
            bedrooms: populate.bedrooms,
            bathrooms: populate.bathrooms,
            suites: populate.suites,
            parkingSpaces: populate.parkingSpaces,
            area: populate.area,
            totalArea: populate.totalArea,
          }
        }))
      }
    }

    return (
      <Wrapper onChange={this.handleFormChange}>
        <TabletInliner>
          <div style={{ flexDirection: 'column', width: "100%", marginRight: 15 }}>
            <div className={'form-floating'}>
              <BedroomsInput
                defaultValue={form.bedrooms}
                id={'bedrooms'}
                mask={masks.integer()}
                onBlur={this.handleInputBlur}
                className={'form-control'}
                style={{ 'width': '100%' }}
                isInvalid={(() => {
                  if (!showErrorsFor.bedrooms && !showInvalids) return;
                  return !validationUtil.number({
                    min: 0,
                    value: form.bedrooms,
                  });
                })()}
              />
              <label htmlFor="bedrooms" className="form-label">Nº Quartos</label>
            </div>
          </div>
          <div style={{ flexDirection: 'column', width: "100%" }}>
            <div className={'form-floating'}>
              <Bathrooms
                defaultValue={form.bathrooms}
                mask={masks.integer()}
                id={'bathrooms'}
                className={'form-control'}
                style={{ 'width': '100%' }}
                onBlur={this.handleInputBlur}
                isInvalid={(() => {
                  if (!showErrorsFor.bathrooms && !showInvalids) return;
                  return !validationUtil.number({
                    min: 0,
                    value: form.bathrooms,
                  });
                })()}
              />
              <label htmlFor="bathrooms" className="form-label">Nº Banheiros</label>
            </div>
          </div>
        </TabletInliner>
        <TabletInliner>
          <div style={{ flexDirection: 'column', width: "100%", marginRight: 15 }}>
            <div className={'form-floating'}>
              <SuitesInput
                defaultValue={form.suites}
                mask={masks.integer()}
                id={'suites'}
                className={'form-control'}
                style={{ 'width': '100%' }}
                onBlur={this.handleInputBlur}
                isInvalid={(() => {
                  // if(!showErrorsFor.suites && !showInvalids) return;
                  // return !validationUtil.number({
                  //   min: 0,
                  //   value: form.suites,
                  // });
                })()}
              />
              <label className={'form-label'} htmlFor={'suites'}>Nº Suítes</label>
            </div>
          </div>
          <div style={{ flexDirection: 'column', width: "100%" }}>
            <div className={'form-floating'}>
              <ParkingSpacesInput
                defaultValue={form.parkingSpaces}
                mask={masks.integer()}
                onBlur={this.handleInputBlur}
                className={'form-control'}
                style={{ 'width': '100%' }}
                id={'parkingSpaces'}
                isInvalid={(() => {
                  // if(!showErrorsFor.parkingSpaces && !showInvalids) return;
                  // return !validationUtil.number({
                  //   min: 0,
                  //   value: form.parkingSpaces,
                  // });
                })()}
              />
              <label className={'form-label'} htmlFor={'parkingSpaces'}>Nº Vagas</label>
            </div>
          </div>
        </TabletInliner>
        <TabletInliner>
          <div style={{ flexDirection: 'column', width: "100%", marginRight: 15 }}>
            <div className={'form-floating'}>
              <AreaInput
                defaultValue={form.area}
                mask={masks.integer()}
                id={'area'}
                className={'form-control'}
                style={{ 'width': '100%' }}
                onBlur={this.handleInputBlur}
                isInvalid={(() => {
                  if (!showErrorsFor.area && !showInvalids) return;
                  return !validationUtil.number({
                    min: 0,
                    value: form.area,
                  });
                })()}
              />
              <label className={'form-label'} htmlFor={'area'}>Área útil (m²)</label>
            </div>
          </div>
          <div style={{ flexDirection: 'column', width: "100%" }}>
            <div className={'form-floating'}>
              <TotalAreaInput
                defaultValue={form.totalArea}
                mask={masks.integer()}
                onBlur={this.handleInputBlur}
                id={'totalArea'}
                className={'form-control'}
                style={{ 'width': '100%' }}
                isInvalid={(() => {
                  // if(!showErrorsFor.totalArea && !showInvalids) return;
                  // return !validationUtil.number({
                  //   min: 0,
                  //   value: form.totalArea,
                  // });
                })()}
              />
              <label className={'form-label'} htmlFor={'totalArea'}>Área Total (m²)</label>
            </div>
          </div>
        </TabletInliner>
      </Wrapper>
    )
  }
}

const Wrapper = styled.form`
  margin-bottom: 50px;
`;

const TabletInliner = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  ${mqTablet`
    flex-direction: row;
  ` }
`;

const BedroomsInput = styled(MaskedBaseInput).attrs({
  name: 'bedrooms',
  placeholder: 'Nº quartos',
})`
  ${mqTablet`
    margin: 0;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  ` }
`;

const Bathrooms = styled(BedroomsInput).attrs({
  name: 'bathrooms',
  placeholder: 'Nº banheiros',
})``;

const SuitesInput = styled(BedroomsInput).attrs({
  name: 'suites',
  placeholder: 'Nº suítes'
})``;

const ParkingSpacesInput = styled(BedroomsInput).attrs({
  name: 'parkingSpaces',
  placeholder: 'Nº vagas'
})``;

const AreaInput = styled(BedroomsInput).attrs({
  name: 'area',
  placeholder: 'Área útil (m²)'
})``;

const TotalAreaInput = styled(BedroomsInput).attrs({
  name: 'totalArea',
  placeholder: 'Área total (m²)'
})``;

export default MainInfo;
