import React from 'react';
import styled from 'styled-components';

import { BaseLabel } from '../../styled/labels/labels';
import { COLOR_1, COLOR_3 } from '../../styled/variables/variables';
import { mqTablet } from '../../styled/helpers/helpers';

const PrivacyPolicy = () => (
    <Wrapper>
        <Title>POLÍTICA DE PRIVACIDADE</Title>

        {blocks.map(({ title, topic, level1, level2, }, index) => (
            <BlockWrapper key={index}>
                {title && (
                    <SectionTitle>
                        {title}
                    </SectionTitle>
                )}

                {topic && (
                    <Topic>{topic}</Topic>
                )}

                {level1 && (
                    <TextBlockLevel1>
                        {level1}
                    </TextBlockLevel1>
                )}

                {level2 && (
                    <TextBlockLevel2>
                        {level2}
                    </TextBlockLevel2>
                )}

            </BlockWrapper>
        ))}
    </Wrapper>
);

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 10px;
  padding-top: 130px;
  padding-bottom: 50px;

  ${mqTablet`
    padding-top: 200px;
    padding-bottom: 200px;
  ` }
`;

const BlockWrapper = styled.div`
  margin-bottom: 30px;

  ${mqTablet`
    max-width: 1000px;
    align-self: left;
    width: 70%; 
    margin: 0 auto; 
    margin-bottom: 30px;
    text-align: left
  ` }
`;

const Title = styled(BaseLabel)`
  align-self: center;
  font-size: 28px;
  font-weight: 800;
  margin-bottom: 30px;
`;

const SectionTitle = styled(Title)`
  color: ${COLOR_1};
  margin-bottom: 20px;
  margin-top: 30px;
  font-size: 24px;
  font-weight: 700;
`;

const Topic = styled(Title)`
  font-size: 20px;
  color: ${COLOR_1};
  align-self: flex-start;
  margin-bottom: 10px;
`;

const TextBlockLevel1 = styled.p`
  padding-left: 5px;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: ${COLOR_3};
  font-size: 16px;
  text-align: justify;
`;

const TextBlockLevel2 = styled(TextBlockLevel1)`
  padding-left: 25px;
`;

const blocks = [
    {
        title: '1. Informações gerais',
        level1: 'A presente Política de Privacidade contém informações a respeito do modo como tratamos, total ou parcialmente, de forma automatizada ou não, os dados pessoais dos usuários que acessam nosso site. Seu objetivo é esclarecer os interessados acerca dos tipos de dados que são coletados, dos motivos da coleta e da forma como o usuário poderá atualizar, gerenciar ou excluir estas informações.',
    },
    {
        level1: 'Esta Política de Privacidade foi elaborada em conformidade com a Lei Federal n. 12.965 de 23 de abril de 2014 (Marco Civil da Internet), com a Lei Federal n. 13.709, de 14 de agosto de 2018 (Lei de Proteção de Dados Pessoais) e com o Regulamento UE n. 2016/679 de 27 de abril de 2016 (Regulamento Geral Europeu de Proteção de Dados Pessoais - RGDP).'
    },
    { level1: 'Esta Política de Privacidade poderá ser atualizada em decorrência de eventual atualização normativa, razão pela qual se convida o usuário a consultar periodicamente esta seção.' },

    {
        title: '2. Direitos do usuário',
        level1: 'O usuário do site possui os seguintes direitos, conferidos pela Lei de Proteção de Dados Pessoais e pelo RGPD:'
    },
    { level1: '- Direito de confirmação e acesso: é o direito do usuário de obter do site a confirmação de que os dados pessoais que lhe digam respeito são ou não objeto de tratamento e, se for esse o caso, o direito de acessar os seus dados pessoais;' },
    { level1: '- Direito de retificação: é o direito do usuário de obter do site a retificação dos dados pessoais inexatos que lhe digam respeito;' },
    { level1: '- Direito à limitação do tratamento dos dados: é o direito do usuário de limitar o tratamento de seus dados pessoais,  quando o tratamento for ilícito;' },
    { level1: '- Direito de oposição: é o direito do usuário de, a qualquer momento, se opor por motivos relacionados com a sua situação particular, ao tratamento dos dados pessoais que lhe digam respeito;' },
    { level1: 'O usuário poderá exercer os seus direitos por meio de comunicação escrita enviada ao site. O pedido deverá ser enviado ao e-mail: atendimento@koortimoveis.com.br;' },

    {
        title: '3. Dever de não fornecer dados de terceiros ',
        level1: 'Durante a utilização do site, a fim de resguardar e de proteger os direitos de terceiros, o usuário do site deverá fornecer somente seus dados pessoais, e não os de terceiros.'
    },

    {
        title: '4. Informações coletadas ',
        level1: '4.1.1. Dados de identificação do usuário para realização de cadastro',
        level2: 'A utilização, pelo usuário, de determinadas funcionalidades do site dependerá de cadastro, sendo que, nestes casos, os seguintes dados do usuário serão coletados e armazenados: nome, data de nascimento, endereço de e-mail, endereço postal, detalhes de redes sociais, número de telefone, número de CPF e número de CNPJ. ',

    },
    { level1: '4.1.2. Dados informados no formulário de contato' },
    { level2: 'Os dados eventualmente informados pelo usuário que utilizar o formulário de contato disponibilizado no site, incluindo o teor da mensagem enviada, serão coletados e armazenados.' },
    { level1: '4.1.3. Dados relacionados à execução de contratos firmados com o usuário.' },
    { level2: 'Para a execução de contrato de compra e venda ou de prestação de serviços eventualmente firmado entre o site e o usuário, poderão ser coletados e armazenados outros dados relacionados ou necessários a sua execução, incluindo o teor de eventuais comunicações tidas com o usuário.' },
    { level1: '4.1.4. Registros de acesso' },
    { level2: 'Em atendimento às disposições do art. 15, caput e parágrafos, da Lei Federal n. 12.965/2014 (Marco Civil da Internet), os registros de acesso do usuário serão coletados e armazenados por, pelo menos, seis meses.' },
    { level1: '4.1.5. Newsletter' },
    { level2: 'O endereço de e-mail cadastrado pelo usuário que optar por se inscrever em nossa Newsletter será coletado e armazenado até que o usuário solicite seu descadastro.' },
    { level1: '4.1.6. Dados sensíveis' },
    { level2: 'Não serão coletados dados sensíveis dos usuários, assim entendidos aqueles definidos nos arts. 9º e 10 do RGPD e nos arts. 11 e seguintes da Lei de Proteção de Dados Pessoais. Assim, dentre outros, não haverá coleta dos seguintes dados: - dados que revelem a origem racial ou étnica, as opiniões políticas, as convicções religiosas ou filosóficas, ou a filiação sindical do usuário; - dados genéticos; - dados biométricos para identificar uma pessoa de forma inequívoca; - dados relativos à saúde do usuário; - dados relativos à vida sexual ou à orientação sexual do usuário; - dados relacionados a condenações penais ou a infrações ou com medidas de segurança conexas.' },
    { level1: '4.2. Fundamento jurídico para o tratamento dos dados pessoais' },
    { level2: 'Poderão ainda ser coletados dados pessoais necessários para a execução e cumprimento dos serviços contratados pelo usuário no site. O tratamento de dados pessoais sem o consentimento do usuário apenas será realizado em razão de interesse legítimo ou para as hipóteses previstas em lei, ou seja, dentre outras, as seguintes: - para o cumprimento de obrigação legal ou regulatória pelo controlador; - para a realização de estudos por órgão de pesquisa, garantida, sempre que possível, a anonimização dos dados pessoais; - quando necessário para a execução de contrato ou de procedimentos preliminares relacionados a contrato do qual seja parte o usuário, a pedido do titular dos dados; - para o exercício regular de direitos em processo judicial, administrativo ou arbitral, esse último nos termos da Lei nº 9.307, de 23 de setembro de 1996 (Lei de Arbitragem); - para a proteção da vida ou da incolumidade física do titular dos dados ou de terceiro; - para a tutela da saúde, em procedimento realizado por profissionais da área da saúde ou por entidades sanitárias; - quando necessário para atender aos interesses legítimos do controlador ou de terceiro, exceto no caso de prevalecerem direitos e liberdades fundamentais do titular dos dados que exijam a proteção dos dados pessoais; - para a proteção do crédito, inclusive quanto ao disposto na legislação pertinente.' },
    { level2: '4.3. Finalidades do tratamento dos dados pessoais' },
    { level2: 'Os dados pessoais do usuário coletados pelo site têm por finalidade facilitar, agilizar e cumprir os compromissos estabelecidos com o usuário e a fazer cumprir as solicitações realizadas por meio do preenchimento de formulários.' },
    { level2: 'Os dados pessoais poderão ser utilizados também com uma finalidade comercial, para personalizar o conteúdo oferecido ao usuário, bem como para dar subsídio ao site para a melhora da qualidade e funcionamento de seus serviços.' },
    { level2: 'O site recolhe os dados do usuário para que seja realizada definição de perfis, ou seja, tratamento automatizado de dados pessoais que consista em utilizar estes dados para avaliar certos aspectos pessoais do usuário, principalmente para analisar ou prever características relacionadas ao seu desempenho profissional, a sua situação econômica, saúde, preferências pessoais, interesses, fiabilidade, comportamento, localização ou deslocamento.' },
    { level2: 'Os dados de cadastro serão utilizados para permitir o acesso do usuário a determinados conteúdos do site, exclusivos para usuários cadastrados.' },
    { level2: 'A coleta de dados relacionados ou necessários à execução de um contrato de compra e venda ou de prestação de serviços eventualmente firmado com o usuário terá a finalidade de conferir às partes segurança jurídica, além de facilitar e viabilizar a conclusão do negócio.' },
    { level1: '4.4. Prazo de conservação dos dados pessoais' },
    { level2: 'Os dados pessoais do usuário serão conservados por um período não superior ao exigido para cumprir os objetivos em razão dos quais eles são processados.' },
    { level2: 'Os dados pessoais dos usuários apenas poderão ser conservados após o término de seu tratamento nas seguintes hipóteses: - para o cumprimento de obrigação legal ou regulatória pelo controlador; - para estudo por órgão de pesquisa, garantida, sempre que possível, a anonimização dos dados pessoais; - para a transferência a terceiro, desde que respeitados os requisitos de tratamento de dados dispostos na legislação; - para uso exclusivo do controlador, vedado seu acesso por terceiro, e desde que anonimizados os dados.' },
    { level1: '4.5. Destinatários e transferência dos dados pessoais' },
    { level2: 'A transferência apenas poderá ser feita para outro país caso o país ou território em questão ou a organização internacional em causa assegurem um nível de proteção adequado dos dados do usuário.' },
    { level2: 'Caso não haja nível de proteção adequado, o site se compromete a garantir a proteção dos seus dados de acordo com as regras mais rigorosas, por meio de cláusulas contratuais específicas para determinada transferência, cláusulas-padrão contratuais, normas corporativas globais ou selos, certificados e códigos de conduta regularmente emitidos.' },

    {
        title: '5. Segurança no tratamento dos dados pessoais do usuário',
        level1: 'O site se compromete a aplicar as medidas técnicas e organizativas aptas a proteger os dados pessoais de acessos não autorizados e de situações de destruição, perda, alteração, comunicação ou difusão de tais dados.'
    },
    { level1: 'Para a garantia da segurança, serão adotadas soluções que levem em consideração: as técnicas adequadas; os custos de aplicação; a natureza, o âmbito, o contexto e as finalidades do tratamento; e os riscos para os direitos e liberdades do usuário.' },
    { level1: 'O site utiliza certificado SSL (Secure Socket Layer) que garante que os dados pessoais se transmitam de forma segura e confidencial, de maneira que a transmissão dos dados entre o servidor e o usuário, e em retroalimentação, ocorra de maneira totalmente cifrada ou encriptada.' },
    { level1: 'No entanto, o site se exime de responsabilidade por culpa exclusiva de terceiro, como em caso de ataque de hackers ou crackers, ou culpa exclusiva do usuário, como no caso em que ele mesmo transfere seus dados a terceiro. ' },
    { level1: 'Por fim, o site se compromete a tratar os dados pessoais do usuário com confidencialidade, dentro dos limites legais.' },

    {
        title: '6. Dados de navegação (cookies)',
        level1: 'Cookies são pequenos arquivos de texto enviados pelo site ao computador do usuário e que nele ficam armazenados, com informações relacionadas à navegação do site.'
    },
    { level1: 'Por meio dos cookies, pequenas quantidades de informação são armazenadas pelo navegador do usuário para que nosso servidor possa lê-las posteriormente. Podem ser armazenados, por exemplo, dados sobre o dispositivo utilizado pelo usuário, bem como seu local e horário de acesso ao site. ' },
    { level1: 'Os cookies não permitem que qualquer arquivo ou informação sejam extraídos do disco rígido do usuário, não sendo possível, ainda, que, por meio deles, se tenha acesso a informações pessoais que não tenham partido do usuário ou da forma como utiliza os recursos do site.' },
    { level1: 'É importante ressaltar que nem todo cookie contém informações que permitem a identificação do usuário, sendo que determinados tipos de cookies podem ser empregados simplesmente para que o site seja carregado corretamente ou para que suas funcionalidades funcionem do modo esperado.  ' },
    { level1: '6.1. Cookies do site' },
    { level2: 'Os cookies do site são aqueles enviados ao computador ou dispositivo do usuário e administrador exclusivamente pelo site.' },
    { level2: 'As informações coletadas por meio destes cookies são utilizadas para melhorar e personalizar a experiência do usuário, sendo que alguns cookies podem, por exemplo, ser utilizados para lembrar as preferências e escolhas do usuário, bem como para o oferecimento de conteúdo personalizado.' },
    { level1: '6.2. Cookies de terceiros' },
    { level2: 'Alguns de nossos parceiros podem configurar cookies nos dispositivos dos usuários que acessam nosso site.' },
    { level2: 'Estes cookies, em geral, visam possibilitar que nossos parceiros possam oferecer seu conteúdo e seus serviços ao usuário que acessa nosso site de forma personalizada, por meio da obtenção de dados de navegação extraídos a partir de sua interação com o site.' },
    { level1: '6.3. Cookies de redes sociais' },
    { level2: 'O site utiliza plugins de redes sociais, que permitem acessá-las a partir do site. Assim, ao fazê-lo, os cookies utilizados por elas poderão ser armazenados no navegador do usuário.' },
    { level2: 'Cada rede social possui sua própria política de privacidade e de proteção de dados pessoais, sendo as pessoas físicas ou jurídicas que as mantêm responsáveis pelos dados coletados e pelas práticas de privacidade adotadas. O usuário pode pesquisar, junto às redes sociais, informações sobre como seus dados pessoais são tratados.' },
    { level1: '6.4. Gestão dos cookies e configurações do navegador' },
    { level2: 'O usuário poderá se opor ao registro de cookies pelo site, bastando que desative esta opção no seu próprio navegador ou aparelho.' },
    { level2: 'A desativação dos cookies, no entanto, pode afetar a disponibilidade de algumas ferramentas e funcionalidades do site, comprometendo seu correto e esperado funcionamento. Outra consequência possível é remoção das preferências do usuário que eventualmente tiverem sido salvas, prejudicando sua experiência.' },

    {
        title: '7. Das alterações',
        level1: 'A presente versão desta Política de Privacidade foi atualizada pela última vez em 31/05/2020. O editor se reserva o direito de modificar, a qualquer momento o site as presentes normas, especialmente para adaptá-las às evoluções da plataforma KOORTimóveis, seja pela disponibilização de novas funcionalidades, seja pela supressão ou modificação daquelas já existentes.'
    },
    { level1: 'Ao utilizar o serviço após eventuais modificações, o usuário demonstra sua concordância com as novas normas. Caso discorde de alguma das modificações, deverá pedir, imediatamente, o cancelamento de sua conta e apresentar a sua ressalva ao serviço de atendimento, se assim o desejar.' },

];

export default PrivacyPolicy;
