import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { InputBootstrap } from '../../components/form/input-bootstrap';
import { SelectBootstrap } from '../../components/form/select-bootstrap';
import { TextArea } from '../../components/form/textarea';

import reportAdService from '../../services/report_ads/report_ads.service'

import pathsConstants from '../../constants/paths';
import eventConstants from '../../constants/events';
import domEventsUtil from '../../utils/dom-events/dom-events.util';

import {
  Wrapper,
  Title,
  Form,
  SendButton,
} from './styles';

const reportFormSchema = yup.object().shape({
  name: yup.string()
    .required('Este campo é obrigatório')
    .min(3, 'Mínimo de 3 caracteres')
    .max(30, 'Máximo de 30 caracteres'),
  email: yup.string()
    .required('Este campo é obrigatório')
    .email('Informe um e-mail válido'),
  subject: yup.string()
    .required('Selecione um assunto'),
  description: yup.string()
    .required('Este campo é obrigatório')
    .min(10, 'Mínimo de 10 caracteres')
    .max(2000, 'Máximo de 2000 caracteres'),
});

const subjects = [
  { value: 'Palavras inadequadas no texto do anúncio', label: 'Palavras inadequadas no texto do anúncio', },
  { value: 'Endereço incorreto', label: 'Endereço incorreto', },
  { value: 'Fotos falsas', label: 'Fotos falsas', },
  { value: 'Imóvel anunciado sem autorização', label: 'Imóvel anunciado sem autorização', },
  { value: 'Imóvel já vendido ou alugado', label: 'Imóvel já vendido ou alugado', },
  { value: 'Imóvel não existente', label: 'Imóvel não existente', },
  { value: 'Anunciante não responde', label: 'Anunciante não responde', },
  { value: 'Preço incorreto', label: 'Preço incorreto', },
  { value: 'Outros', label: 'Outros', },
];

const customId = "custom-id-yes";

export const AdReport = (props) => {
  const customId = "custom-id-yes";
  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(reportFormSchema),
  });

  const { errors } = formState;

  const handleFormSubmit = useCallback(async values => {
    try {
      domEventsUtil.dispatch({
        name: eventConstants.LOADING_ANIMATION,
        params: { show: true, },
      });

      const url = window.location.href;
      const id = url.split('adId=')[1];

      const formData = {
        name: values.name,
        email: values.email,
        reportAdType: values.subject,
        reportAdDescription: values.description
      };

      const data = await reportAdService.sendMessageReport({ data: formData, id })

      if (data.response && data.response.status !== 200) {
        throw new Error(data);
      }

      toast.info('Sua denùncia foi enviada com sucesso!', {
        autoClose: false,
        toastId: customId,
      });

      props.history.push(pathsConstants.HOME)
    } catch {
      toast.error('Ocorreu um erro, tente novamente!', {
        autoClose: false,
        toastId: customId,
      });
    } finally {
      domEventsUtil.dispatch({
        name: eventConstants.LOADING_ANIMATION,
        params: { show: false, },

      });
    }
  }, [window.location.href]);

  return (
    <Wrapper onSubmit={handleSubmit(handleFormSubmit)}>
      <Title>Denúncia de Anúncio</Title>
      <Form>
        <div className="mb-3">
          <InputBootstrap
            name="name"
            label="Nome"
            placeholder="Nome"
            error={errors.name}
            {...register('name')}
          />
        </div>

        <div className="mb-3">
          <InputBootstrap
            name="email"
            label="E-mail"
            placeholder="E-mail"
            error={errors.email}
            {...register('email')}
          />
        </div>

        <div>
          <SelectBootstrap
            name="subject"
            label="Problema identificado"
            options={subjects}
            error={errors.subject}
            {...register('subject')}
          />
        </div>

        <TextArea
          name="description"
          label="Descrição"
          height={100}
          error={errors.description}
          {...register('description')}
        />

        <SendButton>enviar</SendButton>
      </Form>
    </Wrapper>
  )
}
