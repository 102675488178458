import React, { PureComponent } from 'react';
import styled from 'styled-components';
import Select from 'react-select';

import { BaseLabel } from '../../../../styled/labels/labels';

class SurroundingFeatures extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        features: [],
      },
      fieldsHasFilled: false,
      surroundingsPropertiesUnformatted: [],
      surroundingsPropertiesFormatted: [],
    };
  }

  handleSelectChange = (arr, name) => {
    if (name === "selectedOptionsSurroundings") {
      this.setState(prevState => ({
        ...prevState,
        surroundingsPropertiesUnformatted: arr,
        surroundingsPropertiesFormatted: arr !== null ?
          arr.map(item => item.value) : [],
      }));
    }

    this.setState(prevState => ({
      ...prevState,
      form: {
        features: prevState.surroundingsPropertiesFormatted,
      }
    }));
  }

  render() {
    const { isEditing, populate, data } = this.props;
    const { fieldsHasFilled } = this.state;

    if (isEditing) {
      if (populate.length > 0 && !fieldsHasFilled && !!data.length) {
        let surroundingsProperties = [];

        for (let i = 0; i < data.length; i++) {
          for (let j = 0; j < populate.length; j++) {
            if (data[i].key === populate[j].key) {
              surroundingsProperties.push({
                label: data[i].label,
                value: data[i].value,
              });
            }
          }
        }

        this.setState(prevState => ({
          ...prevState,
          fieldsHasFilled: true,
          surroundingsPropertiesUnformatted: surroundingsProperties,
          surroundingsPropertiesFormatted: surroundingsProperties.map(item => item.label),
          form: {
            features: surroundingsProperties.map(item => item.label),
          }
        }));
      }
    }

    const customStyle = {
      control: base => ({
        ...base,
        border: '1px solid #999',
        boxShadow: 'none'
      }),
      option: (provided, state) => ({
        ...provided,
        fontSize: 16,
      }),
      input: (provided, state) => ({
        ...provided,
        fontSize: 25,
      }),
    }

    return (
      <Wrapper>
        <GroupTitle>
          informações da vizinhança
        </GroupTitle>
        <GroupWrapper>
          <Select
            styles={customStyle}
            noOptionsMessage={() => 'Sem opções'}
            value={this.state.surroundingsPropertiesUnformatted}
            onChange={(value) => { this.handleSelectChange(value, 'selectedOptionsSurroundings') }}
            name="optionsServices"
            id="optionsServices"
            placeholder={'Selecione'}
            options={data}
            isMulti={true}
            theme={theme => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary: 'none',
              },
            })}
          />
        </GroupWrapper>
      </Wrapper>
    );
  }
}

const Wrapper = styled.form`
  margin-bottom: 20px;
`;

const GroupTitle = styled(BaseLabel)`
  text-transform: uppercase;
  font-size: 14px;
  margin-bottom: 10px;
`;

const GroupWrapper = styled.div`
  margin-bottom: 20px;
`;

const options = {
  surroundings: [
    { label: 'Próximo a Áreas de Lazer', value: 'Próximo a Áreas de Lazer' },
    { label: 'Próximo a Parques e Áreas Verdes', value: 'Próximo a Parques e Áreas Verdes' },
    { label: 'Próximo a Praia', value: 'Próximo a Praia' },
    { label: 'Próximo ao Metrô', value: 'Próximo ao Metrô' },
    { label: 'Próximo a Mercado', value: 'Próximo a Mercado' },
    { label: 'Próximo a Farmácia', value: 'Próximo a Farmácia' },
    { label: 'Próximo a Academia', value: 'Próximo a Academia' },
    { label: 'Próximo a Escola Pública', value: 'Próximo a Escola Pública' },
    { label: 'Próximo a Escola Particular', value: 'Próximo a Escola Particular' },
    { label: 'Próximo ao Shopping', value: 'Próximo ao Shopping' },
    { label: 'Próximo a Restaurante', value: 'Próximo a Restaurante' },
    { label: 'Próximo a Centro Empresarial', value: 'Próximo a Centro Empresarial' },
    { label: 'Próximo a Serviços de Saúde', value: 'Próximo a Serviços de Saúde' },
    { label: 'Próximo a Comércio Diversos', value: 'Próximo a Comércio Diversos' },
  ],
};

export default SurroundingFeatures;
