
import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import propTypes from 'prop-types';

import { BaseLabel } from '../../../styled/labels/labels';
import { COLOR_1 } from '../../../styled/variables/variables';
import { mqTablet } from '../../../styled/helpers/helpers';

// import Status from './status/Status';
import AdSlider from '../../../components/ad-slider';
import { GROUP_TYPES } from '../../../models/feature/feature.model';
import { TYPES as IMAGE_TYPES } from '../../../models/image/image.model';

/*
  global
*/

const RealEstate = ({
  images,
  features,
  // constructionStatus,
  history,
}) => {
  const { pathname } = history.location;

  images = images.filter(img => {
    return img.type === IMAGE_TYPES.PANORAMA;
  });

  return (
    <Wrapper>
      { images.length > 0 && (
        <REAdSlider
          isPanorama
          images={ images.map(img => img.url) }
        />
      ) }
      {/* <Status
        constructionStatus={ constructionStatus }
      /> */}
      <FeatureList>
        { features.filter(f => {
          return pathname.includes('condo') ?
            f.group === GROUP_TYPES.CONDO :
            f.group === GROUP_TYPES.PROPERTY;
        }).map((f, index) => (
          <FeatureWrapper key={ index }>
            <Bullet />
            <FeatureLabel>
              { f.label }
            </FeatureLabel>
          </FeatureWrapper>
        )) }
      </FeatureList>
    </Wrapper>
  );
};

RealEstate.propTypes = {
  images: propTypes.arrayOf(propTypes.shape({
    url: propTypes.string,
  })),
  features: propTypes.arrayOf(propTypes.string),
  constructionStatus: propTypes.string,
  history: propTypes.object,
};

RealEstate.defaultProps = {
  // cover: `${ process.env.PUBLIC_URL }/imgs/ad-example.png`,
  // features: [
  //   'Aceita Pet',
  //   'Acesso à Praia',
  // ],
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const REAdSlider = styled(AdSlider)`
  margin-bottom: 20px;
`;

const FeatureList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  padding-top: 0;

  ${ mqTablet`
    width: 100%;
    max-width: 600px;
    align-self: center;
  ` }
`;

const FeatureWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;

  &:last-child {
    margin: 0;
  }
`;

const Bullet = styled.div`
  height: 7px;
  width: 7px;
  border-radius: 1px;
  margin-right: 10px;
  align-self: center;
  background-color: ${ COLOR_1 };
`;

const FeatureLabel = styled(BaseLabel)`
  font-size: 12px;
  align-self: center;
`;

export default withRouter(RealEstate);
