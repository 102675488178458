import styled from "styled-components";

const markerSize = "12px"
const launchMarkerSize = "18px"
const markerBorder = "2px"
const launchMarkerBorder = "2px"

export const MarkerContainer = styled.div`
  width: ${props => props.isLaunch ? launchMarkerSize : markerSize};
  height: ${props => props.isLaunch ? launchMarkerSize : markerSize};
  border: ${props => props.isLaunch  ? launchMarkerBorder : markerBorder} solid #fff;
  outline: ${props => props.isLaunch ? "8px solid #8ACC2C35;" : "8px solid #2748C205;"}
  background: ${props => props.isLaunch  ? "#8ACC2C" : "#2748C2"};
  border-radius: 50%;
  z-index: ${props => props.isLaunch ? "100" : "10"};
  box-shadow: ${props => props.isLaunch ? "0 0 5px #00000044" : "0 0 4px #00000064"};

  &:hover {
    cursor: pointer;
  }

  &.clicked {
    background-color: #F6861F;
    outline: 4px solid #F6861F35;
    animation: pulse 2s cubic-bezier(0.5, 1, 0.89, 1) 0s infinite normal forwards;
  }

  @keyframes pulse {
    0% {
      outline: 2px solid #F6861F75;
    }

    80% {
      outline: 18px solid #F6861F00;
    }

    100% {
      outline: 30px solid #F6861F00;
    }
  }
`

export const ClusterContainer = styled.div`
  height: ${props => props.length < 50 ? props.length / 2 + 25 : 50}px;
  width: ${props => props.length < 50 ? props.length / 2 + 25 : 50}px;
  border-radius: 50%;
  background-color: #2748C2aa;
  border: 2px solid #fff;
  box-shadow: 0 0 7px #00000064;

  p {
    width: 100%;
    height: 100%;
    display: flex;
    color: #fff;
    font-weight: bold;
    align-items: center;
    justify-content: center;
    margin: 0;
  }

  &:hover {
    cursor: pointer;
  }

  &.clicked {
    background-color: #F6861F;
    outline: 4px solid #F6861F35;
    animation: pulse 2s cubic-bezier(0.5, 1, 0.89, 1) 0s infinite normal forwards;
  }

  @keyframes pulse {
    0% {
      outline: 2px solid #F6861F75;
    }

    80% {
      outline: 18px solid #F6861F00;
    }

    100% {
      outline: 30px solid #F6861F00;
    }
  }
`

export const Tooltip = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateX(calc(-50% - ${markerBorder} + ${props => props.isLaunch ? launchMarkerSize : markerSize} / 2));
  bottom: ${props => 
    props.distanceToCenter.verticalDistance < 0 ? 
    (props.isCluster ? "-10" : "-20") : 
    (props.isCluster ? "340" : "300")
  }px;
  left: ${(props) => {
    if (Math.abs(props.distanceToCenter.horizontalDistance) < 0.3) {
      return "0"
    }
    if (props.distanceToCenter.horizontalDistance < 0) {
      return "-120"
    }
    return "120"
  }}px;
  
  

  width: 250px;
  color: #2b2b2b;
  z-index: 1000;
  box-shadow: 0 5px 15px #00000068;

  &:hover {
    cursor: pointer;
  }

  .loading {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px;
    background-color: #fff;
    height: 250px;
    font-style: italic;
  }
`

export const ImageSlider = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding: 5px;
  width: 100%;
  border-top: 1px solid #dedede;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    height: 100%;
    transition: 0.2s;
    padding: 5px;
    color: #83786f;

    &:hover {
      background-color: #dedede;
    }
  }

  .dots {
    display: flex;
    padding: 10px 5px;
    overflow-x: auto;
    gap: 8px;

    &::-webkit-scrollbar {
      width: 2px;
    }
  }

  .dots::-webkit-scrollbar {
    display: none;
  }
`

export const Dot = styled.div`
    width: 5px;
    min-width: 5px;
    height: 5px;
    border-radius: 50%;
    outline: 1px solid #83786f;
    outline-offset: 1px;
    background-color: ${props => props.isSelected ? "#2748C2" : "transparent"};
`