import React, { useEffect, useState } from 'react';
import { Slide } from "react-slideshow-image";
import { RiMapPinLine, RiCommunityLine } from "react-icons/ri";

import { Title } from './components/title';
import Arrows from '../cover/arrows/Arrows';
import Highlights from '../highlights/Highlights';

import listingV2Service from '../../../services/listing/listing-v2.service';

import { COLOR_1 } from '../../../styled/variables/variables';

import currencyUtil from '../../../utils/currency/currency.util';
import windowUtil from '../../../utils/window/window.util';
import pathsConstants from '../../../constants/paths';

import {
  Container,
  ReleasesSlider,
  ReleaseSlideItem,
  LoadingSpinner,
  ReleaseSlideInfo,
  Features,
  M2Icon,
  BedroomsIcon,
  Property,
  Price,
  SeeMoreButton,
} from './styles';

export function Releases() {
  const [highlightsData, setHighlightsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    async function loadData() {
      try {
        const { status, data } = await listingV2Service.getReleaseAdsByHome();

        if (status !== 200) {
          throw new Error('Ocorreu um erro ao carregar os destaques!');
        }

        const { items } = data;

        setHighlightsData(items);
      } catch (error) {
        console.log(error.message);

        setHasError(true);
      } finally {
        setIsLoading(false)
      }
    }

    loadData();
  }, []);

  function handleShowMoreButton() {
    windowUtil.scrollToElement({
      element: Highlights.ID,
      options: {
        offset: 0,
      },
    });
  }

  return (
    <Container id="release-wrapper">
      <Title />

      {isLoading && <LoadingSpinner loading />}

      {!isLoading && !hasError && (
        <>
          <ReleasesSlider>
            <Slide {...properties}>
              {highlightsData.map((item, index) => (
                <ReleaseSlideItem key={item._id} isRelease={item.types.listing === 'Lançamento'}>
                  <img
                  src={item.images.ad[0].replace('{width}', '550')}
                  alt={item.seo && item.seo.title ? `${item.seo.title} - Foto ${index + 1}` : `Foto ${index + 1}`}
                  />

                  <ReleaseSlideInfo>
                    <div>
                      <strong>{item.seo.title || item.title}</strong>

                      <Property>
                        <p><RiMapPinLine size={17} />{item.address.neighborhood} - {item.address.city}</p>
                        <p><RiCommunityLine size={17} />{item.types.property}</p>
                      </Property>

                      <Features>
                        <span>
                          <M2Icon />
                          {item.area[0]} m²
                        </span>
                        <span>
                          <BedroomsIcon />
                          {item.bedrooms[0]} {item.bedrooms[0] > 1 ? 'quartos' : 'quarto'}
                        </span>
                      </Features>

                      <Price>
                        {item.prices.main[0] === 0 ? 'A partir de: Sob consulta' : `${item.types.listing === 'Lançamento' ? 'A partir de' : item.types.transaction}: ${currencyUtil.format({
                          value: item.prices.main,
                        })
                          }`}
                      </Price>
                    </div>

                    <a href={`${pathsConstants.AD_DETAIL}/${item.slug}`}>Ver anúncio</a>
                  </ReleaseSlideInfo>
                </ReleaseSlideItem>
              ))}
            </Slide>
          </ReleasesSlider>

          <SeeMoreButton onClick={handleShowMoreButton}>
            <Arrows color={COLOR_1} />
          </SeeMoreButton>
        </>
      )}
    </Container>
  );
}

const properties = {
  duration: 3000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  infinite: false,
  indicators: true,
  arrows: window.innerWidth < Number(736) ? false : true,
  canSwipe: window.innerWidth > Number(736) ? false : true,
  prevArrow: (
    <button type="button" aria-label="previous" className={"bt-new-slide"}>
      <svg width="24" height="38" viewBox="0 0 24 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M24 33.535L9.16599 19L24 4.465L19.4332 0L0 19L19.4332 38L24 33.535Z" fill="#2B2A28" />
      </svg>
    </button>
  ),
  nextArrow: (
    <button type="button" aria-label="next" className={"bt-new-slide-next"}>
      <svg width="24" height="38" viewBox="0 0 24 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 33.535L14.834 19L0 4.465L4.5668 0L24 19L4.5668 38L0 33.535Z" fill="#2B2A28" />
      </svg>
    </button>
  ),
};
