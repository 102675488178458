
import React from 'react';
import propTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Filter } from 'styled-icons/boxicons-regular/Filter';
import { List } from 'styled-icons/fa-solid/List';
import { Map as MapFA } from 'styled-icons/fa-solid/Map';


import { iconBaseStyle, pointer, mqDesktop, shadower } from '../../../styled/helpers/helpers';
import { COLOR_2, COLOR_1 } from '../../../styled/variables/variables';
import { BaseLabel } from '../../../styled/labels/labels';
import { transitions } from 'polished';


export const FILTERS_VIEW = 'filters';
export const RESULTS_VIEW = 'results';
export const DETAIL_VIEW = 'detail';
export const SEARCH_VIEW = 'search';

const ViewModeSelector = ({
  onClick,
  viewMode,
}) => {

  return (
    <Wrapper>
      <ItemWrapper
        onClick={ () => onClick(FILTERS_VIEW) }
        isActive={ viewMode === FILTERS_VIEW }
      >
        <FilterIcon />
        <Label>filtros</Label>
      </ItemWrapper>
      <ItemWrapper
        onClick={ () => onClick(SEARCH_VIEW) }
        isActive={ (viewMode === SEARCH_VIEW || viewMode === DETAIL_VIEW) }
      >
        <MapIcon />
        <Label>mapa</Label>
      </ItemWrapper>
      <ItemWrapper
        onClick={ () => onClick(RESULTS_VIEW) }
        isActive={ viewMode === RESULTS_VIEW }
      >
        <ListIcon />
        <Label>resultados</Label>
      </ItemWrapper>
    </Wrapper>
  );
};

ViewModeSelector.propTypes = {
  onClick: propTypes.func,
  viewMode: propTypes.string,
};

ViewModeSelector.defaultProps = {
  viewMode: SEARCH_VIEW,
};

const Wrapper = styled.div`
  position: relative;
  width: 100vw;
  top: 60px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  ${ shadower({ direction: 'bottom' }) }

  ${ mqDesktop`
    display: none;
  ` };

  @media (max-width: 1079px) {
    top: 0px;
  }
`;

const ItemWrapper = styled.div`
  border: solid ${ COLOR_2 };
  border-width: 0 1px 0 0;
  background-color: ${ COLOR_1 };
  padding: 10px;
  overflow: hidden;
  display: flex;
  ${ pointer };
  & :last-child {
    border-right: none;
    padding-left: 11px;
  }
  ${ transitions([ 'width' ], 'ease .3s') };
  ${ transitions([ 'filter' ], 'ease .3s') };
  /* width: ${ ({ isActive }) => isActive ? 'calc(100% - 80px)' : '40px' }; */
  width: calc(100% - 80px);

  ${({isActive}) => !isActive && css`
    filter: brightness(0.7);
  `}

  @media (max-width: 1079px) {
    padding: 24px 10px;
  }
`;

const MapIcon = styled(MapFA)`
  ${ iconBaseStyle };
  color: ${ COLOR_2 };
  align-self: center;
  min-width: 18px;
  height: 18px;
`;

const ListIcon = styled(MapIcon).attrs({
  as: List,
})``;

const FilterIcon = styled(MapIcon).attrs({
  as: Filter,
})`
  min-width: 26px;
  height: 26px;
  margin-left: -4px;
`;

const Label = styled(BaseLabel)`
  color: ${ COLOR_2 };
  align-self: center;
  text-transform: capitalize;
  margin-left: 0px;
  font-size: 12px;
`;

export default ViewModeSelector;
