import httpClient from '../../clients/http/http.client';

const sendMessageReport = async ({
  data,
  id,
}) => {
  const params = {
    realEstateId: id,
    name: data.name,
    email: data.email,
    type: data.reportAdType,
    description: data.reportAdDescription,
  };

  const path = "/denunciations";
  const response = await httpClient.post({ path, params });

  return response;
};


export default {
  sendMessageReport,
};
