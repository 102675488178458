import styled from 'styled-components';
import { Container as ContainerBootstrap } from 'react-bootstrap';

import { BaseLabel } from "../../../styled/labels/labels";

export const Container = styled(ContainerBootstrap)`
  margin: 5% 0;
  max-width: 1000px;
`;

export const Title = styled.h3`
    font-size: 16px;
    margin-bottom: 10px;
    display: inline-block;
    letter-spacing: 0.5px;
    color: rgb(51, 51, 51);
    font-weight: bold;
    align-self: flex-start;
    margin-top: 5px;
`;

export const StepsList = styled.ol`
  padding-left: 15px;

    li {
    font-size: 16px;

    h6 {
      font-size: unset;
      line-height: unset;

      a {
        color: #2B50D8;
      }
    }
  }
`;

export const GroupSelect = styled.div`
  max-width: 420px;
  margin-top: 42px;
  margin-bottom: 32px;

  .react-select__multi-value__label {
    padding: 3px 6px !important;
  }

  .react-select__multi-value__remove {
    display: none;
  }
`;

export const Label = styled(BaseLabel)`
  font-weight: bold;
  font-size: 15px;
  color: #555;
  align-self: center;
  margin-bottom: 8px;
  margin-top: 5px;
`;
