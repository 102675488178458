import React from 'react';
import styled from 'styled-components';

import { BaseLabel } from '../../styled/labels/labels';
import { COLOR_1, COLOR_3 } from '../../styled/variables/variables';
import { mqTablet } from '../../styled/helpers/helpers';

const DiversityAndInclusionPolicy = () => (
    <Wrapper>
        <Title>POLÍTICA DE DIVERSIDADE E INCLUSÃO (PDI)</Title>

        {blocks.map(({ title, topic, level1, level2, }, index) => (
            <BlockWrapper key={index}>
                {title && (
                    <SectionTitle>
                        {title}
                    </SectionTitle>
                )}

                {topic && (
                    <Topic>{topic}</Topic>
                )}

                {level1 && (
                    <TextBlockLevel1>
                        {level1}
                    </TextBlockLevel1>
                )}

                {level2 && (
                    <TextBlockLevel2>
                        {level2}
                    </TextBlockLevel2>
                )}

            </BlockWrapper>
        ))}
    </Wrapper>
);

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 10px;
  padding-top: 130px;
  padding-bottom: 50px;

  ${mqTablet`
    padding-top: 200px;
    padding-bottom: 200px;
  ` }
`;

const BlockWrapper = styled.div`
  margin-bottom: 30px;

  ${mqTablet`
    max-width: 1000px;
    align-self: left;
    width: 70%; 
    margin: 0 auto; 
    margin-bottom: 30px;
    text-align: left
  ` }
`;

const Title = styled(BaseLabel)`
  align-self: center;
  font-size: 28px;
  font-weight: 800;
  margin-bottom: 30px;
`;

const SectionTitle = styled(Title)`
  color: ${COLOR_1};
  margin-bottom: 20px;
  margin-top: 30px;
  font-size: 24px;
  font-weight: 700;
`;

const Topic = styled(Title)`
  font-size: 20px;
  color: ${COLOR_1};
  align-self: flex-start;
  margin-bottom: 10px;
`;

const TextBlockLevel1 = styled.p`
  padding-left: 5px;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: ${COLOR_3};
  font-size: 16px;
  text-align: justify;
`;

const TextBlockLevel2 = styled(TextBlockLevel1)`
  padding-left: 25px;
`;

const blocks = [
    {
        title: 'INTRODUÇÃO',
        level1: 'Perseguir de forma permanente as diretrizes e os compromissos que direcionarão a atuação do KOORT para que a mesma seja pautada pela inclusão e valorização da diversidade humana e cultural no desenvolvimento de suas atividades e dos seus produtos, contribuindo assim para equidade e justiça social.',
    },

    {
        title: 'COMPROMISSO',
        level1: 'Procuramos estabelecer o compromisso de excelência na busca de colaboradores, parceiros e funcionários de maneira a contemplar das seguintes representações:'
    },
    
    { level2: '-Diversidade de Gênero ' },
    { level2: '-Diversidade de Sexualidade (LGBTQI+)' },
    { level2: '-Diversidade de Etnia' },
    { level2: '-Diversidade Social' },
    { level2: '-Pessoas com Deficiências' },

    {
        title: 'DIRETRIZES',
        level1: 'Combater o Preconceito – Prevenir, levantar e combater qualquer prática ou situação que represente qualquer tipo de preconceito contra qualquer representação dentro do nosso ambiente de trabalho. ',
        level1: 'Buscar a Representatividade – Aliado a excelência profissional, procurar a representatividade e pluralidade na atração de pessoas para nossos quadros de colaboradores.',
        level1: 'Equidade na Remuneração – Zelar pela equidade e equiparação das remunerações e benefícios entre todas as representações que compõem nossas equipes de trabalho.'
    },

];

export default DiversityAndInclusionPolicy;
