import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Router, Route, Switch, Redirect } from 'react-router-dom';


import ContactForm from './contact-form/ContactForm';
import Prices from './prices/Prices';
import MainSlider from './main-slider/MainSlider';
import Cover from './cover/Cover';
import Tabs from '../../components/tabs';

import pathsConstants from '../../constants/paths';
import { TYPES as IMAGE_TYPES } from '../../models/image/image.model';

import queryStringUtil from '../../utils/query-string/query-string.util';

import RealEstate from './real-estate/RealEstate';
import Surroundings from './surroundings/Surroundings';
import userService from '../../services/user/user.service';
import reportService from '../../services/report/report.service';

/*
  global
  window
  setTimeout
*/
const customId = "custom-id-yes";
const Report = ({ history }) => {

  const [state, setState] = useState({
    realEstate: null,
  });

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  }, []);

  useEffect(() => {
    getRealEstate();
  }, []);

  const getRealEstate = async () => {
    const { reportId, creating, realEstateId } = queryStringUtil.parse(history);
    const user = userService.retrieve();
    let data = { 'teste': 'teste' }
    let realEstate;
    try {
      if (creating) {
        const result = await reportService.create({
          token: user.token,
          realEstateId,
          ...data,
        });
      } else {
        realEstate = await reportService.getByIdPublic({ reportId });
      }
    } catch (error) {
      console.error(error);
      toast.error('Erro buscando relatório.', {
        autoClose: false,
        toastId: customId,
      });
    }

    setState(prev => ({
      ...prev,
      realEstate,
    }));
  };

  if (!state.realEstate) return <Wrapper />;

  const images = state.realEstate.images.filter(img => {
    return img.type === IMAGE_TYPES.PICTURE &&
      img.size === 'm';
  });

  return (
    <Wrapper>
      <MainSlider images={images.map(img => img.url)} />
      <Cover {...state.realEstate} />
      <Prices
        {...state.realEstate.price}
        transaction={state.realEstate.transaction}
        koortimativa={state.realEstate.koortimativa}
      />
      <ARTabs configs={tabConfigs} />

      <ContentWrapper>
        <Router history={history}>
          <Switch>
            <Route
              path={pathsConstants.REPORT_REAL_ESTATE}
              render={props => (
                <RealEstate
                  {...props}
                  {...state.realEstate}
                />
              )}
            />
            <Route
              path={pathsConstants.REPORT_CONDO}
              render={props => (
                <RealEstate
                  {...props}
                  {...state.realEstate}
                />
              )}
            />
            <Route
              path={pathsConstants.REPORT_SURROUNDINGS}
              render={props => (
                <Surroundings
                  {...props}
                  realEstate={state.realEstate}
                />
              )}
            />
            <Redirect
              to={{
                pathname: pathsConstants.REPORT_REAL_ESTATE,
                search: queryStringUtil.stringify(history),
              }}
            />
          </Switch>
        </Router>
      </ContentWrapper>

      <ContactForm />
    </Wrapper>
  );
}

Report.propTypes = {
  history: propTypes.object,
};

Report.defaultProps = {};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 80px;
`;

const ARTabs = styled(Tabs)``;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 70px;
`;

const tabConfigs = [
  {
    path: pathsConstants.REPORT_REAL_ESTATE,
    label: 'O Imóvel',
  },
  {
    path: pathsConstants.REPORT_CONDO,
    label: 'O Condomínio',
  },
  {
    path: pathsConstants.REPORT_SURROUNDINGS,
    label: 'O Entorno',
  },
];

export default Report;
