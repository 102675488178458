import React from 'react';
import { RiCalendarCheckLine, RiStackLine, RiBuildingLine, RiLayoutLine, RiPencilRuler2Line } from 'react-icons/ri';

import { Wrapper, Title, DevelopmentInfo } from './styles';

export function DevelopmentInformation({
  development,
}) {
  return (
    <Wrapper>
      <Title as="strong">{development.name}</Title>

      <DevelopmentInfo>
        {development.constructionStatus && (
          <li>
            <RiPencilRuler2Line />

            <span>{development.constructionStatus}</span>
          </li>
        )}

        {development.buildings > 0 && (
          <li>
            <RiBuildingLine />

            <span>{development.buildings} {development.buildings > 1 ? 'prédios' : 'prédio'}</span>
          </li>
        )}

        {development.floor > 0 && (
          <li>
            <RiStackLine />

            <span>{development.floor} {development.floor > 1 ? 'andares' : 'andar'}</span>
          </li>
        )}

        {development.apartmentsPerFloor > 0 && (
          <li>
            <RiLayoutLine />

            <span>{development.apartmentsPerFloor} ap. por andar</span>
          </li>
        )}

        {development.yearBuilt !== null && (
          <li>
            <RiCalendarCheckLine />

            <span>Construído em {development.yearBuilt}</span>
          </li>
        )}

      </DevelopmentInfo>
    </Wrapper>
  );
}


