
import httpClient from '../../clients/http/http.client';
import Address from '../../models/address/address.model';
import Geolocation from '../../models/geolocation/geolocation.model';
import stateConstants from '../../constants/states/state.constants';
import stringUtil from '../../utils/string/string.util';
import objectUtil from '../../utils/object/object.util';

/*
  global
  process
  navigator
  Promise
*/

const getCurrentGeolocation = async () => {
  const response = await new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject);
  });

  // The 'Coordinates' object does not have the
  // object rest spread transform built in.
  const result = new Geolocation({
    latitude: response.coords.latitude,
    longitude: response.coords.longitude,
  });
  
  return result;
};

const getAddressAndGeolocationByIp = async () => {
  const path = '/json';
  const { REACT_APP_EXTREME_IP_LOOKUP_API_URL, } = process.env;
  
  const response = await httpClient.get({
    url: REACT_APP_EXTREME_IP_LOOKUP_API_URL,
    path,
  });

  const { data } = response;
  const state = stateConstants.find(state => {
    const normalizedState = stringUtil.normalize(data.region)
      .toLowerCase();
      
    return normalizedState === stringUtil.normalize(state.name)
      .toLowerCase();
  });

  const result = new Geolocation({
    latitude: parseFloat(data.lat),
    longitude: parseFloat(data.lon),
    address: new Address({
      ...data,
      state: state && state.abbreviation,
    }),
  });
  
  return result;
};

const getAddressByZipCode = async ({
  zipCode,
}) => {
  const { 
    REACT_APP_VIACEP_URL,
  } = process.env;

  const path = `/ws/${ stringUtil.onlyNumbers(zipCode) }/json/`;
  
  const response = await httpClient.get({ 
    url: REACT_APP_VIACEP_URL,
    path,
  });
  
  const result = new Address({
    neighborhood: response.data.bairro,
    city: response.data.localidade,
    street: response.data.logradouro,
    state: response.data.uf,
  });
  
  return result;
};

const getGeolocationByAddress = async ({
  ...address
}) => {
  const path = '/maps/api/geocode/json';
  let addressString = '';
  objectUtil.keys(address).forEach(key => {
    addressString += `${ address[key] } `;
  });

  const response = await httpClient.get({
    url: process.env.REACT_APP_GOOGLE_MAPS_API_URL,
    path,
    params: {
      key: process.env.REACT_APP_GOOGLE_API_KEY,
      address: addressString,
    },
  });

  if(response.data.results[0] && response.data.results[0].geometry){
    const { lat, lng } = response.data.results[0].geometry.location;
    const result = new Geolocation({
      latitude: lat,
      longitude: lng,
    });
  
    return result;
  }

  return  new Geolocation({
    latitude: 0,
    longitude: 0,
  });

};

export default {
  getAddressAndGeolocationByIp,
  getCurrentGeolocation,
  getAddressByZipCode,
  getGeolocationByAddress,
};
