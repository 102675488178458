import React from 'react';
import propTypes from 'prop-types';

import {
  Wrapper,
  MainWrapper,
  MainTitle,
  MainRatingStars,
  CategoryListWrapper,
  CategoryWrapper,
  CategoryTitle,
  CategoryRatingWrapper,
  CategoryRatingStars,
  CategoryRating
} from './styles';
/*
global
*/
const RatingAverages = ({
  reviews,
}) => {

  const avg = {
    negotiation: 0,
    propertyKnowledge: 0,
    recommend: 0,
    service: 0,
    surroundingsKnowledge: 0,
  };

  reviews.forEach(review => {
    avg.negotiation += review.negotiation / reviews.length;
    avg.propertyKnowledge += review.propertyKnowledge / reviews.length;
    avg.recommend += review.recommend / reviews.length;
    avg.service += review.service / reviews.length;
    avg.surroundingsKnowledge += review.surroundingsKnowledge / reviews.length;
  });

  const categories = [
    { title: 'Conhecimento do Imóvel', rating: avg.propertyKnowledge, },
    { title: 'Conhecimento do Local', rating: avg.surroundingsKnowledge, },
    { title: 'Negociação', rating: avg.negotiation, },
    { title: 'Serviço', rating: avg.service, },
  ];
  return (
    <Wrapper>
      <MainWrapper>
        <MainTitle>Recomendo</MainTitle>
        <CategoryRatingWrapper>
          <MainRatingStars rating={avg.recommend} />
          <CategoryRating>
            {avg.recommend.toFixed(1)}
          </CategoryRating>
        </CategoryRatingWrapper>
      </MainWrapper>
      <CategoryListWrapper>
        {categories.map(({ title, rating }, index) => (
          <CategoryWrapper key={index}>
            <CategoryTitle>{title}</CategoryTitle>
            <CategoryRatingWrapper>
              <CategoryRatingStars rating={(rating).toFixed(1)} />
              <CategoryRating>
                {rating.toFixed(1)}
              </CategoryRating>
            </CategoryRatingWrapper>
          </CategoryWrapper>
        ))}
      </CategoryListWrapper>
    </Wrapper>
  );
};

RatingAverages.propTypes = {
  reviews: propTypes.arrayOf(propTypes.shape({
    negotiation: propTypes.number,
    propertyKnowledge: propTypes.number,
    recommend: propTypes.number,
    service: propTypes.number,
    surroundingsKnowledge: propTypes.number,
  })),
};

RatingAverages.defaultProps = {
  reviews: [],
};



export default RatingAverages;