import React, { PureComponent } from 'react';
import propTypes from 'prop-types';
import ReactPlayer from 'react-player';

import { Wrapper } from './styles';


class VideoPlayer extends PureComponent {
  constructor(props) {
    super(props)
  }
  render() {
    const { className, url, options, onError, onReady, video } = this.props;

    return (
      <Wrapper className={className}>
        <ReactPlayer
          url={video}
          volume={100}
          loop
          playing
          controls={true}
          onError={onError}
          onReady={onReady}
          {...options}
        />
      </Wrapper>
    )
  }
}

VideoPlayer.propTypes = {
  className: propTypes.string,
  url: propTypes.string,
  options: propTypes.object,
  onError: propTypes.func,
  onReady: propTypes.func,
};

VideoPlayer.defaultProps = {
  url: 'https://www.youtube.com/watch?v=Gic_CtkJSjE',
  options: { height: 300 },
};



export default VideoPlayer;
