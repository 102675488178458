import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import propTypes from 'prop-types';
import { Heart as HeartRegular } from 'styled-icons/fa-regular/Heart';
import { Heart as HeartSolid } from 'styled-icons/fa-solid/Heart';
import { useAuth0 } from '@auth0/auth0-react';

import { COLOR_4, COLOR_2 } from '../../../styled/variables/variables';
import { BaseLabel } from '../../../styled/labels/labels';
import currencyUtil from '../../../utils/currency/currency.util';
import { iconBaseStyle } from '../../../styled/helpers/helpers';
import { BaseButton } from '../../../styled/buttons/buttons';

import userFavoriteService from '../../../services/user/favorite/user.favorite.service';
import userService from '../../../services/user/user.service';

import pathsConstants from '../../../constants/paths';

import './prices.css'

const Prices = ({
  listingId,
  sale,
  rental,
  main,
  transaction,
  condo,
  iptu,
  favorite,
  listing
}) => {
  const [state, setState] = useState({
    isFavorite: favorite,
  });

  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      isFavorite: favorite
    }));
  }, [favorite]);

  const addOrRemoveFavorite = async ({ isAutoFavorite = false }) => {
    const user = userService.retrieve();

    if (!user) {
      const pathRedirect = `${window.location.href.replace(window.location.origin, '')}?action=autoFavorite`;
      localStorage.setItem('@Koort:PathRedirect', pathRedirect);

      loginWithRedirect({ authorizationParams: { redirect_uri: `${window.location.origin}/authentication/login/success?login=true` } });
    } else {
      setState(prev => ({
        ...prev,
        isFavorite: !state.isFavorite,
      }));

      let result;
      try {
        result = await userFavoriteService.patch({
          listingId,
          token: user.token,
        });
        userFavoriteService.save({
          listingIds: result,
        });

        if (isAutoFavorite) {
          const { origin, pathname } = window.location;
          const url = `${origin + pathname}`;

          window.location.href = url;
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    const user = userService.retrieve();

    if (user) {
      const params = new URL(document.location).searchParams;

      const actionParam = params.get("action");

      if (actionParam && actionParam === 'autoFavorite') {
        addOrRemoveFavorite({ isAutoFavorite: true });
      }
    }
  }, []);

  let valor = main;
  // if (sale && (transaction === 'Venda|Locação' || transaction === 'Venda')) {
  //   valor = sale
  // } else if (rental && (transaction === 'Locação|Venda' || transaction === 'Locação')) {
  //   valor = rental
  // } else {
  //   valor = main
  // }

  switch (transaction) {
    case 'Locação':
    case 'Locação|Venda':
      valor = rental;
      break;

    default:
      break;
  }

  console.log(transaction)

  if (transaction === 'Locação|Venda' && main[0] > 0 && rental === 0) {
    return (
      <Wrapper>
        <FavoriteButton onClick={addOrRemoveFavorite}>
          {state.isFavorite && <HeartFillIcon />}
          {!state.isFavorite && <HeartIcon />}
        </FavoriteButton>

        <ContentWrapper>
          <table className={'tb-valores'}>
            <thead>
              <tr>
                <th>valor do aluguel:</th>
                <strong>
                  sob consulta
                </strong>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>Valor de venda</td>
                <td>
                  {currencyUtil.format({
                    value: main,
                  })}
                </td>
              </tr>

              <tr>
                <td>Condomínio</td>
                <td>
                  {condo == 0 ? '-' : currencyUtil.format({
                    value: condo || 0,
                  })}
                </td>
              </tr>
              <tr>
                <td>IPTU</td>
                <td>
                  {iptu == 0 ? '-' : currencyUtil.format({
                    value: iptu,
                  })}
                </td>
              </tr>
            </tbody>
          </table>
        </ContentWrapper>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <FavoriteButton onClick={addOrRemoveFavorite}>
        {state.isFavorite && <HeartFillIcon />}
        {!state.isFavorite && <HeartIcon />}
      </FavoriteButton>

      <ContentWrapper>
        <table className={'tb-valores'}>
          <thead>
            <tr>
              {valor == 0 || (listing === 'Lançamento') ? (
                <th>A partir de</th>
              ) : (
                <th>valor {transaction === 'Venda' || transaction === 'Venda|Locação' ? 'de venda:' : 'do aluguel:'}</th>
              )}

              {valor != 0 && (listing !== 'Lançamento') && (
                <strong>
                  {currencyUtil.format({
                    value: transaction === 'Venda' || transaction === 'Venda|Locação' ? (sale ? sale : main) : (rental ? rental : main),
                  })}
                </strong>
              )}
            </tr>
          </thead>

          {valor != 0 && (listing !== 'Lançamento') ? (
            <tbody>
              {transaction === 'Locação|Venda' && main[0] !== 0 && (
                <tr>
                  <td>Valor de venda</td>
                  <td>
                    {currencyUtil.format({
                      value: main,
                    })}
                  </td>
                </tr>
              )}
              {transaction === 'Venda|Locação' && rental !== 0 && (
                <tr>
                  <td>Valor do aluguel</td>
                  <td>
                    {currencyUtil.format({
                      value: rental,
                    })}
                  </td>
                </tr>
              )}
              <tr>
                <td>Condomínio</td>
                <td>
                  {condo == 0 ? '-' : currencyUtil.format({
                    value: condo || 0,
                  })}
                </td>
              </tr>
              <tr>
                <td>IPTU</td>
                <td>
                  {iptu == 0 ? '-' : currencyUtil.format({
                    value: iptu,
                  })}
                </td>
              </tr>
            </tbody>
          ) : valor != 0 ? (
            <tbody>
              <tr>
                <strong className='isRelease'>
                  {currencyUtil.format({
                    value: transaction === 'Venda' ? (sale ? sale : main) : (rental ? rental : main),
                  })}
                </strong>
              </tr>
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td className='isRelease'>Sob consulta</td>
              </tr>
            </tbody>
          )}
        </table>
      </ContentWrapper>
    </Wrapper>
  );
}

Prices.propTypes = {
  condo: propTypes.string,
  iptu: propTypes.string,
  isFavorite: propTypes.string,
  listingId: propTypes.string,
  rental: propTypes.string,
  sale: propTypes.string,
  transaction: propTypes.string,
};

Prices.defaultProps = {
  isFavorite: false,
};

const Wrapper = styled.div`
  border: solid 1px ${COLOR_4};
  border-radius: 1px;;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const FavoriteButton = styled(BaseButton)`
  position: absolute;
  border-radius: 0;
  background-color: ${COLOR_4};
  border-color: ${COLOR_4};
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  margin: 0;
`;

const HeartIcon = styled(HeartRegular)`
  ${iconBaseStyle}
  color: ${COLOR_2};
  height: 20px;
  width: 20px;
  position: absolute;
  align-self: center;
`;

const HeartFillIcon = styled(HeartIcon).attrs({
  as: HeartSolid,
})``;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
`;

const MainLabel = styled(BaseLabel)`
  font-weight: 700;
  font-size: 16px;
  align-self: center;
  margin-bottom: 5px;
`;

const Price = styled(MainLabel)`
  font-weight: 400;
  margin-bottom: 30px;
`;

const FeeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  &:last-child {
    margin: 0;
  }
`;

const FeeIndicator = styled(Price)`
  font-size: 12px;
  margin: 0;
`;

const Fee = styled(FeeIndicator)`
  font-size: 14px;
`;

export default Prices;
