import styled from 'styled-components';

export const AlertContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 32px;

  h3 {
    font-size: 18px;
    font-weight: 500;
  }

  p {
    font-size: 16px;
    margin-top: 12px;
    text-align: center;
  }
`;
