/* global Intl */

const format = ({ value, locale = 'pt-BR', options = {} }) => {
  return new Intl.NumberFormat(locale, {
    currency: 'BRL',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...options,
    style: 'currency',
  }).format(value).replace(',00', '');
};

export default {
  format,
};
