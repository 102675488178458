import styled from 'styled-components';

export const FeedbackSuccessStepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  width: 304px;

  > span {
    margin-top: 8px;
    font-size: 18px;
    color: #FFFFFF;
    font-weight: 500;
  }

  > button {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 24px;
    padding-left: 24px;
    margin-top: 24px;
    border-radius: 8px;
    background-color: #4d87f6;
    color: #FFFFFF;
    font-weight: 500;

    transition: background-color .2s;

    &:hover {
      background-color: #2B50D8;
    }
  }
`;
