import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';

import { BaseButton } from '../../../../styled/buttons/buttons';
import { COLOR_2, COLOR_1, COLOR_4, COLOR_17 } from '../../../../styled/variables/variables';

export const TYPES = {
  LANCAMENTO: 'Lançamento',
  USADO: 'Usado',
};

class Type extends PureComponent {

  constructor(props) {
    super(props);
    this.hiddenInputRef = React.createRef();
    this.state = {
      typeHasDefined: false,
      form: {
        type: TYPES.LANCAMENTO,
      },
    };
  }

  handleButtonClick = event => {
    const { name } = event.target;

    this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        type: name,
      },
    }), () => {
      const { onChange } = this.props;
      onChange(name);
    });
  };

  render() {
    const { isEditing, populate } = this.props;
    const { form, typeHasDefined } = this.state;

    if (isEditing) {
      if (!!populate && !typeHasDefined) {
        this.setState(prevState => ({
          ...prevState,
          typeHasDefined: true,
          form: {
            ...prevState.form,
            type: populate,
          }
        }));
      }
    }

    return (
      <Wrapper>
        <Button
          isActive={form.type === TYPES.LANCAMENTO}
          onClick={this.handleButtonClick}
          name={TYPES.LANCAMENTO}
        >
          Lançamento
        </Button>
        <Button
          isActive={form.type === TYPES.USADO}
          name={TYPES.USADO}
          onClick={this.handleButtonClick}
        >
          Usado
        </Button>
        <HiddenInput
          name='type'
          id='type'
          ref={this.hiddenInputRef}
        />
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 50px;
`;

const Button = styled(BaseButton)`
  flex-grow: 1;
  width: 1px;
  font-weight: 300;
  border: solid 1px ${COLOR_1};

  ${({ isActive }) => !isActive && css`
    color: ${COLOR_4};
    background-color: ${COLOR_2};
    border-color: ${COLOR_17};
  ` }
`;

const HiddenInput = styled.input`
  display: none;
`;

export default Type;
