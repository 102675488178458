import styled from 'styled-components';

import { ProfilePicture } from '../../../../styled/components/components';
import { BaseLabel } from '../../../../styled/labels/labels';
import { COLOR_2 } from '../../../../styled/variables/variables';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProfilePictureListing = styled(ProfilePicture)`
  align-self: center;
  margin-bottom: 20px;
  height: 60px;
  width: 60px;
  border: none;
  cursor: pointer;
`;

export const ProfileInfo = styled.div`
  text-align: right;
  width: calc(100% - 60px);
`;

export const Title = styled.div`
  color: #999;
`;

export const Text = styled.div`
  color: #999;
  font-size: 14px;
`

export const AOProfilePicture = styled(ProfilePicture)`
  align-self: center;
  margin-bottom: 20px;
  height: 60px;
  width: 60px;
  border: none;
  cursor: pointer;
`;

export const Type = styled(BaseLabel)`
  color: ${COLOR_2};
  font-size: 14px;
  align-self: center;
  text-transform: uppercase;
  margin-bottom: 10px;
`;

export const Label = styled(Type)`
  text-transform: none;
  margin-bottom: 3px;
  font-size: 12px;
`;
