import React from 'react';

import { FakeInputWrapper } from './styles';

export function FakeInput({ label, value }) {
  return (
    <FakeInputWrapper>
      <label>{label}</label>
      <span>{value}</span>
    </FakeInputWrapper>
  )
}
