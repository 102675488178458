import React from 'react';
import propTypes from 'prop-types';
import { transitions } from 'polished';
import { DotsHorizontal } from 'styled-icons/boxicons-regular/DotsHorizontal';
import styled from 'styled-components';

import { COLOR_1 } from '../../styled/variables/variables';
import { iconBaseStyle } from '../../styled/helpers/helpers';

import {
  Wrapper,
  Square,
  Tooltip,
  Triangle,
  TooltipLabel
} from './styles';

const TooltipButton = ({
  onClick,
  label,
  Icon,
  iconStyle,
}) => {

  Icon = styled(Icon)`
    ${iconBaseStyle}
    color: ${COLOR_1};
    align-self: center;
    height: 18px;
    ${transitions(['color'], 'ease .3s')}
    ${iconStyle}
  `;

  return (
    <Wrapper onClick={onClick}>
      <Square>
        <Icon />
      </Square>
      <Tooltip>
        <Triangle />
        <TooltipLabel>{label}</TooltipLabel>
      </Tooltip>
    </Wrapper>
  );
};

TooltipButton.propTypes = {
  onClick: propTypes.func,
  label: propTypes.string,
  Icon: propTypes.object,
  iconStyle: propTypes.object,
};

TooltipButton.defaultProps = {
  onClick: () => alert('Missing on click handler.'),
  label: 'Label',
  Icon: DotsHorizontal,
};



export default TooltipButton;
