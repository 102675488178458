import styled from 'styled-components';
import { PhotoSizeSelectSmall } from 'styled-icons/material/PhotoSizeSelectSmall';
import { Bed } from 'styled-icons/boxicons-regular/Bed';

import { iconBaseStyle } from '../../../styled/helpers/helpers';
import { COLOR_2 } from '../../../styled/variables/variables';
import { BaseLabel } from '../../../styled/labels/labels';

export const Wrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
  align-self: center;
`;

export const M2Icon = styled(PhotoSizeSelectSmall)`
  ${ iconBaseStyle }
  color: ${ COLOR_2 };
  height: 17px;
  align-self: center;
  margin-right: 5px;
`;

export const BedroomsIcon = styled(M2Icon).attrs({
  as: Bed,
})``;

export const Label = styled(BaseLabel)`
  font-weight: 200;
  color: ${ COLOR_2 };
  font-size: 14px;
  align-self: center;
  margin-right: 5px;

  &:last-child {
    margin-right: 0;
  }
`;
