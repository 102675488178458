import React from 'react';
import styled from 'styled-components';

import { BaseLabel } from '../../styled/labels/labels';
import { COLOR_1, COLOR_3 } from '../../styled/variables/variables';
import { mqTablet } from '../../styled/helpers/helpers';

import pathsConstants from '../../constants/paths';

const TermsAndConditions = () => (
	<Wrapper>
		<Title>Termos e condições de uso</Title>

		{blocks.map(({ title, topic, level1, level2, link, }, index) => (
			<BlockWrapper key={index}>
				{title && (
					<SectionTitle>
						{title}
					</SectionTitle>
				)}

				{topic && (
					<Topic>{topic}</Topic>
				)}

				{level1 && (
					<TextBlockLevel1>
						{level1 + ' '}
						<a href={pathsConstants.PRIVACY_POLICY} target='_blank'>{link}</a>
					</TextBlockLevel1>
				)}

				{level2 && (
					<TextBlockLevel2>
						{level2}
					</TextBlockLevel2>
				)}

			</BlockWrapper>
		))}
	</Wrapper>
);

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 10px;
  padding-top: 130px;
  padding-bottom: 50px;

  ${mqTablet`
    padding-top: 200px;
    padding-bottom: 200px;
  ` }
`;

const BlockWrapper = styled.div`
  margin-bottom: 30px;

  ${mqTablet`
    max-width: 1000px;
    align-self: left;
    width: 70%; 
    margin: 0 auto; 
    margin-bottom: 30px;
    text-align: left
  ` }
`;

const Title = styled(BaseLabel)`
  align-self: center;
  font-size: 28px;
  font-weight: 800;
  margin-bottom: 30px;
`;

const SectionTitle = styled(Title)`
  color: ${COLOR_1};
  margin-bottom: 20px;
  margin-top: 30px;
  font-size: 24px;
  font-weight: 700;
`;

const Topic = styled(Title)`
  font-size: 20px;
  color: ${COLOR_1};
  align-self: flex-start;
  margin-bottom: 10px;
`;

const TextBlockLevel1 = styled.p`
  padding-left: 5px;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: ${COLOR_3};
  font-size: 16px;
  text-align: justify;
`;

const TextBlockLevel2 = styled(TextBlockLevel1)`
  padding-left: 25px;
`;

const blocks = [
	{
		title: '1. Objeto',
		level1: '1.1 O KOORT é uma Plataforma que promove a divulgação e a oferta de produtos e serviços para os Usuários promovidos pelos Parceiros cadastrados.'
	},
	{ level1: '1.2 A Plataforma, portanto, viabiliza aos Usuários uma ferramenta de soluções e oferta de produtos e serviços disponibilizados pelos Parceiros e personalizados de acordo com os interesses do Usuário.' },
	{ level1: '1.3 Os Parceiros são responsáveis exclusivos pela legitimidade do conteúdo, condição, preço e formas de pagamentos dos Produtos e serviços anunciados na Plataforma, de forma que o KOORT não se responsabiliza por qualquer contratempo decorrente da contratação e aquisição dos produtos e serviços oferecidos pelos Parceiros.' },
	{ level1: '1.4 Todo o pagamento e repasse de valores é realizado pela empresa que oferece ao KOORT um sistema integrado de pagamento, referida neste instrumento como sistema de pagamento ("Sistema de Pagamento").' },
	{ level1: '1.5 Ao se cadastrarem, os Usuários e Parceiros poderão utilizar todos os serviços disponibilizados na Plataforma para a sua região, após a declaração de leitura e compreensão do Termo de Uso. ' },

	{
		title: '2. Capacidade para Cadastrar-se',
		level1: '2.1 Os serviços da Plataforma para Usuários estão disponíveis às pessoas civilmente capazes, sendo vedada a utilização da Plataforma por aqueles considerados incapazes pela legislação pátria. Ao utilizar os Serviços, o Usuário declara preencher o requisito acerca da capacidade.'
	},
	{ level1: '2.2 Ao confirmar o cadastro, o Parceiro terá acesso à Plataforma do KOORT e ao perfil dos seus Usuários para ofertar seus produtos e serviços e realizar um ou mais negócios com os Usuários da Plataforma.' },
	{ level1: '2.3 Cada Usuário e cada Parceiro têm o direito de criar apenas um perfil de acesso à Plataforma, sendo vedada a multiplicidade de perfis. O KOORT, verificando a existência de perfis múltiplos, se reserva ao direito de excluí-los sem comunicação prévia ao Usuário e ao Parceiro.' },

	{
		title: '3. Acesso à Plataforma KOORT',
		level1: '3.1 Para acessar e usufruir as funcionalidades de anunciar um imóvel ou participar como corretor Parceiro da Plataforma, você precisará se cadastrar na qualidade de "Usuário" ou "Parceiro", respectivamente. O cadastro implica na anuência dos direitos e deveres estabelecidos aqui pelas partes envolvidas.'
	},
	{ level1: '3.2 O cadastro é realizado diretamente na Plataforma KOORT, após o preenchimento dos dados solicitados pelo sistema.' },
	{ level1: '3.3 O KOORT poderá exercer o direito de recusar qualquer solicitação de cadastro e de cancelar um cadastro previamente aceito, a seu exclusivo critério e sem aviso prévio, isentando-se de qualquer responsabilização.' },
	{ level1: '3.4 A veracidade, precisão e atualização dos dados cadastrados são de responsabilidade dos Usuários e Parceiros da Plataforma. A responsabilidade, civil e criminal, pela autenticidade dos dados pessoais é única e exclusiva daquele prestou as informações à Plataforma. ' },
	{ level1: '3.5 A proteção da senha de acesso à Plataforma é de responsabilidade do Usuário ou do Parceiro cadastrado. O KOORT não se responsabiliza por qualquer acesso ou atividade indevida junto ao perfil cadastrado. ' },
	{ level1: '3.6 O Usuário e Parceiro se comprometem a notificar o KOORT imediatamente, por meio dos canais de contato mantidos na Plataforma, a respeito de qualquer suspeita ou constatação de uso não autorizado de sua conta. O Usuário ou Parceiro serão os únicos responsáveis pelas operações efetuadas em sua conta, uma vez que o acesso só será possível mediante a utilização de sistema de verificação que é exclusivo para sua conta.' },
	{ level2: 'I. Estes Termos incluem, mas não se limitam a utilização de funcionalidades "lembrar senha" ou "mantenha-me conectado" em computadores ou dispositivos móveis próprios ou de terceiros.' },
	{ level1: '3.7 Não serão permitidas a cessão, a venda, o aluguel ou outra forma de transferência da conta. Não se permitirá, ainda, a criação de novos cadastros por pessoas cujos cadastros originais tenham sido cancelados por infrações às políticas estabelecidas pelo KOORT.' },

	{
		title: '4 Responsabilidades do Usuário',
		level1: '4.1 Os Usuários são responsáveis e se obrigam a:'
	},
	{ level2: 'a) Reconhecer que todos os acessos realizados na Plataforma são interpretados como tendo sido feitos por ele próprio de forma incontestável;' },
	{ level2: 'b) Dispor de dispositivos, recursos e equipamentos tecnológicos com antivírus e firewall habilitados, softwares devidamente atualizados, além da adoção de medidas de segurança mínimas para acesso à Plataforma;' },
	{ level2: 'c) Deixar seus sistemas contra spams, filtros similares ou configurações de redirecionamento de mensagens ajustados de modo que não interfiram no recebimento dos comunicados e materiais do KOORT, não sendo aceitável nenhuma escusa caso não tenha tido acesso a algum e-mail ou mensagem eletrônica em virtude dos recursos mencionados; e' },
	{ level2: 'd) Responsabilizar-se integralmente em virtude de danos decorrentes de atos praticados por meio do acesso à Plataforma ou a terceiros.' },
	{ level1: '4.2 As funcionalidades da Plataforma são oferecidas na forma de prestação de serviço, não conferindo ao Usuário nenhum direito sobre o software relacionado à Plataforma ou sobre as estruturas de informática que a sustentam.' },
	{ level1: '4.3 O Usuário será o único responsável pelos custos decorrentes das negociações e negócios firmados com os Parceiros, bem como por despesas relacionadas a eventuais ações judiciais, incluindo custas processuais e honorários advocatícios.' },
	{ level1: '4.4 Ao utilizar a Plataforma, os Usuários devem observar toda a legislação e regras aplicáveis, inclusive as regras de conduta aqui estipuladas. Os Usuários declaram que não irão:' },
	{ level2: '4.4.1 Utilizar a Plataforma para finalidades fraudulentas;' },
	{ level2: '4.4.2 Coletar informações sobre outros Usuários da Plataforma;' },
	{ level2: '4.4.3 Publicar conteúdo não verídico, bem como publicar fotos que não correspondam aos imóveis anunciados;' },
	{ level2: '4.4.4 Incluir publicidade de terceiros em nossos serviços ou por meio deles sem a anuência prévia do KOORT;' },
	{ level2: '4.4.5 Interferir ou tornar indisponível a operação da Plataforma, dos servidores ou redes utilizadas para tornar a Plataforma disponível, inclusive invadir ou desconfigurar partes do site e/ou aplicativo; ou violar quaisquer requisitos, procedimentos, políticas ou regulamentos dos servidores ou redes;' },
	{ level2: '4.4.6 Reproduzir, duplicar, copiar, modificar, adaptar, traduzir, criar obras derivadas, vender, revender, alugar, emprestar, compartilhar por tempo parcial ou explorar qualquer parte, uso ou acesso ao site e/ou aplicativo, sem o consentimento prévio e por escrito do KOORT;' },
	{ level2: '4.4.7 Publicar, transmitir ou disponibilizar, de qualquer outra forma, através ou em conexão com a Plataforma quaisquer materiais que sejam ou possam ter conteúdos de: (a) ameaça, assédio, ódio ou que afrontem aos direitos e a dignidade de outras pessoas; (b) difamatório ou fraudulento (c) obsceno, indecente, pornográfico ou de outra forma censurável; ou (d) protegidos por direitos autorais, marca registrada, segredo comercial, sem o prévio consentimento prévio por escrito do proprietário;' },
	{ level2: '4.4.8 Realizar engenharia reversa, decompilar ou desmontar qualquer parte da Plataforma;' },
	{ level2: '4.4.9 Remover marca registrada, ou qualquer outro aviso de direitos de propriedade intelectual da Plataforma;' },
	{ level2: '4.4.10 Armazenar sistematicamente o conteúdo da Plataforma KOORT;' },
	{ level2: '4.4.11 Utilizar qualquer robô, spider, crawlers ou aplicações para decompilar, indexar, minerar ou, de qualquer forma, reunir conteúdo da Plataforma ou reproduzir ou alterar a estrutura de navegação ou a apresentação da Plataforma sem o consentimento prévio e por escrito do KOORT; e ' },
	{ level2: '4.4.12 Vender perfis de Usuários. ' },
	{ level1: '4.5 O Usuário concorda em defender, indenizar e manter indene o KOORT e seus diretores, empregados e agentes, de e contra quaisquer encargos, ações ou demandas, incluindo, mas não limitado a honorários advocatícios razoáveis, resultantes: (i) da sua eventual utilização indevida da Plataforma e seu Conteúdo; ou (ii) da violação das condições ora pactuadas.' },
	{ level1: '4.6 O Usuário poderá, a qualquer momento, solicitar a exclusão da sua Conta na Plataforma do KOORT, encerrando seu relacionamento com a Plataforma. Para solicitar a exclusão da sua Conta, o Usuário deverá (i) acessar a Plataforma do KOORT e selecionar a opção clicar no botão "Excluir Conta"; ou (ii) enviar um e-mail para atendimento@koortimoveis.com.br solicitando a exclusão.' },
	{ level1: '4.7 Mediante a solicitação de exclusão da Conta na Plataforma do KOORT pelo Usuário, os dados pessoais serão apagados, de forma que todos os dados coletados se tornem anônimos, não restando informações que possam indicar a identidade do Usuário, exceto aqueles que não possam ser excluídos em cumprimento ao disposto na legislação brasileira.' },
	{ level1: '4.8 A exclusão dos dados coletados implicará na imediata desativação da sua Conta na Plataforma, com perda permanente desses dados. Para voltar a usar o KOORT, o Usuário deverá iniciar os procedimentos necessários para criar uma nova Conta na Plataforma.' },
	{ level1: '4.9 KOORTIMATIVA – Trata-se de um serviço auxiliar de informação, consistindo na ferramenta utilizada pelo KOORT para fornecer estimativas de preços de venda e aluguel de imóveis anunciados pelos Parceiros. A KOORTIMATIVA se baseia nas características informadas no anúncio e não substitui a avaliação do imóvel, cabendo ao Usuário ou Parceiro responsável pelo anúncio a decisão sobre como utilizá-la, caso deseje, na definição dos valores anunciados. ' },

	{
		title: '5. Responsabilidades do Parceiro',
		level1: '5.1 O Parceiro obriga-se a fornecer aos Usuários os produtos e serviços exatamente como foram oferecidos e divulgados na Plataforma.'
	},
	{ level1: '5.2 O Parceiro obriga-se a formular descrições com a clareza e objetividade suficientes para que o Usuário possa compreender os detalhes e características relevantes dos produtos ou serviços. Ao disponibilizar o produto, serviço ou evento para comercialização na Plataforma, presume-se que o Parceiro tenha a capacidade de cumprir a oferta e realizá-lo nos termos e condições divulgados aos Usuários, na medida em que a oferta é vinculante e o seu cumprimento poderá ser exigido judicialmente, nos termos do artigo 30 do Código de Defesa do Consumidor e do artigo 429 do Código Civil.' },
	{ level1: '5.3 O Parceiro responsabiliza-se, exclusiva e integralmente, perante ao KOORT e aos Usuários, por qualquer falha na prestação dos serviços relacionados a comercialização de produtos ou serviços, que resulte em qualquer dano moral ou material causado a Usuários ou terceiros, bem como por devoluções de valores em decorrência de alterações nas condições anteriormente ofertadas.' },
	{ level1: '5.4 O Parceiro responsabiliza-se pela veracidade e exatidão do conteúdo que incluir na Plataforma e que esse conteúdo por direito pertence à entidade jurídica que representa.' },
	{ level1: '5.5 Sempre que um produto ou um serviço for comercializado pela Plataforma, o KOORT terá o direito exclusivo de receber comissão no valor de 12% do valor total de cada um dos serviços e/ou produtos, incluído no percentual as taxas aplicáveis pelas transações efetuadas por cartão de crédito.' },
	{ level2: '5.5.1 Este valor será cobrado integral e exclusivamente do ofertante do serviço e/ou produto comercializado.' },
	{ level2: '5.5.2 O KOORT se reserva ao direito de receber a comissão mesmo quando a comercialização não se efetive em virtude de arrependimento das Partes.' },

	{
		title: '6. Política de Cancelamento de Compra',
		level1: '6.1 Para o cancelamento das compras, os Usuários devem enviar a respectiva solicitação para o e-mail atendimento@koortimoveis.com.br, de acordo com as seguintes condições:'
	},
	{ level2: '6.1.2 O KOORT responderá a solicitação de cancelamento dentro do prazo de 48 (quarenta e oito) horas após o recebimento do e-mail, considerando-se o horário comercial para contagem do início do prazo e para envio da resposta;' },
	{ level2: '6.1.2 O pedido de cancelamento da compra poderá ocorrer em até 7 (sete) dias contados a partir da data da compra, desde que esse prazo não ultrapasse o prazo de 48 (quarenta e oito) horas que antecedam o evento;' },
	{ level2: '6.1.3 Em caso de cancelamento do pedido de compra pelo Usuário, o reembolso será realizado pelo Sistema de Pagamentos no prazo de até 45 (quarenta e cinco) dias após o cancelamento, sendo descontada a taxa de conveniência (se houver). No caso de compras com cartões de crédito, o valor será devolvido como crédito nas faturas seguintes.' },
	{ level1: '6.2 Após os prazos descritos não será possível o cancelamento da compra, visto que, após estes prazos, os valores recebidos são repassados diretamente aos Parceiros por meio do Sistema de Pagamento.' },

	{
		title: '7. Política de Cancelamento da Conta de Usuário ou Parceiro',
		level1: '7.1 O KOORT pode rescindir estes Termos, Contas de Usuários ou Parceiros ou acesso dos Usuários ou Parceiros à Plataforma e aos Serviços a qualquer momento, sem aviso ou consentimento prévio e a seu exclusivo critério. O Usuário ou Parceiro pode cancelar sua Conta do KOORT na própria Plataforma, desde que não tenha nenhuma obrigação pendente em relação ao KOORT ou Parceiras.'
	},
	{ level1: '7.2 Em caso de rescisão:' },
	{ level2: 'a) o Usuário ou Parceiro permanecerá responsável por todos os montantes devidos o KOORT ou a terceiros;' },
	{ level2: 'b) o KOORT, juntamente com os Parceiros, verificará eventuais valores que sejam legalmente devidos ao Usuário e lhes pagarão em tempo hábil; e' },
	{ level2: 'c) o KOORT não assumirá nenhuma responsabilidade por valores devidos por (ou a) terceiros.' },
	{ level1: '7.3 Em caso de cancelamento de sua conta, o KOORT não é obrigado a excluir ou devolver qualquer Conteúdo que você tenha enviado para a Plataforma ou para os Parceiros, incluindo, mas não se limitado a quaisquer opiniões, comentários ou depoimentos.' },
	{ level1: '7.4 O fato de o KOORT deixar de exercer ou executar qualquer direito ou disposição destes Termos não constitui uma renúncia à aplicação futura desse direito ou cláusula. A renúncia de qualquer direito ou cláusula só será eficaz se efetivada por escrito e assinada por um representante devidamente autorizado do KOORT.' },

	{
		title: '8. Isenções e Limitações de Responsabilidade do KOORT',
		level1: '8.1 O KOORT observa toda a legislação aplicável às suas atividades. A Plataforma não garante e não assume nenhuma responsabilidade pela conformidade de seus Usuários ou Parceiros com quaisquer leis ou regulamentos. Ao utilizar a Plataforma ou Serviços do KOORT, os Usuários e Parceiros declaram estar cientes, entenderem e concordarem que são exclusiva e totalmente responsáveis pelo cumprimento de todas e quaisquer leis, normas, regulamentos e obrigações legais e fiscais que possam ser aplicadas para o seu uso da Plataforma, Serviços e Conteúdos.'
	},
	{ level1: '8.2 Os Usuários e Parceiros reconhecem e concordam que:' },
	{ level2: 'a) O KOORT é apenas uma Plataforma de intermediação e, portanto, não poderá ser responsabilizada pelo cumprimento efetivo das obrigações assumidas pelos Parceiros;' },
	{ level2: 'b) O KOORT não será responsável por transações que ocorram fora de sua Plataforma ou pelos serviços e produtos oferecidos pelos Parceiros ou quaisquer terceiros, inclusive no que diz respeito a sua disponibilidade, qualidade, quantidade, características essenciais, ofertas, preços, formas de pagamento, cancelamentos, atrasos e quaisquer outros elementos a eles referentes, quando a transação ocorrer mesmo após os mecanismos de avaliação de riscos presentes na Plataforma ou quando acarretarem qualquer dano moral ou material ao Usuário ao qual o KOORT não tiver diretamente dado causa;' },
	{ level2: 'c) Por eventuais prejuízos sofridos pelos Usuários ou Parceiros em razão da tomada de decisões com base nas informações disponibilizadas na Plataforma;' },
	{ level2: 'd) O KOORT não se responsabiliza pelos produtos, serviços e informações oferecidos pelos Parceiros, nem pelas trocas, devoluções, chargebacks, cancelamentos, erros ou fraudes dos Usuários ou pelo cumprimento das obrigações tributárias dos Parceiros ou dos Usuários decorrentes da utilização da Plataforma;' },
	{ level2: 'e) O KOORT não tem qualquer responsabilidade relacionada às informações e às condições divulgadas ou aos preços e formas de pagamento oferecidas pelos Parceiros, incluindo todo e qualquer dano relacionado à insuficiência ou inadequação das informações dadas, publicidade enganosa ou abusiva, falta de pagamento ou descumprimento de prazos e condições estabelecidos;' },
	{ level2: 'f) O KOORT não gerencia diretamente os pagamentos efetuados por seus Usuários e não armazena os seus dados bancários ou dos seus cartões de crédito, utilizando, para maior segurança, serviços independentes especializados em receber e repassar pagamentos. O KOORT não assume qualquer responsabilidade por erros, contratempos ou prejuízos decorrentes do uso desses serviços, inclusive, mas não limitados a perda de valores, cobranças em excesso, atrasos na confirmação de pagamentos ou divulgação de dados de uso desses serviços, cadastrais, bancários ou sigilosos fornecidos durante as transações;' },
	{ level2: 'g) O KOORT não se responsabiliza pelas fraudes, nem por qualquer dano causado pela ação de terceiros falsários que, por meios escusos, furtarem os dados bancários e/ou dados dos cartões de crédito dos Usuários ou qualquer outra forma de pagamento. Também não se responsabiliza pelos vírus, trojans, malwares ou qualquer outro programa malicioso instalado no computador, tablet, celular, dispositivo móvel ou navegador do Usuário e que efetuem a modificação dos números gerados para pagamento por boleto bancário;' },
	{ level2: 'h) As compras realizadas pelos Usuários poderão ser canceladas a qualquer momento pela instituição financeira e/ou operadora do cartão de débito/crédito e a sua causa não poderá ser declarada pelo KOORT, pois são informações protegidas pelo sigilo bancário. Para estes casos, os Usuários deverão entrar em contato com a sua instituição financeira e/ou operadora do cartão de débito/crédito que possui relação jurídica;' },
	{ level2: 'i) As Partes reconhecem que o KOORT não será responsável perante aos Usuários ou Parceiros da Plataforma por quaisquer danos, indiretos, acidentais, consectários, especiais, exemplares ou punitivos de qualquer tipo, resultantes da aquisição dos produtos ou serviços oferecidos pelos Parceiros, ainda que o KOORT esteja ou devesse estar ciente da possibilidade de ocorrerem tais danos;' },
	{ level2: 'j) O KOORT não responderá por quaisquer danos ou prejuízos causados em seu(s) aparelho(s) celular(es), computador(es) ou quaisquer outros equipamentos eletrônicos, como resultado do acesso e utilização da Plataforma;' },
	{ level2: 'k) O KOORT não será responsável por erros ou interrupções no fornecimento de informações por sistemas independentes de prestação de serviços, como os sistemas de pagamento e os servidores, ou por sites integrados gerenciados por terceiros. Tais sistemas e sites integrados somente buscam trazer maior conveniência aos Usuários e Parceiros, sendo que o KOORT não tem qualquer influência ou responsabilidade sobre o conteúdo disponibilizado e os serviços oferecidos;' },
	{ level2: 'l) O KOORT não se responsabiliza por qualquer dano, prejuízo e/ou perda sofridos pelo Usuário ou Parceiro em razão de falhas em sua conexão com a internet, com o seu provedor, no sistema operacional ou servidor utilizados pelo Usuário ou Parceiro, decorrentes de condutas de terceiros, caso fortuito ou força maior;' },
	{ level2: 'm) O KOORT não será responsável por qualquer vírus, trojan, malware, spyware ou qualquer software que possa danificar, alterar as configurações ou infiltrar o equipamento do Usuário ou Parceiro em decorrência do acesso, da utilização ou da navegação na internet, ou, ainda, como consequência da transferência de quaisquer dados e informações;' },
	{ level2: 'n) Devem indenizar e/ou isentar de responsabilidade o KOORT e seus representantes, conforme o caso, na ocorrência de quaisquer reclamações, processos, perdas, responsabilidades e despesas, incluindo honorários advocatícios razoáveis e custas judiciais, resultantes dos danos que vier a causar ou sofrer direta ou indiretamente resultantes da relação entre Usuários e Parceiros;' },
	{ level2: 'o) O KOORT não se responsabiliza por qualquer ação ou omissão dos Usuários ou Parceiros decorrente de qualquer informação ou conteúdo disponibilizado na Plataforma por Parceiros ou terceiros; e ' },
	{ level2: 'p) O KOORT, seus administradores, procuradores, funcionários ou colaboradores estão isentos de responsabilidade em qualquer reclamação ou processo judicial movido pelos Usuários em face dos Parceiros, em razão dos serviços prestados por estas últimas, devendo ser excluídos do polo passivo de qualquer demanda nesse sentido. Na hipótese de interpelação judicial que tenha como Ré o KOORT, cujos fatos sejam fundados em ações ou na comercialização de produtos, serviços ou eventos do Parceiro, esta será chamada ao processo, devendo arcar com todos os ônus que daí decorram.' },

	{
		title: '9. Propriedade Intelectual',
		level1: '9.1 O uso comercial da expressão KOORT como marca, nome empresarial ou nome de domínio, bem como os logos, marcas, insígnias, layout do KOORT, conteúdo das telas relativas aos serviços do KOORT e o conjunto de programas, bancos de dados, redes e arquivos que permitem que o Usuário ou Parceiro acessem e usem sua conta, são de propriedade do KOORT e estão protegidos pelas leis e pelos tratados internacionais de direito autoral, de marcas e propriedade industrial. O uso indevido e a reprodução total ou parcial dos referidos conteúdos são proibidos.'
	},
	{ level1: '9.2 Todas as outras marcas comerciais, marcas de serviço, logotipos, nomes comerciais e quaisquer outras designações proprietárias são marcas comerciais ou marcas registradas de suas respectivas partes.' },
	{ level1: '9.3 Desde que em conformidade com estes Termos, o KOORT lhe concede uma licença limitada, não exclusiva e intransferível, para (i) acessar e visualizar qualquer Conteúdo da Plataforma, exclusivamente para fins pessoais e não comerciais e (ii) acessar e visualizar qualquer Conteúdo de Usuário ou Parceiro para o qual você tem permissão de acesso, exclusivamente para fins pessoais e não comerciais. Você não tem direito de sublicenciar os direitos de licença concedidos nesta seção. Você se compromete a não usar, copiar, adaptar, modificar, preparar trabalhos derivados com base, distribuir, licenciar, vender, transferir, exibir publicamente, executar publicamente, transmitir ou explorar a Plataforma, Serviços ou Conteúdos, exceto se expressamente permitido nestes Termos. Você se compromete a não remover, alterar ou ocultar quaisquer direitos autorais, marca registrada, marca de serviço ou outros direitos de propriedade incorporados ou acompanhando a Plataforma, Serviços ou Conteúdos. Nenhuma licença ou direitos são concedidos por implicação ou sob quaisquer direitos de propriedade intelectual pertencentes ou controlados pelo KOORT ou de seus licenciadores, exceto para as licenças e direitos expressamente concedidos nestes Termos.' },
	{ level1: '9.4 Qualquer intromissão, tentativa de, ou atividade que viole ou contrarie as leis de direito de propriedade intelectual e/ou as proibições estipuladas nestes Termos e Condições de Uso, tornarão o responsável passível das ações legais pertinentes, bem como das sanções aqui previstas, sendo ainda responsável pelas indenizações por eventuais danos causados.' },

	{
		title: '10. Contato',
		level1: '10.1 O KOORT poderá contatar o Usuário ou Parceiro por meio do número de telefone e/ou do e-mail fornecidos, sempre que necessário, para tratar de assuntos relacionados às funcionalidades da Plataforma como, por exemplo, para esclarecer dúvidas em relação à utilização, comunicar alterações nos Termos e Condições de Uso e/ou na Política de Privacidade, e realizar pesquisas em relação a novas funcionalidades e à experiência do Usuário ou Parceiro ao utilizar a nossa Plataforma.'
	},
	{ level1: '10.2 Além disso, poderemos fazer comunicações de caráter comercial e/ou promocional por e-mail referentes ao KOORT. Caso deseje não ser contatado para receber esse tipo de conteúdo, o Usuário poderá solicitar, a qualquer momento, o descadastramento de seu e-mail, enviando uma solicitação para o e-mail atendimento@koortimoveis.com.br.' },
	{ level1: '10.3 Para qualquer assunto relacionado às condições destes Termos e Condições de Uso ou da Política de Privacidade, bem como à Plataforma, o Usuário deverá entrar em contato com o KOORT por meio do e-mail atendimento@koortimoveis.com.br.' },

	{
		title: '11. Política de Privacidade',
		link: 'clicando aqui.',
		level1: '11.1 A forma como coletamos, armazenamos e tratamos os dados dos Usuários e Parceiros, bem como para quais finalidades eles poderão ser utilizados, estão descritas na Política de Privacidade do KOORT. A Política de Privacidade é parte integrante e inseparável destes Termos e Condições de Uso e pode ser acessada '
	},

	{
		title: '12. Disposições Gerais',
		level1: '12.1 Caso qualquer disposição destes Termos e Condições de Uso ou da Política de Privacidade seja considerada ilegal, nula ou inexequível por qualquer razão, as disposições restantes não serão afetadas e manter-se-ão válidas e aplicáveis na máxima extensão possível.'
	},
	{ level1: '12.2 Qualquer falha do KOORT para impor ou exercer qualquer disposição destes Termos e Condições de Uso, da Política de Privacidade ou direitos conexos, não constitui uma renúncia a esse direito ou disposição.' },
	{ level1: '12.3 O KOORT poderá alterar estes Termos e Condições de Uso ou a Política de Privacidade para, por exemplo, refletir alterações nas funcionalidades oferecidas.' },
	{ level1: '12.4 Quaisquer alterações nos Termos e Condições de Uso e na Política de Privacidade serão comunicadas aos Usuários e Parceiros pelo e-mail informado no momento do cadastro e publicadas na Plataforma, sendo o Usuário ou Parceiro responsável por analisar tais alterações.' },
	{ level1: '12.5 As alterações não serão aplicadas retroativamente e entrarão em vigor 15 (quinze) dias após sua publicação. O Usuário e Parceiro entendem e concordam que, após esse período, o uso da Plataforma passará a ser submetido à versão atualizada dos Termos e Condições de Uso e da Política de Privacidade. Se você não concordar com as modificações, recomendamos que solicite a exclusão da sua Conta na Plataforma do KOORT e descontinue o uso da mesma.' },

	{
		title: '13. Lei e Foro',
		level1: '13.1 Os Termos e Condições de Uso aqui descritos são interpretados segundo a legislação brasileira, no idioma português, sendo eleito o Foro do domicílio do Usuário, ou o foro da Capital do Estado do Rio de Janeiro, nas relações mantidas com Parceiros para, para dirimir qualquer litígio ou controvérsia envolvendo o presente instrumento, salvo ressalva específica de competência pessoal, territorial ou funcional pela legislação aplicável.'
	}

];

export default TermsAndConditions;
