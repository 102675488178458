import React from 'react';
import { Controller } from 'react-hook-form'
import { IMaskInput } from 'react-imask';

import { ErrorMessage } from '../error-message';

export function PhoneInputBootstrap({ control, name, label, error = null }) {
  return (
    <>
      <div className="form-floating">
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value } }) => (
            <IMaskInput
              className="form-control"
              mask='+55 (00) 0000-0000'
              autoComplete="off"
              value={value}
              onChange={onChange}
              placeholder="Telefone"
            />
          )}
        />

        <label htmlFor={name} className="form-label">{label}</label>
      </div>

      {error && <ErrorMessage message={error.message} />}
    </>
  );
}
