import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { BaseLabel } from '../../../../styled/labels/labels';
import { COLOR_1, COLOR_2 } from '../../../../styled/variables/variables';
import { mqTablet, hexToRgb } from '../../../../styled/helpers/helpers';
import { BaseButton } from '../../../../styled/buttons/buttons';

import userService from '../../../../services/user/user.service';

import pathsConstants from '../../../../constants/paths';

const Arguments = () => {
  const [userIsSubscriber, setUserIsSubscriber] = useState(false);

  async function loadUserProfile() {
    try {
      const user = userService.retrieve();

      const data = await userService.getUserProfile({
        token: user.token,
      });

      setUserIsSubscriber(data.isSubscriber);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    try {
      const user = userService.retrieve();

      if (!!user) {
        loadUserProfile();
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <Wrapper>
      {list.map(({ key, text }) => (
        <Item key={key}>
          <OrderIndicator>{key}</OrderIndicator>
          <Text>{text}</Text>
        </Item>
      ))}
      <CreateAdButton
        to={userIsSubscriber ? pathsConstants.DASHBOARD_ADS : pathsConstants.PURCHASE_CHOOSE_TYPE}
      >
        anuncie
      </CreateAdButton>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;

  ${mqTablet`
    align-self: flex-end;
    width: 330px;
  ` }
`;

const Item = styled.div`
  display: flex;
  margin-bottom: 15px;
  ${mqTablet`
    align-self: flex-start;
  ` }
`;

const OrderIndicator = styled(BaseLabel)`
  color: ${COLOR_1};
  font-size: 28px;
  align-self: flex-start;
  margin-right: 15px;
  font-weight: bold;

  ${mqTablet`
    font-size: 36px;
  ` }
`;

const Text = styled(BaseLabel)`
  color: ${COLOR_2};
  align-self: center;
  text-align: left;
  font-size: 14px;
  opacity: 0.75;
  width: 230px;
`;

const CreateAdButton = styled(BaseButton).attrs({
  as: Link,
})`
  background-color: ${COLOR_1};
  color: ${hexToRgb(COLOR_2, 0.75)};
  align-self: center;
  width: 200px;
  margin: 0;
  margin-top: 30px;
  border: none;
  font-weight: 700;
  line-height: 50px;

  transition: filter .2s;

  &:hover {
    filter: brightness(0.9);
    color: ${hexToRgb(COLOR_2, 0.75)};
  }

  ${mqTablet`
    align-self: flex-start;
    width: 160px;
  ` }
`;

const list = [
  {
    key: '1',
    text: 'Anuncie o seu imóvel para venda ou aluguel você mesmo, ou encontre um corretor.',
  },
  {
    key: '2',
    text: 'Descubra as tendências do mercado e valorize seu imóvel com fotos, vídeos e informações do entorno.',
  },
  {
    key: '3',
    text: 'Aproveite as parcerias do KOORT para fechar um bom negócio de maneira rápida e descomplicada.',
  },
];

export default Arguments;
