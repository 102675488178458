
import React, { PureComponent } from 'react';
import styled from 'styled-components';

import { BaseButton } from '../../../../styled/buttons/buttons';
import { OvalProfilePicture } from '../../../../styled/components/components';
import { BoldLabel } from '../../../../styled/labels/labels';
import { COLOR_2, COLOR_4 } from '../../../../styled/variables/variables';
import { mqTablet, mqCustom } from '../../../../styled/helpers/helpers';
//import RatingStars from '../../../../components/rating-stars';
import Steps from './steps/Steps';
import listingV2Service from '../../../../services/listing/listing-v2.service';
import queryStringUtil from '../../../../utils/query-string/query-string.util';
import pathsConstants from '../../../../constants/paths';
import LazyLoad from 'react-lazyload';
import { toast } from 'react-toastify';

/*
  global
  console
*/
class Slider extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      agents: [],
      centeredIndex: 0,
      page: 1,
      agentsPerPage: 4,
      isInitialSize: true,
      isFinalSize: false,
    };
  }

  getProfessionals = async () => {
    try {
      const response = await listingV2Service.getProfessionalsByHome();

      const { data, status } = response;

      if (status !== 200) {
        throw new Error('Não foi possível buscar os corretores!');
      }

      this.setState(prevState => ({
        ...prevState,
        agents: data.items,
      }));
    } catch {
      toast.error('Não foi possível buscar os corretores!', {
        autoClose: 3000,
      })
    }
  }

  componentDidMount() {
    this.getProfessionals();
  }

  seeProfile = (id) => {
    const { history } = this.props;
    history.push({
      pathname: pathsConstants.PROFILE_SALE,
      search: queryStringUtil.stringify(history, {
        id: id,
      }),
      state: {
        notUser: true
      }
    });
  }

  rightStepClick = () => {
    const { agents, page, agentsPerPage } = this.state;

    if (agents.length > (page + 1) * agentsPerPage) {
      this.setState(prevState => ({
        ...prevState,
        page: page + 1,
        isInitialSize: false,
      }));
    }

    if (agents.length > (page) * agentsPerPage) {
      this.setState(prevState => ({
        ...prevState,
        isFinalSize: true,
      }));
    }
  }

  leftStepClick = () => {
    const { page } = this.state;

    if (page >= 2) {
      this.setState(prevState => ({
        ...prevState,
        isFinalSize: false,
        page: page - 1
      }));
    }

    if (page === 2) {
      this.setState(prevState => ({
        ...prevState,
        isInitialSize: true,
      }));
    }
  }

  render() {
    const { agents, agentsPerPage, page } = this.state;
    const initialIndex = page === 1 ? 0 : agentsPerPage * page;
    const finalIndex = (agentsPerPage * page) + (page === 1 ? 0 : 4);

    return (
      <Wrapper style={{ 'maxWidth': '100%' }} >
        <CardsWrapper>
          {agents.length > 0 && agents.slice(initialIndex, finalIndex).map((agent) => (
            <Card key={agent._id}>
              <LazyLoad>
                <ProfilePicture pictureUrl={agent.profile.image} />
              </LazyLoad>
              <Name title={agent.name}>{agent.name}</Name>
              <SeeProfileButton onClick={() => { this.seeProfile(agent._id) }}>ver perfil</SeeProfileButton>
            </Card>
          ))}
        </CardsWrapper>
        <Steps
          isInitialSize={this.state.isInitialSize}
          isFinalSize={this.state.isFinalSize}
          handleRightClick={this.rightStepClick}
          handleLeftClick={this.leftStepClick}
          realtorsLength={this.state.agents.length}
        />
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${mqTablet`
    align-self: center;
  ` }

  @media (max-width: 635px) {
    overflow: hidden;
  }
`;

const CardsWrapper = styled.div`
  align-self: center;
  display: flex;
`;

const Card = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  background-color: ${COLOR_2};
  border-radius: 1px;;
  transition: .2s ease;
  transform: scale(0.8);
  margin: 0 -15px;
  width: fit-content;
  align-self: center;
  max-width: 152px;

  ${mqCustom(800)`
    display: flex;
    &:hover {
      transform: scale(1);
    }
  ` }
`;

const ProfilePicture = styled(OvalProfilePicture)`
  align-self: center;
  margin-bottom: 10px;
  background-position-y: unset;
  background-size: cover;
`;

const Name = styled(BoldLabel)`
  color: ${COLOR_4};
  font-size: 14px;
  margin-bottom: 5px;
  align-self: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90%;
`;

/* const SRatingStars = styled(RatingStars)`
  align-self: center;
  margin-bottom: 20px;
  & > svg {
    height: 14px;
  }
`; */

const SeeProfileButton = styled(BaseButton)`
  background-color: ${COLOR_4};
  color: ${COLOR_2};
  font-weight: 700;
  text-transform: uppercase;
  height: 30px;
  line-height: 30px;
  width: fit-content;
  align-self: center;
  font-size: 14px;
`;

export default Slider;
