import httpClient from '../../clients/http/http.client';

const BASE_PATH = '/api/v2';

const getRealEstatesFeatures = async () => {
  const path = `${BASE_PATH}/constants/realestates/features`;

  const response = await httpClient.get({ path });

  return response;
}

export default {
  getRealEstatesFeatures,
}
