
import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  updateGeolocation: [ 'geolocation' ],
});

const INITIAL_STATE = null;

const update = (_, action) => {
  return action.geolocation || null;
};

export default createReducer(INITIAL_STATE, {
  [Types.UPDATE_GEOLOCATION]: update,
});
