
import React from 'react';
import styled, { css } from 'styled-components';
import propTypes from 'prop-types';

import { mqTablet, dimmer } from '../../../../../styled/helpers/helpers';
import { COLOR_2, COLOR_1 } from '../../../../../styled/variables/variables';
import { BaseButton } from '../../../../../styled/buttons/buttons';

export const BUY_AD_TYPE = 0;
export const RENT_AD_TYPE = 1;
export const NEW_RELEASES_AD_TYPE = 2;

const buttonList = [
  {
    label: 'comprar',
    name: BUY_AD_TYPE,
  },
  {
    label: 'lançamentos',
    name: NEW_RELEASES_AD_TYPE,
  },
  {
    label: 'alugar',
    name: RENT_AD_TYPE,
  },
];

const AdTypeButtonList = ({ onButtonClick, selectedAdType }) => (
  <Wrapper>
    { buttonList.map(item => (
      <Button
        key={ item.name }
        name={ item.name }
        highlight={ selectedAdType === item.name }
        onClick={ onButtonClick }>{ item.label }
      </Button>
    )) }
  </Wrapper>
);

AdTypeButtonList.propTypes = {
  onButtonClick: propTypes.func.isRequired,
  selectedAdType: propTypes.oneOf([
    BUY_AD_TYPE,
    NEW_RELEASES_AD_TYPE,
    RENT_AD_TYPE,
  ]).isRequired,
};

const Wrapper = styled.div`
  display: flex;
  align-self: center;
  width: 100%;
  margin-bottom: 10px;
  height: 30px;
  
  ${ mqTablet`
    max-width: 500px;
    margin-bottom: 20px;
  ` }
`;

const Button = styled(BaseButton)`
  border: solid 1px ${ COLOR_2 };
  flex-grow: 1;
  flex-basis: 0;
  color: ${ COLOR_2 };
  background: none;
  text-transform: uppercase;
  border-radius: 0;
  margin: 0;
  margin-right: -1px;
  margin-right: 3px;
  padding: 0 7px;
  height: 100%;
  line-height: 30px;
  font-size: 12px;
  border-radius: 1px;
  font-weight: 300;
  
  &:last-child {
    margin-right: 0;
  }

  &:hover {
    ${ ({ highlight }) => !highlight && css`
      ${ dimmer(0.5, COLOR_1) }
    ` }
  }

  ${ ({ highlight }) => highlight && css`
    ${ dimmer(0.75, COLOR_1) }
  ` }
`;

export default AdTypeButtonList;
