import styled from 'styled-components';

export const FakeInputWrapper = styled.div`
  & + div {
    margin-top: 14px;
  }

  label {
    font-size: 15px;
    margin-bottom: 8px;
    font-weight: 500;
    color: #666666;
  }

  span {
    display: flex;
    align-items: center;
    background-color: #EEF0F4;
    border-radius: 8px;
    height: 54px;
    font-size: 16px;
    font-weight: 500;
    padding: 16px;
    color: #444444;
  }
`;
