import React, { Fragment, PureComponent } from 'react';
import styled from 'styled-components';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';

import StylingResetter from '../styled/globals/globals';

import Main from '../pages/main/Main';
import Loading from '../components/loading';
import withTracker from "../components/google-analytics/Tracker";
import Authentication from '../pages/authentication/Authentication';
import NewPasswordAuth from '../pages/new-password/NewPassword';
import { HandlingAccount } from '../pages/authentication/handling-account';

import httpClient from '../clients/http/http.client';
import userService from '../services/user/user.service';
import userFavoriteService from '../services/user/favorite/user.favorite.service';
import cookiesService from '../services/cookies/cookies.service';

import storageUtil from '../utils/storage/storage.util';
import domEventsUtil from '../utils/dom-events/dom-events.util';
import eventsConstants from '../constants/events';
import pathsConstants, { AUTHENTICATION_PARAMS } from '../constants/paths';

import './app.css'

class App extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      user: userService.retrieve(),
      cookiesAccept: storageUtil.get('cookies_accept', true) || null,
      form: {
        accessPassword: '',
      },
      // TODO: Remove it when on production.
      access: storageUtil.get('ACCESS', false) || false,
    };
    this.initAuthRoutines();
  }

  componentWillUnmount() {
    domEventsUtil.unsubscribe({
      name: eventsConstants.SIGN_OUT,
      callback: this.logout
    });
    domEventsUtil.unsubscribe({
      name: eventsConstants.AUTH_STATUS_CHANGED,
      callback: this.handleAuthStatusChanged
    });
  }

  initAuthRoutines = async () => {

    //initGA();
    // userService.init();

    if (!this.state.user) {
      var ok = await userService.checkIsAuth();
      if (ok) {
        window.location.reload();
      }
    }

    userService.subscribe(auth => {
      domEventsUtil.dispatch({
        name: eventsConstants.AUTH_STATUS_CHANGED,
        params: { auth, timeout: 1000 },
      });
    });

    domEventsUtil.subscribe({
      name: eventsConstants.AUTH_STATUS_CHANGED,
      callback: this.handleAuthStatusChanged
    });

    domEventsUtil.subscribe({
      name: eventsConstants.SIGN_OUT,
      callback: this.logout,
    });
  };

  logout = async () => {
    domEventsUtil.dispatch({
      name: eventsConstants.LOADING_ANIMATION,
      params: { show: true },
    });

    localStorage.clear()
    sessionStorage.clear()
    storageUtil.set('cookies_accept', true)

    domEventsUtil.dispatch({ name: eventsConstants.AUTH_STATUS_CHANGED, params: {} });
    domEventsUtil.dispatch({
      name: eventsConstants.LOADING_ANIMATION,
      params: { show: false },
    });
  }

  handleAuthStatusChanged = event => {
    const { auth, timeout } = event.detail.params;

    // The timeout bellow is meant to allow navigation before
    // the natural redirecting home when user is present.
    setTimeout(() => {
      this.setState(state => ({
        ...state,
        user: auth ? userService.retrieve() : null,
      }), () => {
        const { user } = this.state;
        if (user) this.getFavorites();
      });
    }, timeout || 0);
  };

  handleFormChange = event => {
    const { name, value } = event.target;

    this.setState({
      ...this.state,
      form: {
        [name]: value,
      },
    });
  };

  getFavorites = async () => {
    const { user } = this.state;
    const result = await userFavoriteService.get({ token: user.token });
    userFavoriteService.save({ ids: result, });
  };

  registerAcceptCookies = async event => {
    event.preventDefault();

    document.getElementById('koort-cookies').style.display = 'none';

    try {
      const cookieInfo = {
        type: "analytics",
        description: "Usamos cookies para personalizar conteúdos e melhorar a sua experiência. Ao navegar neste site, você concorda com a nossa Política de Cookies.",
        purpose: "Coletar informações sobre o uso do site para melhorar a experiência do usuário",
      };

      const method = "Botão de consentimento";

      await cookiesService.registerConsentCookies({ cookieInfo, method });
    } catch (err) {
      console.log(err);
    }

    const { user } = this.state;
    var id = null
    if (user) {
      id = user.id
      const path = '/users/preferences/cookie-policy';
      const response = await httpClient.patch({
        path,
        params:
        {
          "cookies": true,
        },
        token: user.token,
        options: {
          header: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.token
          },
        },
      }).catch((ex) => {
        console.log(ex)
      });


    } else {
      id = navigator.appName;
    }

    storageUtil.set('cookies_accept', true, id);
  }

  handleFormSubmit = event => {
    event.preventDefault();
    const { accessPassword } = this.state.form;
    if (accessPassword !== 'koort@bob') alert('Wrong password!');
    else {
      // TODO: Remove it when on production.
      storageUtil.set('ACCESS', true, false);
      this.setState({
        ...this.state,
        access: true,
      });
    }
  };

  render() {
    const { user } = this.state;

    return (
      <Fragment>
        <StylingResetter />
        <BrowserRouter>
          <Route component={withTracker(
            <Switch>
              {!user && (
                <Route
                  path={`${pathsConstants.AUTHENTICATION}/${AUTHENTICATION_PARAMS.NEW_PASSWORD}`}
                  component={NewPasswordAuth}
                />
              )}
              {!user && (
                <Route
                  path={`${pathsConstants.AUTHENTICATION}/${AUTHENTICATION_PARAMS.LOGIN}/${AUTHENTICATION_PARAMS.SUCCESS}`}
                  component={HandlingAccount}
                />
              )}
              {!user && (
                <Route
                  path={`${pathsConstants.AUTHENTICATION}/${AUTHENTICATION_PARAMS.SIGNUP}/${AUTHENTICATION_PARAMS.SUCCESS}`}
                  component={HandlingAccount}
                />
              )}
              {!user && (
                <Redirect
                  from={pathsConstants.AUTHENTICATION}
                  to={
                    `${pathsConstants.AUTHENTICATION}/${AUTHENTICATION_PARAMS.LOGIN}`
                  }
                />
              )}
              <Route
                path={pathsConstants.Home}
                component={Main}
              />
              <Redirect to={pathsConstants.HOME} />

            </Switch>,
            { /* additional attributes */ }
          )} />
        </BrowserRouter>
        <Loading />
        {!this.state.cookiesAccept &&
          <div className={'koort__cookies'} id="koort-cookies">
            <div className={'koort__cookies_content'}>
              <label>
                Usamos cookies para personalizar conteúdos e melhorar a sua experiência.
                Ao navegar neste site, você concorda com a nossa {' '}
                <PrivacyPolicyLink
                  href={pathsConstants.PRIVACY_POLICY}
                  target='_blank'
                  rel="noreferrer noopener"
                >
                  Política de Cookies
                </PrivacyPolicyLink>.
              </label>
              <button onClick={this.registerAcceptCookies}>ENTENDI</button>
            </div>
          </div>
        }
      </Fragment>
    );
  }
}

const PrivacyPolicyLink = styled.a`
  color: #FFFFFF;
  font-weight: 500;

  transition: filter .2s;

  &:hover {
    color: #FFFFFF;
    filter: brightness(0.8);
  }
`;

export default (
  <App />
);
