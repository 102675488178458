import styled from 'styled-components';

import { FacebookF } from 'styled-icons/fa-brands/FacebookF';
import { FaXTwitter } from "react-icons/fa6";
import { Instagram } from 'styled-icons/fa-brands/Instagram';
import { iconBaseStyle } from '../../styled/helpers/helpers';
import { COLOR_2 } from '../../styled/variables/variables';
import { pointer } from '../../styled/helpers/helpers';
import { transitions } from 'polished';

export const Wrapper = styled.div`
  display: flex;
`;

export const FacebookIcon = styled(FacebookF)`
  ${iconBaseStyle}
  height: 20px;
  color: ${COLOR_2};
  align-self: center;
  margin-right: 10px;
  ${pointer}

  ${transitions(['transform'], 'ease .1s')}
  &:hover {
    transform: scale(1.3);
  }
`;

export const TwitterIcon = styled(FaXTwitter)`
  ${iconBaseStyle}
  height: 20px;
  color: ${COLOR_2};
  align-self: center;
  margin-right: 10px;
  ${pointer}

  ${transitions(['transform'], 'ease .1s')}
  &:hover {
    transform: scale(1.3);
  }
`;

export const InstagramIcon = styled(FacebookIcon).attrs({
    as: Instagram,
})`
  margin-right: 0;
`;