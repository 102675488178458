
import BaseModel from '../base/base.model';

class User extends BaseModel {

  constructor({
    id,
    activePlan,
    name,
    publicName,
    email,
    isEmailVerified,
    phoneNumber,
    pictureUrl,
    publicPictureUrl,
    token,
    type,
  }) {
    super({ id });

    this.activePlan = activePlan;
    this.name = name;
    this.publicName = publicName;
    this.email = email;
    this.isEmailVerified = isEmailVerified;
    this.publicPictureUrl = publicPictureUrl;
    this.phoneNumber = phoneNumber;
    this.pictureUrl = pictureUrl;
    this.token = token;
    this.type = type;
  }
}

export default User;
