import React, { PureComponent } from 'react';
import styled from 'styled-components';
import Select from 'react-select';

import { BaseLabel } from '../../../../styled/labels/labels';

class Features extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        features: props.features,
      },
      fieldsHasFilled: false,
      generalPropertiesUnformatted: [],
      additionalPropertiesUnformatted: [],
      generalPropertiesFormatted: [],
      additionalPropertiesFormatted: [],
    };
  }

  handleSelectChange = (arr, name) => {
    if (name === "selectedOptionsGeneral") {
      this.setState(prevState => ({
        ...prevState,
        generalPropertiesUnformatted: arr,
        generalPropertiesFormatted: arr !== null ?
          arr.map(item => item.value) : [],
      }));
    }

    if (name === "selectedOptionsAdditional") {
      this.setState(prevState => ({
        ...prevState,
        additionalPropertiesUnformatted: arr,
        additionalPropertiesFormatted: arr !== null ?
          arr.map(item => item.value) : [],
      }));
    }

    this.setState(prevState => ({
      ...prevState,
      form: {
        features: prevState.generalPropertiesFormatted.concat(prevState.additionalPropertiesFormatted)
      }
    }));
  }

  render() {
    const { isEditing, populate, data } = this.props;
    const { fieldsHasFilled } = this.state;

    if (isEditing) {
      if (populate.length > 0 && !fieldsHasFilled && !!data.length) {
        let generalProperties = [];

        for (let i = 0; i < data.length; i++) {
          for (let j = 0; j < populate.length; j++) {
            if (data[i].key === populate[j].key) {
              generalProperties.push({
                label: data[i].label,
                value: data[i].value,
              });
            }
          }
        }

        this.setState(prevState => ({
          ...prevState,
          fieldsHasFilled: true,
          generalPropertiesUnformatted: generalProperties,
          generalPropertiesFormatted: generalProperties.map(item => item.label),
          form: {
            features: generalProperties.map(item => item.label),
          }
        }));
      }
    }

    const customStyle = {
      control: base => ({
        ...base,
        border: '1px solid #999',
        boxShadow: 'none'
      }),
      option: (provided, state) => ({
        ...provided,
        fontSize: 16,
      }),
      input: (provided, state) => ({
        ...provided,
        fontSize: 25,
      }),
    }

    return (
      <Wrapper>
        <GroupTitle>
          características gerais do imóvel
        </GroupTitle>

        <GroupWrapper>
          <Select
            styles={customStyle}
            noOptionsMessage={() => 'Sem opções'}
            onChange={(value) => { this.handleSelectChange(value, 'selectedOptionsGeneral') }}
            name="options"
            id="options"
            placeholder={'Selecione'}
            value={this.state.generalPropertiesUnformatted}
            options={data}
            isMulti={true}
            theme={theme => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary: 'none',
              },
            })}
          />
        </GroupWrapper>
      </Wrapper>
    );
  }
}

const Wrapper = styled.form`
  margin-bottom: 20px;

  div#options {
    margin-bottom: 20px;
  }
`;

const GroupTitle = styled(BaseLabel)`
  text-transform: uppercase;
  font-size: 14px;
  margin-bottom: 10px;
`;

const GroupWrapper = styled.div``;

const options = {
  general: [
    { label: 'Ambientes Integrados', value: 'Ambientes Integrados' },
    { label: 'Armários Planejados', value: 'Armários Planejados' },
    { label: 'Closet', value: 'Closet' },
    { label: 'Cozinha Americana', value: 'Cozinha Americana' },
    { label: 'Gás Encanado', value: 'Gás Encanado' },
    { label: 'Acessibilidade', value: 'Acessibilidade' },
    { label: 'Área de Serviço', value: 'Área de Serviço' },
    { label: 'Quarto/WC Empregada', value: 'Quarto/WC Empregada' },
    { label: 'Terraço', value: 'Terraço' },
    { label: 'Varanda', value: 'Varanda' },
  ],
  additional: [
    { label: 'Mobiliado', value: 'Mobiliado' },
    { label: 'Piscina', value: 'Piscina' },
    { label: 'Sauna', value: 'Sauna' },
    { label: 'Churrasqueira', value: 'Churrasqueira' },
    { label: 'Vista Panorâmica', value: 'Vista Panorâmica' },
  ],
};

export default Features;
