import React from 'react';

import { MessageTextareaWrapper, TextArea } from './styles';

export function MessageTextarea({ label, value }) {
  return (
    <MessageTextareaWrapper>
      <label>{label}</label>

      <TextArea
        id="message-textarea"
        placeholder="As mensagens estarão aqui..."
        disabled
        value={value}
      />
    </MessageTextareaWrapper>
  );
}
