import React from 'react';
import { Controller } from 'react-hook-form'
import { IMaskInput } from 'react-imask';
import { ErrorMessage } from '../error-message';

export function CepInput({ control, name, label, error = null }) {
  return (
    <>
      <div className="form-floating">
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value, onBlur } }) => (
            <IMaskInput
              className="form-control"
              mask='00000-000'
              autoComplete="off"
              value={value}
              onChange={onChange}
              placeholder="CEP"
              onBlur={onBlur}
            />
          )}
        />

        <label htmlFor={name} className="form-label">{label}</label>
      </div>

      {error && <ErrorMessage message={error.message} />}
    </>
  );
}
