import styled from 'styled-components';

import { COLOR_18 } from '../../styled/variables/variables';
import { BaseLabel } from '../../styled/labels/labels';
import RatingStars from '../../components/rating-stars';
import { mqTablet } from '../../styled/helpers/helpers';


export const Wrapper = styled.div`
  background-color: ${ COLOR_18 };
  padding: 30px;
  border-radius: 1px;
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;

  ${ mqTablet`
    flex-direction: row;
  ` }
`;

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
`;

export const MainTitle = styled(BaseLabel)`
  font-size: 38px;
  font-weight: 700;
  align-self: center;
  margin-bottom: 10px;
`;

export const MainRatingStars = styled(RatingStars)`
  margin-right: 10px;
  align-self: center;
`;

export const CategoryListWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${ mqTablet`
    flex-grow: 1;
    align-self: flex-end;
  ` }
`;

export const CategoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  ${ mqTablet`
    flex-direction: row;
    align-self: flex-end;
  ` }
`;

export const CategoryTitle = styled(BaseLabel)`
  font-size: 16px;
  align-self: center;
  margin-bottom: 5px;

  ${ mqTablet`
    margin-right: 20px;
  ` }
`;

export const CategoryRatingWrapper = styled.div`
  display: flex;
  align-self: center;
`;

export const CategoryRatingStars = styled(MainRatingStars)`
  margin-right: 10px;
  align-self: center;
`;

export const CategoryRating = styled(CategoryTitle)`
  font-weight: 700;
  margin: 0;
  margin-top: 1px;
  min-width: 30px;
`;