import styled, { css } from 'styled-components';
import { PhotoSizeSelectSmall} from 'styled-icons/material';
import { Bed } from 'styled-icons/boxicons-regular/Bed';

import { COLOR_2, COLOR_3 } from '../../../styled/variables/variables';
import { iconBaseStyle, mqTablet } from '../../../styled/helpers/helpers';
import { LoadingAnimation } from '../../../styled/components/components';

export const Container = styled.section`
  background-color: ${COLOR_2};
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 30px;

  display: flex;
  flex-direction: column;
  align-items: center;

  ${mqTablet`
    height: 100vh;
    min-height: 910px;
  `}

  @media (max-width: 992px) {
   padding: 0 24px;
  }
`;

export const ReleasesSlider = styled.div`
  width: 100%;
  max-width: 1100px;

  @media (max-width: 1180px) {
    max-width: 900px;
  }

  @media (max-width: 992px) {
    max-width: 700px;
  }
`;

export const ReleaseSlideItem = styled.div`
  max-width: 1000px;
  margin: 0 auto;

  height: 440px;
  border: 20px solid #8096E8;
  background-color: #2B50D8;

  ${({isRelease}) => isRelease && css`
    border: 20px solid #B9E080;
    background-color: #8ACC2C;
  `}

  display: flex;
  align-items: flex-start;

  img {
    width: 60%;
    min-width: 60%;
    object-fit: cover;
    height: 100%;
  }

  @media (max-width: 1180px) {
    max-width: 800px;

    img {
      width: 52%;
      min-width: 52%;
    }
  }

  @media (max-width: 992px) {
    flex-direction: column;

    height: 100%;

    img {
      width: 100%;
      min-width: 100%;
      min-height: 240px;
      max-height: 240px;
    }
  }

  @media (max-width: 992px) {
    max-width: 600px;
  }
`;

export const ReleaseSlideInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: 100%;

  strong {
    font-size: 28px;
    color: #ffffff;
    font-weight: 700;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  div {
    flex: 1;
  }

  a {
    height: 54px;
    padding: 16px;
    background-color: #ffffff;
    color: #000000;
    font-weight: 600;
    text-decoration: none;
    width: fit-content;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }
  }

  @media (max-width: 992px) {
    strong {
      -webkit-line-clamp: 2;
    }
  }
`;

export const LoadingSpinner = styled(LoadingAnimation).attrs({
  color: COLOR_3,
})``;

export const Features = styled.div`
  display: flex;

  margin-top: 8px;

  span {
    display: flex;
    align-items: center;
    color: #ffffff;
    font-size: 14px;

    & + span {
      margin-left: 12px;
    }
  }
`;

export const M2Icon = styled(PhotoSizeSelectSmall)`
  ${ iconBaseStyle }
  color: ${ COLOR_2 };
  height: 17px;
  align-self: center;
  margin-right: 8px;
`;

export const BedroomsIcon = styled(M2Icon).attrs({
  as: Bed,
})``;

export const Property = styled.div`
  margin-top: 20px;

  p {
    margin: 0;
    color: #ffffff;

    display: flex;
    align-items: center;
    font-size: 14px;

    > svg {
      margin-right: 8px;
    }

    & + p {
      margin-top: 8px;
    }
  }
`;

export const Price = styled.span`
  display: block;
  margin-top: 20px;
  font-size: 22px;
  font-weight: 600;
  color: #ffffff;

  @media (max-width: 992px) {
    margin-bottom: 20px;
  }
`;

export const SeeMoreButton = styled.div`
  display: none;

  ${mqTablet`
    display: flex;
    justify-content: center;
    margin-top: 100px;
    align-self: center;
    align-items: center;
  `}
`;
