
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Persist } from 'react-persist-plus';

import InputWrapper from '../../../components/input-wrapper';
import Checkbox from '../../../components/checkbox';

/*
  global
  window
*/

class MainInfo extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      form: {
        bathrooms: false,
        bedrooms: false,
        suites: false,
        parkingSpaces: false,
        area: false,
      },
    };

  }

  handleFormChange = event => {
    const { name, checked } = event.target;

    this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        [name]: checked,
      },
    }));
  };

  render() {
    const { form } = this.state;

    return (
      <Wrapper
        onChange={ this.handleFormChange }
      >
        <Persist
          name='REPORT_BUILDER_MAIN_INFO_FORM'
          data={ form }
          onMount={ data => this.setState(state => ({
            ...state,
            form: data,
          })) }
          store={ window.sessionStorage }
        />
        <AInputWrapper
          label='Tipo (Apartamento, Casa...)'
          htmlFor='property'
        >
          <ACheckBox
            id='property'
            name='property'
            checked={ form.property }
          />
        </AInputWrapper>

        <AInputWrapper
          label='Quartos'
          htmlFor='bedrooms'
        >
          <ACheckBox
            id='bedrooms'
            name='bedrooms'
            checked={ form.bedrooms }
          />
        </AInputWrapper>

        <AInputWrapper
          label='Suítes'
          htmlFor='suites'
        >
          <ACheckBox
            id='suites'
            name='suites'
            checked={ form.suites }
          />
        </AInputWrapper>

        <AInputWrapper
          label='Banheiros'
          htmlFor='bathrooms'
        >
          <ACheckBox
            id='bathrooms'
            name='bathrooms'
            checked={ form.bathrooms }
          />
        </AInputWrapper>

        <AInputWrapper
          label='Vagas de Garagem'
          htmlFor='parkingSpaces'
        >
          <ACheckBox
            id='parkingSpaces'
            name='parkingSpaces'
            checked={ form.parkingSpaces }
          />
        </AInputWrapper>

        <AInputWrapper
          label='Área'
          htmlFor='area'
        >
          <ACheckBox
            id='area'
            name='area'
            checked={ form.area }
          />
        </AInputWrapper>
      </Wrapper>
    );
  }
}

const Wrapper = styled.form`
  margin-bottom: 20px;
`;

const AInputWrapper = styled(InputWrapper)`
  flex-direction: row-reverse;
  justify-content: flex-end;
  margin-bottom: 10px;
`;

const ACheckBox = styled(Checkbox)`
  margin-right: 5px;
`;

export default MainInfo;
