/*
  global
  window
  process
*/

/**
 * Variáveis Globais
 */
var GLOBAL_MARKER_CLUSTER = null;
var GLOBAL_INFO_WINDOW = null;
var LAST_POSITION_MOUSE_X = null;
var LAST_POSITION_MOUSE_Y = null;
var _lastDrawing = null;
var GLOBAL_MAP = null;
var GLOBAL_LIST_MARKERS = null;
var GLOBAL_INTERVAL = null;
var GLOBAL_INTERVAL_2 = null;
var GLOBAL_MOUSE_ENTER_WINDOW = null;
var GLOBAL_COUNT = 0;

const styleMarkerCluster = [window.MarkerClusterer.withDefaultStyle({
  width: 32,
  height: 32,
  url: `${process.env.PUBLIC_URL}/imgs/marker-cluster.png`,
  textColor: "#fff",
  textSize: 12,
})];

/**
 *
 * @param { (Object) } Map Mapa que vai receber as marcações.
 * @param { (Object) } realEstates Lista de itens ( deve ter latitude e longitude como atributo )
 * @param { (Object) } Props Acesso a properties do componente
 * @param { (Object) } drawingManager Acesso a properties do drawing
 * @returns { (Array) } Lista de google.marker.
 */
export function createMarkersInMap(map, realEstatesCoordinates, props, drawingManager=undefined, clearClusters = false) {
  GLOBAL_MAP = map;

  if (GLOBAL_MARKER_CLUSTER !== null && clearClusters) {
    GLOBAL_MARKER_CLUSTER.clearMarkers();
  }

  const markers = realEstatesCoordinates.map((item, index) => {
    let marker = new window.google.maps.Marker({
      flat: true,
      // MONGO SALVA LONG-LAT
      position: { lat: item.coordinates[1], lng: item.coordinates[0] },
      icon: (item.isLaunch ? `${process.env.PUBLIC_URL}/imgs/mark-launch.png` : `${process.env.PUBLIC_URL}/imgs/mark-cluster-1.png`),
      data: item,
      optimized: false,
      visible: true,
    });

    //addFunctionsInMarker(marker, props, map, drawingManager);

    return marker;
  });
  addMarker(map, markers, props);
  return markers;
};

/**
 *
 * @param {Mapa que vai receber os markers } map
 * @param {lista de google.marker} markers
 * @returns
 */
function addMarker(map, markers, props) {
  GLOBAL_LIST_MARKERS = markers;

  let markerEquals = {}
  let listMarkerEquals = []

  markers.forEach(item => {
    if (markerEquals[item.position.lat() + "," + item.position.lng()]) {
      let itemE = markerEquals[item.position.lat() + "," + item.position.lng()]
      listMarkerEquals.push(itemE);
      listMarkerEquals.push(item);
    }
    markerEquals[item.position.lat() + "," + item.position.lng()] = item;

    item.setMap(map)
  });

  GLOBAL_MARKER_CLUSTER = new window.MarkerClusterer(map, markers, {
    maxZoom: 16,
    zoomOnClick: false,
    styles: styleMarkerCluster,
  });

  window.google.maps.event.addListener(GLOBAL_MARKER_CLUSTER, "click", function (mCluster, e) {
    const markers = mCluster.getMarkers();
    const realEstatesData = markers.map(item => item.data);

    if (realEstatesData.length > 10) {
      mCluster.markerClusterer_.setZoomOnClick(true);
      props.onHideHorizontalScrollList();
    } else {
      props.onMarkerClick(realEstatesData);
    }
  });

  window.google.maps.event.addListener(GLOBAL_MARKER_CLUSTER, "mouseover", function (mCluster, e) {
    var markers = mCluster.getMarkers();
    LAST_POSITION_MOUSE_Y = mCluster.center_.lat();
    LAST_POSITION_MOUSE_X = mCluster.center_.lng();
  });

  window.google.maps.event.addListener(map, 'click', function (event) {
    if (GLOBAL_INFO_WINDOW) {
      GLOBAL_INFO_WINDOW.close();
    }
  });

  window.google.maps.event.addListener(map, 'mousemove', function (event) {

    if (!GLOBAL_INTERVAL_2) {
      GLOBAL_INTERVAL_2 = window.setInterval(() => {
        if (GLOBAL_INFO_WINDOW && !GLOBAL_MOUSE_ENTER_WINDOW) {
          GLOBAL_INFO_WINDOW.close();
        }

        window.clearInterval(GLOBAL_INTERVAL_2)
        GLOBAL_INTERVAL_2 = null;
      }, 1500)

    }
  });

};

/**
 *
 * @param {*} marker que vai receber as alterações
 * @param {*} props acesso as propriedades do documento
 */
function addFunctionsInMarker(marker, props, map, drawingManager) {
  marker.addListener('click', function () {
    const realEstatesData = [];
    realEstatesData.push(marker.data);

    props.onMarkerClick(realEstatesData);
  });
}

export function closeInfoWindowMarker(marker) {
  if (marker.data.isInfoWindow) {
    marker.data.infoWindow.close();
    marker.data.isInfoWindow = false;
    GLOBAL_COUNT = 1;
  }
}

export function getWindowSize() {
  var win = window,
    doc = window.document,
    docElem = doc.documentElement,
    body = doc.getElementById('map'),
    x = win.innerWidth || docElem.clientWidth || body.clientWidth,
    y = win.innerHeight || docElem.clientHeight || body.clientHeight;
  return x, y
}

export function openInfoWindowMarker(marker, map, cluster) {
  if (!marker) {
    return;
  }
  if (GLOBAL_INFO_WINDOW) {
    GLOBAL_INFO_WINDOW.close();
  }

  var offset = null;
  var y = 0;
  var x = 0;
  if (LAST_POSITION_MOUSE_Y < 320) {
    y = 240;
    x = -130;
  }
  if (LAST_POSITION_MOUSE_X < 250) {
    x = 130;
    if (y == 0) {
      y = 20;
    }
  }

  if (LAST_POSITION_MOUSE_X > 750) {
    x = -130;
    if (y == 0) {
      y = 20;
    }
  }

  if (y || x) {
    offset = new window.google.maps.Size(x, y)
  }

  var infoWindow = new window.google.maps.InfoWindow();
  infoWindow.setOptions({ disableAutoPan: true, pixelOffset: offset });
  infoWindow.setContent(marker.data.html);
  infoWindow.open(GLOBAL_MAP, marker);
  marker.data.isInfoWindow = true;
  marker.data.infoWindow = infoWindow;
  GLOBAL_INFO_WINDOW = infoWindow;
}

window.openNewWindow = function (id) {
  const win = window.open(`/search/detail?listingId=${id}`, "_blank");
  win.focus();
}

window.customFunction = function (lat, lng, id) {
  let list = []
  GLOBAL_LIST_MARKERS.forEach(item => {
    if (item.position.lat() == lat && item.position.lng() == lng && id != item.data.listingId) {
      list.push(item);
    }
  });

  let i = Math.floor(Math.random() * list.length);
}

window.mouseIn = function (e) {
  GLOBAL_MOUSE_ENTER_WINDOW = true
}

window.mouseOut = function (e) {
  GLOBAL_MOUSE_ENTER_WINDOW = false
}

export function clearDrawingMap(param) {
  param.forEach(p => {
    if (
      p.type == "circle" ||
      p.type == "polyline" ||
      p.visible
    ) {
      if (p.setMap) {
        p.setMap(null);
      } else if (p.overlay.setMap) {
        p.overlay.setMap(null);
      }
    }
  });
}

export function disabledMarkersNoSelect(markers, map, param) {
  if (param.length == 1) {
    //Aplicar zoom caso seja a primeira marca
    if (param[0].type == "circle") {
      map.fitBounds(param[0].overlay.getBounds());
    } else if (param[0].type == "polyline") {
      var bounds = new window.google.maps.LatLngBounds;
      param[0].overlay.getPath().forEach(function (latLng) {
        bounds.extend(latLng);
      });
      map.fitBounds(bounds);
    } else if (param[0].visible) {
      var bounds = new window.google.maps.LatLngBounds;
      param[0].getPath().forEach(function (latLng) {
        bounds.extend(latLng);
      });
      map.fitBounds(bounds);
    }
  }

  return markers.filter(marker => {
    const position = new window.google.maps.LatLng({
      lat: marker.position.lat(),
      lng: marker.position.lng(),
    });

    let markerEnable = false;
    param.forEach(p => {
      if (
        (p.type == "circle" && window.google.maps.geometry.spherical.computeDistanceBetween(position, p.overlay.getCenter()) <= p.overlay.getRadius()) ||
        (p.type == "polyline" && window.google.maps.geometry.poly.containsLocation(position, p.overlay)) ||
        (p.visible && window.google.maps.geometry.poly.containsLocation(position, p))
      ) {
        markerEnable = true;
        marker.setIcon(marker.data.highlight ? `${process.env.PUBLIC_URL}/imgs/mark-highlight.png` : `${process.env.PUBLIC_URL}/imgs/mark-cluster-1.png`);
      }
    });
    if (!markerEnable) {
      marker.setIcon(`${process.env.PUBLIC_URL}/imgs/mark-off.png`);
    } else {
      return marker;
    }
  });
}
