import styled, { css } from 'styled-components';

export const AnnotationTextareaWrapper = styled.div`
  label {
    display: block;
    font-size: 15px;
    margin-bottom: 8px;
    font-weight: 500;
    color: #666666;
  }
`;

export const TextArea = styled.textarea`
    background-color: transparent;
    resize: none;
    height: 200px;
    width: 100%;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    padding: 16px;
    color: #444444;
    border: 1px solid #EEF0F4;

    ${(props) => !props.isResponsibleLead && css`
      background-color: #EEF0F4;
      border: 0px;
    `}
`;
