import styled from 'styled-components';
import { FindInPage } from 'styled-icons/material/FindInPage';

import { iconBaseStyle } from '../../styled/helpers/helpers';
import { COLOR_16 } from '../../styled/variables/variables';
import { BaseLabel } from '../../styled/labels/labels';

export const Wrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Icon = styled(FindInPage)`
  ${ iconBaseStyle }
  height: 60px;
  color: ${ COLOR_16 };
  align-self: center;
  margin-bottom: 20px;
`;

export const Label = styled(BaseLabel)`
  align-self: center;
  color: ${ COLOR_16 };
  font-size: 18px;
`;