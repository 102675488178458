import httpClient from '../../clients/http/http.client';

const getCompanyBrokers = async ({ companyId, page, pageSize = 10, email = '', token }) => {
  let path;

  if (!!email) {
    path = `/companies/${companyId}/realtors?page=${page}&size=${pageSize}&email=${email}`
  } else {
    path = `/companies/${companyId}/realtors?page=${page}&size=${pageSize}`
  }

  const response = await httpClient.get({
    path,
    token: token,
  });

  return response;
}

const registerBroker = async ({
  companyId,
  brokerName,
  brokerEmail,
  token }) => {
  const response = await httpClient.post({
    path: `/companies/${companyId}/realtors`,
    params: {
      name: brokerName,
      email: brokerEmail,
    },
    token: token,
  });

  return response;
}

const deleteBrokerCompany = async ({ companyId, brokerId, token }) => {
  const response = await httpClient.del({
    path: `/companies/${companyId}/realtors/${brokerId}`,
    token: token,
  });

  return response;
}

const attachPropertyToBroker = async ({ realEstateId, brokerId, token }) => {
  const response = await httpClient.patch({
    path: `/realestates/${realEstateId}/assign`,
    params: {
      userId: brokerId,
    },
    token,
  });

  return response;
}

const detachPropertyToBroker = async ({ realEstateId, token }) => {
  const response = await httpClient.patch({
    path: `/realestates/${realEstateId}/unassign`,
    token,
  });

  return response;
}

const getRealEstatesAttachedBroker = async ({ brokerId, token, page = 1 }) => {
  const response = await httpClient.get({
    path: `/realestates/search/${brokerId}?page=${page}`,
    token,
  });

  return response;
};

export default {
  getCompanyBrokers,
  registerBroker,
  deleteBrokerCompany,
  attachPropertyToBroker,
  getRealEstatesAttachedBroker,
  detachPropertyToBroker,
}
