
import React, { PureComponent } from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import { BaseButton } from '../../../styled/buttons/buttons';
import { COLOR_1, COLOR_2, COLOR_3, COLOR_6 } from '../../../styled/variables/variables';
import './plan.css'

import PlansExpose, { TYPES, MODES } from '../../../components/plans-expose/PlansExpose';
import NoResults from '../../../components/no-results';
// import Payment from './payment/Payment';

import planService from '../../../services/plan/plan.service';
import currencyUtil from '../../../utils/currency/currency.util';
import pathsConstants from '../../../constants/paths';
import queryStringUtil from '../../../utils/query-string/query-string.util';

class Plan extends PureComponent {

  constructor(props) {
    super(props)

    this.state = {
      plans: [],
      products: [],
      planSelected: null,
      loaded: false,
    }
  }

  componentDidMount() {
    this.getPlans()
    this.getProducts()
  }

  handleOptionsClick = realEstateId => {
    const { history } = this.props;

    history.push({
      pathname: pathsConstants.REPORT,
      search: queryStringUtil.stringify(history, {
        realEstateId,
      }),
    });
  };

  selectPlan = (event) => {
    console.log('oi' , event.target.value)
    this.state.products.forEach((item, i) => {
      if (item.ident == event.target.value) {
        this.setState(state => ({
          ...state,
          planSelected: item,
        }))
      }
    });
    // planSelected
  }

  getProducts = async () => {
    const { user, history } = this.props;
    if (!user || !user.token) {
      history.push(`${pathsConstants.AUTHENTICATION}/${pathsConstants.AUTHENTICATION_PARAMS.LOGIN}`);
    }
    let results
    try {
      results = await planService.getProduct({
        token: user.token
      })
      this.setState(state => ({
        ...state,
        products: results.data || [],
        loaded: true,
      }))
    } catch (error) {
      console.error(error)
    }
  }



  getPlans = async () => {
    const { user, history } = this.props;
    if (!user || !user.token) {
      history.push(`${pathsConstants.AUTHENTICATION}/${pathsConstants.AUTHENTICATION_PARAMS.LOGIN}`);
    }
    const { plans } = this.state;
    let results
    try {
      results = await planService.get({
        token: user.token
      })
    } catch (error) {
      // 
    }
    const cancellationExposeConfigs = [
      plans.map(item => {
        return (
          {
            key: TYPES.MONTHLY,
            name: plans.length > 0 ? item.name : '',
            price: plans.length > 0 ? item.price : '',
            isBestSeller: false,
            features: [
              {
                key: `${TYPES.MONTHLY}_ads`,
                indicator: 'anúncios',
                value: plans.length > 0 ? item.numberOfAds : '',
              },
              {
                key: `${TYPES.MONTHLY}_use`,
                indicator: 'anúncios utilizados',
                value: plans.length > 0 ? item.ads : '',
              },
              {
                key: `${TYPES.MONTHLY}_pictures`,
                indicator: 'destaques',
                value: plans.length > 0 ? item.numberOfHighlights : '',
              },
              {
                key: `${TYPES.MONTHLY}_analysis`,
                indicator: 'análises',
                value: 'ilimitado',
              },
            ],
          }
        )
      })
    ];
    await this.setState(state => ({
      ...state,
      plans: cancellationExposeConfigs || []
    }));
    await this.setState(state => ({
      ...state,
      loaded: true,
    }))

  }
  render() {
    const { plans, products } = this.state;
    //debugger
    return (
      <Wrapper>
        {this.state.loaded &&
          <>
            <div>
            <div className={'container-select-product form-floating'} style={{maxWidth: '300px', float: 'left'}}>
              <select className={'form-select'} id={"product"} onChange={(e) => {this.selectPlan(e)}}>
                {this.state.loaded && products.map((item, index) => {
                  if (index === 0) {
                    this.state.products.forEach((ii, i) => {
                      if (ii.ident == item.ident && !this.state.planSelected) {
                        this.setState(state => ({
                          ...state,
                          planSelected: ii,
                        }))
                      }
                    });
                  }

                  return <option key={`${item.ident}_${index}`} selected={index === 0} value={item.ident}>{item.ident}</option>
                })}
              </select>
              <label htmlFor="product">Selecione um produto</label>
            </div>
            </div>
            {this.state.planSelected &&
              <>
                <div className={'container-value'}>
                  <h2>{this.state.planSelected.ident}</h2>
                  <h1>
                    {
                      currencyUtil.format({
                        value: this.state.planSelected.price,
                      })
                    }

                  </h1>
                </div>
                <div className={'container-tb'}>
                  <table>
                    <tbody>
                      <tr>
                        <td>ANÚNCIOS CONTRATADOS</td>
                        <td>{this.state.planSelected.numberOfAds}</td>
                      </tr>
                      <tr>
                        <td>ANÚNCIOS UTILIZADOS</td>
                        <td>{this.state.planSelected.adsUsed}</td>
                      </tr>
                      <tr>
                        <td>PERÍODO CONTRATADO</td>
                        <td>{this.state.planSelected.contractedPeriod}</td>
                      </tr>
                      <tr>
                        <td>DIAS RESTANTES</td>
                        <td>{this.state.planSelected.daysRemaining}</td>
                      </tr>
                    </tbody>
                  </table>
                  <span>O produto vence em: {this.state.planSelected.contractEnd}</span>
                </div>
                {/* <div className={'container-plan-selected'}>
                  <div>{this.state.planSelected.ident}</div>
                  <div>{this.state.planSelected.contractEnd}</div>
                  <div>{this.state.planSelected.daysRemaining}</div>
                  <div>{this.state.planSelected.numberOfAds}</div>
                  <div>{this.state.planSelected.adsUsed}</div>
                  <div>{this.state.planSelected.contractedPeriod}</div>
                  <div>{this.state.planSelected.price}</div>
                </div> */}
              </>
            }

          </>
        }


        {/* {this.state.loaded && plans.map((item, index) => {
          //eslint-disable-next-line react/jsx-key
          return <PlansExpose
            mode={MODES.CANCELLATION}
            renovation={plans.length > 0 && item.contractRenewal ? 'Automática' : 'Sem renovação'}
            dueDate={plans.length > 0 && item.contractEnd ? new Date(item.contractEnd) : new Date('12/12/2020')}
          />
        })} */}
        {/* 
        <Payment />
        <PlansExpose 
          mode={ MODES.ACQUISITION }
          configs={ acquisitionExposeConfigs }
        /> 
        */}
        {this.state.products.length === 0 &&
          <NoResults />
        }
      </Wrapper>
    )
  }
}

Plan.propTypes = {
  history: propTypes.object,
  user: propTypes.object,
};

Plan.defaultProps = {};

const Button = styled(BaseButton).attrs({
  type: 'submit',
})`
  background-color: #8ACC2C;
  color: ${COLOR_2};
  height: 50px;
  padding: 0 10px;
  width: 250px;
  margin-top: 5px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  border: none;
  float: right;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1000px;
  align-self: center;
`;

export default Plan;
