/* eslint-disable no-undef */
/* eslint-disable react/prop-types */

import React, { useLayoutEffect, useState, useEffect } from 'react';
import { Router, Route, Switch, Link } from 'react-router-dom';
import propTypes from 'prop-types';
import { transitions } from 'polished';
import styled, {css} from 'styled-components';
import { Container, Col, Row } from 'react-bootstrap';

import { COLOR_1, COLOR_2, COLOR_20 } from '../../styled/variables/variables';
import { BaseLabel } from '../../styled/labels/labels';
import { mqTablet, mqDesktop, backgrounder, pointer } from '../../styled/helpers/helpers';
import './config-account.css';

import Tabs from '../../components/tabs';
// import Leads from './Leads';
import { Profile } from './profile';
import { Ad } from './ad';
import { AdIntegrationLogs } from './ad-integration-logs';
import { ExternalIntegration } from './external-integration';
import AdLog from './ad-log';
import { Plan } from './plan';
import Payment from './Payment';

import userService from '../../services/user/user.service';
import pathsConstants from '../../constants/paths';
import queryStringUtil from '../../utils/query-string/query-string.util';
import {
  RiArticleFill,
  RiFileDownloadFill,
  RiGitPullRequestFill,
  RiArrowLeftSLine,
  RiArrowRightSLine,
  RiUserFill,
  RiWalletFill
 } from 'react-icons/ri';

const { PUBLIC_URL } = process.env;
const imobiliaria = "1";
const incorporadora = "2";
const owner = 'owner';
const broker = 'broker';

let tabConfigs = [

  /* {
    label: 'Meus Leads',
    path: pathsConstants.CONFIG_ACCOUNT_LEADS,
  }, */
  {
    label: 'Integração de Anúncios',
    path: pathsConstants.CONFIG_ACCOUNT_AD,
    Icon: <RiFileDownloadFill size={32} />,
  },
  {
    label: 'Logs da Integração',
    path: pathsConstants.CONFIG_ACCOUNT_AD_INTEGRATION_LOGS,
    Icon: <RiFileDownloadFill size={32} />,
  },
  {
    label: 'Meu Plano',
    path: pathsConstants.CONFIG_ACCOUNT_PLAN,
    Icon: <RiWalletFill size={32} />,
  },
  {
    label: 'Meu Perfil',
    path: pathsConstants.CONFIG_ACCOUNT_PROFILE,
    Icon: <RiUserFill size={32} />,
  },
  //{
  // label: 'Pagamento',
  // path: pathsConstants.CONFIG_ACCOUNT_PAYMENT,
  //},
];

const ConfigAccount = ({
  history
}) => {

  const [token, setToken] = useState();
  const [user, setUser] = useState();

  const [menuIsOpen, setMenuIsOpen] = useState(() => {
    const screenWidth = window.screen.width;

    if (screenWidth <= 992) {
      return false;
    }

    return true;
  });

  const getToken = async () => {
    let user;
    user = await userService.retrieve();

    if (user !== undefined) {
      setToken(user.token);
      setUser(user);
    } else {
      setToken(null)
      setUser(null);
    }
  }

  useEffect(() => {
    getToken();
  }, []);

  useLayoutEffect(() => {
    const user = userService.retrieve();

    if (user.userType === 2) {
      tabConfigs = tabConfigs.filter(item => {
        if (item.label === "Meu Perfil" || item.label === "Meu Plano") {
          return item;
        }
      });

      return;
    }

    if (userService.retrieve().isLinked == true) {
      tabConfigs = [
        {
          label: 'Meu Perfil',
          path: pathsConstants.CONFIG_ACCOUNT_PROFILE,
          Icon: <RiUserFill size={32} />,
        },
      ]
    } else {
      tabConfigs = [
        {
          label: 'Integração de Anúncios',
          path: pathsConstants.CONFIG_ACCOUNT_AD,
          Icon: <RiFileDownloadFill size={32} />,
        },
        {
          label: 'Logs da Integração',
          path: pathsConstants.CONFIG_ACCOUNT_AD_INTEGRATION_LOGS,
          Icon: <RiArticleFill size={32} />,
        },
        {
          label: 'Meu Plano',
          path: pathsConstants.CONFIG_ACCOUNT_PLAN,
          Icon: <RiWalletFill size={32} />,
        },
        {
          label: 'Meu Perfil',
          path: pathsConstants.CONFIG_ACCOUNT_PROFILE,
          Icon: <RiUserFill size={32} />,
        },
      ];
    }

    if (userService.retrieve().userType >= 3 && userService.retrieve().isLinked == false) {
      tabConfigs.splice(2, 0, {
        label: 'Integração com Terceiros',
        path: pathsConstants.CONFIG_ACCOUNT_EXTERNAL_INTEGRATION,
        Icon: <RiGitPullRequestFill size={32} />
      });
    }
  }, []);

  function handleMinimizeMenu() {
    setMenuIsOpen(state => !state);
  }

  function handleNavigate() {
    const screenWidth = window.screen.width;

    if (screenWidth <= 992) {
      setMenuIsOpen(false);
    }
  }

  return (
    <>
      <Wrapper>
        <Container>
          <NameWrapper>
            <Name>Configuração da conta</Name>
            {/*<CRatingStars rating={ rating } />*/}
          </NameWrapper>
        </Container>
      </Wrapper>
      <Wrapper2>
        {!window.location.href.includes('ad-log') &&
          <RouterContainer>
            <RouterNav minimize={!menuIsOpen}>
              <RouterNavList>
                <button type="button" onClick={handleMinimizeMenu}>
                  {menuIsOpen ? <RiArrowLeftSLine size={20} /> : <RiArrowRightSLine size={20}/> }
                </button>

                {tabConfigs.map(({label, path, Icon}) => (
                  <RouterNavListItem key={label} isActive={window.location.pathname === path} isMinimized={!menuIsOpen} onClick={handleNavigate}>
                    <Link to={path}>
                      {Icon}
                      {menuIsOpen && label}
                    </Link>
                  </RouterNavListItem>
                ))}
              </RouterNavList>
            </RouterNav>

            {/* <div className={"nav_ul"}>
              <Tabs configs={tabConfigs} />
            </div> */}
            {/* <Tabs configs={ tabConfigs.filter(tc => {
              switch (tc.path) {
                case pathsConstants.PROFILE_SALE:
                  return realEstatesForSale.length > 0;
                case pathsConstants.PROFILE_RENTAL:
                  return realEstatesForRental.length > 0;
                case pathsConstants.PROFILE_DEALS:
                  return realEstateDeals.length > 0;
                default: return true;
              }
            }) } /> */}

            <Router history={history}>
              <Switch>
                <Route
                  path={pathsConstants.CONFIG_ACCOUNT_PROFILE}
                  render={props => (
                    <Profile
                      {...props}
                      key={pathsConstants.CONFIG_ACCOUNT_PROFILE}
                      user={user}
                    />
                  )}
                />
                {/* <Route
                  path={pathsConstants.CONFIG_ACCOUNT_LEADS}
                  render={props => (
                    <Leads
                      {...props}
                      key={pathsConstants.CONFIG_ACCOUNT_LEADS}
                      user={user}
                    />
                  )}
                /> */}
                <Route
                  path={pathsConstants.CONFIG_ACCOUNT_AD}
                  render={props => (
                    <Ad
                      {...props}
                      key={pathsConstants.CONFIG_ACCOUNT_AD}
                      user={user}
                    />
                  )}
                />
                <Route
                  path={pathsConstants.CONFIG_ACCOUNT_AD_INTEGRATION_LOGS}
                  render={props => (
                    <AdIntegrationLogs
                      {...props}
                      key={pathsConstants.CONFIG_ACCOUNT_AD_INTEGRATION_LOGS}
                      user={user}
                    />
                  )}
                />
                <Route
                  path={pathsConstants.CONFIG_ACCOUNT_EXTERNAL_INTEGRATION}
                  render={props => (
                    <ExternalIntegration
                      {...props}
                      key={pathsConstants.CONFIG_ACCOUNT_EXTERNAL_INTEGRATION}
                      user={user}
                    />
                  )}
                />
                <Route
                  path={pathsConstants.CONFIG_ACCOUNT_PLAN}
                  render={props => (
                    <Plan
                      {...props}
                      key={pathsConstants.CONFIG_ACCOUNT_PLAN}
                      user={user}
                    />
                  )}
                />
                <Route
                  path={pathsConstants.CONFIG_ACCOUNT_PAYMENT}
                  render={props => (
                    <Payment
                      {...props}
                      key={pathsConstants.CONFIG_ACCOUNT_PAYMENT}
                      user={user}
                    />
                  )}
                />
              </Switch>
            </Router>
            {/* <Leads /> */}
          </RouterContainer>
        }
      </Wrapper2>
    </>
  );
}

const RouterContainer = styled(Container)`
  display: flex;
  position: relative;

  min-height: 80vh;

  @media (max-width: 992px) {
    padding-left: 94px;
    padding-top: 8px;
  }
`;

const RouterNav = styled.nav`
  margin-top: 72px;
  border-right: 1px solid #aeaeae;
  width: 300px;
  margin-right: 32px;

  ${({minimize}) => minimize && css`
    width: 72px;
  `}

  transition: width 0.2s;

  @media (max-width: 1200px) {
    margin-top: 40px;
  }

  @media (max-width: 874px) {
    margin-top: 32px;
  }

  @media (max-width: 992px) {
    position: absolute;
    left: 16px;
    top: -40px;
    bottom: 0;
    margin-right: unset;
    z-index: 99;
    padding-top: 32px;


    ${({minimize}) => !minimize && css`
    background-color: #ffffff;
  `}

    ${({minimize}) => minimize && css`
    width: 60px;
  `}

    ${({minimize}) => !minimize && css`
    border-right: unset;
    box-shadow: 10px 0px 5px rgba(0, 0, 0, 0.1);
  `}
  }

  @media (max-width: 874px) {
    top: -31px;
  }
`;

const RouterNavList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  position: sticky;
  top: 140px;

  > button {
    background-color: #eaeaea;
    position: absolute;
    right: -16px;
    top: 40%;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    font-size: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 1px 1px 16px 0px rgba(0, 0, 0, 0.1);

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }
  }
`;

const RouterNavListItem = styled.li`
  > a {
    font-size: 16px;
    font-weight: 600;
    color: #363636;
    width: 280px;

    text-decoration: none;
    display: inline-flex;
    align-items: center;

    > svg {
      margin-right: 12px;
    }

    ${({isActive}) => isActive && css`
      color: ${COLOR_20};
    `}
  }

  & + li {
      margin-top: 16px;
    }

  ${({isMinimized}) => isMinimized && css`

    a {
      width: fit-content;

      > svg {
        margin-right: unset;
      }
    }
  `}
`;

const Wrapper = styled.div`
  background-color: ${COLOR_1};
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  padding-top: 120px;

  * { position: relative; }
  &:before {
    content: "";
    ${backgrounder(`${PUBLIC_URL}/imgs/koort-texture-outline.svg`)};
    background-size: 800px;
    height: 100%;
    width: 100%;
    opacity: .3;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
  }

  ${mqTablet`
    &:before {
      background-size: 600px;
      background-repeat: repeat;
    }
  ` }
`;


const Wrapper2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items:center;
  align-self:center;
  background-color:white;
  /* height: 700px; */
  width: 100%;
  ${mqTablet`
    padding-bottom: 20px
  ` };
    ${mqDesktop`
    width: 100%;
  ` };
`;

const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-bottom: 10px;
  width: 315px;
  margin-top: 50px;
  ${mqTablet`
    flex-grow: 1;
    width: 315px;
    order: 2;
    align-self: flex-start;
    margin-top: 50px;
  ` }
`;

const Name = styled(BaseLabel)`
  color: ${COLOR_2};
  align-self: center;
  font-weight: 700;
  margin-bottom: 10px;
  cursor: pointer;

  ${mqTablet`
    align-self: flex-start;
    font-size: 24px;
  ` }
`;

const Texture = styled.div`
  ${backgrounder(`${PUBLIC_URL}/imgs/koort-texture-white-outline.svg`)};
  background-position: bottom;
  height: 140px;
  width: 100%;
  background-repeat: repeat-x;
  background-size: 620px;
  margin-bottom: 30px;

  ${mqDesktop`
    background-position: right;
    width: 300px;
    height: 100vh;
    background-size: 1000px;
    margin: 0;
  ` }
`;

const WrapperSquares = styled.div`
  display: flex;
  flex-direction: column;
  ${mqTablet`
    flex-direction: row;
  ` };
`;

const WrapperTexture = styled.div`
  display: flex;
  flex-direction: column;
  width:100%;
  padding-top:20px;
  ${mqTablet`
    flex-direction: row;
  ` };
`;

const WrapperCards = styled.div`
  ${backgrounder(`${PUBLIC_URL}/imgs/koort-texture-white.svg`)};
  background-position: bottom;
  background-position-y: 190px;
  height: 280px;
  background-size: 620px;
  margin-bottom: 30px;
  background-color: ${COLOR_1};

  width: 200px;
  margin: 8px 8px 24px;
  min-height: 200px;
  min-width: 200px;
  padding-top:15px;

  cursor: pointer;

  ${mqDesktop`
    ${transitions(['transform'], 'ease .3s')}
    &:hover {
      transform: scale(1.1);
    }
  `}
`;

// const Squares = styled.div`
//   background-color: #FEFEFE;
//   height: 200px;
//   width: 200px;
//   margin: 5px;
//   word-wrap:break-word;
// `;

const SubtitleWrapper = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  margin-top: 20px;
`;

const Title = styled.h4`
  text-align: center;
  padding-top: 15px;
  font-weight: 700;
  font-size: 26px;
  color: ${COLOR_2}
`;

const Subtitle = styled.p`
  width: 180px;
  text-align: left;
  align-self: center;
  color: ${COLOR_2}
`;

export default ConfigAccount;

