import styled, {css} from "styled-components";

import { COLOR_13, COLOR_7 } from "../../../../styled/variables/variables";

export const GeneralStatisticsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 40px;
  margin-top: 32px;
  flex-wrap: wrap;
`;

export const StatisticsCard = styled.div`
  flex: 1;
  padding: 24px;
  background-color: #F6F6F6;
  border-radius: 8px;

  > h3 {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 15px;
    color: #7C889A;

    > svg {
      margin-right: 12px;
    }
  }

  > strong {
    display: flex;
    align-items: center;

    margin: 12px 0;
    font-size: 48px;

    > span {
      background-color: #eaeaea;
      color: #000000;
      padding: 3px 6px;
      font-size: 12px;
      font-weight: 400;
      border-radius: 18px;

      display: flex;
      align-items: center;
      margin-left: 12px;

      ${(props) => props.type === 'up' && css`
        background-color: ${COLOR_13};
        color: #000000;
      `}

      ${(props) => props.type === 'down' && css`
        background-color: ${COLOR_7};
        color: #FFFFFF;
      `}
    }
  }

  > span {
    font-size: 14px;
    color: #7C889A;

      b {
        font-size: 16px;
        color: #5E5E5E;
      }
  }
`;
