import React from 'react';

import { FakeTextareaWrapper, TextArea } from './styles';

export function FakeTextarea({ label, value }) {
  return (
    <FakeTextareaWrapper>
      <label>{label}</label>

      <TextArea
        id="fake-textarea"
        placeholder="A descrição estará aqui..."
        disabled
        value={value}
      />
    </FakeTextareaWrapper>
  );
}
