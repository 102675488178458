import React, { useEffect, useState } from 'react'
import { MarkerContainer, ClusterContainer, Tooltip, ImageSlider, Dot }  from './styles'
import { PiCaretRightBold, PiCaretLeftBold } from "react-icons/pi";
import listingV2Service from '../../../../../../services/listing/listing-v2.service'
import { RealEstateCard } from '../../../results/realEstateCard'
import RealEstate from '../../../../../../models/real-estate/real-estate.model'
import { useSelector } from 'react-redux';
import { calculateProportionalDistanceFromCenter } from '../../utils';

export function Marker({marker, isMouseOverTooltip}) {
  const [realEstates, setRealEstates] = useState(null)
  const [realEstateIndex, setRealEstateIndex] = useState(0)
  const [distanceToCenter, setDistanceToCenter] = useState({ verticalDistance: 0, horizontalDistance: 0 })

  const { rect } = useSelector(state => state.filter);

  const getRealEstates = async () => {
    if (marker.cluster.length === 1) {
      const realEstateData = await listingV2Service.getById({ listingId: marker.cluster[0]._id})
      setRealEstates([realEstateData])
      return
    }
    const ids = marker.cluster.map(realEstate => realEstate._id)
    const realEstates = await getRealEstatesByIds(ids)

    const formatedRealEstates = realEstates.map(item => new RealEstate({
      ...item,
      images: item.images.map(imageURL => ({ url: imageURL })),
      price: item.prices
    }))
    setRealEstates(formatedRealEstates)
  }

  const getRealEstatesByIds = async (ids) => {
    try {
      const realEstates = await listingV2Service.getRealEstatesByIds({ ids })
      return realEstates.data.items
    } catch (err) {
      console.error("Não foi possível obter os imóveis por ID.", err)
    }
    finally {
      setRealEstateIndex(0)
    }
  }

  const handleNextRealEstate = () => {
    if (realEstateIndex === realEstates.length - 1) {
      setRealEstateIndex(0)
    } else {
      setRealEstateIndex(state => state + 1)
    }
  }

  const handlePreviousRealEstate = () => {
    if (realEstateIndex === 0) {
      setRealEstateIndex(realEstates.length - 1)
    } else {
      setRealEstateIndex(state => state - 1)
    }
  }

  useEffect(() => {
    if (!!marker && marker.showingTooltip) {
      getRealEstates()

      const horizontalDistance = calculateProportionalDistanceFromCenter({ 
        borderStart: rect[0][1], 
        borderLimit: rect[1][1], 
        point: marker.coordinates[0] 
      })

      const verticalDistance = calculateProportionalDistanceFromCenter({
        borderStart: rect[0][0],
        borderLimit: rect[1][0], 
        point: marker.coordinates[1]
      })

      console.log({ verticalDistance, horizontalDistance })
      setDistanceToCenter({ verticalDistance, horizontalDistance })
    }

    if (!marker.showingTooltip) {
      setRealEstates(null)
    }   

  }, [marker.showingTooltip])

  return (
    <>
      {
        marker.cluster.length === 1 
        ?
        <MarkerContainer
          isLaunch={marker.cluster[0].isLaunch}
          className={marker.showingTooltip ? "clicked" : ""}
        >
          { marker.showingTooltip &&
            <Tooltip 
              distanceToCenter={distanceToCenter}
              onMouseEnter={() => isMouseOverTooltip(true)} 
              onMouseLeave={() => isMouseOverTooltip(false)}
            >
              {
                realEstates 
                ?
                <RealEstateCard realEstate={realEstates[0]} />
                :
                <div className='loading'>Carregando informações do imóvel...</div>
              }
            </Tooltip>
          }
        </MarkerContainer>
        :
        <ClusterContainer className={marker.showingTooltip ? "clicked" : ""} length={marker.cluster.length}>
          <p>{marker.cluster.length < 50 ? marker.cluster.length : "50+"}</p>
          { marker.showingTooltip &&
            <Tooltip 
              isCluster 
              distanceToCenter={distanceToCenter}
              onMouseEnter={() => isMouseOverTooltip(true)} 
              onMouseLeave={() => isMouseOverTooltip(false)}
            >
              {
                realEstates 
                ?
                <RealEstateCard key={realEstates[realEstateIndex].id} realEstate={realEstates[realEstateIndex]} />
                :
                <div className='loading'>Carregando informações do imóvel...</div>
              }
              <ImageSlider>
                <span onClick={handlePreviousRealEstate}>
                  <PiCaretLeftBold size={18} />
                </span>
                <div className='dots'>
                  { marker.cluster.map((_, index) => {
                    return <Dot key={index} isSelected={index === realEstateIndex} className='dot'/>
                  }
                  )}
                </div>
                <span onClick={handleNextRealEstate}>
                  <PiCaretRightBold size={18} />
                </span>
              </ImageSlider>
            </Tooltip>
          }
        </ClusterContainer>
      }
    </>
    
    
  )
}