import React from 'react';
import propTypes from 'prop-types';

import { Wrapper, Icon, Label } from './styles';

const NoResults = ({
  className,
  label,
}) => (
  <Wrapper className={className}>
    <Icon />
    <Label>
      {label}
    </Label>
  </Wrapper>
);

NoResults.propTypes = {
  className: propTypes.string,
  label: propTypes.string,
};

NoResults.defaultProps = {
  label: 'Nenhum resultado.'
};



export default NoResults;
