import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';

import { COLOR_17, COLOR_1, COLOR_2, COLOR_13 } from '../../../styled/variables/variables';
import { BaseLabel } from '../../../styled/labels/labels';
import { BaseButton } from '../../../styled/buttons/buttons';
import { mqTablet } from '../../../styled/helpers/helpers';

import Koortimativa from '../../../components/koortimativa/Koortimativa';

import currencyUtil from '../../../utils/currency/currency.util';



const Prices = ({
  condo,
  iptu,
  koortimativa,
  rental,
  sale,
  transaction,
}) => (
  <Wrapper>
    <ContentWrapper>
      <TabletCenterWrapper>
        <MainPriceWrapper>
          <MainPriceIndicator>
            valor de { transaction === 'Venda' ? 'venda' : 'aluguel' }
          </MainPriceIndicator>
          <Price>
            { currencyUtil.format({
              value: transaction === 'Venda' ? sale : rental,
            }) }
          </Price>
        </MainPriceWrapper>
        <PKoortimativa 
          { ...koortimativa }
        />
      </TabletCenterWrapper>

      <CondoWrapper>
        <FeeIndicator>
          condomínio
        </FeeIndicator>
        <Price>
          { currencyUtil.format({
            value: condo,
          }) }
        </Price>
      </CondoWrapper>
      <IPTUWrapper>
        <FeeIndicator>
          iptu
        </FeeIndicator>
        <Price>
          { currencyUtil.format({
            value: iptu,
          }) }
        </Price>
      </IPTUWrapper>
    </ContentWrapper>
    <ContactAgentButton>
      fale com o corretor
    </ContactAgentButton>
  </Wrapper>
);

Prices.propTypes = {
  condo: propTypes.number,
  iptu: propTypes.number,
  koortimativa: propTypes.object,
  rental: propTypes.number,
  sale: propTypes.number,
  transaction: propTypes.string,
};

Prices.defaultProps = {
  
};

const Wrapper = styled.div`
  padding: 10px;
  padding-top: 140px;
  padding-bottom: 50px;
  background-color: ${ COLOR_17 };
  display: flex;
  flex-direction: column;
  margin-top: -90px;
  margin-bottom: 50px;
`;

const ContentWrapper = styled.div`
  margin-bottom: 40px;

  ${ mqTablet`
    display: flex;
    width: 100%;
    max-width: 1000px;
    align-self: center;
  ` }
`;

const TabletCenterWrapper = styled.div`
  margin-bottom: 20px;

  ${ mqTablet`
    order: 2;
    align-self: center;
    margin-right: 30px;
    flex-grow: 1;
    width: 1px;
    margin-bottom: 0;
  ` }
`;

const MainPriceWrapper = styled.div`
  border-radius: 1px;
  border: solid 3px ${ COLOR_1 };
  display: flex;
  flex-direction: column;
  padding: 30px 0;

  ${ mqTablet`
    padding: 40px;
  ` }
`;

const MainPriceIndicator = styled(BaseLabel)`
  text-transform: uppercase;
  color: ${ COLOR_2 };
  align-self: center;
  opacity: 0.8;
`;

const Price = styled(MainPriceIndicator)`
  font-weight: 700;
  margin: 0;
  opacity: 1;
  font-size: 22px;
`;

const PKoortimativa = styled(Koortimativa)`
  margin: 0;
`;

const CondoWrapper = styled(MainPriceWrapper)`
  margin-bottom: 20px;

  ${ mqTablet`
    order: 1;
    align-self: center;
    margin: 0;
    margin-right: 30px;
  ` }
`;

const FeeIndicator = styled(MainPriceIndicator)`
  font-size: 14px;
`;

const IPTUWrapper = styled(CondoWrapper)`
  ${ mqTablet`
    order: 3;
    margin: 0;
  ` }
`;

const ContactAgentButton = styled(BaseButton)`
  background-color: ${ COLOR_13 };
  align-self: center;

  ${ mqTablet`
    order: 4;
  ` }
`;

export default Prices;
