
import React from 'react';
import styled, { css } from 'styled-components';
import propTypes from 'prop-types';

import { COLOR_17, COLOR_1, COLOR_2 } from '../../../styled/variables/variables';
import { BaseLabel } from '../../../styled/labels/labels';
import { transitions } from 'polished';
import { mqTablet } from '../../../styled/helpers/helpers';

export const TYPES = {
  CREATION: 'CREATION',
  PLAN: 'PLAN',
  PAYMENT: 'PAYMENT',
  CONGRATULATIONS: 'CONGRATULATIONS',
};

const Steps = ({
  activeType,
  alternativeSteps,
}) => (
  <Wrapper>
    <Line />
    <IndicatorsWrapper>
      { alternativeSteps ? 
         alternativeSteps.map(({ type, label }, index) => (
        <Indicator 
          key={ type }
          isActive={ activeType === type }
        >
          <Number>
            { index + 1 }
          </Number>
          <Tooltip show={ activeType === type }>
            <TooltipArrow />
            { label }
          </Tooltip>
        </Indicator>
      )) :
      list.map(({ type, label }, index) => (
        <Indicator 
          key={ type }
          isActive={ activeType === type }
        >
          <Number>
            { index + 1 }
          </Number>
          <Tooltip show={ activeType === type }>
            <TooltipArrow />
            { label }
          </Tooltip>
        </Indicator>
      )) }
    </IndicatorsWrapper>
  </Wrapper>
);

const Wrapper = styled.div`
  padding-top: 25px;
  margin-bottom: 30px;

  ${ mqTablet`
    padding: 0 10px;
    max-width: 1200px;
    align-self: center;
    width: 100%;
    margin-bottom: 80px;
  ` }
`;

const Line = styled.div`
  height: 1px;
  background-color: ${ COLOR_17 };
  width: 100%;
`;

const IndicatorsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: -25px;

  ${ mqTablet`
    justify-content: space-between;
  ` }
`;

const Indicator = styled.div`
  height: 50px;
  width: 50px;
  border-radius: 1px;
  background-color: ${ COLOR_17 };
  margin-bottom: 30px;

  ${ transitions([ 'background-color' ], 'ease .6s') }
  ${ ({ isActive }) => isActive && css`
    background-color: ${ COLOR_1 };
  ` }
`;

const Number = styled(BaseLabel)`
  font-weight: 700;
  color: ${ COLOR_2 };
  line-height: 50px;
  width: 100%;
  text-align: center;
  font-size: 20px;
  align-self: center;
`;

const Tooltip = styled.div`
  padding: 7px;
  font-size: 10px;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  width: 70px;
  margin-left: -10px;
  text-align: center;
  color: ${ COLOR_2 };
  border-radius: 1px;

  background-color: ${ COLOR_17 };
  opacity: 0;

  ${ transitions([ 'background-color', 'opacity' ], 'ease .6s') }
  ${ ({ show }) => show && css`
    background-color: ${ COLOR_1 };
    opacity: 1;
  ` }
`;

const TooltipArrow = styled.div`
  height: 5px;
  width: 5px;
  background-color: ${ COLOR_1 };
  transform: rotate(45deg);
  align-self: center;
  margin-top: -9px;
  margin-bottom: 3px;
`;

const list = [
  {
    type: TYPES.CREATION,
    label: 'Criação',
  },
  {
    type: TYPES.PLAN,
    label: 'Planos',
  },
  {
    type: TYPES.PAYMENT,
    label: 'Finalização do Anúncio',
  },
  {
    type: TYPES.CONGRATULATIONS,
    label: 'Parabéns',
  },
];

export default Steps;
