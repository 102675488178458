import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  > .form-floating .form-control {
    border-right-width: 0;
  }
`;

export const ShowPasswordButtonWrapper = styled.div`
  background: #FFFFFF;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  border: 1px solid #83786f;
  border-left-width: 0;
  padding-left: 16px;

  button {
  color: #83786f;
  background-color: transparent;

  transition: filter .2s;

  &:hover {
    filter: brightness(0.9);
  }
}
`;
