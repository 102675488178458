import styled, { css } from 'styled-components';
import { Card as CardBootstrap } from 'react-bootstrap';
import {
  COLOR_2,
  COLOR_3,
  COLOR_6,
  COLOR_7,
  COLOR_1,
} from '../../../styled/variables/variables';

export const Container = styled.div`
  margin: 5% 0;
  max-width: 1000px;
`;

export const EmptyLogs = styled.div`
  margin: 5% 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > span {
    margin-top: 12px;
  }
`;

export const Card = styled(CardBootstrap)`
  & + div {
    margin-top: 12px;
  }

  .card-header {
    background-color: #F6F6F6;
    cursor: pointer;

    transition: filter .2s;

    &:hover {
      filter: brightness(0.9);
    }
  }
`;

export const AccordionHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  @media (max-width: 760px) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
`;


export const AccordionHeaderList = styled.ul`
  list-style: none;
  padding-inline-start: unset;
  margin-bottom: unset;

  flex: 1;

  @media (max-width: 760px) {
    margin-top: 16px;
  }
`;

export const AccordionHeaderTagSituation = styled.span`
  width: 117px;
  height: 26px;
  background-color: ${COLOR_3};

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  font-weight: 500;
  color: ${COLOR_2};

  ${({ hasIntegrated }) => hasIntegrated && css`
  background-color: ${COLOR_1};
  `}

  ${({ hasNotIntegrated }) => hasNotIntegrated && css`
  background-color: #dc3545;
  `}

  ${({ hasPartiallyIntegrated }) => hasPartiallyIntegrated && css`
  background-color: #1EBEA5;
  `}
`;

export const AccordionHeaderListItem = styled.li`
  font-size: 16px;

  & + li {
    margin-top: 8px;
  }

  > span {
    font-weight: bold;
  }

  ${({ isTag }) => isTag && css`
      text-transform: capitalize;
  `}
`;

export const AccordionBodyLogContainer = styled.div`
  & + div {
    margin-top: 20px;
  }
`;

export const AccordionBodyTagOfLog = styled.span`
  display: block;
  padding: 4px 4px 4px 12px;
  border-radius: 4px 4px 0 0;
  font-size: 13px;
  font-weight: 500;
  color: ${COLOR_2};

  ${({ isWarning }) => isWarning && css`
    background-color: ${COLOR_6};
    `}

  ${({ isError }) => isError && css`
    background-color: ${COLOR_7};
  `}

  ${({ isSuccess }) => isSuccess && css`
    background-color: #008000;
  `}
`;

export const AccordionBodyLogDescription = styled.p`
  margin-bottom: 0;
  border-radius: 0 0 4px 4px;
  padding: 12px;

  ${({ isWarning }) => isWarning && css`
    background-color: rgba(246, 134, 31, 0.5);
    `}

  ${({ isError }) => isError && css`
    background-color: rgba(220, 53, 69, 0.5);
  `}

  ${({ isSuccess }) => isSuccess && css`
    background-color: rgba(0, 128, 0, 0.5);
  `}
`;

export const NextPageButtonContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
`;

export const NextPageButton = styled.button`
  background-color: #2B50D8;
  color: #FFFFFF;
  padding: 8px 16px;
  font-weight: 500;

  transition: filter .2s;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const FilterForm = styled.form``;

export const FilterOptionsContainer = styled.div`
  display: flex;
  margin-top: 16px;
  margin-bottom: 24px;
  flex-wrap: wrap;
`;

export const FilterSelectsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  > div.form-floating {
     width: 200px;
   }

   > button {
     width: 200px;
     height: 58px;

    background-color: #2B50D8;
    color: #FFFFFF;
    padding: 8px 16px;
    font-weight: 500;

    transition: filter .2s;

    ${({ filterButtonDisabled }) => filterButtonDisabled && css`
     filter: grayscale(0.8);
      cursor: not-allowed;
    `}

    ${({ filterButtonDisabled }) => !filterButtonDisabled && css`
      &:hover {
        filter: brightness(0.9);
      }
    `}
  }

  @media (max-width: 544px) {
    > div.form-floating, button {
      width: 100%;
    }
  }
`;
