import React from 'react';
import { RiWhatsappLine, RiMailLine, RiPhoneLine } from 'react-icons/ri';

import pathsConstants from '../../../constants/paths';

import {
  Container,
  ProfessionalTypeBadge,
  ImageContent,
  InfoContent,
  RealtorNameContent,
  ContactContent,
  WhatsAppContent,
  EmailContent,
  TelephoneContent,
} from './styles';

const USER_TYPES = [
  { id: 3, name: 'Corretor(a)' },
  { id: 4, name: 'Imobiliária' },
  { id: 5, name: 'Incorporadora' },
];

export function RealtorCard(props) {
  const { _id: id, profile, numberOfAds, email, userType } = props;

  const professionalType = USER_TYPES.find(item => item.id === userType);

  return (
    <Container>
      <ProfessionalTypeBadge>{professionalType.name}</ProfessionalTypeBadge>

      <ImageContent>
        <img src={profile.image} />
      </ImageContent>

      <InfoContent>
        <RealtorNameContent>
          <strong title={profile.screenName}>{profile.screenName}</strong>

          <span>{numberOfAds} {numberOfAds === 1 ? 'imóvel' : 'imóveis'}</span>
        </RealtorNameContent>

        <a
          target="_blank"
          rel="noreferrer noopener"
          href={`${pathsConstants.PROFILE_SALE}?id=${id}`}
        >
          Ver perfil
        </a>
      </InfoContent>

      <ContactContent>
        {profile.professionalInformation.cellphone.number &&
          profile.professionalInformation.cellphone.whatsApp &&
          <WhatsAppContent
            target="_blank"
            rel="noreferrer noopener"
            href={`https://wa.me/+55${profile.professionalInformation.cellphone.number}`}
          >
            <RiWhatsappLine size={24} />
            {profile.professionalInformation.cellphoneFormatted}
          </WhatsAppContent>
        }

        {profile.professionalInformation.cellphone.number &&
          !profile.professionalInformation.cellphone.whatsApp &&
          <WhatsAppContent
            href={`tel:${profile.professionalInformation.cellphone.number}`}
          >
            <RiPhoneLine size={24} />
            {profile.professionalInformation.cellphoneFormatted}
          </WhatsAppContent>
        }

        {profile.professionalInformation.telephone.number &&
          !profile.professionalInformation.cellphone.number &&
          <TelephoneContent
            href={`tel:${profile.professionalInformation.telephone.number}`}
          >
            <RiPhoneLine size={24} />
            {profile.professionalInformation.telephoneFormatted}
          </TelephoneContent>
        }

        <EmailContent
          title={email}
          href={`mailto:${email}`}
        >
          <RiMailLine size={24} />
          {email}
        </EmailContent>
      </ContactContent>
    </Container>
  );
}
