
import Base from "../base/base.model";

class Koortimativa extends Base {
  constructor({
    id,

    value,
    rangeStart,
    rangeEnd,   
  }) {
    super({ id });

    this.value = value;
    this.rangeStart = rangeStart;
    this.rangeEnd = rangeEnd;
  }
}

export default Koortimativa;
