import styled from "styled-components";

export const PageErrorWrapper = styled.div`
   height: 100vh;

   display: flex;
   align-items: center;
   justify-content: center;

   > div {
    max-width: 600px;
    padding: 0 24px;

    display: flex;
    flex-direction: column;
    align-items: center;

    > p {
      margin-top: 32px;

      font-size: 24px;
      font-weight: 500;
      text-align: center;
    }

    > button {
      margin-top: 32px;
      background-color: #3578E5;
      color: #fff;
      padding: 16px;
      font-size: 18px;
      font-weight: 500;

      transition: filter .2s;

      &:hover {
        filter: brightness(0.9);
      }
    }
   }
`;

export const CompanyBrokerWrapper = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;

  @media(max-width: 1050px) {
    padding: 0 16px;
  }
`;

export const Title = styled.h1`
  margin-top: 48px;
  font-size: 32px;
  font-weight: bold;
`;

export const ChartAndLeadsTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 0;
`;

export const OriginLeadsSelect = styled.div``;
