
import React, { PureComponent } from 'react';
import styled from 'styled-components';

import ImageDropGallery from '../../../../components//image-drop-gallery';

class FloorPlans extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      form: {
        floorPlans: [ ],
      },
    };
  }

  handleImageDropGalleryUpdate = floorPlans => {
    this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        floorPlans,
      },
    }));
  };

  render() {

    return (
      <Wrapper>
        <IImageDropGallery
          onUpdate={ this.handleImageDropGalleryUpdate }
        />
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  margin-bottom: 50px;
`;

const IImageDropGallery = styled(ImageDropGallery)`
  /* margin-bottom: 50px; */
`;

export default FloorPlans;
