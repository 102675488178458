import styled from 'styled-components';
import { Container as ContainerBootstrap } from 'react-bootstrap';

export const Container = styled(ContainerBootstrap)`
  margin: 5% 0;
`;

export const Title = styled.h3`
    font-size: 16px;
    margin-bottom: 10px;
    display: inline-block;
    letter-spacing: 0.5px;
    color: rgb(51, 51, 51);
    font-weight: bold;
    align-self: flex-start;
    margin-top: 5px;
`;

export const StepsList = styled.ol`
  padding-left: 15px;

    li {
    font-size: 16px;

    h6 {
      font-size: unset;
      line-height: unset;

      a {
        color: #2B50D8;
      }
    }
  }
`;

export const UrlIntegrationModalBody = styled.div`
  font-size: 16px;
  color: #FFFFFF;
  background-color: #2B50D8;
  width: 100%;
  max-width: 400px;

  padding: 24px;

  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    text-align: center;
    margin-bottom: 16px;
  }
`;

export const RecurrenceModalButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
`;

export const ConfirmRecurrenceButton = styled.button`
  padding: 8px 24px;
  background-color: #FFFFFF;
  border: 0;

  transition: filter .2s;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const DenyRecurrenceButton = styled.button`
  background-color: transparent;
  border: 0;
  margin-top: 14px;
  color: rgba(255, 255, 255, 0.8);

  transition: filter .2s;

  &:hover {
    filter: brightness(0.9);
  }
`;
