
import React from 'react';
import styled, { css } from 'styled-components';
import propTypes from 'prop-types';

import Slider from './slider/Slider';
import Info from './info/Info';
import { mqTablet } from '../../../../styled/helpers/helpers';
import { transitions } from 'polished';

const Card = ({
  reverse,
  opacify,
  onClick,
  ...ad
}) => (
  <Wrapper
    reverse={reverse}
    opacify={opacify}
    onClick={onClick}
  >
    <Info {...ad} />

    {ad.images[0] ? <Slider url={ad.images[0].url.replace('{width}', '550')} />
      : <Slider url={`${process.env.PUBLIC_URL}/imgs/website-preview.png`} />}
  </Wrapper>
);

Card.propTypes = {
  reverse: propTypes.bool,
  opacify: propTypes.bool,
  onClick: propTypes.func,
};

Card.defaultProps = {};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  opacity: 1;
  ${transitions(['opacity'], 'ease .3s')}
  ${({ opacify }) => opacify && css`
    opacity: 0.5;
  ` }

  ${mqTablet`
    justify-content: center;
    flex-direction: row;
    margin-bottom: 30px;
    max-width: fit-content;
    align-self: center;

    ${({ reverse }) => reverse && css`
      flex-direction: row-reverse;
      & > div:first-child {
        margin-left: -30px;
      }
      & > div:nth-child(2) {
        margin-left: 0;
      }
    ` }
  ` }
`;

export default Card;
