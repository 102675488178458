
import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';
import { Hospital } from 'styled-icons/fa-regular/Hospital';
import { ShoppingBag } from 'styled-icons/boxicons-regular/ShoppingBag';
import { Handshake } from 'styled-icons/fa-regular/Handshake';
import { CartPlus } from 'styled-icons/fa-solid/CartPlus';
import { transitions } from 'polished';
import { DotsHorizontal } from 'styled-icons/boxicons-regular/DotsHorizontal';

import { BaseButton } from '../../../styled/buttons/buttons';
import { COLOR_17, COLOR_1, COLOR_2 } from '../../../styled/variables/variables';
import { iconBaseStyle, hexToRgb, mqTablet } from '../../../styled/helpers/helpers';

import Map from '../../../components/map/Map';


class Surroundings extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      activeMarkerFilter: markerFilters[0].key,
    };
  }

  componentDidMount() {
    this.loadMarkers();
  }

  loadMarkers = () => {
    const { realEstate } = this.props;
    this.smapRef.setMarkers({
      realEstates: [{
        id: realEstate.id,
        latitude: realEstate.address.geolocation.latitude,
        longitude: realEstate.address.geolocation.longitude,
        highlight: true,
      }],
      surroundingPlaces: realEstate.surroundingPlaces.map(sp => {
        const mapped = {
          ...sp,
          latitude: sp.geolocation.latitude,
          longitude: sp.geolocation.longitude,
        };
        delete mapped.geolocation;
        return mapped;
      }),
    });
  };

  handleFilterClick = activeMarkerFilter => {
    this.setState(state => ({
      ...state,
      activeMarkerFilter,
    }));
  };

  render() {
    const { realEstate } = this.props;
    const { activeMarkerFilter } = this.state;
    
    return (
      <Wrapper>
        <SMap 
          ref={ ref => {
            this.smapRef = ref;
          } } 
          options={ {
            draggable: true,
            center: {
              lat: realEstate.address.geolocation.latitude,
              lng: realEstate.address.geolocation.longitude,
            },
          } }
        />
        <ButtonWrapper>
          { markerFilters.map(({ key, Icon, label }) => (
            <Button 
              key={ key }
              isActive={ activeMarkerFilter === key }
              onClick={ () => this.handleFilterClick(key) }
            >
              <Icon />
              <Tooltip isActive={ activeMarkerFilter === key }>
                <Triangle />
                { label }
              </Tooltip>
            </Button>
          )) }
        </ButtonWrapper>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${ mqTablet`
    margin-bottom: 100px;
  ` }
`;

const SMap = styled(Map)`
  height: 250px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  padding: 0 10px;
  margin-top: -30px;
  align-self: center;
  position: relative;

  ${ mqTablet`
    width: 100%;
    max-width: 1000px;
    
  ` }
`;

const Button = styled(BaseButton)`
  background-color: ${ COLOR_17 };
  height: 50px;
  width: 50px;
  margin-right: 3px;
  align-self: center;
  padding: 0;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0;

  ${ transitions([ 'background-color' ], 'ease .3s') }

  ${ ({ isActive }) => isActive && css`
    background-color: ${ COLOR_1 };
  ` }
`;

const HospitalIcon = styled(Hospital)`
  ${ iconBaseStyle }
  color: ${ hexToRgb(COLOR_2, 0.8) };
  min-height: 50px;
  min-width: 50px;
  padding: 17px;
`;

const BagIcon = styled(HospitalIcon).attrs({
  as: ShoppingBag,
})`
  padding: 14px;
`;

const HandsIcon = styled(HospitalIcon).attrs({
  as: Handshake,
})`
  padding: 16px;
`;

const CartIcon = styled(HospitalIcon).attrs({
  as: CartPlus,
})`
  padding: 17px;
`;

const EllipsisIcon = styled(HospitalIcon).attrs({
  as: DotsHorizontal,
})`
  padding: 12px;
`;

const Tooltip = styled.div`
  padding: 5px;
  width: 60px;
  background-color: ${ COLOR_1 };
  font-size: 10px;
  margin-top: 7px;
  font-weight: normal;
  line-height: 12px;
  color: ${ hexToRgb(COLOR_2, 0.8) };
  display: flex;
  flex-direction: column;
  border-radius: 1px;
  margin-left: -5px;
  text-transform: none;
  opacity: 0;

  ${ transitions([ 'opacity' ], 'ease .3s') }

  ${ ({ isActive }) => isActive && css`
    opacity: 1;
  ` }
`;

const Triangle = styled.div`
  height: 8px;
  width: 8px;
  background-color: ${ COLOR_1 };
  align-self: center;
  margin-top: -8px;
  transform: rotate(45deg);
  border-radius: 1px;
`;

const markerFilters = [
  {
    key: 'hospital',
    Icon: HospitalIcon,
    label: 'Hospital',
  },

  {
    key: 'shopping',
    Icon: BagIcon,
    label: 'Shopping',
  },
  {
    key: 'supermarket',
    Icon: HandsIcon,
    label: 'Mercado',
  },
  {
    key: 'drugstore',
    Icon: CartIcon,
    label: 'Farmácia',
  },
  {
    key: 'all',
    Icon: EllipsisIcon,
    label: 'Todos',
  },
];

export default Surroundings;
