import styled from 'styled-components';

import { SectionTitle } from '../../../../../styled/labels/labels';
import { COLOR_3 } from '../../../../../styled/variables/variables';
import { mqTablet } from '../../../../../styled/helpers/helpers';

export const Wrapper = styled.div`
  align-self: center;
  text-align: center;
  margin-bottom: 30px;

  ${ mqTablet`
    margin-bottom: 40px;
  ` }
`;

export const TSectionTitle = styled(SectionTitle)`
  color: ${ COLOR_3 };
`;
