
import React from 'react';
import styled, { css } from 'styled-components';
import propTypes from 'prop-types';

import { mqTablet, dimmer } from '../../../../../styled/helpers/helpers';
import { COLOR_2, COLOR_1 } from '../../../../../styled/variables/variables';
import { BaseButton } from '../../../../../styled/buttons/buttons';

export const BUY_AD_TYPE = 'Venda';
export const RENT_AD_TYPE = 'Locação';
export const NEW_RELEASES_AD_TYPE = 'Lançamento';

const buttonList = [
  {
    label: 'Comprar',
    name: BUY_AD_TYPE,
  },
  {
    label: 'Alugar',
    name: RENT_AD_TYPE,
  },
  {
    label: 'Lançamentos',
    name: NEW_RELEASES_AD_TYPE,
  },
];

const AdTypeButtonList = ({ onButtonClick, selectedAdType }) => (
  <Wrapper>
    { buttonList.map(item => (
      <Button
        key={ item.name }
        name={ item.name }
        highlight={ selectedAdType === item.name }
        onClick={ onButtonClick }>{ item.label }
      </Button>
    )) }
  </Wrapper>
);

AdTypeButtonList.propTypes = {
  onButtonClick: propTypes.func.isRequired,
  selectedAdType: propTypes.oneOf([
    BUY_AD_TYPE,
    NEW_RELEASES_AD_TYPE,
    RENT_AD_TYPE,
  ]).isRequired,
};

const Wrapper = styled.div`
  padding: 2px;
  display: flex;
  align-self: center;
  width: 100%;
  height: 50px;
  background: white;
  ${ mqTablet`
    max-width: 500px;
  ` }
`;

const Button = styled(BaseButton)`
  border: solid 1px ${ COLOR_2 };
  flex-grow: 1;
  flex-basis: 0;
  color: ${ COLOR_1 };
  text-transform: unset !important;
  background: none;
  border-radius: 0;
  margin: 0;
  margin-right: -1px;
  margin-right: 3px;
  padding: 0 7px;
  height: 100%;
  line-height: 45px;
  font-size: 16px;
  border-radius: 1px;
  font-weight: 700;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    ${ ({ highlight }) => !highlight && css`
      ${ dimmer(0.5, "gray") }
    ` }
  }

  ${ ({ highlight }) => highlight && css`
    ${ dimmer(1, "gray") }
    color: white;
  ` }
`;

export default AdTypeButtonList;
