import React from 'react';
import styled, { css } from 'styled-components';
import { RiLinkUnlinkM } from 'react-icons/ri';

import TooltipButton from '../../../../../../../../components/tooltip-button';

import { mqTablet } from '../../../../../../../../styled/helpers/helpers';

export function DetachBrokerButton({ onDetachPropertyToBroker }) {
  function handleClickButton() {
    onDetachPropertyToBroker();
  }

  return (
    <Wrapper className={"dashboard-btn-view"}>
      <BTooltipButton
        label='Desvincular esse imóvel'
        onClick={handleClickButton}
        Icon={RiLinkUnlinkM}
        iconStyle={unlinkIconStyle}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
position: absolute;
font-size: 80px;
user-select: none;

top: 5px;
right: 0;

${mqTablet`
  align-self: flex-end;
` }
`;

const BTooltipButton = styled(TooltipButton)``;

const unlinkIconStyle = css`
height: 20px;
`;
