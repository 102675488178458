import uuid from 'uuid';
import copy from 'copy-to-clipboard';

const normalize = (str) => {
  if (str) return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  return '';
};

// Converts a word or sentence to an appropriate path name.
const pathfy = (str) => {
  return normalize(str).trim().toLowerCase().replace(/ /g, '-');
};

const onlyNumbers = (str) => str.replace(/[^0-9]+/g, '').trim();

const generateUniqueId = () => uuid.v4();

const copyToClipboard = ({ str, options = {} }) => {
  copy(str, options);
};

export default {
  copyToClipboard,
  generateUniqueId,
  normalize,
  onlyNumbers,
  pathfy,
};
