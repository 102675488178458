import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const ContentWrapper = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
`;

export const ErrorLoadBrokerData = styled.div`
   height: 100vh;

   display: flex;
   align-items: center;
   justify-content: center;

   > div {
    max-width: 600px;
    padding: 0 24px;

    display: flex;
    flex-direction: column;
    align-items: center;

    > p {
      margin-top: 32px;

      font-size: 24px;
      font-weight: 500;
      text-align: center;
    }

    > button {
      margin-top: 32px;
      background-color: #3578E5;
      color: #fff;
      padding: 16px;
      font-size: 18px;
      font-weight: 500;

      transition: filter .2s;

      &:hover {
        filter: brightness(0.9);
      }
    }
   }
`;
