import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';
import propTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import { transitions } from 'polished';
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';

import { backgrounder, mqTablet, mqDesktop } from '../../styled/helpers/helpers';

import Panorama from '../panorama';
import SliderFullScreenComponent from './SliderFullScreen'

import imageUtil from '../../utils/image/image.util';

/*
  global
  setTimeout,
  clearTimeout,
  window
*/

class ImageSlider extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      currentSlide: 0,
      slideshowStatus: props.playSlideshow,
      multiplePhotos: false,
      fullScreenImage: false,
      fullScreenSlide: false,
      fullScreenUrl: '',
      arrayImages: [],
      lazyImagesURLs: props.images.map((image, index) => {
        // first, second and last images always load
        if (index === 0 || index === 1) {
          return image.url
          // return imageUtil.transformImageLink(image)
        } else if (index + 1 === props.images.length) {
          return image.url
          // return imageUtil.transformImageLink(image)
        }

        return ""
      })
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions.bind(this));
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  setSlide = (slide) => {
    const { currentSlide } = this.state;
    const totalSlides = this.calcTotalSlides();
    const calculatedCurrentSlide = currentSlide === 0 ? totalSlides - 1 : slide;
    this.setState({
      ...this.state,
      currentSlide: calculatedCurrentSlide,
    }, () => {
      const { onSlideChange } = this.props;
      if (onSlideChange) onSlideChange(calculatedCurrentSlide);
    })
  }

  next = () => {
    const { currentSlide, lazyImagesURLs } = this.state;

    const totalSlides = this.calcTotalSlides();
    const calculatedCurrentSlide = currentSlide === totalSlides - 1 ? 0 : currentSlide + 1;
    this.setState({
      ...this.state,
      currentSlide: calculatedCurrentSlide,
    }, () => {
      const { slideshowStatus, currentSlide } = this.state;
      if (slideshowStatus) this.disableSlideshowTemporarily();

      const { onSlideChange } = this.props;
      if (onSlideChange) onSlideChange(calculatedCurrentSlide);

      // lazy load always load one slide ahead
      if (this.props.enableLazyload && !lazyImagesURLs[calculatedCurrentSlide + 1]) {
        let _lazyImagesURLs = [...lazyImagesURLs];
        _lazyImagesURLs[calculatedCurrentSlide + 1] = imageUtil.transformImageLink(this.props.images[calculatedCurrentSlide + 1]);
        this.setState({ ...this.state, lazyImagesURLs: _lazyImagesURLs })
      }

    });
  };

  previous = () => {
    const { currentSlide, lazyImagesURLs } = this.state;
    const calculatedCurrentSlide = currentSlide === 0 ? totalSlides - 1 : currentSlide - 1;
    const totalSlides = this.calcTotalSlides();
    this.setState({
      ...this.state,
      currentSlide: calculatedCurrentSlide,
    }, () => {
      const { slideshowStatus, currentSlide } = this.state;
      if (slideshowStatus) this.disableSlideshowTemporarily();

      const { onSlideChange } = this.props;
      if (onSlideChange) onSlideChange(calculatedCurrentSlide);

      // lazy load always load one slide ahead
      if (this.props.enableLazyload && !lazyImagesURLs[calculatedCurrentSlide - 1]) {
        let _lazyImagesURLs = [...lazyImagesURLs];
        _lazyImagesURLs[calculatedCurrentSlide - 1] = imageUtil.transformImageLink(this.props.images[calculatedCurrentSlide - 1]);
        this.setState({ ...this.state, lazyImagesURLs: _lazyImagesURLs })
      }
    });
  }

  slideshowTimeout;
  disableSlideshowTemporarily = () => {
    this.setState({ ...this.state, slideshowStatus: false, });
    clearTimeout(this.slideshowTimeout);

    this.slideshowTimeout = setTimeout(() => {
      this.setState({ ...this.state, slideshowStatus: true, });
    }, 10000);
  };

  startSlideshow = () => {
    this.setState(state => ({
      ...state,
      slideshowStatus: true,
    }));
  };

  stopSlideshow = () => {
    this.setState(state => ({
      ...state,
      slideshowStatus: false,
    }));
  };

  calcTotalSlides = () => {
    const { images, concurrentSlides, } = this.props;

    let totalSlides = 1;
    if (images.length > concurrentSlides) {
      totalSlides = 0;
      totalSlides += parseInt(images.length / concurrentSlides);
      if (images.length % concurrentSlides > 0) {
        totalSlides++;
      }
    }

    return totalSlides;
  };


  handleSearchInfoAdImages = (url) => {
    if (this.props.searchInfoAd) {
      this.setState({ fullScreenUrl: imageUtil.transformImageLink(url, 'large'), fullScreenImage: !this.state.fullScreenImage, fullScreenSlide: !this.state.fullScreenSlide })
      this.props.isPanorama ? this.setState({ panorama: true, showPanorama: true }) : this.setState({ panorama: false, showPanorama: false })
    }

  }

  buildSlider = () => {
    const {
      images,
      height,
      tabletHeight,
      desktopHeight,
      concurrentSlides,
      isPanorama,
      enableLazyload
    } = this.props;
    const { lazyImagesURLs } = this.state;
    const slideGroups = [];
    let count = 0;
    let secondRoundCount = 0;

    this.setState({
      arrayImages: images
    })

    images.forEach((i, o) => {
      let url = "";
      if (typeof i == "string") {
        url = i;
      } else {
        url = i.url.replace('1024', '316');
      }
      const slideGroup = [];
      const SlideContent = isPanorama ? ISPanorama : Image;
      slideGroup.push(
        <SlideContent
          key={o}
          url={url}
          height={height}
          tabletHeight={tabletHeight}
          desktopHeight={desktopHeight}
          onClick={() => { this.handleSearchInfoAdImages(url) }}
        />
      );
      slideGroups.push(
        <Slide key={count++} index={count++} //style={{width:450}}
        >
          {slideGroup.map(slide => slide)}
        </Slide>
      );

    });

    if (slideGroups.length > 2) {
      this.setState({ multiplePhotos: true });
    }

    return slideGroups;
  };


  handleModal = () => {
    this.setState(() => ({
      fullScreenImage: !this.state.fullScreenImage,
      fullScreenSlide: !this.state.fullScreenSlide,
    }));
  }

  render() {
    const {
      className,
      height,
      tabletHeight,
      desktopHeight,
      sliderSidePadding,
      tabletSliderSidePadding,
      slideshowInterval,
    } = this.props;
    const { currentSlide, slideshowStatus } = this.state;

    const config = {
      naturalSlideWidth: '100%',
      naturalSlideHeight: window.innerWidth > 768 ? tabletHeight : height,
      totalSlides: this.calcTotalSlides(),
      dragEnabled: false,
      isPlaying: slideshowStatus,
      interval: slideshowInterval,
      ...this.props.config,
    };

    return (
      <>
        <Wrapper
          {...config}
          currentSlide={currentSlide}
          infinite={true}
          className={className}
          height={height}
          tabletHeight={tabletHeight}
          desktopHeight={desktopHeight}
          sliderSidePadding={sliderSidePadding}
          tabletSliderSidePadding={tabletSliderSidePadding}
        >
          <Slider style={this.state.multiplePhotos && this.props.searchInfoAd && window.innerWidth > 1000 ? { width: 450 } : {}}>
            {this.buildSlider().map(slideGroup => slideGroup)}
          </Slider>
        </Wrapper>
        {this.state.panorama && this.state.showPanorama &&
          <div style={{
            position: 'absolute',
            top: '5.5%',
            left: '12.5%',
            width: '75%',
            height: 500,
            zIndex: 2,
            overflow: 'visible'
          }}>
            <div style={{ position: 'absolute', right: 20, top: 20, cursor: 'pointer', zIndex: 10 }}>
              <p style={{ fontSize: 32, color: 'black', fontWeight: 'bold' }} onClick={() => { this.setState({ showPanorama: false, fullScreenImage: false, fullScreenSlide: false }) }}>
                X
              </p>
            </div>
            <Panorama
              url={imageUtil.transformImageLink(this.state.fullScreenUrl, 'large')}
              style={{
                height: 500,
                width: '95%',
                marginTop: 70,
              }}
            />
          </div>
        }

        {this.state.fullScreenSlide && !this.state.showPanorama &&
          <SliderFullScreenComponent
            images={this.state.arrayImages}
            currentURL={imageUtil.transformImageLink(this.state.fullScreenUrl, 'large')}
            show={this.state.fullScreenSlide}
            handle={this.handleModal.bind(this)}
          >
          </SliderFullScreenComponent>

        }
      </>
    );
  }
}

ImageSlider.propTypes = {
  className: propTypes.string,
  concurrentSlides: propTypes.number.isRequired,
  config: propTypes.object.isRequired,
  desktopHeight: propTypes.string,
  height: propTypes.string,
  images: propTypes.arrayOf(propTypes.string),
  isPanorama: propTypes.bool,
  onSlideChange: propTypes.func,
  placeholders: propTypes.arrayOf(propTypes.string),
  playSlideshow: propTypes.bool,
  sliderSidePadding: propTypes.string,
  slideshowInterval: propTypes.number,
  tabletHeight: propTypes.string,
  tabletSliderSidePadding: propTypes.string,
};

ImageSlider.defaultProps = {
  concurrentSlides: 1,
  config: {},
  desktopHeight: '250px',
  height: '200px',
  images: [],
  isPanorama: false,
  playSlideshow: false,
  slideshowInterval: 5000,
  tabletHeight: '250px',
  enableLazyload: true
};

const LazyLoadStyled = styled(LazyLoad)`
  flex-grow: 1;
`;

const Wrapper = styled.div.attrs({
  as: CarouselProvider,
})`
  overflow: hidden;

  ul {
    display: flex;
    padding-left: 0;
    // preventing flickering image background on Chrome
    -webkit-transform: translate3d(0,0,0);
    -webkit-backface-visibility: hidden;
    ${transitions(['transform'], 'ease 1s !important')}
    li {
      list-style-type: none;
      & > div {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }
  }

  ${({ height, sliderSidePadding }) => css`
    height: ${height};
    ul {
      li {
        & > div {
          padding: 0 ${sliderSidePadding};
          cursor: pointer;
        }
      }
    }
  ` }



  ${mqTablet`
    ${({ tabletHeight, tabletSliderSidePadding }) => css`
      min-height: ${tabletHeight};
      max-height: ${tabletHeight}
      ul {
        li {
          & > div {
            padding: 0 ${tabletSliderSidePadding};
            cursor: pointer;
          }
        }
      }
    ` }
  ` }

  ${mqDesktop`
    ${({ desktopHeight }) => css`
      min-height: ${desktopHeight};
      max-height: ${desktopHeight}
    ` }
  ` }
`;

const imageStyle = css`
  flex-grow: 1;
  ${({ height }) => css`
    height: ${height};
  ` }

  ${mqTablet`
    ${({ tabletHeight }) => css`
      height: ${tabletHeight};
    ` }
  ` }

  ${mqDesktop`
    ${({ desktopHeight }) => css`
      height: ${desktopHeight};
    ` }
  ` }
`;

const Image = styled.div`
  ${imageStyle}
  ${({ url }) => url !== '' && backgrounder(url)}
  height: 300px;
`;

const ISPanorama = styled(Panorama)`
  ${imageStyle}
  background: none;
`;

export default ImageSlider;
