import React from 'react';
import styled from 'styled-components';

import { BaseLabel } from '../../../styled/labels/labels';
import { COLOR_1 } from '../../../styled/variables/variables';
import { backgrounder, mqTablet } from '../../../styled/helpers/helpers';

/*
  global
  process
  */

const { PUBLIC_URL } = process.env;

const Content = () => (
  <Wrapper>
    <SectionTitle>
      A Koortimativa
    </SectionTitle>
    <TextBlock>
      A KOORTimativa é uma ferramenta que realiza a estimativa de preço de
      imóveis residenciais (apartamentos e casas).
    </TextBlock>
    <TextBlock>
      A metodologia desenvolvida e empregada pela KOORTimativa segue as
      normas da ABNT 14653 e emprega critérios estatísticos rigorosos, além de
      utilizar   ferramentas   de   inteligência   artificial   (IA)   e   banco   de   dados
      atualizados.
    </TextBlock>
    <TextBlock>
      Para   a   realização   de   uma   estimativa   de   preço   são   utilizadas   as
      características básicas do imóvel tais como o endereço, área, número de
      quartos e banheiros, entre outros.
    </TextBlock>
    <TextBlock>
      A estimativa do preço do imóvel é apresentada de forma padronizada no
      Relatório Auxiliar de Avaliação do Imóvel (RAAI) o qual é gerado em poucos
      minutos e consolida em um único documento a estimativa de preço e seus
      intervalos máximo e mínimo, a localização do imóvel no mapa, os pontos
      relevantes do entorno, as principais variáveis que afetam o preço de um
      imóvel, além da seleção de imóveis similares para efeito de comparação.
    </TextBlock>
    {/* <SectionTitle>
      Como era a avaliação de imóveis antes da Koortimativa
    </SectionTitle>

    <TextBlock>
      <strong>Existem muitas razões para precisar avaliar o preço de um imóvel</strong>: compra e venda, aluguel,
      inventário, execução de dívidas, alienação fiduciária, entre diversos outros. Existe, inclusive, a
      norma ABNT 14653, que delimita os padrões técnicos mínimos necessários.
      Contudo, existe na prática um vácuo no segmento de avaliação. <strong>Apesar dos altos custos e da
        demora, a alternativa mais usada é o avaliador profissional</strong>, que levanta amostras de imóveis
      representativos e preparam laudos. É comum que laudos preparados por profissionais
      diferentes para um mesmo imóvel deem avaliações completamente diferentes. Na prática,
      grandes usuários de avaliações (como bancos, seguradoras e fundos imobiliários) costumam
      encomendar várias avaliações independentes para ter alguma confiança no valor estimado.
    </TextBlock> */}

    <SectionTitle>
      Diferenciais da Koortimativa
    </SectionTitle>

    <BlueText>
      Tempo
    </BlueText>
    <TextBlock>
      Ao contrário dos avaliadores, que precisam levantar amostras para cada
      análise, a KOORTimativa faz uso automático de grandes bases de dados. Os
      resultados são produzidos de forma automática e nenhum desses processos
      requer trabalho manual, o que torna o tempo de obtenção de avaliações
      curto e previsível.
    </TextBlock>


    <BlueText>
      Confiabilidade
    </BlueText>
    <TextBlock>
      A KOORTimativa utiliza técnicas avançadas e banco de dados de mercado, o
      que permite seu uso para avaliar qualquer imóvel residencial, mantendo a
      qualidade e a padronização da metodologia e dos relatórios.
    </TextBlock>

    <BlueText>
      Custo
    </BlueText>
    <TextBlock>
      A KOORTimativa não envolve custos altos e repetitivos de deslocamento e
      preparação de laudos específicos para cada imóvel. Isso permite produzir
      resultados a um custo final por imóvel avaliado muito mais baixo para o
      consumidor.
    </TextBlock>

  </Wrapper>
);

const Wrapper = styled.div`
padding: 20px 10px;

${mqTablet`
width: 100%;
max-width: 700px;
align-self: center;
padding: 80px 10px;
display: flex;
flex-direction: column;
` }
`;

const SectionTitle = styled(BaseLabel)`
font-weight: 700;
font-size: 24px;
margin-bottom: 30px;
margin-top: 20px;
`;

const TextBlock = styled(BaseLabel).attrs({
  as: 'p',
})`
font-size: 16px;
margin-bottom: 20px;
`;

const BlueText = styled.p`
color: ${COLOR_1};
font-weight: 700;
display: inline;
margin-bottom: 10px;
`;


export default Content;
