import styled, { css } from 'styled-components';

const IntegrationButtonBase = styled.button`
  width: 48%;
  margin-top: 24px;

  padding: 12px;
  border-radius: 4px;

  color: #FFFFFF;

  transition: filter .2s;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const IntegrationActionButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const IntegrationManageButton = styled(IntegrationButtonBase)`
  background-color: #2B50D8;
`;

export const IntegrationRemoveButton = styled(IntegrationButtonBase)`
  background-color: #343234;

  ${({ hasPortalToBeRemoved }) => hasPortalToBeRemoved && css`
    background-color: #dc3545;
  `}
`;
