import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
`;

export const FilterContainer = styled.div`
  padding: 20px;
`;

export const FilterOptionsGroup = styled.div`
  margin-top: 18px;

  @media (min-width: 620px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 28px;
  }
`;

export const SearchKeyFilter = styled.div`
  label {
    font-size: 16px;
    margin-bottom: 8px;
  }

  .month-react-select-container {
    height: 40px;

    input {
      height: 40px;
    }
  }
`;

export  const FilterButton = styled.button`
  margin-top: 32px;
  width: 140px;
  height: 42px;
  background-color: #3578E5;
  color: #ffffff;
  font-weight: 500;
  border-radius: 4px;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: filter 0.2s;

  &:disabled {
    cursor: not-allowed;
  }

  &:not(:disabled):hover {
    filter: brightness(0.9);
  }
`;

export const LeadsContainer = styled.div`
  padding: 20px;

  margin-top: 42px;
  margin-bottom: 56px;

  h2 {
    font-size: 18px;
    font-weight: 600;

    display: flex;
    justify-content: space-between;
  }

  @media (max-width: 620px) {
    h2 {
      flex-direction: column;
      align-items: flex-start;

      button {
        margin-top: 16px;
      }
    }
  }
`;

export const LeadsGroup = styled.div`
  display: flex;
  align-items: flex-start;

  h3 {
    width: 140px;
  }

  & + div {
    margin-top: 32px;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-left: 24px;
    width: 100%;

    li {
      position: relative;

      border: 2px solid #eaeaea;
      padding: 12px 12px 12px 18px;

      &::before {
        content: '';
        background-color: #2B50D8;

        position: absolute;
        top: -2px;
        left: -2px;
        bottom: -2px;
        width: 6px;
      }

      & + li {
        margin-top: 8px;
      }

      p {
        margin: 0;
        margin-top: 4px;
        color: #696969;
        line-break: anywhere;
      }

      > a {
        display: block;
        margin-top: 8px;
        color: #2B50D8;
      }
    }
  }

  @media (max-width: 620px) {
    flex-direction: column;

    ul {
      margin-left: unset;
      margin-top: 16px;
    }
  }
`;

export const LastMonthsContainer = styled.div`
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;

  @media (max-width: 767px) {
    margin-top: -64px;
  }

  @media (max-width: 730px) {
    grid-template-columns: 1fr;
  }
`;

export const LastMonthItem = styled.div`
  padding: 18px;
  background: #FFF;
  box-shadow: 1px 1px 12px 0px rgba(0, 0, 0, 0.25);

  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    font-size: 20px;
    font-weight: 600;

    span {
      color: #777;
      font-size: 13px;
      font-weight: 500;
      display: block;
      margin-bottom: 8px;
    }
  }

  strong {
    color: #2B50D8;
    font-size: 32px;
    font-weight: 700;
  }

  ${({isCurrent}) => isCurrent && css`
    background: #2B50D8;
    color: #fff;

    h3 > span,
    strong {
      color: #FFFFFF;
    }
  `}
`;

export const LoadingContainer = styled.div`
  height: 400px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ExportLeadsButton = styled.button`
  background-color: transparent;
  color: #2B50D8;
  font-weight: 500;

  transition: filter 0.2s;

  &:not(:disabled):hover {
    filter: brightness(0.9);
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;

export const CSVDownload = styled.a`
  display: none;
`;

export const PriceGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 24px;
  margin-bottom: 52px;

   > strong {
    display: block;
    font-weight: 500;
    font-size: 18px;

    > span {
      display: inline-block;
      font-weight: 600;
      margin-top: 8px;
      color: #3578E5;
    }
  }

  @media (max-width: 775px) {
    grid-template-columns: 1fr;

    > strong:last-child {
      margin-top: 18px;
    }
  }
`;
