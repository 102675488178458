import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';

import { mqTablet, dimmer } from '../../../../../styled/helpers/helpers';
import { BaseButton } from '../../../../../styled/buttons/buttons';
import { COLOR_1, COLOR_2, COLOR_3 } from '../../../../../styled/variables/variables';

const SearchButton = ({ onClick, disabled }) => (
  /*<Wrapper>
    {
      disabled ? 
    <ButtonDisabled onClick={ onClick } disabled={disabled}>
      procurar corretores
    </ButtonDisabled>
    :
    <Button onClick={onClick} disabled={disabled}>
      procurar corretores
    </Button>
    }
  </Wrapper>*/
  <></>
);

SearchButton.propTypes = {
  onClick: propTypes.func.isRequired,
  disabled: propTypes.bool,
};

const Wrapper = styled.div`
  padding: 20px;
  align-self: center;
  ${ dimmer(0.3, COLOR_2) }
  width: 100%;
  border-radius: 0 0 3px 3px;
  display: flex;
  justify-content: center;

  ${ mqTablet`
    background: none;
    border-radius: 0;
    width: unset;
  ` }
`;

const Button = styled(BaseButton).attrs({
  type: 'submit',
})`
  background-color: ${ COLOR_1 };
  color: ${ COLOR_2 };
  height: 50px;
  padding: 0 10px;
  width: 200px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  border: none;
`;

const ButtonDisabled = styled(BaseButton).attrs({
  type: 'submit',
})`
  background-color: ${ COLOR_3};
  color: ${ COLOR_2};
  height: 50px;
  padding: 0 10px;
  width: 200px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  border: none;
`;

export default SearchButton;
