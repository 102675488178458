import styled from 'styled-components';
import { Popover } from "@headlessui/react";

export const PopoverButton = styled(Popover.Button)`
  line-height: 0;
  position: absolute;
  right: 16px;
  top: 18px;
  background-color: transparent;

  transition: filter 0.2s;

  &:hover {
      filter: brightness(0.9);
    }
`;
