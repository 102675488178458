const propertyTypesForAdPage = {
  "Apartamento": ["Outros", "apartamentos"],
  "Casa": ["Outras", "casas"],
  "Casa de Condomínio": ["Outras", "casas de condomínio"],
  "Cobertura": ["Outras", "coberturas"],
  "Flat": ["Outros", "flats"],
  "Kitnet": ["Outras", "kitnets"],
  "Terreno": ["Outros", "terrenos"],
  "Chácara | Sítio | Fazenda": ["Outras", "opções"],
  "Edifício": ["Outros", "Edifícios"],
  "Galpão | Depósito | Armazém": ["Outras", "opções"],
  "Loja | Ponto Comercial": ["Outras", "opções"],
  "Sala": ["Outras", "salas"],
}

export { propertyTypesForAdPage };
