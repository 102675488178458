import styled from 'styled-components';

import { BaseLabel } from '../../styled/labels/labels';
import { mqTablet } from '../../styled/helpers/helpers';
import { BaseButton } from '../../styled/buttons/buttons';
import { COLOR_4, COLOR_1, COLOR_2 } from '../../styled/variables/variables';

import SocialIcons from '../../components/social-icons';

export const Counter = styled.div`
  font-size: 12px;
  margin-top: -5px;
  margin-bottom: 5px;
  text-align: right;
`;

export const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 10px;
  padding-top: 130px;
  padding-bottom: 50px;

  ${mqTablet`
    padding-top: 200px;
    padding-bottom: 200px;
  ` }
`;

export const Title = styled(BaseLabel)`
  align-self: center;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 20px;
`;

export const Subtitle = styled(BaseLabel)`
  align-self: center;
  text-align: center;
  max-width: 700px;
  font-size: 14px;
  margin-bottom: 20px;
`;

export const CSocialIcons = styled(SocialIcons)`
  align-self: center;
  margin-bottom: 30px;

  & > svg {
    color: ${COLOR_4};

    &:nth-child(2) {
      color: ${COLOR_1};
    }
  }
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 540px;

  ${mqTablet`
    align-self: center;
  ` }
`;

export const SendButton = styled(BaseButton).attrs({
  type: 'submit',
})`
  width: 220px;
  align-self: flex-end;
  background-color: ${COLOR_1};
  color: ${COLOR_2};
  border: none;
  font-weight: 700;
`;
