import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { transitions } from 'polished';
import { Search } from 'styled-icons/octicons/Search';
import { Row, Col } from 'react-bootstrap';

import { BaseButton } from '../../../styled/buttons/buttons';
import { BaseInput } from '../../../styled/inputs/inputs';
import { COLOR_1, COLOR_2, COLOR_5, COLOR_3, COLOR_17 } from '../../../styled/variables/variables';
import { iconBaseStyle, dimmer, mqDesktop, mqCustom } from '../../../styled/helpers/helpers';

import SearchAutocomplete from '../../../components/search-autocomplete';
import { DETAIL_VIEW } from '../../../pages/search/view-mode-selector/ViewModeSeletor';

import realEstateTypes from '../../../constants/real-estate-types';
import listingService from '../../../services/listing/listing.service';

import domEventsUtil from '../../../utils/dom-events/dom-events.util';
import queryStringUtil from '../../../utils/query-string/query-string.util';
import eventsConstants from '../../../constants/events';
import pathsConstants from '../../../constants/paths';

import './search-bar.css';


/*
  global window
*/

const TRANSACTION_TYPES = {
  SALE: 0,
  RENTAL: 1,
  NEW_RELEASES: 2,
};

class SearchBar extends PureComponent {

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    const { history } = props;
    this.state = {
      searchString: queryStringUtil.parse(history).searchString,
      autocompleteAlternatives: [],
      selectedAutocomplete: null,
      isSearchbarFocused: false,
      showFilters: false,
      showFiltersDesktop: false,
      form: {
        searchString: queryStringUtil.parse(history).searchString,
        transaction: TRANSACTION_TYPES.SALE.toString(),
        type: realEstateTypes[0].types[0].id,
      },
      cities: [],
      districts: [{
        types: [{
          label: 'Selecione uma cidade',
          id: 1
        }],
      }],
      selectedCitie: null,
      selectedDistrict: null,
      selectedBedRooms: null,
      selectedAdType: 0,
      selectedRealEstateType: realEstateTypes[0].types[0],
    };
  }

  async componentDidMount() {
    const { history } = this.props;

    this.setState(state => ({
      ...state,
      searchString: queryStringUtil.parse(history).searchString,
    }));
    window.addEventListener('click', this.handleOutsideClick)
    this.unlistenRouteChange = this.props.history
      .listen(this.handleRouteChange);

    localStorage.setItem("adTypeForSearchResponsiveInMap", "Venda");

    var r = await listingService.getCities({ adType: "Venda" });

    if (r[0].types[0]) {
      this.setState({
        ...this.state,
        cities: r,
        selectedCitie: r[0].types[0],
        selectedDistrict: null,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleOutsideClick);
    this.unlistenRouteChange();
  }

  handleRouteChange = location => {
    const { pathname } = location;
    this.setState(state => ({
      ...state,
      showFilters: !state.isSearchbarFocused && pathname === pathsConstants.HOME
    }))
  }

  handleOutsideClick = () => {
    this.setState(state => ({
      ...state,
      showFilters: false,
    }));
  };

  handleInputChange = event => {
    const { value } = event.target;
    this.setState({
      ...this.state,
      searchString: value,
    });
  };

  handleSubmit = event => {
    if (event) event.preventDefault();
    const { history } = this.props;
    const { form, autocompleteAlternatives, selectedAutocomplete } = this.state;
    let newSearchString;
    if (selectedAutocomplete) {
      const alternative = autocompleteAlternatives.find(item => {
        return item.id === selectedAutocomplete;
      });
      newSearchString = alternative ? alternative.formatted : '';
    } else if (autocompleteAlternatives.length > 0) {
      newSearchString = autocompleteAlternatives[0].formatted;
    } else {
      newSearchString = form.searchString;
    }

    if (!newSearchString) {
      newSearchString = queryStringUtil.parse(history).searchString
    }


    this.setState({
      ...this.state,
      selectedAutocomplete: null,
      autocompleteAlternatives: [],
      isSearchbarFocused: false,
      form: {
        ...this.state.form,
        searchString: newSearchString,
      },
    }, () => {
      const { history } = this.props;
      const { pathname } = history.location;
      const { form } = this.state;
      if (pathname === pathsConstants.HOME) {
        var params = {
          searchString: newSearchString,
          transaction: form.transaction,
          type: form.type,
          viewMode: DETAIL_VIEW,
          // city: this.state.selectedCitie,
          // neighborhood: this.state.selectedDistrict,
          // bedrooms: this.state.selectedBedRooms,
          // type: this.state.selectedRealEstateType,
        }

        if (this.state.selectedRealEstateType && this.state.selectedRealEstateType >= 0) {
          params.type = this.state.selectedRealEstateType
        }
        if (this.state.selectedCitie) {
          params.city = this.state.selectedCitie
        }
        if (this.state.selectedDistrict) {
          params.neighborhood = this.state.selectedDistrict;
        }
        if (this.state.selectedBedRooms) {
          params.bedrooms = this.state.selectedBedRooms;
        }

        history.push({
          pathname: pathsConstants.SEARCH,
          search: queryStringUtil.stringify(history, params),
        });
      } else {
        domEventsUtil.dispatch({
          name: eventsConstants.NEW_SEARCH,
          params: {
            searchString: newSearchString,
            transaction: form.transaction,
            type: form.type,
          }
        });
      }
    });
  };

  handleAutocompleteClick = addressAutocomplete => {
    this.setState({
      ...this.state,
      selectedAutocomplete: addressAutocomplete.id,
    }, () => {
      this.handleSubmit();
    });
  };

  handleAutocompleteLoad = autocompleteAlternatives => {
    this.setState({
      ...this.state,
      autocompleteAlternatives,
    });
  };

  handleSelectedAutocompleteAlternativeUpdate = alternative => {
    this.setState({
      ...this.state,
      selectedAutocomplete: alternative.id,
    });
  };

  handleFormChange = event => {
    const { name, value } = event.target;
    this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        [name]: value,
      },
    }));
  }

  handleDropdownItemClick = async (e) => {
    let value = e.target.value
    let id = e.target.id
    switch (id) {
      case "select-city-real":
        var r = await listingService.getDistricts({ city: value })
        this.setState({
          ...this.state,
          selectedCitie: value,
          districts: r,
          selectedDistrict: null,
        });
        break;
      case "select-bairro":
        this.setState({
          ...this.state,
          selectedDistrict: value,
        });
        break;
      case "select-tipo":
        this.setState({
          ...this.state,
          selectedRealEstateType: value,
        });
        break;
      case "select-quartos":
        this.setState({
          ...this.state,
          selectedBedRooms: value,
        })
        break;
    }
  };

  handleChangeAdTypeSelect = async event => {
    event.persist();

    const { value } = event.target;

    this.setState({
      ...this.state,
      districts: [{
        types: [{
          label: 'Selecione uma cidade',
          id: 1
        }],
      }],
    });

    switch (value) {
      case '0':
        localStorage.setItem("adTypeForSearchResponsiveInMap", "Venda");

        document.getElementById("select-city-real").selectedIndex = 0;

        var r = await listingService.getCities({ adType: "Venda" });

        if (r[0].types[0]) {
          this.setState({
            ...this.state,
            cities: r,
            selectedCitie: null,
            selectedDistrict: null,
          });
        }

        break;

      case '1':
        localStorage.setItem("adTypeForSearchResponsiveInMap", "Lançamento");

        document.getElementById("select-city-real").selectedIndex = 0;

        var r = await listingService.getCities({ adType: "Lançamento" });

        if (r[0].types[0]) {
          this.setState({
            ...this.state,
            cities: r,
            selectedCitie: null,
            selectedDistrict: null,
          });
        }

        break;

      case '2':
        localStorage.setItem("adTypeForSearchResponsiveInMap", "Locação");

        document.getElementById("select-city-real").selectedIndex = 0;

        var r = await listingService.getCities({ adType: "Locação" });

        if (r[0].types[0]) {
          this.setState({
            ...this.state,
            cities: r,
            selectedCitie: null,
            selectedDistrict: null,
          });
        }

        break;

      default:
        break;
    }
  }

  render() {
    const { className, show } = this.props;
    const {
      autocompleteAlternatives,
      isSearchbarFocused,
      showFilters,
    } = this.state;

    return (
      <Wrapper
        className={className}
        show={show}
        showFilters={showFilters}
        onChange={this.handleFormChange}
        onClick={event => event.stopPropagation()}
        onSubmit={this.handleSubmit}
      >
        <InnerWrapper>
          <InputWrapper>
            <SearchAutocompleteWrapper
              show={isSearchbarFocused && autocompleteAlternatives.length > 0}
              bringItUp={!showFilters}
            >
              <SBSearchAutocomplete
                onItemClick={this.handleAutocompleteClick}
                onLoad={this.handleAutocompleteLoad}
                onSelectedAlternativeUpdate={this.handleSelectedAutocompleteAlternativeUpdate}
                searchString={this.state.searchString}
                show={true}
              />
            </SearchAutocompleteWrapper>
          </InputWrapper>
          <TransactionTypeButtonWrapper show={showFilters}>
            <Row style={{ width: "800px" }} className={'no-gutter'}>
              <Col xl={2} style={{}}>
                <select className="form-select" id="select-city" name="select-city" onChange={this.handleChangeAdTypeSelect}>
                  <option value={0}> Comprar </option>
                  <option value={1}> Lançamentos </option>
                  <option value={2}> Alugar </option>
                </select>
              </Col>
              <Col xl={2} style={{}}>
                <select className="form-select" defaultValue="Cidade" id="select-city-real" name="select-city-real" onChange={this.handleDropdownItemClick}>
                  <option disabled hidden value="Cidade">Cidade</option>
                  {this.state.cities[0] && this.state.cities[0].types.map(({ value, label, }) => (
                    <option key={label} value={label}> {label} </option>
                  ))}
                </select>
              </Col>
              <Col xl={2} style={{}}>
                <select className="form-select" defaultValue="Bairro" id="select-bairro" name="select-bairro" onChange={this.handleDropdownItemClick}>
                  <option disabled hidden value="Bairro">Bairro</option>
                  {this.state.districts[0] && this.state.districts[0].types.map(({ value, label, }) => (
                    <option key={label} value={value}> {label} </option>
                  ))}
                </select>
              </Col>
              <Col xl={2} style={{}}>
                <select className="form-select" defaultValue="Tipo" id="select-tipo" name="select-tipo" onChange={this.handleDropdownItemClick}>
                  <option disabled hidden value="Tipo">Tipo</option>
                  {realEstateTypes[0] && realEstateTypes[0].types.map(({ id, label }) => (
                    <option key={label} value={id}> {label} </option>
                  ))}
                </select>
              </Col>
              <Col xl={2} style={{}}>
                <select className="form-select" defaultValue="N° de quartos" id="select-quarto" name="select-quarto">
                  <option disabled hidden value="N° de quartos">N° de quartos</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4+</option>
                </select>
              </Col>
              <Col xl={2}>
                <SearchButton>
                  <SearchButtonIcon />
                </SearchButton>
              </Col>
            </Row>
          </TransactionTypeButtonWrapper>
        </InnerWrapper>
      </Wrapper>
    );
  }
}

SearchBar.defaultProps = {
  show: true,
};

const Wrapper = styled.form`
  position: absolute;
  padding: 10px;
  background-color: ${COLOR_17};
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 80px;
  display: flex;
  flex-direction: column;

  height: 60px;
  opacity: 1;
  ${transitions([
  'top',
  'opacity',
  'height',
  'width',
  'max-width'
], 'ease .6s')}

  ${({ show }) => !show && css`
    height: 0;
    opacity: 0;

    overflow: hidden;
  ` }

  ${({ showFilters }) => showFilters && css`
    height: 140px;
  ` }

  ${mqDesktop`
    opacity: 1;
    top: unset;
    flex-grow: 1;
    left: unset;
    margin: 0;
    border-radius: 1px;;
    padding: 0;
    position: relative;
    background: none;
    align-self: center;
    height: 40px;
    padding: 0 10px;
    max-width: 100%;
    justify-content: center;
    ${transitions([
  'top',
  'opacity',
  'height',
  'width',
  'max-width'
], 'ease .6s .6s')}
    ${({ show }) => !show && css`
    ${transitions([
  'top',
  'opacity',
  'height',
  'width',
  'max-width'
], 'ease .6s 0s')}
      opacity: 0;
      max-width: 0;
    ` }
  ` };

  @media (max-width: 1080px) {
    display: none;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${mqDesktop`
    min-width: 750px;
    flex-direction: row-reverse;
    justify-content: center;
  ` }
`;

const InputWrapper = styled.div`
  display: flex;
  height: 40px;

  ${mqDesktop`
    position: relative;
    min-width: 50px;
  ` };
`;

const Input = styled(BaseInput)`
  flex-grow: 1;
  height: 100%;
  border-radius: 0;
  border-radius: 1px;
  border: none;
  height: 40px;
  margin: 0;
  ${mqDesktop`
    background: transparent;
    color: ${COLOR_2};
    font-size: 16px;
    letter-spacing: 1.1px;
    width: 100%;
    ${transitions(['background-color', 'color'], 'ease .3s')};
  ` };

  &::placeholder {
    ${mqDesktop`
      color: ${COLOR_2};
    ` };
  }
  &:focus {
    ${mqDesktop`
      background-color: ${COLOR_2};
      color: ${COLOR_3};
    ` };
    &::placeholder {
      color: ${COLOR_5};
    }
  }
`;

const SearchButton = styled(BaseButton).attrs({
  type: 'submit',
})`
  margin: 0;
  border: none;
  border-radius: 0;
  padding: 0 10px;
  background-color: #f6861f;
  color: ${COLOR_2};
  border-radius: 1px;
  height: 38px;
`;

const SearchButtonIcon = styled(Search).attrs({
  type: 'submit',
})`
  ${iconBaseStyle};
  color: ${COLOR_2};
  height: 22px;
  align-self:center;
`;

const TransactionTypeButtonWrapper = styled.div`
  width: 100%;
  padding: 0;
  display: flex;
  overflow: hidden;
  opacity: 0;
  max-height: 0;
  min-height: 0;

  ${transitions([
  'opacity',
  'min-height',
  'max-height',
  'padding'
], 'ease .6s')}

  ${({ show }) => show && css`
    max-height: 50px;
    min-height: 50px;
    opacity: 1;
    padding: 10px 0;
  ` }

  ${mqDesktop`
    max-height: 40px;
    min-height: 40px;
    opacity: 1;
    padding: 0;
    align-self: center;
    width: fit-content;
    margin-right: 10px;
  ` }
`;

const TransactionTypeButton = styled(BaseButton)`
  background-color: transparent;
  text-transform: none;
  border: solid 1px ${COLOR_2};
  color: ${COLOR_2};
  padding: 0 5px;
  flex-grow: 1;
  font-size: 16px;
  height: 30px;
  line-height: 25px;
  min-width: calc((100% - 20px) / 3);
  justify-content: center;
  font-weight: 600;

  ${({ isActive }) => {
    return isActive && css`
    ${dimmer(0.75, COLOR_1)}
  `
  }}

  ${mqDesktop`
      min-width: 130px;
      max-width: 130px;
  ` }

  ${mqCustom(250)`
  font-size: 12px;
  `}

  ${mqCustom(400)`
    font-size: 16px;
  `}
`;

const RealEstateTypeSelector = styled.select`
  background-color: transparent;
  border: solid 1px ${COLOR_2};
  border-width: 0;
  color: ${COLOR_2};
  font-size: 16px;
  text-transform: capitalize;
  margin: 0;
  justify-content:center;
  align-self:center;
  display:flex;
  align-items:center;
  font-family: Montserrat, "Open Sans", Helvetica, Arial, sans-serif;
  font-size:14px;

  opacity: 0;
  min-height: 0;
  max-height: 0;
  ${transitions([
  'opacity',
  'min-height',
  'max-height',
  'border-width'
], 'ease .6s')}
  ${({ show }) => show && css`
    min-height: 30px;
    max-height: 30px;
    opacity: 1;
    border-width: 1px;
  ` }

  ${mqDesktop`
    min-height: 30px;
    max-height: 30px;
    opacity: 1;
    align-self: center;
    margin-right: 10px;
    width: 260px;
    padding: 0 10px;
    border-width: 1px;
  ` }
`;

const Option = styled.option`
  width:13px;
  color:black;
`;

const SearchAutocompleteWrapper = styled.div`
  ${dimmer(0.9)};
  padding: 0 10px 10px 10px;
  opacity: 0;
  border-radius: 0;
  max-height: 0;
  min-height: 0;
  margin: 10px -10px 0 -10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  position: absolute;
  top: 130px;
  width: 100vw;

  ${transitions(['min-height', 'opacity'], 'ease .6s')};

  ${({ bringItUp }) => bringItUp && css`
    top: 60px;
    margin-top: 0;
  ` }

  ${({ show }) => show && css`
    opacity: 1;
    min-height: 180px;
    max-height: 180px;
    ${mqDesktop`
      max-height: 300px;
    ` };
  ` };

  ${mqDesktop`
    border-radius: 0 0 3px 3px;
    margin: 10px 0 0 0;
    width: 100%;
    top: 50px;
    right: 0;
  ` };
`;

const SBSearchAutocomplete = styled(SearchAutocomplete)`
  flex-grow: 1;
`;

export default withRouter(SearchBar);
