import styled, {css} from 'styled-components';
import Modal from 'react-modal';
import { Filter } from "styled-icons/boxicons-regular/Filter";

import { BaseButton } from '../../../styled/buttons/buttons';
import { COLOR_2 } from '../../../styled/variables/variables';
import { iconBaseStyle, mqDesktop, noScrollBar, pointer } from '../../../styled/helpers/helpers';

export const TopWrapper = styled.div`
  display: none;

  ${mqDesktop`
    ${pointer};
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  `};
`;

export const OpenButton = styled(BaseButton)`
  background-color: #2b2b2b;
  color: ${COLOR_2};
  display: flex;
  justify-content: center;
  padding: 0 10px;
  height: 35px;
  line-height: 35px;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 600;
`;

export const FilterIcon = styled(Filter)`
  ${iconBaseStyle};
  color: ${COLOR_2};
  margin-right: 8px;
  align-self: center;
  min-height: 20px;
  min-width: 20px;
`;

export const FilterModal = styled(Modal).attrs({
  style: {
    content: {
      backgroundColor: 'transparent',
      border: 0,
      padding: 0,
      inset: 0,
      borderRadius: 0,
      overflowY: 'hidden',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }
  }
})`
`;

export const FilterModalContent = styled.div`
  margin-top: 24px;
  padding: 0 24px;
  height: 60vh;
  overflow-y: scroll;
  overflow-x: hidden;
`;
export const FilterModalBody = styled.div`
  background: #FAFAFA;
  width: 100vw;
  max-width: 768px;
`;

export const FilterModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #000;
  padding: 16px;

  h3 {
    font-size: 20px;
    font-weight: 700;
    color: #fff;
  }

  button {
    width: 42px;
    height: 42px;
    border-radius: 8px;
    background-color: transparent;
    font-size: 0;
    margin-left: 16px;

    transition: background-color .2s;

    &:hover {
      background-color: #EEF0F4;
    }
  }
`;

export const FilterModalFooter = styled.div`
  background-color: #FAFAFA;
  padding: 12px 24px;

  border-top: 1px solid #aeaeae;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SearchButton = styled(BaseButton)`
  background-color: #000000;
  color: #ffffff;
  border: none;
`;

export const ClearFilterButton = styled(BaseButton)`
  color: #000000;
  border: 1px solid #000000;
  background-color: #ffffff;
  padding: 0 16px;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 600;

  transition: filter .2s;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const Wrapper = styled.form`
  z-index: 15;
  position: absolute;
  height: calc(100vh - 180px);
  top: 180px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  ${noScrollBar};
  padding: 10px;
  width: 100vw;
  background-color: ${COLOR_2};

  left: -100vw;

  ${mqDesktop`
    top: 80px;
    left: unset;
    right: 10px;
    width: 550px;

    opacity: 1;
    height: calc(100vh - 80px);
    border-radius: 1px;
    background-color: ${COLOR_2};
    padding: 20px;
    padding-top: 10px;

    top: 100px;
    height: 38px;
    right: 430px;
    width: 110px;
    padding: 0;
    background-color: ${COLOR_2};
  `};

  ${({ show }) =>
    show
    ? css`
      opacity: 1;
      left: 0px;
    `
    : css`
      opacity: 0;
      left: -100vw;
    `};

    ${({ showMap }) =>
      !showMap &&
      css`
      left: 24px !important;
    `}

  @media (max-width: 1079px) {
    height: calc(100% - 128px);
    top: 128px;

    &::before {
      content: '';
      background: #ffffff;
      height: 90px;

      position: fixed;
      width: 100%;
      bottom: 0px;
    }
  }
`;

export const TransactionsButtonsGroup = styled.div`
  padding: 0 24px;
  margin-top: 24px;
`;

const TransactionButtonBase = styled.button`
  font-size: 16px;
  font-weight: 600;
  color: #000;
  background-color: transparent;

  &:disabled {
    cursor: not-allowed;
  }
`;

export const TransactionButtonRental = styled(TransactionButtonBase)`
  margin-left: 20px;

  ${({active}) => !active && css`
    color: #777;
  `}
`;

export const TransactionButtonSale = styled(TransactionButtonBase)`
  ${({active}) => !active && css`
    color: #777;
  `}
`;

export const ItemFormWrapper = styled.div`
  flex: 1;
  background-color: #ffffff;
  padding: 4px 12px 2px;

  margin-bottom: 5px;
  margin-top: 24px;

  display: flex;
  align-items: center;

  border: 1px solid #777;

  .react-select__indicators {
    display: flex !important;
  }

  ${({ isRequired }) => isRequired && css`
    border-color: #dc3545;
  `}

  @media (min-width: 1200px) {
    &.city, &.bedrooms {
      margin-left: 5px;
    }

    & + div {
      margin-left: 5px;
    }
  }
`;

export const SelectWrapper = styled.div`
  flex: 1;

  > label {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.65);
  }
`;

export const ButtonGroup = styled.div`
  margin-top: 16px;

  label {
  color: #2b2b2b;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
  }
`;

export const ButtonsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 5px;

  display: flex;
`;

export const ButtonsListItem = styled.li`
  border: 1px solid #aeaeae;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;

  & + li {
    margin-left: 16px;
  }

  button {
    background-color: transparent;
    width: 36px;
    height: 36px;
    color: #aeaeae;
  }

  ${({active}) => active && css`
    border-color: #000000;

    button {
      color: #000000;
    }
  `}
`;

export const PriceRangeGroup = styled.div`
  padding: 0 10px;

  > label {
    color: #2b2b2b;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;
    margin-top: 16px;
    margin-left: -10px;
  }
`;

export const PriceInputsGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
`;

export const PriceInput = styled.div`
  width: 48%;
  label {
    display: block;
    margin-bottom: 8px;
  }

  input {
    background-color: transparent;
    border: 1px solid #000;
    height: 48px;
    width: 100%;
    padding: 0 16px;
  }
`;
