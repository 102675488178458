import styled, { css } from 'styled-components';
import Modal from 'react-modal';
import { Card as CardBootstrap } from 'react-bootstrap';
import {
  COLOR_2,
  COLOR_3,
  COLOR_6,
  COLOR_7,
  COLOR_1,
} from '../../../styled/variables/variables';

export const PendenciesWrapper = styled.div`
  margin: 0 auto;
  margin-top: 40px;
  border: 1px solid #EEF0F4;
  padding: 24px;
  border-radius: 8px;
  width: 100%;
  max-width: 900px;
`;

export const PendenciesHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 880px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const PendenciesHeaderInfo = styled.div`
  > h3 {
    font-size: 18px;
    font-weight: 600;
  }

  > p {
    font-size: 14px;
    margin-top: 8px;
    margin-bottom: 24px;
  }
`;

export const PendenciesFilter = styled.div`
  border: 1px solid #EEF0F4;
  border-radius: 8px;
  padding: 24px;
  margin-top: 24px;
  margin-bottom: 24px;

  > span {
    font-size: 18px;
    font-weight: 700;

    display: flex;
    align-items: center;

    margin-bottom: 16px;

    > svg {
      margin-right: 8px;
    }
  }
`;

const FilterComponentBase = styled.div`
  label {
    font-weight: 500;
    margin-right: 8px;
  }

  select {
    background-color: transparent;

    transition: background-color .2s;

    &:hover {
      background-color: #EEF0F4;
    }
  }
`;

export const OriginFilter = styled(FilterComponentBase)``;

export const StatusFilter = styled(FilterComponentBase)`
  margin-left: 24px;

  @media (max-width: 680px) {
    margin-left: 0;
  }
`;

export const PendenciesFilterGroup = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 680px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const PendenciesFilterActionButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  margin-top: 38px;

  @media (max-width: 680px) {
    flex-direction: column;
    align-items: unset;
    justify-content: unset;
  }
`;

const PendenciesFilterButtonBase = styled.button`
  padding: 8px;
  width: 40%;
  font-weight: 600;
  border-radius: 8px;

  transition: filter .2s;

  &:hover {
    filter: brightness(0.8);
  }

  @media (max-width: 680px) {
    width: 100%;

    & + button {
      margin-top: 16px;
    }
  }
`;

export const PendenciesFilterSubmitButton = styled(PendenciesFilterButtonBase)`
  background-color: #2B50D8;
  color: #FFFFFF;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
`;

export const PendenciesFilterClearButton = styled(PendenciesFilterButtonBase)`
  background-color: #D1CCC4;
`;

export const PendenciesDataEmptyText = styled.p`
  text-align: center;
`;

export const PendenciesModal = styled(Modal).attrs({
  style: {
    content: {
      backgroundColor: 'transparent',
      border: 0,
      padding: 0,
      inset: 0,
      borderRadius: 0,
      overflowY: 'hidden',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }
  }
})``;

export const PendenciesModalBody = styled.div`
  background: #FAFAFA;
  height: 80vh;
  width: 100vw;
  max-width: 600px;
  border-radius: 8px;
  border: 2px solid #EEF0F4;
  padding: 18px;
`;

export const PendenciesModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;

  h3 {
    font-size: 20px;
    font-weight: 700;
  }

  button {
    width: 42px;
    height: 42px;
    border: 1px solid #EEF0F4;
    border-radius: 8px;
    background-color: transparent;
    font-size: 0;
    margin-left: 16px;

    transition: background-color .2s;

    &:hover {
      background-color: #EEF0F4;
    }
  }
`;

export const PendenciesModalContent = styled.div`
  padding-top: 24px;
  padding-right: 16px;
  height: 68vh;
  overflow-y: scroll;
`;

export const Logs = styled.div`
  margin-bottom: 18px;

  label {
    display: block;
    font-size: 15px;
    margin-bottom: 8px;
    font-weight: 500;
    color: #666666;
  }
`;

export const Card = styled(CardBootstrap)`
  & + div {
    margin-top: 12px;
  }

  .card-header {
    background-color: #EEF0F4;
    cursor: pointer;

    transition: filter .2s;

    &:hover {
      filter: brightness(0.9);
    }
  }
`;

export const AccordionHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  @media (max-width: 760px) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
`;


export const AccordionHeaderList = styled.ul`
  list-style: none;
  padding-inline-start: unset;
  margin-bottom: unset;

  flex: 1;

  @media (max-width: 760px) {
    margin-top: 16px;
  }
`;

export const AccordionHeaderTagSituation = styled.span`
  width: 117px;
  height: 26px;
  background-color: ${COLOR_3};

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  font-weight: 500;
  color: ${COLOR_2};

  ${({ hasIntegrated }) => hasIntegrated && css`
  background-color: ${COLOR_1};
  `}

  ${({ hasNotIntegrated }) => hasNotIntegrated && css`
  background-color: #dc3545;
  `}

  ${({ hasPartiallyIntegrated }) => hasPartiallyIntegrated && css`
  background-color: #1EBEA5;
  `}
`;

export const AccordionHeaderListItem = styled.li`
  font-size: 16px;

  & + li {
    margin-top: 8px;
  }

  > span {
    font-weight: bold;
  }

  ${({ isTag }) => isTag && css`
      text-transform: capitalize;
  `}
`;

export const AccordionBodyLogContainer = styled.div`
  & + div {
    margin-top: 20px;
  }
`;

export const AccordionBodyTagOfLog = styled.span`
  display: block;
  padding: 4px 4px 4px 12px;
  border-radius: 4px 4px 0 0;
  font-size: 13px;
  font-weight: 500;
  color: ${COLOR_2};

  ${({ isWarning }) => isWarning && css`
    background-color: ${COLOR_6};
    `}

  ${({ isError }) => isError && css`
    background-color: ${COLOR_7};
  `}

  ${({ isSuccess }) => isSuccess && css`
    background-color: #008000;
  `}
`;

export const AccordionBodyLogDescription = styled.p`
  margin-bottom: 0;
  border-radius: 0 0 4px 4px;
  padding: 12px;

  ${({ isWarning }) => isWarning && css`
    background-color: rgba(246, 134, 31, 0.5);
    `}

  ${({ isError }) => isError && css`
    background-color: rgba(220, 53, 69, 0.5);
  `}

  ${({ isSuccess }) => isSuccess && css`
    background-color: rgba(0, 128, 0, 0.5);
  `}
`;
