
import BaseModel from '../base/base.model';

class SurroundingPlace extends BaseModel {
  constructor({
    id,

    category,
    categoryId,
    distance,
    formattedAddress,
    geolocation,
    name,
    subCategory,
    url,
  }) {
    super({ id });

    this.category = category;
    this.categoryId = categoryId;
    this.distance = distance;
    this.formattedAddress = formattedAddress;
    this.geolocation = geolocation;
    this.name = name;
    this.subCategory = subCategory;
    this.url = url;
  }
}

export default SurroundingPlace;
