import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import Select from 'react-select';
import { toast } from 'react-toastify';

import { Alert } from './components/Alert';
import { GrupoZap } from './components/portals/grupo-zap';
import { CasaMineira } from './components/portals/navent/casa-mineira';
import { ImovelWeb } from './components/portals/navent/imovel-web';

import userService from '../../../services/user/user.service';
import externalIntegrationService from '../../../services/koort-business/external-integration.service';

import eventsConstants from '../../../constants/events';
import domEventsUtil from '../../../utils/dom-events/dom-events.util';

import {
  Container,
  Title,
  StepsList,
  GroupSelect,
  Label,
} from './styles';

const customStyle = {
  control: base => ({
    ...base,
    border: '1px solid #999',
    boxShadow: 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: 16,
  }),
  input: (provided, state) => ({
    ...provided,
    fontSize: 25,
  }),
  clearIndicator: (prevStyle, state) => state.isMulti ? ({
    ...prevStyle,
    display: 'none'
  }) : null,
}

export function ExternalIntegration() {
  const [availablePortals, setAvailablePortals] = useState([]);
  const [portals, setPortals] = useState([]);
  const [portalsInUse, setPortalsInUse] = useState([]);
  const [integratedPortals, setIntegratedPortals] = useState([]);

  const [portalToBeRemoved, setPortalToBeRemoved] = useState(null);

  const [loadingPage, setLoadingPage] = useState(true);
  const [hasPageError, setHasPageError] = useState(false);


  useEffect(() => {
    async function loadData() {
      try {
        const user = userService.retrieve();

        const { data, status } = await externalIntegrationService.getAllDataExternalIntegration({
          token: user.token,
        })

        if (status !== 200) {
          throw new Error('Não foi possível buscar as informações.');
        }

        const { integrator, portals, portalsInUse: portalsInUseResponse } = data;

        const availablePortalsFormatted = portals.map(item => {
          return {
            value: item,
            label: item,
          }
        });

        setPortalsInUse(portalsInUseResponse);
        setAvailablePortals(availablePortalsFormatted);
        setIntegratedPortals(integrator.integratedPortals);

        if (portalsInUseResponse) {
          const portalsInUseFormatted = portalsInUseResponse.map(item => {
            return {
              value: item,
              label: item,
            }
          });

          setPortals(portalsInUseFormatted);
        }
      } catch {
        setHasPageError(true);
      } finally {
        setLoadingPage(false);
      }
    }

    loadData();
  }, []);

  async function handleRemoveIntegration() {
    const isRemoteSaved = portalsInUse.includes(portalToBeRemoved);

    if (isRemoteSaved) {
      try {
        domEventsUtil.dispatch({
          name: eventsConstants.LOADING_ANIMATION,
          params: { show: true },
        });

        const user = userService.retrieve();

        const { status } = await externalIntegrationService.removePortalIntegration({
          portal: portalToBeRemoved,
          token: user.token,
        });

        if (status !== 204) {
          throw new Error('Não foi possível remover a integração, tente novamente!');
        }

        toast.success('A integração foi removida com sucesso!');

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } catch {
        toast.success('Não foi possível remover a integração, tente novamente!');
      } finally {
        domEventsUtil.dispatch({
          name: eventsConstants.LOADING_ANIMATION,
          params: { show: false },
        });
      }
    } else {
      toast.success('A integração foi removida com sucesso!');

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  }

  return (
    <Container fluid="md">
      {loadingPage &&
        <Row>
          <Alert
            title="Carregando..."
            description="Aguarde enquanto buscamos as informações."
          />
        </Row>
      }

      {hasPageError &&
        <Row>
          <Alert
            title="Ops..."
            description="Ocorreu um erro ao buscar as informações, recarregue a página ou tente novamente mais tarde."
          />
        </Row>
      }

      {!loadingPage && !hasPageError && (
        <>
          <Row>
            <Col xs={10} md={12}>
              <Title>Integração com Terceiros</Title>

              <StepsList>
                <li>
                  <h6 className={"text_p_2"}>
                    Selecione o portal que deseja realizar a integração;
                  </h6>
                </li>
                <li>
                  <h6 className={"text_p_2"}>
                    Ao clicar no botão 'Gerenciar imóveis', você será redirecionado para a dashboard onde executará as ações;
                  </h6>
                </li>
                <li>
                  <h6 className={"text_p_2"}>
                    Ao clicar no botão 'Excluir integração', você perderá o seu arquivo XML com os imóveis integrados.
                  </h6>
                </li>
              </StepsList>

              <GroupSelect>
                <Label>Selecione os portais para integração</Label>
                <Select
                  className="react-select-container"
                  classNamePrefix="react-select"
                  onChange={setPortals}
                  options={availablePortals}
                  defaultValue={portals}
                  isMulti
                  clearable
                  placeholder="Selecione"
                  noOptionsMessage={() => 'Sem opções'}
                  styles={customStyle}
                  theme={theme => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary: 'none',
                    },
                  })}
                />
              </GroupSelect>

              {!!portals && (
                <Col>
                  {portals.map((item) => {
                    switch (item.value) {
                      case 'grupo-zap':
                        return (
                          <GrupoZap
                            key={item.value}
                            data={item}
                            integratedPortal={integratedPortals.filter(item => item.portal === 'grupo-zap')[0] || null}
                            portalToBeRemoved={portalToBeRemoved}
                            onRemoveIntegration={handleRemoveIntegration}
                            setPortalToBeRemoved={(portal) => setPortalToBeRemoved(portal)}
                          />
                        );

                      case 'casa-mineira':
                        return (
                          <CasaMineira
                            key={item.value}
                            data={item}
                            integratedPortal={integratedPortals.filter(item => item.portal === 'casa-mineira')[0] || null}
                            portalToBeRemoved={portalToBeRemoved}
                            onRemoveIntegration={handleRemoveIntegration}
                            setPortalToBeRemoved={(portal) => setPortalToBeRemoved(portal)}
                          />
                        );

                      case 'imovelweb':
                        return (
                          <ImovelWeb
                            key={item.value}
                            data={item}
                            integratedPortal={integratedPortals.filter(item => item.portal === 'imovelweb')[0] || null}
                            portalToBeRemoved={portalToBeRemoved}
                            onRemoveIntegration={handleRemoveIntegration}
                            setPortalToBeRemoved={(portal) => setPortalToBeRemoved(portal)}
                          />
                        );

                      default:
                        break;
                    }
                  })}
                </Col>
              )}
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
}
