
import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';
import propTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Search } from 'styled-icons/octicons/Search';


import { BaseButton } from '../../../styled/buttons/buttons';
import { COLOR_2, COLOR_13 } from '../../../styled/variables/variables';
import { mqTablet, mqCustom } from '../../../styled/helpers/helpers';

import Drawer from './drawer/Drawer';
import Menu from '../menu/Menu';

import userService from '../../../services/user/user.service';

import domEventsUtil from '../../../utils/dom-events/dom-events.util';
import eventsConstants from '../../../constants/events';
import pathsConstants from '../../../constants/paths';

class Navbar extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
      user: userService.retrieve(),
      userIsSubscriber: false,
      showSearchButton: false,
    };

    domEventsUtil.subscribe({
      name: eventsConstants.AUTH_STATUS_CHANGED,
      callback: this.handleAuthStatusChanged,
    });
  }

  loadUserProfile = async () => {
    try {
      const data = await userService.getUserProfile({
        token: this.state.user.token
      });

      this.setState(prevState => ({
        ...prevState,
        user: {
          ...data,
          token: this.state.user.token,
        },
        userIsSubscriber: data.isSubscriber,
      }));
    } catch (err) {
      console.log(err)
    }
  }

  scrollListener = () => {
    if (window.scrollY > 300) {
      this.setState(prevState => ({
        ...prevState,
        showSearchButton: true,
      }));
    } else {
      this.setState(prevState => ({
        ...prevState,
        showSearchButton: false,
      }));
    }
  };

  componentDidMount() {
    if (this.state.user) {
      this.loadUserProfile();
    }

    if (window.location.pathname !== pathsConstants.HOME) {
      this.setState(prevState => ({
        ...prevState,
        showSearchButton: true,
      }));
    } else {
      window.addEventListener('scroll', this.scrollListener);
    }
  }

  componentWillUnmount() {
    domEventsUtil.unsubscribe({
      name: eventsConstants.AUTH_STATUS_CHANGED,
      callback: this.handleAuthStatusChanged,
    });

    if (window.location.pathname === pathsConstants.HOME) {
      window.removeEventListener('scroll', this.scrollListener);
    }
  }

  handleAuthStatusChanged = event => {
    const { auth } = event.detail.params;
    this.setState({
      ...this.state,
      user: auth ? userService.retrieve() : null,
    });
  }

  controlShowMenuStatus = (showMenu = !this.state.showMenu) => {
    this.setState({
      ...this.state,
      showMenu,
    });

    this.props.openMenu();
  };

  handleMenuItemClick = path => {
    const { history } = this.props;

    if (path.includes('profile')) {
      history.push(path);
      window.location.reload();
    } else if (path && !history.location.pathname.includes(path) || path === "/") {
      history.push(path);
    }

    this.controlShowMenuStatus(false);
  };

  handleOutsideMenuClick = () => {
    this.controlShowMenuStatus(false);
    document.getElementById('menu-nav-item-list').scrollTop = 0;
  };

  handleToSearch = () => {
    if (window.location.pathname === '/') {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;

      return;
    }

    if (window.location.pathname !== '/') {
      window.open('/', '_self');
    }
  }

  render() {
    const { scrolled, isReleasePage } = this.props;
    const { user, showMenu, userIsSubscriber, showSearchButton } = this.state;

    return (
      <Wrapper>
        {!isReleasePage && (
          <>
            {window.location.pathname !== pathsConstants.SEARCH && showSearchButton && <SearchButton type="button" onClick={this.handleToSearch} />}

            <AdCreationButton
              scrolled={scrolled}
              onClick={() => {
                const { history } = this.props;

                if (userIsSubscriber) {
                  history.push(pathsConstants.DASHBOARD_ADS);
                } else {
                  history.push(pathsConstants.PURCHASE_CHOOSE_TYPE);
                }
              }}
            >
              {userIsSubscriber ? 'cadastrar imóvel' : 'anunciar'}
            </AdCreationButton>
          </>
        )}
        <Drawer
          highlight={showMenu}
          onClick={() => this.controlShowMenuStatus()}
          scrolled={scrolled}
        />
        <Menu
          onItemClick={this.handleMenuItemClick}
          onOutsideClick={this.handleOutsideMenuClick}
          show={showMenu}
          user={user}
        />
      </Wrapper>
    );
  }
}

Navbar.propTypes = {
  scrolled: propTypes.bool,
};

const SearchButton = styled(Search)`
  background-color: #f6861f;
  margin-left: 20px;
  width: 40px;
  height: 38px;
  align-self: center;
  margin-right: 12px;
  padding: 8px;
  color: #FFFFFF;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }
`;

const Wrapper = styled.div`
  align-self: flex-end;
  display: flex;
`;

const AdCreationButton = styled(BaseButton)`
  height: 38px;
  line-height: 38px;
  align-self: center;
  text-transform: uppercase;
  display: none;
  background-color: ${COLOR_13};
  color: ${COLOR_2};
  border: none;
  font-weight: 700;
  min-width: 140px;

  @media (min-width: 1080px) {
    ${({ scrolled }) => scrolled && css`
      line-height: unset;
      height: unset;
      padding-top: 4px;
      padding-bottom: 4px;
    `}
  }

  @media (min-width: 1376px) {
    height: 38px;
  }

  ${mqTablet`
    display: unset;
  `};

  ${mqCustom(250)`
    display: unset;
  `}

@media (max-width: 400px) {
  margin-right: -6px;
  }
`;

export default withRouter(Navbar);
