
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import propTypes from 'prop-types';

import { COLOR_1, COLOR_2, COLOR_4 } from '../../../styled/variables/variables';
import { backgrounder, mqTablet } from '../../../styled/helpers/helpers';
import { BaseLabel } from '../../../styled/labels/labels';
import RatingStars from '../../../components/rating-stars';
import { ProfilePicture } from '../../../styled/components/components';
import { BaseButton } from '../../../styled/buttons/buttons';
import pathConstants from '../../../constants/paths';
import queryStringUtil from '../../../utils/query-string/query-string.util';

/*
  global
  process
  window
*/

const { PUBLIC_URL } = process.env;

const Cover = ({
  name,
  pictureUrl,
  id,
  history,
  userType,
}) => (
  <Wrapper>
    <ContentWrapper>
      <NameWrapper>
        <Name>{name}</Name>
        {/* <CRatingStars rating={ rating } /> */}
      </NameWrapper>
      <CProfilePicture pictureUrl={pictureUrl} />
      {/* <OpenTour
        onClick={() => {initTour()}}
      >
        iniciar tutorial
      </OpenTour> */}
      {userType > 2 &&
        <OpenProfileButton
          to={{
            pathname: pathConstants.PROFILE,
            search: queryStringUtil.stringify(history, {
              id,
            })
          }}
        >
          ver perfil
        </OpenProfileButton>
      }
    </ContentWrapper>
  </Wrapper>
);

Cover.propTypes = {
  id: propTypes.string.isRequired,
  name: propTypes.string,
  pictureUrl: propTypes.string,
  rating: propTypes.number,
  history: propTypes.object,
};

const Wrapper = styled.div`
  background-color: ${COLOR_1};
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  padding-top: 120px;
  margin-bottom: 170px;

  * { position: relative; }
  &:before {
    content: "";
    ${backgrounder(`${PUBLIC_URL}/imgs/koort-texture-outline.svg`)};
    background-size: 800px;
    height: 100%;
    width: 100%;
    opacity: .3;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
  }

  ${mqTablet`
    margin-bottom: 90px;

    &:before {
      background-size: 600px;
      background-repeat: repeat;
    }
  ` }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${mqTablet`
    flex-direction: row;
    width: 100%;
    max-width: 1000px;
    align-self: center;
    margin-top: 40px;
    padding: 0 10px;
  ` }
`;

const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;

  ${mqTablet`
    flex-grow: 1;
    width: 1px;
    order: 2;
    align-self: flex-start;
    margin-top: 10px;
  ` }
`;

const Name = styled(BaseLabel)`
  color: ${COLOR_2};
  align-self: center;
  font-weight: 700;
  margin-bottom: 10px;

  ${mqTablet`
    align-self: flex-start;
    font-size: 24px;
  ` }
`;

const CRatingStars = styled(RatingStars)`
  align-self: center;
  margin-bottom: 30px;

  ${mqTablet`
    align-self: flex-start;
  ` }
`;

const CProfilePicture = styled(ProfilePicture)`
  align-self: center;
  /* min-height: 140px; */
  margin-bottom: 20px;

  ${mqTablet`
    order: 1;
    margin-right: 20px;
  ` }
`;

const OpenTour = styled(BaseButton)`
  background-color: ${COLOR_4};
  color: ${COLOR_2};
  font-weight: 700;
  align-self: center;
  width: fit-content;
  height: 30px;
  line-height: 30px;

  ${mqTablet`
    order: 3;
    align-self: flex-start;
    margin-top: 45px;
  ` }
`


const OpenProfileButton = styled(BaseButton).attrs({
  as: Link,
})`
  background-color: ${COLOR_4};
  color: ${COLOR_2};
  font-weight: 700;
  align-self: center;
  width: fit-content;
  height: 30px;
  line-height: 30px;

  ${mqTablet`
    order: 3;
    align-self: flex-start;
    margin-top: 45px;
  ` }
`;

export default withRouter(Cover);
