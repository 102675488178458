import styled from 'styled-components';

export const Container = styled.div`
  width: 320px;
  height: 100%;
  border: 1px solid #e2e2e2;
	box-shadow: 0 2px 2px -1px #aaa;
  background: #ffffff;

  position: relative;

  @media (max-width: 1040px) {
    width: 280px;
  }

  @media (max-width: 910px) {
    width: 260px;
  }

  @media (max-width: 852px) {
    width: 232px;
  }

  @media (max-width: 767px) {
    width: 200px;
  }

  @media (max-width: 671px) {
    width: 300px;
  }

  @media (max-width: 660px) {
    width: 280px;
  }

  @media (max-width: 620px) {
    width: 260px;
  }

  @media (max-width: 580px) {
    width: 240px;
  }

  @media (max-width: 540px) {
    width: 220px;
  }

  @media (max-width: 500px) {
    width: 200px;
  }

  @media (max-width: 460px) {
    width: 100%;
  }

  @media (max-width: 380px) {
    width: 320px;
  }

  @media (max-width: 370px) {
    width: 280px;
  }
`;

export const ProfessionalTypeBadge = styled.span`
  position: absolute;
  background: #3578E5;
  color: #ffffff;
  padding: 4px 8px;
  font-weight: 500;
  font-size: 14px;
`;

export const ImageContent = styled.div`
  height: 230px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const InfoContent = styled.div`
  padding: 16px 16px 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    text-decoration: none;
    background-color: #3578E5;
    padding: 5px 12px;
    width: 100%;
    max-width: 98px;

    font-size: 16px;
    font-weight: 500;
    color: #ffffff;

    text-align: center;

    transition: filter .2s;

    &:hover {
      color: #ffffff;
      filter: brightness(0.9);
    }
  }

  @media (max-width: 1040px) {
    flex-direction: column;

    a {
      margin-top: 12px;
    }
  }
`;

export const RealtorNameContent = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  margin-right: 12px;

  strong {
    font-size: 18px;
    font-weight: 600;

  }

  span {
    display: block;
    opacity: 0.5;

    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (max-width: 1040px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const ContactContent = styled.div`
  margin-top: 32px;
  padding: 0 16px 16px;

  a {
    font-size: 15px;
    color: inherit;
    text-decoration: none;

    transition: opacity .2s;

    & + a {
      margin-top: 12px;
    }

    &:hover {
      color: inherit;
      opacity: 0.8;
    }
  }
`;

export const WhatsAppContent = styled.a`
  display: block;

  > svg {
    margin-right: 12px;
  }
`;

export const EmailContent = styled.a`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  > svg {
    margin-right: 12px;
  }
`;

export const TelephoneContent = styled.a`
  display: block;

  > svg {
    margin-right: 12px;
  }
`;
