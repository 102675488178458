import React, { PureComponent } from "react";
import propTypes from "prop-types";
import styled from "styled-components";
import { toast } from "react-toastify";
import "./modal.css";

import { BaseButton } from "../../../styled/buttons/buttons";
import { BaseLabel } from "../../../styled/labels/labels";
import { COLOR_2, COLOR_4, COLOR_1 } from "../../../styled/variables/variables";
import { mqTablet, mqDesktop, mqCustom } from "../../../styled/helpers/helpers";

import Checkbox from "../../../components/checkbox";
import AdditionalInfo from "./additional-info/AdditionalInfo";
import CommonAreaFeatures from "./common-area-features/CommonAreaFeatures";
import CreatorInfo from "./creator-info/CreatorInfo";
import Description from "../../purchase-flow/builder2/description/description";
import Features from "./features/Features";
import FloorPlans from "./floor-plans/FloorPlans";
import Images from "./images/Images";
import Title from "./title/Title";
import Video from "./video/Video";
import Modal from "react-modal";
import Panoramas from "./panoramas/Panoramas";

import domEventsUtil from "../../../utils/dom-events/dom-events.util";
import eventConstants from "../../../constants/events";
import objectUtil from "../../../utils/object/object.util";
import pathsConstants from "../../../constants/paths";
import queryStringUtil from "../../../utils/query-string/query-string.util";
import realEstateService from "../../../services/real-estate/real-estate.service";
import planService from "../../../services/plan/plan.service";
import SurroundingFeatures from "./surrounding-features/SurroundingFeatures";

/*
  global
  alert
  localStorage
*/

const customId = "custom-id-yes";
const { PUBLIC_URL } = process.env;

class Builder2 extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      vlImovel: 0,
      vlKoort: 0,
      vlKoortMin: 0,
      vlKoortMax: 0,
      mostrarKoort: true,
      modalOpen: false,
      showInvalids: false,
      showTypeOfGuarantees: false,
      infoAd: {},
      imagesHasUploaded: false,
    };
  }

  handleFormSubmit = async (event) => {
    event.preventDefault();
    const data = {
      // ...this.creatorInfoRef.state.form,
      ...this.floorPlansRef.state.form,
      ...this.imagesRef.state.form,
      ...this.panoramaRef.state.form,
      ...this.titleRef.state.form,
      ...this.descriptionRef.state.form,
      ...this.videoRef.state.form,
      guarantees: this.additionalInfoRef.state.guarantees,
      features: {
        property: this.featuresRef.state.form.features,
        condo: this.commonAreaFeaturesRef.state.form.features,
        near: this.surroundingFeaturesRef.state.form.features,
      },
    };

    const validationErrors = [];
    const optionalFields = [
      "additionalInfo",
      "apartmentsPerFloor",
      "cellphoneNumber",
      "condo",
      "constructionYear",
      "description",
      "features",
      "floor",
      "floorPlans",
      "images",
      "lastReformYear",
      "panoramas",
      "phoneNumber",
      "videoUrl",
    ];

    if (this.state.infoAd.transaction !== "Locação") {
      optionalFields.push("guarantees");
    }

    objectUtil.keys(data).forEach((key) => {
      if (optionalFields.find((f) => f === key)) return;

      if (data.description.length > 2000) {
        toast.info("O campo descrição não pode ultrapassar 2000 caracteres.", {
          autoClose: false,
          toastId: customId,
        });
        return;
      }

      if (
        data[key] === null ||
        data[key] === undefined ||
        data[key] === "" ||
        data[key].length === 0
      ) {
        validationErrors.push(`${key} cannot be empty.`);
      }
    });

    if (
      data.floorPlans.length + data.images.length + data.panoramas.length <
      3
    ) {
      return toast.error(
        "Você deve inserir pelos menos 3 imagens para finalizar a criação de seu anúncio",
        {
          autoClose: false,
          toastId: customId,
        }
      );
    }

    if (validationErrors.length > 0) {
      toast.error(
        "Os campos obrigatórios foram destacados. Por favor, verifique-os.",
        {
          autoClose: false,
          toastId: customId,
        }
      );

      this.setState((state) => ({
        ...state,
        showInvalids: true,
      }));
      return;
    }

    domEventsUtil.dispatch({
      name: eventConstants.LOADING_ANIMATION,
      params: { show: true },
    });

    const { user, history } = this.props;
    const { paymentMethod } = queryStringUtil.parse(history);

    if (paymentMethod === 0 || paymentMethod === "invoice") {
      try {
        const mergeAdInfos = {
          ...this.state.infoAd,
          ...data,
        };
        await this.setState({ infoAd: mergeAdInfos });
        if (!user || !user.token) {
          history.push(
            `${pathsConstants.AUTHENTICATION}/${pathsConstants.AUTHENTICATION_PARAMS.LOGIN}`
          );
        }
      } catch (error) {
        toast.error("Erro criando anúncio.", {
          autoClose: false,
          toastId: customId,
        });
        domEventsUtil.dispatch({
          name: eventConstants.LOADING_ANIMATION,
          params: { show: false },
        });
        throw error;
      }

      if (data.images.length > 0) {
        try {
          if (!user || !user.token) {
            history.push(
              `${pathsConstants.AUTHENTICATION}/${pathsConstants.AUTHENTICATION_PARAMS.LOGIN}`
            );
          } else {
            if (!this.state.imagesHasUploaded) {
              await realEstateService.uploadImages({
                pictureBlobs: data.images.map(({ blob }) => blob),
                floorPlanBlobs: data.floorPlans.map(({ blob }) => blob),
                panoramaBlobs: data.panoramas.map(({ blob }) => blob),
                realEstateId: localStorage.getItem("adId"),
                token: user.token,
              });

              this.setState((prevState) => ({
                ...prevState,
                imagesHasUploaded: true,
              }));
            }
          }
        } catch (error) {
          toast.error("Erro enviando imagens.", {
            autoClose: false,
            toastId: customId,
          });
          domEventsUtil.dispatch({
            name: eventConstants.LOADING_ANIMATION,
            params: { show: false },
          });
          throw error;
        }
      }
    } else {
      let paymentInfo = localStorage.getItem("paymentInfo");
      paymentInfo = JSON.parse(paymentInfo);
      await planService
        .buyBankSlip({
          token: user.token,
          data: paymentInfo,
        })
        .then((response) => {
          return;
          // window.open(response.data.paymentResponse.Payment.Url);
          // history.push({
          //   pathname: pathsConstants.PURCHASE_FLOW_BUILDER2,
          //   search: queryStringUtil.stringify(history, {
          //     paymentMethod: '0',
          //   }),
          // });
        });
      try {
        const mergeAdInfos = {
          ...this.state.infoAd,
          ...data,
        };
        await this.setState({ infoAd: mergeAdInfos });

        if (!user || !user.token) {
          history.push(
            `${pathsConstants.AUTHENTICATION}/${pathsConstants.AUTHENTICATION_PARAMS.LOGIN}`
          );
        }
      } catch (error) {
        toast.error("Erro criando anúncio.", {
          autoClose: false,
          toastId: customId,
        });
        domEventsUtil.dispatch({
          name: eventConstants.LOADING_ANIMATION,
          params: { show: false },
        });
        throw error;
      }

      if (data.images.length > 0) {
        try {
          if (!user || !user.token) {
            history.push(
              `${pathsConstants.AUTHENTICATION}/${pathsConstants.AUTHENTICATION_PARAMS.LOGIN}`
            );
          } else {
            if (!this.state.imagesHasUploaded) {
              await realEstateService.uploadImages({
                pictureBlobs: data.images.map(({ blob }) => blob),
                floorPlanBlobs: data.floorPlans.map(({ blob }) => blob),
                panoramaBlobs: data.panoramas.map(({ blob }) => blob),
                realEstateId: localStorage.getItem("adId"),
                token: user.token,
              });

              this.setState((prevState) => ({
                ...prevState,
                imagesHasUploaded: true,
              }));
            }
          }
        } catch (error) {
          toast.error("Erro enviando imagens.", {
            autoClose: false,
            toastId: customId,
          });
          domEventsUtil.dispatch({
            name: eventConstants.LOADING_ANIMATION,
            params: { show: false },
          });
          throw error;
        }
      }
    }

    domEventsUtil.dispatch({
      name: eventConstants.LOADING_ANIMATION,
      params: { show: false },
    });

    if (!user || !user.token) {
      history.push(
        `${pathsConstants.AUTHENTICATION}/${pathsConstants.AUTHENTICATION_PARAMS.LOGIN}`
      );
    } else {
      const rr = await realEstateService.createRealStateStep3({
        adId: localStorage.getItem("adId"),
        data: data,
        user: user,
      });

      if (rr.status == 200) {
        let vl = rr.data.ads.prices.main;
        let vlKoort = rr.data.ads.koortimativa.value;
        let vlKoortMin = rr.data.ads.koortimativa.range[0];
        let vlKoortMax = rr.data.ads.koortimativa.range[1];
        this.setState((state) => ({
          vlImovel: vl,
          vlKoort: vlKoort,
          vlKoortMin: vlKoortMin,
          vlKoortMax: vlKoortMax,
        }));
        //COLOCAR AQUI O MODAL
        // Função para abrir o modal koortimativa
        // this.openModal()

        // Função responsável por pular para etapa 4
        history.push({
          pathname: pathsConstants.PURCHASE_FLOW_CONGRATULATIONS,
          search: queryStringUtil.stringify(history, {
            realEstateId: localStorage.getItem("adId"),
            finishedAd: true,
          }),
        });
      } else {
        toast.error("Erro criando anúncio. \n" + rr.statusText, {
          autoClose: false,
          toastId: customId,
        });
        domEventsUtil.dispatch({
          name: eventConstants.LOADING_ANIMATION,
          params: { show: false },
        });
      }
    }
  };

  // Função chamada pelo modal koortimativa
  // openModal = () => {
  //   this.setState(state => ({ modalOpen: !state.modalOpen }));
  // }

  // Função chamada pelo modal koortimativa
  // closeModal = async () => {
  //   const { user, history } = this.props;

  //   const rr = await realEstateService.changeAdsKoortimativa({
  //     adId: localStorage.getItem('adId'),
  //     data: this.state.mostrarKoort,
  //     user: user,
  //   });

  //   this.setState(state => ({ modalOpen: !state.modalOpen }));

  //   history.push({
  //     pathname: pathsConstants.PURCHASE_FLOW_CONGRATULATIONS,
  //     search: queryStringUtil.stringify(history, {
  //       realEstateId: localStorage.getItem('adId'),
  //       finishedAd: true,
  //     }),
  //   });
  // }

  // Função chamada pelo modal koortimativa
  // changeMostrarKoort = () => {
  //   this.setState(state => ({ mostrarKoort: !state.mostrarKoort }));
  // }

  componentDidMount() {
    let data = localStorage.getItem("infoAd");
    //this.saveStep2();
    data = JSON.parse(data);
    this.setState({ infoAd: data, mostrarKoort: true, modalOpen: false });
  }

  render() {
    Modal.setAppElement("#root");
    const { showInvalids, mostrarKoort } = this.state;
    let showTypeOfGuarantees = this.state.infoAd.transaction == "Locação";
    const data = this.state;

    const customStyles = {
      content: {
        background: "#000",
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        height: "calc(100% - 50px)",
        width: "calc(100% - 20px)",
        maxWidth: "665px",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
      },
    };

    return (
      <Wrapper onSubmit={this.handleFormSubmit}>
        <SectionTitle>Informações do anúncio</SectionTitle>
        <Title
          ref={(ref) => {
            this.titleRef = ref;
          }}
          title={data.title ? data.title.title : ""}
          showInvalids={showInvalids}
        />
        <Description
          ref={(ref) => {
            this.descriptionRef = ref;
          }}
          description={data.description ? data.description.description : ""}
        />

        <SectionTitle>Fotos do anúncio</SectionTitle>
        <Images
          ref={(ref) => {
            this.imagesRef = ref;
          }}
        />

        <SectionTitle>Panorama</SectionTitle>
        <Panoramas
          ref={(ref) => {
            this.panoramaRef = ref;
          }}
        />
        <SectionTitle>Planta baixa</SectionTitle>
        <FloorPlans
          ref={(ref) => {
            this.floorPlansRef = ref;
          }}
        />
        <SectionTitle>Vídeo</SectionTitle>
        <Video
          ref={(ref) => {
            this.videoRef = ref;
          }}
          video={data.video ? data.video.videoUrl : ""}
        />

        <AdditionalInfo
          ref={(ref) => {
            this.additionalInfoRef = ref;
          }}
          showInvalids={showInvalids}
          showTypeOfGuarantees={showTypeOfGuarantees}
          floor={data.additional ? data.additional.floor : ""}
          yearBuilt={data.additional ? data.additional.yearBuilt : ""}
          buildings={data.additional ? data.additional.buildings : ""}
        />

        <SectionTitle>Detalhamento do imóvel</SectionTitle>
        <Features
          ref={(ref) => {
            this.featuresRef = ref;
          }}
          features={data.features ? data.features.property : []}
        />
        <SectionTitle>Áreas comuns</SectionTitle>
        <CommonAreaFeatures
          ref={(ref) => {
            this.commonAreaFeaturesRef = ref;
          }}
        />
        <SectionTitle>Entorno</SectionTitle>
        <SurroundingFeatures
          ref={(ref) => {
            this.surroundingFeaturesRef = ref;
          }}
        />
        <ButtonWrapper>
          <SaveButton>criar anúncio</SaveButton>
        </ButtonWrapper>

        {/* Removendo koortimativa */}
        {/* <>
          <Modal
            isOpen={this.state.modalOpen}
            // onAfterOpen={this.afterOpenModal}
            // onRequestClose={this.closeModal}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <div className={"container-modal"}>
              <div className={"txt-modal"}>
                <p>A <b className={"txt-koort"}>KOORT</b><b>imativa</b> é uma ferramenta de inteligência</p>
                <p>artificial elaborada para estimar o preço de um imóvel.</p>
                <br></br>
                <p>Abaixo você pode ver o PREÇO DE VENDA que você</p>
                <p>está sugerido para seu imóvel e o valor estimado</p>
                <p>pela nossa ferramenta <b className={"txt-koort"}>KOORT</b><b>imativa</b>, com os</p>
                <p>respectivos valores máximo e mínimo.</p>
              </div>
              <div className={"container-preco"}>
                <div className={"preco"}>
                  <div className={"lb-preco"}>VALOR DE VENDA</div>
                  <div className={"txt-preco"}>{this.state.vlImovel.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</div>
                </div>
                <div className={"container-preco-sugerido"}>
                  <div className={"preco-sugerido"}>
                    <div className={"title_box"} id="bill_to">
                      <div id="title">(MÍN)</div>
                      <div id="content">{this.state.vlKoortMin.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</div>
                    </div>
                  </div>
                  <div className={"preco-sugerido"}>
                    <img className={"logo"} src={`${PUBLIC_URL}/imgs/koort-marker-orange-outline.svg`}></img>
                    <img className={"logo-txt"} src={`${PUBLIC_URL}/imgs/koortimativa-text-orange-white.png`}></img>
                    <span>{this.state.vlKoort.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>
                  </div>
                  <div className={"preco-sugerido"}>
                    <div className={"title_box title_box_max"} id="bill_to">
                      <div id="title">(MAX)</div>
                      <div id="content">{this.state.vlKoortMax.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</div>
                    </div>
                  </div>
                </div>
              </div>
              <br></br>
              <div>
                <MostrarKoort
                  id='mostrarKoort'
                  name='mostrarKoort'
                  checked={mostrarKoort}
                  onClick={this.changeMostrarKoort}
                />
                <div className={"container-txt-check"}>Mostrar <b>KOORT</b><span>imativa</span> no anúncio</div>
                <br></br>
                <br></br>
                <div className={"txt-modal"}>
                  <p>Esta e outras configurações do anúncio podem ser</p>
                  <p>alteradas a qualquer momento me seu dashboard de</p>
                  <p>usuário.</p>
                </div>
                <div className={"container-button"}>
                  <SaveModal onClick={this.closeModal}>
                    OK
                  </SaveModal>
                </div>
              </div>
            </div>
          </Modal>
        </> */}
      </Wrapper>
    );
  }
}

Builder2.propTypes = {
  history: propTypes.object,
  user: propTypes.object,
};

Builder2.defaultProps = {};

const Wrapper = styled.form`
  ${mqTablet`
    width: 100%;
    max-width: 500px;
    align-self: flex-start;
    display: flex;
    flex-direction: column;
  `}
`;

const SectionTitle = styled(BaseLabel)`
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 10px;
`;

const MapWrapper = styled.div`
  ${mqTablet`
    width:740px;
    max-width:800px;
    heigth:800px;
    align-self:flex-start;
    display:flex;
    flex:1;
    overflow:visible;
  `}
  ${mqDesktop`
    width:1200px;
    max-width:1500px;
    heigth:800px;
  `}
`;

const MapContainer = styled.div`
  ${mqCustom(340)`
    height:300px;
    width: 300px;
    margin-left:60px;
    margin-bottom:25px;
    align-self:center;
  `}
  ${mqTablet`
      height:350px;
      width: 350px;
      margin-left:50px;
      margin-bottom:20px;
      align-self:center;
  `}
    ${mqDesktop`
      height:350px;
      width: 400px;
      margin-left:100px;
      margin-bottom:20px;
      align-self:center;
  `}
`;

const ButtonWrapper = styled.div`
  display: flex;

  ${mqTablet`
    align-self: center;
    width: 400px;
  `}
`;

const VisualizeButton = styled(BaseButton)`
  color: ${COLOR_2};
  background-color: ${COLOR_4};
  flex-grow: 1;
  width: 1px;
  border: none;
`;

const SaveButton = styled(VisualizeButton).attrs({
  type: "submit",
})`
  background-color: ${COLOR_1};
`;

const SaveModal = styled(VisualizeButton).attrs({
  type: "submit",
})`
  background-color: ${COLOR_1};
  width: 150px;
`;

const MostrarKoort = styled(Checkbox)`
  float: left;
`;

export default Builder2;
