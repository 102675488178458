import * as yup from 'yup';

const formSchema = yup.object().shape({
  title: yup.string()
    .required('Este campo é obrigatório')
    .min(5, 'Mínimo de 5 caracteres')
    .max(50, 'Máximo de 50 caracteres'),
  description: yup.string()
    .required('Este campo é obrigatório')
    .min(10, 'Mínimo de 10 caracteres')
    .max(2000, 'Máximo de 2000 caracteres'),
});

export { formSchema };
