export const AUTHENTICATION = '/authentication';
export const AUTHENTICATION_PARAMS = {
  LOGIN: 'login',
  SIGNUP: 'signup',
  PASSWORD_RECOVERY: 'password-recovery',
  PASSWORD_RECOVERY_CONFIRM: 'password-recovery-confirm',
  EMAIL_CONFIRM: 'email-confirm',
  NEW_PASSWORD: 'new-password',
  SUCCESS: 'success'
};

export const ABOUT_US = '/quem-somos';
export const CONTACT = '/contato';
export const KOORTIMATIVA = '/koortimativa';
export const DASHBOARD = '/dashboard';
export const DASHBOARD_ADS = `${DASHBOARD}/ads`;
export const DASHBOARD_MY_BROKERS = `${DASHBOARD}/my-brokers`;
export const DASHBOARD_BROKERS_ATTACH_PROPERTY = `/dashboard-brokers/attach-property/all-property`;
export const DASHBOARD_BROKERS_ATTACH_PROPERTY_LINKED = `/dashboard-brokers/attach-property/all-property/linked`;
export const DASHBOARD_LEADS = `/dashboard-leads`;
export const DASHBOARD_LEADS_VIEW_LEAD = `/dashboard-leads/lead/:leadId`;
export const DASHBOARD_LEADS_COMPANY_BROKER = `/dashboard-leads/company-broker`;
export const DASHBOARD_PLAN = `${DASHBOARD}/plan`;
export const DASHBOARD_RECOMMENDATIONS = `${DASHBOARD}/recommendations`;
export const DASHBOARD_REPORTS = `${DASHBOARD}/reports`
export const DASHBOARD_REQUESTS = `${DASHBOARD}/requests`;
export const DASHBOARD_REVIEWS = `${DASHBOARD}/reviews`;
export const FAVORITES = '/favorites';
export const FAVORITES_MY_FAVORITES = `${FAVORITES}/my-favorites`;
export const FAVORITES_PREFERENCES = `${FAVORITES}/preferences`;
export const DASHBOARDADS = '/dashboard-ads';
export const DASHBOARDADS_STATS = `${DASHBOARDADS}/stats`;
export const DASHBOARDADS_REPORTS = `${DASHBOARDADS}/reports`;
export const DASHBOARDADS_MESSAGES = `${DASHBOARDADS}/messages`;
export const DASHBOARDADS_EDIT = `${DASHBOARDADS}/edit`;
export const DASHBOARDADS_PENDENCIES = `${DASHBOARDADS}/pendencies`;
export const DASHBOARDADS_NAV = `${DASHBOARD}/`;
export const BILLING_LEADS = '/billing-leads';

export const HOME = '/';
export const FILTER = '/busca';
export const PROFILE = '/perfil-agente';
export const PROFILE_DEALS = `${PROFILE}/deals`;
export const PROFILE_EDITION = `${PROFILE}-edition`;
export const PROFILE_RENTAL = `${PROFILE}/locacao`;
export const PROFILE_REVIEWS = `${PROFILE}/reviews`;
export const PROFILE_SALE = `${PROFILE}/venda`;
export const PURCHASE_CHOOSE_TYPE = '/choose-plan';
export const CONFIG_ACCOUNT = '/config-account';
export const CONFIG_ACCOUNT_AD_LOG = '/log';
export const CONFIG_ACCOUNT_LEADS = `${CONFIG_ACCOUNT}/leads`;
export const CONFIG_ACCOUNT_PROFILE = `${CONFIG_ACCOUNT}/profile`;
export const CONFIG_ACCOUNT_COMMON_USER_PROFILE = '/my-account/common-profile';
export const CONFIG_ACCOUNT_AD = `${CONFIG_ACCOUNT}/ad`;
export const CONFIG_ACCOUNT_AD_INTEGRATION_LOGS = `${CONFIG_ACCOUNT}/ad-integration-logs`;
export const CONFIG_ACCOUNT_EXTERNAL_INTEGRATION = `${CONFIG_ACCOUNT}/external-integration`;
export const CONFIG_ACCOUNT_PLAN = `${CONFIG_ACCOUNT}/plan`;
export const CONFIG_ACCOUNT_PAYMENT = `${CONFIG_ACCOUNT}/payment`;
export const PURCHASE_FLOW = '/purchase-flow';
export const BILLING_INFO = `${PURCHASE_FLOW}/billing-info`;
export const PURCHASE_FLOW_BUILDER = `${PURCHASE_FLOW}/builder`;
export const PURCHASE_FLOW_BUILDER2 = `${PURCHASE_FLOW}/builder2`;
export const PURCHASE_FLOW_BUILDER3 = `${PURCHASE_FLOW}/builder3`;
export const PURCHASE_FLOW_BUILDER3_REGISTER_PROPERTY = `${PURCHASE_FLOW}/builder3-register-property`;
export const PURCHASE_FLOW_CONGRATULATIONS = `${PURCHASE_FLOW}/congratulations`;
export const PURCHASE_FLOW_PAYMENT = `${PURCHASE_FLOW}/payment`;
export const PURCHASE_FLOW_PLANS = `${PURCHASE_FLOW}/plans`;
export const PURCHASE_FLOW_INCORPORATION = `/Incorporation-purchase`;
export const REPORT = '/report';
export const REPORT_BUILDER = `${REPORT}/builder`;
export const REPORT_CONDO = `${REPORT}/condo`;
export const REPORT_REAL_ESTATE = `${REPORT}/real-estate`;
export const REPORT_SURROUNDINGS = `${REPORT}/surroundings`;
export const SEARCH = '/mapa';
export const SEARCH_DETAIL = `${SEARCH}/detail`;
export const AD_DETAIL = `/anuncio`;
export const TERMS_AND_CONDITIONS = '/termos-e-condicoes';
export const CONTRACT = '/contract';
export const REALTORS_SEARCH = '/buscar-agente-imobiliario';
export const AD_REPORT = '/ad-report';
export const AD_LOG_LEADS = `${CONFIG_ACCOUNT}/ad`;
export const CHECKOUT = '/checkout';
export const LOADING_USER_INFO = '/loading-user-info';
export const PRIVACY_POLICY = '/privacy-policy';
export const DIVERSITY_AND_INCLUSION_POLICY = '/diversidade-e-inclusao';

export default {
  ABOUT_US,
  AUTHENTICATION_PARAMS,
  AUTHENTICATION,
  BILLING_INFO,
  CONTACT,
  DASHBOARD_ADS,
  DASHBOARD_PLAN,
  DASHBOARD_MY_BROKERS,
  DASHBOARD_BROKERS_ATTACH_PROPERTY,
  DASHBOARD_BROKERS_ATTACH_PROPERTY_LINKED,
  DASHBOARD_LEADS,
  DASHBOARD_LEADS_COMPANY_BROKER,
  DASHBOARD_LEADS_VIEW_LEAD,
  DASHBOARD_RECOMMENDATIONS,
  DASHBOARD_REPORTS,
  DASHBOARD_REQUESTS,
  DASHBOARD_REVIEWS,
  DASHBOARD,
  BILLING_LEADS,
  FAVORITES,
  FAVORITES_MY_FAVORITES,
  FAVORITES_PREFERENCES,
  DASHBOARDADS,
  DASHBOARDADS_STATS,
  DASHBOARDADS_MESSAGES,
  DASHBOARDADS_EDIT,
  DASHBOARDADS_PENDENCIES,
  DASHBOARDADS_REPORTS,
  DASHBOARDADS_NAV,
  HOME,
  CONFIG_ACCOUNT,
  CONFIG_ACCOUNT_LEADS,
  CONFIG_ACCOUNT_PROFILE,
  CONFIG_ACCOUNT_AD,
  CONFIG_ACCOUNT_AD_INTEGRATION_LOGS,
  CONFIG_ACCOUNT_EXTERNAL_INTEGRATION,
  CONFIG_ACCOUNT_PLAN,
  CONFIG_ACCOUNT_PAYMENT,
  CONFIG_ACCOUNT_AD_LOG,
  PROFILE_DEALS,
  PROFILE_EDITION,
  PROFILE_RENTAL,
  PROFILE_REVIEWS,
  PROFILE_SALE,
  PROFILE,
  PURCHASE_CHOOSE_TYPE,
  PURCHASE_FLOW_BUILDER,
  PURCHASE_FLOW_BUILDER2,
  PURCHASE_FLOW_BUILDER3,
  PURCHASE_FLOW_BUILDER3_REGISTER_PROPERTY,
  PURCHASE_FLOW_CONGRATULATIONS,
  PURCHASE_FLOW_PAYMENT,
  PURCHASE_FLOW_PLANS,
  PURCHASE_FLOW,
  PURCHASE_FLOW_INCORPORATION,
  REPORT_BUILDER,
  REPORT_CONDO,
  REPORT_REAL_ESTATE,
  REPORT_SURROUNDINGS,
  REPORT,
  SEARCH_DETAIL,
  SEARCH,
  AD_DETAIL,
  TERMS_AND_CONDITIONS,
  KOORTIMATIVA,
  CONTRACT,
  REALTORS_SEARCH,
  AD_LOG_LEADS,
  AD_REPORT,
  CHECKOUT,
  LOADING_USER_INFO,
  PRIVACY_POLICY,
  DIVERSITY_AND_INCLUSION_POLICY,
  CONFIG_ACCOUNT_COMMON_USER_PROFILE,
  FILTER
};
