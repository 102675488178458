import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import pathsConstants from '../../../constants/paths';

export function PrivateProfileRoute({
  component: Component,
  user,
  location,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={props => !user || user.userType > 2 ||
        (location.state && !!location.state.notUser) ||
        (location.pathname === pathsConstants.PROFILE_RENTAL &&
          location.search.split('?id=')[1] !== user._id &&
          location.search.split('?id=')[1] !== undefined) ||
        (location.pathname === pathsConstants.PROFILE_SALE &&
          location.search.split('?id=')[1] !== user._id &&
          location.search.split('?id=')[1] !== undefined) ?
        <Component {...props} />
        :
        <Redirect to={{
          pathname: pathsConstants.HOME,
          state: { from: props.location }
        }}
        />}
    />
  );
}
