// import firebase from 'firebase/app';
// import 'firebase/auth';

import httpClient from '../../clients/http/http.client';
import storageUtil from '../../utils/storage/storage.util';
import User from '../../models/user/user.model';

import { Auth, Amplify } from 'aws-amplify'
// TODO: Move this service to '../user/user.service.js'.

export const USER_STORAGE_KEY = 'user';

const logout = async _ => {
  remove();
  const result = await Auth.signOut({ global: true });
  return result
}

const postUser = async userObj => {
  const params = {
    'name': userObj.displayName,
    'email': userObj.email,
    // 'types': [ '1', ],
    'profile.image': userObj.photoURL
  };
  const response = await httpClient.post({
    path: '/users',
    params,
  });

  const result = new User({
    ...response.data,
    pictureUrl: response.data.profile.image,
    id: response.data._id,
  });
  return result;
};

const subscribe = async () => {
  const storageResult = storageUtil.get(USER_STORAGE_KEY);
  if (!storageResult) return;
  let u = new User({
    ...storageResult,
  });
  let dbResult;

  try {
    dbResult = await getUserProfile({
      token: u.token,
    });
  } catch {
    console.log('Erro ao obter informações do usuário')
  }

  let user = {
    ...dbResult,
    isEmailVerified: u.isEmailVerified,
    phoneNumber: u.phoneNumber,
    token: u.token,
  };

  save(user)
}

//Validação necessária para login com facebook / outros.
const checkIsAuth = async () => {

  const current = await Auth.currentSession()
    .catch(err => {
      console.log(err)
    });

  if (!current) {
    // Não existe usuário conectado
    return
  }

  var response = await httpClient.post({
    path: '/users',
    params: {
      name: current.idToken.payload.name,
      email: current.idToken.payload.email,
      receiveNewsOffers: true,
    },
  }).catch(err => {
    console.log('Usuário já cadastrado')
  });

  let userKoort = await getUserProfile({
    token: current.idToken.jwtToken,
  })
    .catch(err => {
      //Usuário existe no cognito mais não existe no banco da koort.
      console.log('Usuário não encontrado na Koort')
    });

  if (!userKoort) {
    console.info('Não existe usuário koort conectado')
    return
  }

  const user = {
    ...userKoort,
    token: current.idToken.jwtToken,
  };

  storageUtil.set("user", user)
  localStorage.setItem("AUTH_USER_TOKEN_KEY", user.token);
  return true;
}


const save = user => storageUtil.set(USER_STORAGE_KEY, user);

const retrieve = () => {
  const storageResult = storageUtil.get(USER_STORAGE_KEY);
  if (!storageResult) return;

  return storageResult;
};

const remove = () => storageUtil.set(USER_STORAGE_KEY);

const getPreferences = async (token) => {
  const response = await httpClient.get({
    path: '/users/preferences',
    token,
  });

  return response.data
}

const savePreferences = async (state, token) => {
  let err = null;

  const response = await httpClient.patch({
    path: '/users/preferences',
    params:
    {
      "preferences": {
        "features": {
          "property": state.optionsfproperty ?? [],
          "condo": state.optionscondo ?? [],
          "near": state.optionsnear ?? [],
        },
        "typeOfGuarantee": state.optionsguarantee,
        // "areaRange": [1, 100],
        "parkingSpaces": !state.minValueParkingSpaces ? [] :
          !state.maxValueParkingSpaces ?
            [state.minValueParkingSpaces, state.minValueParkingSpaces] :
            [state.minValueParkingSpaces, state.maxValueParkingSpaces],
        "suites": !state.minValueSuites ? [] :
          !state.maxValueSuites ?
            [state.minValueSuites, state.minValueSuites] :
            [state.minValueSuites, state.maxValueSuites],
        "bathrooms": !state.minValueBathrooms ? [] :
          !state.maxValueBathrooms ?
            [state.minValueBathrooms, state.minValueBathrooms] :
            [state.minValueBathrooms, state.maxValueBathrooms],
        "bedrooms": !state.minValueBedrooms ? [] :
          !state.maxValueBedrooms ?
            [state.minValueBedrooms, state.minValueBedrooms] :
            [state.minValueBedrooms, state.maxValueBedrooms],
        "city": state.optionsCity,
        "state": state.optionsState,
        "neighborhood": state.optionsBairro,
        "types": {
          "listing": state.optionsListing,
          "transaction": state.optionsTransaction,
          "property": state.optionsProperty,
          "use": state.optionsUse,
        }
      },
    },
    token: token,
    options: {
      header: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
    },
  }).catch((ex) => {
    console.log(ex)
    err = ex;
  });

  if (err) {
    return "Error"
  } else {
    return "Ok"
  }
}

const getUserProfile = async ({ token, userId }) => {
  let result = {}

  try {
    const response = await httpClient.get({
      path: `/users/profile/${userId || ''}`,
      token,
    });

    result = response.data
  } catch (ex) {
    console.log(ex)
  }

  return result;
}

const confirmFirstAccess = async ({ token }) => {
  try {
    await httpClient.patch({
      path: '/users/register/first-login',
      token,
    });

  } catch (ex) {
    console.log(ex)
  }
}

const deleteUserData = async ({ token }) => {
  const response = await httpClient.del({
    path: '/users',
    token,
  });

  return response;
}

const getUserAccounts = async ({ userEmail }) => {
  const response = await httpClient.get({
    path: `/users?email=${userEmail}`
  });

  return response;
}

const confirmUserAccount = async ({email}) => {
  const response = await httpClient.post({
    path: '/users/confirm-account',
    params: { email },
  });

  return response;
}

const getUserTokenWithUserId = async ({ userId, token }) => {
  const response = await httpClient.post({
    path: '/login',
    params: { userId },
    token,
  });

  return response;
}

export default {
  logout,
  retrieve,
  subscribe,
  checkIsAuth,
  getPreferences,
  savePreferences,
  getUserProfile,
  confirmFirstAccess,
  deleteUserData,
  getUserAccounts,
  confirmUserAccount,
  getUserTokenWithUserId,
};
