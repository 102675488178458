import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';
import { KeyboardArrowLeft } from 'styled-icons/material/KeyboardArrowLeft';
import { KeyboardArrowRight } from 'styled-icons/material/KeyboardArrowRight';
import { transitions } from 'polished';

import { iconBaseStyle, mqDesktop, mqTablet, pointer } from '../../../styled/helpers/helpers';
import { COLOR_2, COLOR_1 } from '../../../styled/variables/variables';

import Title from './title/Title';
import AdList from './ad-list/AdList';
import Arrows from '../cover/arrows/Arrows';

import windowUtil from '../../../utils/window/window.util';
import stringUtil from '../../../utils/string/string.util';
import CallToAction from '../call-to-action/CallToAction';

import listingV2Service from '../../../services/listing/listing-v2.service';
import userService from '../../../services/user/user.service';

class Highlights extends PureComponent {

  static ID = `${Highlights.name}_${stringUtil.generateUniqueId()}`;

  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();

    this.state = {
      realEstates: [],
      isLoading: false,
      page: null,
      prevButtonDisabled: false,
      nextButtonDisabled: false,
    };
  }

  async componentDidMount() {
    await this.getRealEstates({});
  }

  getRealEstates = async ({
    page = 1,
    isLoading = true,
    isNext,
  }) => {
      if (isNext && this.state.page === 5) {
        return;
      }

      if (!isNext && this.state.page === 1) {
        return;
    }

    this.setState({
      ...this.state,
      isLoading
    });

    const realEstates = await listingV2Service.getRecentRecommendationsAdsByHome({
      page,
      pageSize: 4,
    });

    this.setState(state => ({
      ...state,
      realEstates: !realEstates.length ? state.realEstates : realEstates,
      isLoading: false,
      page,
      nextButtonDisabled: page === 5 ? true : false,
      prevButtonDisabled: page === 1 ? true : false,
    }));
  };

  handlePaginationButtonClick = ({ isNext = true }) => {
    const { page } = this.state;

    this.getRealEstates({
      page: page + (isNext ? 1 : -1),
      isNext,
    });
  };

  handleShowMoreButton = () => windowUtil.scrollToElement({
    element: userService.retrieve() ? 'recommended-container-id' : CallToAction.ID,
    options: {
      offset: 0,
    },
  });

  backToTop = () => windowUtil.scrollToElement({
    element: Highlights.name,
    options: {
      offset: 0,
    },
  });

  render() {
    const { realEstates, isLoading } = this.state;

    return (
      <Wrapper ref={this.wrapperRef} as="section">
        <Title />
        <CenterWrapper>
          {realEstates && realEstates.length > 0 && (
            <LeftArrowIcon
              isDisabled={this.state.prevButtonDisabled}
              onClick={() => this.handlePaginationButtonClick({ isNext: false })}
            />
          )}
          <AdList
            realEstates={realEstates}
            isLoading={isLoading}
          />
          {realEstates && realEstates.length > 0 && (
            <RightArrowIcon
              isDisabled={this.state.nextButtonDisabled}
              onClick={() => this.handlePaginationButtonClick({})}
            />
          )}
        </CenterWrapper>
        {realEstates && realEstates.length > 0 && (
          <ArrowsWrapper>
            <LeftArrowIcon
              isDisabled={this.state.prevButtonDisabled}
              onClick={() => {
                this.handlePaginationButtonClick({ isNext: false });
                this.backToTop();
              }}
            />
            <RightArrowIcon
              isDisabled={this.state.nextButtonDisabled}
              onClick={() => {
                this.handlePaginationButtonClick({});
                this.backToTop();
              }}
            />
          </ArrowsWrapper>
        )}
        {realEstates && realEstates.length > 0 && (
          <SeeMoreButton onClick={this.handleShowMoreButton}>
            <Arrows color={COLOR_1} />
          </SeeMoreButton>
        )}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div.attrs({
  id: Highlights.ID,
})`
  background-color: ${COLOR_2};
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 30px;

${mqTablet`
  height: 100vh;
  min-height: 910px;
` }
`;

const CenterWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  & > svg {
    display: none;
    ${mqDesktop`
      display: block;
    ` }
  }
`;

const LeftArrowIcon = styled(KeyboardArrowLeft)`
  ${iconBaseStyle}
  height: 70px;
  align-self: center;
  cursor: pointer;

  ${mqDesktop`
    ${({ isDisabled }) => !isDisabled && css`
    ${transitions(['transform'], 'ease .3s')}
    &:hover {
      transform: scale(1.3);
    }
    `}
  ` }

  ${({ isDisabled }) => isDisabled && css`
    opacity: 0.1;
    cursor: not-allowed;
  `}
`;

const RightArrowIcon = styled(LeftArrowIcon).attrs({
  as: KeyboardArrowRight,
})``;

const ArrowsWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;

  ${mqDesktop`
    display: none;
  ` }
`;

const SeeMoreButton = styled.div`
  display: none;

  ${mqTablet`
      display: flex;
      justify-content: center;
      margin-top: 100px;
      align-self: center;
      align-items: center;
  `}
`;

export default Highlights;
