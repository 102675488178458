import React, { useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";

import { formSchema } from "./schema";
import {
  propertyUsesItems,
  propertyTypesItems,
} from "../../../../constants/form/propertyItems";

import { SelectBootstrap } from "../../../../components/form/select-bootstrap";
import { InputBootstrap } from "../../../../components/form/input-bootstrap";
import { CepInput } from "../../../../components/form/cep-input";
import { PriceInput } from "../../../../components/form/price-input";
import { CellphoneInput } from "../../../../components/form/cellphone-input";

import realEstateService from "../../../../services/real-estate/real-estate.service";
import geolocationService from "../../../../services/geolocation/geolocation.service";

import pathsConstants from "../../../../constants/paths";
import eventConstants from "../../../../constants/events";
import domEventsUtil from "../../../../utils/dom-events/dom-events.util";
import queryStringUtil from "../../../../utils/query-string/query-string.util";

import {
  Form,
  SectionTitle,
  SelectTransactionContainer,
  SelectTransactionButton,
  PropertiesSelectsContainer,
  AddressContainer,
  AddressStreetInputContainer,
  TabletInliner,
  AddressNumberInputContainer,
  AddressComplementInputContainer,
  AddressNeighborhoodInputContainer,
  AddressCityInputContainer,
  AddressStateInputContainer,
  AddressCompleteInTheAd,
  CheckboxInputWrapper,
  ShowOnMapInput,
  PropertyFeaturesContainer,
  PropertyFeaturesBedroomsInputContainer,
  PropertyFeaturesBathroomsInputContainer,
  ValuesContainer,
  AdvertiserDataContainer,
  HasWhatsappInput,
  ButtonWrapper,
  SaveButton,
} from "./styles";

const customId = "custom-id-yes";

export function BuilderRefactored(props) {
  const [transactionType, setTransactionType] = useState("Venda");
  const [showCompleteAddressInTheAd, setShowCompleteAddressInTheAd] =
    useState(false);
  const [isWhatsapp, setIsWhatsapp] = useState(false);

  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();

  const { register, handleSubmit, formState, control, setValue, clearErrors } =
    useForm({
      resolver: yupResolver(formSchema),
    });

  const { errors } = formState;

  const handleFormSubmit = useCallback(
    async (values) => {
      domEventsUtil.dispatch({
        name: eventConstants.LOADING_ANIMATION,
        params: { show: true },
      });

      const data = {
        ...values,
        type: values.propertyType,
        use: values.propertyUse,
        additionalInfo: values.complement,
        phoneNumber: values.cellphone
          ? values.cellphone.replace(/[^a-zA-Z0-9]+/g, "").substring(2)
          : "",
        latitude,
        longitude,
        whatsApp: isWhatsapp,
        showOnMap: showCompleteAddressInTheAd,
        price: values.price.replace("R$ ", ""),
        condo: values.condo ? values.condo.replace("R$ ", "") : "",
        iptu: values.iptu ? values.iptu.replace("R$ ", "") : "",
        transaction: transactionType,
      };

      const { user, history } = props;
      let realEstateId;

      try {
        realEstateId = await realEstateService.createinfoAd({
          data,
          user,
        });

        localStorage.setItem("adId", realEstateId);
        localStorage.setItem("infoAd", JSON.stringify(data));

        if (!user || !user.token) {
          history.push(
            `${pathsConstants.AUTHENTICATION}/${pathsConstants.AUTHENTICATION_PARAMS.LOGIN}`
          );
        } else {
          history.push({
            pathname: pathsConstants.PURCHASE_FLOW_PLANS,
            search: queryStringUtil.stringify(history, {
              realEstateId: realEstateId,
              plans: true,
            }),
            state: {
              userAddress: {
                number: values.number,
                zipCode: values.zipCode,
                additionalInfo: values.complement,
              },
            },
          });
        }
      } catch (error) {
        toast.error("Houve um erro ao criar o anúncio!", {
          autoClose: false,
          toastId: customId,
        });
      } finally {
        domEventsUtil.dispatch({
          name: eventConstants.LOADING_ANIMATION,
          params: { show: false },
        });
      }
    },
    [
      transactionType,
      showCompleteAddressInTheAd,
      isWhatsapp,
      latitude,
      longitude,
    ]
  );

  async function getAddressByCep(zipCode) {
    let geolocation;

    let result;

    domEventsUtil.dispatch({
      name: eventConstants.LOADING_ANIMATION,
      params: { show: true },
    });

    try {
      result = await geolocationService.getAddressByZipCode({
        zipCode,
      });
    } catch (error) {
      // Do nothing.
    }

    if (result) {
      try {
        geolocation = await geolocationService.getGeolocationByAddress({
          zipCode,
          street: result.street,
          state: result.state,
          country: "Brazil",
        });

        setLatitude(geolocation.latitude);
        setLongitude(geolocation.longitude);

        setValue("street", result.street);

        if (!!result.neighborhood) {
          setValue("neighborhood", result.neighborhood);
          clearErrors("neighborhood");
        }

        setValue("city", result.city);
        setValue("state", result.state);
      } catch (err) {
        toast.error("Erro verificando geolocalização do endereço.", {
          autoClose: false,
          toastId: customId,
        });
      } finally {
        domEventsUtil.dispatch({
          name: eventConstants.LOADING_ANIMATION,
          params: { show: false },
        });
      }
    } else {
      domEventsUtil.dispatch({
        name: eventConstants.LOADING_ANIMATION,
        params: { show: false },
      });
    }
  }

  function handleZipCodeInputBlur(event) {
    if (!!event.target.value) {
      if (event.target.value.length === 9) {
        getAddressByCep(event.target.value);
      }
    }
  }

  return (
    <Form onSubmit={handleSubmit(handleFormSubmit)}>
      <SectionTitle>Selecione a transação</SectionTitle>

      <SelectTransactionContainer>
        <SelectTransactionButton
          isActive={transactionType === "Venda"}
          onClick={() => setTransactionType("Venda")}
        >
          Venda
        </SelectTransactionButton>
        <SelectTransactionButton
          isActive={transactionType === "Locação"}
          onClick={() => setTransactionType("Locação")}
        >
          Locação
        </SelectTransactionButton>
      </SelectTransactionContainer>

      <SectionTitle>Tipo de imóvel que deseja anunciar</SectionTitle>

      <PropertiesSelectsContainer>
        <SelectBootstrap
          name="propertyUse"
          label="Uso"
          options={propertyUsesItems}
          error={errors.propertyUse}
          {...register("propertyUse")}
        />

        <SelectBootstrap
          name="propertyType"
          label="Tipo"
          options={propertyTypesItems}
          error={errors.propertyType}
          {...register("propertyType")}
        />
      </PropertiesSelectsContainer>

      <AddressContainer>
        <SectionTitle>Localização do seu imóvel</SectionTitle>

        <CepInput
          name="zipCode"
          label="Cep"
          error={errors.zipCode}
          control={control}
          {...register("zipCode", {
            onBlur: handleZipCodeInputBlur,
          })}
        />
        <p style={{ marginTop: 5, fontSize: 12 }}>
          Não sabe seu CEP? Clique{" "}
          <a
            target="_blank"
            href="http://www.buscacep.correios.com.br/sistemas/buscacep/buscaCep.cfm"
          >
            aqui
          </a>
        </p>

        <AddressStreetInputContainer className="mb-3">
          <InputBootstrap
            name="street"
            label="Endereço"
            placeholder="Endereço"
            disabled
            error={errors.street}
            {...register("street")}
          />
        </AddressStreetInputContainer>

        <TabletInliner>
          <AddressNumberInputContainer>
            <InputBootstrap
              name="number"
              label="Número"
              placeholder="Número"
              type="number"
              error={errors.number}
              {...register("number")}
            />
          </AddressNumberInputContainer>

          <AddressComplementInputContainer>
            <InputBootstrap
              name="complement"
              label="Complemento"
              placeholder="Complemento"
              error={errors.complement}
              {...register("complement")}
            />
          </AddressComplementInputContainer>
        </TabletInliner>

        <AddressNeighborhoodInputContainer className="mt-3">
          <InputBootstrap
            name="neighborhood"
            label="Bairro"
            placeholder="Bairro"
            error={errors.neighborhood}
            {...register("neighborhood")}
          />
        </AddressNeighborhoodInputContainer>

        <TabletInliner className="mt-3">
          <AddressCityInputContainer>
            <InputBootstrap
              name="city"
              label="Cidade"
              placeholder="Cidade"
              disabled
              error={errors.city}
              {...register("city")}
            />
          </AddressCityInputContainer>

          <AddressStateInputContainer>
            <InputBootstrap
              name="state"
              label="Estado"
              placeholder="Estado"
              disabled
              error={errors.state}
              {...register("state")}
            />
          </AddressStateInputContainer>
        </TabletInliner>

        <AddressCompleteInTheAd>
          {showCompleteAddressInTheAd ? (
            <>
              As informações de localização do seu imóvel serão exibidas no
              corpo do anúncio
            </>
          ) : (
            <>
              Nosso sistema de busca utiliza como ferramenta principal o mapa e
              o endereço completo será utilizado apenas para localizar
              geograficamente o seu imóvel. Se você quiser mostrar o endereço no
              anúncio, clique abaixo.
            </>
          )}
        </AddressCompleteInTheAd>

        <CheckboxInputWrapper
          label="Mostrar o endereço completo no anúncio"
          htmlFor="showOnMap"
        >
          <ShowOnMapInput
            id="showOnMap"
            name="showOnMap"
            checked={showCompleteAddressInTheAd}
            onClick={() =>
              setShowCompleteAddressInTheAd((prevState) => !prevState)
            }
          />
        </CheckboxInputWrapper>
      </AddressContainer>

      <PropertyFeaturesContainer>
        <SectionTitle>Características do imóvel</SectionTitle>

        <TabletInliner>
          <PropertyFeaturesBedroomsInputContainer>
            <InputBootstrap
              name="bedrooms"
              label="Nº Quartos"
              placeholder="Nº Quartos"
              error={errors.bedrooms}
              {...register("bedrooms")}
            />
          </PropertyFeaturesBedroomsInputContainer>

          <PropertyFeaturesBathroomsInputContainer>
            <InputBootstrap
              name="bathrooms"
              label="Nº Banheiros"
              placeholder="Nº Banheiros"
              error={errors.bathrooms}
              {...register("bathrooms")}
            />
          </PropertyFeaturesBathroomsInputContainer>
        </TabletInliner>
        <TabletInliner>
          <PropertyFeaturesBedroomsInputContainer>
            <InputBootstrap
              name="suites"
              label="Nº Suítes"
              placeholder="Nº Suítes"
              error={errors.suites}
              {...register("suites")}
            />
          </PropertyFeaturesBedroomsInputContainer>

          <PropertyFeaturesBathroomsInputContainer>
            <InputBootstrap
              name="parkingSpaces"
              label="Nº Vagas"
              placeholder="Nº Vagas"
              error={errors.parkingSpaces}
              {...register("parkingSpaces")}
            />
          </PropertyFeaturesBathroomsInputContainer>
        </TabletInliner>

        <TabletInliner>
          <PropertyFeaturesBedroomsInputContainer>
            <InputBootstrap
              name="area"
              label="Área Útil (m²)"
              placeholder="Área Útil (m²)"
              error={errors.area}
              {...register("area")}
            />
          </PropertyFeaturesBedroomsInputContainer>

          <PropertyFeaturesBathroomsInputContainer>
            <InputBootstrap
              name="totalArea"
              label="Área Total (m²)"
              placeholder="Área Total (m²)"
              error={errors.totalArea}
              {...register("totalArea")}
            />
          </PropertyFeaturesBathroomsInputContainer>
        </TabletInliner>
      </PropertyFeaturesContainer>

      <ValuesContainer>
        <SectionTitle>Valores</SectionTitle>

        <PriceInput
          name="price"
          label={
            transactionType === "Venda"
              ? "Valor de venda (R$)"
              : "Valor do aluguel (R$)"
          }
          error={errors.price}
          control={control}
          {...register("price")}
        />

        <PriceInput
          name="condo"
          label="Condomínio (R$)"
          error={errors.condo}
          control={control}
          {...register("condo")}
        />

        <PriceInput
          name="iptu"
          label="IPTU (R$ MENSAL)"
          error={errors.iptu}
          control={control}
          {...register("iptu")}
        />
      </ValuesContainer>

      <AdvertiserDataContainer>
        <SectionTitle>Dados do anunciante</SectionTitle>

        <InputBootstrap
          name="name"
          label="Nome"
          placeholder="Nome"
          error={errors.name}
          {...register("name")}
        />

        <InputBootstrap
          name="email"
          label="E-mail"
          placeholder="E-mail"
          error={errors.email}
          {...register("email")}
        />

        <CellphoneInput
          name="cellphone"
          label="Telefone/Celular"
          error={errors.cellphone}
          control={control}
          {...register("cellphone")}
        />

        <CheckboxInputWrapper label="WhatsApp?" htmlFor="whatsApp">
          <HasWhatsappInput
            id="whatsApp"
            name="whatsApp"
            checked={isWhatsapp}
            onClick={() => setIsWhatsapp((prevState) => !prevState)}
          />
        </CheckboxInputWrapper>
      </AdvertiserDataContainer>

      <ButtonWrapper>
        <SaveButton>salvar</SaveButton>
      </ButtonWrapper>
    </Form>
  );
}
