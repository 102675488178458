import styled from 'styled-components';

export const HorizontalScrollListContainer = styled.div`
  display: flex;

  position: fixed;
  bottom: 16px;
  width: 100%;
  overflow-x: scroll;
  z-index: 99999;

  @media (min-width: 1080px) {
    display: none;
  }
`;
