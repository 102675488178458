/*
  global
  window
*/
import { toast } from "react-toastify";
import copy from "copy-to-clipboard"

export const PLATFORMS = {
  WHATS_APP: 'WHATS_APP',
};

const share = ({
  platform = PLATFORMS.WHATS_APP,
  title = 'Title',
  message = 'Message',
  newPage = true,
}) => {
  let url = '';
  switch (platform) {
    case PLATFORMS.WHATS_APP:
      url += 'whatsapp://send?';
      url += `text=${encodeURIComponent(`${title}\n\n${message}`)}`;
      break;
    default:
      throw new Error('Unknown platform.');
  }

  // url += encodeURIComponent(`\n\n${ url }`);

  window.open(url, newPage ? '_blank' : '_self');
};

const copyURLToClipBoard = async (link, toastId) => {
  try {
    copy(link, {})
  } catch (error) {
    toast.error(
      "Não foi possível copiar o link.",
      {
        autoClose: false,
        toastId,
      }
    );
    throw new Error(`Erro ao tentar copiar link.`)
  }

  toast.success(
    "Link copiado!",
    {
      autoClose: false,
      toastId,
    }
  );
}

export default {
  share,
  copyURLToClipBoard
};
