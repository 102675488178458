/* eslint-disable no-undef */
import React, { PureComponent } from "react";
import styled from "styled-components";
import propTypes from "prop-types";
import { Router, Route, Switch, Redirect, Link } from "react-router-dom";
import Joyride, { EVENTS } from 'react-joyride';
import { toast } from 'react-toastify';

import { COLOR_2, COLOR_1 } from '../../styled/variables/variables';
import { BaseButton } from '../../styled/buttons/buttons';

import Stats from "./stats/Stats";
import Tabs from "../../components/tabs";
import { Edit } from './edit';
import Reports from './reports/Reports';
import { LeadsTable } from './leads/leads-table';
import { Pendencies } from "./pendencies";

import userService from "../../services/user/user.service";
import domEventsUtil from "../../utils/dom-events/dom-events.util";
import eventsConstants from "../../constants/events";
import pathsConstants from "../../constants/paths";

import queryStringUtil from '../../utils/query-string/query-string.util';
import realEstateService from '../../services/real-estate/real-estate.service';
/*
  global
  window
  process
  setTimeout
  alert
*/
const customId = "custom-id-yes";
class Dashboardd extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showFixedTabs: false,

      user: userService.retrieve(),
      adInfo: '',
      messages: null,
      run: false,
      steps: [
        {
          content:
            <>
              <h2><b>Bem vindo!</b></h2>
              <br></br>

              <p>Este é o início de um tutorial para o <b>Dashboard do Imóvel</b>.</p>
            </>,
          // locale: { skip: <strong aria-label="skip">Sair</strong> },
          placement: 'center',
          target: 'body',
          locale: {
            next: `Próximo`, skip: `Sair`
          },
        },
        {
          target: '.dashboard-ads-bt',
          content:
            <>
              <h2><b>Excluir e Remover</b></h2>
              <br></br>
              <p>Os anúncios podem ser excluídos definitivamente (Vendidos ou Alugados) ou removidos do plano para possível reinserção futura.</p>
            </>,
          locale: {
            next: `Próximo`, skip: `Sair`, back: 'Voltar'
          },
        },
        {
          target: '.dashboard-ads-view',
          content:
            <>
              <h2><b>Visualizações dos anúncios</b></h2>
              <br></br>
              <p>Você consegue identificar quantas mensagens, visualizações e compartilhamentos este imóvel recebeu.</p>
            </>,
          locale: {
            next: `Próximo`, skip: `Sair`, back: 'Voltar'
          },
          placement: 'left-end'
        },
        {
          target: '.dashboard-ads-nav',
          content:
            <>
              <h2><b>Mensagens e Editar</b></h2>
              <br></br>
              <p>Aqui você consegue verificar todas as mensagens enviadas sobre este imóvel ou editar os dados cadastrais.</p>
            </>,
          locale: {
            next: `Próximo`, skip: `Sair`, back: 'Voltar', last: 'Último'
          },
          placement: 'bottom-end'
        }
      ],
    };
  }

  componentDidMount() {
    this.getProfileInfo();
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  }

  getProfileInfo = async () => {
    domEventsUtil.dispatch({
      name: eventsConstants.LOADING_ANIMATION,
      params: { show: true }
    });

    const { user } = this.state;
    const { history } = this.props;
    const { realEstateId } = queryStringUtil.parse(history);
    let realEstate;
    try {
      realEstate = await realEstateService.getNewById({ id: realEstateId, token: user.token });
    } catch (error) {
      toast.error("Erro buscando dados do dashboard", {
        autoClose: false,
        toastId: customId,
      });
      return;
    } finally {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: false }
      });
    }

    this.setState({
      ...this.state,
      adInfo: realEstate,
    });
  };

  initTour = () => {
    this.setState({
      ...this.state,
      run: true
    });
  }

  handleJoyrideCallback = (data) => {
    const { type } = data;
    if (type === EVENTS.TOUR_END && this.state.run) {
      this.setState({
        ...this.state,
        run: false
      });
    }
  }

  handleSubmitDashboardEditForm = () => {
    document.getElementById('dashboard-edit-submit-button').click();
  }

  render() {
    const { history } = this.props;
    const { user, steps, adInfo, run } = this.state;
    if (!adInfo) return <Wrapper />;

    let tabConfigs = [
      {
        label: "Editar",
        path: pathsConstants.DASHBOARDADS_EDIT,
        showTooltip: false,
        disabled: adInfo.externalId.length > 1,
        tooltipTitle:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
      },
      {
        label: "Pendências",
        path: pathsConstants.DASHBOARDADS_PENDENCIES,
        showTooltip: false,
        tooltipTitle:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
      },
    ];

    if (user.userType >= 3) {
      tabConfigs = [
        {
          label: "Leads",
          path: pathsConstants.DASHBOARDADS_MESSAGES,
          showTooltip: false,
          tooltipTitle:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        },
        ...tabConfigs,
      ]
    }

    return (
      <Wrapper>
        <>
          {/* <Cover/> */}
          <ContentWrapper>
            <Joyride
              steps={steps}
              scrollDuration={300}
              scrollToFirstStep={true}
              disableScrolling={true}
              showProgress={true}
              showSkipButton={true}
              continuous={true}
              run={run}
              callback={this.handleJoyrideCallback}
            />
            {/* <NavigationLinksWrapper>
        <Link to="/dashboard/ads">&laquo; Voltar ao Dashboard</Link>
      </NavigationLinksWrapper> */}
              <Stats adInfo={adInfo} user={user} />
            <Tabs configs={tabConfigs} className={"dashboard-ads-nav"} />
            <Router history={history}>
              <Switch>
                <Route
                  path={pathsConstants.DASHBOARDADS_MESSAGES}
                  render={props => (
                    <LeadsWrapper>
                      <LeadsTable {...props} />
                    </LeadsWrapper>
                  )}
                />
                <Route
                  path={pathsConstants.DASHBOARDADS_EDIT}
                  render={props => <Edit {...props} user={user} adInfo={adInfo} history={history} />}
                />
                <Route
                  path={pathsConstants.DASHBOARDADS_PENDENCIES}
                  render={props => <Pendencies {...props} user={user} adInfo={adInfo} history={history} />}
                />
                <Route
                  path={pathsConstants.DASHBOARDADS_REPORTS}
                  render={props => <Reports {...props} user={user} adInfo={adInfo} history={history} />}
                />
                <Route
                  path={pathsConstants.DASHBOARDADS_NAV}
                />
              </Switch>
            </Router>
            <Footer>
              <BackButton style={{ float: 'left', marginLeft: '5%' }} type="button" onClick={() => { this.props.history.push('/dashboard/ads') }}>Voltar</BackButton>
              <BackButton
                style={!history.location.pathname.includes("dashboard-ads/edit") ?
                  { display: "none" } : {}}
                type="button"
                onClick={this.handleSubmitDashboardEditForm}
              >
                Salvar
              </BackButton>
            </Footer>
          </ContentWrapper>
        </>
      </Wrapper>
    );
  }
}


Dashboardd.propTypes = {
  history: propTypes.object
};

Dashboardd.defaultProps = {};

const LeadsWrapper = styled.div`
  max-width: 1000px;
  padding: 0 18px;

  margin: 0 auto;

  @media (max-width: 999px) {
    margin: unset;
  }
`;

const OpenTourGlobal = styled.img`
  width: 40px;
  position: fixed;
  z-index: 999;
  right: 10px;
  bottom: 20px;
  cursor: pointer;
`;

const BackButton = styled(BaseButton)`
        color: ${COLOR_2};
        background-color: ${COLOR_1};
        flex-grow: 1;
        border: none;
        max-width: 170px;
        `;

const Footer = styled.div`
  position: fixed;
  width: 100%;
  height: 70px;
  bottom: 0px;
  left: 0px;
  padding-top: 10px;
  padding-right: 120px;
  background: white;
  -webkit-box-shadow: 0px -3px 3px 0px rgba(0,0,0,0.15);
  -moz-box-shadow: 0px -3px 3px 0px rgba(0,0,0,0.15);
  box-shadow: 0px -3px 3px 0px rgba(0,0,0,0.15);
  z-index: 2;

  display: flex;
  justify-content: flex-end;

`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const NavigationLinksWrapper = styled.div`
  max-width: 1000px;
  width: 100%;
  align-self: center;
  display: flex;
`

const ContentWrapper = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
  margin-top: 85px;
`;

export default Dashboardd;
