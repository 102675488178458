import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Col, Row, Accordion } from 'react-bootstrap';
import { RiFile2Fill } from 'react-icons/ri';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { SelectBootstrap } from '../../../components/form/select-bootstrap';

import userService from '../../../services/user/user.service';
import urlIntegrationService from '../../../services/koort-business/url_integration.service';

import domEventsUtil from '../../../utils/dom-events/dom-events.util';
import eventsConstants from '../../../constants/events';

import {
  Container,
  FilterForm,
  EmptyLogs,
  Card,
  AccordionHeader,
  AccordionHeaderList,
  AccordionHeaderTagSituation,
  AccordionHeaderListItem,
  AccordionBodyLogContainer,
  AccordionBodyTagOfLog,
  AccordionBodyLogDescription,
  NextPageButtonContainer,
  NextPageButton,
  FilterOptionsContainer,
  FilterSelectsWrapper
} from './styles';

const customId = "custom-id-yes";
const filterFormSchema = yup.object().shape({
  subject: yup.string()
    .required('Selecione uma opção'),
  filterDate: yup.string()
    .required('Selecione uma opção'),
  filterSize: yup.string()
    .required('Selecione uma opção'),
});

const filterOptions = [
  { value: 'todas', label: 'Todos', },
  { value: 'integrado', label: 'Integrado', },
  { value: 'não integrado', label: 'Não Integrado', },
  { value: 'parcialmente integrado', label: 'Parcialmente Integrado', },
];

const filterOptionsDate = [
  { value: 'todas', label: 'Todos', },
  { value: '7', label: 'Últimos 7 dias', },
  { value: '1', label: 'Últimas 24 horas', },
];

const filterOptionsSize = [
  { value: '30', label: '30', },
  { value: '100', label: '100', },
  { value: '300', label: '300', },
  { value: '500', label: '500', },
];

export function AdIntegrationLogs() {
  const [logs, setLogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showNextPageButton, setShowNextPageButton] = useState(false);

  const [filterButtonDisabled, setFilterButtonDisabled] = useState(true);

  const [tag, setTag] = useState('');
  const [period, setPeriod] = useState('');
  const [logPerPage, setLogPerPage] = useState('30');

  const [handleFilter, setHandleFilter] = useState(false);

  const { register, handleSubmit, formState, watch } = useForm({
    resolver: yupResolver(filterFormSchema),
  });

  const { errors } = formState;

  useEffect(() => {
    async function getAdIntegrationLogs() {
      try {
        domEventsUtil.dispatch({
          name: eventsConstants.LOADING_ANIMATION,
          params: { show: true },
        });

        const user = userService.retrieve();

        const response = await urlIntegrationService.getAdLogs({
          token: user.token,
          page: currentPage,
          tag,
          period,
          logPerPage
        });

        if (response && response.status !== 200) {
          throw 'Houve um erro ao carregar os logs!';
        }

        const { data } = response;

        if (!data.length) {
          setShowNextPageButton(false);

          if (currentPage !== 1) {
            toast.info('Todos os logs já foram carregados!', {
              autoClose: false,
              toastId: customId,
            })
          }

          return;
        }
        let dataSerialized = [];

        dataSerialized = data.map(item => {
          const date = new Date(item.createdAt);

          return {
            ...item,
            createdAtFormatted: `${date.toLocaleDateString('pt-br')} ${date.toLocaleTimeString('pt-br')}`
          }
        });

        if (data.length === Number(logPerPage)) {
          setShowNextPageButton(true);
        }

        setLogs(prevState => [...prevState, ...dataSerialized]);
      } catch (err) {
        toast.error(err, {
          autoClose: false,
          toastId: customId,
        });
      } finally {
        domEventsUtil.dispatch({
          name: eventsConstants.LOADING_ANIMATION,
          params: { show: false },
        });
      }
    }

    getAdIntegrationLogs();
  }, [handleFilter]);

  useEffect(() => {
    const subscription = watch((data) => {
      if (data.subject !== tag) {
        setFilterButtonDisabled(false)
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  function handleFilterFormSubmit(values) {
    setCurrentPage(1);

    setTag(values.subject);
    setPeriod(values.filterDate);
    setLogPerPage(values.filterSize);

    setLogs([]);
    setHandleFilter(prev => !prev)

    setFilterButtonDisabled(true);
  }

  return (
    <Container>
      <Row>
        <Col xs={10} md={12}>
          <FilterForm onSubmit={handleSubmit(handleFilterFormSubmit)}>
            <h3>Selecione as opções para filtrar</h3>

            <FilterOptionsContainer filterButtonDisabled={filterButtonDisabled}>
              <FilterSelectsWrapper filterButtonDisabled={filterButtonDisabled}>
                <SelectBootstrap
                  name="subject"
                  label="Situação"
                  options={filterOptions}
                  error={errors.subject}
                  {...register('subject')}
                />

                <SelectBootstrap
                  name="filterDate"
                  label="Período"
                  options={filterOptionsDate}
                  error={errors.filterDate}
                  {...register('filterDate')}
                />

                <SelectBootstrap
                  name="filterSize"
                  label="Logs por página"
                  options={filterOptionsSize}
                  error={errors.filterSize}
                  {...register('filterSize')}
                />

                <button type="submit" disabled={filterButtonDisabled}>Filtrar</button>
              </FilterSelectsWrapper>

            </FilterOptionsContainer>

          </FilterForm>
        </Col>
      </Row>

      <Row>
        <Col xs={10} md={12}>
          <Accordion defaultActiveKey="1">
            {!logs.length ? (
              <EmptyLogs>
                <RiFile2Fill size={32} />
                <span>Não há nada aqui</span>
              </EmptyLogs>
            ) : logs.map((item, index) => (
              <Card key={item._id}>
                <Accordion.Toggle as={Card.Header} eventKey={index + 1}>
                  <AccordionHeader>
                    <AccordionHeaderList>
                      <AccordionHeaderListItem isTag={false}>
                        <span>Código do anúncio:</span> {item.externalId}
                      </AccordionHeaderListItem>

                      <AccordionHeaderListItem isTag={false}>
                        <span>Data:</span> {item.createdAtFormatted}
                      </AccordionHeaderListItem>
                    </AccordionHeaderList>

                    {item.tag === "integrado" &&
                      <AccordionHeaderTagSituation hasIntegrated>
                        Integrado
                      </AccordionHeaderTagSituation>
                    }

                    {item.tag === "não integrado" &&
                      <AccordionHeaderTagSituation hasNotIntegrated>
                        Não Integrado
                      </AccordionHeaderTagSituation>
                    }

                    {item.tag === "parcialmente integrado" &&
                      <AccordionHeaderTagSituation hasPartiallyIntegrated>
                        Parcialmente I.
                      </AccordionHeaderTagSituation>
                    }
                  </AccordionHeader>
                </Accordion.Toggle>

                <Accordion.Collapse eventKey={index + 1}>
                  <Card.Body>
                    {!item.logs.length && (
                      <AccordionBodyLogContainer>
                        <AccordionBodyTagOfLog isSuccess>
                          sucesso
                        </AccordionBodyTagOfLog>

                        <AccordionBodyLogDescription isSuccess>
                          Este imóvel foi integrado com sucesso.
                        </AccordionBodyLogDescription>
                      </AccordionBodyLogContainer>
                    )}

                    {item.logs.map((item, index) => {
                      if (item !== null) {
                        return (
                          <AccordionBodyLogContainer key={index}>
                            <AccordionBodyTagOfLog
                              isWarning={item.tag === 'warning'}
                              isError={item.tag === 'error'}
                            >
                              {item.tag === 'warning' && 'aviso'}
                              {item.tag === 'error' && 'erro'}
                            </AccordionBodyTagOfLog>

                            <AccordionBodyLogDescription
                              isWarning={item.tag === 'warning'}
                              isError={item.tag === 'error'}
                            >
                              {item.description}
                            </AccordionBodyLogDescription>
                          </AccordionBodyLogContainer>
                        )
                      } else {
                        return;
                      }
                    })}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            ))
            }
          </Accordion>

          {showNextPageButton && (
            <NextPageButtonContainer>
              <NextPageButton
                type="button"
                onClick={() => {
                  setCurrentPage(prevState => prevState + 1)
                  setHandleFilter(prev => !prev)
                }}>
                Carregar mais logs
              </NextPageButton>
            </NextPageButtonContainer>
          )}
        </Col>
      </Row>
    </Container>
  );
}
