/* eslint-disable no-unreachable */
import React, { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { scroller, Element } from "react-scroll";

import { formSchema } from "./schema";
import {
  propertyTypesItems,
  propertyUsesItems,
} from "../../../../constants/form/propertyItems";
import { stateAcronymsItems } from "../../../../constants/form/states-acronyms";

import { ButtonsOptions } from "../../../../components/form/buttons-options";
import { SelectBootstrap } from "../../../../components/form/select-bootstrap";
import { CepInput } from "../../../../components/form/cep-input";
import { InputBootstrap } from "../../../../components/form/input-bootstrap";
import { TextArea } from "../../../../components/form/textarea";
import { PriceInput } from "../../../../components/form/price-input";
import { CellphoneInput } from "../../../../components/form/cellphone-input";
import AdditionalInfo from "../../builder2/additional-info/AdditionalInfo";
import Features from "../../builder2/features/Features";
import CommonAreaFeatures from "../../builder2/common-area-features/CommonAreaFeatures";
import SurroundingFeatures from "../../builder2/surrounding-features/SurroundingFeatures";
import Images from "../../builder2/images/Images";
import Panoramas from "../../builder2/panoramas/Panoramas";
import FloorPlans from "../../builder2/floor-plans/FloorPlans";
import Video from "../../builder2/video/Video";

import userService from "../../../../services/user/user.service";
import userProfileService from "../../../../services/user-profile/user-profile.service";
import realEstateService from "../../../../services/real-estate/real-estate.service";
import geolocationService from "../../../../services/geolocation/geolocation.service";
import constantsService from "../../../../services/constants/constants.service";

import pathsConstants from "../../../../constants/paths";
import eventConstants from "../../../../constants/events";
import domEventsUtil from "../../../../utils/dom-events/dom-events.util";
import {
  addMaskCellphone,
  addMaskTelephone,
} from "../../../../utils/add-masks";

import {
  Container,
  LoadDataErrorContainer,
  ReturnDashboardButton,
  Title,
  Form,
  SectionTitle,
  PropertiesSelectsContainer,
  AddressContainer,
  AddressStreetInputContainer,
  TabletInliner,
  AddressNumberInputContainer,
  AddressComplementInputContainer,
  AddressNeighborhoodInputContainer,
  AddressCityInputContainer,
  AddressStateInputContainer,
  AddressCompleteInTheAd,
  CheckboxInputWrapper,
  ShowOnMapInput,
  EnterpriseStatusTitle,
  SelectContainer,
  SelectStep,
  StepBulletContainer,
  StepBulletGap,
  StepBullet,
  SelectTextItem,
  StepLine,
  PropertyFeaturesContainer,
  PropertyFeaturesBedroomsInputContainer,
  PropertyFeaturesBathroomsInputContainer,
  LabelInputPropertyFeature,
  Counter,
  ValuesContainer,
  AdvertiserDataContainer,
  HasWhatsappInput,
  ButtonWrapper,
  SaveButton,
  SaveAndPublishButton,
  ReleaseFeatures,
  FeatureContainer
} from "./styles";

// Tipo de usuário:
// 0 - Usuário administrador;
// 1 - Usuário cadastrado;
// 2 - usuário com plano (proprietário);
// 3 - Corretor autônomo;
// 4 - Imobiliárias;
// 5 - Incorporadoras/Construtoras.

const customId = "custom-id-yes";

export function Builder3Refactored(props) {
  const [userType, setUserType] = useState(null);
  const [hasErrorLoadProfile, setHasErrorLoadProfile] = useState(false);

  const [adType, setAdType] = useState("Lançamento");
  const [transactionType, setTransactionType] = useState("Venda");
  const [showCompleteAddressInTheAd, setShowCompleteAddressInTheAd] =
    useState(false);
  const [isWhatsapp, setIsWhatsapp] = useState(false);
  const [isSaleAndRental, setIsSaleAndRental] = useState(false);
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [enterpriseStatus, setEnterpriseStatus] = useState("Pronto para Morar");

  const [counter, setCounter] = useState(0);

  const [propertyHasRegistered, setPropertyHasRegistered] = useState(false);
  const [imagesHasUploaded, setImagesHasUploaded] = useState(false);

  const [isPublishAdButton, setIsPublishAdButton] = useState(false);

  const [constantsFeaturesData, setConstantsFeaturesData] = useState({
    CONDO: [],
    NEAR: [],
    PROPERTY: [],
  });

  const imagesAdRef = useRef(null);
  const panoramasAdRef = useRef(null);
  const floorPlansAdRef = useRef(null);
  const videoAdRef = useRef(null);
  const guaranteesAdRef = useRef(null);
  const featuresAdRef = useRef(null);
  const commonAreaFeaturesAdRef = useRef(null);
  const surroundingFeaturesAdRef = useRef(null);

  const {
    register,
    handleSubmit,
    formState,
    control,
    setValue,
    watch,
    clearErrors,
  } = useForm({
    resolver: yupResolver(formSchema),
    context: {
      adTypeIsRelease: adType === "Lançamento",
    },
  });

  const { errors } = formState;

  useEffect(() => {
    async function loadData() {
      try {
        const user = userService.retrieve();

        if (user && user.isLinked === true) {
          window.location.href = pathsConstants.HOME;

          return;
        }

        const data = await userProfileService.getUserBasicProfile({
          userId: user._id,
          token: user.token,
        });

        setUserType(data.userType);

        if (data.userType === 2) {
          setAdType("Usado");
        }

        setValue("name", user.name);
        setValue("email", user.email);

        if (data) {
          if (!!data.profile.professionalInformation.cellphone.number) {
            setValue(
              "cellphone",
              addMaskCellphone(
                data.profile.professionalInformation.cellphone.number
              )
            );
            setIsWhatsapp(
              data.profile.professionalInformation.cellphone.whatsApp
            );

            return;
          }

          if (!!data.profile.professionalInformation.telephone.number) {
            setValue(
              "cellphone",
              addMaskTelephone(
                data.profile.professionalInformation.telephone.number
              )
            );
          }
        }
      } catch {
        toast.error(
          "Houve um erro ao carregar seus dados do perfil, tente novamente!",
          {
            autoClose: false,
            toastId: customId,
          }
        );

        setHasErrorLoadProfile(true);
      }
    }

    loadData();
  }, []);

  useEffect(() => {
    const subscription = watch((data) => setCounter(data.description.length));

    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    async function loadData() {
      try {
        const { data: responseData, status } = await constantsService.getRealEstatesFeatures();

        if (status !== 200) {
          throw new Error('Não foi possível buscar as características. Recarregue a página!');
        }

        const { data: { CONDO, NEAR, PROPERTY } } = responseData;

        const featuresMapped = {
          CONDO: CONDO.map(item => {
            return {
              ...item,
              label: item.value,
              value: item.key,
            }
          }),
          NEAR: NEAR.map(item => {
            return {
              ...item,
              label: item.value,
              value: item.key,
            }
          }),
          PROPERTY: PROPERTY.map(item => {
            return {
              ...item,
              label: item.value,
              value: item.key,
            }
          }),
        };

        setConstantsFeaturesData(featuresMapped);
      } catch (err) {
        toast.error('Não foi possível buscar as características. Recarregue a página!', {
          autoClose: false,
        });
      }
    }

    loadData();
  }, []);

  async function getAddressByCep(zipCode) {
    let geolocation;

    let result;

    domEventsUtil.dispatch({
      name: eventConstants.LOADING_ANIMATION,
      params: { show: true },
    });

    try {
      result = await geolocationService.getAddressByZipCode({
        zipCode,
      });
    } catch (error) {
      // Do nothing.
    }

    if (result) {
      try {
        geolocation = await geolocationService.getGeolocationByAddress({
          zipCode,
          street: result.street,
          state: result.state,
          country: "Brazil",
        });

        setLatitude(geolocation.latitude);
        setLongitude(geolocation.longitude);

        if (!!result.neighborhood) {
          setValue("neighborhood", result.neighborhood);
          clearErrors("neighborhood");
        }

        setValue("street", result.street);
        setValue("city", result.city);
        setValue("state", result.state);
        clearErrors("street");
        clearErrors("city");
        clearErrors("state");
      } catch (err) {
        toast.error("Erro verificando geolocalização do endereço.", {
          autoClose: false,
          toastId: customId,
        });
      } finally {
        domEventsUtil.dispatch({
          name: eventConstants.LOADING_ANIMATION,
          params: { show: false },
        });
      }
    } else {
      domEventsUtil.dispatch({
        name: eventConstants.LOADING_ANIMATION,
        params: { show: false },
      });
    }
  }

  function handleZipCodeInputBlur(event) {
    if (!!event.target.value) {
      if (event.target.value.length === 9) {
        getAddressByCep(event.target.value);
      }
    }
  }

  function handleSelectAdType(type) {
    if (type === "Lançamento") {
      setAdType("Lançamento");
      setTransactionType("Venda");

      setValue("bedrooms", "");
      setValue("bathrooms", "");
      setValue("suites", "");
      setValue("parkingSpaces", "");
      setValue("area", "");
      setValue("totalArea", "");
    }

    if (type === "Usado") {
      setAdType("Usado");

      setValue("bedroomsFrom", "");
      setValue("bedroomsTo", "");
      setValue("bathroomsFrom", "");
      setValue("bathroomsTo", "");
      setValue("suitesFrom", "");
      setValue("suitesTo", "");
      setValue("parkingSpacesFrom", "");
      setValue("parkingSpacesTo", "");
      setValue("areaFrom", "");
      setValue("areaTo", "");
      setValue("totalAreaFrom", "");
      setValue("totalAreaTo", "");
    }
  }

  async function handleFormSubmit(values) {
    function handlePrices() {
      let main = formatPrice(values.price);
      let rental = "";

      function formatPrice(price) {
        return price?.replace(/[R$\.\,]/g, "").trim() ?? "";
      }

      if (isSaleAndRental) {
        if (transactionType === "Venda") {
          main = formatPrice(values.price);
          rental = formatPrice(values.rentalWithSale);
        }

        if (transactionType === "Locação") {
          main = formatPrice(values.saleWithRental);
          rental = formatPrice(values.price);
        }
      }

      if (values.price === undefined) {
        return {
          main: 0,
          rental: formatPrice(rental),
        }
      }

      return {
        main: formatPrice(main),
        rental: formatPrice(rental),
      };
    }

    let formData = {
      ...values,
      ...imagesAdRef.current.state.form,
      ...panoramasAdRef.current.state.form,
      ...videoAdRef.current.state.form,
      use: values.propertyUse,
      type: values.propertyType,
      guarantees: [],
      additionalInfo: values.complement,
      latitude,
      longitude,
      features: {
        property: featuresAdRef.current.state.form.features,
        condo: commonAreaFeaturesAdRef.current.state.form.features,
        near: surroundingFeaturesAdRef.current.state.form.features,
      },
      bedrooms: values.bedroomsFrom
        ? [values.bedroomsFrom, values.bedroomsTo]
        : [values.bedrooms],
      bathrooms: values.bathroomsFrom
        ? [values.bathroomsFrom, values.bathroomsTo]
        : [values.bathrooms],
      suites: values.suitesFrom
        ? [values.suitesFrom, values.suitesTo]
        : [values.suites],
      parkingSpaces: values.parkingSpacesFrom
        ? [values.parkingSpacesFrom, values.parkingSpacesTo]
        : [values.parkingSpaces],
      area: values.areaFrom ? [values.areaFrom, values.areaTo] : [values.area],
      totalArea: values.totalAreaFrom
        ? [values.totalAreaFrom, values.totalAreaTo]
        : [values.totalArea],
      listing: userType !== 2 ? adType : "Usado",
      transaction: isSaleAndRental ? `${transactionType}|${transactionType === 'Venda' ? 'Locação' : 'Venda'}` : transactionType,
      development: {
        name:
          adType === "Lançamento" &&
            transactionType === "Venda" &&
            userType !== 2
            ? values.enterpriseName
            : "",
        constructionStatus:
          adType === "Lançamento" &&
            transactionType === "Venda" &&
            userType !== 2
            ? enterpriseStatus
            : "Pronto para Morar",
        buildings:
          adType === "Lançamento" &&
            transactionType === "Venda" &&
            userType !== 2
            ? values.towerNumber
            : "",
        floors:
          adType === "Lançamento" &&
            transactionType === "Venda" &&
            userType !== 2
            ? values.floorsNumber
            : 0,
        apartmentsPerFloor:
          adType === "Lançamento" &&
            transactionType === "Venda" &&
            userType !== 2
            ? values.apartmentsPerFloor
            : 0,
      },
      // price: values.price.replace(/[R$\.\,]/g, "").trim(): values.rentalWithSale ? values.rentalWithSale.replace(/[R$\.\,]/g, "").trim() : "",
      // rental: values.rental ? values.rental.replace(/[R$\.\,]/g, "").trim() : values.rentalWithSale ? values.rentalWithSale.replace(/[R$\.\,]/g, "").trim() : "",
      price: handlePrices().main,
      rental: handlePrices().rental,
      condo: values.condo ? values.condo.replace(/[R$\.\,]/g, "").trim() : "",
      iptu: values.iptu ? values.iptu.replace(/[R$\.\,]/g, "").trim() : "",
      showOnMap: showCompleteAddressInTheAd,
      phoneNumber: values.cellphone
        ? values.cellphone.replace(/[^a-zA-Z0-9]+/g, "").substring(2)
        : "",
      whatsApp: isWhatsapp,
    };

    // console.log(formData)

    // return;

    if (adType === "Lançamento") {
      formData = {
        ...formData,
        ...floorPlansAdRef.current.state.form,
      }
    }

    if (adType !== "Lançamento" || transactionType !== "Venda") {
      delete formData.development.name;
      delete formData.development.buildings;
    }

    if (transactionType === "Locação") {
      formData = {
        ...formData,
        guarantees: guaranteesAdRef.current.state.guarantees,
      };
    }

    if (formData.images.length < 3) {
      toast.error(
        "Você deve inserir pelos menos 3 imagens para finalizar a criação de seu anúncio",
        {
          autoClose: false,
          toastId: customId,
        }
      );

      scroller.scrollTo("images-ad-component", {
        duration: 400,
        offset: -100,
      });

      return;
    }

    domEventsUtil.dispatch({
      name: eventConstants.LOADING_ANIMATION,
      params: { show: true },
    });

    const { user, history } = props;

    let realEstateId;

    try {
      if (!user || !user.token) {
        history.push(
          `${pathsConstants.AUTHENTICATION}/${pathsConstants.AUTHENTICATION_PARAMS.LOGIN}`
        );
      } else {
        if (!propertyHasRegistered) {
          realEstateId = await realEstateService.createRealEstateRealtor({
            data: formData,
            user: user,
          });

          if (!realEstateId) {
            throw "Ocorreu um erro ao cadastrar as informações do imóvel!";
          }

          setPropertyHasRegistered(true);
        }
      }
    } catch (err) {
      toast.error(err, {
        autoClose: false,
        toastId: customId,
      });
    } finally {
      domEventsUtil.dispatch({
        name: eventConstants.LOADING_ANIMATION,
        params: { show: false },
      });
    }

    try {
      domEventsUtil.dispatch({
        name: eventConstants.LOADING_ANIMATION,
        params: { show: true },
      });

      if (!user || !user.token) {
        history.push(
          `${pathsConstants.AUTHENTICATION}/${pathsConstants.AUTHENTICATION_PARAMS.LOGIN}`
        );
      } else {
        if (!imagesHasUploaded && realEstateId) {
          await realEstateService.uploadImages({
            pictureBlobs: formData.images.map(({ blob }) => blob),
            floorPlanBlobs: adType === "Lançamento" ? formData.floorPlans.map(({ blob }) => blob) : [],
            panoramaBlobs: formData.panoramas.map(({ blob }) => blob),
            realEstateId: realEstateId,
            token: user.token,
          });

          setImagesHasUploaded(true);

          toast.success("O imóvel foi cadastrado com sucesso!", {
            autoClose: false,
            toastId: customId,
          });
        }
      }

      if (isPublishAdButton) {
        try {
          await realEstateService.addEstateProduct({
            token: user.token,
            realId: realEstateId,
          })
        } catch {
          toast.info(
            "As informações do seu imóvel foram cadastradas, porém ocorreu um erro ao anunciar o imóvel. Mas calma! Você pode fazer o anúncio manualmente acessando seu imóvel pela dashboard.",
            {
              autoClose: false,
              toastId: customId,
            }
          );
        }
      }
    } catch {
      toast.info(
        "As informações do seu imóvel foram cadastradas, porém ocorreu um erro ao anexar suas imagens. Mas calma! Você pode adicioná-las acessando seu imóvel pela dashboard.",
        {
          autoClose: false,
          toastId: customId,
        }
      );
    } finally {
      domEventsUtil.dispatch({
        name: eventConstants.LOADING_ANIMATION,
        params: { show: false },
      });

      history.push({
        pathname: pathsConstants.DASHBOARD_ADS,
      });
    }
  }

  function onInvalidForm(fieldValues) {
    if (!!fieldValues.price && fieldValues.price.type === "required") {
      scroller.scrollTo("price-input", {
        duration: 400,
        offset: -140,
      });
    }
  }

  return (
    <Container>
      {hasErrorLoadProfile ? (
        <LoadDataErrorContainer>
          <h2>Ops...</h2>

          <p>
            Por algum motivo não foi possível carregar as informações
            necessárias para o preenchimento do formulário. Por favor,
            recarregue a página ou retorne à dashboard.
          </p>

          <ReturnDashboardButton onClick={props.history.goBack}>
            Retornar à dashboard
          </ReturnDashboardButton>
        </LoadDataErrorContainer>
      ) : (
        <>
          <Title>Cadastrar Imóvel</Title>

          <Form onSubmit={handleSubmit(handleFormSubmit, onInvalidForm)}>
            {userType !== null && userType !== 2 && (
              <>
                <SectionTitle>Selecione o tipo de anúncio</SectionTitle>

                <ButtonsOptions
                  option1="Lançamento"
                  option2="Usado"
                  option1Active={adType === "Lançamento"}
                  option2Active={adType === "Usado"}
                  option1OnClick={() => handleSelectAdType("Lançamento")}
                  option2OnClick={() => handleSelectAdType("Usado")}
                />
              </>
            )}

            {adType !== "Lançamento" && (
              <>
                <SectionTitle>Selecione a transação</SectionTitle>
                <ButtonsOptions
                  option1="Venda"
                  option2="Locação"
                  option1Active={transactionType === "Venda"}
                  option2Active={transactionType === "Locação"}
                  option1OnClick={() => setTransactionType("Venda")}
                  option2OnClick={() => setTransactionType("Locação")}
                />
              </>
            )}

            <SectionTitle>Tipo de imóvel que deseja anunciar</SectionTitle>

            <PropertiesSelectsContainer>
              <SelectBootstrap
                name="propertyUse"
                label="Uso"
                options={propertyUsesItems}
                error={errors.propertyUse}
                {...register("propertyUse")}
              />

              <SelectBootstrap
                name="propertyType"
                label="Tipo"
                options={propertyTypesItems}
                error={errors.propertyType}
                {...register("propertyType")}
              />
            </PropertiesSelectsContainer>

            <AddressContainer>
              <SectionTitle>Localização do seu imóvel</SectionTitle>

              <CepInput
                name="zipCode"
                label="Cep"
                error={errors.zipCode}
                control={control}
                // onBlurInput={handleZipCodeInputBlur}
                {...register("zipCode", {
                  onBlur: handleZipCodeInputBlur,
                })}
              />
              <p style={{ marginTop: 5, fontSize: 12 }}>
                Não sabe seu CEP? Clique{" "}
                <a
                  target="_blank"
                  href="http://www.buscacep.correios.com.br/sistemas/buscacep/buscaCep.cfm"
                >
                  aqui
                </a>
              </p>

              <AddressStreetInputContainer className="mb-3">
                <InputBootstrap
                  name="street"
                  label="Endereço"
                  placeholder="Endereço"
                  error={errors.street}
                  {...register("street")}
                />
              </AddressStreetInputContainer>

              <TabletInliner>
                <AddressNumberInputContainer>
                  <InputBootstrap
                    name="number"
                    label="Número"
                    placeholder="Número"
                    type="number"
                    error={errors.number}
                    {...register("number")}
                  />
                </AddressNumberInputContainer>

                <AddressComplementInputContainer>
                  <InputBootstrap
                    name="complement"
                    label="Complemento"
                    placeholder="Complemento"
                    error={errors.complement}
                    {...register("complement")}
                  />
                </AddressComplementInputContainer>
              </TabletInliner>

              <AddressNeighborhoodInputContainer className="mt-3">
                <InputBootstrap
                  name="neighborhood"
                  label="Bairro"
                  placeholder="Bairro"
                  error={errors.neighborhood}
                  {...register("neighborhood")}
                />
              </AddressNeighborhoodInputContainer>

              <TabletInliner className="mt-3">
                <AddressCityInputContainer>
                  <InputBootstrap
                    name="city"
                    label="Cidade"
                    placeholder="Cidade"
                    error={errors.city}
                    {...register("city")}
                  />
                </AddressCityInputContainer>

                <AddressStateInputContainer>
                  <SelectBootstrap
                    name="state"
                    label="Estado"
                    options={stateAcronymsItems}
                    error={errors.state}
                    {...register("state")}
                  />
                </AddressStateInputContainer>
              </TabletInliner>

              <AddressCompleteInTheAd>
                {showCompleteAddressInTheAd ? (
                  <>
                    As informações de localização do seu imóvel serão exibidas
                    no corpo do anúncio
                  </>
                ) : (
                  <>
                    Nosso sistema de busca utiliza o mapa como ferramenta de filtragem. 
                    O endereço completo será utilizado apenas para localizar geograficamente o seu imóvel. Se você quiser
                    mostrar o endereço no anúncio, selecione a caixa abaixo.
                  </>
                )}
              </AddressCompleteInTheAd>

              <CheckboxInputWrapper
                label="Mostrar o endereço completo no anúncio."
                htmlFor="showOnMap"
              >
                <ShowOnMapInput
                  id="showOnMap"
                  name="showOnMap"
                  checked={showCompleteAddressInTheAd}
                  onClick={() =>
                    setShowCompleteAddressInTheAd((prevState) => !prevState)
                  }
                />
              </CheckboxInputWrapper>
            </AddressContainer>

            {adType === "Lançamento" &&
              transactionType === "Venda" &&
              userType !== 2 && (
                <>
                  <SectionTitle>Empreendimento</SectionTitle>
                  <InputBootstrap
                    name="enterpriseName"
                    label="Nome do empreendimento"
                    placeholder="Nome do empreendimento"
                    error={errors.enterpriseName}
                    {...register("enterpriseName")}
                  />

                  <EnterpriseStatusTitle>
                    Status do empreendimento
                  </EnterpriseStatusTitle>
                  <SelectContainer>
                    <SelectStep
                      type="button"
                      onClick={() => setEnterpriseStatus("Na Planta")}
                    >
                      <StepBulletContainer
                        active={enterpriseStatus === "Na Planta"}
                      >
                        <StepBulletGap>
                          <StepBullet
                            active={enterpriseStatus === "Na Planta"}
                          />
                        </StepBulletGap>
                      </StepBulletContainer>
                      <SelectTextItem active={enterpriseStatus === "Na Planta"}>
                        Na planta
                      </SelectTextItem>
                    </SelectStep>

                    <SelectStep
                      type="button"
                      onClick={() => setEnterpriseStatus("Em Construção")}
                    >
                      <StepBulletContainer
                        active={enterpriseStatus === "Em Construção"}
                      >
                        <StepBulletGap>
                          <StepBullet
                            active={enterpriseStatus === "Em Construção"}
                          />
                        </StepBulletGap>
                      </StepBulletContainer>
                      <SelectTextItem
                        active={enterpriseStatus === "Em Construção"}
                      >
                        Em construção
                      </SelectTextItem>
                    </SelectStep>

                    <SelectStep
                      type="button"
                      onClick={() => setEnterpriseStatus("Pronto para Morar")}
                    >
                      <StepBulletContainer
                        active={enterpriseStatus === "Pronto para Morar"}
                      >
                        <StepBulletGap>
                          <StepBullet
                            active={enterpriseStatus === "Pronto para Morar"}
                          />
                        </StepBulletGap>
                      </StepBulletContainer>
                      <SelectTextItem
                        active={enterpriseStatus === "Pronto para Morar"}
                      >
                        Pronto para morar
                      </SelectTextItem>
                    </SelectStep>
                    <StepLine />
                  </SelectContainer>

                  <TabletInliner>
                    <InputBootstrap
                      name="towerNumber"
                      label="Número de torres"
                      placeholder="Número de torres"
                      type="number"
                      error={errors.towerNumber}
                      {...register("towerNumber")}
                    />

                    <AddressNumberInputContainer>
                      <InputBootstrap
                        name="floorsNumber"
                        label="Número de andares"
                        placeholder="Número de andares"
                        type="number"
                        error={errors.floorsNumber}
                        {...register("floorsNumber")}
                      />
                    </AddressNumberInputContainer>

                    <InputBootstrap
                      name="apartmentsPerFloor"
                      label="Apartamentos por andar"
                      placeholder="Apartamentos por andar"
                      type="number"
                      error={errors.apartmentsPerFloor}
                      {...register("apartmentsPerFloor")}
                    />
                  </TabletInliner>
                </>
              )}

            <PropertyFeaturesContainer>
              <SectionTitle>Características do imóvel</SectionTitle>

              {adType === "Lançamento" ? (
                <ReleaseFeatures>
                  <FeatureContainer>
                    <LabelInputPropertyFeature>
                      N° de Quartos
                    </LabelInputPropertyFeature>
                    <TabletInliner>
                      <PropertyFeaturesBedroomsInputContainer>
                        <InputBootstrap
                          name="bedroomsFrom"
                          label="De"
                          placeholder="De"
                          error={errors.bedroomsFrom}
                          {...register("bedroomsFrom")}
                        />
                      </PropertyFeaturesBedroomsInputContainer>

                      <PropertyFeaturesBathroomsInputContainer>
                        <InputBootstrap
                          name="bedroomsTo"
                          label="Até"
                          placeholder="Até"
                          error={errors.bedroomsTo}
                          {...register("bedroomsTo")}
                        />
                      </PropertyFeaturesBathroomsInputContainer>
                    </TabletInliner>
                  </FeatureContainer>
                  <FeatureContainer>
                    <LabelInputPropertyFeature>
                      N° de Banheiros
                    </LabelInputPropertyFeature>
                    <TabletInliner>
                      <PropertyFeaturesBedroomsInputContainer>
                        <InputBootstrap
                          name="bathroomsFrom"
                          label="De"
                          placeholder="De"
                          error={errors.bathroomsFrom}
                          {...register("bathroomsFrom")}
                        />
                      </PropertyFeaturesBedroomsInputContainer>

                      <PropertyFeaturesBathroomsInputContainer>
                        <InputBootstrap
                          name="bathroomsTo"
                          label="Até"
                          placeholder="Até"
                          error={errors.bathroomsTo}
                          {...register("bathroomsTo")}
                        />
                      </PropertyFeaturesBathroomsInputContainer>
                    </TabletInliner>
                  </FeatureContainer>
                  <FeatureContainer>
                    <LabelInputPropertyFeature>
                      N° de Suítes
                    </LabelInputPropertyFeature>
                    <TabletInliner>
                      <PropertyFeaturesBedroomsInputContainer>
                        <InputBootstrap
                          name="suitesFrom"
                          label="De"
                          placeholder="De"
                          error={errors.suitesFrom}
                          {...register("suitesFrom")}
                        />
                      </PropertyFeaturesBedroomsInputContainer>

                      <PropertyFeaturesBathroomsInputContainer>
                        <InputBootstrap
                          name="suitesTo"
                          label="Até"
                          placeholder="Até"
                          error={errors.suitesTo}
                          {...register("suitesTo")}
                        />
                      </PropertyFeaturesBathroomsInputContainer>
                    </TabletInliner>
                  </FeatureContainer>

                  <FeatureContainer>
                    <LabelInputPropertyFeature>
                      N° de Vagas
                    </LabelInputPropertyFeature>
                    <TabletInliner>
                      <PropertyFeaturesBedroomsInputContainer>
                        <InputBootstrap
                          name="parkingSpacesFrom"
                          label="De"
                          placeholder="De"
                          error={errors.parkingSpacesFrom}
                          {...register("parkingSpacesFrom")}
                        />
                      </PropertyFeaturesBedroomsInputContainer>

                      <PropertyFeaturesBathroomsInputContainer>
                        <InputBootstrap
                          name="parkingSpacesTo"
                          label="Até"
                          placeholder="Até"
                          error={errors.parkingSpacesTo}
                          {...register("parkingSpacesTo")}
                        />
                      </PropertyFeaturesBathroomsInputContainer>
                    </TabletInliner>
                  </FeatureContainer>

                  <FeatureContainer>
                    <LabelInputPropertyFeature>
                      Área Útil (m²)
                    </LabelInputPropertyFeature>
                    <TabletInliner>
                      <PropertyFeaturesBedroomsInputContainer>
                        <InputBootstrap
                          name="areaFrom"
                          label="De"
                          placeholder="De"
                          error={errors.areaFrom}
                          {...register("areaFrom")}
                        />
                      </PropertyFeaturesBedroomsInputContainer>

                      <PropertyFeaturesBathroomsInputContainer>
                        <InputBootstrap
                          name="areaTo"
                          label="Até"
                          placeholder="Até"
                          error={errors.areaTo}
                          {...register("areaTo")}
                        />
                      </PropertyFeaturesBathroomsInputContainer>
                    </TabletInliner>
                  </FeatureContainer>

                  <FeatureContainer>
                    <LabelInputPropertyFeature>
                      Área Total (m²)
                    </LabelInputPropertyFeature>
                    <TabletInliner>
                      <PropertyFeaturesBedroomsInputContainer>
                        <InputBootstrap
                          name="totalAreaFrom"
                          label="De"
                          placeholder="De"
                          error={errors.totalAreaFrom}
                          {...register("totalAreaFrom")}
                        />
                      </PropertyFeaturesBedroomsInputContainer>

                      <PropertyFeaturesBathroomsInputContainer>
                        <InputBootstrap
                          name="totalAreaTo"
                          label="Até"
                          placeholder="Até"
                          error={errors.totalAreaTo}
                          {...register("totalAreaTo")}
                        />
                      </PropertyFeaturesBathroomsInputContainer>
                    </TabletInliner>
                  </FeatureContainer>
                </ReleaseFeatures>
              ) : (
                <>
                  <TabletInliner>
                    <PropertyFeaturesBedroomsInputContainer>
                      <InputBootstrap
                        name="bedrooms"
                        label="Nº Quartos"
                        placeholder="Nº Quartos"
                        error={errors.bedrooms}
                        {...register("bedrooms")}
                      />
                    </PropertyFeaturesBedroomsInputContainer>

                    <PropertyFeaturesBathroomsInputContainer>
                      <InputBootstrap
                        name="bathrooms"
                        label="Nº Banheiros"
                        placeholder="Nº Banheiros"
                        error={errors.bathrooms}
                        {...register("bathrooms")}
                      />
                    </PropertyFeaturesBathroomsInputContainer>
                  </TabletInliner>
                  <TabletInliner>
                    <PropertyFeaturesBedroomsInputContainer>
                      <InputBootstrap
                        name="suites"
                        label="Nº Suítes"
                        placeholder="Nº Suítes"
                        error={errors.suites}
                        {...register("suites")}
                      />
                    </PropertyFeaturesBedroomsInputContainer>

                    <PropertyFeaturesBathroomsInputContainer>
                      <InputBootstrap
                        name="parkingSpaces"
                        label="Nº Vagas"
                        placeholder="Nº Vagas"
                        error={errors.parkingSpaces}
                        {...register("parkingSpaces")}
                      />
                    </PropertyFeaturesBathroomsInputContainer>
                  </TabletInliner>

                  <TabletInliner>
                    <PropertyFeaturesBedroomsInputContainer>
                      <InputBootstrap
                        name="area"
                        label="Área Útil (m²)"
                        placeholder="Área Útil (m²)"
                        error={errors.area}
                        {...register("area")}
                      />
                    </PropertyFeaturesBedroomsInputContainer>

                    <PropertyFeaturesBathroomsInputContainer>
                      <InputBootstrap
                        name="totalArea"
                        label="Área Total (m²)"
                        placeholder="Área Total (m²)"
                        error={errors.totalArea}
                        {...register("totalArea")}
                      />
                    </PropertyFeaturesBathroomsInputContainer>
                  </TabletInliner>
                </>
              )}
            </PropertyFeaturesContainer>

            <SectionTitle>Informações do anúncio</SectionTitle>

            {/* <InputBootstrap
              name="title"
              label="Título do anúncio"
              placeholder="Título do anúncio"
              error={errors.title}
              {...register("title")}
            /> */}

            <TextArea
              name="description"
              label="Descrição do imóvel"
              height={190}
              error={errors.description}
              {...register("description")}
            />

            <Counter>{counter} caracteres</Counter>

            <ValuesContainer>
              <SectionTitle>Valores</SectionTitle>

              <Element id="price-input">
                <PriceInput
                  name="price"
                  label={
                    transactionType === "Venda"
                      ? "Valor de venda (R$)"
                      : "Valor do aluguel (R$)"
                  }
                  error={errors.price}
                  control={control}
                  {...register("price")}
                />
              </Element>
              {adType === "Usado" && (
                <>
                  <CheckboxInputWrapper
                    label={`Desejo que o imóvel também esteja disponível para ${transactionType === "Venda" ? "locação" : "venda"
                      }.`}
                    htmlFor="SaleAndRental"
                  >
                    <HasWhatsappInput
                      id="SaleAndRental"
                      name="SaleAndRental"
                      checked={isSaleAndRental}
                      onClick={() =>
                        setIsSaleAndRental((prevState) => !prevState)
                      }
                    />
                  </CheckboxInputWrapper>
                  {isSaleAndRental && (
                    <PriceInput
                      name={
                        transactionType === "Venda"
                          ? "rentalWithSale"
                          : "saleWithRental"
                      }
                      label={
                        transactionType === "Venda"
                          ? "Valor do aluguel (R$)"
                          : "Valor de venda (R$)"
                      }
                      error={
                        transactionType === "Venda"
                          ? errors.rental
                          : errors.price
                      }
                      control={control}
                      {...register(
                        transactionType === "Venda"
                          ? "rentalWithSale"
                          : "saleWithRental"
                      )}
                    />
                  )}
                </>
              )}

              <PriceInput
                name="condo"
                label="Condomínio (R$)"
                error={errors.condo}
                control={control}
                {...register("condo")}
              />

              <PriceInput
                name="iptu"
                label="IPTU (R$ MENSAL)"
                error={errors.iptu}
                control={control}
                {...register("iptu")}
              />
            </ValuesContainer>

            {transactionType === "Locação" && (
              <AdditionalInfo
                ref={guaranteesAdRef}
                showInvalids={false}
                onSelectItems={() => { }}
                guaranteesIsRequired={false}
                showTypeOfGuarantees={transactionType === "Locação"}
              />
            )}

            {!!constantsFeaturesData.PROPERTY.length && (
              <>
                <SectionTitle>Detalhamento do imóvel</SectionTitle>
                <Features
                  ref={featuresAdRef}
                  features={[]}
                  data={constantsFeaturesData.PROPERTY}
                />
              </>
            )}

            {!!constantsFeaturesData.CONDO.length && (
              <>
                <SectionTitle>Áreas comuns</SectionTitle>
                <CommonAreaFeatures
                  ref={commonAreaFeaturesAdRef}
                  data={constantsFeaturesData.CONDO}
                />
              </>
            )}

            {!!constantsFeaturesData.NEAR.length && (
              <>
                <SectionTitle>Entorno</SectionTitle>
                <SurroundingFeatures
                ref={surroundingFeaturesAdRef}
                data={constantsFeaturesData.NEAR}
                />
              </>
            )}

            <Element id="images-ad-component">
              <SectionTitle>Fotos do anúncio</SectionTitle>
              <Images ref={imagesAdRef} />
            </Element>

            <SectionTitle>Panorama</SectionTitle>
            <Panoramas ref={panoramasAdRef} />

            {adType === "Lançamento" && (
              <>
                <SectionTitle>Planta baixa</SectionTitle>
                <FloorPlans ref={floorPlansAdRef} />
              </>
            )}

            <SectionTitle>Vídeo</SectionTitle>
            <Video ref={videoAdRef} video="" />

            <AdvertiserDataContainer>
              <SectionTitle>Dados do anunciante</SectionTitle>

              <InputBootstrap
                name="name"
                label="Nome"
                placeholder="Nome"
                error={errors.name}
                {...register("name")}
              />

              <InputBootstrap
                name="email"
                label="E-mail"
                placeholder="E-mail"
                error={errors.email}
                {...register("email")}
              />

              <CellphoneInput
                name="cellphone"
                label="Telefone/Celular"
                error={errors.cellphone}
                control={control}
                {...register("cellphone")}
              />

              <CheckboxInputWrapper label="WhatsApp?" htmlFor="whatsApp">
                <HasWhatsappInput
                  id="whatsApp"
                  name="whatsApp"
                  checked={isWhatsapp}
                  onClick={() => setIsWhatsapp((prevState) => !prevState)}
                />
              </CheckboxInputWrapper>
            </AdvertiserDataContainer>

            <ButtonWrapper>
              <SaveButton>cadastrar</SaveButton>
              <SaveAndPublishButton onClick={() => {
                setIsPublishAdButton(true)
              }}>cadastrar e anunciar</SaveAndPublishButton>
            </ButtonWrapper>
          </Form>
        </>
      )}
    </Container>
  );
}
