import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { RiSearchLine, RiLinksLine, RiDeleteBinLine } from 'react-icons/ri';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';

import attachBrokersService from '../../../services/attach-brokers/attach-brokers.service';
import userService from '../../../services/user/user.service';

import domEventsUtil from '../../../utils/dom-events/dom-events.util';
import eventsConstants from '../../../constants/events';
import pathsConstants from '../../../constants/paths';

import {
  Wrapper,
  FilterForm,
  ClearFilterAndNewBroker,
  ActionButtons,
  ModalContent,
  DeleteBrokerDialog,
  BrokerInfoDialog,
  BrokerActionButtons,
  NewBrokerForm,
  NewBrokerInputWrapper,
  ClearFilterButton,
  CancelRegisterBrokerButton
} from './styles';

const customStyles = {
  rows: {
    style: {
      fontSize: '16px'
    },
  },
  headCells: {
    style: {
      fontSize: '15px',
      fontWeight: '600'
    },
  },
  cells: {
  },
};

export function MyBrokers() {
  const [brokersCompanyData, setBrokersCompanyData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);

  const [modalDeleteBrokerVisible, setModalDeleteBrokerVisible] = useState(false);
  const [modalNewBrokerVisible, setModalNewBrokerVisible] = useState(false);

  const [brokerName, setBrokerName] = useState('');
  const [brokerEmail, setBrokerEmail] = useState('');

  const [brokerToBeRemoved, setBrokerToBeRemoved] = useState({});

  const [emailFilter, setEmailFilter] = useState('');

  async function fetchBrokersCompany({ page }) {
    try {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: true },
      });

      const user = userService.retrieve();

      const { data, status } = await attachBrokersService.getCompanyBrokers({
        companyId: user.company._id,
        page,
        pageSize: perPage,
        email: emailFilter,
        token: user.token,
      });

      if (status !== 200) {
        throw new Error('Não foi possível buscar os dados, recarregue a página!')
      }

      const { users, pagination } = data;

      const brokersDataSerialized = users.map(item => {
        return {
          id: item._id,
          name: item.name,
          email: item.email,
          creci: !!item.creci ? item.creci : 'Não informado',
        }
      });

      setBrokersCompanyData(brokersDataSerialized);
      setTotalRows(pagination.total);
      setCurrentPage(page);
    } catch (err) {
      toast.error(err.message);
    } finally {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: false },
      });
    }
  }

  const handlePageChange = page => {
    fetchBrokersCompany({ page });
  };

  function handleOpenModalDeleteBroker({ broker }) {
    const { row } = broker;

    setBrokerToBeRemoved(row);
    setModalDeleteBrokerVisible(true);
  }

  async function handleRegisterBroker(event) {
    event.preventDefault();

    try {
      setLoading(true);

      const user = userService.retrieve();

      const response = await attachBrokersService.registerBroker({
        companyId: user.company._id,
        brokerName,
        brokerEmail,
        token: user.token,
      });

      if (response.status !== 201) {
        if (response.response && response.response.data.message === 'Realtor already exist.') {
          throw new Error('Corretor já existente na base de dados!');
        } else {
          throw new Error('Não foi possível cadastrar o corretor, tente novamente!');
        }
      }

      toast.success('Corretor cadastrado com sucesso!');
      fetchBrokersCompany({ page: currentPage });
    } catch (err) {
      toast.error(err.message);
    } finally {
      setLoading(false);
      setModalNewBrokerVisible(false);
      setBrokerName('');
      setBrokerEmail('');
    }
  }

  const handlePerRowsChange = async (newPerPage, page) => {
    try {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: true },
      });

      const user = userService.retrieve();

      const { data, status } = await attachBrokersService.getCompanyBrokers({
        companyId: user.company._id,
        page,
        email: emailFilter,
        pageSize: newPerPage,
        token: user.token,
      });

      if (status !== 200) {
        throw new Error('Não foi possível buscar os dados, recarregue a página!')
      }

      const { users } = data;

      const brokersDataSerialized = users.map(item => {
        return {
          id: item._id,
          name: item.name,
          email: item.email,
          creci: !!item.creci ? item.creci : 'Não informado',
        }
      });

      setBrokersCompanyData(brokersDataSerialized);

      setPerPage(newPerPage);
    } catch (err) {
      toast.error(err.message);
    } finally {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: false },
      });
    }
  };

  async function handleDeleteBrokerCompany() {
    try {
      setLoading(true);

      const user = userService.retrieve();

      const { status } = await attachBrokersService.deleteBrokerCompany({
        brokerId: brokerToBeRemoved.id,
        companyId: user.company._id,
        token: user.token
      });

      if (status !== 204) {
        throw new Error('Não foi possível removê-lo, tente novamente!')
      }

      toast.success('Corretor removido com sucesso!');
      fetchBrokersCompany({ page: currentPage });
    } catch (err) {
      toast.error(err.message);
    } finally {
      setLoading(false);
      setModalDeleteBrokerVisible(false)
    }
  }

  const Buttons = (row) => {
    const {row: rowContent} = row;

    return (
      <ActionButtons>
        <button
          type="button"
          title="Anexar imóvel"
          onClick={() => {
            window.open(`${pathsConstants.DASHBOARD_BROKERS_ATTACH_PROPERTY}?brokerId=${rowContent.id}`, '_blank')
          }}
        >
          <RiLinksLine size={22} />
        </button>

        <button
          type="button"
          title="Excluir corretor"
          onClick={() => handleOpenModalDeleteBroker({ broker: row })}
        >
          <RiDeleteBinLine size={22} />
        </button>
      </ActionButtons>
    )
  }

  const columns = [
    {
      name: 'Nome',
      selector: row => row.name,

    },
    {
      name: 'E-mail',
      selector: row => row.email,
    },
    {
      name: 'Creci',
      selector: row => row.creci,
    },
    {
      name: 'Ações',
      cell: (row) => <Buttons row={row} />,
      button: true,
      ignoreRowClick: true,
      allowOverflow: true,
    }
  ];

  function handleFormFilter(event) {
    event.preventDefault();

    fetchBrokersCompany({ page: 1 });
  }

  // function handleClearFilter() {
  //   setEmailFilter('');
  // }

  useEffect(() => {
    if (emailFilter === '') {
      fetchBrokersCompany({ page: 1 });
    }

  }, [emailFilter])

  return (
    <Wrapper>
      <header>
        <FilterForm onSubmit={handleFormFilter}>
          <input
            value={emailFilter}
            onChange={event => setEmailFilter(event.target.value)}
            placeholder="Digite o e-mail do corretor"
            required
          />

          <button type="submit" title="Pesquisar"><RiSearchLine size={24} /></button>
        </FilterForm>

        <ClearFilterAndNewBroker>
          {/* {!!emailFilter && (
            <ClearFilterButton type="button" title="Pesquisar" onClick={handleClearFilter}>
              Limpar filtro
            </ClearFilterButton>
          )} */}

          <button type="button" onClick={() => setModalNewBrokerVisible(true)}>
            + Novo
          </button>
        </ClearFilterAndNewBroker>
      </header>

      <DataTable
        columns={columns}
        data={brokersCompanyData}
        customStyles={customStyles}
        noDataComponent="Não há registros para exibir"
        pagination
        paginationComponentOptions={{
          rowsPerPageText: 'Corretores por página',
          rangeSeparatorText: 'de'
        }}
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
      />

      <Modal
        isOpen={modalDeleteBrokerVisible}
        onRequestClose={() => setModalDeleteBrokerVisible(false)}
        style={{
          content: {
            backgroundColor: 'transparent',
            border: 0,
            padding: 0,
            inset: 0,
            borderRadius: 0,
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)'
          }
        }}
      >
        <ModalContent>
          <DeleteBrokerDialog>
            <h3>Deseja realmente remover esse corretor?</h3>

            <BrokerInfoDialog>
              <p>Corretor: <span>{brokerToBeRemoved.name}</span></p>
              <p>Creci: <span>{brokerToBeRemoved.creci}</span></p>
            </BrokerInfoDialog>

            <BrokerActionButtons>
              {!loading && (
                <>
                  <button
                    type="button"
                    onClick={() => setModalDeleteBrokerVisible(false)}
                  >
                    Cancelar
                  </button>

                  <button
                    type="button"
                    onClick={handleDeleteBrokerCompany}
                  >
                    Sim, desejo removê-lo
                  </button>
                </>
              )}

              {loading && <CircularProgress color="#2B50D8" size={28} />}
            </BrokerActionButtons>
          </DeleteBrokerDialog>
        </ModalContent>

      </Modal>

      <Modal
        isOpen={modalNewBrokerVisible}
        onRequestClose={() => setModalNewBrokerVisible(false)}
        style={{
          content: {
            backgroundColor: 'transparent',
            border: 0,
            padding: 0,
            inset: 0,
            borderRadius: 0,
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.8)'
          }
        }}
      >
        <ModalContent>
          <NewBrokerForm onSubmit={handleRegisterBroker}>
            <h3>Cadastrar novo corretor</h3>

            <NewBrokerInputWrapper>
              <label htmlFor="name">Nome</label>
              <input
                type="text"
                id="name"
                value={brokerName}
                onChange={event => setBrokerName(event.target.value)}
                placeholder="Digite o nome do corretor"
                required
              />
            </NewBrokerInputWrapper>

            <NewBrokerInputWrapper>
              <label>E-mail</label>
              <input
                type="email"
                id="email"
                value={brokerEmail}
                onChange={event => setBrokerEmail(event.target.value)}
                placeholder="Digite o e-mail do corretor"
                required
              />
            </NewBrokerInputWrapper>

            <button type="submit" disabled={loading}>
              {!loading && 'Cadastrar'}

              {loading && <CircularProgress color="inherit" size={28} />}
            </button>

            <CancelRegisterBrokerButton
              type="button"
              onClick={() => setModalNewBrokerVisible(false)}
            >
              Cancelar
            </CancelRegisterBrokerButton>
          </NewBrokerForm>
        </ModalContent>
      </Modal>
    </Wrapper>
  );
};
