import React, { PureComponent } from "react";
import styled from "styled-components";
import propTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { toast } from 'react-toastify';
import './request.css'

import { backgrounder, mqTablet } from "../../../styled/helpers/helpers";
import {
  COLOR_BORDER,
  COLOR_4,
  COLOR_13,
  COLOR_7,
  COLOR_2,
  COLOR_17,
  COLOR_9,
  COLOR_3
} from "../../../styled/variables/variables";
import { ProfilePicture, Divider } from "../../../styled/components/components";
import { BaseLabel } from "../../../styled/labels/labels";
import { BaseButton } from "../../../styled/buttons/buttons";
import { TYPES as IMAGE_TYPES } from "../../../models/image/image.model";

import NoResults from "../../../components/no-results";
import Features from '../../search-detail/features'
import FancyModalButton from "../../../components/modal";

import pathsConstants from "../../../constants/paths";
import { DASHBOARD } from "../../../constants/paths";
import queryStringUtil from "../../../utils/query-string/query-string.util";
import realtorsService from "../../../services/realtors/realtors.service";
import currencyUtil from '../../../utils/currency/currency.util';


/*
  global
  process
  window
  console
  alert
*/
const customId = "custom-id-yes";
class Requests extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      requests: []
    };
  }

  componentDidMount() {
    this.getRequests();
  }

  getRequests = async () => {
    const { user } = this.props;
    let response;
    try {
      response = await realtorsService.get({
        token: user.token
      });
    } catch (error) {
      console.error(error);
      toast.error("Erro buscando solicitações.", {
        autoClose: false,
        toastId: customId,
      });
      return;
    }

    console.log(response);

    this.setState(prev => ({
      ...prev,
      requests: response || [],
      textContact: "none"
    }));
  };

  onClickContact = () => {
    let tC = "block";
    if (this.state.textContact !== tC) {
      tC = "block";
      console.log("block");
    } else {
      tC = "none";
    }
    this.setState(prev => ({
      ...prev,
      requests: this.state.requests,
      textContact: tC
    }));
  };

  deleteRequest = async (id) => {
    const { user } = this.props;
    if (window.confirm("Tem certeza que deseja excluir esta solicitação?")) {
      await realtorsService.remove({
        id,
        token: user.token
      });

      this.getRequests();
    }
  };

  hasAccess = () => {
    const { user } = this.props;

    return [0, 2, 3, 4].includes(user.userType);
  };

  render() {
    const { requests } = this.state;
    const { history, user } = this.props;

    return (
      <>
        {!this.hasAccess() && <Redirect to={DASHBOARD} />}
        <Wrapper>
          {requests.map(rq => {
            return (
              <>
                <div className={'container-result'}>
                  <div className={'container-result__type'}>
                    <span>{rq.types.transaction}</span>
                    <span>{rq.types.property}</span>
                    <div className={'container-result__button'}>
                      <button type={'button'} onClick={() => { this.deleteRequest(rq._id) }}>Apagar</button>
                    </div>
                  </div>

                  <div className={'container-result__price'}>
                    <label>Preço sugerido:</label>
                    <h1>{currencyUtil.format({
                      value: rq.realEstate.price,
                    })}</h1>
                  </div>
                  <div className={'container-result__info'}>
                    <span>{rq.address.city} - {rq.address.neighborhood}</span>
                    <div>
                      <span>{rq.realEstate.area} m²</span>
                      <span>{rq.realEstate.bedrooms} quarto(s)</span>
                      <span>{rq.realEstate.parkingSpaces} vaga(s)</span>
                      <span>{rq.realEstate.bathrooms} banheiro(s)</span>
                    </div>
                  </div>
                  <div className={'container-result__owner'}>
                    <h1>Contato do proprietário:</h1>
                    <span>{rq.owner.email} - {rq.owner.phone ? rq.owner.phone : 'Não há telefone disponível'}</span>
                  </div>
                  <div className={'container-result__msg'}>
                    <h1>Menssagem do proprietário:</h1>
                    <label>{rq.owner.message ? rq.owner.message : 'Não há mensagem disponível.'}</label>
                  </div>
                </div>
              </>
            )
          })}
          {requests.length === 0 && <NoResults />}
        </Wrapper>
      </>
    );
  }
}

Requests.propTypes = {
  user: propTypes.object,
  history: propTypes.object
};

Requests.defaultProps = {};

const ProfilePictureBig = styled(ProfilePicture)`
  height: 150px;
  width: 150px;
  margin-right: 15px;
`;

const ModalDataWrapper = styled.div`
  flex-wrap: wrap;
  width: 60%;
  align-content: center;
`;

const ModalPicWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 40%;
  justify-content: center;
`;

const Phone = styled.p`
  font-size: 15px;
  color: ${({ phone }) => phone ? `${COLOR_17}` : `${COLOR_9}`};
`;

const Email = styled.p`
  font-size: 15px;
  margin-bottom: 4px;
`;

const Status = styled.p`
  font-style: italic;
  font-size: 15px;
  margin-bottom: 10px;
`;

const Title = styled.h2`
  margin-bottom: 1px;
`;

const Wrapper = styled.div`
  ${mqTablet`
    width: 100%;
    max-width: 1000px;
    align-self: center;
  `}
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  padding-bottom: 25px;
  border-bottom: 1px solid ${COLOR_BORDER};

  ${mqTablet`
    flex-direction: row;
  `}
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  cursor: pointer;

  ${mqTablet`
    min-width: 250px;
    max-width: 250px;
    /* align-self: center; */
    margin: 0;
    margin-right: 10px;
  `}
`;

const RealEstateImage = styled.div`
  height: 250px;
  border-radius: 1px;
  margin-bottom: 10px;
  ${({ pictureUrl }) => backgrounder(pictureUrl)}

  ${mqTablet`
    height: 200px;
  `}
`;

const RequesterWrapper = styled.div`
  /* border: solid 1px ${COLOR_4}; */
  border-radius: 1px;
  padding: 10px;
  display: flex;
  cursor: pointer;
`;

const RProfilePicture = styled(ProfilePicture)`
  height: 40px;
  width: 40px;
  margin-right: 15px;
  align-self: center;
`;

const RequesterInfoWrapper = styled.div`
  flex-grow: 1;
  align-self: center;
  width: 1px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

const RequesterInfoWrapperContatct = styled(BaseLabel)`
  border: solid 1px ${COLOR_4};
  border-radius: 1px;
  padding: 10px;
  display: flex;

`;

const RequesterName = styled(BaseLabel)`
  text-transform: none;
  font-size: 14px;
  white-space: nowrap;
`;

const RequesterType = styled(RequesterName)`
  text-transform: none;
  font-size: 12px;
  margin: 0;
  font-style: italic;
`;

const RequesterContact = styled(RequesterName)`
  text-transform: none;
  font-size: 12px;
  margin: 0;
  display: block;
  font-style: italic;
`;

const RealEstateInfoWrapper = styled.div`
  /* border: solid 1px ${COLOR_4}; */
  border-radius: 1px;
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  ${mqTablet`
    margin: 0;
    margin-right: 10px;
  `}
`;

const RealEstateType = styled(BaseLabel)`
  font-size: 12px;
  margin-bottom: 10px;
`;

const RealEstateAdTitle = styled(RealEstateType)`
  font-size: 24px;
  font-weight: 700;
`;

const RealEstateNeighborhood = styled(RealEstateType)`
  font-size: 14px;
  margin: 0;
`;

const ItemName = styled(RealEstateType)`
  font-weight: 700;
  font-size: 12px;
  margin: 0;
`;

const RealEstateSectionTitle = styled(RealEstateAdTitle)`
  font-size: 16px;
`;

const RealEstateDescription = styled(RealEstateNeighborhood).attrs({
  as: "p"
})`
  text-align: justify;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-self: center;

  ${mqTablet`
    flex-direction: column;
    align-self: flex-start;
  `}
`;

const AcceptButton = styled(BaseButton)`
  background-color: ${COLOR_13};
  height: 60px;
  width: 60px;
  padding: 0;
  font-size: 12px;
  text-transform: capitalize;
  line-height: 60px;
  cursor: pointer;

  ${mqTablet`
    margin: 0;
    margin-bottom: 10px;
  `}
`;

const RefuseButton = styled(AcceptButton)`
  background-color: ${COLOR_7};
  color: ${COLOR_2};
`;

const FirstSectionDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${COLOR_BORDER};
  margin: 15px 0;
`;

const SecondSectionDivider = styled(FirstSectionDivider)``;

export default Requests;
