
import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import { COLOR_18 } from '../../../styled/variables/variables';
import { BaseLabel } from '../../../styled/labels/labels';
import { Divider } from '../../../styled/components/components';
import Features from './features/Features';
import { mqDesktop } from '../../../styled/helpers/helpers';
import Prices from './prices/Prices';

const Info = ({
  property,
  title,
  neighborhood,
  description,
  price,
  features,
  ...rest
}) => (
  <Wrapper>
    <PropertyType>
      [ { property } ]
    </PropertyType>
    <Title>
      { title }
    </Title>
    <Neighborhood>
      { neighborhood }
    </Neighborhood>
    <IDivider />
    <Features
      { ...rest }
    />
    <IDivider />
    <Prices
      { ...rest }
      { ...price }
    />
    <IDivider />
    <DescriptionSectionTitle>
      Descrição:
    </DescriptionSectionTitle>
    <Paragraph>
      { description }
    </Paragraph>
    <FeaturesSectionTitle>
      Imóvel
    </FeaturesSectionTitle>
    <Paragraph>
      { (() => {
        let paragraph = '';
        features.forEach(f => paragraph += `${ f.label } / `);
        return paragraph.substring(0, paragraph.length - 3);
      })() }
    </Paragraph>
  </Wrapper>
);

Info.propTypes = {
  type: propTypes.string,
  title: propTypes.string,
  neighborhood: propTypes.string,
  description: propTypes.string,
  price: propTypes.number,
  features: propTypes.arrayOf(propTypes.object),
}

Info.defaultProps = { };

const Wrapper = styled.div`
  padding: 30px 10px;
  background-color: ${ COLOR_18 };
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;

  ${ mqDesktop`
    flex-grow: 1;
    width: 1px;
    max-width: calc(50% - 10px);
    margin-right: 20px;
    padding: 30px;

    &:last-child {
      margin-right: 0;
    }
  ` }
`;

const PropertyType = styled(BaseLabel)`
  font-size: 12px;
  margin-bottom: 5px;
`;

const Title = styled(BaseLabel)`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
`;

const Neighborhood = styled(PropertyType)`
  font-size: 14px;
`;

const IDivider = styled(Divider)``;

const DescriptionSectionTitle = styled(Title)`
  font-size: 14px;
  margin-bottom: 10px;
`;

const Paragraph = styled(BaseLabel).attrs({
  as: 'p',
})`
  font-size: 12px;
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const FeaturesSectionTitle = styled(Title)`
  font-size: 12px;
`;

export default Info;
