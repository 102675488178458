import httpClient from '../../clients/http/http.client';

const getLeads = async ({
  searchKey = null,
  searchValue = null,
  date,
  page,
  size = 31,
  token,
}) => {
  let path = `/leads?page=${page}&size=${size}&date=${date}`;

  if (searchKey && searchValue) {
    path = `${path}&searchKey=${searchKey}&searchValue=${searchValue}`
  }

  const response = await httpClient.get({ path, token });

  return response;
}

const getLeadsResources = async ({
  searchKey = null,
  searchValue = null,
  token,
}) => {
  let path = `/leads/resources`;

  if (searchKey && searchValue) {
    path = `${path}?searchKey=${searchKey}&searchValue=${searchValue}`
  }

  const response = await httpClient.get({ path, token });

  return response;
}

const generateLeadsReportCSV = async ({ date, token }) => {
  const path = `/leads/csv?date=${date}`;

  const response = await httpClient.get({ path, token });

  return response;
}

export default {
  getLeads,
  getLeadsResources,
  generateLeadsReportCSV,
}
