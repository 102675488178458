
/*
  global
  google
  document
  process
*/

const { PUBLIC_URL } = process.env;
const { OverlayView, LatLng } = google.maps;

export const TYPES = {
  REAL_ESTATE: 'REAL_ESTATE',
  REAL_ESTATE_HIGHLIGHT: 'REAL_ESTATE_HIGHLIGHT',
  SURROUNDING_PLACE: 'SURROUNDING_PLACE',
};

class Marker extends OverlayView {

  constructor({
    data,
    type,
    latitude,
    longitude,
    map,
  }) {
    super();

    this.blinkerElement = type === TYPES.REAL_ESTATE_HIGHLIGHT ? 
      document.createElement('div') : 
      document.createElement('div')
    this.data = data;
    this.element = document.createElement('div');
    this.type = type;
    this.latitude = latitude;
    this.longitude = longitude;
    this.map = map;
    this.setMap(map);
  }

  onAdd() {
    this.element.classList.add('mapMarker');

    const panes = this.getPanes();
    if(this.type === TYPES.REAL_ESTATE_HIGHLIGHT) {
      this.element.classList.add('-highlight');
      panes.overlayLayer.prepend(this.blinkerElement);

      this.blinkerElement.classList.add('mapMarkerBlinker');
      panes.overlayLayer.appendChild(this.element);
    } else if(this.type === TYPES.SURROUNDING_PLACE) {
      this.element.classList.add('-surrounding-place');
      const { categoryId } = this.data;
      this.element.style.backgroundImage = `url('${ PUBLIC_URL }/imgs/map-icons/near-${ categoryId }.svg')`;
      panes.overlayLayer.prepend(this.element);
      } else {
        panes.overlayLayer.prepend(this.element);
      }
    panes.overlayMouseTarget.appendChild(this.element);
  }

  draw() {
    const { x, y } = this.getProjection()
      .fromLatLngToDivPixel(new LatLng(this.latitude, this.longitude));
      
    this.element.style.top = `${ y }px`;
    this.element.style.left = `${ x }px`;
    if(this.type === TYPES.REAL_ESTATE_HIGHLIGHT) {
      this.blinkerElement.style.top = `${ y }px`;
      this.blinkerElement.style.left = `${ x }px`;
    }
  }

  onRemove() {
    if(this.element){
      if(this.element.parentNode){
        this.element.parentNode.innerHTML = '';
      }
      this.element = null;
      if(this.type === TYPES.REAL_ESTATE_HIGHLIGHT && this.blinkerElement) {
        if(this.blinkerElement.parentNode){
          this.blinkerElement.parentNode.innerHTML = '';
        }
        this.blinkerElement = null;
      }
    }
  }

  startBlinking = () => {
    if(!this.blinkerElement) return;
    this.blinkerElement.classList.add('blink');
  };

  stopBlinking = () => {
    if(!this.blinkerElement) return;
    this.blinkerElement.classList.remove('blink');
  };
}

export default Marker;
