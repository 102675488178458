import React from 'react';
import { RiEyeLine, RiAwardFill } from 'react-icons/ri';

import pathsConstants from '../../../../../../constants/paths';

import { Container } from './styles';
import userService from '../../../../../../services/user/user.service';

export function LeadsTableButtons({ onKualifiqueLead, row }) {
  const user = userService.retrieve();

  const wasQualified = row.kualifique.status !== "UNQUALIFIED";

  return (
    <Container>
      <a
        title="Visualizar lead"
        href={`${pathsConstants.DASHBOARD_LEADS_VIEW_LEAD.replace(':leadId', '')}${row.id}`}
        target="_blank"
        rel="noreferrer noopener"
      >
        <RiEyeLine size={22} />
      </a>

      {user.userType > 2 && (
        <button type="button" title="KUALIFIQUE" onClick={onKualifiqueLead}>
          <RiAwardFill size={22} color={wasQualified ? '#f6861f' : '#363636'} />
        </button>
      )}
    </Container>
  );
}
