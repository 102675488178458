import React from 'react';
import styled, { css } from 'styled-components';
import { PhotoSizeSelectSmall } from 'styled-icons/material/PhotoSizeSelectSmall';
import { Bed } from 'styled-icons/boxicons-regular/Bed';

import { backgrounder, iconBaseStyle, mqTablet, mqDesktop } from '../../../styled/helpers/helpers';
import { BaseLabel } from '../../../styled/labels/labels';
import { BaseButton } from '../../../styled/buttons/buttons';
import { COLOR_1, COLOR_2, COLOR_4, COLOR_13 } from '../../../styled/variables/variables';

import currencyUtil from '../../../utils/currency/currency.util';
import pathsConstants from '../../../constants/paths';

/*
  global
  process
*/

const { PUBLIC_URL } = process.env;

const Ad = ({
  listingId,
  isComparing,
  onCompareClick,
  onRemoveClick,
  city,
  neighborhood,
  property,
  area,
  sale,
  rental,
  bedrooms,
  transaction,
  price,
  image,
  slug,
}) => (
  <Wrapper>
    <Image url={ image ? image.url.replace('{width}','1024') : null } />
    <PropertyType>[ { property } ]</PropertyType>
    <City>{ city }</City>
    <Neighborhood>{ neighborhood }</Neighborhood>
    <FeaturesWrapper>
      <M2Icon />
      <Feature>{ area } m²</Feature>
      <BedroomsIcon />
      <Feature>{ bedrooms } quartos</Feature>
    </FeaturesWrapper>
    <Price>
      { transaction === 'Venda' ? 'Venda: ' : 'Aluguel: ' }
      { currencyUtil.format({
        value: price.main,
      }) }
    </Price>
      <SeeButton onClick={() => window.open(`${pathsConstants.AD_DETAIL}/${slug}`)}>
        ver imóvel
      </SeeButton>
      <CompareButton
        onClick={ onCompareClick }
        isActive={ isComparing }
      >
        comparar
      </CompareButton>
    <DiscardButton onClick={ onRemoveClick }>
      descartar
    </DiscardButton>
  </Wrapper>
);

Ad.propTypes = {

};

Ad.defaultProps = {

};

const Wrapper = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  padding: 20px;
  width: 100%;
  max-width: 300px;

  ${ mqTablet`
    max-width: unset;
    width: calc(33.3% - 20px);
    padding: 0;
    margin: 10px;
    margin-bottom: 40px;
  ` }

  ${ mqDesktop`
    width: calc(25% - 20px);
  ` }
`;

const Image = styled.div`
  ${ ({ url }) => backgrounder(url || `${ PUBLIC_URL }/imgs/ad-example.png`) }
  min-height: 170px;
  max-height: 170px;
  width: 100%;
  border-radius: 1px;
  margin-bottom: 10px;
`;

const PropertyType = styled(BaseLabel)`
  font-size: 10px;
  align-self: center;
  margin-bottom: 10px;
`;

const City = styled(PropertyType)`
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 5px;
`;

const Neighborhood = styled(PropertyType)`
  font-size: 14px;
  margin-bottom: 20px;
`;

const FeaturesWrapper = styled.div`
  display: flex;
  align-self: center;
  margin-bottom: 5px;
`;

const M2Icon = styled(PhotoSizeSelectSmall)`
  ${ iconBaseStyle }
  height: 17px;
  align-self: center;
  margin-right: 5px;
`;

const BedroomsIcon = styled(M2Icon).attrs({
  as: Bed,
})``;

const Feature = styled(PropertyType)`
  margin: 0;
  margin-right: 10px;
  font-size: 12px;

  &:last-child {
    margin-right: 0;
  }
`;

const Price = styled(City)`
  font-size: 14px;
  margin-bottom: 20px;
`;

const SeeButton = styled(BaseButton)`
  border: solid 1px ${ COLOR_1 };
  margin: 0;
  margin-bottom: 3px;
  background-color: ${ COLOR_1 };
  color: ${ COLOR_2 };
  font-size: 12px;
  font-weight: 700;
  height: 50px;

  &:last-child {
    margin: 0;
  }
`;

const DiscardButton = styled(SeeButton)`
  background-color: ${ COLOR_2 };
  color: ${ COLOR_4 };
  border-color: ${ COLOR_4 };
  font-weight: 500;
`;

const CompareButton = styled(SeeButton)`
    background-color: ${ COLOR_2 };
    color: ${ COLOR_1 };
    border-color: ${ COLOR_1 };

  ${ ({ isActive }) => isActive && css`
    background-color: ${ COLOR_13 };
    color: #FFFFFF;
    border-color: ${ COLOR_13 };
  ` }
`;

export default Ad;
