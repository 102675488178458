import Base from "../base/base.model";

class AccessData extends Base {
  constructor({
    id,

    phone,
    favorites,
    messages,
    prints,
    shares,
    views,
    totalDeals,
  }) {
    super({ id });

    this.phone = phone;
    this.favorites = favorites;
    this.messages = messages;
    this.prints = prints;
    this.shares = shares;
    this.views = views;
    this.totalDeals = totalDeals;
  }
}

export default AccessData;
