
import React from 'react';
import styled, { css } from 'styled-components';
import propTypes from 'prop-types';
import { KeyboardArrowLeft } from 'styled-icons/material/KeyboardArrowLeft';
import { KeyboardArrowRight } from 'styled-icons/material/KeyboardArrowRight';

import { COLOR_2 } from '../../../../../styled/variables/variables';
import { iconBaseStyle, pointer } from '../../../../../styled/helpers/helpers';
import { transitions } from 'polished';

// import { COLOR_2 } from '../../../../'


const Steps = ({
  handleLeftClick,
  handleRightClick,
  isInitialSize,
  isFinalSize,
  realtorsLength,
}) => {
  return (
    <Wrapper>
      <LeftArrow
        active={isInitialSize}
        onClick={() => { isInitialSize ? () => { } : handleLeftClick() }}
      />

      <RightArrow
        active={isFinalSize || realtorsLength <= 4}
        onClick={() => { isFinalSize ? () => { } : handleRightClick() }}
      />
    </Wrapper>
  )
}

Steps.propTypes = {
  current: propTypes.number,
};

const Wrapper = styled.div`
  align-self: center;
  display: flex;
`;

const Step = styled.div`
  height: 4px;
  background-color: ${COLOR_2};
  min-width: 20px;
  max-width: 20px;
  margin-right: 10px;
  border-radius: 1px;
  align-self: center;

  &:last-child {
    margin-right: 0;
  }

  opacity: 0.7;
  ${({ isActive }) => isActive && css`
    opacity: 1;
  ` }
`;

const LeftArrow = styled(KeyboardArrowLeft)`
  ${iconBaseStyle}
  height: 50px;
  color: ${COLOR_2};
  margin-right: 20px;
  align-self: center;
  ${pointer}

  ${transitions(['opacity'], 'ease .3s')}

  ${({ active }) => active && css`
      opacity: 0.25 !important;
  `}
`;

const RightArrow = styled(KeyboardArrowRight)`
${iconBaseStyle}
  height: 50px;
  color: ${COLOR_2};
  align-self: center;
  margin-right: 0;
  margin-left: 20px;
  ${pointer}

  ${({ active }) => active && css`
      opacity: 0.25 !important;
  `}
`;

export default Steps;
