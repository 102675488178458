const pendenciesStatus = {
  "open": "Aberto",
  "in analysis": "Em análise",
  "solved": "Resolvido",
  "closed": "Fechado",
};

const pendenciesOrigin = {
  "integration": "Integração",
  "denunciation": "Denúncia",
}

const pendenciesSituation = {
  "published": "Publicado",
  "unpublished": "Não publicado"
}

export {
  pendenciesStatus,
  pendenciesOrigin,
  pendenciesSituation,
}
