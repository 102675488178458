
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Persist } from 'react-persist-plus';

import InputWrapper from '../../../components/input-wrapper';
import Checkbox from '../../../components/checkbox';

/*
  global
  window
*/

class Address extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      form: {
        street: false,
        number: false,
        floor: false,
        complement: false,
        neighborhood: false,
        city: false,
        state: false,
        zipCode: false,
        location: false,
      },
    };

  }

  handleFormChange = event => {
    const { name, checked } = event.target;

    this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        [name]: checked,
      },
    }));
  };

  render() {
    const { form } = this.state;

    return (
      <Wrapper
        onChange={ this.handleFormChange }
      >
        <Persist
          name='REPORT_BUILDER_ADDRESS_FORM'
          data={ form }
          onMount={ data => this.setState(state => ({
            ...state,
            form: data,
          })) }
          store={ window.sessionStorage }
        />
        <AInputWrapper
          label='Rua'
          htmlFor='street'
        >
          <ACheckBox
            id='street'
            name='street'
            checked={ form.street }
          />
        </AInputWrapper>

        <AInputWrapper
          label='Número'
          htmlFor='number'
        >
          <ACheckBox
            id='number'
            name='number'
            checked={ form.number }
          />
        </AInputWrapper>

        <AInputWrapper
          label='Andar'
          htmlFor='floor'
        >
          <ACheckBox
            id='floor'
            name='floor'
            checked={ form.floor }
          />
        </AInputWrapper>

        <AInputWrapper
          label='Complemento'
          htmlFor='complement'
        >
          <ACheckBox
            id='complement'
            name='complement'
            checked={ form.complement }
          />
        </AInputWrapper>

        <AInputWrapper
          label='Bairro'
          htmlFor='neighborhood'
        >
          <ACheckBox
            id='neighborhood'
            name='neighborhood'
            checked={ form.neighborhood }
          />
        </AInputWrapper>

        <AInputWrapper
          label='Cidade'
          htmlFor='city'
        >
          <ACheckBox
            id='city'
            name='city'
            checked={ form.city }
          />
        </AInputWrapper>

        <AInputWrapper
          label='Estado'
          htmlFor='state'
        >
          <ACheckBox
            id='state'
            name='state'
            checked={ form.state }
          />
        </AInputWrapper>

        <AInputWrapper
          label='CEP'
          htmlFor='zipCode'
        >
          <ACheckBox
            id='zipCode'
            name='zipCode'
            checked={ form.zipCode }
          />
        </AInputWrapper>

        <AInputWrapper
          label='Lozalização'
          htmlFor='location'
        >
          <ACheckBox
            id='location'
            name='location'
            checked={ form.location }
          />
        </AInputWrapper>
      </Wrapper>
    );
  }
}

const Wrapper = styled.form`
  margin-bottom: 20px;
`;

const AInputWrapper = styled(InputWrapper)`
  flex-direction: row-reverse;
  justify-content: flex-end;
  margin-bottom: 10px;
`;

const ACheckBox = styled(Checkbox)`
  margin-right: 5px;
`;

export default Address;
