import httpClient from "../../clients/http/http.client";

const getStates = async ({ token }) => {
  return httpClient.get({
    path: '/users/regions',
    token,
  });
}

const getCitiesByState = async ({ state, token }) => {
  return httpClient.get({
    path: `/users/regions?state=${state}`,
    token,
  });
}

const getNeighborhoodByStateAndCity = async ({ state, city, token }) => {
  return httpClient.get({
    path: `/users/regions?state=${state}&city=${city}`,
    token,
  });
}

export default {
  getStates,
  getCitiesByState,
  getNeighborhoodByStateAndCity,
};
