import styled from "styled-components";
import { iconBaseStyle, mqTablet, mqDesktop } from "../../../../../styled/helpers/helpers";
import { PhotoSizeSelectSmall } from 'styled-icons/material/PhotoSizeSelectSmall';
import { Bed } from 'styled-icons/boxicons-regular/Bed';

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  
  background: #fff;
  cursor: pointer;

  .cover {
    display: flex;
    height: 200px;
    position: relative;
    align-items: center;
    justify-content: center;
    overflow-y: hidden;
    background-color: rgb(39, 72, 194, 0.27);

    .releaseTag {
      display: flex;
      font-size: 10pt;
      gap: 0.25rem;
      align-items: space-between;
      padding-top: 0.5rem;
      position: absolute;
      color: #fff;
      font-weight: 500;
      background-color: #8acc2c; 
      padding: 0.25rem 0.5rem;
      height: fit-content;
      top: 0.75rem;
      right: 0;
    }

    
    .imageActions {
      display: flex;
      align-items: center;
      font-size: 10pt;
      gap: 0.25rem;
      width: 100%;
      align-items: space-between;
      justify-content: space-between;
      padding-top: 0.5rem;
      position: absolute;
      color: #fff;
      font-weight: 500;
      padding: 0.25rem 0.5rem;
      top: 50%;
      right: 0;
      z-index: 10;

      span {
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 35px;
        transform: translateY(-17px);

        svg {
          color: #ffffffaa;
          filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.8));
        }
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .priceContainer {
      display: flex;
      flex-direction: column;
      align-items: end;
      justify-content: right;
      align-items: space-between;
      padding: 2rem 1rem 0.5rem;
      width: 100%;
      position: absolute;
      bottom: 0;
      right: 0;
      background: linear-gradient(0deg, rgb(0, 0, 0, 0.7) 0%, rgb(0, 0, 0, 0) 100%);

      p {
        color: #fff;
        font-size: 10pt;
        margin: 0;
        font-weight: 300;
      }

      .price {
        font-size: 14pt;
        color: #fff;
      }
    }
  }

  .infos {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;

    .neighborhood {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      h3 {
        font-weight: bold;
        color: #2b2b2b;
      }

      svg {
        z-index: 10;
      }
    }

    .otherInfos {
      margin-top: 0.25rem;
      display: flex;
      gap: 0.25rem;
      flex-direction: column;
      font-size: 10pt;
      color: #777;

      .roomsAndArea {
        display: flex;
        gap: 0.5rem

        div {
          display: flex;
          gap: 0.25rem;
          align-items: center;
        }
      }
    }
  }

  ${mqTablet`
    .cover {
      height: 150px;
      overflow-y: hidden;
      background-color: rgb(39, 72, 194, 0.27);
    }  
  `}

  ${mqDesktop`
    .cover { 
      height: ${props => {
        if (props.isMapVisible || props.isFilterVisible) {
          return "180px"
        } 
        else {
          return "100%"
        }
      }};
      max-height: 210px;
    }
  `}
`

export const M2Icon = styled(PhotoSizeSelectSmall)`
  ${iconBaseStyle}
  color: #aeaeae;
`;

export const BedroomsIcon = styled(M2Icon).attrs({
  as: Bed,
})`
  color: #aeaeae;
`;