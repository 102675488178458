import React, { useState, useRef } from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { BaseButton } from '../../../styled/buttons/buttons';
import { BaseLabel } from '../../../styled/labels/labels';
import { COLOR_2, COLOR_4, COLOR_1, COLOR_5 } from '../../../styled/variables/variables';
import { mqTablet, mqDesktop, mqCustom } from '../../../styled/helpers/helpers';

import AdditionalInfo from './../builder2/additional-info/AdditionalInfo';
import Address from './../builder/address/Address';
import CommonAreaFeatures from './../builder2/common-area-features/CommonAreaFeatures';
import CreatorInfo from './../builder/creator-info/CreatorInfo';
import Description from '../builder2/description/description';
import eventConstants from '../../../constants/events';
import Features from './../builder2/features/Features';
import FloorPlans from './../builder2/floor-plans/FloorPlans';
import Images from './../builder2/images/Images';
import Video from './../builder2/video/Video';
import Prices from './../builder/prices/Prices';
import Panoramas from './../builder2/panoramas/Panoramas';
import Title from '../builder2/title/Title';
import { MyMapComponent } from './../builder/google-maps/Map';
import Type, { TYPES } from '../builder/type/Type';
import MainInfo from './../builder/main-info/MainInfo';
import PropertyType from './../builder/property-type/PropertyType';
import MainInfoEnterprise from '../builder/main-info-enterprise/MainInfoEnterprise';
import SurroundingFeatures from '../builder2/surrounding-features/SurroundingFeatures';
import Transaction, { TYPES as TRANSACTION_TYPES } from './../builder/transaction/Transaction';

import geolocationService from '../../../services/geolocation/geolocation.service';
import realEstateService from '../../../services/real-estate/real-estate.service';

import objectUtil from '../../../utils/object/object.util';
import pathsConstants from '../../../constants/paths';
import queryStringUtil from '../../../utils/query-string/query-string.util';
import domEventsUtil from '../../../utils/dom-events/dom-events.util';

/*
  localStorage
  window
*/
const customId = "custom-id-yes";

const Builder3 = (props) => {

  const { user, history } = props;
  const qParams = queryStringUtil.parse(history);
  const { noStep } = qParams;

  const typeOfGuaranteeRef = useRef(null);

  const [state, setState] = useState({
    transactionRef: null,
    propertyTypeRef: null,
    addressRef: null,
    showEnterprise: true,
    adTypeIsRelease: true,
    transactionTypeIsSale: true,
    titleRef: null,
    subtitleRef: null,
    pricesRef: null,
    videoRef: null,
    creatorInfoRef: null,
    featuresRef: null,
    commonAreaFeaturesRef: null,
    surroundingFeaturesRef: null,
    mainInfoRef: null,
    typeRef: null,
    descriptionRef: null,
  });

  const [enterpriseName, setEnterpriseName] = useState('');
  const [enterpriseStatus, setEnterpriseStatus] = useState('Pronto para Morar');
  const [towerNumber, setTowerNumber] = useState('');
  const [floorsNumber, setFloorsNumber] = useState('');
  const [apartmentsPerFloor, setApartmentsPerFloor] = useState('');

  const handleFormSubmit = async event => {
    event.preventDefault();

    const queryParams = queryStringUtil.parse(history);
    const { flowType } = queryParams;

    let data = {
      ...state.transactionRef.state.form,
      ...state.propertyTypeRef.state.form,
      ...state.addressRef.state.form,
      ...state.mainInfoRef.state.form,
      ...state.descriptionRef.state.form,
      ...state.pricesRef.state.form,
      ...state.creatorInfoRef.state.form,
      ...state.floorPlansRef.state.form,
      ...state.imagesRef.state.form,
      ...state.panoramaRef.state.form,
      ...state.titleRef.state.form,
      ...state.videoRef.state.form,
      guarantee: !state.transactionTypeIsSale ?
        typeOfGuaranteeRef.current.state.guarantees : [],
      features: {
        property: state.featuresRef.state.form.features,
        condo: state.commonAreaFeaturesRef.state.form.features,
        near: state.surroundingFeaturesRef.state.form.features,
      },
      listing: state.typeRef.state.form.type || 'Usado',
      development: {
        name: state.adTypeIsRelease && state.transactionTypeIsSale && qParams.flowType !== 'OWNER' ? enterpriseName : '',
        constructionStatus: state.adTypeIsRelease && state.transactionTypeIsSale && qParams.flowType !== 'OWNER' ? enterpriseStatus : 'Pronto para Morar',
        buildings: state.adTypeIsRelease && state.transactionTypeIsSale && qParams.flowType !== 'OWNER' ? towerNumber : '',
        floors: state.adTypeIsRelease && state.transactionTypeIsSale && qParams.flowType !== 'OWNER' ? floorsNumber : 0,
        apartmentsPerFloor: state.adTypeIsRelease && state.transactionTypeIsSale && qParams.flowType !== 'OWNER' ? apartmentsPerFloor : 0,
      },
    };

    const validationErrors = [];
    const optionalFields = [
      'additionalInfo',
      'apartmentsPerFloor',
      'cellphoneNumber',
      'condo',
      'constructionYear',
      'description',
      'features',
      'floor',
      'floorPlans',
      'images',
      'lastReformYear',
      'panoramas',
      'phoneNumber',
      'videoUrl',
    ];
    if (data.transaction === 'Venda') {
      optionalFields.push('guarantees');
    }

    objectUtil.keys(data).forEach(key => {
      if (optionalFields.find(f => f === key)) return;

      if (
        data[key] === null ||
        data[key] === undefined ||
        data[key] === '' ||
        data[key].length === 0
      ) {
        validationErrors.push(`${key} cannot be empty.`);
      }
    });

    if (data.floorPlans.length + data.images.length + data.panoramas.length < 3) {
      return (
        toast.error("Você deve inserir pelos menos 3 imagens para finalizar a criação de seu anúncio", {
          autoClose: false,
          toastId: customId,
        })
      )
    }

    if (validationErrors.length > 0) {
      toast.error('Os campos obrigatórios foram destacados. Por favor, verifique-os.', {
        autoClose: false,
        toastId: customId,
      });

      setState(_state => ({
        ..._state,
        showInvalids: true,
      }));
      return;
    }

    domEventsUtil.dispatch({
      name: eventConstants.LOADING_ANIMATION,
      params: { show: true, },
    });

    // Geolocation

    const { zipCode, street, _state } = data;
    let geolocation;
    try {
      geolocation = await geolocationService.getGeolocationByAddress({
        zipCode,
        street,
        _state,
        country: 'Brazil',
      });
    } catch (error) {
      toast.error('Erro verificando geolocalização do endereço.', {
        autoClose: false,
        toastId: customId,
      });
      domEventsUtil.dispatch({
        name: eventConstants.LOADING_ANIMATION,
        params: { show: false, },
      });
      throw error;
    }
    data.latitude = geolocation.latitude;
    data.longitude = geolocation.longitude;

    let realEstateId;

    if (true) {
      try {
        if (!user || !user.token) {
          history.push(`${pathsConstants.AUTHENTICATION}/${pathsConstants.AUTHENTICATION_PARAMS.LOGIN}`);
        } else {

          realEstateId = await realEstateService.createRealEstateRealtor({
            data: objectUtil.conformify(data),
            user: user,
          });

          if (!realEstateId) {
            toast.error('Erro criando anúncio.', {
              autoClose: false,
              toastId: customId,
            });
            domEventsUtil.dispatch({
              name: eventConstants.LOADING_ANIMATION,
              params: { show: false, },
            });
          }
          localStorage.setItem('adId', realEstateId);
          window.sessionStorage.clear();
        }

      } catch (error) {
        toast.error('Erro criando anúncio.', {
          autoClose: false,
          toastId: customId,
        });
        domEventsUtil.dispatch({
          name: eventConstants.LOADING_ANIMATION,
          params: { show: false, },
        });
        throw error;
      }

      // upload images

      if (data.images.length > 0) {
        try {
          if (!user || !user.token) {
            history.push(`${pathsConstants.AUTHENTICATION}/${pathsConstants.AUTHENTICATION_PARAMS.LOGIN}`);
          } else {
            await realEstateService.uploadImages({
              pictureBlobs: data.images.map(({ blob }) => blob),
              floorPlanBlobs: data.floorPlans.map(({ blob }) => blob),
              panoramaBlobs: data.panoramas.map(({ blob }) => blob),
              realEstateId,
              token: user.token,
            });
          }
        } catch (error) {
          toast.error('Erro enviando imagens.', {
            autoClose: false,
            toastId: customId,
          });
          domEventsUtil.dispatch({
            name: eventConstants.LOADING_ANIMATION,
            params: { show: false, },
          });
          throw error;
        }
      }
    }

    domEventsUtil.dispatch({
      name: eventConstants.LOADING_ANIMATION,
      params: { show: false, },
    });

    if (!user || !user.token) {
      history.push(`${pathsConstants.AUTHENTICATION}/${pathsConstants.AUTHENTICATION_PARAMS.LOGIN}`);
    } else if (!flowType === 'BROKER') {
      history.push({
        pathname: pathsConstants.PURCHASE_FLOW_PLANS,
        search: queryStringUtil.stringify(history, {
          realEstateId: realEstateId,
          plans: true,
        }),
      });

    } else {
      history.push({
        pathname: pathsConstants.DASHBOARD,
        search: queryStringUtil.stringify(history, {}),
      });
    }

  };

  const renderMap = (latitude, longitude) => {
    // return (
    //   <MyMapComponent
    //     isMarkerShown
    //     googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyA4_6WVLsCm8wA2kII6y68yCmuXgnF-XXY"
    //     loadingElement={<div style={{ height: `100%` }} />}
    //     containerElement={<MapContainer />}
    //     mapElement={<div style={{ height: `100%` }} />}
    //     latitude={latitude}
    //     longitude={longitude}
    //   />
    // )
  };

  return (
    <Wrapper onSubmit={handleFormSubmit}>
      {qParams.flowType !== 'OWNER' &&
        <>
          <SectionTitle>
            Selecione o tipo de anúncio
          </SectionTitle>
          <Type
            ref={ref => {
              return state.typeRef = ref;
            }}
            onChange={transaction => {
              setState(state => ({
                ...state,
                showEnterprise: transaction === TYPES.LANCAMENTO,
                adTypeIsRelease: transaction === TYPES.LANCAMENTO,
              }));
            }}
          />
        </>
      }
      <SectionTitle>
        Selecione a transação
      </SectionTitle>
      <Transaction
        ref={ref => {
          return state.transactionRef = ref;
        }}
        onChange={transaction => {
          setState(_state => ({
            ..._state,
            transactionTypeIsSale: transaction === TRANSACTION_TYPES.SALE,
            showTypeOfGuarantees: transaction === TRANSACTION_TYPES.RENTAL,
          }));
        }}
      />
      <SectionTitle>
        Tipo de imóvel que deseja anúnciar
      </SectionTitle>
      <PropertyType
        ref={ref => {
          state.propertyTypeRef = ref;
        }}
      />
      <MapWrapper>
        <div style={{ flexDirection: 'column', width: 500 }}>
          <SectionTitle>
            Localização do seu imóvel
          </SectionTitle>
          <Address
            ref={ref => {
              state.addressRef = ref;
            }}
            onChangeZip={(latitude, longitude, form) => {
              setState(_state => ({
                ..._state,
                latitude: latitude,
                longitude: longitude,
                showMap: !form.showOnMap,
                form: form
              }));
            }}
            showInvalids={state.showInvalids}
          />
        </div>

        {state.latitude && state.showMap && renderMap(state.latitude, state.longitude)}
      </MapWrapper>

      {state.adTypeIsRelease &&
        state.transactionTypeIsSale &&
        qParams.flowType !== 'OWNER' &&
        <>
          <SectionTitle>
            Empreendimento
          </SectionTitle>

          <div className="form-floating mb-3">
            <input
              placeholder="Nome do empreendimento"
              value={enterpriseName}
              onChange={event => setEnterpriseName(event.target.value)}
              type="text"
              className="form-control"
              id="enterpriseName"
              name="enterpriseName"
              aria-describedby="enterpriseName"
            />
            <label
              htmlFor="enterpriseName"
              className="form-label"
            >
              Nome do empreendimento
            </label>
          </div>

          <EnterpriseStatusTitle>Status do empreendimento</EnterpriseStatusTitle>
          <SelectContainer>
            <SelectStep
              type="button"
              onClick={() => setEnterpriseStatus('Na Planta')}
            >
              <StepBulletContainer
                active={enterpriseStatus === 'Na Planta'}
              >
                <StepBulletGap>
                  <StepBullet
                    active={enterpriseStatus === 'Na Planta'}
                  />
                </StepBulletGap>
              </StepBulletContainer>
              <SelectTextItem
                active={enterpriseStatus === 'Na Planta'}
              >
                Na planta
              </SelectTextItem>
            </SelectStep>

            <SelectStep
              type="button"
              onClick={() => setEnterpriseStatus('Em Construção')}
            >
              <StepBulletContainer
                active={enterpriseStatus === 'Em Construção'}
              >
                <StepBulletGap>
                  <StepBullet
                    active={enterpriseStatus === 'Em Construção'}
                  />
                </StepBulletGap>
              </StepBulletContainer>
              <SelectTextItem
                active={enterpriseStatus === 'Em Construção'}
              >
                Em construção
              </SelectTextItem>
            </SelectStep>

            <SelectStep
              type="button"
              onClick={() => setEnterpriseStatus('Pronto para Morar')}
            >
              <StepBulletContainer
                active={enterpriseStatus === 'Pronto para Morar'}
              >
                <StepBulletGap>
                  <StepBullet
                    active={enterpriseStatus === 'Pronto para Morar'}
                  />
                </StepBulletGap>
              </StepBulletContainer>
              <SelectTextItem
                active={enterpriseStatus === 'Pronto para Morar'}
              >
                Pronto para morar
              </SelectTextItem>
            </SelectStep>
            <StepLine />
          </SelectContainer>

          <div className="form-floating mt-3">
            <input
              placeholder="Número de torres"
              value={towerNumber}
              onChange={event => setTowerNumber(event.target.value)}
              type="number"
              className="form-control"
              id="towerNumber"
              name="towerNumber"
              aria-describedby="towerNumber"
            />
            <label htmlFor="towerNumber" className="form-label">Número de torres</label>
          </div>

          <div className="form-floating mt-2 mb-5">
            <div className="row g-2">
              <div className="col-md">
                <div className="form-floating">
                  <input
                    placeholder="Número de andares"
                    value={floorsNumber}
                    onChange={event => setFloorsNumber(event.target.value)}
                    type="number"
                    className="form-control"
                    id="floorsNumber"
                    name="floorsNumber"
                    aria-describedby="floorsNumber"
                  />
                  <label htmlFor="floorsNumber" className="form-label">Número de andares</label>
                </div>
              </div>
              <div className="col-md">
                <div className="form-floating">
                  <input
                    placeholder="Apartamentos por andar"
                    value={apartmentsPerFloor}
                    onChange={event => setApartmentsPerFloor(event.target.value)}
                    type="number"
                    className="form-control"
                    id="apartmentsPerFloor"
                    name="apartmentsPerFloor"
                    aria-describedby="apartmentsPerFloor"
                  />
                  <label htmlFor="apartmentsPerFloor" className="form-label">Apartamentos por andar</label>
                </div>
              </div>
            </div>
          </div>
        </>
      }
      <SectionTitle>
        Características do imóvel
      </SectionTitle>
      {state.showEnterprise ?
        <MainInfoEnterprise
          ref={ref => {
            state.mainInfoRef = ref;
          }}
          showInvalids={state.showInvalids}
          totalArea={state.mainInfo ? state.mainInfo.totalArea : ''}
          area={state.mainInfo ? state.mainInfo.area : ''}
          bathrooms={state.mainInfo ? state.mainInfo.bathrooms : ''}
          bedrooms={state.mainInfo ? state.mainInfo.bedrooms : ''}
          suites={state.mainInfo ? state.mainInfo.suites : ''}
          parkingSpaces={state.mainInfo ? state.mainInfo.parkingSpaces : ''}
        /> :
        <MainInfo
          ref={ref => {
            state.mainInfoRef = ref;
          }}
          showInvalids={state.showInvalids}
          totalArea={state.mainInfo ? state.mainInfo.totalArea : '0'}
          area={state.mainInfo ? state.mainInfo.area : '0'}
          bathrooms={state.mainInfo ? state.mainInfo.bathrooms : '0'}
          bedrooms={state.mainInfo ? state.mainInfo.bedrooms : '0'}
          suites={state.mainInfo ? state.mainInfo.suites : '0'}
          parkingSpaces={state.mainInfo ? state.mainInfo.parkingSpaces : '0'}

        />
      }

      <SectionTitle>
        Informações do anúncio
      </SectionTitle>
      <Title
        ref={ref => {
          state.titleRef = ref;
        }}
        title={state.title ? state.title.title : ''}
        showInvalids={state.showInvalids}
      />
      <Description ref={ref => {
        state.descriptionRef = ref;
      }}
        description={state.description ? state.description.description : ''}
      />

      {props.editing &&
        <>
          <SectionTitle>
            Detalhamento do imóvel
          </SectionTitle>
          <Features
            ref={ref => {
              state.featuresRef = ref;
            }}
            features={state.features ? state.features.property : []}
          />
          <SectionTitle>
            Áreas comuns
          </SectionTitle>
          <CommonAreaFeatures
            ref={ref => {
              state.commonAreaFeaturesRef = ref;
            }}
          />
          <SectionTitle>
            Entorno
          </SectionTitle>
          <SurroundingFeatures
            ref={ref => {
              state.surroundingFeaturesRef = ref;
            }}
          />
          <SectionTitle>
            Planta baixa
          </SectionTitle>
          <FloorPlans
            ref={ref => {
              state.floorPlansRef = ref;
            }}
          />
        </>
      }
      <SectionTitle>
        Valores
      </SectionTitle>
      <Prices
        ref={ref => {
          state.pricesRef = ref;
        }}
        condo={state.prices ? state.prices.condo : ''}
        iptu={state.prices ? state.prices.iptu : ''}
        price={state.prices ? state.prices.price : ''}
        showInvalids={state.showInvalids}
      />

      <AdditionalInfo
        ref={typeOfGuaranteeRef}
        showInvalids={false}
        showTypeOfGuarantees={!state.transactionTypeIsSale}
      />

      <SectionTitle>
        Detalhamento do imóvel
      </SectionTitle>
      <Features
        ref={ref => {
          state.featuresRef = ref;
        }}
        features={state.features ? state.features.property : []}
      />
      <SectionTitle>
        Áreas comuns
      </SectionTitle>
      <CommonAreaFeatures
        ref={ref => {
          state.commonAreaFeaturesRef = ref;
        }}
      />
      <SectionTitle>
        Entorno
      </SectionTitle>
      <SurroundingFeatures
        ref={ref => {
          state.surroundingFeaturesRef = ref;
        }}
      />

      <SectionTitle>
        Fotos do anúncio
      </SectionTitle>
      <Images
        ref={ref => {
          state.imagesRef = ref;
        }}
      />
      <SectionTitle>
        Panorama
      </SectionTitle>
      <Panoramas
        ref={ref => {
          state.panoramaRef = ref;
        }}
      />
      <SectionTitle>
        Planta baixa
      </SectionTitle>
      <FloorPlans
        ref={ref => {
          state.floorPlansRef = ref;
        }}
      />
      <SectionTitle>
        Vídeo
      </SectionTitle>
      <Video
        ref={ref => {
          state.videoRef = ref;
        }}
        video={state.video ? state.video.videoUrl : ''}
      />

      <SectionTitle>
        Dados do anunciante
      </SectionTitle>
      <CreatorInfo
        ref={ref => {
          state.creatorInfoRef = ref;
        }}
        showInvalids={state.showInvalids}
        name={state.creator ? state.creator.name : (user && user.name ? user.name : '')}
        email={state.creator ? state.creator.email : (user && user.email ? user.email : '')}
        telephone={state.creator ? state.creator.telephone : (user && user.phoneNumber ? user.phoneNumber : '')}
      // cellphone={state.creator ? state.creator.cellphone : ''}
      />
      {props.editing &&
        <>
          <SectionTitle>
            Fotos do anúncio
          </SectionTitle>
          <Images
            ref={ref => {
              state.imagesRef = ref;
            }}
          />
          <SectionTitle>
            Vídeo
          </SectionTitle>
          <Video
            ref={ref => {
              state.videoRef = ref;
            }}
            video={state.video ? state.video.videoUrl : ''}
          />
          <SectionTitle>
            Panorama
          </SectionTitle>
          <Panoramas
            ref={ref => {
              state.panoramaRef = ref;
            }}
          />

          <SectionTitle>
            Dados do anunciante
          </SectionTitle>
          <CreatorInfo
            ref={ref => {
              state.creatorInfoRef = ref;
            }}
            showInvalids={state.showInvalids}
            name={state.creator ? state.creator.name : (user && user.name ? user.name : '')}
            email={state.creator ? state.creator.email : (user && user.email ? user.email : '')}
            telephone={state.creator ? state.creator.telephone : (user && user.phoneNumber ? user.phoneNumber : '')}
          />
        </>
      }
      <ButtonWrapper>
        {!noStep &&
          <SaveButton>
            {props.editing ? "Editar" : "Criar"} anúncio
          </SaveButton>
        }
        {noStep &&
          <SaveButton>
            CADASTRAR IMÓVEL
          </SaveButton>
        }

      </ButtonWrapper>
    </Wrapper>
  );
};

const VisualizeButton = styled(BaseButton)`
  color: ${COLOR_2};
  background-color: ${COLOR_4};
  flex-grow: 1;
  width: 1px;
  border: none;
`;

const SaveButton = styled(VisualizeButton).attrs({
  type: 'submit',
})`
  background-color: ${COLOR_1};
`;

const ButtonWrapper = styled.div`
  display: flex;

  ${mqTablet`
    align-self: center;
    width: 400px;
  ` }
`;

const Wrapper = styled.form`
  ${mqTablet`
    width: 100%;
    max-width: 500px;
    align-self: flex-start;
    display: flex;
    flex-direction: column;
  ` }
`;

const MapContainer = styled.div`
    ${mqCustom(340)`
    height:300px;
    width: 300px;
    margin-left:60px;
    margin-bottom:25px;
    align-self:center;
  ` }
    ${mqTablet`
      height:350px;
      width: 350px;
      margin-left:50px;
      margin-bottom:20px;
      align-self:center;
  ` }
    ${mqDesktop`
      height:350px;
      width: 400px;
      margin-left:100px;
      margin-bottom:20px;
      align-self:center;
  ` }
`;

const MapWrapper = styled.div`
  ${mqTablet`
    width:740px;
    max-width:800px;
    heigth:800px;
    align-self:flex-start;
    display:flex;
    flex:1;
    overflow:visible;
  ` }
    ${mqDesktop`
    width:1200px;
    max-width:1500px;
    heigth:800px;
  ` }
`;

const SectionTitle = styled(BaseLabel)`
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 10px;
`;

const EnterpriseStatusTitle = styled.label`
  margin-bottom: 16px;
  color: #000000;
`;

const SelectContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;

  @media screen and (max-width: 500px) {
    flex-direction: column;
    justify-content: unset;
  }
`;

const SelectTextItem = styled.span`
  color: ${({ active }) => active ? COLOR_1 : '#777'};
  margin-top: 12px;

  transition: color 0.2s;

  @media screen and (max-width: 500px) {
      margin-left: 16px;
      margin-top: 0;
    }
`;

const SelectStep = styled.button`
  flex:1;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  background-color: transparent;

  @media screen and (max-width: 500px) {
    flex-direction: row;
    background-color: #fff;

    & + button {
      margin-top: 48px;
    }
  }
`;

const StepBulletContainer = styled.div`
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background: ${({ active }) => active ? COLOR_1 : '#777'};
  display: flex;
  justify-content: center;
  align-items: center;

  transition: background-color 0.2s;
`;

const StepBulletGap = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;

  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StepBullet = styled.div`
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: ${({ active }) => active ? COLOR_1 : '#777'};

  transition: background-color 0.2s;
`;

const StepLine = styled.div`
  position: absolute;
  height: 2px;
  top: 16px;
  right: 80px;
  left: 80px;
  background: ${COLOR_5};
  opacity: 0.65;
  z-index: 0;
  margin: 0 auto;

  @media screen and (max-width: 767px) {
    right: 110px;
    left: 110px;
  }

  @media screen and (max-width: 600px) {
    right: 80px;
  left: 80px;
  }

  @media screen and (max-width: 500px) {
    top: 0px;
    bottom: 0;
    left: 16px;
    right: unset;
    height: unset;
    width: 2px;
  }
`;

Builder3.propTypes = {
  history: propTypes.object,
  user: propTypes.object,
};

Builder3.defaultProps = {

};

export default Builder3;
