import styled from 'styled-components';

import { COLOR_17, COLOR_2 } from '../../../../../../styled/variables/variables';
import { Divider } from '../../../../../../styled/components/components';
import { BaseLabel } from '../../../../../../styled/labels/labels';

export const Wrapper = styled.div`
  padding: 30px 20px;
  background-color: ${COLOR_17};
  display: flex;
  flex-direction: column;
  border-radius: 1px;
`;

export const CFDivider = styled(Divider)`
  background-color: ${COLOR_2};
`;

export const FormCall = styled(BaseLabel)`
  color: ${COLOR_2};
  font-size: 14px;
`;

export const CallToActionButton = styled.button`
  height: 54px;
  background-color: #2B50D8;
  margin-top: 24px;

  > strong {
    font-weight: 600;
    color: #fff;
  }

  @media (max-width: 380px) {
    max-width: 300px;
  }
`;

export const ModalContent = styled.div`
  height: 100%;
  overflow-y: hidden;

  max-width: 740px;
  margin: 0 auto;

  @media (max-width: 790px) {
    padding: 0px 16px;
  }
`;

export const ModalBody = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ContactFormModal = styled.div`
  background: #000000;
  padding: 42px 78px;

  > p {
    color: #909090;
    font-size: 16px;
    font-weight: 400;
  }

  > header {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      color: #ffffff;
      font-size: 24px;
      max-width: 360px;
    }

    button {
      background-color: #262626;
      border-radius: 4px;
      color: #ffffff;
      font-size: 0px;
      padding: 2px;

      transition: background-color 0.2s, color 0.2s;

      &:hover {
        background-color: #ffffff;
        color: #000000;
      }
    }
  }

  @media (max-width: 620px) {
    padding: 42px 42px;

    > header {
      flex-direction: column-reverse;
      align-items: unset;
      justify-content: unset;

      button {
        margin-left: auto;
      }

      h3 {
        margin-top: 16px;
      }
    }
  }

  @media (max-width: 510px) {
    p {
      font-size: 14px;
    }

    > header {
      h3 {
        font-size: 20px;
      }
    }
  }
`;
