
import React, { PureComponent } from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { Persist } from 'react-persist-plus';

import { BaseInput, ErrorMessage } from '../../../styled/inputs/inputs';

import validationUtil from '../../../utils/validation/validation.util';
import storageUtil from '../../../utils/storage/storage.util';

/* 
  global
  window
*/

class ReportTitle extends PureComponent {

  constructor(props) {
    super(props);
    
    const { name } = props;
    if(name) storageUtil.del(ReportTitle.STORAGE_KEY, false);

    this.state = {
      form: {
        reportTitle: props.name || '',
      },
      showErrorsFor: { },
    };
  }

  handleFormChange = event => {
    const { name, value } = event.target;

    this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        [name]: value,
      },
    }));
  };

  handleInputBlur = event => {
    const { name } = event.target;

    this.setState(state => ({
      ...state,
      showErrorsFor: {
        ...state.showErrorsFor,
        [name]: true,
      },
    }));
  };

  render() {
    const { showInvalids } = this.props;
    const { form, showErrorsFor } = this.state;

    const isReportTitleValid = validationUtil.text({
      minLength: 5,
      maxLength: 50,
      value: form.reportTitle,
    });
    return (
      <Wrapper onChange={ this.handleFormChange }>
        <Persist 
          name={ ReportTitle.STORAGE_KEY }
          data={ form }
          onMount={ data => this.setState(state => ({
            ...state,
            form: data,
          })) }
          store={ window.sessionStorage }
        />
        <Input 
          value={ form.reportTitle } 
          onBlur={ this.handleInputBlur }
          isInvalid={ 
            (showErrorsFor.reportTitle || showInvalids) && !isReportTitleValid 
          }
        />
        { (showErrorsFor.reportTitle || showInvalids) && !isReportTitleValid && (
          <ErrorMessage>
            Nomes devem conter de 5 a 50 caracteres.
          </ErrorMessage>
        ) }
      </Wrapper>
    );
  }
}

ReportTitle.STORAGE_KEY = 'REPORT_BUILDER_REPORT_TITLE_FORM';

ReportTitle.propTypes = {
  name: propTypes.string,
  showInvalids: propTypes.bool,
};

ReportTitle.defaultProps = { };

const Wrapper = styled.form`
  margin-bottom: 20px;
`;

const Input = styled(BaseInput).attrs({
  name: 'reportTitle',
})``;

export default ReportTitle;
