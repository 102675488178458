import React, { useEffect, useState, useRef } from "react";
import { Results, TopFilter, LoadingResults } from './styles'
import { PiCaretDown } from "react-icons/pi";
import { RiRoadMapLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { setOrderBy, setSort } from '../../../../reducers/filterReducer/actions';
import { RealEstateCard } from "./realEstateCard";
import { useDisplay } from "../../../../contexts/filterContext";

const orderTypes = [
  { index: 1, orderBy: "updatedAt", sort: "asc", label: "Recentes" },
  { index: 2, orderBy: "price", sort: "desc", label: "Mais caros" },
  { index: 3, orderBy: "price", sort: "asc", label: "Mais baratos" },
  { index: 4, orderBy: "area", sort: "desc", label: "Maior área" },
  { index: 5, orderBy: "area", sort: "asc", label: "Menor área" },
]

export function ResultsSection({ results, getMoreResults, noMoreResults }) {
  const dispatch = useDispatch();
  const filterState = useSelector(state => state.filter);
  const { isFilterVisible, setFilterVisible, isMapVisible, setMapVisible } = useDisplay();
  
  const [isFetchingResults, setIsFetchingResults] = useState(false)
  const [showOrderOptions, setShowOrderOptions] = useState(false)
  const [page, setPage] = useState(1)

  const lastElement = useRef(null)
  const container = useRef(null)

  const handleOrderBy = (type) => {
    dispatch(setOrderBy(type.orderBy))
    dispatch(setSort(type.sort))
    setShowOrderOptions(false)
  };

  const toggleShowOrderOptions = () => {
    setShowOrderOptions(state => !state)
  }

  const handleMoreResults = async (resultsPage) => {
    await getMoreResults(resultsPage)
    setIsFetchingResults(false)
  }

  useEffect(() => {
    setPage(1)
  }, [filterState])

  useEffect(() => {
    page > 1 && handleMoreResults(page)
  }, [page])

  useEffect(() => {
    (results && isFetchingResults) && setPage(state => state + 1)
  }, [isFetchingResults])

  useEffect(() => {
    const options = {
      root: container.current,
      rootMargin: "0px",
      threshold: 0,
    };

    const intersectorObserver = new IntersectionObserver(() => {
      setIsFetchingResults(true)
    }, options)

    let target = document.querySelector("#lastElement")
    
    intersectorObserver.observe(target);
    
    return () => intersectorObserver.disconnect()
  }, [lastElement])

  return (
    <Results ref={container} isMapVisible={isMapVisible} isFilterVisible={isFilterVisible}>
      <TopFilter>
        <p>{results?.seo ? results.seo.title : "Nenhum resultado para a busca."}</p>
        <div className="fastFilter">
          <div className="visibilityButtons">
            <button className="hideFilter" onClick={() => setFilterVisible(!isFilterVisible)}>{isFilterVisible ? "Ocultar filtro" : "Ver filtro"}</button>
            <button className="hideFilter hideMap" onClick={() => setMapVisible(!isMapVisible)}>{isMapVisible ? "Ocultar mapa" : "Ver mapa"}</button>
          </div>
          <div className="orderSearch">
            <div>
              <div className="orderBy" onClick={() => toggleShowOrderOptions()}><span>Ordenar</span><PiCaretDown /></div>
              {
                showOrderOptions &&
                <div className="orderOptions">
                  {
                    orderTypes.map(type => <option onClick={() => handleOrderBy(type)} value={type.index} key={type.index}>{ type.label }</option>)
                  }
                </div>
              }
            </div>
            {/* <MapToggle /> */}
          </div>
        </div>
      </TopFilter>
      { results?.realEstates.length === 0 ?
        <div className="noContent">
          <RiRoadMapLine size={36} />
          <p>Não encontramos resultados para a sua busca</p>
        </div>
        :
        <div className="realEstates">
          {results?.realEstates &&
          results?.realEstates.map(realEstate => {
            return (
              <RealEstateCard key={realEstate.id} realEstate={realEstate} />
            )})}
        </div>
      }
        <LoadingResults id="lastElement" ref={lastElement}>
          {
            (isFetchingResults && !noMoreResults) &&
            <p>{page === 1 ? "Carregando resultados..." : "Carregando mais resultados..."}</p>
          }
        </LoadingResults>
    </Results>
  )
}
