import styled from 'styled-components';
import { mqTablet } from '../../../styled/helpers/helpers';
import { BaseInput } from '../../../styled/inputs/inputs';

export const Input = styled(BaseInput).attrs({
  placeholder: 'Digite seu e-mail e receba novidades'
})`
  align-self: center;
  width: 280px;
  margin-bottom: 10px;
  text-align: left;

  @media (max-width: 767px) {
    font-size: 2vw;
  }

  ${mqTablet`
    margin-bottom: 0;
    border-radius: 1px; 0 0 3px;
    width: 340px;
    text-align: left;
  ` }
`;