import styled, { css } from "styled-components";
import { transitions } from "polished";
import { AngleDown } from "styled-icons/fa-solid/AngleDown";
import { InfoCircle } from "styled-icons/boxicons-regular/InfoCircle";

import { BaseLabel } from "../../styled/labels/labels";
import { COLOR_13, COLOR_17 } from "../../styled/variables/variables";
import {
    iconBaseStyle,
    pointer,
    mqDesktop
} from "../../styled/helpers/helpers";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 52px;
  overflow: hidden;
  margin-bottom: 20px;

  ${transitions(["max-height"], "ease .3s")}
  ${({ isOpened, listLength }) =>
        isOpened &&
        css`
      max-height: ${listLength * 50}px;
    `}

  ${mqDesktop`
    border: solid 1px ${COLOR_17};
    border-width: 1px 0 1px 0;
    width: 100%;
    max-width: 1000px;
    flex-direction: row;
    align-self: center;
    justify-content: center;
    overflow: unset;
  `}
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: solid 1px ${COLOR_17};
  border-width: 1px 0 1px 0;
  /* width: 100%; */
  ${pointer}

  ${({ isActive, order }) =>
        !isActive &&
        css`
      border: none;
      order: ${order};
    `}

  ${mqDesktop`
    border: none;
    order: unset;
    flex-grow: 1;
    width: 1px;
  `}

  ${({ isDisabled }) => isDisabled && css`
    opacity: 0.4;

    &, * {
    cursor: not-allowed;
  }
  `}

  ${({isIncorporation}) => isIncorporation && css`
    opacity: 1;

    &, * {
      cursor: auto;
    }
  `}
`;

export const LabelWrapper = styled.div`
  display: flex;
  padding: 15px 0;
  margin-bottom: -2px;
`;

export const Label = styled(BaseLabel)`
  font-weight: 700;
  align-self: center;
  flex-grow: 1;
  width: 1px;
  text-align: center;
  margin-right: -10px;

  ${mqDesktop`
    margin: 0;
    font-size: 14px;
  `}

  ${({isIncorporation}) => isIncorporation && css`
    text-align: left;
    padding-left: 15px;
  `}
`;

export const DownArrowIcon = styled(AngleDown)`
  ${iconBaseStyle}
  height: 18px;
  align-self: center;
  margin-right: 12px;
  margin-left: 8px;

  ${({ show }) =>
        !show &&
        css`
      opacity: 0;
    `}

  ${mqDesktop`
    display: none;
  `}
`;

export const InfoTooltip = styled(InfoCircle)`
  ${iconBaseStyle}
`;

export const ActiveIndicator = styled.div`
  align-self: center;
  background-color: ${COLOR_13};
  height: 5px;
  border-radius: 1px;
  align-self: center;
  margin-bottom: -3px;
  width: 100%;
  max-width: 220px;

  ${({ show }) =>
        !show &&
        css`
      opacity: 0;
    `}

  ${mqDesktop`
    ${transitions(["opacity"], "ease .3s")}
  `}
`;
