/* eslint-disable no-undef */

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import propTypes from 'prop-types';

import Slogan from './slogan/Slogan';
import SearchForm from './search-form/SearchForm';
import Arrows from './arrows/Arrows';

import { Creators as GeolocationActions } from '../../../reducers/data/geolocation/geolocation.data.reducer';
import { COLOR_2 } from '../../../styled/variables/variables';

import stringUtil from '../../../utils/string/string.util';
import { mqTablet, backgrounder, bigSectionPadding } from '../../../styled/helpers/helpers';

import './cover.css';

/* global process */

const { PUBLIC_URL } = process.env;

const Cover = () => {
  const [backgroundUrl, _] = useState(() => {
    const screenWidth = window.screen.width;

    if (screenWidth <= 1080) {
      return `${PUBLIC_URL}/imgs/webp/home/1080/bg${parseInt(Math.random() * 2 + 1)}-huge.webp`;
    } else if (screenWidth <= 1920) {
      return `${PUBLIC_URL}/imgs/webp/home/1920/bg${parseInt(Math.random() * 2 + 1)}-huge.webp`;
    } else if (screenWidth <= 2560) {
      return `${PUBLIC_URL}/imgs/webp/home/2560/bg${parseInt(Math.random() * 2 + 1)}-huge.webp`;
    } else {
      return `${PUBLIC_URL}/imgs/webp/home/3840/bg${parseInt(Math.random() * 2 + 1)}-huge.webp`;
    }
  });

  return (
    <Wrapper className={'bg-global'} backgroundUrl={backgroundUrl}>
      <CenterWrapper>
        <Slogan />
        <SearchForm searchBarBackground={'https://s1.1zoom.me/b5050/96/Brazil_Houses_Marinas_Rio_de_Janeiro_Bay_Night_547331_1920x1080.jpg'} />
      </CenterWrapper>
      <ArrowWrapper>
        <Arrows color={COLOR_2} />
      </ArrowWrapper>
    </Wrapper>
  );
};

Cover.ID = `${Cover.name}_${stringUtil.generateUniqueId()}`;
Cover.defaultBackgroundUrl = `https://s1.1zoom.me/b5050/96/Brazil_Houses_Marinas_Rio_de_Janeiro_Bay_Night_547331_1920x1080.jpg`;

Cover.propTypes = {
  geolocation: propTypes.object,
  updateGeolocation: propTypes.func,
};

const ArrowWrapper = styled.div`
display: none;

  ${mqTablet`
      display: flex;
      justify-content: center;
  `}
`;

const Wrapper = styled.section.attrs({
  id: Cover.ID,
})`
  ${({ backgroundUrl }) => backgrounder(backgroundUrl)};
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const CenterWrapper = styled.div`
  width: 100%;
  height: 1px;
  flex-grow: 1;
  ${bigSectionPadding};
  padding-top: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${mqTablet`
    padding-top: 50px;
  ` };
`;

const mapStateToProps = state => ({
  geolocation: state.data.geolocation,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  updateGeolocation: GeolocationActions.updateGeolocation,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Cover);
