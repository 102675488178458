
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Persist } from 'react-persist-plus';

import InputWrapper from '../../../components/input-wrapper';
import Checkbox from '../../../components/checkbox';

/*
  global
  window
*/

class Others extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      form: {
        typesOfGuarantee: false,
        status: true,
        video: false,
      },
    };

  }

  handleFormChange = event => {
    const { name, checked } = event.target;

    this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        [name]: checked,
      },
    }));
  };

  render() {
    const { form } = this.state;

    return (
      <Wrapper
        onChange={ this.handleFormChange }
      >
        <Persist
          name='REPORT_BUILDER_OTHERS_FORM'
          data={ form }
          onMount={ data => this.setState(state => ({
            ...state,
            form: data,
          })) }
          store={ window.sessionStorage }
        />
        <AInputWrapper
          label='Video'
          htmlFor='video'
        >
          <ACheckBox
            id='video'
            name='video'
            checked={ form.video }
          />
        </AInputWrapper>

        <AInputWrapper
          label='Tipos de garantia'
          htmlFor='typesOfGuarantee'
        >
          <ACheckBox
            id='typesOfGuarantee'
            name='typesOfGuarantee'
            checked={ form.typesOfGuarantee }
          />
        </AInputWrapper>

        <AInputWrapper
          label='Ano de Construção'
          htmlFor='yearBuilt'
        >
          <ACheckBox
            id='yearBuilt'
            name='yearBuilt'
            checked={ form.yearBuilt }
          />
        </AInputWrapper>
      </Wrapper>
    );
  }
}

const Wrapper = styled.form`
  margin-bottom: 100px;
`;

const AInputWrapper = styled(InputWrapper)`
  flex-direction: row-reverse;
  justify-content: flex-end;
  margin-bottom: 10px;
`;

const ACheckBox = styled(Checkbox)`
  margin-right: 5px;
`;

export default Others;
