import React, { PureComponent } from 'react';
import propTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { toast } from 'react-toastify';

import { BaseInput, MaskedBaseInput, masks, ErrorMessage } from '../../../styled/inputs/inputs';
import { COLOR_2, COLOR_1, COLOR_4, COLOR_17, COLOR_13 } from '../../../styled/variables/variables';
import { BaseButton } from '../../../styled/buttons/buttons';
import { mqTablet } from '../../../styled/helpers/helpers';
import { BaseLabel } from '../../../styled/labels/labels';

import Address from '../builder/address/Address';
import Payment from '../payment/Payment';

import planService from '../../../services/plan/plan.service'

import queryStringUtil from '../../../utils/query-string/query-string.util';
import objectUtil from '../../../utils/object/object.util';
import validationUtil from '../../../utils/validation/validation.util';
import domEventsUtil from '../../../utils/dom-events/dom-events.util';
import eventConstants from '../../../constants/events';
import pathsConstants from '../../../constants/paths';

const customId = "custom-id-yes";
class BillingInfo extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        phoneNumber: props.user.phoneNumber,
        cellphoneNumber: '',
        personName: props.user.name,
        social: '',
        email: props.user.email,
        document: '',
      },
      showErrorsFor: {},
      showInvalids: false,
      barCodeInfoPayment: '',
      isCompany: false,
    };
  }

  handleFormChange = async event => {
    const { name, value } = event.target;

    await this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        [name]: value,
      },
    }));
    await this.handleGenerateBarCode();
    const data = await {
      ...this.state.form,
    };
    const validationErrors = [];
    const optionalFields = ['social', 'phoneNumber', 'email'];
    await objectUtil.keys(data).forEach(key => {
      if (optionalFields.find(f => f === key)) return;
      if (
        data[key] === null ||
        data[key] === undefined ||
        data[key] === '' ||
        data[key].length === 0
      ) {
        validationErrors.push(`${key} cannot be empty.`);
      }
    });

    if (validationErrors.length > 0 || await !this.validateCPF(this.state.form.document) || await !this.validateEmail(this.state.form.email)) {
      await localStorage.setItem('invalidAd', true);
      await this.setState(state => ({
        ...state,
        showInvalids: true,
      }));
      return;
    } else {
      await localStorage.setItem('invalidAd', false);
      await this.setState(state => ({
        ...state,
        showInvalids: false,
      }));
      return;
    }
  };

  handleInputBlur = event => {
    const { name } = event.target;

    this.setState(state => ({
      ...state,
      showErrorsFor: {
        ...state.showErrorsFor,
        [name]: true,
      },
    }));
  };

  setValidation = (value) => {
    this.setState({ showInvalids: value });
  }

  componentDidMount() {
    this.handleGenerateBarCode();
    localStorage.setItem('invalidAd', true);
  }

  handleGenerateBarCode = () => {
    let parsePlanInfo = localStorage.getItem('planInfo');
    parsePlanInfo = JSON.parse(parsePlanInfo);
    const data = {
      ...this.state.form,
      ...this.addressRef.state,
      ...parsePlanInfo
    }
    this.setState({
      barCodeInfoPayment: data
    })
  }

  handleButtonClick = () => {
    this.setState({ isCompany: !this.state.isCompany });
  }

  validateCPF(string) {
    const cpf = string.replace(/[^\d]+/g, '');
    if (cpf === '') return false;
    // Elimina CPFs invalidos conhecidos
    if (
      cpf.length !== 11 ||
      cpf === '00000000000' ||
      cpf === '11111111111' ||
      cpf === '22222222222' ||
      cpf === '33333333333' ||
      cpf === '44444444444' ||
      cpf === '55555555555' ||
      cpf === '66666666666' ||
      cpf === '77777777777' ||
      cpf === '88888888888' ||
      cpf === '99999999999'
    )
      return false;
    // Valida 1o digito
    let add = 0;
    for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i), 10) * (10 - i);
    let rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) rev = 0;
    if (rev !== parseInt(cpf.charAt(9), 10)) return false;
    // Valida 2o digito
    add = 0;
    for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i), 10) * (11 - i);
    rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) rev = 0;
    if (rev !== parseInt(cpf.charAt(10), 10)) return false;
    return true;
  }

  validateEmail(string) {
    const email = string;
    if (email.length > 0) {
      if (email.includes('@')) return true;
      else return false;
    } else return false;
  }

  handleNextClick = () => {
    const { history } = this.props;
    let planId = localStorage.getItem('planInfo');
    planId = JSON.parse(planId);
    history.push({
      pathname: pathsConstants.PURCHASE_FLOW_PAYMENT,
      search: queryStringUtil.stringify(history, {
        planId: planId.name,
      }),
    })
  }

  validateFields = () => {
    let parsePlanInfo = localStorage.getItem('planInfo');
    parsePlanInfo = JSON.parse(parsePlanInfo);
    const data = {
      ...this.state.form,
      ...this.addressRef.state.form,
      ...parsePlanInfo
    };

    if (this.state.showInvalids) {
      toast.error('Erro! Verifique se todos os campos foram preenchidos corretamente', {
        autoClose: false,
        toastId: customId,
      });
      return false;
    }
    if (
      !data.personName ||
      !data.email ||
      !data.phoneNumber ||
      !data.cellphoneNumber ||
      !data.document ||
      !data.zipCode ||
      !data.street ||
      !data.number ||
      !data.neighborhood ||
      !data.city ||
      !data.state
    ) {
      toast.error('Erro! Verifique se todos os campos foram preenchidos corretamente', {
        autoClose: false,
        toastId: customId,
      });

      return false;
    }

    return true;
  }

  onSubmit = async (event) => {
    event.preventDefault();
    if (this.validateFields()) {
      let parsePlanInfo = localStorage.getItem('planInfo');
      parsePlanInfo = JSON.parse(parsePlanInfo);
      const data = {
        ...this.state.form,
        ...this.addressRef.state,
        ...parsePlanInfo
      };

      const { user } = this.props;

      domEventsUtil.dispatch({
        name: eventConstants.LOADING_ANIMATION,
        params: { show: true, },
      });

      await planService.buyBankSlip({ token: user.token, data })
        .then(() => {
          const { history } = this.props;
          history.push({
            pathname: pathsConstants.PURCHASE_FLOW_CONGRATULATIONS,
            search: queryStringUtil.stringify(history, {
              paymentMethod: '0',
            }),
          })
        })
        .catch((error) => {
          toast.error('Erro criando anúncio.', {
            autoClose: false,
            toastId: customId,
          });
        })

      domEventsUtil.dispatch({
        name: eventConstants.LOADING_ANIMATION,
        params: { show: false, },
      });
    }
  }

  render() {
    const { form, barCodeInfoPayment } = this.state;

    return (
      <>
        <Wrapper onChange={this.handleFormChange} onSubmit={this.onSubmit}>
          <h1 style={{ marginBottom: 30, fontSize: '23px' }}><b>Informações para faturamento</b></h1>
          <Label>
            Tipo de documento
          </Label>
          <div style={{ 'display': 'flex' }}>
            <Button
              isActive={!this.state.isCompany}
              onClick={this.handleButtonClick}
              name={'PF'}
            >
              Pessoa física
            </Button>
            <Button
              isActive={this.state.isCompany}
              onClick={this.handleButtonClick}
              name={'PJ'}
            >
              Pessoa Jurídica
            </Button>
          </div>
          <div style={{ alignSelf: 'flex-start', marginTop: 10, width: '100%' }}>
            {
              this.state.isCompany ?
                <>
                  <div className={'form-floating'}>
                    <Input
                      value={form.social}
                      onBlur={this.handleInputBlur}
                      name='social'
                      id='social'
                      placeholder="Razão social"
                      className={'form-control'}
                      style={{ 'width': '100%' }}
                      isInvalid={this.state.showInvalids && !form.social ? true : false}
                    />
                    <label className={'form-label'} htmlFor={'social'}>Razão social</label>
                  </div>
                </>
                :
                <>
                  <div className={'form-floating'}>
                    <Input
                      value={form.personName}
                      onBlur={this.handleInputBlur}
                      placeholder="Nome"
                      id='personName'
                      className={'form-control'}
                      style={{ 'width': '100%' }}
                      name='personName'
                      isInvalid={this.state.showInvalids && !form.personName ? true : false}
                    />
                    <label className={'form-label'} htmlFor={'personName'}>Nome</label>
                  </div>
                </>
            }
            <div className={'form-floating'}>
              <Input
                value={form.email}
                onBlur={this.handleInputBlur}
                placeholder="Email"
                name='email'
                id='email'
                className={'form-control'}
                style={{ width: '100%' }}
              />
              <label className={'form-label'} htmlFor={'email'}>Email</label>
            </div>
            {this.state.showInvalids && form.email && !validationUtil.email({ value: form.email }) && <ErrorMessage>Email inválido</ErrorMessage>}
            <div className={'form-floating'}>
              <PhoneNumberInput
                name='phoneNumber'
                id='phoneNumber'
                className={'form-control'}
                style={{ width: '100%' }}
                value={form.phoneNumber}
              />
              <label className={'form-label'} htmlFor={'phoneNumber'}>Telefone</label>
            </div>
            {form.phoneNumber && form.phoneNumber.replace(/[^a-zA-Z0-9]+/g, '').substr(2).length < 11 && <ErrorMessage>Informe os 11 dígitos</ErrorMessage>}

            {
              this.state.isCompany ?
                <>
                  <div className={'form-floating'}>
                    <Input
                      as={MaskedBaseInput}
                      value={form.document}
                      isInvalid={this.state.showInvalids && !form.document ? true : false}
                      onBlur={this.handleInputBlur}
                      placeholder="CNPJ"
                      mask={masks.cnpj}
                      name='document'
                      id='document'
                      className={'form-control'}
                      style={{ width: '100%' }}
                    />
                    <label className={'form-label'} htmlFor={'document'}>CNPJ</label>
                  </div>
                  {this.state.showInvalids && form.document && !validationUtil.cnpj({ value: form.document }) && <ErrorMessage>CNPJ inválido</ErrorMessage>}
                </>
                :
                <>
                  <div className={'form-floating'}>
                    <Input
                      as={MaskedBaseInput}
                      value={form.document}
                      isInvalid={this.state.showInvalids && !form.document ? true : false}
                      placeholder="CPF"
                      onBlur={this.handleInputBlur}
                      mask={masks.cpf}
                      name='document'
                      id='document'
                      className={'form-control'}
                      style={{ width: '100%' }}
                    />
                    <label className={'form-label'} htmlFor={'document'}>CPF</label>
                  </div>
                  {this.state.showInvalids && form.document && !validationUtil.cpf({ value: form.document }) && <ErrorMessage>CPF inválido</ErrorMessage>}
                </>
            }
            <Label>
              Endereço de cobrança
            </Label>
            <Address
              ref={ref => {
                this.addressRef = ref;
              }}
              isInvalid={this.state.showInvalids}
              billingInfo
              userAddress={this.props.userAddress}
            />
          </div>
          {!this.props.showPayment && <FinishButton>
            Continuar
          </FinishButton>}
        </Wrapper>
        {this.props.showPayment &&
          <Payment
            history={this.props.history}
            user={this.props.user}
            paymentInfo={barCodeInfoPayment}
            formValue={this.state}
            setInvalid={this.setValidation}
            isChangePlan={!!this.props.isChangePlan}
            onChangePlanTriggered={(paymentInfo, creditCardInfo, isCompany) => this.props.onChangePlanTriggered(paymentInfo, creditCardInfo, isCompany)}
            cardBrands={this.props.cardBrands}
          />
        }
      </>
    );
  }
}

BillingInfo.propTypes = {
  title: propTypes.string,
  showPayment: propTypes.bool,
};

BillingInfo.defaultProps = {
  showPayment: true,
};

const Wrapper = styled.form`
  ${mqTablet`
    width: 100%;
    max-width: 573px;
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
` }
`;

const Button = styled(BaseButton)`
  flex-grow: 1;
  width: 1px;
  font-weight: 300;
  border: solid 1px ${COLOR_1};

  ${({ isActive }) => !isActive && css`
    color: ${COLOR_4};
    background-color: ${COLOR_2};
    border-color: ${COLOR_17};
  ` }
`;

const Input = styled(BaseInput)`
  align-self:flex-start;
  margin-top: 10px;
  color: #000000;
`;

const Label = styled(BaseLabel)`
  margin-bottom: 10px;
  margin-top:5px;
  font-weight:bold;
`;

const PhoneNumberInput = styled(BaseInput).attrs({
  placeholder: 'Telefone',
  as: MaskedBaseInput,
  mask: masks.phones,
})`
  width:100%;
`;

const FinishButton = styled(BaseButton).attrs({
  type: 'submit',
})`
  background-color: ${COLOR_13};
  border: none;
  color: ${COLOR_2};
  width: 170px;
  align-self: flex-end;
  margin-top: 10px;
`;

export default BillingInfo;
