
import React from 'react';
import propTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { toast } from 'react-toastify';
import { ShareSquare } from 'styled-icons/fa-regular/ShareSquare';
import { Edit } from 'styled-icons/boxicons-regular/Edit';
import { TrashAlt } from 'styled-icons/boxicons-regular/TrashAlt';

import { mqTablet } from '../../../../../styled/helpers/helpers';

import TooltipButton from '../../../../../components/tooltip-button';
import pathConstants from '../../../../../constants/paths';
import queryStringUtil from '../../../../../utils/query-string/query-string.util';
import socialShareUtil from '../../../../../utils/social-share/social-share.util';
import reportService from '../../../../../services/report/report.service';
/*
  global
  window
  alert
*/
const customId = "custom-id-yes";

const Buttons = ({
  history,
  onUpdate,
  report,
  user,
}) => {

  const share = () => {
    let href = window.location.origin;
    href += history.createHref({
      pathname: pathConstants.REPORT,
      search: queryStringUtil.stringify(null, {
        reportId: report.id,
      }),
    });

    socialShareUtil.share({
      title: `${report.title}\n${report.subtitle}`,
      message: href,
    });
  };

  const edit = async () => {
    // let href = window.location.origin;
    // const href = history.createHref({
    //   pathname: pathConstants.REPORT_BUILDER,
    //   search: queryStringUtil.stringify(null, {
    //     reportId: report.id,
    //   }),
    // });
    // history.push(href);
    try {
      await reportService.update({
        reportId: report.id,
        token: user.token,
      });
    } catch (error) {
      toast.error('Erro atualizando relatório.', {
        autoClose: false,
        toastId: customId,
      }
      );
      return;
    }

    onUpdate();
  };

  const del = async () => {
    try {
      await reportService.del({
        reportId: report._id,
        token: user.token,
      });
    } catch (error) {
      toast.error('Erro removendo relatório.', {
        autoClose: false,
        toastId: customId,
      });
      return;
    }

    onUpdate();
  };

  return (
    <Wrapper>
      <BTooltipButton
        label='Compart.'
        onClick={share}
        Icon={ShareSquare}
      />
      <BTooltipButton
        label='Editar'
        onClick={edit}
        Icon={Edit}
        iconStyle={editIconStyle}
      />
      <BTooltipButton
        label='Remover'
        onClick={del}
        Icon={TrashAlt}
        iconStyle={removeIconStyle}
      />
      {/* <BTooltipButton 
        label='Desabilitar'
        onClick={ disable }
        Icon={ EyeSlash }
      /> */}
    </Wrapper>
  );
};

Buttons.propTypes = {
  history: propTypes.object,
  onUpdate: propTypes.func,
  report: propTypes.object,
  user: propTypes.object,
};

Buttons.defaultProps = {

};

const Wrapper = styled.div`
  display: flex;
  align-self: center;

  ${mqTablet`
    align-self: flex-end;
  ` }
`;

const BTooltipButton = styled(TooltipButton)``;

const editIconStyle = css`
  height: 20px;
`;

const removeIconStyle = css`
  height: 20px;
`;

export default withRouter(Buttons);
