import React from 'react';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { RiGroupFill, RiTimeFill } from 'react-icons/ri';
import { RiArrowDownLine, RiArrowUpLine, RiRefreshLine } from 'react-icons/ri';

import userService from '../../../../services/user/user.service';
import leadsService from '../../../../services/leads/leads.service';

import {
  GeneralStatisticsWrapper,
  StatisticsCard
} from './styles';

export function GeneralStatistics({ referenceType, referenceId }) {
  const [averageResponseTimeData, setAverageResponseTimeData] = useState({
    lastMonthFormatted: '0min',
    currentMonthFormatted: '0min',
    percentage: '0%',
    percentageStatus: 'off',
  });

  const [totalLeadsData, setTotalLeadsData] = useState({
    lastMonth: 0,
    currentMonth: 0,
    percentage: '0%',
    percentageStatus: 'off',
  });

  function minutesToHours(minutes) {
    if (minutes > 59) {
      const hours = Math.round((minutes / 60));

      return `${hours}h`;
    }

    return `${minutes}min`;
  }

  function getPercentageBetweenTwoValues(value1, value2) {
    if (value2 > 0) {
      const percentage = ((value1 - value2) / value2) * 100;

      return `${percentage.toFixed(2)}%`;
    }

    return '0%';
  }

  function getPercentageBetweenTwoValuesForAverageResponseTime(value1, value2) {
    if (value2 > 0) {
      let percentage = ((value1 - value2) / value2) * 100;

      if (Math.sign(percentage) === -1) {
        percentage = String(percentage).replace('-', '')
      } else {
        percentage = Number(`-${percentage}`);
      }

      return `${Number(percentage).toFixed(2)}%`;
    }

    return '0%';
  }

  function getPercentageStatus(percentageFormatted) {
    if (percentageFormatted === '0%') {
      return 'off';
    }

    if (!percentageFormatted.includes('-')) {
      return 'up';
    }

    if (percentageFormatted.includes('-')) {
      return 'down';
    }
  }

  useEffect(() => {
    async function getLeadsStatistics() {
      try {
        const user = userService.retrieve();

        const { status, data } = await leadsService.getLeadsStatistics({
          referenceId,
          referenceType,
          token: user.token,
        });

        if (status !== 200) {
          throw new Error('Não foi possível buscar as estatísticas, recarregue a página!');
        }

        const { averageResponseTime, totalLeads } = data;

        const averageResponseTimePercentage = getPercentageBetweenTwoValuesForAverageResponseTime(averageResponseTime.currentMonth, averageResponseTime.lastMonth);
        const totalLeadsPercentage = getPercentageBetweenTwoValues(totalLeads.currentMonth, totalLeads.lastMonth);

        const averageResponseTimeFormatted = {
          ...averageResponseTime,
          currentMonthFormatted: minutesToHours(averageResponseTime.currentMonth),
          lastMonthFormatted: minutesToHours(averageResponseTime.lastMonth),
          percentage: averageResponseTimePercentage,
          percentageStatus: getPercentageStatus(averageResponseTimePercentage),
        }

        const totalLeadsFormatted = {
          ...totalLeads,
          percentage: totalLeadsPercentage,
          percentageStatus: getPercentageStatus(totalLeadsPercentage),
        }

        setAverageResponseTimeData(averageResponseTimeFormatted);
        setTotalLeadsData(totalLeadsFormatted);
      } catch {
        toast.error('Não foi possível buscar as estatísticas, recarregue a página!', {
          autoClose: 5000,
        })
      }
    }

    if (!!referenceType && !!referenceId) {
      getLeadsStatistics();
    }
  }, [referenceType, referenceId])

  return (
    <GeneralStatisticsWrapper>
      {totalLeadsData && (
        <StatisticsCard type={totalLeadsData.percentageStatus}>
          <h3>
            <RiGroupFill size={22} />
            Total de leads
          </h3>

          <strong>
            {totalLeadsData.currentMonth}
            <span>
              {totalLeadsData.percentageStatus === "up" && <RiArrowUpLine size={14} />}
              {totalLeadsData.percentageStatus === "down" && <RiArrowDownLine size={14} />}
              {totalLeadsData.percentage}
            </span>
          </strong>

          <span>
            vs Último mês: <b>{totalLeadsData.lastMonth}</b>
          </span>
        </StatisticsCard>
      )}

      {averageResponseTimeData && (
        <StatisticsCard type={averageResponseTimeData.percentageStatus}>
          <h3>
            <RiTimeFill size={22} />
            Tempo médio de resposta
          </h3>

          <strong>
            {averageResponseTimeData.currentMonthFormatted}
            <span>
              {averageResponseTimeData.percentageStatus === "up" && <RiArrowUpLine size={14} />}
              {averageResponseTimeData.percentageStatus === "down" && <RiArrowDownLine size={14} />}
              {averageResponseTimeData.percentage}
            </span>
          </strong>

          <span>
            vs Último mês: <b>{averageResponseTimeData.lastMonthFormatted}</b>
          </span>
        </StatisticsCard>
      )}
    </GeneralStatisticsWrapper>
  );
}
