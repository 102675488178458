
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Persist } from 'react-persist-plus';

import InputWrapper from '../../../components/input-wrapper';
import Checkbox from '../../../components/checkbox';

/*
  global
  window
*/

class Development extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      form: {
        code: false,
        name: false,
        logo: false,
        constructionStatus: false,
        buildings: false,
        floors: false,
        highlights: false,
      },
    };

  }

  handleFormChange = event => {
    const { name, checked } = event.target;

    this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        [name]: checked,
      },
    }));
  };

  render() {
    const { form } = this.state;

    return (
      <Wrapper
        onChange={ this.handleFormChange }
      >
        <Persist
          name='REPORT_BUILDER_DEVELOPMENT_FORM'
          data={ form }
          onMount={ data => this.setState(state => ({
            ...state,
            form: data,
          })) }
          store={ window.sessionStorage }
        />
        <AInputWrapper
          label='Código'
          htmlFor='code'
        >
          <ACheckBox
            id='code'
            name='code'
            checked={ form.code }
          />
        </AInputWrapper>

        <AInputWrapper
          label='Nome'
          htmlFor='name'
        >
          <ACheckBox
            id='name'
            name='name'
            checked={ form.name }
          />
        </AInputWrapper>

        <AInputWrapper
          label='Logo'
          htmlFor='logo'
        >
          <ACheckBox
            id='logo'
            name='logo'
            checked={ form.logo }
          />
        </AInputWrapper>

        <AInputWrapper
          label='Status da Construção'
          htmlFor='constructionStatus'
        >
          <ACheckBox
            id='constructionStatus'
            name='constructionStatus'
            checked={ form.constructionStatus }
          />
        </AInputWrapper>

        <AInputWrapper
          label='Prédios'
          htmlFor='buildings'
        >
          <ACheckBox
            id='buildings'
            name='buildings'
            checked={ form.buildings }
          />
        </AInputWrapper>

        <AInputWrapper
          label='Andares'
          htmlFor='floors'
        >
          <ACheckBox
            id='floors'
            name='floors'
            checked={ form.floors }
          />
        </AInputWrapper>

        <AInputWrapper
          label='Destaques'
          htmlFor='highlights'
        >
          <ACheckBox
            id='highlights'
            name='highlights'
            checked={ form.highlights }
          />
        </AInputWrapper>
      </Wrapper>
    );
  }
}

const Wrapper = styled.form`
  margin-bottom: 20px;
`;

const AInputWrapper = styled(InputWrapper)`
  flex-direction: row-reverse;
  justify-content: flex-end;
  margin-bottom: 10px;
`;

const ACheckBox = styled(Checkbox)`
  margin-right: 5px;
`;

export default Development;
