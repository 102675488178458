import styled from 'styled-components';
import { CarouselProvider } from 'pure-react-carousel';

export const Container = styled.div`
  height: 100%;
  position: relative;
`;

export const SliderControlButtonLeft = styled.span`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;

  opacity: 0;

  background: -moz-linear-gradient(90deg, rgba(0,0,0,0.3) 0%, rgba(0,212,255,0) 100%);
  background: -webkit-linear-gradient(90deg, rgba(0,0,0,0.3) 0%, rgba(0,212,255,0) 100%);
  background: linear-gradient(90deg, rgba(0,0,0,0.3) 0%, rgba(0,212,255,0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#00d4ff",GradientType=1);

  display: flex;
  align-items: center;

  transition: opacity .25s ease;

  > button {
    background-color: transparent;
    height: 100%;
  }

  @media screen and (max-width: 768px) {
    opacity: 1;
  }
`;

export const SliderControlButtonRight = styled.span`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;

  background: -moz-linear-gradient(270deg, rgba(0,0,0,0.3) 0%, rgba(0,212,255,0) 100%);
  background: -webkit-linear-gradient(270deg, rgba(0,0,0,0.3) 0%, rgba(0,212,255,0) 100%);
  background: linear-gradient(270deg, rgba(0,0,0,0.3) 0%, rgba(0,212,255,0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#00d4ff",GradientType=1);

  display: flex;
  align-items: center;

  transition: opacity .25s ease;

  > button {
    background-color: transparent;
    height: 100%;
  }

  @media screen and (max-width: 768px) {
    opacity: 1;
  }
`;

export const Carousel = styled(CarouselProvider)`
  height: 100%;

  div {
    outline: 0;
  }

  &:hover {
    ${SliderControlButtonRight},
    ${SliderControlButtonLeft} {
      opacity: 1;
    }
  }
`;

export const SlideItem = styled.div`
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

export const SlideImg = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const ModalContent = styled.div`
  height: 100%;
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CarouselContent = styled.div``;

export const ButtonsArea = styled.div`
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    border: 0;
    background-color: transparent;
    opacity: 0.7;

    transition: opacity .2s;

    &:hover {
      opacity: 1;
    }

    & + button {
      margin-left: 24px;
    }
  }
`;

export const ModalSliderControlButtonLeft = styled.span`
  position: absolute;
  top: 50%;
  left: 20px;

  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.4);

  display: flex;
  align-items: center;
  justify-content: center;

  transition: background-color 0.2s;

  &:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }

  > button {
    background-color: transparent;
  }
`;

export const ModalSliderControlButtonRight = styled.span`
  position: absolute;
  top: 50%;
  right: 20px;

  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.4);

  display: flex;
  align-items: center;
  justify-content: center;

  transition: background-color 0.2s;

  &:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }

  > button {
    background-color: transparent;
  }
`;
