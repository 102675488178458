import styled from "styled-components";
import { mqCustom, mqDesktop } from "../../../../styled/helpers/helpers";

export const Container = styled.div`
  display: none;
  width: 100%;
  height: calc(100vh - 80px);
  background-color: #ccc;
  margin-top: 80px;

	${mqDesktop`
    display: flex;
    max-width: 600px;
  `}

  ${mqCustom(1300)`
    max-width: 700px;
  `}
`


