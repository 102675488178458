import { css } from 'styled-components';
import { rgba, parseToRgb, lighten, transitions } from 'polished';

import { COLOR_3, COLOR_17, COLOR_8 } from '../variables/variables';

export const mqTablet = (...style) => css`
    @media (min-width: 768px) {
        ${ css(...style) };
    }
`;

export const maxMqTablet = (...style) => css`
    @media (max-width: 768px) {
        ${ css(...style) };
    }
`;

export const mqDesktop = (...style) => css`
    @media (min-width: 1080px) {
        ${ css(...style) };
    }
`;

export const mqCustom = minWidth => (...style) => css`
  @media (min-width: ${ minWidth }px) {
    ${ css(...style) };
  }
`;

export const maxMqCustom = minWidth => (...style) => css`
  @media (max-width: ${ minWidth }px) {
    ${ css(...style) };
  }
`;

export const mqLandscape = minHeight => (...style) => css`
  @media (min-height: ${ minHeight }px){
    ${ css(...style) };
  }
`;

export const interacted = (...style) => css`
  &:hover, &:active, &:focus {
    ${ css(...style) };
  }
`;

export const backgrounder = (url) => css`
  background-image: url('${ url }');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const dimmer = (amount = 0.7, color = COLOR_17) => css`
  background-color: ${ hexToRgb(color, amount) };
`;

export const hexToRgb = (color, opacity = 1) => {
  return rgba({ ...parseToRgb(color), alpha: opacity });
};

export const gradient = (direction='bottom', alpha = 1) => {
  const startColor = rgba({ ...parseToRgb(lighten(0, COLOR_3)), alpha, });
  const endColor = 'rgba(0, 0, 0, 0.0)';
  return css`
    background-image: linear-gradient(to ${ direction }, ${ startColor }, ${ endColor });
  `;
};

export const pointer = css`
  &, * {
    cursor: pointer;
  }
`;

export const sectionPadding = css`
  padding: 10px;
  ${ mqTablet`
    padding: 10px 25px;
  ` }
`;

export const noScrollBar = css`
  & ::-webkit-scrollbar {
    display: none;
  };
`;

export const bigSectionPadding = css`
  padding: 100px 10px 50px 10px;
  ${ mqTablet`
    padding: 100px 20px 50px 20px;
  ` }
`;

export const bigSectionBaseStyle = css`
  ${ bigSectionPadding }
  min-height: 100vh;

  ${ mqTablet`
    height: 100vh;
    min-height: 800px;
  ` }
`;

export const noTextSelect = css`
  * {
    user-select: none;
  }
`

export const pagePadding = css`
  padding-top: 60px;
`;

export const iconBaseStyle = css`
  height: 20px;
  display: block;
  color: ${ COLOR_8 };

  ${ transitions([ 'color' ], 'ease .3s') }
`;

export const scrollingShadow = css`
  background: linear-gradient(white 30%, rgba(255, 255, 255, 0)),
              linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
              radial-gradient(farthest-side at 50% 0,
              rgba(0, 0, 0, 0.2),
              rgba(0, 0, 0, 0)),
              radial-gradient(farthest-side at 50% 100%,
              rgba(0, 0, 0, 0.2),
              rgba(0, 0, 0, 0)) 0 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  /* Opera doesn't support this in the shorthand */
  background-attachment: local, local, scroll, scroll;
  transition: height 0.3s ease-in 0.3s;
`;

export const scrollBar = css`
  overflow-y: scroll;
  -ms-overflow-style: none;  IE and Edge
  /* scrollbar-width: none;  Firefox */
  &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 1px;
      background-color: transparent;
      border: solid 10px transparent;
  }
  &::-webkit-scrollbar {
      width: 10px;
      background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 1px;;
      background-color: lightgray;
      border: solid 10px transparent;
  }
`;

export const shadower = ({
  direction = 'bottom',
  size = 4,
  opacity = 0.3,
}) => {

  let vertical = 0,
      horizontal = 0,
      spread = 0;

  const negative = -1;
  switch (direction) {
    case 'top': {
      vertical = negative * size;
      break;
    }
    case 'top-right': {
      vertical = negative * size;
      horizontal = size;
      break;
    }
    case 'right': {
      horizontal = size;
      break;
    }
    case 'bottom-right': {
      vertical = size;
      horizontal = size;
      break;
    }
    case 'bottom': {
      vertical = size;
      break;
    }
    case 'bottom-left': {
      vertical = size;
      horizontal = negative * size;
      break;
    }
    case 'left': {
      horizontal = negative * size;
      break;
    }
    case 'top-left': {
      vertical = negative * size;
      horizontal = negative * size;
      break;
    }
    case 'all': {
      spread = size;
      break;
    }
    default: throw new Error('Unknown direction.');
  }

  return css`
    box-shadow:
      ${ horizontal }px
      ${ vertical }px
      6px
      ${ spread }px
      rgba(0, 0, 0, ${ opacity });
  `;
};

export default {
  backgrounder,
  dimmer,
  iconBaseStyle,
  interacted,
  mqTablet,
  mqDesktop,
  mqCustom,
  maxMqCustom,
  maxMqTablet,
  noScrollBar,
  pointer,
  scrollBar,
  scrollingShadow,
  sectionPadding,
  shadower,
};
