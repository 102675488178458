import React, { PureComponent } from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { ellipsis } from 'polished';
import { Edit } from 'styled-icons/boxicons-regular/Edit';
import { Exit } from 'styled-icons/boxicons-regular/Exit';
import { MouseAlt } from 'styled-icons/boxicons-regular/MouseAlt';
import { Circle } from 'styled-icons/boxicons-regular/Circle';
import { Pyramid } from 'styled-icons/boxicons-regular/Pyramid';
import { Pencil } from 'styled-icons/boxicons-regular/Pencil';
import { PhotoSizeSelectSmall } from 'styled-icons/material/PhotoSizeSelectSmall';

import { BaseButton } from '../../../styled/buttons/buttons';
import { COLOR_2, COLOR_3, COLOR_13, GOOGLE_MAPS_STYLE, COLOR_17 } from '../../../styled/variables/variables';
import { mqDesktop, iconBaseStyle } from '../../../styled/helpers/helpers';

import Marker, { TYPES as MARKER_TYPES } from '../../../components/marker/Marker';
import * as map_functions from './map-functions';

import currencyUtil from '../../../utils/currency/currency.util';
import domEventsUtil from '../../../utils/dom-events/dom-events.util';
import eventConstants from '../../../constants/events';

const { PUBLIC_URL } = process.env;
const { LatLng } = window.google.maps;
const ListDrawingInUse = [];

class Map extends PureComponent {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.state = {
      inDrawing: false,
      drawingMode: null,
      isDrawingActive: false,
      drawingModeActive: false,
      idAd: this.props.idAd,
      markers: [],
      circle: null,
      boundsDefault: null,
    };
  }

  componentDidMount() {
    this.map = new window.google.maps.Map(document.getElementById("map"), {
      zoom: window.innerWidth <= 1200 ? 9 : 11,
      // maxZoom: 15,
      minZoom: window.innerWidth <= 1200 ? 9 : 11,
      disableDoubleClickZoom: true,
      // center: { lat: -22.9035, lng: -43.2096 },
      // center: { lat: -23.594139523233785, lng: -46.680919281371175 },
      styles: GOOGLE_MAPS_STYLE,
      mapTypeControl: false,
      streetViewControl: false,
    });



    var myoverlay = new window.google.maps.OverlayView();
    myoverlay.draw = function () {
      this.getPanes().markerLayer.id = 'markerLayer';
    };
    myoverlay.setMap(this.map);


    window.google.maps.event.addListener(this.map, 'click', () => {
      if (this.tooltip) this.tooltip.close();
    });

    this.drawingManager = new window.google.maps.drawing.DrawingManager({
      drawingMode: window.google.maps.drawing.OverlayType.MARKER,
      drawingControl: false,
      drawingControlOptions: {
        position: window.google.maps.ControlPosition.TOP_LEFT,
        drawingModes: [
          window.google.maps.drawing.OverlayType.CIRCLE,
          window.google.maps.drawing.OverlayType.POLYLINE,
        ],
      },
      markerOptions: {
        icon: "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
      },
      circleOptions: {
        fillColor: "#8ACC2C",
        fillOpacity: 0.3,
        strokeWeight: 1,
        clickable: false,
        editable: true,
        zIndex: 1,
      },
      polylineOptions: {
        clickable: false,
        editable: true,
        zIndex: 1,
        strokeColor: "#8ACC2C",
        strokeOpacity: 1.0,
        strokeWeight: 3,
      },
    });

    this.drawingManager.setDrawingMode(null);
    this.setState(({
      drawingMode: null,
    }))
    this.drawingManager.setMap(this.map);

    // this.enableBoundsChangedEvent();
    this.registerFunctions();
  }

  getPadding = () => ({
    top: window.innerWidth < 1080 ? 110 : 180,
    right: window.innerWidth < 1080 ? 0 : 500,
  });



  changeIsDrawing = () => {
    this.setState(() => ({
      isDrawingActive: !this.state.isDrawingActive,
    }));
  }

  removeMarkers = () => {
    if (this.state.markers) {
      this.state.markers.forEach(item => {
        item.setMap(null)
        // item.onRemove();
      });
    }
  };

  setBounds = geolocation => {
    if (ListDrawingInUse.length > 0) {
      ListDrawingInUse.forEach(draw => {
        if (draw.setMap) {
          draw.setMap(null);
        } else if (draw.overlay.setMap) {
          draw.overlay.setMap(null);
        }
      })
      this.drawingManager.setDrawingMode(null);
      this.setState(({ drawingMode: null, inDrawing: false }))
    }

    this.bounds = new window.google.maps.LatLngBounds({
      lat: geolocation.south,
      lng: geolocation.west,
    }, {
      lat: geolocation.north,
      lng: geolocation.east,
    });
    this.setState(() => ({
      boundsDefault: this.bounds
    }));
    // this.map.fitBounds(this.bounds, {top: 0, right: 0});
    this.map.fitBounds(this.bounds);
    this.map.panToBounds(this.bounds);

    // this.map.setZoom(12);

    setTimeout(() => this.enableBoundsChangedEvent(), 2000);
  };

  setCenter = ({ latitude, longitude }) => {
    // this.disableBoundsChangedEvent();
    this.map.setCenter({
      lat: latitude,
      lng: longitude,
    });
    this.map.setZoom(15);

    this.map.panBy((this.getPadding().right / 2), 0);
    setTimeout(() => this.enableBoundsChangedEvent(), 500);
  };

  enableBoundsChangedEvent = () => {
    var timeout = null;
    window.google.maps.event.addListener(this.map, 'idle', () => {

      const bounds = this.map.getBounds();
      if (bounds && !this.state.inDrawing) {
        const { onBoundsChanged } = this.props;
        onBoundsChanged(bounds.toJSON(), true);
      }

      if (timeout) {
        window.clearTimeout(timeout);
      }
      timeout = window.setTimeout(() => {
        const bounds = this.map.getBounds();
        if (bounds && !this.state.inDrawing) {
          const { onBoundsChanged } = this.props;
          onBoundsChanged(bounds.toJSON(), false);
        }
      }, 1500);
    });
  };

  setMarkers = (configs, clearClusters = false) => {
    this.state.markers = map_functions.createMarkersInMap(this.map, configs, this.props, this.drawingManager, clearClusters);
  }

  initDrawing = () => {
    this.setState(({
      inDrawing: true,
    }), () => {
      // map_functions.drawingPolyline(this.map);
      // this.map.setOptions({draggableCursor:'crosshair'});
      // this.drawingManager.setMap(this.map);
      this.drawingManager.setDrawingMode('circle');
      this.setState(({
        drawingMode: 'circle',
      }))
      // if (this.drawingManager.getDrawingMode()) {
      // this.map.setOptions({ draggable: false, zoomControl: false, scrollwheel: false, disableDoubleClickZoom: true });
      // }

    });
  }

  clearDrawing = () => {
    this.setState(({
      inDrawing: false,
    }), () => {
      this.drawingManager.setDrawingMode(null); // Return to 'hand' mode
      this.setState(({
        drawingMode: null,
      }))
      // if (this.state.markers) this.state.markers.forEach(item => {
      //   item.setIcon(item.highlight ? `${process.env.PUBLIC_URL}/imgs/mark-highlight.png` : `${process.env.PUBLIC_URL}/imgs/mark.png`)
      // });
      map_functions.clearDrawingMap(ListDrawingInUse);
      while (ListDrawingInUse.length) {
        ListDrawingInUse.pop();
      }

      const bounds = this.map.getBounds();
      if (bounds && !this.state.inDrawing) {
        const { onBoundsChanged } = this.props;
        onBoundsChanged(bounds.toJSON(), true);
      }
      // this.map.setOptions({ draggable: true, zoomControl: true, scrollwheel: true, disableDoubleClickZoom: false });
    });
  }


  sendMarkerList = (markerList) => {
    const { onNewMapDraw } = this.props;
    setTimeout(() => {
      onNewMapDraw(markerList);
    }, 1000);
  }

  /**
   *
   */
  registerFunctions = async () => {
    /**
     * CIRCLE LISTENER
     */


    window.google.maps.event.addListener(this.drawingManager, 'circlecomplete', (circle) => {
      // this.map.fitBounds(circle.getBounds());
      this.sendMarkerList(map_functions.disabledMarkersNoSelect(this.state.markers, this.map, ListDrawingInUse));
      window.google.maps.event.addListener(circle, 'center_changed', () => {
        this.sendMarkerList(map_functions.disabledMarkersNoSelect(this.state.markers, this.map, ListDrawingInUse));
      });
      window.google.maps.event.addListener(circle, 'radius_changed', () => {
        this.sendMarkerList(map_functions.disabledMarkersNoSelect(this.state.markers, this.map, ListDrawingInUse));
      });
    });
    /**
     * POLYLINE LISTENER
     */
    window.google.maps.event.addListener(this.drawingManager, 'polylinecomplete', (polyline) => {
      // var bounds = new window.google.maps.LatLngBounds;
      // polyline.getPath().forEach(function (latLng) {
      //   bounds.extend(latLng);
      // });
      // this.map.fitBounds(bounds);
      this.sendMarkerList(map_functions.disabledMarkersNoSelect(this.state.markers, this.map, ListDrawingInUse));
      window.google.maps.event.addListener(polyline, 'mouseup', () => {
        this.sendMarkerList(map_functions.disabledMarkersNoSelect(this.state.markers, this.map, ListDrawingInUse));
      });
    });
    /**
     * OverlayComplete
     */
    window.google.maps.event.addListener(this.drawingManager, "overlaycomplete", (event) => {
      ListDrawingInUse.push(event);
      // this.drawingManager._lastDrawing = event.overlay;
      this.drawingManager.setDrawingMode(null); // Return to 'hand' mode
      this.setState(({
        drawingMode: null,
      }));
    });
  }

  drawingPolyline = () => {
    this.polyLine = new window.google.maps.Polyline({
      map: this.map,
      clickable: false,
    });
    this.map.setOptions({
      draggable: false,
      disableDoubleClickZoom: false,
      draggableCursor: 'crosshair',
    });

    window.google.maps.event.addListener(this.map, 'mousedown', () => {
      window.google.maps.event.addListener(this.map, 'mousemove', event => {
        this.polyLine.getPath().push(event.latLng);

        window.google.maps.event.addListener(this.map, 'mouseup', () => {
          window.google.maps.event.clearListeners(this.map, 'mousedown');
          window.google.maps.event.clearListeners(this.map, 'mousemove');
          window.google.maps.event.clearListeners(this.map, 'mouseup');

          this.map.setOptions({
            draggable: true,
            disableDoubleClickZoom: true,
            draggableCursor: null,
          });

          ListDrawingInUse.push(this.polyLine);

          this.sendMarkerList(map_functions.disabledMarkersNoSelect(this.state.markers, this.map, ListDrawingInUse));
          this.drawingManager.setDrawingMode(null); // Return to 'hand' mode
          this.setState(({
            drawingMode: null,
          }));
        });
      });
    });
  }

  setActiveMark = (id) => {
    var markerSelect = null;
    this.state.markers.forEach(function (marker) {
      if (marker.data.id == id) {
        markerSelect = marker;
      }
      if (marker && marker.setTitle) {
        marker.setTitle(null);
        // map_functions.closeInfoWindowMarker(marker);
      }
    });
    if (markerSelect) {
      markerSelect.setTitle(markerSelect.data.property + (markerSelect.data.highlight ? ' destaque selecionado(a).' : ' selecionado(a).'));
      // map_functions.openInfoWindowMarker(markerSelect, this.map);
    }
  }

  setDesactiveMark = (id) => {
    this.state.markers.forEach(function (marker) {
      if (marker.data.id == id) {
        marker.setTitle(null);
        // map_functions.closeInfoWindowMarker(marker);
      }
    });
  }

  render() {
    return (
      <Wrapper >
        <GoogleMapsContainer ref={this.mapContainerRef} id={'map'}>
        </GoogleMapsContainer>
        {!this.state.inDrawing && <DrawButton onClick={this.initDrawing}>
          <PenIcon />
          selecionar área
        </DrawButton>}
        {this.state.inDrawing && <DrawButton onClick={this.clearDrawing}>
          <PenIcon />
          limpar seleção
        </DrawButton>}

        {this.state.inDrawing && <HandleButton onClick={(e) => { this.drawingManager.setDrawingMode(null); this.setState(({ drawingMode: null })) }}>
          <MouseIcon style={{ color: (!this.state.drawingMode ? `${COLOR_13}` : `${COLOR_2}`) }} />
        </HandleButton>}
        {this.state.inDrawing && <CircleButton onClick={() => { this.drawingManager.setDrawingMode('circle'); this.setState(({ drawingMode: 'circle' })) }}>
          <CircleIcon style={{ color: (this.state.drawingMode == 'circle' ? `${COLOR_13}` : `${COLOR_2}`) }} />
        </CircleButton>}
        {this.state.inDrawing && <PolylineButton onClick={() => { this.drawingManager.setDrawingMode('polyline'); this.setState(({ drawingMode: 'polyline' })) }}>
          <PolygonIcon style={{ color: (this.state.drawingMode == 'polyline' ? `${COLOR_13}` : `${COLOR_2}`) }} />
        </PolylineButton>}
        {this.state.inDrawing && <PencilButton onClick={() => { this.drawingManager.setDrawingMode(null); this.drawingPolyline(); this.setState(({ drawingMode: 'pencil' })) }}>
          <PencilIcon style={{ color: (this.state.drawingMode == 'pencil' ? `${COLOR_13}` : `${COLOR_2}`) }} />
        </PencilButton>}

      </Wrapper >
    );
  }
}


Map.defaultProps = {

};

const GoogleMapsContainer = styled.div`
  height: 100%;
  width: 100%;
`;


const Wrapper = styled.div`
  top: 180px;
  position: fixed;
  height: calc(100% - 180px);
  width: 100vw;
  display: block;

  ${mqDesktop`
    height: calc(100% - 80px);
    width: calc(100% - 540px);
    top: 80px;
  ` }

  @media (max-width: 1079px) {
    height: calc(100% - 130px);
    top: 130px;
  }
`;

const DrawButton = styled(BaseButton)`
  ${ellipsis('100%')};
  position: absolute;
  top: 10px;
  left: 10px;
  margin: 0;
  height: 50px;
  background-color: ${COLOR_17};
  color: ${COLOR_2};
  padding: 0 10px;
  height: 40px;
  border: none;
  line-height: 40px;

  svg {
    margin-right: 5px;
    align-self: center;
  }

  ${mqDesktop`
    top: 10px;
  ` };
`;

const HandleButton = styled(DrawButton)`
  top: 60px !important;
`;

const CircleButton = styled(DrawButton)`
    top: 60px !important;
    left: 60px;
`;

const PolylineButton = styled(DrawButton)`
    top: 60px !important;
    left: 110px;
`;

const PencilButton = styled(DrawButton)`
    top: 60px !important;
    left: 160px;
`;

const MouseIcon = styled(MouseAlt)`
  ${iconBaseStyle};
  display: inline-block;
  margin: 0;
  margin-right: 0px !important;
  margin-top: -4px;
  color: ${COLOR_2};
`;

const CircleIcon = styled(Circle)`
  ${iconBaseStyle};
  display: inline-block;
  margin: 0;
  margin-right: 0px !important;
  margin-top: -4px;
  color: ${COLOR_13};
`;

const PolygonIcon = styled(Pyramid)`
  ${iconBaseStyle};
  display: inline-block;
  margin: 0;
  margin-right: 0px !important;
  margin-top: -4px;
  color: ${COLOR_2};
`;

const PencilIcon = styled(Pencil)`
  ${iconBaseStyle};
  display: inline-block;
  margin: 0;
  margin-right: 0px !important;
  margin-top: -4px;
  color: ${COLOR_2};
`;


const PenIcon = styled(Exit)`
  ${iconBaseStyle};
  display: inline-block;
  margin: 0;
  color: ${COLOR_13};
`;

const M2Icon = styled(PhotoSizeSelectSmall)`
  ${iconBaseStyle}
  /* color: ${COLOR_3}; */
  height: 17px;
  align-self: center;
  margin-right: 5px;
`;

export default Map
