function addMaskCellphone(value) {
  const number = value.split('');

  return `+55 (${number[0]}${number[1]}) ${number[2]}${number[3]}${number[4]}${number[5]}${number[6]}-${number[7]}${number[8]}${number[9]}${number[10]}`;
}

function addMaskTelephone(value) {
  const number = value.split('');

  return `+55 (${number[0]}${number[1]}) ${number[2]}${number[3]}${number[4]}${number[5]}-${number[6]}${number[7]}${number[8]}${number[9]}`;
}

export { addMaskCellphone, addMaskTelephone };
