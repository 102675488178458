import httpClient from "../../clients/http/http.client";

const get = async ({
  token,
  type
}) => {
  // type = 1: proprietario
  // type = 2: corretor

  const path = `/plans?userType=${type}`
  const response = await httpClient.get({ path, token });
  return response;
};

const getProduct = async ({
  token
}) => {
  const path = `/plans/management/products/get-active-products`
  return await httpClient.get({ path, token })
}

const buy = async ({
  token,
  data
}) => {
  const params = data;
  const path = '/plans/buy/credit-card'
  const response = await httpClient.post({ path, params, token });

  return response;
};

const buyBankSlip = async ({
  token,
  data
}) => {
  const params = {
    "ident": data.name,
    "numberOfAds": data.features[0].value,
    "contractedPeriod": data.features[1].value,
    "numberOfHighlights": data.features[2].value,
    "price": data.price,
    "contractRenewal": "true",
    "customer": {
      "name": data.personName,
      "identity": data.document.replace(/\D/g, ""),
      "identityType": "CPF",
      "email": data.email,
      "birthdate": "1991-01-02",
      "address": {
        "street": data.form.street,
        "number": data.form.number,
        "complement": data.form.additionalInfo,
        "zipCode": data.form.zipCode.replace(/\D/g, ""),
        "city": data.form.city,
        "state": data.form.state,
        "district": data.form.neighborhood
      }
    }
  };
  const path = '/plans/buy/bank-slip'
  const response = await httpClient.post({ path, params, token });

  return response;
};

const getPlan = async ({ token }) => {
  const path = `/plans/user`

  const response = await httpClient.get({ path, token });

  return response;
};

const getPlansForUpgrade = async ({ token }) => {
  const path = '/plans/user/upgrade';

  const response = await httpClient.get({ path, token });

  return response;
}

const upgradePlanRealtor = async ({
  planId,
  paymentInfo,
  creditCardInfo,
  isCompany,
  isRecurrence,
  token,
}) => {
  let path;

  if (isRecurrence) {
    path = `/plans/${planId}/subscribe`;
  } else {
    path = `/plans/${planId}/purchase`;
  }

  const phoneNumberUnmasked = paymentInfo.phoneNumber.replace(/[^a-zA-Z0-9]+/g, '');

  const countryCode = phoneNumberUnmasked.slice(0, 2);
  const areaCode = phoneNumberUnmasked.slice(2, 4);
  const numberPhone = phoneNumberUnmasked.slice(4, phoneNumberUnmasked.length);

  const expirationDate = creditCardInfo.expiryDate;
  const expirationMonth = expirationDate.split('/')[0];
  const expirationYear = expirationDate.split('/')[1].substr(2);

  const params = {
    customer: {
      name: isCompany ? paymentInfo.social : paymentInfo.personName,
      email: paymentInfo.email,
      type: isCompany ? "company" : "individual",
      document: paymentInfo.document.replace(/\D/g, ""),
      document_type: isCompany ? "CNPJ" : "CPF",
      phones: {
        mobile_phone: {
          country_code: countryCode,
          area_code: areaCode,
          number: numberPhone,
        }
      },
    },
    credit_card: {
      number: creditCardInfo.cardNumber.replace(/\./g, ''),
      holder_name: creditCardInfo.ownerName,
      exp_month: expirationMonth,
      exp_year: expirationYear,
      cvv: creditCardInfo.securityCode,
      brand: creditCardInfo.brand,
      billing_address: {
        line_1: `${paymentInfo.form.number}, ${paymentInfo.form.street}, ${paymentInfo.form.neighborhood}`,
        line_2: paymentInfo.form.additionalInfo,
        zip_code: paymentInfo.form.zipCode.replace(/\D/g, ""),
        city: paymentInfo.form.city,
        state: paymentInfo.form.state,
        country: "BR",
      }
    }
  };

  const response = await httpClient.patch({ path, params, token });

  return response;
}

const renewPlan = async ({
  paymentInfo,
  creditCardInfo,
  isCompany,
  token,
}) => {
  const path = '/plans/user/renew';

  const phoneNumberUnmasked = paymentInfo.phoneNumber.replace(/[^a-zA-Z0-9]+/g, '');

  const countryCode = phoneNumberUnmasked.slice(0, 2);
  const areaCode = phoneNumberUnmasked.slice(2, 4);
  const numberPhone = phoneNumberUnmasked.slice(4, phoneNumberUnmasked.length);

  const expirationDate = creditCardInfo.expiryDate;
  const expirationMonth = expirationDate.split('/')[0];
  const expirationYear = expirationDate.split('/')[1].substr(2);

  const params = {
    customer: {
      name: isCompany ? paymentInfo.social : paymentInfo.personName,
      email: paymentInfo.email,
      type: isCompany ? "company" : "individual",
      document: paymentInfo.document.replace(/\D/g, ""),
      document_type: isCompany ? "CNPJ" : "CPF",
      phones: {
        mobile_phone: {
          country_code: countryCode,
          area_code: areaCode,
          number: numberPhone,
        }
      },
    },
    credit_card: {
      number: creditCardInfo.cardNumber.replace(/\./g, ''),
      holder_name: creditCardInfo.ownerName,
      exp_month: expirationMonth,
      exp_year: expirationYear,
      cvv: creditCardInfo.securityCode,
      brand: creditCardInfo.brand,
      billing_address: {
        line_1: `${paymentInfo.form.number}, ${paymentInfo.form.street}, ${paymentInfo.form.neighborhood}`,
        line_2: paymentInfo.form.additionalInfo,
        zip_code: paymentInfo.form.zipCode.replace(/\D/g, ""),
        city: paymentInfo.form.city,
        state: paymentInfo.form.state,
        country: "BR",
      }
    }
  };

  const response = await httpClient.post({ path, params, token });

  return response;
}

const cancelPlan = async ({ token }) => {
  const path = '/plans/user/cancel';

  const response = await httpClient.post({ path, token });

  return response;
}

export default {
  get,
  buy,
  buyBankSlip,
  getProduct,
  getPlan,
  getPlansForUpgrade,
  upgradePlanRealtor,
  renewPlan,
  cancelPlan,
};

