import httpClient from "../../clients/http/http.client";

const BASE_PATH = '/api/v2';

const addEstateProduct = async ({ token, realId }) => {
  const path = `${BASE_PATH}/realestates/${realId}/publication`;

  const result = await httpClient.post({ path, token });

  return result;
};

const removeEstateProduct = async ({ token, realId }) => {
  try {
    const path = `${BASE_PATH}/realestates/${realId}/publication`;
    const result = await httpClient.del({ path, token });

    return result;
  } catch (err) {
    throw new Error(err);
  }
};

export default {
  addEstateProduct,
  removeEstateProduct,
}
