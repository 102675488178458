import React, { useState } from "react";
import copyURLToClipBoard from "../../../utils/social-share/social-share.util";
import styled from "styled-components";
import { mqCustom, mqDesktop, mqTablet } from "../../../styled/helpers/helpers";
import { toast } from "react-toastify";

import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { RiShareCircleLine, RiLink } from "react-icons/ri";
import { SmallButton } from "../../../styled/buttons/buttons";
import { Share } from "styled-icons/material";
import userService from "../../../services/user/user.service";
import userProfileService from "../../../services/user-profile/user-profile.service";

import socialShareUtil from '../../../utils/social-share/social-share.util';

export function ShareButtons() {
  let url = window.location.href;

  if (url.includes("http://localhost:3000")) {
    url = url.replace("http://localhost:3000", "https://dev.koort.com.br");
  }

  const params = (new URL(document.location)).searchParams;
  const idQuery = params.get('id');

  const userLogged = userService.retrieve();

  if (!!userLogged && !idQuery) {
    url = `${url}id=${userLogged._id}`
  }

  const incrementShares = async () => {
    await userProfileService.updateProfileShares({
      id: idQuery,
    });
  };

  const handleCopyURLToClipBoard = async () => {
    await socialShareUtil.copyURLToClipBoard(url, idQuery)

    await incrementShares()
  }

  return (
    <ShareButtonsContainer>
      <DropdownButton
        id={`dropdown-button-drop-down`}
        drop="down"
        title="Compartilhar perfil "
      >
        <Dropdown.Item className="dropDownIconsContainer">
          <FacebookButton
            quote="Venha conhecer esse perfil"
            url={url}
            onClick={incrementShares}
            style={{ backgroundColor: "#3b5998" }}
          >
            <FacebookIcon className="socialIcon" size={35} />
          </FacebookButton>
          <TwitterButton
            title="Venha conhecer esse perfil"
            url={url}
            onClick={incrementShares}
            style={{ backgroundColor: "#00aced" }}
          >
            <TwitterIcon className="socialIcon" size={35} />
          </TwitterButton>
          <WhatsAppButton
            title="Venha conhecer esse perfil"
            separator=": "
            url={url}
            onClick={incrementShares}
            style={{ backgroundColor: "#2cb742" }}
          >
            <WhatsappIcon className="socialIcon" size={35} />
          </WhatsAppButton>
          <ShareIcon
            onClick={handleCopyURLToClipBoard}
          />
        </Dropdown.Item>
      </DropdownButton>
    </ShareButtonsContainer>
  );
}

const ShareButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
  margin-top: 1rem;
  align-self: center;
  height: unset;

  .dropDownIconsContainer {
    display: flex;
    gap: 5px;
    padding-right: 1rem;
  }

  .socialIcon {
    border-radius: 50%;
  }

  ${mqCustom(200)`
    position: unset;
    align-self:center;
    margin-bottom: 40px;
  `}
  ${mqTablet`
    position: unset;
    margin-bottom: 40px;
    flex-direction:row;
    align-self:center;
`}
  ${mqDesktop`
    flex-direction:column;
  `}
`;

const FacebookButton = styled(FacebookShareButton)`
  align-self: flex-start;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #3b5998;
`;

const TwitterButton = styled(TwitterShareButton)`
  align-self: flex-start;
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

const WhatsAppButton = styled(WhatsappShareButton)`
  align-self: flex-start;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #25d366 !important;
`;

export const ShareIcon = styled(SmallButton).attrs({
  as: Share,
})`
  align-self: flex-start;
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;
