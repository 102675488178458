import Base from "../base/base.model";

class RealEstate extends Base {
  constructor({
    id,
    adId,
    listingId,
    realEstateId,
    contact,
    accessData,
    yearBuilt,
    active,
    address,
    area,
    bathrooms,
    bedrooms,
    buildings,
    code,
    constructionStatus,
    description,
    externalId,
    features,
    video,
    floors,
    highlight,
    highlights,
    images,
    isHistory,
    koortimativa,
    listing,
    logo,
    name,
    parkingSpaces,
    petAcceptance,
    price,
    property,
    publication,
    publisher,
    plan,
    ranking,
    reports,
    subtitle,
    suites,
    surroundingPlaces,
    title,
    transaction,
    use,
    favorite,
    incorporation,
    development,
    slug,
    seo,
    launch,
    featuresOriginFormat,
  }) {
    super({ id });
    this.adId = adId;
    this.video = video;
    this.listingId = listingId;
    this.realEstateId = realEstateId;
    this.contact = contact;
    this.accessData = accessData;
    this.active = active;
    this.address = address;
    this.area = area;
    this.bathrooms = bathrooms;
    this.bedrooms = bedrooms;
    this.buildings = buildings;
    this.code = code;
    this.constructionStatus = constructionStatus;
    this.description = description;
    this.externalId = externalId;
    this.features = features;
    this.floors = floors;
    this.highlight = highlight;
    this.highlights = highlights;
    this.images = images;
    this.isHistory = isHistory;
    this.koortimativa = koortimativa;
    this.listing = listing;
    this.logo = logo;
    this.name = name;
    this.parkingSpaces = parkingSpaces;
    this.petAcceptance = petAcceptance;
    this.price = price;
    this.property = property;
    this.publication = publication;
    this.publisher = publisher;
    this.plan = plan;
    this.ranking = ranking;
    this.yearBuilt = yearBuilt;
    this.reports = reports;
    this.subtitle = subtitle;
    this.suites = suites;
    this.surroundingPlaces = surroundingPlaces;
    this.title = title;
    this.transaction = transaction;
    this.use = use;
    this.favorite = favorite;
    this.incorporation = incorporation;
    this.development = development;
    this.slug = slug;
    this.seo = seo;
    this.launch = launch;
    this.featuresOriginFormat = featuresOriginFormat;
  }
}

export default RealEstate;
