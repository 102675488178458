import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';
import propTypes from 'prop-types';
import { transitions } from 'polished';
import { Images } from 'styled-icons/icomoon/Images';
import { RiCompasses2Line, RiSlideshowLine } from 'react-icons/ri';
import { Map } from 'styled-icons/fa-regular/Map';
import { Video } from 'styled-icons/boxicons-regular/Video';
import { StreetView } from 'styled-icons/fa-solid/StreetView';
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from '@material-ui/core/styles';

import { BaseButton } from '../../../../styled/buttons/buttons';
import { COLOR_17, COLOR_2, COLOR_1 } from '../../../../styled/variables/variables';
import { iconBaseStyle, mqDesktop } from '../../../../styled/helpers/helpers';

/*
  global
  process
*/
const { PUBLIC_URL } = process.env;

export const TYPES = {
  IMAGES: 'IMAGES',
  MAP: 'MAP',
  PANORAMA: 'PANORAMA',
  VIDEO: 'VIDEO',
  STREET_VIEW: 'STREET_VIEW',
  FLOOR_PLAN: 'FLOOR_PLAN',
  SLIDES: 'SLIDES',
};

const TOOLTIP_TEXT = {
  IMAGES: 'Imagens do imóvel',
  MAP: 'Localização do imóvel no mapa',
  PANORAMA: 'Imagens 360º do imóvel',
  VIDEO: 'Vídeo do imóvel',
  STREET_VIEW: 'Visualização do imóvel ao nível do solo',
  FLOOR_PLAN: 'Planta baixa do imóvel',
  SLIDES: 'Visualizar book'
}

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: '15px',
    textAlign: 'center',
  },
}));

export default function Tabs(props) {
  const classes = useStylesBootstrap();

  const { active, onClick, hasSlides } = props;
  
   let list = [
      ImagesIcon,
      FloorPlanIcon,
      MapIcon,
      PanoramaIcon,
      // VideoIcon,
      StreetViewIcon,
    ];

    if (props.video) {
      list.push(VideoIcon)
    }

    if (hasSlides) {
      list.push(SlidesIcon);
    }

    return (
      <Wrapper spaceOut={active === TYPES.VIDEO}>
        {list.map(Icon => (
          props.removeTab.includes(Icon.ID) &&
          <Tooltip key={Icon.ID} title={TOOLTIP_TEXT[Icon.ID]} arrow classes={classes}>
            <Button
              key={Icon.ID}
              isActive={Icon.ID === active}
              onClick={() => onClick(Icon.ID)}
            >
              <Icon />
            </Button>
          </Tooltip>
        ))}
      </Wrapper>
    );
  }

Tabs.propTypes = {
  onClick: propTypes.func,
  active: propTypes.oneOf([
    TYPES.IMAGES,
    TYPES.FLOOR_PLAN,
    TYPES.MAP,
    TYPES.PANORAMA,
    TYPES.VIDEO,
    TYPES.STREET_VIEW,
    TYPES.PDF
  ]),
};

Tabs.defaultProps = {

};

const Wrapper = styled.div`
  display: flex;
  margin-top: -25px;
  position: relative;
  z-index: 1;
  justify-content: center;
  min-height: 50px;

  ${transitions(['margin-top'], 'ease .3s')}
  ${({ spaceOut }) => spaceOut && css`
    margin-top: -10px;
  ` }

  ${mqDesktop`
    align-self: center;
    width: 800px;
    justify-content: left;
    ${transitions(['width', 'margin-top'], 'ease .3s')}
    ${({ spaceOut }) => spaceOut && css`
      margin-top: 10px;
      width: 845px;
  ` }
  ` }
`;

const Button = styled(BaseButton)`
  height: 50px;
  width: 50px;
  padding: 0;
  align-self: center;
  margin-right: 3px;
  border-radius: 1px;
  background-color: ${COLOR_17};
  display: flex;
  justify-content: center;

  ${transitions(['background-color'], 'ease .3s')}
  ${({ isActive }) => isActive && css`
    background-color: ${COLOR_1};
  ` }
`;

const ImagesIcon = styled(Images)`
  ${iconBaseStyle}
  color: ${COLOR_2};
  align-self: center;
  height: 18px;
`;
ImagesIcon.ID = TYPES.IMAGES;

const FloorPlanIcon = styled(RiCompasses2Line)`
  ${iconBaseStyle}
  color: ${COLOR_2};
  align-self: center;
  font-size: 32px;
`;
FloorPlanIcon.ID = TYPES.FLOOR_PLAN;

const SlidesIcon = styled(RiSlideshowLine)`
  ${iconBaseStyle}
  color: ${COLOR_2};
  align-self: center;
  font-size: 32px;
`;
SlidesIcon.ID = TYPES.SLIDES;

const MapIcon = styled(ImagesIcon).attrs({
  as: Map,
})``;
MapIcon.ID = TYPES.MAP;

const PanoramaIcon = styled(ImagesIcon).attrs({
  as: 'img',
  src: `${PUBLIC_URL}/imgs/icons/view-360.svg`,
})`
  height: 14px;
`;
PanoramaIcon.ID = TYPES.PANORAMA;

const VideoIcon = styled(ImagesIcon).attrs({
  as: Video,
})`
  height: 24px;
`;
VideoIcon.ID = TYPES.VIDEO;

const StreetViewIcon = styled(ImagesIcon).attrs({
  as: StreetView,
})`
  height: 20px;
`;
StreetViewIcon.ID = TYPES.STREET_VIEW;
