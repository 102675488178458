
import React from 'react';
import styled, { css } from 'styled-components';
import propTypes from 'prop-types';
import { transitions } from 'polished';

import RatingStars from '../../../components/rating-stars';

import { COLOR_18 } from '../../../styled/variables/variables';
import { BaseLabel } from '../../../styled/labels/labels';
import { shadower, mqTablet } from '../../../styled/helpers/helpers';

const RatingDetail = ({
  show,

  negotiation,
  propertyKnowledge,
  service,
  surroundingsKnowledge,
}) => {

  const categories = [
    { title: 'Conhecimento do Imóvel', rating: propertyKnowledge, },
    { title: 'Conhecimento do Local', rating: surroundingsKnowledge, },
    { title: 'Negociação', rating: negotiation, },
    { title: 'Serviço', rating: service, },
  ];

  return (
    <Wrapper show={ show }>
      { categories.map(({ title, rating }, index) => (
        <CategoryWrapper key={ index }>
          <Category>{ title }</Category>
          <RatingWrapper>
            <RDRatingStars rating={ rating } />
            <Rating>
              { rating }
            </Rating>
          </RatingWrapper>
        </CategoryWrapper>
      )) }
    </Wrapper>
  );
};

RatingDetail.propTypes = {
  show: propTypes.bool.isRequired,
};

RatingDetail.defaultProps = {
  show: false,
};

const Wrapper = styled.div`
  background-color: ${ COLOR_18 };
  padding: 30px 20px;
  border-radius: 1px;
  display: flex;
  flex-direction: column;
  position: absolute;
  margin-top: 30px;
  margin-left: 65px;
  overflow: hidden;
  ${ shadower({
    direction: 'all',
    size: 1,
  }) }

  max-height: 310px;
  opacity: 1;
  ${ transitions([
    'opacity',
    'max-height',
    'padding',
  ], 'ease .3s') }
  ${ ({ show }) => !show && css`
    max-height: 0;
    opacity: 0;
    padding: 0 20px;
  ` }
`;

const CategoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  ${ mqTablet`
    flex-direction: row;
    align-self: flex-end;
  ` }
`;

const Category = styled(BaseLabel)`
  font-size: 14px;
  align-self: center;
  margin-bottom: 5px;
  
  ${ mqTablet`
    margin: 0;
    margin-right: 20px;
  ` }
`;

const RatingWrapper = styled.div`
  display: flex;
  align-self: center;
`;

const RDRatingStars = styled(RatingStars)`
  align-self: center;
  margin-right: 10px;
`;

const Rating = styled(BaseLabel)`
  font-weight: 700;
  font-size: 16px;
  align-self: center;
  margin-top: 1px;
`;

export default RatingDetail;
