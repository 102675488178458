import React, { PureComponent } from 'react';
import propTypes from 'prop-types';

import {
  Wrapper,
  SImageSlider,
  GalleryImageDim,
  DimTrashIcon,
  BackButtonIcon,
  NextButtonIcon,
} from './styles';

/* process */

class AdSlider extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { endSlide: false, currentSlide: 0 }

    this.imageSliderRef = React.createRef();
  }

  handleNextButtonClick = (event) => {
    const restart = this.props.images.length - 1;
    let currentSlide = this.state.currentSlide;

    event.stopPropagation();
    if (currentSlide == restart) {
      this.imageSliderRef.current.setSlide(0);
    }
    else {
      this.imageSliderRef.current.next();
    }
  };

  handleBackButtonClick = event => {
    const first = 0;
    const last = this.props.images.length - 1;
    let currentSlide = this.state.currentSlide;


    event.stopPropagation();

    if (currentSlide <= first) {
      this.imageSliderRef.current.setSlide(last);
    }
    else {
      this.imageSliderRef.current.previous();
    }
  };

  handleImageSlideChange = currentSlide => {

    const { onSlideChange } = this.props;
    if (onSlideChange) onSlideChange(currentSlide);
    this.setState({ currentSlide: currentSlide })

  };

  render() {
    const {
      className,
      desktopHeight,
      height,
      images,
      buttonVisible,
      isPanorama,
      playSlideshow,
      tabletHeight,
      searchInfoAd,
      enableLazyload,
      imagesFull
    } = this.props;

    return (
      <Wrapper className={className}>
        <SImageSlider
          images={images.length > 0 ? images : [
            `${process.env.PUBLIC_URL}/imgs/website-preview.png`,
          ]}
          imagesFull={imagesFull}
          ref={this.imageSliderRef}
          playSlideshow={playSlideshow}
          currentSlide={this.props.currentSlide}
          height={300}
          enableLazyload={enableLazyload}
          tabletHeight={tabletHeight}
          desktopHeight={desktopHeight}
          onSlideChange={this.handleImageSlideChange}
          isPanorama={isPanorama}
          searchInfoAd={searchInfoAd}
        />
        {buttonVisible && images.length > 0 &&
          <GalleryImageDim onClick={this.props.removeItem}>
            <DimTrashIcon />
          </GalleryImageDim>
        }
        {
          images.length > 1 &&
          <>
            <BackButtonIcon
              onClick={this.handleBackButtonClick}
              height={height}
              tabletHeight={tabletHeight}
              desktopHeight={desktopHeight}
            />
            <NextButtonIcon
              onClick={this.handleNextButtonClick}
              height={height}
              tabletHeight={tabletHeight}
              desktopHeight={desktopHeight}
            />
          </>
        }

      </Wrapper>
    );
  }
}

AdSlider.propTypes = {
  className: propTypes.string,
  images: propTypes.array,
  reactRef: propTypes.object,
  onMinimizeClick: propTypes.func,
  onCloseClick: propTypes.func,
  playSlideshow: propTypes.bool,
  currentSlide: propTypes.number,
  isPanorama: propTypes.bool,
  buttonVisible: propTypes.bool,
  height: propTypes.string,
  tabletHeight: propTypes.string,
  desktopHeight: propTypes.string,
  onSlideChange: propTypes.func,
  removeItem: propTypes.func,
};

AdSlider.defaultProps = {
  className: '',
  images: [],
  reactRef: null,
  playSlideshow: false,
  concurrentSlides: 1,
  isPanorama: false,

  height: '200px',
  tabletHeight: '250px',
  desktopHeight: '250px',
};

export default AdSlider;
