/* eslint-disable no-unreachable */
import React, { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { scroller, Element } from "react-scroll";

import { formSchema } from "./schema";
import {
  propertyTypesItems,
  propertyUsesItems,
} from "../../../constants/form/propertyItems";
import { stateAcronymsItems } from "../../../constants/form/states-acronyms";

import { ButtonsOptions } from "../../../components/form/buttons-options";
import { SelectBootstrap } from "../../../components/form/select-bootstrap";
import { CepInput } from "../../../components/form/cep-input";
import { InputBootstrap } from "../../../components/form/input-bootstrap";
import { TextArea } from "../../../components/form/textarea";
import { PriceInput } from "../../../components/form/price-input";
import { CellphoneInput } from "../../../components/form/cellphone-input";
import AdditionalInfo from "../../purchase-flow/builder2/additional-info/AdditionalInfo";
import Features from "../../purchase-flow/builder2/features/Features";
import CommonAreaFeatures from "../../purchase-flow/builder2/common-area-features/CommonAreaFeatures";
import SurroundingFeatures from "../../purchase-flow/builder2/surrounding-features/SurroundingFeatures";
import Images from "../../purchase-flow/builder/images/Images";
import Panoramas from "../../purchase-flow/builder/panoramas/Panoramas";
import FloorPlans from "../../purchase-flow/builder/floor-plans/FloorPlans";
import Video from "../../purchase-flow/builder/video/Video";

import httpClient from "../../../clients/http/http.client";
import userService from "../../../services/user/user.service";
import userProfileService from "../../../services/user-profile/user-profile.service";
import realEstateService from "../../../services/real-estate/real-estate.service";
import geolocationService from "../../../services/geolocation/geolocation.service";
import constantsService from "../../../services/constants/constants.service";

import pathsConstants from "../../../constants/paths";
import eventConstants from "../../../constants/events";
import domEventsUtil from "../../../utils/dom-events/dom-events.util";
import queryStringUtil from "../../../utils/query-string/query-string.util";
import { addMaskCellphone, addMaskTelephone } from "../../../utils/add-masks";

import {
  Container,
  LoadDataErrorContainer,
  ReturnDashboardButton,
  Title,
  Form,
  SectionTitle,
  PropertiesSelectsContainer,
  AddressContainer,
  AddressStreetInputContainer,
  TabletInliner,
  AddressNumberInputContainer,
  AddressComplementInputContainer,
  AddressNeighborhoodInputContainer,
  AddressCityInputContainer,
  AddressStateInputContainer,
  AddressCompleteInTheAd,
  CheckboxInputWrapper,
  ShowOnMapInput,
  EnterpriseStatusTitle,
  SelectContainer,
  SelectStep,
  StepBulletContainer,
  StepBulletGap,
  StepBullet,
  SelectTextItem,
  StepLine,
  PropertyFeaturesContainer,
  PropertyFeaturesBedroomsInputContainer,
  PropertyFeaturesBathroomsInputContainer,
  LabelInputPropertyFeature,
  Counter,
  ValuesContainer,
  AdvertiserDataContainer,
  HasWhatsappInput,
  ButtonWrapper,
  SaveButton,
} from "./styles";

// Tipo de usuário:
// 0 - Usuário administrador;
// 1 - Usuário cadastrado;
// 2 - usuário com plano (proprietário);
// 3 - Corretor autônomo;
// 4 - Imobiliárias;
// 5 - Incorporadoras/Construtoras.
const customId = "custom-id-yes";

export function Edit({ user, history }) {
  const [userType, setUserType] = useState(null);
  const [hasErrorLoadProfile, setHasErrorLoadProfile] = useState(false);

  const [adType, setAdType] = useState("Lançamento");
  const [transactionType, setTransactionType] = useState("Venda");
  const [showCompleteAddressInTheAd, setShowCompleteAddressInTheAd] =
    useState(false);
  const [isWhatsapp, setIsWhatsapp] = useState(false);
  const [isSaleAndRental, setIsSaleAndRental] = useState(false);
  const [rentalPrice, setRentalPrice] = useState("");
  const [mainPrice, setMainPrice] = useState("");
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [enterpriseStatus, setEnterpriseStatus] = useState("Pronto para Morar");

  const [counter, setCounter] = useState(0);

  const [imagesHasUploaded, setImagesHasUploaded] = useState(false);

  const [typeOfGuaranteePopulated, setTypeOfGuaranteePopulated] = useState([]);
  const [featuresProperty, setFeaturesProperty] = useState([]);
  const [featuresNear, setFeaturesNear] = useState([]);
  const [featuresCondo, setFeaturesCondo] = useState([]);

  const [imagesAd, setImagesAd] = useState([]);
  const [imagesFloorPlan, setImagesFloorPlan] = useState([]);
  const [imagesTour360, setImagesTour360] = useState([]);
  const [videoPopulated, setVideoPopulated] = useState("");

  const [constantsFeaturesData, setConstantsFeaturesData] = useState({
    CONDO: [],
    NEAR: [],
    PROPERTY: [],
  });

  const imagesAdRef = useRef(null);
  const panoramasAdRef = useRef(null);
  const floorPlansAdRef = useRef(null);
  const videoAdRef = useRef(null);
  const guaranteesAdRef = useRef(null);
  const featuresAdRef = useRef(null);
  const commonAreaFeaturesAdRef = useRef(null);
  const surroundingFeaturesAdRef = useRef(null);

  const {
    register,
    handleSubmit,
    formState,
    control,
    setValue,
    watch,
    clearErrors,
  } = useForm({
    resolver: yupResolver(formSchema),
    context: {
      adTypeIsRelease: adType === "Lançamento",
    },
  });

  const { errors } = formState;

  useEffect(() => {
    async function loadData() {
      try {
        const user = userService.retrieve();

        const data = await userProfileService.getUserBasicProfile({
          userId: user._id,
          token: user.token,
        });

        setUserType(data.userType);

        if (data.userType === 2) {
          setAdType("Usado");
        }

        setValue("name", user.name);
        setValue("email", user.email);

        if (data) {
          if (!!data.profile.professionalInformation.cellphone.number) {
            setValue(
              "cellphone",
              addMaskCellphone(
                data.profile.professionalInformation.cellphone.number
              )
            );
            setIsWhatsapp(
              data.profile.professionalInformation.cellphone.whatsApp
            );

            return;
          }

          if (!!data.profile.professionalInformation.telephone.number) {
            setValue(
              "cellphone",
              addMaskTelephone(
                data.profile.professionalInformation.telephone.number
              )
            );
          }
        }
      } catch {
        toast.error(
          "Houve um erro ao carregar seus dados do perfil, tente novamente!",
          {
            autoClose: false,
            toastId: customId,
          }
        );

        setHasErrorLoadProfile(true);
      }
    }

    loadData();
  }, []);

  useEffect(() => {
    async function loadData() {
      try {
        const { data: responseData, status } = await constantsService.getRealEstatesFeatures();

        if (status !== 200) {
          throw new Error('Não foi possível buscar as características. Recarregue a página!');
        }

        const { data: { CONDO, NEAR, PROPERTY } } = responseData;

        const featuresMapped = {
          CONDO: CONDO.map(item => {
            return {
              ...item,
              label: item.value,
              value: item.key,
            }
          }),
          NEAR: NEAR.map(item => {
            return {
              ...item,
              label: item.value,
              value: item.key,
            }
          }),
          PROPERTY: PROPERTY.map(item => {
            return {
              ...item,
              label: item.value,
              value: item.key,
            }
          }),
        };

        setConstantsFeaturesData(featuresMapped);
      } catch (err) {
        toast.error('Não foi possível buscar as características. Recarregue a página!', {
          autoClose: false,
        });
      }
    }

    loadData();
  }, []);

  useEffect(() => {
    async function loadDataForPopulateForm() {
      try {
        domEventsUtil.dispatch({
          name: eventConstants.LOADING_ANIMATION,
          params: { show: true },
        });

        const queryParams = queryStringUtil.parse(history);
        const { realEstateId } = queryParams;

        const user = userService.retrieve();

        const path = `/realestates/${realEstateId}?complete=true`;
        const token = user.token;

        const { data } = await httpClient.get({ path, token });

        setAdType(data.ads.types.listing);
        setTransactionType(
          data.ads.types.transaction === "Venda|Locação"
            ? "Venda"
            : data.ads.types.transaction
        );

        setValue("propertyUse", data.realEstate.types.use);
        setValue("propertyType", data.realEstate.types.property);

        setValue("zipCode", data.realEstate.address.zipCode);
        setValue("street", data.realEstate.address.street);
        setValue("number", data.realEstate.address.streetNumber);
        setValue("complement", data.realEstate.address.complement);
        setValue("neighborhood", data.realEstate.address.neighborhood);
        setValue("city", data.realEstate.address.city);
        setValue("state", data.realEstate.address.state);
        setShowCompleteAddressInTheAd(data.ads.showInfo.address);

        if (data.ads.types.listing === "Lançamento") {
          setValue("bedroomsFrom", data.realEstate.bedrooms[0]);
          setValue("bedroomsTo", data.realEstate.bedrooms[1]);

          setValue("bathroomsFrom", data.realEstate.bathrooms[0]);
          setValue("bathroomsTo", data.realEstate.bathrooms[1]);

          setValue("suitesFrom", data.realEstate.suites[0]);
          setValue("suitesTo", data.realEstate.suites[1]);

          setValue("parkingSpacesFrom", data.realEstate.parkingSpaces[0]);
          setValue("parkingSpacesTo", data.realEstate.parkingSpaces[1]);

          setValue("areaFrom", data.realEstate.area[0]);
          setValue("areaTo", data.realEstate.area[1]);

          setValue("totalAreaFrom", data.realEstate.totalArea[0]);
          setValue("totalAreaTo", data.realEstate.totalArea[1]);

          if (data.ads.types.transaction === 'Venda') {
            setValue("enterpriseName", data.ads.development.name ?? "");
            setEnterpriseStatus(data.ads.development.constructionStatus);
            setValue("towerNumber", data.ads.development.buildings ?? "");
            setValue("floorsNumber", data.ads.development.floor ?? "");
            setValue("apartmentsPerFloor", data.ads.development.apartmentsPerFloor ?? "");
          }
        } else {
          setValue("bedrooms", data.realEstate.bedrooms[0]);
          setValue("bathrooms", data.realEstate.bathrooms[0]);
          setValue("suites", data.realEstate.suites[0]);
          setValue("parkingSpaces", data.realEstate.parkingSpaces[0]);
          setValue("area", data.realEstate.area[0]);
          setValue("totalArea", data.realEstate.totalArea[0]);
          setValue("saleWithRental", data.ads.prices.main);
          setValue("rentalWithSale", data.ads.prices.rental);
          setIsSaleAndRental(data.ads.types.transaction === "Venda|Locação");
          setValue("rental", data.ads.prices.rental);
        }

        // setValue("title", data.ads.title || "");
        setValue("description", data.ads.description || "");

        if (data.ads.prices.main !== 0) {
          setValue("price", String(data.ads.prices.main));
        }

        if (data.ads.prices.condo !== 0) {
          setValue("condo", String(data.ads.prices.condo));
        }

        if (data.ads.prices.iptu !== 0) {
          setValue("iptu", String(data.ads.prices.iptu));
        }

        setTypeOfGuaranteePopulated(data.ads.typeOfGuarantee);

        setFeaturesProperty(data.realEstate.features.property);
        setFeaturesNear(data.realEstate.features.near);
        setFeaturesCondo(data.realEstate.features.condo);

        setImagesAd(data.ads.images.ad);
        setImagesFloorPlan(data.ads.images.floor_plan);
        setImagesTour360(data.ads.images.tour360);

        setVideoPopulated(data.ads.video);
      } catch {
        setHasErrorLoadProfile(true);
      } finally {
        domEventsUtil.dispatch({
          name: eventConstants.LOADING_ANIMATION,
          params: { show: false },
        });
      }
    }

    loadDataForPopulateForm();
  }, [history]);

  function toggleTransactionType() { }

  useEffect(() => {
    const subscription = watch((data) => setCounter(data.description.length));

    return () => subscription.unsubscribe();
  }, [watch]);

  async function getAddressByCep(zipCode) {
    let geolocation;

    let result;

    domEventsUtil.dispatch({
      name: eventConstants.LOADING_ANIMATION,
      params: { show: true },
    });

    try {
      result = await geolocationService.getAddressByZipCode({
        zipCode,
      });
    } catch (error) {
      // Do nothing.
    }

    if (result) {
      try {
        geolocation = await geolocationService.getGeolocationByAddress({
          zipCode,
          street: result.street,
          state: result.state,
          country: "Brazil",
        });

        setLatitude(geolocation.latitude);
        setLongitude(geolocation.longitude);

        if (!!result.neighborhood) {
          setValue("neighborhood", result.neighborhood);
          clearErrors("neighborhood");
        }

        setValue("street", result.street);
        setValue("city", result.city);
        setValue("state", result.state);
        clearErrors("street");
        clearErrors("city");
        clearErrors("state");
      } catch (err) {
        toast.error("Erro verificando geolocalização do endereço.", {
          autoClose: false,
          toastId: customId,
        });
      } finally {
        domEventsUtil.dispatch({
          name: eventConstants.LOADING_ANIMATION,
          params: { show: false },
        });
      }
    } else {
      domEventsUtil.dispatch({
        name: eventConstants.LOADING_ANIMATION,
        params: { show: false },
      });
    }
  }

  function handleZipCodeInputBlur(event) {
    if (!!event.target.value) {
      if (event.target.value.length === 9) {
        getAddressByCep(event.target.value);
      }
    }
  }

  function handleSelectAdType(type) {
    if (type === "Lançamento") {
      setAdType("Lançamento");
      setTransactionType("Venda");

      setValue("bedrooms", "");
      setValue("bathrooms", "");
      setValue("suites", "");
      setValue("parkingSpaces", "");
      setValue("area", "");
      setValue("totalArea", "");
    }

    if (type === "Usado") {
      setAdType("Usado");

      setValue("bedroomsFrom", "");
      setValue("bedroomsTo", "");
      setValue("bathroomsFrom", "");
      setValue("bathroomsTo", "");
      setValue("suitesFrom", "");
      setValue("suitesTo", "");
      setValue("parkingSpacesFrom", "");
      setValue("parkingSpacesTo", "");
      setValue("areaFrom", "");
      setValue("areaTo", "");
      setValue("totalAreaFrom", "");
      setValue("totalAreaTo", "");
    }
  }

  async function handleFormSubmit(values) {
    function handlePrices() {
      let main = values.price === undefined ? 0 : formatPrice(values.price);
      let rental = "";

      function formatPrice(price) {
        return (
          price
            .toString()
            .replace(/[R$\.\,]/g, "")
            .trim() ?? ""
        );
      }

      if (isSaleAndRental) {
        if (transactionType === "Venda") {
          main = formatPrice(values.price);
          rental = formatPrice(values.rentalWithSale);
        }

        if (transactionType === "Locação") {
          main = formatPrice(values.price);
        }
      }

      if (values.price === undefined) {
        return {
          main: 0,
          rental: formatPrice(rental),
        }
      }

      return {
        main: formatPrice(main),
        rental: formatPrice(rental),
      };
    }

    function handleTransactionType() {
      if (transactionType === "Locação") {
        setIsSaleAndRental(false);
        return "Locação";
      }

      return isSaleAndRental ? `${transactionType}|${transactionType === 'Venda' ? 'Locação' : 'Venda'}` : transactionType;
    }

    let formData = {
      ...values,
      ...imagesAdRef.current.state.form,
      ...panoramasAdRef.current.state.form,
      ...videoAdRef.current.state.form,
      use: values.propertyUse,
      type: values.propertyType,
      guarantees: [],
      additionalInfo: values.complement,
      latitude,
      longitude,
      features: {
        property: featuresAdRef.current.state.form.features,
        condo: commonAreaFeaturesAdRef.current.state.form.features,
        near: surroundingFeaturesAdRef.current.state.form.features,
      },
      bedrooms: values.bedroomsFrom
        ? [values.bedroomsFrom, values.bedroomsTo]
        : [values.bedrooms],
      bathrooms: values.bathroomsFrom
        ? [values.bathroomsFrom, values.bathroomsTo]
        : [values.bathrooms],
      suites: values.suitesFrom
        ? [values.suitesFrom, values.suitesTo]
        : [values.suites],
      parkingSpaces: values.parkingSpacesFrom
        ? [values.parkingSpacesFrom, values.parkingSpacesTo]
        : [values.parkingSpaces],
      area: values.areaFrom ? [values.areaFrom, values.areaTo] : [values.area],
      totalArea: values.totalAreaFrom
        ? [values.totalAreaFrom, values.totalAreaTo]
        : [values.totalArea],
      listing: userType !== 2 ? adType : "Usado",
      transaction: handleTransactionType(),
      development: {
        name:
          adType === "Lançamento" &&
            transactionType === "Venda" &&
            userType !== 2
            ? values.enterpriseName
            : "",
        constructionStatus:
          adType === "Lançamento" &&
            transactionType === "Venda" &&
            userType !== 2
            ? enterpriseStatus
            : "Pronto para Morar",
        buildings:
          adType === "Lançamento" &&
            transactionType === "Venda" &&
            userType !== 2
            ? values.towerNumber
            : "",
        floors:
          adType === "Lançamento" &&
            transactionType === "Venda" &&
            userType !== 2
            ? values.floorsNumber
            : 0,
        apartmentsPerFloor:
          adType === "Lançamento" &&
            transactionType === "Venda" &&
            userType !== 2
            ? values.apartmentsPerFloor
            : 0,
      },
      price: handlePrices().main,
      rental: handlePrices().rental,
      condo: values.condo ? values.condo.replace(/[R$\.\,]/g, "").trim() : "",
      iptu: values.iptu ? values.iptu.replace(/[R$\.\,]/g, "").trim() : "",
      showOnMap: showCompleteAddressInTheAd,
      phoneNumber: values.cellphone
        ? values.cellphone.replace(/[^a-zA-Z0-9]+/g, "").substring(2)
        : "",
      whatsApp: isWhatsapp,
    };

    if (adType === "Lançamento") {
      formData = {
        ...formData,
        ...floorPlansAdRef.current.state.form,
      }
    }

    if (adType !== "Lançamento" || transactionType !== "Venda") {
      delete formData.development.name;
      delete formData.development.buildings;
    }

    if (transactionType === "Locação") {
      formData = {
        ...formData,
        guarantees: guaranteesAdRef.current.state.guarantees,
      };
    }

    const adImagesRemoteRemoved = formData.adImagesRemoteRemoved.map(
      (item) => item.dataUrl
    );
    const panoramaImagesRemoteRemoved =
      formData.panoramaImagesRemoteRemoved.map((item) => item.dataUrl);

    let floorPlanImagesRemoteRemoved = [];

    if (adType === "Lançamento") {
      floorPlanImagesRemoteRemoved = formData.floorPlanImagesRemoteRemoved.map((item) => item.dataUrl);
    }

    const concatenatedImagesRemoteRemoved = adImagesRemoteRemoved
      .concat(panoramaImagesRemoteRemoved)
      .concat(floorPlanImagesRemoteRemoved);

    const hasComplete = imagesAd.length >= 3;

    const hasIncomplete = imagesAd.length === 0;

    if (hasComplete) {
      if (
        imagesAd.length +
        formData.images.length -
        concatenatedImagesRemoteRemoved.length <
        3
      ) {
        toast.error(
          "Você deve inserir pelos menos 3 imagens para finalizar a criação de seu anúncio",
          {
            autoClose: false,
            toastId: customId,
          }
        );

        scroller.scrollTo("images-ad-component", {
          duration: 400,
          offset: -100,
        });

        return;
      }
    }

    if (hasIncomplete) {
      if (formData.images.length < 3) {
        toast.error(
          "Você deve inserir pelos menos 3 imagens para finalizar a criação de seu anúncio",
          {
            autoClose: false,
            toastId: customId,
          }
        );

        scroller.scrollTo("images-ad-component", {
          duration: 400,
          offset: -100,
        });

        return;
      }
    }

    domEventsUtil.dispatch({
      name: eventConstants.LOADING_ANIMATION,
      params: { show: true },
    });

    const queryParams = queryStringUtil.parse(history);

    if (concatenatedImagesRemoteRemoved.length > 0) {
      try {
        await realEstateService.removeImageOfAdRealEstate({
          realEstateId: queryParams.realEstateId,
          data: concatenatedImagesRemoteRemoved,
          user,
        });
      } catch {
        toast.error("Houve um erro ao remover suas imagens, tente novamente!", {
          autoClose: false,
          toastId: customId,
        });

        return;
      } finally {
        domEventsUtil.dispatch({
          name: eventConstants.LOADING_ANIMATION,
          params: { show: false },
        });
      }
    }

    domEventsUtil.dispatch({
      name: eventConstants.LOADING_ANIMATION,
      params: { show: true },
    });

    try {
      if (!user || !user.token) {
        history.push(
          `${pathsConstants.AUTHENTICATION}/${pathsConstants.AUTHENTICATION_PARAMS.LOGIN}`
        );
      } else {
        if (!imagesHasUploaded) {
          await realEstateService.uploadImages({
            pictureBlobs: formData.images.map(({ blob }) => blob),
            floorPlanBlobs: adType === "Lançamento" ? formData.floorPlans.map(({ blob }) => blob) : [],
            panoramaBlobs: formData.panoramas.map(({ blob }) => blob),
            realEstateId: queryParams.realEstateId,
            token: user.token,
          });

          setImagesHasUploaded(true);
        }
      }
    } catch {
      toast.error("Ocorreu um erro ao anexar suas imagens!", {
        autoClose: false,
        toastId: customId,
      });
    } finally {
      domEventsUtil.dispatch({
        name: eventConstants.LOADING_ANIMATION,
        params: { show: false },
      });
    }

    try {
      domEventsUtil.dispatch({
        name: eventConstants.LOADING_ANIMATION,
        params: { show: true },
      });

      if (!user || !user.token) {
        history.push(
          `${pathsConstants.AUTHENTICATION}/${pathsConstants.AUTHENTICATION_PARAMS.LOGIN}`
        );
      } else {
        await realEstateService.updateRealEstate({
          realEstateId: queryParams.realEstateId,
          data: formData,
          user,
        });

        toast.info("Imóvel atualizado com sucesso!", {
          autoClose: false,
          toastId: customId,
        });

        history.push({
          pathname: pathsConstants.DASHBOARD,
          search: queryStringUtil.stringify(history, {}),
        });
      }
    } catch {
      // domEventsUtil.dispatch({
      //   name: eventConstants.LOADING_ANIMATION,
      //   params: { show: true, },
      // });

      toast.error("Houve um erro ao atualizar o seu imóvel, tente novamente!", {
        autoClose: false,
        toastId: customId,
      });
    } finally {
      domEventsUtil.dispatch({
        name: eventConstants.LOADING_ANIMATION,
        params: { show: false },
      });

      domEventsUtil.dispatch({
        name: eventConstants.LOADING_ANIMATION,
        params: { show: false },
      });
    }
  }

  function onInvalidForm(fieldValues) {
    if (!!fieldValues.price && fieldValues.price.type === "required") {
      scroller.scrollTo("price-input", {
        duration: 400,
        offset: -140,
      });
    }
  }

  return (
    <Container>
      {hasErrorLoadProfile ? (
        <LoadDataErrorContainer>
          <h2>Ops...</h2>

          <p>
            Por algum motivo não foi possível carregar as informações
            necessárias para o preenchimento do formulário. Por favor,
            recarregue a página ou retorne à dashboard.
          </p>

          <ReturnDashboardButton onClick={history.goBack}>
            Retornar à dashboard
          </ReturnDashboardButton>
        </LoadDataErrorContainer>
      ) : (
        <>
          <Form onSubmit={handleSubmit(handleFormSubmit, onInvalidForm)}>
            {userType !== null && userType !== 2 && (
              <>
                <SectionTitle>Selecione o tipo de anúncio</SectionTitle>

                <ButtonsOptions
                  option1="Lançamento"
                  option2="Usado"
                  option1Active={adType === "Lançamento"}
                  option2Active={adType === "Usado"}
                  option1OnClick={() => handleSelectAdType("Lançamento")}
                  option2OnClick={() => handleSelectAdType("Usado")}
                />
              </>
            )}

            {adType !== "Lançamento" && (
              <>
                <SectionTitle>Selecione a transação</SectionTitle>
                <ButtonsOptions
                  option1="Venda"
                  option2="Locação"
                  option1Active={transactionType === "Venda"}
                  option2Active={transactionType === "Locação"}
                  option1OnClick={() => setTransactionType("Venda")}
                  option2OnClick={() => setTransactionType("Locação")}
                />
              </>
            )}

            <SectionTitle>Tipo de imóvel que deseja anunciar</SectionTitle>

            <PropertiesSelectsContainer>
              <SelectBootstrap
                name="propertyUse"
                label="Uso"
                options={propertyUsesItems}
                error={errors.propertyUse}
                {...register("propertyUse")}
              />

              <SelectBootstrap
                name="propertyType"
                label="Tipo"
                options={propertyTypesItems}
                error={errors.propertyType}
                {...register("propertyType")}
              />
            </PropertiesSelectsContainer>

            <AddressContainer>
              <SectionTitle>Localização do seu imóvel</SectionTitle>

              <CepInput
                name="zipCode"
                label="Cep"
                error={errors.zipCode}
                control={control}
                // onBlurInput={handleZipCodeInputBlur}
                {...register("zipCode", {
                  onBlur: handleZipCodeInputBlur,
                })}
              />
              <p style={{ marginTop: 5, fontSize: 12 }}>
                Não sabe seu CEP? Clique{" "}
                <a
                  target="_blank"
                  href="http://www.buscacep.correios.com.br/sistemas/buscacep/buscaCep.cfm"
                >
                  aqui
                </a>
              </p>

              <AddressStreetInputContainer className="mb-3">
                <InputBootstrap
                  name="street"
                  label="Endereço"
                  placeholder="Endereço"
                  error={errors.street}
                  {...register("street")}
                />
              </AddressStreetInputContainer>

              <TabletInliner>
                <AddressNumberInputContainer>
                  <InputBootstrap
                    name="number"
                    label="Número"
                    placeholder="Número"
                    type="number"
                    error={errors.number}
                    {...register("number")}
                  />
                </AddressNumberInputContainer>

                <AddressComplementInputContainer>
                  <InputBootstrap
                    name="complement"
                    label="Complemento"
                    placeholder="Complemento"
                    error={errors.complement}
                    {...register("complement")}
                  />
                </AddressComplementInputContainer>
              </TabletInliner>

              <AddressNeighborhoodInputContainer className="mt-3">
                <InputBootstrap
                  name="neighborhood"
                  label="Bairro"
                  placeholder="Bairro"
                  error={errors.neighborhood}
                  {...register("neighborhood")}
                />
              </AddressNeighborhoodInputContainer>

              <TabletInliner className="mt-3">
                <AddressCityInputContainer>
                  <InputBootstrap
                    name="city"
                    label="Cidade"
                    placeholder="Cidade"
                    error={errors.city}
                    {...register("city")}
                  />
                </AddressCityInputContainer>

                <AddressStateInputContainer>
                  <SelectBootstrap
                    name="state"
                    label="Estado"
                    options={stateAcronymsItems}
                    error={errors.state}
                    {...register("state")}
                  />
                </AddressStateInputContainer>
              </TabletInliner>

              <AddressCompleteInTheAd>
                {showCompleteAddressInTheAd ? (
                  <>
                    As informações de localização do seu imóvel serão exibidas
                    no corpo do anúncio
                  </>
                ) : (
                  <>
                    Nosso sistema de busca utiliza como ferramenta principal o
                    mapa e o endereço completo será utilizado apenas para
                    localizar geograficamente o seu imóvel. Se você quiser
                    mostrar o endereço no anúncio, clique abaixo.
                  </>
                )}
              </AddressCompleteInTheAd>

              <CheckboxInputWrapper
                label="Mostrar o endereço completo no anúncio"
                htmlFor="showOnMap"
              >
                <ShowOnMapInput
                  id="showOnMap"
                  name="showOnMap"
                  checked={showCompleteAddressInTheAd}
                  onClick={() =>
                    setShowCompleteAddressInTheAd((prevState) => !prevState)
                  }
                />
              </CheckboxInputWrapper>
            </AddressContainer>

            {adType === "Lançamento" &&
              transactionType === "Venda" &&
              userType !== 2 && (
                <>
                  <SectionTitle>Empreendimento</SectionTitle>
                  <InputBootstrap
                    name="enterpriseName"
                    label="Nome do empreendimento"
                    placeholder="Nome do empreendimento"
                    error={errors.enterpriseName}
                    {...register("enterpriseName")}
                  />

                  <EnterpriseStatusTitle>
                    Status do empreendimento
                  </EnterpriseStatusTitle>
                  <SelectContainer>
                    <SelectStep
                      type="button"
                      onClick={() => setEnterpriseStatus("Na Planta")}
                    >
                      <StepBulletContainer
                        active={enterpriseStatus === "Na Planta"}
                      >
                        <StepBulletGap>
                          <StepBullet
                            active={enterpriseStatus === "Na Planta"}
                          />
                        </StepBulletGap>
                      </StepBulletContainer>
                      <SelectTextItem active={enterpriseStatus === "Na Planta"}>
                        Na planta
                      </SelectTextItem>
                    </SelectStep>

                    <SelectStep
                      type="button"
                      onClick={() => setEnterpriseStatus("Em Construção")}
                    >
                      <StepBulletContainer
                        active={enterpriseStatus === "Em Construção"}
                      >
                        <StepBulletGap>
                          <StepBullet
                            active={enterpriseStatus === "Em Construção"}
                          />
                        </StepBulletGap>
                      </StepBulletContainer>
                      <SelectTextItem
                        active={enterpriseStatus === "Em Construção"}
                      >
                        Em construção
                      </SelectTextItem>
                    </SelectStep>

                    <SelectStep
                      type="button"
                      onClick={() => setEnterpriseStatus("Pronto para Morar")}
                    >
                      <StepBulletContainer
                        active={enterpriseStatus === "Pronto para Morar"}
                      >
                        <StepBulletGap>
                          <StepBullet
                            active={enterpriseStatus === "Pronto para Morar"}
                          />
                        </StepBulletGap>
                      </StepBulletContainer>
                      <SelectTextItem
                        active={enterpriseStatus === "Pronto para Morar"}
                      >
                        Pronto para morar
                      </SelectTextItem>
                    </SelectStep>
                    <StepLine />
                  </SelectContainer>

                  <InputBootstrap
                    name="towerNumber"
                    label="Número de torres"
                    placeholder="Número de torres"
                    type="number"
                    error={errors.towerNumber}
                    {...register("towerNumber")}
                  />

                  <TabletInliner className="mt-3">
                    <AddressNumberInputContainer>
                      <InputBootstrap
                        name="floorsNumber"
                        label="Número de andares"
                        placeholder="Número de andares"
                        type="number"
                        error={errors.floorsNumber}
                        {...register("floorsNumber")}
                      />
                    </AddressNumberInputContainer>

                    <InputBootstrap
                      name="apartmentsPerFloor"
                      label="Apartamentos por andar"
                      placeholder="Apartamentos por andar"
                      type="number"
                      error={errors.apartmentsPerFloor}
                      {...register("apartmentsPerFloor")}
                    />
                  </TabletInliner>
                </>
              )}

            <PropertyFeaturesContainer>
              <SectionTitle>Características do imóvel</SectionTitle>

              {adType === "Lançamento" ? (
                <>
                  <LabelInputPropertyFeature>
                    N° de Quartos
                  </LabelInputPropertyFeature>
                  <TabletInliner>
                    <PropertyFeaturesBedroomsInputContainer>
                      <InputBootstrap
                        name="bedroomsFrom"
                        label="De"
                        placeholder="De"
                        error={errors.bedroomsFrom}
                        {...register("bedroomsFrom")}
                      />
                    </PropertyFeaturesBedroomsInputContainer>

                    <PropertyFeaturesBathroomsInputContainer>
                      <InputBootstrap
                        name="bedroomsTo"
                        label="Até"
                        placeholder="Até"
                        error={errors.bedroomsTo}
                        {...register("bedroomsTo")}
                      />
                    </PropertyFeaturesBathroomsInputContainer>
                  </TabletInliner>

                  <LabelInputPropertyFeature>
                    N° de Banheiros
                  </LabelInputPropertyFeature>
                  <TabletInliner>
                    <PropertyFeaturesBedroomsInputContainer>
                      <InputBootstrap
                        name="bathroomsFrom"
                        label="De"
                        placeholder="De"
                        error={errors.bathroomsFrom}
                        {...register("bathroomsFrom")}
                      />
                    </PropertyFeaturesBedroomsInputContainer>

                    <PropertyFeaturesBathroomsInputContainer>
                      <InputBootstrap
                        name="bathroomsTo"
                        label="Até"
                        placeholder="Até"
                        error={errors.bathroomsTo}
                        {...register("bathroomsTo")}
                      />
                    </PropertyFeaturesBathroomsInputContainer>
                  </TabletInliner>

                  <LabelInputPropertyFeature>
                    N° de Suítes
                  </LabelInputPropertyFeature>
                  <TabletInliner>
                    <PropertyFeaturesBedroomsInputContainer>
                      <InputBootstrap
                        name="suitesFrom"
                        label="De"
                        placeholder="De"
                        error={errors.suitesFrom}
                        {...register("suitesFrom")}
                      />
                    </PropertyFeaturesBedroomsInputContainer>

                    <PropertyFeaturesBathroomsInputContainer>
                      <InputBootstrap
                        name="suitesTo"
                        label="Até"
                        placeholder="Até"
                        error={errors.suitesTo}
                        {...register("suitesTo")}
                      />
                    </PropertyFeaturesBathroomsInputContainer>
                  </TabletInliner>

                  <LabelInputPropertyFeature>
                    N° de Vagas
                  </LabelInputPropertyFeature>
                  <TabletInliner>
                    <PropertyFeaturesBedroomsInputContainer>
                      <InputBootstrap
                        name="parkingSpacesFrom"
                        label="De"
                        placeholder="De"
                        error={errors.parkingSpacesFrom}
                        {...register("parkingSpacesFrom")}
                      />
                    </PropertyFeaturesBedroomsInputContainer>

                    <PropertyFeaturesBathroomsInputContainer>
                      <InputBootstrap
                        name="parkingSpacesTo"
                        label="Até"
                        placeholder="Até"
                        error={errors.parkingSpacesTo}
                        {...register("parkingSpacesTo")}
                      />
                    </PropertyFeaturesBathroomsInputContainer>
                  </TabletInliner>

                  <LabelInputPropertyFeature>
                    Área Útil (m²)
                  </LabelInputPropertyFeature>
                  <TabletInliner>
                    <PropertyFeaturesBedroomsInputContainer>
                      <InputBootstrap
                        name="areaFrom"
                        label="De"
                        placeholder="De"
                        error={errors.areaFrom}
                        {...register("areaFrom")}
                      />
                    </PropertyFeaturesBedroomsInputContainer>

                    <PropertyFeaturesBathroomsInputContainer>
                      <InputBootstrap
                        name="areaTo"
                        label="Até"
                        placeholder="Até"
                        error={errors.areaTo}
                        {...register("areaTo")}
                      />
                    </PropertyFeaturesBathroomsInputContainer>
                  </TabletInliner>

                  <LabelInputPropertyFeature>
                    Área Total (m²)
                  </LabelInputPropertyFeature>
                  <TabletInliner>
                    <PropertyFeaturesBedroomsInputContainer>
                      <InputBootstrap
                        name="totalAreaFrom"
                        label="De"
                        placeholder="De"
                        error={errors.totalAreaFrom}
                        {...register("totalAreaFrom")}
                      />
                    </PropertyFeaturesBedroomsInputContainer>

                    <PropertyFeaturesBathroomsInputContainer>
                      <InputBootstrap
                        name="totalAreaTo"
                        label="Até"
                        placeholder="Até"
                        error={errors.totalAreaTo}
                        {...register("totalAreaTo")}
                      />
                    </PropertyFeaturesBathroomsInputContainer>
                  </TabletInliner>
                </>
              ) : (
                <>
                  <TabletInliner>
                    <PropertyFeaturesBedroomsInputContainer>
                      <InputBootstrap
                        name="bedrooms"
                        label="Nº Quartos"
                        placeholder="Nº Quartos"
                        error={errors.bedrooms}
                        {...register("bedrooms")}
                      />
                    </PropertyFeaturesBedroomsInputContainer>

                    <PropertyFeaturesBathroomsInputContainer>
                      <InputBootstrap
                        name="bathrooms"
                        label="Nº Banheiros"
                        placeholder="Nº Banheiros"
                        error={errors.bathrooms}
                        {...register("bathrooms")}
                      />
                    </PropertyFeaturesBathroomsInputContainer>
                  </TabletInliner>
                  <TabletInliner>
                    <PropertyFeaturesBedroomsInputContainer>
                      <InputBootstrap
                        name="suites"
                        label="Nº Suítes"
                        placeholder="Nº Suítes"
                        error={errors.suites}
                        {...register("suites")}
                      />
                    </PropertyFeaturesBedroomsInputContainer>

                    <PropertyFeaturesBathroomsInputContainer>
                      <InputBootstrap
                        name="parkingSpaces"
                        label="Nº Vagas"
                        placeholder="Nº Vagas"
                        error={errors.parkingSpaces}
                        {...register("parkingSpaces")}
                      />
                    </PropertyFeaturesBathroomsInputContainer>
                  </TabletInliner>

                  <TabletInliner>
                    <PropertyFeaturesBedroomsInputContainer>
                      <InputBootstrap
                        name="area"
                        label="Área Útil (m²)"
                        placeholder="Área Útil (m²)"
                        error={errors.area}
                        {...register("area")}
                      />
                    </PropertyFeaturesBedroomsInputContainer>

                    <PropertyFeaturesBathroomsInputContainer>
                      <InputBootstrap
                        name="totalArea"
                        label="Área Total (m²)"
                        placeholder="Área Total (m²)"
                        error={errors.totalArea}
                        {...register("totalArea")}
                      />
                    </PropertyFeaturesBathroomsInputContainer>
                  </TabletInliner>
                </>
              )}
            </PropertyFeaturesContainer>

            <SectionTitle>Informações do anúncio</SectionTitle>

            {/* <InputBootstrap
              name="title"
              label="Título do anúncio"
              placeholder="Título do anúncio"
              error={errors.title}
              {...register("title")}
            /> */}

            <TextArea
              name="description"
              label="Descrição do imóvel"
              height={190}
              error={errors.description}
              {...register("description")}
            />

            <Counter>{counter} caracteres</Counter>

            <ValuesContainer>
              <SectionTitle>Valores</SectionTitle>

              {/* {isSaleAndRental === true ? (
                <PriceInput
                  name={transactionType === 'Venda' ? 'price' : 'rental'}
                  label={transactionType === 'Venda' ? 'Valor de venda (R$)' :
                    'Valor do aluguel (R$)'}
                  error={transactionType === 'Venda' ? errors.rental : errors.price}
                  control={control}
                  {...register(transactionType === 'Venda' ? 'price' : 'rental')}
                />
              )

                :

                (
                  <PriceInput
                    name="price"
                    label={transactionType === 'Venda' ? 'Valor de venda (R$)' :
                      'Valor do aluguel (R$)'}
                    error={errors.price}
                    control={control}
                    {...register('price')}
                  />
                )
              } */}

              {/* <PriceInput
                name={transactionType === 'Venda' ? 'price' : 'rental'}
                label={transactionType === 'Venda' ? 'Valor de venda (R$)' :
                  'Valor do aluguel (R$)'}
                error={transactionType === 'Venda' ? errors.price : errors.rental}
                control={control}
                {...register(transactionType === 'Venda' ? 'price' : 'rental')}
              /> */}

              <Element id="price-input">
                <PriceInput
                  name="price"
                  label={
                    transactionType === "Venda"
                      ? "Valor de venda (R$)"
                      : "Valor do aluguel (R$)"
                  }
                  error={errors.price}
                  control={control}
                  {...register("price")}
                />
              </Element>

              {adType === "Usado" && transactionType === "Venda" && (
                <>
                  <CheckboxInputWrapper
                    label={`Desejo que o imóvel também esteja disponível para locação.`}
                    htmlFor="SaleAndRental"
                  >
                    <HasWhatsappInput
                      id="SaleAndRental"
                      name="SaleAndRental"
                      checked={isSaleAndRental}
                      onClick={() =>
                        setIsSaleAndRental((prevState) => !prevState)
                      }
                    />
                  </CheckboxInputWrapper>
                  {isSaleAndRental && (
                    <PriceInput
                      name="rentalWithSale"
                      label="Valor do aluguel (R$)"
                      error={errors.rental}
                      control={control}
                      {...register("rentalWithSale")}
                    />
                  )}
                </>
              )}

              <PriceInput
                name="condo"
                label="Condomínio (R$)"
                error={errors.condo}
                control={control}
                {...register("condo")}
              />

              <PriceInput
                name="iptu"
                label="IPTU (R$ MENSAL)"
                error={errors.iptu}
                control={control}
                {...register("iptu")}
              />
            </ValuesContainer>

            {transactionType === "Locação" && (
              <AdditionalInfo
                ref={guaranteesAdRef}
                showInvalids={false}
                onSelectItems={() => { }}
                guaranteesIsRequired={false}
                showTypeOfGuarantees={transactionType === "Locação"}
                isEditing
                populate={typeOfGuaranteePopulated}
              />
            )}

            <SectionTitle>Detalhamento do imóvel</SectionTitle>
            <Features
              ref={featuresAdRef}
              features={[]}
              isEditing
              populate={featuresProperty}
              data={constantsFeaturesData.PROPERTY}
            />

            <SectionTitle>Áreas comuns</SectionTitle>
            <CommonAreaFeatures
              ref={commonAreaFeaturesAdRef}
              isEditing
              populate={featuresCondo}
              data={constantsFeaturesData.CONDO}
            />

            <SectionTitle>Entorno</SectionTitle>
            <SurroundingFeatures
              ref={surroundingFeaturesAdRef}
              isEditing
              populate={featuresNear}
              data={constantsFeaturesData.NEAR}
            />

            <Element id="images-ad-component">
              <SectionTitle>Fotos do anúncio</SectionTitle>
              <Images ref={imagesAdRef} isEditing populateImages={imagesAd} />
            </Element>

            <SectionTitle>Panorama</SectionTitle>
            <Panoramas
              ref={panoramasAdRef}
              isEditing
              populateImages={imagesTour360}
            />

            {adType === "Lançamento" && (
              <>
                <SectionTitle>Planta baixa</SectionTitle>
                <FloorPlans
                  ref={floorPlansAdRef}
                  isEditing
                  populateImages={imagesFloorPlan}
                />
              </>
            )}

            <SectionTitle>Vídeo</SectionTitle>
            <Video
              ref={videoAdRef}
              video=""
              isEditing
              populate={videoPopulated}
            />

            <AdvertiserDataContainer>
              <SectionTitle>Dados do anunciante</SectionTitle>

              <InputBootstrap
                name="name"
                label="Nome"
                placeholder="Nome"
                error={errors.name}
                {...register("name")}
              />

              <InputBootstrap
                name="email"
                label="E-mail"
                placeholder="E-mail"
                error={errors.email}
                {...register("email")}
              />

              <CellphoneInput
                name="cellphone"
                label="Telefone/Celular"
                error={errors.cellphone}
                control={control}
                {...register("cellphone")}
              />

              <CheckboxInputWrapper label="WhatsApp?" htmlFor="whatsApp">
                <HasWhatsappInput
                  id="whatsApp"
                  name="whatsApp"
                  checked={isWhatsapp}
                  onClick={() => setIsWhatsapp((prevState) => !prevState)}
                />
              </CheckboxInputWrapper>
            </AdvertiserDataContainer>

            <ButtonWrapper>
              <SaveButton id="dashboard-edit-submit-button">
                atualizar imóvel
              </SaveButton>
            </ButtonWrapper>
          </Form>
        </>
      )}
    </Container>
  );
}
