
import React, { PureComponent } from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { Router, Route, Switch, Redirect } from 'react-router-dom';

import { PrivateProfileRoute } from '../../routes/privates/private-profile-route';
import { PrivateDashboardRoute } from '../../routes/privates/private-dashboard-route';

import AboutUs from '../about-us/AboutUs';
import { AdReport } from '../ad-report';
import Koortimativa from '../koortimativa/Koortimativa';
import { Contact } from '../contact';
import Dashboard from '../dashboard/Dashboard';
import Dashboardd from '../dashboard-ads/DashboardAds';
import domEventsUtil from '../../utils/dom-events/dom-events.util';
import eventsConstants from '../../constants/events';
import Favorites from '../favorites/Favorites';
import Footer from '../../partials/footer/Footer';
import Header from '../../partials/header/Header';
import Home from '../home/Home';
import pathsConstants from '../../constants/paths';
import Profile from '../profile/Profile';
import { ProfileEdition } from '../profile-edition';
import PurchaseFlow from '../purchase-flow/PurchaseFlow';
import Report from '../report/Report';
import ReportBuilder from '../report-builder/ReportBuilder';
import Search from '../search/Search';
import { Filter } from '../filter';
import SearchDetail from '../search-detail/SearchDetail';
import TermsAndConditions from '../terms-and-conditions/TermsAndConditions';
import Contract from '../contract/Contract';
import userService from '../../services/user/user.service';
import ChoosePlan from '../choose-plan/ChoosePlan';
import ConfigAccount from '../config-account/ConfigAccount';
import Ad from '../config-account/ad-log';
import { Incorporation } from '../Incorporation-purchase';
import BillingInfo from '../purchase-flow/billing-info/BillingInfo';
import Builder2 from '../purchase-flow/builder2/Builder';
import RealtorsSearch from '../realtors-search/RealtorsSearch';
import PlansExpose from '../../components/plans-expose/PlansExpose'
import Checkout from '../../pages/checkout';
import { LoadingUserInfo } from '../loading-use-info/LoadingUserInfo';
import PrivacyPolicy from '../privacy-policy/PrivacyPolicy';
import DiversityAndInclusionPolicy from '../diversity-inclusion-policy/DiversityAndInclusionPolicy';
import NewPasswordAuth from '../new-password/NewPassword';
import { AttachProperty } from '../dashboard/my-brokers/attach-property';
import { DashboardLeads } from '../dashboard-leads';
import { CompanyBroker } from '../dashboard-leads/company-broker';
import { FeedbackWidget } from '../../components/feedback-widget';
import { CommonUserProfile } from '../config-account/common-user-profile';
import { Lead } from '../dashboard-leads/lead';
import { BillingLeads } from '../billing-leads';
import { HistoryContext } from '../../contexts/historyContext';
import { DisplayProvider } from '../../contexts/filterContext';

/*
  global
  window
*/

class Main extends PureComponent {

  constructor(props) {
    super(props);

    const { location } = props;
    this.state = {
      showFooter: this.checkFooterStatus(location),
      user: userService.retrieve(),
    };

    domEventsUtil.subscribe({
      name: eventsConstants.AUTH_STATUS_CHANGED,
      callback: this.handleAuthStatusChanged
    });
  }

  componentDidMount() {
    const { history } = this.props;
    history.listen((location) => {
      if (this.shouldScrollToTop(location)) {
        window.scrollTo(0, 0);
      }

      const showFooter = this.checkFooterStatus(location);
      this.setState({
        ...this.state,
        showFooter,
      });
    });
  }

  componentWillUnmount() {
    domEventsUtil.unsubscribe({
      name: eventsConstants.AUTH_STATUS_CHANGED,
      callback: this.handleAuthStatusChanged
    });
  }

  checkFooterStatus = location => {
    return location.pathname !== pathsConstants.SEARCH &&
      !location.pathname.includes(pathsConstants.PURCHASE_FLOW) &&
      location.pathname !== pathsConstants.PURCHASE_CHOOSE_TYPE &&
      !location.pathname.includes("dashboard-ads") &&
      !location.pathname.includes("config-account/profile") &&
      !location.pathname.includes("my-account/common-profile") &&
      !location.pathname.includes("profile-edition") &&
      !location.pathname.includes("/anuncio");
  };

  shouldScrollToTop = ({ pathname }) => (
    pathname !== pathsConstants.DASHBOARD_ADS &&
    pathname !== pathsConstants.DASHBOARD_PLAN &&
    pathname !== pathsConstants.DASHBOARD_RECOMMENDATIONS &&
    pathname !== pathsConstants.DASHBOARD_REPORTS &&
    pathname !== pathsConstants.DASHBOARD_REQUESTS &&
    pathname !== pathsConstants.DASHBOARD_REVIEWS &&
    pathname !== pathsConstants.PROFILE_SALE &&
    pathname !== pathsConstants.PROFILE_RENTAL &&
    pathname !== pathsConstants.PROFILE_DEALS &&
    pathname !== pathsConstants.PROFILE_REVIEWS &&
    pathname !== pathsConstants.REPORT_REAL_ESTATE &&
    pathname !== pathsConstants.REPORT_CONDO &&
    pathname !== pathsConstants.REPORT_SURROUNDINGS &&
    pathname !== pathsConstants.DASHBOARDADS &&
    pathname !== pathsConstants.DASHBOARDADS_EDIT &&
    pathname !== pathsConstants.DASHBOARDADS_MESSAGES &&
    pathname !== pathsConstants.DASHBOARDADS_REPORTS &&
    pathname !== pathsConstants.DASHBOARDADS_NAV
  );

  handleAuthStatusChanged = event => {
    const { auth } = event.detail.params;

    this.setState({
      ...this.state,
      user: auth ? userService.retrieve() : null,
    }, () => {
      // const { history } = this.props;
      // history.push(pathsConstants.HOME);
    });
  };

  render() {
    const { history } = this.props;
    const { user, showFooter } = this.state;

    const showHeader = !window.location.href.includes('/anuncio');

    return (
      <HistoryContext.Provider value={history}>
        <Wrapper>
          {/* {!window.location.href.includes('/mapa?') && <FeedbackWidget />} */}

          <Router history={history}>
            <Switch>
              <Route
                exact
                path={pathsConstants.HOME}
                component={Home}
              />
              <Route
                path={pathsConstants.AD_DETAIL}
                component={SearchDetail}
              />
              <Route
                path={pathsConstants.SEARCH}
                component={Search}
                exact
              />
              <Route
                path={pathsConstants.FILTER}
                render={(props) => (
                  <DisplayProvider>
                    <Filter {...props} />
                  </DisplayProvider>
                )}
                exact
              />
              <PrivateProfileRoute
                path={`${pathsConstants.PROFILE}`}
                component={Profile}
                user={user}
              />
              <Route
                path={pathsConstants.ABOUT_US}
                component={AboutUs}
              />
              <Route
                path={pathsConstants.KOORTIMATIVA}
                component={Koortimativa}
              />
              <Route
                path={pathsConstants.CONTACT}
                component={Contact}
              />
              <Route
                path={pathsConstants.TERMS_AND_CONDITIONS}
                component={TermsAndConditions}
              />
              <Route
                path={pathsConstants.PRIVACY_POLICY}
                component={PrivacyPolicy}
              />
              {/* <Route
                path={pathsConstants.NEW_PASSWORD}
                component={NewPasswordAuth}
              /> */}
              <Route
                path={pathsConstants.DIVERSITY_AND_INCLUSION_POLICY}
                component={DiversityAndInclusionPolicy}
              />
              <Route
                path={`${pathsConstants.REPORT}`}
                component={Report}
              />
              <Route
                path={pathsConstants.PURCHASE_FLOW}
                component={PurchaseFlow}
              />
              {user && (
                <Route
                  path={`${pathsConstants.REPORT_BUILDER}`}
                  component={ReportBuilder}
                />
              )}
              {user && (
                <Route
                  path={`${pathsConstants.CONTRACT}`}
                  component={Contract}
                />
              )}
              {user && (
                <Route
                  path={`${pathsConstants.REPORT_BUILDER2}`}
                  component={Builder2}
                />
              )}
              {user && (
                <Route
                  path={`${pathsConstants.BILLING_INFO}`}
                  component={BillingInfo}
                />
              )}
              {user && (
                <PrivateDashboardRoute
                  path={pathsConstants.DASHBOARD}
                  component={Dashboard}
                  user={user}
                />
              )}
              {user && (
                <PrivateDashboardRoute
                  path={pathsConstants.DASHBOARD_BROKERS_ATTACH_PROPERTY}
                  component={AttachProperty}
                  user={user}
                />
              )}
              {user && (
                <PrivateDashboardRoute
                  path={pathsConstants.DASHBOARD_LEADS}
                  component={DashboardLeads}
                  exact
                  user={user}
                />
              )}
              <Route
                path={pathsConstants.DASHBOARD_LEADS_VIEW_LEAD}
                component={Lead}
                exact
                user={user}
              />
              {user && (
                <PrivateDashboardRoute
                  path={pathsConstants.DASHBOARD_LEADS_COMPANY_BROKER}
                  component={CompanyBroker}
                  user={user}
                />
              )}
              {user && (
                <Route
                  path={pathsConstants.BILLING_LEADS}
                  component={BillingLeads}
                />
              )}
              {user && (
                <Route
                  path={pathsConstants.DASHBOARDADS_NAV}
                  component={Dashboard}
                />
              )}
              {user && (
                <Route
                  path={pathsConstants.DASHBOARDADS_NAV}
                  component={Dashboardd}
                />
              )}
              {user && (
                <Route
                  path={pathsConstants.DASHBOARDADS}
                  component={Dashboardd}
                />
              )}
              {user && (
                <Route
                  path={pathsConstants.CHECKOUT}
                  component={Checkout}
                />
              )}
              <Route
                path={pathsConstants.PURCHASE_CHOOSE_TYPE}
                component={ChoosePlan}
                history={history}
              />
              <Route
                path={pathsConstants.PURCHASE_CHOOSE_TYPE}
                component={PlansExpose}
                history={history}
              />
              <Route
                path={pathsConstants.CONFIG_ACCOUNT_AD_LOG}
                component={Ad}
                history={history}
              />
              <Route
                path={pathsConstants.CONFIG_ACCOUNT}
                component={ConfigAccount}
                history={history}
              />
              <Route
                path={pathsConstants.CONFIG_ACCOUNT_COMMON_USER_PROFILE}
                component={CommonUserProfile}
                history={history}
              />
              <Route
                path={pathsConstants.PURCHASE_FLOW_INCORPORATION}
                component={Incorporation}
                history={history}
              />
              <Route
                path={pathsConstants.AD_REPORT}
                component={AdReport}
                history={history}
              />
              <Route
                path={pathsConstants.REALTORS_SEARCH}
                component={RealtorsSearch}
                history={history}
              />

              {user && (
                <Route
                  path={pathsConstants.FAVORITES}
                  component={Favorites}
                />
              )}
              {user && (
                <Route
                  path={pathsConstants.FAVORITES_MY_FAVORITES}
                  component={Favorites}
                />
              )}
              {user && (
                <Route
                  path={pathsConstants.PROFILE_EDITION}
                  component={ProfileEdition}
                  exact
                />
              )}
              {user && (
                <Redirect
                  from={pathsConstants.PROFILE}
                  to={pathsConstants.PROFILE}
                />
              )}
              <Route
                path={pathsConstants.LOADING_USER_INFO}
                component={LoadingUserInfo}
                history={history}
              />

              <Redirect to={pathsConstants.HOME} />
            </Switch>
          </Router>
          {showFooter && <Footer />}

          {showHeader && <Header />}
        </Wrapper>
      </HistoryContext.Provider>
    );
  }
}

Main.propTypes = {
  history: propTypes.object,
  location: propTypes.object,
};

Main.defaultProps = {};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export default Main;
