import React, { useState, useRef, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { Slide } from "react-slideshow-image";
import DOMPurify from 'dompurify';
import { useAuth0 } from '@auth0/auth0-react';
import Modal from 'react-modal';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Slider, Slide as PureSlide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import { KeyboardArrowLeft, KeyboardArrowRight } from 'styled-icons/material';
import {
  RiHeartLine,
  RiHeartFill,
  RiCommunityLine,
  RiRuler2Line,
  RiHotelBedLine,
  RiRoadsterLine,
  RiCheckboxCircleLine,
  RiImage2Line,
  RiCompasses2Line,
  RiRefreshLine,
  RiSlideshowLine,
  RiMap2Line,
  RiMapPinUserLine,
  RiFullscreenExitLine,
  RiFullscreenLine,
  RiCloseLine
} from 'react-icons/ri';
import { Bathtub } from "@phosphor-icons/react";

import { KnowMoreCTA } from './components/know-more-cta';
import AdCardInteractive from '../../../../components/ad-card-interactive-legacy';
import Map from '../../../../components/map/Map';
import StreetView from '../../../../components/street-view';
import { AdCardInteractiveWrapper, AdReportContainer } from '../CommonAd/styles';
import AdCardInteractiveLegacy from '../../../../components/ad-card-interactive-legacy';
import { ContactForm } from './components/contact-form';
import SliderFullScreen from '../../../../components/image-slider/SliderFullScreen';
import Panorama from '../../../../components/panorama';
import { CTAWidget } from '../components/cta-widget';
import Footer from '../../../../partials/footer/Footer';

import { TYPES as IMAGE_TYPES } from '../../../../models/image/image.model';

import {
  Container,
  HeadlineContainer,
  HeadlineOverlay,
  FloatingCTABox,
  FloatingCTABoxContent,
  PriceGroup,
  SeparatorLine,
  CompanyGroup,
  CTAButton,
  IntroductionContainer,
  IntroductionTitleGroup,
  IntroductionDescriptionGroup,
  FeaturesList,
  DetailingContainer,
  DetailingContent,
  DetailingTitleGroup,
  DetailingDescriptionGroup,
  GalleryContainer,
  GalleryTitleGroup,
  GallerySlideGroup,
  GallerySlideItemGroup,
  GalleryTabGroup,
  GalleryTabItemGroup,
  AboutContainer,
  AboutContent,
  AboutTitleGroup,
  AboutDescriptionGroup,
  AboutVideoGroup,
  CharacteristicsContainer,
  CharacteristicsTitleGroup,
  CharacteristicsGroup,
  CharacteristicsGroupItem,
  ComplementContainer,
  ComplementTitleGroup,
  ComplementDescriptionGroup,
  BannerContainer,
  BannerContent,
  BannerTitleGroup,
  BannerDescriptionGroup,
  LocationContainer,
  LocationTitleGroup,
  LocationMapGroup,
  DataSheetContainer,
  DataSheetTitleGroup,
  DataSheetList,
  CompanyContainer,
  CompanyTitleGroup,
  CompanyDescriptionGroup,
  RecommendationContainer,
  MapLocationPlaceholder,
} from './styles';

import {
  ModalContent,
  ButtonsArea,
  Carousel,
  CarouselContent,
  ModalBody,
  ModalSliderControlButtonLeft,
  ModalSliderControlButtonRight
} from './components/carousel-presentation/styles';
import Header from '../components/Header';

import { COLOR_2 } from '../../../../styled/variables/variables';

import userService from '../../../../services/user/user.service';
import userFavoriteService from '../../../../services/user/favorite/user.favorite.service';
import listingServiceV2 from '../../../../services/listing/listing-v2.service';
import realEstateService from '../../../../services/real-estate/real-estate.service';

import currencyUtil from '../../../../utils/currency/currency.util';

import '../../search-detail.css';

const GALLERY_TAB_TYPES = {
  IMAGES: 'IMAGES',
  PANORAMA: 'PANORAMA',
  FLOOR_PLAN: 'FLOOR_PLAN',
  SLIDES: 'SLIDES',
};

const LOCATION_TAB_TYPES = {
  MAP: 'MAP',
  STREET_VIEW: 'STREET_VIEW',
}

export function CustomRelease({ realEstate, history }) {
  const [headlineBgImg, setHeadlineBgImg] = useState('');

  const [isFavorited, setIsFavorited] = useState(false);

  const [adsOthersRealEstate, setAdsOthersRealEstate] = useState(null);
  const [adsRealEstate, setAdsRealEstate] = useState(null);

  const [galleryTabSelected, setGalleryTabSelected] = useState(GALLERY_TAB_TYPES.IMAGES);
  const [locationTabSelected, setLocationTabSelected] = useState(LOCATION_TAB_TYPES.MAP);

  const [fullScreenImageUrl, setFullScreenImageUrl] = useState('');
  const [isFullScreenImageUrl, setIsFullScreenImageUrl] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [panoramaModalVisible, setPanoramaModalVisible] = useState(false);
  const [panoramaActiveSlide, setPanoramaActiveSlide] = useState(0);

  const [userStored, setUserStored] = useState(userService.retrieve());
  const [favorites, setFavorites] = useState();

  const [contactFormModalVisible, setContactFormModalVisible] = useState(false);

  const [floatingCTAOnTop, setFloatingCTAOnTop] = useState(false);

  const [showMapLocationPlaceholder, setShowMapLocationPlaceholder] = useState(true);
  const [showStreetViewPlaceholder, setShowStreetViewPlaceholder] = useState(true);

  const [floating, setFloating] = useState(false);

  const mapRef = useRef(null);
  const handle = useFullScreenHandle();
  const { loginWithRedirect } = useAuth0();

  async function getRealEstateRecommendations() {
    const user = userService.retrieve();

    try {
      const {
        status,
        listingsInTheSameNeighborhood,
        othersListings,
      } = await listingServiceV2.getRecommendedAds({
        id: realEstate.listingId,
        token: (user ? user.token : null),
      });

      if (status !== 200) {
        throw new Error("Não foi possível buscar as recomendações");
      }

      setAdsOthersRealEstate(listingsInTheSameNeighborhood);
      setAdsRealEstate(othersListings)
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    if (realEstate && realEstate.listingId) {
      const inDashboard = window.location.href.includes('inDashboard=true');

      if (!inDashboard) {
        informPrint();
      }

      // getRealEstateRecommendations();
    }
  }, [realEstate]);

  useEffect(() => {
    async function getFavorites() {
      const user = userService.retrieve();

      if (user) {
        setUserStored(user);

        const favoritesAds = await userFavoriteService.get({
          token: user.token,
        });

        setFavorites(favoritesAds);
        setIsFavorited(favoritesAds.includes(realEstate.listingId));
      }

      if (user) {
        const params = new URL(document.location).searchParams;

        const actionParam = params.get("action");

        if (actionParam && actionParam === 'autoFavorite') {
          handleAddOrRemoveFavorite({ isAutoFavorite: true });
        }
      }
    }

    getFavorites();
  }, [])

  useEffect(() => {
    const imageUrl = realEstate.launch.headline.image.replace('{width}', window.screen.width);

    async function checkImageUrl(url) {
      return fetch(url)
        .then(response => {
          if (response.ok) {
            return url;
          } else {
            if (window.screen.width >= 2560) {
              return realEstate.launch.headline.image.replace('{width}', 'original');
            } else {
              return fetch(realEstate.launch.headline.image.replace('{width}', '1920'))
                .then(response => {
                  if (response.ok) {
                    return realEstate.launch.headline.image.replace('{width}', '1920');
                  } else {
                    return realEstate.launch.headline.image.replace('{width}', 'original');
                  }
                })
                .catch(() => {
                  return realEstate.launch.headline.image.replace('{width}', 'original');
                });
            }
          }
        })
        .catch(() => {
          return realEstate.launch.headline.image.replace('{width}', 'original');
        });
    }

    checkImageUrl(imageUrl).then(img => setHeadlineBgImg(img));
  }, [realEstate.images]);

  async function informPrint() {
    try {
      await realEstateService.informView({
        id: realEstate.listingId,
      });
    } catch (error) {
      console.error(error);
      // Do nothing.
    }
  };

  function setMarkers() {
    const { id, surroundingPlaces, address } = realEstate;

    if (mapRef.current === null) {
      return;
    }

    mapRef.current.setMarkers({
      realEstates: [
        {
          id,
          latitude: address.geolocation.latitude,
          longitude: address.geolocation.longitude,
          highlight: true,
        },
      ],
      surroundingPlaces: !!surroundingPlaces ? surroundingPlaces.map((sp) => {
        const mapped = {
          ...sp,
          latitude: sp.geolocation.latitude,
          longitude: sp.geolocation.longitude,
        };
        delete mapped.geolocation;
        return mapped;
      }) : [],
    });
  }

  function searchFavoriteAdOnFavorites(adId) {
    if (favorites && favorites.includes(adId)) return true;

    return false
  }

  function handleOpenContactFormModal() {
    setContactFormModalVisible(true);
  }

  function handleCloseContactFormModal() {
    setContactFormModalVisible(false);
  }

  function handleOpenGallerySlide(url) {
    setFullScreenImageUrl(url.replace('550', '1024'));
    setIsFullScreenImageUrl(true);
  };

  function handleCloseGallerySlide() {
    setFullScreenImageUrl('');
    setIsFullScreenImageUrl(false);
  };

  function onCloseFullScreen(state) {
    if (!state) {
      setIsFullscreen(false)
    }
  }

  function handleOpenPanoramaSlide(index) {
    setPanoramaModalVisible(true)
    setPanoramaActiveSlide(index);
    setIsFullscreen(true)
  }

  function handleClosePanoramaSlide(index) {
    setPanoramaModalVisible(false)
    setPanoramaActiveSlide(0);
    setIsFullscreen(false)
  }

  function handleChangeFullscreen(mode) {
    if (mode === 'maximize') {
      setIsFullscreen(true);
      handle.enter();
    } else {
      setIsFullscreen(false);
      handle.exit();
    }
  }

  function onCloseFullScreen(state) {
    if (!state) {
      setIsFullscreen(false)
    }
  }

  async function handleAddOrRemoveFavorite({ isAutoFavorite = false }) {
    const user = userService.retrieve();

    if (!user) {
      const pathRedirect = `${window.location.href.replace(window.location.origin, '')}?action=autoFavorite`;
      localStorage.setItem('@Koort:PathRedirect', pathRedirect);

      loginWithRedirect({ authorizationParams: { redirect_uri: `${window.location.origin}/authentication/login/success?login=true` } });
    } else {
      setIsFavorited(state => !state);

      let result;
      try {
        result = await userFavoriteService.patch({
          listingId: realEstate.listingId,
          token: user.token,
        });
        userFavoriteService.save({
          listingIds: result,
        });

        if (isAutoFavorite) {
          const { origin, pathname } = window.location;
          const url = `${origin + pathname}`;

          window.location.href = url;
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    function verifyFloatingCTAOnTop() {
      const element = document.getElementById('floating-cta-box-page');
      const elementRect = element.getBoundingClientRect();

      if (elementRect.top <= 0) {
        setFloatingCTAOnTop(true);
      } else {
        setFloatingCTAOnTop(false);
      }
    }

    window.addEventListener('scroll', verifyFloatingCTAOnTop);

    return () => {
      window.removeEventListener('scroll', verifyFloatingCTAOnTop);
    };
  }, []);

  useEffect(() => {
    if (floatingCTAOnTop) {
      setTimeout(() => {
        setFloating(true);
      }, 200);
    } else {
      setFloating(false)
    }
  }, [floatingCTAOnTop]);

  const { launch, seo, price, incorporation, images, featuresOriginFormat } = realEstate;

  const isMozillaNavigator = !(/Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor));

  const images_slide = images
    .filter(({ type }) => type === IMAGE_TYPES.PICTURE)
    .map((image) => image.url);

  const images_slide_1024 = images
    .filter(({ type }) => type === IMAGE_TYPES.PICTURE)
    .map((image) => image.url.replace('550', '1024'));

  const floor_plan_slide = images
    .filter(({ type }) => type === IMAGE_TYPES.FLOOR_PLAN)
    .map((image) => image.url);

  const floor_plan_slide_1024 = images
    .filter(({ type }) => type === IMAGE_TYPES.FLOOR_PLAN)
    .map((image) => image.url.replace('550', '1024'));

  const panorama_slide = images
    .filter(({ type }) => type === IMAGE_TYPES.PANORAMA)
    .map((image) => image.url);

  const slides_images_slide = images.filter(({ type }) => type === IMAGE_TYPES.SLIDES)
    .map((image) => image.url);

  const slides_images_slide_1024 = images.filter(({ type }) => type === IMAGE_TYPES.SLIDES)
    .map((image) => image.url.replace('550', '1024'));

  return (
    <>
      <Header
        history={history}
        isFullScreenImageUrl={isFullScreenImageUrl}
        panoramaModalVisible={panoramaModalVisible}
        floatingCTAOnTop={floatingCTAOnTop}
        isMozillaNavigator={isMozillaNavigator}
        price={price}
        incorporation={incorporation}
        isFavorited={isFavorited}
        onAddOrRemoveFavorite={handleAddOrRemoveFavorite}
        onOpenContactFormModal={handleOpenContactFormModal}
      />
      <Container>
        <Helmet>
          <title>{realEstate.seo.headTitle}</title>
          <meta name="title" content={realEstate.seo.headTitle} />
          <meta name="description" content={realEstate.seo.headDescription} />

          <meta property="og:title" content={realEstate.seo.headTitle} />
          <meta property="og:description" content={realEstate.seo.headDescription} />
          <meta property="og:url" content={window.location.href} />

          <meta property="twitter:title" content={realEstate.seo.headTitle} />
          <meta property="twitter:description" content={realEstate.seo.headDescription} />
          <meta property="twitter:url" content={window.location.href} />
        </Helmet>

        <HeadlineContainer bgImg={headlineBgImg}>
          <HeadlineOverlay>
            <h1>{launch.headline.title}</h1>
            <p>{launch.headline.subTitle}</p>
          </HeadlineOverlay>

          <ContactForm
            listingId={realEstate.listingId}
            isRelease
            modalVisible={contactFormModalVisible}
            onCloseModal={handleCloseContactFormModal}
            onOpenModal={handleOpenContactFormModal}
          />
        </HeadlineContainer>

        <FloatingCTABox floating={floating} isTop={isFullScreenImageUrl || panoramaModalVisible}>
          <FloatingCTABoxContent
            id="floating-cta-box-page"
            isTop={floatingCTAOnTop}
            isMozillaNavigator={isMozillaNavigator}
          >
            <PriceGroup isTop={floatingCTAOnTop}>
              <button type="button" onClick={handleAddOrRemoveFavorite} aria-label="Favoritar anúncio">
                {isFavorited ? (
                  <RiHeartFill size={24} color={floatingCTAOnTop ? '#fff' : '#000'} />
                ) : (
                  <RiHeartLine size={24} color={floatingCTAOnTop ? '#fff' : '#000'} />
                )}
              </button>

              <div>
                A partir de <br />
                <strong>{price.main[0] !== 0 ? currencyUtil.format({ value: price.main[0] }) : 'Sob consulta'}</strong>
              </div>
            </PriceGroup>

            <SeparatorLine isTop={floatingCTAOnTop} />

            <CompanyGroup isTop={floatingCTAOnTop}>
              {!floatingCTAOnTop && <img src={incorporation?.image} alt={incorporation?.name} />}

              <span>Incorporadora <strong>{incorporation?.name}</strong></span>
            </CompanyGroup>

            <CTAButton type="button" onClick={handleOpenContactFormModal} isTop={floatingCTAOnTop}>
              {window.innerWidth < Number(520) && floatingCTAOnTop ? 'Contato' : 'Entrar em contato'}
            </CTAButton>
          </FloatingCTABoxContent>
        </FloatingCTABox>

        <IntroductionContainer>
          <div>
            <IntroductionTitleGroup>
              <strong>{launch.label}</strong>
              {launch.introduction.title}
            </IntroductionTitleGroup>

            <IntroductionDescriptionGroup dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(launch.introduction.htmlDescription) }} />
          </div>

          <FeaturesList>
            <li>
              <span><RiCommunityLine size={24} /></span>
              Lançamento {realEstate.development.constructionStatus}
            </li>
            <li>
              <span><RiRuler2Line size={24} /></span>
              {realEstate.area.length === 1 &&
                `${realEstate.area} m²`
              }

              {realEstate.area.length === 2 && realEstate.area[0] !== realEstate.area[1] &&
                `${realEstate.area[0]} - ${realEstate.area[1]} m²`
              }

              {realEstate.area.length === 2 && realEstate.area[0] === realEstate.area[1] &&
                `${realEstate.area[0]} m²`
              }
            </li>
            <li>
              <span><RiHotelBedLine size={24} /></span>
              {realEstate.bedrooms.length === 1 &&
                `${realEstate.bedrooms} quarto${realEstate.bedrooms > 1 ? 's' : ''}`
              }

              {realEstate.bedrooms.length === 2 && realEstate.bedrooms[0] !== realEstate.bedrooms[1] &&
                `${realEstate.bedrooms[0]} - ${realEstate.bedrooms[1]} quartos`
              }

              {realEstate.bedrooms.length === 2 && realEstate.bedrooms[0] === realEstate.bedrooms[1] &&
                `${realEstate.bedrooms[0]} quarto${realEstate.bedrooms[0] > 1 ? 's' : ''}`
              }
            </li>
            <li>
              <span><Bathtub weight='bold' size={24} /></span>
              {realEstate.bathrooms.length === 1 &&
                `${realEstate.bathrooms} banheiro${realEstate.bathrooms > 1 ? 's' : ''}`
              }

              {realEstate.bathrooms.length === 2 && realEstate.bathrooms[0] !== realEstate.bathrooms[1] &&
                `${realEstate.bathrooms[0]} - ${realEstate.bathrooms[1]} banheiros`
              }

              {realEstate.bathrooms.length === 2 && realEstate.bathrooms[0] === realEstate.bathrooms[1] &&
                `${realEstate.bathrooms[0]} banheiro${realEstate.bathrooms[0] > 1 ? 's' : ''}`
              }
            </li>
            <li>
              <span><RiRoadsterLine size={24} /></span>
              {realEstate.parkingSpaces.length === 0 && 'Sem vagas'}

              {realEstate.parkingSpaces.length === 1 &&
                `${realEstate.parkingSpaces} vaga${realEstate.parkingSpaces > 1 ? 's' : ''}`
              }

              {realEstate.parkingSpaces.length === 2 && realEstate.parkingSpaces[0] !== realEstate.parkingSpaces[1] &&
                `${realEstate.parkingSpaces[0]} - ${realEstate.parkingSpaces[1]} vagas`
              }

              {realEstate.parkingSpaces.length === 2 && realEstate.parkingSpaces[0] === realEstate.parkingSpaces[1] &&
                `${realEstate.parkingSpaces[0]} vaga${realEstate.parkingSpaces[0] > 1 ? 's' : ''}`
              }
            </li>
          </FeaturesList>
        </IntroductionContainer>

        <DetailingContainer>
          <DetailingContent>
            <div>
              <DetailingTitleGroup>
                <strong>{launch.label}</strong>
                {launch.detailing.title}
              </DetailingTitleGroup>

              <DetailingDescriptionGroup dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(launch.detailing.htmlDescription) }} />

              <KnowMoreCTA onClick={handleOpenContactFormModal} />
            </div>

            <img src={launch.detailing.image.replace('{width}', 1024)} alt={launch.detailing.title} />
          </DetailingContent>
        </DetailingContainer>

        <GalleryContainer>
          <GalleryTitleGroup>
            <strong>{launch.label}</strong>
            Confira nossa galeria de fotos
          </GalleryTitleGroup>

          <GallerySlideGroup>
            {galleryTabSelected === GALLERY_TAB_TYPES.IMAGES && (
              <Slide {...properties}>
                {images_slide.map((url, index) => (
                  <GallerySlideItemGroup
                    key={url}
                    onClick={() => handleOpenGallerySlide(url)}
                  >
                    <img
                      src={url}
                      alt={`${seo.title} - Foto ${index + 1}`}
                      title={`Imagem ${index + 1} - ${seo.title}`}
                      loading='lazy'
                    />
                  </GallerySlideItemGroup>
                ))}
              </Slide>
            )}

            {galleryTabSelected === GALLERY_TAB_TYPES.FLOOR_PLAN && (
              <Slide {...properties}>
                {floor_plan_slide.map((url, index) => (
                  <GallerySlideItemGroup
                    key={url}
                    onClick={() => handleOpenGallerySlide(url)}
                  >
                    <img
                      src={url}
                      alt={`${seo.title} - Planta Baixa ${index + 1}`}
                      title={`Planta Baixa ${index + 1} - ${seo.title}`}
                      loading='lazy'
                    />
                  </GallerySlideItemGroup>
                ))}
              </Slide>
            )}

            {galleryTabSelected === GALLERY_TAB_TYPES.PANORAMA && (
              <Slide {...properties}>
                {panorama_slide.map((url, index) => (
                  <GallerySlideItemGroup
                    key={url}
                    onClick={() => handleOpenPanoramaSlide(index)}
                  >
                    <img
                      src={url}
                      alt={`${seo.title} - Panorama ${index + 1}`}
                      title={`Panorama ${index + 1} - ${seo.title}`}
                      loading='lazy'
                    />
                  </GallerySlideItemGroup>
                ))}
              </Slide>
            )}

            {galleryTabSelected === GALLERY_TAB_TYPES.SLIDES && (
              <Slide {...properties}>
                {slides_images_slide.map((url, index) => (
                  <GallerySlideItemGroup
                    key={url}
                    onClick={() => handleOpenGallerySlide(url)}
                  >
                    <img
                      src={url}
                      alt={`${seo.title} - Slide ${index + 1}`}
                      title={`Slide ${index + 1} - ${seo.title}`}
                      loading='lazy'
                    />
                  </GallerySlideItemGroup>
                ))}
              </Slide>
            )}
          </GallerySlideGroup>

          <GalleryTabGroup>
            <GalleryTabItemGroup isSelected={galleryTabSelected === GALLERY_TAB_TYPES.IMAGES} >
              <button type="button" onClick={() => setGalleryTabSelected(GALLERY_TAB_TYPES.IMAGES)}>
                <span><RiImage2Line size={24} /></span>
                Imagens do imóvel
              </button>
            </GalleryTabItemGroup>

            {!!floor_plan_slide.length && (
              <GalleryTabItemGroup isSelected={galleryTabSelected === GALLERY_TAB_TYPES.FLOOR_PLAN}>
                <button type="button" onClick={() => setGalleryTabSelected(GALLERY_TAB_TYPES.FLOOR_PLAN)}>
                  <span> <RiCompasses2Line size={24} /></span>
                  Planta baixa
                </button>
              </GalleryTabItemGroup>
            )}

            {!!panorama_slide.length && (
              <GalleryTabItemGroup isSelected={galleryTabSelected === GALLERY_TAB_TYPES.PANORAMA}>
                <button type="button" onClick={() => setGalleryTabSelected(GALLERY_TAB_TYPES.PANORAMA)}>
                  <span><RiRefreshLine size={24} /></span>
                  Tour 360
                </button>
              </GalleryTabItemGroup>
            )}

            {!!slides_images_slide.length && (
              <GalleryTabItemGroup isSelected={galleryTabSelected === GALLERY_TAB_TYPES.SLIDES}>
                <button type="button" onClick={() => setGalleryTabSelected(GALLERY_TAB_TYPES.SLIDES)}>
                  <span><RiSlideshowLine size={24} /></span>
                  Book Digital
                </button>
              </GalleryTabItemGroup>
            )}
          </GalleryTabGroup>

          {isFullScreenImageUrl &&
            galleryTabSelected == GALLERY_TAB_TYPES.IMAGES && (
              <SliderFullScreen
                images={images_slide_1024}
                show={isFullScreenImageUrl}
                handle={handleCloseGallerySlide}
                currentURL={fullScreenImageUrl}
              />
            )}

          {isFullScreenImageUrl &&
            galleryTabSelected == GALLERY_TAB_TYPES.FLOOR_PLAN && (
              <SliderFullScreen
                images={floor_plan_slide_1024}
                show={isFullScreenImageUrl}
                handle={handleCloseGallerySlide}
                currentURL={fullScreenImageUrl}
              />
            )}

          {isFullScreenImageUrl &&
            galleryTabSelected == GALLERY_TAB_TYPES.SLIDES && (
              <SliderFullScreen
                images={slides_images_slide_1024}
                show={isFullScreenImageUrl}
                handle={handleCloseGallerySlide}
                currentURL={fullScreenImageUrl}
              />
            )}

          <Modal
            isOpen={panoramaModalVisible}
            onRequestClose={handleClosePanoramaSlide}
            style={{
              content: {
                backgroundColor: 'transparent',
                border: 0,
                padding: 0,
                inset: 0,
                borderRadius: 0,
              },
              overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.8)'
              }
            }}
          >
            <FullScreen handle={handle} onChange={(state) => onCloseFullScreen(state)}>
              <ModalContent>
                <ButtonsArea>
                  {isFullscreen ?
                    <button type="button" onClick={() => handleChangeFullscreen('minimize')}>
                      <RiFullscreenExitLine size="28" color="#FFFFFF" />
                    </button>
                    :
                    <button type="button" onClick={() => handleChangeFullscreen('maximize')}>
                      <RiFullscreenLine size="28" color="#FFFFFF" />
                    </button>
                  }
                  <button type="button" onClick={handleClosePanoramaSlide}>
                    <RiCloseLine size="28" color="#FFFFFF" />
                  </button>
                </ButtonsArea>

                <ModalBody>
                  <CarouselContent>
                    <Carousel
                      naturalSlideWidth={window.innerWidth}
                      naturalSlideHeight={window.innerHeight * 0.85}
                      totalSlides={panorama_slide.length}
                      currentSlide={panoramaActiveSlide}
                      dragEnabled={false}
                      touchEnabled={false}
                    >
                      <Slider
                        style={{
                          height: '100%'
                        }}
                      >
                        {panorama_slide.map((item, index) => (
                          <PureSlide key={index} index={index}>
                            <Panorama
                              url={item}
                            />
                          </PureSlide>
                        ))}
                      </Slider>

                      <ModalSliderControlButtonLeft>
                        <ButtonBack>
                          <KeyboardArrowLeft size="42" color={COLOR_2} />
                        </ButtonBack>
                      </ModalSliderControlButtonLeft>
                      <ModalSliderControlButtonRight>
                        <ButtonNext>
                          <KeyboardArrowRight size="42" color={COLOR_2} />
                        </ButtonNext>
                      </ModalSliderControlButtonRight>
                    </Carousel>
                  </CarouselContent>
                </ModalBody>
              </ModalContent>
            </FullScreen>
          </Modal>
        </GalleryContainer>

        <AboutContainer>
          <AboutContent hasVideo={!!realEstate.video}>
            <div>
              <AboutTitleGroup hasVideo={!!realEstate.video}>
                <strong>{launch.label}</strong>
                {launch.about.title}
              </AboutTitleGroup>

              <AboutDescriptionGroup
                hasVideo={!!realEstate.video}
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(launch.about.htmlDescription) }}
              />
            </div>

            {realEstate.video &&
              <AboutVideoGroup>
                <ReactPlayer
                  url={realEstate.video}
                  width="100%"
                  controls
                  light
                />
              </AboutVideoGroup>
            }
          </AboutContent>
        </AboutContainer>

        {featuresOriginFormat.property.length > 0 ||
          featuresOriginFormat.condo.length > 0 ||
          featuresOriginFormat.near.length > 0 ? (
          <CharacteristicsContainer>
            <CharacteristicsTitleGroup>
              <strong>{launch.label}</strong>
              Características Gerais
            </CharacteristicsTitleGroup>

            <CharacteristicsGroup>
              {featuresOriginFormat.property.length > 0 && (
                <CharacteristicsGroupItem>
                  <h3>Imóvel</h3>

                  <ul>
                    {featuresOriginFormat.property.map(item => (
                      <li key={item}>
                        <span><RiCheckboxCircleLine size={20} /></span>
                        {item.value}
                      </li>
                    ))}
                  </ul>
                </CharacteristicsGroupItem>
              )}

              {featuresOriginFormat.condo.length > 0 && (
                <CharacteristicsGroupItem>
                  <h3>Condomínio</h3>

                  <ul>
                    {featuresOriginFormat.condo.map(item => (
                      <li key={item}>
                        <span><RiCheckboxCircleLine size={20} /></span>
                        {item.value}
                      </li>
                    ))}
                  </ul>
                </CharacteristicsGroupItem>
              )}

              {featuresOriginFormat.near.length > 0 && (
                <CharacteristicsGroupItem>
                  <h3>Proximidades</h3>

                  <ul>
                    {featuresOriginFormat.near.map(item => (
                      <li key={item}>
                        <span><RiCheckboxCircleLine size={20} /></span>
                        {item.value}
                      </li>
                    ))}
                  </ul>
                </CharacteristicsGroupItem>
              )}
            </CharacteristicsGroup>
          </CharacteristicsContainer>
        ) : <></>}

        <ComplementContainer>
          <ComplementTitleGroup>
            <strong>{launch.label}</strong>
            {launch.complement.title}
          </ComplementTitleGroup>

          <ComplementDescriptionGroup dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(launch.complement.htmlDescription) }} />
        </ComplementContainer>

        <BannerContainer>
          <BannerContent>
            <div>
              <BannerTitleGroup>
                {seo.title}
              </BannerTitleGroup>
              <BannerDescriptionGroup dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(seo.htmlDescription) }} />
            </div>

            <KnowMoreCTA onClick={handleOpenContactFormModal} />
          </BannerContent>
        </BannerContainer>

        <LocationContainer>
          <LocationTitleGroup>
            Endereço {launch.label}
            <strong>{launch.datasheet.streetAddress}, {realEstate.address.state}</strong>
          </LocationTitleGroup>

          <LocationMapGroup>
            {locationTabSelected === LOCATION_TAB_TYPES.MAP && (
              showMapLocationPlaceholder ? (
                <MapLocationPlaceholder
                  placeholderUrl={`${process.env.PUBLIC_URL}/imgs/map-location-placeholder.webp`}
                >
                  <button
                    type="button"
                    onClick={() => setShowMapLocationPlaceholder(false)}
                  >
                    Ver no mapa
                  </button>
                </MapLocationPlaceholder>
              ) : (
                <Map
                  ref={mapRef}
                  onLoad={() => setTimeout(() => setMarkers(), 2000)}
                  isFunctionComponent
                  defaultLocation={{
                    latitude: realEstate.address.geolocation.latitude,
                    longitude: realEstate.address.geolocation.longitude,
                  }}
                  options={{
                    draggable: true,
                    zoom: 18,
                  }}
                />
              )
            )}

            {locationTabSelected === LOCATION_TAB_TYPES.STREET_VIEW && (
              showStreetViewPlaceholder ? (
                <MapLocationPlaceholder
                  placeholderUrl={`${process.env.PUBLIC_URL}/imgs/street-view-placeholder.webp`}
                >
                  <button
                    type="button"
                    onClick={() => setShowStreetViewPlaceholder(false)}
                  >
                    Ver local
                  </button>
                </MapLocationPlaceholder>
              ) : (
                <StreetView
                  defaultLocation={{
                    latitude: realEstate.address.geolocation.latitude,
                    longitude: realEstate.address.geolocation.longitude,
                  }}
                />
              )
            )}
          </LocationMapGroup>

          <GalleryTabGroup>
            <GalleryTabItemGroup isSelected={locationTabSelected === LOCATION_TAB_TYPES.MAP}>
              <button type="button" onClick={() => setLocationTabSelected(LOCATION_TAB_TYPES.MAP)}>
                <span><RiMap2Line size={24} /></span>
                Localização do imóvel
              </button>
            </GalleryTabItemGroup>

            <GalleryTabItemGroup isSelected={locationTabSelected === LOCATION_TAB_TYPES.STREET_VIEW}>
              <button type="button" onClick={() => setLocationTabSelected(LOCATION_TAB_TYPES.STREET_VIEW)}>
                <span> <RiMapPinUserLine size={24} /></span>
                Visualizar local
              </button>
            </GalleryTabItemGroup>
          </GalleryTabGroup>
        </LocationContainer>

        <DataSheetContainer>
          <DataSheetTitleGroup>
            Ficha Técnica
          </DataSheetTitleGroup>

          <DataSheetList>
            {launch.datasheet.streetAddress && (
              <li>
                <span>ENDEREÇO</span>
                {launch.datasheet.streetAddress[0]}
              </li>
            )}

            {launch.datasheet.bedrooms && launch.datasheet.bedrooms[0] !== 0 && (
              <li>
                <span>QUARTOS</span>
                {launch.datasheet.bedrooms.length === 1 &&
                  `${launch.datasheet.bedrooms} quarto${launch.datasheet.bedrooms > 1 ? 's' : ''}`
                }

                {launch.datasheet.bedrooms.length === 2 && launch.datasheet.bedrooms[0] === launch.datasheet.bedrooms[1] &&
                  `${launch.datasheet.bedrooms[0]} quarto${launch.datasheet.bedrooms[0] > 1 ? 's' : ''}`
                }

                {launch.datasheet.bedrooms.length === 2 && launch.datasheet.bedrooms[0] !== launch.datasheet.bedrooms[1] &&
                  `${launch.datasheet.bedrooms[0]} - ${launch.datasheet.bedrooms[1]} quartos`
                }
              </li>
            )}

            {launch.datasheet.propertyTypes && (
              <li>
                <span>TIPO DE IMÓVEL</span>
                {launch.datasheet.propertyTypes.join(', ')}
              </li>
            )}

            {launch.datasheet.parkingSpaces && launch.datasheet.parkingSpaces[0] !== 0 && (
              <li>
                <span>VAGAS DE GARAGEM</span>
                {launch.datasheet.parkingSpaces.length === 0 && 'Sem vagas'}

                {launch.datasheet.parkingSpaces.length === 1 &&
                  `${launch.datasheet.parkingSpaces} vaga${launch.datasheet.parkingSpaces > 1 ? 's' : ''}`
                }

                {launch.datasheet.parkingSpaces.length === 2 && launch.datasheet.parkingSpaces[0] === launch.datasheet.parkingSpaces[1] &&
                  `${launch.datasheet.parkingSpaces[0]} vaga${launch.datasheet.parkingSpaces[0] > 1 ? 's' : ''}`
                }

                {launch.datasheet.parkingSpaces.length === 2 && launch.datasheet.parkingSpaces[0] !== launch.datasheet.parkingSpaces[1] &&
                  `${launch.datasheet.parkingSpaces[0]} - ${launch.datasheet.parkingSpaces[1]} vagas`
                }
              </li>
            )}

            {launch.datasheet.floorPlans && (
              <li>
                <span>PLANTAS</span>
                {launch.datasheet.floorPlans.join(', ')}
              </li>
            )}

            {launch.datasheet.condominium && (
              <li>
                <span>CONDOMÍNIO</span>
                {launch.datasheet.condominium}
              </li>
            )}

            {launch.datasheet.area && launch.datasheet.area[0] !== 0 && (
              <li>
                <span>METRAGEM TOTAL</span>
                {launch.datasheet.area.length === 1 &&
                  `${launch.datasheet.area} m²`
                }

                {launch.datasheet.area.length === 2 && launch.datasheet.area[0] !== launch.datasheet.area[1] &&
                  `${launch.datasheet.area[0]} - ${launch.datasheet.area[1]} m²`
                }
              </li>
            )}

            {launch.datasheet.suites && launch.datasheet.suites[0] !== 0 && (
              <li>
                <span>SUÍTES</span>
                {launch.datasheet.suites.length === 1 &&
                  `${launch.datasheet.suites} suíte${launch.datasheet.suites > 1 ? 's' : ''}`
                }

                {launch.datasheet.suites.length === 2 && launch.datasheet.suites[0] !== launch.datasheet.suites[1] &&
                  `${launch.datasheet.suites[0]} - ${launch.datasheet.suites[1]} suítes`
                }

                {launch.datasheet.suites.length === 2 && launch.datasheet.suites[0] === launch.datasheet.suites[1] &&
                  `${launch.datasheet.suites[0]} suíte${launch.datasheet.suites[0] > 1 ? 's' : ''}`
                }
              </li>
            )}
          </DataSheetList>
        </DataSheetContainer>

        {incorporation && (
          <CompanyContainer>
            <div>

              <CompanyTitleGroup>
                {incorporation?.name}
              </CompanyTitleGroup>

              <CompanyDescriptionGroup>
                {incorporation?.about}
              </CompanyDescriptionGroup>
            </div>

            <img src={incorporation?.image} alt={incorporation?.name} />
          </CompanyContainer>
        )}

        <AdReportContainer>
          <p>
            Você notou alguma inconsistência neste anúncio? Se assim for, você pode nos avisar na {' '}
            <Link to={`/ad-report?adId=${realEstate.listingId}`}>
              página de denúncia
            </Link>
            .
          </p>
        </AdReportContainer>

        <CTAWidget onClick={handleOpenContactFormModal} />
        <Footer />
      </Container>
    </>
  );
}

const properties = {
  duration: 0,
  transitionDuration: window.innerWidth < Number(720) ? 200 : 400,
  slidesToShow: window.innerWidth < Number(720) ? 1 : window.innerWidth < Number(1180) ? 2 : 3,
  slidesToScroll: 1,
  autoplay: false,
  infinite: true,
  indicators: true,
  arrows: window.innerWidth < Number(736) ? false : true,
  canSwipe: window.innerWidth > Number(736) ? false : true,
  prevArrow: (
    <button type="button" aria-label="previous" className={"bt-new-slide"}>
      <svg width="11" height="19" viewBox="0 0 11 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11 16.7675L4.2011 9.5L11 2.2325L8.9069 0L0 9.5L8.9069 19L11 16.7675Z" fill="#7B7B7B" />
      </svg>
    </button>
  ),
  nextArrow: (
    <button type="button" aria-label="next" className={"bt-new-slide-next"}>
      <svg width="11" height="19" viewBox="0 0 11 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 16.7675L6.7989 9.5L0 2.2325L2.0931 0L11 9.5L2.0931 19L0 16.7675Z" fill="#7B7B7B" />
      </svg>

    </button>
  ),
};
