import React, { PureComponent } from "react";
import styled from "styled-components";
import propTypes from "prop-types";
import { Slide } from "react-slideshow-image";
import Tabs, { TYPES as TAB_TYPES } from "./tabs/Tabs";

import { shadower, mqDesktop } from "../../../styled/helpers/helpers";

import Map from "../../../components/map/Map";
import VideoPlayer from "../../../components/video-player/VideoPlayer";
import StreetView from "../../../components/street-view";
import SliderFullScreen from "../../../components/image-slider/SliderFullScreen";

import { CarouselPresentation } from "../../../components/carousel-presentation";
import { TYPES as IMAGE_TYPES } from "../../../models/image/image.model";

/*
  window
*/

class Presentation extends PureComponent {
  constructor(props) {
    super(props);

    this.mapRef = React.createRef();

    const { innerWidth } = window;
    this.state = {
      isFullScreanSlide: false,
      fullScreenUrl: "",
      concurrentImageSlides: innerWidth > 768 ? 3 : 1,
      activeTab: TAB_TYPES.IMAGES,
      imageTypes: [],
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowResizeEvent);
    this.verifyLengthImages();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowResizeEvent);
  }

  componentDidUpdate(lastProps) {
    const { id } = this.props;
    if (lastProps.id !== id) {
      this.setState((state) => ({
        ...state,
        activeTab: TAB_TYPES.IMAGES,
      }));
    }
  }

  handleWindowResizeEvent = () => {
    const { innerWidth } = window;
    this.setState((state) => ({
      ...state,
      concurrentImageSlides: innerWidth > 768 ? 0 : 1,
    }));
  };

  handleTabClick = (activeTab) => {
    this.setState(
      (state) => ({
        ...state,
        activeTab,
      }),
      () => {
        if (activeTab === TAB_TYPES.MAP) {
          this.setMarkers();
        }
      }
    );
  };

  verifyLengthImages = () => {
    let { images, adInfo } = this.props;

    const PANORAMA = images.filter(({ type }) => type === IMAGE_TYPES.PANORAMA);

    const SLIDES_IMAGES = images.filter(({ type }) => type === IMAGE_TYPES.SLIDES);

    let FLOOR_PLAN = [];

    if (adInfo.listing === 'Lançamento') {
      FLOOR_PLAN = images.filter(({ type }) => type === IMAGE_TYPES.FLOOR_PLAN);
    }

    let imageTypes = ["IMAGES", "VIDEO", "MAP", "STREET_VIEW"];

    if (PANORAMA.length > 0) {
      imageTypes.push("PANORAMA");
    }

    if (FLOOR_PLAN.length > 0) {
      imageTypes.push("FLOOR_PLAN");
    }

    if (SLIDES_IMAGES.length > 0) {
      imageTypes.push("SLIDES");
    }

    this.setState({ imageTypes });
  };

  setMarkers = () => {
    const { id, surroundingPlaces, address } = this.props;

    this.mapRef.current.setMarkers({
      realEstates: [
        {
          id,
          latitude: address.geolocation.latitude,
          longitude: address.geolocation.longitude,
          highlight: true,
        },
      ],
      surroundingPlaces: surroundingPlaces.map((sp) => {
        const mapped = {
          ...sp,
          latitude: sp.geolocation.latitude,
          longitude: sp.geolocation.longitude,
        };
        delete mapped.geolocation;
        return mapped;
      }),
    });
  };

  handleClickImage = (each) => {
    this.setState((prevState) => ({
      ...prevState,
      fullScreenUrl: each.replace('550', '1024'),
      isFullScreanSlide: true,
    }));
  };

  handleCloseImageModal = () => {
    this.setState((prevState) => ({
      ...prevState,
      isFullScreanSlide: false,
      fullScreenUrl: "",
    }));
  };

  render() {
    const { address, images, video, seo } = this.props;

    const images_slide = images
      .filter(({ type }) => type === IMAGE_TYPES.PICTURE)
      .map((image) => image.url);

    const images_slide_1024 = images
      .filter(({ type }) => type === IMAGE_TYPES.PICTURE)
      .map((image) => image.url.replace('550', '1024'));

    const floor_plan_slide = images
      .filter(({ type }) => type === IMAGE_TYPES.FLOOR_PLAN)
      .map((image) => image.url);

    const floor_plan_slide_1024 = images
      .filter(({ type }) => type === IMAGE_TYPES.FLOOR_PLAN)
      .map((image) => image.url.replace('550', '1024'));

    const slides_images_slide = images.filter(({ type }) => type === IMAGE_TYPES.SLIDES)
      .map((image) => image.url);

    const slides_images_slide_1024 = images.filter(({ type }) => type === IMAGE_TYPES.SLIDES)
      .map((image) => image.url.replace('550', '1024'));

    const { activeTab } = this.state;

    const tabsSettings = { video };

    return (
      <Wrapper>
        {
          <>
            <ContentWrapper>
              {activeTab === TAB_TYPES.IMAGES && (
                <div className={"dad-carousel"}>
                  <Slide {...properties}>
                    {images_slide.map((each, index) => (
                      <div
                        key={index}
                        style={style}
                        onClick={() => this.handleClickImage(each)}
                      >
                        <img
                          src={each}
                          style={styleImg}
                          alt={`${seo.title} - Foto ${index + 1}`}
                          title={`Imagem ${index + 1} - ${seo.title}`}
                        />
                      </div>
                    ))}
                  </Slide>
                </div>
              )}
              {activeTab === TAB_TYPES.FLOOR_PLAN && (
                <div className={"dad-carousel"}>
                  <Slide {...properties}>
                    {floor_plan_slide.map((each, index) => (
                      <div
                        key={index}
                        style={style}
                        onClick={() => this.handleClickImage(each)}
                      >
                        <img
                          src={each}
                          style={styleImg}
                          alt={`${seo.title} - Planta Baixa ${index + 1}`}
                          title={`Planta Baixa ${index + 1} - ${seo.title}`}
                        />
                      </div>
                    ))}
                  </Slide>
                </div>
              )}
              {activeTab === TAB_TYPES.MAP && (
                <Map
                  ref={this.mapRef}
                  onLoad={this.setMarkers}
                  defaultLocation={{
                    latitude: address.geolocation.latitude,
                    longitude: address.geolocation.longitude,
                  }}
                  options={{
                    draggable: true,
                    zoom: 18,
                  }}
                />
              )}
              {activeTab === TAB_TYPES.PANORAMA && (
                <CarouselPresentation
                  images={images
                    .filter(({ type }) => type === IMAGE_TYPES.PANORAMA)
                    .map((image) => image.url)}
                />
              )}
              {activeTab === TAB_TYPES.VIDEO && (
                <PVideoPlayer {...tabsSettings} />
              )}
              {activeTab === TAB_TYPES.STREET_VIEW && (
                <StreetView
                  defaultLocation={{
                    latitude: address.geolocation.latitude,
                    longitude: address.geolocation.longitude,
                  }}
                />
              )}

              {activeTab === TAB_TYPES.SLIDES && (
                <div className={"dad-carousel"}>
                  <Slide {...properties}>
                    {slides_images_slide.map((each, index) => (
                      <div
                        key={index}
                        style={style}
                        onClick={() => this.handleClickImage(each)}
                      >
                        <img
                          src={each}
                          style={styleImg}
                          alt={`${seo.title} - Slide ${index + 1}`}
                          title={`Slide ${index + 1} - ${seo.title}`}
                        />
                      </div>
                    ))}
                  </Slide>
                </div>
              )}

              {this.state.isFullScreanSlide &&
                activeTab == TAB_TYPES.IMAGES && (
                  <SliderFullScreen
                    images={images_slide_1024}
                    show={this.state.isFullScreanSlide}
                    handle={this.handleCloseImageModal}
                    currentURL={this.state.fullScreenUrl}
                  />
                )}

              {this.state.isFullScreanSlide &&
                activeTab == TAB_TYPES.FLOOR_PLAN && (
                  <SliderFullScreen
                    images={floor_plan_slide_1024}
                    show={this.state.isFullScreanSlide}
                    handle={this.handleCloseImageModal}
                    currentURL={this.state.fullScreenUrl}
                  />
                )}

              {this.state.isFullScreanSlide &&
                activeTab == TAB_TYPES.SLIDES && (
                  <SliderFullScreen
                    images={slides_images_slide_1024}
                    show={this.state.isFullScreanSlide}
                    handle={this.handleCloseImageModal}
                    currentURL={this.state.fullScreenUrl}
                  />
                )}
            </ContentWrapper>
            <Tabs
              {...tabsSettings}
              active={activeTab}
              onClick={this.handleTabClick}
              removeTab={this.state.imageTypes}
              hasSlides={slides_images_slide}
            />
          </>
        }
      </Wrapper>
    );
  }
}

const style = {
  textAlign: "center",
  height: "300px",
};
const styleImg = {
  width: "100%",
  height: "100%",
};

const properties = {
  duration: 3000,
  transitionDuration: window.innerWidth < Number(720) ? 200 : 400,
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 720,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
  autoplay: true,
  indicators: true,
  canSwipe: false,
  indicators: false,
  prevArrow: (
    <button type="button" aria-label="previous" className={"bt-new-slide"}>
      <svg width="46" height="34" viewBox="0 0 46 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_63_12)">
          <path d="M46 17C46 7.61116 38.3888 0 29 0C19.6112 0 12 7.61116 12 17C12 26.3888 19.6112 34 29 34C38.3888 34 46 26.3888 46 17Z" fill="white" fill-opacity="0.9" />
          <path d="M33 23.7675L26.2011 16.5L33 9.2325L30.9069 7L22 16.5L30.9069 26L33 23.7675Z" fill="#7B7B7B" />
        </g>
        <defs>
          <clipPath id="clip0_63_12">
            <rect width="34" height="34" fill="white" transform="translate(12)" />
          </clipPath>
        </defs>
      </svg>
    </button>
  ),
  nextArrow: (
    <button type="button" aria-label="next" className={"bt-new-slide-next"}>
      <svg width="46" height="34" viewBox="0 0 46 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_63_13)">
          <path d="M34 17C34 7.61116 26.3888 0 17 0C7.61116 0 0 7.61116 0 17C0 26.3888 7.61116 34 17 34C26.3888 34 34 26.3888 34 17Z" fill="white" fill-opacity="0.9" />
          <path d="M12 23.7675L18.7989 16.5L12 9.2325L14.0931 7L23 16.5L14.0931 26L12 23.7675Z" fill="#7B7B7B" />
        </g>
        <defs>
          <clipPath id="clip0_63_13">
            <rect width="34" height="34" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </button>
  ),
};

Presentation.propTypes = {
  id: propTypes.string,
  highlight: propTypes.bool,
  images: propTypes.arrayOf(
    propTypes.shape({
      url: propTypes.string,
    })
  ),
  address: propTypes.object,
  surroundingPlaces: propTypes.array,
};

Presentation.defaultProps = {
  address: {
    geolocation: {},
  },
  images: [],
  surroundingPlaces: [],
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 300px;
  min-height: 300px;
  z-index: 0;

  ${mqDesktop`
    height: 325px;
    min-height: 325px;
  `}
`;

const ContentWrapper = styled.div`
  min-height: 250px;
  ${shadower({ direction: "bottom" })}
  ${mqDesktop`
    min-height: 300px;
  `}
`;

const PVideoPlayer = styled(VideoPlayer).attrs({
  options: {
    controls: true,
  },
})`
  margin: 0 auto;

  ${mqDesktop`
    & > div {
      width: calc(100% - 240px) !important;
      max-width: 700px;
    }
  `}
`;

export default Presentation;
