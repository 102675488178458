import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  text-decoration: none;
  padding: 0.25rem 0.5rem;

  .mapView {
    font-size: 13pt;

    span {
      margin-left: 20px;
    }
  }

  a {
    display: flex;
    gap: 0.25rem;
    text-decoration: none;
    color: #2b2b2b;
    font-weight: 700;
    font-size: 11pt;
    align-items: center;

    svg {
      color: #2748C2;
    }
  }
`

 

