import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { CircularProgress } from '@material-ui/core';
import { toast } from 'react-toastify';

import billingLeadsService from '../../services/leads/billing-leads.service';
import userService from '../../services/user/user.service';

import { addMaskCellphone } from '../../utils/add-masks';
import domEventsUtil from '../../utils/dom-events/dom-events.util';
import pathConstants from '../../constants/paths';
import eventsConstants from '../../constants/events';

import {
  Container,
  FilterContainer,
  SearchKeyFilter,
  FilterOptionsGroup,
  FilterButton,
  LeadsContainer,
  LeadsGroup,
  LastMonthsContainer,
  LastMonthItem,
  LoadingContainer,
  ExportLeadsButton,
  CSVDownload,
  PriceGroup
} from './styles';

const customStyle = {
  control: base => ({
    ...base,
    border: '1px solid #999',
    boxShadow: 'none',
    background: 'transparent',

  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: 16,
  }),
  input: (provided, state) => ({
    ...provided,
    fontSize: 16,
  }),
}

export function BillingLeadsContent() {
  const [previousPeriodsOptions, setPreviousPeriodsOptions] = useState([]);
  const [monthOptionSelected, setMonthOptionSelected] = useState(null);

  const [monthSelectedLabel, setMonthSelectedLabel] = useState('');

  const [leadsData, setLeadsData] = useState([]);
  const [lastMonthsData, setLastMonthsData] = useState([]);

  const [isHandleFilter, setIsHandleFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [totalLeads, setTotalLeads] = useState(0);
  const [totalPrice, setTotalPrice] = useState('');
  const [averagePrice, setAveragePrice] = useState('');

  const [dataInCSV, setDataInCSV] = useState("");

  async function getLeads({ date, monthLabel, isFilterFetching = false }) {
    try {
      if (isFilterFetching) {
        setIsLoading(true);
      } else {
        domEventsUtil.dispatch({
          name: eventsConstants.LOADING_ANIMATION,
          params: { show: true }
        });
      }

      const token = userService.retrieve().token;

      const { status, data } = await billingLeadsService.getLeads({ date, page: 1, token });

      if (status !== 200) {
        throw new Error('Não foi possível buscar dados dos leads!');
      }

      setLeadsData(data.items);
      setMonthSelectedLabel(monthLabel);
      setTotalLeads(data.pagination.total);
      setTotalPrice(data.totalPrice);
      setAveragePrice(data.averagePrice);
    } catch (error) {
      toast.error('Não foi possível buscar dados dos leads', {
        autoClose: 3000,
      });
    } finally {
      setIsHandleFilter(false);

      if (isFilterFetching) {
        setIsLoading(false);
      } else {
        domEventsUtil.dispatch({
          name: eventsConstants.LOADING_ANIMATION,
          params: { show: false }
        });
      }
    }
  }

  useEffect(() => {
    async function getLeadsResources() {
      try {
        domEventsUtil.dispatch({
          name: eventsConstants.LOADING_ANIMATION,
          params: { show: true }
        });

        const userType = userService.retrieve().userType;

        if (userType < 3) {
          return window.open('/', '_self');
        }

        const token = userService.retrieve().token;

        const { status, data: leadsBillingResources } = await billingLeadsService.getLeadsResources({ token });

        if (status !== 200) {
          throw new Error('Não foi possível buscar dados do filtro!');
        }

        const { previousPeriods, lastMonths } = leadsBillingResources;

        const previousPeriodsOptionsSerialized = previousPeriods.map(item => {
          return {
            label: `Ano de ${item.year}`,
            options: item.months.map(({ month, monthName, year }) => {
              return {
                value: `${month}/${year}`,
                label: `${monthName} de ${year}`,
              }
            }),
          }
        });

        const currentMonth = {
          value: lastMonths[0].month,
          label: `${lastMonths[0].monthName} de ${lastMonths[0].month.split('/')[1]}`
        };

        setPreviousPeriodsOptions(previousPeriodsOptionsSerialized);
        setMonthOptionSelected(currentMonth);
        setLastMonthsData(lastMonths.reverse());
        setMonthSelectedLabel(currentMonth.label);

        getLeads({ date: currentMonth.value, monthLabel: currentMonth.label });
      } catch (error) {
        toast.error('Não foi possível buscar dados do filtro', {
          autoClose: 3000,
        });
      } finally {
        domEventsUtil.dispatch({
          name: eventsConstants.LOADING_ANIMATION,
          params: { show: false }
        });
      }
    }

    getLeadsResources();
  }, []);

  function handleSubmitFilter() {
    setIsHandleFilter(true);
    setIsLoading(true);

    getLeads({
      date: monthOptionSelected.value,
      monthLabel: monthOptionSelected.label,
      isFilterFetching: true,
    });
  }

  function handleChangeMonthOption(option) {
    setMonthOptionSelected(option);
  }

  async function handleExportLeads() {
    try {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: true }
      });

      const token = userService.retrieve().token;

      const { status, data } = await billingLeadsService.generateLeadsReportCSV({ date: monthOptionSelected.value, token });

      if (status !== 200) {
        throw new Error('Não foi possível exportar os leads!');
      }

      setDataInCSV(data);

      document.getElementById('download-csv').click();
    } catch (err) {
      toast.error('Não foi possível exportar os leads!', {
        autoClose: 3000,
      });
    } finally {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: false }
      });
    }
  }

  return (
    <Container>
      {dataInCSV && (
        <CSVDownload
          href={`data:text/csv;charset=utf-8,${encodeURI(dataInCSV)}`}
          download={`Relatório de leads do Koort - ${monthOptionSelected.label}.csv`}
          id="download-csv"
        >
          download
        </CSVDownload>
      )}

      <LastMonthsContainer>
        {lastMonthsData.map((item, index) => (
          <LastMonthItem key={item.month} isCurrent={index === 2}>
            <h3>
              <span>{item.month.split('/')[1]}</span>
              {item.monthName}
            </h3>

            <strong>{item.total}</strong>
          </LastMonthItem>
        ))}
      </LastMonthsContainer>

      <FilterContainer>
        <FilterOptionsGroup>
          <SearchKeyFilter>
            <label>Escolha o mês:</label>

            <Select
              className="month-react-select-container"
              onChange={handleChangeMonthOption}
              options={previousPeriodsOptions}
              value={monthOptionSelected}
              clearable
              placeholder="Selecione"
              noOptionsMessage={() => 'Sem opções'}
              styles={customStyle}
            />
          </SearchKeyFilter>
        </FilterOptionsGroup>


        <FilterButton type="button" disabled={isHandleFilter} onClick={handleSubmitFilter}>
          {!isHandleFilter && !isLoading && "Buscar"}

          {isHandleFilter && isLoading && <CircularProgress color="#FFFFFF" size={18} />}
        </FilterButton>
      </FilterContainer>

      {isLoading ? (
        <LoadingContainer>
          <CircularProgress size={32} color="primary" />
        </LoadingContainer>
      ) : (
        <LeadsContainer>
          {monthSelectedLabel && (
            <>
              <h2>
                {totalLeads > 0 ? `Exibindo ${totalLeads} ${totalLeads === 1 ? 'lead' : 'leads'} - ${monthSelectedLabel}`
                  : `Não há leads - ${monthSelectedLabel}`}

                <ExportLeadsButton
                  type="button"
                  disabled={totalLeads === 0}
                  onClick={handleExportLeads}
                >
                  exportar leads
                </ExportLeadsButton>
              </h2>

            <PriceGroup>
              <strong>
                Valor total referente ao mês: <br />
                <span>{totalPrice.includes('R$') ? totalPrice : 'Não calculado'}</span>
              </strong>

              <strong>
                Valor médio por lead: <br />
                <span>{averagePrice.includes('R$') ? averagePrice : 'Não calculado'}</span>
              </strong>
            </PriceGroup>
            </>
          )}

          {leadsData.map((item, index) => (
            <LeadsGroup key={index}>
              <h3>
                {String(item.date.day).padStart(2, '0')}/{String(item.date.month).padStart(2, '0')}/{item.date.year}
              </h3>
              <ul>
                {item.leads.map((lead) => (
                  <li key={lead.id}>
                    <strong>{lead.name}</strong>
                    <p>{lead.email}</p>
                    <p>{addMaskCellphone(lead.phone).replace('+55 ', '')}</p>

                    <a
                      href={`${pathConstants.AD_DETAIL}/${lead.realEstateSlug}`}
                      target='_blank'
                      rel="noreferrer noopener"
                    >
                      {lead.realEstateSlug}
                    </a>
                  </li>
                ))}
              </ul>
            </LeadsGroup>
          ))}
        </LeadsContainer>
      )}
    </Container>
  );
}
