import styled from 'styled-components';
import { ellipsis } from 'polished';
import { PhotoSizeSelectSmall } from 'styled-icons/material/PhotoSizeSelectSmall';
import { Bed } from 'styled-icons/boxicons-regular/Bed';
import { Heart as HeartOutline } from 'styled-icons/fa-regular/Heart';
import { Heart as HeartFull } from 'styled-icons/fa-solid/Heart';

import { BaseLabel } from '../../styled/labels/labels';
import { iconBaseStyle, pointer, mqTablet } from '../../styled/helpers/helpers';
import { COLOR_2, COLOR_7 } from '../../styled/variables/variables';
import { BaseButton } from '../../styled/buttons/buttons';

export const Wrapper = styled.div`
  margin: 5px 10px;
  ${pointer}
  width: 240px;
  position: relative;
  height: fit-content;
  align-self: flex-start;
`;

export const WrapperLoading = styled.div`
  margin: 10px;
  margin-bottom: 20px;
  ${pointer}
  width: 240px;
  height: 310px;
  align-self: flex-start;
  background-image: url("${process.env.PUBLIC_URL}/imgs/load.gif");
  background-repeat: no-repeat;
  background-size: 60px;
  background-position: 50% 50%;
  background-color: rgba(0,0,0,0.2);
  opacity: 0.5;
`

export const InfoWrapper = styled.div`
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
`;

export const Label = styled(BaseLabel)`
  ${ellipsis('100%')}
  font-weight: 300;
  align-self: center;
  font-size: 12px;
`;

export const City = styled(Label)`
  font-size: 22px;
  font-weight: bold;

  ${mqTablet`
    font-size: 18px;
  ` }
`;

export const Neighborhood = styled(Label)`
  font-size: 18px;
  margin-bottom: 20px;

  ${mqTablet`
    font-size: 16px;
  ` }
`;

export const InlineWrapper = styled.div`
  align-self: center;
  display: flex;
  width: fit-content;
  margin-bottom: 10px;
`;

export const Area = styled(Label)`
  margin-right: 10px;
  font-size: 16px;
  ${mqTablet`
    font-size: 14px;
  ` }
`;

export const Bedrooms = styled(Area)`
  margin-right: 0;
`;

export const M2Icon = styled(PhotoSizeSelectSmall)`
  ${iconBaseStyle}
  height: 17px;
  align-self: center;
  margin-right: 5px;
`;

export const BedroomsIcon = styled(M2Icon).attrs({
  as: Bed,
})``;

export const Price = styled(City)`
  ${mqTablet`
    font-size: 16px;
  ` }
`;

export const FavoriteButton = styled(BaseButton)`
  background-color: transparent;
  width: fit-content;
  height: 35px;
  display: flex;
  justify-content: center;
  position: absolute;
  left: 175px;
  z-index: 1;
`;

export const HeartOutlineIcon = styled(HeartOutline)`
  ${iconBaseStyle}
  color: ${COLOR_2};
  height: 25px;
  width: 25px;
  position: absolute;
  align-self: center;

`;

export const HeartFullIcon = styled(HeartOutlineIcon).attrs({
  as: HeartFull,
})`
color: ${COLOR_7};
height: 25px;
width: 25px;
`;

export const DefaultImagePropertyList = styled.img`
  height: 145.66px;

  @media (max-width: 1079px) {
    height: 158.91px;
  }
`;
