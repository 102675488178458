
import React, { PureComponent } from 'react';
import styled from 'styled-components';

import { MaskedBaseInput, masks } from '../../../../styled/inputs/inputs';
import { mqTablet } from '../../../../styled/helpers/helpers';
import validationUtil from '../../../../utils/validation/validation.util';
import { BaseLabel } from '../../../../styled/labels/labels';

class MainInfoEnterprise extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      fieldsHasFilled: false,
      form: {
        bedrooms: props.bedrooms,
        bedrooms2: props.bedrooms2,
        bathrooms: props.bathrooms,
        bathrooms2: props.bathrooms2,
        suites: props.suites,
        suites2: props.suites2,
        parkingSpaces: props.parkingSpaces,
        parkingSpaces2: props.parkingSpaces2,
        area: props.area,
        area2: props.area2,
        totalArea: props.totalArea,
        totalArea2: props.totalArea2
      },
      showErrorsFor: {},
    };
  }

  handleFormChange = event => {
    const { name, value } = event.target;

    this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        [name]: value,
      },
    }));
  };

  handleInputBlur = event => {
    const { name } = event.target;

    this.setState(state => ({
      ...state,
      showErrorsFor: {
        ...state.showErrorsFor,
        [name]: true,
      },
    }));
  };

  render() {
    const { showInvalids, isEditing, populate } = this.props;
    const { form, showErrorsFor, fieldsHasFilled } = this.state;

    if (isEditing) {
      if (!!populate.bedrooms && !!populate.bedrooms2 &&
        !!populate.bathrooms && !!populate.bathrooms2 &&
        !!populate.suites && !!populate.suites2 &&
        !!populate.parkingSpaces && !!populate.parkingSpaces2 &&
        !!populate.area && !!populate.area2 &&
        !!populate.totalArea && !!populate.totalArea2 &&
        !fieldsHasFilled) {
        this.setState(prevState => ({
          ...prevState,
          fieldsHasFilled: true,
          form: {
            ...prevState.form,
            bedrooms: populate.bedrooms,
            bedrooms2: populate.bedrooms2,
            bathrooms: populate.bathrooms,
            bathrooms2: populate.bathrooms2,
            suites: populate.suites,
            suites2: populate.suites2,
            parkingSpaces: populate.parkingSpaces,
            parkingSpaces2: populate.parkingSpaces2,
            area: populate.area,
            area2: populate.area2,
            totalArea: populate.totalArea,
            totalArea2: populate.totalArea2
          }
        }))
      }
    }

    return (
      <Wrapper onChange={this.handleFormChange}>
        <SubtitlePropertyFeature>
          Especifique abaixo as configurações dos imóveis do empreendimento.
        </SubtitlePropertyFeature>
        <div style={{ flexDirection: 'column', width: "100%" }}>
          <LabelInputPropertyFeature>
            N° de Quartos
          </LabelInputPropertyFeature>
          <TabletInliner>
            <div style={{ flexDirection: 'column', width: "100%", marginRight: 15 }}>
              <div className={'form-floating'}>
                <BedroomsInput
                  defaultValue={form.bedrooms}
                  id={'bedrooms'}
                  name={'bedrooms'}
                  mask={masks.integer()}
                  onBlur={this.handleInputBlur}
                  className={'form-control'}
                  style={{ 'width': '100%' }}
                  isInvalid={(() => {
                    if (!showErrorsFor.bedrooms && !showInvalids) return;
                    return !validationUtil.number({
                      min: 0,
                      value: form.bedrooms,
                    });
                  })()}
                />
                <label htmlFor="bedrooms" className="form-label">De</label>
              </div>
            </div>
            <div style={{ flexDirection: 'column', width: "100%" }}>
              <div className={'form-floating'}>
                <BedroomsInput
                  defaultValue={form.bedrooms2}
                  id={'bedrooms2'}
                  name={'bedrooms2'}
                  mask={masks.integer()}
                  onBlur={this.handleInputBlur}
                  className={'form-control'}
                  style={{ 'width': '100%' }}
                  isInvalid={(() => {
                    // if (!showErrorsFor.bedrooms && !showInvalids) return;
                    // return !validationUtil.number({
                    //   min: 0,
                    //   value: form.bedrooms2,
                    // });
                  })()}
                />
                <label htmlFor="bedrooms2" className="form-label">Até</label>
              </div>
            </div>
          </TabletInliner>
        </div>
        <div style={{ flexDirection: 'column', width: "100%", marginTop: 16 }}>
          <LabelInputPropertyFeature>
            N° de Banheiros
          </LabelInputPropertyFeature>
          <TabletInliner>
            <div style={{ flexDirection: 'column', width: "100%", marginRight: 15 }}>
              <div className={'form-floating'}>
                <Bathrooms
                  defaultValue={form.bathrooms}
                  mask={masks.integer()}
                  id={'bathrooms'}
                  name={'bathrooms'}
                  className={'form-control'}
                  style={{ 'width': '100%' }}
                  onBlur={this.handleInputBlur}
                  isInvalid={(() => {
                    if (!showErrorsFor.bathrooms && !showInvalids) return;
                    return !validationUtil.number({
                      min: 0,
                      value: form.bathrooms,
                    });
                  })()}
                />
                <label htmlFor="bathrooms" className="form-label">De</label>
              </div>
            </div>
            <div style={{ flexDirection: 'column', width: "100%" }}>
              <div className={'form-floating'}>
                <Bathrooms
                  defaultValue={form.bathrooms2}
                  mask={masks.integer()}
                  id={'bathrooms2'}
                  name={'bathrooms2'}
                  className={'form-control'}
                  style={{ 'width': '100%' }}
                  onBlur={this.handleInputBlur}
                  isInvalid={(() => {
                    // if (!showErrorsFor.bathrooms && !showInvalids) return;
                    // return !validationUtil.number({
                    //   min: 0,
                    //   value: form.bathrooms2,
                    // });
                  })()}
                />
                <label htmlFor="bathrooms2" className="form-label">Até</label>
              </div>
            </div>
          </TabletInliner>
        </div>
        <div style={{ flexDirection: 'column', width: "100%", marginTop: 16 }}>
          <LabelInputPropertyFeature>
            N° de Suítes
          </LabelInputPropertyFeature>
          <TabletInliner>
            <div style={{ flexDirection: 'column', width: "100%", marginRight: 15 }}>
              <div className={'form-floating'}>
                <SuitesInput
                  defaultValue={form.suites}
                  mask={masks.integer()}
                  id={'suites'}
                  name={'suites'}
                  className={'form-control'}
                  style={{ 'width': '100%' }}
                  onBlur={this.handleInputBlur}
                  isInvalid={(() => {
                    // if(!showErrorsFor.suites && !showInvalids) return;
                    // return !validationUtil.number({
                    //   min: 0,
                    //   value: form.suites,
                    // });
                  })()}
                />
                <label className={'form-label'} htmlFor={'suites'}>De</label>
              </div>
            </div>
            <div style={{ flexDirection: 'column', width: "100%" }}>
              <div className={'form-floating'}>
                <SuitesInput
                  defaultValue={form.suites2}
                  mask={masks.integer()}
                  id={'suites2'}
                  name={'suites2'}
                  className={'form-control'}
                  style={{ 'width': '100%' }}
                  onBlur={this.handleInputBlur}
                  isInvalid={(() => {
                    // if(!showErrorsFor.suites && !showInvalids) return;
                    // return !validationUtil.number({
                    //   min: 0,
                    //   value: form.suites,
                    // });
                  })()}
                />
                <label className={'form-label'} htmlFor={'suites2'}>Até</label>
              </div>
            </div>
          </TabletInliner>
        </div>
        <div style={{ flexDirection: 'column', width: "100%", marginTop: 16 }}>
          <LabelInputPropertyFeature>
            N° de Vagas
          </LabelInputPropertyFeature>
          <TabletInliner>
            <div style={{ flexDirection: 'column', width: "100%", marginRight: 15 }}>
              <div className={'form-floating'}>
                <ParkingSpacesInput
                  defaultValue={form.parkingSpaces}
                  mask={masks.integer()}
                  onBlur={this.handleInputBlur}
                  className={'form-control'}
                  style={{ 'width': '100%' }}
                  id={'parkingSpaces'}
                  name={'parkingSpaces'}
                  isInvalid={(() => {
                    // if(!showErrorsFor.parkingSpaces && !showInvalids) return;
                    // return !validationUtil.number({
                    //   min: 0,
                    //   value: form.parkingSpaces,
                    // });
                  })()}
                />
                <label className={'form-label'} htmlFor={'parkingSpaces'}>De</label>
              </div>
            </div>
            <div style={{ flexDirection: 'column', width: "100%" }}>
              <div className={'form-floating'}>
                <ParkingSpacesInput
                  defaultValue={form.parkingSpaces2}
                  mask={masks.integer()}
                  onBlur={this.handleInputBlur}
                  className={'form-control'}
                  style={{ 'width': '100%' }}
                  id={'parkingSpaces2'}
                  name={'parkingSpaces2'}
                  isInvalid={(() => {
                    // if(!showErrorsFor.parkingSpaces && !showInvalids) return;
                    // return !validationUtil.number({
                    //   min: 0,
                    //   value: form.parkingSpaces,
                    // });
                  })()}
                />
                <label className={'form-label'} htmlFor={'parkingSpaces2'}>Até</label>
              </div>
            </div>
          </TabletInliner>
        </div>
        <div style={{ flexDirection: 'column', width: "100%", marginTop: 16 }}>
          <LabelInputPropertyFeature>
            Área útil (m²)
          </LabelInputPropertyFeature>
          <TabletInliner>
            <div style={{ flexDirection: 'column', width: "100%", marginRight: 15 }}>
              <div className={'form-floating'}>
                <AreaInput
                  defaultValue={form.area}
                  mask={masks.integer()}
                  id={'area'}
                  name={'area'}
                  className={'form-control'}
                  style={{ 'width': '100%' }}
                  onBlur={this.handleInputBlur}
                  isInvalid={(() => {
                    if (!showErrorsFor.area && !showInvalids) return;
                    return !validationUtil.number({
                      min: 0,
                      value: form.area,
                    });
                  })()}
                />
                <label className={'form-label'} htmlFor={'area'}>De</label>
              </div>
            </div>
            <div style={{ flexDirection: 'column', width: "100%" }}>
              <div className={'form-floating'}>
                <AreaInput
                  defaultValue={form.area2}
                  mask={masks.integer()}
                  id={'area2'}
                  name={'area2'}
                  className={'form-control'}
                  style={{ 'width': '100%' }}
                  onBlur={this.handleInputBlur}
                  isInvalid={(() => {
                    // if (!showErrorsFor.area && !showInvalids) return;
                    // return !validationUtil.number({
                    //   min: 0,
                    //   value: form.area2,
                    // });
                  })()}
                />
                <label className={'form-label'} htmlFor={'area2'}>Até</label>
              </div>
            </div>
          </TabletInliner>
        </div>
        <div style={{ flexDirection: 'column', width: "100%", marginTop: 16 }}>
          <LabelInputPropertyFeature>
            Área total (m²)
          </LabelInputPropertyFeature>
          <TabletInliner>
            <div style={{ flexDirection: 'column', width: "100%", marginRight: 15 }}>
              <div className={'form-floating'}>
                <TotalAreaInput
                  defaultValue={form.totalArea}
                  mask={masks.integer()}
                  onBlur={this.handleInputBlur}
                  id={'totalArea'}
                  name={'totalArea'}
                  className={'form-control'}
                  style={{ 'width': '100%' }}
                  isInvalid={(() => {
                    // if(!showErrorsFor.totalArea && !showInvalids) return;
                    // return !validationUtil.number({
                    //   min: 0,
                    //   value: form.totalArea,
                    // });
                  })()}
                />
                <label className={'form-label'} htmlFor={'totalArea'}>De</label>
              </div>
            </div>
            <div style={{ flexDirection: 'column', width: "100%" }}>
              <div className={'form-floating'}>
                <TotalAreaInput
                  defaultValue={form.totalArea2}
                  mask={masks.integer()}
                  onBlur={this.handleInputBlur}
                  id={'totalArea2'}
                  name={'totalArea2'}
                  className={'form-control'}
                  style={{ 'width': '100%' }}
                  isInvalid={(() => {
                    // if(!showErrorsFor.totalArea && !showInvalids) return;
                    // return !validationUtil.number({
                    //   min: 0,
                    //   value: form.totalArea,
                    // });
                  })()}
                />
                <label className={'form-label'} htmlFor={'totalArea2'}>Até</label>
              </div>
            </div>
          </TabletInliner>
        </div>
      </Wrapper>
    )
  }
}

const Wrapper = styled.form`
  margin-bottom: 50px;
`;

const TabletInliner = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  ${mqTablet`
    flex-direction: row;
  ` }
`;

const BedroomsInput = styled(MaskedBaseInput).attrs({
  placeholder: 'Nº quartos',
})`
  ${mqTablet`
    margin: 0;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  ` }
`;

const Bathrooms = styled(BedroomsInput).attrs({
  placeholder: 'Nº banheiros',
})``;

const SuitesInput = styled(BedroomsInput).attrs({
  placeholder: 'Nº suítes'
})``;

const ParkingSpacesInput = styled(BedroomsInput).attrs({
  placeholder: 'Nº vagas'
})``;

const AreaInput = styled(BedroomsInput).attrs({
  placeholder: 'Área útil (m²)'
})``;

const TotalAreaInput = styled(BedroomsInput).attrs({
  placeholder: 'Área total (m²)'
})``;

const Label = styled(BaseLabel)`
  margin-bottom: 5px;
  margin-top: 5px;
  font-weight: 600;
`;

const LabelInputPropertyFeature = styled.label`
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  opacity: 0.65;
  margin-bottom: 12px;
`;

const SubtitlePropertyFeature = styled.p`
  font-size: 14px;
  color: #000000;
  opacity: 0.65;
`;

export default MainInfoEnterprise;
