import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import userService from '../../../services/user/user.service';

import pathsConstants from '../../../constants/paths';

export function PrivateDashboardRoute({ component: Component, user, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => userService.retrieve().userType > 1 ?
        <Component {...props} />
        :
        <Redirect to={{
          pathname: pathsConstants.HOME,
          state: { from: props.location }
        }}
        />}
    />
  );
}
