import styled from 'styled-components';
import { Bed } from 'styled-icons/boxicons-regular/Bed';
import { Car } from 'styled-icons/boxicons-regular/Car';
import { Shower } from 'styled-icons/fa-solid/Shower';
import { Pets } from 'styled-icons/material/Pets';
import { PhotoSizeSelectSmall } from 'styled-icons/material/PhotoSizeSelectSmall';

import { iconBaseStyle, mqDesktop } from '../../../styled/helpers/helpers';
import { COLOR_BORDER } from '../../../styled/variables/variables';
import { BaseLabel } from '../../../styled/labels/labels';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  margin-bottom: -15px;
`;

export const IconWrapper = styled.div`
  width: 33%;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-self: center;
  height: 40px;
  justify-content: space-between;

  ${mqDesktop`
    width: fit-content;
    min-width: 90px;

    & + div {
      margin-left: 16px;
    }
  ` }
`;

export const Label = styled(BaseLabel)`
  font-size: 14px;
  align-self: center;
`;

export const BedIcon = styled(Bed)`
  ${iconBaseStyle}
  color: ${COLOR_BORDER};
  opacity: 0.9;
  align-self: center;
  height: 22px;
  margin-bottom: 3px;
`;

export const M2Icon = styled(BedIcon).attrs({
  as: PhotoSizeSelectSmall,
})`
  height: 20px;
`;

export const CarIcon = styled(BedIcon).attrs({
  as: Car,
})``;

export const ShowerIcon = styled(BedIcon).attrs({
  as: Shower,
})`
  height: 20px;
`;

export const PetIcon = styled(BedIcon).attrs({
  as: Pets,
})`
  height: 18px;
`;

export const Spacer = styled.div`
  flex-grow: 1;
  width: 1px;
`;
