import styled, { css } from 'styled-components';
import Modal from 'react-modal';

export const LeadsTableWrapper = styled.div`
  margin-top: 40px;
  border: 1px solid #EEF0F4;
  padding: 24px;
  border-radius: 8px;
`;

export const LeadsTableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 880px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const LeadsTableHeaderLeftBox = styled.div`
  > h3 {
    font-size: 18px;
    font-weight: 600;
  }

  > p {
    font-size: 14px;
    margin-top: 8px;
    margin-bottom: 24px;
  }
`;

export const LeadsDataEmptyText = styled.p`
  text-align: center;
`;

export const LeadsTableFilter = styled.div`
  border: 1px solid #EEF0F4;
  border-radius: 8px;
  padding: 24px;
  margin-top: 24px;
  margin-bottom: 24px;

  > span {
    font-size: 18px;
    font-weight: 700;

    display: flex;
    align-items: center;

    margin-bottom: 16px;

    > svg {
      margin-right: 8px;
    }
  }
`;

const FilterComponentBase = styled.div`
  label {
    font-weight: 500;
    margin-right: 8px;
  }

  select {
    background-color: transparent;

    transition: background-color .2s;

    &:hover {
      background-color: #EEF0F4;
    }
  }
`;

export const OrderByFilter = styled(FilterComponentBase)``;

export const WasContactedFilter = styled(FilterComponentBase)`
  margin-left: 24px;

  @media (max-width: 680px) {
    margin-left: 0;
  }
`;

export const PeriodFilter = styled.div`
  margin-top: 12px;

  label {
    font-weight: 500;
    margin-right: 8px;
  }

  > span {
    display: inline-block;
    margin-bottom: 4px;

    font-weight: 500;
  }

  > div {
    & .initialDate {
      margin-left: 16px;
    }

    & .finalDate {
      margin-left: 24px;

      @media (max-width: 680px) {
        margin-left: 16px;
      }
    }

    input {
      transition: background-color .2s;

      &:hover {
        background-color: #EEF0F4;
      }
    }
  }
`;

export const LeadsTableFilterGroup = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 680px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const LeadsTableFilterActionButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  margin-top: 38px;

  @media (max-width: 680px) {
    flex-direction: column;
    align-items: unset;
    justify-content: unset;
  }
`;

const LeadsTableFilterButtonBase = styled.button`
  padding: 8px;
  width: 40%;
  font-weight: 600;
  border-radius: 8px;

  transition: filter .2s;

  &:hover {
    filter: brightness(0.8);
  }

  @media (max-width: 680px) {
    width: 100%;

    & + button {
      margin-top: 16px;
    }
  }
`;

export const LeadsTableFilterSubmitButton = styled(LeadsTableFilterButtonBase)`
  background-color: #2B50D8;
  color: #FFFFFF;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
`;

export const LeadsTableFilterClearButton = styled(LeadsTableFilterButtonBase)`
  background-color: #D1CCC4;
`;

export const PeriodMessageError = styled.span`
  color: #dc3545;
  font-size: 14px;
  margin-top: 8px;
`;

export const LeadDetailsModal = styled(Modal).attrs({
  style: {
    content: {
      backgroundColor: 'transparent',
      border: 0,
      padding: 0,
      inset: 0,
      borderRadius: 0,
      overflowY: 'hidden'
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  }
})``;

export const LeadDetailsModalBody = styled.div`
  background: #FAFAFA;
  height: 80vh;
  width: 100vw;
  max-width: 600px;
  border-radius: 8px;
  border: 2px solid #EEF0F4;
  padding: 18px;
`;

export const LeadDetailsModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;

  h3 {
    font-size: 20px;
    font-weight: 700;
  }

  button {
    width: 42px;
    height: 42px;
    border: 1px solid #EEF0F4;
    border-radius: 8px;
    background-color: transparent;
    font-size: 0;
    margin-left: 16px;

    transition: background-color .2s;

    &:hover {
      background-color: #EEF0F4;
    }
  }
`;

export const LeadDetailsModalContent = styled.div`
  padding-top: 24px;
  padding-right: 16px;
  height: 68vh;
  overflow-y: scroll;
`;

export const LeadDetailsModalContactWrapper = styled.div`
  margin-bottom: 14px;

  label {
    font-size: 15px;
    margin-bottom: 8px;
    font-weight: 500;
    color: #666666;
  }
`;

export const LeadDetailsModalContactButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LeadDetailsModalContactButtonBase = styled.button`
  width: 48%;
  height: 54px;
  background-color: transparent;
  border-radius: 8px;
  border: 1px solid #EEF0F4;
  font-size: 16px;
  font-weight: 500;
  padding: 16px;
  color: #444444;

  display: flex;
  align-items: center;
  justify-content: center;

  gap: 8px;

  transition: background-color .2s;

  &:hover {
    background-color: #EEF0F4;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export const LeadDetailsModalContactMarkAsDone = styled(LeadDetailsModalContactButtonBase)`
  ${(props) => props.maskAsDone && css`
    background-color: #EEF0F4;
  `}
`;

export const LeadDetailsModalContactMarkAsUndone = styled(LeadDetailsModalContactButtonBase)`
   ${(props) => props.maskAsUndone && css`
    background-color: #EEF0F4;
  `}
`;

