import React from 'react';
import propTypes from 'prop-types';

import pathsConstants from '../../../../constants/paths';

import {
  Wrapper,
  AOProfilePicture,
  Type,
  Label,
  ProfilePictureListing,
  ProfileInfo,
  Title,
  Text
} from './styles';

const AdOwner = ({
  name,
  type,
  ddd,
  number,
  email,
  isListing,
  pictureUrl,
  id,
  isIncorporation,
  incorporationInfo
}) => {

  const handleNavigateToProfile = () => {
    window.open(`${pathsConstants.PROFILE_SALE}?id=${isIncorporation ? incorporationInfo.accountId : id}`, '_blank');
  }

  return (
    <Wrapper>
      {!isListing &&
        <>
          <AOProfilePicture pictureUrl={isIncorporation ? incorporationInfo.image : pictureUrl} onClick={handleNavigateToProfile } />
          <Type>{type}</Type>
          <Label>{name ? name : incorporationInfo.name}</Label>

          {number &&
            <Label>{`Tel: (${ddd}) ${number}`}</Label>
          }

          <Label>{email}</Label>
        </>
      }
      {isListing &&
        <>
          <ProfilePictureListing pictureUrl={isIncorporation ? incorporationInfo.image : pictureUrl} onClick={handleNavigateToProfile } />
          <ProfileInfo>
          <Type>{type}</Type>
          <Label>{name ? name : incorporationInfo.name}</Label>
          </ProfileInfo>
        </>
      }

    </Wrapper>
  )
};

AdOwner.propTypes = {
  name: propTypes.string,
  type: propTypes.string,
  ddd: propTypes.string,
  number: propTypes.string,
  email: propTypes.string,
  pictureUrl: propTypes.string,
};

AdOwner.defaultProps = {};

export default AdOwner;
