import styled from 'styled-components';

export const Header = styled.header`
  .header-button {
    line-height: 0;
    position: absolute;
    left: 16px;
    top: 18px;
    background-color: transparent;

    transition: filter .2s;

    &:hover {
      filter: brightness(0.9);
    }
  }

  > span {
    display: flex;
    align-items: center;
    gap: 8px;
    line-height: 24px;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 500;

    > img {
      width: 26px;
      height: 26px;
    }
  }
`;

export const Form = styled.form`
  margin-top: 16px;
  margin-bottom: 16px;
  width: 100%;

  > textarea {
    min-width: 304px;
    width: 100%;
    min-height: 120px;
    padding: 8px;
    border-radius: 8px;
    font-size: 15px;
    resize: none;

    @media (max-width: 360px) {
      min-width: unset;
      width: 267px;
    }
  }

  > footer {
    display: flex;
    gap: 8px;
    margin-top: 8px;

    .footer-button {
      padding: 8px;
      border-radius: 8px;
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      cursor: pointer;
      background-color: #FFFFFF;
      color: #222222;
      font-weight: 500;

      transition: filter .2s;

      &:not(:disabled):hover {
        filter: brightness(0.9);
      }

      &:disabled {
        cursor: not-allowed;
        opacity: 0.7;
      }
    }
  }
`;
