import browserImageCompression from "browser-image-compression";

/*
  global
  Promise
  Image
*/

const compress = async (
  file,
  options = {
    maxSizeMB: 5, // (default: Number.POSITIVE_INFINITY)
    maxWidthOrHeight: 1920, // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
    useWebWorker: true, // optional, use multi-thread web worker, fallback to run in main-thread (default: true)
    maxIteration: 10,
  }
) => {
  const result = await browserImageCompression(file, options);
  return result;
};

const exists = async (src) =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = resolve;
    img.onerror = reject;
    img.src = src;
  });

const transformImageLink = (url, size) => {
  const SIZES = {
    small: "316",
    medium: "550",
    large: "1024",
  };

  // return url.replace('{width}', SIZES[size]);

  if (typeof url == "string") {
    return url.replace("{width}", SIZES[size]);
  } else if (url && url !== "string") {
    return url.url.replace("{width}", SIZES[size]);
  } else {
    return "";
  }
};

export default {
  transformImageLink,
  compress,
  exists,
};
