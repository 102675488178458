import httpClient from '../../clients/http/http.client';

let origin = 'koort';

(function getOrigin() {
  const params = (new URL(document.location)).searchParams;

  const originParam = params.get('origin');

  if (!originParam) {
    return;
  }

  origin = originParam;
})()

const getLeadsFromCompanyBroker = async ({
  responsibleId,
  page = 1,
  size = 10,
  wasContacted = false,
  orderBy = "desc",
  initialDate = '',
  finalDate = '',
  token,
}) => {
  let path = `/leads/${origin}/from/${responsibleId}?page=${page}&size=${size}&wasContacted=${wasContacted}&orderBy=${orderBy}`;

  if (initialDate && finalDate) {
    path = `${path}&period=${initialDate},${finalDate}`;
  }

  const response = await httpClient.get({ path, token });

  return response;
};

const getLeadsFromLoggedUser = async ({
  searchLeadsFromMyCompanyAndBrokers,
  responsibleId,
  page = 1,
  size = 10,
  wasContacted = false,
  orderBy = "desc",
  initialDate = '',
  finalDate = '',
  token,
}) => {
  const leadsFromMyCompanyAndBrokersPath = `/leads/${origin}/from/${responsibleId}/from-the-company`;
  const leadsFromMyCompanyPath = `/leads/${origin}/from/me`;

  let path = `?page=${page}&size=${size}&wasContacted=${wasContacted}&orderBy=${orderBy}`;

  if (searchLeadsFromMyCompanyAndBrokers) {
    path = `${leadsFromMyCompanyAndBrokersPath}${path}`;
  } else {
    path = `${leadsFromMyCompanyPath}${path}`;
  }

  if (initialDate && finalDate) {
    path = `${path}&period=${initialDate},${finalDate}`;
  }

  const response = await httpClient.get({ path, token });

  return response;
}

const getLeadsFromRealEstate = async ({
  realEstateId,
  page = 1,
  size = 10,
  wasContacted = false,
  orderBy = "desc",
  initialDate = '',
  finalDate = '',
  token
}) => {
  let path = `/leads/koort/from/realestates/${realEstateId}?page=${page}&size=${size}&wasContacted=${wasContacted}&orderBy=${orderBy}`;

  if (initialDate && finalDate) {
    path = `${path}&period=${initialDate},${finalDate}`;
  }

  const response = await httpClient.get({ path, token });

  return response;
}

const getLeadsStatistics = async ({
  referenceType,
  referenceId,
  token,
}) => {
  // referenceType = company | realestate | realtor
  // referenceId = id da empresa | id do imóvel | id do corretor

  const path = `/leads/from/${origin}?referenceType=${referenceType}&referenceId=${referenceId}`;

  const response = await httpClient.get({ path, token });

  return response;
}

const assignAnnotationToLead = async ({ leadId, annotation, token }) => {
  const path = `/leads/${leadId}/add-annotation`;

  const response = await httpClient.patch({
    path,
    params: {
      content: annotation,
    },
    token,
  });

  return response;
}

const markContactAsDone = async ({ leadId, token }) => {
  const path = `/leads/${leadId}/contact`;

  const response = await httpClient.patch({ path, token });

  return response;
}

const markContactAsUndone = async ({ leadId, token }) => {
  const path = `/leads/${leadId}/uncontact`;

  const response = await httpClient.patch({ path, token });

  return response;
}


const getUserSettingsNotifications = async ({ token }) => {
  const path = '/leads/settings';

  const response = await httpClient.get({ path, token });

  return response;
}

const updateUserSettingsNotifications = async ({
  receiveLeadsMode,
  token
}) => {
  const path = '/leads/settings/receive-leads-by';

  const response = await httpClient.patch({
    path,
    params: {
      receiveLeadsBy: receiveLeadsMode,
    },
    token,
  });

  return response;
}

const getLeadsOriginsFromLoggedUser = async ({
  brokerId = null,
  token
}) => {
  let path = '/leads/origins/from/me';

  if (brokerId !== null) {
    path = `/leads/origins/from/${brokerId}`;
  }

  const response = await httpClient.get({
    path,
    token,
  });

  return response;
}

const enableC2SIntegration = async ({
  email,
  token
}) => {
  const path = '/leads/settings/c2s/enable';

  const response = await httpClient.patch({
    path,
    params: {
      email,
    },
    token,
  });

  return response;
}

const disableC2SIntegration = async ({ token }) => {
  const path = '/leads/settings/c2s/disable';

  const response = await httpClient.patch({ path, token });

  return response;
}

const getKualifiqueOptions = async ({ token }) => {
  const path = '/leads/kualifique/options';

  const response = await httpClient.get({ path, token });

  return response;
}

const kualifiqueLead = async ({ leadId, kualifiqueOptionKey, token }) => {
  const path = `/leads/${leadId}/kualifique`;

  const params = {
    kualifiqueOptionKey,
  };

  const response = await httpClient.patch({ path, params, token });

  return response;
}

const getLeadById = async ({ leadId, token }) => {
  const path = `/leads/${leadId}`;

  const response = await httpClient.get({ path, token });

  return response;
}

export default {
  getLeadsFromCompanyBroker,
  getLeadsFromLoggedUser,
  getLeadsFromRealEstate,
  getLeadsStatistics,
  assignAnnotationToLead,
  markContactAsDone,
  markContactAsUndone,
  getUserSettingsNotifications,
  updateUserSettingsNotifications,
  getLeadsOriginsFromLoggedUser,
  enableC2SIntegration,
  disableC2SIntegration,
  getKualifiqueOptions,
  kualifiqueLead,
  getLeadById,
}
