import React, {useState} from 'react';
import styled from 'styled-components';

import { Modal, Button } from 'react-bootstrap'

import { transitions } from "polished";
import { BaseLabel } from "../../../styled/labels/labels";
import { mqTablet, mqDesktop } from '../../../styled/helpers/helpers';
import Buttons from '../../dashboard/ads/card/buttons/Buttons';
import {
	backgrounder,
	pointer
} from "../../../styled/helpers/helpers";
import { BaseButton } from "../../../styled/buttons/buttons";
import { BaseInput } from "../../../styled/inputs/inputs";
import { SmallButton } from "../../../styled/buttons/buttons";
import pathsConstants from "../../../constants/paths";
import {
	COLOR_10,
	COLOR_1
} from "../../../styled/variables/variables";

import Checkbox from '../../../components/checkbox';

import queryStringUtil from '../../../utils/query-string/query-string.util';
import reportService from '../../../services/report/report.service';

/* window */

const Reports = ({
	user,
  adInfo,
  history
}) => {

  const [state, setState] = useState({
    seeReports: true,
    modalVisible: false,
    name: '',
    street: false,
    complement: false,
    streetNumber: false,
    unitNumber: false,
    area: false,
    city: false,
    neighborhood: false,
    state: false,
    zipCode: false,
    location:false,
    bathrooms: false,
    bedrooms: false,
    suites: false,
    typeOfGuarantee: false,
    video: false,
    yearBuilt: false,
    floor: false,
    complement: false,
    code: false,
    logo: false,
    constructionStatus: false,
    buildings: false,
    main: false,
    rental: false,
    sale: false,
    condo: false,
    iptu: false,
    parkingSpaces: false,
    floors:false,
    highlights:false,
    Name: false,
  });

  const openReport = reportId => {
    const href = history.createHref({
      pathname: pathsConstants.REPORT_REAL_ESTATE,
      search: queryStringUtil.stringify(null, {
        reportId
      })
    });

    history.push(href);
  };

  const handleFormChange = event => {
    const { name, value, type, checked, id } = event.target;
    setState(prev => ({
      ...prev,
      form: {
        ...prev.form,
        [name]: type === 'checkbox' ? checked : value,
      },
      [name]: type === 'checkbox' ? checked : value,

    }));
  };

  const handleFormSubmit = async () => {
    try {
      await reportService.create({
        realEstateId: adInfo.id,
        token: user.token,
        obj: state
      });
      window.location.reload()
    } catch (error) {
      console.error(error);
      return;
    }
  };

  const renderCheckBoxNear = (quantity) => {
    var indents = [];
    let i;
    for (i = 1; i <= quantity; i++) {
      indents.push(
        <CheckboxContainer key={i}>
          <LabelBox>Proximidade{i}?</LabelBox>
          <CheckboxReport
            name={'near'+i}
            checked={state['near'+i]}
          />
          <LabelBox>Proximidade{i + 1}?</LabelBox>
          <CheckboxReport
            name={'near'+(i+1)}
            checked={state['near'+(i+1)]}
          />
          <LabelBox>Proximidade{i + 2}?</LabelBox>
          <CheckboxReport
            name={'near'+(i+2)}
            checked={state['near'+(i+2)]}
          />
          <LabelBox>Proximidade{i + 3}?</LabelBox>
          <CheckboxReport
            name={'near'+(i+3)}
            checked={state['near'+(i+3)]}
          />
        </CheckboxContainer>
      )
      i = i + 3;
    }
    return indents
  }

  const handleModal = () => {
    setState((state) => ({
      ...state,
      modalVisible: !state.modalVisible
    }));
  }


  return (
    <Wrapper>
      <AddButton onClick={() => { handleModal() }}>
        Adicionar Relatório
      </AddButton>
      {state.modalVisible &&
        <CheckboxContainer style={{ justifyContent: 'center', alignItems: 'center' }}>
          <Modal
            show={state.modalVisible}
            onHide={() => { handleModal() }}
            dialogClassName="modal-90w"
            centered
            scrollable
            style={{
              position: "absolute",
              top: 410,
              left: '5%',
              backgroundColor: 'white',
              width: '90%',
            }}
            aria-labelledby="styling-title"
          >
            <Modal.Dialog scrollable>
              <Modal.Header style={{ alignItems: 'center', justifyContent: 'center', marginTop: 10 }}>
                <Modal.Title style={{ alignSelf: 'center', textAlign: 'center', fontSize: 22, color: 'black', fontWeight: 'bold' }}>
                  Relatório
                  </Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ maxHeight: 'calc(75vh - 210px)', overflowY: 'auto', marginTop: 20,'display':'flex', justifyContent:'center' }}>
                <Form
                  onChange={handleFormChange}
                  onSubmit={handleFormSubmit}
                  style={{ marginLeft: 10 }}
                >
                  <NameInput
                    name='name'
                    value={state.name}
                  />
                  <DescriptionInput
                    name='description'
                    value={state.description}
                  />
                  <TitleInput
                    name='title'
                    value={state.title}
                  />
                  <SubTitleInput
                    name='subtitle'
                    value={state.subtitle}
                  />
                  <Titles>Proximidades</Titles>
                  <div>
                    {renderCheckBoxNear(12)}
                  </div>
                  <Titles>Endereço</Titles>
                  <CheckboxContainer>
                    <LabelBox>Rua</LabelBox>
                    <CheckboxReport
                      name='street'
                      checked={state.street}

                    />
                    <LabelBox>Número da rua</LabelBox>
                    <CheckboxReport
                      name='streetNumber'
                      checked={state.streetNumber}
                    />
                    <LabelBox>Número da unidade</LabelBox>
                    <CheckboxReport
                      name='unitNumber'
                      checked={state.unitNumber}
                    />
                    <LabelBox>Complemento</LabelBox>
                    <CheckboxReport
                      name='complement'
                      checked={state.complement}
                    />
                    <LabelBox>Andar</LabelBox>
                    <CheckboxReport
                      name='floor'
                      checked={state.floor}
                    />
                  </CheckboxContainer>
                  <CheckboxContainer>
                    <LabelBox>Bairro</LabelBox>
                    <CheckboxReport
                      name='neighborhood'
                      checked={state.neighborhood}
                    />
                    <LabelBox>Cidade</LabelBox>
                    <CheckboxReport
                      name='city'
                      checked={state.city}
                    />
                    <LabelBox>Estado</LabelBox>
                    <CheckboxReport
                      name='state'
                      checked={state.state}
                    />
                    <LabelBox>CEP</LabelBox>
                    <CheckboxReport
                      name='zipCode'
                      checked={state.zipCode}
                    />
                    <LabelBox>Localização</LabelBox>
                    <CheckboxReport
                      name='location'
                      checked={state.location}
                    />
                  </CheckboxContainer>
                  <CheckboxContainer>
                    <LabelBox>Ano de construção</LabelBox>
                    <CheckboxReport
                      name='yearBuilt'
                      checked={state.yearBuilt}
                    />
                    <LabelBox>Banheiro</LabelBox>
                    <CheckboxReport
                      name='bathrooms'
                      checked={state.bathrooms}
                    />
                    <LabelBox>Quarto</LabelBox>
                    <CheckboxReport
                      name='bedrooms'
                      checked={state.bedrooms}
                    />
                    <LabelBox>Suite</LabelBox>
                    <CheckboxReport
                      name='suites'
                      checked={state.suites}
                    />
                    <LabelBox>Vagas</LabelBox>
                    <CheckboxReport
                      name='parkingSpaces'
                      checked={state.parkingSpaces}
                    />
                    <LabelBox>Área</LabelBox>
                    <CheckboxReport
                      name='area'
                      checked={state.area}
                    />
                  </CheckboxContainer>
                  <Titles>Desenvolvimento</Titles>
                  <CheckboxContainer>
                    <LabelBox>Código</LabelBox>
                    <CheckboxReport
                      name='code'
                      checked={state.code}
                    />
                    <LabelBox>Nome</LabelBox>
                    <CheckboxReport
                      name='name'
                      checked={state.Name}
                    />
                    <LabelBox>Logo</LabelBox>
                    <CheckboxReport
                      name='logo'
                      checked={state.logo}
                    />
                    <LabelBox>Status da construção</LabelBox>
                    <CheckboxReport
                      name='constructionStatus'
                      checked={state.constructionStatus}
                    />
                    <LabelBox>Prédios</LabelBox>
                    <CheckboxReport
                      name='buildings'
                      checked={state.buildings}
                    />
                    <LabelBox>Andares</LabelBox>
                    <CheckboxReport
                      name='floors'
                      checked={state.floors}
                    />
                    <LabelBox>Destaques</LabelBox>
                    <CheckboxReport
                      name='highligths'
                      checked={state.highligths}
                    />
                  </CheckboxContainer>
                  <Titles>Preço</Titles>
                  <CheckboxContainer>
                    <LabelBox>Principal</LabelBox>
                    <CheckboxReport
                      name='main'
                      checked={state.main}
                    />
                    <LabelBox>Venda</LabelBox>
                    <CheckboxReport
                      name='sale'
                      checked={state.sale}
                    />
                    <LabelBox>Aluguel</LabelBox>
                    <CheckboxReport
                      name='rental'
                      checked={state.rental}
                    />
                    <LabelBox>Condomínio</LabelBox>
                    <CheckboxReport
                      name='condo'
                      checked={state.condo}
                    />
                    <LabelBox>Iptu</LabelBox>
                    <CheckboxReport
                      name='iptu'
                      checked={state.iptu}
                    />
                  </CheckboxContainer>
                  <Titles>Outros</Titles>
                  <CheckboxContainer>
                    <LabelBox>Garantia</LabelBox>
                    <CheckboxReport
                      name='typeOfGuarantee'
                      checked={state.typeOfGuarantee}
                    />
                    <LabelBox>Vídeo</LabelBox>
                    <CheckboxReport
                      name='video'
                      checked={state.video}
                    />
                  </CheckboxContainer>
                </Form>
              </Modal.Body>
              <Modal.Footer style={{ marginTop: 20, 'display': 'flex', justifyContent: 'center', paddingBottom: 20 }}>
                <SmallButton onClick={() => { handleModal() }} >Fechar</SmallButton>
                <SmallButton onClick={()=> { handleFormSubmit() }} >Enviar</SmallButton>
              </Modal.Footer>
            </Modal.Dialog>
          </Modal>
        </CheckboxContainer>
      }
		{adInfo.reports.map(r => (
            <ReportWrapper key={r._id}>
              <ReportInfo onClick={() => {openReport(r._id)}}>
                <ReportTitle>{r.report.title}</ReportTitle>
                <ReportDescription>{r.report.description}</ReportDescription>
              </ReportInfo>
              <Buttons report={r} user={user} onUpdate={()=>{window.location.reload()}}/>
            </ReportWrapper>
          ))
        }
	</Wrapper>
  );
}

const Wrapper = styled.div`
	flex:1;
  display: flex;
	max-width:365px;
  align-self:center;
  ${ mqTablet`
	max-width:980px;
    align-self:center;
    width: 100%;
  ` }
	${ mqDesktop`
	align-self:center;
  max-width: 1000px;
  heigth: 500px;
    ` }
  flex-direction: column;
  min-height: 400px;
`;

const PropertyLabel = styled(BaseLabel)`
  font-size: 12px;
  margin-bottom: 10px;
`;

const AddButton = styled(BaseButton)`
  width:180px;
  align-self:flex-end;
  background-color:#F68620;
  margin-bottom:15px;
`;

const ReportWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const ReportInfo = styled.div`
  background-color: ${COLOR_10};
  padding: 20px 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  ${pointer}

  ${mqTablet`
    ${transitions(["transform"], "ease .1s")}
    &:hover {
      transform: scale(1.03)
    }
  `}
`;

const Title = styled(PropertyLabel)`
  font-weight: bold;
  font-size: 20px;
`;
const ListTitle = styled(Title)`
  font-size: 16px;
  align-self: center;
`;
const ReportTitle = styled(ListTitle)`
  align-self: flex-start;
  font-size: 14px;
  color: ${COLOR_1};
`;

const ReportDescription = styled(PropertyLabel)`
  margin: 0;
`;


const Form = styled.form`
  max-width: 700px;
`;

const LabelBox = styled.p`
  margin-left:9px;
  margin-rigth:9px;
`;

const NameInput = styled(BaseInput).attrs({
  placeholder: 'Nome completo'
})`
  max-width: 700px;
`;

const DescriptionInput = styled(BaseInput).attrs({
  placeholder: 'Descrição'
})`
  max-width: 700px;
`;

const TitleInput = styled(BaseInput).attrs({
  placeholder: 'Título'
})`
  max-width: 700px;
`;

const SubTitleInput = styled(BaseInput).attrs({
  placeholder: 'Subtítulo'
})`
  max-width: 700px;
`;

const CheckboxReport = styled(Checkbox)`
  max-width: 100px;
  margin-left: 10px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  ${mqTablet`
   display: flex;
   flex-direction: row;
   margin-top: 10px;
  `}
`;

const Titles = styled.h4`
  margin-top: 5px;
`;




export default Reports;
