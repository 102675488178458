import React, { PureComponent } from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import { BaseInput, ErrorMessage } from '../../../../styled/inputs/inputs';

import validationUtil from '../../../../utils/validation/validation.util';

class Title extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      fieldsHasFilled: false,
      form: {
        title: props.title,
      },
      showErrorsFor: {},
    };
  }

  handleFormChange = event => {
    const { name, value } = event.target;

    this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        [name]: value,
      },
    }));
  };

  handleInputBlur = event => {
    const { name } = event.target;

    this.setState(state => ({
      ...state,
      showErrorsFor: {
        ...state.showErrorsFor,
        [name]: true,
      },
    }));
  };

  render() {
    const { showInvalids, isEditing, populate } = this.props;
    const { form, showErrorsFor, fieldsHasFilled } = this.state;

    if (isEditing) {
      if (!!populate && !fieldsHasFilled) {
        this.setState(prevState => ({
          ...prevState,
          fieldsHasFilled: true,
          form: {
            ...prevState.form,
            title: populate,
          }
        }));
      }
    }

    const isTitleValid = validationUtil.text({
      minLength: 5,
      maxLength: 50,
      value: form.title,
    });
    return (
      <Wrapper onChange={this.handleFormChange}>
        <div className="form-floating mb-3" style={{ marginTop: '15px' }}>
          <textarea
            className="form-control"
            placeholder="Título"
            id="title"
            name="title"
            value={this.state.form.title}
            onChange={(e) => this.setState({ title: e.target.value.length })}
            onBlur={this.handleInputBlur}
            isInvalid={(showErrorsFor.title || showInvalids) && !isTitleValid}
          />

          <label htmlFor="message">Título do anúncio</label>
        </div>
        {(showErrorsFor.title || showInvalids) && !isTitleValid && (
          <ErrorMessage>
            Títulos devem conter de 5 a 50 caracteres.
          </ErrorMessage>
        )}
      </Wrapper>
    );
  }
}

Title.propTypes = {
  title: propTypes.string,
};

Title.defaultProps = {};

const Wrapper = styled.form`
  margin-bottom: 10px;
`;

const Input = styled(BaseInput).attrs({
  name: 'title',
  placeholder: 'Título'
})``;

export default Title;
