import React from 'react';
import { Container } from 'react-bootstrap';
import { Route, Router, Switch } from 'react-router-dom';

import Tabs from '../../../components/tabs';
import { CommonUserProfilePage } from './page';

import pathsConstants from '../../../constants/paths';

import '../config-account.css';

import {
  Wrapper,
  NameWrapper,
  Name,
  Wrapper2
} from './styles';

export function CommonUserProfile({ history }) {
  const tabConfigs = [
    {
      label: 'Meu Perfil',
      path: pathsConstants.CONFIG_ACCOUNT_COMMON_USER_PROFILE,
    },
  ];

  return (
    <>
      <Wrapper>
        <Container>
          <NameWrapper>
            <Name>Configuração da conta</Name>
          </NameWrapper>
        </Container>
      </Wrapper>
      <Wrapper2>
        <Container>
          <div className={"nav_ul"}>
            <Tabs configs={tabConfigs} />
          </div>

          <Router history={history}>
            <Switch>
              <Route
                path={pathsConstants.CONFIG_ACCOUNT_COMMON_USER_PROFILE}
                render={props => <CommonUserProfilePage history={history} />}
              />
            </Switch>
          </Router>
        </Container>
      </Wrapper2>
    </>
  );
}
