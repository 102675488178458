import autoParse from 'auto-parse';

const conformify = (value) => autoParse(objToJson(value));

const isNullOrUndefined = (value) => value === undefined || value === null;

const isEqual = (obj1, obj2) => stringify(obj1) === stringify(obj2);

const keys = (obj) => Object.keys(obj);

const objToJson = (obj) => parse(stringify(obj));

const parse = (obj) => JSON.parse(obj);

const stringify = (obj) => JSON.stringify(obj);

const clean = (obj) => {
  keys(obj).forEach(key => {
    if (isNullOrUndefined(obj[key])) delete obj[key];
  });

  return obj;
};

export default {
  clean,
  conformify,
  isEqual,
  isNullOrUndefined,
  keys,
  objToJson,
  parse,
  stringify,
};
