
import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import { Bed } from 'styled-icons/boxicons-regular/Bed';
import { Car } from 'styled-icons/boxicons-regular/Car';
import { Shower } from 'styled-icons/fa-solid/Shower';
import { Pets } from 'styled-icons/material/Pets';
import { PhotoSizeSelectSmall } from 'styled-icons/material/PhotoSizeSelectSmall';

import { iconBaseStyle, mqDesktop } from '../../../../styled/helpers/helpers';
import { COLOR_3 } from '../../../../styled/variables/variables';
import { BaseLabel } from '../../../../styled/labels/labels';

/*
  global
*/

const Features = ({
  bedrooms,
  area,
  parkingSpaces,
  bathrooms,
  petAcceptance,
}) => {

  return (
    <Wrapper>
      <IconWrapper>
        <BedIcon />
        <Label>
          { bedrooms } quarto{ bedrooms > 1 ? 's' : '' }
        </Label>
      </IconWrapper>
      <IconWrapper>
        <M2Icon />
        <Label>{ area } m²</Label>
      </IconWrapper>
      <IconWrapper>
        <CarIcon />
        <Label>
          { parkingSpaces > 0 ?
            `${ parkingSpaces } vaga${ parkingSpaces > 1 ? 's' : '' }` :
            `Sem Vagas`
          }
        </Label>
      </IconWrapper>
      <IconWrapper>
        <ShowerIcon />
        <Label>
          { bathrooms } banheiro{ bathrooms > 1 ? 's' : '' }
        </Label>
      </IconWrapper>
      {/* <IconWrapper>
        <PetIcon />
        <Label>
          { petAcceptance ? 'Permitido' : 'Não' }
        </Label>
      </IconWrapper> */}
      <Spacer />
    </Wrapper>
  );
};

Features.propTypes = {
  bedrooms: propTypes.number.isRequired,
  area: propTypes.number.isRequired,
  parkingSpaces: propTypes.number.isRequired,
  bathrooms: propTypes.number.isRequired,
  petAcceptance: propTypes.bool.isRequired,
};

Features.defaultProps = {

};

const Wrapper = styled.div`
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  margin-bottom: -15px;
`;

const IconWrapper = styled.div`
  width: 33%;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-self: center;
  height: 40px;
  justify-content: space-between;

  ${ mqDesktop`
    flex-grow: 1;
    width: 1px;
  ` }
`;

const Label = styled(BaseLabel)`
  font-size: 12px;
  align-self: center;
`;

const BedIcon = styled(Bed)`
  ${ iconBaseStyle }
  color: ${ COLOR_3 };
  opacity: 0.9;
  align-self: center;
  height: 18px;
  margin-bottom: 3px;
`;

const M2Icon = styled(BedIcon).attrs({
  as: PhotoSizeSelectSmall,
})`
  height: 16px;
`;

const CarIcon = styled(BedIcon).attrs({
  as: Car,
})``;

const ShowerIcon = styled(BedIcon).attrs({
  as: Shower,
})`
  height: 16px;
`;

const PetIcon = styled(BedIcon).attrs({
  as: Pets,
})`
  height: 14px;
`;

const Spacer = styled.div`
  flex-grow: 1;
  width: 1px;

  ${ mqDesktop`
    display: none;
  ` }
`;

export default Features;
