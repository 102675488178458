/* eslint-disable no-undef */
/* eslint-disable react/prop-types */

import React, { PureComponent, useState, useEffect } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { transitions } from 'polished';
import { Container, Col, Row } from 'react-bootstrap';

import { BaseButton } from '../../styled/buttons/buttons';
import { BaseLabel } from '../../styled/labels/labels';
import { mqTablet, mqDesktop, backgrounder, pointer } from '../../styled/helpers/helpers';
import { COLOR_1, COLOR_2 } from '../../styled/variables/variables';
import './ad-log.css';

import NoResults from '../../../src/components/no-results';

import userService from '../../services/user/user.service';
import pathsConstants from '../../constants/paths';

const { PUBLIC_URL } = process.env;

const log = ({
  history
}) => {

  const [token, setToken] = useState();
  const [user, setUser] = useState();
  const getToken = async () => {
    let user;
    user = await userService.retrieve();

    if (user !== undefined) {
      setToken(user.token);
      setUser(user);
    } else {
      setToken(null)
      setUser(null);
    }
  }

  useEffect(() => {
    getToken();
  }, []);

  const handleRedirectionClick = path => {
    if (path === 'ad') {
      history.push({
        pathname: pathsConstants.AD_LOG_LEADS,
      })
    } else {
      window.location.reload();
    }
  }

  return (
    <>

      <Wrapper>
        <Container>
          <NameWrapper>
            <Name>Configuração da conta</Name>
            {/*<CRatingStars rating={ rating } />*/}
          </NameWrapper>
        </Container>
      </Wrapper>
      <Wrapper2>
        <Col md={12} xs={12} style={{ paddingTop: '1%' }}>
          <div className="table-responsive">
            <table className="table" >
              <tbody>
                <tr>
                  <th><h4 className={"h4_tittle"}>Total de Anúncios</h4></th>
                  <th><h4 className={"h4_tittle"}>Data</h4></th>
                  <th><h4 className={"h4_tittle"}>Hora</h4></th>
                  <th><h4 className={"h4_tittle"}>Novos</h4></th>
                  <th><h4 className={"h4_tittle"}>Atualizados</h4></th>
                  <th><h4 className={"h4_tittle"}>Inalterados</h4></th>
                  <th><h4 className={"h4_tittle"}>Status</h4></th>
                </tr>
              </tbody>
            </table>
          </div>
          <ContainerWrapper>
            <NoResults />
            <RowButton>
              <ButtonWrapper>
                <BackButton onClick={() => handleRedirectionClick("ad")}>
                  Voltar
                </BackButton>
              </ButtonWrapper>
            </RowButton>
          </ContainerWrapper>
        </Col>
      </Wrapper2>
    </>
  );
}

const Wrapper = styled.div`
 background-color: ${COLOR_1};
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  padding-top: 120px;

  * { position: relative; }
  &:before {
    content: "";
    ${backgrounder(`${PUBLIC_URL}/imgs/koort-texture-outline.svg`)};
    background-size: 800px;
    height: 100%;
    width: 100%;
    opacity: .3;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
  }

  ${mqTablet`
    &:before {
      background-size: 600px;
      background-repeat: repeat;
    }
  ` }
`;


const Wrapper2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items:center;
  align-self:center;
  background-color:white;
  /* height: 700px; */
  width: 100%;
  ${mqTablet`
  margin-left: calc(55% - 630px);
    width: 950px;
    padding-bottom: 20px
  ` };
    ${mqDesktop`
    width: 100%;
  ` };
`;

const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-bottom: 10px;
  width: 315px;
  margin-top: 50px;
  ${mqTablet`
    flex-grow: 1;
    width: 315px;
    order: 2;
    align-self: flex-start;
    margin-top: 50px;
  ` }
`;

const Name = styled(BaseLabel)`
  color: ${COLOR_2};
  align-self: center;
  font-weight: 700;
  margin-bottom: 10px;
  cursor: pointer;

  ${mqTablet`
    align-self: flex-start;
    font-size: 24px;
  ` }
`;

const ContainerWrapper = styled.div`
  padding-top: 120px;
`;

const RowButton = styled.div`
    width: 100%;
    height: 90px;
    left: 0px;
    padding-top: 25px;
    bottom: 0px;
    background-color: white;
    text-align: right;
    padding-left: 50%;
`;

const BackButton = styled(BaseButton).attrs({
  type: 'submit',
})`
  margin-right: 10px;
  flex-grow: 1;
  width: 1px;
  max-width: 200px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export default log;