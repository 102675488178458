import React, { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { InputBootstrap } from '../../components/form/input-bootstrap';
import { SelectBootstrap } from '../../components/form/select-bootstrap';
import { TextArea } from '../../components/form/textarea';

import userProfileService from '../../services/user-profile/user-profile.service'

import pathsConstants from '../../constants/paths';
import eventsConstants from '../../constants/events';
import domEventsUtil from '../../utils/dom-events/dom-events.util';

import {
    Wrapper,
    Title,
    Form,
    SendButton,
    LoadingContainer
} from './styles';


export const LoadingUserInfo = (props) => {

    const getProfile = async () => {
        domEventsUtil.dispatch({
            name: eventsConstants.LOADING_ANIMATION,
            params: { show: true }
        });
        let result;

        const user = JSON.parse(localStorage.getItem('user'));
        setTimeout(async () => {
            try {
                if (user) {
                    result = await userProfileService.getUserBasicProfile({
                        token: user.token,
                        userId: user._id,
                    });

                    localStorage.setItem("user", JSON.stringify(result));
                    window.location.href = pathsConstants.DASHBOARD
                }
            } catch (error) {
                console.log(error)
                // window.location.href = pathsConstants.HOME
                throw error;
            }

            finally {
                domEventsUtil.dispatch({
                    name: eventsConstants.LOADING_ANIMATION,
                    params: { show: false },
                });
            }
        },
            2000
        );
    }

    useEffect(() => {
        getProfile();
    }, []);

    return (

        <>
            <Wrapper>
                <LoadingContainer>
                    <h3>Carregando...</h3>
                    <p>Aguarde enquanto preparamos o seu dashboard</p>
                </LoadingContainer>
            </Wrapper>
        </>
    )
}
