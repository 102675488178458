
export const CHECKBOX_TYPE = 'checkbox';
export const RADIO_TYPE = 'radio';
export const SPECTRUM_TYPE = 'spectrum';

const filters = [
  {
    label: 'Área',
    name: 'area',
    type: SPECTRUM_TYPE,
    options: [
      {
        min: 10,
        max: 1000,
        step: 5,
        value: [ 10, 1000 ],
        prefix: '',
        postfix: 'm2',
      },
    ]
  },
  {
    label: 'Preço',
    name: 'mainPrice',
    type: SPECTRUM_TYPE,
    options: [
      {
        min: 0,
        max: 10000000,
        step: 1000,
        value: [ 0, 10000000 ],
        prefix: 'R$',
        postfix: '',
      },
    ]
  },
  {
    label: 'Uso',
    name: 'use',
    type: CHECKBOX_TYPE,
    options: [
      {
        label: 'Residencial',
        name: 0,
      },
      {
        label: 'Comercial',
        name: 1,
      },
    ],
  },
  // {
  //   label: 'Transação',
  //   name: 'transaction',
  //   type: RADIO_TYPE,
  //   options: [
  //     {
  //       label: 'Comprar',
  //       name: 0,
  //     },
  //     {
  //       label: 'Alugar',
  //       name: 1,
  //     },
  //   ]
  // },
  // {
  //   label: 'Quartos',
  //   name: 'bedrooms',
  //   type: CHECKBOX_TYPE,
  //   options: [
  //     {
  //       label: '1',
  //       name: 1,
  //     },
  //     {
  //       label: '2',
  //       name: 2,
  //     },
  //     {
  //       label: '3',
  //       name: 3,
  //     },
  //     {
  //       label: '4',
  //       name: 4,
  //     },
  //   ],
  // },
  {
    label: 'Banheiros',
    name: 'bathrooms',
    type: CHECKBOX_TYPE,
    options: [
      {
        label: '1',
        name: 1,
      },
      {
        label: '2',
        name: 2,
      },
      {
        label: '3',
        name: 3,
      },
      {
        label: '4',
        name: 4,
      },
    ],
  },
  {
    label: 'Suítes',
    name: 'suites',
    type: CHECKBOX_TYPE,
    options: [
      {
        label: '1',
        name: 1,
      },
      {
        label: '2',
        name: 2,
      },
      {
        label: '3',
        name: 3,
      },
      {
        label: '4',
        name: 4,
      },
    ],
  },
  {
    label: 'Vagas',
    name: 'parkingSpaces',
    type: CHECKBOX_TYPE,
    options: [
      {
        label: '1',
        name: 1,
      },
      {
        label: '2',
        name: 2,
      },
      {
        label: '3',
        name: 3,
      },
    ]
  },
  {
    label: 'Garantia',
    name: 'typeOfGuarantee',
    type: CHECKBOX_TYPE,
    conditions: [
      {
        category: 'transaction',
        option: 1,
      }
    ],
    options: [
      {
        label: 'Fiador',
        name: 0,
      },
      {
        label: 'Seguro-Fiança',
        name: 1,
      },
      {
        label: 'Título de Capitalização',
        name: 2,
      },
      {
        label: 'Carta de Fiança',
        name: 3,
      },
      {
        label: 'Depósito Caução',
        name: 4,
      },
    ]
  },
  {
    label: 'Características do Imóvel',
    name: 'features',
    type: CHECKBOX_TYPE,
    options: [
      {
        label: 'Ambientes Integrados',
        name: 3,
      },
      {
        label: 'Área de Serviço',
        name: 6,
      },
      {
        label: 'Copa',
        name: 23,
      },
      {
        label: 'Quarto / WC Empregada',
        name: 61,
      },
      {
        label: 'Despensa',
        name: 26,
      },
      {
        label: 'Lavabo',
        name: 44,
      },
      {
        label: 'Mezanino',
        name: 47,
      },
      {
        label: 'Terraço',
        name: 75,
      },
      {
        label: 'Varanda',
        name: 76,
      },
      {
        label: 'Mobiliado',
        name: 48,
      },
      {
        label: 'Móveis Planejados',
        name: 49,
      },
      {
        label: 'Próximo a Praia',
        name: 81,
      },
      {
        label: 'Reformado',
        name: 63,
      },
      {
        label: 'Vista Panorâmica',
        name: 79,
      },
      {
        label: 'Andar Inteiro',
        name: 4,
      },
      {
        label: 'Biblioteca',
        name: 10,
      },
      {
        label: 'Blindex',
        name: 12,
      },
      {
        label: 'Churrasqueira na Varanda',
        name: 17,
      },
      {
        label: 'Cobertura',
        name: 21,
      },
      {
        label: 'Escritório',
        name: 28,
      },
      {
        label: 'Hall de Entrada',
        name: 36,
      },
      {
        label: 'Home Theater',
        name: 39,
      },
      {
        label: 'Isolamento Acústico',
        name: 40,
      },
      {
        label: 'Isolamento Térmico',
        name: 41,
      },
      {
        label: 'Meio Andar',
        name: 46,
      },
      {
        label: 'Sala Íntima',
        name: 64,
      },
      {
        label: 'Pet',
        name: 1,
      },
    ]
  },
  {
    label: 'Acabamento',
    name: 'features',
    type: CHECKBOX_TYPE,
    options: [
      {
        label: 'Piso de Madeira',
        name: 51,
      },
      {
        label: 'Piso Laminado',
        name: 52,
      },
      {
        label: 'Porcelanato',
        name: 55,
      },
    ],
  },

  // SPLITTER
  {
    label: 'Características do Condomínio',
    name: 'features',
    type: CHECKBOX_TYPE,
    options: [
      {
        label: 'Ar Condicionado',
        name: 5,
      },
      {
        label: 'Armário de Cozinha',
        name: 7,
      },
      {
        label: 'Armário Embutido',
        name: 8,
      },
      {
        label: 'Banheira',
        name: 9,
      },
      {
        label: 'Churrasqueira',
        name: 16,
      },
      {
        label: 'Closet',
        name: 20,
      },
      {
        label: 'Cozinha Americana',
        name: 24,
      },
      {
        label: 'Hidromassagem',
        name: 38,
      },
      {
        label: 'Jardim de Inverno',
        name: 43,
      },
      {
        label: 'Estacionamento Coberto',
        name: 30,
      },
      {
        label: 'Elevador',
        name: 27,
      },
      {
        label: 'Estacionamento Visitantes',
        name: 32,
      },
      {
        label: 'Guarita ou Portaria',
        name: 35,
      },
      {
        label: 'Jardim',
        name: 42,
      },
      {
        label: 'Pista de Cooper',
        name: 53,
      },
      {
        label: 'Ciclovia',
        name: 19,
      },
      {
        label: 'Quadra de Squash',
        name: 58,
      },
      {
        label: 'Segurança Patrimonial',
        name: 70,
      },
      {
        label: 'Sistema de Incêndio',
        name: 72,
      },
      {
        label: 'Sistema de Vigilância',
        name: 73,
      },
      {
        label: 'Vestiário',
        name: 77,
      },
      {
        label: 'Acesso à Praia',
        name: 2,
      },
    ],
  },
  {
    label: 'Lazer',
    name: 'features',
    type: CHECKBOX_TYPE,
    options: [
      {
        label: 'Campo de Futebol',
        name: 14,
      },
      {
        label: 'Churrasqueira no Condomínio',
        name: 18,
      },
      {
        label: 'Espaço Gourmet',
        name: 29,
      },
      {
        label: 'Piscina',
        name: 50,
      },
      {
        label: 'Playground',
        name: 54,
      },
      {
        label: 'Quadra de Tênis',
        name: 59,
      },
      {
        label: 'Quadra Poliesportiva',
        name: 60,
      },
      {
        label: 'Salão de Festas',
        name: 65,
      },
      {
        label: 'Salão de Jogos',
        name: 66,
      },
      {
        label: 'Salão de Vídeo/Cinema',
        name: 67,
      },
      {
        label: 'Sauna',
        name: 68,
      },
    ],
  },
  {
    label: 'Serviços',
    name: 'features',
    type: CHECKBOX_TYPE,
    options: [
      {
        label: 'Academia',
        name: 0,
      },
      {
        label: 'Comércio / Serviços no Condomínio',
        name: 22,
      },
      {
        label: 'Estacionamento Rotativo',
        name: 31,
      },
      {
        label: 'Gás Encanado',
        name: 34,
      },
      {
        label: 'Hidroginástica',
        name: 37,
      },
      {
        label: 'Lavanderia Coletiva',
        name: 45,
      },
      {
        label: 'Porteiro 24 Horas',
        name: 56,
      },
      {
        label: 'Sala de Ginástica',
        name: 62,
      },
      {
        label: 'Segurança Interna',
        name: 69,
      },
      {
        label: 'Serviço de Transporte no Condomínio',
        name: 71,
      },
    ],
  },
  {
    label: 'Outros',
    name: 'features',
    type: CHECKBOX_TYPE,
    options: [
      {
        label: 'Bicicletário',
        name: 11,
      },
      {
        label: 'Bosque',
        name: 13,
      },
      {
        label: 'Children Care',
        name: 15,
      },
      {
        label: 'Depósito Privativo no Subsolo',
        name: 25,
      },
      {
        label: 'Estuda Permuta',
        name: 33,
      },
      {
        label: 'Primeira Locação',
        name: 57,
      },
      {
        label: 'Solarium',
        name: 74,
      },
      {
        label: 'Via Asfaltada',
        name: 78,
      },
      {
        label: 'Acessibilidade',
        name: 80,
      },
    ]
  },
];

export default filters;