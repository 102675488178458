import React, { PureComponent, Fragment } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import { BaseLabel } from '../../../styled/labels/labels';
import { mqTablet } from '../../../styled/helpers/helpers';
import { COLOR_2 } from '../../../styled/variables/variables';

import userService from '../../../services/user/user.service';

import pathsConstants from '../../../constants/paths';

const { AUTHENTICATION, AUTHENTICATION_PARAMS } = pathsConstants;

class Links extends PureComponent {
  constructor(props) {
    super(props);

    const user = userService.retrieve();
    this.state = {
      userToken: !!user ? user.token : '',
      authenticationStatus: !!user,
      userIsSubscriber: false,
    };
  }

  loadUserProfile = async () => {
    try {
      const data = await userService.getUserProfile({
        token: this.state.userToken
      });

      this.setState(prevState => ({
        ...prevState,
        userIsSubscriber: data.isSubscriber,
      }));
    } catch (err) {
      console.log(err)
    }
  }

  componentDidMount() {
    if (this.state.authenticationStatus) {
      this.loadUserProfile();
    }
  }

  render() {
    const { authenticationStatus, userIsSubscriber } = this.state;

    const list = [
      {
        key: 'about',
        path: `${pathsConstants.ABOUT_US}`,
        label: 'quem somos',
        ifAuth: true,
        ifNoAuth: true,
      },
      {
        key: 'contact',
        path: `${pathsConstants.CONTACT}`,
        label: 'contato',
        ifAuth: true,
        ifNoAuth: true,
      },
      {
        key: 'terms_and_conditions',
        path: `${pathsConstants.TERMS_AND_CONDITIONS}`,
        label: 'termos e condições',
        ifAuth: true,
        ifNoAuth: true,
      },
      {
        key: 'diversity_and_inclusion_policy',
        path: `${pathsConstants.DIVERSITY_AND_INCLUSION_POLICY}`,
        label: 'diversidade e inclusão',
        ifAuth: true,
        ifNoAuth: true,
      },
    ];

    return (
      <Wrapper>
        {list.map(({ key, path, label, ifAuth, ifNoAuth }, index) => {

          let show;
          if (ifAuth && ifNoAuth) show = true;
          else if (ifAuth && !ifNoAuth) {
            show = authenticationStatus;
          } else if (!ifAuth && ifNoAuth) {
            show = !authenticationStatus;
          }

          return (
            <Fragment key={key}>
              {show && (
                <Item
                  to={path}
                  show={String(!!show)}
                >
                  {label}
                </Item>
              )}
              {show && index < (list.length - 1) && <Divider />}
            </Fragment>
          );
        })}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  margin-bottom: 50px;

  ${mqTablet`
    flex-direction: row;
  ` }
`;

const Item = styled(BaseLabel).attrs({
  as: Link,
})`
  text-transform: uppercase;
  color: ${COLOR_2};
  opacity: 0.75;
  font-size: 14px;
  align-self: center;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  ${mqTablet`
    margin-right: 10px;
    margin-bottom: 0;
    font-size: 12px;

    &:last-child {
      margin-right: 0;
    }
  ` }

  ${({ show }) => !show && css`
    display: none;
  ` }
`;

const Divider = styled.div`
  display: none;

  ${mqTablet`
    display: unset;
    margin-right: 10px;
    align-self: center;
    height: 10px;
    width: 1px;
    background-color: ${COLOR_2};
  ` }
`;

export default Links;
