import styled from 'styled-components';



export const StyledWrapper = styled.div`

	ul {
		list-style: none;
		display: flex;
		flex-flow: row;
		justify-content: center;
	}

	.previous a, .next a {
		cursor: pointer;
		&:hover {
			text-decoration: underline;
			color: #2B50D8;
		}
	}

	.pagination-page-item {
		width: 25px;
		margin: 0 5px;

		a {
			border: solid 1px #000;
			display: block;
			text-align: center;
			cursor: pointer;

			&:hover {
				border-color: #2B50D8;
			}
		}

		&.selected a {
			cursor: default;
			background: #2B50D8;
			color: #fff;
			font-weight: bold;
		}
	}

`;