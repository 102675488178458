import React, { PureComponent, Fragment } from 'react';
import styled from 'styled-components';
import Select from 'react-select';

import { ErrorMessage } from '../../../../components/form/error-message';

import { BaseLabel } from '../../../../styled/labels/labels';

class AdditionalInfo extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      guaranteesUnformatted: [],
      guarantees: [],
      fieldsHasFilled: false,
    };
  }

  handleSelectChange = (arr, name) => {
    if (name === "guarantees") {
      this.setState(prevState => ({
        ...prevState,
        guaranteesUnformatted: arr,
        guarantees: arr !== null ?
          arr.map(item => item.value) : [],
      }));
    }
  }

  render() {
    const {
      showTypeOfGuarantees,
      isEditing,
      populate,
      guaranteesIsRequired = false,
    } = this.props;
    const { fieldsHasFilled } = this.state;

    if (isEditing) {
      if (populate.length > 0 && !fieldsHasFilled) {
        const guaranteesAdapted = populate.map(item => ({
          value: item,
          label: item
        }));

        this.setState(prevState => ({
          ...prevState,
          fieldsHasFilled: true,
          guaranteesUnformatted: guaranteesAdapted,
          guarantees: guaranteesAdapted.map(item => item.label),
        }))
      }
    }

    const customStyle = {
      control: base => ({
        ...base,
        border: '1px solid #999',
        boxShadow: 'none'
      }),
      option: (provided, state) => ({
        ...provided,
        fontSize: 16,
      }),
      input: (provided, state) => ({
        ...provided,
        fontSize: 25,
      }),
    }

    let options = {
      guarantee: []
    }

    guarantees.map((g, index) => {
      let el = { label: g, value: g }
      options.guarantee.push(el)
    })

    return (
      <Wrapper>
        {showTypeOfGuarantees && (
          <Fragment>
            <GroupTitle>
              Garantias aceitas
            </GroupTitle>
            <Select
              styles={customStyle}
              noOptionsMessage={() => 'Sem opções'}
              value={this.state.guaranteesUnformatted}
              onChange={(value) => {
                this.handleSelectChange(value, 'guarantees')
                this.props.onSelectItems(value);
              }}
              name="select-guarantees"
              id="select-guarantees"
              placeholder={'Selecione'}
              options={options.guarantee}
              isMulti={true}
              theme={theme => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary: 'none',
                },
              })}
            />

            {guaranteesIsRequired && <ErrorMessage message="Seleção obrigatória" />}
          </Fragment>
        )}
      </Wrapper>
    );
  }
}

const Wrapper = styled.form`
  margin-bottom: 50px;
`;

const GroupTitle = styled(BaseLabel)`
  font-family: Montserrat, "Open Sans", Helvetica, Arial, sans-serif !important;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 10px;
`;

const guarantees = [
  'Fiador',
  'Seguro-Fiança',
  'Título de Capitalização',
  'Carta de Fiança',
  'Depósito Caução',
];

export default AdditionalInfo;
