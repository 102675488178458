
import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';

import { BaseLabel } from '../../../styled/labels/labels';
import { mqDesktop } from '../../../styled/helpers/helpers';

export function TitleAndDescription({
  title,
  content,
  className,
}) {
  return (
  <Wrapper className={ className }>
    <Title>{ title }</Title>

    {!!content && content.map((item, index) => (
      <Content key={index}>{ item }</Content>
    ))}
  </Wrapper>
);
}

const Wrapper = styled.div`
  margin-bottom: 30px;
`;

const Title = styled(BaseLabel)`
  font-size: 14px;
  font-weight: 700;
  display: block;
  margin-bottom: 10px;
`;

const Content = styled(Title).attrs({
  as: 'p',
})`
  font-weight: 300;
  margin: 0;

  & + p {
    margin-top: 10px;
  }
`;
