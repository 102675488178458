import SweetScroll from 'sweet-scroll';

/*
  global
  window
  document
*/

export const DEFAULT_OPTIONS = {
  easing: 'easeInOutQuad',
  duration: 300,
};

const scrollToTop = (
  { container = window, options = {} } = {
    container: window,
  },
) => {
  const scroller = new SweetScroll(
    {
      ...DEFAULT_OPTIONS,
      offset: (window.innerHeight / 10) * -1,
      ...options,
    },
    container,
  );
  scroller.to(0);

  return scroller;
};

const scrollTo = ({ container = window, position = 0, options = {} }) => {
  const scroller = new SweetScroll(
    {
      ...DEFAULT_OPTIONS,
      offset: (window.innerHeight / 10) * -1,
      ...options,
    },
    container,
  );
  scroller.to(position);
  return scroller;
};

const scrollToElement = ({
  container = window,
  element,
  options = {},
}) => {
  if (typeof element === 'string') {
    element = document.getElementById(element);
  }
  if (!element) return;

  if (typeof container === 'string') {
    container = document.getElementById(container);
  }
  if (!container) return;

  const scroller = new SweetScroll(
    {
      ...DEFAULT_OPTIONS,
      offset: (window.innerHeight / 10) * -1,
      ...options,
    },
    container,
  );
  scroller.toElement(element);

  return scroller;
};

// The checker
const isElementVisible = (element) => {
  if (typeof element === 'string') {
    element = document.getElementById(element);
  }
  if (!element) return false;

  const scroll = window.scrollY || window.pageYOffset;
  const boundsTop = element.getBoundingClientRect().top + scroll;

  const viewport = {
    top: scroll,
    bottom: scroll + window.innerHeight,
  };

  const bounds = {
    top: boundsTop,
    bottom: boundsTop + element.clientHeight,
  };

  const isVisible =
    (bounds.bottom >= viewport.top && bounds.bottom <= viewport.bottom) ||
    (bounds.top <= viewport.bottom && bounds.top >= viewport.top);

  return isVisible;
};

export default {
  scrollToTop,
  scrollTo,
  scrollToElement,
  isElementVisible,
};
