
import Base from "../base/base.model";

class Price extends Base {
  constructor({
    id,

    main,
    sale,
    rental,
    condo,
    iptu,
  }) {
    super({ id });

    this.main = main;
    this.sale = sale;
    this.rental = rental;
    this.condo = condo;
    this.iptu = iptu;
  }
}

export default Price;
