import styled, { css } from 'styled-components';
import { LocationOn } from 'styled-icons/material/LocationOn';

import { BaseLabel } from '../../styled/labels/labels';
import { COLOR_3, COLOR_5, COLOR_9, COLOR_2 } from '../../styled/variables/variables';
import { ellipsis, transitions } from 'polished';
import { iconBaseStyle, pointer, noScrollBar, mqDesktop } from '../../styled/helpers/helpers';


export const Wrapper = styled.div`
  width: 100%;
  background-color: ${ COLOR_2 };
  border-radius: 1px;;
  overflow: hidden;
  overflow-y: scroll;
  ${ noScrollBar };
  max-height: 0px;
  opacity: 0;
  ${ transitions([ 'max-height', 'opacity' ], 'ease .6s') };

  ${ ({ show }) => show && css`
    opacity: 1;
    max-height: 180px;
    ${ mqDesktop`
      max-height: 250px;
    ` };
  ` };
`;

export const selectedAlternativeStyle = css`
  background-color: ${ COLOR_5 };
`;

export const Alternative = styled.div`
  padding: 10px;
  border-bottom: solid 1px ${ COLOR_5 };
  color: ${ COLOR_3 };
  display: flex;
  ${ pointer };
  ${ transitions([ 'background-color' ], 'ease .3s') };

  &:last-child {
    border-bottom: none;
  }
  &:hover {
    ${ selectedAlternativeStyle };
  }
  ${ ({ isSelected }) => isSelected && selectedAlternativeStyle }
`;

export const AlternativeLabel = styled(BaseLabel)`
  ${ ellipsis('100%') };
  flex-grow: 1;
  width: 1px;
  align-self: center;
  color: ${ COLOR_3 };
`;

export const LocationIcon = styled(LocationOn)`
  ${ iconBaseStyle };
  align-self: center;
  margin-right: 5px;
  color: ${ COLOR_9 };
`;