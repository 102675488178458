import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { RiCloseLine, RiFilter2Fill } from 'react-icons/ri';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { Accordion } from 'react-bootstrap';

import { PendenciesTableButtons } from './components/pendencies-table-buttons';
import { FakeInput } from './components/fake-input';
import { FakeTextarea } from './components/fake-textarea';

import realEstatePendingService from '../../../services/real-estate-pending/real-estate-pending.service';

import { pendenciesOrigin, pendenciesStatus, pendenciesSituation } from './constants/pendencies';
import queryStringUtil from '../../../utils/query-string/query-string.util';

import {
  PendenciesWrapper,
  PendenciesHeader,
  PendenciesHeaderInfo,
  OriginFilter,
  PendenciesFilter,
  PendenciesFilterActionButtons,
  PendenciesFilterGroup,
  StatusFilter,
  PendenciesFilterClearButton,
  PendenciesFilterSubmitButton,
  PendenciesDataEmptyText,
  PendenciesModal,
  PendenciesModalBody,
  PendenciesModalHeader,
  PendenciesModalContent,
  Logs,
  Card,
  AccordionHeader,
  AccordionHeaderList,
  AccordionHeaderTagSituation,
  AccordionHeaderListItem,
  AccordionBodyLogContainer,
  AccordionBodyTagOfLog,
  AccordionBodyLogDescription,
} from './styles';

const customStyles = {
  rows: {
    style: {
      fontSize: '16px'
    },
  },
  headCells: {
    style: {
      fontSize: '15px',
      fontWeight: '600'
    },
  },
  cells: {
  },
};

export function Pendencies(props) {
  const [pendenciesData, setPendenciesData] = useState([]);
  const [pendenciesPagination, setPendenciesPagination] = useState({
    page: "1/1",
    size: 10,
    total: 0,
  });
  const [hasFilterAction, setHasFilterAction] = useState(false);
  const [clearFilter, setClearFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [pendencyDetailsData, setPendencyDetailsData] = useState({});

  const [originFilterValue, setOriginFilterValue] = useState('all');
  const [statusFilterValue, setStatusFilterValue] = useState('all');

  const [pendenciesModalVisible, setPendenciesModalVisible] = useState(false);

  async function getRealEstatePendencies({ page }) {
    try {
      const { history, user } = props;
      const { realEstateId } = queryStringUtil.parse(history);

      const { status, data } = await realEstatePendingService.getRealEstatePendencies({
        page,
        realEstateId,
        origin: originFilterValue === 'all' ? null : originFilterValue,
        status: statusFilterValue === 'all' ? null : statusFilterValue,
        token: user.token,
      });

      if (status !== 200) {
        throw new Error("Não foi possível buscar as pendências, tente novamente!");
      }

      const { items: pendencies, pagination } = data;

      if (!!pendencies.length) {
        const pendenciesSerialized = pendencies.map(item => {
          return {
            ...item,
            status: pendenciesStatus[item.status] ?? item.status,
            origin: pendenciesOrigin[item.origin] ?? item.origin,
            createdAtFormatted: format(new Date(item.createdAt), "dd/MM/yyyy", {
              locale: ptBR,
            }),
          }
        })

        setPendenciesData(pendenciesSerialized);
        setPendenciesPagination(pagination);
      } else {
        setPendenciesData([]);
        setPendenciesPagination({
          page: "1/1",
          size: 10,
          total: 0,
        });
      }

      setHasFilterAction(false);
    } catch (error) {
      toast.error(error.message, {
        autoClose: 3000,
      })
    }
  }

  useEffect(() => {
    getRealEstatePendencies({ page: currentPage });
  }, [currentPage, clearFilter]);

  function handleChangeOriginFilter(event) {
    setOriginFilterValue(event.target.value);
  }

  function handleChangeStatusFilter(event) {
    setStatusFilterValue(event.target.value);
  }

  function handleChangePendenciesPage(page) {
    setCurrentPage(page);
  }

  function handlePendenciesTableFilterSubmit() {
    if (currentPage > 1) {
      setCurrentPage(1);
      setHasFilterAction(true);
    } else {
      getRealEstatePendencies({ page: 1 })
    }
  }

  function handlePendenciesTableFilterClear() {
    setOriginFilterValue('all');
    setStatusFilterValue('all');

    setCurrentPage(1);
    setClearFilter(state => !state);
  }

  function handleClosePendenciesModal() {
    setPendenciesModalVisible(false);
  }

  function handleViewPendency(pendency) {
    let pendencyFormatted = {
      ...pendency,
      createdAtFormatted: format(new Date(pendency.createdAt), "dd/MM/yyyy 'às' HH:mm'h'", {
        locale: ptBR,
      }),
    };

    if (!!pendency.log) {
      pendencyFormatted = {
        ...pendencyFormatted,
        log: pendency.log.map(log => {
          return {
            ...log,
            createdAtFormatted: format(new Date(log.createdAt), "dd/MM/yyyy 'às' HH:mm'h'", {
              locale: ptBR,
            }),
          }
        }),
      }
    }

    setPendencyDetailsData(pendencyFormatted);
    setPendenciesModalVisible(true);
  }

  const pendenciesTableColumns = [
    {
      name: 'Data',
      selector: row => row.createdAtFormatted,
      width: '120px',
    },
    {
      name: 'ID da pendência',
      selector: row => row._id,
      width: '260px',
    },
    {
      name: 'Origem',
      selector: row => row.origin,
      width: '200px',
    },
    {
      name: 'Status',
      selector: row => row.status,
    },
    {
      name: 'Ações',
      cell: (row) => <PendenciesTableButtons
        onViewLead={() => handleViewPendency(row)}
      />,
      button: true,
      ignoreRowClick: true,
      allowOverflow: true,
    }
  ];

  return (
    <PendenciesWrapper>
      <PendenciesHeader>
        <PendenciesHeaderInfo>
          <h3>Pendências</h3>
          <p>Aqui você poderá ver todas as pendências anexadas a este imóvel.</p>
        </PendenciesHeaderInfo>
      </PendenciesHeader>

      <PendenciesFilter>
        <span>
          <RiFilter2Fill size={18} />
          Filtro
        </span>

        <PendenciesFilterGroup>
          <OriginFilter>
            <label htmlFor="originSelect">Origem:</label>

            <select
              name="originSelect"
              id="originSelect"
              value={originFilterValue}
              onChange={handleChangeOriginFilter}
            >
              <option value="all">Todos</option>
              <option value="integration">Integração</option>
              <option value="denunciation">Denúncia</option>
            </select>
          </OriginFilter>

          <StatusFilter>
            <label htmlFor="statusSelect">Status:</label>

            <select
              name="statusSelect"
              id="statusSelect"
              value={statusFilterValue}
              onChange={handleChangeStatusFilter}
            >
              <option value="all">Todos</option>
              <option value="open">Aberto</option>
              <option value="in analysis">Em análise</option>
              <option value="solved">Resolvido</option>
              <option value="closed">Fechado</option>
            </select>
          </StatusFilter>
        </PendenciesFilterGroup>

        <PendenciesFilterActionButtons>
          <PendenciesFilterSubmitButton
            type="button"
            onClick={handlePendenciesTableFilterSubmit}
          >
            Filtrar
          </PendenciesFilterSubmitButton>

          <PendenciesFilterClearButton
            type="button"
            onClick={handlePendenciesTableFilterClear}
          >
            Limpar filtro
          </PendenciesFilterClearButton>
        </PendenciesFilterActionButtons>
      </PendenciesFilter>

      {!!pendenciesData.length ? (
        <DataTable
          columns={pendenciesTableColumns}
          data={pendenciesData}
          customStyles={customStyles}
          noDataComponent="Não há registros para exibir"
          pagination
          paginationComponentOptions={{
            rowsPerPageText: 'pendências por página',
            rangeSeparatorText: 'de'
          }}
          paginationServer
          paginationTotalRows={pendenciesPagination.total}
          paginationRowsPerPageOptions={[10]}
          paginationResetDefaultPage={hasFilterAction}
          onChangePage={handleChangePendenciesPage}
        />
      ) : (
        <PendenciesDataEmptyText>Não há registros para exibir</PendenciesDataEmptyText>
      )}

      <PendenciesModal
        isOpen={pendenciesModalVisible}
      >
        <PendenciesModalBody>
          <PendenciesModalHeader>
            <h3>Veja todas as informações da pendência</h3>

            <button type="button" onClick={handleClosePendenciesModal}>
              <RiCloseLine size={22} />
            </button>
          </PendenciesModalHeader>

          <PendenciesModalContent>
            <FakeInput
              label="Data e Hora (pendência registrada)"
              value={pendencyDetailsData.createdAtFormatted}
            />

            <FakeInput
              label="Origem"
              value={pendenciesOrigin[pendencyDetailsData.origin] ?? pendencyDetailsData.origin}
            />

            <FakeInput
              label="Status"
              value={pendenciesStatus[pendencyDetailsData.status] ?? pendencyDetailsData.status}
            />

            <FakeInput
              label="Situação do anúncio"
              value={pendenciesSituation[pendencyDetailsData.situation] ?? pendencyDetailsData.situation}
            />

            <FakeInput
              label="Título"
              value={pendencyDetailsData.title}
            />

            <FakeTextarea
              label="Descrição"
              value={pendencyDetailsData.description}
            />

            {!!pendencyDetailsData.log && (
              <Logs>
                <label>Logs</label>

                <Accordion defaultActiveKey="1">
                  {!!pendencyDetailsData._id && pendencyDetailsData.log.map((item, index) => (
                    <Card key={item._id}>
                      <Accordion.Toggle as={Card.Header} eventKey={index + 1}>
                        <AccordionHeader>
                          <AccordionHeaderList>
                            <AccordionHeaderListItem isTag={false}>
                              <span>External Id:</span> {item.externalId}
                            </AccordionHeaderListItem>

                            <AccordionHeaderListItem isTag={false}>
                              <span>Data:</span> {item.createdAtFormatted}
                            </AccordionHeaderListItem>
                          </AccordionHeaderList>

                          {item.tag === "integrado" &&
                            <AccordionHeaderTagSituation hasIntegrated>
                              Integrado
                            </AccordionHeaderTagSituation>
                          }

                          {item.tag === "não integrado" &&
                            <AccordionHeaderTagSituation hasNotIntegrated>
                              Não Integrado
                            </AccordionHeaderTagSituation>
                          }

                          {item.tag === "parcialmente integrado" &&
                            <AccordionHeaderTagSituation hasPartiallyIntegrated>
                              Parcialmente I.
                            </AccordionHeaderTagSituation>
                          }
                        </AccordionHeader>
                      </Accordion.Toggle>

                      <Accordion.Collapse eventKey={index + 1}>
                        <Card.Body>
                          {!item.logs.length && (
                            <AccordionBodyLogContainer>
                              <AccordionBodyTagOfLog isSuccess>
                                sucesso
                              </AccordionBodyTagOfLog>

                              <AccordionBodyLogDescription isSuccess>
                                Este imóvel foi integrado com sucesso.
                              </AccordionBodyLogDescription>
                            </AccordionBodyLogContainer>
                          )}

                          {item.logs.map((item, index) => {
                            if (item !== null) {
                              return (
                                <AccordionBodyLogContainer key={index}>
                                  <AccordionBodyTagOfLog
                                    isWarning={item.tag === 'warning'}
                                    isError={item.tag === 'error'}
                                  >
                                    {item.tag === 'warning' && 'aviso'}
                                    {item.tag === 'error' && 'erro'}
                                  </AccordionBodyTagOfLog>

                                  <AccordionBodyLogDescription
                                    isWarning={item.tag === 'warning'}
                                    isError={item.tag === 'error'}
                                  >
                                    {item.description}
                                  </AccordionBodyLogDescription>
                                </AccordionBodyLogContainer>
                              )
                            } else {
                              return;
                            }
                          })}
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  ))
                  }
                </Accordion>
              </Logs>
            )}
          </PendenciesModalContent>
        </PendenciesModalBody>
      </PendenciesModal>
    </PendenciesWrapper>
  );
}
