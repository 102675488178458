import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import { RiFilter2Fill } from 'react-icons/ri';

import { BrokersTableButtons } from './components/brokers-table-buttons';

import userService from '../../../../services/user/user.service';
import attachBrokersService from '../../../../services/attach-brokers/attach-brokers.service';

import pathsConstants from '../../../../constants/paths';
import eventsConstants from '../../../../constants/events'
import domEventsUtil from '../../../../utils/dom-events/dom-events.util';

import {
  BrokersTableWrapper,
  BrokersTableFilter,
  EmailFilter,
  BrokersTableFilterActionButtons,
  BrokersTableFilterSubmitButton,
  BrokersTableFilterClearButton,
} from './styles';

const customStyles = {
  rows: {
    style: {
      fontSize: '16px'
    },
  },
  headCells: {
    style: {
      fontSize: '15px',
      fontWeight: '600'
    },
  },
  cells: {
  },
};

export function BrokersTable() {
  const [brokersData, setBrokersData] = useState([]);
  const [brokersPagination, setBrokersPagination] = useState({
    page: "1/1",
    size: 10,
    total: 0,
  });

  const [currentPage, setCurrentPage] = useState(1);

  const [emailFilter, setEmailFilter] = useState('');

  async function getBrokersFromLoggedUser({ page, isClear = false }) {
    try {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: true },
      });

      const user = userService.retrieve();

      const { data, status } = await attachBrokersService.getCompanyBrokers({
        companyId: user.company._id,
        page,
        email: !isClear ? emailFilter : '',
        token: user.token,
      });

      if (status !== 200) {
        throw new Error('Não foi possível buscar os corretores!')
      }

      const { users, pagination } = data;

      const brokersDataSerialized = users.map(item => {
        return {
          id: item._id,
          name: item.name,
          email: item.email,
          creci: !!item.creci ? item.creci : 'Não informado',
        }
      });

      setBrokersData(brokersDataSerialized);
      setBrokersPagination(pagination);
    } catch (err) {
      toast.error(err.message);
    } finally {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: false },
      });
    }
  }

  useEffect(() => {
    getBrokersFromLoggedUser({ page: 1 });
  }, [])

  function handleChangeBrokersPage(page) {
    setCurrentPage(page);
    getBrokersFromLoggedUser({ page })
  }

  function handleBrokersTableFilterSubmit(event) {
    event.preventDefault();

    getBrokersFromLoggedUser({ page: currentPage })
  }

  function handleBrokersTableFilterClear() {
    setEmailFilter('');

    getBrokersFromLoggedUser({ page: currentPage, isClear: true });
  }

  function handleViewBrokerLeads(brokerId) {
    const params = (new URL(document.location)).searchParams;

    const originParam = params.get('origin');

    window.open(`${pathsConstants.DASHBOARD_LEADS_COMPANY_BROKER}?brokerId=${brokerId}&origin=${originParam}`, '_target');
  }

  const brokersTableColumns = [
    {
      name: 'Nome',
      selector: row => row.name,

    },
    {
      name: 'E-mail',
      selector: row => row.email,
    },
    {
      name: 'Creci',
      selector: row => row.creci,
    },
    {
      name: 'Ações',
      cell: (row) => <BrokersTableButtons
        onViewBroker={() => handleViewBrokerLeads(row.id)}
      />,
      button: true,
      ignoreRowClick: true,
      allowOverflow: true,
    }
  ];

  const brokersTableFilterSubmitButtonDisabled = !emailFilter;

  return (
    <BrokersTableWrapper>
      <h3>Seus corretores</h3>
      <p>Abaixo estão os corretores associados à sua conta e você poderá controlar seus leads.</p>

      <BrokersTableFilter onSubmit={handleBrokersTableFilterSubmit}>
        <span>
          <RiFilter2Fill size={18} />
          Filtro
        </span>

        <EmailFilter>
          <label htmlFor="emailInput">E-mail:</label>

          <input
            id="emailInput"
            name="emailInput"
            placeholder='Digite o e-mail do corretor'
            type="email"
            required
            value={emailFilter}
            onChange={(event) => setEmailFilter(event.target.value)}
          />
        </EmailFilter>

        <BrokersTableFilterActionButtons>
          <BrokersTableFilterSubmitButton
            type="submit"
            disabled={brokersTableFilterSubmitButtonDisabled}
          >
            Filtrar
          </BrokersTableFilterSubmitButton>

          <BrokersTableFilterClearButton
            type="button"
            onClick={handleBrokersTableFilterClear}
          >
            Limpar filtro
          </BrokersTableFilterClearButton>
        </BrokersTableFilterActionButtons>
      </BrokersTableFilter>

      <DataTable
        columns={brokersTableColumns}
        data={brokersData}
        customStyles={customStyles}
        noDataComponent="Não há registros para exibir"
        pagination
        paginationComponentOptions={{
          rowsPerPageText: 'Corretores por página',
          rangeSeparatorText: 'de'
        }}
        paginationServer
        paginationTotalRows={brokersPagination.total}
        paginationRowsPerPageOptions={[10]}
        onChangePage={handleChangeBrokersPage}
      />
    </BrokersTableWrapper>
  )
}
