import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from "react-google-maps";
import React from "react";

export const MyMapComponent = withScriptjs(
  withGoogleMap((props) => (
    <>
      <GoogleMap
        defaultZoom={15}
        defaultCenter={{ lat: props.latitude, lng: props.longitude }}
        key={new Date().getTime()}
      >
        {props.isMarkerShown && (
          <Marker
            key={new Date().getTime()}
            position={{ lat: props.latitude, lng: props.longitude }}
          />
        )}
      </GoogleMap>

      <div
        style={{
          color: "#ff000094",
          marginTop: "25px",
          fontSize: "14px",
          fontWeight: "500",
        }}
      >
        {document.getElementById("showOnMap").checked ? (
          <>
            As informações de localização do seu imóvel serão exibidas no corpo
            do anúncio
          </>
        ) : (
          <>
            Nosso sistema de busca utiliza como ferramenta principal o mapa e o
            endereço completo será utilizado apenas para localizar
            geograficamente o seu imóvel. Se você quiser mostrar o endereço no
            anúncio, clique abaixo.
          </>
        )}
      </div>
    </>
  ))
);
