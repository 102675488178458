import React from 'react';

import { AlertContainer } from './styles';

export function Alert({ title, description }) {
  return (
    <AlertContainer>
      <h3>{title}</h3>
      <p>{description}</p>
    </AlertContainer>
  )
}
