
import React, { PureComponent } from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { Persist } from 'react-persist-plus';

import { BaseInput, ErrorMessage } from '../../../styled/inputs/inputs';

import validationUtil from '../../../utils/validation/validation.util';
import storageUtil from '../../../utils/storage/storage.util';

/* 
  global
  window
*/

class Title extends PureComponent {

  constructor(props) {
    super(props);

    const { title } = props;
    if(title) storageUtil.del(Title.STORAGE_KEY, false);

    this.state = {
      form: {
        title: title || '',
      },
      showErrorsFor: { },
    };
  }

  handleFormChange = event => {
    const { name, value } = event.target;

    this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        [name]: value,
      },
    }));
  };

  handleInputBlur = event => {
    const { name} = event.target;

    this.setState(state => ({
      ...state,
      showErrorsFor: {
        ...state.showErrorsFor,
        [name]: true,
      },
    }));
  };

  render() {
    const { showInvalids } = this.props;
    const { form, showErrorsFor } = this.state;

    const isTitleValid = validationUtil.text({
      minLength: 5,
      maxLength: 50,
      value: form.title,
    });
    return (
      <Wrapper onChange={ this.handleFormChange }>
        <Persist 
          name={ Title.STORAGE_KEY }
          data={ form }
          onMount={ data => this.setState(state => ({
            ...state,
            form: data,
          })) }
          store={ window.sessionStorage }
        />
        <Input 
          value={ form.title } 
          onBlur={ this.handleInputBlur }
          isInvalid={ 
            (showErrorsFor.title || showInvalids) && !isTitleValid 
          }
        />
        { (showErrorsFor.title || showInvalids) && !isTitleValid && (
          <ErrorMessage>
            Títulos devem conter de 5 a 50 caracteres.
          </ErrorMessage>
        ) }
      </Wrapper>
    );
  }
}

Title.STORAGE_KEY = 'REPORT_BUILDER_TITLE_FORM';

Title.propTypes = {
  title: propTypes.string,
};

Title.defaultProps = { };

const Wrapper = styled.form`
  margin-bottom: 20px;
`;

const Input = styled(BaseInput).attrs({
  name: 'title',
})``;

export default Title;
