import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import { Bed } from 'styled-icons/boxicons-regular/Bed';
import { Car } from 'styled-icons/boxicons-regular/Car';
import { Shower } from 'styled-icons/fa-solid/Shower';
import { Pets } from 'styled-icons/material/Pets';
import { PhotoSizeSelectSmall } from 'styled-icons/material/PhotoSizeSelectSmall';

import { COLOR_1, COLOR_2, COLOR_13 } from '../../../styled/variables/variables';
import { BaseLabel } from '../../../styled/labels/labels';
import { iconBaseStyle, mqTablet } from '../../../styled/helpers/helpers';
import { BaseButton } from '../../../styled/buttons/buttons';

const Cover = ({
  area,
  bathrooms,
  bedrooms,
  description,
  parkingSpaces,
  petAcceptance,
  title,
}) => (
  <Wrapper>
    <InfoWrapper>
      <Title>
        { title }
      </Title>
      <Description>
        { description }
      </Description>
      <FeaturesWrapper>
        <Feature>
          <BedroomIcon />
          <FeatureLabel>
            { bedrooms } quartos
          </FeatureLabel>
        </Feature>
        <Feature>
          <AreaIcon />
          <FeatureLabel>
            { area } m²
          </FeatureLabel>
        </Feature>
        <Feature>
          <CarIcon />
          <FeatureLabel>
            { parkingSpaces } vagas
          </FeatureLabel>
        </Feature>
        <Feature>
          <BathroomIcon />
          <FeatureLabel>
            { bathrooms } banheiros
          </FeatureLabel>
        </Feature>
        {/* <Feature>
          <PetIcon />
          <FeatureLabel>
            { petAcceptance ? 'Permitido' : 'Proibido' }
          </FeatureLabel>
        </Feature> */}
      </FeaturesWrapper>
    </InfoWrapper>
    <ContactAgentButton>
      fale com o corretor
    </ContactAgentButton>
  </Wrapper>
);

Cover.propTypes = {
  area: propTypes.number,
  bathrooms: propTypes.number,
  bedrooms: propTypes.number,
  description: propTypes.string,
  parkingSpaces: propTypes.number,
  petAcceptance: propTypes.bool,
  title: propTypes.string,
};

Cover.defaultProps = {
  // area: 900,
  // bathrooms: 5,
  // bedrooms: 3,
  // description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
  // parkingSpaces: 3,
  // petAcceptance: true,
  // title: 'Super oportunidade!',
};

const Wrapper = styled.div`
  padding: 30px 10px;
  background-color: ${ COLOR_1 };
  margin: 10px;
  margin-top: -20px;
  border-radius: 1px;
  position: relative;
  display: flex;
  flex-direction: column;

  ${ mqTablet`
    width: 100%;
    max-width: 1000px;
    align-self: center;
  ` }
`;

const InfoWrapper = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
`;

const Title = styled(BaseLabel)`
  color: ${ COLOR_2 };
  font-weight: 700;
  font-size: 28px;
  text-align: center;
  margin-bottom: 20px;
  align-self: center;
  width: fit-content;
`;

const Description = styled(Title).attrs({
  as: 'p',
})`
  font-weight: normal;
  font-size: 14px;
  opacity: .7;
  margin-bottom: 40px;
  max-width: 500px;
`;

const FeaturesWrapper = styled.div`
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  margin-bottom: 30px;
`;

const Feature = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: calc(100% / 3);
  margin-bottom: 10px;

  ${ mqTablet`
    width: 80px;
  ` }
`;

const FeatureLabel = styled(Description).attrs({
  as: 'label',
})`
  font-size: 12px;
  align-self: center;
  margin: 0;
`;

const BedroomIcon = styled(Bed)`
  ${ iconBaseStyle }
  color: ${ COLOR_2 };
  align-self: center;
  opacity: .7;
`;

const AreaIcon = styled(BedroomIcon).attrs({
  as: PhotoSizeSelectSmall,
})``;

const CarIcon = styled(BedroomIcon).attrs({
  as: Car
})``;

const BathroomIcon = styled(BedroomIcon).attrs({
  as: Shower,
})``;

const PetIcon = styled(BedroomIcon).attrs({
  as: Pets,
})``;

const ContactAgentButton = styled(BaseButton)`
  background-color: ${ COLOR_13 };
  align-self: center;
`;

export default Cover;
