import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import propTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";

import queryStringUtil from "../../utils/query-string/query-string.util";

import {
  Wrapper,
  ItemWrapper,
  LabelWrapper,
  Label,
  DownArrowIcon,
  InfoTooltip,
  ActiveIndicator
} from './styles';

class Tabs extends PureComponent {
  constructor(props) {
    super(props);

    const { configs, history } = props;
    const { pathname } = history.location;

    const indexFound = configs.findIndex(({ path }) => {
      return path === pathname;
    });

    this.state = {
      activePath: indexFound !== -1 ? pathname : configs[0].path,
      showAll: true
    };
  }

  componentDidUpdate() {
    const { configs, history } = this.props;
    const { pathname } = history.location;

    const indexFound = configs.findIndex(({ path }) => {
      return path === pathname;
    });

    this.setState(prevState => ({
      ...prevState,
      activePath: indexFound !== -1 ? pathname : configs[0].path,
    }));
  }

  handleItemClick = activePath => {
    this.setState(
      state => ({
        ...state,
        showAll: !state.showAll,
        activePath
      }),
      () => {
        const { history } = this.props;

        const { pathname } = history.location;
        if (pathname === activePath) return;

        history.push({
          pathname: activePath,
          search: queryStringUtil.stringify(history),
        });
      }
    );
  };

  render() {
    const { className, configs } = this.props;
    const { activePath, showAll } = this.state;

    return (
      <Wrapper
        className={className}
        isOpened={showAll}
        listLength={configs.length}
      >
        {configs.map(({ label, path, showTooltip, tooltipTitle, disabled = false, isIncorporation = false }, index) => {
          const isActive = path === activePath;

          let order = index;
          if (isActive) order = 0;
          else if (!isActive && index === 0) order = 1;

          return (
            <ItemWrapper
              key={path}
              isActive={isActive}
              isDisabled={isIncorporation ? true : disabled}
              onClick={!disabled && !isIncorporation ? () => this.handleItemClick(path) : () => { }}
              order={order}
              isIncorporation={isIncorporation}
            >
              <LabelWrapper>
                {showTooltip &&
                  <>
                    <Tooltip title={tooltipTitle}>
                      <Label>{label}</Label>
                    </Tooltip>
                  </>
                }
                {!showTooltip &&
                  <>
                    <Label isIncorporation={isIncorporation}>{label}</Label>
                  </>
                }

                {configs && configs.length > 1 && <DownArrowIcon show={isIncorporation ? false : isActive} />}
              </LabelWrapper>
              <ActiveIndicator show={isIncorporation ? false : isActive} />
            </ItemWrapper>
          );
        })}
      </Wrapper>
    );
  }
}

Tabs.propTypes = {
  onTabClick: propTypes.func.isRequired,
  configs: propTypes.arrayOf(
    propTypes.shape({
      label: propTypes.string,
      path: propTypes.string
    })
  ),
  history: propTypes.object,
  className: propTypes.string
};

Tabs.defaultProps = {
  configs: []
};



export default withRouter(Tabs);
