import React from 'react';
import { RiEyeLine } from 'react-icons/ri';

import { Container } from './styles';

export function BrokersTableButtons({ onViewBroker }) {
  return (
    <Container>
      <button type="button" title="Visualizar leads desse corretor" onClick={onViewBroker}>
        <RiEyeLine size={22} />
      </button>
    </Container>
  );
}
