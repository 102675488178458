import React, { PureComponent } from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import { mqTablet } from '../../../styled/helpers/helpers';

import NoResults from '../../../components/no-results';
import Pagination from '../../../components/pagination';
import AdCard from '../../../components/ad-card/RecommendationAdCard';

import domEventsUtil from '../../../utils/dom-events/dom-events.util';
import windowUtil from '../../../utils/window/window.util';
import eventsConstants from '../../../constants/events';
import pathsConstants from '../../../constants/paths';
import queryStringUtil from '../../../utils/query-string/query-string.util';
import realEstateService from '../../../services/real-estate/real-estate.service';
/*
  global
  console
  window
*/
class Recommendations extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      realEstates: {
        items: [],
        _links: {}
      },
    };

    this.recommendationRef = React.createRef();
  }

  componentDidMount() {
    this.getAds();
  }

  handleOptionsClick = realEstateId => {
    const { history } = this.props;

    history.push({
      pathname: pathsConstants.REPORT,
      search: queryStringUtil.stringify(history, {
        realEstateId,
      }),
    });
  };

  getAds = async (page) => {
    const { user } = this.props;
    domEventsUtil.dispatch({
      name: eventsConstants.LOADING_ANIMATION,
      params: { show: true }
    });
    let results;
    try {
      results = await realEstateService.getRecommendedAds({
        token: user.token,
        page
      });
    } catch (error) {
      //
    }

    await this.setState(state => ({
      ...state,
      realEstates: results || [ ],
    }));

    await domEventsUtil.dispatch({
      name: eventsConstants.LOADING_ANIMATION,
      params: { show: false }
    });
  };

  onPageChange = async ({selected}) => {
    await this.getAds(selected+1);
    windowUtil.scrollToElement({element: this.recommendationRef.current});
  };

  render() {
    const { realEstates } = this.state;
    const { history } = this.props;
    return (
      <Wrapper ref={this.recommendationRef}>
        <WrapperItems>
          { realEstates.items && realEstates.items.map(re => (
            <RAdCard
              key={ re.id }
              { ...re }
              { ...re.address }
              adId={re.id}
            />
          )) }
          { realEstates.items && realEstates.items.length === 0 && (
            <NoResults />
          ) }
        </WrapperItems>
        { realEstates.items && realEstates.items.length > 0 &&
          realEstates._links &&
          <Pagination onPageChange={this.onPageChange} pageCount={realEstates._links.pages} />
        }
        {!realEstates.items && <NoResults />}
      </Wrapper>
    );
  }
}

Recommendations.propTypes = {
  history: propTypes.object,
  user: propTypes.object,
};

Recommendations.defaultProps = {

};

const WrapperItems = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  ${ mqTablet`
    flex-flow: wrap;
    flex-direction: row;
  ` }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${ mqTablet`
    width: 100%;
    max-width: 900px;
    align-self: center;
  ` }
`;

const RAdCard = styled(AdCard).attrs({
  height: '250px',
  tabletHeight: '350px',
  desktopHeight: '350px',
})`
  ${ mqTablet`
    flex-grow: .5;
    height: 350px;
  ` }
`;

export default Recommendations;
