import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';

import { BaseButton } from '../../../../styled/buttons/buttons';
import { COLOR_2, COLOR_1, COLOR_4, COLOR_17 } from '../../../../styled/variables/variables';

export const TYPES = {
  SALE: 'Venda',
  RENTAL: 'Locação',
};

class Transaction extends PureComponent {

  constructor(props) {
    super(props);
    this.hiddenInputRef = React.createRef();
    this.state = {
      transactionHasDefined: false,
      form: {
        transaction: TYPES.SALE,
      },
    };
  }

  handleButtonClick = event => {
    const { name} = event.target;

    this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        transaction: name,
      },
    }), () => {
      const { onChange } = this.props;
      onChange(name);
    });
  };

  render() {
    const { form, transactionHasDefined } = this.state;

    const { isEditing, populate } = this.props;

    if (isEditing) {
      if (!!populate && !transactionHasDefined) {
        this.setState(prevState => ({
          ...prevState,
          transactionHasDefined: true,
          form: {
            ...prevState.form,
            transaction: populate,
          }
        }));
      }
    }

    return (
      <Wrapper>
        <Button
          isActive={ form.transaction === TYPES.SALE }
          onClick={ this.handleButtonClick }
          name={ TYPES.SALE }
        >
          Venda
        </Button>
        <Button
          isActive={ form.transaction === TYPES.RENTAL }
          name={ TYPES.RENTAL }
          onClick={ this.handleButtonClick }
        >
          Locação
        </Button>
        <HiddenInput
          name='transaction'
          id='transaction'
          ref={ this.hiddenInputRef }
        />
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 50px;
`;

const Button = styled(BaseButton)`
  flex-grow: 1;
  width: 1px;
  font-weight: 300;
  border: solid 1px ${ COLOR_1 };

  ${ ({ isActive }) => !isActive && css`
    color: ${ COLOR_4 };
    background-color: ${ COLOR_2 };
    border-color: ${ COLOR_17 };
  ` }
`;

const HiddenInput = styled.input`
  display: none;
`;

export default Transaction;
