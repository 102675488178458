import React from 'react';
import propTypes from 'prop-types';

import { Wrapper, Select, ArrowDownIcon, Option } from './styles';

const Selector = ({
  className,
  disabled,
  id,
  isInvalid,
  name,
  options,
  value,
  style,
}) => (
  <Wrapper className={className} style={style && style}>
    <div className="form-floating" style={{ width: '100%' }}>
      <select className="form-select" id={id} name={name} aria-label="Selecione" isInvalid={isInvalid} disabled={disabled}>
        {options.map((option, index) => (
          <option key={`${option.value}_${index.toString()}`} value={option.value} selected={option.value === value} style={index > 0 ? {} : { visibility: 'hidden' }}> {option.label} </option>
        ))}
      </select>
      <label htmlFor="floatingSelect">{options[0] ? options[0].label : 'carregando'}</label>
    </div>
    {/* <Select
      disabled={disabled}
      id={id}
      isInvalid={isInvalid}
      name={name}
    >
      {options.map((option, index) => (
        <Option
          key={`${option.value}_${index.toString()}`}
          value={option.value}
          selected={option.value === value}
        >
          {option.label}
        </Option>
      ))}
    </Select> */}
    <ArrowDownIcon disabled={disabled} />
  </Wrapper>
);

Selector.propTypes = {
  className: propTypes.string,
  disabled: propTypes.bool,
  id: propTypes.string,
  isInvalid: propTypes.bool,
  name: propTypes.string,
  options: propTypes.arrayOf(propTypes.string),
  value: propTypes.string,
};

Selector.defaultProps = {
  options: [],
};



export default Selector;
