import React, { PureComponent } from 'react';
import styled from 'styled-components';

import { MaskedBaseInput, masks } from '../../../../styled/inputs/inputs';
import { BaseLabel } from '../../../../styled/labels/labels';

import validationUtil from '../../../../utils/validation/validation.util';

class Prices extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      fieldsHasFilled: false,
      form: {
        price: '',
        condo: '',
        iptu: '',
      },
      showErrorsFor: {},
      transactionType: props.transactionType,
    };
  }

  toggleTransaction = transaction => {
    this.setState(state => ({
      ...state,
      transactionType: transaction,
    }))
  }

  handleFormChange = event => {
    const { name, value } = event.target;

    let formattedValue;
    try {
      formattedValue = value.split(' ')[1];
      formattedValue = formattedValue.replace(/\./g, '');
      formattedValue = parseFloat(formattedValue.replace(',', '.'));
    } catch (error) {
      formattedValue = value;
    }

    this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        [name]: formattedValue,
      },
    }));
  };

  handleInputBlur = event => {
    const { name } = event.target;

    this.setState(state => ({
      ...state,
      showErrorsFor: {
        ...state.showErrorsFor,
        [name]: true,
      },
    }));
  };

  isValid = ({ value }) => {
    return validationUtil.number({
      min: 0,
      value: value && value.length > 0 ?
        value.split(' ')[1] : value,
    });
  };

  render() {
    const { showInvalids, isEditing, populate } = this.props;
    const { form, showErrorsFor, fieldsHasFilled } = this.state;

    if (isEditing) {
      if ((!!populate.main || !!populate.condo ||
        !!populate.iptu)  && !fieldsHasFilled) {
        this.setState(prevState => ({
          ...prevState,
          fieldsHasFilled: true,
          form: {
            ...prevState.form,
            price: populate.main,
            condo:  populate.condo,
            iptu: populate.iptu
          }
        }))
      }
    }

    return (
      <Wrapper onChange={this.handleFormChange}>
        <div className={'form-floating'}>
          <PriceInput
            defaultValue={form.price}
            mask={masks.price()}
            onBlur={this.handleInputBlur}
            id={'price'}
            className={'form-control'}
            style={{ 'width': '100%' }}
            isInvalid={(() => {
              if (!showErrorsFor.price && !showInvalids) return;
              return !this.isValid({ value: form.price });
            })()}
          />
          <label className={'form-label'} htmlFor={'price'}>{this.state.transactionType == "Venda" ? "Valor de venda": "Valor do aluguel"} (R$)</label>
        </div>
        <div className={'form-floating'}>
          <CondoInput
            defaultValue={form.condo}
            mask={masks.price()}
            onBlur={this.handleInputBlur}
            id={'condo'}
            className={'form-control'}
            style={{ 'width': '100%' }}
            isInvalid={(() => {
              if (!showErrorsFor.condo && !showInvalids) return;
              // return !this.isValid({ value: form.condo });
            })()}
          />
          <label className={'form-label'} htmlFor={'condo'}>Condomínio (R$)</label>
        </div>
        <div className={'form-floating'}>
          <IPTUInput
            defaultValue={form.iptu}
            mask={masks.price()}
            id={'iptu'}
            className={'form-control'}
            style={{ 'width': '100%' }}
            onBlur={this.handleInputBlur}
            isInvalid={(() => {
              if (!showErrorsFor.iptu && !showInvalids) return;
              // return !this.isValid({ value: form.iptu });
            })()}
          />
          <label className={'form-label'} htmlFor={'iptu'}>IPTU (R$ MENSAL)</label>
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.form`
  margin-bottom: 50px;
`;

const PriceInput = styled(MaskedBaseInput).attrs({
  placeholder: 'Valor (R$)',
  name: 'price',
})``;

const CondoInput = styled(MaskedBaseInput).attrs({
  placeholder: 'Condomínio (R$)',
  name: 'condo',
})``;

const IPTUInput = styled(MaskedBaseInput).attrs({
  placeholder: 'IPTU (R$ MENSAL)',
  name: 'iptu',
})``;

const Label = styled(BaseLabel)`
  margin-bottom: 10px;
  margin-top: 5px;
  font-weight: 600;
`;

export default Prices;
