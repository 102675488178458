import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { scroller, Element } from 'react-scroll';

import { formSchema } from './schema';

import { InputBootstrap } from '../../../../components/form/input-bootstrap';
import { TextArea } from '../../../../components/form/textarea';
import Images from '../images/Images';
import Panoramas from '../panoramas/Panoramas';
import FloorPlans from '../floor-plans/FloorPlans';
import Video from '../video/Video';
import AdditionalInfo from '../additional-info/AdditionalInfo';
import Features from '../features/Features';
import CommonAreaFeatures from '../common-area-features/CommonAreaFeatures';
import SurroundingFeatures from '../surrounding-features/SurroundingFeatures';

import realEstateService from '../../../../services/real-estate/real-estate.service';

import pathsConstants from '../../../../constants/paths';
import eventConstants from '../../../../constants/events';
import domEventsUtil from '../../../../utils/dom-events/dom-events.util';
import queryStringUtil from '../../../../utils/query-string/query-string.util';

import {
  Form,
  SectionTitle,
  Counter,
  ButtonWrapper,
  SaveButton,
} from './styles';

const customId = "custom-id-yes";

export function Builder2Refactored(props) {
  const [counter, setCounter] = useState(0);

  const [showGuarantees, setShowGuarantees] = useState(false);
  const [guaranteesRequired, setGuaranteesRequired] = useState(false);
  const [infoAd, setInfoAd] = useState();
  const [imagesHasUploaded, setImagesHasUploaded] = useState(false);

  const imagesAdRef = useRef(null);
  const panoramasAdRef = useRef(null);
  const floorPlansAdRef = useRef(null);
  const videoAdRef = useRef(null);
  const guaranteesAdRef = useRef(null);
  const featuresAdRef = useRef(null);
  const commonAreaFeaturesAdRef = useRef(null);
  const surroundingFeaturesAdRef = useRef(null);

  const { register, handleSubmit, formState, watch } = useForm({
    resolver: yupResolver(formSchema),
  });

  const { errors } = formState;

  useEffect(() => {
    const subscription = watch((data) => setCounter(data.description.length));

    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('infoAd'));

    if (!!data) {
      setInfoAd(data);
      setShowGuarantees(data.transaction === 'Locação');
    }
  }, []);

  async function handleFormSubmit(values) {
    let data = {
      ...values,
      ...imagesAdRef.current.state.form,
      ...panoramasAdRef.current.state.form,
      ...floorPlansAdRef.current.state.form,
      ...videoAdRef.current.state.form,
      guarantees: [],
      features: {
        property: featuresAdRef.current.state.form.features,
        condo: commonAreaFeaturesAdRef.current.state.form.features,
        near: surroundingFeaturesAdRef.current.state.form.features,
      },
    };

    if (showGuarantees) {
      data = {
        ...data,
        guarantees: guaranteesAdRef.current.state.guarantees,
      }
    }

    if (infoAd.transaction === 'Locação') {
      if (guaranteesAdRef.current.state.guarantees !== null) {
        if (guaranteesAdRef.current.state.guarantees.length === 0) {
          setGuaranteesRequired(true);
          scroller.scrollTo('guarantees-component', {
            duration: 400,
            offset: -100
          });

          return;
        }
      } else {
        setGuaranteesRequired(true);
        scroller.scrollTo('guarantees-component', {
          duration: 400,
          offset: -100
        });

        return;
      }
    }

    if (data.images.length < 3) {
      toast.error("Você deve inserir pelos menos 3 imagens para finalizar a criação de seu anúncio", {
        autoClose: false,
        toastId: customId,
      });

      scroller.scrollTo('images-ad-component', {
        duration: 400,
        offset: -100
      });

      return;
    }

    domEventsUtil.dispatch({
      name: eventConstants.LOADING_ANIMATION,
      params: { show: true, },
    });

    const { user, history } = props;

    try {
      if (!user || !user.token) {
        history.push(`${pathsConstants.AUTHENTICATION}/${pathsConstants.AUTHENTICATION_PARAMS.LOGIN}`);
      } else {
        if (!imagesHasUploaded) {
          await realEstateService.uploadImages({
            pictureBlobs: data.images.map(({ blob }) => blob),
            floorPlanBlobs: data.floorPlans.map(({ blob }) => blob),
            panoramaBlobs: data.panoramas.map(({ blob }) => blob),
            realEstateId: localStorage.getItem('adId'),
            token: user.token,
          });

          setImagesHasUploaded(true);
        }
      }
    } catch {
      toast.error('Erro enviando imagens.', {
        autoClose: false,
        toastId: customId,
      });

      return;
    } finally {
      domEventsUtil.dispatch({
        name: eventConstants.LOADING_ANIMATION,
        params: { show: false, },
      });
    }

    domEventsUtil.dispatch({
      name: eventConstants.LOADING_ANIMATION,
      params: { show: true, },
    });

    try {
      if (!user || !user.token) {
        history.push(`${pathsConstants.AUTHENTICATION}/${pathsConstants.AUTHENTICATION_PARAMS.LOGIN}`);
      } else {
        const response = await realEstateService.createRealStateStep3({
          adId: localStorage.getItem('adId'),
          data: data,
          user: user,
        });

        if (response.status === 200) {
          history.push({
            pathname: pathsConstants.PURCHASE_FLOW_CONGRATULATIONS,
            search: queryStringUtil.stringify(history, {
              realEstateId: localStorage.getItem('adId'),
              finishedAd: true,
            }),
          });
        } else {
          toast.error('Erro criando anúncio.', {
            autoClose: false,
            toastId: customId,
          });
        }
      }
    } catch {
      toast.error('Erro criando anúncio.', {
        autoClose: false,
        toastId: customId,
      });
    } finally {
      domEventsUtil.dispatch({
        name: eventConstants.LOADING_ANIMATION,
        params: { show: false, },
      });
    }
  };

  function handleSelectGuarantee(value) {
    if (value !== null) {
      if (value.length === 0) {
        setGuaranteesRequired(true);
      } else {
        setGuaranteesRequired(false);
      }
    } else {
      setGuaranteesRequired(true);
    }
  }

  function onInvalidForm() {
    if (infoAd.transaction === 'Locação') {
      if (!guaranteesAdRef.current.state.guarantees.length) {
        setGuaranteesRequired(true);
      }
    }
  }

  return (
    <Form onSubmit={handleSubmit(handleFormSubmit, onInvalidForm)}>
      <SectionTitle>Informações do anúncio</SectionTitle>

      <InputBootstrap
        name="title"
        label="Título do anúncio"
        placeholder="Título do anúncio"
        error={errors.title}
        {...register('title')}
      />

      <TextArea
        name="description"
        label="Descrição do imóvel"
        height={190}
        error={errors.description}
        {...register('description')}
      />

      <Counter>{counter}/2000</Counter>

      <Element id="images-ad-component">
        <SectionTitle>
          Fotos do anúncio
        </SectionTitle>
        <Images ref={imagesAdRef} />
      </Element>

      <SectionTitle>
        Panorama
      </SectionTitle>
      <Panoramas ref={panoramasAdRef} />

      <SectionTitle>
        Planta baixa
      </SectionTitle>
      <FloorPlans ref={floorPlansAdRef} />

      <SectionTitle>
        Vídeo
      </SectionTitle>
      <Video
        ref={videoAdRef}
        video=""
      />

      {showGuarantees && (
        <Element id="guarantees-component">
          <AdditionalInfo
            ref={guaranteesAdRef}
            showInvalids={false}
            onSelectItems={handleSelectGuarantee}
            guaranteesIsRequired={guaranteesRequired}
            showTypeOfGuarantees={showGuarantees}
          />
        </Element>
      )}

      <SectionTitle>
        Detalhamento do imóvel
      </SectionTitle>
      <Features
        ref={featuresAdRef}
        features={[]}
      />

      <SectionTitle>
        Áreas comuns
      </SectionTitle>
      <CommonAreaFeatures
        ref={commonAreaFeaturesAdRef}
      />

      <SectionTitle>
        Entorno
      </SectionTitle>
      <SurroundingFeatures
        ref={surroundingFeaturesAdRef}
      />

      <ButtonWrapper>
        <SaveButton>
          criar anúncio
        </SaveButton>
      </ButtonWrapper>
    </Form>
  );
}
