import styled from 'styled-components';
import { Popover as PopoverHeadless } from "@headlessui/react";

export const Popover = styled(PopoverHeadless)`
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 9999;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const PopoverButton = styled(PopoverHeadless.Button)`
  border-radius: 8px;
  padding: 12px;
  display: flex;
  align-items: center;
  background-color: #3578E5;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.3);

  padding-right: 4px;

  > span {
    max-width: 0px;
    overflow: hidden;
    color: #FFFFFF;
    margin-left: 8px;
  }

  &:hover {
    width: auto;
    padding-right: 12px;

    > span {
      transition: max-width .3s ease-in;
      max-width: 200px;
    }
  }
`;
