import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import propTypes from 'prop-types';
import { Whatsapp as WhatsApp } from 'styled-icons/fa-brands/Whatsapp';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import {
  EmailShareButton,
  FacebookShareButton,
  FacebookIcon,
  TwitterIcon,
  PinterestIcon,
  WhatsappIcon,
  EmailIcon,
  LinkedinIcon,
  LinkedinShareButton,
  PinterestShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  Share
} from "react-share";

import { ShareIcon } from '../../profile/components/ShareButtons';

import { BaseLabel } from '../../../styled/labels/labels';
import { iconBaseStyle, pointer } from '../../../styled/helpers/helpers';
import { COLOR_2, COLOR_WHATS_APP_1 } from '../../../styled/variables/variables';

import listingService from '../../../services/listing/listing.service';

import socialShareUtil from '../../../utils/social-share/social-share.util';


/*
  global
  window
*/

const MainInfo = ({
  listingId,
  property,
  title,
  neighborhood,
  subtitle,
  contact,
  seo,
  externalId
}) => {
  let url = window.location.href

  if (url.includes('http://localhost:3000')) {
    url = url.replace('http://localhost:3000', 'https://dev.koort.com.br')
  }

  const messageWhatsapp = `Olá! Tenho interesse no imóvel - ${title}: ${url}.`

  const share = () => {
    const { href } = window.location;
    socialShareUtil.share({
      title: `${title}\n${subtitle}`,
      message: href,
    });
  };

  const incrementShares = async () => {
    await listingService.incrementShares({
      listingId: listingId,
    });
  }

  const handleCopyURLToClipBoard = async () => {
    await socialShareUtil.copyURLToClipBoard(url, title);

    await incrementShares();
  }

  return (
    <Wrapper>
      <PropertyType>[ {property} ]</PropertyType>
      <Title as="h1">{seo.title}</Title>
      {
        externalId &&
        <AdvertiserCode>Código do anúncio: {externalId}</AdvertiserCode>
      }

      {seo && seo.htmlDescription && (
        <HtmlDescription
          dangerouslySetInnerHTML={{ __html: seo.htmlDescription.replace('<p>', '').replace('</p>', '') }}
        />
      )}

      <Neighborhood>{neighborhood}</Neighborhood>

      <DropdownButton id={`dropdown-button-drop-down`} drop="down" title="Compartilhar anúncio ">
        <Dropdown.Item className='shareIconsContainer'>
          <FacebookButton quote='Venha conhecer esse imóvel' url={url} onClick={incrementShares} style={{ backgroundColor: '#3b5998' }}>
            <FacebookIcon size={35} />
          </FacebookButton>
          <TwitterButton title='Venha conhecer esse imóvel' url={url} onClick={incrementShares} style={{ backgroundColor: '#00aced' }}>
            <TwitterIcon size={35} />
          </TwitterButton>
          <WhatsAppButton title='```Venha conhecer esse imóvel```' separator=": " url={url} onClick={incrementShares} style={{ backgroundColor: '#2cb742' }}>
            <WhatsappIcon size={35} />
          </WhatsAppButton>
          <ShareIcon
            onClick={handleCopyURLToClipBoard}
          />
        </Dropdown.Item>

      </DropdownButton>
      {contact.phone.whatsApp && (
        <WhatsAppContent
          target="_blank"
          rel="noreferrer noopener"
          href={`https://api.whatsapp.com/send?phone=55${contact.phone.number}&text=${messageWhatsapp}`}
        >
          <WhatsAppButton style={{ backgroundColor: '#2cb742' }}>
            <WhatsappIcon size={35} />
          </WhatsAppButton>
        </WhatsAppContent>
      )}

      <SocialShareWrapper>
      </SocialShareWrapper>
    </Wrapper>
  );
}

MainInfo.propTypes = {
  property: propTypes.string,
  title: propTypes.string,
  neighborhood: propTypes.string,
  subtitle: propTypes.string,
  listingId: propTypes.string,
  history: propTypes.object,
};

const HtmlDescription = styled.h2`
  color: #333333;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.5px;
  margin-bottom: 30px;
`;

const Wrapper = styled.div`
  .shareIconsContainer {
    display: flex;
    padding-right: 1rem;
    gap: 8px;
  }
`;

const OwnTitle = styled.p`
   color: #333333;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0.5px;
`;

const PropertyType = styled(BaseLabel)`
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 10px;
  display: block;
`;

const AdvertiserCode = styled(BaseLabel)`
  font-size: 11px;
  font-weight: 400;
  margin-bottom: 20px;
  display: block;
`;

const Title = styled(PropertyType)`
  font-size: 20px;
  font-weight: 700;
`;

const Neighborhood = styled(PropertyType)`
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 0;
  align-self: center;
  margin-bottom: 10px;
`;

const SocialShareWrapper = styled.div`
  display: flex;
  ${pointer}
`;

const WhatsAppIcon = styled(WhatsApp)`
  ${iconBaseStyle}
  color: ${COLOR_2};
  background-color: ${COLOR_WHATS_APP_1};
  padding: 4px;
  border-radius: 3px;
  height: 25px;
  align-self: center;
`;

const FacebookButton = styled(FacebookShareButton)`
  align-self: flex-start;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  background-color:#3b5998;

`;

const TwitterButton = styled(TwitterShareButton)`
  align-self: flex-start;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
`;

const WhatsAppButton = styled(WhatsappShareButton)`
  align-self: flex-start;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  background: #25d366 !important;
`;

export const WhatsAppContent = styled.a`
  margin-left: 12px;
  button {
    border-radius: 7px;
  }

  > svg {
    margin-right: 12px;
  }
`;

export default withRouter(MainInfo);
