
import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { BaseLabel } from '../../../../styled/labels/labels';
import { mqDesktop } from '../../../../styled/helpers/helpers';
import currencyUtil from '../../../../utils/currency/currency.util';

const Prices = ({
  sale,
  rental,
  condo,
  iptu,
  main,
  transaction,
}) => (
  <Wrapper>
    <MainItemWrapper>
      <MainIndicator>
        valor de { transaction === 'Venda' ? 'venda' : 'aluguel' }:
      </MainIndicator>
      <MainValue>
      { currencyUtil.format({
          value: main,
        }) }
      </MainValue>
    </MainItemWrapper>
    <ItemWrapper>
      <Label>
        Condomínio
      </Label>
      <Label>
        { currencyUtil.format({
          value: condo,
        }) }
      </Label>
    </ItemWrapper>
    <ItemWrapper>
      <Label>
        IPTU
      </Label>
      <Label>
        { currencyUtil.format({
          value: iptu,
        }) }
      </Label>
    </ItemWrapper>
  </Wrapper>
);

Prices.propTypes = {
  sale: propTypes.number,
  rental: propTypes.number,
  condo: propTypes.number,
  iptu: propTypes.number,
  transaction: propTypes.string,
};

Prices.defaultProps = { };

const Wrapper = styled.div``;

const ItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
`;

const MainItemWrapper = styled(ItemWrapper)`
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 30px;

  ${ mqDesktop`
    flex-direction: row;
    margin-bottom: 20px;
    justify-content: space-between;
  ` }
`;

const Label = styled(BaseLabel)`
  font-size: 12px;
`;

const MainIndicator = styled(Label)`
  font-weight: bold;
  font-size: 14px;
  align-self: center;
`;

const MainValue = styled(MainIndicator)`
  font-weight: 500;
`;

export default Prices;
