import styled, { css } from 'styled-components';
import { BaseButton } from '../../../../styled/buttons/buttons';
import { mqLandscape, mqTablet } from '../../../../styled/helpers/helpers';
import { COLOR_2, COLOR_6 } from '../../../../styled/variables/variables';

export const FieldsFormWrapper = styled.div`
  display: flex;
  padding: 0px;
@media (max-width: 770px) {
  flex-direction: column;
}
`;

export const PropertyAndBedroomWrapper = styled.div`
  display: flex;
  padding: 0px;
  flex: 2;

  /* @media (max-width: 480px) {
    flex-direction: column;
  } */
`;

export const ItemFormWrapper = styled.div`
  flex: 1;
  background-color: #ffffff;
  padding: 8px 12px 4px;

  margin-bottom: 5px;

  display: flex;
  align-items: center;

  border: 1px solid #777;

  &.type, &.bedrooms {
    .react-select__indicators {
      display: block !important;
    }
  }

  & + div {
    margin-left: 5px;

    @media (max-width: 770px) {
      margin-left: 0px;

      &.bedrooms {
        margin-left: 5px;
      }
    }

    @media (max-width: 480px) {
      &.bedrooms {
        margin-left: 0px;
      }
  }
  }
`;

export const SelectWrapper = styled.div`
  flex: 1;

  > label {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
  }
`;

export const Wrapper = styled.form`
  width: 100%;
  align-self: center;
  display: flex;
  flex-direction: column;

  ${mqTablet`
    max-width: 700px;
  ` };

  ${mqLandscape(200)`
    margin-top: 70px;
  `};
`;

export const SearchBarWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Button = styled(BaseButton).attrs({
  type: 'submit',
})`
  background-color: ${COLOR_6};
  color: ${COLOR_2};
  height: 50px;
  padding: 0 10px;
  width: 100%;
  margin-top: 5px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  border: none;
`;
