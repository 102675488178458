import styled,{ css } from 'styled-components';

import { iconBaseStyle } from '../../styled/helpers/helpers';
import { COLOR_6 } from '../../styled/variables/variables';

export const Wrapper = styled.div`
  align-self: left;
  display: flex;
`;

export const starStyle = css`
  ${ iconBaseStyle };
  color: ${ COLOR_6 };
  margin-right: 3px;

  &:last-child {
    margin-right: 0;
  }
`;