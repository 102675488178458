import React, { PureComponent } from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';

import { COLOR_13, COLOR_2 } from '../../../styled/variables/variables';
import { BaseButton } from '../../../styled/buttons/buttons';

import { PlansExpose } from '../../../components/plans-expose';
import { TYPES, MODES } from '../../../components/plans-expose/PlansExpose';
import BillingInfo from '../billing-info/BillingInfo';

import planService from '../../../services/plan/plan-v2.service';

import queryStringUtil from '../../../utils/query-string/query-string.util';
import pathsConstants from '../../../constants/paths';

class Plans extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      plans: [],
      loaded: false,
      selectedPlan: '',
      flow: '',
      planType: null,
    };
  }

  componentWillMount() {
    const { history } = this.props;
    const queryParams = queryStringUtil.parse(history);
    const { flowType } = queryParams;

    this.setState({ flow: flowType })
  }

  componentDidMount() {
    this.getPlans();
  }

  setPlansQuantity = async (plans) => {
    const plansLength = plans.length;
    let plan = [];
    let isBestSeller = false;
    for (let i = 0; i < plansLength; i++) {
      if (i === plansLength - 1) {
        isBestSeller = true;
      }
      plan.push(
        {
          key: TYPES.MONTHLY,
          name: 'mensal',
          price: 99,
          ident: '',
          isBestSeller: isBestSeller,
          features: [
            {
              key: `${TYPES.MONTHLY}_ads`,
              indicator: 'anúncios',
              value: '1',
            },
            {
              key: `${TYPES.MONTHLY}_pictures`,
              indicator: 'dias contratados',
              value: '30',
            },
            {
              key: `${TYPES.MONTHLY}_analysis`,
              indicator: 'descrição',
              value: 'ilimitado',
            },
          ],
        }
      )
    }
    await this.setState({ plans: plan });
    return plan;
  }

  getPlans = async () => {
    const { user, history } = this.props;
    const flowType = this.state.flow;
    let type = flowType === 'OWNER' ? 2 : 3;

    if (!user || !user.token) {
      history.push(`${pathsConstants.AUTHENTICATION}/${pathsConstants.AUTHENTICATION_PARAMS.LOGIN}`);
      return;
    }

   const { data } = await planService.getPlans({
      token: user.token,
      type: type,
    });

    this.setState(prevState => ({
      ...prevState,
      plans: data.data,
      loaded: true,
    }));
  };

  handleSelectButtonClick = (planId, planInfo) => {
    localStorage.setItem('planInfo', JSON.stringify(planInfo));
    this.setState({ selectedPlan: planInfo });
  };

  render() {
    const { history, user, location } = this.props;
    const { selectedPlan, flow: flowType } = this.state;

    let cardBrands = [{
      value: '',
      label: 'Selecione a bandeira'
    }];

    if (selectedPlan) {
      cardBrands.push(...selectedPlan.cardBrands.map(item => {
        return {
          label: String(item.value).toUpperCase(),
          value: item.key,
        }
      }));
    }

    return (
      <Wrapper>
        {this.state.loaded &&
          <PlansExpose
            configs={this.state.plans}
            onPlanClick={this.handleSelectButtonClick}
            mode={MODES.ACQUISITION}
            selectedPlan={selectedPlan}
          />
        }
        {selectedPlan.price === 0 && flowType === 'OWNER' ?
          <FinishButton>
            Continuar
          </FinishButton>
          :
          <div style={{ marginTop: 100 }}>
            <BillingInfo
              history={history}
              user={user}
              userAddress={location.state.userAddress || null}
              showPayment={selectedPlan.price > 0}
              flowType={flowType}
              cardBrands={cardBrands}
            />
          </div>
        }
      </Wrapper>
    );
  }
}

Plans.propTypes = {
  history: propTypes.object,
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const FinishButton = styled(BaseButton)`
  background-color: ${COLOR_13};
  border: none;
  color: ${COLOR_2};
  width: 170px;
  align-self: center;
  margin-top: 50px;
`;

export default Plans;
