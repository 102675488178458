import React, { PureComponent } from 'react';
import propTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { transitions } from 'polished';

import { BaseLabel } from '../../styled/labels/labels';
import { COLOR_2, COLOR_5 } from '../../styled/variables/variables';
import { mqTablet, pointer, backgrounder, dimmer, mqDesktop } from '../../styled/helpers/helpers';

import Features from './features';
import ImageSlider from '../image-slider/ImageSlider';

import currencyUtil from '../../utils/currency/currency.util';
import pathConstants from '../../constants/paths';

class RecommendationAdCard extends PureComponent {
  constructor(props) {
    super(props);

    this.imageSliderRef = React.createRef();
  }

  handleMouseOver = () => {
    // this.imageSliderRef.current.startSlideshow();

    const { onMouseOver } = this.props;
    if (onMouseOver) onMouseOver();
  };

  handleMouseOut = () => {
    this.imageSliderRef.current.stopSlideshow();
    const { onMouseOut } = this.props;
    if (onMouseOut) onMouseOut();
  };

  render() {
    const {
      className,
      city,
      neighborhood,
      price,
      images,
      property,
      transaction,
      bedrooms,
      area,
      adId,
      opacify,
      height,
      tabletHeight,
      desktopHeight,
      listing,
      slug,
    } = this.props;

    return (
      <Wrapper
        className={className}
        onMouseOver={this.handleMouseOver}
        onMouseOut={this.handleMouseOut}
        opacify={opacify}
        href={`${pathConstants.AD_DETAIL}/${slug}`}
      >
        <ImageSlider
          images={[images[0]]}
          ref={this.imageSliderRef}
          playSlideshow={false}
          slideshowInterval={2000}
          height={height}
          tabletHeight={tabletHeight}
          desktopHeight={desktopHeight}
        />
        <InnerWrapper
          tabletHeight={tabletHeight}
          desktopHeight={desktopHeight}
        >
          <MailInfoWrapper>
            <RealEstateTypeLabel>[ {property} ]</RealEstateTypeLabel>
            <CityLabel>{city}</CityLabel>
            <NeighborhoodLabel>{neighborhood}</NeighborhoodLabel>
            <Features
              {...{
                bedrooms,
                area,
              }}
            />
            <PriceLabel>
              {price.main[0] === 0 ? 'sob consulta' : `${listing === 'Lançamento' ? 'A partir de' : transaction}: ${currencyUtil.format({
                value: price.main,
              })
                }`}
            </PriceLabel>
          </MailInfoWrapper>
        </InnerWrapper>
      </Wrapper>
    );
  }
}


RecommendationAdCard.propTypes = {
  className: propTypes.string,
  city: propTypes.string.isRequired,
  neighborhood: propTypes.string.isRequired,
  price: propTypes.object.isRequired,
  images: propTypes.arrayOf(propTypes.object).isRequired,
  property: propTypes.string.isRequired,
  transaction: propTypes.string.isRequired,
  bedrooms: propTypes.number.isRequired,
  area: propTypes.number.isRequired,
  opacify: propTypes.bool,
  onClick: propTypes.func,
  onMouseOver: propTypes.func,
  onMouseOut: propTypes.func,

  // Height attributes.
  height: propTypes.string,
  tabletHeight: propTypes.string,
  desktopHeight: propTypes.string,
};

RecommendationAdCard.defaultProps = {
  height: '250px',
  tabletHeight: '250px',
  desktopHeight: '250px',
};

const Wrapper = styled.a`
  display: flex;
  flex-direction: column;
  ${pointer};
  align-self: center;
  height: 250px;
  width: 100%;
  background: ${COLOR_5};
  border-radius: 1px;;
  overflow: hidden;
  margin: 5px;
  text-decoration: none;
  ${({ backgroundImage }) => backgrounder(backgroundImage)};
  border: solid 1px lightgray;

  ${mqTablet`
    width: 350px;
  ` }

  ${transitions(['opacity'], 'ease .3s')}
  ${({ opacify }) => opacify && css`
    opacity: 0.5;
  ` }
`;

const InnerWrapper = styled.div`
  height: 100%;
  width: 100%;
  ${dimmer(0.6, 'black')}
  margin-top: -250px;

  display: flex;
  flex-direction: column;
  padding: 10px;
  position: relative;

  ${mqTablet`
    ${({ tabletHeight }) => css`
      margin-top: -${tabletHeight};
    ` }
  ` }

  ${mqDesktop`
    ${({ desktopHeight }) => css`
      margin-top: -${desktopHeight};
    ` }
  ` }
`;

const MailInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  height: 1px;
`;

const RealEstateTypeLabel = styled(BaseLabel)`
  color: ${COLOR_2};
  align-self: center;
  font-size: 12px;
  margin-bottom: 5px;
  font-weight: 200;
`;

const CityLabel = styled(RealEstateTypeLabel)`
  font-size: 18px;
  font-weight: 500;

  ${mqTablet`
    font-size: 24px;
  ` }
`;

const NeighborhoodLabel = styled(RealEstateTypeLabel)`
  font-size: 12px;
  align-self: center;
  margin-bottom: 10px;

  ${mqTablet`
    font-size: 16px;
    margin-bottom: 30px;
  ` }
`;

const PriceLabel = styled(RealEstateTypeLabel)`
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0;

  ${mqTablet`
    font-size: 16px;
  ` }
`;

export default RecommendationAdCard;
