import styled from 'styled-components';

export const GrupoZapWrapper = styled.div`
  max-width: 420px;

  > h3 {
    font-size: 28px;
    font-weight: 600;
  }

  > div {
    display: flex;
    flex-direction: column;

    margin-top: 16px;
  }

  & + div {
    margin-top: 24px;
  }
`;

export const UrlXmlWrapper = styled.div`
  max-width: 420px;

  display: flex;
  align-items: center;
`;

export const ClipboardUrlXml = styled.button`
  color: #333333;
  background: transparent;

  transition: color .2s;

  &:hover {
    color: #222222;
  }
`;

export const UrlXmlLink = styled.div`
  max-width: 380px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;

  > a {
    color: #2B50D8;
  }
`;

export const ReceiveLeadsUrlText = styled.p`
  margin-top: 24px;
  margin-bottom: 8px;
  font-weight: 500;
`;
