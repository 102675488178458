import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { CircularProgress } from '@material-ui/core';

import userService from '../../../services/user/user.service';

import {
  Wrapper,
  Texture,
  ContentWrapper,
  Logo,
  Content,
  CheckingAccountWrapper,
  AccountCardsContainer,
  ChooseAccount,
  ChooseAccountList,
  AccountCard,
  OwnAccountContainer,
  LinkedAccountContainer,
  ErrorAccountWrapper
} from './styles';
import storageUtil from '../../../utils/storage/storage.util';

export function HandlingAccount({ history }) {
  const [loadingUserToken, setLoadingUserToken] = useState(true);
  const [state, setState] = useState({
    chooseAccount: false,
    userOwnAccount: [],
    userLinkedAccounts: [],
    userAccountSelected: {},
  });
  const [userIdToken, setUserIdToken] = useState(null);
  const [proceedLogin, setProceedLogin] = useState(false);

  const { isAuthenticated, getIdTokenClaims, error: authError, loginWithRedirect } = useAuth0();

  // Essa função realiza a busca das contas vinculadas ao usuário e as identifica
  async function handleGetUserAccounts({ email, token = null }) {
    try {
      const { data, status } = await userService.getUserAccounts({ userEmail: email });

      if (status !== 200) {
        throw new Error('Não encontramos o seu usuário, tente novamente!');
      }

      const userHasLinkedAccount = data.find(item => !!item.company);

      if (!!userHasLinkedAccount && data.length > 1) {
        const userOwnAccount = data.filter(item => !item.company);
        const userLinkedAccounts = data.filter(item => !!item.company);

        setState(prevState => ({
          ...prevState,
          userOwnAccount,
          userLinkedAccounts,
          chooseAccount: true,
        }));
      } else {
        setState(prevState => ({
          ...prevState,
          userAccountSelected: data[0],
        }));

        handleProceedLogin(data[0], token);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingUserToken(false);
    }
  }

  useEffect(() => {
    async function getUserToken() {
      try {
        const user = await getIdTokenClaims();

        setUserIdToken(user.__raw);
        handleGetUserAccounts({ email: user.email, token: user.__raw });
      } catch (err) {
        console.log(err);
        setLoadingUserToken(false);
      }
    }

    if (isAuthenticated) {
      getUserToken();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (authError) {
      setLoadingUserToken(false)
    }
  }, [authError]);

  function setCookie(c_name, value, exdays) {
    var exdate = new Date();
    exdate.setDate(exdate.getDate() + exdays);
    var c_value =
      escape(value) +
      (exdays == null ? "" : "; expires=" + exdate.toUTCString()) +
      "; path=/; domain=koort.com.br";

    document.cookie = c_name + "=" + c_value;

    var c_value =
      escape(value) +
      (exdays == null ? "" : "; expires=" + exdate.toUTCString()) +
      "; path=/; domain=localhost";

    document.cookie = c_name + "=" + c_value;
  };

  async function handleProceedLogin(userAccount, token = null) {
    try {
      setProceedLogin(true);
      setState(prevState => ({
        ...prevState,
        chooseAccount: false,
      }));

      const { data, status } = await userService.getUserTokenWithUserId({ token: `${token !== null ? token : userIdToken}${userAccount._id}`, userId: userAccount._id });

      if (status !== 200) {
        throw new Error('Não foi possível buscar as informações do usuário!');
      }

      const { user, accessToken } = data;

      const userData = {
        ...user,
        token: accessToken,
      };

      storageUtil.set("user", userData);
      setCookie("user", JSON.stringify(userData));
      setCookie("AUTH_USER_TOKEN_KEY", accessToken);
      localStorage.setItem("user", JSON.stringify(userData));
      localStorage.setItem("AUTH_USER_TOKEN_KEY", accessToken);

      const pathRedirect = localStorage.getItem('@Koort:PathRedirect');

      if (pathRedirect) {
        localStorage.removeItem('@Koort:PathRedirect');

        history.push(pathRedirect);
      } else {
        history.push('/');
      }

    } catch (err) {
      console.log(err);
      setProceedLogin(false);
    }
  }

  return (
    <Wrapper>
      <Texture />
      <ContentWrapper>
        <Logo />

        <Content>
          {loadingUserToken && (
            <CheckingAccountWrapper>
              <p>Estamos verificando sua conta para garantir sua segurança.</p>
              <p>Aguarde um momento.</p>

              <CircularProgress color="#ffffff" />
            </CheckingAccountWrapper>
          )}

          {authError && authError.message === "User did not authorize the request" && (
            <ErrorAccountWrapper>
              <p>Para prosseguir com o cadastro, é necessário que você autorize o acesso.</p>


              <button type="button" onClick={() => loginWithRedirect({ authorizationParams: { redirect_uri: `${window.location.origin}/authentication/login/success?login=true` } })}>
                Autorizar acesso
              </button>
            </ErrorAccountWrapper>
          )}

          {!loadingUserToken && proceedLogin && (
            <CheckingAccountWrapper>
              <p>Acessando sua conta.</p>

              <CircularProgress color="#ffffff" />
            </CheckingAccountWrapper>
          )}

          {state.chooseAccount && (
            <ChooseAccount>
              <strong>Escolha uma conta para prosseguir</strong>

              <AccountCardsContainer>
                <ChooseAccountList>
                  {!!state.userOwnAccount.length && (
                    <OwnAccountContainer>
                      <h3>Conta própria</h3>

                      {state.userOwnAccount.map(item => (
                        <AccountCard
                          key={item._id}
                          onClick={() => handleProceedLogin(item)}
                        >
                          <img src={item.avatarUrl} />

                          <div>
                            <h3>{item.name}</h3>
                            <span>{item.email}</span>
                          </div>
                        </AccountCard>
                      ))}
                    </OwnAccountContainer>
                  )}

                  {!!state.userLinkedAccounts.length && (
                    <LinkedAccountContainer>
                      <h3>Contas vinculadas</h3>

                      {state.userLinkedAccounts.map(item => (
                        <AccountCard
                          key={item._id}
                          onClick={() => handleProceedLogin(item)}
                        >
                          <img src={item.avatarUrl} />

                          <div>
                            <h3>{item.name}</h3>
                            <span>{item.company.name}</span>
                          </div>
                        </AccountCard>
                      ))}
                    </LinkedAccountContainer>
                  )}
                </ChooseAccountList>
              </AccountCardsContainer>
            </ChooseAccount>
          )}
        </Content>
      </ContentWrapper>
    </Wrapper>
  );
}
