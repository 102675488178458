import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import Cover from '../../dashboard/cover/Cover';
import { LeadContent } from './lead-content';

import userService from '../../../services/user/user.service';

export function Lead() {
  const { loginWithRedirect } = useAuth0();

  if (!userService.retrieve()) {
    const pathRedirect = `${window.location.href.replace(window.location.origin, '')}`;
    localStorage.setItem('@Koort:PathRedirect', pathRedirect);

    loginWithRedirect({ authorizationParams: { redirect_uri: `${window.location.origin}/authentication/login/success?login=true` } });
  };

  return (
    <>
      <Cover
        name={userService.retrieve().name}
        pictureUrl={userService.retrieve().profile.image}
      />

      <LeadContent />
    </>
  );
}
