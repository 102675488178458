import React from 'react';
import propTypes from 'prop-types';

import { Wrapper, FacebookIcon, TwitterIcon, InstagramIcon } from './styles';

const SocialIcons = ({
  className,
}) => (
  <Wrapper className={className}>
    <FacebookIcon onClick={() => { window.open("https://www.facebook.com/koort.com.br") }} />
    <TwitterIcon onClick={() => { window.open("https://twitter.com/ImoveisKoort") }} />
    <InstagramIcon onClick={() => { window.open("https://www.instagram.com/koort_imoveis/") }} />
  </Wrapper>
);

SocialIcons.propTypes = {
  className: propTypes.string,
};

SocialIcons.defaultProps = {};

SocialIcons.defaultProps = {
  show: true,
};



export default SocialIcons;
