import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { bigSectionBaseStyle, mqTablet } from '../../../styled/helpers/helpers';
import { COLOR_1, COLOR_2 } from '../../../styled/variables/variables';
import { SecondaryButton } from '../../../styled/buttons/buttons';
import { BaseLabel } from '../../../styled/labels/labels';

import Title from './title/Title';
import Slider from './slider/Slider';
import Footer from '../../../partials/footer/Footer'
import Arrows from '../cover/arrows/Arrows';

import stringUtil from '../../../utils/string/string.util';
import windowUtil from '../../../utils/window/window.util';
import pathConstants from '../../../constants/paths';

const handleShowMoreButton = () => windowUtil.scrollToElement({
  element: Footer.ID
})

class HighlightedAgents extends PureComponent {

  static ID = `${HighlightedAgents.name}_${stringUtil.generateUniqueId()}`;
  render() {
    return (
      <Wrapper as="section">
        <ContentWrapper>
          <TopWrapper>
            <Title />
            <Text>
              Confira alguns dos corretores e imobiliárias que podem facilitar sua vida na hora de vender ou alugar seu imóvel.
            </Text>
            <Link to={pathConstants.REALTORS_SEARCH} style={{ textDecoration: 'none',}}>
              <SecondaryButton>Buscar</SecondaryButton>
            </Link>
          </TopWrapper>
          <Slider history={this.props.history} />
        </ContentWrapper>
        <SeeMoreButton onClick={() => handleShowMoreButton()}>
          <Arrows color={COLOR_2} />
        </SeeMoreButton>
      </Wrapper>
    );
  }
}

HighlightedAgents.ID = `${HighlightedAgents.name}_${stringUtil.generateUniqueId()}`;

const Wrapper = styled.div.attrs({
  id: HighlightedAgents.ID,
})`
  ${bigSectionBaseStyle}
    background-color: ${COLOR_1};
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${mqTablet`
    flex-direction: row;
  ` }
`;

const TopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  align-self: center;
  ${mqTablet`
    margin-right: 30px;
  ` }

  @media (max-width: 767px) {
    align-items: center;
  }
`;

const Text = styled(BaseLabel)`
  color: ${COLOR_2};
  text-align: center;
  opacity: .75;
  line-height: 24px;
  width: 280px;
  align-self: center;
  margin-bottom: 30px;

  ${mqTablet`
    text-align: left;
    width: 200px;
  ` }
`;

const SeeMoreButton = styled.div`
  display: none;

  ${mqTablet`
      display: flex;
      justify-content: center;
      align-self: center;
      align-items: center;

  `}
`;

export default HighlightedAgents;
