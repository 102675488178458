import styled, { css } from 'styled-components';
import Modal from 'react-modal';

import { BaseLabel } from '../../styled/labels/labels';

export const GroupButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const OriginLeadsSelect = styled.div``;

export const SettingsButton = styled.button`
  background-color: transparent;
  color: #2B50D8;

  transition: filter .2s;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  margin-bottom: 40px;

  @media(max-width: 1050px) {
    padding: 0 16px;
  }
`;

export const Title = styled.h1`
  margin-top: 48px;
  font-size: 32px;
  font-weight: bold;
`;

export const ChartAndBrokersContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`;

export const UserSettingsModal = styled(Modal).attrs({
  style: {
    content: {
      backgroundColor: 'transparent',
      border: 0,
      padding: 0,
      inset: 0,
      borderRadius: 0,
      overflowY: 'hidden',
      overflowX: 'hidden',
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  }
})``;

export const UserSettingsModalBody = styled.div`
  background: #ffffff;
  height: 85vh;
  width: 100vw;
  max-width: 600px;
  border-radius: 8px;
  padding: 18px;

  position: relative;
`;

export const UserSettingsModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;


  h3 {
    font-size: 20px;
    font-weight: 700;

    display: flex;
    align-items: center;

    > svg {
      margin-right: 12px;
    }
  }

  button {
    width: 42px;
    height: 42px;
    border-radius: 8px;
    background-color: transparent;
    font-size: 0;
    margin-left: 16px;

    transition: background-color .2s;

    &:hover {
      background-color: #EEF0F4;
    }
  }
`;

export const UserSettingsModalContent = styled.div`
  padding-right: 16px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 85%;
`;

export const GroupSelect = styled.div`
  & + div {
    margin-top: 18px;
  }
`;

export const Label = styled(BaseLabel)`
  font-weight: 500;
  font-size: 14px;
  color: #333;
  align-self: center;
  margin-bottom: 8px;
`;

export const UserSettingsActionFeedback = styled.div`
  height: 54px;
  padding: 16px;
  width: 100%;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  margin-top: 18px;

  background-color: transparent;
  color: #FFFFFF;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: all .2s;

  ${(props) => props.isSubmitting && css`
    background-color: #2B50D8;
  `}

  ${(props) => props.isSuccess && css`
    background-color: #8ACC2C;
  `}

  ${(props) => props.isError && css`
    background-color: #dc3545;
  `}
`;

export const UserSettingsModalSectionTitle = styled.span`
  display: block;
  font-weight: 600;
  font-size: 16px;
  color: #222;
  margin-bottom: 18px;
`;

export const UserSettingsModalNotificationArea = styled.div`
  margin-top: 16px;
  background-color: #F6F6F6;
  border-radius: 8px;
  padding: 16px;
`;

export const C2SFormWrapper = styled.form`
  display: flex;

  input {
    border: 1px solid #999;
    background-color: transparent;
    height: 50px;
    padding: 8px 16px;
    border-radius: 4px;
    margin-right: 12px;
    flex: 1;

    &::placeholder {
      color: #777777;
    }
  }

  > div {
    display: flex;

    > button {
      border: 1px solid #999;
      background-color: transparent;
      padding: 8px 16px;
      border-radius: 4px;

      transition: filter 0.2s;

      &:not(:disabled):hover {
        filter: brightness(0.8);
      }

      &:disabled {
        cursor: not-allowed;
        opacity: 0.7;
      }
    }

    .removeC2SButton {
      border: 0;
    }
  }

  @media (max-width: 475px) {
    flex-direction: column;

    input {
      width: 100%;
      flex: unset;
    }

    > div {
      margin-top: 8px;

      > button {
        width: 48%;
      }
    }
  }
`;

export const C2SLink = styled.span`
  display: block;
  font-size: 13px;

  margin-top: -6px;
  margin-bottom: 8px;

  opacity: 0.7;
`;
