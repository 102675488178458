import styled, { css } from 'styled-components';

export const Container = styled.main`
  min-height: 100vh;
  width: 100vw;
  padding-top: 80px;
`;

export const HeadlineContainer = styled.section`
  background-image: ${({ bgImg }) => `url(${bgImg})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100% 40%;
  height: 60vh;
  width: 100vw;

  position: relative;
`;

export const HeadlineOverlay = styled.div`
  background-image: linear-gradient(180deg, #111 0%, rgba(17, 17, 17, 0.73) 26.56%, rgba(17, 17, 17, 0.00) 100%);

  position: absolute;
  inset: 0;

  h1 {
    color: #FFFFFF;
    font-size: 42px;
    text-align: center;
    margin-top: 16vh;
    font-weight: 700;
  }

  p {
    color: #FFFFFF;
    font-size: 20px;
    max-width: 665px;
    text-align: center;
    margin: 0 auto;
    margin-top: 8px;
    font-weight: 400;
  }

  @media (max-width: 1270px) {
    padding: 0 24px;
  }
`;

export const FloatingCTABox = styled.div`
  display: flex;
  justify-content: center;

  position: sticky;
  top: 40px;
  z-index: 99;

  ${({ floating }) => floating && css`
    visibility: hidden;
  `}

  ${({ isTop }) => isTop && css`
    position: fixed;
    width: 100%;
  `}
`;

export const FloatingCTABoxContent = styled.div`
  position: absolute;
  bottom: -40px;

  /* background-color: #FFF; */
  box-shadow: 1px 1px 20px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
  max-width: 1000px;
  height: 80px;
  padding: 20px 24px;

  display: flex;
  flex-direction: row;

  background: linear-gradient(0deg, #000 50%, #fff 50%);
  background-size: 100% 200%;
  transition: background-position 0.2s;

  ${({ isTop, isMozillaNavigator }) => isTop && css`
    max-width: 1000px;
    /* background-color: #000; */
    background-position: 0 100%;
    padding: 20px 0px;

    ${isMozillaNavigator && css`
      padding-left: 24px;
      margin-left: 18px;
    `}

    @media (max-width: 1200px) {
      max-width: 860px;

      ${isMozillaNavigator && css`
        max-width: 880px;
      `}
    }

    @media (max-width: 1030px) {
      max-width: 830px;

      ${isMozillaNavigator && css`
        max-width: 860px;
      `}
    }

    @media (max-width: 1010px) {
      max-width: 810px;

      ${isMozillaNavigator && css`
        max-width: 840px;
      `}
    }

    @media (max-width: 992px) {
      max-width: 780px;

      ${isMozillaNavigator && css`
        max-width: 820px;
      `}
    }

    @media (max-width: 958px) {
      max-width: 750px;

      ${isMozillaNavigator && css`
        max-width: 782px;
      `}
    }

    @media (max-width: 930px) {
      max-width: 724px;

      ${isMozillaNavigator && css`
        max-width: 760px;
      `}
    }

    @media (max-width: 896px) {
      max-width: 694px;

      ${isMozillaNavigator && css`
        max-width: 724px;
      `}
    }

    @media (max-width: 868px) {
      max-width: 664px;

      ${isMozillaNavigator && css`
        max-width: 694px;
      `}
    }

    @media (max-width: 840px) {
      max-width: 636px;

      ${isMozillaNavigator && css`
        max-width: 670px;
      `}
    }

    @media (max-width: 808px) {
      max-width: 604px;

      ${isMozillaNavigator && css`
        max-width: 638px;
      `}
    }

    @media (max-width: 775px) {
      max-width: 590px;

      ${isMozillaNavigator && css`
        max-width: 624px;
      `}
    }

    @media (max-width: 734px) {
      max-width: 560px;

      ${isMozillaNavigator && css`
        max-width: 590px;
      `}
    }

    @media (max-width: 710px) {
      max-width: 534px;

      ${isMozillaNavigator && css`
        max-width: 564px;
      `}
    }

    @media (max-width: 679px) {
      max-width: 504px;

      ${isMozillaNavigator && css`
        max-width: 534px;
      `}
    }

    @media (max-width: 644px) {
      max-width: 470px;

      ${isMozillaNavigator && css`
        max-width: 500px;
      `}
    }

    @media (max-width: 612px) {
      max-width: 440px;

      ${isMozillaNavigator && css`
        max-width: 470px;
      `}
    }

    @media (max-width: 580px) {
      max-width: 406px;

      ${isMozillaNavigator && css`
        max-width: 436px;
        margin-left: 12px;
      `}
    }

    @media (max-width: 545px) {
      max-width: 370px;

      ${isMozillaNavigator && css`
        max-width: 408px;
      `}
    }

    @media (max-width: 515px) {
      max-width: 350px;

      ${isMozillaNavigator && css`
        max-width: 380px;
      `}
    }

    @media (max-width: 488px) {
      max-width: 314px;

      ${isMozillaNavigator && css`
        max-width: 344px;
      `}
    }

    @media (max-width: 453px) {
      max-width: 300px;

      ${isMozillaNavigator && css`
        max-width: 344px;
      `}
    }

    @media (max-width: 446px) {
      max-width: 270px;

      ${isMozillaNavigator && css`
        max-width: 300px;
      `}
    }

    @media (max-width: 408px) {
      max-width: 240px;

      ${isMozillaNavigator && css`
        max-width: 260px;
      `}
    }
  `}

  ${({ isTop }) => !isTop && css`
    @media (max-width: 1080px) {
      max-width: calc(100vw - 48px);
    }

    @media (max-width: 535px) {
      height: 100px;
      bottom: -100px;
    }

    @media (max-width: 474px) {
      flex-direction: column;
      height: 140px;
      bottom: -100px;
    }
  `}
`;

export const PriceGroup = styled.div`
  display: flex;
  align-items: center;

  width: max-content;

  button {
    background-color: transparent;
    font-size: 0;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.8);
    }
  }

  div {
    margin-left: 24px;

    color: #000000;
    font-size: 12px;
    font-weight: 400;

    strong {
      font-size: 16px;
      font-weight: 700;
    }
  }

  @media (max-width: 474px) {
    width: 100%;
    justify-content: center;
  }

  ${({ isTop }) => isTop && css`
    div {
      color: #fff;
      margin-left: 16px;

      strong {
        color: #fff;
      }
    }

    @media (max-width: 515px) {
      margin-right: 18px;

      div {
        strong {
          font-size: 12px;
        }
      }
    }

    @media (max-width: 474px) {
      margin-right: 18px;
      justify-content: unset;
    }
  `}
`;

export const SeparatorLine = styled.div`
  width: 1px;
  height: 30px;
  background: rgba(0, 0, 0, 0.10);
  margin: auto 42px;

  @media (max-width: 474px) {
    display: none;
  }

  ${({ isTop }) => isTop && css`
    @media (max-width: 840px) {
      margin: auto 24px;
    }

    @media (max-width: 775px) {
      margin: auto 12px;
    }

    @media (max-width: 734px) {
      display: none;
    }
  `}
`;

export const CompanyGroup = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 120px;
    height: 40px;
    object-fit: cover;
    margin-right: 24px;

    ${({ isTop }) => isTop && css`
      height: 60px;

        @media (max-width: 1040px) {
          display: none;
        }
    `}
  }

  span {
    color: #000000;
    font-size: 12px;

    strong {
      font-size: 14px;
    }

    ${({ isTop }) => isTop && css`
    color: #fff;

    strong {
      color: #fff;
    }
  `}
  }

  @media (max-width: 900px) {
    img {
      display: none;
    }
  }

  @media (max-width: 760px) {
    display: none;
  }

  ${({ isTop }) => isTop && css`
    @media (max-width: 734px) {
      display: none;
      }
  `}
`;

export const CTAButton = styled.button`
  margin-left: auto;
  padding: 12px 32px;
  background-color: #96D22D;

  color: #FFF;
  font-size: 14px;
  font-weight: 700;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }

  @media (max-width: 474px) {
   margin-left: unset;
   margin-top: 16px;
  }

  ${({ isTop }) => isTop && css`
    @media (max-width: 515px) {
      padding: 12px 14px;
      font-size: 14px;
    }

    @media (max-width: 488px) {
      height: 48px;
      margin-top: -4px;
    }

    @media (max-width: 453px) {
      width: 200px;
    }
  `}

`;

export const IntroductionContainer = styled.section`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 154px 0;

  > div {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  @media (max-width: 1270px) {
    padding: 154px 24px;
  }

  @media (max-width: 1180px) {
    > div {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
`;

const TitleStyleBase = styled.h2`
  color: #000000;
  font-size: 32px;
  font-weight: 700;

  strong {
    color: #111111;
    font-size: 14px;
    font-weight: 400;
    display: block;
  }

  @media (max-width: 1270px) {
    font-size: 28px;
  }
`;

const DescriptionStyleBase = styled.div`
  color: #000000;
  font-size: 20px;

  @media (max-width: 1270px) {
    font-size: 18px;
  }
`;

export const IntroductionTitleGroup = styled(TitleStyleBase)`
  width: 100%;
  max-width: 528px;

  @media (max-width: 1180px) {
    max-width: 640px;
    text-align: center;
  }
`;

export const IntroductionDescriptionGroup = styled(DescriptionStyleBase)`
  width: 100%;
  max-width: 592px;

  @media (max-width: 1180px) {
    max-width: unset;
    text-align: center;
    margin-top: 42px;
  }
`;

export const FeaturesList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 auto;

  display: flex;
  max-width: 1120px;
  margin-top: 92px;
  gap: 8px;

  li {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 14px;

    color: #616161;
    font-size: 14px;
    background-color: #F3F3F3;

    span {
      display: block;
      width: 24px;
      margin-right: 18px;
    }
  }

  @media (max-width: 1060px) {
    flex-wrap: wrap;

    li {
      min-width: 320px;
      padding: 24px 14px;
    }
  }
`;

export const DetailingContainer = styled.section`
  background-color: #F5FBEA;
`;

export const DetailingContent = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 72px 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    width: 550px;
    margin-left: 64px;
  }

  @media (max-width: 1270px) {
    padding: 154px 24px;
  }

  @media (max-width: 1180px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > div {
      button {
        margin: 0 auto;
      }
    }

    img {
      display: none;
    }
  }
`;

export const DetailingTitleGroup = styled(TitleStyleBase)`
  width: 100%;
  max-width: 526px;

  @media (max-width: 1180px) {
    max-width: unset;
    text-align: center;
  }
`;

export const DetailingDescriptionGroup = styled(DescriptionStyleBase)`
  width: 100%;
  max-width: 550px;
  margin-top: 18px;
  margin-bottom: 48px;

  @media (max-width: 1180px) {
    max-width: unset;
    text-align: center;
    margin-top: 42px;
  }
`;

export const GalleryContainer = styled.section`
  width: 100%;
  max-width: 1150px;
  margin: 0 auto;
  padding: 154px 0;

  @media (max-width: 1333px) {
    padding: 154px 24px;
    max-width: 1020px;
  }

  @media (max-width: 1180px) {
    max-width: 900px;
  }

  @media (max-width: 1020px) {
    max-width: 800px;
  }

  @media (max-width: 992px) {
    max-width: 640px;
  }

  @media (max-width: 728px) {
    max-width: 520px;
  }

  @media (max-width: 520px) {
    max-width: 420px;
  }

  @media (max-width: 1180px) {
    > div {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const GalleryTitleGroup = styled(TitleStyleBase)`
  text-align: center;
`;

export const GallerySlideGroup = styled.div`
  margin-top: 42px;

  > div .react-slideshow-container {
    > button:first-child {
      left: -42px;
    }

    > button:last-child {
      right: -42px;
    }
  }
`;

export const GallerySlideItemGroup = styled.div`
  height: 330px;
  cursor: pointer;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border: 4px solid #ffffff;
  }

  @media (max-width: 1180px) {
    height: 330px;
  }
`;

export const GalleryTabGroup = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 auto;
  margin-top: 42px;

  display: flex;
  justify-content: center;
  max-width: 730px;
  gap: 8px;

  @media (max-width: 660px) {
    flex-wrap: wrap;
  }
`;

export const GalleryTabItemGroup = styled.li`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  min-height: 66px;
  max-width: 176px;

  background-color: #E8E8E8;

  transition: filter 0.2s;

  ${({ isSelected }) => isSelected && css`
  background-color: #EAF0DF;
  `}

  button {
    padding: 12px 14px;
    display: flex;
    align-items: center;
    color: #2E2E2E;
    font-size: 14px;
    background-color: transparent;
    text-align: left;
    height: 100%;
    width: 100%;
  }

  span {
    display: block;
    width: 24px;
    margin-right: 18px;
  }

  &:hover {
    filter: brightness(0.9);
  }

  @media (max-width: 660px) {
    min-width: 200px;
  }
`;

export const AboutContainer = styled.section`
  background-color: #F5FBEA;
`;

export const AboutContent = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 72px 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ hasVideo }) => !hasVideo && css`
    justify-content: center;

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  `}

  @media (max-width: 1270px) {
    padding: 72px 24px;
  }

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

export const AboutTitleGroup = styled(TitleStyleBase)`
  width: 100%;
  max-width: 440px;

  ${({ hasVideo }) => !hasVideo && css`
    text-align: center;
    max-width: 720px;
  `}

  @media (max-width: 1200px) {
    max-width: 720px;
    text-align: center;
    margin: 0 auto;
  }
`;

export const AboutDescriptionGroup = styled(DescriptionStyleBase)`
  width: 100%;
  max-width: 477px;
  margin-top: 18px;

  ${({ hasVideo }) => !hasVideo && css`
    text-align: center;
    max-width: 1200px;
    margin-top: 32px;
  `}

  @media (max-width: 1200px) {
    max-width: unset;
    text-align: center;
  }
`;

export const AboutVideoGroup = styled.div`
  margin-left: 64px;
  width: 630px;

  @media (max-width: 1200px) {
    margin-left: unset;
    margin-top: 32px;
  }

  @media (max-width: 730px) {
    width: 480px;
  }

  @media (max-width: 580px) {
    width: 420px;
  }

  @media (max-width: 490px) {
    width: 360px;
  }

  @media (max-width: 440px) {
    width: 300px;
  }
`;

export const CharacteristicsContainer = styled.section`
  width: 100%;
  max-width: 1200px;
  margin: 154px auto;

  @media (max-width: 1270px) {
    padding: 0 24px;
  }
`;

export const CharacteristicsTitleGroup = styled(TitleStyleBase)`
  text-align: center;
`;

export const CharacteristicsGroup = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  margin-top: 32px;
  gap: 20px;
`;

export const CharacteristicsGroupItem = styled.div`
  background-color: #F3F3F3;
  padding: 24px;

  ${({ hasColumn }) => hasColumn && css`
    column-count: 2;
  `}

  flex: 1;

  h3 {
    color: #000000;
    font-size: 20px;
    font-weight: 600;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 18px;

    font-size: 18px;
    color: #000000;

    li {
      display: flex;
      align-items: center;

      span {
        width: 20px;
        margin-right: 8px;
      }

      & + li {
        margin-top: 8px;
      }
    }
  }

  @media (max-width: 1080px) {
    min-width: 320px;
  }

  @media (max-width: 380px) {
    min-width: 280px;
  }
`;

export const ComplementContainer = styled.section`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 154px 0;

  @media (max-width: 1270px) {
    padding: 154px 24px;
  }
`;

export const ComplementTitleGroup = styled(TitleStyleBase)`
  text-align: center;
`;

export const ComplementDescriptionGroup = styled(DescriptionStyleBase)`
  margin-top: 32px;
  text-align: center;
`;

export const BannerContainer = styled.section`
  background-color: #F5FBEA;
`;

export const BannerContent = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 72px 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1270px) {
    flex-direction: column;
    padding: 72px 24px;
  }
`;

export const BannerTitleGroup = styled(TitleStyleBase)`
  max-width: 673px;

  @media (max-width: 1270px) {
    text-align: center;
    margin: 0 auto;
  }
`;

export const BannerDescriptionGroup = styled(DescriptionStyleBase)`
  margin-top: 18px;
  max-width: 833px;

  @media (max-width: 1270px) {
    text-align: center;
    margin-bottom: 42px;
  }
`;

export const LocationContainer = styled.section`
  width: 100%;
  max-width: 1150px;
  margin: 0 auto;
  padding: 154px 0;

  @media (max-width: 1270px) {
    padding: 154px 24px;
  }
`;

export const LocationTitleGroup = styled(TitleStyleBase)`
  text-align: center;

  strong {
    font-size: 20px;
    margin-top: 12px;
  }
`;

export const LocationMapGroup = styled.div`
  margin-top: 42px;
  height: 375px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
`;

export const DataSheetContainer = styled.section`
  width: 100%;
  max-width: 1150px;
  margin: 0 auto;
  padding: 154px 0;
  padding-top: 0;

  @media (max-width: 1270px) {
    padding: 154px 24px;
    padding-top: 0;
  }
`;

export const DataSheetTitleGroup = styled(TitleStyleBase)`
  text-align: center;
`;

export const DataSheetList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 42px;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;

  li {
    color: #000000;
    font-size: 20px;

    text-align: center;

    span {
      display: block;
      margin-bottom: 8px;

      font-size: 16px;
      font-weight: 700;
    }
  }

  @media (max-width: 768px) {
    li {
      font-size: 18px;

      span {
        font-size: 14px;
      }
    }
  }

  @media (max-width: 570px) {
    grid-template-columns: 1fr;
    gap: 28px;
  }
`;

export const CompanyContainer = styled.section`
  width: 100%;
  max-width: 1200px;
  margin: 154px auto;
  margin-top: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: #F3F3F3;
  padding: 64px;

  img {
    width: 320px;
    object-fit: cover;
  }

  @media (max-width: 1180px) {
    flex-direction: column;

    img {
      width: 280px;
      height: 280px;

      margin-top: 42px;
    }
  }
`;

export const CompanyTitleGroup = styled(TitleStyleBase)`
  max-width: 600px;

  @media(max-width: 1180px) {
    text-align: center;
  }
`;

export const CompanyDescriptionGroup = styled(DescriptionStyleBase)`
  margin-top: 18px;
  max-width: 687px;

  @media(max-width: 1180px) {
    text-align: center;
  }
`;

export const RecommendationContainer = styled.section`
  display: flex;
  flex-direction: column;

  @media (max-width: 1270px) {
    padding: 0 24px;
  }
`;

export const MapLocationPlaceholder = styled.div`
  background-image: ${({ placeholderUrl }) => `url(${placeholderUrl})`};
  background-repeat: no-repeat;

  width: 100%;
  height: 100%;
  padding-bottom: 26px;

  display: flex;
  align-items: flex-end;
  justify-content: center;


  > button {
    font-size: 16px;
    font-weight: 600;
    color: #111;
    border-radius: 4px;
    padding: 18px 24px;
    background-color: #fff;
    box-shadow: 1px 1px 20px 0px rgba(0, 0, 0, 0.25);

    transition: background-color 0.2s;

    &:hover {
      background-color: #f0f0f0;
    }
  }
`;
