
import React, { PureComponent } from 'react';
import propTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { transitions } from 'polished';
import { RiRoadMapLine } from 'react-icons/ri';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MapToggle } from '../../filter/components/mapToggle';

import { mqDesktop, scrollBar, scrollingShadow, shadower } from '../../../styled/helpers/helpers';
import { BaseLabel } from '../../../styled/labels/labels';
import { COLOR_2, COLOR_1 } from '../../../styled/variables/variables';

import AdCardInteractive from '../../../components/ad-card-interactive-legacy';

import domEventsUtil from '../../../utils/dom-events/dom-events.util';

/*
  global
*/

class Results extends PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      pageSize: 8,
      pageChanged: false,
    };
  }

  handlePageChanged = () => {
    this.setState(prevState => ({
      ...prevState,
      pageChanged: true,
    }))

    this.props.onResultsPageChanged();
  }

  handleScrollEvent = event => {
    const shouldPaginate = domEventsUtil.hasReachedBottom({
      element: event.target,
    });

    if (!shouldPaginate) {
      this.setState(prevState => ({
        ...prevState,
        pageChanged: false,
      }))
    }

    if (shouldPaginate && !this.state.pageChanged) {
      this.handlePageChanged();
    }
  };

  render() {
    const {
      show,
      realEstates,
      realEstatesPagination,
      onClickItem,
      user,
      onMouseEnter,
      onMouseOut,
      showMap,
      showEmpty,
      realEstatesLoading = false,
      showRealEstatesLoading,
      title,
    } = this.props;

    // const transactionUrl = (new URL(document.location)).searchParams;
    // const transactionType = transactionUrl.get('transaction');

    return (
      <Wrapper show={show} showMap={showMap}>
        {/* <CountLabel showMap={showMap}>{realEstates.length && realEstatesPagination?.total || 0} resultados</CountLabel> */}
        <div className='mapToggle'>
          <CountLabel as="h1" showMap={showMap}>{title}</CountLabel>
          <MapToggle className="toggleMapButton" currentFilterStyle="map" />
        </div>
        <ListWrapper
          onScroll={this.handleScrollEvent}
          showMap={showMap}
        >
          {showRealEstatesLoading && realEstatesLoading && !realEstates.length && (
            <EmptyWrapper>
              <CircularProgress size={24} />
            </EmptyWrapper>
          )}

          {realEstates.map(re => (
            <RAdCardInteractive
              key={re._id}
              user={user}
              // transactionTypeInMapPage={transactionType}
              {...re}
              {...re.price}
              {...re.address}
              favorite={re.favorite}
              onClick={() => onClickItem(re)}
            // ###### RESPONSÁVEL POR SINALIZAR O MARKER NO MAPA ######
            // onMouseEnter={() => onMouseEnter(re)}
            // onMouseOut={() => onMouseOut(re)}
            />
          ))}

          {realEstates.length === 0 && showEmpty && !realEstatesLoading && (
            <EmptyWrapper>
              <RiRoadMapLine size={32} />
              <h3>Não encontramos resultados para a sua busca</h3>
            </EmptyWrapper>
          )}
        </ListWrapper>
      </Wrapper>
    );
  }
}

Results.propTypes = {
  show: propTypes.bool,
  desktopShow: propTypes.bool,
  realEstates: propTypes.arrayOf(propTypes.object),
  onClickItem: propTypes.func,
  onMouseOver: propTypes.func,
  onMouseOut: propTypes.func,
};

Results.defaultProps = {

};

const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 400px;
  text-align: center;

  > h3 {
    font-size: 18px;
    font-weight: 600;
    margin-top: 22px;
  }
`;

const Wrapper = styled.div`
  background-color: ${COLOR_2};
  position: absolute;
  width: 100vw;
  height: calc(100vh - 180px);
  top: 180px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .mapToggle {
    display: flex;
    width: 100%;
    align-items: end;
    margin: 20px 0 10px;
    padding-right: 30px;
    justify-content: space-between;

    .toggleMapButton {
      height: 30px;
      width: 145px;
    }
  }

  ${transitions(['opacity', 'right'], 'ease .6s')};

  ${({ show }) => show ? css`
    opacity: 1;
    right: 0px;
  ` : css`
    opacity: 0;
    right: -100vw;
  ` };

  ${({ showMap }) => !showMap && css`
    width: 100vw !important;
    `}

    transition: width .2s;

  ${mqDesktop`
    position: absolute;
    margin: 0;
    opacity: 1;
    bottom: unset;
    width: 550px;
    height: calc(100vh - 80px);
    right: 10px;
    top: 80px;
    border-radius: 1px;
    ${shadower({ direction: 'left' })}
  ` };

  @media (max-width: 1079px) {
    height: calc(100% - 130px);
    top: 130px;
  }
`;

const CountLabel = styled(BaseLabel)`
  color: #2b2b2b;
  align-self: center;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: unset;
  margin-right: 1rem;
  padding-left: 20px;

  text-align: left;
  align-self: flex-start;

  ${mqDesktop`
    margin-top: 52px;
    text-align: left;
  `}

  ${({showMap}) => !showMap && css`
    padding-left: 32px !important;
  `}
`;

const ListWrapper = styled.div`
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  ${scrollBar}
  ${scrollingShadow}
  padding: 10px;
  padding-top: 0;

  ${mqDesktop`
    justify-content: flex-start;
  ` }

  ${({ showMap }) => !showMap && css`
    padding: 10px 16px;

    ${mqDesktop`
      justify-content: center;
  ` }
  `}
`;

const RAdCardInteractive = styled(AdCardInteractive)`
  align-self: flex-start;

  @media (max-width: 549px) {
    width: 320px;

    img {
      object-fit: cover;
    }
  }

  @media (max-width: 376px) {
    width: 100%;
  }
`;

export default Results;
