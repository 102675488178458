import React from 'react';

import { RiExternalLinkFill } from 'react-icons/ri';

import pathsConstants from '../../../../../../constants/paths';

import { RealEstateFakeInputWrapper } from './styles';

export function RealEstateFakeInput({ label, value, slug }) {
  return (
    <RealEstateFakeInputWrapper>
      <label>{label}</label>
      <span>
        {value}
        <a
          href={`${pathsConstants.AD_DETAIL}/${slug}`}
          target="_blank"
          rel="noreferrer noopener"
        >
          <RiExternalLinkFill size={20} />
        </a>
      </span>
    </RealEstateFakeInputWrapper>
  )
}
