import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { RiArrowLeftLine } from 'react-icons/ri';

import { RealEstateFakeInput } from './components/realestate-fake-input';
import { FakeInput } from './components/fake-input';
import { MessageTextarea } from './components/message-textarea';

import leadsService from '../../../services/leads/leads.service';
import userService from '../../../services/user/user.service';

import domEventsUtil from '../../../utils/dom-events/dom-events.util';
import eventsConstants from '../../../constants/events';
import pathsConstants from '../../../constants/paths';
import { addMaskCellphone, addMaskTelephone } from '../../../utils/add-masks';

import {
  Container,
  InfoContent,
  ContactButtons,
  ContactMarkAsDone,
  ContactMarkAsUndone,
  ContactWrapper,
  LeadAnnotationSaveButton,
  ResponsiveGroup,
  AnnotationGroup,
  Divisor,
  KualifiqueContainer,
  KualifiqueContent,
  KualifiqueLoadingWrapper,
  GoBackButton
} from './styles';
import { CircularProgress } from '@material-ui/core';
import { AnnotationTextarea } from './components/annotation-textarea';
import { toast } from 'react-toastify';


export function LeadContent() {
  const [leadData, setLeadData] = useState(null);

  const [markContactAsDoneLoading, setMarkContactAsDoneLoading] = useState(false);
  const [markContactAsUndoneLoading, setMarkContactAsUndoneLoading] = useState(false);

  const [submitLeadAnnotationText, setSubmitLeadAnnotationText] = useState('Salvar observações');
  const [submitLeadAnnotationSuccess, setSubmitLeadAnnotationSuccess] = useState(false);
  const [submitLeadAnnotationError, setSubmitLeadAnnotationError] = useState(false);

  const [kualifiqueLeadOptionsData, setKualifiqueLeadOptionsData] = useState(null);
  const [kualifiqueLoading, setKualifiqueLoading] = useState(false);
  const [kualifiqueOptionSelected, setKualifiqueOptionSelected] = useState('');

  async function getLead() {
    try {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: true },
      });

      const leadId = window.location.pathname.replace('/dashboard-leads/lead/', '');
      const token = userService.retrieve().token;

      const { data, status } = await leadsService.getLeadById({ leadId, token });

      if (status !== 200) {
        throw new Error('Não foi possível buscar os dados do lead!');
      }

      const leadSerialized = {
        ...data,
        createdAtFormatted: format(new Date(data.createdAt), "dd/MM/yyyy 'às' HH:mm'h'", {
          locale: ptBR,
        }),
        contactedAtFormatted: data.contactedAt !== null ? format(new Date(data.contactedAt), "dd/MM/yyyy 'às' HH:mm'h'", {
          locale: ptBR,
        }) : '---',
        phoneFormatted: data.phone ? data.phone.length > 10 ? addMaskCellphone(data.phone) : addMaskTelephone(data.phone) : null,
        ...data,
      };

      setKualifiqueOptionSelected(data.kualifique.status === 'UNQUALIFIED' ? '' : data.kualifique.status);
      setLeadData(leadSerialized);
    } catch (err) {
      toast.error('Não foi possível buscar os dados do lead!', {
        autoClose: 3000,
      });
    } finally {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: false },
      });
    }
  }

  async function getKualifiqueOptions() {
    try {
      const token = userService.retrieve().token;

      const { status, data } = await leadsService.getKualifiqueOptions({ token });

      if (status !== 200) {
        throw new Error('Não foi possível buscar as opções para qualificar o lead!');
      }

      const { kualifiqueOptions } = data;

      const kualifiqueOptionsFiltered = kualifiqueOptions.filter(item => item.key !== "UNQUALIFIED");

      setKualifiqueLeadOptionsData(kualifiqueOptionsFiltered);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getLead();

    if (userService.retrieve().userType > 2) {
      getKualifiqueOptions();
    }
  }, []);

  async function handleMarkContactAsDone(leadId) {
    try {
      setMarkContactAsDoneLoading(true);

      const user = userService.retrieve();

      const { status } = await leadsService.markContactAsDone({
        leadId,
        token: user.token,
      });

      if (status !== 204) {
        throw new Error('Não foi possível registrar o contato, tente novamente!');
      }

      getLead();

      toast.success('Registrado como respondido!', {
        autoClose: 3000,
      });
    } catch {
      toast.error('Não foi possível registrar o contato, tente novamente!', {
        autoClose: 5000,
      });
    } finally {
      setMarkContactAsDoneLoading(false);
    }
  }

  async function handleMarkContactAsUndone(leadId) {
    try {
      setMarkContactAsUndoneLoading(true);

      const user = userService.retrieve();

      const { status } = await leadsService.markContactAsUndone({
        leadId,
        token: user.token,
      });

      if (status !== 204) {
        throw new Error('Não foi possível desmarcar o contato, tente novamente!');
      }

      getLead();

      toast.success('Registrado como não respondido!', {
        autoClose: 3000,
      });
    } catch {
      toast.error('Não foi possível desmarcar o contato, tente novamente!', {
        autoClose: 5000,
      });
    } finally {
      setMarkContactAsUndoneLoading(false);
    }
  }

  async function handleAssignAnnotationToLead(leadId) {
    try {
      setSubmitLeadAnnotationText('Salvando...');

      const annotation = document.getElementById('annotation-textarea').value;

      const user = userService.retrieve();

      const { status } = await leadsService.assignAnnotationToLead({
        annotation,
        leadId,
        token: user.token,
      });

      if (status !== 204) {
        throw new Error('Não foi possível salvar a observação.')
      }

      setSubmitLeadAnnotationText('Observações salvas com sucesso!');
      setSubmitLeadAnnotationSuccess(true);

      setTimeout(() => {
        setSubmitLeadAnnotationText('Salvar observações');
        setSubmitLeadAnnotationSuccess(false);
      }, 2000);
    } catch {
      setSubmitLeadAnnotationText('Tente novamente em instantes!');
      setSubmitLeadAnnotationError(true);

      setTimeout(() => {
        setSubmitLeadAnnotationText('Salvar observações');
        setSubmitLeadAnnotationError(false);
      }, 2000);
    }
  }

  async function handleChangeKualifique(event) {
    try {
      const kualifiqueKey = event.target.value;

      setKualifiqueLoading(true);

      const token = userService.retrieve().token;

      const { status } = await leadsService.kualifiqueLead({ leadId: leadData.id, kualifiqueOptionKey: kualifiqueKey, token });

      if (status !== 204) {
        throw new Error('Não foi possível qualificar esse lead!');
      }

      setKualifiqueOptionSelected(kualifiqueKey);

      toast.success('Qualificação salva com sucesso!', {
        autoClose: 3000,
      });
    } catch (err) {
      toast.error('Não foi possível qualificar esse lead!', {
        autoClose: 3000,
      });
    } finally {
      setKualifiqueLoading(false);
    }
  };

  function handleNavigateToDashboardLeads() {
    window.open(`${pathsConstants.DASHBOARD_LEADS}?origin=koort`, '_self');
  }

  if (leadData === null) return <Container />;

  return (
    <Container>
      {userService.retrieve().userType > 2 && (
        <GoBackButton type="button" onClick={handleNavigateToDashboardLeads}>
          <RiArrowLeftLine size={16} />
          Voltar
        </GoBackButton>
      )}

      {userService.retrieve().userType > 2 && (
        <KualifiqueContainer>
          <h3>KUALIFIQUE</h3>
          <p>Registre ou altere sua avaliação para este lead.</p>

          <KualifiqueContent>
            {kualifiqueLoading ? (
              <KualifiqueLoadingWrapper>
                <CircularProgress />
              </KualifiqueLoadingWrapper>
            ) : (
              <FormControl component="fieldset">
                <RadioGroup aria-label="kualifique" name="gender1" value={kualifiqueOptionSelected} onChange={handleChangeKualifique}>
                  {kualifiqueLeadOptionsData.map(item => (
                    <FormControlLabel key={item.key} value={item.key} control={<Radio color="primary" />} label={item.value} />
                  ))}
                </RadioGroup>
              </FormControl>
            )}
          </KualifiqueContent>
        </KualifiqueContainer>
      )}

      <Divisor />

      <h3>
        Veja todas as informações do lead:
        <strong>
          {leadData.name}
        </strong>
      </h3>

      <InfoContent>
        <ResponsiveGroup>
          <RealEstateFakeInput
            label="ID do anúncio"
            value={leadData.realEstateId}
            slug={leadData.realEstateSlug}
          />

          <FakeInput
            label="ID do lead"
            value={leadData.id}
          />
        </ResponsiveGroup>

        <ResponsiveGroup>
          <FakeInput
            label="Nome"
            value={leadData.name}
          />

          <FakeInput
            label="E-mail"
            value={leadData.email}
          />
        </ResponsiveGroup>

        <ResponsiveGroup>
          <FakeInput
            label="Telefone"
            value={leadData.phoneFormatted ? leadData.phoneFormatted : 'Não informado'}
          />

          <FakeInput
            label="Data e Hora (Lead registrado)"
            value={leadData.createdAtFormatted}
          />
        </ResponsiveGroup>

        <ResponsiveGroup>
          <ContactWrapper>
            <label>O contato foi realizado?</label>

            <ContactButtons>
              <ContactMarkAsDone
                type="button"
                disabled={leadData.contactedAt !== null || markContactAsDoneLoading}
                maskAsDone={leadData.contactedAt !== null}
                onClick={() => handleMarkContactAsDone(leadData.id)}
              >
                Sim {markContactAsDoneLoading && <CircularProgress color="inherit" size={16} />}
              </ContactMarkAsDone>

              <ContactMarkAsUndone
                type="button"
                disabled={leadData.contactedAt === null || markContactAsUndoneLoading}
                maskAsUndone={leadData.contactedAt === null}
                onClick={() => handleMarkContactAsUndone(leadData.id)}
              >
                Não {markContactAsUndoneLoading && <CircularProgress color="inherit" size={16} />}
              </ContactMarkAsUndone>
            </ContactButtons>
          </ContactWrapper>


          {leadData.contactedAt !== null && (
            <FakeInput
              label="Data e Hora (Contato realizado)"
              value={leadData.contactedAtFormatted}
            />
          )}

          {leadData.kualifique && leadData.kualifique.status && (
            <FakeInput
              label="KUALIFIQUE"
              value={kualifiqueOptionSelected ? kualifiqueLeadOptionsData.filter(item => item.key === kualifiqueOptionSelected)[0].value : 'Aguardando avaliação'}
            />
          )}
        </ResponsiveGroup>

        <ResponsiveGroup>
          <MessageTextarea
            label="Mensagem"
            value={leadData.message}
          />

          <AnnotationGroup>
            <AnnotationTextarea
              label="Observações"
              isResponsibleLead={leadData.responsibleId === userService.retrieve()._id}
              value={leadData.annotation}
            />

            {leadData.responsibleId === userService.retrieve()._id && (
              <LeadAnnotationSaveButton
                type="button"
                onClick={() => handleAssignAnnotationToLead(leadData.id)}
                disabled={submitLeadAnnotationText !== 'Salvar observações'}
                isSuccess={submitLeadAnnotationSuccess}
                isError={submitLeadAnnotationError}
              >
                {submitLeadAnnotationText}
              </LeadAnnotationSaveButton>
            )}
          </AnnotationGroup>
        </ResponsiveGroup>
      </InfoContent>
    </Container>
  );
}
