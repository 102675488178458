import styled from 'styled-components';

import { BaseLabel } from '../../../styled/labels/labels';
import { BaseInput } from '../../../styled/inputs/inputs';
import { mqTablet, } from '../../../styled/helpers/helpers';
import { BaseButton } from '../../../styled/buttons/buttons';
import { COLOR_5, COLOR_15, COLOR_7, COLOR_2, COLOR_1 } from '../../../styled/variables/variables';

import Checkbox from '../../../components/checkbox';
import InputWrapper from '../../../components/input-wrapper';

export const Wrapper = styled.div`
  min-height: 100vh;
  padding: 10px;
  padding-bottom: 120px;
  display: flex;
  flex-direction: column;
`;

export const RowButton = styled.div`
    width: 100%;
    height: 90px;
    padding-top: 15px;
    bottom: 0px;
    background-color: white;
    text-align: center;

    @media (min-width: 768px) {
    position: fixed;
    left: 0px;
    text-align: right;
    padding-left: 50%;
  }

`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
`;

export const ProfilePhotoWrapper = styled.div`
  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > label {
      align-self: center;
    }
  }
`;

export const Title = styled(BaseLabel)`
  font-size: 24px;
  font-weight: bold;
  align-self: center;
  margin-bottom: 30px;

  ${mqTablet`
    font-size: 30px;
    margin-bottom: 60px;
  ` }
`;

export const ProfilePicture = styled.img`
  width: 120px;
  height: 140px;
  margin-bottom: 10px;
  object-fit: cover;
`;

export const SectionTitle = styled(Title)`
  font-size: 16px;
  align-self: flex-start;
  margin-bottom: 10px;
  margin-top: 5px;

  ${mqTablet`
    font-size: 16px;
    margin-bottom: 10px;
  ` }
`;

export const CheckboxInputWrapper = styled(InputWrapper)`
  flex-direction: row-reverse;
  justify-content: flex-end;
`;

export const WhatsappCheckboxInput = styled(Checkbox)`
  margin-right: 10px;
`;

export const NameInput = styled(BaseInput).attrs({
  placeholder: 'Nome completo'
})``;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const SaveButton = styled(BaseButton).attrs({
  type: 'submit',
})`
  align-self: center;
  flex-grow: 1;
  width: 1px;
  max-width: 200px;
`;

export const ViewButton = styled(SaveButton).attrs({
  type: 'button',
})`
  margin-right: 10px;
`;

export const InputFileProfile = styled.div`
  width: 100%;
  background: #2b50d8;
  color : white;
  padding: 15px;
`
export const CropImageContentOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99999;
  padding: 0 24px;

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.9);
`;

export const CropImageCard = styled.div`
  width: 100%;
  max-width: 448px;
  background-color: ${COLOR_15};
`;

export const CropImageCardHeader = styled.div`
  background-color: #0040c4;
  padding: 16px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    border: 0;
    background-color: transparent;
    font-size: 0;
    color: ${COLOR_15};
    margin-left: 16px;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.8);
    }
  }
`;

export const CropImageCardHeaderTitleWrapper = styled.div`
  h3 {
    font-size: 16px;
    font-weight: 600;
    color: ${COLOR_15};
  }

  span {
    font-size: 14px;
    color: ${COLOR_15};

    display: flex;
    align-items: center;
    margin-top: 8px;

    > svg {
      margin-right: 12px;
    }
  }
`;

export const CropImageCardWrapper = styled.div`
  height: 300px;
  padding: 16px;
  overflow: auto;
`;

export const CropImageCardContent = styled.div`
  position: relative;
  height: 300px;
  padding: 16px;

  .reactEasyCrop_CropArea,
  .reactEasyCrop_CropAreaGrid::after,
  .reactEasyCrop_CropAreaGrid::before {
    border-color: ${COLOR_5};
  }
`;

export const CropImageCardFooter = styled.div`
  margin-top: 24px;
  background-color: ${COLOR_5};
  padding: 16px;

  display: flex;
  justify-content: center;

  button {
    border: 0;
    width: 100%;
    background-color: #2B50D8;
    font-size: 14px;
    padding: 5px 16px;
    color: ${COLOR_15};

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.8);
    }
  }
`;

export const OccupationAreaContainer = styled.div``;

export const OccupationAreaEmpty = styled.div`
  margin-top: 24px;

  display: flex;
  justify-content: center;

  span {
    display: block;
    width: 100%;
    max-width: 220px;
    text-align: center;
    color: #333333;
    opacity: 0.65;
  }
`;

export const OccupationAreaTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 4px;

  button {
    display: flex;
    align-items: center;
    background-color: transparent;

    color: #2B50D8;
    font-size: 14px;

    transition: filter 0.2s;

    > svg {
      margin-right: 4px;
    }

    &:hover {
      filter: brightness(0.9);
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.65;
    }
  }
`;

export const OccupationAreaCount = styled.span`
  display: inline-block;
  margin-left: 8px;
  background-color: rgba(51, 51, 51, 0.4);
  padding: 0 12px;
  height: 22px;
  border-radius: 10px;
  color: #333333;
  font-weight: 500;
`;

export const OccupationAreaScroll = styled.div`
  max-height: 400px;
  overflow-y: auto;
`;

export const OccupationAreaFields = styled.div`
  span {
    display: flex;
    justify-content: center;
    margin-top: 8px;
    margin-bottom: 16px;

    button {
      background-color: transparent;
      color: #F32626;
      font-size: 14px;
      font-weight: 600;

      transition: filter 0.2s;

      &:hover {
      filter: brightness(0.9);
      }

      &:disabled {
      cursor: not-allowed;
      opacity: 0.65;
    }
    }
  }
`;

export const OccupationAreaActionButtons = styled.div`
display: flex;
justify-content: center;
margin-top: 8px;

button {
  background-color: transparent;

  transition: filter 0.2s;

  &:hover {
      filter: brightness(0.8);
    }
}
`;

export const OccupationAreaButtonConfirmRemove = styled.button`
  font-weight: 600;
  color: #2B50D8;
  font-size: 14px;
`;

export const OccupationAreaButtonCancelRemove = styled.button`
  margin-left: 16px;
  font-weight: normal;
  color: #333333;
  font-size: 14px;
`;

export const DeleteUserDataContent = styled.div`
  max-width: 500px;
  border-top: 1px solid rgba(0, 0, 0, 0.4);
  margin-top: 32px;
  padding-top: 32px;

  > button {
    width: 100%;
    padding: 16px;
    background-color: ${COLOR_7};
    color: ${COLOR_2};
    font-weight: 500;

    transition: filter .2s;

    &:hover {
      filter: brightness(0.9);
    }
  }
`;

export const ModalContent = styled.div`
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalBody = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;

  background-color: ${COLOR_1};
  width: 100%;
  max-width: 400px;

  padding: 16px;

  color: ${COLOR_2};

  span {
    font-size: 16px;
  }

  p {
   /* color: inherit; */
   font-size: 16px;
  }

  label {
    margin-top: 12px;
  }
`;

export const DeleteUserDataButtonsGroup = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 12px;

  button {
    width: 100%;
    padding: 8px 16px;

    transition: filter .2s;

    &:first-child {
      background: ${COLOR_2};
    }

    &:last-child {
      margin-top: 12px;

      background: transparent;
      color: rgba(255, 255, 255, 0.8);
    }

    &:hover {
      filter: brightness(0.9);
    }
  }
`;

export const LoadingModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
  background-color: ${COLOR_1};

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  h3 {
    text-align: center;
    margin-bottom: 24px;
  }
`;

export const CountTextArea = styled.span`
  align-self: flex-end;
  font-size: 13px;
  margin-top: 8px;
`;
