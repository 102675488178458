/* eslint-disable no-undef */
import React from 'react';
import styled from 'styled-components';

import { BaseLabel } from '../../../styled/labels/labels';
import { COLOR_1, COLOR_17, COLOR_2 } from '../../../styled/variables/variables';
import { BaseButton } from '../../../styled/buttons/buttons';

import queryStringUtil from '../../../utils/query-string/query-string.util';
import pathConstants from '../../../constants/paths'

import planService from '../../../services/plan/plan.service'


const Congratulations = ({ history, user }) => {
  const flowType = queryStringUtil.parse(history).flowType

  return (
    <Wrapper>
      <Title>
        Parabéns!
        </Title>
      <Subtitle>
        Plano escolhido:
        </Subtitle>
      <Info>
        {planInfo()}
      </Info>
      {flowType === 'OWNER' &&
        <>
          <Subtitle>
            Código de identificação do anúncio:
          </Subtitle>
          <Info>
            {adId()}
          </Info>
          <Subtitle>
            Seu anúncio foi criado com sucesso! Clique no botão abaixo para visualizá-lo
        </Subtitle>
          <OpenAdButton onClick={() => { goToAd(history) }}>
            ver meu anúncio
        </OpenAdButton>
        </>
      }
      {flowType === 'BROKER' && 
        <Subtitle>
          Seu plano foi comprado com sucesso!
        </Subtitle>
      }
      {seeBarCode(history, user)}
    </Wrapper>
  );
};

const seeBarCode = (history, user) => {
  const queryParams = queryStringUtil.parse(history);
  const { paymentMethod } = queryParams;

  if (paymentMethod === 'barCode') {
    return (
      <div style={{ alignSelf: 'center', marginRight: 10 }}>
        <OpenBarCodeButton onClick={() => { getBarCode(user) }}>
          ver meu boleto
        </OpenBarCodeButton>
      </div>
    );
  }
  return;
}

const getBarCode = async (user) => {
  let paymentInfo = localStorage.getItem('paymentInfo');
  paymentInfo = JSON.parse(paymentInfo);
  await planService.buyBankSlip({
    token: user.token,
    data: paymentInfo
  }).then(response => {
    window.open(response.data.paymentResponse.Payment.Url);
  })
}

const goToAd = (history) => {
  const id = localStorage.getItem('adId');
  const href = history.createHref({
    pathname: pathConstants.SEARCH_DETAIL,
    search: queryStringUtil.stringify(null, {
      listingId: id,
    }),
  });
  history.push(href);
}

const planInfo = () => {
  const plan = JSON.parse(localStorage.getItem('planInfo'));
  return plan.name;
}

const adId = () => {
  const id = localStorage.getItem('adId');
  return id;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Info = styled.p`
  align-self:center;
  margin-bottom:10px;
  font-size:22px;
`;

const Title = styled(BaseLabel)`
  font-weight: 700;
  font-size: 28px;
  color: ${ COLOR_1};
  align-self: center;
  margin-bottom: 10px;
`;

const Subtitle = styled(Title)`
  font-size: 22px;
  color: ${ COLOR_17};
  text-align: center;
  max-width: 550px;
`;

const OpenAdButton = styled(BaseButton)`
  border: none;
  background-color: ${ COLOR_1};
  color: ${ COLOR_2};
  width: fit-content;
  align-self: center;
  margin-top:20px;
  min-width:150px;
`;

const OpenBarCodeButton = styled(BaseButton)`
  border: none;
  background-color: ${ COLOR_1};
  color: ${ COLOR_2};
  width: fit-content;
  align-self: center;
  margin-top:20px;
  min-width:150px;
  margin-right:10px;
`;

export default Congratulations;
