import React, { PureComponent } from 'react';
import propTypes from 'prop-types';

import domEventsUtil from '../../utils/dom-events/dom-events.util';
import eventsConstants from '../../constants/events';

import { Wrapper, LoadingSpinner } from './styles';

class Loading extends PureComponent {

  constructor(props) {
    super(props);    

    this.count = 0;
    this.state = {
      show: false,
    };

  this.handleEventSubscriptions(true);
  }

  componentWillUnmount() {
    this.handleEventSubscriptions(false);
  }

  handleEventSubscriptions = activated => {
    domEventsUtil[activated ? 'subscribe' : 'unsubscribe']({
      name: eventsConstants.LOADING_ANIMATION,
      callback: this.handleLoadingEvent,
    });
  }

  handleLoadingEvent = event => {
    const { show } = event.detail.params;
    if(show) this.count++;
    else this.count--;
    
    this.setState({
      ...this.state,
      show: this.count > 0 ? true : false,
    });
  };

  render() {
    const { show, opacify } = this.props;

    return (
      <Wrapper
        show={ this.state.show || show }
        opacify={ opacify }
      >
        <LoadingSpinner />
      </Wrapper>
    )
  }
}

Loading.propTypes = {
  show: propTypes.bool,
  opacify: propTypes.bool,
};

Loading.defaultProps = {
  show: false,
  opacify: true,
};



export default Loading;

