import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';

const DisplayContext = createContext();

export const DisplayProvider = ({ children }) => {
  const [isFilterVisible, setFilterVisible] = useState(true);
  const [isMapVisible, setMapVisible] = useState(false);
  const [isMobileDevice, setIsMobileDevice] = useState(false)
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  const handleWindowResize = useCallback(event => {
    setWindowSize(window.innerWidth);
  }, []);

  useEffect(() => {
    // CÓDIGO RESPONSÁVEL POR NÃO EXIBIR O MAPA EM DISPOSITIVOS MÓVEIS
    if (windowSize < 1080) {
      setIsMobileDevice(true)
      setMapVisible(false)
    }
    console.log(windowSize)
    window.addEventListener('resize', handleWindowResize);
    return () => {
        window.removeEventListener('resize', handleWindowResize);
    };
  }, [handleWindowResize])

  return (
    <DisplayContext.Provider value={{ isFilterVisible, setFilterVisible, isMapVisible, setMapVisible, isMobileDevice }}>
      {children}
    </DisplayContext.Provider>
  );
};

export const useDisplay = () => useContext(DisplayContext);