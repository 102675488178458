import * as yup from 'yup';

const formSchema = yup.object().shape({
  propertyUse: yup.string()
    .test("", "Seleção obrigatória", value => {
      if (value !== 'Selecione uma transação') {
        return true;
      } else {
        return false;
      }
    }),
  propertyType: yup.string()
    .test("", "Seleção obrigatória", value => {
      if (value !== 'Selecione um tipo') {
        return true;
      } else {
        return false;
      }
    }),
  city: yup.string().required('Este campo é obrigatório'),
  neighborhood: yup.string().required('Este campo é obrigatório'),
  bedrooms: yup.string().required('Este campo é obrigatório'),
  bathrooms: yup.string().required('Este campo é obrigatório'),
  parkingSpaces: yup.string().required('Este campo é obrigatório'),
  area: yup.string().required('Este campo é obrigatório'),
  price: yup.string().required('Este campo é obrigatório'),
  name: yup.string().required('Este campo é obrigatório'),
  email: yup.string().email('Informe um e-mail válido')
    .required('Este campo é obrigatório'),
  telephone: yup.string().required('Este campo é obrigatório').min(19, 'Informe os 11 dígitos'),
  message: yup.string().required('Este campo é obrigatório')
});

export { formSchema };
