import styled, { css } from 'styled-components';

import { BaseButton } from '../../../../../styled/buttons/buttons';
import { mqTablet } from '../../../../../styled/helpers/helpers';
import { BaseLabel } from '../../../../../styled/labels/labels';
import { COLOR_2, COLOR_6 } from '../../../../../styled/variables/variables';

export const Wrapper = styled.div`
  align-self: center;
  width: 100%;

  ${mqTablet`
    height: 50px;
    flex-grow: 1;
    width: 1px;
    padding-top: 12px;
    position: relative;
  `}
`;

export const SearchBarWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Button = styled(BaseButton).attrs({
  type: 'button',
})`
  background-color: ${COLOR_6};
  color: ${COLOR_2};
  height: 50px;
  padding: 0 10px;
  width: 100%;
  margin-top: 5px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  border: none;

  ${({ isDisabled }) => isDisabled && css`
    cursor: not-allowed !important;
    filter: grayscale(0.4);
  `}
`;

export const RealtorsList = styled.ul`
	display: flex;
	flex-direction: column;
	align-items: center;
	list-style: none;
	justify-content: center;
	margin-bottom: 20px;

	> li {
		flex-basis: 190px;
		margin-bottom: 10px;
	}

	${mqTablet`
		flex-direction: row;
		flex-wrap: wrap;

		> li {
			margin-right: 5px;
		}
  ` }
`;

export const SectionTitle = styled(BaseLabel)`
font-weight: 700;
width: 100%;
font-size: 24px;
margin-bottom: 30px;
margin-top: 20px;
`;

export const ItemFormWrapper = styled.div`
  flex: 1;
  background-color: #ffffff;
  padding: 8px 12px 4px;

  margin-bottom: 5px;

  display: flex;
  align-items: center;

  border: 1px solid #777;

  &.district {
    margin-left: 5px;
  }

  &.professionalType {
    .react-select__indicators {
      display: block !important;
    }
  }

  @media (max-width: 1199px) {
    &.district {
    margin-left: unset;
  }
  }
`;

export const SelectWrapper = styled.div`
  flex: 1;

  > label {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
  }
`;
