import React from 'react';
import styled from 'styled-components';
import { mqCustom, iconBaseStyle } from '../../../styled/helpers/helpers';
import { COLOR_2, COLOR_4 } from '../../../styled/variables/variables';
import { Link } from 'react-router-dom';
import { Phone } from 'styled-icons/icomoon/Phone';
import { Mail4 } from 'styled-icons/icomoon/Mail4';
import { Whatsapp } from 'styled-icons/icomoon/Whatsapp';
import stringUtil from "../../../utils/string/string.util";

const RealtorCard = (props) => {
	return (
		<Card className={props.profile.screenName} >
			<Link to={`/profile/sale?id=${props._id}`}>
				<RealtorImage src={props.profile.image} alt={props.profile.screenName} />
				<InternalCardWrapper>
					<span className="realtor-name">{props.profile.screenName}</span>
				</InternalCardWrapper>
			</Link>
			<InternalCardWrapper>
				<div className="realtor-data">
					<ul>
						<li>
							{props.profile.professionalInformation.cellphone.number && props.profile.professionalInformation.cellphone.whatsApp &&
								<a target="_blank" href={`https://wa.me/${stringUtil.onlyNumbers(props.profile.professionalInformation.cellphone.number)}`}>
									<IconWhatsapp />
								</a>
							}
						</li>
						<li>
							{props.profile.professionalInformation.cellphone.number && !props.profile.professionalInformation.cellphone.whatsApp &&
								<a target="_blank" href={`tel:${props.profile.professionalInformation.cellphone.number}`}>
									<><IconPhone /> </>
								</a>

							}
						</li>
						<li>
							{props.profile.professionalInformation.telephone.number && !props.profile.professionalInformation.cellphone.number &&
								<a target="_blank" href={`tel:${props.profile.professionalInformation.telephone.number}`}>
									<><IconPhone /> </>
								</a>}
						</li>
						<li>
							{props.profile.professionalInformation.email &&
								<a target="_blank" href={`mailto:${props.profile.professionalInformation.email}`}>
									<><IconEmail /> </>
								</a>}
						</li>
					</ul>
				</div>
			</InternalCardWrapper>
		</Card>
	);
}

const InternalCardWrapper = styled.div`
	margin: 0 10px 10px;
`;

const RealtorImage = styled.img`
	display: block;
	margin-bottom: 10px;
	width: 100%;
`;

const IconWhatsapp = styled(Whatsapp)`
	${iconBaseStyle}
	margin-right: 30px;
    font-size: 13pt;
    border-radius: 1px solid ;
    float: left;
	color: green;
`;

const IconPhone = styled(Phone)`
	${iconBaseStyle}
	margin-right: 30px;
    font-size: 13pt;
    border-radius: 1px solid ;
    float: left;
	color: green;
`;

const IconEmail = styled(Mail4)`
	${iconBaseStyle}
	margin-right: 30px;
    font-size: 13pt;
    border-radius: 1px solid ;
    float: left;
`;

const RealtorData = styled.ul`
	list-style: none;
`;

const Card = styled.article`
  display: flex;
  flex-direction: column;
  background-color: ${COLOR_2};
	border: 1px solid #e2e2e2;
  transition: .2s ease;
  transform: scale(0.9);
  align-self: center;
	box-shadow: 0 2px 2px -1px #aaa;

	.realtor-name {
		margin-bottom: 15px;
		display: block;
		font-weight: bold;
	}

	.realtor-data {
		color: ${COLOR_4};

		li {
			margin-bottom: 5px;
		}
	}

	ul {
		font-size: 14px;
		list-style: none;
	}

  ${mqCustom(800)`
    display: flex;
    &:hover {
      transform: scale(1);
    }
  ` }
`;

export default RealtorCard;
