import React from 'react';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-PV4C9JP',
};

TagManager.initialize(tagManagerArgs);

const GoogleTagManager = () => {
  // Pode ser usado para enviar eventos personalizados para o Google Tag Manager
  const sendEventToTagManager = (event) => {
    TagManager.dataLayer({
      dataLayer: {
        event,
      },
    });
  };

  // Outros métodos úteis podem ser adicionados aqui, conforme necessário

  return <div />;
};

export default GoogleTagManager;
