
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { KeyboardArrowLeft } from 'styled-icons/material/KeyboardArrowLeft';
import { KeyboardArrowRight } from 'styled-icons/material/KeyboardArrowRight';
import { transitions } from 'polished';

import { BaseButton } from '../../../styled/buttons/buttons';
import { COLOR_1, COLOR_2 } from '../../../styled/variables/variables';
import { bigSectionPadding, mqTablet, iconBaseStyle, pointer, mqDesktop } from '../../../styled/helpers/helpers';
import { LoadingAnimation } from '../../../styled/components/components';

import Title from './title/Title';
import Card from './card/Card';
import Arrows from '../cover/arrows/Arrows';
import HilightedAgents from '../highlighted-agents/HighlightedAgents';
import Advantages from '../advantages/Advantages';
import HighlightedAgents from '../highlighted-agents/HighlightedAgents'

import windowUtil from '../../../utils/window/window.util';
import queryStringUtil from '../../../utils/query-string/query-string.util';
import stringUtil from '../../../utils/string/string.util';
import pathConstants from '../../../constants/paths';

import listingV2Service from '../../../services/listing/listing-v2.service';

/*
  global
  window
*/

const pageSize = 2;
const Recommended = ({
  geolocation,
  history,
}) => {

  const [state, setState] = useState({
    isLoading: false,
    realEstates: [],
    page: 1,
  });

  useEffect(() => {
    getRecommendedAds({});
  }, [geolocation]);

  const getRecommendedAds = async ({
    page = 1,
  }) => {
    setState(prev => ({
      ...prev,
      isLoading: true,
    }));

    let result;
    try {
      result = await listingV2Service.getRecommendationsAdsForUserByHome();
    } catch (error) {
      console.error(error);
      return;
    }

    // if (page < 1 || page > result.length / 2 - 2) return;

    setState(prev => ({
      ...prev,
      realEstates: result,
      isLoading: false,
      page: page < 1 || page > ((result.length / 2) - 2) ? 1 : page,
    }));
  };

  const handlePaginationButtonClick = ({ isNext = true }) => {
    let page = state.page + (isNext === true ? 1 : 2);
    if (page * pageSize > state.realEstates.length) {
      page = 1;
    } else if (page <= 0) {
      page = parseInt(state.realEstates.length / pageSize);
    }

    getRecommendedAds({
      page,
      isNext,
    });
  };

  return (
    <Wrapper as="section">
      <Title />
      <RLoadingAnimation
        loading={state.isLoading && state.realEstates.length === 0}
      />
      <CenterWrapper>
        <LeftArrowIcon
          onClick={() => {
            handlePaginationButtonClick({ isNext: false });
          }}
        />
        <ListWrapper>
          {state.realEstates.slice(
            state.page * pageSize,
            pageSize + (state.page * pageSize)
          ).map((re, index) => (
            <Card
              {...re}
              {...re.address}
              {...re.category}
              {...re.price}
              key={index}
              reverse={index % 2 !== 0}
              opacify={state.isLoading}
              onClick={() => history.push(`${pathConstants.AD_DETAIL}/${re.slug}`)}
            />
          ))}
        </ListWrapper>
        <RightArrowIcon
          onClick={() => {
            handlePaginationButtonClick({});
          }}
        />
      </CenterWrapper>

      {state.realEstates.length > 0 && (
        <ArrowsWrapper>
          <LeftArrowIcon
            onClick={() => {
              handlePaginationButtonClick({ isNext: false });
            }}
          />
          <RightArrowIcon
            onClick={() => {
              handlePaginationButtonClick({});
            }}
          />
        </ArrowsWrapper>
      )}
      <SeeMoreButton
        onClick={() => windowUtil.scrollToElement({
          element: HighlightedAgents.ID,
        })}
      >
        <Arrows color={COLOR_1}
        />
      </SeeMoreButton>
    </Wrapper>
  );
};

// Recommended.ID = `${Recommended.name}_${stringUtil.generateUniqueId()}`;
Recommended.ID = 'recommended-container-id';

Recommended.propTypes = {
  geolocation: propTypes.object,
};

Recommended.propTypes = {

};

const Wrapper = styled.div.attrs({
  id: Recommended.ID,
})`
  ${bigSectionPadding}
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  min-height: 1210px;

  ${mqTablet`
    min-height: 910px;
  ` }
`;

const RLoadingAnimation = styled(LoadingAnimation).attrs({
  css: css`
    align-self: center;
    margin-bottom: 30px;
  `,
})``;

const CenterWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  & > svg {
    display: none;
  }

  ${mqDesktop`
    flex-direction: row;
    & > svg {
      display: block;
    }
  ` }
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;

  ${mqDesktop`
    width: 900px;
  ` }
`;

const LeftArrowIcon = styled(KeyboardArrowLeft)`
  ${iconBaseStyle}
  height: 70px;
  align-self: center;
  ${pointer}

  ${mqDesktop`
    ${transitions(['transform'], 'ease .6s')}
    &:hover {
      transform: scale(1.3);
    }
  ` }
`;

const RightArrowIcon = styled(LeftArrowIcon).attrs({
  as: KeyboardArrowRight,
})``;

const SeeMoreButton = styled.div`
display: none;

  ${mqTablet`
    display: flex;
    justify-content: center;
    margin-top: 60px;
    align-self: center;
    border: none;
  `}
`;

const ArrowsWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;

  ${mqDesktop`
    display: none;
  ` }
`;

const mapStateToProps = state => ({
  geolocation: state.data.geolocation,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Recommended);
