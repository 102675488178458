
import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';

import { KeyboardArrowDown } from 'styled-icons/material/KeyboardArrowDown';
import { iconBaseStyle, pointer } from '../../../../styled/helpers/helpers';
import windowUtil from '../../../../utils/window/window.util';
import {Releases} from '../../releases';

const Arrows = ({
  color,
}) => (
  <Wrapper
    onClick={ () => windowUtil.scrollToElement({
      element: 'release-wrapper',
      options: {
        offset: 0,
      },
    }) }
  >
    { [ 1, 2, 3 ].map(order => (
      <Arrow key={ order } order={ order } color={color} />
    )) }
  </Wrapper>
);

Arrows.propTypes = {
  color: propTypes.string,
}

Arrows.defaultProps = { };

const Wrapper = styled.div`
  align-self: center;

`;


const Arrow = styled(KeyboardArrowDown)`
  ${ iconBaseStyle }
  color: ${  ({ color }) => color  };
  height: 50px;
  margin-top: -40px;
  opacity: 0.9;
  ${ pointer }
  animation-name: blink;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  ${ ({ order }) => {
    let delay = 'animation-delay: ';
    switch (order) {
      case 1: delay += '0s'; break;
      case 2: delay += '.3s'; break;
      case 3: delay += '.6s'; break;
      default:
        break;
    }
    delay += ';';
    return delay;
  } };

  @keyframes blink {
    0% { opacity: 1; }
    50% { opacity: .3; }
    100% { opacity: 1; }
  }
`;

export default Arrows;
