import styled from "styled-components";

export const OriginLeadsSelect = styled.div``;

export const BrokersTableWrapper = styled.div`
  flex: 1;
  padding: 24px;
  border: 1px solid #EEF0F4;
  border-radius: 8px;
  overflow-x: auto;
  margin-top: 40px;

  > h3 {
    font-size: 18px;
    font-weight: 600;
  }

  > p {
    font-size: 14px;
    margin-top: 8px;
    margin-bottom: 24px;
  }
`;

export const BrokersTableFilter = styled.form`
  border: 1px solid #EEF0F4;
  border-radius: 8px;
  padding: 24px;
  margin-top: 24px;
  margin-bottom: 24px;

  > span {
    font-size: 18px;
    font-weight: 700;

    display: flex;
    align-items: center;

    margin-bottom: 16px;

    > svg {
      margin-right: 8px;
    }
  }
`;

export const EmailFilter = styled.div`
  label {
    font-weight: 500;
    margin-right: 8px;
  }

  input {
    background-color: transparent;
    border-bottom: 1px solid #999999;
    width: 100%;
    max-width: 420px;

    transition: background-color .2s;

    &::placeholder {
      color: #aeaeae;
    }

    &:hover {
      background-color: #EEF0F4;
    }
  }
`;

export const BrokersTableFilterActionButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  margin-top: 38px;

  @media (max-width: 680px) {
    flex-direction: column;
    align-items: unset;
    justify-content: unset;
  }
`;

const BrokersTableFilterButtonBase = styled.button`
  padding: 8px;
  width: 40%;
  font-weight: 600;
  border-radius: 8px;

  transition: filter .2s;

  &:hover {
    filter: brightness(0.8);
  }

  @media (max-width: 680px) {
    width: 100%;

    & + button {
      margin-top: 16px;
    }
  }
`;

export const BrokersTableFilterSubmitButton = styled(BrokersTableFilterButtonBase)`
  background-color: #2B50D8;
  color: #FFFFFF;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
`;

export const BrokersTableFilterClearButton = styled(BrokersTableFilterButtonBase)`
  background-color: #D1CCC4;
`;
