import httpClient from '../../clients/http/http.client';

import Profile from '../../models/profile/profile.model';

import imageUtil from '../../utils/image/image.util';

/*
FormData
Promise
*/

const update = async ({
  data,
  token,
}) => {
  const params = {
    "name": data.name,
    "profile": {
      "screenName": data.name,
      "aboutMe": data.aboutMe,
      "role": data.role,
      "specialties": data.specialties,
      "professionalInformation": {
        "chat": data.chat,
        "serviceAreas": data.serviceAreas,
        "creci": data.creci,
        "cellphone": data.cellphoneNumber,
        "facebook": data.facebook,
        "instagram": data.instagram,
        "linkedIn": data.linkedIn,
        "telephone": data.telephone,
        "address": {
          "zip": data.zipCode,
          "city": data.city,
          "complement": data.complement,
          "number": data.number,
          "street": data.street
        }
      }
    }

  }

  const path = `/users/profile`;
  const response = await httpClient.patch({
    path,
    params,
    token,
  });

  const result = await new Profile({
    ...response.data.profile,
    description: response.data.profile.aboutMe,
    id: response.data._id,
  });


  return result;
};


const uploadImages = async ({
  pictureBlobs,
  token,
}) => {

  const params = new FormData();
  await Promise.all([
    Promise.all(pictureBlobs.map(async blob => {
      const compressed = await imageUtil.compress(blob, {
        maxSizeMB: 5.0,
        maxWidthOrHeight: 1024,
      });

      params.append('publicity', compressed);
    })),
  ]);

  const path = '/users/profile/images/upload-publicity-photos';
  const response = await httpClient.patch({
    path,
    params,
    token,
    options: {
      header: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  });

  return response;
};

const uploadProfileImage = async ({
  picture,
  token,
}) => {
  const params = new FormData();

  params.append('profile', picture);

  const path = '/users/profile/images/upload-user-image';
  const response = await httpClient.patch({
    path,
    params,
    token,
    options: {
      header: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  });

  return response;
};

const deleteImage = async ({
  item,
  token,
}) => {

  let params = {
    "item": item
  };

  const path = '/users/profile/images/remove-publicity-photos';

  const response = await httpClient.patch({
    path,
    params,
    token,
    options: {
      header: {
        'Content-Type': 'application/merge-patch+json',
      },
    },
  });

  return response;
};

const updateLabel = async ({
  item,
  token,
  id
}) => {

  let params = {
    "label": item
  };

  const path = `/users/profile/images/label-publicity-photos/${id}`;

  const response = await httpClient.patch({
    path,
    params,
    token,
    options: {
      header: {
        'Content-Type': 'application/merge-patch+json',
      },
    },
  });
  return response;
};

const updateView = async ({
  id
}) => {
  const path = `/users/profile/update-statistics/views/${id}`;
  const response = await httpClient.patch({
    path,
  });
  return response;
};

const updateProfileShares = async ({
  id
}) => {
  const path = `/users/profile/update-statistics/shares/${id}`;
  const response = await httpClient.patch({
    path,
  });
  return response;
};

/**
 * Busca as informações básicas do perfil do usuário
 *
 * @param {string} token Token do usuário
 * @param {string} userId ID do usuário
 *
 * @returns {Promise<object>} retorna um objeto com as informações básicas do perfil do usuário
 */

const getUserBasicProfile = async ({ token, userId }) => {
  let result = {};

  try {
    const response = await httpClient.get({
      path: `/users/profile/${userId || ''}`,
      token,
    });

    result = response.data
  } catch (ex) {
    console.log(ex)
    throw new Error(ex);
  }

  return result;
}

/**
 * Busca o perfil completo de um usuário
 *
 * @param {string} token Token do usuário
 * @param {string} userId ID do usuário
 *
 * @returns {Promise<{}>} retorna um objeto com todas as informações do perfil do usuário
 */

const getUserCompleteProfile = async ({ token, userId }) => {
  let result = {};

  try {
    const response = await httpClient.get({
      path: `/users/profile/${userId}?complete=true`,
      token,
    });

    result = response.data
  } catch (ex) {
    console.log(ex)
  }

  return result;
}

export default {
  update,
  uploadImages,
  uploadProfileImage,
  deleteImage,
  updateLabel,
  updateView,
  updateProfileShares,
  getUserBasicProfile,
  getUserCompleteProfile,
};
