
import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import propTypes from 'prop-types';
import { transitions, ellipsis } from 'polished';

import { mqTablet, pointer, scrollingShadow, noScrollBar } from '../../../../../../styled/helpers/helpers';
import { COLOR_1, COLOR_2, COLOR_3, COLOR_5 } from '../../../../../../styled/variables/variables';

const Dropdown = ({ show, categories, selected, onTypeClicked }) => (
  <Wrapper show={ show }>
    { categories.map(category => (
      <Fragment key={ category.id }>
        <Category>
          { category.label }
        </Category>
        { category.types.map(type => (
          <Type
            key={ type.id === undefined ? -1 : type.id}
            highlight={ selected.id === type.id }
            onClick={ _ => onTypeClicked(type) }
          >
            { type.label }
          </Type>
        )) }
      </Fragment>
    )) }
  </Wrapper>
);

Dropdown.propTypes = {
  show: propTypes.bool.isRequired,
  categories: propTypes.array.isRequired,
  selected: propTypes.object.isRequired,
  onTypeClicked: propTypes.func.isRequired,
};

const Wrapper = styled.div`
  ${ scrollingShadow };
  ${ noScrollBar };
  background: ${ COLOR_2 };
  margin-top: -5px;
  border-radius: 1px;;
  overflow-y: scroll;
  position: relative;
  opacity: 0;
  ${ transitions([ 'height', 'opacity' ], 'ease .3s') };
  height: 0;

  ${ ({ show }) => show && css`
    height: 200px;
    opacity: 1;
  ` };

  ${ mqTablet`
    width: 250px;

    ${ ({ show }) => show && css`
      height: 300px;
    ` }
  ` };
`;

const Category = styled.div`
  background-color: ${ COLOR_1 };
  color: ${ COLOR_2 };
  padding: 5px;
  text-transform: uppercase;
  font-size: 12px;
  ${ ellipsis('100%') };
  width: 100%;
`;

const typeHighlightStyle = css`
  background-color: ${ COLOR_5 };
`;

const Type = styled.div`
  ${ pointer };
  padding: 10px;
  color: ${ COLOR_3 };
  text-transform: capitalize;
  ${ ellipsis('100%') };
  width: 100%;
  ${ transitions([ 'background-color' ], 'ease .3s') };
  & :hover {
    ${ mqTablet`
      ${ typeHighlightStyle };
  ` };
  }
  ${ ({ highlight }) => highlight && typeHighlightStyle }
`;

export default Dropdown;
