import styled from 'styled-components';

import { BaseLabel } from '../../../styled/labels/labels';

export const Wrapper = styled.div`
  margin-bottom: 30px;
  margin-top: 24px;
`;

export const Title = styled(BaseLabel)`
  font-size: 14px;
  font-weight: 700;
  display: block;
  margin-bottom: 16px;
`;

export const DevelopmentInfo = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  display: flex;

  > li {
    font-size: 15px;
    text-align: center;

    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 4px;

    & + li {
      margin-left: 16px;
    }
  }

  @media (max-width: 480px) {
    flex-wrap: wrap;
    gap: 16px;

    > li {
      width: 125px;

        & + li {
        margin-left: 0px;
      }
    }
  }
`;
