import styled from 'styled-components';
import { transitions } from 'polished';

import TextSection from '../../text-section/TextSection';

import { COLOR_2, COLOR_BORDER } from '../../../../styled/variables/variables';
import { mqDesktop } from '../../../../styled/helpers/helpers';

export const IncorporationInfoContainer = styled.div`
  margin-top: 72px;

  > h2 {
    font-size: 32px;
    font-weight: 600;

    > span {
      font-weight: 500;
    }
  }

  > div {
    display: flex;
    align-items: flex-start;

    margin-top: 24px;

    > img {
      width: 120px;
    }

    > p {
      margin-left: 16px;
      font-size: 14px;
      font-weight: 500;
    }
  }

  @media (max-width: 1079px) {
    margin-bottom: 72px;

    > div {
      > p {
        max-width: 600px;
      }
    }
  }

  @media (max-width: 600px) {
    > h2 {
      font-size: 24px;
    }

    > div {
      flex-direction: column;

      > p {
        margin-left: unset;
        margin-top: 16px;
      }
    }
  }

`;

export const AdReportContainer = styled.div`
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 16px;

  margin-top: 120px;

  > p {
    font-size: 12px;
    text-align: center;
  }
`;

export const Wrapper = styled.div`
  min-height: 100vh;
  width: 100%;
  background-color: ${COLOR_2};
  display: flex;
  flex-direction: column;
  padding-top: 80px;
  ${transitions(['top', 'opacity', 'right'], 'ease .6s')};

  ${mqDesktop`
    padding-top: 80px;

    ${transitions([
  'top',
  'opacity',
  'right',
], 'ease .9s')};

    top: 80px;
    opacity: 1;
  ` };
`;

export const ContentWrapper = styled.div`
  padding: 10px;

  ${mqDesktop`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-self: center;
    width: 950px;
    padding: 50px 0;
  ` }
`;

export const LeftSideDesktopWrapper = styled.div`
  ${mqDesktop`
    align-self: flex-start;
    min-width: 500px;
    max-width: 500px;
    justify-content:space-around;
  ` }
`;

export const RightSideDesktopWrapper = styled(LeftSideDesktopWrapper)`
  display: none;

  ${mqDesktop`
    display: unset;
    min-width: 400px;
    max-width: 400px;
    margin-left: 15px;
    height: 100%;
  ` }
`;

export const PriceAndContactStickyContainer = styled.div`
  position: sticky;
  top: 100px;
`;

export const HideOnDesktop = styled.div`
  ${mqDesktop`
    display: none;
  ` }
`;

export const AdCardInteractiveWrapper = styled.div`
  display:flex;
`;

export const FirstSectionDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${COLOR_BORDER};
  margin: 20px 0;
`;


export const SecondSectionDivider = styled(FirstSectionDivider)``;

export const ADDescriptionTextSection = styled(TextSection)``;

export const ADRealEstateTextSection = styled(ADDescriptionTextSection)``;
