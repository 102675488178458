/* eslint-disable no-unreachable */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from 'react-modal'
import styled, { css } from 'styled-components';
import { Question } from 'styled-icons/fa-solid/Question';
import { RiHandbagLine, RiCloseLine } from 'react-icons/ri';

import { BaseButton } from '../../../styled/buttons/buttons';
import { BaseInput, MaskedBaseInput, masks } from '../../../styled/inputs/inputs';
import { BaseLabel } from '../../../styled/labels/labels';
import { COLOR_13, COLOR_2, COLOR_4, COLOR_1, COLOR_17, COLOR_7 } from '../../../styled/variables/variables';
import { mqTablet, iconBaseStyle } from '../../../styled/helpers/helpers';
import './modal.css'

import Selector from '../../../components/selector';
import Checkbox from '../../../components/checkbox';

import pathsConstants from '../../../constants/paths';
import queryStringUtil from '../../../utils/query-string/query-string.util';
import objectUtil from '../../../utils/object/object.util';
import storageUtil from '../../../utils/storage/storage.util';
import currencyUtil from '../../../utils/currency/currency.util';

import domEventsUtil from '../../../utils/dom-events/dom-events.util';
import eventConstants from '../../../constants/events';
import realEstateService from '../../../services/real-estate/real-estate.service';
import userService from '../../../services/user/user.service';
import User from '../../../models/user/user.model';
import planService from '../../../services/plan/plan.service'
import {
  getPaymentLinkInformationsById,
  payPlanByPaymentLinkId
} from '../../../services/payment/payment.service';

Modal.setAppElement('#root');

class Payment extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      form: {
        brand: '',
        cardNumber: '',
        ownerName: '',
        expiryDate: '',
        securityCode: '',
        readContract: false,
        generateBarCode: false,
        selectedPlan: [],
      },
      isBarCode: false,
      showInvalids: [],
      modalOpen: false,
      currentDate: '',
      dueDate: '',
      planChose: null,
      recurrenceModalVisible: false,
      acceptRecurrence: false,
      planName: '',
      planDetails: null,
    };

    this.customStyles = {
      content: {
        background: '#FFF',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        height: '350px',
        width: '350px',
        maxWidth: '665px',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
      },
    };
  }

  openModal = () => {
    this.setState(state => ({ modalOpen: true }));
  }

  loadData = async () => {
    try {
      const params = (new URL(document.location)).searchParams;

      const paymentLinkId = params.get('paymentLinkId');

      const { data, status } = await getPaymentLinkInformationsById({ paymentLinkId });

      if (status === 404) {
        throw new Error('O link informado está inválido!');
      }

      if (status !== 200) {
        throw new Error('Não foi possível buscar as informações!')
      }

      const dataSerialized = {
        ...data,
        plan: {
          ...data.plan,
          priceFormatted: currencyUtil.format({ value: data.plan.price })
        }
      }

      this.setState(prevState => ({
        ...prevState,
        planDetails: dataSerialized,
      }));
    } catch (err) {
      toast.error(err.message || 'Ocorreu um erro inesperado!', {
        autoClose: false
      });

      setTimeout(() => {
        window.open('/', '_self');
      }, 2000);
    }
  }

  componentDidMount() {
    this.loadData()
  }

  handleFormChange = event => {
    const { name, value, type, checked } = event.target;

    let formattedValue = '';
    if (name === 'expiryDate') {

      try {
        const splitted = value.split('/');
        if (splitted[0] && splitted[0].length > 1) {
          const month = parseInt(splitted[0]);


          const currentMonth = new Date().getMonth();
          const currentYear = (new Date()).getFullYear();
          const currentDate = new Date(`${currentMonth}-30-${currentYear}`)
          const year = parseInt(splitted[1]);
          const dueDate = new Date(`${month}-30-${year}`)
          this.setState(state => ({ currentDate, dueDate }));
          if (month > 12) formattedValue += '12';

          if (splitted[1] && splitted[1].length === 4) {

            if (year < currentYear) {

              formattedValue += currentYear.toString();
            }
          }
        }
      } catch (error) {
        // Do nothing
      }
    }
    if (name !== 'readContract' && name !== 'generateBarCode') {
      this.setState(state => ({
        ...state,
        form: {
          ...state.form,
          [name]: formattedValue || value,
        },
      }));
    } else {
      this.setState(state => ({
        ...state,
        form: {
          ...state.form,
          [name]: type === 'checkbox' ? checked : value,
        },
      }))
    }
  };

  validateCardNumber(number) {
    const card = number.replace(/[^\d]+/g, '');
    return this.luhnCheck(card);
  }

  luhnCheck(val) {
    var sum = 0;
    for (var i = 0; i < val.length; i++) {
      var intVal = parseInt(val.substr(i, 1));
      if (i % 2 == 0) {
        intVal *= 2;
        if (intVal > 9) {
          intVal = 1 + (intVal % 10);
        }
      }
      sum += intVal;
    }
    return (sum % 10) == 0;
  }

  handleFormSubmit = async ({ acceptRecurrence = false }) => {
    const { phoneNumber, cellphoneNumber, personName, email,
      document, } = this.props.formValue.form;

    const data = {
      ...this.state.form,
      ...this.props.formValue,
    };
    const validationErrors = [];
    let optionalFields;

    if (
      phoneNumber &&
      phoneNumber.replace(/[^a-zA-Z0-9]+/g, '').substr(2).length < 11
    ) {
      toast.error('Erro! Verifique se todos os campos foram preenchidos corretamente', {
        autoClose: false,
      });

      return;
    }

    if (!this.state.isBarCode) {
      optionalFields = [
        'social',
        'phoneNumber',
        'cellphoneNumber',
        'email',
        'document',
        'selectedPlan',
        'brand',
        'personName'
      ];
    } else if (!this.props.formValue.showInvalids) {
      if (phoneNumber && cellphoneNumber && personName && email && document) {
        optionalFields = [
          'social',
          'ownerName',
          'selectedPlan',
          'brand',
          'cardNumber',
          'expiryDate',
          'securityCode'
        ];
      } else {
        toast.error('Os campos obrigatórios não podem ser nulos. Por favor, verifique-os.', {
          autoClose: 3000,
          closeButton: false,
          draggable: false,
        });
        this.props.setInvalid(true);
        return;
      }
    } else {
      optionalFields = [
        'social',
        'ownerName',
        'selectedPlan',
        'brand',
        'cardNumber',
        'expiryDate',
        'securityCode'
      ];
    }
    objectUtil.keys(data).forEach(key => {
      if (optionalFields.find(f => f === key)) return;

      if (
        data[key] === null ||
        data[key] === undefined ||
        data[key] === '' ||
        data[key].length === 0
      ) {
        validationErrors.push(`${key} cannot be empty.`);
      }
      if (key === 'cardNumber' && !this.validateCardNumber(this.state.form.cardNumber)) {
        validationErrors.push(`cardNumber invalid`);
      }
    });

    if (validationErrors.length > 0) {
      toast.error('Erro! Verifique se todos os campos foram preenchidos corretamente', {
        autoClose: 3000,
        closeButton: false,
        draggable: false,
      });

      this.setState(state => ({
        ...state,
        showInvalids: validationErrors,
      }));
      return;
    }

    this.handleEmitInvoiceButtonClick({ data });
  };

  handleEmitInvoiceButtonClick = async ({ data }) => {
    try {
      domEventsUtil.dispatch({
        name: eventConstants.LOADING_ANIMATION,
        params: { show: true, },
      });

      const params = (new URL(document.location)).searchParams;

      const paymentLinkId = params.get('paymentLinkId');

      const { response } = await payPlanByPaymentLinkId({
        paymentInfo: data.barCodeInfoPayment,
        creditCardInfo: data,
        isCompany: data.isCompany,
        paymentLinkId,
      });

      if (response && response.status !== 204) {
        throw response.data.message || 'Ocorreu um erro inesperado!';
      }

      const user = userService.retrieve();

      let userProfile = await userService.getUserProfile({
        userId: user._id,
        token: user.token
      });

      localStorage.setItem("user", JSON.stringify(userProfile));

      toast.success('Pagamento realizado com sucesso!');

      setTimeout(() => {
        window.open('/', '_self');
      }, 2000);
    } catch (err) {
      toast.error(err.replace('Usuário', 'Você'), {
        autoClose: false,
      });
    } finally {
      domEventsUtil.dispatch({
        name: eventConstants.LOADING_ANIMATION,
        params: { show: false, },
      });
    }
  };

  handleButtonClick = () => {
    this.setState({ isBarCode: !this.state.isBarCode });
  }

  handleOpenRecurrenceModal = () => {
    let planInfo = JSON.parse(localStorage.getItem('planInfo'));

    this.setState(prevState => ({
      ...prevState,
      recurrenceModalVisible: true,
      planName: planInfo.name
    }));
  }

  handleAcceptRecurrence = () => {
    this.setState(prevState => ({
      ...prevState,
      recurrenceModalVisible: false,
    }));

    this.handleFormSubmit({ acceptRecurrence: true });
  }

  handleNotAcceptRecurrence = () => {
    this.setState(prevState => ({
      ...prevState,
      recurrenceModalVisible: false,
    }));

    this.handleFormSubmit({ acceptRecurrence: false });
  }

  render() {
    const { form, isBarCode, showInvalids, planDetails } = this.state;

    return (
      <Wrapper>
        <Form
          onChange={this.handleFormChange}
          onSubmit={this.handleFormSubmit}
        >
          <SectionTitle>
            Selecione a forma de pagamento
          </SectionTitle>
          <div style={{ 'display': 'flex', marginBottom: 20 }}>
            <Button
              isActive={!isBarCode}
              // onClick={this.handleButtonClick}
              name={'creditCard'}
            >
              Cartão de crédito
            </Button>
          </div>
          {!isBarCode ?
            <>
              <SectionTitle>
                Cartão de crédito
              </SectionTitle>
              <BrandSelector options={brands} />
              <div className={'form-floating'}>
                <CardNumberInput isInvalid={showInvalids.includes('cardNumber cannot be empty.')} id={'cardNumber'} />
                <label className={'form-label'} htmlFor={'cardNumber'}>Nº do cartão</label>
              </div>
              <div className={'form-floating'}>
                <OwnerNameInput isInvalid={showInvalids.includes('ownerName cannot be empty.')} />
                <label className={'form-label'} htmlFor={'ownerName'}>Nome no cartão</label>
              </div>
              <>
                <div className={'form-floating'}>
                  <ExpiryDateInput
                    defaultValue={form.expiryDate}
                    value={form.expiryDate}
                    isInvalid={showInvalids.includes('expiryDate cannot be empty.')}
                  />
                  <label className={'form-label'} htmlFor={'expiryDate'}>Data de validade</label>
                </div>
                {this.state.dueDate < this.state.currentDate && <ErrorMessage>insira uma data de vencimento válida</ErrorMessage>}
              </>
              <InlineWrapper>
                <div style={{ flexDirection: 'column', width: "100%" }}>
                  <div className={'form-floating'}>
                    <SecurityCodeInput isInvalid={showInvalids.includes('securityCode cannot be empty.')} />
                    <label className={'form-label'} htmlFor={'securityCode'}>Código de segurança</label>
                  </div>
                </div>
              </InlineWrapper>
            </>
            :
            <>
              <SectionTitle>
                Boleto bancário
              </SectionTitle>
              <div style={{ marginBottom: 20 }}>
                <p>
                  Seu boleto será gerado ao final da criação do anúncio
                </p>
              </div>
              <SectionTitle>
                Boleto bancário
              </SectionTitle>
              <TransferenceInfoWrapper>
                <TransferenceInfo>
                  Banco: Lorem Ipsum
                </TransferenceInfo>
                <TransferenceInfo>
                  Agência: 0000
                </TransferenceInfo>
                <TransferenceInfo>
                  Conta: 00000-0
                </TransferenceInfo>
                <TransferenceInfo>
                  Favorecido: Koort Imóveis
                </TransferenceInfo>
                <TransferenceInfo>
                  CNPJ: 00.000.000/0001-00
                </TransferenceInfo>
              </TransferenceInfoWrapper>
            </>
          }
          <div style={{ 'display': 'flex', flexDirection: 'row' }}>
            <p>Li e concordo com os termos do
              <Contract>
                contrato do plano
              </Contract>
            </p>
            <Checkbox
              name='readContract'
              checked={form.readContract}
            />
          </div>

          {planDetails !== null && (
            <>
              <PlanDetails>
                <PlanDetailsWrapper>
                  <PlanDetailsWrapperBoxTop>
                    <strong>{planDetails.plan.name}</strong>

                    <p>{planDetails.plan.description}</p>

                    <PlanUseContent>
                      <AdsInUseContent>
                        <Label>Número de anúncios</Label>
                        <h3>{planDetails.plan.numberOfAds}</h3>
                      </AdsInUseContent>

                      <PlanDivider />

                      <AdsTotalContent>
                        <Label>Dias contratados</Label>
                        <h3>{planDetails.plan.contractDuration}</h3>
                      </AdsTotalContent>
                    </PlanUseContent>
                  </PlanDetailsWrapperBoxTop>

                  <Separator />

                  <PlanDetailsWrapperBoxBottom>
                    <PriceAndContractContent>
                      <PriceContent>
                        <Label>Preço</Label>
                        <h3>
                          {planDetails.plan.priceFormatted}
                          {planDetails.isRecurrence && <sub>/mês</sub>}
                        </h3>
                      </PriceContent>
                    </PriceAndContractContent>
                  </PlanDetailsWrapperBoxBottom>
                </PlanDetailsWrapper>
              </PlanDetails>
            </>
          )}

          {
            form.readContract ?
              <FinishButton
                onClick={this.handleFormSubmit}
                disabled={!form.readContract}
              >
                Realizar pagamento
              </FinishButton>
              :
              <DisabledButton disabled={!form.readContract}>
                Realizar pagamento
              </DisabledButton>
          }
        </Form>

        <Modal
          isOpen={this.state.modalOpen}
          style={this.customStyles}
          contentLabel="Example Modal"
        >
          <div className={'container_congratulations'}>
            <h1>Parabéns, seu plano foi <br />comprado com sucesso!</h1>
            <br />
            <h2>Plano escolhido</h2>
            <h3>{this.state.planChose}</h3>
            <DashboardButton onClick={() => {
              window.location.href = pathsConstants.LOADING_USER_INFO
            }} >
              Acessar meu dashboard
            </DashboardButton>
          </div>
        </Modal>
      </Wrapper>
    );
  }
}

const PriceAndContractContent = styled.div`
  padding: 0 24px 24px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  min-height: 140px;
`;

const ErrorMessage = styled.label`
  color: ${COLOR_7};
  font-size: 12px;
  margin-top: -5px;
  margin-bottom: 5px;
  display: block;
`;

const PriceContent = styled.div`
  h3 {
    font-size: 24px;
    font-weight: 600;
  }

  sub {
    font-size: 12px;
    font-weight: 500;
  }
`;

const Separator = styled.div`
  background-color: #E5E5E5;
  height: 1px;
  margin: 32px 0;
  width: 100%;

  @media (min-width: 876px) and (max-width: 1020px) {
    height: 252px;
    width: 1px;
  }
`;

const AdsTotalContent = styled.div`
  h3 {
    font-size: 24px;
    font-weight: 600;
  }
`;

const AdsInUseContent = styled.div`
    h3 {
    font-size: 24px;
    font-weight: 600;
    color: #363636;
  }
`;

const PlanDivider = styled.div`
  background-color: #E5E5E5;
  height: 58px;
  width: 1px;
`;

const PlanUseContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 24px;
  padding: 0 24px;
`;

const PlanDetails = styled.div`
  display: flex;

  @media (max-width: 1020px) {
    flex-direction: column;
  }
`;

const PlanDetailsWrapperBoxTop = styled.div`
  @media (min-width: 876px) and (max-width: 1020px) {
    max-width: 360px;
  }
`;

const PlanDetailsWrapperBoxBottom = styled.div`
  width: 100%;

  @media (min-width: 876px) and (max-width: 1020px) {
    max-width: 290px;
  }
`;

const PlanDetailsWrapper = styled.div`
  width: 100%;
  margin: 32px 0;

  border: 1px solid #E5E5E5;
  border-radius: 4px;

  strong {
    display: block;
    font-size: 24px;
    font-weight: 600;
    padding: 24px 24px 0 24px;
  }

  p {
    font-size: 16px;
    margin-top: 12px;
    margin-bottom: 0;
    opacity: 0.8;
    padding: 0 24px;
  }

  @media (min-width: 876px) and (max-width: 1020px) {
    max-width: unset;
    display: flex;
    align-items: center;
    justify-content: space-between;

    height: 300px;

    strong {
      padding-top: unset;
    }
  }

  @media (max-width: 875px) {
    max-width: unset;
  }
  `;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 140px;
  padding: 24px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  margin: 0;
  flex-grow: 1;
  width: 100%;
  max-width: 573px;

`;

const Button = styled(BaseButton)`
  flex-grow: 1;
  width: 1px;
  font-weight: 300;
  border: solid 1px ${COLOR_1};

  ${({ isActive }) => !isActive && css`
    color: ${COLOR_4};
    background-color: ${COLOR_2};
    border-color: ${COLOR_17};
  ` }
`;

const Label = styled(BaseLabel)`
  margin-bottom: 10px;
  margin-top: 5px;
  font-weight: 600;
`;

const SectionTitle = styled(BaseLabel)`
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 10px;
`;

const BrandSelector = styled(Selector).attrs({
  name: 'brand',
})`width: 100%`;

const CardNumberInput = styled(MaskedBaseInput).attrs({
  placeholder: 'Nº do cartão',
  name: 'cardNumber',
  className: 'form-control',
  mask: masks.creditCardNumber,
})`
  width: 100% !important;
`;

const OwnerNameInput = styled(CardNumberInput).attrs({
  as: BaseInput,
  placeholder: 'Nome no cartão',
  name: 'ownerName',
  id: 'ownerName',
})``;

const ExpiryDateInput = styled(CardNumberInput).attrs({
  placeholder: 'Data de validade(MM/AAAA)',
  name: 'expiryDate',
  id: 'expiryDate',
  mask: masks.creditCardExpiryDate,
})``;

const InlineWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

const SecurityCodeInput = styled(CardNumberInput).attrs({
  placeholder: 'Código de segurança',
  name: 'securityCode',
  id: 'securityCode',
  type: 'password',
  mask: masks.creditCardSecurityCode,
})`
  align-self: center;
  margin: 0;
`;

const QuestionMarkIcon = styled(Question)`
  ${iconBaseStyle}
  align-self: center;
  margin-left: -25px;
  height: 14px;
`;

const DashboardButton = styled(BaseButton).attrs({
  type: 'submit',
})`
  background-color: ${COLOR_1};
  border: none;
  color: ${COLOR_2};
  width: 200px;
  align-self: flex-end;
  margin-top: 35px;
  margin-left: 55px;
`;

const FinishButton = styled(BaseButton).attrs({
  type: 'button',
})`
  background-color: ${COLOR_13};
  border: none;
  color: ${COLOR_2};
  width: 100%;
  align-self: flex-end;
  margin-top: 10px;
`;

const DisabledButton = styled(BaseButton).attrs({
  type: 'button',
})`
  background-color: gray;
  border: none;
  color: ${COLOR_2};
  width: 100%;
  align-self: flex-end;
  margin-top: 10px;
`;

const OtherMethodsWrapper = styled.div`
  ${mqTablet`
    flex-grow: 1;
    width: 1px;
    align-self: flex-start;
  ` }
`;

const EmitInvoiceButton = styled(FinishButton)`
  font-weight: 300;
  background-color: ${COLOR_4};
  margin: 0;
  width: 100%;
  text-transform: none;
  margin-bottom: 50px;

  ${mqTablet`
    font-size: 14px;
    margin-bottom: 45px;
  ` }
`;

const TransferenceInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: solid 1px ${COLOR_4};
  border-radius: 1px;
  padding: 20px;
  margin-bottom: 20px;
`;

const TransferenceInfo = styled(BaseLabel)`
  align-self: center;
  font-size: 14px;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Contract = styled.label.attrs({
  as: Link,
  to: pathsConstants.CONTRACT,
  target: '_blank',
})`
  color: ${COLOR_1};
  text-decoration: underline;
  margin-left: 5px;
  margin-right: 10px;
`;

const brands = [
  {
    value: '',
    label: 'Selecione a bandeira'
  },
  {
    value: 'Mastercard',
    label: 'Mastercard'
  },
  {
    value: 'Visa',
    label: 'Visa'
  },
  {
    value: 'Elo',
    label: 'Elo'
  },
  {
    value: 'Amex',
    label: 'Amex'
  },
  {
    value: 'JCB',
    label: 'JCB'
  },
  {
    value: 'Aura',
    label: 'Aura'
  },
  {
    value: 'Hipercard',
    label: 'Hipercard'
  },
  {
    value: 'Diners',
    label: 'Diners'
  },
  {
    value: 'Discover',
    label: 'Discover'
  },
];

export const RecurrenceModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const RecurrenceModalCloseButton = styled.button`
  background-color: transparent;
  border: 0;
  height: min-content;
  color: rgba(255, 255, 255, 0.7);

  transition: filter .2s;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const RecurrenceModalBody = styled.div`
  font-size: 16px;
  color: #FFFFFF;
  background-color: #2B50D8;
  width: 100%;
  max-width: 400px;

  padding: 24px;
`;

export const RecurrenceModalTitleContent = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 22px;

  h3 {
    margin-left: 12px;
    font-size: 18px;
  }
`;

export const RecurrenceModalButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
`;

export const ConfirmRecurrenceButton = styled.button`
  padding: 8px 24px;
  background-color: #FFFFFF;
  border: 0;

  transition: filter .2s;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const DenyRecurrenceButton = styled.button`
  background-color: transparent;
  border: 0;
  margin-top: 14px;
  color: rgba(255, 255, 255, 0.5);

  transition: filter .2s;

  &:hover {
    filter: brightness(0.9);
  }
`;

export default Payment;
