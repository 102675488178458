import React, { useEffect, useState } from "react";
import { RiCloseLine } from "react-icons/ri";
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IntlCurrencyInput from 'react-intl-currency-input';
import { toast } from "react-toastify";

import { Select } from "../filters-old/components/select";
import Category from "./components/category/Category";
import { AirbnbSlider, AntSwitch, AirbnbThumbComponent } from "./components/mui-component";

import listingV2Service from "../../../services/listing/listing-v2.service";

import { CHECKBOX_TYPE, RADIO_TYPE, SPECTRUM_TYPE } from "../../../constants/filters";
import currencyUtil from '../../../utils/currency/currency.util';
import queryStringUtil from "../../../utils/query-string/query-string.util";

import {
  FilterModal,
  FilterModalHeader,
  FilterModalBody,
  FilterModalContent,
  Wrapper,
  TopWrapper,
  OpenButton,
  FilterIcon,
  TransactionsButtonsGroup,
  TransactionButtonRental,
  TransactionButtonSale,
  ItemFormWrapper,
  SelectWrapper,
  ButtonGroup,
  ButtonsList,
  PriceRangeGroup,
  PriceInputsGroup,
  PriceInput,
  FilterModalFooter,
  ButtonsListItem,
  SearchButton,
  ClearFilterButton
} from './styles';

export function Filters(props) {
  const [transactionOptions, setTransactionOptions] = useState([
    { label: "Comprar", value: 0 },
    { label: "Lançamentos", value: 1 },
    { label: "Alugar", value: 2 },
  ]);

  const [modalVisible, setModalVisible] = useState(false);

  const [selectedTransactionOption, setSelectedTransactionOption] = useState({ label: "Comprar", value: 0 });
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [bedroomsOptions, setBedroomsOptions] = useState([]);
  const [bathroomsOptions, setBathroomsOptions] = useState([]);
  const [suitesOptions, setSuitesOptions] = useState([]);
  const [parkingSpacesOptions, setParkingSpacesOptions] = useState([]);
  const [filterOptions, setFilterOptions] = useState([]);
  const [filterOptionsDedicatedPropertyTypes, setFilterOptionsDedicatedPropertyTypes] = useState([]);
  const [selectedCityOption, setSelectedCityOption] = useState(null);
  const [isReleaseTransaction, setIsReleaseTransaction] = useState(false);

  const [bedroomsOptionsSelected, setBedroomsOptionsSelected] = useState([]);
  const [bathroomsOptionsSelected, setBathroomsOptionsSelected] = useState([]);
  const [suitesOptionsSelected, setSuitesOptionsSelected] = useState([]);
  const [parkingSpacesOptionsSelected, setParkingSpacesOptionsSelected] = useState([]);

  const [minAndMaxPriceValue, setMinAndMaxPriceValue] = useState([0, 0]);
  const [currentPriceRangeValue, setCurrentPriceRangeValue] = useState([0, 0]);

  const [minAndMaxAreaValue, setMinAndMaxAreaValue] = useState([0, 0]);
  const [currentAreaRangeValue, setCurrentAreaRangeValue] = useState([0, 0]);

  const [rentalAndSaleFeaturesData, setRentalAndSaleFeaturesData] = useState(null);

  const [filterResultsCounter, setFilterResultsCounter] = useState(0);

  async function resetFilter() {
    const { history } = props;
    const queryParams = queryStringUtil.parse(history);
    const {
      transaction,
      city,
      neighborhood,
      type,
      bedrooms,
      area,
      parkingSpaces,
      mainPrice,
      use,
      bathrooms,
      suites,
      uf,
      //features: featuresParam,
      typeOfGuarantee
    } = queryParams;
    //const { features } = await getFeatures()

    const features = rentalAndSaleFeaturesData[transaction === 2 ? 'rental' : 'sale'];
    const propertyFeaturesOptions = features.features.property.map(property => {
      return {
        label: property.value,
        name: property.index,
        value: features === property.index || Array.isArray(features) && features.includes(property.index)
      }
    });

    const condoFeaturesOptions = features.features.condo.map(condo => {
      return {
        label: condo.value,
        name: condo.index,
        value: features === condo.index || Array.isArray(features) && features.includes(condo.index)
      }
    });


    const nearFeaturesOptions = features.features.near.map(near => {
      return {
        label: near.value,
        name: near.index,
        value: features === near.index || Array.isArray(features) && features.includes(near.index)
      }
    });

    const resetedFilterOptions = [
      {
        label: 'Características do Imóvel',
        name: 'features',
        type: CHECKBOX_TYPE,
        options: propertyFeaturesOptions,
      },
      {
        label: 'Características do Condomínio',
        name: 'features',
        type: CHECKBOX_TYPE,
        options: condoFeaturesOptions,
      },
      {
        label: 'Características do Entorno',
        name: 'features',
        type: CHECKBOX_TYPE,
        options: nearFeaturesOptions,
      },
    ];

    setFilterOptions(resetedFilterOptions)


    const useTypesOptions = features.useTypes.map(useType => {
      return {
        label: useType.name,
        name: useType.index,
        value: features === useType.index || Array.isArray(features) && features.includes(useType.index)
      }
    });

    const newPropertyTypesOptions = features.propertyTypes.map(property => {
      return {
        label: property.name,
        name: property.index,
        value: features === property.index || Array.isArray(features) && features.includes(property.index)
      }
    });

    const resetedFilterOptionsDedicatedPropertyType = [
      {
        label: 'Uso',
        name: 'use',
        type: CHECKBOX_TYPE,
        options: useTypesOptions,
      },
      {
        label: 'Tipos de imóvel',
        name: 'type',
        type: CHECKBOX_TYPE,
        options: newPropertyTypesOptions,
      }];

    setFilterOptionsDedicatedPropertyTypes(resetedFilterOptionsDedicatedPropertyType)

    setCurrentPriceRangeValue(minAndMaxPriceValue)
    setCurrentAreaRangeValue(minAndMaxAreaValue)

    setBedroomsOptionsSelected([])
    setBathroomsOptionsSelected([])
    setSuitesOptionsSelected([])
    setParkingSpacesOptionsSelected([])

    getCountFilterResults()
  }

  const currencyConfig = {
    locale: "pt-BR",
    formats: {
      number: {
        BRL: {
          style: "currency",
          currency: "BRL",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        },
      },
    },
  };

  const handleSetReleaseTransaction = (event) => {
    setIsReleaseTransaction(event.target.checked);
  };

  function handleOpenModal() {
    setModalVisible(true);
  }

  function handleCloseModal() {
    setModalVisible(false);
  }

  useEffect(() => {
    async function loadData() {
      const { history } = props;
      const queryParams = queryStringUtil.parse(history);
      const { transaction, city } = queryParams;

      await getCities({ adType: transaction });
      const { features } = await getFeatures();
      // await getDistricts({ city, adType: transaction })

      populateFields({ features });
    }

    loadData();
  }, []);

  async function populateFields({ features, isAuto = false }) {
    const { history } = props;
    const queryParams = queryStringUtil.parse(history);
    const {
      transaction,
      city,
      neighborhood,
      type,
      bedrooms,
      area,
      parkingSpaces,
      mainPrice,
      use,
      bathrooms,
      suites,
      uf,
      features: featuresParam,
      typeOfGuarantee
    } = queryParams;

    if (!isAuto) {
      features = features[transaction === 2 ? 'rental' : 'sale'];
    }

    let fieldsToPopulate = {};

    if (transaction >= 0) {
      const types = {
        0: "Comprar",
        1: "Lançamentos",
        2: "Alugar",
      };

      const transactionType = types[transaction];

      fieldsToPopulate.selectedTransactionOption = {
        label: transactionType,
        value: transaction,
      };
    }

    if (city) {
      fieldsToPopulate.selectedCityOption = {
        label: city,
        value: city,
        uf: uf,
      };
    }

    if (neighborhood) {
      fieldsToPopulate.selectedDistrictOption = {
        label: neighborhood,
        value: neighborhood,
      };
    }

    if (bedrooms) {
      fieldsToPopulate.selectedBedroomOption = {
        label: String(bedrooms),
        value: String(bedrooms),
      };
    }

    const useTypesOptions = features.useTypes.map(useType => {
      return {
        label: useType.name,
        name: useType.index,
        value: use === useType.index || Array.isArray(use) && use.includes(useType.index) ? true : false,
      }
    });

    const serializedBedrooms = [...new Set(features.bedrooms)].filter(item => item <= 4);

    const bedroomsOptionsMapped = serializedBedrooms.map(bedroom => {
      if (bedroom < 4) {
        return {
          label: String(bedroom),
          value: String(bedroom),
        }
      }

      return {
        label: `${bedroom}+`,
        value: `${bedroom}`,
      }
    });

    const serializedBathrooms = [...new Set(features.bathrooms)].filter(item => item <= 4);
    const bathroomsOptionsMapped = serializedBathrooms.map(bathroom => {
      if (bathroom < 4) {
        return {
          label: String(bathroom),
          value: String(bathroom),
        }
      }

      return {
        label: `${bathroom}+`,
        value: `${bathroom}`,
      }
    });

    const serializedSuites = [...new Set(features.suites)].filter(item => item <= 4);
    const suitesOptionsMapped = serializedSuites.map(suite => {
      if (suite < 4) {
        return {
          label: String(suite),
          value: String(suite),
        }
      }

      return {
        label: `${suite}+`,
        value: `${suite}`,
      }
    });

    const serializedParkingSpaces = [...new Set(features.parkingSpaces)].filter(item => item <= 4);
    const parkingSpacesOptionsMapped = serializedParkingSpaces.map(parkingSpace => {
      if (parkingSpace < 4) {
        return {
          label: String(parkingSpace),
          value: String(parkingSpace),
        }
      }

      return {
        label: `${parkingSpace}+`,
        value: `${parkingSpace}`,
      }
    });

    const propertyFeaturesOptions = features.features.property.map(property => {
      return {
        label: property.value,
        name: property.index,
        value: featuresParam === property.index || Array.isArray(featuresParam) && featuresParam.includes(property.index) ? true : false,
      }
    });

    const condoFeaturesOptions = features.features.condo.map(condo => {
      return {
        label: condo.value,
        name: condo.index,
        value: featuresParam === condo.index || Array.isArray(featuresParam) && featuresParam.includes(condo.index) ? true : false,
      }
    });

    const nearFeaturesOptions = features.features.near.map(near => {
      return {
        label: near.value,
        name: near.index,
        value: featuresParam === near.index || Array.isArray(featuresParam) && featuresParam.includes(near.index) ? true : false,
      }
    });

    const newPropertyTypesOptions = features.propertyTypes.map(property => {
      return {
        label: property.name,
        name: property.index,
        value: featuresParam === property.index || Array.isArray(featuresParam) && featuresParam.includes(property.index) ? true : false,
      }
    });

    const typeOfGuaranteeOptions = features.typeOfGuarantee.map(guarantee => {
      return {
        label: guarantee.name,
        name: guarantee.index,
        value: typeOfGuarantee === guarantee.index || Array.isArray(typeOfGuarantee) && typeOfGuarantee.includes(guarantee.index) ? true : false,
      }
    });

    fieldsToPopulate.filterOptions = [
      {
        label: 'Características do Imóvel',
        name: 'features',
        type: CHECKBOX_TYPE,
        options: propertyFeaturesOptions,
      },
      {
        label: 'Características do Condomínio',
        name: 'features',
        type: CHECKBOX_TYPE,
        options: condoFeaturesOptions,
      },
      {
        label: 'Características do Entorno',
        name: 'features',
        type: CHECKBOX_TYPE,
        options: nearFeaturesOptions,
      },
    ];

    if (!!features.typeOfGuarantee.length) {
      fieldsToPopulate.filterOptions.push({
        label: 'Garantia',
        name: 'typeOfGuarantee',
        type: CHECKBOX_TYPE,
        options: typeOfGuaranteeOptions,
      });
    }

    const filterOptionsDedicatedPropertyType = [
      {
        label: 'Uso',
        name: 'use',
        type: CHECKBOX_TYPE,
        options: useTypesOptions,
      },
      {
        label: 'Tipos de imóvel',
        name: 'type',
        type: CHECKBOX_TYPE,
        options: newPropertyTypesOptions,
      }];


    const minAndMaxPrice = features.prices;
    const minAndMaxArea = features.areas;

    setBedroomsOptions(bedroomsOptionsMapped);
    setBathroomsOptions(bathroomsOptionsMapped);
    setSuitesOptions(suitesOptionsMapped);
    setParkingSpacesOptions(parkingSpacesOptionsMapped);

    setMinAndMaxPriceValue(minAndMaxPrice)
    setMinAndMaxAreaValue(minAndMaxArea);
    setFilterOptions(fieldsToPopulate.filterOptions)
    setFilterOptionsDedicatedPropertyTypes(filterOptionsDedicatedPropertyType);

    setSelectedCityOption(fieldsToPopulate.selectedCityOption);

    if (!isAuto) {
      setSelectedTransactionOption(fieldsToPopulate.selectedTransactionOption);
    }

    if (mainPrice && !isAuto) {
      setCurrentPriceRangeValue(mainPrice);
    } else {
      setCurrentPriceRangeValue(minAndMaxPrice);
    }
    if (area && !isAuto) {
      setCurrentAreaRangeValue(area);
    } else {
      setCurrentAreaRangeValue(minAndMaxArea);
    }

    if (fieldsToPopulate.selectedTransactionOption.label === "Lançamentos") {
      setIsReleaseTransaction(true);
    }

    if (bedrooms) {
      if (Array.isArray(bedrooms)) {
        const bed = bedrooms.map(item => item.toString())

        setBedroomsOptionsSelected(bed);
      } else {
        setBedroomsOptionsSelected([bedrooms.toString()]);
      }
    }
    if (bathrooms) {
      if (Array.isArray(bathrooms)) {
        const bath = bathrooms.map(item => item.toString())

        setBathroomsOptionsSelected(bath);
      } else {
        setBathroomsOptionsSelected([bathrooms.toString()]);
      }
    }
    if (suites) {
      if (Array.isArray(suites)) {
        const st = suites.map(item => item.toString())

        setSuitesOptionsSelected(st);
      } else {
        setSuitesOptionsSelected([suites.toString()]);
      }
    }
    if (parkingSpaces) {
      if (Array.isArray(parkingSpaces)) {
        const st = parkingSpaces.map(item => item.toString())

        setParkingSpacesOptionsSelected(st);
      } else {
        setParkingSpacesOptionsSelected([parkingSpaces.toString()]);
      }
    }
  }

  async function getFeatures() {
    try {
      const { data: dataFeatures } = await listingV2Service.getFeatures();

      setRentalAndSaleFeaturesData(dataFeatures);

      return { features: dataFeatures };
    } catch (error) {
      toast.error(error.message, {
        autoClose: 3000,
      });
    }
  }

  async function getCities({ adType }) {
    try {
      const types = {
        0: "Venda",
        1: "Lançamento",
        2: "Locação",
      };

      const transactionType = types[adType];

      const { status, data } = await listingV2Service.getCities({ adType: transactionType });


      if (status !== 200) {
        throw new Error('Não foi possível buscar as cidades!');
      }

      const citiesOptionsMapped = data.map(item => {
        return {
          label: item.state,
          options: item.cities.map(city => {
            return {
              value: city,
              label: city,
              uf: item.uf,
            }
          })
        }
      });

      setCitiesOptions(citiesOptionsMapped);
    } catch (error) {
      toast.error(error.message, {
        autoClose: 3000,
      });
    }
  }

  function handleChangeTransaction(option) {
    const optionMapped = transactionOptions.filter(transaction => transaction.label === option)[0];

    setSelectedTransactionOption(optionMapped);

    getCities({ adType: option === 'Alugar' ? 2 : 0, getPriceAndArea: true });
    populateFields({ features: rentalAndSaleFeaturesData[option === 'Alugar' ? 'rental' : 'sale'], isAuto: true });
  }

  function handleOptionChangeUseAndProperty(categoryIndex, optionName, value) {
    const newFilterOptions = [...filterOptionsDedicatedPropertyTypes];
    newFilterOptions[categoryIndex] = { ...newFilterOptions[categoryIndex] };
    newFilterOptions[categoryIndex].options = [...newFilterOptions[categoryIndex].options];

    let optionIndex;
    if (newFilterOptions[categoryIndex].type !== SPECTRUM_TYPE)
      optionIndex = newFilterOptions[categoryIndex].options.findIndex(
        (option) => option.name === optionName
      );
    else optionIndex = 0;

    newFilterOptions[categoryIndex].options[optionIndex] = {
      ...newFilterOptions[categoryIndex].options[optionIndex],
    };

    if (newFilterOptions[categoryIndex].type === RADIO_TYPE) {
      newFilterOptions[categoryIndex].options.map((option) => {
        option.value = false;
        return option;
      });
      newFilterOptions[categoryIndex].options[optionIndex].value = true;
    } else {
      newFilterOptions[categoryIndex].options[optionIndex].value = value;
    }

    setFilterOptionsDedicatedPropertyTypes(newFilterOptions);
  }

  useEffect(() => {
    getCountFilterResults();
  }, [
    selectedCityOption,
    filterOptionsDedicatedPropertyTypes,
    bedroomsOptionsSelected,
    bathroomsOptionsSelected,
    suitesOptionsSelected,
    parkingSpacesOptionsSelected,
    filterOptions,
    isReleaseTransaction,
  ]);

  function handleOptionChange(categoryIndex, optionName, value) {
    const newFilterOptions = [...filterOptions];
    newFilterOptions[categoryIndex] = { ...newFilterOptions[categoryIndex] };
    newFilterOptions[categoryIndex].options = [...newFilterOptions[categoryIndex].options];

    let optionIndex;
    if (newFilterOptions[categoryIndex].type !== SPECTRUM_TYPE)
      optionIndex = newFilterOptions[categoryIndex].options.findIndex(
        (option) => option.name === optionName
      );
    else optionIndex = 0;

    newFilterOptions[categoryIndex].options[optionIndex] = {
      ...newFilterOptions[categoryIndex].options[optionIndex],
    };

    if (newFilterOptions[categoryIndex].type === RADIO_TYPE) {
      newFilterOptions[categoryIndex].options.map((option) => {
        option.value = false;
        return option;
      });
      newFilterOptions[categoryIndex].options[optionIndex].value = true;
    } else {
      newFilterOptions[categoryIndex].options[optionIndex].value = value;
    }

    setFilterOptions(newFilterOptions);
  }

  function handleChangePriceRange(_, value) {
    setCurrentPriceRangeValue(value);
  }

  function handleChangeMinPrice(_, value) {
    const newCurrentPriceRangeValue = [value, currentPriceRangeValue[1]];

    setCurrentPriceRangeValue(newCurrentPriceRangeValue);
  }
  function handleChangeMaxPrice(_, value) {
    const newCurrentPriceRangeValue = [currentPriceRangeValue[0], value];
    setCurrentPriceRangeValue(newCurrentPriceRangeValue);
  }

  function handleChangeAreaRange(_, value) {
    setCurrentAreaRangeValue(value);
  }

  function handleChangeMinArea(event) {
    const value = event.target.value;

    const newCurrentAreaRangeValue = [value, currentAreaRangeValue[1]];

    setCurrentAreaRangeValue(newCurrentAreaRangeValue);
  }
  function handleChangeMaxArea(event) {
    const value = event.target.value;

    const newCurrentAreaRangeValue = [currentAreaRangeValue[0], value];
    setCurrentAreaRangeValue(newCurrentAreaRangeValue);
  }

  function handleSelectBedrooms(option) {
    const bedroomsSelected = bedroomsOptionsSelected;

    if (bedroomsSelected.includes(option)) {
      setBedroomsOptionsSelected(bedroomsSelected.filter(item => item !== option));
    } else {
      setBedroomsOptionsSelected(state => [...state, option]);
    }
  }

  function handleSelectBathrooms(option) {
    const bathroomsSelected = bathroomsOptionsSelected;

    if (bathroomsSelected.includes(option)) {
      setBathroomsOptionsSelected(bathroomsSelected.filter(item => item !== option));
    } else {
      setBathroomsOptionsSelected(state => [...state, option]);
    }
  }

  function handleSelectSuites(option) {
    const suitesSelected = suitesOptionsSelected;

    if (suitesSelected.includes(option)) {
      setSuitesOptionsSelected(suitesSelected.filter(item => item !== option));
    } else {
      setSuitesOptionsSelected(state => [...state, option]);
    }
  }

  function handleSelectParkingSpaces(option) {
    const parkingSpacesSelected = parkingSpacesOptionsSelected;

    if (parkingSpacesSelected.includes(option)) {
      setParkingSpacesOptionsSelected(parkingSpacesSelected.filter(item => item !== option));
    } else {
      setParkingSpacesOptionsSelected(state => [...state, option]);
    }
  }
  async function getCountFilterResults() {
    const selectedFilters = {};
    filterOptions.forEach((category) => {
      if (category.type === CHECKBOX_TYPE) {
        if (!selectedFilters[category.name])
          selectedFilters[category.name] = [];
        category.options.forEach((option) => {
          if (option.value) {
            selectedFilters[category.name].push(option.name);
          }
        });
      } else if (category.type === RADIO_TYPE) {
        const option = category.options.find((option) => option.value);
        if (option) selectedFilters[category.name] = option.name;
      } else {
        selectedFilters[category.name] = category.options[0].value;
      }
    });

    filterOptionsDedicatedPropertyTypes.forEach((category) => {
      if (category.type === CHECKBOX_TYPE) {
        if (!selectedFilters[category.name])
          selectedFilters[category.name] = [];
        category.options.forEach((option) => {
          if (option.value) {
            selectedFilters[category.name].push(option.name);
          }
        });
      } else if (category.type === RADIO_TYPE) {
        const option = category.options.find((option) => option.value);
        if (option) selectedFilters[category.name] = option.name;
      } else {
        selectedFilters[category.name] = category.options[0].value;
      }
    });

    if (selectedCityOption !== null) {
      selectedFilters["city"] = selectedCityOption.value;
      selectedFilters["uf"] = selectedCityOption.uf;
    } else {
      selectedFilters["city"] = null;
    }

    if (bedroomsOptionsSelected.length > 0) {
      selectedFilters["bedrooms"] = bedroomsOptionsSelected.map(item => Number(item));
    } else {
      selectedFilters["bedrooms"] = null;
    }

    if (bathroomsOptionsSelected.length > 0) {
      selectedFilters["bathrooms"] = bathroomsOptionsSelected.map(item => Number(item));
    } else {
      selectedFilters["bathrooms"] = null;
    }

    if (suitesOptionsSelected.length > 0) {
      selectedFilters["suites"] = suitesOptionsSelected.map(item => Number(item));
    } else {
      selectedFilters["suites"] = null;
    }

    if (parkingSpacesOptionsSelected.length > 0) {
      selectedFilters["parkingSpaces"] = parkingSpacesOptionsSelected.map(item => Number(item));
    } else {
      selectedFilters["parkingSpaces"] = null;
    }

    if (selectedTransactionOption) {
      selectedFilters["transaction"] = selectedTransactionOption.label === "Comprar" && isReleaseTransaction ? 1 : selectedTransactionOption.value;
    } else {
      selectedFilters["transaction"] = null;
    }

    selectedFilters["area"] = currentAreaRangeValue;
    selectedFilters["mainPrice"] = currentPriceRangeValue;
    selectedFilters["rect"] = JSON.parse(localStorage.getItem('@Koort:rect'));

    const { data } = await listingV2Service.getCountSearchListingFilterResults(selectedFilters);

    setFilterResultsCounter(data.total);
  }

  function handleFormSubmit(event) {
    event.preventDefault();

    const selectedFilters = {};
    filterOptions.forEach((category) => {
      if (category.type === CHECKBOX_TYPE) {
        if (!selectedFilters[category.name])
          selectedFilters[category.name] = [];
        category.options.forEach((option) => {
          if (option.value) {
            selectedFilters[category.name].push(option.name);
          }
        });
      } else if (category.type === RADIO_TYPE) {
        const option = category.options.find((option) => option.value);
        if (option) selectedFilters[category.name] = option.name;
      } else {
        selectedFilters[category.name] = category.options[0].value;
      }
    });

    filterOptionsDedicatedPropertyTypes.forEach((category) => {
      if (category.type === CHECKBOX_TYPE) {
        if (!selectedFilters[category.name])
          selectedFilters[category.name] = [];
        category.options.forEach((option) => {
          if (option.value) {
            selectedFilters[category.name].push(option.name);
          }
        });
      } else if (category.type === RADIO_TYPE) {
        const option = category.options.find((option) => option.value);
        if (option) selectedFilters[category.name] = option.name;
      } else {
        selectedFilters[category.name] = category.options[0].value;
      }
    });

    if (selectedCityOption !== null) {
      selectedFilters["city"] = selectedCityOption.value;
      selectedFilters["uf"] = selectedCityOption.uf;
    } else {
      selectedFilters["city"] = null;
    }

    // if (this.state.selectedDistrictOption) {
    //   selectedFilters["neighborhood"] = this.state.selectedDistrictOption.value;
    // } else {
    //   selectedFilters["neighborhood"] = null;
    // }

    if (bedroomsOptionsSelected.length > 0) {
      selectedFilters["bedrooms"] = bedroomsOptionsSelected;
    } else {
      selectedFilters["bedrooms"] = null;
    }

    if (bathroomsOptionsSelected.length > 0) {
      selectedFilters["bathrooms"] = bathroomsOptionsSelected;
    } else {
      selectedFilters["bathrooms"] = null;
    }

    if (suitesOptionsSelected.length > 0) {
      selectedFilters["suites"] = suitesOptionsSelected;
    } else {
      selectedFilters["suites"] = null;
    }

    if (parkingSpacesOptionsSelected.length > 0) {
      selectedFilters["parkingSpaces"] = parkingSpacesOptionsSelected;
    } else {
      selectedFilters["parkingSpaces"] = null;
    }

    if (selectedTransactionOption) {
      selectedFilters["transaction"] = selectedTransactionOption.label === "Comprar" && isReleaseTransaction ? 1 : selectedTransactionOption.value;
    } else {
      selectedFilters["transaction"] = null;
    }

    selectedFilters["area"] = currentAreaRangeValue;
    selectedFilters["mainPrice"] = currentPriceRangeValue;

    handleCloseModal();

    const { onSubmit } = props;
    onSubmit(selectedFilters);
  };

  function handleChangeCity(option) {
    setSelectedCityOption(option)
  }

  useEffect(() => {
    if (props.show) {
      handleOpenModal()
    } else {
      handleCloseModal()
    }
  }, [props.show])

  return (
    <Wrapper
      show={false}
      isMinimized={true}
      showMap={props.showMap}
      onSubmit={handleFormSubmit}
    >
      <TopWrapper onClick={() => handleOpenModal()} showMap={props.showMap}>
        <OpenButton id="open-form-button">
          <FilterIcon />
          Filtros
        </OpenButton>
      </TopWrapper>


      <FilterModal
        isOpen={modalVisible}
      >
        <FilterModalBody>
          <FilterModalHeader>
            <h3>Filtre sua busca</h3>

            {/* <button type="button" onClick={() => handleCloseModal()}>
              <RiCloseLine size={24} color="#fff" />
            </button> */}
          </FilterModalHeader>

          <TransactionsButtonsGroup>
            <TransactionButtonSale
              type="button"
              active={selectedTransactionOption.label === 'Comprar' || selectedTransactionOption.label === 'Lançamentos'}
              onClick={() => handleChangeTransaction('Comprar')}
              disabled={selectedTransactionOption.label === 'Comprar' || selectedTransactionOption.label === 'Lançamentos'}
            >
              Comprar
            </TransactionButtonSale>
            <TransactionButtonRental
              type="button"
              active={selectedTransactionOption.label === 'Alugar'}
              onClick={() => handleChangeTransaction('Alugar')}
              disabled={selectedTransactionOption.label === 'Alugar'}
            >
              Alugar
            </TransactionButtonRental>
          </TransactionsButtonsGroup>

          <FilterModalContent>
            {selectedTransactionOption.label !== 'Alugar' && (
              <FormGroup>
                <Typography component="div">
                  <Grid component="label" container alignItems="center" spacing={1}>
                    <Grid item>
                      <AntSwitch checked={isReleaseTransaction} onChange={handleSetReleaseTransaction} name="release-transaction" />
                    </Grid>
                    <Grid item>Lançamentos</Grid>
                  </Grid>
                </Typography>
              </FormGroup>
            )}

            <ItemFormWrapper className="city" isRequired={false}>
              <SelectWrapper>
                <label>Cidade</label>
                <Select
                  name="city-select"
                  options={citiesOptions}
                  onChange={option => handleChangeCity(option)}
                  placeholder="Selecione"
                  value={selectedCityOption}
                />
              </SelectWrapper>
            </ItemFormWrapper>

            {filterOptionsDedicatedPropertyTypes.map((category, index) => (
              <Category
                {...category}
                key={`${category.name}_${index}`}
                index={index}
                onChange={handleOptionChangeUseAndProperty}
                show={true}
              />
            ))}

            <PriceRangeGroup>
              <label>Preço</label>
              <PriceInputsGroup>
                <PriceInput>
                  <label htmlFor="">
                    Mínimo {' '}
                    {currencyUtil.format({
                      value: minAndMaxPriceValue[0],
                    })}
                  </label>
                  <IntlCurrencyInput
                    currency="BRL"
                    config={currencyConfig}
                    value={currentPriceRangeValue[0]}
                    placeholder="Prices"
                    onChange={handleChangeMinPrice}
                  />
                </PriceInput>
                <PriceInput>
                  <label htmlFor="">
                    Máximo {' '}
                    {currencyUtil.format({
                      value: minAndMaxPriceValue[1],
                    })}
                  </label>
                  <IntlCurrencyInput
                    currency="BRL"
                    config={currencyConfig}
                    value={currentPriceRangeValue[1]}
                    placeholder="Prices"
                    onChange={handleChangeMaxPrice}
                  />
                </PriceInput>
              </PriceInputsGroup>
              <AirbnbSlider
                ThumbComponent={AirbnbThumbComponent}
                getAriaLabel={(index) => (index === 0 ? 'Minimum price' : 'Maximum price')}
                min={minAndMaxPriceValue[0]}
                max={minAndMaxPriceValue[1]}
                step={selectedTransactionOption.label === 'Alugar' ? 1000 : 100000}
                value={currentPriceRangeValue}
                onChange={handleChangePriceRange}
                onChangeCommitted={getCountFilterResults}
              />
            </PriceRangeGroup>
            <PriceRangeGroup>
              <label>Área</label>
              <PriceInputsGroup>
                <PriceInput>
                  <label htmlFor="">
                    Mínimo {minAndMaxAreaValue[0]}m²
                  </label>
                  <input
                    type="number"
                    value={currentAreaRangeValue[0]}
                    onChange={handleChangeMinArea}
                  />
                </PriceInput>
                <PriceInput>
                  <label htmlFor="">
                    Máximo {minAndMaxAreaValue[1]}m²
                  </label>
                  <input
                    type="number"
                    value={currentAreaRangeValue[1]}
                    onChange={handleChangeMaxArea}
                  />
                </PriceInput>
              </PriceInputsGroup>
              <AirbnbSlider
                ThumbComponent={AirbnbThumbComponent}
                getAriaLabel={(index) => (index === 0 ? 'Minimum area' : 'Maximum area')}
                min={minAndMaxAreaValue[0]}
                max={minAndMaxAreaValue[1]}
                step={20}
                value={currentAreaRangeValue}
                onChange={handleChangeAreaRange}
                onChangeCommitted={getCountFilterResults}
              />
            </PriceRangeGroup>

            <ButtonGroup>
              <label>Quarto</label>

              <ButtonsList>
                {bedroomsOptions.map(item => (
                  <ButtonsListItem key={item.value} active={bedroomsOptionsSelected.includes(item.value)}>
                    <button
                      type="button"
                      onClick={() => handleSelectBedrooms(item.value)}
                    >
                      {item.label}
                    </button>
                  </ButtonsListItem>
                ))}
              </ButtonsList>
            </ButtonGroup>

            <ButtonGroup>
              <label>Banheiros</label>

              <ButtonsList>
                {bathroomsOptions.map(item => (
                  <ButtonsListItem key={item.value} active={bathroomsOptionsSelected.includes(item.value)}>
                    <button
                      type="button"
                      onClick={() => handleSelectBathrooms(item.value)}
                    >
                      {item.label}
                    </button>
                  </ButtonsListItem>
                ))}
              </ButtonsList>
            </ButtonGroup>

            <ButtonGroup>
              <label>Suites</label>

              <ButtonsList>
                {suitesOptions.map(item => (
                  <ButtonsListItem key={item.value} active={suitesOptionsSelected.includes(item.value)}>
                    <button
                      type="button"
                      onClick={() => handleSelectSuites(item.value)}
                    >
                      {item.label}
                    </button>
                  </ButtonsListItem>
                ))}
              </ButtonsList>
            </ButtonGroup>

            <ButtonGroup>
              <label>Vagas</label>

              <ButtonsList>
                {parkingSpacesOptions.map(item => (
                  <ButtonsListItem key={item.value} active={parkingSpacesOptionsSelected.includes(item.value)}>
                    <button
                      type="button"
                      onClick={() => handleSelectParkingSpaces(item.value)}
                    >
                      {item.label}
                    </button>
                  </ButtonsListItem>
                ))}
              </ButtonsList>
            </ButtonGroup>

            {filterOptions.map((category, index) => (
              <Category
                {...category}
                key={`${category.name}_${index}`}
                index={index}
                onChange={handleOptionChange}
                show={true}
              />
            ))}
          </FilterModalContent>
        </FilterModalBody>
        <FilterModalFooter>
          <ClearFilterButton
            type="button"
            onClick={resetFilter}
          >Limpar Filtros</ClearFilterButton>

          <SearchButton
            type="button"
            onClick={async () => {
              document.getElementById('filter-form-submit-button').click()
            }}
          >
            pesquisar {filterResultsCounter} imóveis
          </SearchButton>
        </FilterModalFooter>
      </FilterModal>

      <button id="filter-form-submit-button" type="submit">pesquisar</button>
    </Wrapper>
  )
}
