import styled from 'styled-components';

import { mqTablet } from '../../../../../styled/helpers/helpers';
import { COLOR_20 } from '../../../../../styled/variables/variables';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 500px;

  ${mqTablet`
    width: 100%;
    max-width: 1000px;
    align-self: center;
  `}
`;

export const LoadMoreButton = styled.button`
  padding: 16px;
  background-color: ${COLOR_20};
  margin-top: 24px;

  font-size: 16px;
  color: #fff;
  font-weight: 500;
`;
