import React, { PureComponent } from "react";
import styled from "styled-components";

import {
  MaskedBaseInput,
  masks,
  BaseInput,
} from "../../../../styled/inputs/inputs";
import Selector from "../../../../components/selector";
import Checkbox from "../../../../components/checkbox";
import InputWrapper from "../../../../components/input-wrapper";
import { mqTablet } from "../../../../styled/helpers/helpers";
import geolocationService from "../../../../services/geolocation/geolocation.service";
import validationUtil from "../../../../utils/validation/validation.util";
import { BaseLabel } from "../../../../styled/labels/labels";
import userService from "../../../../services/user/user.service";
import httpClient from "../../../../clients/http/http.client";

import './address.css';

/*
  global
  window
*/

const customId = "custom-id-yes";
class Address extends PureComponent {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = {
      form: {
        zipCode: "",
        street: "",
        number: "",
        additionalInfo: "",
        neighborhood: "",
        city: cities[0].value,
        state: cities[0].state,
        showOnMap: false,
      },
      cities: [],
      states: [],
      latitude: null,
      longitude: null,
      showErrorsFor: {},
    };
  }

  componentWillMount() {
    window.sessionStorage.removeItem("form");
  }

  getAddressByCep = async (value) => {
    let geolocation;

    let result;
    try {
      result = await geolocationService.getAddressByZipCode({
        zipCode: value,
      });
    } catch (error) {
      // Do nothing.
    }

    if (result) {
      geolocation = await geolocationService.getGeolocationByAddress({
        zipCode: value,
        street: result.street,
        state: result.state,
        country: "Brazil",
      });
      await this.setState({
        latitude: geolocation.latitude,
        longitude: geolocation.longitude,
      });
      await this.setState((state) => ({
        ...state,
        form: {
          ...state.form,
        },
      }));

      await this.setState((state) => ({
        ...state,
        form: {
          ...state.form,
          street: result.street,
          neighborhood: result.neighborhood,
          city: result.city,
          state: result.state,
        },
        cities: [
          {
            value: result.city,
            label: result.city,
            state: result.state,
          },
        ],
        states: [
          {
            value: result.state,
            label: result.state,
          },
        ],
      }));
    }
  };

  handleZipCodeInputBlur = async (event) => {
    const { name, value } = event.target;
    this.setShowErrorsFor({ name });

    this.getAddressByCep(value);
  };

  setShowErrorsFor = ({ name }) => {
    this.setState((state) => ({
      ...state,
      showErrorsFor: {
        ...state.showErrorsFor,
        [name]: true,
      },
    }));
  };

  handleFormChange = (event) => {
    const { name, value, type, checked } = event.target;
    const { latitude, longitude, form } = this.state;

    this.setState(
      (state) => ({
        ...state,
        form: {
          ...state.form,
          [name]: type === "checkbox" ? checked : value,
        },
      }),
      async () => {
        const { onChangeZip } = await this.props;
        onChangeZip && onChangeZip(latitude, longitude, form);
      }
    );
  };

  handleInputBlur = (event) => {
    const { name } = event.target;
    this.setShowErrorsFor({ name });
  };

  loadDataForPopulateForm = async () => {
    try {
      const realEstateId = window.location.href.split("realEstateId=")[1];

      const user = userService.retrieve();

      const path = `/realestates/${realEstateId}?complete=true`;
      const token = user.token;

      const { data } = await httpClient.get({ path, token });

      this.setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          zipCode: data.realEstate.address.zipCode,
          number: data.realEstate.address.streetNumber,
          additionalInfo: data.realEstate.address.complement,
          showOnMap: data.ads.showInfo.address,
        },
      }));

      this.getAddressByCep(data.realEstate.address.zipCode);
    } catch {
      toast.error(
        "Houve um erro ao carregar a localização do seu imóvel, tente novamente!",
        {
          autoClose: false,
          toastId: customId,
        }
      );
    }
  };

  componentDidMount() {
    const { isPopulateForm, userAddress } = this.props;

    // Este IF preenche os dados na step 2 vindo da step 1
    // do fluxo proprietário
    if (!!userAddress) {
      this.setState((prevState) => ({
        ...prevState,
        form: {
          ...prevState.form,
          zipCode: userAddress.zipCode,
          number: userAddress.number,
          additionalInfo: userAddress.additionalInfo,
        },
      }));

      this.getAddressByCep(userAddress.zipCode);
    }

    // Este IF popula os campos quando está na página de edição
    if (!!isPopulateForm) {
      this.loadDataForPopulateForm();
    }
  }

  render() {
    const { showInvalids } = this.props;
    const { form, showErrorsFor } = this.state;

    return (
      <Wrapper onChange={this.handleFormChange}>
        <div className={"form-floating mb-3"}>
          <ZipCodeInput
            defaultValue={form.zipCode}
            ref={this.form}
            className={"form-control"}
            style={{ width: "100%" }}
            id={"zipCode"}
            info
            onBlur={this.handleZipCodeInputBlur}
            isInvalid={(() => {
              if (!showErrorsFor.zipCode && !showInvalids) return;
              return !validationUtil.zipCode({ value: form.zipCode });
            })()}
          ></ZipCodeInput>
          <label htmlFor="zipCode" className="form-label">
            CEP
          </label>
        </div>
        <p style={{ marginTop: 5, marginBottom: 5, fontSize: 12 }}>
          Não sabe seu CEP? Clique{" "}
          <a
            target="_blank"
            href="http://www.buscacep.correios.com.br/sistemas/buscacep/buscaCep.cfm"
          >
            aqui
          </a>
        </p>
        {/* <Label>
          Endereço
        </Label> */}
        <div className={"form-floating mb-3"}>
          <StreetInput
            value={form.street}
            onBlur={this.handleInputBlur}
            className={"form-control"}
            style={{ width: "100%" }}
            disabled={false}
            id={"street"}
            isInvalid={(() => {
              if (!showErrorsFor.street && !showInvalids) return;
              return !validationUtil.text({ value: form.street });
            })()}
          />
          <label htmlFor="street" className="form-label">
            Endereço
          </label>
        </div>
        <TabletInliner>
          {/* <div style={{ 'display': 'flex', flexDirection: 'column' }}> */}
          <div
            className={"form-floating mb-1 "}
            style={{ marginRight: "10px" }}
          >
            <NumberInput
              mask={masks.integer()}
              defaultValue={form.number}
              onBlur={this.handleInputBlur}
              className={"form-control"}
              style={{}}
              id={"number"}
              isInvalid={(() => {
                if (!showErrorsFor.number && !showInvalids) return;
                return !validationUtil.number({ value: form.number, min: 1 });
              })()}
            />
            <label htmlFor="number" className="form-label">
              Número
            </label>
          </div>
          <div className={"form-floating mb-3"} style={{ minWidth: "65%" }}>
            <AdditionalInfoInput
              value={form.additionalInfo}
              onBlur={this.handleInputBlur}
              isInvalid={false}
              className={"form-control"}
              style={{ width: "100%" }}
              id={"additionalInfo"}
            />
            <label htmlFor="additionalInfo" className="form-label">
              Complemento
            </label>
          </div>
        </TabletInliner>
        <div className={"form-floating mb-3"} style={{}}>
          <NeighborhoodInput
            value={form.neighborhood}
            onBlur={this.handleInputBlur}
            disabled={false}
            id={"neighborhood"}
            className={"form-control"}
            style={{ width: "100%" }}
            isInvalid={(() => {
              if (!showErrorsFor.neighborhood && !showInvalids) return;
              return !validationUtil.text({ value: form.neighborhood });
            })()}
          />
          <label className={"koort__input_label"} htmlFor={"neighborhood"}>
            Bairro
          </label>
        </div>
        <TabletInliner>
          <div
            className="form-floating"
            style={{ minWidth: "49%", marginRight: "2%", marginBottom: "8px" }}
          >
            <select
              disabled
              className="form-select"
              id="floatingSelect"
              aria-label="Floating label select example"
            >
              {this.state.cities.map(({ value, label }) => (
                <option key={value} selected value={value}>
                  {" "}
                  {label}{" "}
                </option>
              ))}
            </select>
            <label htmlFor="floatingSelect">Cidade</label>
          </div>
          <div className="form-floating" style={{ minWidth: "49%" }}>
            <select
              disabled
              className="form-select"
              id="floatingSelect"
              aria-label="Floating label select example"
            >
              {this.state.states.map(({ value, label }) => (
                <option key={value} selected value={value}>
                  {" "}
                  {label}{" "}
                </option>
              ))}
            </select>
            <label htmlFor="floatingSelect">Estado</label>
          </div>
        </TabletInliner>
        {!this.props.billingInfo && (
          <div
            style={{
              color: "#ff000094",
              marginTop: "0px",
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            {form.showOnMap ? (
              <>
                As informações de localização do seu imóvel serão exibidas no
                corpo do anúncio
              </>
            ) : (
              <>
                Nosso sistema de busca utiliza como ferramenta principal o mapa
                e o endereço completo será utilizado apenas para localizar
                geograficamente o seu imóvel. Se você quiser mostrar o endereço
                no anúncio, clique abaixo.
              </>
            )}
          </div>
        )}
        {!this.props.billingInfo && (
          <CheckboxInputWrapper
            label="Mostrar o endereço completo no anúncio"
            htmlFor="showOnMap"
          >
            <ShowOnMapInput
              id="showOnMap"
              name="showOnMap"
              checked={form.showOnMap}
            />
          </CheckboxInputWrapper>
        )}
      </Wrapper>
    );
  }
}

const Wrapper = styled.form`
  margin-bottom: 50px;
`;

const ZipCodeInput = styled(MaskedBaseInput).attrs({
  mask: masks.zipCode,
  name: "zipCode",
  placeholder: "CEP",
})``;

const StreetInput = styled(ZipCodeInput).attrs({
  as: BaseInput,
  placeholder: "Endereço",
  name: "street",
})``;

const TabletInliner = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  ${mqTablet`
    flex-direction: row;
  `}
`;

const NumberInput = styled(MaskedBaseInput).attrs((props) => ({
  placeholder: "Número",
  name: "number",
  mask: props.mask,
}))`
  ${mqTablet`
    margin: 0;
    margin-right: 20px;
  `}
`;

const Label = styled(BaseLabel)`
  margin-bottom: 10px;
  margin-top: 5px;
  font-weight: 600;
`;

const AdditionalInfoInput = styled(ZipCodeInput).attrs({
  as: BaseInput,
  placeholder: "Complemento",
  name: "additionalInfo",
})`
  ${mqTablet`
    margin: 0;
  `}
`;

const NeighborhoodInput = styled(ZipCodeInput).attrs({
  as: BaseInput,
  placeholder: "Bairro",
  name: "neighborhood",
})``;

const CitySelector = styled(Selector).attrs({
  name: "city",
})`
  ${mqTablet`
    margin: 0;
    margin-right: 20px;
    flex-grow: 1;
    width:50%;
    min-width:200px;
  `}
`;

const StateSelector = styled(CitySelector).attrs({
  name: "state",
})`
  ${mqTablet`
    width: 100%;
    margin: 0;
    margin-left:0px;
  `}
`;

const CheckboxInputWrapper = styled(InputWrapper)`
  margin-top: 12px;
  flex-direction: row-reverse;
  justify-content: flex-end;
`;

const ShowOnMapInput = styled(Checkbox)`
  margin-right: 10px;
`;

const states = [
  {
    value: "RJ",
    label: "RJ",
  },
  {
    value: "SP",
    label: "SP",
  },
];

const cities = [
  {
    value: "RIO DE JANEIRO",
    label: "Rio de Janeiro",
    state: states[0].value,
  },
  {
    value: "SAO PAULO",
    label: "São Paulo",
    state: states[1].value,
  },
];

export default Address;
