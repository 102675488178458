
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import propTypes from 'prop-types';
import styled from 'styled-components';

import { mqTablet } from '../../../../styled/helpers/helpers';
import { COLOR_3 } from '../../../../styled/variables/variables';

import RecommendationAdCard from '../../../../components/ad-card/RecommendationAdCard';
import { LoadingAnimation } from '../../../../styled/components/components';

/*
  global
  setTimeout
  clearTimeout
  window
*/

class AdList extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      activeAdIndex: null,
    };
  }

  handleAdMouseOver = activeAdIndex => {
    clearTimeout(this.adMouseOutTimeout);
    this.setState(state => ({
      ...state,
      activeAdIndex,
    }));
  }

  handleAdMouseOut = () => {
    this.adMouseOutTimeout = setTimeout(() => {
      this.setState(state => ({
        ...state,
        activeAdIndex: null,
      }));
    }, 250);
  };

  render() {
    const { isLoading, realEstates, history } = this.props;
    const { activeAdIndex } = this.state;

    return (
      <Wrapper>
        <LoadingSpinner
          loading={
            isLoading &&
            realEstates.length === 0
          }
        />
        { realEstates.map((re, index) => (
          <RecommendationAdCard
            { ...re }
            { ...re.address }
            images={!!re.images.length ? re.images : [`${process.env.PUBLIC_URL}/imgs/website-preview.png`]}
            adId={re.listingId}
            key={ re.id }
            price={ re.price }
            onMouseOver={ () => this.handleAdMouseOver(index) }
            onMouseOut={ () => this.handleAdMouseOut() }
            opacify={
              (activeAdIndex !== null && index !== activeAdIndex) ||
              (isLoading && realEstates.length > 0)
            }
          />
        )) }
      </Wrapper>
    )
  }
}

AdList.propTypes = {
  isLoading: propTypes.bool,
  realEstates: propTypes.arrayOf(propTypes.object),
  history: propTypes.object,
};

AdList.defaultProps = {

};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-flow: wrap;
  align-self: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
  ${ mqTablet`
    max-width: 900px;
    flex-direction: row;
  ` };
`;

const LoadingSpinner = styled(LoadingAnimation).attrs({
  color: COLOR_3,
})``;

export default withRouter(AdList);
