
import React from 'react';
import styled from 'styled-components';
import { BaseLabel } from '../../../../styled/labels/labels';
import { COLOR_2 } from '../../../../styled/variables/variables';
import { mqTablet } from '../../../../styled/helpers/helpers';

/*
  global
  process
*/

const { PUBLIC_URL } = process.env;

const Title = () => (
  <Wrapper>
    {/* <ParLogo /> */}
    <Label as="h2">Corretores e imobiliárias em destaque</Label>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  > h2 {
    min-width: 200px;
  }

  ${ mqTablet`
    flex-direction: row-reverse;
    align-self: flex-start;
  ` }
`;

const ParLogo = styled.img.attrs({
  src: `${ PUBLIC_URL }/imgs/koort-logo-piece.svg`,
})`
  height: 30px;
  margin-bottom: 20px;

  ${ mqTablet`
    margin-left: -25px;
  ` }
`;

const Label = styled(BaseLabel)`
  color: ${ COLOR_2 };
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
  align-self: center;
  text-align: center;
  width: 200px;

  ${ mqTablet`
    text-align: left;
    font-size: 26px;
    width: 170px;
  ` }
`;

export default Title;
