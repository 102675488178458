import styled from 'styled-components';
import { ellipsis } from 'polished';
import { PhotoSizeSelectSmall } from 'styled-icons/material/PhotoSizeSelectSmall';
import { Bed } from 'styled-icons/boxicons-regular/Bed';
import { Heart as HeartOutline } from 'styled-icons/fa-regular/Heart';
import { Heart as HeartFull } from 'styled-icons/fa-solid/Heart';

import { BaseLabel } from '../../styled/labels/labels';
import { iconBaseStyle, pointer, mqTablet } from '../../styled/helpers/helpers';
import { COLOR_7 } from '../../styled/variables/variables';
import { BaseButton } from '../../styled/buttons/buttons';

export const Wrapper = styled.div`
  margin: 5px 10px 10px;
  width: 240px;
  position: relative;
  height: fit-content;
  align-self: flex-start;
  background: ${({ bgColor }) => bgColor};

  transition: box-shadow 0.2s;

  &:hover {
    box-shadow: 0px 8px 16px rgba(0,0,0,0.09);
  }

  .bt-new-slide {
    left: 8px;
  }
  .bt-new-slide-next {
    right: 8px;
  }
`;

export const WrapperLoading = styled.div`
  margin: 10px;
  margin-bottom: 20px;
  ${pointer}
  width: 240px;
  height: 310px;
  align-self: flex-start;
  background-image: url("${process.env.PUBLIC_URL}/imgs/load.gif");
  background-repeat: no-repeat;
  background-size: 60px;
  background-position: 50% 50%;
  background-color: rgba(0,0,0,0.2);
  opacity: 0.5;
`

export const InfoWrapper = styled.div`
  padding: 12px 10px;
  padding-top: 12px;
  display: flex;
  flex-direction: column;
`;

export const Label = styled(BaseLabel)`
  ${ellipsis('100%')}
  font-weight: 300;
  font-size: 12px;

  &.transaction-label {
    margin-top: -4px;
  }
`;

export const Neighborhood = styled(Label)`
  font-size: 18px;
  font-weight: bold;
  display: block;
`;

export const City = styled(Label)`
  font-size: 14px;
  margin-top: -8px;
`;

export const InlineWrapper = styled.div`
  display: flex;
  width: fit-content;
  margin-top: 4px;
`;

export const Area = styled(Label)`
  margin-right: 10px;
  font-size: 16px;
  ${mqTablet`
    font-size: 14px;
  ` }
`;

export const Bedrooms = styled(Area)`
  margin-right: 0;
`;

export const M2Icon = styled(PhotoSizeSelectSmall)`
  ${iconBaseStyle}
  height: 17px;
  align-self: center;
  margin-right: 5px;
`;

export const BedroomsIcon = styled(M2Icon).attrs({
  as: Bed,
})``;

export const Price = styled(City)`
  position: absolute;
  top: 122px;
  right: 8px;
  padding: 6px;
  color: #fff;
  font-weight: bold;
  font-size: 16px !important;
  margin-top: 0px;
`;

export const FavoriteButton = styled(BaseButton)`
  background-color: transparent;
  padding: 0;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const HeartOutlineIcon = styled(HeartOutline)`
  ${iconBaseStyle}
  color: rgba(0, 0, 0, 0.7);
  height: 25px;
  width: 25px;
`;

export const HeartFullIcon = styled(HeartOutlineIcon).attrs({
  as: HeartFull,
})`
  color: rgba(0, 0, 0, 0.7);
  height: 25px;
  width: 25px;
`;

export const CityWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ImageOverlay = styled.div`
  position: absolute;
  bottom: 0px;
  height: 114px;
  width: 240px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0) 100%);
`;

export const CityInfo = styled.div`
  max-width: 180px;
`;

export const BadgesContainer = styled.div`
  position: absolute;
  top: 8px;
  left: 8px;
`;

export const ReleaseBadge = styled.div`
  background-color: #96D22D;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  padding: 4px 8px;
`;

export const PreReleaseBadge = styled(ReleaseBadge)`
  background-color: #f6861f;
`;
