import React, { PureComponent } from 'react';
import propTypes from 'prop-types';
import { PanoViewer } from '@egjs/view360';


import { Wrapper} from './styles';
/* 
  global
  process
*/

const { PUBLIC_URL } = process.env;

class Panorama extends PureComponent {

  constructor(props) {
    super(props);

    this.wrapperRef = React.createRef();
    this.instance = null;
  }

  componentDidMount() {
    this.build();
  }

  componentDidUpdate() {
    this.build();
  }

  build = () => {
    
    const { url } = this.props;
    if(
      this.instance && 
      this.instance.getImage() &&
      url === this.instance.getImage().src
    ) return;    

    if(!this.instance) {
      this.instance = new PanoViewer(this.wrapperRef.current, {
        image: url,
      });
    } else {
      this.instance.setImage(url);
    }
  };

  render() {
    const { className } = this.props;

    return (
      <Wrapper 
        className={ className }
        ref={ this.wrapperRef } 
        onClick={this.props.onClick}
      />
    );
  }
}

Panorama.propTypes = {
  className: propTypes.string,
  url: propTypes.string,
};

Panorama.defaultProps = {
  url: `${ PUBLIC_URL }/imgs/ad-360-example.jpg`,
};



export default Panorama;
