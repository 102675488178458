
import React, { PureComponent } from 'react';
import styled from 'styled-components';

import ImageDropGallery from '../../../../components//image-drop-gallery';

class FloorPlans extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      form: {
        floorPlans: [],
        floorPlanImagesRemoteRemoved: [],
      },
    };
  }

  handleImageDropGalleryUpdate = floorPlans => {
    if (this.props.isEditing) {
      const imagesLocal = floorPlans.filter(item => !item.isRemote);

      this.setState(state => ({
        form: {
          ...state.form,
          floorPlans: imagesLocal,
        },
      }));
    } else {
      this.setState(state => ({
        form: {
          ...state.form,
          floorPlans,
        },
      }));
    }
  };

  handleDeleteRemoteImages = images => {
    this.setState(state => ({
      form: {
        ...state.form,
        floorPlanImagesRemoteRemoved: images,
      },
    }));
  }

  render() {
    return (
      <Wrapper>
        <IImageDropGallery
          onUpdate={this.handleImageDropGalleryUpdate}
          onDelete={this.handleDeleteRemoteImages}
          populateImages={this.props.populateImages}
          isEditing={this.props.isEditing}
        />
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  margin-bottom: 50px;
`;

const IImageDropGallery = styled(ImageDropGallery)`
  /* margin-bottom: 50px; */
`;

export default FloorPlans;
