import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { format, isAfter, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { toast } from 'react-toastify';
import { RiCloseLine, RiFilter2Fill } from 'react-icons/ri';
import CircularProgress from '@material-ui/core/CircularProgress';
// import Switch from '@mui/material/Switch';
import Switch from '@material-ui/core/Switch';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import { RealEstateFakeInput } from './components/realestate-fake-input';
import { FakeInput } from './components/fake-input';
import { AnnotationTextarea } from './components/annotation-textarea';
import { LeadsTableButtons } from './components/leads-table-buttons';
import { MessageTextarea } from './components/message-textarea';

import userService from '../../../../services/user/user.service';
import leadsService from '../../../../services/leads/leads.service';

import eventsConstants from '../../../../constants/events';
import pathsConstants from '../../../../constants/paths';
import domEventsUtil from '../../../../utils/dom-events/dom-events.util';
import { addMaskCellphone, addMaskTelephone } from '../../../../utils/add-masks';

import {
  LeadsTableWrapper,
  LeadsTableHeader,
  LeadsTableHeaderLeftBox,
  LeadsTableHeaderRightBox,
  LeadsDataEmptyText,
  LeadsTableFilter,
  LeadsTableFilterGroup,
  OrderByFilter,
  WasContactedFilter,
  PeriodFilter,
  PeriodMessageError,
  LeadsTableFilterActionButtons,
  LeadsTableFilterSubmitButton,
  LeadsTableFilterClearButton,
  LeadDetailsModal,
  LeadDetailsModalBody,
  LeadDetailsModalHeader,
  LeadDetailsModalContent,
  LeadDetailsModalContactWrapper,
  LeadDetailsModalContactButtons,
  LeadDetailsModalContactMarkAsDone,
  LeadDetailsModalContactMarkAsUndone,
  LeadDetailsModalSaveButton,
  KualifiqueModal,
  KualifiqueModalBody,
  KualifiqueModalContent,
  KualifiqueModalHeader,
  KualifiqueLoadingWrapper
} from './styles';

const customStyles = {
  rows: {
    style: {
      fontSize: '16px'
    },
  },
  headCells: {
    style: {
      fontSize: '15px',
      fontWeight: '600'
    },
  },
  cells: {
  },
};

export function LeadsTable() {
  // False -> Traz apenas os leads associados a companhia
  // True => Traz todos os leads, associados a companhia e aos corretores da mesma
  const [searchLeadsFromMyCompanyAndBrokers, setSearchLeadsFromMyCompanyAndBrokers] = useState(false);

  const [leadsData, setLeadsData] = useState([]);
  const [leadsPagination, setLeadsPagination] = useState({
    page: "1/1",
    size: 10,
    total: 0,
  });

  const [orderByLeadsFilterValue, setOrderByLeadsFilterValue] = useState('desc'); // desc ou asc
  const [wasContactedLeadsFilterValue, setWasContactedLeadsFilterValue] = useState('false');
  const [initialDatePeriodFilterValue, setInitialDatePeriodFilterValue] = useState('');
  const [finalDatePeriodFilterValue, setFinalDatePeriodFilterValue] = useState('');
  const [periodMessageError, setPeriodMessageError] = useState('');

  const [leadDetailsModalVisible, setLeadDetailsModalVisible] = useState(false);
  const [leadDetailsData, setLeadDetailsData] = useState({});

  const [submitLeadAnnotationText, setSubmitLeadAnnotationText] = useState('Salvar observações');
  const [submitLeadAnnotationSuccess, setSubmitLeadAnnotationSuccess] = useState(false);
  const [submitLeadAnnotationError, setSubmitLeadAnnotationError] = useState(false);

  const [markContactAsDoneLoading, setMarkContactAsDoneLoading] = useState(false);
  const [markContactAsUndoneLoading, setMarkContactAsUndoneLoading] = useState(false);

  const [kualifiqueModalVisible, setKualifiqueModalVisible] = useState(false);
  const [kualifiqueLeadOptionsData, setKualifiqueLeadOptionsData] = useState(null);

  const [kualifiqueOptionSelected, setKualifiqueOptionSelected] = useState('');
  const [leadId, setLeadId] = useState('');

  const [kualifiqueLoading, setKualifiqueLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  async function getLeadsFromLoggedUser({
    page,
    isClear = false,
  }) {
    try {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: true }
      });

      const user = userService.retrieve();

      const response = await leadsService.getLeadsFromLoggedUser({
        searchLeadsFromMyCompanyAndBrokers,
        responsibleId: user._id,
        page,
        orderBy: !isClear ? orderByLeadsFilterValue : 'desc',
        wasContacted: !isClear ? wasContactedLeadsFilterValue : false,
        initialDate: !isClear ? initialDatePeriodFilterValue : '',
        finalDate: !isClear ? finalDatePeriodFilterValue : '',
        token: user.token,
      });

      const { status, data, response: responseError } = response;

      if (status !== 200) {
        if (responseError && responseError.data.message === 'Page not found.') {
          throw new Error('Não há leads na página informada, retorne a página 1!');
        } else {
          throw new Error('Não foi possível buscar seus leads, recarregue a página!');
        }
      }

      const { items: leads, pagination } = data;

      if (!!leads.length) {
        const leadsSerialized = leads.map(item => {
          return {
            createdAtFormatted: format(new Date(item.createdAt), "dd/MM/yyyy", {
              locale: ptBR,
            }),
            contactedAtFormatted: item.contactedAt !== null ? format(new Date(item.contactedAt), "dd/MM/yyyy 'às' HH:mm'h'", {
              locale: ptBR,
            }) : '---',
            phoneFormatted: item.phone ? item.phone.length > 10 ? addMaskCellphone(item.phone) : addMaskTelephone(item.phone) : null,
            ...item,
          }
        });

        setLeadsData(leadsSerialized);
        setLeadsPagination(pagination);
      } else {
        setLeadsData([]);
        setLeadsPagination({
          page: "1/1",
          size: 10,
          total: 0,
        });
      }
    } catch (err) {
      toast.error(err.message, {
        autoClose: 5000,
      });
    } finally {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: false }
      });
    }
  }

  useEffect(() => {
    getLeadsFromLoggedUser({ page: 1 });
  }, [searchLeadsFromMyCompanyAndBrokers]);

  useEffect(() => {
    getKualifiqueOptions();
  } , []);

  function handleChangeLeadsPage(page) {
    setCurrentPage(page);
    getLeadsFromLoggedUser({ page })
  }

  function handleChangeOrderByFilter(event) {
    setOrderByLeadsFilterValue(event.target.value); // desc ou asc
  }

  function handleChangeWasContactedFilter(event) {
    setWasContactedLeadsFilterValue(event.target.value);
  }

  function handleChangeInitialDatePeriodFilter(event) {
    setInitialDatePeriodFilterValue(event.target.value);
  }

  function handleChangeFinalDatePeriodFilter(event) {
    setFinalDatePeriodFilterValue(event.target.value);
  }

  useEffect(() => {
    if (initialDatePeriodFilterValue) {
      const periodValid = isAfter(parseISO(finalDatePeriodFilterValue), parseISO(initialDatePeriodFilterValue));

      if (!periodValid) {
        setPeriodMessageError('A data final precisa ser maior que a data inicial.')
      } else {
        setPeriodMessageError('');
      }
    }
  }, [initialDatePeriodFilterValue, finalDatePeriodFilterValue]);

  function handleLeadsTableFilterSubmit() {
    getLeadsFromLoggedUser({ page: currentPage })
  }

  function handleLeadsTableFilterClear() {
    setOrderByLeadsFilterValue('desc');
    setWasContactedLeadsFilterValue('false');
    setInitialDatePeriodFilterValue('');
    setFinalDatePeriodFilterValue('');
    setPeriodMessageError('');

    getLeadsFromLoggedUser({ page: currentPage, isClear: true });
  }

  const leadsTableColumns = [
    {
      name: 'Data',
      selector: row => row.createdAtFormatted,
      width: '120px',
    },
    {
      name: 'Nome',
      selector: row => row.name,
      width: '200px',
    },
    {
      name: 'E-mail',
      selector: row => row.email,
    },
    {
      name: 'Ações',
      cell: (row) => <LeadsTableButtons
        onKualifiqueLead={() => handleKualifiqueLead(row)}
        row={row}
      />,
      button: true,
      ignoreRowClick: true,
      allowOverflow: true,
    }
  ];

  function handleViewLead(lead) {
    const leadFormatted = {
      ...lead,
      createdAtFormatted: format(new Date(lead.createdAt), "dd/MM/yyyy 'às' HH:mm'h'", {
        locale: ptBR,
      }),
    };
    setLeadDetailsModalVisible(true);
    setLeadDetailsData(leadFormatted);
  }

  async function getKualifiqueOptions() {
    try {
      const token = userService.retrieve().token;

      const { status, data } = await leadsService.getKualifiqueOptions({ token });

      if (status !== 200) {
        throw new Error('Não foi possível buscar as opções para qualificar o lead!');
      }

      const { kualifiqueOptions } = data;

      const kualifiqueOptionsFiltered = kualifiqueOptions.filter(item => item.key !== "UNQUALIFIED");

      setKualifiqueLeadOptionsData(kualifiqueOptionsFiltered);
    } catch (err) {
      console.log(err);
    }
  }

  async function handleKualifiqueLead(row) {
    if (row.kualifique && row.kualifique.status) {
      setKualifiqueOptionSelected(row.kualifique.status);
    } else {
      setKualifiqueOptionSelected('');
    }

    setLeadId(row.id);
    setKualifiqueModalVisible(true);
  }

  async function handleAssignAnnotationToLead(leadId) {
    try {
      setSubmitLeadAnnotationText('Salvando...');

      const annotation = document.getElementById('annotation-textarea').value;

      const user = userService.retrieve();

      const { status } = await leadsService.assignAnnotationToLead({
        annotation,
        leadId,
        token: user.token,
      });

      if (status !== 204) {
        throw new Error('Não foi possível salvar a observação.')
      }

      const newLeadsData = leadsData.map(item => {
        if (item.id === leadId) {
          return {
            ...item,
            annotation,
          };
        } else {
          return item;
        }
      });

      setLeadsData(newLeadsData);
      setSubmitLeadAnnotationText('Observações salvas com sucesso!');
      setSubmitLeadAnnotationSuccess(true);

      setTimeout(() => {
        setSubmitLeadAnnotationText('Salvar observações');
        setSubmitLeadAnnotationSuccess(false);
      }, 2000);
    } catch {
      setSubmitLeadAnnotationText('Tente novamente em instantes!');
      setSubmitLeadAnnotationError(true);

      setTimeout(() => {
        setSubmitLeadAnnotationText('Salvar observações');
        setSubmitLeadAnnotationError(false);
      }, 2000);
    }
  }

  function handleCloseLeadDetailsModal() {
    setLeadDetailsModalVisible(false);
  }

  async function handleMarkContactAsDone(leadId) {
    try {
      setMarkContactAsDoneLoading(true);

      const user = userService.retrieve();

      const { status } = await leadsService.markContactAsDone({
        leadId,
        token: user.token,
      });

      if (status !== 204) {
        throw new Error('Não foi possível registrar o contato, tente novamente!');
      }

      setLeadDetailsModalVisible(false);

      toast.success('Registrado como respondido!', {
        autoClose: 3000,
      });

      getLeadsFromLoggedUser({ page: currentPage });
    } catch {
      setLeadDetailsModalVisible(false);

      toast.error('Não foi possível registrar o contato, tente novamente!', {
        autoClose: 5000,
      });
    } finally {
      setMarkContactAsDoneLoading(false);
    }
  }

  async function handleMarkContactAsUndone(leadId) {
    try {
      setMarkContactAsUndoneLoading(true);

      const user = userService.retrieve();

      const { status } = await leadsService.markContactAsUndone({
        leadId,
        token: user.token,
      });

      if (status !== 204) {
        throw new Error('Não foi possível desmarcar o contato, tente novamente!');
      }

      setLeadDetailsModalVisible(false);

      toast.success('Registrado como não respondido!', {
        autoClose: 3000,
      });

      getLeadsFromLoggedUser({ page: currentPage });
    } catch {
      setLeadDetailsModalVisible(false);

      toast.error('Não foi possível desmarcar o contato, tente novamente!', {
        autoClose: 5000,
      });
    } finally {
      setMarkContactAsUndoneLoading(false);
    }
  }

  const leadsTableFilterSubmitButtonDisabled = !!periodMessageError;

  async function handleChangeLeadsSearch() {
    setSearchLeadsFromMyCompanyAndBrokers(state => {
      return !state;
    });
    setLeadsData([]);

    setOrderByLeadsFilterValue('desc');
    setWasContactedLeadsFilterValue('false');
    setInitialDatePeriodFilterValue('');
    setFinalDatePeriodFilterValue('');
    setPeriodMessageError('');
    setCurrentPage(1);
  }

  async function handleChangeKualifique(event) {
    try {
      const kualifiqueKey = event.target.value;

      setKualifiqueLoading(true);

      const token = userService.retrieve().token;

      setKualifiqueOptionSelected(event.target.value);

      const { status } = await leadsService.kualifiqueLead({ leadId, kualifiqueOptionKey: kualifiqueKey, token });

      if (status !== 204) {
        throw new Error('Não foi possível qualificar esse lead!');
      }

      const newLeadsData = leadsData.map(item => {
        if (item.id === leadId) {
          return {
            ...item,
            kualifique: {
              status: kualifiqueKey,
            }
          }
        }

        return item;
      })

      setLeadsData(newLeadsData);
    } catch (err) {
      setKualifiqueModalVisible(false);

      toast.error('Não foi possível qualificar esse lead!', {
        autoClose: 3000,
      });
    } finally {
      setKualifiqueLoading(false);
    }
  };

  return (
    <>
      <LeadsTableWrapper>
        <LeadsTableHeader>
          <LeadsTableHeaderLeftBox>
            <h3>Leads</h3>
            <p>Aqui você poderá ver clientes em potencial que estão interessados nos anúncios publicados por você e também incluir os leads dos corretores associados.</p>
          </LeadsTableHeaderLeftBox>

          {userService.retrieve().userType > 3 && (
            <LeadsTableHeaderRightBox>
              <label>{!searchLeadsFromMyCompanyAndBrokers ? 'Seus leads' : 'Leads da empresa'}</label>
              <Switch
                id="switch-search-mode"
                checked={searchLeadsFromMyCompanyAndBrokers}
                color="primary"
                onChange={handleChangeLeadsSearch}
              />
            </LeadsTableHeaderRightBox>
          )}
        </LeadsTableHeader>

        <LeadsTableFilter>
          <span>
            <RiFilter2Fill size={18} />
            Filtro
          </span>

          <LeadsTableFilterGroup>
            <OrderByFilter>
              <label htmlFor="orderBySelect">Ordenar por:</label>

              <select
                name="orderBySelect"
                id="orderBySelect"
                value={orderByLeadsFilterValue}
                onChange={handleChangeOrderByFilter}
              >
                <option value="desc">mais recentes</option>
                <option value="asc">mais antigos</option>
              </select>
            </OrderByFilter>

            <WasContactedFilter>
              <label htmlFor="wasContactedSelect">Listar leads:</label>

              <select
                name="wasContactedSelect"
                id="wasContactedSelect"
                value={wasContactedLeadsFilterValue}
                onChange={handleChangeWasContactedFilter}
              >
                <option value="false">não respondidos</option>
                <option value="true">respondidos</option>
              </select>
            </WasContactedFilter>
          </LeadsTableFilterGroup>

          <PeriodFilter>
            <span>Período:</span>

            <LeadsTableFilterGroup>
              <div className="initialDate">
                <label htmlFor="initialDatePeriodFilter">Data inicial:</label>
                <input
                  type="date"
                  id="initialDatePeriodFilter"
                  name="initialDatePeriodFilter"
                  value={initialDatePeriodFilterValue}
                  onChange={handleChangeInitialDatePeriodFilter}
                />
              </div>

              <div className="finalDate">
                <label htmlFor="finalDatePeriodFilter">Data final:</label>
                <input
                  type="date"
                  id="finalDatePeriodFilter"
                  name="finalDatePeriodFilter"
                  value={finalDatePeriodFilterValue}
                  onChange={handleChangeFinalDatePeriodFilter}
                />
              </div>
            </LeadsTableFilterGroup>

            {periodMessageError && (
              <PeriodMessageError>*{periodMessageError}</PeriodMessageError>
            )}
          </PeriodFilter>

          <LeadsTableFilterActionButtons>
            <LeadsTableFilterSubmitButton
              type="button"
              disabled={leadsTableFilterSubmitButtonDisabled}
              onClick={handleLeadsTableFilterSubmit}
            >
              Filtrar
            </LeadsTableFilterSubmitButton>

            <LeadsTableFilterClearButton
              type="button"
              onClick={handleLeadsTableFilterClear}
            >
              Limpar filtro
            </LeadsTableFilterClearButton>
          </LeadsTableFilterActionButtons>
        </LeadsTableFilter>

        {!!leadsData.length ? (
          <DataTable
            columns={leadsTableColumns}
            data={leadsData}
            customStyles={customStyles}
            noDataComponent="Não há registros para exibir"
            pagination
            paginationComponentOptions={{
              rowsPerPageText: 'Leads por página',
              rangeSeparatorText: 'de'
            }}
            paginationServer
            paginationTotalRows={leadsPagination.total}
            paginationRowsPerPageOptions={[10]}
            onChangePage={handleChangeLeadsPage}
          />
        ) : (
          <LeadsDataEmptyText>Não há registros para exibir</LeadsDataEmptyText>
        )}
      </LeadsTableWrapper>

      <LeadDetailsModal
        isOpen={leadDetailsModalVisible}
      >
        <LeadDetailsModalBody>
          <LeadDetailsModalHeader>
            <h3>Veja todas as informações do lead</h3>

            <button type="button" onClick={handleCloseLeadDetailsModal}>
              <RiCloseLine size={22} />
            </button>
          </LeadDetailsModalHeader>

          <LeadDetailsModalContent>
            <RealEstateFakeInput
              label="ID do anúncio"
              value={leadDetailsData.realEstateId}
              slug={leadDetailsData.realEstateSlug}
            />

            <FakeInput
              label="ID do lead"
              value={leadDetailsData.id}
            />

            <FakeInput
              label="Nome"
              value={leadDetailsData.name}
            />

            <FakeInput
              label="E-mail"
              value={leadDetailsData.email}
            />

            <FakeInput
              label="Telefone"
              value={leadDetailsData.phoneFormatted ? leadDetailsData.phoneFormatted : 'Não informado'}
            />

            <FakeInput
              label="Data e Hora (Lead registrado)"
              value={leadDetailsData.createdAtFormatted}
            />

            <MessageTextarea
              label="Mensagem"
              value={leadDetailsData.message}
            />

            <LeadDetailsModalContactWrapper>
              <label>O contato foi realizado?</label>

              <LeadDetailsModalContactButtons>
                <LeadDetailsModalContactMarkAsDone
                  type="button"
                  disabled={leadDetailsData.contactedAt !== null || markContactAsDoneLoading}
                  maskAsDone={leadDetailsData.contactedAt !== null}
                  onClick={() => handleMarkContactAsDone(leadDetailsData.id)}
                >
                  Sim {markContactAsDoneLoading && <CircularProgress color="inherit" size={16} />}
                </LeadDetailsModalContactMarkAsDone>

                <LeadDetailsModalContactMarkAsUndone
                  type="button"
                  disabled={leadDetailsData.contactedAt === null || markContactAsUndoneLoading}
                  maskAsUndone={leadDetailsData.contactedAt === null}
                  onClick={() => handleMarkContactAsUndone(leadDetailsData.id)}
                >
                  Não {markContactAsUndoneLoading && <CircularProgress color="inherit" size={16} />}
                </LeadDetailsModalContactMarkAsUndone>
              </LeadDetailsModalContactButtons>
            </LeadDetailsModalContactWrapper>

            {leadDetailsData.contactedAt !== null && (
              <FakeInput
                label="Data e Hora (Contato realizado)"
                value={leadDetailsData.contactedAtFormatted}
              />
            )}

            {leadDetailsData.kualifique && leadDetailsData.kualifique.status && (
              <FakeInput
                label="KUALIFIQUE"
                value={kualifiqueLeadOptionsData.filter(item => item.key === leadDetailsData.kualifique.status)[0].value}
              />
            )}

            <AnnotationTextarea
              label="Observações"
              isResponsibleLead={leadDetailsData.responsibleId === userService.retrieve()._id}
              value={leadDetailsData.annotation}
            />

            {leadDetailsData.responsibleId === userService.retrieve()._id && (
              <LeadDetailsModalSaveButton
                type="button"
                onClick={() => handleAssignAnnotationToLead(leadDetailsData.id)}
                disabled={submitLeadAnnotationText !== 'Salvar observações'}
                isSuccess={submitLeadAnnotationSuccess}
                isError={submitLeadAnnotationError}
              >
                {submitLeadAnnotationText}
              </LeadDetailsModalSaveButton>
            )}
          </LeadDetailsModalContent>
        </LeadDetailsModalBody>
      </LeadDetailsModal>

      {kualifiqueLeadOptionsData !== null && (
        <KualifiqueModal
          isOpen={kualifiqueModalVisible}
        >
          <KualifiqueModalBody>
            <KualifiqueModalHeader>
              <h3>KUALIFIQUE esse lead</h3>

              <button type="button" onClick={() => setKualifiqueModalVisible(false)}>
                <RiCloseLine size={22} />
              </button>
            </KualifiqueModalHeader>

            <KualifiqueModalContent>
              {kualifiqueLoading ? (
                <KualifiqueLoadingWrapper>
                  <CircularProgress />
                </KualifiqueLoadingWrapper>
              ) : (
                <FormControl component="fieldset">
                  <RadioGroup aria-label="kualifique" name="gender1" defaultValue="UNQUALIFIED" value={kualifiqueOptionSelected} onChange={handleChangeKualifique}>
                    {kualifiqueLeadOptionsData.map(item => (
                      <FormControlLabel key={item.key} value={item.key} control={<Radio color="primary" />} label={item.value} />
                    ))}
                  </RadioGroup>
                </FormControl>
              )}
            </KualifiqueModalContent>
          </KualifiqueModalBody>
        </KualifiqueModal>
      )}
    </>
  );
}
