import styled from 'styled-components';

export const Container = styled.div`
    position: relative;
    flex: 1 0 25%;
    align-items: center;
    height: 50px;
    margin-top: 15px;
    padding-top: 10px;
    background: white;

    input {
      width: 100%;
      height: 38px;
      border: none;
      padding: 0 15px;
      margin: 0;
      font-size: 15px;

      &::-webkit-input-placeholder {
        opacity: 0;
      }

      &:-ms-input-placeholder {
        opacity: 0;
      }

      &::placeholder {
        opacity: 0;
      }

      &:focus~label,
      &:not(:placeholder-shown)~label {
          top: 5px;
          left: 10px;
          font-size: 13px;
          visibility: visible;
          color: #83786f;
      }
    }

    label {
      position: absolute;
      font-size: 15px;
      top: 15px;
      left: 10px;
      transition: top .3s;
      padding: 0 5px;
      cursor: text;
    }
`;
