import React, { PureComponent, Fragment } from 'react';
import styled, { css } from 'styled-components';
import { transitions } from 'polished';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { BaseButton } from '../../styled/buttons/buttons';
import { BaseLabel } from '../../styled/labels/labels';
import { COLOR_17, COLOR_13, COLOR_2, COLOR_1, COLOR_9 } from '../../styled/variables/variables';
import { mqTablet } from '../../styled/helpers/helpers';

import Selector from './select/Selectorr';

import currencyUtil from '../../utils/currency/currency.util';
import pathsConstants from '../../constants/paths';

const corretor = "3";


/* global alert */
export const TYPES = {
  MONTHLY: 'MONTHLY',
  MONTHLY_PREMIUM: 'MONTHLY_PREMIUM',
  YEARLY_PREMIUM: 'YEARLY_PREMIUM',
};

export const MODES = {
  CANCELLATION: 'CANCELLATION',
  ACQUISITION: 'ACQUISITION',
};


class PlansExpose extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      selected: props.configs[0].key,
      selectedIndex: 4,
    };

    let {
      onPlanClick,
    } = this.props;

    onPlanClick(props.configs[props.configs.length - 1].key, props.configs[props.configs.length - 1]);
  }

  handleSelectorItemClick = type => {
    this.setState(state => ({
      ...state,
      selected: type,
    }));
  };



  render() {
    const {
      configs,
      onPlanClick,
      mode,
      dueDate,
      renovation,
      history,
    } = this.props;
    const { selected, selectedIndex } = this.state;

    return (
      <Wrapper>
        <Selector
          options={configs}
          selected={selected}
          onItemClick={this.handleSelectorItemClick}
        />
        <ListWrapper>
          <IndicatorsWrapper>
            {configs.find(c => c.key === selected).features.map((f, index) => (
              <Feature
                key={index}
                name={`${index}-feature`}
              >
                <FeatureIndicator>
                  {f.indicator}
                </FeatureIndicator>
              </Feature>
            ))}
          </IndicatorsWrapper>
          {configs.map((option, index) => (
            <Fragment key={index}>
              <Option
                showMobile={option.key === selected}
                highlight={
                  option.isBestSeller && selectedIndex === null || index === selectedIndex ||
                  mode === MODES.CANCELLATION
                }
              >
                <BestSellerIndicator show={option.isBestSeller}>
                  Mais assinado
                  <BestSellerCorner />
                </BestSellerIndicator>
                <MainInfo>
                  <Name>{option.name}</Name>
                  {index ? <Price>
                    {currencyUtil.format({
                      value: option.price,
                      options: {
                        minimumFractionDigits: 0,
                      }
                    })}
                  </Price> : ''}
                </MainInfo>
                {option.features.map((feature, index) => (
                  <Feature
                    key={index}
                    name={`${index}-feature`}
                    dark={mode === MODES.CANCELLATION}
                  >
                    <FeatureIndicator>{feature.indicator}</FeatureIndicator>
                    <FeatureValue>{feature.value}</FeatureValue>
                  </Feature>
                ))}
                {index > 0

                  ? mode === MODES.ACQUISITION && index !== selectedIndex && (
                    <SelectButton
                      onClick={() => { onPlanClick(option.key, option); this.setState({ selectedIndex: index }); }} >
                      selecionar
                    </SelectButton>
                  )

                  :
                  <Link style={{
                    background: '#8ACC2C',
                    fontWeight: '700',
                    border: 'none',
                    textDecoration: 'none',
                    justifyContent: 'center',
                    textTransform: 'uppercase',
                    padding: '0 20px',
                    height: '50px',
                    outline: 'none',
                    borderRadius: '1px',
                    marginRight: '10px',
                    lineHeight: '50px',
                    textAlign: 'center',
                    display: 'flex',
                  }}
                    to={{
                      pathname: pathsConstants.PURCHASE_FLOW_INCORPORATION,
                      search: "type=3&subject=subscribe plan",
                      state: { type: corretor }
                    }}
                  ><ContactButton>entrar em contato</ContactButton></Link>

                }
                {mode === MODES.ACQUISITION && index === selectedIndex && (
                  <SelectedButton
                    onClick={() => {
                      onPlanClick(option.key, option);
                      this.setState({ selectedIndex: index });
                    }}
                  >
                    selecionado
                  </SelectedButton>
                )}
                {mode === MODES.CANCELLATION && (
                  // <CancelButton 
                  //   onClick={ () => {
                  //     onPlanClick(option.key);
                  //   } }
                  //   >
                  //   cancelar
                  // </CancelButton>
                  <ReplaceButton>
                  </ReplaceButton>
                )}
              </Option>
              <Spacer>
                {configs[0].features.map((c, index) => (
                  <Feature
                    key={c.key}
                    name={`${index}-feature`}
                  />
                ))}
              </Spacer>
            </Fragment>
          ))}
        </ListWrapper>
        {
          mode === MODES.CANCELLATION && (
            <ActivePlanInfoWrapper>
              <ActivePlanIndicator>
                O plano vence:
              </ActivePlanIndicator>
              <ActivePlanValue>
                {dueDate.toLocaleDateString('pt-br')}
              </ActivePlanValue>
              <ActivePlanIndicator>
                Renovação:
              </ActivePlanIndicator>
              <ActivePlanValue>
                {renovation}
              </ActivePlanValue>
            </ActivePlanInfoWrapper>
          )
        }
      </Wrapper >
    );
  }
}

PlansExpose.propTypes = {
  onPlanClick: propTypes.func,
  mode: propTypes.oneOf([
    MODES.CANCELLATION,
    MODES.ACQUISITION,
  ]),
  dueDate: propTypes.objectOf(Date),
  renovation: propTypes.string,
  configs: propTypes.arrayOf(propTypes.shape({
    key: propTypes.oneOf([
      TYPES.MONTHLY,
      TYPES.MONTHLY_PREMIUM,
      TYPES.YEARLY_PREMIUM,
    ]),
    name: propTypes.string,
    isBestSeller: propTypes.bool,
    price: propTypes.number,
    features: propTypes.arrayOf(propTypes.shape({
      key: propTypes.oneOf([
        `${TYPES.MONTHLY}_ads`,
        `${TYPES.MONTHLY}_use`,
        `${TYPES.MONTHLY}_pictures`,
        `${TYPES.MONTHLY}_analysis`,
        `${TYPES.MONTHLY_PREMIUM}_ads`,
        `${TYPES.MONTHLY_PREMIUM}_use`,
        `${TYPES.MONTHLY_PREMIUM}_pictures`,
        `${TYPES.MONTHLY_PREMIUM}_analysis`,
        `${TYPES.YEARLY_PREMIUM}_ads`,
        `${TYPES.YEARLY_PREMIUM}_use`,
        `${TYPES.YEARLY_PREMIUM}_pictures`,
        `${TYPES.YEARLY_PREMIUM}_analysis`,
      ]),
      indicator: propTypes.string,
      value: propTypes.string,
    })),
  })),
};

PlansExpose.defaultProps = {
  onPlanClick: () => alert('Missing callback.'),
  mode: MODES.ACQUISITION,
  dueDate: new Date('2024-01-01'),
  renovation: 'Automática',
  configs: [
    {
      key: TYPES.MONTHLY,
      name: 'mensal',
      price: 99,
      isBestSeller: true,
      features: [
        {
          key: `${TYPES.MONTHLY}_ads`,
          indicator: 'anúncios',
          value: '1',
        },
        {
          key: `${TYPES.MONTHLY}_use`,
          indicator: 'anúncios utilizados',
          value: '1',
        },
        {
          key: `${TYPES.MONTHLY}_pictures`,
          indicator: 'fotos',
          value: '30',
        },
        {
          key: `${TYPES.MONTHLY}_analysis`,
          indicator: 'análises',
          value: 'ilimitado',
        },
      ],
    },
  ],
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${mqTablet`
    flex-direction: row;
  ` }
`;

const IndicatorsWrapper = styled.div`
  display: none;

  ${mqTablet`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 1px;
    justify-content: flex-end;
    padding-bottom: 60px;
    min-width: 90px;

    & > div {
      margin: 0;
      padding-right: 0;
      & > label {
        display: unset;
      }
    }
  ` }
`;

const Option = styled.div`
  display: flex;
  flex-direction: column;

  ${({ showMobile }) => !showMobile && css`
    display: none;
  ` }

  ${mqTablet`
    display: flex;
    width: 260px;
    align-self: flex-end;
    padding: 10px;
    border-radius: 1px;
    margin-top: 48px;

    ${transitions(['background-color'], 'ease .3s')}

    & div, & div[name="0-feature"] {
      ${transitions(['border-color'], 'ease .3s')}
    }
    & > div > label {
      ${transitions(['color'], 'ease .3s')}
    }

    ${({ highlight }) => highlight && css`
        background-color: ${COLOR_1};

        & div, & div[name="0-feature"] {
          border-color: ${COLOR_2};
        }
        & > div > label {
          color: ${COLOR_2};
        }
      ` }
    }
  ` }
`;

const Spacer = styled(IndicatorsWrapper)`
  ${mqTablet`
    width: 30px;
    min-width: unset;
    flex-grow: unset;
    &:last-child {
      display: none;
    }
  ` }
`;

const BestSellerIndicator = styled.div`
  background-color: ${COLOR_1};
  color: ${COLOR_2};
  font-weight: 700;
  text-align: center;
  padding: 10px;
  margin-top: 10px;
  border-radius: 1px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  ${({ show }) => !show && css`
    display: none;
  ` }

  ${mqTablet`
    margin: -58px -10px 20px -10px;
  ` }
`;

const BestSellerCorner = styled.div`
  margin-bottom: -13px;
  margin-top: 6px;
  min-height: 7px;
  min-width: 7px;
  background-color: ${COLOR_1};
  transform: rotate(45deg);
  border-radius: 1px;
  align-self: center;
`;

const MainInfo = styled.div`
  display: none;

  ${mqTablet`
    min-height: 130px;
    width: 100%;
    background-color: ${COLOR_1};
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 1px;
  ` }
`;

const Name = styled(BaseLabel)`
  color: ${COLOR_2};
  align-self: center;
  font-size: 13.5px;
  margin-left:5px;
  text-transform: uppercase;
  margin-bottom: 10px;
`;

const Price = styled(Name)`
  font-weight: 700;
  margin: 0;
  font-size: 30px;
`;

const Feature = styled.div`
  display: flex;
  height: 110px;
  text-align: justify;
  text-justify: inter-word;
  border-bottom: solid 1px ${COLOR_17};
  padding-top: 80px;
  padding-bottom: 70px;

  ${({ dark }) => dark && css`
    border-color: ${COLOR_2};
    color: ${COLOR_2};
    background-color: ${COLOR_1};
    & :nth-child(3) {
      margin-top: 7px;
    }
    & > label {
      color: ${COLOR_2};
    }
  ` }

  ${mqTablet`
    margin: 0 -10px;
    &[name="0-feature"] {
      border-top: solid 1px ${COLOR_17};
    }
  ` }
`;

const FeatureIndicator = styled(BaseLabel)`
  font-weight: 700;
  align-self: center;
  text-transform: uppercase;
  font-size: 12px;
  flex-grow: 1;
  width: 1px;

  ${mqTablet`
    display: none;
  ` }
`;

const FeatureValue = styled(FeatureIndicator)`
 font-size: 12px;
 text-align: justify;
  text-justify: inter-word;
  
  ${mqTablet`
    display: unset;
    flex-grow: 1;
    width: 1px;
    text-align: center;
  ` }

@media (max-width: 600px) {
  font-size: 10px;
}
`;


const ContactButton = styled(BaseButton)`
  background-color: ${COLOR_13};
  color: ${COLOR_2};
  font-weight: 700;
  border: none;
  width: auto; 
`;

const SelectButton = styled(BaseButton)`
  background-color: ${COLOR_13};
  color: ${COLOR_2};
  font-weight: 700;
  border: none;
`;

const SelectedButton = styled(BaseButton)`
  background-color: ${COLOR_1};
  color: ${COLOR_2};
  font-weight: 700;
  border: none;
`;

// const CancelButton = styled(SelectButton)`
//   background-color: ${ COLOR_15 };
//   color: ${ COLOR_9 };
//   height: 50px;
//   border: solid 7px ${ COLOR_1 };
//   border-top: 0;
//   line-height: 42px;

//   ${ mqTablet`
//     border: none;
//   ` }
// `;

const ReplaceButton = styled.div`
  background-color: ${COLOR_1};
  color: ${COLOR_9};
  height: 50px;
  border: solid 7px ${COLOR_1};
  border-top: 0;
  line-height: 42px;

  ${mqTablet`
    border: none;
  ` }
`;

const ActivePlanInfoWrapper = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  ${mqTablet`
    margin-bottom: 0;
    flex-direction: row;
    padding: 10px;
    margin-top: -60px;
    margin-bottom: 60px;
  ` }
`;

const ActivePlanIndicator = styled(BaseLabel)`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
  text-align: center;
  ${mqTablet`
    margin-bottom: 0;
    margin-right: 5px;
  ` }
`;

const ActivePlanValue = styled(ActivePlanIndicator)`
  font-weight: normal;
  margin-bottom: 10px;

  ${mqTablet`
    margin-right: 20px;
  ` }
`;

export default PlansExpose;
