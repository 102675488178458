import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';
import propTypes from 'prop-types';
import { toast } from 'react-toastify';
import { RiRoadMapLine, RiCommunityLine, RiCheckboxBlankLine, RiCheckboxFill } from 'react-icons/ri';
import { Helmet } from 'react-helmet-async';

import { mqDesktop } from '../../styled/helpers/helpers';

import ViewModeSelector, {
  DETAIL_VIEW,
  FILTERS_VIEW,
  RESULTS_VIEW,
  SEARCH_VIEW
} from './view-mode-selector/ViewModeSeletor';
import Map from './new-map-2/Map';
import { Filters } from './filter-in-modal';
import Results from './results/Results';
import { HorizontalScrollList } from './horizontal-scroll-list';

import listingService from '../../services/listing/listing.service';
import listingV2Service from '../../services/listing/listing-v2.service';
import googleMapsService from '../../services/google-maps/google-maps.service';
import userService from '../../services/user/user.service';
import realEstateService from '../../services/real-estate/real-estate.service';

import eventConstants from '../../constants/events';
import eventsConstants from '../../constants/events';
import pathConstants from '../../constants/paths';
import objectUtil from '../../utils/object/object.util';
import domEventsUtil from '../../utils/dom-events/dom-events.util';
import queryStringUtil from '../../utils/query-string/query-string.util';
import { FeedbackWidget } from '../../components/feedback-widget';
import { MapToggle } from '../filter/components/mapToggle';

/*
  global
  clearTimeout
  setTimeout
  alert
  window
*/

var INTERRUPT_BOUNDS_CHANGE = 0;

const customId = "custom-id-yes";
class Search extends PureComponent {

  constructor(props) {
    super(props);
    this.activeRequests = 0;
    this.mapRef = React.createRef();

    const { history } = props;
    const { viewMode } = queryStringUtil.parse(history);

    this.state = {
      user: userService.retrieve(),

      // View
      viewMode: viewMode || DETAIL_VIEW,
      showMapLoading: 0,

      // Data
      realEstates: [],
      realEstatesCoordinates: [],
      realEstatesPagination: null,
      realEstatesLoading: false,
      showRealEstatesLoading: false,
      realEstatesIds: null,
      showHorizontalScrollList: false,
      resultsCurrentPage: 1,
      realEstatesComplete: [],
      selectedRealEstate: null,
      geolocation: null,
      filters: [],
      idAd: undefined,
      mapMarkers: [],
      showMap: true,
      hasSearchDetachedMap: false,
      isFirstLoad: true,
      oldRealEstates: [],
      hasMarkerSelected: false,
      seo: null,

      bounds: null,
      idle: null,

      boundsHasSetted: false,

      visualizeAdReleases: false,
      visualizeAdReleasesIsFetching: false,

      oldSelectedFilters: null,
    };

    this.handleEventSubscriptions(true);
  }

  componentDidMount() {
    this.search({ loadLocation: true });

    // CÓDIGO RESPONSÁVEL POR OCULTAR O SCROLL NA PÁGINA DO MAPA
    const el = document.querySelector("html");
    el.style.overflowY = "hidden";
  }

  componentDidUpdate(_, prevState) {
    if (this.state.resultsCurrentPage > prevState.resultsCurrentPage) {
      if (this.state.realEstatesPagination !== null) {
        if (this.state.resultsCurrentPage > Number(this.state.realEstatesPagination.page.split('/')[1])) {
          return;
        }
      }

      this.search({
        loadLocation: false,
        isHandledPagination: true,
        showLoading: false,
        bounds: this.state.bounds
      });
    }
  }

  componentWillUnmount() {
    // CÓDIGO RESPONSÁVEL POR VOLTAR A EXIBIR O SCROLL NAS DEMAIS PÁGINAS
    const el = document.querySelector("html");
    el.style.overflowY = "auto";

    this.handleEventSubscriptions(false);
  }

  handleEventSubscriptions = activate => {
    const action = domEventsUtil[activate ? 'subscribe' : 'unsubscribe'];
    action({
      name: eventsConstants.NEW_SEARCH,
      callback: this.handleSearchStringUpdate,
    });
  };

  getRealEstatesCoordinates = async ({
    query,
    rect,
    city,
    neighborhood,
    token,
  }) => {
    const { coordinates, longLat } = await listingV2Service.getCoordinates({
      ...query,
      state: query.uf ? query.uf : null,
      rect,
      city,
      neighborhood: !this.state.boundsHasSetted ? neighborhood : '',
      token,
    });

    if (!this.state.boundsHasSetted) {
      const bounds = new window.google.maps.LatLngBounds();
      bounds.extend(new window.google.maps.LatLng(longLat[1], longLat[0]))
      this.mapRef.current.setBounds(bounds.toJSON());
      this.mapRef.current.map.setZoom(window.innerWidth <= 1200 ? 9 : 11);
    }

    return coordinates;
  }

  getRealEstates = async ({
    query,
    rect,
    city,
    neighborhood,
    token,
    isHandledPagination,
    sizePagination,
  }) => {
    if (!isHandledPagination) {
      this.setState(prevState => ({
        ...prevState,
        realEstates: [],
        realEstatesCoordinates: [],
        realEstatesPagination: null,
        resultsCurrentPage: 1,
        isFirstLoad: true,
      }));
    }

    if (this.state.realEstatesIds) {
      this.setState(prevState => ({
        ...prevState,
        showHorizontalScrollList: true,
      }))
    }

    const { realEstates, pagination, seo } = await listingV2Service.get({
      ...query,
      rect,
      city,
      uf: query.uf ? query.uf : null,
      neighborhood: !this.state.boundsHasSetted || !this.state.showMap ? neighborhood : '',
      page: this.state.resultsCurrentPage,
      token,
      realEstatesIds: this.state.realEstatesIds,
      sizePagination,
    });

    this.setState(prevState => ({
      ...prevState,
      realEstatesPagination: pagination,
      seo,
    }));

    return realEstates;
  }

  search = async ({
    loadLocation = false,
    bounds,
    showLoading,
    idle,
    isHandledPagination = false,
    loadCoordinates = true,
    sizePagination = 10,
  } = {
      loadLocation: false,
      showLoading,
      bounds,
      idle,
    }) => {
    if (showLoading === undefined || showLoading) {
      domEventsUtil.dispatch({
        name: eventConstants.LOADING_ANIMATION,
        params: { show: true, },
      });
    }
    const { history } = this.props;
    const queryParams = queryStringUtil.parse(history);
    let realEstates, geolocation, realEstatesCoordinates;
    let searchStringV2 = (queryParams.searchString ? queryParams.searchString : (queryParams.city ? (queryParams.neighborhood ? `${queryParams.neighborhood}, ` : "") + queryParams.city : "Brasil"))

    try {
      if (
        isNaN(queryParams.south) ||
        isNaN(queryParams.west) ||
        isNaN(queryParams.north) ||
        isNaN(queryParams.east)
      ) {
        geolocation = await googleMapsService
          .getGeolocationSummaryByAddress({
            addressString: searchStringV2,
          });
      } else {
        geolocation = objectUtil.conformify({
          north: queryParams.north,
          east: queryParams.east,
          south: queryParams.south,
          west: queryParams.west,
        });
      }

      const { north, east, south, west } = (this.state.bounds ? this.state.bounds : geolocation);
      const { user } = this.state;

      localStorage.setItem('@Koort:rect', this.state.showMap ? JSON.stringify([[north, east,], [south, west,]]) : false)

      realEstates = await this.getRealEstates({
        query: queryParams,
        rect: this.state.showMap ? [[north, east,], [south, west,]] : undefined,
        city: queryParams.city,
        neighborhood: queryParams.neighborhood || null,
        token: (user ? user.token : null),
        isHandledPagination,
        sizePagination,
      });

      console.log(queryParams)

      if (this.state.resultsCurrentPage === 1 && this.state.showMap) {
        if (loadCoordinates && !isHandledPagination) {
          this.setState(prevState => ({
            ...prevState,
            realEstatesLoading: true,
          }));

          realEstatesCoordinates = await this.getRealEstatesCoordinates({
            query: queryParams,
            rect: [[north, east,], [south, west,]],
            city: queryParams.city,
            neighborhood: queryParams.neighborhood || null,
            token: (user ? user.token : null),
          });
        }
      }
    } catch (error) {
      if (showLoading === undefined || showLoading) {
        domEventsUtil.dispatch({
          name: eventConstants.LOADING_ANIMATION,
          params: { show: false, },
        });
      }
      return;
    }

    // se for apenas atualização de bounds, nao atualizar o result de uma vez
    // if (this.state.bounds && this.state.idle) {
    //   if (this.state.resultsCurrentPage === 1) {
    //     this.setMapMarkers(realEstatesCoordinates);
    //   }
    //   if (showLoading === undefined || showLoading) {
    //     domEventsUtil.dispatch({
    //       name: eventConstants.LOADING_ANIMATION,
    //       params: { show: false, },
    //     });
    //   }

    //   this.setState(prevState => ({
    //     ...prevState,
    //     realEstatesLoading: false,
    //   }))
    //   return;
    // }

    this.setState(state => ({
      ...state,
      // realEstates: realEstates && realEstates.length > 0 ?
      //   [...state.realEstates, ...realEstates] :
      //   [],
      realEstates: state.resultsCurrentPage === 1 ? realEstates :
        [...state.realEstates, ...realEstates],
      realEstatesCoordinates: realEstatesCoordinates && realEstatesCoordinates.length > 0 ?
        realEstatesCoordinates :
        [],
      realEstatesComplete: realEstates && realEstates.length > 0 ?
        realEstates :
        [],
      realEstatesLoading: false,
      viewMode: queryParams.viewMode,
      geolocation,
      isFirstLoad: false,
      showRealEstatesLoading: true,
    }), () => {
      // if (this.state.idle == null) {
      // if (!persistMarkers ) {
      if (this.state.resultsCurrentPage === 1 && this.state.showMap) {
        if (loadCoordinates && !isHandledPagination) {
          this.setMapMarkers(realEstatesCoordinates);
        }
      }
      // }
      // }
      if (loadLocation && this.state.boundsHasSetted) this.loadMapLocation();

      if (!this.state.boundsHasSetted) {
        this.setState(prevState => ({
          ...prevState,
          boundsHasSetted: true,
        }));
      }
      // if (queryParams.listingId) setTimeout(this.getSelectedRealEstate, 1000);
    });
    if (showLoading === undefined || showLoading) {
      domEventsUtil.dispatch({
        name: eventConstants.LOADING_ANIMATION,
        params: { show: false, },
      });
    }
  };

  getSelectedRealEstate = async () => {
    const { history } = this.props;
    const { listingId, viewMode } = queryStringUtil.parse(history);

    let selectedRealEstate;
    try {
      selectedRealEstate = await listingService.getById({
        listingId,
      });
    } catch (error) {
      queryStringUtil.update(history, {
        listingId: undefined,
      });
      toast.error('Anúncio não encontrado.', {
        autoClose: false,
        toastId: customId,
      });
      return;
    }

    this.setState(state => ({
      ...state,
      selectedRealEstate,
      viewMode,
    }), async () => {
      await realEstateService.informPrint({
        realEstateId: listingId,
      });
    });
  };

  loadMapLocation = () => {
    const { selectedRealEstate, geolocation } = this.state;

    if (selectedRealEstate) {
      this.mapRef.current.setCenter(selectedRealEstate.address.geolocation);
    } else {
      this.mapRef.current.setBounds(geolocation);
    }

  };

  setMapMarkers = async (r) => {
    //Apenas update do mapa
    let realEstatesCoordinates = null;
    if (r) {
      realEstatesCoordinates = r;
    } else {
      realEstatesCoordinates = this.state.realEstatesCoordinates;
    }

    const markerList = realEstatesCoordinates.map(re => ({
      ...re,
      id: re._id,
      isMain: false,
      iconSize: 40,
      iconSizeOnHover: 50,
      clickable: true,
    }));

    if (this.mapRef.current) {
      this.mapRef.current.removeMarkers();
      this.mapRef.current.setMarkers(markerList, true);
    }
  }

  handleSearchStringUpdate = event => {
    let { searchString, type, transaction } = event.detail.params;
    const { history } = this.props;
    if (!searchString) {
      searchString = queryStringUtil.parse(history).searchString
    }

    console.log("tipo nos parametros: ", type)

    queryStringUtil.update(history, {
      searchString,
      type,
      transaction,
      listingId: null,
      viewMode: null,
      south: null,
      west: null,
      north: null,
      east: null,
    });
    this.search({ loadLocation: true });
  };

  handleViewModeUpdate = viewMode => {
    const { selectedRealEstate } = this.state;
    if (viewMode === SEARCH_VIEW && selectedRealEstate) {
      viewMode = DETAIL_VIEW;
    }
    const { history } = this.props;
    queryStringUtil.update(history, { viewMode });

    this.setState(state => ({
      ...state,
      viewMode,
    }))
  };

  handleFilterSearchRealEstatesDetachedMap = async () => {
    try {
      domEventsUtil.dispatch({
        name: eventConstants.LOADING_ANIMATION,
        params: { show: true, },
      });

      const { history } = this.props;
      const { user } = this.state;
      const queryParams = queryStringUtil.parse(history);

      const data = await this.getRealEstates({
        ...queryParams,
        city: (!queryParams.searchString ? queryParams.city : null),
        neighborhood: (!queryParams.searchString ? queryParams.neighborhood : null),
        token: (user ? user.token : null),
      });

      this.setState(prevState => ({
        ...prevState,
        realEstates: data && data.length > 0 ?
          data :
          [],
        realEstatesComplete: data && data.length > 0 ?
          data :
          [],
        viewMode: queryParams.viewMode,
      }));
    } catch {
      toast.error('Não foi possível realizar a busca, tente novamente!');
    } finally {
      domEventsUtil.dispatch({
        name: eventConstants.LOADING_ANIMATION,
        params: { show: false, },
      });
    }
  }

  handleFiltersSubmitionBackup = selectedFilters => {
    if (!this.state.showMap) {
      const { history } = this.props;
      queryStringUtil.update(history, {
        ...selectedFilters,
        listingId: null,
        viewMode: SEARCH_VIEW,
      },
        this.setState(state => ({
          ...state,
          hasSearchDetachedMap: true,
          viewMode: SEARCH_VIEW,
        }), () => {
          this.handleFilterSearchRealEstatesDetachedMap()
        })
      );

      return;
    }

    const { history } = this.props;
    queryStringUtil.update(history, {
      ...selectedFilters,
      listingId: null,
      viewMode: SEARCH_VIEW,

    },
      this.setState(state => ({
        ...state,
        viewMode: SEARCH_VIEW,
        hasSearchDetachedMap: false,
      }), () => {
        this.search({ loadLocation: true, idle: true, });
      })
    );

  };

  handleFiltersSubmition = selectedFilters => {
    console.log("selected: ", selectedFilters)
    const { history } = this.props;

    const queryParams = queryStringUtil.parse(history);
    console.log("queryParams: ", queryParams)

    const hasChangedCity = selectedFilters.city !== queryParams.city;

    queryStringUtil.update(history, {
      ...selectedFilters,
      // area: selectedFilters.area && selectedFilters.area.toString(),
      listingId: null,
      viewMode: window.innerWidth <= 1079 ? RESULTS_VIEW : SEARCH_VIEW,
    },
      this.setState(state => ({
        ...state,
        viewMode: window.innerWidth <= 1079 ? RESULTS_VIEW : SEARCH_VIEW,
        hasSearchDetachedMap: true,
        boundsHasSetted: hasChangedCity ? false : true,
      }), () => {
        this.search({ showLoading: false, loadLocation: hasChangedCity });
      })
    );
  };

  handleResultClick = selectedRealEstate => {
    const win = window.open(`${pathConstants.SEARCH_DETAIL}?listingId=${selectedRealEstate.listingId}`, "_blank");
    win.focus();
  };

  handleAdDetailsClose = () => {
    const { history } = this.props;
    queryStringUtil.update(history, {
      listingId: null,
      viewMode: SEARCH_VIEW
    });

    this.setState(state => ({
      ...state,
      viewMode: SEARCH_VIEW,
    }), () => setTimeout(() => this.setState(state => ({
      ...state,
      selectedRealEstate: null,
    })), 1000));
  };

  handleBoundsChange = (bounds, idle) => {
    INTERRUPT_BOUNDS_CHANGE++;

    // if (INTERRUPT_BOUNDS_CHANGE > 1) {
    //   INTERRUPT_BOUNDS_CHANGE = 0;

    //   return;
    // }

    if (!!idle) return;

    if (this.updateBoundsResultsTimeout) {
      clearTimeout(this.updateBoundsResultsTimeout);
    }

    this.updateBoundsResultsTimeout = setTimeout(_ => {
      this.setState(prevState => ({
        ...prevState,
        realEstatesIds: null,
        showRealEstatesLoading: true,
        showHorizontalScrollList: false,
        bounds: bounds,
        idle: idle,
      }), () => {
        this.search({ showLoading: false, bounds: bounds, idle: idle });
      })
    }, 1200);
  };

  handleMapMarkerClick = async markers => {
    const realEstatesIds = markers.map(item => item.id);

    this.setState(prevState => ({
      ...prevState,
      realEstates: [],
      realEstatesLoading: true,
      realEstatesIds,
    }), () => {
      this.search({
        isHandledPagination: false,
        showLoading: false,
        loadCoordinates: false,
        persistMarkers: true,
      });
    });
  };

  handleCancelDraw = () => {
    // this.search({ loadLocation: true });
  }

  handleNewMapDraw = markerList => {
    const realEstatesData = markerList.map(item => item.data);
    const realEstatesIds = realEstatesData.map(item => item.id);

    this.setState(prevState => ({
      ...prevState,
      realEstates: [],
      realEstatesLoading: true,
      realEstatesIds,
    }), () => {
      this.search({
        isHandledPagination: false,
        showLoading: false,
        loadLocation: false,
      });
    });
  };

  onMouseEnter = ad => {
    this.mapRef.current.setActiveMark(ad.listingId);
  }

  onMouseOut = ad => {
    this.mapRef.current.setDesactiveMark(ad.listingId);
  }

  handleResultsPageChanged = () => {
    this.setState(prevState => ({
      ...prevState,
      resultsCurrentPage: prevState.resultsCurrentPage + 1,
    }));
  }

  handleHideHorizontalScrollList = () => {
    this.setState(prevState => ({
      ...prevState,
      showHorizontalScrollList: false,
    }));
  }

  handleViewAdReleases = () => {
    const { history } = this.props;

    const queryParams = queryStringUtil.parse(history);

    if (this.state.oldSelectedFilters === null) {
      this.setState(prevState => ({
        ...prevState,
        oldSelectedFilters: queryParams,
        visualizeAdReleases: true,
      }), () => {
        const selectedFilters = {
          ...queryParams,
          transaction: 1,
        };

        this.handleFiltersSubmition(selectedFilters);
      });
    } else {
      this.handleFiltersSubmition(this.state.oldSelectedFilters);

      this.setState(prevState => ({
        ...prevState,
        oldSelectedFilters: null,
        visualizeAdReleases: false,
      }));
    }
  }

  render() {
    const {
      realEstates,
      realEstatesPagination,
      realEstatesCoordinates,
      user,
      viewMode,
      showHorizontalScrollList,
      seo,
      visualizeAdReleases,
      visualizeAdReleasesIsFetching
    } = this.state;

    const { history } = this.props;

    return (
      <>
      {seo !== null && (
        <Helmet>
        <title>{seo.head.title} - Koort Imóveis</title>
        <meta name="title" content={`${seo.head.title} - Koort Imóveis`} />
        <meta name="description" content={seo.head.description} />

        <meta property="og:title" content={`${seo.head.title} - Koort Imóveis`} />
        <meta property="og:description" content={seo.head.description} />
        <meta property="og:url" content={window.location.href} />

        <meta property="twitter:title" content={`${seo.head.title} - Koort Imóveis`} />
        <meta property="twitter:description" content={seo.head.description} />
        <meta property="twitter:url" content={window.location.href} />
      </Helmet>
      )}

        <Wrapper>
          <Map
            ref={this.mapRef}
            onMarkerClick={this.handleMapMarkerClick}
            onNewMapDraw={this.handleNewMapDraw}
            onBoundsChanged={this.handleBoundsChange}
            realEstates={realEstates}
            realEstatesCoordinates={realEstatesCoordinates}
            onHideHorizontalScrollList={this.handleHideHorizontalScrollList}
          />

          <Results
            show={viewMode === RESULTS_VIEW}
            desktopShow={true}
            realEstates={realEstates}
            realEstatesPagination={realEstatesPagination}
            onClickItem={this.handleResultClick}
            onResultsPageChanged={this.handleResultsPageChanged}
            user={user}
            title={seo !== null ? seo.title : ''}
            showMap={this.state.showMap}
            showEmpty={!this.state.isFirstLoad}
            realEstatesLoading={this.state.realEstatesLoading}
            showRealEstatesLoading={this.state.showRealEstatesLoading}
          />

          <Filters
            show={viewMode === FILTERS_VIEW}
            onSubmit={this.handleFiltersSubmition}
            history={history}
            showMap={this.state.showMap}
          />

          {/* <ShowMap
            showMap={this.state.showMap}
          >
            <MapToggle currentFilterStyle="map" />
          </ShowMap> */}

          <VisualizeAdReleasesButton
            type="button"
            onClick={() => this.handleViewAdReleases(visualizeAdReleases)}
            // disabled={visualizeAdReleasesIsFetching}
          >
            {visualizeAdReleases ? <RiCheckboxFill size={18} /> : <RiCheckboxBlankLine size={18} />}
            Lançamentos
          </VisualizeAdReleasesButton>

          <ViewModeSelector
            onClick={this.handleViewModeUpdate}
            viewMode={viewMode}
          />

          {realEstates && showHorizontalScrollList && (
            <HorizontalScrollList
              realEstates={realEstates}
              user={user}
              onHide={this.handleHideHorizontalScrollList}
            />
          )}
          {/* <FeedbackWidget /> */}
        </Wrapper>

      </>
    );
  }
}

Search.propTypes = {
  history: propTypes.object,
};

Search.defaultProps = {

};

const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: fixed;
  top: 0;
  padding-top: 80px;

  ${mqDesktop`
    display: flex;
  ` };

  /* CSS RESPONSÁVEL POR AJUSTAR O POSICIONAMENTO
  DO BOTÃO FULLSCREEN E ZOOM +/_ */
  button[title="Ativar a visualização em tela cheia"] {
    right: 20px !important;

    @media (max-width: 1079px) {
      right: 0px !important;
    }
  }

  div[data-control-height="81"] {
    left: unset;
    right: 60px !important;

    @media (max-width: 1079px) {
      right: 40px !important;
    }
  }

  /* @media(max-width: 1079px) {
    div[id="feedback-widget-id"] {
      display: none !important;
    }
  } */
`;

const ShowMap = styled.button`
  background-color: #2b2b2b;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  padding: 4px 0px;
  height: 35px;
  width: 120px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;

  > svg {
    margin-right: 8px;
  }

  ${({ showMap }) => !showMap && css`
    left: 10px !important;
    right: unset !important;
    left: 140px !important;
  `}

  ${mqDesktop`
    position: absolute;
    margin: 0;
    opacity: 1;
    bottom: unset;
    right: 290px;
    top: 100px;
    border-radius: 1px;
  ` };

  @media (max-width: 1079px) {
    display: none;
  }
`;

const VisualizeAdReleasesButton = styled.button`
  background-color: #8ACC2C;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  padding: 4px;
  height: 36px;
  width: 150px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;

  display: flex;
  align-items: center;

  transition: filter 0.2s;

  > svg {
    margin-right: 8px;
  }

  &:hover {
    filter: brightness(0.9);
  }

  ${mqDesktop`
    position: absolute;
    margin: 0;
    opacity: 1;
    bottom: unset;
    right: 24px;
    top: 100px;
    border-radius: 1px;
  ` };

   @media (max-width: 1079px) {
    display: none;
  }
`;

export default Search;
