import styled from 'styled-components';

import { Input as InputComponent } from '../../../../../../../components/form/input';

import { COLOR_2, COLOR_1 } from '../../../../../../../styled/variables/variables';
import { BaseButton } from '../../../../../../../styled/buttons/buttons';

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Input = styled(InputComponent)``;

export const SendButton = styled(BaseButton).attrs({
  type: 'submit'
})`
  color: ${COLOR_2};
  background-color: ${COLOR_1};
  width: 100%;
  align-self: flex-end;
  border: none;
  font-weight: 600;
  margin-top: 20px;
  margin-right: unset;
`;
