import React, { useEffect } from 'react';

import AdCardInteractive from '../../../components/ad-card-interactive-legacy';

import { HorizontalScrollListContainer } from './styles';

export function HorizontalScrollList({
  realEstates,
  user,
  onHide,
}) {
  function myFunction(event) {
    const obj = document.getElementById("horizontal-container");

    try {
      if (!obj.contains(event.target)) {
        onHide();
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    document.addEventListener("touchstart", myFunction);

    return () => {
      document.removeEventListener("touchstart", myFunction);
    }
  }, [])

  return (
    <HorizontalScrollListContainer id="horizontal-container">
      {realEstates.map(re => (
        <AdCardInteractive
          key={re._id}
          user={user}
          {...re}
          {...re.price}
          {...re.address}
          favorite={re.favorite}
          bgColor="#FFFFFF"
        // onClick={() => onClickItem(re)}
        />
      ))}
    </HorizontalScrollListContainer>
  );
}
