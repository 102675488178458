import React from 'react';

import Cover from '../dashboard/cover/Cover';
import { DashboardLeadsContent } from './dashboard-leads-content';

import userService from '../../services/user/user.service';

export function DashboardLeads() {
  return (
    <>
      <Cover
        name={userService.retrieve().name}
        pictureUrl={userService.retrieve().profile.image}
      />

      <DashboardLeadsContent />
    </>
  )
}
