
import React from 'react';
import styled from 'styled-components';
import Typist from 'react-typist';

import { Bold, BaseLabel } from '../../../../styled/labels/labels';
import { COLOR_2 } from '../../../../styled/variables/variables';
import { mqLandscape } from '../../../../styled/helpers/helpers';

import './slogan.css';

/* global window */

const Slogan_Realtors = () => (
  <Wrapper>
    <Label>
      <Typist
        startDelay={1200}
        cursor={{
          element: <Blinker />,
          blink: true,
        }}
      >
        <Bold>Procure seu corretor ou imobiliária,  </Bold><br />
        <Typist.Delay ms={500} />
        eles são <Bold> rápidos.</Bold>

      </Typist>

      {/* <Typist
        startDelay={ window.innerWidth > 768 ? 4500 : 2000 }
        cursor={ {
          element: <Blinker />,
          blink: true,
        } }
      >
        <Bold>Koort, </Bold>
        <Typist.Delay ms={ 500 } />
        o <Bold>portal</Bold>
        <br />
        ideal para você <Bold>proprietário, </Bold>
        <Typist.Backspace count={ 19 } delay={ 2000 } />
        <Typist.Delay ms={ 300 } />
        sua <Bold>imobiliária,</Bold>
        <Typist.Backspace count={ 12 } delay={ 2000 } />
        <Typist.Delay ms={ 300 } />
        <Bold>construtora.</Bold>
        <Typist.Backspace count={ 27 } delay={ 2000 } />
        <Typist.Delay ms={ 300 } />
        onde <Bold>bons negócios</Bold> acontecem.
      </Typist>
      */}

    </Label>
  </Wrapper>
);

const Wrapper = styled.div`
  align-self: center;
  text-align: center;
  margin-bottom: 10px;
  min-height: 90px;
  display: none;

  ${mqLandscape(500)`
      display:flex;
      justify-content: center;
  `};
`;

const Label = styled(BaseLabel)`
  font-size: 34px;
  align-self: center;
  color: ${ COLOR_2 };

  @media (max-width: 550px) {
    font-size: 28px;
  }

  @media (max-width: 480px) {
    font-size: 22px;
    margin-top: 60px;
  }
`;

const Blinker = styled.div`
  height: 30px;
  width: 2px;
  background-color: ${COLOR_2};
  display: inline-block;
  margin-left: 5px;
  vertical-align: middle;

  animation-name: blink;
  animation-iteration-count: infinite;
  animation-duration: 1s;

  @keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
  }
`;

export default Slogan_Realtors;
