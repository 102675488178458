import styled from 'styled-components';

export const WidgetFormWrapper = styled.div`
  background-color: #3578E5;
  padding: 16px;
  position: relative;
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.3);

  @media (max-width: 360px) {
    width: 300px;
  }
`;

export const Footer = styled.footer`
  font-size: 14px;
  color: #FFFFFF;
  opacity: 0.8;
`;
