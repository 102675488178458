import React from 'react';
import propTypes from 'prop-types';

import {
  Wrapper,
  M2Icon,
  Label,
  BedroomsIcon,
} from './styles';

const Features = ({
  area,
  bedrooms,
}) => (
  <Wrapper>
    <M2Icon />
    <Label>{`${area} m²`}</Label>
    <BedroomsIcon />
    <Label>{`${bedrooms}`} quartos</Label>
  </Wrapper>
);

Features.propTypes = {
  area: propTypes.number,
  bedrooms: propTypes.number,
};

Features.defaultProps = {};

export default Features;
