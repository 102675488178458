import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { pointer } from '../../../styled/helpers/helpers';

import { KoortWhiteVerticalLogo } from '../../../styled/components/components';
import { KoortBlueVerticalLogo } from '../../../styled/components/components';

import pathsConstants from '../../../constants/paths';

const Brand = ({
  className,
  history,
}) => (
  <Wrapper
    className={ className }
  >
    <Link to={pathsConstants.HOME}>
      <Image />
    </Link>
  </Wrapper>
);

Brand.propTypes = {
  className: propTypes.string,
  history: propTypes.object,
};

Brand.defaultProps = { };

const Wrapper = styled.div`
  display: flex;
  ${ pointer };
  height: 100%;
`;

const Image = styled(KoortBlueVerticalLogo)`
  width: fit-content;
  height: 100%;
  padding: 0;
  align-self: center;
  /* height: 38px;
  margin-top: 10px; */
`;

export default withRouter(Brand);
