import React, { PureComponent } from "react";
import propTypes from "prop-types";
import { Router, Route, Switch } from "react-router-dom";
import styled from "styled-components";
import { toast } from "react-toastify";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { RiWhatsappLine, RiMailLine, RiPhoneLine } from "react-icons/ri";
import Joyride, { EVENTS } from "react-joyride";
import { Share } from "styled-icons/material/Share";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { BaseLabel } from "../../styled/labels/labels";
import { SmallButton } from "../../styled/buttons/buttons";
import {
  mqDesktop,
  mqTablet,
  mqCustom,
  pointer,
} from "../../styled/helpers/helpers";

import Ads from "./ads/Ads";
import Work from "./work/Work";
import Cover from "./cover/Cover";
import Tabs from "../../components/tabs";
import { ContactInfo } from "./contact-info";

import userService from "../../services/user/user.service";
import userProfileService from "../../services/user-profile/user-profile.service";
import realEstateService from "../../services/real-estate/real-estate.service";
import listingService from "../../services/listing/listing.service";

import pathsConstants from "../../constants/paths";
import eventsConstants from "../../constants/events";
import domEventsUtil from "../../utils/dom-events/dom-events.util";
import queryStringUtil from "../../utils/query-string/query-string.util";

/*
  global
  window
  process
*/

const customId = "custom-id-yes";
class Profile extends PureComponent {
  constructor(props) {
    window.scrollTo(0, 0);
    super(props);

    const user = userService.retrieve();

    const { history } = props;
    const { id } = queryStringUtil.parse(history);
    if (user && !id)
      queryStringUtil.update(history, {
        id: user.id,
      });

    this.state = {
      user,
      id,
      profile: null,
      isRentalIntersected: false,
      isSaleIntersected: false,
      isRentalFinishData: false,
      isSaleFinishData: false,
      renderizeCount: 0,
      realEstatesForSale: [],
      realEstatesForRental: [],
      realEstateDeals: [],
      realEstatesForSaleCurrentPage: 1,
      realEstatesForRentalCurrentPage: 1,
      ratingAvg: 0,
      editionMode: false,
      form: {
        screenName: null,
        role: null,
        creci: null,
        description: null,
      },
      seeMoreDesc: true,
      buttonVisible: false,
      screenWidthMobile: null,
      seeMoreShare: false,
      run: false,
      steps: [
        {
          content: (
            <>
              <h2>
                <b>Bem vindo!</b>
              </h2>
              <br></br>

              <p>
                Este é o início de um tutorial para o <b>Perfil do Usuário</b>.
              </p>
            </>
          ),
          // locale: { skip: <strong aria-label="skip">Sair</strong> },
          placement: "center",
          target: "body",
          locale: {
            next: `Próximo`,
            skip: `Sair`,
          },
        },
        {
          target: ".profile-description",
          content: (
            <>
              <h2>
                <b>Meu Trabalho</b>
              </h2>
              <br></br>

              <p>
                Descrever os aspectos mais importantes de seu trabalho tais
                como, tipo de ímovel, histórico de négocios realizados,
                experiências, etc...
              </p>
            </>
          ),
          locale: {
            next: `Próximo`,
            skip: `Sair`,
            back: "Voltar",
          },
        },
        {
          target: ".profile-information",
          content: (
            <>
              <h2>
                <b>Contato</b>
              </h2>
              <br></br>

              <p>
                Incluir as informações de contatos que achar relevante para o
                seu trabalho.
              </p>
            </>
          ),
          locale: {
            next: `Próximo`,
            skip: `Sair`,
            back: "Voltar",
          },
        },
        {
          target: ".profile-tabs",
          content: (
            <>
              <h2>
                <b>Imóveis Cadastrados</b>
              </h2>
              <br></br>
              <p>
                Cadastrar os seus imóveis de acordo com as duas categorias
                mostradas abaixo.
              </p>
            </>
          ),
          locale: {
            next: `Próximo`,
            skip: `Sair`,
            back: "Voltar",
            last: "Último",
          },
        },
      ],
    };
    this.resize = this.resize.bind(this);
  }

  initTour = () => {
    this.setState({
      ...this.state,
      run: true,
    });
  };

  handleJoyrideCallback = (data) => {
    const { type } = data;
    if (type === EVENTS.TOUR_END && this.state.run) {
      this.setState({
        ...this.state,
        run: false,
      });
    }
  };

  getRealEstates = async () => {
    try {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: true },
      });

      let userId;

      const user = JSON.parse(localStorage.getItem("user"));

      const { history } = this.props;
      const { id } = queryStringUtil.parse(history);

      if (user) {
        userId = user._id;
      }

      if (id) {
        if (user) {
          if (id !== user._id) {
            userId = id;
          }
        } else {
          userId = id;
        }
      }

      const realEstatesForSale = await listingService.getAds({
        userId,
        transaction: "Venda",
      });

      const realEstatesForRental = await listingService.getAds({
        userId,
        transaction: "Locação",
      });

      this.setState((prevState) => ({
        ...prevState,
        realEstatesForSale,
        realEstatesForRental,
      }));
    } catch {
      toast.error("Houve um erro ao carregar as informações dos imóveis!", {
        autoClose: false,
        toastId: customId,
      });
    } finally {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: false },
      });
    }
  };

  componentDidMount() {
    this.getProfile();
    this.getRealEstates();
    const url = window.location.href;
    // const index = url.indexOf('=');
    // const { user } = this.state;
    // if (user) {
    //   const filteredUrl = url.slice(index + 1, url.length).toString();
    //   if (user && user.id === filteredUrl) {
    //     this.setState({ buttonVisible: true });
    //   } else {
    //     this.setState({ buttonVisible: false });
    //   }
    // }
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  handleRequestMoreDataForInfiniteScroll = async ({ transaction, page }) => {
    try {
      let userId;

      const user = JSON.parse(localStorage.getItem("user"));

      const { history } = this.props;
      const { id } = queryStringUtil.parse(history);

      if (user) {
        userId = user._id;
      }

      if (id) {
        if (user) {
          if (id !== user._id) {
            userId = id;
          }
        } else {
          userId = id;
        }
      }

      const realEstates = await listingService.getAds({
        userId,
        transaction: transaction,
        page: page,
      });

      if (transaction === "Venda") {
        if (realEstates.length === 0) {
          this.setState((prevState) => ({
            ...prevState,
            isSaleFinishData: true,
          }));

          return;
        }

        this.setState((prevState) => ({
          ...prevState,
          realEstatesForSale: [...prevState.realEstatesForSale, ...realEstates],
        }));
      }

      if (transaction === "Locação") {
        if (realEstates.length === 0) {
          this.setState((prevState) => ({
            ...prevState,
            isRentalFinishData: true,
          }));

          return;
        }

        this.setState((prevState) => ({
          ...prevState,
          realEstatesForRental: [
            ...prevState.realEstatesForRental,
            ...realEstates,
          ],
        }));
      }
    } catch {
      toast.error("Houve um erro ao carregar os anúncios!", {
        autoClose: false,
        toastId: customId,
      });
    }
  };

  infiniteScrollForTabsOfSaleAndRental() {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    };

    const observerSale = new IntersectionObserver((entities) => {
      const target = entities[0];

      this.setState((prevState) => ({
        ...prevState,
        isRentalIntersected: false,
      }));

      if (!target.isIntersecting && this.state.renderizeCount === 1) {
        this.setState((state) => ({
          ...state,
          renderizeCount: 0,
        }));
      }

      if (target.isIntersecting && this.state.renderizeCount === 0) {
        this.setState((state) => ({
          ...state,
          isSaleIntersected: true,
          renderizeCount: 1,
          realEstatesForSaleCurrentPage:
            state.realEstatesForSaleCurrentPage + 1,
        }));

        if (!this.state.isSaleFinishData) {
          this.handleRequestMoreDataForInfiniteScroll({
            transaction: "Venda",
            page: this.state.realEstatesForSaleCurrentPage,
          });
        }
      }
    }, options);

    const observerRental = new IntersectionObserver((entities) => {
      const target = entities[0];

      this.setState((prevState) => ({
        ...prevState,
        isSaleIntersected: false,
      }));

      if (!target.isIntersecting && this.state.renderizeCount === 1) {
        this.setState((state) => ({
          ...state,
          renderizeCount: 0,
        }));
      }

      if (target.isIntersecting && this.state.renderizeCount === 0) {
        this.setState((state) => ({
          ...state,
          isRentalIntersected: true,
          renderizeCount: 1,
          realEstatesForRentalCurrentPage:
            state.realEstatesForRentalCurrentPage + 1,
        }));

        if (!this.state.isRentalFinishData) {
          this.handleRequestMoreDataForInfiniteScroll({
            transaction: "Locação",
            page: this.state.realEstatesForRentalCurrentPage,
          });
        }
      }
    }, options);

    if (this.loaderSaleRef && !this.state.isSaleIntersected) {
      observerSale.observe(this.loaderSaleRef);
    }

    if (this.loaderRentalRef && !this.state.isRentalIntersected) {
      observerRental.observe(this.loaderRentalRef);
    }
  }

  componentDidUpdate() {
    this.infiniteScrollForTabsOfSaleAndRental();
  }

  resize() {
    let currentHideNav = window.innerWidth;
    if (currentHideNav >= 200 && currentHideNav <= 650) {
      this.setState({ screenWidthMobile: currentHideNav });
    } else {
      this.setState({ seeMoreShare: true });
      this.setState({ screenWidthMobile: false });
    }
  }

  getProfile = async () => {
    domEventsUtil.dispatch({
      name: eventsConstants.LOADING_ANIMATION,
      params: { show: true },
    });

    let userId;

    const { history } = this.props;
    const { id } = queryStringUtil.parse(history);
    const user = JSON.parse(localStorage.getItem("user"));

    if (user) {
      userId = user._id;
    }

    if (id) {
      if (user) {
        if (id !== user._id) {
          userId = id;
        }
      } else {
        userId = id;
      }
    }

    let result;
    try {
      result = await userProfileService.getUserCompleteProfile({
        userId: userId,
      });
      if (!result) {
        // history.push(pathsConstants.HOME)
      }

      // Exibe botão de editar perfil, caso o usuário acessa seu perfil
      if (user) {
        if (user._id === result._id) {
          this.setState((prevState) => ({
            ...prevState,
            buttonVisible: true,
          }));
        }
      }
    } catch (error) {
      throw error;
    } finally {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: false },
      });
    }

    this.setState((state) => ({
      ...state,
      profile: result,
    }));

    // Nao adicionar view se for o mesmo usuário logado
    if (this.state.user && this.state.user.id == id) {
      return;
    }
    try {
      result = await userProfileService.updateView({ id });
    } catch (err) {
      console.log(err);
    }
  };

  checkSize = (description) => {
    const seeMore = true;
    if (description.length > 500) return seeMore;
    else return !seeMore;
  };

  showMore = () => {
    this.setState({ seeMoreDesc: !this.state.seeMoreDesc });
  };

  render() {
    const { history } = this.props;
    const { id } = queryStringUtil.parse(history);
    const {
      user,
      profile,
      seeMoreDesc,
      buttonVisible,
      screenWidthMobile,
      seeMoreShare,
      listingId,
    } = this.state;

    let url = window.location.href;

    if (url.includes("http://localhost:3000")) {
      url = url.replace("http://localhost:3000", "https://dev.koort.com.br");
    }

    if (!profile) return <Wrapper />;

    const { steps, run } = this.state;

    const incrementShares = async () => {
      await listingService.incrementShares({
        listingId: listingId,
      });
    };

    let tabConfigs = [
      {
        label: "Imóveis para venda",
        path: pathsConstants.PROFILE_SALE,
        isIncorporation: profile && profile.userType === 5,
      }
    ];

    if (profile && profile.userType < 5) {
      tabConfigs.push({
        label: "Imóveis para locação",
        path: pathsConstants.PROFILE_RENTAL,
      });
    }

    return (
      <Wrapper>
        <Joyride
          steps={steps}
          scrollDuration={300}
          scrollToFirstStep={true}
          disableScrolling={true}
          showProgress={true}
          showSkipButton={true}
          continuous={true}
          run={run}
          callback={this.handleJoyrideCallback}
        />
        <div className={"profile-apresentation"}>
          <Cover
            {...profile}
            pictureUrl={profile.profile.image}
            // rating={(() => {
            //   let avg = 0;
            //   reviews.forEach(r => avg += r.averageScore);
            //   avg /= reviews.length;

            //   return avg;
            // })()}
            buttonVisible={buttonVisible}
            // reviews={reviews.length}
          />
        </div>

        <ContentWrapper>
          <Container fluid>
            <Row>
              <Col xs={12}>
                {" "}
                {profile.profile.professionalInformation.creci && (
                  <Creci>
                    Creci: {profile.profile.professionalInformation.creci}
                  </Creci>
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={8}>
                <Work
                  checkDescriptionSize={() =>
                    this.checkSize(profile.profile.aboutMe)
                  }
                  description={profile.profile.aboutMe}
                  areas={profile.profile.professionalInformation.serviceAreas}
                  showMore={() => this.showMore()}
                  visible={seeMoreDesc}
                />
              </Col>
              <Col xs={12} md={4}>
                <ContactInfo
                  {...profile.profile.professionalInformation.address}
                  phoneNumber={
                    profile.profile.professionalInformation.cellphone.number ||
                    profile.profile.professionalInformation.telephone.number
                  }
                  email={profile.email}
                  facebook={profile.profile.professionalInformation.facebook}
                  instagram={profile.profile.professionalInformation.instagram}
                  linkedIn={profile.profile.professionalInformation.linkedIn}
                  whatsapp={
                    profile.profile.professionalInformation.cellphone.whatsApp
                  }
                  isContactuser={id && user && user.id != id}
                  history={history}
                  user={user}
                />
              </Col>
            </Row>
          </Container>
          <Col xs={12} sm={12} style={{ marginTop: '32px' }}>
            <TabsWrapper>
              <div className={"profile-tabs"}>
                <Tabs configs={tabConfigs} />
              </div>
              <Router history={history}>
                <Switch>
                  <Route
                    path={pathsConstants.PROFILE_SALE}
                    render={(props) => (
                      <>
                        <Ads
                          {...props}
                          key={pathsConstants.PROFILE_SALE}
                          ads={this.state.realEstatesForSale}
                          user={user}
                        />

                        <p
                          ref={(ref) => {
                            this.loaderSaleRef = ref;
                          }}
                          style={{ visibility: "hidden" }}
                        >
                          Carregando mais anúncios de venda...
                        </p>
                      </>
                    )}
                  />
                  <Route
                    path={pathsConstants.PROFILE_RENTAL}
                    render={(props) => (
                      <>
                        <Ads
                          {...props}
                          key={pathsConstants.PROFILE_RENTAL}
                          ads={this.state.realEstatesForRental}
                          user={user}
                        />
                        <div
                          ref={(ref) => {
                            this.loaderRentalRef = ref;
                          }}
                          style={{ visibility: "hidden" }}
                        >
                          Carregando mais anúncios de aluguel...
                        </div>
                      </>
                    )}
                  />
                  {/* <Route
                  path={pathsConstants.PROFILE_DEALS}
                  render={props => (
                    <Ads
                      {...props}
                      key={pathsConstants.PROFILE_DEALS}
                      ads={this.state.realEstateDeals}
                      user={user}
                    />
                  )}
                /> */}
                </Switch>
              </Router>
            </TabsWrapper>
          </Col>
        </ContentWrapper>
        {/* <OpenTourGlobal
          alt={"Iniciar tutorial"}
          onClick={this.initTour}
          src={`${process.env.PUBLIC_URL}/imgs/tutorial.png`}
        ></OpenTourGlobal> */}
      </Wrapper>
    );
  }
}

Profile.propTypes = {
  history: propTypes.object,
};

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const OpenTourGlobal = styled.img`
  width: 40px;
  position: fixed;
  z-index: 1;
  right: 10px;
  bottom: 20px;
  cursor: pointer;
`;

const WhatsAppContent = styled.a`
  display: block;

  > svg {
    margin-right: 12px;
  }
`;

const ContentWrapper = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
  // position: absolute;
  // top: 435px;

  ${mqTablet`
    // margin-top: -180px;
  `}

  ${mqDesktop`
    width: 1000px;
    align-self: center;
    margin-top: -80px;
    justify-content: flex-start;
    align-items:flex-start;
    flex-direction: row;
    flex-flow: wrap;
    // position: absolute;
    // top: 435px;
  `}
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  min-width: 100%;
  ${mqTablet`

    justify-content: space-between;
    margin-bottom: 30px;
    margin-top:30px;
    flex-grow: 1;
    justify-content: flex-end;
    margin-top: 0;
  `}

  ${mqDesktop`
    // min-width: initial;
    flex-direction: row;
  `}
`;

const Creci = styled(BaseLabel)`
  font-weight: 700;
  align-self: center;
  font-size: 14px;
  margin-bottom: 10px;

  ${mqTablet`
    align-self: flex-start;
    margin-left: 140px;

  `}
`;

const TabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  ${mqTablet`
    margin-top: 10px;
`}
  ${mqDesktop`
    width: calc(100% - 250px);
    order: 4;
    margin-top: 0px;

  `}
`;

export default Profile;
