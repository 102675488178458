import styled from 'styled-components';

export const Container = styled.span`
  color: #E71837;
  font-size: 12px;
  display: flex;
  align-items: center;
  margin: 8px 0;

  > svg {
    margin-right: 6px;
  }
`;
