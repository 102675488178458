import styled from 'styled-components';
import Select from 'react-select';

export const ReactSelect = styled(Select)`
  .react-select__control {
    border: 0 !important;
    box-shadow: none !important;
    border-color: none !important;
    min-height: 32px !important;
    background-color: transparent !important;
    font-size: 14px;
  }

  .react-select__value-container {
    padding: 0 !important;
  }

  .react-select__indicators {
    display: flex !important;
  }

  margin-top: -6px;
`;
