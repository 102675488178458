import React, { useState } from 'react';
import { RiClipboardLine } from 'react-icons/ri';
import { toast } from 'react-toastify';

import { IntegrationActionButtons } from '../../integration-action-buttons';

import pathsConstants from '../../../../../../constants/paths';
import stringUtil from '../../../../../../utils/string/string.util';

import {
  GrupoZapWrapper,
  UrlXmlWrapper,
  UrlXmlLink,
  ClipboardUrlXml,
  ReceiveLeadsUrlText,
} from './styles';

export function GrupoZap({
  data,
  integratedPortal,
  portalToBeRemoved,
  onRemoveIntegration,
  setPortalToBeRemoved
}) {
  const [clipboardUrlXmlButtonDisabled, setClipboardUrlXmlButtonDisabled] = useState(false);

  let grupoZapReceiveLeadsUrl = '';

  if (window.location.origin.includes('localhost') || window.location.origin.includes('dev.koort')) {
    grupoZapReceiveLeadsUrl = 'https://db-api.dev.koort.com.br/webhooks/grupo-zap/sending-the-leads';
  } else {
    grupoZapReceiveLeadsUrl = 'https://db-api.koort.com.br/webhooks/grupo-zap/sending-the-leads';
  }

  function handleCopyUrlXmlToClipboard(urlXml) {
    setClipboardUrlXmlButtonDisabled(true);

    stringUtil.copyToClipboard({
      str: urlXml,
    });

    toast.info('Url copiada para a área de transferência!', {
      autoClose: 2000,
    });

    setTimeout(() => {
      setClipboardUrlXmlButtonDisabled(false);
    }, 2000);
  }

  function handleRemoveIntegration() {
    if (portalToBeRemoved === data.value) {
      onRemoveIntegration();
    } else {
      setPortalToBeRemoved(data.value);
    }
  }

  function handleManageIntegrationIntoDashboard() {
    window.open(`${pathsConstants.DASHBOARD_ADS}?integrationPortal=${data.value}`, '_self')
  }

  return (
    <GrupoZapWrapper>
      <h3>{data.label}</h3>

      <div>
        {integratedPortal !== null ?
          <>
            <UrlXmlWrapper>
              <UrlXmlLink>
                <a
                  href={integratedPortal.url}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {integratedPortal.url}
                </a>
              </UrlXmlLink>

              <ClipboardUrlXml
                type="button"
                title="Copiar URL"
                disabled={clipboardUrlXmlButtonDisabled}
                onClick={() => handleCopyUrlXmlToClipboard(integratedPortal.url)}
              >
                <RiClipboardLine size={24} />
              </ClipboardUrlXml>
            </UrlXmlWrapper>

            <ReceiveLeadsUrlText>
              Url para integrar o recebimento de leads do grupo-zap:
            </ReceiveLeadsUrlText>

            <UrlXmlWrapper>
              <UrlXmlLink>
                <a
                  title={grupoZapReceiveLeadsUrl}
                  style={{ opacity: 0.8, cursor: 'default' }}
                >
                  {grupoZapReceiveLeadsUrl}
                </a>
              </UrlXmlLink>

              <ClipboardUrlXml
                type="button"
                title="Copiar URL"
                disabled={clipboardUrlXmlButtonDisabled}
                onClick={() => handleCopyUrlXmlToClipboard(grupoZapReceiveLeadsUrl)}
              >
                <RiClipboardLine size={24} />
              </ClipboardUrlXml>
            </UrlXmlWrapper>
          </>
          :
          <span>Aqui estará o seu arquivo XML</span>
        }

        <IntegrationActionButtons
          hasPortalToBeRemoved={portalToBeRemoved === data.value}
          onRemoveIntegration={handleRemoveIntegration}
          onManageIntegrationIntoDashboard={handleManageIntegrationIntoDashboard}
        />
      </div>
    </GrupoZapWrapper>
  );
}
