import styled from 'styled-components';

import { mqTablet } from '../helpers/helpers';
import { COLOR_2, COLOR_3 } from '../variables/variables';

export const BaseLabel = styled.label`
  font-weight: 300;
  display: block;
  letter-spacing: 0.5px;
  color: ${COLOR_3};
  font-size: 16px;
`;

export const BoldLabel = styled(BaseLabel)`
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const SectionTitle = styled(BaseLabel)`
  font-size: 22px;
  align-self: center;
  color: ${COLOR_2};

  ${mqTablet`
    font-size: 30px;
  `}
`;

export const Bold = styled.label`
  font-weight: ${({ weight }) => weight || 700};
`;

export default {
  BaseLabel,
  Bold,
  BoldLabel,
  SectionTitle,
};
