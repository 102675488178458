import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { RiCloseLine } from 'react-icons/ri';

import { ContactFormFields } from './contact-form-fields';

import {
  Wrapper,
  CFDivider,
  FormCall,
  CallToActionButton,
  ModalContent,
  ModalBody,
  ContactFormModal
} from './styles';

export function ContactForm({
  listingId,
  isRelease,
  modalVisible,
  onCloseModal,
  onOpenModal
}) {

  // useEffect(() => {
  //   if (!isRelease) {
  //     return;
  //   }

  //  const modalTimeOut = setTimeout(() => {
  //   onOpenModal();
  //   }, 12000);

  //   return () => {
  //     clearTimeout(modalTimeOut);
  //   }
  // }, [isRelease]);

  return (
    <>
    <Modal
        isOpen={modalVisible}
        style={{
          content: {
            backgroundColor: 'transparent',
            border: 0,
            padding: 0,
            inset: 0,
            borderRadius: 0,
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.15)'
          }
        }}
      >
        <ModalContent>
          <ModalBody>
            <ContactFormModal>
              <header>
                <h3>Acesse condições exclusivas junto ao executivo de vendas</h3>

                <button type="button" onClick={onCloseModal}>
                  <RiCloseLine size={26} />
                </button>
              </header>

              <p>
                O Koort é o seu parceiro confiável na busca pelo imóvel perfeito. Aproveite esta oportunidade única e
                transforme este anúncio em realidade. Não espere mais, preencha o formulário agora e comece sua nova jornada!
              </p>

              <ContactFormFields
                listingId={listingId}
                onSuccess={onCloseModal}
              />
            </ContactFormModal>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
