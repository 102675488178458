import styled from 'styled-components';
import { transitions } from 'polished';

import { pointer, iconBaseStyle, mqTablet } from '../../styled/helpers/helpers';
import { COLOR_2, COLOR_4 } from '../../styled/variables/variables';
import { DotsHorizontal } from 'styled-icons/boxicons-regular/DotsHorizontal';
import { BaseLabel } from '../../styled/labels/labels';



export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${pointer}

  ${mqTablet`
    align-self: flex-start;
  ` }

  &:hover {
    & > div:first-child {
      background-color: ${COLOR_4};
      & > svg {
        color: ${COLOR_2};
      }
    }
    & > div:last-child {
      opacity: 1;
    }
  }
`;


export const Square = styled.div`
  border-radius: 1px;
  border: solid 1px ${ COLOR_4 };
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
  align-self: center;
  ${ transitions([ 'background-color' ], 'ease .3s') }
`;

export const Tooltip = styled.div`
  background-color: ${ COLOR_4 };
  padding: 7px 3px;
  border-radius: 1px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  opacity: 0;
  width: 60px;

  ${ transitions([ 'opacity' ], 'ease .3s') }
`;

export const Triangle = styled.div`
  height: 8px;
  width: 8px;
  background-color: ${ COLOR_4 };
  align-self: center;
  margin-top: -9px;
  transform: rotate(45deg);
  border-radius: 1px;
`;

export const TooltipLabel = styled(BaseLabel)`
  color: ${ COLOR_2 };
  font-size: 9px;
  align-self: center;
  text-align: center;
`;


