import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';
import { transitions } from 'polished';
import propTypes from 'prop-types';
import { AngleDown } from 'styled-icons/fa-solid/AngleDown';

import { COLOR_1, COLOR_2 } from '../../../styled/variables/variables';
import { BaseLabel } from '../../../styled/labels/labels';
import { iconBaseStyle, pointer, mqTablet } from '../../../styled/helpers/helpers';

import currencyUtil from '../../../utils/currency/currency.util';


class Selectorr extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      showAll: false,
    };
  }

  handleItemClick = type => {
    this.setState(state => ({
      ...state,
      showAll: !state.showAll,
    }));

    const { onItemClick, selected } = this.props;
    if(type !== selected) onItemClick(type);
  };

  render() {
    const { options, selected } = this.props;
    const { showAll } = this.state;

    return (
      <Wrapper 
        isOpened={ showAll }
        length={ options.length }
      >
        { options.map(({ key, name, price }, index) => {

          const isActive = key === selected;

          let order = index;
          if(isActive) order = 0;
          else if(!isActive && index === 0) order = 1;

          return (
            <Item 
              key={ key }
              onClick={ () => this.handleItemClick(key) }
              order={ order }
              isActive={ isActive }
            >
              <Name>{ name }</Name>
              <Price>{ currencyUtil.format({ value: price, }) }</Price>
              
              { options.length > 1 && isActive && (
                <DownArrowIcon />
              ) }
            </Item>
          );
        }) }
      </Wrapper>
    )
  }
}

Selectorr.propTypes = {
  onItemClick: propTypes.func.isRequired,
  selected: propTypes.string,
  options: propTypes.arrayOf(propTypes.shape({
    key: propTypes.string.isRequired,
    name: propTypes.string.isRequired,
    price: propTypes.number.isRequired,
  })),
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 60px;
  overflow: hidden;
  border-radius: 1px;
  
  ${ transitions([ 'max-height' ], 'ease .3s') }
  ${ ({ isOpened, length }) => isOpened && css`
    max-height: ${ length * 60 }px;
  ` }

  ${ mqTablet`
    display: none;
  ` }
`;

const Item = styled.div`
  min-height: 60px;
  display: flex;
  ${ pointer }
  background-color: ${ COLOR_1 };
  padding: 0 10px;

  ${ ({ order }) => css`
    order: ${ order };
  ` }
`;

const Name = styled(BaseLabel)`
  color: ${ COLOR_2 };
  flex-grow: 1;
  width: 1px;
  align-self: center;
  font-size: 14px;
  text-transform: uppercase;
  opacity: 0.9;
`;

const Price = styled(Name)`
  font-weight: 700;
  width: fit-content;
  flex-grow: unset;
  text-transform: none;
  font-size: 18px;
  margin-right: 10px;
  opacity: 1;
`;

const DownArrowIcon = styled(AngleDown)`
  ${ iconBaseStyle }
  color: ${ COLOR_2 };
  height: 15px;
  align-self: center;
  opacity: 0.7;
`;

export default Selectorr;