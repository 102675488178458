
import { createGlobalStyle } from 'styled-components';
import { COLOR_1, COLOR_6, COLOR_2, COLOR_8 } from '../variables/variables';
import { pointer } from '../helpers/helpers';

const StylingResetter = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
  body {
    position: relative;
  }
  *, *:before, *:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    text-decoration: none;
    font-family: Montserrat, "Open Sans", Helvetica, Arial, sans-serif;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  img {
    display: block;
  }
  input::placeholder {
    color: ${COLOR_8};
    opacity: 1;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }


  .mapMarker {
    height: 15px;
    width: 15px;
    background-color: ${COLOR_1};
    border-radius: 50%;
    position: absolute;
    border: solid 1px ${COLOR_2};
    ${pointer}

    &.-highlight {
      height: 18px;
      width: 18px;
      background-color: ${COLOR_6};
    }

    &.-surrounding-place {
      height: 27px;
      width: 27px;
      border-radius: 1px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  .markerAnimation {
    animation: pulse .5s infinite alternate !important;
    -webkit-animation: pulse .5s infinite alternate !important;
    transform-origin: center !important;
    -webkit-transform-origin: center !important;
  }

  /* Animaitons */
  keyframes pulse{
    to {
      transform: scale(0.7);
      -webkit-transform: scale(0.7);
    }
  }

  @-webkit-keyframes pulse{
    to {
      transform: scale(0.7);
      -webkit-transform: scale(0.7);
    }
  }

  .mapMarkerBlinker {
    height: 18px;
    width: 18px;
    display: flex;
    justify-content: center;
    border: none;
    position: absolute;
    border-radius: 50%;
    transform: scale(2);

    &.blink {
      animation-name: mapMarkerBlinker;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-play-state: running;
    }
  }

  @keyframes mapMarkerBlinker {
    0% {
      transform: scale(1);
      background-color: rgba(246, 134, 31, 0.7);
    }
    100% {
      transform: scale(4);
      background-color: rgba(246, 134, 31, 0);
    }
  }

  .info-window{
    width: 230px;
    height: 270px;
    background: #fff;
    padding: 0px;
    overflow: none;
  }

  .info-window__container_img{
    height: 150px;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .info-window__container_property{
    margin-top: 10px;
    text-align: center;
    width: 100%;
  }

  .info-window__container_property span{
    color: #333333;
    font-size: 12px;
  }

  .info-window__container_property h1{
    color: #333333;
    font-size: 16px;
    font-weight: bold;
    margin-top: 2px;
  }

  .info-window__container_property h2{
    color: #333333;
    font-size: 15px;
    margin-top: 0px;
  }

  .info-window__container_property label{
    color: #333333;
    font-size: 14px;
    width: calc(50% - 15px);
    text-align: center;
    margin-top: 0px;
    float:left;
  }

  .info-window__container_property h3{
    color: #333333;
    font-size: 16px;
    font-weight: bold;
    margin-top: 4px;
    float:left;
    width:100%;
  }

  .info-window__span, .info-window__span_right {
    top: 0px;
    left: -35px;
    width: 35px;
    height: 270px;
    position:absolute;
    // background:rgba(0,0,0,0.1);
    text-align: center;
    line-height: 315px;
  }

  .info-window__span:hover, .info-window__span_right:hover {
    width: 35px;
    // background:rgba(0,0,0,0.3);
    cursor: pointer;
  }

  .info-window__span:hover .arrow {
    border-color: black;
  }
  .info-window__span_right:hover .arrow {
    border-color: black;
  }

  .arrow {
    border: solid rgba(0,0,0,0.5);
    border-width: 0 5px 5px 0;
    display: inline-block;
    padding: 9px;
    margin-left: 10px;
  }

   //Remove ícone de seta abaixo do card
  .gm-style .gm-style-iw-tc::after {
    background: transparent;
  }

  .gm-style-iw-a {
    //left: calc(250px + 100vw - 1024px)!important;
    overflow: visible !important;
  }

  .gm-style-iw-d {
    overflow: visible !important;
  }

  .gm-style-iw-c{
    overflow: visible !important;
    padding: 0px !important;
  }

  *:focus {
    outline: none;
}

  .right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    margin-left: -13px;
  }

  .left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }

  .info-window__span_right{
    right: -37px;
    left: unset;
  }

  .gm-style-iw gm-style-iw-c {
    overflow: visible !important;
  }

  .gm-style .gm-style-iw-t::after {
    visibility: hidden;
  }

  .cluster span {
    //margin-left: 80%;
  }


  @-moz-keyframes pulsate {
		from {
			-moz-transform: scale(0.25);
			opacity: 1.0;
		}
		95% {
			-moz-transform: scale(1.3);
			opacity: 0;
		}
		to {
			-moz-transform: scale(0.3);
			opacity: 0;
		}
	}
	@-webkit-keyframes pulsate {
		from {
			-webkit-transform: scale(0.25);
			opacity: 1.0;
		}
		95% {
			-webkit-transform: scale(1.3);
			opacity: 0;
		}
		to {
			-webkit-transform: scale(0.3);
			opacity: 0;
		}
	}
  div[title*=" destaque selecionado(a)."] {
		-moz-animation: pulsate 1.5s ease-in-out infinite;
		-webkit-animation: pulsate 1.5s ease-in-out infinite;
		border:10pt solid #ff7a00;
		-moz-border-radius:51px;
		-webkit-border-radius:51px;
		border-radius:51px;
		-moz-box-shadow:inset 0 0 5px #f6861f, inset 0 0 5px #f6861f, inset 0 0 5px #f6861f, 0 0 5px #f6861f, 0 0 5px #f6861f, 0 0 5px #f6861f;
		-webkit-box-shadow:inset 0 0 5px #f6861f, inset 0 0 5px #f6861f, inset 0 0 5px #f6861f, 0 0 5px #f6861f, 0 0 5px #f6861f, 0 0 5px #f6861f;
		box-shadow:inset 0 0 5px #f6861f, inset 0 0 5px #f6861f, inset 0 0 5px #f6861f, 0 0 5px #f6861f, 0 0 5px #f6861f, 0 0 5px #f6861f;
		height:51px!important;
		margin:-18px 0 0 -18px;
		width:51px!important;
	}

	div[title*=" destaque selecionado(a)."] img {
		display:none;
	}

	@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (device-width: 768px) {
		div.gmnoprint[title*=" destaque selecionado(a)."] {
			margin:-10px 0 0 -10px;
		}
	}

	div[title*=" selecionado(a)."]:not(div[title*=" destaque selecionado(a)."]) {
		-moz-animation: pulsate 1.5s ease-in-out infinite;
		-webkit-animation: pulsate 1.5s ease-in-out infinite;
		border:10pt solid #06f;
		-moz-border-radius:51px;
		-webkit-border-radius:51px;
		border-radius:51px;
		-moz-box-shadow:inset 0 0 5px #06f, inset 0 0 5px #06f, inset 0 0 5px #06f, 0 0 5px #06f, 0 0 5px #06f, 0 0 5px #06f;
		-webkit-box-shadow:inset 0 0 5px #06f, inset 0 0 5px #06f, inset 0 0 5px #06f, 0 0 5px #06f, 0 0 5px #06f, 0 0 5px #06f;
		box-shadow:inset 0 0 5px #06f, inset 0 0 5px #06f, inset 0 0 5px #06f, 0 0 5px #06f, 0 0 5px #06f, 0 0 5px #06f;
		height:51px!important;
		margin:-18px 0 0 -18px;
		width:51px!important;
	}

	div[title*=" selecionado(a)."]:not(div[title*=" destaque selecionado(a)."]) img {
		display:none;
	}

	@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (device-width: 768px) {
		div.gmnoprint[title*=" selecionado(a)."]:not(div[title*=" destaque selecionado(a)."]) {
			margin:-10px 0 0 -10px;
		}
	}

`;

export default StylingResetter;
