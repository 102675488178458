import React from 'react';

import { ReactSelect } from './styles';

export function Select({
  noOptionsMessage = () => 'Sem opções',
  onChange,
  name,
  placeholder,
  isSearchable = true,
  isClearable = false,
  options,
  ...rest
}) {
  return (
    <ReactSelect
      id={name}
      className="react-select-container"
      classNamePrefix="react-select"
      placeholder={placeholder}
      name={name}
      noOptionsMessage={noOptionsMessage}
      onChange={onChange}
      singleValue
      isSearchable={isSearchable}
      isClearable={isClearable}
      options={options}
      {...rest}
    />
  );
}
