/* eslint-disable no-unreachable */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import attachBrokersService from '../../../../../services/attach-brokers/attach-brokers.service';
import userService from '../../../../../services/user/user.service';
import domEventsUtil from '../../../../../utils/dom-events/dom-events.util';
import eventsConstants from "../../../../../constants/events";
import { Card } from './components/card';

import {
  Wrapper,
  LoadMoreButton
} from './styles';
import NoResults from '../../../../../components/no-results';

export function AttachedProperty() {
  const [realEstatesData, setRealEstatesData] = useState([]);

  const [showLoadMoreButton, setShowLoadMoreButton] = useState(false);
  const [page, setPage] = useState(1);

  async function loadData() {
    try {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: true },
      });

      const params = new URL(document.location).searchParams;

      const brokerId = params.get("brokerId");

      const token = userService.retrieve().token;

      const { status, data } = await attachBrokersService.getRealEstatesAttachedBroker({
        brokerId,
        page,
        token
      });

      if (status !== 200) {
        throw new Error('Não foi possível carregar os imóveis vinculados, recarregue a página!');
      }


      if (data) {
        setRealEstatesData([...realEstatesData, ...data]);

        if (data.length === 10) {
          setShowLoadMoreButton(true);
        } else {
          setShowLoadMoreButton(false);

          toast.info("Todos os imóveis foram carregados!");
        }
      }
    } catch (err) {
      toast.error(err.message);
    } finally {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: false },
      });
    }
  }

  useEffect(() => {
    loadData();
  }, [page]);

  async function handleDetachPropertyToBroker(realEstateId) {
    try {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: true },
      });

      const token = userService.retrieve().token;

      const { status } = await attachBrokersService.detachPropertyToBroker({
        realEstateId,
        token
      });

      if (status !== 204) {
        throw new Error('Não foi possível desvincular esse imóvel, tente novamente!');
      }

      const realEstatesDataFiltered = realEstatesData.filter(item => item._id !== realEstateId);

      setRealEstatesData(realEstatesDataFiltered);

      toast.success('Imóvel desvinculado com sucesso!');
    } catch (err) {
      toast.error(err.message);
    } finally {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: false },
      });
    }
  }

  return (
    <Wrapper>
      {realEstatesData.length > 0 ? realEstatesData.map(item => (
        <Card
          key={item._id}
          realEstate={item}
          onDetachPropertyToBroker={realEstateId => handleDetachPropertyToBroker(realEstateId)}
        />
      )) : <NoResults />}

      {showLoadMoreButton && (
        <LoadMoreButton type="button" onClick={() => setPage(state => state + 1)}>
          Carregar mais imóveis
        </LoadMoreButton>
      )}
    </Wrapper>
  );
}
