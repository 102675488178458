import styled from 'styled-components';

import { COLOR_1 } from '../../styled/variables/variables';

export const StyledModal = styled.div`
  width: 40rem;
  height: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  opacity: ${props => props.opacity};
  transition: opacity ease 500ms;
`;

export const CloseButton = styled.a`
  color: ${COLOR_1};
  font-size: 30px;
  margin-right: 20px;
  height: 95%;
  `;

export const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  margin-right: ${({ noMargin }) => noMargin ? '0px' : '20px'};
  width:100%  

`;