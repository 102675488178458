import React from 'react';
import styled from 'styled-components';
import Cover from './cover/Cover';

import Content from './content/Content';
import Koortimativa from './koortimativa/Koortimativa';

const AboutUs = () => (
  <Wrapper>
    <Cover />
    <Content />
    <Koortimativa />
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export default AboutUs;
