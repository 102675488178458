import styled from "styled-components";

export const ChartWrapper = styled.div`
  flex: 1;
  height: 560px;
  padding: 24px 0px;
  border: 1px solid #EEF0F4;
  border-radius: 8px;

  .recharts-responsive-container {
    height: 560px !important;
  }

  > h3 {
    font-size: 18px;
    font-weight: 600;
    margin-left: 18px;
  }

  > p {
    font-size: 14px;
    padding: 0 16px;
    margin-top: 8px;
    margin-bottom: 24px;
  }
`;
