import httpClient from '../../clients/http/http.client';

const getRealEstatePendingTotal = async ({ token }) => {
  const path = '/realestates/search/pending/total';

  const response = await httpClient.get({ path, token });

  return response;
}

const getRealEstatePendencies = async ({
  realEstateId,
  status = null,
  origin = null,
  page = 1,
  size = 10,
  token,
}) => {
  // Params:
  //   status(opcional): | "open" | "in analysis" | "solved" | "closed"
  //   origin(opcional): "integration" | "denunciation"

  let path = `/realestates/${realEstateId}/pendencies?page=${page}&size=${size}`;

  if (status !== null) {
    path = `${path}&status=${status}`;
  }

  if (origin !== null) {
    path = `${path}&origin=${origin}`;
  }

  const response = await httpClient.get({ path, token });

  return response;
}

export default {
  getRealEstatePendingTotal,
  getRealEstatePendencies,
};
