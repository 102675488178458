import styled from 'styled-components';

export const Button = styled.button`
  background-color: #96D22D;
  padding: 16px 54px;

  color: #FFFFFF;
  font-size: 16px;
  font-weight: 700;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.8);
  }
`;
