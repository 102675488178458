import ReactDOM from 'react-dom';
import React from 'react';
import { HelmetProvider, Helmet } from "react-helmet-async";
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import Amplify, { Auth } from 'aws-amplify';
import { ToastContainer } from 'react-toastify';
import { Auth0Provider } from '@auth0/auth0-react';
import 'rbx/index.css';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-slideshow-image/dist/styles.css';

import GoogleTagManager from './google-tag-manager';

import * as serviceWorker from './serviceWorker';
import amplifyConfig from './amplify-config';
import StoreProvider from './hocs/store-provider/StoreProvider';
import App from './app/App';

import './index.css';

/* global document */

Amplify.configure(amplifyConfig);

// >>New - Configuring Auth Module
Auth.configure(amplifyConfig);

Bugsnag.start({
  apiKey: '05f905606f3c5355a238e429d7d1c039',
  plugins: [new BugsnagPluginReact(React)],
});

const ErrorBoundary = Bugsnag.getPlugin('react');

const helmetContext = {};

ReactDOM.render(
  <ErrorBoundary>
    <HelmetProvider context={helmetContext}>
      <Helmet>
        <link rel="canonical" href={window.location.href} />

        <title>Koort Imóveis</title>
        <meta name="title" content="Koort Imóveis" />
        <meta name="description" content="Koort, negócios inteligentes, rápidos." />

        <meta property="og:url" content="https://koort.com.br/" />
        <meta property="og:title" content="Koort Imóveis" />
        <meta property="og:description" content="Koort, negócios inteligentes, rápidos." />

        <meta property="twitter:url" content="https://koort.com.br/" />
        <meta property="twitter:title" content="Koort Imóveis" />
        <meta property="twitter:description" content="Koort, negócios inteligentes, rápidos." />
      </Helmet>
      <StoreProvider>
        <Auth0Provider
          domain={process.env.REACT_APP_AUTH0_DOMAIN}
          clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
          authorizationParams={{
            redirect_uri: `${window.location.origin}/authentication/login/success`,
            prompt: "select_account",
          }}
        >
          {App}
          <>
            <ToastContainer
              position="bottom-center"
              autoClose={false}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <GoogleTagManager />
          </>
        </Auth0Provider>
      </StoreProvider>
    </HelmetProvider>
  </ErrorBoundary>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
