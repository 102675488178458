
import Base from "../base/base.model";

class RealEstate extends Base {
  constructor({
    id,
    
    realtorId,
    user,
    realEstate,
    name,
    email,
    telephone,
    message,
    date,
  }) {
    super({ id });
    
    this.realtorId = realtorId;
    this.user = user;
    this.realEstate = realEstate;
    this.name = name;
    this.email = email;
    this.telephone = telephone;
    this.message = message;
    this.date = date;
  }
}

export default RealEstate;
