import React, { useState, useEffect, useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Modal from 'react-modal';
import CircularProgress from '@material-ui/core/CircularProgress';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom'

import { InputBootstrap } from '../../../components/form/input-bootstrap';

import userService from '../../../services/user/user.service';
import urlIntegrationService from '../../../services/koort-business/url_integration.service';

import domEventsUtil from '../../../utils/dom-events/dom-events.util';
import eventsConstants from '../../../constants/events';
import pathsConstants from '../../../constants/paths';

import {
  Container,
  Title,
  StepsList,
  UrlIntegrationModalBody,
  RecurrenceModalButtons,
  ConfirmRecurrenceButton,
  DenyRecurrenceButton
} from './styles';


const customId = "custom-id-yes";
const urlIntegrationFormSchema = yup.object().shape({
  urlIntegration: yup.string().required('Este campo é obrigatório')
    .url('Informe uma URL válida')
});

export function Ad() {
  const [urlIntegration, setUrlIntegration] = useState('');
  const [urlIntegrationGetError, setUrlIntegrationGetError] = useState(false);

  const [xmlErrorModalVisible, setXmlErrorModalVisible] = useState(false);
  const [xmlErrorMessage, setXmlErrorMessage] = useState('');

  const [forceIntegration, setForceIntegration] = useState(false);

  const { register, handleSubmit, formState, setValue } = useForm({
    resolver: yupResolver(urlIntegrationFormSchema),
  });

  const { errors, isSubmitting } = formState;

  useEffect(() => {
    async function getUrlIntegrator() {
      try {
        domEventsUtil.dispatch({
          name: eventsConstants.LOADING_ANIMATION,
          params: { show: true },
        });

        const user = userService.retrieve();

        const response = await urlIntegrationService.getUrl({
          token: user.token,
        });

        if (response && response.status !== 200) {
          throw 'Houve um erro ao carregar sua URL integrada!';
        }

        if (response.data === null) {
          return;
        }

        setUrlIntegration(response.data.ads.url);

        setValue('urlIntegration', response.data.ads.url);
      } catch (err) {
        toast.error(err, {
          autoClose: false,
          toastId: customId,
        });

        setUrlIntegrationGetError(true);
      } finally {
        domEventsUtil.dispatch({
          name: eventsConstants.LOADING_ANIMATION,
          params: { show: false },
        });
      }
    }

    getUrlIntegrator();
  }, []);

  const handleUrlIntegrationFormSubmit = useCallback(async values => {
    try {
      const user = userService.retrieve();

      const { response } = await urlIntegrationService.sendUrl({
        token: user.token,
        url: values.urlIntegration,
        toForceIntegration: forceIntegration,
      });

      if (response && response.status !== 200) {
        if (response.data.message.includes('imite de anúncios')) {
          setXmlErrorMessage(response.data.message);
          setXmlErrorModalVisible(true);

          return;
        } else {
          throw response.data.message;
        }
      }

      setUrlIntegration(values.urlIntegration);

      toast.success('Sua URL foi integrada com sucesso!', {
        autoClose: false,
        toastId: customId,
      });
    } catch (err) {
      toast.error(err.replace('Usuário', 'Você'), {
        autoClose: false,
        toastId: customId,
      });
    }
  }, [forceIntegration]);

  async function removeUrlIntegration() {
    try {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: true },
      });

      const user = userService.retrieve();

      const { response } = await urlIntegrationService.removeUrl({
        token: user.token,
      });

      if (response && response.status !== 200) {
        throw 'Houve um erro ao remover sua URL de integração!';
      }

      setValue('urlIntegration', '');
      setUrlIntegration('');

      toast.success('Sua URL foi removida com sucesso!', {
        autoClose: false,
        toastId: customId,
      });
    } catch (err) {
      toast.error(err, {
        autoClose: false,
        toastId: customId,
      });
    } finally {
      domEventsUtil.dispatch({
        name: eventsConstants.LOADING_ANIMATION,
        params: { show: false },
      });
    }
  }

  function handleForceIntegration() {
    setForceIntegration(true);

    setTimeout(() => {
      setXmlErrorModalVisible(false);
      document.getElementById('integration-xml-button').click();
    }, 200);
  }

  return (
    <Container fluid="md">
      <Row>
        <Col xs={12} md={12}>
          <Title>Integração de Anúncios</Title>

          <StepsList>
            <li>
              <h6 className={"text_p_2"}>
                Peça ao seu software CRM uma URL para integração de anúncios com a Koort, baseado em nossa <a rel="noreferrer noopener" target="_blank" href='https://developers.koort.com.br/'>documentação</a>;
              </h6>
            </li>
            <li>
              <h6 className={"text_p_2"}>
                Cadastre a url correspondente no formulário a seguir;
              </h6>
            </li>
            <li>
              <h6 className={"text_p_2"}>
                Acompanhe o sucesso pelo {' '}
                <Link to={pathsConstants.CONFIG_ACCOUNT_AD_INTEGRATION_LOGS}>
                  log de integração
                </Link>
                .
              </h6>
            </li>
          </StepsList>
        </Col>

        <Row
          as="form"
          onSubmit={handleSubmit(handleUrlIntegrationFormSubmit)}
        >
          <Col
            sm={8}
            md={12}
            style={{ paddingTop: '3%' }}>
            <h6 className={"text_p_2"}>
              URL de Integração
            </h6>
          </Col>

          <Col xs={8} md={6}>
            <InputBootstrap
              name="URLIntegracao"
              label="Informe a URL"
              disabled={!!urlIntegration}
              placeholder="Informe a URL"
              error={errors.urlIntegration}
              {...register('urlIntegration')}
            />
          </Col>

          {urlIntegration && (
            <Col xs={4} md={2} className={"btn_2"}>
              <button
                type="button"
                className={"bt-global_2"}
                style={{ backgroundColor: '#dc3545' }}
                onClick={removeUrlIntegration}
              >
                Remover
              </button>
            </Col>
          )}

          {!urlIntegration && (
            <Col xs={4} md={2} className={"btn_2"}>
              <button
                id="integration-xml-button"
                disabled={urlIntegrationGetError}
                type="submit"
                className={"bt-global_2"}
              >
                Adicionar
              </button>
            </Col>
          )}
        </Row>
      </Row>

      <Modal
        isOpen={isSubmitting}
        contentLabel="Modal de Integração do XML"
        style={{
          content: {
            border: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'unset',
            borderRadius: 'unset',
          }
        }}
      >
        <UrlIntegrationModalBody>
          <h3>Aguarde um momento enquanto validamos a sua URL de integração</h3>

          <CircularProgress color="inherit" size={32} />
        </UrlIntegrationModalBody>
      </Modal>

      <Modal
        isOpen={xmlErrorModalVisible}
        contentLabel="Modal de Confirmação de Envio XML"
        style={{
          content: {
            border: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'unset',
            borderRadius: 'unset',
          }
        }}
      >
        <UrlIntegrationModalBody>
          <h3>{xmlErrorMessage}</h3>

          <p>Deseja continuar mesmo assim, sabendo que serão integrados apenas o número de anúncios disponíveis?</p>

          <RecurrenceModalButtons>
            <ConfirmRecurrenceButton
              onClick={() => setXmlErrorModalVisible(false)}
              type="button"
            >
              Não
            </ConfirmRecurrenceButton>

            <DenyRecurrenceButton
              onClick={handleForceIntegration}
              type="button"
            >
              Continuar mesmo assim
            </DenyRecurrenceButton>
          </RecurrenceModalButtons>
        </UrlIntegrationModalBody>
      </Modal>
    </Container>
  );
}
