import styled from 'styled-components';
import Select from 'react-select';

export const ReactSelect = styled(Select)`
  .react-select__control {
    border: 0 !important;
    box-shadow: none !important;
    border-color: none !important;
    min-height: 32px !important;
  }

  .react-select__value-container {
    padding: 0 !important;
  }

  /* .react-select__indicators {
    display: none !important;
  } */

  margin-top: -4px;
`;
